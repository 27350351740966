/* eslint-disable react/no-unknown-property */
import {
  Action, CheckboxToggle, HScroll, ICON_COMPARE_OUTLINE, Message, Spacing, Icon, Typography,
  HStack,
  CardSkeleton,
} from '@doveit/bricks';
import React, {
  FunctionComponent, HTMLAttributes, useCallback, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/card/Card';
import ContextualNavigation, { ContextualNavigationItem } from '../../../components/contextual-navigation/ContextualNavigation';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import { useStatefulPagination } from '../../../hooks/use-pagination/usePagination';
import ViewIntentCard from '../../../intent/containers/view-intent-card/ViewIntentCard';
import { IntentSection } from '../../../intent/types';
import MatchingSearchCriteriaCard from '../../../matching-search-criteria/components/matching-search-criteria-card/MatchingSearchCriteriaCard';
import { MatchingSearchCriteria, Intent, MatchingIntents } from '../../../providers/api/dtos';
import usePropertyMatchCounters from '../../hooks/use-property-match-counters/usePropertyMatchCounters';
import usePropertyMatches, { ALL_PROPERTY_MATCHES_RIDS } from '../../hooks/use-property-matches/usePropertyMatches';
import { toPropertyMatchSectionCounters } from '../../mappers/propertyMatchCountersMapper';
import { PropertyMatchAdditionalSection, PropertyMatchesSection } from '../../types';
import { useMatchMutate } from '../../../utils/swr/swr';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import {
  ARCHIVED_INTENTS_NAVIGATION_ITEMS,
  ARCHIVED_INTENTS_SECTIONS,
  MAIN_NAVIGATION_ITEMS, OFFERS_NAVIGATION_ITEMS, OFFERS_SECTIONS, PENDING_INTENTS_NAVIGATION_ITEMS, PENDING_INTENTS_SECTIONS, POTENTIAL_BUYERS_NAVIGATION_ITEMS, POTENTIAL_BUYERS_SECTIONS, SCHEDULED_INTENTS_NAVIGATION_ITEMS, SCHEDULED_INTENTS_SECTIONS,
} from './constants';
import { NavigationItem } from '../../../types';
import { PropertyMatchesNavigationItem } from './types';
import ViewMatchingIntentsCard from '../../../matching-intents/containers/view-matching-intents-card/ViewMatchingIntentsCard';
import { Status } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { NO_VALUE_SYMBOL } from '../../utils';
import usePropertyPreview from '../../hooks/use-property-preview/usePropertyPreview';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';

export const PROPERTY_MATCHES_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare i match dell\'immobile';
export const NO_PROPERTY_MATCHES_MESSAGE = 'Non sono presenti match';

export interface PropertyMatchesWidgetProps extends Pick<HTMLAttributes<HTMLDivElement>, 'id'> {
  propertyId: number,
}

const PropertyMatchesWidget: FunctionComponent<PropertyMatchesWidgetProps> = ({
  propertyId,
  ...rest
}) => {
  const [currentMainSection, setCurrentMainSection] = useState<PropertyMatchesSection>(IntentSection.TODO);
  const [currentSubSection, setCurrentSubSection] = useState<PropertyMatchesSection>(IntentSection.TODO);
  const [showHiddenPotentialBuyers, setShowHiddenPotentialBuyers] = React.useState(false);
  const navigate = useNavigate();
  const matchMutate = useMatchMutate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { page, goToPage } = useStatefulPagination(scrollRef);
  const { data: property, error: propertyError } = usePropertyPreview(propertyId);
  const { userIsAdmin } = useRBAC();

  const propertyMatchesFilters = React.useMemo(() => ({
    showHiddenMatches: showHiddenPotentialBuyers,
  }), [showHiddenPotentialBuyers]);

  const {
    data: propertyMatches,
    error: propertyMatchesError,
    mutate: mutatePropertyMatches,
  } = usePropertyMatches(
    currentSubSection,
    propertyId,
    propertyMatchesFilters,
    page,
  );
  const { data: propertyMatchesCounters, mutate: mutatePropertyMatchCounters } = usePropertyMatchCounters(propertyId, propertyMatchesFilters);
  const { data: agent } = useAgentByPropertyId(propertyId);
  const isPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);

  const isSellableProperty = useMemo(
    () => property && property.status !== Status.VENDUTO && property.status !== Status.RITIRATO,
    [property],
  );

  const formatNavigationItemLabel = useCallback((item: NavigationItem, counters?: { [key: string]: number }) => `${item.label} (${counters ? counters[item.id] : NO_VALUE_SYMBOL})`, []);

  const isMainItemActive = useCallback((item: PropertyMatchesNavigationItem) => item.id === currentMainSection, [currentMainSection]);
  const isSubItemActive = useCallback((item: PropertyMatchesNavigationItem) => item.id === currentSubSection, [currentSubSection]);

  const goToSubSection = useCallback((section: PropertyMatchesSection) => () => {
    setCurrentSubSection(section);
    goToPage(1);
  }, [goToPage]);

  const goToMainSection = useCallback((section: PropertyMatchesSection) => () => {
    setCurrentMainSection(section);
    goToSubSection(section)();
  }, [goToSubSection]);

  const mutateMatches = React.useCallback(async () => {
    await mutatePropertyMatches();
    await mutatePropertyMatchCounters();
  }, [mutatePropertyMatchCounters, mutatePropertyMatches]);

  const changePotentialBuyersToggle = React.useCallback(async () => {
    setShowHiddenPotentialBuyers((prev) => !prev);
  }, []);

  React.useEffect(() => {
    mutateMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHiddenPotentialBuyers]);

  const showHidePotentialBuyersToggle = React.useMemo(() => {
    if (!isSellableProperty) {
      return false;
    }

    const hasPrivileges = userIsAdmin || isPropertyAgentOrManager;

    return hasPrivileges && POTENTIAL_BUYERS_SECTIONS.includes(currentSubSection);
  }, [currentSubSection, isPropertyAgentOrManager, isSellableProperty, userIsAdmin]);

  const isASearchCriteriaSection = useMemo(() => currentSubSection === PropertyMatchAdditionalSection.SEARCH_CRITERIA, [currentSubSection]);

  const isTheSimilarIntentsSection = useMemo(() => currentSubSection === PropertyMatchAdditionalSection.SIMILAR_INTENTS, [currentSubSection]);

  const sectionCounters = useMemo(() => (propertyMatchesCounters
    ? toPropertyMatchSectionCounters(propertyMatchesCounters)
    : undefined), [propertyMatchesCounters]);

  const sectionCountersAggregated: { [key: string]: number } | undefined = useMemo(() => {
    if (!sectionCounters) {
      return undefined;
    }

    return {
      [IntentSection.TODO]: PENDING_INTENTS_SECTIONS.map((it) => sectionCounters[it]).reduce((sum, count) => sum + count),
      [PropertyMatchAdditionalSection.SEARCH_CRITERIA]: POTENTIAL_BUYERS_SECTIONS.map((it) => sectionCounters[it]).reduce((sum, count) => sum + count),
      [IntentSection.OFFERS_IN_PROGRESS]: OFFERS_SECTIONS.map((it) => sectionCounters[it]).reduce((sum, count) => sum + count),
      [IntentSection.SCHEDULED_TODO]: SCHEDULED_INTENTS_SECTIONS.map((it) => sectionCounters[it]).reduce((sum, count) => sum + count),
      [PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITH_DONE_APPOINTMENTS]: ARCHIVED_INTENTS_SECTIONS.map((it) => sectionCounters[it]).reduce((sum, count) => sum + count),
    };
  }, [sectionCounters]);

  const tabItems: ContextualNavigationItem[] = useMemo(() => MAIN_NAVIGATION_ITEMS.map((item) => {
    const label = formatNavigationItemLabel(item, sectionCountersAggregated);

    return ({
      id: item.id,
      label: formatNavigationItemLabel(item, sectionCountersAggregated),
      'aria-label': label,
      active: isMainItemActive(item),
      onClick: goToMainSection(item.id),
    });
  }), [formatNavigationItemLabel, sectionCountersAggregated, isMainItemActive, goToMainSection]);

  const subNavigation = useMemo(() => {
    if (PENDING_INTENTS_SECTIONS.includes(currentSubSection)) {
      return {
        id: 'todo',
        items: PENDING_INTENTS_NAVIGATION_ITEMS,
      };
    }

    if (POTENTIAL_BUYERS_SECTIONS.includes(currentSubSection)) {
      return {
        id: 'potential-buyers',
        items: POTENTIAL_BUYERS_NAVIGATION_ITEMS,
      };
    }

    if (SCHEDULED_INTENTS_SECTIONS.includes(currentSubSection)) {
      return {
        id: 'scheduled',
        items: SCHEDULED_INTENTS_NAVIGATION_ITEMS,
      };
    }

    if (OFFERS_SECTIONS.includes(currentSubSection)) {
      return {
        id: 'offers',
        items: OFFERS_NAVIGATION_ITEMS,
      };
    }

    if (ARCHIVED_INTENTS_SECTIONS.includes(currentSubSection)) {
      return {
        id: 'archived',
        items: ARCHIVED_INTENTS_NAVIGATION_ITEMS,
      };
    }

    return undefined;
  }, [currentSubSection]);

  const onViewIntentActionClick = useCallback((matchedIntent: Intent) => () => {
    navigate(`/intents/${matchedIntent.id}`);
  }, [navigate]);

  const onViewSearchCriteriaActionClick = useCallback((searchCriteriaId: number) => {
    navigate(`/search-criteria/${searchCriteriaId}`);
  }, [navigate]);

  const propertyMatchesMutate = useCallback(() => {
    ALL_PROPERTY_MATCHES_RIDS.forEach((rid) => matchMutate(rid));
    mutatePropertyMatchCounters();
  }, [matchMutate, mutatePropertyMatchCounters]);

  if ((!property && !propertyError) || (!propertyMatches && !propertyMatchesError)) {
    return <CardSkeleton />;
  }

  if (propertyError || propertyMatchesError) {
    return (
      <Message
        type="critical"
        boxed
        message={PROPERTY_MATCHES_LOAD_ERROR_MESSAGE}
      />
    );
  }

  return property! && propertyMatches! && (
    <Card {...rest}>
      <Card.Header>
        <Card.Title>
          <Icon path={ICON_COMPARE_OUTLINE} />
          <Typography.HEADING_3 color="brand.primary">
            Matching
          </Typography.HEADING_3>
        </Card.Title>
      </Card.Header>
      <Card.Box>
        <ContextualNavigation items={tabItems} />
        <Spacing padding={[200, 0, 0]}>
          <HStack alignItems="center" justifyContent="space-between">
            {subNavigation && (
              <div data-ref={`${subNavigation.id}-navigation`}>
                <HScroll>
                  <HStack>
                    {subNavigation.items.map((item) => (
                      <Action
                        key={`${item.id}-${item.label}`}
                        dataRef={`${subNavigation.id}-navigation-${item.id}`}
                        aria-label={formatNavigationItemLabel(item, sectionCounters)}
                        label={item.label}
                        badgeLabel={sectionCounters ? sectionCounters[item.id].toString() : NO_VALUE_SYMBOL}
                        size="S"
                        color={isSubItemActive(item) ? 'primary' : 'neutral'}
                        onClick={goToSubSection(item.id)}
                      />
                    ))}
                  </HStack>
                </HScroll>
              </div>
            )}

            {showHidePotentialBuyersToggle && (
              <CheckboxToggle
                checked={showHiddenPotentialBuyers}
                text="Mostra nascosti"
                aria-label={`${showHiddenPotentialBuyers ? 'Non mostrare' : 'Mostra'} risultati nascosti`}
                onChange={changePotentialBuyersToggle}
              />
            )}
          </HStack>
          <Spacing margin={[200, 0, 0]}>
            <PaginatedList
              {...propertyMatches}
              emptyResultMessage={NO_PROPERTY_MATCHES_MESSAGE}
              goToPage={goToPage}
              hideResultCount
            >
              {propertyMatches.content.map((propertyMatch) => {
                if (isASearchCriteriaSection) {
                  const matchSearchCriteria = propertyMatch as MatchingSearchCriteria;
                  return (
                    <MatchingSearchCriteriaCard
                      key={matchSearchCriteria.contactId}
                      matchingSearchCriteria={matchSearchCriteria}
                      property={property}
                      propertyType={property.propertyType}
                      onClickVisitAction={onViewSearchCriteriaActionClick}
                      onIntentCreated={propertyMatchesMutate}
                      showCreateIntentAction={isSellableProperty}
                      showContactMatchBlacklistAction
                      disableCreateIntentAction={!isPropertyAgentOrManager}
                      onContactMatchBlacklistUpdated={mutateMatches}
                      disableContactMatchBlacklistAction={!showHidePotentialBuyersToggle}
                    />
                  );
                }

                if (isTheSimilarIntentsSection) {
                  const matchIntents = propertyMatch as MatchingIntents;

                  return (
                    <ViewMatchingIntentsCard
                      key={matchIntents.propertyId}
                      property={property}
                      disableAddIntentAction={!isPropertyAgentOrManager}
                      disableContactMatchBlacklistAction={!showHidePotentialBuyersToggle}
                      matchingIntents={matchIntents}
                      onIntentCreated={mutateMatches}
                      onContactMatchBlacklistAction={mutateMatches}
                    />
                  );
                }

                const matchedIntent = propertyMatch as Intent;
                return (
                  <ViewIntentCard
                    key={matchedIntent.id}
                    intent={matchedIntent}
                    disableEditIntentAction={!isPropertyAgentOrManager}
                    onViewActionClick={onViewIntentActionClick(matchedIntent)}
                    onAppointmentOutcome={propertyMatchesMutate}
                  />
                );
              })}
            </PaginatedList>
          </Spacing>
        </Spacing>
      </Card.Box>
    </Card>
  );
};

export default PropertyMatchesWidget;
