import {
  ActionProps,
  Dropdown,
  ICON_ACCOUNT_CIRCLE_OUTLINE, Message, Portal, Spacing, useModal, Card,
  HStack,
  Badge,
  ICON_CHECK,
  ActionDropDown,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback } from 'react';
import { UserActionsProps } from '../../../components/user-actions/UserActions';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Contact } from '../../../providers/api/dtos/contact';
import { slugIt } from '../../../utils/string';
import UpsertContact from '../../containers/upsert-contact/UpsertContact';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import ContactInfo from '../contact-info/ContactInfo';
import useContactConcessions from '../../hooks/use-contact-concessions/useContactConcessions';
import { concessionLabels } from '../../../labels';
import UpdateContactAdditionalServicesAction from '../../containers/update-contact-additional-services-action/UpdateContactAdditionalServicesAction';
import UpdateContactOptInsAction from '../../containers/update-contact-opt-ins-action/UpdateContactOptInsAction';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import UpdateContactConcessionsAction from '../../containers/update-contact-concessions-action/UpdateContactConcessionsAction';

export interface ContactWidgetProps extends Pick<UserActionsProps, 'whatsAppMessage' | 'onCallActionClick' | 'onEmailActionClick' | 'onLinkedInActionClick' > {
  contact: Contact,
  title?: string,
  onUpdate?: (updatedContact: Contact) => void,
  hideViewAction?: boolean,
  hideEditAction?: boolean,
  hideExtraInfo?: boolean,
  hideContactActions?: boolean,
  extraActions?: ActionProps[],
}

export const UPDATE_CONTACT_WARNING_MESSAGE = 'Stai modificando il contatto{{contactName}}. Le modifiche saranno visibili in ogni interesse, notizia, valutazione o immobile associato al contatto.';

const ContactWidget: FunctionComponent<ContactWidgetProps> = ({
  contact,
  title = 'Contatto',
  onUpdate,
  hideViewAction,
  hideEditAction,
  hideContactActions,
  extraActions = [],
  whatsAppMessage,
  onCallActionClick,
  onEmailActionClick,
  onLinkedInActionClick,
  ...rest
}) => {
  const isMobile = useIsDevice('mobile');
  const {
    userIsAdmin, userIsAgent, userIsCallCenter, userIsFinance,
  } = useRBAC();
  const contactModal = useModal();

  const { data: concessions = [] } = useContactConcessions(contact?.id);

  const onSuccess = useCallback((updatedContact: Contact) => {
    contactModal.close();

    if (onUpdate) {
      onUpdate(updatedContact);
    }
  }, [contactModal, onUpdate]);

  const goToContactPage = useCallback(() => {
    goToDetailPage(contact.id!, ReferenceType.CONTACT);
  }, [contact]);

  const onEditContactClick = React.useCallback(() => contactModal.open(), [contactModal]);

  const canActWithHighPrivileges = React.useMemo(() => userIsAdmin || userIsAgent || userIsCallCenter,
    [userIsAdmin, userIsAgent, userIsCallCenter]);

  return (
    <Card {...rest}>
      <Card.Header
        title={title}
        icon={{ path: ICON_ACCOUNT_CIRCLE_OUTLINE }}
        primaryActions={
          !isMobile && !hideEditAction && (canActWithHighPrivileges || userIsFinance) ? [
            <ActionDropDown
              label="Modifica"
              size="S"
              emphasis="low"
              aria-label="Azioni di modifica del contatto"
            >
              {canActWithHighPrivileges && (
                <Dropdown.Option
                  onClick={onEditContactClick}
                  label="Modifica contatto"
                  aria-label="Pulsante per modificare le informazioni del contatto"
                />
              )}
              {contact.id !== null && canActWithHighPrivileges && (
                <UpdateContactAdditionalServicesAction
                  contactId={contact.id!}
                  canAdd
                  canDelete
                >
                  {({ isSaving, update }) => (
                    <Dropdown.Option
                      onClick={update}
                      label="Modifica servizi"
                      loading={isSaving}
                    />
                  )}
                </UpdateContactAdditionalServicesAction>
              )}
              {contact.id !== null && canActWithHighPrivileges && (
                <UpdateContactOptInsAction
                  contactId={contact.id!}
                  canAdd={userIsAdmin || userIsCallCenter}
                  canDelete={userIsAdmin || userIsCallCenter}
                >
                  {({ isSaving, update }) => (
                    <Dropdown.Option
                      onClick={update}
                      label="Modifica consensi"
                      loading={isSaving}
                    />
                  )}
                </UpdateContactOptInsAction>
              )}
              {contact.id !== null && (canActWithHighPrivileges || userIsFinance) && (
                <UpdateContactConcessionsAction
                  contactId={contact.id!}
                  canAdd
                  canDelete
                >
                  {({ isSaving, update }) => (
                    <Dropdown.Option
                      onClick={update}
                      label="Modifica agevolazioni"
                      loading={isSaving}
                    />
                  )}
                </UpdateContactConcessionsAction>
              )}
            </ActionDropDown>,
          ] : undefined
        }
        secondaryActions={[
          !hideViewAction && canActWithHighPrivileges && (
            <Dropdown.Option
              label="Visualizza"
              aria-label="Visualizza contatto"
              onClick={goToContactPage}
            />
          ),
          isMobile && canActWithHighPrivileges && (
            <Dropdown.Option
              label="Modifica contatto"
              aria-label="Pulsante per modificare le informazioni del contatto"
              onClick={onEditContactClick}
            />
          ),
          isMobile && canActWithHighPrivileges && contact.id !== null && (
            <UpdateContactAdditionalServicesAction
              contactId={contact.id!}
              canAdd
              canDelete
            >
              {({ isSaving, update }) => (
                <Dropdown.Option
                  onClick={update}
                  label="Modifica servizi"
                  loading={isSaving}
                />
              )}
            </UpdateContactAdditionalServicesAction>
          ),
          isMobile && canActWithHighPrivileges && contact.id !== null && (
            <UpdateContactOptInsAction
              contactId={contact.id!}
              canAdd={userIsAdmin || userIsCallCenter}
              canDelete={userIsAdmin || userIsCallCenter}
            >
              {({ isSaving, update }) => (
                <Dropdown.Option
                  onClick={update}
                  label="Modifica consensi"
                  loading={isSaving}
                />
              )}
            </UpdateContactOptInsAction>
          ),
          isMobile && (canActWithHighPrivileges || userIsFinance) && contact.id !== null && (
            <UpdateContactConcessionsAction
              contactId={contact.id!}
              canAdd
              canDelete
            >
              {({ isSaving, update }) => (
                <Dropdown.Option
                  onClick={update}
                  label="Modifica agevolazioni"
                  loading={isSaving}
                />
              )}
            </UpdateContactConcessionsAction>
          ),
          ...(!hideEditAction && canActWithHighPrivileges ? extraActions.map((actionConfig) => (
            <Dropdown.Option
              key={slugIt(actionConfig.label)}
              label={actionConfig.label}
              onClick={actionConfig.onClick}
              href={actionConfig.href}
            />
          )) : []),
        ]}
      />
      <Card.Content>
        <ContactInfo
          contact={contact}
          actions={{
            whatsAppMessage,
            onCallActionClick,
            onEmailActionClick,
            onLinkedInActionClick,
          }}
          hideActions={hideContactActions}
        />
      </Card.Content>
      {concessions.length > 0 && (
        <>
          <Card.Divider />

          <Card.Content>
            <HStack aria-label="Lista agevolazioni">
              {concessions.map(({ id: concessionId, type }) => (
                <Badge
                  key={concessionId}
                  icon={ICON_CHECK}
                  label={concessionLabels[type]}
                  size="XS"
                  color="primary"
                />
              ))}
            </HStack>
          </Card.Content>
        </>
      )}
      <Portal>
        <SimpleModal
          {...contactModal}
          data-ref="contact-modal"
          title="Modifica contatto"
        >
          <Spacing margin={[0, 0, 200]}>
            <Message
              type="warning"
              message={UPDATE_CONTACT_WARNING_MESSAGE.replace('{{contactName}}', ` ${contact.name}` || '')}
            />
          </Spacing>
          <UpsertContact
            contact={contact}
            onSuccess={onSuccess}
            submitLabel="Modifica"
          />
        </SimpleModal>
      </Portal>
    </Card>
  );
};

export default ContactWidget;
