import useSWR from 'swr';
import hash from 'object-hash';
import { PaginatedResultWithInfoAndStats, PaginationParams } from '../../../providers/pagination';
import { CrawledPropertiesFilter, getCrawledProperties } from '../../../providers/api/crawled-property/crawledPropertyProvider';
import { CrawledProperty } from '../../../providers/api/dtos/crawledProperties';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';

export type CrawledPropertiesResponse = SWRCustomResponse<PaginatedResultWithInfoAndStats<CrawledProperty>, Error>;

export const CRAWLED_PROPERTIES_RID = '/crawled-properties/';

export default function useCrawledProperties(
  filter: CrawledPropertiesFilter,
  pagination?: PaginationParams,
): CrawledPropertiesResponse {
  const matchMutate = useMatchMutate();
  const swrResponse = useSWR([CRAWLED_PROPERTIES_RID, hash({ ...filter, ...pagination })], () => getCrawledProperties(filter, pagination));

  return {
    ...swrResponse,
    mutate: () => matchMutate(CRAWLED_PROPERTIES_RID),
  };
}
