import { AreaType, PlaceType } from '../../../domain/types';
import {
  Location, Point, GeoLocationResult, AreaSuggestion, PlaceSuggestion,
} from '../dtos';
import {
  PUBLIC_API_LOCATIONS_PATH, PUBLIC_API_SUGGEST_PATH, getClientV1, getClientV2, PUBLIC_API_PLACE_SUGGEST_PATH,
} from '../client';
import { serializeParams } from '../../api/utils/serializer';

export interface SuggestPlaceParams {
  size?: number,
  type?: PlaceType | PlaceType[],
  excludeName?: string | string[],
}

export async function getLocationsByMunicipalityCodes(municipalityCodes: string[]): Promise<Location[]> {
  const client = await getClientV1();
  const { data } = await client.get(PUBLIC_API_LOCATIONS_PATH, {
    params: {
      municipalityCodes: municipalityCodes.join(','),
    },
  });

  return data;
}

export async function getAreaSuggestions(value: string, areaType: AreaType): Promise<AreaSuggestion[]> {
  const client = await getClientV2();
  const { data } = await client.get(`${PUBLIC_API_SUGGEST_PATH}/${areaType}/${value}`);

  return data;
}

export async function geolocate(point: Point): Promise<GeoLocationResult> {
  const client = await getClientV1();
  const { data } = await client.get(`${PUBLIC_API_LOCATIONS_PATH}/geolocate`, {
    params: point,
  });

  return data;
}

export async function getPlaceSuggestions(
  query: string,
  params: SuggestPlaceParams = {},
): Promise<PlaceSuggestion[]> {
  const client = await getClientV1();

  const { data } = await client.get<PlaceSuggestion[]>(`${PUBLIC_API_PLACE_SUGGEST_PATH}/${query}`, {
    params: serializeParams(params),
  });

  return data;
}
