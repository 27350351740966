export enum ReferenceType {
  CONTACT = 'CONTACT',
  INTENT = 'INTENT',
  JOB_APPLICATION = 'JOB_APPLICATION',
  LEAD = 'LEAD',
  PROPERTY = 'PROPERTY',
  PROSPECT = 'PROSPECT',
  RUMOR = 'RUMOR',
  SEARCH_CRITERIA = 'SEARCH_CRITERIA',
}
