import { API_CRAWLED_PROPERTIES, getClient } from '../api';
import { PaginatedResultWithInfoAndStats, PaginationParams, serializePagination } from '../../pagination';
import { serializeParams } from '../utils/serializer';
import { CrawledProperty } from '../dtos';
import { ResidentialPropertyType } from '../../../domain/types';

export interface CrawledPropertiesFilter {
  latitude: number,
  longitude: number
  radius?: number,
  expired?: boolean,
  propertyType?: ResidentialPropertyType,
}

export async function getCrawledProperties(
  filter: CrawledPropertiesFilter,
  pagination: PaginationParams = { size: 50 },
): Promise<PaginatedResultWithInfoAndStats<CrawledProperty>> {
  const client = await getClient();
  const { data } = await client.get(API_CRAWLED_PROPERTIES, {
    params: {
      ...serializeParams(filter),
      ...serializePagination(pagination),
    },
  });

  return data;
}
