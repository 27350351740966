import {
  Action, FormHandlers, Portal, useModal,
} from '@doveit/bricks';
import React, {
  FunctionComponent, useCallback, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { ReferenceType } from '../../../domain/types';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { Rumor } from '../../../providers/api/dtos/rumor';
import UpsertReminder, { UpsertReminderProps } from '../../../reminders/containers/upsert-reminder/UpsertReminder';
import useFilters, { Filters } from '../../../hooks/use-filters/useFilters';
import CreateRumor from '../../containers/create-rumor/CreateRumor';

interface CreateRumorPageFilters extends Filters {
  contactId?: string;
}

const CreateRumorPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { filters: { contactId } } = useFilters<CreateRumorPageFilters>();
  const formRef = useRef<FormHandlers>() as React.MutableRefObject<FormHandlers>;
  const addReminderModal = useModal<UpsertReminderProps>();
  const [rumor, setRumor] = useState<Rumor | undefined>();
  const [isSavingReminder, setIsSavingReminder] = useState(false);

  const onSuccess = useCallback((createdRumor: Rumor) => {
    setRumor(createdRumor);
    addReminderModal.open({
      reminder: {
        agentId: createdRumor.agentId,
        referenceId: createdRumor.id!,
        referenceType: ReferenceType.RUMOR,
        resolved: false,
      },
    });
  }, [addReminderModal]);

  const goToRumorPage = useCallback(() => {
    if (rumor) {
      navigate(`/rumors/${rumor.id}`);
    }
  }, [navigate, rumor]);

  const saveReminder = useCallback(async () => {
    const form = formRef.current as any;
    setIsSavingReminder(true);

    const errors = await form.validateForm();
    form.handleSubmit();

    if (Object.keys(errors).length > 0) {
      setIsSavingReminder(false);
    }
  }, []);

  const onAddReminderSuccess = useCallback(() => {
    setIsSavingReminder(false);
    goToRumorPage();
  }, [goToRumorPage]);

  const onAddReminderError = useCallback(() => {
    setIsSavingReminder(false);
  }, []);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent>
          <CreateRumor
            contactId={contactId}
            onSuccess={onSuccess}
          />
        </GenericPageLayout.InnerContent>

        {addReminderModal.data && (
          <Portal>
            <SimpleModal
              {...addReminderModal}
              title="Vuoi aggiungere un promemoria?"
              footer={(
                <>
                  <Action
                    label="Salta"
                    onClick={goToRumorPage}
                    disabled={isSavingReminder}
                  />
                  <Action
                    label="Crea promemoria"
                    color="primary"
                    emphasis="high"
                    onClick={saveReminder}
                    loading={isSavingReminder}
                  />
                </>
              )}
            >
              <UpsertReminder
                {...addReminderModal.data}
                onSuccess={onAddReminderSuccess}
                onError={onAddReminderError}
                formRef={formRef}
                hideSubmit
              />
            </SimpleModal>
          </Portal>
        )}
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default CreateRumorPage;
