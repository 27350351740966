import React from 'react';
import { Action, ICON_TRANSLATE, useNotifications } from '@doveit/bricks';
import { TranslationRequest, TranslationResponse } from '../../providers/api/dtos/contentProcessing';
import { translate } from '../../providers/api/content-processing/contentProcessingProvider';

export const TRANSLATE_TEXT_ERROR_MESSAGE = 'Non è stato possibile tradurre il contenuto';

interface ChildFnProps {
  disabled: boolean,
  translate: VoidFunction,
}

export interface TranslateTextActionProps {
  text: string,
  disabled?: boolean,
  children?: (props: ChildFnProps) => React.ReactNode,
  onClick?: VoidFunction,
  onError?: (err: Error) => void,
  onSuccess?: (res: TranslationResponse) => void,
}
const TranslateTextAction: React.FC<TranslateTextActionProps> = ({
  text,
  disabled = false,
  children,
  onClick,
  onSuccess,
  onError,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { addError } = useNotifications();

  const translateInfo = React.useCallback(async () => {
    try {
      setIsLoading(true);
      onClick?.();

      const translationRequest: TranslationRequest = {
        context: 'TRANSLATE',
        text,
      };

      const translationResponse = await translate(translationRequest);

      setIsLoading(false);
      onSuccess?.(translationResponse);
    } catch (err) {
      setIsLoading(false);
      onError?.(err as Error);
      addError(TRANSLATE_TEXT_ERROR_MESSAGE);
    }
  }, [addError, onClick, onError, onSuccess, text]);

  return children?.({ disabled, translate: translateInfo }) ?? (
    <Action
      label="Traduci da italiano"
      aria-label="Traduci il contenuto da Italiano a Inglese"
      disabled={disabled}
      title={disabled ? 'Non è possibile tradurre il contenuto' : 'Traduci il contenuto da italiano a inglese utilizzando l\'AI'}
      loading={isLoading}
      iconLeft={{ path: ICON_TRANSLATE }}
      size="S"
      color="primary"
      onClick={translateInfo}
    />
  );
};
export default TranslateTextAction;
