import useSWR, { SWRConfiguration } from 'swr';
import hash from 'object-hash';
import { Intent } from '../../../providers/api/dtos/intent';
import { getIntentsWithOffer, IntentsWithOfferProviderFilters } from '../../../providers/api/intent/intentProvider';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';

export const INTENTS_WITH_OFFER_RID = '/api/v2/intents/with-offer';

export default function useIntentsWithOffer(
  filters?: IntentsWithOfferProviderFilters,
  pagination?: PaginationParams,
  options?: SWRConfiguration,
): SWRCustomResponse<PaginatedResult<Intent>, Error> {
  const matchMutate = useMatchMutate();
  const key = filters ? [INTENTS_WITH_OFFER_RID, hash({ ...filters, ...pagination })] : null;
  const swrResponse = useSWR(key, () => getIntentsWithOffer(filters!, pagination), options);

  return {
    ...swrResponse,
    mutate: () => matchMutate(INTENTS_WITH_OFFER_RID),
  };
}
