import React from 'react';
import { Form, FormActionsAlign, FormProps } from '@doveit/bricks';
import { BooleanString } from '../../../utils/boolean-string/booleanString';
import { JobTitle } from '../../../domain/types';
import editAgentDetailsFormValidationSchema from './EditAgentDetailsForm.schema';
import { isKeyOf } from '../../../utils/object/object';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { jobTitleLabels } from '../../../labels';
import * as styles from './EditAgentDetailsForm.style';

export interface EditAgentDetailsFormModel {
  jobTitle: string,
  hasLicense: BooleanString,
  needsSupervisor: BooleanString,
  inTrial: boolean,
}

export interface EditAgentDetailsFormProps {
  initialValues?: Partial<EditAgentDetailsFormModel>,
  onSubmit: FormProps<EditAgentDetailsFormModel>['onSubmit'],
}

export const EDIT_AGENT_DETAILS_FORM_DEFAULT_VALUES: EditAgentDetailsFormModel = {
  jobTitle: '',
  hasLicense: '',
  needsSupervisor: '',
  inTrial: false,
};

const jobTitleToHasAttributes: Record<string, {
  hasLicense?: BooleanString,
  needsSupervisor?: BooleanString,
  inTrial?: boolean,
}> = {
  '': {
    hasLicense: '',
    needsSupervisor: '',
    inTrial: false,
  },
  [JobTitle.AGENTE]: {
    hasLicense: 'true',
    inTrial: false,
  },
  [JobTitle.AREA_MANAGER]: {
    hasLicense: 'true',
    needsSupervisor: 'false',
    inTrial: false,
  },
  [JobTitle.CONSULENTE]: {
    hasLicense: 'false',
    needsSupervisor: 'true',
    inTrial: false,
  },
};

const EditAgentDetailsForm: React.FC<EditAgentDetailsFormProps> = ({
  initialValues = {},
  onSubmit,
}) => {
  const mergedInitialValues = React.useMemo(() => ({
    ...EDIT_AGENT_DETAILS_FORM_DEFAULT_VALUES,
    ...jobTitleToHasAttributes[initialValues.jobTitle || EDIT_AGENT_DETAILS_FORM_DEFAULT_VALUES.jobTitle],
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={editAgentDetailsFormValidationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => {
        const onJobTitleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          const jobTitleSelected = e.target.value;

          if (isKeyOf(jobTitleToHasAttributes, jobTitleSelected)) {
            Object.entries(jobTitleToHasAttributes[jobTitleSelected]).forEach(([field, value]) => {
              setFieldValue(field, value, true);
            });
          }
        };

        const setToTrue = (fieldToChange: 'needsSupervisor' | 'hasLicense') => {
          const { jobTitle } = values;

          if (jobTitle === JobTitle.EXECUTIVE || jobTitle === JobTitle.TEAM_LEADER || jobTitle === JobTitle.BROKER) {
            setFieldValue(fieldToChange, 'true');
          }
        };

        const onNeedsSupervisorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value === 'false') {
            setToTrue('hasLicense');
          }
        };

        const onHasLicenseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value === 'false') {
            setToTrue('needsSupervisor');
          }
        };

        return (
          <>
            <Form.Item size={{ MD: 2 / 3 }}>
              <Form.Select
                label="Ruolo"
                name="jobTitle"
                aria-label="Campo per specificare il ruolo"
                options={asSelectOptions(jobTitleLabels)}
                onChange={onJobTitleChange}
                required
              />
            </Form.Item>

            {(values.jobTitle === JobTitle.AGENTE) && (
              <Form.Item
                size={{ MD: 1 / 3 }}
                css={styles.inTrialCheckbox}
              >
                <Form.CheckboxButton
                  text="Modalità trial"
                  name="inTrial"
                  aria-label="Campo per specificare se l'agente è in modalità trial"
                />
              </Form.Item>
            )}

            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.YesOrNotButtons
                label="Ha il patentino?"
                name="hasLicense"
                aria-label="Campo per specificare se ha il patentino"
                disabled={['', JobTitle.AREA_MANAGER, JobTitle.AGENTE, JobTitle.CONSULENTE].includes(values.jobTitle)}
                onChange={onHasLicenseChange}
                required
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.YesOrNotButtons
                label="Necessita approvazione?"
                name="needsSupervisor"
                aria-label="Campo per specificare se necessita di approvazione"
                disabled={['', JobTitle.AREA_MANAGER, JobTitle.CONSULENTE].includes(values.jobTitle)}
                onChange={onNeedsSupervisorChange}
                required
              />
            </Form.Item>

            <Form.Actions align={FormActionsAlign.RIGHT}>
              <Form.Submit
                label="Modifica"
                aria-label="Modifica dati"
              />
            </Form.Actions>
          </>
        );
      }}
    </Form>
  );
};

export default EditAgentDetailsForm;
