/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Swiper, SwiperClass, SwiperSlide,
} from 'swiper/react';
import {
  Navigation, Virtual, Thumbs,
} from 'swiper/modules';
import {
  ActionIcon,
  AutoCompleteV2,
  AutoCompleteV2OnSuggestionSelected,
  BREAKPOINTS_VALUES,
  ConfirmModal,
  FontWeight, ICON_TRASH_CAN_OUTLINE, ICON_VIEW_GALLERY_OUTLINE, Image, InputAction, Message, Portal, Skeleton,
  Spacing,
  Spinner,
  SpinnerSize,
  Text,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import { useTheme } from 'styled-components';
import useContentsByPropertyId from '../../hooks/use-contents-by-property-id/useContentsByPropertyId';
import { ContentAbout, ContentAboutType, ContentType } from '../../../domain/types';
import { Content, Property } from '../../../providers/api/dtos';
// @TODO: importare solo moduli strettamente necessari
import 'swiper/css/bundle';
import MultimediaContentThumbnail from '../../../content/components/MultimediaContentThumbnail';
import * as styles from './ViewMultimediaPropertyContentsGallery.style';
import { deleteContent, updateContent } from '../../../providers/api/content/contentProvider';
import { contentAboutLabels } from '../../../labels';

export interface ViewMultimediaPropertyContentsGalleryProps extends React.AriaAttributes {
  propertyId: NonNullable<Property['id']>,
  contentType: ContentType.FOTO | ContentType.PLANIMETRIA,
  initialSlideIndex?: number,
  canDelete?: boolean,
  canEdit?: boolean,
  canSeeMissingAboutError?: boolean,
  onContentDelete?: VoidFunction,
}

export interface ContentAboutSuggestion {
  value: ContentAboutType,
  label: string,
}

export const printSuggestion = (suggestion: ContentAboutSuggestion): string => suggestion.label;

export const ViewMultimediaPropertyContentsGallery: React.FC<ViewMultimediaPropertyContentsGalleryProps> = ({
  propertyId,
  contentType,
  initialSlideIndex = 0,
  canDelete = false,
  canEdit = false,
  canSeeMissingAboutError = false,
  'aria-label': ariaLabel = 'view-multimedia-property-contents-gallery',
  onContentDelete,
  ...rest
}) => {
  const {
    data: contents,
    error: contentsError,
    isLoading: isContentsLoading,
    mutate: mutateContents,
  } = useContentsByPropertyId(propertyId, contentType);

  const confirmModal = useModal();
  const [isSaving, setIsSaving] = React.useState(false);
  const [showThumbnails, setShowThumbnails] = React.useState(false);
  const [isRemovingContent, setIsRemovingContent] = React.useState<Content | undefined>();
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperClass | null>(null);
  const theme = useTheme();
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState<number>(initialSlideIndex);
  const [descriptionInput, setDescriptionInput] = React.useState<string | undefined>();
  const [selectedSuggestion, setSelectedSuggestion] = React.useState<ContentAboutSuggestion | undefined>();
  const typeLabel = React.useMemo(
    () => (contentType === ContentType.FOTO ? 'fotografie' : 'planimetrie'),
    [contentType],
  );

  React.useEffect(() => {
    const currentContent = contents?.[currentSlideIndex];

    setSelectedSuggestion((currentContent && currentContent?.about) ? {
      value: currentContent.about as ContentAboutType,
      label: contentAboutLabels[currentContent.about as ContentAboutType],
    } : undefined);
    setDescriptionInput((currentContent && currentContent?.about) ? contentAboutLabels[currentContent.about as ContentAboutType] : undefined);
  }, [contents, currentSlideIndex]);

  const { addSuccess, addError } = useNotifications();

  const removeCurrentContent = React.useCallback(async () => {
    if (contents?.[currentSlideIndex].id) {
      setIsSaving(true);
      setIsRemovingContent(contents[currentSlideIndex]);

      try {
        await deleteContent(contents[currentSlideIndex].id);

        setIsRemovingContent(undefined);
        setIsSaving(false);
        mutateContents();
        confirmModal.close();
        addSuccess('Il contenuto è stato eliminato.');

        onContentDelete?.();
      } catch (error) {
        setIsSaving(false);
        addError('Non è stato possibile eliminare il contenuto. Riprova.');
      }
    }
  }, [addError, addSuccess, confirmModal, contents, currentSlideIndex, mutateContents, onContentDelete]);

  const onAbortRemove = React.useCallback(() => {
    setIsRemovingContent(undefined);
    confirmModal.close();
  }, [confirmModal]);

  const suggestions: ContentAboutSuggestion[] = React.useMemo(() => {
    const allSuggestions: ContentAboutSuggestion[] = Object.values(ContentAbout).map((contentAbout) => ({
      value: contentAbout,
      label: contentAboutLabels[contentAbout],
    }));

    if (!descriptionInput) {
      return allSuggestions;
    }

    return allSuggestions.filter((suggestion) => suggestion.label.toLocaleLowerCase().includes(descriptionInput.toLocaleLowerCase()));
  }, [descriptionInput]);

  const onContentAboutSelected: (content: Content) => AutoCompleteV2OnSuggestionSelected<ContentAboutSuggestion | null> = React.useCallback((content) => async (suggestion) => {
    setIsSaving(true);

    try {
      await updateContent(content.id!, {
        ...content,
        about: suggestion?.value,
      });

      setIsSaving(false);
      setDescriptionInput(content.about ? contentAboutLabels[content.about as ContentAboutType] : undefined);
      setSelectedSuggestion(content.about ? {
        value: content.about as ContentAboutType,
        label: contentAboutLabels[content.about as ContentAboutType],
      } : undefined);
      addSuccess('La descrizione è stata modificata.');
      mutateContents();
    } catch (error) {
      setIsSaving(false);
      addError('Non è stato possibile modificare la descrizione.');
    }
  }, [addError, addSuccess, mutateContents]);

  const onDescriptionInputChange = React.useCallback((searchText: string, action: InputAction) => {
    switch (action) {
      case 'input-change':
        setDescriptionInput(searchText);
        break;
      case 'input-blur':
        setDescriptionInput(selectedSuggestion?.label || '');
        break;
      default:
    }
  }, [selectedSuggestion]);

  if (isContentsLoading) {
    // @TODO create a more specific skeleton
    return (
      <Skeleton
        count={3}
        aria-label="Contenuti in caricamento"
      />
    );
  }

  if (contentsError) {
    return (
      <Message
        type="critical"
        message="Non è stato possibile caricare i contenuti multimediali dell'immobile"
      />
    );
  }

  if (contents!.length === 0) {
    return (<Message message={`Non sono presenti ${typeLabel} dell'immobile.`} />);
  }

  return contents! && (
    <div
      {...rest}
      aria-label={ariaLabel}
    >
      <div css={styles.slider}>
        <div css={styles.header}>
          {showThumbnails && (
            <div css={styles.thumbnails}>
              <Swiper
                aria-label="thumbnails"
                onSwiper={(swiper) => { setThumbsSwiper(swiper); }}
                spaceBetween={theme.size[50]}
                slidesPerView={5}
                freeMode
                watchSlidesProgress
                modules={[Navigation, Virtual, Thumbs]}
                virtual
                navigation
                initialSlide={initialSlideIndex}
                breakpoints={{
                  [BREAKPOINTS_VALUES.MD]: {
                    slidesPerView: 8,
                  },
                  [BREAKPOINTS_VALUES.LG]: {
                    slidesPerView: 12,
                  },
                }}
              >
                {contents.map((content, index) => (
                  <SwiperSlide
                    aria-label="thumbnail"
                    key={content.id}
                    virtualIndex={index}
                  >
                    <MultimediaContentThumbnail
                      picture={{ src: content.thumbnail!, alt: content.about || '' }}
                      index={index + 1}
                      active={currentSlideIndex === index}
                      isRemoving={isRemovingContent?.id === content.id}
                      interactive
                      hasError={!content.about && canSeeMissingAboutError}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          <div css={styles.toolbar}>
            <div css={styles.info}>
              <ActionIcon
                label={showThumbnails ? 'Nascondi anteprime' : 'Mostra anteprime'}
                aria-label={showThumbnails ? 'Pulsante per nascondere le anteprime' : 'Pulsante per mostrare le anteprime'}
                icon={{ path: ICON_VIEW_GALLERY_OUTLINE }}
                onClick={() => setShowThumbnails((value) => !value)}
              />
              <div
                css={styles.status}
                fontWeight={FontWeight.REGULAR}
                aria-label="navigation-status"
              >
                {currentSlideIndex + 1} di {contents.length}
              </div>
            </div>
            <div css={styles.actions}>
              {canEdit && (
                <div css={styles.select}>
                  <AutoCompleteV2
                    aria-label="Autocomplete per modificare la descrizione del contenuto"
                    suggestions={suggestions}
                    printSuggestion={printSuggestion}
                    onSuggestionSelected={onContentAboutSelected(contents[currentSlideIndex])}
                    minLength={1}
                    loading={isSaving}
                    value={selectedSuggestion}
                    placeholder="Descrizione"
                    clearValueOnSelection
                    onInputChange={onDescriptionInputChange}
                    inputValue={descriptionInput || ''}
                  />
                </div>
              )}
              {canDelete && (
                <ActionIcon
                  label="Elimina dalla galleria"
                  aria-label="Pulsante per eliminare il contenuto dalla galleria"
                  icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                  emphasis="low"
                  onClick={confirmModal.open}
                  disabled={isSaving}
                />
              )}
            </div>
          </div>
        </div>
        <Swiper
          aria-label="slider"
          modules={[Navigation, Virtual, Thumbs]}
          spaceBetween={0}
          slidesPerView={1}
          initialSlide={initialSlideIndex}
          virtual
          thumbs={{ swiper: thumbsSwiper }}
          navigation
          onSlideChange={(swiper) => setCurrentSlideIndex(swiper.activeIndex)}
        >
          {contents.map((content, index) => (
            <SwiperSlide
              aria-label="slide"
              key={content.id}
              virtualIndex={index}
            >
              <div css={styles.slide}>
                <div css={styles.slideContent}>
                  <Image
                    aria-label={`${ariaLabel} (picture)`}
                    src={content.url!}
                    alt={content.about || ''}
                    lazyLoad
                  />
                  {isRemovingContent?.id === content.id && (
                    <div css={styles.removeOverlay}>
                      <Spinner
                        color="neutrals.lightest"
                        size={SpinnerSize.SMALL}
                      />
                      <Text.Body color="neutral.default.lowest" fontWeight={FontWeight.REGULAR}>
                        In rimozione
                      </Text.Body>
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Portal>
        <ConfirmModal
          isOpen={confirmModal.isOpen}
          saving={isSaving}
          aria-label="Modale di conferma eliminazione contenuto"
          title="Confermi di eliminare il contenuto?"
          onConfirm={removeCurrentContent}
          onAbort={onAbortRemove}
          variant="critical"
        >
          Eliminando questo contenuto, verrà definitivamente rimosso dall&apos;elenco dei contenuti dell&apos;immobile.
          {contents[currentSlideIndex] && (
            <Spacing margin={[400, 0, 0]}>
              <Image
                aria-label={`${ariaLabel} (delete picture)`}
                src={contents[currentSlideIndex].url!}
                alt={contents[currentSlideIndex].about ?? ''}
                lazyLoad
              />
            </Spacing>
          )}
        </ConfirmModal>
      </Portal>
    </div>
  );
};

export default ViewMultimediaPropertyContentsGallery;
