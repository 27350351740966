import { css } from 'styled-components';
import {
  ThemedFunctor, TypographyName, getTypography, getSpacing, SpacingName,
} from '@doveit/bricks';

export const header: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.HEADING_2)}
  ${getSpacing.margin(theme, [SpacingName.MEDIUM, 0])}
  text-align: center;
`;

export const list: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginBottom(theme, SpacingName.BIG)}
  display: flex;
  flex-direction: column;
`;

export const item: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.HEADING_4)}
  ${getSpacing.padding(theme, [SpacingName.SMALL, 0])}
  border-bottom: 1px solid ${theme.palettes.neutrals.lighter};
  display: flex;
  flex-basis: 100%;
  justify-content: space-evenly;
`;

export const name: ThemedFunctor = () => css`
  font-weight: 400;
`;

export const points: ThemedFunctor = ({ theme }) => css`
  color: ${theme.palettes.brand.primary};
`;
