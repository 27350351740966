export const item = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

export const key = {
  fontSize: 10,
  fontWeight: 'bold',
  marginRight: 5,
};

export const value = {
  fontSize: 10,
};
