import React from 'react';
import {
  ICON_HOME_CITY_OUTLINE, Card, BreakpointQueryName, Action, Dropdown, DetailItemList,
} from '@doveit/bricks';
import { formatEuro, formatSquareMeters } from '@doveit/hammer';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { Property } from '../../../providers/api/dtos';
import { furnitureTypeLabels, propertyStatusLabels, propertyTypeLabels } from '../../../labels';
import { buildFloorLabel, isApartmentAlike } from '../../utils';
import { ReferenceType } from '../../../domain/types';
import EntityReferenceBadge from '../../../components/entity-reference-badge/EntityReferenceBadge';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { WidgetCardSkeleton } from '../../../components/card/Card.skeleton';
import useProperty from '../../hooks/use-property/useProperty';

const PROPERTY_LOAD_ERROR_MESSAGE = "Non è stato possibile caricare le informazioni dell'immobile.";

export interface PropertyDetailsWidgetProps {
  propertyId: NonNullable<Property['id']>,
  onLoadError?: (error: string) => void,
}

const PropertyDetailsWidget: React.FC<PropertyDetailsWidgetProps> = (props) => {
  const { propertyId, onLoadError } = props;

  const {
    data: property,
    isLoading: isPropertyLoading,
  } = useProperty(propertyId, {
    onError: () => onLoadError?.(PROPERTY_LOAD_ERROR_MESSAGE),
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  const goToPropertyDetailPage = React.useCallback(() => {
    if (!property) return;

    goToDetailPage(`${property.id}`, ReferenceType.PROPERTY);
  }, [property]);

  if (isPropertyLoading) {
    return <WidgetCardSkeleton />;
  }

  if (!property) return null;

  return (
    <Card>
      <Card.Header
        icon={{ path: ICON_HOME_CITY_OUTLINE }}
        title="Immobile"
        primaryActions={[!isMobile && (
          <Action
            aria-label="Visualizza immobile"
            label="Visualizza"
            size="S"
            onClick={goToPropertyDetailPage}
          />
        )]}
        secondaryActions={[isMobile && (
          <Dropdown.Option
            label="Visualizza"
            onClick={goToPropertyDetailPage}
          />
        )]}
      />
      <Card.Content>
        <DetailItemList columns={2}>
          <DetailItemList.Item label="Riferimento">
            <EntityReferenceBadge
              entity={ReferenceType.PROPERTY}
              id={property.id!}
              label={property.referenceId!}
              isNewTab
            />
          </DetailItemList.Item>
          <DetailItemList.Item label="Indirizzo">
            {property.geo?.normalizedAddress || '-'}
          </DetailItemList.Item>
          {property.overriddenGeo?.normalizedAddress && (
            <DetailItemList.Item label="Indirizzo portali">
              {property.overriddenGeo?.normalizedAddress}
            </DetailItemList.Item>
          )}
          <DetailItemList.Item label="Tipologia">
            {propertyTypeLabels[property.propertyType]}
          </DetailItemList.Item>
          <DetailItemList.Item label="Superficie">
            {property.propertySize ? formatSquareMeters(property.propertySize) : undefined}
          </DetailItemList.Item>
          {isApartmentAlike(property.propertyType) && (
            <DetailItemList.Item label="Piano">
              {buildFloorLabel(property.mainFloor, property.buildingFloors)}
            </DetailItemList.Item>
          )}
          <DetailItemList.Item label="Stato immobile">
            {property.propertyStatus ? propertyStatusLabels[property.propertyStatus!] : undefined}
          </DetailItemList.Item>
          <DetailItemList.Item label="Classe ener.">
            {property.energyClass}
          </DetailItemList.Item>
          <DetailItemList.Item label="Arredamento">
            {property.furniture ? furnitureTypeLabels[property.furniture] : undefined}
          </DetailItemList.Item>
          <DetailItemList.Item label="Spese cond.">
            {property.additionalExpenses ? formatEuro(property.additionalExpenses) : undefined}
          </DetailItemList.Item>
        </DetailItemList>
      </Card.Content>
    </Card>
  );
};

export default PropertyDetailsWidget;
