import {
  ICON_EMAIL_OUTLINE, ICON_PHONE_OUTLINE, Action, ActionIcon, ICON_TRASH_CAN_OUTLINE, ICON_ACCOUNT_OUTLINE, ICON_HOME_OUTLINE, ICON_CARD_TEXT_OUTLINE, ICON_ACCOUNT_CIRCLE_OUTLINE, Card,
  DetailItemList,
} from '@doveit/bricks';
import React, { FunctionComponent } from 'react';
import { Contact } from '../../../../../providers/api/dtos/contact';
import { SearchContactFormModel } from '../../../../components/search-contact-form/SearchContactForm';

export interface ContactSelectedViewProps {
  clearContact?: VoidFunction,
  searchValues?: SearchContactFormModel,
  existingContact?: Contact,
}

export const ContactSelectedView: FunctionComponent<ContactSelectedViewProps> = ({
  clearContact,
  searchValues,
  existingContact,
}) => (
  <Card
    aria-label="contact-preview"
    data-ref="contact-preview"
  >
    <Card.Header
      title="Contatto"
      icon={{ path: ICON_ACCOUNT_CIRCLE_OUTLINE }}
      primaryActions={[existingContact?.id && (
        <Action
          label="Visualizza"
          size="S"
          aria-label="Visualizza la pagina del contatto"
          href={`/contacts/${existingContact.id}`}
          target="_blank"
        />
      ), clearContact && (
        <ActionIcon
          label="Rimuovi"
          icon={{ path: ICON_TRASH_CAN_OUTLINE }}
          size="S"
          aria-label="Annulla la selezione del contatto"
          onClick={clearContact}
        />
      )]}
    />
    <Card.Content>
      <DetailItemList>
        {(searchValues?.name || existingContact?.name) && (
          <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
            <span aria-label="Nome del contatto">
              {searchValues?.name || existingContact?.name}
            </span>
          </DetailItemList.Item>
        )}
        {(searchValues?.phoneNumber || existingContact?.phoneNumber) && (
          <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
            <span aria-label="Numero di telefono del contatto">
              {searchValues?.phoneNumber || existingContact?.phoneNumber}
            </span>
          </DetailItemList.Item>
        )}
        {(searchValues?.email || existingContact?.email) && (
          <DetailItemList.Item icon={ICON_EMAIL_OUTLINE}>
            <span aria-label="Email del contatto">
              {searchValues?.email || existingContact?.email}
            </span>
          </DetailItemList.Item>
        )}
        {existingContact?.fiscalCode && (
          <DetailItemList.Item icon={ICON_CARD_TEXT_OUTLINE}>
            {existingContact?.fiscalCode}
          </DetailItemList.Item>
        )}
        {existingContact?.residence?.normalizedAddress && (
          <DetailItemList.Item icon={ICON_HOME_OUTLINE}>
            {existingContact?.residence?.normalizedAddress}
          </DetailItemList.Item>
        )}
      </DetailItemList>
    </Card.Content>
  </Card>
);

export default ContactSelectedView;
