/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Badge,
  FontWeight,
  HStack,
  ICON_CHECK,
  ICON_DATABASE_ARROW_RIGHT_OUTLINE,
  Icon,
  Message, Popover, PopoverProps, Spacing,
  Stack,
  Text,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import { Lead } from '../../../providers/api/dtos';
import useLead from '../../hooks/use-lead/useLead';
import PopoverPreviewSkeleton from '../../../components/popover-preview/PopoverPreview.skeleton';
import {
  LeadPropertyFeature, LeadSource, LeadStatus, ReferenceType,
} from '../../../domain/types';
import useSearchProspects from '../../../prospect/hooks/use-search-prospects/useSearchProspects';
import { goToDetailPage } from '../../../utils/navigate/utils';
import PopoverPreview from '../../../components/popover-preview/PopoverPreview';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import * as styles from './ViewLeadPopoverPreview.style';
import { NO_VALUE_SYMBOL, formatFloor } from '../../../property/utils';
import { leadFeatureLabels, propertyTypeLabels } from '../../../labels';
import { leadSourceLabels } from '../../../labels/leadSourceLabels';

export interface ViewLeadPopoverPreviewProps {
  leadId: NonNullable<Lead['id']>,
  trigger: PopoverProps['trigger'],
}

const ViewLeadPopoverPreview: React.FC<ViewLeadPopoverPreviewProps> = ({
  leadId,
  trigger,
}) => {
  const { data: lead, isLoading: isLeadLoading, error: leadError } = useLead(leadId);

  const hasScheduledAppointment = lead?.status === LeadStatus.APPOINTMENT_SCHEDULED;
  const { data: paginatedProspects, isLoading: areProspectsLoading } = useSearchProspects(hasScheduledAppointment
    ? { leadId: [lead.id!] }
    : undefined);

  const prospectId = paginatedProspects?.content[0]?.id;

  const goToProspectPage = React.useCallback(() => {
    if (!prospectId) return;

    goToDetailPage(prospectId, ReferenceType.PROSPECT);
  }, [prospectId]);

  const features = React.useMemo(() => ([
    {
      key: 'propertySize',
      label: 'Superficie',
      value: lead?.propertySize ? formatSquareMeters(lead.propertySize) : NO_VALUE_SYMBOL,
    },
    {
      key: 'propertyFloor',
      label: 'Piano',
      value: lead?.propertyFloor ? formatFloor(lead.propertyFloor) : NO_VALUE_SYMBOL,
    },
  ]), [lead?.propertyFloor, lead?.propertySize]);

  return (
    <Popover
      trigger={trigger}
      hasArrow
    >
      <Spacing padding={25} css={styles.wrapper}>
        {(isLeadLoading || areProspectsLoading) && <PopoverPreviewSkeleton aria-label="Caricamento informazioni lead in corso" />}

        {leadError && (
          <Spacing padding={150}>
            <Message
              type="critical"
              message="Non è stato possibile caricare le informazioni della lead."
            />
          </Spacing>
        )}

        {lead && (
          <PopoverPreview
            aria-label="Informazioni lead"
            title={lead.propertyAddress}
            details={lead.locality}
            avatar={<PropertyAvatar size="L" />}
            action={prospectId ? {
              label: 'Visualizza valutazione',
              'aria-label': 'Vai alla pagina della valutazione',
              onClick: goToProspectPage,
            } : undefined}
          >
            <Stack gap={150}>
              <HStack gap={150}>
                <Text.Body fontWeight={FontWeight.MEDIUM}>
                  {propertyTypeLabels[lead.propertyType]}
                </Text.Body>

                {features.length > 0 && (
                  <div css={styles.features}>
                    {features.map(({ key, label, value }) => (
                      <div
                        key={key}
                        css={styles.feature}
                      >
                        <Text.BodySmall
                          fontWeight={FontWeight.MEDIUM}
                          lineHeight="1"
                          as="div"
                        >
                          {value}
                        </Text.BodySmall>
                        <Text.Mini
                          transform="uppercase"
                          as="div"
                        >
                          {label}
                        </Text.Mini>
                      </div>
                    ))}
                  </div>
                )}
              </HStack>

              <HStack>
                {Object.keys(lead.propertyFeatures)
                  .filter((feat) => feat)
                  .map((feature) => (
                    <Badge
                      key={feature}
                      icon={ICON_CHECK}
                      label={leadFeatureLabels[feature as LeadPropertyFeature]}
                      size="XS"
                    />
                  ))}
              </HStack>

              {lead.source && (
                <HStack>
                  <Icon path={ICON_DATABASE_ARROW_RIGHT_OUTLINE} />
                  <Text.Body>{leadSourceLabels[lead.source as LeadSource]}</Text.Body>
                </HStack>
              )}
            </Stack>
          </PopoverPreview>
        )}
      </Spacing>
    </Popover>
  );
};

export default ViewLeadPopoverPreview;
