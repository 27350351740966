/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Action, Card, CardSkeleton, DetailItemList, Dropdown, HStack, ICON_ALERT_CIRCLE_OUTLINE, Message,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { Prospect } from '../../../providers/api/dtos';
import useProspect from '../../hooks/use-prospect/useProspect';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import EditProspectAction from '../edit-prospect-action/EditProspectAction';
import UpdateProspectApprovalStatus from '../update-prospect-approval-status/UpdateProspectApprovalStatus';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import useLead from '../../../lead/hooks/use-lead/useLead';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { needsSupervisorApproval } from '../../utils/needsSupervisorApproval';
import LocationSellabilityWidget from '../../../containers/location-sellability-widget/LocationSellabilityWidget';

export interface ProspectWidgetProps extends React.AriaAttributes {
  prospectId: NonNullable<Prospect['id']>,
  canEdit?: boolean,
  asNestedComponent?: boolean,
  onUpdate?: (prospect: Prospect) => void,
}

const ProspectWidget: React.FC<ProspectWidgetProps> = ({
  prospectId,
  canEdit = false,
  asNestedComponent: asContent = false,
  onUpdate,
  ...rest
}) => {
  const isMobile = useIsDevice('mobile');

  const {
    data: prospect,
    isLoading: isProspectLoading,
    error: prospectError,
    mutate: mutateProspect,
  } = useProspect(prospectId);

  const { data: lead } = useLead(prospect?.leadId);
  const { data: prospectAgent } = useAgent(prospect?.agentId);

  const agentEvaluation = React.useMemo(() => (prospect?.agentEvaluation ? formatEuro(prospect.agentEvaluation) : NO_VALUE_SYMBOL), [prospect?.agentEvaluation]);

  const leadGeoPoint = React.useMemo(
    () => (lead?.isGeoComplete ? {
      latitude: lead.latitude!.toString(),
      longitude: lead.longitude!.toString(),
    } : undefined),
    [lead?.isGeoComplete, lead?.latitude, lead?.longitude],
  );

  const handleUpdate = React.useCallback((updatedProspect: Prospect) => {
    mutateProspect();
    onUpdate?.(updatedProspect);
  }, [mutateProspect, onUpdate]);

  if (prospectError) {
    return (
      <Card
        aria-label="Errore caricamento valutazione"
        asContent={asContent}
      >
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Valutazione"
          color="critical"
        />
        <Card.Content>
          <Message message="Non è stato possibile caricare le informazioni della valutazione." />
        </Card.Content>
      </Card>
    );
  }

  if (!prospect || isProspectLoading) {
    return (
      <CardSkeleton
        aria-label="Valutazione in caricamento"
        asContent={asContent}
      >
        {(!isMobile && !asContent) && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  return (
    <>
      <Card
        aria-label="Informazioni valutazione"
        asContent={asContent}
        {...rest}
      >
        <Card.Header
          title="Valutazione"
          primaryActions={(!isMobile && canEdit) ? [
            <EditProspectAction
              prospect={prospect}
              onSuccess={handleUpdate}
            >
              {({ onClick, disabled }) => (
                <Action
                  onClick={onClick}
                  label="Modifica"
                  aria-label="Modifica valutazione"
                  size="S"
                  disabled={disabled}
                />
              )}
            </EditProspectAction>,
          ] : undefined}
          secondaryActions={(isMobile && canEdit) ? [
            <EditProspectAction
              prospect={prospect}
              onSuccess={handleUpdate}
            >
              {({ onClick, disabled }) => (
                <Dropdown.Option
                  onClick={onClick}
                  label="Modifica"
                  aria-label="Modifica valutazione"
                  disabled={disabled}
                />
              )}
            </EditProspectAction>,
          ] : undefined}
        />
        <Card.Content>
          <DetailItemList>
            <DetailItemList.Item
              label="Valutazione"
              aria-label="Valutazione agente"
            >
              {prospectAgent && needsSupervisorApproval(prospect, prospectAgent) ? (
                <HStack>
                  {agentEvaluation}
                  <UpdateProspectApprovalStatus
                    prospect={prospect}
                    onSuccess={handleUpdate}
                    size="XS"
                  />
                </HStack>
              ) : agentEvaluation}
            </DetailItemList.Item>
            <DetailItemList.Item
              label="Richiesta"
              aria-label="Richiesta proprietario"
            >
              {prospect.ownerEvaluation !== undefined && formatEuro(prospect.ownerEvaluation)}
            </DetailItemList.Item>
          </DetailItemList>
        </Card.Content>
      </Card>
      {lead && leadGeoPoint && (
        <LocationSellabilityWidget
          latitude={leadGeoPoint.latitude!.toString()}
          longitude={leadGeoPoint.longitude!.toString()}
          propertySize={lead.propertySize}
          asNestedComponent
        />
      )}
    </>
  );
};

export default ProspectWidget;
