import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const grid = () => css`
  align-items: flex-start !important;
`;

export const table: ThemedFunctor = ({ theme }) => css`
  border: 1px solid ${theme.color.border.neutral.default.high};
`;

export const prestigeTooltip: ThemedFunctor = () => css`
  min-width: 15rem;
`;

export const packageBox: ThemedFunctor = ({ theme }) => css`
  width: 100%;
  margin-top: ${theme.remSize[300]};
`;
