import { API_OPT_INS_PATH, getClient } from '../api';
import { OptIn } from '../dtos';

export async function deleteOptIn(id: number): Promise<{}> {
  const client = await getClient();
  const { data } = await client.delete(`${API_OPT_INS_PATH}/${id}`);
  return data;
}
export async function createOptIn(optIn: OptIn): Promise<OptIn> {
  const client = await getClient();
  const { data } = await client.post<OptIn>(API_OPT_INS_PATH, optIn);
  return data;
}
