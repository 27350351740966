/* eslint-disable react/no-unknown-property */
import {
  Badge, BadgeProps, HStack, Select,
} from '@doveit/bricks';
import React from 'react';
import useIsDevice from '../../hooks/use-is-device/useIsDevice';

export type ContextualNavigationItem = Pick<BadgeProps, 'icon' | 'onClick' | 'label' | 'aria-label'> & {
  id: string;
  active?: boolean;
};

export interface ContextualNavigationProps extends React.AriaAttributes {
  items: ContextualNavigationItem[];
}

const ContextualNavigation: React.FC<ContextualNavigationProps> = ({
  items,
  'aria-label': ariaLabel = 'Navigazione contestuale',
  ...rest
}) => {
  const isDesktop = useIsDevice('desktop');

  return (
    <div
      {...rest}
      aria-label={ariaLabel}
    >
      {isDesktop && (
        <HStack gap={100}>
          {items.map((item) => (
            <Badge
              aria-label={`Badge ${item.label}`}
              key={item.id}
              label={item.label}
              color={item.active ? 'primary' : 'neutral'}
              size="S"
              onClick={item.active ? undefined : () => item.onClick?.()}
            />
          ))}
        </HStack>
      )}
      {!isDesktop && (
        <Select
          aria-label={`${ariaLabel} (select)`}
          value={items.find((item) => item.active)?.id}
          options={items.map((item) => ({ label: item.label.toString(), value: item.id }))}
          onChange={({ target: { value } }) => {
            items.find((item) => item.id === value)?.onClick?.();
          }}
        />
      )}
    </div>
  );
};

export default ContextualNavigation;
