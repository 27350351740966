import {
  Form, FormHandlers, FormProps,
} from '@doveit/bricks';
import React, { MutableRefObject } from 'react';
import * as styles from './EditAgentBioForm.style';

export interface EditAgentBioFormModel {
  professionalBackground: string,
  professionalReasons: string,
  hobbies: string,
  bio: string,
}

export interface EditAgentBioFormProps {
  initialValues?: Partial<EditAgentBioFormModel>,
  innerRef?: MutableRefObject<FormHandlers>
  onSubmit: FormProps<EditAgentBioFormModel>['onSubmit']
}

const EDIT_AGENT_BIO_FORM_DEFAULT_VALUES: EditAgentBioFormModel = {
  professionalBackground: '',
  professionalReasons: '',
  hobbies: '',
  bio: '',
};

const EditAgentBioForm: React.FC<EditAgentBioFormProps> = ({
  initialValues,
  onSubmit,
  innerRef,
}) => {
  const mergedInitialValues: EditAgentBioFormModel = React.useMemo(() => ({
    ...EDIT_AGENT_BIO_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      css={styles.form}
      innerRef={innerRef}
    >
      <Form.Item>
        <Form.TextArea
          label="Background professionale"
          aria-label="Campo per inserire il background professionale dell'agente"
          name="professionalBackground"
          rows={2}
        />
      </Form.Item>

      <Form.Item>
        <Form.TextArea
          label="Motivazioni professionali"
          aria-label="Campo per inserire le motivazioni professionali dell'agente"
          name="professionalReasons"
          rows={2}
        />
      </Form.Item>

      <Form.Item>
        <Form.TextArea
          label="Hobby e passioni"
          aria-label="Campo per inserire gli hobby e le passioni dell'agente"
          name="hobbies"
          rows={3}
        />
      </Form.Item>

      <Form.Item>
        <Form.TextArea
          label="Biografia"
          aria-label="Campo per inserire la biografia dell'agente"
          name="bio"
          rows={6}
        />
      </Form.Item>
    </Form>
  );
};

export default EditAgentBioForm;
