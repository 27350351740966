import useSWR, { SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import hash from 'object-hash';
import { GeoLocationResult, Point } from '../../providers/public-api/dtos';
import { geolocate } from '../../providers/public-api/location/locationProvider';

export const GEOLOCATION_RID = '/location/geolocate';

export default function useGeolocate(point?: Point): SWRResponse<GeoLocationResult, AxiosError> {
  return useSWR(point ? [GEOLOCATION_RID, hash(point)] : null, () => geolocate(point!));
}
