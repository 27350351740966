import { Department } from '../domain/types';

export type JobPositionDepartmentMap = {
  [key in Department]: string;
};

export const jobPositionDepartmentLabels: JobPositionDepartmentMap = {
  CONTACT: 'Contact',
  CONTENT: 'Content',
  FINANCE: 'Finance',
  HR: 'HR',
  MARKETING: 'Marketing',
  PRODUCT: 'Product',
  OPERATIONS: 'Operations',
  SALES: 'Sales',
  TECH: 'Tech',
};
