import React, { FunctionComponent, useMemo } from 'react';
import {
  Route, Routes, useLocation, useNavigate, useResolvedPath,
} from 'react-router-dom';
import { Action, HStack, Select } from '@doveit/bricks';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { AGENT_SEARCH_SECTIONS } from './routeDefinitions';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';

const AgentsSearchPage: FunctionComponent = () => {
  const isMobile = useIsDevice('mobile');
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname: basePath } = useResolvedPath('');

  const tabItems = useMemo(() => AGENT_SEARCH_SECTIONS.map((section) => {
    const fullPath = `${basePath}${section.path}`;

    return ({
      ...section,
      active: location.pathname === fullPath,
      onClick: () => navigate(fullPath),
    });
  }), [basePath, location.pathname, navigate]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Bar>
        <HStack gap={100}>
          {!isMobile && tabItems.map((item) => (
            <Action
              key={item.id}
              label={item.label}
              onClick={item.onClick}
              size="M"
              color={item.active ? 'primary' : 'neutral'}
            />
          ))}
          {isMobile && (
            <Select
              aria-label="Tab di ricerca agenti (select)"
              value={tabItems.find((item) => item.active)?.id}
              options={tabItems.map((item) => ({ label: item.label.toString(), value: item.id }))}
              onChange={({ target: { value } }) => {
                tabItems.find((item) => item.id === value)?.onClick?.();
              }}
            />
          )}
        </HStack>
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent>
          <Routes>
            {AGENT_SEARCH_SECTIONS.map(({
              id, path, component: Component, props,
            }) => (
              <Route
                key={id}
                path={path}
                element={<Component {...props} />}
              />
            ))}
          </Routes>
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default AgentsSearchPage;
