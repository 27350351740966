import React from 'react';
import { useAgent, useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import { Agent, Prospect } from '../../../providers/api/dtos';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { ProspectApprovalStatus } from '../../../domain/types';
import { needsSupervisorApproval } from '../../utils/needsSupervisorApproval';
import { removeNullAndUndefinedValues } from '../../../utils/array';

export type ComputedProspectApprovalStatus = ProspectApprovalStatus | 'TO_BE_REQUESTED';

export type UseComputedProspectApprovalStatusData = {
  status: ComputedProspectApprovalStatus,
  canBeUpdated: boolean,
  canBeUpdatedTo: (status: ComputedProspectApprovalStatus) => boolean,
};

export type UseComputedProspectApprovalStatusResult = {
  data: UseComputedProspectApprovalStatusData,
};

const agentAllowedStatusUpdates = (
  prospect: Prospect,
  agent: Agent,
): Record<ComputedProspectApprovalStatus, ComputedProspectApprovalStatus[]> => ({
  TO_BE_REQUESTED: removeNullAndUndefinedValues([
    needsSupervisorApproval(prospect, agent) ? ProspectApprovalStatus.WAITING_FOR_SUPERVISOR : undefined,
  ]),
  [ProspectApprovalStatus.WAITING_FOR_SUPERVISOR]: ['TO_BE_REQUESTED'],
  [ProspectApprovalStatus.WAITING_FOR_AGENT]: [ProspectApprovalStatus.APPROVED, ProspectApprovalStatus.WAITING_FOR_SUPERVISOR],
  [ProspectApprovalStatus.APPROVED]: [],
});

const supervisorOrAdminAllowedStatusUpdates: Record<ComputedProspectApprovalStatus, ComputedProspectApprovalStatus[]> = {
  TO_BE_REQUESTED: [],
  [ProspectApprovalStatus.WAITING_FOR_AGENT]: [],
  [ProspectApprovalStatus.APPROVED]: [],
  [ProspectApprovalStatus.WAITING_FOR_SUPERVISOR]: [ProspectApprovalStatus.APPROVED, ProspectApprovalStatus.WAITING_FOR_AGENT],
};

export const useComputedProspectApprovalStatus = (prospect: Prospect): UseComputedProspectApprovalStatusResult => {
  const { userIsAdmin } = useRBAC();
  const currentAgent = useCurrentAgent();

  const { data: agent } = useAgent(prospect.agentId);

  const computedStatus: ComputedProspectApprovalStatus = React.useMemo(
    () => prospect.approval?.status || 'TO_BE_REQUESTED',
    [prospect.approval],
  );

  const canBeUpdatedTo = React.useMemo(() => {
    if (agent && currentAgent?.id === agent.id) {
      return agentAllowedStatusUpdates(prospect, agent)[computedStatus];
    }

    if (userIsAdmin || currentAgent?.id === agent?.supervisorId || (!currentAgent?.needsSupervisor && currentAgent?.hasLicense && currentAgent.canManage?.some((managedAgent) => managedAgent.id === agent?.id))) {
      return supervisorOrAdminAllowedStatusUpdates[computedStatus];
    }

    return [];
  }, [agent, computedStatus, currentAgent?.canManage, currentAgent?.hasLicense, currentAgent?.id, currentAgent?.needsSupervisor, prospect, userIsAdmin]);

  const canBeUpdatedToFn = React.useCallback(
    (status: ComputedProspectApprovalStatus) => canBeUpdatedTo.includes(status),
    [canBeUpdatedTo],
  );

  const data: UseComputedProspectApprovalStatusData = React.useMemo(() => ({
    status: computedStatus,
    canBeUpdated: canBeUpdatedTo.length > 0,
    canBeUpdatedTo: canBeUpdatedToFn,
  }), [canBeUpdatedTo.length, canBeUpdatedToFn, computedStatus]);

  return { data };
};
