import {
  ActionIcon, DetailItemList, HStack, ICON_ACCOUNT_OUTLINE, ICON_CALENDAR_CLOCK_OUTLINE, ICON_CALENDAR_PLUS_OUTLINE, ICON_CASH_MULTIPLE, ICON_CLOCK_OUTLINE, ICON_EYE_OUTLINE, ICON_PHONE_OUTLINE, ICON_WHATSAPP, Message, Spacing, Typography,
} from '@doveit/bricks';
import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { formatDate, formatEuro } from '@doveit/hammer';
import {
  AppointmentStatus, EventType, IntentStatus, ReferenceType,
} from '../../../domain/types';
import { Intent } from '../../../providers/api/dtos/intent';
import { Appointment } from '../../../providers/api/dtos/appointment';
import useContact from '../../../contact/hooks/use-contact/useContact';
import useOffersByIntentId from '../../../offer/hooks/use-offers-by-intent-id/useOffersByIntentId';
import useContactAppointments from '../../../appointment/hooks/use-contact-appointments/useContactAppointments';
import useIntentEvents from '../../hooks/use-intent-events/useIntentEvents';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import buildWhatsAppIntentMessage from '../../utils/buildWhatsAppIntentMessage';
import { getAppointmentsWithOutcome } from '../../../appointment/utils/getAppointmentsWithOutcome';
import { getSortedAppointments } from '../../../appointment/utils/getSortedAppointments';
import Card from '../../../components/card/Card';
import AppointmentStatusBadge from '../../../appointment/components/appointment-status-badge/AppointmentStatusBadge';
import SetAppointmentOutcome from '../../../appointment/containers/set-appointment-outcome/SetAppointmentOutcome';
import { ViewIntentCardSkeleton } from './ViewIntentCard.skeleton';
import useReminders from '../../../reminders/hooks/use-reminders/useReminders';
import ShowAgentName from '../../../agent/containers/show-agent-name/ShowAgentName';
import { EntityType } from '../../../domain/types/entityType';
import {
  hasPendingOffers, hasRefusedOffers, hasWithdrawnOffers,
} from '../../../offer/utils';
import { UserRole } from '../../../types';
import UserActions from '../../../components/user-actions/UserActions';
import { isArchived } from '../../utils/isArchived';
import IntentStatusBadge from '../../components/intent-status-badge/IntentStatusBadge';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import EntityReferenceBadge from '../../../components/entity-reference-badge/EntityReferenceBadge';
import { NoteRole } from '../../../providers/api/dtos';
import usePropertyPreview from '../../../property/hooks/use-property-preview/usePropertyPreview';

export interface ViewIntentCardProps {
  intent: Intent,
  showPropertyDetails?: boolean,
  showAddAppointmentAction?: boolean,
  showLastNoteSnippet?: boolean,
  disableEditIntentAction?: boolean,
  onViewActionClick?: VoidFunction,
  onAppointmentOutcome?: (intent: Intent, appointment: Appointment) => void,
  onAddAppointment?: (intent: Intent) => void,
}

export const LOAD_RELATED_DATA_ERROR = 'Non è stato possibile caricare l\'interesse d\'acquisto';
export const CREATE_WHATSAPP_EVENT_ERROR_MESSAGE = 'Non è stato possibile aprire WhatsApp';

const ViewIntentCard: FunctionComponent<ViewIntentCardProps> = ({
  intent,
  showPropertyDetails,
  showAddAppointmentAction,
  showLastNoteSnippet,
  disableEditIntentAction,
  onViewActionClick,
  onAppointmentOutcome,
  onAddAppointment,
}) => {
  const { data: contact, error: contactError } = useContact(intent.contactId);
  const { data: property, error: propertyError } = usePropertyPreview(intent.propertyId);
  const { data: agent, error: agentError } = useAgentByPropertyId(intent.propertyId);
  const { data: offers, error: offersError } = useOffersByIntentId(intent.id);
  const { data: appointments, error: appointmentsError } = useContactAppointments(intent.contactId, {
    referenceId: intent.id,
    referenceType: ReferenceType.INTENT,
  });
  const { data: events } = useIntentEvents(intent.id);
  const { data: reminders } = useReminders({
    referenceId: intent.id,
    referenceType: ReferenceType.INTENT,
  });

  const { userIsAgent } = useRBAC();

  const isArchivedIntent = useMemo(() => isArchived(intent), [intent]);

  const whatsAppEvents = useMemo(() => (events || []).filter((e) => e.type === EventType.WHATSAPP), [events]);

  const appointmentsWithOutcome = useMemo(
    () => appointments?.content && getSortedAppointments(getAppointmentsWithOutcome(appointments.content)),
    [appointments],
  );

  const whatsAppMessage = useMemo(
    () => agent && property && events && appointments && reminders && buildWhatsAppIntentMessage(
      agent,
      property,
      events.length,
      appointments.totalElements,
      reminders.totalElements,
    ),
    [agent, appointments, events, property, reminders],
  );

  const todoAppointment = useMemo(
    () => appointments?.content.find((appointment) => appointment.status === AppointmentStatus.TODO),
    [appointments],
  );

  const canAddAppointment = useMemo(
    () => onAddAppointment && intent.status === IntentStatus.IN_PROGRESS && !todoAppointment,
    [intent.status, onAddAppointment, todoAppointment],
  );

  const canShowLastNoteSnippet = useMemo(() => {
    if (showLastNoteSnippet) {
      const { notes } = intent;

      return userIsAgent
        && notes
        && notes.length > 0
        && ([UserRole.AGENT, UserRole.CALL_CENTER] as NoteRole[]).includes(notes[0].role);
    }

    return false;
  }, [userIsAgent, intent, showLastNoteSnippet]);

  const onInternalAppointmentOutcome = useCallback((appointment: Appointment) => {
    if (onAppointmentOutcome) {
      onAppointmentOutcome(intent, appointment);
    }
  }, [onAppointmentOutcome, intent]);

  const onAddAppointmentClick = useCallback(() => {
    if (onAddAppointment) {
      onAddAppointment(intent);
    }
  }, [intent, onAddAppointment]);

  if (
    (!contact && contactError) || (!property && propertyError)
    || (!agent && agentError) || (!offers && offersError)
    || (!appointments && appointmentsError)
  ) {
    return (
      <Message
        type="critical"
        message={LOAD_RELATED_DATA_ERROR}
      />
    );
  }

  return ((contact && property && offers && appointments) ? (
    <Card>
      <Card.Inline>
        {/* contact section */}

        <Card.Box>
          <DetailItemList>
            <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
              {contact.name}
            </DetailItemList.Item>
            <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
              {contact.phoneNumber}
            </DetailItemList.Item>
            {showPropertyDetails && (
              <DetailItemList.Item
                data-ref="property-details"
              >
                <EntityReferenceBadge
                  entity={ReferenceType.PROPERTY}
                  id={property.id!}
                  label={property.referenceId!}
                />
                <span>
                  {property.geo?.normalizedAddress || '-'}
                </span>
              </DetailItemList.Item>
            )}

          </DetailItemList>
          <Spacing margin={[100, 0, 0]}>
            <UserActions
              user={contact}
              whatsAppMessage={whatsAppMessage}
            />
          </Spacing>
        </Card.Box>

        {/* dynamic section, shows events, appointments status or offers status based on section and data */}

        {!isArchivedIntent && (whatsAppEvents.length > 0 || (reminders && reminders?.totalElements > 0)) && appointments.content.length === 0 && offers.length === 0 && (
          <>
            <Card.ColumnDivider />
            <Card.Box data-ref="recall-box">
              <DetailItemList>
                <DetailItemList.Item
                  data-ref="intent-agent-name"
                  icon={ICON_ACCOUNT_OUTLINE}
                >
                  <ShowAgentName
                    entityId={intent.propertyId}
                    entityType={EntityType.PROPERTY}
                  />
                </DetailItemList.Item>
                {whatsAppEvents.length > 0 && (
                  <DetailItemList.Item
                    icon={ICON_WHATSAPP}
                    title="Data ultimo messaggio WhatsApp inviato"
                  >
                    {formatDate(new Date(whatsAppEvents[0].createdAt!), {
                      time: true,
                    })}
                  </DetailItemList.Item>
                )}
                {reminders && reminders.totalElements > 0 && (
                  <DetailItemList.Item
                    icon={ICON_CALENDAR_CLOCK_OUTLINE}
                    title="Data ultimo promemoria"
                  >
                    {formatDate(new Date(reminders.content[0].startDate!), {
                      time: true,
                    })}
                  </DetailItemList.Item>
                )}
                {canShowLastNoteSnippet && (
                  <DetailItemList.Item title="Ultima nota inserita">
                    {truncateTextWithSuffix(intent.notes![0].text, 100)}
                  </DetailItemList.Item>
                )}
              </DetailItemList>
            </Card.Box>
          </>
        )}
        {(isArchivedIntent || (appointments.content.length === 0 && whatsAppEvents.length === 0 && reminders?.content.length === 0 && offers.length === 0)) && (
          <>
            <Card.ColumnDivider />
            <Card.Box data-ref="intent-date">
              <DetailItemList>
                <DetailItemList.Item
                  data-ref="intent-agent-name"
                  icon={ICON_ACCOUNT_OUTLINE}
                >
                  <ShowAgentName
                    entityId={intent.propertyId}
                    entityType={EntityType.PROPERTY}
                  />
                </DetailItemList.Item>
                <DetailItemList.Item
                  icon={ICON_CLOCK_OUTLINE}
                  title="Data di creazione interesse"
                >
                  {formatDate(new Date(intent.createdAt!), {
                    time: true,
                  })}
                </DetailItemList.Item>
                {isArchivedIntent && (
                  <DetailItemList.Item>
                    <IntentStatusBadge
                      status={intent.status}
                      size="XS"
                    />
                  </DetailItemList.Item>
                )}
              </DetailItemList>
            </Card.Box>
          </>
        )}
        {!isArchivedIntent && !todoAppointment && appointmentsWithOutcome!.length > 0 && offers.length === 0 && (
          <>
            <Card.ColumnDivider />
            <Card.Box
              data-ref="appointment-data"
            >
              <DetailItemList>
                <DetailItemList.Item
                  data-ref="intent-agent-name"
                  icon={ICON_ACCOUNT_OUTLINE}
                >
                  <ShowAgentName
                    entityId={intent.propertyId}
                    entityType={EntityType.PROPERTY}
                  />
                </DetailItemList.Item>
                <DetailItemList.Item
                  icon={ICON_CALENDAR_CLOCK_OUTLINE}
                  title="Data appuntamento"
                >
                  {formatDate(new Date(appointmentsWithOutcome![0].startDate), {
                    time: true,
                  })}
                </DetailItemList.Item>
                <DetailItemList.Item>
                  <AppointmentStatusBadge
                    status={appointmentsWithOutcome![0].status}
                    size="XS"
                  />
                </DetailItemList.Item>
              </DetailItemList>
            </Card.Box>
          </>
        )}
        {!isArchivedIntent && hasPendingOffers(offers) && (
          <>
            <Card.ColumnDivider />
            <Card.Box
              data-ref="offer-data"
            >
              <DetailItemList>
                <DetailItemList.Item
                  data-ref="intent-agent-name"
                  icon={ICON_ACCOUNT_OUTLINE}
                >
                  <ShowAgentName
                    entityId={intent.propertyId}
                    entityType={EntityType.PROPERTY}
                  />
                </DetailItemList.Item>
                <DetailItemList.Item icon={ICON_CASH_MULTIPLE}>
                  <strong>{formatEuro(offers[0].value)}</strong>
                  {offers[0].mortgage && (
                    <Typography.BODY_SMALL tagName="span">
                      (Mutuo{offers[0].mortgage!.percentage && ` ${offers[0].mortgage!.percentage}%`})
                    </Typography.BODY_SMALL>
                  )}
                </DetailItemList.Item>
                <DetailItemList.Item
                  icon={ICON_CLOCK_OUTLINE}
                  title="Data ultima offerta"
                >
                  {formatDate(new Date(offers[0].offeredAt))}
                </DetailItemList.Item>
              </DetailItemList>
            </Card.Box>
          </>
        )}
        {!isArchivedIntent && todoAppointment && !hasPendingOffers(offers) && (
          <>
            <Card.ColumnDivider />
            <Card.Box>
              <DetailItemList>
                <DetailItemList.Item
                  data-ref="intent-agent-name"
                  icon={ICON_ACCOUNT_OUTLINE}
                >
                  <ShowAgentName
                    entityId={intent.propertyId}
                    entityType={EntityType.PROPERTY}
                  />
                </DetailItemList.Item>
                <DetailItemList.Item
                  icon={ICON_CALENDAR_CLOCK_OUTLINE}
                  title="Data appuntamento da fare"
                >
                  {formatDate(new Date(todoAppointment.startDate), {
                    time: true,
                  })}
                </DetailItemList.Item>
                <DetailItemList.Item>
                  <HStack>
                    <SetAppointmentOutcome
                      appointment={todoAppointment}
                      outcome={AppointmentStatus.DONE}
                      disableAction={disableEditIntentAction}
                      onSuccess={onInternalAppointmentOutcome}
                    />
                    <SetAppointmentOutcome
                      appointment={todoAppointment}
                      outcome={AppointmentStatus.CANCELLED}
                      disableAction={disableEditIntentAction}
                      onSuccess={onInternalAppointmentOutcome}
                    />
                  </HStack>
                </DetailItemList.Item>
              </DetailItemList>
            </Card.Box>
          </>
        )}
        {!isArchivedIntent && !todoAppointment && (hasRefusedOffers(offers) || hasWithdrawnOffers(offers)) && !hasPendingOffers(offers) && (
          <>
            <Card.ColumnDivider />
            <Card.Box
              data-ref="intent-date"
            >
              <DetailItemList>
                <DetailItemList.Item
                  data-ref="intent-agent-name"
                  icon={ICON_ACCOUNT_OUTLINE}
                >
                  <ShowAgentName
                    entityId={intent.propertyId}
                    entityType={EntityType.PROPERTY}
                  />
                </DetailItemList.Item>
              </DetailItemList>
            </Card.Box>
          </>
        )}

        {/* quick actions section */}

        <Card.ColumnDivider />
        <Card.Box fit>
          <HStack>
            {onViewActionClick && (
              <ActionIcon
                dataRef="view-action"
                icon={{ path: ICON_EYE_OUTLINE }}
                label="Visualizza"
                onClick={onViewActionClick}
              />
            )}
            {showAddAppointmentAction && canAddAppointment && (
              <ActionIcon
                dataRef="add-appointment-action"
                icon={{ path: ICON_CALENDAR_PLUS_OUTLINE }}
                label="Aggiungi appuntamento"
                onClick={onAddAppointmentClick}
              />
            )}
          </HStack>
        </Card.Box>
      </Card.Inline>
    </Card>
  ) : <ViewIntentCardSkeleton />);
};

export default ViewIntentCard;
