import { AxiosError } from 'axios';
import {
  getClient, API_CONTACTS_V2,
} from '../api';
import {
  Contact, Appointment, Intent, SearchCriteria, Rumor, Lead, OptIn, AdditionalService, PropertyContact, Prospect,
} from '../dtos';
import {
  PaginatedResult, serializePagination, PaginationParams, SortOrder, SortParams, serializeSortParams,
} from '../../pagination';
import { serializeParams } from '../utils/serializer';
import { ProspectStatus, ReferenceType, RumorStatus } from '../../../domain/types';
import { DuplicateContactError } from './errors/DuplicateContactError';
import { IntentsProviderFilters } from '../intent/intentProvider';
import { LeadsProviderFilters } from '../lead/leadProvider';
import { Concession } from '../dtos/concession';

export interface CalendarEventsFilters {
  referenceId?: string | number,
  referenceType?: ReferenceType,
}

export interface MatchContactFilters {
  phoneNumber?: string,
  email?: string,
}

export interface ContactProspectsFilters {
  excludeStatus?: ProspectStatus | ProspectStatus[]
}

export async function getContact(contactId: string): Promise<Contact> {
  const client = await getClient();
  const { data } = await client.get<Contact>(`${API_CONTACTS_V2}/${contactId}`);
  return data;
}

export async function matchContact(filters: MatchContactFilters): Promise<Contact[]> {
  const client = await getClient();

  const { data } = await client.get<Contact[]>(`${API_CONTACTS_V2}/match`, {
    params: serializeParams(filters),
  });

  return data;
}

export async function searchContacts(query: string, pagination: PaginationParams = {}): Promise<PaginatedResult<Contact>> {
  const client = await getClient();

  const { data } = await client.get<PaginatedResult<Contact>>(`${API_CONTACTS_V2}/search`, {
    params: serializeParams({
      q: query,
      ...pagination,
    }),
  });

  return data;
}

export async function createContact(contact: Contact): Promise<Contact> {
  const client = await getClient();
  try {
    const { data } = await client.post<Contact>(API_CONTACTS_V2, contact);
    return data;
  } catch (err: any) {
    if ((err as AxiosError<Contact>).response?.status === 409) {
      throw new DuplicateContactError(err.message);
    }

    throw err;
  }
}

export async function updateContact(contactId: string, contact: Contact): Promise<Contact> {
  const client = await getClient();
  try {
    const { data } = await client.put<Contact>(`${API_CONTACTS_V2}/${contactId}`, contact);
    return data;
  } catch (err: any) {
    if ((err as AxiosError<Contact>).response?.status === 409) {
      throw new DuplicateContactError(err.message);
    }

    throw err;
  }
}

export async function getContactAppointments(
  contactId: string,
  filters: CalendarEventsFilters = {},
  pagination: PaginationParams = {
    size: 50,
    sort: {
      createdAt: SortOrder.DESC,
    },
  },
): Promise<PaginatedResult<Appointment>> {
  const client = await getClient();

  const { data } = await client.get<PaginatedResult<Appointment>>(`${API_CONTACTS_V2}/${contactId}/appointments`, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getContactLeads(
  contactId: string,
  filters: Pick<LeadsProviderFilters, 'excludeStatus'> = {},
): Promise<Lead[]> {
  const client = await getClient();
  const { data } = await client.get<Lead[]>(`${API_CONTACTS_V2}/${contactId}/leads`, {
    params: serializeParams(filters),
  });

  return data;
}

export async function getContactOptIns(contactId: string): Promise<OptIn[]> {
  const client = await getClient();
  const { data } = await client.get<OptIn[]>(`${API_CONTACTS_V2}/${contactId}/opt-ins`);

  return data;
}

export async function getContactIntents(
  contactId: string,
  filters: Pick<IntentsProviderFilters, 'excludeStatus'>,
): Promise<Intent[]> {
  const client = await getClient();

  const { data } = await client.get<Intent[]>(`${API_CONTACTS_V2}/${contactId}/intents`, {
    params: serializeParams(filters),
  });

  return data;
}

export async function getContactSearchCriteria(contactId: string): Promise<SearchCriteria[]> {
  const client = await getClient();
  const { data } = await client.get<SearchCriteria[]>(`${API_CONTACTS_V2}/${contactId}/search-criteria`);

  return data;
}

export async function getContactAdditionalServices(contactId: string): Promise<AdditionalService[]> {
  const client = await getClient();
  const { data } = await client.get<AdditionalService[]>(`${API_CONTACTS_V2}/${contactId}/additional-services`);

  return data;
}

export async function getContactConcessions(contactId: string): Promise<Concession[]> {
  const client = await getClient();
  const { data } = await client.get<Concession[]>(`${API_CONTACTS_V2}/${contactId}/concessions`);

  return data;
}

export async function getContactRumors(
  contactId: string,
  filters: { excludeStatus?: RumorStatus | RumorStatus[] },
): Promise<Rumor[]> {
  const client = await getClient();
  const { data } = await client.get<Rumor[]>(`${API_CONTACTS_V2}/${contactId}/rumors`, {
    params: serializeParams(filters),
  });

  return data;
}

export async function getContactProperties(
  contactId: string,
  sort?: SortParams,
): Promise<PropertyContact[]> {
  const client = await getClient();
  const { data } = await client.get<PropertyContact[]>(`${API_CONTACTS_V2}/${contactId}/properties`, sort && {
    params: {
      sort: serializeSortParams(sort),
    },
  });

  return data;
}

export async function getContactProspects(
  contactId: NonNullable<Contact['id']>,
  filters: ContactProspectsFilters = {},
  sort?: SortParams,
): Promise<Prospect[]> {
  const client = await getClient();
  const { data } = await client.get<Prospect[]>(
    `${API_CONTACTS_V2}/${contactId}/prospects`,
    {
      params: {
        ...serializeParams(filters),
        sort: sort && serializeSortParams(sort),
      },
    },
  );

  return data;
}
