import React, { FunctionComponent } from 'react';
import { Skeleton } from '@doveit/bricks';
import { useAgentByEntity } from '../../../hooks/use-agent/useAgent';
import { EntityType } from '../../../domain/types/entityType';
import { getAgentName } from '../../utils/utils';

export interface ShowAgentNameProps {
  entityId: string | number,
  entityType: EntityType,
}

const ShowAgentName: FunctionComponent<ShowAgentNameProps> = ({
  entityId,
  entityType,
}) => {
  const { data: agent, error } = useAgentByEntity(entityId, entityType);

  if (!agent && !error) {
    return (
      <Skeleton width="7rem" />
    );
  }

  if (error) {
    return (<>-</>);
  }

  return agent! && (
    <>{getAgentName(agent)}</>
  );
};

export default ShowAgentName;
