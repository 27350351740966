export const ResidentialPropertyTypeValue = {
  APPARTAMENTO: 'APPARTAMENTO',
  CASA_INDIPENDENTE: 'CASA_INDIPENDENTE',
  ATTICO: 'ATTICO',
  MANSARDA: 'MANSARDA',
  LOFT: 'LOFT',
  VILLA: 'VILLA',
  VILLETTA_A_SCHIERA: 'VILLETTA_A_SCHIERA',
  RUSTICO_CASALE: 'RUSTICO_CASALE',
  PALAZZO: 'PALAZZO',
} as const;

export const NonResidentialPropertyTypeValue = {
  TERRENO: 'TERRENO',
  LOCALE_COMMERCIALE: 'LOCALE_COMMERCIALE',
  GARAGE: 'GARAGE',
  UFFICIO: 'UFFICIO',
  MAGAZZINO: 'MAGAZZINO',
  CAPANNONE: 'CAPANNONE',
} as const;

export const PropertyTypeValue = { ...ResidentialPropertyTypeValue, ...NonResidentialPropertyTypeValue } as const;

export type ResidentialPropertyType = keyof typeof ResidentialPropertyTypeValue;
export type NonResidentialPropertyType = keyof typeof NonResidentialPropertyTypeValue;
export type PropertyType = ResidentialPropertyType | NonResidentialPropertyType;

export const propertyTypesWithoutFloor: PropertyType[] = [
  ResidentialPropertyTypeValue.VILLA,
  ResidentialPropertyTypeValue.VILLETTA_A_SCHIERA,
  ResidentialPropertyTypeValue.CASA_INDIPENDENTE,
  ResidentialPropertyTypeValue.PALAZZO,
  ResidentialPropertyTypeValue.RUSTICO_CASALE,
];
