export enum LeadStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
  KO_GEO = 'KO_GEO',
  KO_BUY = 'KO_BUY',
  KO_RENT = 'KO_RENT',
  KO_NOT_INTERESTED = 'KO_NOT_INTERESTED',
  KO_NOT_RESIDENTIAL = 'KO_NOT_RESIDENTIAL',
  KO_GARAGE = 'KO_GARAGE',
  KO_CANNOT_CONTACT = 'KO_CANNOT_CONTACT',
  KO_DUPLICATE = 'KO_DUPLICATE',
  KO_WRONG_PHONE_NUMBER = 'KO_WRONG_PHONE_NUMBER',
  KO_TEST = 'KO_TEST',
  KO_OTHER = 'KO_OTHER',
  /**
   * @todo
   * Not really a KO status anymore, maybe we can rename it to just `ONLY_EVALUATION`.
   */
  KO_ONLY_EVALUATION = 'KO_ONLY_EVALUATION',
  PRIVATE = 'PRIVATE',
}
