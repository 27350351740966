import useSWR, { SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Agency } from '../../../providers/api/dtos';
import { API_AGENCIES_PATH } from '../../../providers/api/api';
import { getAgencyById } from '../../../providers/api/agency/agencyProvider';

export default function useAgency(id: Agency['id']): SWRResponse<Agency, AxiosError> {
  const key = id ? [API_AGENCIES_PATH, id] : null;

  return useSWR(key, () => getAgencyById(id!));
}
