import {
  getSpacing, getTypography, rgba, SpacingName, ThemedFunctor, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({ $clickable }) => css`
  position: relative;
  width: 100%;

  img {
    display: block;
    width: 100%;
  }
  
  ${$clickable && `
    cursor: pointer;
  `}
`;

export const label: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.padding(theme, [SpacingName.SMALLER, SpacingName.SMALL])}
  ${getTypography(theme, TypographyName.HEADING_4)}
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  color: ${theme.palettes.neutrals.lightest};
  background-color: ${rgba(theme.palettes.brand.primary, 0.6)};
  z-index: 1;
  text-transform: uppercase;
  text-align: center;
`;

export const placeholder: ThemedFunctor = ({
  theme,
}) => css`
  position: relative;
  padding-top: 66.6%; /* it is the approximated ratio between the current image height and its width */
  background-color: ${theme.palettes.neutrals.lighter};

  aspect-ratio: 3/2;

  > div {
    position: absolute;
    line-height: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: ${theme.palettes.neutrals.light};
    text-align: center;
  }
`;
