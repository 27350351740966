/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Action,
  ActionIcon,
  HStack,
  ICON_PLUS,
  ICON_TRASH_CAN_OUTLINE,
  Message,
  Portal,
  Spacing,
  Stack,
  Typography,
  useModal,
} from '@doveit/bricks';
import objectHash from 'object-hash';
import { isEqual } from 'lodash';
import { formatEuro } from '@doveit/hammer';
import { AssignmentWizardStepProps } from '../types';
import PropertyGeoForm, { PropertyGeoFormModel } from '../../../../components/property-geo-form/PropertyGeoForm';
import { toAssignmentWizardData, toPropertyGeoFormModel } from './mappers';
import useLead from '../../../../../lead/hooks/use-lead/useLead';
import { PASS_THROUGH_VALIDATION_SCHEMA } from '../../../../../constants';
import SimpleModal from '../../../../../components/simple-modal/SimpleModal';
import PropertyUnitForm, { PropertyUnitFormModel } from '../../../../components/property-unit-form/PropertyUnitForm';
import Card from '../../../../../components/card/Card';
import { AssignmentWizardData, PropertyUnit } from '../../../../../providers/api/dtos';
import { energyClassLabels, propertyUnitIntendedUseLabels } from '../../../../../labels';
import { registryCategoryLabels } from '../../../../../labels/registryCategoryLabels';
import Divider from '../../../../../components/divider/Divider';
import * as styles from './AssignmentWizardProperty.style';
import { useGeocodeByAddress } from '../../../../../hooks/use-geocode-by-address/useGeocodeByAddress';
import { NO_VALUE_SYMBOL } from '../../../../utils';
import { PropertyUnitModalData } from '../../../../types';
import { toPropertyUnit, toPropertyUnitFormModel } from '../../../../components/property-unit-form/mappers';

export const FILL_CADASTRAL_DATA_INFO_MESSAGE = 'Compila i dati catastali e guadagna visibilità sui portali';
export const ADD_PROPERTY_UNITS_INFO_MESSAGE = 'Aggiungi le unità immobiliari';

const AssignmentWizardProperty: React.FC<AssignmentWizardStepProps> = ({
  prospect,
  initialData,
  loading = false,
  formRef,
  onSubmit,
}) => {
  const propertyUnitModal = useModal<PropertyUnitModalData>();
  const { data: lead, isLoading: isLeadLoading } = useLead(prospect?.leadId);
  const { data: geocodeResult = [] } = useGeocodeByAddress(lead?.propertyAddress);

  const [propertyUnits, setPropertyUnits] = React.useState([...(initialData.property?.cadastralRegistry?.units ?? [])]);
  const [isEditingPropertyUnits, setIsEditingPropertyUnits] = React.useState(false);

  const pushPropertyUnit = React.useCallback((formValues: PropertyUnitFormModel) => {
    setIsEditingPropertyUnits(true);

    const propertyUnit = toPropertyUnit(formValues);

    setPropertyUnits((current) => [...current, propertyUnit]);

    setIsEditingPropertyUnits(false);
  }, []);

  const editPropertyUnit = React.useCallback((formValues: PropertyUnitFormModel, index: number) => {
    setIsEditingPropertyUnits(true);

    const propertyUnit = toPropertyUnit(formValues);

    setPropertyUnits((current) => {
      current[index] = propertyUnit;

      return current;
    });

    setIsEditingPropertyUnits(false);
  }, []);

  const removePropertyUnit = React.useCallback((index: number) => () => {
    setPropertyUnits((prev) => {
      const toSplice = prev.slice();
      toSplice.splice(index, 1);

      return toSplice;
    });
  }, []);

  const onGeoFormSubmit = React.useCallback(async (formValues: PropertyGeoFormModel) => {
    const newAssignmentWizardData = await toAssignmentWizardData(initialData, formValues);

    /**
     * @todo fix the type in bricks
     */
    const isGeoFormDirty = (formRef?.current as any)?.dirty ?? false;
    const arePropertyUnitsDirty = !isEqual(initialData.property?.cadastralRegistry?.units ?? [], propertyUnits);

    const withPropertyUnits: AssignmentWizardData = {
      ...newAssignmentWizardData,
      property: {
        ...newAssignmentWizardData.property,
        cadastralRegistry: {
          ...newAssignmentWizardData.property?.cadastralRegistry,
          units: propertyUnits.length > 0 ? propertyUnits : undefined,
        },
      },
    };

    onSubmit(withPropertyUnits, isGeoFormDirty || arePropertyUnitsDirty);
  }, [formRef, initialData, onSubmit, propertyUnits]);

  const onUnitFormSubmit = React.useCallback((formValues: PropertyUnitFormModel) => {
    const { data } = propertyUnitModal;

    if (typeof data?.index !== 'undefined') {
      editPropertyUnit(formValues, data.index);
    } else {
      pushPropertyUnit(formValues);
    }

    propertyUnitModal.close();
  }, [editPropertyUnit, propertyUnitModal, pushPropertyUnit]);

  const openPropertyUnitModal = React.useCallback((data?: PropertyUnit, index?: number) => () => {
    propertyUnitModal.open({ propertyUnit: data, index });
  }, [propertyUnitModal]);

  return (
    <>
      <Spacing margin={[0, 0, 400, 0]}>
        <Message
          type="info"
          message={FILL_CADASTRAL_DATA_INFO_MESSAGE}
        />
      </Spacing>

      <PropertyGeoForm
        initialValues={toPropertyGeoFormModel(initialData, lead?.propertyAddress, geocodeResult[0])}
        innerRef={formRef}
        validationSchema={PASS_THROUGH_VALIDATION_SCHEMA}
        onSubmit={onGeoFormSubmit}
        loading={loading || isLeadLoading}
      />

      <Divider>
        Dati catastali
      </Divider>

      <HStack alignItems="center" justifyContent="end">
        <Spacing margin={[0, 0, 100, 0]}>
          <Action
            label="Unità immobiliare"
            aria-label="Aggiungi unità immobiliare"
            iconLeft={{ path: ICON_PLUS }}
            size="S"
            onClick={openPropertyUnitModal()}
          />
        </Spacing>
      </HStack>

      {propertyUnits.length === 0
        ? <Message message={ADD_PROPERTY_UNITS_INFO_MESSAGE} />
        : (
          <Stack aria-label="Unità immobiliari">
            {propertyUnits.map((propertyUnit, i) => {
              const {
                category,
                energyClass,
                intendedUse,
                parcel,
                section,
                sheet,
                subunit,
                value,
              } = propertyUnit;

              return (
                <Card
                  key={objectHash({ ...propertyUnit, i })}
                  aria-label="Resoconto unità immobiliare"
                >
                  <Card.Header>
                    <Card.Title>
                      <Typography.HEADING_3 color="brand.primary">
                        Unità immobiliare
                      </Typography.HEADING_3>
                    </Card.Title>
                    <HStack>
                      <Action
                        label="Modifica"
                        aria-label="Modifica unità immobiliare"
                        size="S"
                        onClick={openPropertyUnitModal(propertyUnit, i)}
                      />
                      <ActionIcon
                        label="Elimina"
                        icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                        color="critical"
                        size="S"
                        aria-label="Elimina unità immobiliare"
                        onClick={removePropertyUnit(i)}
                      />
                    </HStack>
                  </Card.Header>

                  <Card.Box>
                    {/** @todo evaluate if we wanna have a dedicated component with this style (similar to DetailItem/List) */}
                    <div css={styles.base} $columns={4}>
                      <Stack>
                        <div>Sezione</div>
                        {section ? <strong>{section}</strong> : NO_VALUE_SYMBOL}
                      </Stack>
                      <Stack>
                        <div>Categoria</div>
                        {category ? <strong>{registryCategoryLabels[category]}</strong> : NO_VALUE_SYMBOL}
                      </Stack>
                      <Stack>
                        <div>Foglio</div>
                        {sheet ? <strong>{sheet}</strong> : NO_VALUE_SYMBOL}
                      </Stack>
                      <Stack>
                        <div>Destinazione d&apos;uso</div>
                        {intendedUse ? <strong>{propertyUnitIntendedUseLabels[intendedUse]}</strong> : NO_VALUE_SYMBOL}
                      </Stack>
                      <Stack>
                        <div>Particella</div>
                        {parcel ? <strong>{parcel}</strong> : NO_VALUE_SYMBOL}
                      </Stack>
                      <Stack>
                        <div>Rendita catastale</div>
                        {value ? <strong>{formatEuro(value)}</strong> : NO_VALUE_SYMBOL}
                      </Stack>
                      <Stack>
                        <div>Subalterno</div>
                        {subunit ? <strong>{subunit}</strong> : NO_VALUE_SYMBOL}
                      </Stack>
                      <Stack>
                        <div>Classe energetica</div>
                        {energyClass ? <strong>{energyClassLabels[energyClass]}</strong> : NO_VALUE_SYMBOL}
                      </Stack>
                    </div>
                  </Card.Box>
                </Card>
              );
            })}
          </Stack>
        )}

      <Portal>
        <SimpleModal
          {...propertyUnitModal}
          aria-label={propertyUnitModal.data?.propertyUnit
            ? 'Modale per la modifica dell\'unità immobiliare'
            : 'Modale per l\'aggiunta di un\'unità immobiliare'}
          title={propertyUnitModal.data?.propertyUnit
            ? 'Modifica unità immobiliare'
            : 'Aggiungi unità immobiliare'}
        >
          <PropertyUnitForm
            onSubmit={onUnitFormSubmit}
            initialValues={propertyUnitModal.data?.propertyUnit
              ? toPropertyUnitFormModel(propertyUnitModal.data.propertyUnit)
              : {}}
            loading={isEditingPropertyUnits}
            submitLabel={propertyUnitModal.data?.propertyUnit
              ? 'Modifica'
              : 'Aggiungi'}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default AssignmentWizardProperty;
