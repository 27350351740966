import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setQueryParam } from '../../utils/querystring/querystring';

export interface UsePaginationResult {
  page: number,
  paginationQueryParam: string,
  goToPage: (page: number) => void
}

export default function usePagination<T extends HTMLElement>(paginationQueryParam: string = 'page', ref?: React.RefObject<T>): UsePaginationResult {
  const location = useLocation();
  const navigate = useNavigate();

  const page = React.useMemo(() => {
    const params = new URLSearchParams(location.search);

    return +(params.get(paginationQueryParam) ?? '1') - 1;
  }, [location.search, paginationQueryParam]);

  const goToPage = React.useCallback((newPage: number) => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
    });
    navigate(`${location.pathname}?${setQueryParam(location.search, paginationQueryParam, newPage)}`);
  }, [location.pathname, location.search, paginationQueryParam, ref, navigate]);

  return {
    page,
    paginationQueryParam,
    goToPage,
  };
}

export interface UseStatefulPaginationResult {
  page: number,
  goToPage: (page: number) => void
}

export function useStatefulPagination<T extends HTMLElement>(ref?: React.RefObject<T>): UseStatefulPaginationResult {
  const [page, setPage] = React.useState(1);

  const goToPage = React.useCallback((newPage: number) => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
    });

    setPage(newPage);
  }, [ref]);

  return {
    page: page < 1 ? 1 : page - 1,
    goToPage,
  };
}
