import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { Identifiable } from '../../../types';

export interface SortableItemProps extends React.PropsWithChildren, Identifiable {
  disabled?: boolean,
}

const SortableItem: React.FC<SortableItemProps> = ({
  id,
  disabled = false,
  children,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, disabled });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      aria-disabled={disabled}
    >
      {children}
    </div>
  );
};

export default SortableItem;
