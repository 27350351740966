import React from 'react';
import { Action, Portal, useModal } from '@doveit/bricks';
import { Rumor } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import EditRumor from '../edit-rumor/EditRumor';

interface ChildFnProps {
  onEditRumorClick: VoidFunction,
}

export interface EditRumorActionProps {
  rumor: Rumor,
  children?: (props: ChildFnProps) => React.ReactNode
  onSuccess?: (updatedRumor: Rumor) => void
}

const EditRumorAction: React.FC<EditRumorActionProps> = ({
  rumor,
  onSuccess,
  children,
}) => {
  const editRumorModal = useModal();

  const onEditRumorClick = React.useCallback(() => {
    editRumorModal.open();
  }, [editRumorModal]);

  const internalOnSuccess = React.useCallback((updatedRumor: Rumor) => {
    editRumorModal.close();
    onSuccess?.(updatedRumor);
  }, [editRumorModal, onSuccess]);

  return (
    <>
      {
        children
          ? children({ onEditRumorClick })
          : (
            <Action
              label="Modifica"
              onClick={onEditRumorClick}
              size="S"
              aria-label="Pulsante per modificare la notizia"
            />
          )
      }
      <Portal>
        <SimpleModal
          {...editRumorModal}
          title="Modifica notizia"
          aria-label="Modale per modificare la notizia"
        >
          <EditRumor
            rumor={rumor}
            onSuccess={internalOnSuccess}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditRumorAction;
