/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Action, ICON_FILE_PLUS_OUTLINE, ICON_FILE_REPLACE_OUTLINE, Link, Spacing, Icon, Typography,
} from '@doveit/bricks';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import Card from '../../../components/card/Card';
import * as styles from './OfferModePage.style';
import { GenericPageLayoutWrapperWidth } from '../../../layouts/generic-page-layout/wrapper/GenericPageLayoutWrapper';
import useOffersByIntentId from '../../hooks/use-offers-by-intent-id/useOffersByIntentId';
import { IntentStatus, OfferStatus } from '../../../domain/types';
import { hasPendingOffers } from '../../utils';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import useIntent from '../../../intent/hooks/use-intent/useIntent';
import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';

export const LOAD_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare alcuni dati dell\'interesse.';
export const OFFER_ALREADY_CREATED_ERROR_MESSAGE = 'Una proposta di acquisto è già presente per questo interesse.';
export const INTENT_NOT_IN_PROGRESS_ERROR_MESSAGE = 'Una proposta di acquisto può essere presentata solo per un interesse attivo.';

const OfferModePage: React.FC = () => {
  const navigate = useNavigate();
  const { id: intentId } = useParams();

  const { data: offers, error: offersError } = useOffersByIntentId(intentId, [OfferStatus.IN_PROGRESS, OfferStatus.ACCEPTED]);
  const { data: intent, error: intentError } = useIntent(intentId);
  const currentAgent = useCurrentAgent();

  const hasDigitalOfferEnabled = React.useMemo(
    () => !currentAgent?.franchising || (currentAgent?.agencies?.some((agency) => agency.configuration?.digitalOffer?.enabled === true) ?? false),
    [currentAgent?.agencies, currentAgent?.franchising],
  );

  React.useEffect(() => {
    if (!hasDigitalOfferEnabled) {
      navigate(`/intents/${intentId}/offer/manual`);
    }
  }, [currentAgent, hasDigitalOfferEnabled, intentId, navigate]);

  const goToIntentPage = React.useCallback(() => {
    navigate(`/intents/${intentId}`);
  }, [navigate, intentId]);

  const goToManageOfferPage = React.useCallback((type: 'manual' | 'digital') => () => {
    navigate(`/intents/${intentId}/offer/${type}`);
  }, [intentId, navigate]);

  if (offersError || intentError) {
    return <GenericErrorPage title={LOAD_DATA_ERROR_MESSAGE} />;
  }

  if (offers && hasPendingOffers(offers)) {
    return <GenericErrorPage title={OFFER_ALREADY_CREATED_ERROR_MESSAGE} />;
  }

  if (intent?.status !== IntentStatus.IN_PROGRESS) {
    return <GenericErrorPage title={INTENT_NOT_IN_PROGRESS_ERROR_MESSAGE} />;
  }

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content
        maxWrapperWidth={GenericPageLayoutWrapperWidth.MEDIUM}
        title="Proposta di acquisto"
        subtitle={(
          <Link
            aria-label="Vedi interesse"
            onClick={goToIntentPage}
          >
            ← Vedi interesse
          </Link>
        )}
      >
        <Card>
          <Card.Inline>
            <Card.Box>
              <div css={styles.offerBox}>
                <Icon path={ICON_FILE_PLUS_OUTLINE} size={50} />
                <Typography.HEADING_4 tagName="h1">
                  Proposta cartacea
                </Typography.HEADING_4>
                <Spacing margin={[200, 0]}>
                  <Typography.BODY>
                    Carica la proposta firmata nella sezione documenti
                  </Typography.BODY>
                </Spacing>
                <Action
                  color="primary"
                  emphasis="high"
                  label="Carica proposta"
                  onClick={goToManageOfferPage('manual')}
                />
              </div>
            </Card.Box>
            <Card.ColumnDivider />
            <Card.Box>
              <div css={styles.offerBox}>
                <Icon path={ICON_FILE_REPLACE_OUTLINE} size={50} />
                <Typography.HEADING_4 tagName="h1">
                  Proposta digitale
                </Typography.HEADING_4>
                <Spacing margin={[200, 0]}>
                  <Typography.BODY>
                    Compila la proposta, raccogli le firme digitalmente o stampa il documento e <strong>risparmia tempo</strong>
                  </Typography.BODY>
                </Spacing>
                <Action
                  color="primary"
                  emphasis="high"
                  label="Genera proposta"
                  onClick={goToManageOfferPage('digital')}
                />
              </div>
            </Card.Box>
          </Card.Inline>
        </Card>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default OfferModePage;
