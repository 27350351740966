import axios from 'axios';

export const AIRCALL_HOST = process.env.REACT_APP_AIRCALL_HOST || 'http://localhost:8080/aircall/api/v1';
export const AIRCALL_API_ID = process.env.REACT_APP_AIRCALL_API_ID;
export const AIRCALL_API_TOKEN = process.env.REACT_APP_AIRCALL_API_TOKEN;
export const DEFAULT_OUTBOUND_NUMBER_ID = +process.env.REACT_APP_AIRCALL_OUTBOUND_NUMBER_ID;

// @TODO: find a better way to store user's id
export const AIRCALL_IDS_BY_EMAIL: {
  [key: string]: {
    aircallId: string,
    numberId?: number,
  }
} = {
  'salvatore.vadacca@dove.it': { aircallId: '333797' },
  'stefano.massera@dove.it': { aircallId: '333797' },
  'andrea.mangano@dove.it': { aircallId: '333797' },
  'angela.rocca@dove.it': { aircallId: '941119', numberId: 906192 },
  'serena.denicola@dove.it': { aircallId: '550751', numberId: 906193 },
  'massimiliano.conte@dove.it': { aircallId: '1332568', numberId: 906191 },
};

export async function dialNumber(email: string, phoneNumber: string): Promise<void> {
  const aircallIds = AIRCALL_IDS_BY_EMAIL[email];
  if (!aircallIds) {
    return Promise.reject(new Error('User cannot call or is not listed'));
  }

  const { aircallId, numberId } = aircallIds;
  await axios.post(`${AIRCALL_HOST}/users/${aircallId}/calls`, {
    number_id: numberId ?? DEFAULT_OUTBOUND_NUMBER_ID,
    to: phoneNumber,
  }, {
    auth: {
      username: AIRCALL_API_ID,
      password: AIRCALL_API_TOKEN,
    },
  });

  return undefined;
}
