import { Form, FormSubmitType } from '@doveit/bricks';
import React, { FunctionComponent, useMemo } from 'react';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';
import { provinceSelectOptions } from '../../../select-options';
import { jobPositionDepartmentLabels } from '../../../labels';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { Agent } from '../../../providers/api/dtos';

export interface JobApplicationFiltersFormModel {
  department?: string,
  assignee?: Agent | null,
  province?: string,
  license?: boolean,
}

export interface JobApplicationFiltersFormProps {
  initialValues?: JobApplicationFiltersFormModel
  loading?: boolean
  onSubmit: FormSubmitType<JobApplicationFiltersFormModel>
}

const JobApplicationFiltersForm: FunctionComponent<JobApplicationFiltersFormProps> = ({
  initialValues,
  loading,
  onSubmit,
}) => {
  const mergedInitialValues = useMemo(() => ({
    department: '',
    province: '',
    license: false,
    ...(initialValues || {}),
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      loading={loading}
      onSubmit={onSubmit}
    >
      <Form.Item>
        <Form.Select
          name="department"
          label="Dipartimento"
          aria-label="Campo per specificare il dipartimento"
          options={asSelectOptions(jobPositionDepartmentLabels)}
        />
      </Form.Item>
      <Form.Item>
        <Form.Select
          name="province"
          label="Provincia"
          aria-label="Campo per specificare la provincia"
          options={provinceSelectOptions}
        />
      </Form.Item>
      <Form.Item>
        <AgentAutoComplete
          name="assignee"
          label="Assegnatario"
          aria-label="Campo per specificare l'assegnatario"
          placeholder="Cerca per nome"
        />
      </Form.Item>
      <Form.Item>
        <Form.Checkbox
          name="license"
          aria-label="Licenza per compravendita immobiliare"
          text="Patentino"
        />
      </Form.Item>
      <Form.Actions>
        <Form.Submit
          label="Filtra"
          expanded
        />
      </Form.Actions>
    </Form>
  );
};

export default JobApplicationFiltersForm;
