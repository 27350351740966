import {
  Action, ActionIcon, HStack, ICON_BELL_RING_OUTLINE, ICON_EMAIL_OUTLINE, ICON_WHATSAPP, Message, Portal, Spacing, useModal,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../components/simple-modal/SimpleModal';
import useRBAC from '../../hooks/use-rbac/useRBAC';
import { useAgent } from '../../hooks/use-agent/useAgent';
import { ReferenceType } from '../../domain/types';
import { Prospect, Rumor } from '../../providers/api/dtos';
import SendWhatsappAction from '../send-whatsapp-action/SendWhatsappAction';
import { updateProspect } from '../../providers/api/prospect/prospectProvider';
import { updateRumor } from '../../providers/api/rumor/rumorProvider';
import { getAgentName } from '../../agent/utils/utils';

type Reference =
| {
  referenceType: ReferenceType.PROSPECT,
  reference: Prospect,
  referenceLabel: string,
} | {
  referenceType: ReferenceType.RUMOR,
  reference: Rumor,
  referenceLabel: string,
};

export interface SendNudgeActionBaseProps {
  disabled?: boolean,
  onSuccess?: (updatedReference: Reference['reference']) => void,
  children?: ({ disabled, loading, sendNudge }: { disabled: boolean, loading: boolean, sendNudge?: VoidFunction }) => React.ReactElement | null,
}

export type SendNudgeActionProps = SendNudgeActionBaseProps & Reference;

export const SAVE_NUDGE_INFO_MESSAGE = "Una notifica è stata inviata all'agente. Puoi eventualmente sollecitare l'agente con una email o messaggio whatsapp.";
export const SAVE_NUDGE_WARNING_MESSAGE = "Non è stato possibile inviare la notifica all'agente. Puoi comunque sollecitare l'agente con una email o messaggio whatsapp.";
export const NUDGE_MODAL_ACTIONS_ERROR_MESSAGE = 'Non è stato possibile caricare le azioni di sollecito.';

export const DEFAULT_AGENT_MESSAGE = (entityLabel: String) => `Ciao __AGENT_NAME__, non è stata definita alcuna azione per la ${entityLabel} al link __ENTITY_LINK__. Mi aggiorni sui prossimi passi? Grazie`;

const SendNudgeAction: React.FC<SendNudgeActionProps> = ({
  reference,
  referenceType,
  referenceLabel,
  disabled = false,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const actionsModal = useModal<{ nudgeIsSent: boolean }>();
  const { user, mainUserRole } = useRBAC();
  const { data: agent, isLoading: isAgentLoading } = useAgent(reference.agentId);

  const agentMessage = React.useMemo(() => {
    if (!agent) {
      return '';
    }

    return DEFAULT_AGENT_MESSAGE(referenceLabel)
      .replace('__AGENT_NAME__', agent.name.name)
      .replace('__ENTITY_LINK__', `${process.env.REACT_APP_BACKOFFICE_URL || 'https://backoffice-dev.dove.it'}${referenceType === ReferenceType.PROSPECT ? '/prospects' : '/rumors'}/${reference.id}`); // TODO: switch domain according to the environment
  }, [agent, reference.id, referenceLabel, referenceType]);

  const sendNudge = React.useCallback(async () => {
    setIsSaving(true);

    const referenceAfterNudge = {
      ...reference,
      notes: [
        {
          author: user.name,
          role: mainUserRole,
          text: 'Sollecito per assenza di prossima azione',
          date: new Date().toISOString(),
          nudge: true,
        },
        ...reference.notes || [],
      ],
    };

    try {
      let updatedReference: Reference['reference'] = reference;
      switch (referenceType) {
        case ReferenceType.PROSPECT: {
          updatedReference = await updateProspect(reference.id!, referenceAfterNudge as Prospect);
          break;
        }
        case ReferenceType.RUMOR: {
          updatedReference = await updateRumor(referenceAfterNudge as Rumor);
          break;
        }
        default:
          break;
      }

      setIsSaving(false);
      actionsModal.open({ nudgeIsSent: true });

      onSuccess?.(updatedReference);
    } catch (error) {
      setIsSaving(false);

      actionsModal.open({ nudgeIsSent: false });
    }
  }, [actionsModal, mainUserRole, onSuccess, reference, referenceType, user.name]);

  return (
    <>
      {children
        ? children({
          disabled,
          loading: isSaving,
          sendNudge: disabled ? undefined : sendNudge,
        })
        : (
          <ActionIcon
            label="Sollecita"
            aria-label="Sollecita agente"
            icon={{ path: ICON_BELL_RING_OUTLINE }}
            size="S"
            onClick={sendNudge}
            loading={isSaving}
            disabled={disabled}
          />
        )}
      {actionsModal.data && (
        <Portal>
          <SimpleModal
            {...actionsModal}
            title="Sollecita la definizione della prossima azione"
            aria-label="actions-modal"
          >
            <Spacing margin={[0, 0, 200]}>
              {actionsModal.data?.nudgeIsSent ? (
                <Message
                  type="info"
                  message={SAVE_NUDGE_INFO_MESSAGE}
                />
              ) : (
                <Message
                  type="warning"
                  message={SAVE_NUDGE_WARNING_MESSAGE}
                />
              )}
            </Spacing>
            <HStack justifyContent="center">
              <HStack>
                <Action
                  aria-label="send-email-nudge"
                  label="Email"
                  iconLeft={{ path: ICON_EMAIL_OUTLINE }}
                  href={`mailto:${agent?.email.work}?subject=${encodeURIComponent(`Sollecito prossima azione ${referenceLabel}`)}&body=${encodeURIComponent(agentMessage)}`}
                  disabled={!agent}
                  loading={isAgentLoading}
                />
                <SendWhatsappAction
                  phoneNumber={agent?.mobile.work!}
                  name={getAgentName(agent)}
                  initialMessage={agentMessage}
                  disabled={!agent}
                >
                  {({ onClick, disabled: disableAction }) => (
                    <Action
                      aria-label="send-whatsapp-nudge"
                      label="Whatsapp"
                      iconLeft={{ path: ICON_WHATSAPP }}
                      disabled={disableAction}
                      loading={isAgentLoading}
                      onClick={onClick}
                    />
                  )}
                </SendWhatsappAction>
              </HStack>
            </HStack>
          </SimpleModal>
        </Portal>
      )}
    </>
  );
};

export default SendNudgeAction;
