import { ExtraPackagePayment } from './extraPackagePayment';
import {
  ExtraPackageDoveIt, ExtraPackagePriceType, ExtraPackageProvider, ExtraPackageSPF, ExtraPackageType,
} from './extraPackageType';

export interface ExtraPackage {
  id: number | null,
  name: ExtraPackageType,
  price?: number,
  priceType: ExtraPackagePriceType,
  providedBy: ExtraPackageProvider,
  legacy: boolean,
  payment?: ExtraPackagePayment,
}

export type DoveItExtraPackages = ExtraPackageDoveIt.EXTRA_VISIBILITY | ExtraPackageDoveIt.INTERIOR_RESTYLING | ExtraPackageDoveIt.PRESTIGE | ExtraPackageDoveIt.DRONE | ExtraPackageDoveIt.PHOTO_PLUS | ExtraPackageDoveIt.PERSONALIZED_MARKETING | ExtraPackageDoveIt.PHOTO_PRO;

export type SPFExtraPackages = ExtraPackageSPF.APE | ExtraPackageSPF.DOCUMENTS_ACCESS | ExtraPackageSPF.VERIFICATION_SERVICE;

export const DOVEIT_EXTRA_PACKAGES_DEFAULT_VALUES: Record<DoveItExtraPackages, number | undefined> = {
  EXTRA_VISIBILITY: undefined,
  INTERIOR_RESTYLING: undefined,
  PRESTIGE: undefined,
  PHOTO_PLUS: undefined,
  DRONE: 999,
  PERSONALIZED_MARKETING: undefined,
  PHOTO_PRO: undefined,
};

export const DOVEIT_EXTRA_PACKAGES_DEFAULT = [{
  name: ExtraPackageDoveIt.EXTRA_VISIBILITY,
  supportedPayments: [
    ExtraPackagePayment.AT_ASSIGNMENT_SIGNATURE,
    ExtraPackagePayment.AT_OFFER_ACCEPTANCE,
  ],
}, {
  name: ExtraPackageDoveIt.INTERIOR_RESTYLING,
  supportedPayments: [
    ExtraPackagePayment.AT_ASSIGNMENT_SIGNATURE,
    ExtraPackagePayment.AT_OFFER_ACCEPTANCE,
  ],
}, {
  name: ExtraPackageDoveIt.PRESTIGE,
  supportedPayments: [
    ExtraPackagePayment.AT_ASSIGNMENT_SIGNATURE,
    ExtraPackagePayment.AT_OFFER_ACCEPTANCE,
  ],
}, {
  name: ExtraPackageDoveIt.DRONE,
  supportedPayments: [
    ExtraPackagePayment.AT_ASSIGNMENT_SIGNATURE,
  ],
}];

export const SPF_EXTRA_PACKAGES_DEFAULT_VALUES: Record<SPFExtraPackages, number | undefined> = {
  APE: 200,
  DOCUMENTS_ACCESS: 100,
  VERIFICATION_SERVICE: 299,
};
