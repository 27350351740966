import * as Yup from 'yup';
import { ProspectStatus } from '../../../domain/types';

const stringNumberSchema = Yup.string()
  .matches(/^\d+$/, 'Valore non valido. Es: 150000');

export default Yup.object().shape({
  status: Yup.string()
    .required('Il campo è obbligatorio'),
  ownerEvaluation: stringNumberSchema
    .when('status', {
      is: ProspectStatus.OVER_PRICED,
      then: (schema) => schema.required('Il campo è obbligatorio'),
      otherwise: (schema) => schema.notRequired(),
    }),
  agentEvaluation: stringNumberSchema
    .test('agentEvaluation', (value, context) => {
      if (value === undefined && context.parent.status === ProspectStatus.OVER_PRICED) {
        return context.createError({
          path: 'agentEvaluation',
          message: 'Il campo è obbligatorio',
        });
      }

      if (context.parent.status === ProspectStatus.OVER_PRICED && +(context.parent.agentEvaluation) > +(context.parent.ownerEvaluation) * 0.8) {
        return context.createError({
          path: 'agentEvaluation',
          message: 'Per una valutazione sovrapprezzo, la valutazione deve essere almeno il 20% inferiore alla richiesta',
        });
      }

      return true;
    }),
  willingness: stringNumberSchema
    .when('status', {
      is: ProspectStatus.NOT_INTERESTED,
      then: (schema) => schema.test('willingness', (value, context) => {
        if (value === '1') {
          return true;
        }

        return context.createError({
          path: 'willingness',
          message: 'Per una valutazione in stato NON INTERESSATO, la propensione deve essere pari al minor valore possibile',
        });
      }),
    }),
  sellability: stringNumberSchema
    .when('status', {
      is: ProspectStatus.UNSELLABLE,
      then: (schema) => schema.test('sellability', (value, context) => {
        if (value === '1') {
          return true;
        }

        return context.createError({
          path: 'sellability',
          message: 'Per una valutazione in stato INVENDIBILE, la vendibilità deve essere pari al minor valore possibile',
        });
      }),
    }),
});
