/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Action, ActionIcon, Badge, BreakpointQueryName, Card, ConfirmModal, FontWeight, Grid, ICON_PLUS, ICON_TRASH_CAN_OUTLINE, Portal, Stack, Text, useModal, useNotifications,
} from '@doveit/bricks';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import { formatDate } from '@doveit/hammer';
import { Contact, OptIn } from '../../../providers/api/dtos';
import useContactOptIns from '../../hooks/use-contact-opt-ins/useContactOptIns';
import { createOptIn, deleteOptIn } from '../../../providers/api/opt-in/optInProvider';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { optInTypeLabels } from '../../../labels';
import { OptInSource, OptInType } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { optInSourceIcon } from '../../constants';
import SimpleTable from '../../../components/simple-table/SimpleTable';

export const REVOKE_OPT_IN_ERROR_MESSAGE = 'Non è stato possibile revocare il consenso';
export const REVOKE_OPT_IN_SUCCESS_MESSAGE = 'Il consenso è stato revocato con successo';
export const ADD_OPT_IN_ERROR_MESSAGE = 'Non è stato possibile prestare il consenso';
export const ADD_OPT_IN_SUCCESS_MESSAGE = 'Il consenso è stato prestato con successo';

export type SelectedOptIn = {
  optIn: OptIn,
  selected: boolean,
};

interface ChildFnProps {
  update: VoidFunction,
  label: string,
  isSaving: boolean,
}

export interface UpdateContactOptInsActionProps {
  contactId: NonNullable<Contact['id']>,
  canAdd?: boolean,
  canDelete?: boolean,
  onSuccess?: VoidFunction,
  children?: (props: ChildFnProps) => React.ReactNode,
}

/**
 * @todo integrate this in OptInsWidget
 */
const UpdateContactOptInsAction: React.FC<UpdateContactOptInsActionProps> = (props) => {
  const {
    contactId,
    onSuccess,
    children,
    canAdd = false,
    canDelete = false,
  } = props;

  const { breakpoints } = useTheme();
  const [isSaving, setIsSaving] = React.useState(false);
  const editOptInsModal = useModal();
  const addOptInConfirmationModal = useModal<OptIn>();
  const deleteOptInConfirmationModal = useModal<OptIn>();
  const { addSuccess, addError } = useNotifications();
  const { user } = useRBAC();

  const isMobile = useMediaQuery(breakpoints.down(BreakpointQueryName.MD));

  const {
    data: optIns,
    isLoading: areOptInsLoading,
    error: optInsError,
    mutate: mutateOptIns,
  } = useContactOptIns(contactId);

  const selectedOptIns = React.useMemo(() => Object.values(OptInType)
    .map((optInType) => {
      const foundOptIn = optIns?.find((optIn) => optIn.type === optInType);
      if (!foundOptIn) {
        return ({
          optIn: {
            type: optInType,
            contactId,
            sourceType: OptInSource.BACKOFFICE,
            sourceValue: user.email,
            givenAt: new Date().toISOString(),
          } as OptIn,
          selected: false,
        });
      }

      return {
        optIn: foundOptIn,
        selected: true,
      };
    })
    .sort((a, b) => optInTypeLabels[a.optIn.type].sortingOrder - optInTypeLabels[b.optIn.type].sortingOrder), [contactId, optIns, user.email]);

  const onAddClick = React.useCallback((missingOptIn: OptIn) => () => {
    addOptInConfirmationModal.open(missingOptIn);
  }, [addOptInConfirmationModal]);

  const onDeleteClick = React.useCallback((optIn: OptIn) => () => {
    deleteOptInConfirmationModal.open(optIn);
  }, [deleteOptInConfirmationModal]);

  const handleAddition = React.useCallback(async () => {
    try {
      setIsSaving(true);

      await createOptIn(addOptInConfirmationModal.data!);

      addSuccess(ADD_OPT_IN_SUCCESS_MESSAGE);
      addOptInConfirmationModal.close();
      setIsSaving(false);

      onSuccess?.();

      await mutateOptIns();
    } catch (err) {
      setIsSaving(false);
      addError(ADD_OPT_IN_ERROR_MESSAGE);
    }
  }, [addError, addOptInConfirmationModal, addSuccess, mutateOptIns, onSuccess]);

  const handleDelete = React.useCallback(async () => {
    if (!deleteOptInConfirmationModal.data) return;

    try {
      setIsSaving(true);

      await deleteOptIn(deleteOptInConfirmationModal.data.id!);

      addSuccess(REVOKE_OPT_IN_SUCCESS_MESSAGE);
      deleteOptInConfirmationModal.close();
      setIsSaving(false);

      onSuccess?.();

      await mutateOptIns();
    } catch (err) {
      setIsSaving(false);
      addError(REVOKE_OPT_IN_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, deleteOptInConfirmationModal, mutateOptIns, onSuccess]);

  if (optInsError || areOptInsLoading || !optIns) return null;

  return (
    <>
      {children ? children({
        isSaving,
        label: optIns.length > 0 ? 'Visualizza' : 'Aggiungi',
        update: editOptInsModal.open,
      }) : (
        <Action
          onClick={() => editOptInsModal.open()}
          label={optIns.length > 0 ? 'Visualizza' : 'Aggiungi'}
          size="S"
          loading={isSaving}
          aria-label="Apri modale di modifica consensi"
        />
      )}
      <Portal>
        <SimpleModal
          {...editOptInsModal}
          aria-label="Modifica consensi"
          title="Consensi"
        >
          {!isMobile && (
            <div>
              <SimpleTable>
                <SimpleTable.Header>
                  <SimpleTable.HeaderCell>
                    Consenso
                  </SimpleTable.HeaderCell>
                  <SimpleTable.HeaderCell>
                    Data prestazione
                  </SimpleTable.HeaderCell>
                  <SimpleTable.HeaderCell>
                    Data scadenza
                  </SimpleTable.HeaderCell>
                  <SimpleTable.HeaderCell>
                    Sorgente
                  </SimpleTable.HeaderCell>
                  <SimpleTable.HeaderCell />
                </SimpleTable.Header>

                <SimpleTable.Body>
                  {selectedOptIns.map(({ optIn, selected }) => (
                    <SimpleTable.Row key={optIn.id}>
                      <SimpleTable.Cell>
                        <Text.Body fontWeight={FontWeight.MEDIUM}>
                          {optInTypeLabels[optIn.type].label}
                        </Text.Body>
                      </SimpleTable.Cell>

                      <SimpleTable.Cell>
                        <Text.Body>
                          {optIn.updatedAt ? formatDate(new Date(optIn.updatedAt)) : '-'}
                        </Text.Body>
                      </SimpleTable.Cell>

                      <SimpleTable.Cell>
                        <Text.Body>
                          {optIn.expiresAt ? formatDate(new Date(optIn.expiresAt)) : '-'}
                        </Text.Body>
                      </SimpleTable.Cell>

                      <SimpleTable.Cell>
                        {selected
                          ? (
                            <Badge
                              icon={optInSourceIcon[optIn.sourceType]}
                              label={optIn.sourceValue}
                              size="XS"
                            />
                          )
                          : '-'}
                      </SimpleTable.Cell>

                      <SimpleTable.Cell align="right">
                        {selected ? (
                          <ActionIcon
                            label="Rimuovi"
                            size="S"
                            onClick={onDeleteClick(optIn)}
                            icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                            aria-label={`Pulsante per revocare il consenso al (${optIn.type})`}
                          />
                        ) : (
                          <ActionIcon
                            label="Aggiungi"
                            size="S"
                            onClick={onAddClick(optIn)}
                            icon={{ path: ICON_PLUS }}
                            aria-label={`Pulsante per prestare il consenso al (${optIn.type})`}
                          />
                        )}
                      </SimpleTable.Cell>
                    </SimpleTable.Row>
                  ))}
                </SimpleTable.Body>
              </SimpleTable>
            </div>
          )}

          {isMobile && (
            <Stack gap={150}>
              {selectedOptIns.map(({ optIn, selected }) => (
                <Card
                  key={optIn.type}
                  aria-label={`Informazioni sul consenso ${optIn.type} (Mobile)`}
                >
                  <Card.Header
                    caption="Consenso"
                    title={optInTypeLabels[optIn.type].label}
                    primaryActions={[
                      selected && canDelete && (
                        <ActionIcon
                          label="Rimuovi"
                          size="S"
                          onClick={onDeleteClick(optIn)}
                          icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                          aria-label={`Pulsante per revocare il consenso (${optIn.type})`}
                        />
                      ),
                      !selected && canAdd && (
                        <ActionIcon
                          label="Aggiungi"
                          size="S"
                          onClick={onAddClick(optIn)}
                          icon={{ path: ICON_PLUS }}
                          aria-label={`Pulsante per prestare il consenso (${optIn.type})`}
                        />
                      ),
                    ]}
                  />
                  {selected && (
                    <Card.Content>
                      <Grid gutter={150}>
                        <Grid.Unit size={{ XS: 1 / 2 }}>
                          <Stack>
                            <Text.Mini>
                              DATA PRESTAZIONE
                            </Text.Mini>
                            <Text.Body>
                              {optIn.updatedAt ? formatDate(new Date(optIn.updatedAt)) : '-'}
                            </Text.Body>
                          </Stack>
                        </Grid.Unit>
                        <Grid.Unit size={{ XS: 1 / 2 }}>
                          <Stack>
                            <Text.Mini>
                              DATA SCADENZA
                            </Text.Mini>
                            <Text.Body>
                              {optIn.expiresAt ? formatDate(new Date(optIn.expiresAt)) : '-'}
                            </Text.Body>
                          </Stack>
                        </Grid.Unit>
                        <Grid.Unit size={{ XS: 1 / 2 }}>
                          <Stack>
                            <Text.Mini>
                              SORGENTE
                            </Text.Mini>
                            <div>
                              <Badge
                                icon={optInSourceIcon[optIn.sourceType]}
                                label={optIn.sourceValue}
                                size="XS"
                              />
                            </div>
                          </Stack>
                        </Grid.Unit>
                      </Grid>
                    </Card.Content>
                  )}
                </Card>
              ))}
            </Stack>
          )}
        </SimpleModal>

        {addOptInConfirmationModal.data && (
          <ConfirmModal
            isOpen={addOptInConfirmationModal.isOpen}
            title="Conferma aggiunta consenso"
            aria-label="Modale per confermare l'aggiunta di un consenso"
            onConfirm={handleAddition}
            onAbort={addOptInConfirmationModal.close}
          >
            Conferma l&apos;aggiunta del consenso &quot;{optInTypeLabels[addOptInConfirmationModal.data.type].label}&quot;
          </ConfirmModal>
        )}

        {deleteOptInConfirmationModal.data && (
          <ConfirmModal
            isOpen={deleteOptInConfirmationModal.isOpen}
            title="Conferma revoca consenso"
            aria-label="Modale per confermare la revoca di un consenso"
            onConfirm={handleDelete}
            onAbort={deleteOptInConfirmationModal.close}
          >
            Confermando l&apos;operazione il consenso al &quot;{optInTypeLabels[deleteOptInConfirmationModal.data.type].label}&quot;
            sarà rimosso.
          </ConfirmModal>
        )}
      </Portal>
    </>
  );
};

export default UpdateContactOptInsAction;
