import React from 'react';
import {
  ActionIcon,
  ConfirmModal,
  ICON_CASH_REMOVE,
  Portal, Typography, useModal, useNotifications,
} from '@doveit/bricks';
import { Lead } from '../../../providers/api/dtos';
import { updateLead } from '../../../providers/api/lead/leadProvider';
import { LeadStatus } from '../../../domain/types';

export const ONLY_EVALUATION_LEAD_SUCCESS_MESSAGE = 'La lead è stata aggiornata con successo';
export const ONLY_EVALUATION_LEAD_ERROR_MESSAGE = 'Non è stato possibile aggiornare la lead';

export interface SetLeadOnlyEvaluationActionProps {
  lead: Lead,
  onSuccess?: (updatedLead: Lead) => void,
  disabled?: boolean,
  children?: ({ openEvaluationModal, disabled }: { openEvaluationModal: VoidFunction, disabled?: boolean }) => React.ReactNode
}

export const SetLeadOnlyEvaluationAction: React.FunctionComponent<SetLeadOnlyEvaluationActionProps> = ({
  lead,
  onSuccess,
  disabled,
  children,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const onlyEvaluationModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const isDisabled = React.useMemo(() => disabled || lead.status !== LeadStatus.IN_PROGRESS, [disabled, lead.status]);

  const openEvaluationModal = React.useCallback(() => {
    onlyEvaluationModal.open();
  }, [onlyEvaluationModal]);

  const onAbort = React.useCallback(() => {
    onlyEvaluationModal.close();
  }, [onlyEvaluationModal]);

  const onConfirm = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const updatedLead = await updateLead(lead.id!, {
        ...lead,
        status: LeadStatus.KO_ONLY_EVALUATION,
      });

      setIsSaving(false);
      onlyEvaluationModal.close();
      addSuccess(ONLY_EVALUATION_LEAD_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedLead);
      }
    } catch (err) {
      setIsSaving(false);
      addError(ONLY_EVALUATION_LEAD_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, lead, onSuccess, onlyEvaluationModal]);

  return (
    <>
      {
        children
          ? children({ openEvaluationModal, disabled })
          : (
            <ActionIcon
              icon={{ path: ICON_CASH_REMOVE }}
              label="Solo valutazione"
              aria-label="Pulsante per impostare lo stato della lead a KO_ONLY_EVALUATION"
              disabled={isDisabled}
              onClick={openEvaluationModal}
            />
          )
      }
      <Portal>
        <ConfirmModal
          isOpen={onlyEvaluationModal.isOpen}
          title="Solo valutazione"
          onConfirm={onConfirm}
          onAbort={onAbort}
          saving={isSaving}
          aria-label="Modale per impostare lo stato della lead a KO_ONLY_EVALUATION"
        >
          <Typography.BODY>
            Cliccando OK imposterai lo stato della lead in SOLO VALUTAZIONE.
          </Typography.BODY>

        </ConfirmModal>
      </Portal>
    </>
  );
};
