/* eslint-disable react/no-unknown-property */
import React from 'react';
import { ActionIconDropdown, HStack } from '@doveit/bricks';
import hash from 'object-hash';
import * as styles from './CardHeader.style';
import { useCardContext } from '../CardContext';

export interface CardHeaderProps extends React.PropsWithChildren, React.AriaAttributes {
  primaryActions?: React.ReactNode[],
  secondaryActions?: React.ReactNode[],
  unpadded?: boolean,
}

const CardHeader: React.FC<CardHeaderProps> = ({
  primaryActions = [],
  secondaryActions = [],
  unpadded = false,
  children,
  ...rest
}) => {
  const { isCollapsed, setCollapsed, compact } = useCardContext();

  const onClick = React.useCallback(() => {
    setCollapsed(!isCollapsed);
  }, [isCollapsed, setCollapsed]);

  const sanitizedPrimaryActions = React.useMemo(
    () => primaryActions.filter((item) => React.isValidElement(item)),
    [primaryActions],
  );

  const sanitizedSecondaryActions = React.useMemo(
    () => secondaryActions.filter((item) => React.isValidElement(item)),
    [secondaryActions],
  );

  return (
    <header
      css={styles.base}
      $unpadded={unpadded}
      $compact={compact}
      {...rest}
    >
      <div
        role="button"
        aria-label="collapse-area"
        css={styles.collapseArea}
        onClick={onClick}
        onKeyDown={onClick}
        tabIndex={0}
      />
      {children}
      {(sanitizedPrimaryActions.length > 0 || sanitizedSecondaryActions.length > 0) && (
        <HStack>
          {sanitizedPrimaryActions.length > 0
            ? sanitizedPrimaryActions.map((action, i) => (
              <div key={hash({ action: action?.toString(), i })}>
                {action}
              </div>
            ))
            : undefined}
          {sanitizedSecondaryActions.length > 0 && (
            <ActionIconDropdown
              aria-label="card-header-dropdown-actions"
              label="Azioni"
              size="S"
              emphasis="low"
            >
              {sanitizedSecondaryActions.map((action, i) => (
                <div key={hash({ action: action?.toString(), i })}>
                  {action}
                </div>
              ))}
            </ActionIconDropdown>
          )}
        </HStack>
      )}
    </header>
  );
};

export default CardHeader;
