import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { getProspect } from '../../../providers/api/prospect/prospectProvider';
import { Prospect } from '../../../providers/api/dtos/prospect';

export const PROSPECTS_RID = '/prospects/';

export default function useProspect(
  prospectId?: string,
  options?: SWRConfiguration<Prospect, AxiosError>,
): SWRResponse<Prospect, AxiosError> {
  return useSWR(prospectId ? [PROSPECTS_RID, prospectId] : null, () => getProspect(prospectId!), options);
}
