import { ThemedFunctor, rgba } from '@doveit/bricks';
import { css } from 'styled-components';

export const sellabilityRangeRank: ThemedFunctor = ({ $color, $highlighted }) => css`
  flex: 1 1 0px;
  border-radius: 0.35rem 0.35rem 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: help;
  padding: 4px 0 0;
  text-align: center;

  ${$highlighted && css`
    background-color: ${rgba($color, 0.125)};
  `}

  &::after {
    display: block;
    content: '';
    background-color: ${$color};
    margin-top: 2px;
    width: 100%;
    height: 4px;
  }
`;
