import React from 'react';
import { AppointmentStatus, ProspectStatus, ReferenceType } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Appointment, Prospect } from '../../../providers/api/dtos';
import useContactAppointments from '../../../appointment/hooks/use-contact-appointments/useContactAppointments';
import { PaginatedResult, SortOrder } from '../../../providers/pagination';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';

export type ComputedProspectStatus = Exclude<ProspectStatus, ProspectStatus.IN_PROGRESS | ProspectStatus.CANCELLED> | 'APPOINTMENT' | 'APPOINTMENT_CANCELLED' | 'NO_STATUS';

export type ComputedProspectStatusCanBeUpdatedTo =
  ComputedProspectStatus
  | 'ARCHIVED'
  | 'RESTORED';

export type UseComputedProspectStatusData = {
  status: ComputedProspectStatus,
  canBeUpdated: boolean,
  canBeUpdatedTo: ComputedProspectStatusCanBeUpdatedTo[],
};

type UseComputedProspectStatusResult = {
  data?: UseComputedProspectStatusData | undefined,
  isLoading: boolean,
  mutate: () => Promise<any>,
  errors?: Error[] | undefined,
};

const allowedStatusUpdates: (computedProspectStatus: ComputedProspectStatus, appointments?: Appointment[]) => ComputedProspectStatusCanBeUpdatedTo[] = (computedProspectStatus, appointments) => {
  const basicAllowedStatusUpdates: Record<ComputedProspectStatus, ComputedProspectStatusCanBeUpdatedTo[]> = {
    NO_STATUS: [],
    APPOINTMENT: ['APPOINTMENT_CANCELLED', ProspectStatus.ASSIGNMENT_CREATED, 'ARCHIVED'],
    [ProspectStatus.ONLY_EVALUATION]: ['RESTORED'],
    APPOINTMENT_CANCELLED: ['APPOINTMENT', 'ARCHIVED'],
    [ProspectStatus.ASSIGNMENT_CREATED]: [],
    [ProspectStatus.NOT_INTERESTED]: ['RESTORED'],
    [ProspectStatus.OVER_PRICED]: ['RESTORED'],
    [ProspectStatus.UNSELLABLE]: [],
    [ProspectStatus.SOLD]: [],
  };

  let canBeUpdatedToResult: ComputedProspectStatusCanBeUpdatedTo[] = basicAllowedStatusUpdates[computedProspectStatus];

  if (computedProspectStatus === 'APPOINTMENT' && (
    // If the last appointment has been already closed
    appointments?.[0].status !== AppointmentStatus.TODO
    // or there is at least a DONE appointment...
    || appointments?.some((appointment) => appointment.status === AppointmentStatus.DONE)
  )) {
    // ...we are going to remove from the canBeUpdatedTo status array the 'APPOINTMENT_CANCELLED' option,
    // because it doesn't move to a different status
    canBeUpdatedToResult = canBeUpdatedToResult.filter((status) => status !== 'APPOINTMENT_CANCELLED');
  }
  return canBeUpdatedToResult;
};

const computeStatus = (status: ProspectStatus, appointments?: PaginatedResult<Appointment>) => {
  switch (status) {
    case ProspectStatus.IN_PROGRESS: {
      const allAppointmentsAreCancelled = appointments?.content.every((appointment) => appointment.status === AppointmentStatus.CANCELLED) ?? false;

      if (allAppointmentsAreCancelled) {
        return 'APPOINTMENT_CANCELLED';
      }
      return 'APPOINTMENT';
    }

    case ProspectStatus.ONLY_EVALUATION:
      return ProspectStatus.ONLY_EVALUATION;

    case ProspectStatus.ASSIGNMENT_CREATED:
      return ProspectStatus.ASSIGNMENT_CREATED;

    case ProspectStatus.NOT_INTERESTED:
    case ProspectStatus.CANCELLED:
      return ProspectStatus.NOT_INTERESTED;

    case ProspectStatus.OVER_PRICED:
      return ProspectStatus.OVER_PRICED;

    case ProspectStatus.UNSELLABLE:
      return ProspectStatus.UNSELLABLE;

    case ProspectStatus.SOLD:
      return ProspectStatus.SOLD;
    default:
      return 'NO_STATUS';
  }
};

export const useComputedProspectStatus = (prospect: Prospect): UseComputedProspectStatusResult => {
  const { userIsAdmin, userIsCallCenter } = useRBAC();
  const isProspectCreatorOrManager = useCurrentAgentIsSameAgentOrManager(prospect.agentId);

  const {
    data: appointments,
    isLoading: isContactAppointmentsLoading,
    mutate: mutateContactAppointments,
    error: contactAppointmentsError,
  } = useContactAppointments(
    prospect.contactId,
    { referenceId: prospect.id!, referenceType: ReferenceType.PROSPECT },
    { sort: { createdAt: SortOrder.DESC } },
  );

  return React.useMemo(() => {
    const computedStatus: ComputedProspectStatus = computeStatus(prospect.status, appointments);
    let canBeUpdatedTo: ComputedProspectStatusCanBeUpdatedTo[] = [];

    if (!isContactAppointmentsLoading && (userIsAdmin || userIsCallCenter || isProspectCreatorOrManager)) {
      canBeUpdatedTo = allowedStatusUpdates(computedStatus, appointments?.content);
    }
    return {
      data: (!contactAppointmentsError && !isContactAppointmentsLoading) ? {
        status: computedStatus,
        canBeUpdated: canBeUpdatedTo.length > 0,
        canBeUpdatedTo,
      } : undefined,
      isLoading: isContactAppointmentsLoading,
      errors: contactAppointmentsError ? [contactAppointmentsError] : undefined,
      mutate: mutateContactAppointments,
    };
  }, [appointments, contactAppointmentsError, isContactAppointmentsLoading, isProspectCreatorOrManager, mutateContactAppointments, prospect.status, userIsAdmin, userIsCallCenter]);
};
