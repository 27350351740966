export enum MainRoomType {
  STANZA_DA_LETTO = 'STANZA_DA_LETTO',
  SOGGIORNO = 'SOGGIORNO',
  CUCINA = 'CUCINA',
  BAGNO = 'BAGNO',
  RIPOSTIGLIO = 'RIPOSTIGLIO',
  CORRIDOIO = 'CORRIDOIO',
  MANSARDA = 'MANSARDA',
  TAVERNA = 'TAVERNA',
  ALTRO = 'ALTRO',
}

export enum KitchenType {
  CUCINA = 'CUCINA',
  ANGOLO_COTTURA = 'ANGOLO_COTTURA',
  CUCINOTTO = 'CUCINOTTO',
  CUCINA_SEMI_ABITABILE = 'CUCINA_SEMI_ABITABILE',
  CUCINA_A_VISTA = 'CUCINA_A_VISTA',
}

export const RoomType = {
  ...MainRoomType,
  ...KitchenType,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RoomType = MainRoomType | KitchenType;

export type SubRoomType = Exclude<RoomType, MainRoomType>;
