/* eslint-disable react/no-unknown-property */
import {
  ActionIcon,
  ICON_BELL_OUTLINE, Popover, Card, Dropdown, FontWeight, HStack, Icon, ICON_EYE_OUTLINE, Stack, Text, useNotifications, UserPreview,
} from '@doveit/bricks';
import React from 'react';
import { isFuture } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { ReferenceType, RumorStatus } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import {
  Note, Reminder, SerpRumor, Rumor,
} from '../../../providers/api/dtos';
import { goToDetailPage } from '../../../utils/navigate/utils';
import * as styles from './ViewSerpRumorCard.style';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import ViewContactPopoverPreview from '../../../contact/containers/view-contact-popover-preview/ViewContactPopoverPreview';
import UpsertReminderAction from '../../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import UpsertNoteAction from '../../../notes/containers/UpsertNoteAction';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import { buildWhatsappMessageSentNote } from '../../../intent/utils/buildWhatsappMessageSentNote';
import { updateRumor } from '../../../providers/api/rumor/rumorProvider';
import { rumorMessagesProvider } from '../../../containers/send-whatsapp-action/messages/rumorMessagesProvider';
import ViewRumorPopoverPreview from '../view-rumor-popover-preview/ViewRumorPopoverPreview';
import RumorAvatar from '../../components/rumor-avatar/RumorAvatar';
import UpdateRumorStatus from '../update-rumor-status/UpdateRumorStatus';
import { computeResolvableStatus } from '../../../utils/reminder/reminder';
import { ResolvableReminderStatus } from '../../../types';
import { reminderDateColor } from '../../utils';

export const UPSERT_NOTE_ERROR_MESSAGE = 'Non è stato possibile aggiungere la nota.';
export const UPSERT_NOTE_SUCCESS_MESSAGE = 'Nota aggiunta con successo.';

export interface ViewSerpRumorCardProps extends React.AriaAttributes {
  serpRumor: SerpRumor,
  onRumorUpdate?: (rumor: Rumor) => void,
  onReminderCreate?: (reminder: Reminder) => void,
}

const ViewSerpRumorCard: React.FC<ViewSerpRumorCardProps> = ({
  serpRumor,
  onRumorUpdate,
  onReminderCreate,
  ...rest
}) => {
  const {
    rumor, agent, contact, reminder,
  } = serpRumor;
  const { user, mainUserRole } = useRBAC();
  const { addSuccess, addError } = useNotifications();

  const reminderToCreate: Reminder = React.useMemo(() => ({
    agentId: agent.id,
    referenceId: rumor.id,
    referenceType: ReferenceType.RUMOR,
    resolved: false,
  } as Reminder), [agent, rumor.id]);

  const goToRumorPage = React.useCallback(() => {
    goToDetailPage(rumor.id!, ReferenceType.RUMOR);
  }, [rumor.id]);

  const reminderStatus = React.useMemo(() => (reminder ? computeResolvableStatus(reminder) : undefined), [reminder]);

  const showReminder = React.useMemo(
    () => reminderStatus && [ResolvableReminderStatus.ACTIVE, ResolvableReminderStatus.EXPIRED].includes(reminderStatus),
    [reminderStatus],
  );

  const handleNoteCreated = React.useCallback(async (note: Note) => {
    if (!rumor) return;

    try {
      const updatedRumor = await updateRumor({
        ...rumor,
        notes: [note, ...(rumor.notes || [])],
      });

      onRumorUpdate?.(updatedRumor);
      addSuccess(UPSERT_NOTE_SUCCESS_MESSAGE);
    } catch (err) {
      addError(UPSERT_NOTE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, onRumorUpdate, rumor]);

  const onMessageSent = React.useCallback(async (message: string) => {
    if (!rumor) return;

    try {
      const updatedRumor = await updateRumor({
        ...rumor,
        notes: [{
          text: buildWhatsappMessageSentNote(message),
          author: user.name,
          role: mainUserRole,
        }, ...(rumor.notes || [])],
      });
      onRumorUpdate?.(updatedRumor);
      // eslint-disable-next-line no-empty
    } catch (error) { }
  }, [mainUserRole, onRumorUpdate, rumor, user.name]);

  const goToProspectPage = React.useCallback(
    () => rumor?.prospectId && goToDetailPage(rumor.prospectId, ReferenceType.PROSPECT),
    [rumor],
  );

  return (
    <Card {...rest}>
      <Card.Header
        primaryActions={[(
          <ActionIcon
            aria-label="Visualizza notizia"
            label="Visualizza"
            size="S"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToRumorPage}
          />
        )]}
        secondaryActions={[
          rumor.prospectId && (
            <Dropdown.Option
              aria-label="Pulsante per visualizzare la valutazione"
              label="Visualizza valutazione"
              onClick={goToProspectPage}
            />
          ),
          contact?.phoneNumber && parsePhoneNumber(contact.phoneNumber, 'IT').getType() === 'MOBILE' && (
            <SendWhatsappAction
              phoneNumber={contact.phoneNumber}
              name={contact.name}
              fetchMessages={rumorMessagesProvider(agent)}
              onMessageSent={onMessageSent}
            >
              {({ onClick }) => (
                <Dropdown.Option
                  label="Invia whatsapp"
                  onClick={onClick}
                />
              )}
            </SendWhatsappAction>
          ),
          rumor.status === RumorStatus.IN_PROGRESS && (
            <UpsertReminderAction
              reminder={reminderToCreate}
              onSuccess={onReminderCreate}
              aria-label="Pulsante per aggiungere un promemoria"
            >
              {({ upsert }) => (
                <Dropdown.Option
                  label="Aggiungi promemoria"
                  onClick={upsert}
                />
              )}
            </UpsertReminderAction>
          ),
          <UpsertNoteAction
            upsertNote={handleNoteCreated}
            aria-label="Pulsante per aggiungere una nota"
          >
            {({ upsert }) => (
              <Dropdown.Option
                label="Aggiungi nota"
                onClick={upsert}
              />
            )}
          </UpsertNoteAction>,
        ]}
      >
        <ViewRumorPopoverPreview
          rumorId={rumor.id!}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <RumorAvatar size="S" />
              <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                {rumor.propertyAddress || 'Non presente'}
              </Text.BodySmall>
            </HStack>
          )}
        />
      </Card.Header>
      <Card.Content aria-label="Informazioni notizia">
        <Stack gap={150}>
          <div>
            <UpdateRumorStatus
              rumor={rumor}
              size="XS"
              onSuccess={onRumorUpdate}
            />
          </div>

          <HStack gap={150}>
            <HStack
              gap={100}
              wrap="nowrap"
              css={styles.firstRowWrapper}
            >
              {contact && (
                <ViewContactPopoverPreview
                  contact={contact}
                  trigger={(
                    <UserPreview
                      name={contact.name || 'Contatto'}
                      size="S"
                    >
                      {contact.phoneNumber || contact.email}
                    </UserPreview>
                  )}
                />
              )}
              {!contact && (
                <UserPreview
                  name="Non presente"
                  size="S"
                />
              )}
              <ViewAgentPopoverPreview
                agent={agent}
                trigger={(
                  <AgentPreview
                    {...toAgentPreviewProps(agent, { omit: { name: true } })}
                    size="S"
                  />
                )}
              />
            </HStack>

            {showReminder && (
              <HStack
                gap={150}
                css={styles.secondRowWrapper}
                aria-label="Dettagli promemoria"
              >
                <Popover
                  aria-label="Visualizza dettagli promemoria"
                  trigger={(
                    <HStack wrap="nowrap">
                      <Icon path={ICON_BELL_OUTLINE} size={20} />
                      <div css={styles.dateWrapper}>
                        <Stack gap={25}>
                          <Text.Mini transform="uppercase">
                            Data promemoria
                          </Text.Mini>
                          <Text.Caption fontWeight={FontWeight.REGULAR} color={reminderDateColor(reminderStatus!)}>
                            <DateViewer
                              stringDate={serpRumor.reminder!.startDate}
                              checkIfToday
                              checkIfTomorrow
                              withHour={isFuture(serpRumor.reminder!.startDate)}
                            />
                          </Text.Caption>
                        </Stack>
                      </div>
                    </HStack>
                  )}
                  hasArrow
                >
                  <div css={styles.noteAndReminderPopoverWrapper}>
                    <Text.BodySmall>{serpRumor.reminder!.notes}</Text.BodySmall>
                  </div>
                </Popover>
              </HStack>
            )}
            <Text.BodySmall aria-label="Descrizione notizia">
              {rumor.description}
            </Text.BodySmall>
          </HStack>
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ViewSerpRumorCard;
