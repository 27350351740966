import {
  Message, ICON_ALERT_CIRCLE_OUTLINE, Card, CardSkeleton, ExpandableContent, ICON_PIN_OUTLINE,
  Dropdown,
} from '@doveit/bricks';
import React from 'react';
import { Property } from '../../../providers/api/dtos';
import useProperty from '../../hooks/use-property/useProperty';
import UpsertPropertyNotesAction from '../upsert-property-notes-action/UpsertPropertyNotesAction';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { splitTextToNearestString } from '../../../utils/text/text';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';

export const LOAD_PROPERTY_ERROR_MESSAGE = "Non è stato possibile caricare i dati dell'immobile.";
export const EMPTY_PROPERTY_NOTES_MESSAGE = 'Nessun appunto presente';

export type PropertyNotesWidgetProps = React.AriaAttributes & ({
  propertyId: NonNullable<Property['id']>,
});

const PropertyNotesWidget: React.FC<PropertyNotesWidgetProps> = ({
  propertyId,
}) => {
  const isMobile = useIsDevice('mobile');
  const { userIsAdmin } = useRBAC();
  const { data: propertyAgent } = useAgentByPropertyId(propertyId);
  const isPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(propertyAgent?.id);

  const {
    data: property,
    isLoading: isPropertyLoading,
    error: propertyError,
    mutate: mutateProperty,
  } = useProperty(propertyId);

  const propertyNotes = React.useMemo(
    () => splitTextToNearestString(property?.notes, '.', 300),
    [property?.notes],
  );

  const showNotes = React.useMemo(() => (userIsAdmin || isPropertyAgentOrManager), [userIsAdmin, isPropertyAgentOrManager]);

  const refreshProperty = React.useCallback(() => {
    mutateProperty();
  }, [mutateProperty]);

  if (!showNotes) {
    return null;
  }

  if (isPropertyLoading) {
    return <CardSkeleton aria-label="Dati immobile in caricamento" />;
  }

  if (propertyError) {
    return (
      <Card aria-label="Errore nel caricamento dei dati dell'immobile">
        <Card.Header
          title="Descrizioni"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          color="critical"
        />
        <Card.Content>
          <Message
            message={LOAD_PROPERTY_ERROR_MESSAGE}
          />
        </Card.Content>
      </Card>
    );
  }

  return property && (
    <Card aria-label="Sezione appunti">
      <Card.Header
        title="Appunti personali"
        icon={{ path: ICON_PIN_OUTLINE }}
        primaryActions={[
          !isMobile && isPropertyAgentOrManager && (
            <UpsertPropertyNotesAction
              property={property}
              onSuccess={refreshProperty}
            />
          )]}
        secondaryActions={[
          isMobile && isPropertyAgentOrManager && (
            <UpsertPropertyNotesAction
              property={property}
              onSuccess={refreshProperty}
            >
              {({ openUpsertPropertyNotesModal }) => (
                <Dropdown.Option
                  label="Modifica"
                  aria-label="Apri modale di modifica descrizioni"
                  onClick={openUpsertPropertyNotesModal}
                />
              )}
            </UpsertPropertyNotesAction>
          ),
        ]}
      />
      <Card.Content>
        {propertyNotes.length === 0 && (
          <Message message={EMPTY_PROPERTY_NOTES_MESSAGE} />
        )}
        {propertyNotes.length === 1 && propertyNotes[0]}
        {propertyNotes.length === 2 && (
          <>
            {propertyNotes[0]}
            <ExpandableContent
              aria-label="expandable-content"
              openLabel="Mostra altro"
            >
              {propertyNotes[1]}
            </ExpandableContent>
          </>
        )}
      </Card.Content>
    </Card>
  );
};

export default PropertyNotesWidget;
