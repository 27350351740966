import { ComputedProspectStatus } from '../prospect/hooks/use-computed-prospect-status/useComputedProspectStatus';

export const computedProspectStatusLabels: Record<ComputedProspectStatus, string> = {
  APPOINTMENT: 'Appuntamento',
  ONLY_EVALUATION: 'Solo valutazione',
  APPOINTMENT_CANCELLED: 'App. annullato',
  ASSIGNMENT_CREATED: 'Mandato',
  NOT_INTERESTED: 'Non interessato',
  OVER_PRICED: 'Sovrapprezzo',
  UNSELLABLE: 'Invendibile',
  SOLD: 'Venduto da altri',
  NO_STATUS: '',
};
