import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { getLatestShootingByPropertyId } from '../../../providers/api/shooting/shootingProvider';
import { Shooting } from '../../../providers/api/dtos/shooting';

export const SHOOTING_BY_PROPERTY_ID_RID = '/property/:id/shooting';

export default function useLatestShootingByPropertyId(
  propertyId?: number | null | undefined,
  options?: SWRConfiguration<Shooting, AxiosError>,
): SWRResponse<Shooting, AxiosError> {
  const key = propertyId ? [SHOOTING_BY_PROPERTY_ID_RID, propertyId] : null;

  return useSWR(key, () => getLatestShootingByPropertyId(propertyId!), options);
}
