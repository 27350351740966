import { TextColor } from '@doveit/bricks';
import { ResolvableReminderStatus } from '../types';

export function reminderDateColor(resolvableReminderStatus: ResolvableReminderStatus): TextColor {
  switch (resolvableReminderStatus) {
    case ResolvableReminderStatus.EXPIRED:
      return 'critical.default.low';
    default:
      return 'neutral.default.low';
  }
}
