import React from 'react';
import {
  Action, BreakpointQueryName, DetailItemList, FormHandlers, ICON_ACCOUNT_OUTLINE, ICON_EYE_OUTLINE, ICON_PHONE_OUTLINE, Message, Spacing, Stack,
} from '@doveit/bricks';
import objectHash from 'object-hash';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { OfferWizardStepProps } from '../types';
import { raise } from '../../../../utils';
import { useOfferWizardPDFPreview } from '../../../hooks/use-offer-wizard-pdf-preview/useOfferWizardPDFPreview';
import CardSkeleton from '../../../../components/card/skeleton/CardSkeleton';
import PDFViewer from '../../../../components/pdf-viewer/PDFViewer';

export const NO_SIGNERS_INFO_MESSAGE = 'Non sono presenti firmatari';
export const LOAD_PDF_PREVIEW_ERROR_MESSAGE = "Non è stato possibile caricare l'anteprima della proposta";

const OfferWizardDigitalRequest: React.FC<OfferWizardStepProps> = ({
  initialData,
  intent,
  formRef,
  onSubmit,
  handleStepWorkComplete,
}) => {
  const { buyerContacts = [] } = initialData;
  const { id } = intent;
  const intentId = id ?? raise('missing intent id');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  const {
    data: preview,
    isLoading: isPreviewLoading,
    error: previewError,
  } = useOfferWizardPDFPreview(intentId, { onSuccess: handleStepWorkComplete });

  const previewUrl = React.useMemo(() => {
    if (!preview) {
      return '';
    }

    return URL.createObjectURL(new Blob([preview], { type: 'application/pdf' }));
  }, [preview]);

  React.useImperativeHandle(formRef, () => ({
    handleSubmit: (_: React.FormEvent<HTMLFormElement>) => {
      onSubmit({
        ...initialData,
        isDigitallySigned: true,
      }, false);
    },
  } as FormHandlers), [initialData, onSubmit]);

  return (
    <Stack>
      <div aria-label="Firmatari della proposta">
        <strong>Elenco firmatari</strong>

        <Spacing margin={[200, 0, 0, 0]}>
          {buyerContacts.length === 0 && (
            <Message message={NO_SIGNERS_INFO_MESSAGE} />
          )}
          {buyerContacts.length > 0 && (
            <Stack aria-label="Elenco dei firmatari della proposta">
              {buyerContacts.map((contact) => (
                <Spacing
                  key={objectHash(contact)}
                  margin={[0, 0, 200, 0]}
                >
                  <DetailItemList>
                    <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
                      {contact.name}
                    </DetailItemList.Item>
                    <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
                      {contact.phoneNumber}
                    </DetailItemList.Item>
                  </DetailItemList>
                </Spacing>
              ))}
            </Stack>
          )}
        </Spacing>
      </div>

      <Spacing margin={[200, 0, 0, 0]}>
        {previewError && (
          <Message
            type="critical"
            message={LOAD_PDF_PREVIEW_ERROR_MESSAGE}
          />
        )}
        {isPreviewLoading && <CardSkeleton aria-label="Anteprima della proposta in caricamento" />}
        {preview && (
          <div aria-label="Anteprima della proposta">
            <strong>Anteprima proposta</strong>

            <Spacing margin={[200, 0, 0, 0]}>

              {(isMobile ? (
                <Action
                  label="Visualizza anteprima"
                  aria-label="Visualizza anteprima proposta"
                  size="S"
                  iconLeft={{ path: ICON_EYE_OUTLINE }}
                  href={previewUrl}
                  target="_blank"
                />
              ) : (
                <PDFViewer
                  src={previewUrl}
                  title="Anteprima proposta"
                  height="50rem"
                />
              ))}
            </Spacing>
          </div>
        )}
      </Spacing>
    </Stack>
  );
};

export default OfferWizardDigitalRequest;
