/* eslint-disable react/no-unknown-property */
import React from 'react';
import { formatEuroRange, formatSquareMetersRange } from '@doveit/hammer';
import {
  Badge,
  FontWeight, HStack, ICON_CHECK, Message, Popover, PopoverProps, Spacing, Stack, Text,
} from '@doveit/bricks';
import PopoverPreview from '../../../components/popover-preview/PopoverPreview';
import * as styles from './ViewSearchCriteriaPopoverPreview.style';
import { ReferenceType } from '../../../domain/types';
import PopoverPreviewSkeleton from '../../../components/popover-preview/PopoverPreview.skeleton';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { SearchCriteria } from '../../../providers/api/dtos';
import useSearchCriteria from '../../hooks/use-search-criteria/useSearchCriteria';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import { residentialPropertyTypeLabels } from '../../../labels';
import { getGeoCriterionLabel } from '../../utils/getGeoCriterionLabel';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import SearchCriteriaAvatar from '../../component/search-criteria-avatar/SearchCriteriaAvatar';
import { featureTypeLabels } from '../../../property/labels';

interface BaseViewSearchCriteriaPopoverPreviewProps extends React.AriaAttributes {
  trigger: PopoverProps['trigger'],
  hideAction?: boolean,
}

export type ViewSearchCriteriaPopoverPreviewProps = BaseViewSearchCriteriaPopoverPreviewProps & ({
  searchCriteriaId: NonNullable<SearchCriteria['id']>,
  searchCriteria?: undefined,
} | {
  searchCriteria: SearchCriteria,
  searchCriteriaId?: undefined,
});

export const LOAD_SEARCH_CRITERIA_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni della ricerca';

const ViewSearchCriteriaPopoverPreview: React.FC<ViewSearchCriteriaPopoverPreviewProps> = ({
  searchCriteriaId,
  searchCriteria: externalSearchCriteria,
  trigger,
  hideAction = false,
  ...rest
}) => {
  const {
    data: loadedSearchCriteria,
    isLoading: isSearchCriteriaLoading,
    error: searchCriteriaError,
  } = useSearchCriteria(searchCriteriaId);

  const searchCriteria = externalSearchCriteria || loadedSearchCriteria;

  const infos = React.useMemo(() => ([{
    label: 'Superficie',
    value: formatSquareMetersRange(searchCriteria?.minSize, searchCriteria?.maxSize) || NO_VALUE_SYMBOL,
  }, {
    label: 'Budget',
    value: formatEuroRange(searchCriteria?.minPrice, searchCriteria?.maxPrice) || NO_VALUE_SYMBOL,
  }]), [searchCriteria?.maxPrice, searchCriteria?.maxSize, searchCriteria?.minPrice, searchCriteria?.minSize]);

  const features = React.useMemo(() => {
    if (!searchCriteria) return [];

    const baseFeatures = searchCriteria.features.map((feature) => featureTypeLabels[feature]);

    if (searchCriteria.privateGarden) baseFeatures.push('Giardino');
    if (searchCriteria.garage) baseFeatures.push('Box');
    if (searchCriteria.balcony) baseFeatures.push('Balcone');
    if (searchCriteria.terrace) baseFeatures.push('Terrazzo');

    return baseFeatures;
  }, [searchCriteria]);

  const propertyTypes = React.useMemo(() => searchCriteria?.propertyTypes
    .map((propertyType) => residentialPropertyTypeLabels[propertyType])
    .join(', '), [searchCriteria?.propertyTypes]);

  const goToSearchCriteriaPage = React.useCallback(
    () => goToDetailPage(searchCriteria?.id!, ReferenceType.SEARCH_CRITERIA),
    [searchCriteria?.id],
  );

  return (
    <Popover
      trigger={trigger}
      hasArrow
      {...rest}
    >
      <div css={styles.wrapper}>
        {isSearchCriteriaLoading && <PopoverPreviewSkeleton aria-label="Caricamento informazioni ricerca in corso" />}

        {searchCriteriaError && (
          <Spacing padding={200}>
            <Message
              type="critical"
              message={LOAD_SEARCH_CRITERIA_DATA_ERROR_MESSAGE}
            />
          </Spacing>
        )}

        {searchCriteria && (
          <PopoverPreview
            aria-label="Informazioni ricerca"
            title={truncateTextWithSuffix(searchCriteria.geo.map(getGeoCriterionLabel).join(', '), 60)}
            avatar={<SearchCriteriaAvatar size="L" />}
            action={!hideAction ? {
              label: 'Visualizza ricerca',
              'aria-label': 'Vai alla pagina della ricerca',
              onClick: goToSearchCriteriaPage,
            } : undefined}
          >
            <Stack gap={150}>
              {propertyTypes && <Text.Body fontWeight={FontWeight.MEDIUM}>{propertyTypes}</Text.Body>}
              {infos.length > 0 && (
                <div css={styles.features}>
                  {infos.map(({ value, label }) => (
                    <div
                      key={`${label}-${value}`}
                      css={styles.feature}
                    >
                      <Text.BodySmall
                        fontWeight={FontWeight.MEDIUM}
                        lineHeight="1"
                        as="div"
                      >
                        {value}
                      </Text.BodySmall>
                      <Text.Mini
                        transform="uppercase"
                        as="div"
                      >
                        {label}
                      </Text.Mini>
                    </div>
                  ))}
                </div>
              )}
              {features?.length > 0 && (
                <HStack>
                  {features.map((feature) => (
                    <Badge
                      key={feature}
                      icon={ICON_CHECK}
                      label={feature}
                      size="XS"
                    />
                  ))}
                </HStack>
              )}
            </Stack>
          </PopoverPreview>
        )}
      </div>
    </Popover>
  );
};

export default ViewSearchCriteriaPopoverPreview;
