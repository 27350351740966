import useSWR, { SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Invoice } from '../../../providers/api/dtos/invoice';
import { getInvoiceByPropertyId } from '../../../providers/api/invoice/invoiceProvider';

export const INVOICE_BY_PROPERTY_RID = '/properties/invoice/';

export default function useInvoiceByProperty(propertyId?: number): SWRResponse<Invoice, AxiosError> {
  return useSWR(propertyId ? [INVOICE_BY_PROPERTY_RID, propertyId] : null, () => getInvoiceByPropertyId(propertyId!));
}
