import React from 'react';
import { Form, FormProps } from '@doveit/bricks';
import { BooleanString, booleanStringToBool } from '../../../../utils/boolean-string/booleanString';
import { PASS_THROUGH_VALIDATION_SCHEMA } from '../../../../constants';

export interface OfferWizardAntiMoneyLaunderingFormProps {
  initialValues?: Partial<OfferWizardAntiMoneyLaunderingFormModel>,
  loading?: FormProps<OfferWizardAntiMoneyLaunderingFormModel>['loading'],
  disabled?: FormProps<OfferWizardAntiMoneyLaunderingFormModel>['disabled'],
  formRef?: FormProps<OfferWizardAntiMoneyLaunderingFormModel>['innerRef'],
  onSubmit: FormProps<OfferWizardAntiMoneyLaunderingFormModel>['onSubmit']
}

export interface OfferWizardAntiMoneyLaunderingFormModel {
  includeAntiMoneyLaundering: BooleanString,
  politicallyExposed: BooleanString,
  relatedToAPoliticallyExposedFigure: BooleanString,
  mortgageMoneySource: boolean,
  personalMoneySource: boolean,
  sellingPersonalPropertiesMoneySource: boolean,
  otherMoneySource: boolean,
  otherMoneySourceValue?: string,
  notes?: string,
}

const DEFAULT_INITIAL_VALUES: OfferWizardAntiMoneyLaunderingFormModel = {
  includeAntiMoneyLaundering: 'true',
  politicallyExposed: '',
  relatedToAPoliticallyExposedFigure: '',
  mortgageMoneySource: false,
  personalMoneySource: false,
  sellingPersonalPropertiesMoneySource: false,
  otherMoneySource: false,
  otherMoneySourceValue: '',
  notes: '',
};

const OfferWizardAntiMoneyLaunderingForm: React.FC<OfferWizardAntiMoneyLaunderingFormProps> = ({
  initialValues,
  disabled,
  formRef,
  onSubmit,
}) => {
  const mergedInitialValues = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  const [excludeForm, setExcludeForm] = React.useState(!booleanStringToBool(mergedInitialValues.includeAntiMoneyLaundering));

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      innerRef={formRef}
      disabled={disabled}
      validationSchema={PASS_THROUGH_VALIDATION_SCHEMA}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const onExcludeAntiMoneyLaunderingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value === 'true') {
            setExcludeForm(false);
          } else {
            setExcludeForm(true);

            setFieldValue('politicallyExposed', '');
            setFieldValue('relatedToAPoliticallyExposedFigure', '');
            setFieldValue('mortgageMoneySource', '');
            setFieldValue('personalMoneySource', '');
            setFieldValue('sellingPersonalPropertiesMoneySource', '');
            setFieldValue('otherMoneySource', '');
            setFieldValue('otherMoneySourceValue', '');
            setFieldValue('notes', '');
          }
        };

        const onOtherMoneySourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.checked) {
            setFieldValue('otherMoneySourceValue', '');
          }
        };

        return (
          <>
            <Form.Item>
              <Form.YesOrNotButtons
                aria-label="Campo per compilare i dati di antiriciclaggio"
                label="Compila antiriciclaggio"
                name="includeAntiMoneyLaundering"
                onChange={onExcludeAntiMoneyLaunderingChange}
              />
            </Form.Item>
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.YesOrNotButtons
                aria-label="Campo per indicare se la persona è politicamente esposta"
                label="Persona politicamente esposta"
                name="politicallyExposed"
                disabled={excludeForm}
              />
            </Form.Item>
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.YesOrNotButtons
                aria-label="Campo per indicare se parente o convivente di persona politicamente esposta"
                label="Parente o convivente di persona politicamente esposta"
                name="relatedToAPoliticallyExposedFigure"
                disabled={excludeForm}
              />
            </Form.Item>

            <Form.Fieldset legend="Provenienza del denaro">
              <Form.Item>
                <Form.Checkbox
                  aria-label="Campo per indicare se il denaro è proveniente da mutuo"
                  name="mortgageMoneySource"
                  text="Mutuo"
                  disabled={excludeForm}
                />
              </Form.Item>
              <Form.Item>
                <Form.Checkbox
                  aria-label="Campo per indicare se il denaro è proveniente da redditi o risparmi propri"
                  name="personalMoneySource"
                  text="Redditi o risparmi propri"
                  disabled={excludeForm}
                />
              </Form.Item>
              <Form.Item>
                <Form.Checkbox
                  aria-label="Campo per indicare se il denaro è proveniente dalla vendita di beni propri"
                  name="sellingPersonalPropertiesMoneySource"
                  text="Ricavato dalla vendita di beni propri"
                  disabled={excludeForm}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Checkbox
                  aria-label="Campo per indicare se il denaro è proveniente da altre sorgenti"
                  name="otherMoneySource"
                  text="Altro"
                  disabled={excludeForm}
                  onChange={onOtherMoneySourceChange}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  aria-label="Campo per indicare altre sorgenti di denaro"
                  name="otherMoneySourceValue"
                  disabled={!values.otherMoneySource || excludeForm}
                  label="Specificare"
                />
              </Form.Item>
            </Form.Fieldset>
            <Form.Item>
              <Form.TextArea
                name="notes"
                label="Osservazioni e note"
                rows={5}
                disabled={excludeForm}
              />
            </Form.Item>
          </>
        );
      }}
    </Form>
  );
};

export default OfferWizardAntiMoneyLaunderingForm;
