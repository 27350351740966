import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemedFunctor } from '@doveit/bricks';
import App from './App';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface DOMAttributes<T> {
    [key: string]: any;
    css?: ThemedFunctor;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
