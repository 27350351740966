import hash from 'object-hash';
import useSWR from 'swr';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { AgentSearchParams, searchAgents, GetAgentOptions } from '../../../providers/api/agent/agentProvider';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';
import { Agent } from '../../../providers/api/dtos';

export const SEARCH_AGENTS_RID = '/agents/search';

export default function useSearchAgents(
  params?: AgentSearchParams,
  pagination?: PaginationParams,
  options: GetAgentOptions = {},
): SWRCustomResponse<PaginatedResult<Agent>, Error> {
  const matchMutate = useMatchMutate();
  const key = params ? [SEARCH_AGENTS_RID, hash({ ...params, ...pagination, ...options })] : null;
  const result = useSWR(key, () => searchAgents(params!, pagination, options));

  return {
    ...result,
    mutate: () => matchMutate(SEARCH_AGENTS_RID),
  };
}
