import { formatSquareMeters } from '@doveit/hammer';
import { SellabilityRange } from '../domain/types';

export const sellabilityRangeLabel = (
  range: SellabilityRange,
  options: { withUnit?: boolean } = {},
): string => {
  const { withUnit = false } = options;

  const map: Record<SellabilityRange, string> = {
    [SellabilityRange.UP_TO_50_MQ]: `< ${withUnit ? formatSquareMeters(50) : 50}`,
    [SellabilityRange.BETWEEN_50_AND_85_MQ]: `50-${withUnit ? formatSquareMeters(85) : 85}`,
    [SellabilityRange.BETWEEN_85_AND_115_MQ]: `85-${withUnit ? formatSquareMeters(115) : 115}`,
    [SellabilityRange.BETWEEN_115_AND_145_MQ]: `115-${withUnit ? formatSquareMeters(145) : 145}`,
    [SellabilityRange.OVER_145_MQ]: `> ${withUnit ? formatSquareMeters(145) : 145}`,
  };

  return map[range];
};
