/* eslint-disable import/no-duplicates */
import { SelectOption } from '@doveit/bricks';
import {
  addMonths, eachMonthOfInterval, format, lastDayOfMonth,
} from 'date-fns';
import { it } from 'date-fns/locale';
import { capitalize } from 'lodash';
import { formatInputDate } from '../../form';

const computeMonthYearSelectOptions = (): SelectOption[] => {
  const NOW = new Date();
  const INTERVAL = 12;

  const monthSelectOptions: SelectOption[] = eachMonthOfInterval({
    start: NOW,
    end: addMonths(NOW, INTERVAL),
  }).map((date) => {
    const dateForLastDayOfMonth = lastDayOfMonth(date);
    return {
      value: formatInputDate(dateForLastDayOfMonth),
      label: capitalize(format(dateForLastDayOfMonth, 'MMMM yyyy', { locale: it })),
    };
  });

  return [
    {
      value: '',
      label: 'Seleziona un valore',
    },
    ...monthSelectOptions];
};

export default computeMonthYearSelectOptions;
