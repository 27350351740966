import React from 'react';
import {
  Badge, Card, CardSkeleton, Dropdown, HStack, ICON_ALERT_CIRCLE_OUTLINE, ICON_HEART_OUTLINE, Message, Spacing, Stack,
} from '@doveit/bricks';
import { Contact, Intent } from '../../../providers/api/dtos';
import useContactIntents from '../../hooks/use-contact-intents/useContactIntents';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewContactIntentRow from '../../../intent/containers/view-contact-intent-row/ViewContactIntentRow';
import CreateIntentAction from '../../../intent/containers/create-intent-action/CreateIntentAction';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import ViewContactIntentMobileCard from '../../../intent/containers/view-contact-intent-mobile-row/ViewContactIntentMobileCard';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import { INTENT_KO_STATUS, IntentStatus } from '../../../domain/types';

export const CONTACT_INTENTS_ERROR_MESSAGE = 'Non è stato possibile recuperare gli interessi del contatto.';
export const EMPTY_CONTACT_INTENTS = 'Non sono presenti interessi associati al contatto.';

const FILTERS = ['all', 'active', 'inactive'] as const;

type Filter = typeof FILTERS[number];

const filterLabels: Record<Filter, string> = {
  all: 'Tutti',
  active: 'Attivi',
  inactive: 'Non attivi',
};

export interface ContactIntentsWidgetProps {
  contactId: NonNullable<Contact['id']>,
  onIntentUpdate?: (intent: Intent) => void,
}

const ContactIntentsWidget: React.FC<ContactIntentsWidgetProps> = ({
  contactId,
  onIntentUpdate,
}) => {
  const isMobile = useIsDevice('mobile');

  const {
    data: contactIntents = [], isLoading: isContactIntentsLoading, error: contactIntentsError, mutate: mutateContactIntents,
  } = useContactIntents(contactId);

  const sortedContactIntents = React.useMemo(() => contactIntents && contactIntents.sort((a, b) => Date.parse(b.updatedAt!) - Date.parse(a.updatedAt!)),
    [contactIntents]);

  const [activeFilter, setActiveFilter] = React.useState<Filter>('all');

  const filteredSortedContactIntents = React.useMemo(() => {
    switch (activeFilter) {
      case 'active':
        return sortedContactIntents.filter((intent) => ([IntentStatus.IN_PROGRESS].includes(intent.status)));
      case 'inactive':
        return sortedContactIntents.filter((intent) => ([IntentStatus.BOUGHT, ...INTENT_KO_STATUS].includes(intent.status)));
      default:
        return sortedContactIntents;
    }
  }, [activeFilter, sortedContactIntents]);

  const handleContactIntentsUpdate = React.useCallback(async (intent: Intent) => {
    await mutateContactIntents();

    onIntentUpdate?.(intent);
  }, [mutateContactIntents, onIntentUpdate]);

  if (isContactIntentsLoading) {
    return (
      <CardSkeleton aria-label="Sezione interessi in caricamento">
        {!isMobile && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  if (contactIntentsError) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Interessi"
          color="critical"
        />
        <Card.Content>
          <Message message={CONTACT_INTENTS_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  return filteredSortedContactIntents && (
    <Card>
      <Card.Header
        aria-label="Sezione interessi (card header)"
        title="Interessi"
        icon={{ path: ICON_HEART_OUTLINE }}
        primaryActions={[
          (!isMobile && (
            <CreateIntentAction
              aria-label="Pulsante per aggiungere un nuovo interesse d'acquisto"
              contactId={contactId}
              onSuccess={handleContactIntentsUpdate}
            />
          )),
        ]}
        secondaryActions={[
          (isMobile && (
            <CreateIntentAction
              aria-label="Pulsante per aggiungere un nuovo interesse d'acquisto"
              contactId={contactId}
              onSuccess={handleContactIntentsUpdate}
            >
              {({ createIntent }) => (
                <Dropdown.Option
                  aria-label="Pulsante per aggiungere un nuovo interesse d'acquisto"
                  label="Aggiungi"
                  onClick={createIntent}
                />
              )}
            </CreateIntentAction>
          )),
        ]}
      />
      <Card.Content>
        <Stack aria-label="Lista interessi">
          {isMobile && filteredSortedContactIntents.length > 0 && (
            filteredSortedContactIntents.map((intent) => (
              <ViewContactIntentMobileCard
                key={intent.id}
                intent={intent}
              />
            ))
          )}
          {!isMobile && (
            <>
              {contactIntents.length > 0 && (
                <Spacing margin={[0, 0, 150]}>
                  <HStack aria-label="Filtri">
                    {FILTERS.map(((filter) => (
                      <Badge
                        label={filterLabels[filter]}
                        key={filter}
                        emphasis="low"
                        onClick={filter !== activeFilter ? () => setActiveFilter(filter) : undefined}
                        color={activeFilter === filter ? 'primary' : 'neutral'}
                      />
                    )))}
                  </HStack>
                </Spacing>
              )}
              {filteredSortedContactIntents.length > 0 && (
                <SimpleTable>
                  <SimpleTable.Body>
                    {filteredSortedContactIntents.map((intent) => (
                      <SimpleTable.Row
                        asChild
                        key={intent.id}
                      >
                        <ViewContactIntentRow intent={intent} onIntentUpdate={handleContactIntentsUpdate} />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </>
          )}
          {filteredSortedContactIntents.length === 0 && (
            <Message message={EMPTY_CONTACT_INTENTS} />
          )}
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ContactIntentsWidget;
