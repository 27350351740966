import { SelectOption } from '@doveit/bricks';
import { JobApplicationStatus } from '../../../domain/types/jobApplicationStatus';
import { jobApplicationStatusLabels } from '../../../labels';

export const koReasonOptions: SelectOption[] = [
  {
    value: '',
    label: 'Seleziona una motivazione',
  },
  ...[
    JobApplicationStatus.KO_DUPLICATE,
    JobApplicationStatus.KO_NOT_ELIGIBLE,
    JobApplicationStatus.KO_NOT_INTERESTED,
    JobApplicationStatus.KO_OFFER_REFUSED,
    JobApplicationStatus.KO_GEO,
  ].map((key) => ({
    value: key,
    label: jobApplicationStatusLabels[key as JobApplicationStatus],
  })),
];
