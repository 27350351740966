import { API_JOB_POSITIONS_PATH, getClient } from '../api';
import { JobPosition } from '../dtos/jobPosition';

export async function getJobPositions(): Promise<JobPosition[]> {
  const client = await getClient();
  const { data } = await client.get<JobPosition[]>(API_JOB_POSITIONS_PATH);
  return data;
}

export async function getJobPosition(id: string): Promise<JobPosition> {
  const client = await getClient();
  const { data } = await client.get<JobPosition>(`${API_JOB_POSITIONS_PATH}/${id}`);
  return data;
}
