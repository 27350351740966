import {
  Action, Message, Portal, Spacing, useModal,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../../components/simple-modal/SimpleModal';
import EditPropertyAssignment from '../edit-property-assignment/EditPropertyAssignment';

export const ASSIGNMENT_LIMITED_EDIT_WARNING_MESSAGE = 'Se hai necessità di fare ulteriori modifiche contatta il team content';

export interface ChildFnProps {
  openModal: VoidFunction,
}

export interface EditPropertyAssignmentActionProps {
  propertyId: number,
  limitEdit?: boolean,
  onEditAssignmentSuccess?: VoidFunction,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const EditPropertyAssignmentAction: React.FC<EditPropertyAssignmentActionProps> = ({
  propertyId,
  limitEdit = false,
  onEditAssignmentSuccess,
  children,
}) => {
  const editAssignmentModal = useModal();

  const openEditAssignmentModal = React.useCallback(() => {
    editAssignmentModal.open();
  }, [editAssignmentModal]);

  const onInternalSuccess = React.useCallback(() => {
    onEditAssignmentSuccess?.();
    editAssignmentModal.close();
  }, [editAssignmentModal, onEditAssignmentSuccess]);

  return (
    <>
      {children
        ? children({ openModal: openEditAssignmentModal })
        : (
          <Action
            label="Modifica"
            onClick={openEditAssignmentModal}
            size="S"
            aria-label="Pulsante per modificare il mandato"
          />
        )}
      <Portal>
        <SimpleModal
          {...editAssignmentModal}
          title="Modifica mandato"
          aria-label="Modale per modificare il mandato"
        >
          {limitEdit && (
            <Spacing margin={[0, 0, 200]}>
              <Message
                type="warning"
                message={ASSIGNMENT_LIMITED_EDIT_WARNING_MESSAGE}
              />
            </Spacing>
          )}
          <EditPropertyAssignment
            propertyId={propertyId}
            hiddenFields={limitEdit ? {
              exclusive: true,
              autoRenew: true,
            } : undefined}
            onSuccess={onInternalSuccess}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditPropertyAssignmentAction;
