/* eslint-disable react/no-unknown-property */
import {
  ActionIcon,
  Form,
  FormActions,
  ICON_TRASH_CAN_OUTLINE,
  useNotifications,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { CustomUser } from '../../../types';
import { slugIt } from '../../../utils/string';
import { copyToClipboard } from '../../../utils/text/text';
import buildTrackedLink from '../../utils/build-tracked-link/buildTrackedLink';
import { COPY_LINK_ERROR_MESSAGE, COPY_LINK_SUCCESS_MESSAGE } from '../copy-link/CopyLink';
import validationSchema from './BuildWebsiteTrackedLink.schema';
import * as styles from './BuildWebsiteTrackedLink.style';

export const BUILD_TRACKED_LINK_SUCCESS_MESSAGE = 'Link generato correttamente. Puoi ora copiarlo.';

export interface BuildWebsiteTrackedLinkModel {
  url: string,
}

export interface BuildWebsiteTrackedLinkProps {
  userName: CustomUser['name']
}

const DEFAULT_INITIAL_VALUES: BuildWebsiteTrackedLinkModel = {
  url: '',
};

const BuildWebsiteTrackedLink: FunctionComponent<BuildWebsiteTrackedLinkProps> = ({
  userName,
}) => {
  const [generated, setGenerated] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const onGenerateTrackedLink = useCallback(({ url }: BuildWebsiteTrackedLinkModel, { setFieldValue }: FormActions<BuildWebsiteTrackedLinkModel>) => {
    const trackedLink = buildTrackedLink(url, slugIt(userName));

    setGenerated(true);
    setFieldValue('url', trackedLink);
    addSuccess(BUILD_TRACKED_LINK_SUCCESS_MESSAGE);
  }, [addSuccess, userName]);

  const onCopyTrackedLink = useCallback(async ({ url }: BuildWebsiteTrackedLinkModel) => {
    setIsSaving(true);

    try {
      await copyToClipboard(url);

      setIsSaving(false);
      addSuccess(COPY_LINK_SUCCESS_MESSAGE);
    } catch (error) {
      setIsSaving(false);
      addError(COPY_LINK_ERROR_MESSAGE);
    }
  }, [addError, addSuccess]);

  return (
    <Form
      initialValues={DEFAULT_INITIAL_VALUES}
      onSubmit={generated ? onCopyTrackedLink : onGenerateTrackedLink}
      validationSchema={validationSchema}
      loading={isSaving}
    >
      {({ resetForm }) => {
        const onReset = () => {
          resetForm();
          setGenerated(false);
        };

        return (
          <Form.Group label="Crea un link Dove.it personalizzato">
            <Form.Item size={{ MD: 4 / 6, LG: 5 / 6 }}>
              <div
                css={styles.inputWrapper}
                generated={generated}
              >
                <Form.Input
                  name="url"
                  aria-label="Campo con l'url da copiare"
                  placeholder="Inserisci un link Dove.it per creare url personalizzato"
                  disabled={generated}
                />
                {generated && (
                  <ActionIcon
                    label="Cancella il link generato"
                    color="critical"
                    emphasis="high"
                    icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                    onClick={onReset}
                  />
                )}
              </div>
            </Form.Item>
            <Form.Item size={{ MD: 2 / 6, LG: 1 / 6 }}>
              <Form.Submit
                expanded
                label={generated ? 'Copia link' : 'Genera link'}
              />
            </Form.Item>
          </Form.Group>
        );
      }}
    </Form>
  );
};

export default BuildWebsiteTrackedLink;
