import { Status } from '../../../../domain/types';

export const CONTACT_PROPERTIES_LOAD_ERROR = 'Non è stato possibile caricare i dati relativi agli immobili del contatto.';

export const PROPERTIES_STATUS_SORT_ORDER: Record<Status, number> = {
  [Status.LIVE]: 0,
  [Status.PROPOSTA]: 0,
  [Status.DRAFT]: 0,
  [Status.ANTEPRIMA]: 0,
  [Status.VENDUTO]: 1,
  [Status.RITIRATO]: 1,
};
