import { BreakpointQueryName, ThemedFunctor } from '@doveit/bricks';
import { css, keyframes } from 'styled-components';

const getLightAnimation = (color: string) => keyframes`
  0% { box-shadow: 5px 10px 35px 10px ${color}; }
  50% { box-shadow: none; }
  100% { box-shadow: 5px 10px 35px 10px ${color}; }
`;

export const lights: ThemedFunctor = ({ theme }) => css`
  border-top: 2px solid #222;

  ul {
    margin-top: -35px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 120px;
  }

  li {
    margin: 0 15px;
    position: relative;
    list-style: none;
    padding: 0;
    display: inline-block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    top: 35px;
    background: red;
    flex-shrink: 0;

    ${theme.breakpoints.up(BreakpointQueryName.MD)} {
      margin: 0 25px;
    }

    &:nth-child(even) {
      transform: rotate(10grad);
    }

    &:nth-child(odd) {
      transform: rotate(-5grad);
    }

    &:before {
      content: "";
      position: absolute;
      background: #222;
      width: 10px;
      height: 9px;
      top: -4px;
      left: 1px;
    }
  }
`;

export const setColor = (color: string, seconds: number) => () => css`
  background-color: ${color} !important;
  animation: ${getLightAnimation(color)} ${seconds}s infinite;
`;
