import { Form, FormActionsAlign, FormSubmitType } from '@doveit/bricks';
import React, { FunctionComponent, useMemo } from 'react';
import { PropertyRelationshipType } from '../../../domain/types';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { propertyRelationshipTypeLabels } from '../../../labels';

export interface PropertyContactFormModel {
  relationship: PropertyRelationshipType,
}

export interface PropertyContactFormProps {
  initialValues?: PropertyContactFormModel,
  disabled?: boolean,
  loading?: boolean,
  submitLabel?: string,
  onSubmit: FormSubmitType<PropertyContactFormModel>,
}

export const PropertyContactForm: FunctionComponent<PropertyContactFormProps> = ({
  initialValues,
  disabled,
  loading,
  submitLabel = 'Salva',
  onSubmit,
}) => {
  const mergedInitialValues: PropertyContactFormModel = useMemo(
    () => initialValues || { relationship: PropertyRelationshipType.PROPRIETARIO },
    [initialValues],
  );

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      loading={loading}
      disabled={disabled}
      preventEnterKeypress
    >
      <Form.Item>
        <Form.Select
          name="relationship"
          label="Ruolo"
          options={asSelectOptions(propertyRelationshipTypeLabels, false)}
        />
      </Form.Item>
      <Form.Actions align={FormActionsAlign.RIGHT}>
        <Form.Submit label={submitLabel} />
      </Form.Actions>
    </Form>
  );
};

export default PropertyContactForm;
