import {
  Document, Page, View, Image, Font,
} from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { GMAP_API_KEY } from '../../../../constants';
import {
  Header, Gallery, Description, Details, EnergySection, Features, Location, Reference,
} from '../../components';
import BuyerBrochureModel from '../models/buyerBrochure';

import * as styles from './Layout.style';

Font.register({
  family: 'Exo',
  fonts: [
    { src: require('../../fonts/exo.ttf') },
    { src: require('../../fonts/exo-700.ttf'), fontWeight: 'bold' },
  ],
});

export interface LayoutProps {
  values: BuyerBrochureModel,
}

export const Layout: FunctionComponent<LayoutProps> = (props) => {
  const {
    values: {
      logo,
      refId,
      normalizedAddress,
      price,
      images,
      blueprints,
      description,
      details,
      energyClass,
      energyValue,
      features,
      latitude,
      longitude,
      agent,
      company,
    },
  } = props;

  const theme = useTheme();

  return (
    <Document>
      <Page
        size="A4"
        style={styles.page(theme)}
      >
        <View>
          <View>
            <Header
              logo={logo}
              title={normalizedAddress}
              price={price}
            />
          </View>
          {
            images && images.length > 0 && (
              <View style={styles.gallery}>
                <Gallery
                  images={images}
                />
              </View>
            )
          }
          {
            description && (
              <View>
                <Description
                  description={description}
                />
              </View>
            )
          }
          <View style={styles.row as any}>
            <View style={styles.column}>
              <Details
                values={details.slice(0, 5)}
              />
            </View>
            <View style={styles.column}>
              <Details
                values={details.slice(5)}
              />
            </View>
          </View>
          {
            energyClass && (
              <View>
                <EnergySection
                  type={energyClass}
                  value={energyValue}
                />
              </View>
            )
          }
          {features && features.length > 0 && (
            <View style={styles.features}>
              <Features
                values={features}
              />
            </View>
          )}
          <View style={styles.row as any}>
            <View style={styles.column}>
              <Location
                apiKey={GMAP_API_KEY}
                latitude={latitude}
                longitude={longitude}
              />
            </View>
            <View style={styles.column}>
              <Reference
                reference={refId}
                agent={agent}
                company={company}
              />
            </View>
          </View>
        </View>
      </Page>
      {blueprints.map((blueprint) => (
        <Page
          orientation="landscape"
          key={blueprint}
          style={styles.page(theme)}
        >
          <View style={styles.blueprintPage as any}>
            <Image
              style={styles.blueprint}
              src={blueprint}
            />
          </View>
        </Page>
      ))}
    </Document>
  );
};
