import React, { FunctionComponent } from 'react';
import { Badge, BadgeProps } from '@doveit/bricks';
import { ProspectStatus } from '../../../domain/types';
import { prospectStatusLabels } from '../../../labels';

export interface ProspectStatusBadgeProps {
  status: ProspectStatus,
  size?: BadgeProps['size'],
}

export const ProspectStatusColors: Record<ProspectStatus, BadgeProps['color']> = {
  IN_PROGRESS: 'info',
  ONLY_EVALUATION: 'critical',
  OVER_PRICED: 'critical',
  CANCELLED: 'critical',
  UNSELLABLE: 'critical',
  NOT_INTERESTED: 'critical',
  ASSIGNMENT_CREATED: 'success',
  SOLD: 'critical',
};

const ProspectStatusBadge: FunctionComponent<ProspectStatusBadgeProps> = ({
  status,
  size,
}) => (
  <Badge
    label={prospectStatusLabels[status]}
    color={ProspectStatusColors[status]}
    size={size}
  />
);

export default ProspectStatusBadge;
