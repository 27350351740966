import React from 'react';
import {
  Card, Grid, Stack, Text,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { Property } from '../../../providers/api/dtos';
import UpsertPropertyUnitAction from '../upsert-property-unit-action/UpsertPropertyUnitAction';
import DeletePropertyUnitAction from '../delete-property-unit-action/DeletePropertyUnitAction';
import { propertyUnitIntendedUseLabels } from '../../../labels';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Status } from '../../../domain/types';
import { NO_VALUE_SYMBOL } from '../../utils';

export interface ViewPropertyUnitCardProps {
  property: Property,
  unitIndex: number,
  onPropertyUpdate?: (updatedProperty: Property) => void,
}

export const LOAD_LEAD_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare i dati.';

const ViewPropertyUnitCard: React.FC<ViewPropertyUnitCardProps> = ({
  property,
  unitIndex,
  onPropertyUpdate,
}) => {
  const { status } = property;
  const { userIsAdmin, userIsAgent, userIsContentEditor } = useRBAC();

  const showActions = React.useMemo(() => (userIsAdmin || userIsContentEditor)
    || (userIsAgent && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE, Status.PROPOSTA].includes(status)), [status, userIsAdmin, userIsAgent, userIsContentEditor]);

  const normalizedIndex = React.useMemo(() => Math.max(Math.min(unitIndex!, (property.cadastralRegistry?.units ?? []).length - 1), 0), [property.cadastralRegistry?.units, unitIndex]);
  const propertyUnit = React.useMemo(() => (property.cadastralRegistry?.units ?? [])[normalizedIndex], [normalizedIndex, property.cadastralRegistry?.units]);

  if (!propertyUnit) {
    return null;
  }

  return (
    <Card aria-label="Card dell'unità catastale">
      <Card.Header
        title={`Unità catastale ${normalizedIndex + 1}`}
        primaryActions={showActions ? [
          <UpsertPropertyUnitAction
            property={property}
            unitIndex={normalizedIndex}
            onSuccess={onPropertyUpdate}
          />,
          <DeletePropertyUnitAction
            property={property}
            unitIndex={normalizedIndex}
            onSuccess={onPropertyUpdate}
          />,
        ] : undefined}
      />
      <Card.Content>
        <Grid gutter={150}>
          <Grid.Unit size={1 / 4}>
            <Stack>
              <Text.Mini transform="uppercase">
                Sezione
              </Text.Mini>
              <Text.Caption>
                {propertyUnit.section ?? NO_VALUE_SYMBOL}
              </Text.Caption>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={1 / 4}>
            <Stack>
              <Text.Mini transform="uppercase">
                Foglio
              </Text.Mini>
              <Text.Caption>
                {propertyUnit.sheet}
              </Text.Caption>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={1 / 4}>
            <Stack>
              <Text.Mini transform="uppercase">
                Particella
              </Text.Mini>
              <Text.Caption>
                {propertyUnit.parcel}
              </Text.Caption>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={1 / 4}>
            <Stack>
              <Text.Mini transform="uppercase">
                Subalterno
              </Text.Mini>
              <Text.Caption>
                {propertyUnit.subunit ?? NO_VALUE_SYMBOL}
              </Text.Caption>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={1 / 4}>
            <Stack>
              <Text.Mini transform="uppercase">
                Categoria
              </Text.Mini>
              <Text.Caption>
                {propertyUnit.category}
              </Text.Caption>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={2 / 4}>
            <Stack>
              <Text.Mini transform="uppercase">
                Destinazione d&apos;uso
              </Text.Mini>
              <Text.Caption>
                {propertyUnit.intendedUse && propertyUnitIntendedUseLabels[propertyUnit.intendedUse]}
              </Text.Caption>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={1 / 4}>
            <Stack>
              <Text.Mini transform="uppercase">
                Rendita
              </Text.Mini>
              <Text.Caption>
                {propertyUnit.value ? formatEuro(propertyUnit.value) : NO_VALUE_SYMBOL}
              </Text.Caption>
            </Stack>
          </Grid.Unit>
          <Grid.Unit>
            <Stack>
              <Text.Mini transform="uppercase">
                Classe energetica
              </Text.Mini>
              <Text.Caption>
                {propertyUnit.energyClass ?? NO_VALUE_SYMBOL}
              </Text.Caption>
            </Stack>
          </Grid.Unit>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default ViewPropertyUnitCard;
