/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  ActionIcon,
  Badge, DetailItemList, HStack, ICON_ACCOUNT_OUTLINE, ICON_CASH_MULTIPLE, ICON_CLOCK_OUTLINE, ICON_EYE_OUTLINE, ICON_PHONE_OUTLINE, ICON_RULER, Modal, Portal, Spacing, Stack, Tooltip, Text, useModal,
} from '@doveit/bricks';
import { formatSquareMetersRange, formatEuroRange, formatDate } from '@doveit/hammer';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import Card from '../../../components/card/Card';
import UserActions from '../../../components/user-actions/UserActions';
import { IntentSource, PropertyType } from '../../../domain/types';
import { propertyTypeLabels } from '../../../labels';
import {
  Contact, Intent, MatchingSearchCriteria, Property, PropertyPreview, SearchCriteria,
} from '../../../providers/api/dtos';
import { getGeoCriterionIcon } from '../../../search-criteria/utils/getGeoCriterionIcon';
import { getGeoCriterionLabel } from '../../../search-criteria/utils/getGeoCriterionLabel';
import { BlacklistAction, HorizontalAlignment, VerticalAlignment } from '../../../types';
import * as styles from './MatchingSearchCriteriaCard.style';
import CreateIntentWithAppointmentAction from '../../../intent/containers/create-intent-with-appointment-action/CreateIntentWithAppointmentAction';
import ContactMatchBlacklistAction, { ContactMatchBlacklistActionProps } from '../../../contact-match/containers/contact-match-blacklist-action/ContactMatchBlacklistAction';

export interface MatchingSearchCriteriaCardProps {
  property?: Property | PropertyPreview,
  propertyType?: PropertyType,
  evaluation?: number,
  matchingSearchCriteria: MatchingSearchCriteria,
  showCreateIntentAction?: boolean,
  disableCreateIntentAction?: boolean,
  showContactMatchBlacklistAction?: boolean,
  disableContactMatchBlacklistAction?: boolean,
  onClickVisitAction: (searchCriteriaId: number) => void,
  onIntentCreated?: (intent: Intent) => void,
  onContactMatchBlacklistUpdated?: ContactMatchBlacklistActionProps['onSuccess'],
}

export const MAX_GEO_CRITERION = 3;

const renderContact = (contact: Contact) => (
  <DetailItemList>
    <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
      {contact.name}
    </DetailItemList.Item>
    <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
      {contact.phoneNumber}
    </DetailItemList.Item>
    <DetailItemList.Item>
      <UserActions user={contact} />
    </DetailItemList.Item>
  </DetailItemList>
);

const renderSearchCriteria = (
  searchCriteria: SearchCriteria,
  propertyType?: PropertyType,
  withinBudget?: boolean,
) => (
  <DetailItemList aria-label="search-criteria">
    <DetailItemList.Item icon={ICON_RULER}>
      <div dangerouslySetInnerHTML={{
        __html: `${searchCriteria.propertyTypes.map((searchCriterionPropertyType) => (searchCriterionPropertyType === propertyType
          ? `<strong>${propertyTypeLabels[searchCriterionPropertyType]}</strong>`
          : propertyTypeLabels[searchCriterionPropertyType])).join(', ')}`,
      }}
      />
      <span data-ref="property-size-rage">
        {formatSquareMetersRange(searchCriteria.minSize, searchCriteria.maxSize)}
      </span>
    </DetailItemList.Item>
    <DetailItemList.Item icon={ICON_CASH_MULTIPLE}>
      <span
        aria-label="search-criterion-bugdet"
        css={styles.budget}
        withinBudget={withinBudget}
      >
        {formatEuroRange(searchCriteria.minPrice, searchCriteria.maxPrice)}
      </span>
    </DetailItemList.Item>
    <DetailItemList.Item icon={ICON_CLOCK_OUTLINE}>
      {formatDate(new Date(searchCriteria.createdAt!))}
    </DetailItemList.Item>
    <DetailItemList.Item>
      {searchCriteria.geo.slice(0, MAX_GEO_CRITERION).map((geo) => (
        <Badge
          key={getGeoCriterionLabel(geo)}
          icon={getGeoCriterionIcon(geo)}
          label={getGeoCriterionLabel(geo)}
          size="XS"
        />
      ))}
      {searchCriteria.geo.length > MAX_GEO_CRITERION && (
        <Tooltip
          data-ref="additional-geo-info-tooltip"
          content={(
            <div data-ref="additional-geo-info">
              {searchCriteria.geo.slice(MAX_GEO_CRITERION).map((geo) => (
                <Text.Body key={getGeoCriterionLabel(geo)}>
                  {getGeoCriterionLabel(geo)}
                </Text.Body>
              ))}
            </div>
          )}
        >
          <span
            data-ref="additional-geo-info-count"
            css={styles.moreGeoCriterion}
          >
            +{searchCriteria.geo.length - MAX_GEO_CRITERION}
          </span>
        </Tooltip>
      )}
    </DetailItemList.Item>
  </DetailItemList>
);

const MatchingSearchCriteriaCard: FunctionComponent<MatchingSearchCriteriaCardProps> = ({
  property,
  propertyType,
  evaluation,
  matchingSearchCriteria,
  showCreateIntentAction,
  disableCreateIntentAction,
  showContactMatchBlacklistAction,
  disableContactMatchBlacklistAction,
  onClickVisitAction,
  onIntentCreated,
  onContactMatchBlacklistUpdated,
}) => {
  const searchCriteriaModal = useModal<SearchCriteria[]>();

  const withinBudget = useCallback(
    (searchCriteria: SearchCriteria) => {
      if (evaluation && (searchCriteria.minPrice || searchCriteria.maxPrice)) {
        return evaluation >= (searchCriteria.minPrice ?? 0) && evaluation <= (searchCriteria.maxPrice ?? 0);
      }
      return undefined;
    },
    [evaluation],
  );

  const contact: Contact = useMemo(() => ({
    id: matchingSearchCriteria.contactId,
    name: matchingSearchCriteria.name,
    phoneNumber: matchingSearchCriteria.phoneNumber,
    email: matchingSearchCriteria.email,
  }), [matchingSearchCriteria]);

  const bottomAction = useMemo(() => {
    if (matchingSearchCriteria && matchingSearchCriteria.searchCriteria.length > 1) {
      return ({
        label: `Vedi altri ${matchingSearchCriteria.searchCriteria.length - 1}`,
        onClick: () => searchCriteriaModal.open(matchingSearchCriteria.searchCriteria),
      });
    }

    return undefined;
  }, [matchingSearchCriteria, searchCriteriaModal]);

  const onInternalClickVisitAction = useCallback((searchCriteriaId: number) => () => {
    onClickVisitAction(searchCriteriaId);
  }, [onClickVisitAction]);

  return (
    <>
      <Card bottomAction={bottomAction}>
        <Card.Inline>
          <Card.Box
            verticalAlignment={VerticalAlignment.TOP}
            fit
          >
            <div css={styles.contactWrapper}>
              {renderContact(contact)}
            </div>
          </Card.Box>
          <Card.ColumnDivider />
          <Card.Box data-ref="search-criteria">
            {renderSearchCriteria(
              matchingSearchCriteria.searchCriteria[0],
              propertyType,
              withinBudget(matchingSearchCriteria.searchCriteria[0]),
            )}
          </Card.Box>
          <Card.ColumnDivider />
          <Card.Box
            fit
            horizontalAlignment={HorizontalAlignment.CENTER}
          >
            <HStack>
              <ActionIcon
                label="Visualizza"
                icon={{ path: ICON_EYE_OUTLINE }}
                onClick={onInternalClickVisitAction(matchingSearchCriteria.searchCriteria[0].id!)}
              />
              {showCreateIntentAction && (
                <CreateIntentWithAppointmentAction
                  contactId={matchingSearchCriteria.contactId}
                  property={property}
                  disabled={disableCreateIntentAction}
                  source={IntentSource.MATCHING}
                  onAppointmentAborted={onIntentCreated}
                  onAppointmentCreated={onIntentCreated}
                />
              )}
              {showContactMatchBlacklistAction && property?.id !== undefined && (
                <ContactMatchBlacklistAction
                  contactId={matchingSearchCriteria.contactId}
                  propertyId={property.id}
                  action={matchingSearchCriteria.hidden ? BlacklistAction.REMOVE : BlacklistAction.ADD}
                  onSuccess={onContactMatchBlacklistUpdated}
                  disabled={disableContactMatchBlacklistAction}
                />
              )}
            </HStack>
          </Card.Box>
        </Card.Inline>
      </Card>
      <Portal>
        <Modal
          open={searchCriteriaModal.isOpen}
          onCloseHandler={searchCriteriaModal.close}
          aria-label="search-criteria-modal"
        >
          <Modal.Close />
          {searchCriteriaModal.data && (
            <>
              <Modal.Header>
                <Text.H4>
                  Ricerche
                </Text.H4>
                <Spacing margin={[400, 0, 0]}>
                  {renderContact(contact)}
                </Spacing>
              </Modal.Header>
              <Modal.Body padded>
                <Stack>
                  {searchCriteriaModal.data.map((searchCriteria) => (
                    <Card
                      key={searchCriteria.id}
                      data-ref="search-criteria-modal"
                      aria-label="search-criteria-modal-card"
                    >
                      <Card.Inline>
                        <Card.Box>
                          {renderSearchCriteria(searchCriteria, propertyType, withinBudget(searchCriteria))}
                        </Card.Box>
                        <Card.ColumnDivider />
                        <Card.Box
                          fit
                          horizontalAlignment={HorizontalAlignment.CENTER}
                        >
                          <ActionIcon
                            label="Visualizza"
                            aria-label="Visualizza la pagina della Ricerca"
                            icon={{ path: ICON_EYE_OUTLINE }}
                            onClick={onInternalClickVisitAction(searchCriteria.id!)}
                          />
                        </Card.Box>
                      </Card.Inline>
                    </Card>
                  ))}
                </Stack>
              </Modal.Body>
            </>
          )}
        </Modal>
      </Portal>
    </>
  );
};

export default MatchingSearchCriteriaCard;
