import React from 'react';
import {
  ActionDropDown, Badge, Dropdown, ICON_PROGRESS_ALERT, ICON_PROGRESS_CHECK, useNotifications,
} from '@doveit/bricks';
import { SearchCriteria } from '../../../providers/api/dtos';
import { updateSearchCriteria } from '../../../providers/api/search-criteria/searchCriteriaProvider';

export const UPDATE_SEARCH_CRITERIA_STATUS_ERROR_MESSAGE = 'Non è stato possibile modificare lo stato della ricerca';
export const UPDATE_SEARCH_CRITERIA_STATUS_SUCCESS_MESSAGE = 'Lo stato della ricerca è stato modificato con successo';

export interface UpdateSearchCriteriaStatusActionProps {
  searchCriteria: SearchCriteria,
  showAsBadge?: boolean,
  emphasis?: 'low' | 'high',
  size?: 'XS' | 'S',
  onSuccess?: (updatedSearchCriteria: SearchCriteria) => void,
}

const UpdateSearchCriteriaStatusAction: React.FC<UpdateSearchCriteriaStatusActionProps> = ({
  searchCriteria,
  showAsBadge = false,
  emphasis = 'low',
  size = 'S',
  onSuccess,
}) => {
  const { id, active } = searchCriteria;

  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();

  const handleUpdateStatus = React.useCallback((to: boolean) => async () => {
    if (to === active) return;

    setIsSaving(true);

    try {
      const updatedSearchCriteria = await updateSearchCriteria(id!, {
        ...searchCriteria,
        active: to,
      });

      setIsSaving(false);
      addSuccess(UPDATE_SEARCH_CRITERIA_STATUS_SUCCESS_MESSAGE);
      onSuccess?.(updatedSearchCriteria);
    } catch (err) {
      setIsSaving(false);
      addError(UPDATE_SEARCH_CRITERIA_STATUS_ERROR_MESSAGE);
    }
  }, [active, addError, addSuccess, id, onSuccess, searchCriteria]);

  return (
    showAsBadge
      ? (
        <Badge
          label={active ? 'Attiva' : 'Non attiva'}
          color={active ? 'info' : 'neutral'}
          emphasis={emphasis}
          icon={active ? ICON_PROGRESS_CHECK : ICON_PROGRESS_ALERT}
          size={size}
          aria-label="Badge dello stato della ricerca"
        />
      )
      : (
        <ActionDropDown
          aria-label="Seleziona un nuovo stato della ricerca"
          label={active ? 'Attiva' : 'Non attiva'}
          color={active ? 'info' : 'neutral'}
          iconLeft={{ path: active ? ICON_PROGRESS_CHECK : ICON_PROGRESS_ALERT }}
          emphasis={emphasis}
          size={size}
        >
          <Dropdown.Option
            aria-label={active ? 'Disattiva ricerca' : 'Attiva ricerca'}
            label={active ? 'Disattiva ricerca' : 'Attiva ricerca'}
            loading={isSaving}
            onClick={handleUpdateStatus(!active)}
          />
        </ActionDropDown>
      )
  );
};

export default UpdateSearchCriteriaStatusAction;
