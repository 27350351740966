import { getClient, API_CALL_CENTER } from '../api';
import { Count, ProcessableContact } from '../dtos';
import { ReferenceType } from '../../../domain/types';

export interface CalendarEventsFilters {
  referenceId?: string,
  referenceType?: ReferenceType,
}

export interface MatchContactFilters {
  phoneNumber?: string,
  email?: string,
}

export async function getNextCallCenterContactsCount(): Promise<Count> {
  const client = await getClient();
  const { data } = await client.get<Count>(`${API_CALL_CENTER}/next-contact/count`);

  return data;
}

export async function getNextCallCenterContact(): Promise<ProcessableContact | undefined> {
  const client = await getClient();
  const { data } = await client.get<ProcessableContact | undefined>(`${API_CALL_CENTER}/next-contact`);

  return data;
}

export async function saveCallBackDate(contactId: string, callBackDate: Date): Promise<void> {
  const client = await getClient();
  await client.post<void>(`${API_CALL_CENTER}/call-back/${contactId}`, {
    date: callBackDate.toISOString(),
  });
}

export async function saveNoAnswer(contactId: string): Promise<void> {
  const client = await getClient();
  await client.post(`${API_CALL_CENTER}/no-answer/${contactId}`);
}
