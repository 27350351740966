import React from 'react';
import {
  Action, Portal, useModal,
  useNotifications,
} from '@doveit/bricks';
import { Offer } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import MortgageMaxAcceptanceDateForm, { MortgageMaxAcceptanceDateFormModel } from '../../components/mortgage-max-acceptance-date-form/MortgageMaxAcceptanceDateForm';
import { updateOffer } from '../../../providers/api/offer/offerProvider';

export const UPDATE_MORTGAGE_ACCEPTANCE_DATE_SUCCESS_MESSAGE = 'La data massima di delibera del mutuo è stata modificata con successo';
export const UPDATE_MORTGAGE_ACCEPTANCE_DATE_ERROR_MESSAGE = 'Non è stato possibile modificare la data massima di delibera del mutuo';

export interface ChildFnProps {
  onClick: VoidFunction,
  loading?: boolean,
}

export interface UpdateMortgageAcceptanceDateActionProps {
  offer: Offer,
  onSuccess?: (updatedOffer: Offer) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const UpdateMortgageAcceptanceDateAction: React.FC<UpdateMortgageAcceptanceDateActionProps> = ({
  offer,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();

  const updateMortgageModal = useModal();

  const onUpdateMortgageDateAction = React.useCallback(() => {
    updateMortgageModal.open();
  }, [updateMortgageModal]);

  const onSubmit = React.useCallback(async (formValues: MortgageMaxAcceptanceDateFormModel) => {
    setIsSaving(true);

    try {
      const updatedOffer = await updateOffer({
        ...offer,
        mortgage: {
          ...offer.mortgage!,
          maxAcceptanceDate: formValues.maxAcceptanceDate,
        },
      });

      onSuccess?.(updatedOffer);

      setIsSaving(false);
      updateMortgageModal.close();
      addSuccess(UPDATE_MORTGAGE_ACCEPTANCE_DATE_SUCCESS_MESSAGE);
    } catch (err) {
      setIsSaving(false);

      addError(UPDATE_MORTGAGE_ACCEPTANCE_DATE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, offer, onSuccess, updateMortgageModal]);

  return (
    <> {
      children
        ? children({ onClick: onUpdateMortgageDateAction, loading: isSaving })
        : (
          <Action
            label="Proroga scadenza"
            size="S"
            loading={isSaving}
            onClick={onUpdateMortgageDateAction}
            aria-label="Pulsante per modificare la data massima di delibera del mutuo"
          />
        )
    }
      <Portal>
        <SimpleModal
          {...updateMortgageModal}
          title="Modifica data massima di delibera mutuo"
          aria-label="Modale per modificare la data massima di delibera del mutuo"
        >
          <MortgageMaxAcceptanceDateForm
            initialValues={{ maxAcceptanceDate: offer.mortgage!.maxAcceptanceDate! }}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpdateMortgageAcceptanceDateAction;
