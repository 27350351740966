import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Document } from '../../../providers/api/dtos';
import { getOfferDocuments } from '../../../providers/api/offer/offerProvider';

export const OFFER_DOCUMENTS_RID = 'offers/:id/documents';

export function useOfferDocuments(offerId?: number, options?: SWRConfiguration<Document[], AxiosError>): SWRResponse<Document[], AxiosError> {
  return useSWR(offerId ? [OFFER_DOCUMENTS_RID, offerId] : null, () => getOfferDocuments(offerId!), options);
}
