import React from 'react';
import {
  Action, Badge, Card, CardSkeleton, DetailItemList, Dropdown, HStack, ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK, ICON_HOME_CITY_OUTLINE, Message,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import { Lead } from '../../../providers/api/dtos';
import useLead from '../../hooks/use-lead/useLead';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import {
  propertyTypeLabels, propertyStatusLabels, registryStatusLabels,
  leadFeatureLabels,
} from '../../../labels';
import { LeadPropertyFeature, propertyTypesWithoutFloor } from '../../../domain/types';
import { formatFloor } from '../../../property/utils';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import { EditLeadAction } from '../edit-lead-action/EditLeadAction';
import { useAgent } from '../../../hooks/use-agent/useAgent';

export interface LeadWidgetProps extends React.AriaAttributes {
  leadId: NonNullable<Lead['id']>,
  canSeeSource?: boolean,
}

const LeadWidget: React.FC<LeadWidgetProps> = ({
  leadId,
  canSeeSource = false,
  ...rest
}) => {
  const {
    data: lead, isLoading: isLeadLoading, error: leadError, mutate: mutateLead,
  } = useLead(leadId);
  const isDesktop = useIsDevice('desktop');
  const leadFeatures = React.useMemo(
    () => (lead ? Object.keys(lead.propertyFeatures).filter((feature) => lead.propertyFeatures[feature as LeadPropertyFeature]) : []),
    [lead],
  );
  const { data: personalAgent } = useAgent(lead?.originAgentId);
  const hasPropertyConstraints = React.useMemo(() => (lead ? [
    lead.residualMortgage,
    lead.multiOwner,
    lead.otherAgencies,
    lead.inheritedProperty,
    lead.giftedProperty,
    lead.ownedByCompany,
    lead.registryDiscrepancies,
    lead.subsidizedHousing,
  ].some((c) => c) : false), [lead]);
  const onLeadChange = React.useCallback(() => {
    mutateLead();
  }, [mutateLead]);

  if (isLeadLoading) {
    return (
      <CardSkeleton aria-label="Lead in caricamento">
        {isDesktop && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  if (leadError) {
    return (
      <Card aria-label="Errore caricamento lead">
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Lead"
          color="critical"
        />
        <Card.Content>
          <Message message="Non è stato possibile caricare i dati della lead." />
        </Card.Content>
      </Card>
    );
  }

  return lead && (
    <Card {...rest}>
      <Card.Header
        icon={{ path: ICON_HOME_CITY_OUTLINE }}
        title="Lead"
        primaryActions={isDesktop ? [
          <EditLeadAction
            lead={lead}
            onSuccess={onLeadChange}
          >
            {({ edit }) => (
              <Action
                label="Modifica"
                aria-label="Pulsante per modificare la lead (desktop)"
                size="S"
                onClick={edit}
              />
            )}
          </EditLeadAction>,
        ] : undefined}
        secondaryActions={!isDesktop ? ([
          <EditLeadAction
            lead={lead}
            onSuccess={onLeadChange}
          >
            {({ edit }) => (
              <Dropdown.Option
                label="Modifica"
                aria-label="Pulsante per modificare la lead (mobile)"
                onClick={edit}
              />
            )}
          </EditLeadAction>,
        ]) : undefined}
      />
      <Card.Content>
        <DetailItemList columns={2}>
          <DetailItemList.Item label="Tipologia">
            {propertyTypeLabels[lead.propertyType]}
          </DetailItemList.Item>
          <DetailItemList.Item label="Indirizzo">
            {lead.propertyAddress}
          </DetailItemList.Item>
          {!propertyTypesWithoutFloor.includes(lead.propertyType) && (
            <DetailItemList.Item label="Piano">
              {lead.propertyFloor ? formatFloor(lead.propertyFloor) : undefined}
              {lead.buildingFloors && ` di ${lead.buildingFloors}`}
            </DetailItemList.Item>
          )}
          <DetailItemList.Item label="Superficie">
            {lead.propertySize ? formatSquareMeters(lead.propertySize) : undefined}
          </DetailItemList.Item>
          <DetailItemList.Item label="Stato immobile">
            {propertyStatusLabels[lead.propertyStatus]}
          </DetailItemList.Item>
          <DetailItemList.Item label="Stato proprietà">
            {lead.registryStatus ? registryStatusLabels[lead.registryStatus] : undefined}
          </DetailItemList.Item>
          <DetailItemList.Item label="Dispone di">
            {leadFeatures.length > 0 ? (
              <HStack>
                {leadFeatures.map((key) => (
                  <Badge
                    key={key}
                    icon={ICON_CHECK}
                    label={leadFeatureLabels[key as LeadPropertyFeature]}
                    size="XS"
                  />
                ))}
              </HStack>
            ) : undefined}
          </DetailItemList.Item>
          {canSeeSource && (
            <DetailItemList.Item label="Source">
              {lead.source}
            </DetailItemList.Item>
          )}
          {personalAgent && (
            <DetailItemList.Item label="Personal">
              <ViewAgentPopoverPreview
                agent={personalAgent}
                trigger={(
                  <AgentPreview
                    {...toAgentPreviewProps(personalAgent)}
                    size="XS"
                  />
                )}
              />
            </DetailItemList.Item>
          )}
        </DetailItemList>
      </Card.Content>
      {hasPropertyConstraints && (
        <>
          <Card.Divider />
          <Card.Content>
            <HStack>
              {lead.residualMortgage && (
                <Badge
                  icon={ICON_CHECK}
                  size="XS"
                  label="Mutuo residuo"
                />
              )}
              {lead.multiOwner && (
                <Badge
                  icon={ICON_CHECK}
                  size="XS"
                  label="Più proprietari"
                />
              )}
              {lead.otherAgencies && (
                <Badge
                  icon={ICON_CHECK}
                  size="XS"
                  label="Altre agenzie"
                />
              )}
              {lead.inheritedProperty && (
                <Badge
                  icon={ICON_CHECK}
                  size="XS"
                  label="Immobile ereditato"
                />
              )}
              {lead.giftedProperty && (
                <Badge
                  icon={ICON_CHECK}
                  size="XS"
                  label="Immobile donato"
                />
              )}
              {lead.ownedByCompany && (
                <Badge
                  icon={ICON_CHECK}
                  size="XS"
                  label="Intestato a società"
                />
              )}
              {lead.registryDiscrepancies && (
                <Badge
                  icon={ICON_CHECK}
                  size="XS"
                  label="Con difformità"
                />
              )}
              {lead.subsidizedHousing && (
                <Badge
                  icon={ICON_CHECK}
                  size="XS"
                  label="Soggetto a convenzione"
                />
              )}
            </HStack>
          </Card.Content>
        </>
      )}
    </Card>
  );
};

export default LeadWidget;
