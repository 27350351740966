import React from 'react';
import { AxiosError } from 'axios';
import { isBefore } from 'date-fns';
import { PropertyNoticeData } from '../../../providers/api/dtos/notice';
import {
  Document, Property, SignatureStatus,
} from '../../../providers/api/dtos';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import useAssignmentByPropertyId from '../use-assignment-by-property-id/useAssignmentByPropertyId';
import { AssignmentStatus, getAssignmentStatus } from '../../assignment/utils/assignmentStatus';
import {
  Aggregator,
  ContentType,
  DocumentStatus, OfferDeedStatus, OfferStatus, PropertyDocumentType, REVIEW_USER_TYPES, Status,
} from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { usePropertyDocuments } from '../use-property-documents/usePropertyDocuments';
import { MANDATORY_PROPERTY_DOCUMENT_TYPES } from '../../../document/constants';
import { DocumentReferenceType } from '../../../domain/types/documentReferenceType';
import useOffersByPropertyId from '../../../offer/hooks/use-offers-by-property-id/useOffersByPropertyId';
import useReviewsByPropertyId from '../../../review/hooks/use-reviews-by-property-id/useReviewsByPropertyId';
import useAssignmentSignatureStatus from '../../../prospect/hooks/use-assignment-signature-status/useAssignmentSignatureStatus';
import useAssignmentWizardData from '../../../prospect/hooks/use-assignment-wizard-data/useAssignmentWizardData';
import useLatestShootingByPropertyId from '../use-latest-shooting-by-property-id/useLatestShootingByPropertyId';
import useContentsByPropertyId from '../use-contents-by-property-id/useContentsByPropertyId';

export type UsePropertyNoticesDataResult = {
  data?: PropertyNoticeData[],
  isLoading: boolean,
  errors?: Error[] | undefined,
  mutate: () => Promise<void>,
};

export const usePropertyNoticesData = (property?: Property): UsePropertyNoticesDataResult => {
  const [errors, setErrors] = React.useState<AxiosError[] | undefined>();

  const { user, userIsAdmin, userIsContentEditor } = useRBAC();
  const userIsPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(property?.agentEmail);

  const handleApiError = React.useCallback((err: AxiosError) => {
    if (err.response?.status === 404) { return; }

    setErrors((prev) => {
      if (!prev) return [err];
      return [...prev, err];
    });
  }, []);

  const {
    data: assignment,
    isLoading: isAssignmentLoading,
    mutate: mutateAssignment,
  } = useAssignmentByPropertyId(property?.id, { onError: handleApiError });

  const {
    data: propertyDocuments,
    isLoading: arePropertyDocumentsLoading,
    mutate: mutatePropertyDocuments,
  } = usePropertyDocuments(property?.id, { onError: handleApiError });

  const {
    data: offers,
    isLoading: areOffersLoading,
    mutate: mutateOffers,
  } = useOffersByPropertyId(
    property?.id,
    [OfferStatus.IN_PROGRESS, OfferStatus.ACCEPTED, OfferStatus.CONFIRMED],
    { onError: handleApiError },
  );

  const {
    data: reviews,
    isLoading: areReviewsLoading,
    mutate: mutateReview,
  } = useReviewsByPropertyId(
    property?.id,
    { onError: handleApiError },
  );

  const {
    data: signatureStatus,
    isLoading: isSignatureStatusLoading,
    mutate: mutateSignatureStatus,
  } = useAssignmentSignatureStatus(
    assignment?.prospectId,
    { onError: handleApiError },
  );

  const {
    data: assignmentWizardData,
    isLoading: isAssignmentWizardDataLoading,
    mutate: mutateAssignmentWizardData,
  } = useAssignmentWizardData(
    assignment?.prospectId,
  );

  const {
    data: propertyShooting,
    isLoading: isPropertyShootingLoading,
    mutate: mutatePropertyShooting,
  } = useLatestShootingByPropertyId(
    property?.id,
    { onError: handleApiError },
  );

  const {
    data: propertyContents,
    isLoading: isPropertyContentsLoading,
    mutate: mutatePropertyContents,
  } = useContentsByPropertyId(
    property?.id,
    ContentType.FOTO,
    { onError: handleApiError },
  );

  const isLoading = React.useMemo(
    () => (
      isAssignmentLoading
      || arePropertyDocumentsLoading
      || areOffersLoading
      || areReviewsLoading
      || isSignatureStatusLoading
      || isAssignmentWizardDataLoading
      || isPropertyShootingLoading
      || isPropertyContentsLoading
    ),
    [isAssignmentLoading, arePropertyDocumentsLoading, areOffersLoading, areReviewsLoading, isSignatureStatusLoading, isAssignmentWizardDataLoading, isPropertyShootingLoading, isPropertyContentsLoading],
  );

  const mutate = React.useCallback(async () => {
    Promise.all([
      mutateAssignment(),
      mutatePropertyDocuments(),
      mutateOffers(),
      mutateReview(),
      mutateSignatureStatus(),
      mutateAssignmentWizardData(),
      mutatePropertyShooting(),
      mutatePropertyContents(),
    ]);
  }, [mutateAssignment, mutateAssignmentWizardData, mutateOffers, mutatePropertyContents, mutatePropertyDocuments, mutatePropertyShooting, mutateReview, mutateSignatureStatus]);

  const data = React.useMemo(() => {
    if (isLoading || errors) {
      return undefined;
    }

    const noticesData: PropertyNoticeData[] = [];

    /**
     * PROPERTY_ASSIGNMENT_EXPIRED
     */
    const isValidForAssignmentExpired = property
      && assignment
      && (userIsPropertyAgentOrManager || userIsAdmin)
      && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE].includes(property.status)
      && getAssignmentStatus(assignment.endDate) === AssignmentStatus.EXPIRED;

    if (isValidForAssignmentExpired) {
      noticesData.push({
        type: 'PROPERTY_ASSIGNMENT_EXPIRED',
        referenceId: assignment.id,
        data: {
          assignment,
        },
      });
    }

    /**
     * PROPERTY_ASSIGNMENT_EXPIRING
     */
    const isValidForAssignmentExpiring = property
      && assignment
      && (userIsPropertyAgentOrManager || userIsAdmin)
      && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE].includes(property.status)
      && getAssignmentStatus(assignment.endDate) === AssignmentStatus.EXPIRING;

    if (isValidForAssignmentExpiring) {
      noticesData.push({
        type: 'PROPERTY_ASSIGNMENT_EXPIRING',
        referenceId: assignment.id,
        data: {
          assignment,
        },
      });
    }

    /**
     * PROPERTY_ASSIGNMENT_SIGNATURE_ONGOING
     */
    const isValidForAssignmentSignatureOngoing = (
      userIsAdmin
      || userIsPropertyAgentOrManager
      || userIsContentEditor
    )
      && property
      && signatureStatus
      && assignmentWizardData
      && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE].includes(property.status);

    if (isValidForAssignmentSignatureOngoing) {
      const { signers } = signatureStatus;

      if (signatureStatus?.status === SignatureStatus.ONGOING) {
        noticesData.push({
          type: 'PROPERTY_ASSIGNMENT_SIGNATURE_ONGOING',
          referenceId: property.id!,
          data: {
            signers,
            assignmentWizardData,
          },
        });
      }
    }

    /**
     * PROPERTY_MISSING_MANDATORY_DOCUMENTS
     */
    const isValidForMissingMandatoryDocuments = property
      && propertyDocuments
      && (userIsPropertyAgentOrManager || userIsAdmin)
      && property.status === Status.DRAFT
      && !MANDATORY_PROPERTY_DOCUMENT_TYPES.every((mandatoryDocumentType) => propertyDocuments
        .filter(({ status }) => status === DocumentStatus.APPROVED)
        .some(({ type }) => type === mandatoryDocumentType));

    if (isValidForMissingMandatoryDocuments) {
      const notApprovedMandatoryDocuments = propertyDocuments
        .filter(({ status, type }) => status !== DocumentStatus.APPROVED && MANDATORY_PROPERTY_DOCUMENT_TYPES.includes(type as PropertyDocumentType));

      const documentTypes = propertyDocuments.map(({ type }) => type);

      const notUploadedMandatoryDocuments = MANDATORY_PROPERTY_DOCUMENT_TYPES
        .filter((type) => !documentTypes.includes(type))
        .map((documentType) => ({
          reference: {
            id: property.id!.toString(),
            type: DocumentReferenceType.PROPERTY,
          },
          type: documentType,
          files: [],
          status: DocumentStatus.DRAFT,
          createdBy: user.email,
        }));

      const missingDocuments: Document[] = [
        ...notApprovedMandatoryDocuments,
        ...notUploadedMandatoryDocuments,
      ];

      if (missingDocuments.length > 0) {
        noticesData.push({
          type: 'PROPERTY_MISSING_MANDATORY_DOCUMENTS',
          referenceId: property.id!,
          data: {
            missingDocuments,
          },
        });
      }
    }

    /**
     * PROPERTY_MANDATORY_DOCUMENTS_AWAITING_APPROVAL
     */
    const isValidForMandatoryDocumentsAwaitingApproval = property
      && propertyDocuments
      && (userIsContentEditor || userIsAdmin)
      && property.status === Status.DRAFT
      && MANDATORY_PROPERTY_DOCUMENT_TYPES.some((mandatoryDocumentType) => propertyDocuments
        .filter(({ status }) => status === DocumentStatus.REVIEW)
        .some(({ type }) => type === mandatoryDocumentType));

    if (isValidForMandatoryDocumentsAwaitingApproval) {
      const documentsAwaitingApproval = propertyDocuments
        .filter(({ status, type }) => status === DocumentStatus.REVIEW && MANDATORY_PROPERTY_DOCUMENT_TYPES.includes(type as PropertyDocumentType));

      if (documentsAwaitingApproval.length > 0) {
        noticesData.push({
          type: 'PROPERTY_MANDATORY_DOCUMENTS_AWAITING_APPROVAL',
          referenceId: property.id!,
          data: {
            documentsAwaitingApproval,
          },
        });
      }
    }

    /**
     * PROPERTY_MANDATORY_DOCUMENTS_REJECTED
     */
    const isValidForMandatoryDocumentsRejected = property
      && propertyDocuments
      && (userIsPropertyAgentOrManager || userIsAdmin)
      && MANDATORY_PROPERTY_DOCUMENT_TYPES.some((mandatoryDocumentType) => propertyDocuments
        .filter(({ status }) => status === DocumentStatus.REJECTED)
        .some(({ type }) => type === mandatoryDocumentType));

    if (isValidForMandatoryDocumentsRejected) {
      const documentsRejected = propertyDocuments
        .filter(({ status, type }) => status === DocumentStatus.REJECTED && MANDATORY_PROPERTY_DOCUMENT_TYPES.includes(type as PropertyDocumentType));

      if (documentsRejected.length > 0) {
        noticesData.push({
          type: 'PROPERTY_MANDATORY_DOCUMENTS_REJECTED',
          referenceId: property.id!,
          data: {
            documentsRejected,
          },
        });
      }
    }

    /**
     * PROPERTY_LIVE_WITH_ACCEPTED_OFFER
     */
    const isValidForPropertyLiveWithAcceptedOffer = property
      && offers?.some(({ status }) => status === OfferStatus.ACCEPTED)
      && (userIsPropertyAgentOrManager || userIsAdmin)
      && property.status === Status.LIVE
      && property.publishedOn.length > 1
      && property.publishedOn.some(({ aggregator }) => aggregator === Aggregator.DOVE_IT);

    if (isValidForPropertyLiveWithAcceptedOffer) {
      noticesData.push({
        type: 'PROPERTY_LIVE_WITH_ACCEPTED_OFFER',
        referenceId: property.id!,
        data: {
          property,
        },
      });
    }

    /**
     * PROPERTY_IN_NEGOTIATION_WITH_IN_PROGRESS_OFFER
     */
    const isValidForPropertyInNegotiationWithInProgressOffer = property
      && !offers?.some(({ status }) => status === OfferStatus.ACCEPTED)
      && offers?.some(({ status }) => status === OfferStatus.IN_PROGRESS)
      && (userIsPropertyAgentOrManager || userIsAdmin)
      && property.status === Status.PROPOSTA;

    if (isValidForPropertyInNegotiationWithInProgressOffer) {
      noticesData.push({
        type: 'PROPERTY_IN_NEGOTIATION_WITH_IN_PROGRESS_OFFER',
        referenceId: property.id!,
        data: {
          property,
        },
      });
    }

    /**
     * PROPERTY_OFFER_DEED_NOT_CONFIRMED
     */
    const isValidForOfferDeedNotConfirmed = property
      && property.status === Status.VENDUTO
      && (userIsPropertyAgentOrManager || userIsAdmin);

    if (isValidForOfferDeedNotConfirmed) {
      const offerWithNotConfirmedDeed = offers?.find(({ status, deed }) => status === OfferStatus.CONFIRMED && deed?.status !== OfferDeedStatus.CONFIRMED);

      if (offerWithNotConfirmedDeed) {
        noticesData.push({
          type: 'PROPERTY_OFFER_DEED_NOT_CONFIRMED',
          referenceId: property.id!,
          data: {
            offer: offerWithNotConfirmedDeed,
          },
        });
      }
    }

    /**
     * PROPERTY_REVIEWS_TO_BE_REQUESTED
     */
    const isValidForReviewsToBeRequested = property
      && property.status === Status.VENDUTO
      && !property.hideRequestReviewsNotice
      && offers?.some(({ status, deed }) => status === OfferStatus.CONFIRMED && deed?.status === OfferDeedStatus.CONFIRMED)
      && reviews?.length !== REVIEW_USER_TYPES.length
      && (userIsPropertyAgentOrManager || userIsAdmin);

    if (isValidForReviewsToBeRequested) {
      noticesData.push({
        type: 'PROPERTY_REVIEWS_TO_BE_REQUESTED',
        referenceId: property.id!,
        data: {
          property,
        },
      });
    }

    /**
     * PROPERTY_PRIVACY_HIDDEN_INFO
     */
    const isValidForPrivacyHiddenInfo = property
      && [Status.ANTEPRIMA, Status.LIVE].includes(property.status)
      && Object.values(property.privacy).some((hiddenInfo) => hiddenInfo)
      && (userIsPropertyAgentOrManager || userIsContentEditor || userIsAdmin);

    if (isValidForPrivacyHiddenInfo) {
      noticesData.push({
        type: 'PROPERTY_PRIVACY_HIDDEN_INFO',
        referenceId: property.id!,
        data: {
          property,
        },
      });
    }

    /**
     * PROPERTY_CONTENTS_AND_SHOOTING_MISSING
     */
    const missingPhotoAndShooting = (userIsAdmin || userIsContentEditor || userIsPropertyAgentOrManager)
      && property?.status === Status.DRAFT
      && propertyContents?.length === 0
      && !propertyShooting;

    if (missingPhotoAndShooting) {
      noticesData.push({
        type: 'PROPERTY_CONTENTS_AND_SHOOTING_MISSING',
        referenceId: property?.id!,
        data: {
          property,
        },
      });
    }

    /**
     * PROPERTY_MISSING_CONTENTS_AND_EXPIRED_SHOOTING
     */
    const missingPhotoAndExpiredShooting = (userIsAdmin || userIsContentEditor || userIsPropertyAgentOrManager)
      && propertyShooting
      && property?.status === Status.DRAFT
      && propertyContents?.length === 0
      && isBefore(propertyShooting.date, new Date());

    if (missingPhotoAndExpiredShooting) {
      noticesData.push({
        type: 'PROPERTY_MISSING_CONTENTS_AND_EXPIRED_SHOOTING',
        referenceId: property?.id!,
        data: {
          property,
          shooting: propertyShooting,
        },
      });
    }

    /**
     * PROPERTY_NO_PUBLISHED_ONS
     */
    const isValidForNoPublishedOns = (userIsAdmin || userIsContentEditor)
      && property
      && [Status.ANTEPRIMA, Status.LIVE].includes(property.status)
      && property.publishedOn.length === 0;

    if (isValidForNoPublishedOns) {
      noticesData.push({
        type: 'PROPERTY_NO_PUBLISHED_ONS',
        referenceId: property?.id!,
        data: {
          property,
        },
      });
    }

    return noticesData;
  }, [
    isLoading,
    errors,
    property,
    assignment,
    userIsPropertyAgentOrManager,
    userIsAdmin,
    userIsContentEditor,
    signatureStatus,
    assignmentWizardData,
    propertyDocuments,
    propertyContents,
    propertyShooting,
    offers,
    reviews?.length,
    user.email,
  ]);

  return {
    data,
    isLoading,
    errors,
    mutate,
  };
};
