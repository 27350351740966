import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { COUNTRY } from '../../../../../constants';

export default Yup.object().shape({
  name: Yup.string().matches(/^[^.]*$/, 'Il nome non può contenere il punto'),
  phoneNumber: Yup.string().test('isValid', 'Inserisci un numero di telefono valido', (value: string | undefined) => !value || isValidPhoneNumber(value, COUNTRY)),
  email: Yup.string().trim().email('Inserisci un indirizzo email valido'),
  fiscalCode: Yup.string().length(16, 'Il codice fiscale è composto da 16 caratteri.'),
  placeOfResidence: Yup.object({
    plateCode: Yup.string().matches(/^[A-Za-z]{2}$/, 'Inserisci una sigla valida'),
  }),
});
