import {
  CommonDocumentTypeValue, OfferDocumentType, OfferDocumentTypeValue, PropertyDocumentType, PropertyDocumentTypeValue, ProspectDocumentType, ProspectDocumentTypeValue, RumorDocumentType,
  RumorDocumentTypeValue,
} from '../domain/types';
import { DocumentReferenceType } from '../domain/types/documentReferenceType';

export interface DocumentMetadata {
  description: string,
  mandatory: boolean,
}

export const propertyDocumentMetadata: Record<PropertyDocumentType, DocumentMetadata> = {
  ASSIGNMENT: {
    description: "Copia dell'incarico di mediazione, debitamente compilato e firmato dai venditori e da Dove.it.",
    mandatory: true,
  },
  IDENTITY_DOCUMENT: {
    description: "Copia dei documenti d'identità di tutti i proprietari dell'immobile oggetto della mediazione.",
    mandatory: true,
  },
  APE: {
    description: "Copia dell'attestato di prestazione energetica (APE) dell'immobile.",
    mandatory: false,
  },
  FISCAL_CODE: {
    description: "Copia del codice fiscale di tutti i proprietari dell'immobile oggetto della mediazione.",
    mandatory: false,
  },
  CADASTRAL_SURVEY: {
    description: "Copia della visura catastale dell'immobile.",
    mandatory: false,
  },
  PLANIMETRY: {
    description: "Copia della planimetria dell'immobile.",
    mandatory: false,
  },
  DEED_OF_PROVENANCE: {
    description: "Copia dell'atto di provenienza dell'immobile.",
    mandatory: false,
  },
  APARTMENT_BLOCK_RULES: {
    description: 'Copia del regolamento condominiale.',
    mandatory: false,
  },
  RESTYLING: {
    description: "Brochure relativa al restyling dell'immobile",
    mandatory: false,
  },
  MORTGAGE_SURVEY: {
    description: "Copia della visura ipotecaria dell'immobile.",
    mandatory: false,
  },
  PROSPECT_REPORT: {
    description: 'Copia del report di valutazione.',
    mandatory: false,
  },
};

export const offerDocumentMetadata: Record<OfferDocumentType, DocumentMetadata> = {
  PURCHASE_PROPOSAL: {
    description: "Copia della proposta d'acquisto, debitamente compilata e firmata da compratori e venditori",
    mandatory: true,
  },
  ANTI_MONEY_LAUNDERING: {
    description: "Copia del documento relativo all'antiriciclaggio.",
    mandatory: true,
  },
  BUYER_IDENTITY_DOCUMENT: {
    description: "Copia dei documenti d'identità di tutti i compratori dell'immobile oggetto della mediazione",
    mandatory: true,
  },
  PENDING_PROPOSAL: {
    description: "Copia della proposta d'acquisto debitamente compilata e firmata dai compratori",
    mandatory: false,
  },
};

export const prospectDocumentMetadata: Record<ProspectDocumentType, DocumentMetadata> = {
  ASSIGNMENT: {
    description: "Copia dell'incarico di mediazione, debitamente compilato e firmato dai venditori e da Dove.it.",
    mandatory: false,
  },
  PLANIMETRY_AUTHORIZATION: {
    description: 'Copia della delega per la richiesta della planimetria.',
    mandatory: false,
  },
  DEED_OF_PROVENANCE: {
    description: "Copia dell'atto di provenienza dell'immobile.",
    mandatory: false,
  },
  IDENTITY_DOCUMENT: {
    description: "Copia dei documenti d'identità di tutti i proprietari dell'immobile oggetto della mediazione.",
    mandatory: false,
  },
  PROSPECT_REPORT: {
    description: 'Copia del report di valutazione.',
    mandatory: false,
  },
  MORTGAGE_SURVEY: {
    description: "Copia della visura ipotecaria dell'immobile.",
    mandatory: false,
  },
  CADASTRAL_SURVEY: {
    description: "Copia della visura catastale dell'immobile.",
    mandatory: false,
  },
};

export const rumorDocumentMetadata: Record<RumorDocumentType, DocumentMetadata> = {
  PROSPECT_REPORT: {
    description: 'Copia del report di valutazione.',
    mandatory: false,
  },
  CADASTRAL_SURVEY: {
    description: "Copia della visura catastale dell'immobile.",
    mandatory: false,
  },
};

export const documentMetadata: {
  [DocumentReferenceType.PROPERTY]: Record<PropertyDocumentType, DocumentMetadata>
  [DocumentReferenceType.PROSPECT]: Record<ProspectDocumentType, DocumentMetadata>
  [DocumentReferenceType.OFFER]: Record<OfferDocumentType, DocumentMetadata>
  [DocumentReferenceType.RUMOR]: Record<RumorDocumentType, DocumentMetadata>
} = {
  PROPERTY: propertyDocumentMetadata,
  PROSPECT: prospectDocumentMetadata,
  OFFER: offerDocumentMetadata,
  RUMOR: rumorDocumentMetadata,
};

export const MANDATORY_PROPERTY_DOCUMENT_TYPES = Object.values(PropertyDocumentTypeValue).filter((documentType) => propertyDocumentMetadata[documentType as PropertyDocumentType].mandatory);
export const OPTIONAL_PROPERTY_DOCUMENT_TYPES = Object.values(PropertyDocumentTypeValue).filter((documentType) => !propertyDocumentMetadata[documentType as PropertyDocumentType].mandatory);

export const MANDATORY_OFFER_DOCUMENT_TYPES = Object.values(OfferDocumentTypeValue).filter((documentType) => offerDocumentMetadata[documentType as OfferDocumentType].mandatory);
export const OPTIONAL_OFFER_DOCUMENT_TYPES = Object.values(OfferDocumentTypeValue).filter((documentType) => !offerDocumentMetadata[documentType as OfferDocumentType].mandatory);

export const OPTIONAL_PROSPECT_DOCUMENT_TYPES = Object.values(ProspectDocumentTypeValue).filter((documentType) => !prospectDocumentMetadata[documentType as ProspectDocumentType].mandatory);

export const OPTIONAL_RUMOR_DOCUMENT_TYPES = Object.values(RumorDocumentTypeValue).filter((documentType) => !rumorDocumentMetadata[documentType as RumorDocumentType].mandatory);

const YOUDOMUS_DOCUMENT_TYPES = [CommonDocumentTypeValue.CADASTRAL_SURVEY, CommonDocumentTypeValue.MORTGAGE_SURVEY];
export const YOUDOMUS_PROPERTY_DOCUMENT_TYPES: PropertyDocumentType[] = YOUDOMUS_DOCUMENT_TYPES;
export const YOUDOMUS_PROSPECT_DOCUMENT_TYPES: ProspectDocumentType[] = YOUDOMUS_DOCUMENT_TYPES;
