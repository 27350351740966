import { wizardOwnershipTypes } from '../../../../domain/types';
import { PropertyOwnershipTypeFormModel, DEFAULT_INITIAL_VALUES as PROPERTY_OWNERSHIP_TYPE_FORM_DEFAULT_INITIAL_VALUES } from '../../../../property/assignment/components/property-ownership-type-form/PropertyOwnershipTypeForm';
import { PropertyInfoFormModel } from '../../../../property/components/property-info-form/PropertyInfoForm';
import {
  OfferPropertyData, OfferPropertyStatus, OfferWizardData, Property,
} from '../../../../providers/api/dtos';
import { boolToBooleanString, booleanStringToBool } from '../../../../utils/boolean-string/booleanString';
import { formatInputDate } from '../../../../utils/form';
import type { FormModel } from './OfferWizardPropertyDetails';

type OfferPropertyInfoFormModel =
  Omit<
  PropertyInfoFormModel,
  | 'technician'
  | 'technicianNeeded'
  | 'propertySource'
  | 'subscriptionPlace'
  | 'propertySourceInfo'
  >;

export const toOfferWizardData = (
  initialData: OfferWizardData,
  propertyOwnershipTypeFormValues: PropertyOwnershipTypeFormModel,
  propertyInfoFormValues: Partial<OfferPropertyInfoFormModel>,
  parentFormValues: FormModel,
): OfferWizardData => {
  const { type, details } = propertyOwnershipTypeFormValues;

  const {
    annualCondominiumExpenses,
    annualRentExpiresAt,
    annualRentFee,
    areSystemsCompliant,
    constraintsDetails,
    legalIssues,
    extraExpensesDeliberated,
    extraExpensesDetails,
    hasOccupancyCertificate,
    isConstraintsFree,
    isUrbanCompliant,
    occupancyCertificateNotes,
    propertyStatus,
    systemsCompliantNotes,
    urbanCompliantNotes,
  } = propertyInfoFormValues;

  const { clearOfObjects, clearOfOccupants } = parentFormValues;

  const newOfferPropertyData: Partial<OfferPropertyData> = {
    ...initialData.property,
    clearOfObjects,
    clearOfOccupants,
    additionalExpenses: {
      ...initialData.property?.additionalExpenses,
      extraDeliberated: booleanStringToBool(extraExpensesDeliberated),
      value: annualCondominiumExpenses?.trim() ? parseInt(annualCondominiumExpenses.trim(), 10) : undefined,
      extraDetails: extraExpensesDetails?.trim() || undefined,
    },
    compliance: {
      ...initialData.property?.compliance,
      isUrbanCompliant: booleanStringToBool(isUrbanCompliant),
      urbanCompliantNotes: urbanCompliantNotes?.trim() || undefined,
      areSystemsCompliant: booleanStringToBool(areSystemsCompliant),
      systemsCompliantNotes: systemsCompliantNotes?.trim() || undefined,
      hasOccupancyCertificate: booleanStringToBool(hasOccupancyCertificate),
      occupancyCertificateNotes: occupancyCertificateNotes?.trim() || undefined,
    },
    rent: {
      ...initialData.property?.rent,
      annualFee: annualRentFee?.trim() ? parseFloat(annualRentFee.trim()) : undefined,
      expiresAt: annualRentExpiresAt ? new Date(annualRentExpiresAt).toISOString() : undefined,
    },
    ownership: {
      ...initialData.property?.ownership,
      type: type || undefined,
      details: details.trim() || undefined,
    },
    constraints: {
      ...initialData.property?.constraints,
      legalIssues: booleanStringToBool(legalIssues),
      isConstraintsFree: booleanStringToBool(isConstraintsFree),
      details: constraintsDetails?.trim() || undefined,
    },
    status: {
      ...initialData.property?.status,
      type: (propertyStatus && propertyStatus in OfferPropertyStatus) ? propertyStatus as OfferPropertyStatus : undefined,
    },
  };

  return {
    ...initialData,
    isDigitallySigned: false,
    property: newOfferPropertyData,
  };
};

export const toPropertyOwnershipTypeFormModel = (
  initialData: OfferWizardData,
  intentProperty?: Property,
): Partial<PropertyOwnershipTypeFormModel> => {
  const { property } = initialData;

  if (property) {
    return {
      ...PROPERTY_OWNERSHIP_TYPE_FORM_DEFAULT_INITIAL_VALUES,
      ...property.ownership,
    };
  }

  if (intentProperty?.ownershipType) {
    const { ownershipType } = intentProperty;

    if (!((wizardOwnershipTypes as ReadonlyArray<string>).includes(ownershipType))) {
      return {
        type: 'ALTRO',
        details: ownershipType,
      };
    }

    return {
      type: ownershipType,
      details: '',
    };
  }

  return PROPERTY_OWNERSHIP_TYPE_FORM_DEFAULT_INITIAL_VALUES;
};

export const toPropertyInfoFormModel = (
  initialData: OfferWizardData,
): Partial<PropertyInfoFormModel> => {
  const { property } = initialData;

  return {
    annualCondominiumExpenses: property?.additionalExpenses?.value ? `${property.additionalExpenses?.value}` : '',
    extraExpensesDetails: property?.additionalExpenses?.extraDetails ?? '',
    extraExpensesDeliberated: boolToBooleanString(property?.additionalExpenses?.extraDeliberated) ?? '',
    isUrbanCompliant: boolToBooleanString(property?.compliance?.isUrbanCompliant) ?? '',
    urbanCompliantNotes: property?.compliance?.urbanCompliantNotes ?? '',
    areSystemsCompliant: boolToBooleanString(property?.compliance?.areSystemsCompliant) ?? '',
    systemsCompliantNotes: property?.compliance?.systemsCompliantNotes ?? '',
    hasOccupancyCertificate: boolToBooleanString(property?.compliance?.hasOccupancyCertificate) ?? '',
    occupancyCertificateNotes: property?.compliance?.occupancyCertificateNotes ?? '',
    annualRentFee: property?.rent?.annualFee ? property?.rent.annualFee.toString() : '',
    annualRentExpiresAt: property?.rent?.expiresAt ? formatInputDate(new Date(property?.rent.expiresAt)) : '',
    legalIssues: boolToBooleanString(property?.constraints?.legalIssues) ?? '',
    isConstraintsFree: boolToBooleanString(property?.constraints?.isConstraintsFree) ?? '',
    constraintsDetails: property?.constraints?.details ?? '',
    propertyStatus: property?.status?.type ?? '',
  };
};
