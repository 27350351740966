export enum ProspectStatus {
  ASSIGNMENT_CREATED = 'ASSIGNMENT_CREATED',
  ONLY_EVALUATION = 'ONLY_EVALUATION',
  OVER_PRICED = 'OVER_PRICED',
  CANCELLED = 'CANCELLED',
  UNSELLABLE = 'UNSELLABLE',
  NOT_INTERESTED = 'NOT_INTERESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SOLD = 'SOLD',
}
