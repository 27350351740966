import {
  BadgeProps, ICON_CHECK_CIRCLE_OUTLINE, ICON_PROGRESS_ALERT, ICON_PROGRESS_CHECK,
} from '@doveit/bricks';
import { InvoiceStatus } from '../domain/types/invoiceStatus';

export const invoiceStatusLabels: {
  [key in InvoiceStatus]: string;
} = {
  [InvoiceStatus.TODO]: 'Da fatturare',
  [InvoiceStatus.TO_COLLECT]: 'Da incassare',
  [InvoiceStatus.COLLECTED]: 'Incassato',
};

export const invoiceStatusColors: Record<InvoiceStatus, BadgeProps['color']> = {
  [InvoiceStatus.TODO]: 'critical',
  [InvoiceStatus.TO_COLLECT]: 'warning',
  [InvoiceStatus.COLLECTED]: 'success',
};

export const invoiceStatusIcons: Record<InvoiceStatus, string> = {
  [InvoiceStatus.TODO]: ICON_PROGRESS_ALERT,
  [InvoiceStatus.TO_COLLECT]: ICON_PROGRESS_CHECK,
  [InvoiceStatus.COLLECTED]: ICON_CHECK_CIRCLE_OUTLINE,
};
