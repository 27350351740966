/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
import {
  Spinner, SpinnerSize, Icon, ICON_IMAGE_OUTLINE, Text,
} from '@doveit/bricks';
import React from 'react';
import * as styles from './PropertyCover.style';

export interface PropertyCoverProps {
  url?: string,
  overlayLabel?: string,
  onClick?: VoidFunction
}

export const PropertyCoverSkeleton: React.FC = () => (
  <div css={styles.placeholder}>
    <div>
      <Spinner
        size={SpinnerSize.BIG}
        color="neutrals.light"
      />
    </div>
  </div>
);

export const PropertyCoverError: React.FC<{ error?: string }> = ({
  error = 'Immagine non caricata',
}) => (
  <div css={styles.placeholder}>
    <div>
      <Text.Body>
        {error}
      </Text.Body>
    </div>
  </div>
);

const PropertyCover: React.FC<PropertyCoverProps> = ({
  url,
  overlayLabel,
  onClick,
}) => (
  <div
    css={styles.base}
    onClick={onClick}
    $clickable={!!onClick}
    role="button"
  >
    {overlayLabel && (
      <div
        data-ref="property-cover-label"
        css={styles.label}
      >
        {overlayLabel}
      </div>
    )}
    {url && (
      <img
        src={url}
        alt="Copertina immobile"
      />
    )}
    {!url && (
      <div css={styles.placeholder}>
        <div>
          <Icon
            size={110}
            path={ICON_IMAGE_OUTLINE}
          />
        </div>
      </div>
    )}
  </div>
);

export default PropertyCover;
