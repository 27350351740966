import { AdditionalService } from '../dtos';
import { getClient, API_ADDITIONAL_SERVICES_PATH } from '../api';

export async function createAdditionalService(additionalService: AdditionalService): Promise<AdditionalService> {
  const client = await getClient();
  const { data } = await client.post<AdditionalService>(API_ADDITIONAL_SERVICES_PATH, additionalService);
  return data;
}

export async function deleteAdditionalService(additionalServiceId: number): Promise<{}> {
  const client = await getClient();
  const { data } = await client.delete<{}>(`${API_ADDITIONAL_SERVICES_PATH}/${additionalServiceId}`);
  return data;
}
