import { Form, FormProps } from '@doveit/bricks';
import React from 'react';
import { BooleanString, booleanStringToBool } from '../../../../utils/boolean-string/booleanString';
import { PASS_THROUGH_VALIDATION_SCHEMA } from '../../../../constants';
import { formatInputDate } from '../../../../utils/form';

export interface OfferWizardMortgageFormProps {
  initialValues?: OfferWizardMortgageFormModel,
  loading?: FormProps<OfferWizardMortgageFormModel>['loading'],
  disabled?: FormProps<OfferWizardMortgageFormModel>['disabled'],
  formRef?: FormProps<OfferWizardMortgageFormModel>['innerRef'],
  onSubmit: FormProps<OfferWizardMortgageFormModel>['onSubmit']
}

export interface OfferWizardMortgageFormModel {
  mandatoryMortgageAcceptance: BooleanString,
  value: string,
  daysToProof: string,
  maxAcceptanceDate: string,
  mortgageRequest: BooleanString,
}

const DEFAULT_INITIAL_VALUES: OfferWizardMortgageFormModel = {
  mandatoryMortgageAcceptance: 'false',
  value: '',
  daysToProof: '',
  maxAcceptanceDate: '',
  mortgageRequest: 'false',
};

const TODAY = formatInputDate(new Date());

export const OfferWizardMortgageForm: React.FC<OfferWizardMortgageFormProps> = (props) => {
  const {
    initialValues, loading, disabled, formRef, onSubmit,
  } = props;

  const mergedInitialValues = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={mergedInitialValues}
      loading={loading}
      disabled={disabled}
      innerRef={formRef}
      validationSchema={PASS_THROUGH_VALIDATION_SCHEMA}
    >
      {({ values, setFieldValue }) => {
        const mortgageAcceptanceEnabled = booleanStringToBool(values.mandatoryMortgageAcceptance);
        const mortgageRequestEnabled = booleanStringToBool(values.mortgageRequest);

        const onMortgageAcceptanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value === 'false') {
            setFieldValue('value', '');
            setFieldValue('maxAcceptanceDate', '');
            setFieldValue('mortgageRequest', '');
            setFieldValue('daysToProof', '');
          }
        };

        const onMortgageRequest = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value === 'false') {
            setFieldValue('daysToProof', '');
          }
        };

        return (
          <>
            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.YesOrNotButtons
                name="mandatoryMortgageAcceptance"
                label="Condizione sospensiva mutuo"
                aria-label="Pulsante per specificare se c'è la condizione sospensiva"
                onChange={onMortgageAcceptanceChange}
              />
            </Form.Item>
            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.PriceField
                name="value"
                label="Importo mutuo"
                aria-label="Campo per inserire il valore del mutuo"
                disabled={!mortgageAcceptanceEnabled}
              />
            </Form.Item>
            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.Input
                name="maxAcceptanceDate"
                label="Data massima delibera"
                type="date"
                min={TODAY}
                aria-label="Campo per inserire la data massima di delibera del mutuo"
                disabled={!mortgageAcceptanceEnabled}
              />
            </Form.Item>
            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.YesOrNotButtons
                name="mortgageRequest"
                label="Condizione presentazione mutuo"
                aria-label="Pulsante per specificare se è stato richiesto il mutuo"
                onChange={onMortgageRequest}
                disabled={!mortgageAcceptanceEnabled}
              />
            </Form.Item>
            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.Input
                name="daysToProof"
                type="number"
                min={0}
                label="Giorni per presentazione mutuo"
                aria-label="Campo per inserire massimo numero di giorni per presentare il mutuo"
                disabled={!mortgageAcceptanceEnabled || !mortgageRequestEnabled}
              />
            </Form.Item>
          </>
        );
      }}

    </Form>
  );
};

export default OfferWizardMortgageForm;
