import React from 'react';
import { Action, ICON_CREATION_OUTLINE, useNotifications } from '@doveit/bricks';
import { RephraseRequest, RephraseResponse } from '../../providers/api/dtos/contentProcessing';
import { rephrase } from '../../providers/api/content-processing/contentProcessingProvider';

export const REPHRASE_TEXT_ERROR_MESSAGE = 'Non è stato possibile riformulare il contenuto';

interface ChildFnProps {
  disabled: boolean,
  rephrase: VoidFunction,
}

export interface RephraseTextActionProps {
  text: string,
  disabled?: boolean,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (res: RephraseResponse) => void,
  onClick?: VoidFunction,
  onError?: (err: Error) => void,
}

const RephraseTextAction: React.FC<RephraseTextActionProps> = ({
  text,
  disabled = false,
  children,
  onClick,
  onSuccess,
  onError,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { addError } = useNotifications();

  const rephraseInfo = React.useCallback(async () => {
    try {
      setIsLoading(true);
      onClick?.();

      const rephraseRequest: RephraseRequest = {
        context: 'REPHRASE',
        text,
      };

      const rephraseResponse = await rephrase(rephraseRequest);

      setIsLoading(false);
      onSuccess?.(rephraseResponse);
    } catch (err) {
      setIsLoading(false);
      onError?.(err as Error);
      addError(REPHRASE_TEXT_ERROR_MESSAGE);
    }
  }, [addError, onClick, onError, onSuccess, text]);

  return children?.({ disabled, rephrase: rephraseInfo }) ?? (
    <Action
      label="Riformula"
      aria-label="Riformula il contenuto"
      disabled={disabled}
      title={disabled ? 'Non è possibile riformulare il contenuto' : 'Riformula il contenuto utilizzando l\'AI'}
      loading={isLoading}
      iconLeft={{ path: ICON_CREATION_OUTLINE }}
      size="S"
      color="primary"
      onClick={rephraseInfo}
    />
  );
};

export default RephraseTextAction;
