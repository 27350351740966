import * as Yup from 'yup';
import { ICON_FILE_DOCUMENT_OUTLINE, StepNavigationItemType } from '@doveit/bricks';
import { AssignmentWizardStepProps } from '../../../property/assignment/components/assignment-wizard/types';
import AssignmentWizardSeller from '../../../property/assignment/components/assignment-wizard/assignment-wizard-seller/AssignmentWizardSeller';
import AssignmentWizardProperty from '../../../property/assignment/components/assignment-wizard/assignment-wizard-property/AssignmentWizardProperty';
import AssignmentWizardPropertyType from '../../../property/assignment/components/assignment-wizard/assignment-wizard-property-type/AssignmentWizardPropertyType';
import AssignmentWizardPropertyInfo from '../../../property/assignment/components/assignment-wizard/assignment-wizard-property-info/AssignmentWizardPropertyInfo';
import AssignmentWizardWithdrawal from '../../../property/assignment/components/assignment-wizard/assignment-wizard-withdrawal/AssignmentWizardWithdrawal';
import AssignmentWizardPlanimetryDelegation from '../../../property/assignment/components/assignment-wizard/assignment-wizard-planimetry-delegation/AssignmentWizardPlanimetryDelegation';
import AssignmentWizardDigitalRequest from '../../../property/assignment/components/assignment-wizard/assignment-wizard-digital-request/AssignmentWizardDigitalRequest';
import {
  AssignmentWizardDetailsValidationSchema, AssignmentWizardSellerValidationSchema, AssignmentWizardPropertyValidationSchema, AssignmentWizardPropertyTypeValidationSchema, AssignmentWizardPropertyInfoValidationSchema, AssignmentWizardWithdrawalValidationSchema, AssignmentWizardPlanimetryDelegationValidationSchema, AssignmentWizardPackagesValidationSchema,
} from './schemas';
import AssignmentWizardDetails from '../../../property/assignment/components/assignment-wizard/assignment-wizard-details/AssignmentWizardDetails';
import AssignmentWizardPackages from '../../../property/assignment/components/assignment-wizard/assignment-wizard-packages/AssignmentWizardPackages';
import AssignmentWizardManualRequest from '../../../property/assignment/components/assignment-wizard/assignment-wizard-manual-request/AssignmentWizardManualRequest';

export const LOAD_ASSIGNMENT_WIZARD_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare alcuni dati.';
export const ASSIGNMENT_ALREADY_CREATED_ERROR_MESSAGE = 'Un mandato è già presente per questa valutazione.';
export const SIGNATURE_ALREADY_REQUESTED_ERROR_MESSAGE = 'Non è possibile procedere con la creazione del mandato digitale. Una procedura di firma digitale è attualmente in corso.';
export const SAVE_ASSIGNMENT_WIZARD_DATA_ERROR_MESSAGE = 'Non è stato possibile salvare i dati del mandato.';
export const AT_LEAST_ONE_ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE_CREATE_ACTION = 'Non è stato possibile generare il mandato. Alcuni step risultano incompleti.';
export const AT_LEAST_ONE_ASSIGNMENT_MANUAL_WIZARD_VALIDATION_ERROR_MESSAGE_CREATE_ACTION = 'Non è stato possibile creare l\'immobile. Alcuni step risultano incompleti.';
export const AT_LEAST_ONE_ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE_EDIT_ACTION = 'Non è stato possibile generare il mandato. Alcuni step risultano incompleti.';
export const AT_LEAST_ONE_ASSIGNMENT_MANUAL_WIZARD_VALIDATION_ERROR_MESSAGE_EDIT_ACTION = 'Non è stato possibile caricare il mandato. Alcuni step risultano incompleti.';
export const AT_LEAST_ONE_ASSIGNMENT_VALIDATION_ERROR_MESSAGE_RENEW_ACTION = 'Non è stato possibile modificare il mandato. Alcuni step risultano incompleti.';
export const ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE = 'È necessario risolvere i seguenti errori prima di poter generare il mandato digitale.';
export const ASSIGNMENT_MANUAL_WIZARD_VALIDATION_ERROR_MESSAGE = 'È necessario risolvere i seguenti errori prima di poter creare l\'immobile.';

export const ASSIGNMENT_DIGITAL_CREATE_WIZARD_NAVIGATION: StepNavigationItemType[] = [
  {
    title: 'Dati mandato',
  },
  {
    title: 'Pacchetti extra',
  },
  {
    title: 'Dati venditore',
  },
  {
    title: 'Dati immobile',
  },
  {
    title: 'Tipo di proprietà',
  },
  {
    title: 'Info immobile',
  },
  {
    title: 'Recesso penale e note',
  },
  {
    title: 'Delega per planimetria',
  },
  {
    title: 'Creazione mandato',
    icon: ICON_FILE_DOCUMENT_OUTLINE,
  },
];

export const ASSIGNMENT_MANUAL_CREATE_WIZARD_NAVIGATION: StepNavigationItemType[] = [
  {
    title: 'Dati mandato',
  },
  {
    title: 'Pacchetti extra',
  },
  {
    title: 'Creazione immobile',
    icon: ICON_FILE_DOCUMENT_OUTLINE,
  },
];

export const ASSIGNMENT_RENEW_WIZARD_NAVIGATION: StepNavigationItemType[] = [
  {
    title: 'Dati mandato',
  },
  {
    title: 'Aggiorna immobile',
    icon: ICON_FILE_DOCUMENT_OUTLINE,
  },
];

export const ASSIGNMENT_EDIT_EXTRA_PACKAGE_WIZARD_NAVIGATION: StepNavigationItemType[] = [
  {
    title: 'Pacchetti extra',
  },
  {
    title: 'Aggiorna pacchetti extra',
    icon: ICON_FILE_DOCUMENT_OUTLINE,
  },
];

export const ASSIGNMENT_DIGITAL_CREATE_WIZARD_STEPS: Record<number, React.FC<AssignmentWizardStepProps>> = {
  0: AssignmentWizardDetails,
  1: AssignmentWizardPackages,
  2: AssignmentWizardSeller,
  3: AssignmentWizardProperty,
  4: AssignmentWizardPropertyType,
  5: AssignmentWizardPropertyInfo,
  6: AssignmentWizardWithdrawal,
  7: AssignmentWizardPlanimetryDelegation,
  8: AssignmentWizardDigitalRequest,
};

export const ASSIGNMENT_MANUAL_CREATE_WIZARD_STEPS: Record<number, React.FC<AssignmentWizardStepProps>> = {
  0: AssignmentWizardDetails,
  1: AssignmentWizardPackages,
  2: AssignmentWizardManualRequest,
};

export const ASSIGNMENT_DIGITAL_RENEW_WIZARD_STEPS: Record<number, React.FC<AssignmentWizardStepProps>> = {
  0: AssignmentWizardDetails,
  1: AssignmentWizardDigitalRequest,
};

export const ASSIGNMENT_MANUAL_RENEW_WIZARD_STEPS: Record<number, React.FC<AssignmentWizardStepProps>> = {
  0: AssignmentWizardDetails,
  1: AssignmentWizardManualRequest,
};

export const ASSIGNMENT_DIGITAL_EDIT_EXTRA_PACKAGES_WIZARD_STEPS: Record<number, React.FC<AssignmentWizardStepProps>> = {
  0: AssignmentWizardPackages,
  1: AssignmentWizardDigitalRequest,
};

export const ASSIGNMENT_MANUAL_EDIT_EXTRA_PACKAGES_WIZARD_STEPS: Record<number, React.FC<AssignmentWizardStepProps>> = {
  0: AssignmentWizardPackages,
  1: AssignmentWizardManualRequest,
};

export const ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_SCHEMAS: Record<number, Yup.AnySchema> = {
  0: AssignmentWizardDetailsValidationSchema,
  1: AssignmentWizardPackagesValidationSchema,
  2: AssignmentWizardSellerValidationSchema,
  3: AssignmentWizardPropertyValidationSchema,
  4: AssignmentWizardPropertyTypeValidationSchema,
  5: AssignmentWizardPropertyInfoValidationSchema,
  6: AssignmentWizardWithdrawalValidationSchema,
  7: AssignmentWizardPlanimetryDelegationValidationSchema,
};

export const ASSIGNMENT_MANUAL_WIZARD_VALIDATION_SCHEMAS: Record<number, Yup.AnySchema> = {
  0: AssignmentWizardDetailsValidationSchema,
  1: AssignmentWizardPackagesValidationSchema,
};

export const ASSIGNMENT_DIGITAL_WIZARD_RENEW_OPTIONS_VALIDATION_SCHEMAS: Yup.AnySchema[] = [
  AssignmentWizardPackagesValidationSchema,
  AssignmentWizardSellerValidationSchema,
  AssignmentWizardPropertyValidationSchema,
  AssignmentWizardPropertyTypeValidationSchema,
  AssignmentWizardPropertyInfoValidationSchema,
  AssignmentWizardWithdrawalValidationSchema,
  AssignmentWizardPlanimetryDelegationValidationSchema,
];

export const ASSIGNMENT_DIGITAL_WIZARD_UPDATE_EXTRA_PACKAGES_ACTION_VALIDATION_SCHEMAS: Yup.AnySchema[] = [
  AssignmentWizardDetailsValidationSchema,
  AssignmentWizardSellerValidationSchema,
  AssignmentWizardPropertyValidationSchema,
  AssignmentWizardPropertyTypeValidationSchema,
  AssignmentWizardPropertyInfoValidationSchema,
  AssignmentWizardWithdrawalValidationSchema,
  AssignmentWizardPlanimetryDelegationValidationSchema,
];
