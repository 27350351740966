import {
  FontWeight, getTypography, ThemedFunctor, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base = () => css`
  position: relative;
  cursor: pointer;
`;

export const count: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.CAPTION, { fontWeight: FontWeight.MEDIUM })}
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${theme.color.background.primary.default.high};
  color: ${theme.color.foreground.primary.default.high};
  height: 1.25rem;
  min-width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25rem;
  border-radius: .625rem;
  transform: translateX(45%) translateY(-45%);
`;
