import {
  FontWeight, getTypography, ThemedFunctor, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({ theme, $open }) => css`
  border-radius: ${theme.remRounding['2XS']};
  overflow: hidden;

  ${$open && `
    background: ${theme.color.background.neutral.default.low};
  `}
`;

export const item: ThemedFunctor = ({
  theme, $nested, $active,
}) => css`
  transition: background-color .2s ease-in-out, color .2s ease-in-out;

  ${$active && `
    background: ${theme.color.background.primary.default.low};
    color: ${theme.color.foreground.primary.default.low};
  `}

  &:hover {
    background: ${theme.color.background[$nested ? 'primary' : 'neutral'].default.low};
    color: ${theme.color.foreground[$nested ? 'primary' : 'neutral'].default.low};
  }

  > a, button {
    ${getTypography(theme, TypographyName.BODY, { fontWeight: $nested ? FontWeight.LIGHT : FontWeight.MEDIUM })}
    padding: ${theme.remSize[$nested ? 100 : 150]} ${theme.remSize[150]};
    width: 100%;
    justify-content: space-between;

    &,span {
      display: flex;
      align-items: center;
      gap: ${theme.remSize[100]};
    }
  }
`;
