import * as Yup from 'yup';

export default Yup.object({
  date: Yup
    .string()
    .required('La data è obbligatoria'),
  time: Yup
    .string()
    .required('L\'ora è obbligatoria'),
}).test({
  test: ({ date, time }) => {
    const datetime = new Date(`${date} ${time}`);

    return datetime.getTime() <= Date.now() ? new Yup.ValidationError(
      'Data e ora inserite non possono essere nel passato',
      datetime,
      'date',
    ) : true;
  },
});
