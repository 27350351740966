import hash from 'object-hash';
import useSWR from 'swr';
import { SWRCustomResponse } from '../../../hooks/types';
import { MatchingSearchCriteria, Prospect } from '../../../providers/api/dtos';
import { getProspectMatchingSearchCriteria } from '../../../providers/api/prospect/prospectProvider';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';

export const PROSPECT_MATCHING_SEARCH_CRITERIA_RID = '/prospect/:id/matching-search-criteria';

export function useProspectMatchingSearchCriteria(
  prospectId?: Prospect['id'],
  pagination: PaginationParams = {},
): SWRCustomResponse<PaginatedResult<MatchingSearchCriteria>> {
  const matchMutate = useMatchMutate();

  const idSpecificRid = [PROSPECT_MATCHING_SEARCH_CRITERIA_RID, prospectId].join();
  const key = prospectId ? [idSpecificRid, hash(pagination)] : null;

  const swrResponse = useSWR(key, () => getProspectMatchingSearchCriteria(prospectId!, pagination));

  return {
    ...swrResponse,
    mutate: () => matchMutate(idSpecificRid),
  };
}
