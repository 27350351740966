import { Agent } from '../../providers/api/dtos';
import { AgentPreviewProps } from '../components/agent-preview/AgentPreview';
import { mapToAgentPreviewStatus } from '../components/constants';
import { calculateMembershipStatus } from '../utils/calculateMembershipStatus';
import { getAgentName } from '../utils/utils';

export interface ToAgentPreviewPropsOptions {
  omit?: {
    status?: boolean,
    name?: boolean,
    needsSupervisor?: boolean,
    inTrial?: boolean,
    photoId?: boolean,
  }
}

export function toAgentPreviewProps(
  agent: Agent,
  options: ToAgentPreviewPropsOptions = {},
): AgentPreviewProps {
  const { omit = {} } = options;
  const {
    name, inTrial, needsSupervisor, photoId, status,
  } = omit;

  return {
    status: status ? undefined : mapToAgentPreviewStatus[calculateMembershipStatus(
      new Date(agent.contract.startDate),
      agent.contract.endDate ? new Date(agent.contract.endDate) : undefined,
    )],
    name: name ? undefined : getAgentName(agent),
    needsSupervisor: needsSupervisor ? undefined : agent.needsSupervisor,
    inTrial: inTrial ? undefined : agent.inTrial,
    photoId: photoId ? undefined : agent.photoId,
  };
}
