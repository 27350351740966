import React from 'react';
import camelCase from 'camelcase';
import { snakeCase } from 'snake-case';
import { useLocation, useNavigate } from 'react-router-dom';

export interface Filters {
  [key: string]: any | undefined,
}

export interface UseFiltersResult<T extends Filters> {
  filters: T,
  addFilters: (filters: Partial<T>, destinationPathname?: string,) => void,
  removeFilter: (key: keyof T | string, destinationPathname?: string,) => void,
  removeFilters: (keys: (keyof T)[] | string[], destinationPathname?: string) => void,
}

export type FiltersMapper<T extends Filters> = {
  [key in keyof T]: (value?: string) => T[key]
};

export default function useFilters<T extends Filters = Filters>(mapper?: FiltersMapper<T>): UseFiltersResult<Partial<T>> {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * @todo might want to use `useParams`
   */
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const filters = React.useMemo(() => {
    if (!mapper) {
      const entries = params.entries();

      return Array.from(entries).reduce((acc, [key, value]) => ({
        ...acc,
        [camelCase(key)]: value,
      }), {} as T);
    }

    return Object.keys(mapper).reduce((acc, key) => {
      const value = params.get(snakeCase(key));

      acc[key as keyof T] = mapper[key](value || undefined);

      return acc;
    }, {} as T);
  }, [params, mapper]);

  const addFilters = React.useCallback((
    toAdd: Partial<T>,
    destinationPathname: string = location.pathname,
  ) => {
    Object.entries(toAdd).forEach(([key, value]) => {
      params.set(snakeCase(key), value);
    });

    navigate(`${destinationPathname}?${params.toString()}`);
  }, [location.pathname, navigate, params]);

  const removeFilter = React.useCallback((
    toDelete: keyof T | string,
    destinationPathname: string = location.pathname,
  ) => {
    params.delete(snakeCase(toDelete as string));

    navigate(`${destinationPathname}?${params.toString()}`);
  }, [location.pathname, navigate, params]);

  const removeFilters = React.useCallback((
    toDelete: (keyof T)[] | string[],
    destinationPathname: string = location.pathname,
  ) => {
    toDelete.forEach((key) => {
      params.delete(snakeCase(key as string));
    });

    navigate(`${destinationPathname}?${params.toString()}`);
  }, [location.pathname, navigate, params]);

  return {
    filters,
    addFilters,
    removeFilter,
    removeFilters,
  };
}
