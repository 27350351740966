import {
  Form, FormActionsAlign, FormHandlers, FormProps, FormSubmitType,
} from '@doveit/bricks';
import React, { MutableRefObject } from 'react';
import { prospectApprovalRequestFormValidationSchema } from './ProspectApprovalRequestForm.schema';
import { INPUT_PLACEHOLDERS } from '../../../constants';

export interface ProspectApprovalRequestFormModel {
  agentEvaluation: string,
}

export interface ProspectApprovalRequestFormProps extends React.AriaAttributes {
  initialValues?: Partial<ProspectApprovalRequestFormModel>,
  loading?: FormProps<ProspectApprovalRequestFormModel>['loading'],
  disabled?: FormProps<ProspectApprovalRequestFormModel>['disabled'],
  innerRef?: MutableRefObject<FormHandlers>,
  onSubmit: FormSubmitType<ProspectApprovalRequestFormModel>,
}

const DEFAULT_INITIAL_VALUES: ProspectApprovalRequestFormModel = {
  agentEvaluation: '',
};

const ProspectApprovalRequestForm: React.FC<ProspectApprovalRequestFormProps> = ({
  initialValues = {},
  innerRef,
  ...rest
}) => {
  const mergedInitialValues: ProspectApprovalRequestFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={prospectApprovalRequestFormValidationSchema}
      innerRef={innerRef}
      {...rest}
    >
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.PriceField
          name="agentEvaluation"
          label="Valutazione"
          aria-label="Campo per inserire la valutazione dell'agente"
          placeholder={INPUT_PLACEHOLDERS.PROPERTY_PRICE}
          size="S"
          required
        />
      </Form.Item>
      {!innerRef && (
        <Form.Actions align={FormActionsAlign.RIGHT}>
          <Form.Submit label="Richiedi approvazione" />
        </Form.Actions>
      )}
    </Form>
  );
};

export default ProspectApprovalRequestForm;
