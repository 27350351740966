import {
  ThemedFunctor, BreakpointQueryName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({ theme }) => css`
  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    > .card-box {
      width: 13rem;
      flex-basis: 13rem;
    }
  }
`;
