import { IntentStatus, OfferStatus } from '../../../domain/types';
import { PaginatedResult, PaginationParams, serializePagination } from '../../pagination';
import { API_INTENT_SERP_PATH, getClient } from '../api';
import { SerpIntent } from '../dtos';
import { serializeParams } from '../utils/serializer';

export interface SerpIntentProviderFilters {
  q?: string,
  status?: IntentStatus | IntentStatus[],
  withOffer?: boolean,
  withAppointment?: boolean,
  latestOfferStatus?: OfferStatus | OfferStatus[],
  agentEmail?: string | string[],
  latestAppointmentGt?: Date,
  latestAppointmentLt?: Date,
  seen?: boolean,
}

export async function getSerpIntent(
  filters: SerpIntentProviderFilters = {},
  pagination: PaginationParams = { size: 20 },
): Promise<PaginatedResult<SerpIntent>> {
  const client = await getClient();

  const { data } = await client.get(API_INTENT_SERP_PATH, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}
