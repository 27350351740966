import { UserRole } from '../types';

export const userRoleLabels: Record<UserRole, string> = {
  [UserRole.CUSTOMER]: 'Cliente',
  [UserRole.AGENT]: 'Agente',
  [UserRole.CONTENT_EDITOR]: 'Content',
  [UserRole.DEVELOPER]: 'Sviluppatore',
  [UserRole.ADMIN]: 'Amministratore',
  [UserRole.CALL_CENTER]: 'Call center',
  [UserRole.FINANCE]: 'Finance',
  [UserRole.HR]: 'HR',
  [UserRole.AREA_MANAGER]: 'Area manager',
  [UserRole.COORDINATOR]: 'Coordinatore',
};
