/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FunctionComponent, useState, useMemo, PropsWithChildren,
} from 'react';
import {
  Action, ActionIconDropdown, ActionProps,
} from '@doveit/bricks';
import CardHeader from './header/CardHeader';
import CardTitle from './title/CardTitle';
import CardBox from './box/CardBox';
import { CardContext } from './CardContext';
import CardInline from './inline/CardInline';
import CardColumnDivider from './column-divider/CardColumnDivider';
import CardRowDivider from './row-divider/CardRowDivider';
import CardOverlayLabel from './overlay-label/CardOverlayLabel';
import CardScrollable from './scrollable/CardScrollable';
import * as styles from './Card.style';

export enum CardStatus {
  SUCCESS = 'status.success',
}

export interface CardProps extends React.HTMLAttributes<HTMLDivElement>, React.AriaAttributes, PropsWithChildren<{
  id?: string,
  status?: CardStatus,
  elevated?: boolean,
  collapsed?: boolean,
  compact?: boolean,
  actions?: React.ReactNode[],
  bottomAction?: Pick<ActionProps, 'label' | 'onClick'>,
}> { }

export interface CardComposition {
  Header: typeof CardHeader,
  Inline: typeof CardInline,
  Box: typeof CardBox,
  Title: typeof CardTitle,
  ColumnDivider: typeof CardColumnDivider,
  RowDivider: typeof CardRowDivider,
  OverlayLabel: typeof CardOverlayLabel,
  Scrollable: typeof CardScrollable,
}

/**
 * @deprecated Use Bricks' Card
 */
const Card: FunctionComponent<CardProps> & CardComposition = ({
  id,
  status,
  elevated,
  collapsed,
  compact = false,
  actions = [],
  bottomAction,
  children,
  ...rest
}) => {
  const [isCollapsed, setCollapsed] = useState(collapsed ?? false);

  const cardContext = useMemo(() => ({
    isCollapsed,
    setCollapsed,
    compact,
  }), [compact, isCollapsed]);

  const validActions = React.useMemo(
    () => actions?.filter((item) => React.isValidElement(item)),
    [actions],
  );

  const hasActions = React.useMemo(
    () => validActions.length > 0,
    [validActions],
  );

  return (
    <CardContext.Provider
      value={cardContext}
    >
      <div
        id={id}
        css={styles.base}
        $status={status}
        $elevated={elevated}
        $hasActions={hasActions}
        {...rest}
      >
        {children}
        {hasActions && (
          <div css={styles.actions}>
            <ActionIconDropdown
              aria-label="card-dropdown-actions"
              label="Azioni"
              size="S"
              emphasis="low"
            >
              {validActions}
            </ActionIconDropdown>
          </div>
        )}
        {bottomAction && (
          <div css={styles.bottomAction}>
            <Action {...bottomAction} size="S" />
          </div>
        )}
      </div>
    </CardContext.Provider>
  );
};

Card.Box = CardBox;
Card.Inline = CardInline;
Card.Header = CardHeader;
Card.Title = CardTitle;
Card.ColumnDivider = CardColumnDivider;
Card.RowDivider = CardRowDivider;
Card.OverlayLabel = CardOverlayLabel;
Card.Scrollable = CardScrollable;

export default Card;
