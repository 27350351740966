import useSWR, { SWRConfiguration } from 'swr';
import hash from 'object-hash';
import { AxiosError } from 'axios';
import { SWRCustomResponse } from '../../../hooks/types';
import { getContactLeads } from '../../../providers/api/contact/contactProvider';
import { Lead } from '../../../providers/api/dtos/lead';
import { LeadsProviderFilters } from '../../../providers/api/lead/leadProvider';
import { useMatchMutate } from '../../../utils/swr/swr';

export const CONTACT_LEADS_RID = '/contacts/:id/leads';

export default function useContactLeads(
  contactId?: string,
  filters?: Pick<LeadsProviderFilters, 'excludeStatus'>,
  options?: SWRConfiguration<Lead[], AxiosError>,
): SWRCustomResponse<Lead[], Error> {
  const matchMutate = useMatchMutate();
  const rid = [CONTACT_LEADS_RID, contactId].join();
  const key = contactId ? [rid, hash(filters || {})] : null;

  const result = useSWR(key, () => getContactLeads(contactId!, filters), options);

  return {
    ...result,
    mutate: () => matchMutate(rid),
  };
}
