import { ActionIcon, ICON_PENCIL_OUTLINE, Typography } from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { formatDate } from '@doveit/hammer';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { userRoleLabels } from '../../../labels';
import { Note } from '../../../providers/api/dtos/note';
import { UserRole } from '../../../types';
import Card, { CardProps } from '../../../components/card/Card';

export interface NoteCardProps extends CardProps {
  note: Note,
  onEdit?: (note: Note) => void
}

const NoteCard: FunctionComponent<NoteCardProps> = ({
  note,
  onEdit,
  ...rest
}) => {
  const { user, mainUserRole, userHasAnyRole } = useRBAC();

  const onEditActionClick = useCallback(() => {
    onEdit!(note);
  }, [onEdit, note]);

  const showEdit = useMemo(
    () => onEdit && user && ((note.author === user.name && note.role === mainUserRole) || userHasAnyRole(UserRole.ADMIN)),
    [onEdit, user, note.author, note.role, mainUserRole, userHasAnyRole],
  );

  return (
    <Card {...rest}>
      <Card.Header>
        <Card.Title>
          <Typography.BODY>
            <strong>
              {note.author}
            </strong>
            {` (${note.role === 'system' ? 'Sistema' : userRoleLabels[note.role]})`}
            {note.date && (
              <>
                {' • '}
                <strong>
                  {formatDate(new Date(note.date), {
                    time: true,
                  })}
                </strong>
              </>
            )}
          </Typography.BODY>
        </Card.Title>
        {showEdit && (
          <ActionIcon
            data-testid="edit-action"
            label="Modifica"
            size="S"
            icon={{ path: ICON_PENCIL_OUTLINE }}
            aria-label="Modifica la nota"
            onClick={onEditActionClick}
          />
        )}
      </Card.Header>
      <Card.Box>
        {note.text}
      </Card.Box>
    </Card>
  );
};

export default NoteCard;
