import { User } from '@auth0/auth0-react';
import { UserRole } from '../../types';

export function hasRole(user: User, role: UserRole) {
  const roles = user['https://backoffice.dove.it/roles'];

  if (!roles) {
    return false;
  }

  return roles.includes(UserRole.DEVELOPER) && role === UserRole.ADMIN
    ? true
    : roles.includes(role);
}

export function hasAnyRole(user: User, roles: UserRole | UserRole[]) {
  return Array.isArray(roles)
    ? roles.some((role) => hasRole(user, role))
    : hasRole(user, roles);
}
