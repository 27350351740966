import { PhoneCallEventDirection } from '../../domain/types';
import { Call, CallMedium } from '../../providers/api/dtos/call';

export const MIN_ANSWERED_DURATION = 15;

export type BuildCallOptions = Pick<Call, 'leadTransformationSpecialist' | 'context'> & {
  duration: number,
  outboundNumber: string
};

export default function buildMobileCall(options: BuildCallOptions): Call {
  return {
    ...options,
    direction: PhoneCallEventDirection.OUTBOUND,
    medium: CallMedium.MOBILE,
    startedAt: new Date(Date.now() - options.duration * 1e3).toISOString(),
    answered: options.duration > MIN_ANSWERED_DURATION,
  };
}
