import {
  Form, FormHandlers, FormProps,
} from '@doveit/bricks';
import React, { MutableRefObject } from 'react';
import * as styles from './EditAgencyDescriptionForm.style';

export interface EditAgencyDescriptionFormModel {
  description: string,
}

export interface EditAgencyDescriptionFormProps {
  initialValues?: Partial<EditAgencyDescriptionFormModel>,
  innerRef?: MutableRefObject<FormHandlers>
  onSubmit: FormProps<EditAgencyDescriptionFormModel>['onSubmit']
}

const EDIT_AGENCY_DESCRIPTION_FORM_DEFAULT_VALUES: EditAgencyDescriptionFormModel = {
  description: '',
};

const EditAgencyDescriptionForm: React.FC<EditAgencyDescriptionFormProps> = ({
  initialValues,
  onSubmit,
  innerRef,
}) => {
  const mergedInitialValues: EditAgencyDescriptionFormModel = React.useMemo(() => ({
    ...EDIT_AGENCY_DESCRIPTION_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      css={styles.form}
      innerRef={innerRef}
    >
      <Form.Item>
        <Form.TextArea
          label="Descrizione"
          aria-label="Campo per inserire la descrizione dell'agenzia"
          name="description"
          rows={2}
        />
      </Form.Item>
    </Form>
  );
};

export default EditAgencyDescriptionForm;
