export enum Department {
  OPERATIONS = 'OPERATIONS',
  SALES = 'SALES',
  HR = 'HR',
  TECH = 'TECH',
  MARKETING = 'MARKETING',
  PRODUCT = 'PRODUCT',
  CONTENT = 'CONTENT',
  CONTACT = 'CONTACT',
  FINANCE = 'FINANCE',
}
