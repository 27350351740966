import React from 'react';
import {
  Action, FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Property, Shooting } from '../../../providers/api/dtos';
import { createShooting, updateShooting } from '../../../providers/api/shooting/shootingProvider';
import { shootingToUpsertShootingFormModel, upsertShootingFormModelToShooting } from './utils';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import UpsertShootingForm, { UpsertShootingFormModel } from '../../../shooting/components/upsert-shooting-form/UpsertShootingForm';
import { upsertShootingValidationSchema } from '../../../shooting/components/upsert-shooting-form/UpsertShootingForm.schema';

interface ChildFnProps {
  upsert: VoidFunction,
  isSaving: boolean,
}

export interface UpsertPropertyShootingActionProps extends React.AriaAttributes {
  propertyId: NonNullable<Property['id']>,
  shooting?: Shooting,
  onSuccess?: (upsertedShooting: Shooting) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const UpsertPropertyShootingAction: React.FC<UpsertPropertyShootingActionProps> = ({
  propertyId,
  shooting,
  onSuccess,
  children,
}) => {
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const isEdit = !!shooting;

  const { userIsAdmin, userIsContentEditor, userIsAgent } = useRBAC();

  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onSubmit = React.useCallback(async (formValues: UpsertShootingFormModel) => {
    try {
      setIsSaving(true);

      const shootingToUpsert = upsertShootingFormModelToShooting(
        formValues, shooting || { propertyId },
      );

      const upsertedShooting = isEdit
        ? await updateShooting(shootingToUpsert)
        : await createShooting(shootingToUpsert);

      setIsSaving(false);
      addSuccess(`I dati dello shooting sono stati ${isEdit ? 'modificati' : 'aggiunti'} con successo.`);
      modal.close();

      onSuccess?.(upsertedShooting);
    } catch (error) {
      setIsSaving(false);
      addError(`Non è stato possibile ${isEdit ? 'modificare' : 'aggiungere'} i dati dello shooting.`);
    }
  }, [addError, addSuccess, modal, onSuccess, propertyId, shooting, isEdit]);

  return (
    <>
      {children
        ? children({
          upsert: modal.open,
          isSaving,
        })
        : (
          <Action
            aria-label={isEdit ? 'Modifica dati shooting' : 'Aggiungi dati shooting'}
            label={isEdit ? 'Modifica dati shooting' : 'Aggiungi dati shooting'}
            size="S"
            onClick={() => modal.open()}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title={isEdit ? 'Modifica dati shooting' : 'Aggiungi dati shooting'}
          aria-label={isEdit ? 'Modale per modificare i dati dello shooting' : 'Modale per aggiungere i dati dello shooting'}
          loading={isSaving}
          footer={(
            <Action
              label={isEdit ? 'Modifica' : 'Aggiungi'}
              color="primary"
              emphasis="high"
              aria-label={isEdit ? 'Pulsante per modificare i dati dello shooting' : 'Pulsante per aggiungere i dati dello shooting'}
              onClick={submitForm}
            />
          )}
        >
          <UpsertShootingForm
            innerRef={formRef}
            size="S"
            initialValues={shootingToUpsertShootingFormModel(shooting)}
            validationSchema={upsertShootingValidationSchema(shooting)}
            hiddenFields={{
              extraServicesPrice: !(userIsAdmin || userIsContentEditor),
              photoPrice: !(userIsAdmin || userIsContentEditor),
              expensesRefund: !(userIsAdmin || userIsContentEditor),
              postProcessingRequest: !isEdit && userIsAgent,
              photoDeliveryDate: !isEdit && userIsAgent,
            }}
            disabledFields={{
              postProcessingRequest: isEdit && userIsAgent,
              photoDeliveryDate: isEdit && userIsAgent,
            }}
            loading={isSaving}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpsertPropertyShootingAction;
