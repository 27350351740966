import useSWR from 'swr';
import hash from 'object-hash';
import {
  AgentsProviderFilters, getAgents, GetAgentOptions,
} from '../../providers/api/agent/agentProvider';
import {
  SortOrder, PaginatedResult, PaginationParams,
} from '../../providers/pagination';
import { MAX_PAGED_SIZE } from '../../constants';
import { useMatchMutate } from '../../utils/swr/swr';
import { SWRCustomResponse } from '../types';
import { Agent } from '../../providers/api/dtos';

export const AGENTS_RID = '/v5/agents';

export const DEFAULT_PAGINATION: PaginationParams = {
  size: MAX_PAGED_SIZE,
  sort: {
    name: SortOrder.ASC,
    surname: SortOrder.ASC,
  },
};

export default function useAgents(
  filters?: AgentsProviderFilters,
  pagination: PaginationParams = DEFAULT_PAGINATION,
  options: GetAgentOptions = {},
): SWRCustomResponse<PaginatedResult<Agent>, Error> {
  const matchMutate = useMatchMutate();

  const key = filters ? [AGENTS_RID, hash({ ...filters, ...pagination, ...options })] : null;
  const result = useSWR(key, () => getAgents(filters, pagination, options));

  return {
    ...result,
    mutate: () => matchMutate(AGENTS_RID),
  };
}
