/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unknown-property */
import {
  Action, Spacing, Icon, ICON_CALENDAR_OUTLINE, ICON_WHATSAPP, ICON_PHONE_OUTLINE, ICON_EMAIL_OUTLINE,
  Text,
} from '@doveit/bricks';
import React from 'react';
import { formatDate, formatEuro } from '@doveit/hammer';
import { isPast } from 'date-fns';
import SetAppointmentOutcome from '../../appointment/containers/set-appointment-outcome/SetAppointmentOutcome';
import DateViewer from '../../components/date-viewer/DateViewer';
import SimpleTable from '../../components/simple-table/SimpleTable';
import { intentMessagesProvider } from '../../containers/send-whatsapp-action/messages/intentMessagesProvider';
import SendWhatsappAction from '../../containers/send-whatsapp-action/SendWhatsappAction';
import { AppointmentStatus, OfferStatus, ReferenceType } from '../../domain/types';
import {
  IntentAppointmentOutcomeNoticeData, Notice, Severity, IntentMissingOffersAppointmentsAndRemindersNoticeData, IntentNoticeData,
  ContactMissingThirdPartiesOptInNoticeData,
  OfferMissingMandatoryDocumentTypesNoticeData,
  OfferMortgageAcceptanceDateNoticeData,
  OfferRefusedNoticeData,
  OfferDeedDateExpiredNoticeData,
  OfferExpiredNoticeData,
  PropertyExistingAcceptedOfferNoticeData,
} from '../../providers/api/dtos/notice';
import UpdateAppointmentStatus from '../../reminders/components/update-appointment-status/UpdateAppointmentStatus';
import { BuildIntentNoticeCtx } from './types';
import UpdateContactInfoAction from '../../contact/containers/update-contact-info-action/UpdateContactInfoAction';
import { UserRole } from '../../types';
import { hasAnyRole } from '../users/usersUtils';
import ManageDocumentAction from '../../document/containers/manage-document-action/ManageDocumentAction';
import { DocumentReferenceType } from '../../domain/types/documentReferenceType';
import ArchiveOfferAction from '../../offer/containers/archive-offer-action/ArchiveOfferAction';
import UpdateMortgageAcceptanceDateAction from '../../offer/containers/update-mortgage-acceptance-date-action/UpdateMortgageAcceptanceDateAction';
import EditOfferDeedAction from '../../offer/containers/edit-offer-deed-action/EditOfferDeedAction';
import UpdateOfferValidUntilDateAction from '../../offer/containers/update-offer-valid-until-date-action/UpdateOfferValidUntilDateAction';
import { goToDetailPage } from '../navigate/utils';
import * as styles from './buildNotice.style';

export function buildIntentAppointmentOutcomeNotice(noticeData: IntentAppointmentOutcomeNoticeData, ctx: BuildIntentNoticeCtx): Notice {
  const hasMoreAppointments = noticeData.data.appointments.length > 1;

  const message = hasMoreAppointments ? "Non hai definito l'esito di più appuntamenti" : "Non hai definito l'esito di un appuntamento";

  return ({
    type: noticeData.type,
    severity: Severity.WARNING,
    priority: 0,
    message,
    actions: hasMoreAppointments ? undefined : [
      <SetAppointmentOutcome
        appointment={noticeData.data.appointments[0]}
        outcome={AppointmentStatus.DONE}
        disableAction={ctx.disableEditIntentAction}
        onSuccess={ctx.mutateNoticeData}
      >
        {({ onClick, loading, disabled }) => (
          <Action
            size="S"
            emphasis="high"
            color="warning"
            label="Segna come fatto"
            loading={loading}
            disabled={disabled}
            onClick={onClick}
          />
        )}
      </SetAppointmentOutcome>,
      <SetAppointmentOutcome
        appointment={noticeData.data.appointments[0]}
        outcome={AppointmentStatus.CANCELLED}
        disableAction={ctx.disableEditIntentAction}
        onSuccess={ctx.mutateNoticeData}
      >
        {({ onClick, loading, disabled }) => (
          <Action
            size="S"
            color="warning"
            label="Segna come annullato"
            loading={loading}
            disabled={disabled}
            onClick={onClick}
          />
        )}
      </SetAppointmentOutcome>,
    ],
    children: hasMoreAppointments
      ? (
        <>
          Definisci l&apos;esito dei seguenti appuntamenti:
          <Spacing margin={[200, 0, 0]}>
            <SimpleTable>
              <SimpleTable.Body>
                {noticeData.data.appointments.map((appointment) => (
                  <SimpleTable.Row key={appointment.id}>
                    <SimpleTable.Cell>
                      <div css={styles.flex}>
                        <Icon path={ICON_CALENDAR_OUTLINE} />
                        <Text.BodySmall>
                          <DateViewer
                            stringDate={appointment.startDate}
                            checkIfToday
                            checkIfTomorrow
                            withStrong
                          />
                        </Text.BodySmall>
                      </div>
                    </SimpleTable.Cell>

                    <SimpleTable.Cell width="22rem">
                      <UpdateAppointmentStatus
                        appointment={appointment}
                        onSuccess={ctx.mutateNoticeData}
                      />
                    </SimpleTable.Cell>
                  </SimpleTable.Row>
                ))}
              </SimpleTable.Body>
            </SimpleTable>
          </Spacing>
        </>
      )
      : `Definisci l'esito dell'appuntamento del ${formatDate(new Date(noticeData.data.appointments[0].startDate), { time: true })}.`,
  });
}

export function buildIntentMissingOffersAppointmentsAndRemindersNotice(noticeData: IntentMissingOffersAppointmentsAndRemindersNoticeData, ctx: BuildIntentNoticeCtx): Notice {
  const { type, data } = noticeData;
  const { isMobile, intent } = ctx;
  return ({
    type,
    severity: Severity.INFO,
    priority: 2,
    message: 'Primo contatto',
    children: 'Non hai ancora inserito un promemoria o un appuntamento.',
    actions: [
      data.contact?.phoneNumber
        ? (
          <SendWhatsappAction
            phoneNumber={data.contact?.phoneNumber}
            name={data.contact?.name}
            fetchMessages={intentMessagesProvider(intent, data.agent)}
          >
            {({ onClick }) => (
              <Action
                label="Invia WhatsApp di presentazione"
                size="S"
                emphasis="high"
                iconLeft={{ path: ICON_WHATSAPP }}
                onClick={onClick}
              />
            )}
          </SendWhatsappAction>
        ) : <div />,
      isMobile
        ? (data.contact.phoneNumber ? (
          <Action
            label="Chiama"
            aria-label="Chiama il contatto"
            size="S"
            iconLeft={{ path: ICON_PHONE_OUTLINE }}
            href={`tel:${data.contact.phoneNumber}`}
          />
        ) : <div />
        ) : (data.contact.email ? (
          <Action
            label="Invia email"
            aria-label="Invia un'email al contatto"
            size="S"
            iconLeft={{ path: ICON_EMAIL_OUTLINE }}
            href={`mailto:${data.contact.email}`}
          />
        ) : <div />
        ),
    ],
  });
}

export function buildOfferMissingMandatoryDocumentTypesNotice(noticeData: OfferMissingMandatoryDocumentTypesNoticeData, ctx: BuildIntentNoticeCtx): Notice {
  const { type, referenceId, data } = noticeData;

  return ({
    type,
    severity: Severity.WARNING,
    priority: 2,
    message: 'Mancano alcuni documenti obbligatori',
    children: (
      <>
        Il venditore ha accettato la proposta. Per finalizzare l&apos;accettazione mancano i seguenti documenti:
        <Spacing margin={[200, 0, 0]}>
          <SimpleTable>
            <SimpleTable.Body>
              {data.documents.map((document) => (
                <SimpleTable.Row key={document.type}>
                  <SimpleTable.Cell>
                    <ManageDocumentAction
                      referenceId={referenceId.toString()}
                      referenceType={DocumentReferenceType.OFFER}
                      documentType={document.type}
                      document={document}
                      onCreate={ctx.mutateNoticeData}
                      onUpdate={ctx.mutateNoticeData}
                    />
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              ))}
            </SimpleTable.Body>
          </SimpleTable>
        </Spacing>
      </>
    ),
  });
}

export function buildContactMissingThirdPartiesOptInNotice(noticeData: ContactMissingThirdPartiesOptInNoticeData, ctx: BuildIntentNoticeCtx): Notice {
  const { type, referenceId, data: { additionalServices } } = noticeData;
  const { user } = ctx;

  if (additionalServices.length > 0) {
    return {
      type,
      severity: Severity.WARNING,
      priority: 1,
      message: 'Consensi e servizi',
      children: "L'utente ha manifestato interesse per uno o più servizi aggiuntivi ma non ha prestato il consenso al trasferimento dei dati.",
      actions: [
        <UpdateContactInfoAction
          contactId={referenceId.toString()}
          modalTitle="Invia un messaggio per ottenere il consenso al trasferimento dei dati"
          userEmail={user?.email}
          hasServices
          canSendEmail={hasAnyRole(user, [UserRole.ADMIN, UserRole.AGENT, UserRole.CALL_CENTER])}
          canSendWhatsApp={hasAnyRole(user, [UserRole.ADMIN, UserRole.AGENT])}
          canSendSMS={hasAnyRole(user, [UserRole.ADMIN, UserRole.AGENT, UserRole.CALL_CENTER])}
        >
          {({ isLoading, openModal }) => (
            <Action
              label="Invia richiesta consenso"
              size="S"
              color="warning"
              emphasis="high"
              onClick={openModal}
              loading={isLoading}
            />
          )}
        </UpdateContactInfoAction>,
      ],
    };
  }

  return {
    type,
    severity: Severity.INFO,
    priority: 0,
    message: 'Consensi e servizi',
    children: "L'utente non ha manifestato interesse per alcun servizio aggiuntivo, né ha dato il consenso al trasferimento dei dati.",
    actions: [
      <UpdateContactInfoAction
        contactId={referenceId.toString()}
        modalTitle="Invia un messaggio per ottenere il consenso al trasferimento dei dati"
        userEmail={user?.email}
        canSendEmail={hasAnyRole(user, [UserRole.ADMIN, UserRole.AGENT, UserRole.CALL_CENTER])}
        canSendWhatsApp={hasAnyRole(user, [UserRole.ADMIN, UserRole.AGENT])}
        canSendSMS={hasAnyRole(user, [UserRole.ADMIN, UserRole.AGENT, UserRole.CALL_CENTER])}
      >
        {({ isLoading, openModal }) => (
          <Action
            label="Invia richiesta consenso"
            size="S"
            emphasis="high"
            onClick={openModal}
            loading={isLoading}
          />
        )}
      </UpdateContactInfoAction>,
    ],
  };
}

export function buildOfferRefusedNotice(noticeData: OfferRefusedNoticeData, ctx: BuildIntentNoticeCtx): Notice {
  const { type, data: { offer } } = noticeData;
  const { intent } = ctx;

  const goToOfferBridgePage = () => {
    window.open(`/intents/${intent.id!}/offer`, '_blank');
  };

  return {
    type,
    message: 'La proposta è stata rifiutata',
    priority: 1,
    severity: Severity.WARNING,
    children: (
      <>
        Il venditore non ha accettato la proposta e ha manifestato la disponibilità ad accettare <strong>{formatEuro(offer.counterOffer!)}</strong>.
      </>
    ),
    actions: [
      <Action
        size="S"
        emphasis="high"
        label="Aggiungi controproposta"
        color="warning"
        onClick={goToOfferBridgePage}
      />,
    ],
  };
}

export function buildOfferMortgageAcceptanceDateNotice(noticeData: OfferMortgageAcceptanceDateNoticeData, ctx: BuildIntentNoticeCtx): Notice {
  const { type, data: { offer } } = noticeData;
  const { mutateNoticeData } = ctx;

  if (!offer.mortgage?.maxAcceptanceDate) {
    throw new Error('You are trying to process an OfferMortgageAcceptanceDate notice for an offer without mortgage data.');
  }

  if (isPast(offer.mortgage.maxAcceptanceDate)) {
    return {
      message: 'Il termine massimo per la delibera del mutuo è stato raggiunto',
      priority: 2,
      severity: Severity.CRITICAL,
      type,
      children: 'Verifica lo stato della pratica, contratta una eventuale proroga della scadenza con i venditori o annulla la proposta.',
      actions: [
        <UpdateMortgageAcceptanceDateAction
          offer={offer}
          onSuccess={mutateNoticeData}
        >
          {({ loading, onClick }) => (
            <Action
              label="Proroga scadenza"
              size="S"
              loading={loading}
              onClick={onClick}
              aria-label="Pulsante per modificare la data massima di delibera del mutuo"
              color="critical"
              emphasis="high"
            />
          )}
        </UpdateMortgageAcceptanceDateAction>,
        <ArchiveOfferAction
          offer={offer}
          toStatus={OfferStatus.MORTGAGE_REFUSED}
          onSuccess={mutateNoticeData}
        >
          {({ updateStatus, isLoading }) => (
            <Action
              label="Archivia causa mutuo"
              emphasis="low"
              size="S"
              disabled={isLoading}
              color="critical"
              onClick={updateStatus}
            />
          )}
        </ArchiveOfferAction>,
      ],
    };
  }

  return {
    message: 'La scadenza della delibera del mutuo si sta avvicinando',
    priority: 1,
    severity: Severity.WARNING,
    type,
    children: 'Verifica lo stato della pratica e contratta una eventuale proroga della scadenza con i venditori.',
    actions: [
      <UpdateMortgageAcceptanceDateAction
        offer={offer}
        onSuccess={mutateNoticeData}
      >
        {({ loading, onClick }) => (
          <Action
            label="Proroga scadenza"
            size="S"
            loading={loading}
            onClick={onClick}
            aria-label="Pulsante per modificare la data massima di delibera del mutuo"
            color="warning"
            emphasis="high"
          />
        )}
      </UpdateMortgageAcceptanceDateAction>,
    ],
  };
}

export function buildOfferDeedDateExpiredNotice(noticeData: OfferDeedDateExpiredNoticeData, ctx: BuildIntentNoticeCtx): Notice {
  const { type, data: { offer } } = noticeData;
  const { mutateNoticeData } = ctx;

  return {
    message: 'La data prevista per il rogito è passata',
    priority: 1,
    severity: Severity.WARNING,
    type,
    children: 'Conferma che il rogito sia avvenuto o modifica la data presunta.',
    actions: [
      <EditOfferDeedAction
        offer={offer}
        onSuccess={mutateNoticeData}
        isDone
      >
        {({ openEditOfferDeedModal }) => (
          <Action
            label="Conferma rogito"
            emphasis="high"
            size="S"
            color="warning"
            onClick={openEditOfferDeedModal}
          />
        )}
      </EditOfferDeedAction>,
      <EditOfferDeedAction
        offer={offer}
        onSuccess={mutateNoticeData}
      >
        {({ openEditOfferDeedModal }) => (
          <Action
            label="Modifica data rogito"
            size="S"
            color="warning"
            onClick={openEditOfferDeedModal}
          />
        )}
      </EditOfferDeedAction>,
    ],
  };
}

export function buildOfferExpired(noticeData: OfferExpiredNoticeData, ctx: BuildIntentNoticeCtx): Notice {
  const { type, data: { offer } } = noticeData;
  const { mutateNoticeData } = ctx;

  return {
    message: 'Il termine massimo per l\'accettazione della proposta è stato raggiunto',
    priority: 2,
    severity: Severity.CRITICAL,
    type,
    children: 'Verifica lo stato della proposta, contratta una eventuale proroga della scadenza con i compratori o rifiuta la proposta.',
    actions: [
      <UpdateOfferValidUntilDateAction
        offer={offer}
        onSuccess={mutateNoticeData}
      >
        {({ loading, onClick }) => (
          <Action
            label="Proroga scadenza"
            size="S"
            loading={loading}
            onClick={onClick}
            aria-label="Pulsante per modificare la data massima di validità dell'offerta"
            color="critical"
            emphasis="high"
          />
        )}
      </UpdateOfferValidUntilDateAction>,
      <ArchiveOfferAction
        offer={offer}
        toStatus={OfferStatus.REFUSED}
        onSuccess={mutateNoticeData}
      >
        {({ updateStatus, isLoading }) => (
          <Action
            label="Rifiuta proposta"
            emphasis="low"
            size="S"
            disabled={isLoading}
            color="critical"
            onClick={updateStatus}
          />
        )}
      </ArchiveOfferAction>,
    ],
  };
}

export function buildPropertyExistingAcceptedOfferNotice(noticeData: PropertyExistingAcceptedOfferNoticeData, ctx: BuildIntentNoticeCtx): Notice {
  const { type, data: { intent } } = noticeData;
  const { isMobile } = ctx;

  return {
    message: 'Proposta accettata esistente',
    priority: 2,
    severity: Severity.WARNING,
    type,
    children: <>Esiste una proposta accettata per l&apos;immobile <strong>DV-{intent.propertyId}</strong> e non è possibile aggiungere una nuova proposta.</>,
    actions: [
      <Action
        label="Visualizza interesse con proposta accettata"
        emphasis="high"
        color="warning"
        size="S"
        onClick={() => goToDetailPage(intent.id!, ReferenceType.INTENT, isMobile)}
      />,
    ],
  };
}

export function buildIntentNotice(noticeData: IntentNoticeData, ctx: BuildIntentNoticeCtx): Notice {
  switch (noticeData.type) {
    case 'CONTACT_MISSING_THIRD_PARTIES_OPT_IN':
      return buildContactMissingThirdPartiesOptInNotice(noticeData, ctx);
    case 'INTENT_APPOINTMENT_OUTCOME':
      return buildIntentAppointmentOutcomeNotice(noticeData, ctx);
    case 'INTENT_MISSING_OFFERS_APPOINTMENTS_AND_REMINDERS':
      return buildIntentMissingOffersAppointmentsAndRemindersNotice(noticeData, ctx);
    case 'OFFER_MISSING_MANDATORY_DOCUMENT_TYPES':
      return buildOfferMissingMandatoryDocumentTypesNotice(noticeData, ctx);
    case 'OFFER_REFUSED':
      return buildOfferRefusedNotice(noticeData, ctx);
    case 'OFFER_MORTGAGE_ACCEPTANCE_DATE':
      return buildOfferMortgageAcceptanceDateNotice(noticeData, ctx);
    case 'OFFER_DEED_DATE_EXPIRED':
      return buildOfferDeedDateExpiredNotice(noticeData, ctx);
    case 'OFFER_EXPIRED':
      return buildOfferExpired(noticeData, ctx);
    case 'PROPERTY_EXISTING_ACCEPTED_OFFER':
      return buildPropertyExistingAcceptedOfferNotice(noticeData, ctx);
    default:
      throw new Error('Unknown intent notice type');
  }
}
