import React from 'react';
import { Action, Portal, useModal } from '@doveit/bricks';
import { Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import ViewAgentsByFilters, { ViewAgentsByFiltersProps } from '../view-agents-by-filters/ViewAgentsByFilters';

interface ChildFnProps {
  viewAgentsByFilters: VoidFunction,
}

export interface ViewAgentsByFiltersActionProps extends ViewAgentsByFiltersProps {
  modalTitle?: string,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const ViewAgentsByFiltersAction: React.FC<ViewAgentsByFiltersActionProps> = ({
  children,
  modalTitle = 'Team',
  ...rest
}) => {
  const modal = useModal<Agent>();

  const viewAgentsByFilters = React.useCallback(() => {
    modal.open();
  }, [modal]);

  return (
    <>
      {children
        ? children({ viewAgentsByFilters })
        : (
          <Action
            aria-label="Visualizza le informazioni degli agenti"
            label="Vedi"
            size="S"
            onClick={viewAgentsByFilters}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title={modalTitle}
        >
          <ViewAgentsByFilters {...rest} />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ViewAgentsByFiltersAction;
