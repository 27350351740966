/* eslint-disable react/no-unknown-property */
import {
  Action,
  ActionIcon,
  HStack,
  ICON_COG_OUTLINE,
  ICON_MAGNIFY,
  ICON_PLUS,
  Tooltip,
} from '@doveit/bricks';
import { pluralizeWord } from '@doveit/hammer';
import React, { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ExitSession from '../../../components/exit-session/ExitSession';
import TopBar from '../../../components/top-bar/TopBar';
import ViewContactToProcessCount from '../../containers/view-contact-to-process-count/ViewContactToProcessCount';
import { PageType } from '../../types';
import * as styles from './ContactTopBar.style';

export interface ContactTopBarProps {
  page: PageType;
  count?: Number;
  onExit?: VoidFunction;
}

const ContactTopBar: FunctionComponent<ContactTopBarProps> = ({
  page,
  onExit,
}) => {
  const navigate = useNavigate();

  const renderLeftSlot = useCallback(
    (withLabel: boolean) => (
      <HStack>
        {page !== PageType.SEARCH && (withLabel ? (
          <Action
            data-ref="search-button"
            iconLeft={{ path: ICON_MAGNIFY }}
            label="Cerca"
            onClick={() => navigate('/contacts/search')}
          />
        ) : (
          <ActionIcon
            data-ref="search-button"
            icon={{ path: ICON_MAGNIFY }}
            label="Cerca"
            onClick={() => navigate('/contacts/search')}
          />
        ))}
        {page !== PageType.ADD && (withLabel ? (
          <Action
            data-ref="add-button"
            iconLeft={{ path: ICON_PLUS }}
            label="Aggiungi"
            onClick={() => navigate('/call-center/contacts/create')}
          />
        ) : (
          <ActionIcon
            data-ref="add-button"
            icon={{ path: ICON_PLUS }}
            label="Aggiungi"
            onClick={() => navigate('/call-center/contacts/create')}
          />
        ))}
      </HStack>
    ), [page, navigate],
  );

  const renderRightSlot = useCallback(
    (withLabel: boolean) => (
      <>
        {page === PageType.PROCESS_CONTACT && (
          <ViewContactToProcessCount>
            {(count) => (
              <Tooltip content={`${count} ${pluralizeWord('contatt', 'o', 'i', count)}${withLabel ? ' da processare' : ''}`}>
                <div>
                  <ExitSession
                    badgeLabel={count}
                    onExit={() => {
                      if (onExit) {
                        onExit();
                      }
                      navigate('/call-center/contacts/process');
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </ViewContactToProcessCount>
        )}
        {(page !== PageType.PROCESS_WELCOME && page !== PageType.PROCESS_CONTACT) && (withLabel ? (
          <Action
            data-ref="process-button"
            iconLeft={{ path: ICON_COG_OUTLINE }}
            label="Attività"
            onClick={() => navigate('/call-center/contacts/process')}
          />
        ) : (
          <ActionIcon
            data-ref="process-button"
            icon={{ path: ICON_COG_OUTLINE }}
            label="Attività"
            onClick={() => navigate('/call-center/contacts/process')}
          />
        ))}
      </>
    ), [page, navigate, onExit],
  );

  return (
    <TopBar
      leftSlot={(
        <>
          <div
            css={styles.slotMobile}
            data-ref="left-slot-mobile"
          >
            {renderLeftSlot(false)}
          </div>
          <div
            css={styles.slotDesktop}
            data-ref="left-slot-desktop"
          >
            {renderLeftSlot(true)}
          </div>
        </>
      )}
      rightSlot={(
        <>
          <div
            css={styles.slotMobile}
            data-ref="right-slot-mobile"
          >
            {renderRightSlot(false)}
          </div>
          <div
            css={styles.slotDesktop}
            data-ref="right-slot-desktop"
          >
            {renderRightSlot(true)}
          </div>
        </>
      )}
    />
  );
};

export default ContactTopBar;
