import React from 'react';
import { LeadStatus } from '../../../domain/types';
import { Lead } from '../../../providers/api/dtos';

export type ComputedLeadStatusCanBeUpdatedTo = LeadStatus | 'ARCHIVED';

export type UseComputedLeadStatusResult = {
  status: LeadStatus,
  canBeUpdated: boolean,
  canBeUpdatedTo: ComputedLeadStatusCanBeUpdatedTo[],
};

export const computedLeadStatusCanBeUpdatedTo: Record<LeadStatus, ComputedLeadStatusCanBeUpdatedTo[]> = {
  [LeadStatus.IN_PROGRESS]: [LeadStatus.APPOINTMENT_SCHEDULED, LeadStatus.KO_ONLY_EVALUATION, 'ARCHIVED'],
  [LeadStatus.KO_ONLY_EVALUATION]: [LeadStatus.APPOINTMENT_SCHEDULED, 'ARCHIVED'],
  [LeadStatus.APPOINTMENT_SCHEDULED]: [],
  [LeadStatus.KO_GEO]: [LeadStatus.IN_PROGRESS],
  [LeadStatus.KO_BUY]: [LeadStatus.IN_PROGRESS],
  [LeadStatus.KO_RENT]: [LeadStatus.IN_PROGRESS],
  [LeadStatus.KO_NOT_INTERESTED]: [LeadStatus.IN_PROGRESS],
  [LeadStatus.KO_NOT_RESIDENTIAL]: [LeadStatus.IN_PROGRESS],
  [LeadStatus.KO_GARAGE]: [LeadStatus.IN_PROGRESS],
  [LeadStatus.KO_CANNOT_CONTACT]: [LeadStatus.IN_PROGRESS],
  [LeadStatus.KO_OTHER]: [LeadStatus.IN_PROGRESS],
  [LeadStatus.KO_WRONG_PHONE_NUMBER]: [LeadStatus.IN_PROGRESS],
  [LeadStatus.KO_TEST]: [],
  [LeadStatus.KO_DUPLICATE]: [],
  [LeadStatus.PRIVATE]: [],
};

export const useComputedLeadStatus = ({ status }: Lead): UseComputedLeadStatusResult => React.useMemo(() => {
  const canBeUpdatedTo = computedLeadStatusCanBeUpdatedTo[status];

  return {
    status,
    canBeUpdated: canBeUpdatedTo.length > 0,
    canBeUpdatedTo,
  };
}, [status]);
