import { INTENT_KO_STATUS, IntentStatus, OfferStatus } from '../../../domain/types';
import { FiltersMapper } from '../../../hooks/use-filters/useFilters';
import { SortOrder } from '../../../providers/pagination';
import { GenericSerpSortOption } from '../../../types';
import {
  IntentSerpPageFilterKeys, IntentSerpPageFilterOption, IntentSerpPageSortKeys, type IntentSerpPageFilters,
} from './types';

export const SERP_INTENTS_ERROR_MESSAGE = 'Non è stato possibile recuperare gli interessi.';
export const EMPTY_SERP_INTENTS = 'Non sono presenti interessi.';

export const DEFAULT_FILTER_OPTION: IntentSerpPageFilterOption = {
  key: 'notSeen',
  label: {
    short: 'Non visti',
    long: 'Non visti',
  },
  value: {
    seen: false,
    status: Object.values(IntentStatus).filter((status) => status !== IntentStatus.KO_PROPERTY_NOT_AVAILABLE),
  },
};

export const ALL_INTENTS_FILTER_OPTION: IntentSerpPageFilterOption = {
  key: 'all',
  label: {
    short: 'Tutti',
    long: 'Tutti gli interessi',
  },
  value: {},
};

export const STATUS_FILTERS: IntentSerpPageFilterOption[] = [
  {
    key: 'active',
    label: {
      short: 'Attivi',
      long: 'Attivi',
    },
    value: {
      status: IntentStatus.IN_PROGRESS,
    },
  },
  {
    key: 'inactive',
    label: {
      short: 'Non attivi',
      long: 'Non attivi',
    },
    value: {
      status: [IntentStatus.BOUGHT, ...INTENT_KO_STATUS],
    },
  },
];

export const APPOINTMENTS_FILTERS: IntentSerpPageFilterOption[] = [
  {
    key: 'futureAppointments',
    label: {
      short: 'Futuri',
      long: 'Con appuntamento futuro',
    },
    value: {
      status: IntentStatus.IN_PROGRESS,
      latestAppointmentGt: new Date(),
    },
  },
  {
    key: 'pastAppointments',
    label: {
      short: 'Passati',
      long: 'Con appuntamento passato',
    },
    value: {
      status: [IntentStatus.IN_PROGRESS, IntentStatus.BOUGHT],
      withOffer: false, // To hide intents with status "In contrattazione"
      latestAppointmentLt: new Date(),
    },
  },
  {
    key: 'withoutAppointments',
    label: {
      short: 'Nessuno',
      long: 'Con nessun appuntamento',
    },
    value: {
      status: IntentStatus.IN_PROGRESS,
      withAppointment: false,
    },
  },
];

export const OFFERS_FILTERS: IntentSerpPageFilterOption[] = [
  {
    key: 'inProgressOffers',
    label: {
      short: 'Presentate',
      long: 'Con proposta presentata',
    },
    value: {
      latestOfferStatus: OfferStatus.IN_PROGRESS,
    },
  },
  {
    key: 'acceptedOffers',
    label: {
      short: 'Accettate',
      long: 'Con proposta accettata',
    },
    value: {
      latestOfferStatus: OfferStatus.ACCEPTED,
    },
  },
];

export const FILTER_OPTIONS: IntentSerpPageFilterOption[] = [
  DEFAULT_FILTER_OPTION,
  ...STATUS_FILTERS,
  ...APPOINTMENTS_FILTERS,
  ...OFFERS_FILTERS,
  ALL_INTENTS_FILTER_OPTION,
];

export const GROUPED_FILTER_OPTIONS = [
  {
    label: 'Stato',
    options: STATUS_FILTERS,
  },
  {
    label: 'Appuntamenti',
    options: APPOINTMENTS_FILTERS,
  },
  {
    label: 'Proposte',
    options: OFFERS_FILTERS,
  },
];

type IntentSerpSortOption = GenericSerpSortOption<IntentSerpPageSortKeys>;

export const DEFAULT_SORT_OPTION: IntentSerpSortOption = {
  key: 'default',
  label: 'In evidenza',
} as const;

export const MOST_RECENT_CREATION_SORT_OPTION: IntentSerpSortOption = {
  key: 'mostRecentIntentCreation',
  label: 'Creazione più recente',
  value: {
    createdAt: SortOrder.DESC,
  },
} as const;

export const SORT_OPTIONS_PER_FILTER: Record<IntentSerpPageFilterKeys, IntentSerpSortOption> = {
  notSeen: {
    ...MOST_RECENT_CREATION_SORT_OPTION,
    key: 'default',
  },
  active: {
    ...MOST_RECENT_CREATION_SORT_OPTION,
    key: 'default',
  },
  inactive: {
    ...MOST_RECENT_CREATION_SORT_OPTION,
    key: 'default',
  },
  pastAppointments: {
    label: 'Appuntamento più recente',
    value: {
      'appointment.date': SortOrder.DESC,
    },
    key: 'default',
  },
  futureAppointments: {
    label: 'Appuntamento più prossimo',
    value: {
      'appointment.date': SortOrder.ASC,
    },
    key: 'default',
  },
  inProgressOffers: {
    label: 'Scadenza proposta decrescente',
    value: {
      'latestOffer.dueDate': SortOrder.DESC,
    },
    key: 'default',
  },
  acceptedOffers: {
    label: 'Scadenza proposta decrescente',
    value: {
      'latestOffer.dueDate': SortOrder.DESC,
    },
    key: 'default',
  },
  all: {
    ...MOST_RECENT_CREATION_SORT_OPTION,
    key: 'default',
  },
  withoutAppointments: {
    ...MOST_RECENT_CREATION_SORT_OPTION,
    key: 'default',
  },
};

export const SORT_OPTIONS: IntentSerpSortOption[] = [
  DEFAULT_SORT_OPTION,
  MOST_RECENT_CREATION_SORT_OPTION, {
    key: 'leastRecentIntentCreation',
    label: 'Creazione meno recente',
    value: {
      createdAt: SortOrder.ASC,
    },
  }, {
    key: 'mostRecentIntentUpdate',
    label: 'Aggiornamento più recente',
    value: {
      updatedAt: SortOrder.DESC,
    },
  }, {
    key: 'leastRecentIntentUpdate',
    label: 'Aggiornamento meno recente',
    value: {
      updatedAt: SortOrder.ASC,
    },
  }, {
    key: 'offerDueDateAsc',
    label: 'Scadenza proposta crescente',
    value: {
      'latestOffer.dueDate': SortOrder.ASC,
    },
  }, {
    key: 'offerDueDateDesc',
    label: 'Scadenza proposta decrescente',
    value: {
      'latestOffer.dueDate': SortOrder.DESC,
    },
  }] as const;

export const FILTERS_MAPPER: FiltersMapper<IntentSerpPageFilters> = {
  q: (v) => v,
  filter: (v) => FILTER_OPTIONS.find(({
    key,
  }) => key === v)?.key || DEFAULT_FILTER_OPTION.key,
  sort: (v) => SORT_OPTIONS.find(({
    key,
  }) => key === v)?.key || DEFAULT_SORT_OPTION.key,
};
