import useSWR from 'swr';
import hash from 'object-hash';
import { Prospect, ProspectSearchParams } from '../../../providers/api/dtos/prospect';
import { searchProspect } from '../../../providers/api/prospect/prospectProvider';
import { PaginatedResult } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

export const SEARCH_PROSPECTS_RID = '/prospects/search';

export default function useSearchProspects(filters?: ProspectSearchParams): SWRCustomResponse<PaginatedResult<Prospect>, Error> {
  const matchMutate = useMatchMutate();
  const key = filters ? [SEARCH_PROSPECTS_RID, hash({
    contactId: filters.contactId?.slice().sort(),
    leadId: filters.leadId?.slice().sort(),
  })] : null;
  const result = useSWR(key, () => searchProspect(filters ?? {}));
  return {
    ...result,
    mutate: () => matchMutate(SEARCH_PROSPECTS_RID),
  };
}
