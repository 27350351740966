import React from 'react';
import { AxiosError } from 'axios';
import { Status as PropertyStatus, OfferStatus } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Document, Property } from '../../../providers/api/dtos';
import useAssignmentByPropertyId from '../use-assignment-by-property-id/useAssignmentByPropertyId';
import useOffersWithStatusExists from '../../../offer/hooks/use-offers-with-status-exists/useOffersWithStatusExists';
import { isAssignmentExpired } from '../../../assignment/utils';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import { canPropertyBeInPreview, canPropertyBeLive } from '../../utils';
import { removeNullAndUndefinedValues } from '../../../utils/array';
import { usePropertyDocuments } from '../use-property-documents/usePropertyDocuments';

type ComputedPropertyStatusCanBeUpdatedTo = PropertyStatus;

export type UseComputedPropertyStatusData = {
  status: PropertyStatus,
  canBeUpdated: boolean,
  canBeUpdatedTo: (status: ComputedPropertyStatusCanBeUpdatedTo) => boolean,
};

export type UseComputedPropertyStatusResult = {
  data?: UseComputedPropertyStatusData | undefined,
  isLoading: boolean,
  errors?: Error[] | undefined,
  mutate: () => Promise<void>,
};

const adminAndContentAllowedStatusUpdates = (
  property: Property,
  inProgressOrAcceptedOfferExists: boolean = false,
  documents: Document[] = [],
): ComputedPropertyStatusCanBeUpdatedTo[] => {
  const canBeInPreview = canPropertyBeInPreview(property);
  const canBeLive = canPropertyBeLive(property, documents);

  const canBeUpdatedTo: Record<PropertyStatus, ComputedPropertyStatusCanBeUpdatedTo[]> = {
    [PropertyStatus.DRAFT]: removeNullAndUndefinedValues([
      canBeInPreview ? PropertyStatus.ANTEPRIMA : undefined,
      PropertyStatus.RITIRATO,
    ]),
    [PropertyStatus.ANTEPRIMA]: removeNullAndUndefinedValues([
      canBeLive ? PropertyStatus.LIVE : undefined,
      PropertyStatus.DRAFT,
      PropertyStatus.RITIRATO,
    ]),
    [PropertyStatus.LIVE]: removeNullAndUndefinedValues([
      inProgressOrAcceptedOfferExists ? PropertyStatus.PROPOSTA : undefined,
      PropertyStatus.DRAFT,
      PropertyStatus.RITIRATO,
    ]),
    [PropertyStatus.PROPOSTA]: removeNullAndUndefinedValues([
      canBeLive ? PropertyStatus.LIVE : undefined,
      PropertyStatus.RITIRATO,
    ]),
    [PropertyStatus.RITIRATO]: [PropertyStatus.DRAFT],
    [PropertyStatus.VENDUTO]: [],
  };

  return canBeUpdatedTo[property.status];
};

const propertyAgentAndManagerAllowedStatusUpdates = (
  property: Property,
  inProgressOrAcceptedOfferExists: boolean = false,
  assignmentExpired: boolean = false,
  documents: Document[] = [],
): ComputedPropertyStatusCanBeUpdatedTo[] => {
  const canBeLive = canPropertyBeLive(property, documents);

  const canBeUpdatedTo: Record<PropertyStatus, ComputedPropertyStatusCanBeUpdatedTo[]> = {
    [PropertyStatus.DRAFT]: [],
    [PropertyStatus.ANTEPRIMA]: removeNullAndUndefinedValues([
      canBeLive ? PropertyStatus.LIVE : undefined,
      PropertyStatus.DRAFT,
    ]),
    [PropertyStatus.LIVE]: removeNullAndUndefinedValues([
      inProgressOrAcceptedOfferExists ? PropertyStatus.PROPOSTA : undefined,
      assignmentExpired ? PropertyStatus.RITIRATO : undefined,
    ]),
    [PropertyStatus.PROPOSTA]: removeNullAndUndefinedValues([
      canBeLive ? PropertyStatus.LIVE : undefined,
    ]),
    [PropertyStatus.RITIRATO]: [PropertyStatus.DRAFT],
    [PropertyStatus.VENDUTO]: [],
  };

  return canBeUpdatedTo[property.status];
};

export const useComputedPropertyStatus = (property: Property): UseComputedPropertyStatusResult => {
  const [errors, setErrors] = React.useState<Error[] | undefined>();

  const handleNewError = React.useCallback((err: AxiosError) => {
    if (err.response?.status === 404) return;

    setErrors((prev) => {
      if (!prev) return [err];
      return [...prev, err];
    });
  }, []);

  const { userIsAdmin, userIsContentEditor } = useRBAC();

  const {
    data: assignment,
    isLoading: isAssignmentLoading,
    mutate: mutateAssignment,
  } = useAssignmentByPropertyId(property.id, { onError: handleNewError });

  const {
    exist: inProgressOrAcceptedOfferExists,
    isLoading: inProgressOrAcceptedOfferExistsLoading,
    mutate: mutateInProgressOrAcceptedOfferExists,
  } = useOffersWithStatusExists({
    propertyId: property.id,
    offerStatus: [OfferStatus.IN_PROGRESS, OfferStatus.ACCEPTED],
  }, { onError: handleNewError });

  const {
    data: documents,
    isLoading: areDocumentsLoading,
    mutate: mutateDocuments,
  } = usePropertyDocuments(
    property.id,
    { onError: handleNewError },
  );

  const isPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(assignment?.agentId);

  const canBeUpdatedTo = React.useMemo(() => {
    if (userIsAdmin || userIsContentEditor) {
      return adminAndContentAllowedStatusUpdates(
        property,
        inProgressOrAcceptedOfferExists,
        documents,
      );
    }

    if (isPropertyAgentOrManager) {
      return propertyAgentAndManagerAllowedStatusUpdates(
        property,
        inProgressOrAcceptedOfferExists,
        assignment ? isAssignmentExpired(assignment) : false,
        documents,
      );
    }

    return [];
  }, [
    assignment,
    inProgressOrAcceptedOfferExists,
    isPropertyAgentOrManager,
    property,
    userIsAdmin,
    userIsContentEditor,
    documents,
  ]);

  const canBeUpdatedToFn = React.useCallback(
    (status: ComputedPropertyStatusCanBeUpdatedTo) => canBeUpdatedTo.includes(status),
    [canBeUpdatedTo],
  );

  const mutate = React.useCallback(async () => {
    await mutateAssignment();
    await mutateInProgressOrAcceptedOfferExists();
    await mutateDocuments();
  }, [mutateAssignment, mutateDocuments, mutateInProgressOrAcceptedOfferExists]);

  const isLoading = React.useMemo(
    () => isAssignmentLoading || inProgressOrAcceptedOfferExistsLoading || areDocumentsLoading,
    [areDocumentsLoading, inProgressOrAcceptedOfferExistsLoading, isAssignmentLoading],
  );

  const data: UseComputedPropertyStatusData | undefined = React.useMemo(() => {
    if (errors || isLoading) return undefined;

    return {
      status: property.status,
      canBeUpdated: canBeUpdatedTo.length > 0,
      canBeUpdatedTo: canBeUpdatedToFn,
    };
  }, [canBeUpdatedTo.length, canBeUpdatedToFn, errors, isLoading, property.status]);

  return React.useMemo(() => ({
    data,
    isLoading,
    errors,
    mutate,
  }), [data, errors, isLoading, mutate]);
};
