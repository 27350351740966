import {
  Spinner, SpinnerSize, Typography, Icon, ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE,
  HStack,
} from '@doveit/bricks';
import React, { FunctionComponent, ReactNode } from 'react';
import Card from '../card/Card';

interface FileUploadCardProps {
  fileName: string,
  error?: boolean,
  success?: boolean,
  actions?: ReactNode,
  message?: string,
}

const FileUploadCard: FunctionComponent<FileUploadCardProps> = ({
  fileName,
  error,
  success,
  actions,
  message,
}) => (
  <Card aria-label="Sezione per l'upload di file">
    <Card.Inline>
      <Card.Box>
        <HStack>
          {(!error && !success) && (
          <Spinner
            size={SpinnerSize.SMALLER}
            color="neutrals.medium"
          />
          )}
          {(error || success) && (
          <Icon
            size={24}
            path={success ? ICON_CHECK_CIRCLE_OUTLINE : ICON_ALERT_CIRCLE_OUTLINE}
            color={success ? 'success.default.low' : 'critical.default.low'}
          />
          )}
          <Typography.BODY>
            <strong>
              {fileName}
            </strong>
            {message && (
            <small> - {message}</small>
            )}
          </Typography.BODY>
        </HStack>
      </Card.Box>
      {actions && (
      <>
        <Card.ColumnDivider />
        <Card.Box fit>
          {actions}
        </Card.Box>
      </>
      )}
    </Card.Inline>
  </Card>
);

export default FileUploadCard;
