import React, {
  FunctionComponent, useCallback, useEffect, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useApplicationNotifications from '../../hooks/use-application-notifications/useApplicationNotifications';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import usePagination from '../../../hooks/use-pagination/usePagination';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { GenericPageLayoutWrapperWidth } from '../../../layouts/generic-page-layout/wrapper/GenericPageLayoutWrapper';
import ApplicationNotificationCard from '../../components/application-notification-card/ApplicationNotificationCard';
import { buildPrintableApplicationNotification } from '../../mappers/buildPrintableApplicationNotification';
import { DEFAULT_APPLICATION_NOTIFICATIONS_PAGINATION, markAllApplicationNotificationsAsRead } from '../../../providers/api/application-notification/applicationNotificationProvider';
import ApplicationNotificationCardSkeleton from '../../components/application-notification-card/ApplicationNotificationCard.skeleton';
import { useApplicationNotificationsContext } from '../../containers/application-notifications-provider/ApplicationNotificationsProvider';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';

export const LOAD_NOTIFICATIONS_ERROR_MESSAGE = 'Non è stato possibile caricare le notifiche';

const ViewApplicationNotificationsPage: FunctionComponent = () => {
  const { newNotificationsCount, refreshNotificationsCount } = useApplicationNotificationsContext();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { page, goToPage } = usePagination('page', scrollRef);

  const { data: notifications, error: notificationsError } = useApplicationNotifications({
    ...DEFAULT_APPLICATION_NOTIFICATIONS_PAGINATION,
    page,
  });

  useEffect(() => {
    if (newNotificationsCount && notifications && notifications.totalElements > 0) {
      markAllApplicationNotificationsAsRead({
        notificationDate: notifications.content[0].notificationDate,
      }).then(() => {
        refreshNotificationsCount();
      }).catch((_) => undefined);
    }
    // `refreshNotificationsCount` reference changes on every render and it may cause an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotificationsCount, notifications]);

  const redirectToUrl = useCallback((url: string) => () => {
    if (url.startsWith('http')) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  }, [navigate]);

  if (notificationsError) {
    return (<GenericErrorPage title={LOAD_NOTIFICATIONS_ERROR_MESSAGE} />);
  }

  if (!notifications) {
    return (
      <GenericPageLayout maxWrapperWidth={GenericPageLayoutWrapperWidth.SMALL}>
        <GenericPageLayout.Content>
          <ApplicationNotificationCardSkeleton />
          <ApplicationNotificationCardSkeleton />
          <ApplicationNotificationCardSkeleton />
        </GenericPageLayout.Content>
      </GenericPageLayout>
    );
  }

  return (
    <GenericPageLayout maxWrapperWidth={GenericPageLayoutWrapperWidth.SMALL}>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent>
          <PaginatedList
            {...notifications}
            goToPage={goToPage}
          >
            {notifications.content.map((notification) => {
              const printableNotification = buildPrintableApplicationNotification(notification);

              if (!printableNotification) {
                return null;
              }

              return (
                <ApplicationNotificationCard
                  key={printableNotification.id}
                  {...printableNotification}
                  onClick={printableNotification.url
                    ? redirectToUrl(printableNotification.url)
                    : undefined}
                  actions={printableNotification.actions && printableNotification.actions.map((action) => ({
                    ...action,
                    onClick: redirectToUrl(action.url),
                  }))}
                />
              );
            })}
          </PaginatedList>
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default ViewApplicationNotificationsPage;
