import { FormHandlers, Message, useNotifications } from '@doveit/bricks';
import React from 'react';
import { Contact, Rumor } from '../../../providers/api/dtos';
import useContact from '../../../contact/hooks/use-contact/useContact';
import useGoogleMapsAPI from '../../../hooks/use-google-maps-api/useGoogleMapsAPI';
import { SearchContactFormModel } from '../../../contact/components/search-contact-form/SearchContactForm';
import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import RumorForm, { RumorFormModel } from '../../components/rumor-form/RumorForm';
import createOrUpdateContact from '../../../contact/utils/createOrUpdateContact';
import { geolocate } from '../../../providers/public-api/location/locationProvider';
import { createRumor } from '../../../providers/api/rumor/rumorProvider';
import { RumorStatus } from '../../../domain/types';

export interface CreateRumorProps {
  contactId?: string,
  formRef?: React.MutableRefObject<FormHandlers>,
  onSuccess?: (rumor: Rumor) => void,
}

export const CREATE_RUMOR_SUCCESS_MESSAGE = 'La notizia è stata aggiunta con successo. <a href="__LINK__" target="_blank">Visualizza</a>';
export const CREATE_RUMOR_ERROR_MESSAGE = 'Non è stato possibile aggiungere la notizia.';
export const INITIAL_CONTACT_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni del contatto. Riprova inserendo i dati manualmente.';

const CreateRumor: React.FC<CreateRumorProps> = ({
  contactId,
  formRef,
  onSuccess,
}) => {
  const [selectedContact, setSelectedContact] = React.useState<Contact>();
  const [overrideValues, setOverrideValues] = React.useState<SearchContactFormModel>();
  const [isSaving, setIsSaving] = React.useState(false);

  const { addSuccess, addError } = useNotifications();
  const currentAgent = useCurrentAgent();
  const { getPlaceDetails } = useGoogleMapsAPI();

  const { error: initialContactError } = useContact(contactId, {
    onSuccess: setSelectedContact,
  });

  const onSubmit = React.useCallback(async (values: RumorFormModel) => {
    try {
      setIsSaving(true);

      let contact: Contact | undefined;

      if (selectedContact || overrideValues) {
        contact = await createOrUpdateContact(overrideValues, selectedContact);
      }

      const place = values.propertyAddress
        ? await getPlaceDetails(values.propertyAddress.placeId)
          .catch((_) => undefined)
        : undefined;

      const { suburb } = (place && place.latitude && place.longitude)
        ? await geolocate({
          latitude: `${place.latitude}`,
          longitude: `${place.longitude}`,
        }).catch((_) => ({ suburb: undefined }))
        : { suburb: undefined };

      const rumor = await createRumor({
        status: RumorStatus.IN_PROGRESS,
        contactId: contact?.id ?? undefined,
        agentId: values.agent?.id!,
        originAgentId: currentAgent?.id! ?? values.agent?.id!, // if user is not an agent use assigned agent
        description: values.description!,
        propertyAddress: values.propertyAddress?.description,
        propertyType: values.propertyType,
        propertyStatus: values.propertyStatus,
        propertySize: values.propertySize ? parseInt(values.propertySize, 10) : undefined,
        propertyFloor: values.propertyFloor ? parseFloat(values.propertyFloor) : undefined,
        hasActiveReminder: false,
        locality: place?.locality,
        area: suburb?.area,
        latitude: place?.latitude,
        longitude: place?.longitude,
      });

      setIsSaving(false);
      addSuccess(CREATE_RUMOR_SUCCESS_MESSAGE.replace('__LINK__', `/rumors/${rumor.id}`), 5000);
      onSuccess?.(rumor);
    } catch (error) {
      setIsSaving(false);
      addError(CREATE_RUMOR_ERROR_MESSAGE);
    }
  }, [selectedContact, overrideValues, getPlaceDetails, currentAgent?.id, addSuccess, onSuccess, addError]);

  const onContactChange = React.useCallback((contactData?: Contact, search?: SearchContactFormModel) => {
    setSelectedContact(contactData);
    setOverrideValues(search);
  }, []);

  return (
    <>
      {initialContactError && (
        <Message
          message={INITIAL_CONTACT_LOAD_ERROR_MESSAGE}
          type="critical"
          boxed
        />
      )}
      <RumorForm
        onSubmit={onSubmit}
        initialValues={{ agent: currentAgent }}
        loading={isSaving}
        innerRef={formRef}
        onContactChange={onContactChange}
      />
    </>
  );
};

export default CreateRumor;
