import React, { FunctionComponent, useState, useCallback } from 'react';
import { HStack } from '@doveit/bricks';
import { AppointmentStatus } from '../../../domain/types';
import { Appointment } from '../../../providers/api/dtos/appointment';
import EditAppointmentStatusButton, { EditAppointmentStatusButtonProps } from '../edit-appointment-status-button/EditAppointmentStatusButton';

export interface EditAppointmentStatusActionBarProps {
  appointment: Appointment,
  onSaving?: EditAppointmentStatusButtonProps['onSaving'],
  onSuccess?: EditAppointmentStatusButtonProps['onSuccess'],
  onError?: EditAppointmentStatusButtonProps['onError'],
}

const EditAppointmentStatusActionBar: FunctionComponent<EditAppointmentStatusActionBarProps> = ({
  appointment,
  onSaving,
  onSuccess,
  onError,
}) => {
  const [loading, setLoading] = useState<AppointmentStatus | undefined>();
  const isScheduledInTheFuture = (new Date(appointment.startDate)).getTime() > Date.now();

  const onSaveStart = useCallback((changed: Appointment, status: AppointmentStatus) => {
    setLoading(status);
    if (onSaving) {
      onSaving(changed, status);
    }
  }, [onSaving]);

  const onSaveSuccess = useCallback((changed: Appointment, status: AppointmentStatus) => {
    setLoading(undefined);

    if (onSuccess) {
      onSuccess(changed, status);
    }
  }, [onSuccess]);

  const onSaveError = useCallback((err: Error, message: string) => {
    setLoading(undefined);
    if (onError) {
      onError(err, message);
    }
  }, [onError]);

  return (
    <HStack>
      <EditAppointmentStatusButton
        appointment={appointment}
        to={AppointmentStatus.DONE}
        disabled={loading === AppointmentStatus.CANCELLED || isScheduledInTheFuture}
        onSaving={onSaveStart}
        onSuccess={onSaveSuccess}
        onError={onSaveError}
        expanded
      />
      <EditAppointmentStatusButton
        appointment={appointment}
        to={AppointmentStatus.CANCELLED}
        disabled={loading === AppointmentStatus.DONE}
        onSaving={onSaveStart}
        onSuccess={onSaveSuccess}
        onError={onSaveError}
        expanded
      />
    </HStack>
  );
};

export default EditAppointmentStatusActionBar;
