import { MoneySource, OfferWizardData } from '../../../../providers/api/dtos';
import { boolToBooleanString, booleanStringToBool } from '../../../../utils/boolean-string/booleanString';
import { OfferWizardAntiMoneyLaunderingFormModel } from './OfferWizardAntiMoneyLaunderingForm';

function toMoneySources(antiMoneyLaunderingFormModel: OfferWizardAntiMoneyLaunderingFormModel): MoneySource[] {
  const moneySources = [];
  if (antiMoneyLaunderingFormModel.mortgageMoneySource) {
    moneySources.push(MoneySource.MORTGAGE);
  }
  if (antiMoneyLaunderingFormModel.personalMoneySource) {
    moneySources.push(MoneySource.PERSONAL);
  }
  if (antiMoneyLaunderingFormModel.sellingPersonalPropertiesMoneySource) {
    moneySources.push(MoneySource.SELLING_PERSONAL_PROPERTIES);
  }
  if (antiMoneyLaunderingFormModel.otherMoneySource) {
    moneySources.push(MoneySource.OTHER);
  }
  return moneySources;
}

export function toOfferWizardData(existingData: OfferWizardData, antiMoneyLaunderingFormModel: OfferWizardAntiMoneyLaunderingFormModel): OfferWizardData {
  const excludeAntiMoneyLaundering = !booleanStringToBool(antiMoneyLaunderingFormModel.includeAntiMoneyLaundering);

  return ({
    ...existingData,
    excludeAntiMoneyLaundering,
    antiMoneyLaundering: excludeAntiMoneyLaundering
      ? undefined
      : {
        politicallyExposed: booleanStringToBool(antiMoneyLaunderingFormModel.politicallyExposed),
        relatedToAPoliticallyExposedFigure: booleanStringToBool(antiMoneyLaunderingFormModel.relatedToAPoliticallyExposedFigure),
        moneySources: toMoneySources(antiMoneyLaunderingFormModel),
        notes: antiMoneyLaunderingFormModel.notes?.trim() || undefined,
        otherMoneySourceValue: antiMoneyLaunderingFormModel.otherMoneySourceValue?.trim() || undefined,
      },
  });
}

export function toOfferWizardAntiMoneyLaunderingModel(initialData: OfferWizardData, antiMoneyLaunderingIsNotNeeded: boolean): OfferWizardAntiMoneyLaunderingFormModel {
  if (antiMoneyLaunderingIsNotNeeded) {
    return {
      includeAntiMoneyLaundering: 'false',
      politicallyExposed: '',
      relatedToAPoliticallyExposedFigure: '',
      mortgageMoneySource: false,
      personalMoneySource: false,
      sellingPersonalPropertiesMoneySource: false,
      otherMoneySource: false,
      otherMoneySourceValue: '',
      notes: '',
    };
  }

  const {
    politicallyExposed,
    relatedToAPoliticallyExposedFigure,
    moneySources, otherMoneySourceValue,
    notes,
  } = initialData.antiMoneyLaundering || {};

  return (
    {
      includeAntiMoneyLaundering: boolToBooleanString(!initialData.excludeAntiMoneyLaundering) ?? 'true',
      politicallyExposed: boolToBooleanString(politicallyExposed) || '',
      relatedToAPoliticallyExposedFigure: boolToBooleanString(relatedToAPoliticallyExposedFigure) || '',
      mortgageMoneySource: moneySources?.some((moneySource) => moneySource === MoneySource.MORTGAGE) || false,
      personalMoneySource: moneySources?.some((moneySource) => moneySource === MoneySource.PERSONAL) || false,
      sellingPersonalPropertiesMoneySource: moneySources?.some((moneySource) => moneySource === MoneySource.SELLING_PERSONAL_PROPERTIES) || false,
      otherMoneySource: moneySources?.some((moneySource) => moneySource === MoneySource.OTHER) || false,
      otherMoneySourceValue: otherMoneySourceValue || '',
      notes: notes || '',
    }
  );
}
