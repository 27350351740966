import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { getContactOptIns } from '../../../providers/api/contact/contactProvider';
import { OptIn } from '../../../providers/api/dtos';

export const CONTACT_OPTINS_RID = '/contacts/:id/opt-ins';

export default function useContactOptIns(contactId?: string, options?: SWRConfiguration<OptIn[], AxiosError>): SWRResponse<OptIn[], AxiosError> {
  return useSWR(contactId
    ? [CONTACT_OPTINS_RID, contactId]
    : null, () => getContactOptIns(contactId!), options);
}
