/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, useCallback } from 'react';
import GenericPageLayout from '../../layouts/generic-page-layout/GenericPageLayout';
import Game from './game/Game';
import Leaderboard from './leaderboard/Leaderboard';
import { savePoints, usePoints } from './bowser';
import * as styles from './SPBPage.style';
import useRBAC from '../../hooks/use-rbac/useRBAC';

const SPBPage: FunctionComponent = () => {
  const { user } = useRBAC();
  const { mutate } = usePoints();
  const onEnd = useCallback(async (points: number) => {
    if (!user) {
      return;
    }

    await savePoints(user.email, points);
    mutate();
  }, [user, mutate]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content>
        <div css={styles.logo}>
          <img src="/images/logo-super-paolo-bros.png" alt="logo Super Paolo Bros" />
        </div>
        <div css={styles.game}>
          <Game onEnd={onEnd} />
        </div>
        <Leaderboard />
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default SPBPage;
