import {
  Card, CardSkeleton, DetailItemList, ICON_BULLHORN_OUTLINE, Message,
  Spacing,
  Text,
} from '@doveit/bricks';
import React from 'react';
import { formatSquareMeters } from '@doveit/hammer';
import EditRumorAction from '../../containers/edit-rumor-action/EditRumorAction';
import useRumor from '../../hooks/use-rumor/useRumor';
import { propertyStatusLabels, propertyTypeLabels } from '../../../labels';
import { formatFloor } from '../../../property/utils';

export const RUMOR_LOADING_ERROR_MESSAGE = 'Non è stato possibile caricare la notizia.';

export interface RumorWidgetProps {
  rumorId: number,
  canEdit?: boolean,
}

const RumorWidget: React.FC<RumorWidgetProps> = ({
  rumorId,
  canEdit = false,
}) => {
  const {
    data: rumor,
    error: rumorError,
    isLoading:
    isRumorLoading,
    mutate: mutateRumor,
  } = useRumor(rumorId);

  if (rumorError) {
    return (
      <Card
        aria-label="Errore caricamento notizia"
      >
        <Card.Header
          title="Notizia"
          color="critical"
          icon={{ path: ICON_BULLHORN_OUTLINE }}
        />
        <Card.Content>
          <Message message={RUMOR_LOADING_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  if (!rumor || isRumorLoading) {
    return (
      <CardSkeleton
        aria-label="Notizia in caricamento"
      />
    );
  }

  return (
    <Card aria-label="Sezione della notizia">
      <Card.Header
        title="Notizia"
        icon={{ path: ICON_BULLHORN_OUTLINE }}
        primaryActions={[
          canEdit && (
            <EditRumorAction
              rumor={rumor}
              onSuccess={mutateRumor}
            />
          ),
        ]}
      />
      <Card.Content>
        <Text.Body>
          {rumor.description}
        </Text.Body>

        <Spacing margin={[150, 0]}>
          <Card.Divider />
        </Spacing>

        <DetailItemList columns={2}>
          <DetailItemList.Item label="Tipologia">
            {rumor.propertyType ? propertyTypeLabels[rumor.propertyType] : undefined}
          </DetailItemList.Item>

          <DetailItemList.Item label="Indirizzo">
            {rumor.propertyAddress}
          </DetailItemList.Item>

          <DetailItemList.Item label="Piano">
            {rumor.propertyFloor !== undefined ? formatFloor(rumor.propertyFloor) : undefined}
          </DetailItemList.Item>

          <DetailItemList.Item label="Superificie">
            {rumor.propertySize ? formatSquareMeters(rumor.propertySize) : undefined}
          </DetailItemList.Item>

          <DetailItemList.Item label="Stato immobile">
            {rumor.propertyStatus ? propertyStatusLabels[rumor.propertyStatus] : undefined}
          </DetailItemList.Item>
        </DetailItemList>
      </Card.Content>
    </Card>
  );
};

export default RumorWidget;
