import { API_DOCUMENT_FILES_PATH, getClient } from '../api';
import { DocumentFile } from '../dtos';

export async function createDocumentFile(
  { documentId, createdBy }: Pick<DocumentFile, 'documentId' | 'createdBy'>,
  file: File,
): Promise<DocumentFile> {
  const client = await getClient();

  const formData = new FormData();
  formData.append('multipartFile', file);
  formData.append('documentId', documentId.toString(10));
  formData.append('createdBy', createdBy);

  const { data } = await client.post<DocumentFile>(
    API_DOCUMENT_FILES_PATH,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return data;
}

export async function deleteDocumentFile(documentFileId: number): Promise<void> {
  const client = await getClient();
  await client.delete(`${API_DOCUMENT_FILES_PATH}/${documentFileId}`);
}
