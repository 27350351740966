import { formatEuro } from '@doveit/hammer';
import { Flatten, SelectOption } from '@doveit/bricks';
import { Commissions } from '../../../providers/api/dtos';
import { flattenObject, isKeyOf } from '../../../utils/object/object';
import { getCommissionSchema } from '../../utils/getCommissionSchema';
import { CommissionSchema } from '../../../domain/types';

const agentCommissionsLabels: {
  [K in keyof Flatten<Commissions>]: string
} = {
  fixedSalary: 'Fisso',
  'commissionSplit.personal': 'Personal',
  'commissionSplit.dove': 'Dove',
  'photoPrice.personal': 'Costo foto personal',
  'photoPrice.dove': 'Costo foto Dove',
  overCommissionThreshold: 'Soglia',
  'overCommissionSplit.personal': 'Sopra soglia personal',
  'overCommissionSplit.dove': 'Sopra soglia Dove',
  managementCost: 'Coordinamento',
  fee: 'Fee',
};

const agentCommissionsMappers: {
  [K in keyof Flatten<Commissions>]: (value: Flatten<Commissions>[K]) => string
} = {
  fixedSalary: (v) => formatEuro(v),
  'commissionSplit.personal': (v) => `${Math.round(v * 100).toFixed(0)}%`,
  'commissionSplit.dove': (v) => `${Math.round(v * 100).toFixed(0)}%`,
  'photoPrice.personal': (v) => formatEuro(v),
  'photoPrice.dove': (v) => formatEuro(v),
  overCommissionThreshold: (v) => formatEuro(v),
  'overCommissionSplit.personal': (v) => `${Math.round(v * 100).toFixed(0)}%`,
  'overCommissionSplit.dove': (v) => `${Math.round(v * 100).toFixed(0)}%`,
  managementCost: (v) => `${Math.round(v * 100).toFixed(0)}%`,
  fee: (v) => formatEuro(v),
};

export const buildPrintableCommissionsDetails = (commissions?: Commissions): SelectOption[] => {
  if (!commissions) return [];

  const flattenedCommissions = flattenObject(commissions);

  const worthyCommissionsFields = Object.fromEntries(getCommissionSchema(commissions) === CommissionSchema.CUSTOM
    ? Object.entries(flattenedCommissions)
    : Object
      .entries(flattenedCommissions)
      .filter(([_, value]) => !['0', 0].includes(value)));

  const showOverCommissionSplit = flattenedCommissions.overCommissionThreshold !== 0;

  const details = Object.entries(agentCommissionsLabels)
    .filter(([field, _]) => isKeyOf(worthyCommissionsFields, field))
    .filter(([field, _]) => {
      if (!showOverCommissionSplit && ['overCommissionSplit.personal', 'overCommissionSplit.dove'].includes(field)) {
        return false;
      }

      return true;
    })
    .map(([field, label]) => {
      const value = worthyCommissionsFields[field];

      return {
        label,
        // todo fix this `never`
        value: agentCommissionsMappers[field as keyof typeof agentCommissionsMappers]?.(value as never) || '',
      };
    });

  return details;
};
