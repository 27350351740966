import { getAgentAreasByMunicipalityCodes } from '../../providers/api/agent/agentProvider';
import { AgentArea } from '../../providers/api/dtos/agentArea';
import { Location } from '../../providers/public-api/dtos';
import { getLocationsByMunicipalityCodes } from '../../providers/public-api/location/locationProvider';
import { AgentsPerArea } from '../models/AgentsPerArea';

export const noFilter = () => true;
export const filterByZipCode = (zipCode: string) => (areaZipCode: string) => areaZipCode === zipCode;
export const sortBy = (by: keyof AgentsPerArea) => (a: AgentsPerArea, b: AgentsPerArea) => {
  if (a[by] < b[by]) {
    return -1;
  }

  if (a[by] > b[by]) {
    return 1;
  }

  return 0;
};

async function getData(municipalityCodes: string[]): Promise<[AgentArea[], Location[]]> {
  return Promise.all([
    getAgentAreasByMunicipalityCodes(municipalityCodes),
    getLocationsByMunicipalityCodes(municipalityCodes),
  ]);
}

function groupByMunicipalityCodeAndZipCode(coveredAreas: AgentArea[]): Map<string, AgentArea[]> {
  return coveredAreas.reduce<Map<string, AgentArea[]>>((accumulator, currentValue) => {
    const key = `${currentValue.municipalityCode}__${currentValue.zipCode}`;
    const { agent } = currentValue;
    if (!agent) {
      return accumulator;
    }

    if (accumulator.has(key)) {
      accumulator.get(key)!.push(currentValue);
    } else {
      accumulator.set(key, [currentValue]);
    }

    return accumulator;
  }, new Map());
}

export async function getAgentsPerArea(
  municipalityCodes: string[],
  filter: (zipCode: string) => boolean = noFilter,
  sort: (a: AgentsPerArea, b: AgentsPerArea) => number = sortBy('locality'),
): Promise<AgentsPerArea[]> {
  const result: AgentsPerArea[] = [];
  const [coveredAreas, locations] = await getData(municipalityCodes);
  const grouped = groupByMunicipalityCodeAndZipCode(coveredAreas);

  locations.forEach((location: Location) => {
    location.postalCodes
      .filter(filter)
      .forEach((zipCode) => {
        const key = `${location.municipalityCode}__${zipCode}`;
        const areas = grouped.get(key) || [];
        result.push({
          key,
          zipCode,
          locality: location.name,
          municipalityCode: location.municipalityCode,
          areas,
        });
      });
  });

  return result.sort(sort);
}
