import React from 'react';
import { ActionIcon, ICON_FILE_RESTORE_OUTLINE, useNotifications } from '@doveit/bricks';
import {
  Signature, SignatureStatus, AssignmentWizardData, Prospect,
} from '../../../providers/api/dtos';
import { reactivateAssignmentSignatureRequest } from '../../../providers/api/assignment/assignmentProvider';

export const REACTIVATE_ASSIGNMENT_SIGNATURE_REQUEST_SUCCESS_MESSAGE = 'La richiesta di firma digitale dell\'incarico è stata riattivata con successo e scadrà tra 3 giorni';
export const REACTIVATE_ASSIGNMENT_SIGNATURE_REQUEST_ERROR_MESSAGE = "Non è stato possibile riattivare la richiesta di firma digitale dell'incarico";

interface ChildFnProps {
  reactivateSignatureRequest: VoidFunction,
  isLoading: boolean,
}

export interface ReactivateAssignmentSignatureRequestActionProps {
  prospectId: NonNullable<Prospect['id']>,
  assignmentSignature?: Signature,
  onSuccess?: (reactivatedAssignment: AssignmentWizardData) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const ReactivateAssignmentSignatureRequestAction: React.FC<ReactivateAssignmentSignatureRequestActionProps> = (props) => {
  const {
    prospectId, assignmentSignature, onSuccess, children,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const { addSuccess, addError } = useNotifications();

  const reactivateSignatureRequest = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const reactivatedAssignment = await reactivateAssignmentSignatureRequest(prospectId);

      setIsLoading(false);

      addSuccess(REACTIVATE_ASSIGNMENT_SIGNATURE_REQUEST_SUCCESS_MESSAGE);
      onSuccess?.(reactivatedAssignment);
    } catch (_) {
      setIsLoading(false);
      addError(REACTIVATE_ASSIGNMENT_SIGNATURE_REQUEST_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, prospectId, onSuccess]);

  if (assignmentSignature?.status !== SignatureStatus.EXPIRED) {
    return null;
  }

  return (
    children
      ? children({ reactivateSignatureRequest, isLoading })
      : (
        <ActionIcon
          aria-label="Riattiva la richiesta di firma digitale dell'incarico"
          label="Riattiva"
          onClick={reactivateSignatureRequest}
          icon={{ path: ICON_FILE_RESTORE_OUTLINE }}
          loading={isLoading}
        />
      )
  );
};

export default ReactivateAssignmentSignatureRequestAction;
