export const wrapper = {
  display: 'flex',
  flexDirection: 'row',
  marginHorizontal: -10,
};

export const box = {
  width: '50%',
  paddingHorizontal: 10,
};

export const image = {
  width: '100%',
};
