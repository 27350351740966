import {
  ActionIcon, Form, FormActionsAlign, ICON_ARCHIVE_OUTLINE, Message, Portal, Spacing, useModal, useNotifications,
} from '@doveit/bricks';
import React from 'react';
import { LeadStatus } from '../../../domain/types';
import validationSchema from './ArchiveLeadAction.schema';
import { Lead } from '../../../providers/api/dtos';
import { updateLead } from '../../../providers/api/lead/leadProvider';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { leadStatusLabels } from '../../constants';

export interface ArchiveLeadActionProps {
  lead: Lead,
  disabled?: boolean,
  onSuccess?: (lead: Lead) => void,
  children?: ({ openArchiveModal, disabled }: { openArchiveModal: VoidFunction, disabled?: boolean }) => React.ReactNode,
}

export const ARCHIVE_LEAD_SUCCESS_MESSAGE = 'La lead è stata archiviata con successo';
export const ARCHIVE_LEAD_ERROR_MESSAGE = 'Non è stato possibile archiviare la lead';
export const ARCHIVE_LEAD_INFO_MESSAGE = 'Potrai ripristinare la lead in qualsiasi momento.';

const DEFAULT_INITIAL_VALUES = { status: '' };

const ArchiveLeadAction: React.FC<ArchiveLeadActionProps> = ({
  lead,
  disabled,
  onSuccess,
  children,
}) => {
  const [isSaving, setSaving] = React.useState(false);
  const archiveModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const isDisabled = React.useMemo(
    () => disabled || ![LeadStatus.IN_PROGRESS, LeadStatus.KO_ONLY_EVALUATION].includes(lead.status),
    [disabled, lead.status],
  );

  const openArchiveModal = React.useCallback(
    () => archiveModal.open(),
    [archiveModal],
  );

  const onSubmit = React.useCallback(async (values: { status: string }) => {
    setSaving(true);

    try {
      const updatedLead = await updateLead(lead.id!, {
        ...lead,
        status: values.status as LeadStatus,
      });

      addSuccess(ARCHIVE_LEAD_SUCCESS_MESSAGE);
      archiveModal.close();

      if (onSuccess) {
        onSuccess(updatedLead);
      }
    } catch (error) {
      addError(ARCHIVE_LEAD_ERROR_MESSAGE);
    }

    setSaving(false);
  }, [lead, addSuccess, archiveModal, onSuccess, addError]);

  return (
    <>
      {children
        ? children({ openArchiveModal, disabled: isDisabled })
        : (
          <ActionIcon
            icon={{ path: ICON_ARCHIVE_OUTLINE }}
            label="Archivia"
            aria-label="Archivia lead"
            color="critical"
            disabled={isDisabled}
            onClick={openArchiveModal}
          />
        )}
      <Portal>
        <SimpleModal
          {...archiveModal}
          aria-label="archive-modal"
          title="Archivia lead"
        >
          <Spacing margin={[0, 0, 200]}>
            <Message
              type="info"
              message={ARCHIVE_LEAD_INFO_MESSAGE}
            />
          </Spacing>
          <Form
            initialValues={DEFAULT_INITIAL_VALUES}
            loading={isSaving}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form.Item>
              <Form.Select
                name="status"
                aria-label="Campo per selezionare il motivo dell'archiviazione"
                options={asSelectOptions(leadStatusLabels, { value: '', label: 'Seleziona motivazione', disabled: true })}
              />
            </Form.Item>
            <Form.Actions align={FormActionsAlign.RIGHT}>
              <Form.Submit
                label="Archivia"
                aria-label="submit-button"
              />
            </Form.Actions>
          </Form>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ArchiveLeadAction;
