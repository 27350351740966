import {
  ActionIcon, ICON_CALENDAR_PLUS_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import React, {
  FunctionComponent, ReactChild, useCallback, useMemo, useState,
} from 'react';
import AppointmentForm from '../../../appointment/components/appointment-form/AppointmentForm';
import { getAppointmentEndDate } from '../../../appointment/utils/getAppointmentEndDate';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import useContact from '../../../contact/hooks/use-contact/useContact';
import {
  AppointmentStatus,
  LeadSource,
  LeadStatus,
  LeadWillingness,
  ProspectStatus,
  ReferenceType,
  RumorCompleteness,
  RumorStatus,
} from '../../../domain/types';
import useGoogleMapsAPI from '../../../hooks/use-google-maps-api/useGoogleMapsAPI';
import { getAgent } from '../../../providers/api/agent/agentProvider';
import { createAppointment } from '../../../providers/api/appointment/appointmentProvider';
import { Prospect } from '../../../providers/api/dtos/prospect';
import { Rumor } from '../../../providers/api/dtos/rumor';
import { createLead } from '../../../providers/api/lead/leadProvider';
import { createProspect } from '../../../providers/api/prospect/prospectProvider';
import { updateRumor } from '../../../providers/api/rumor/rumorProvider';
import { formDateTimeToDate } from '../../../utils/form';
import { AppointmentFormModel } from '../../../appointment/models/appointmentFormModel';
import { Agent } from '../../../providers/api/dtos';

export interface ScheduleAppointmentProps {
  rumor: Rumor,
  onSuccess?: (prospect: Prospect) => void,
  disabled?: boolean,
  children?: ({ scheduleAppointment }: { scheduleAppointment: VoidFunction }) => ReactChild,
}

const ScheduleAppointment: FunctionComponent<ScheduleAppointmentProps> = ({
  rumor,
  onSuccess,
  disabled,
  children,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const appointmentModal = useModal();
  const { getAddressSuggestions, getPlaceDetails } = useGoogleMapsAPI();
  const { addSuccess, addError } = useNotifications();
  const [agent, setAgent] = useState<Agent | undefined>();
  const { data: contact } = useContact(rumor?.contactId);

  const isAppointmentSchedulable = useMemo(
    () => contact?.phoneNumber !== undefined,
    [contact],
  );

  const scheduleAppointment = useCallback(async () => {
    try {
      const agentData = await getAgent(rumor.agentId);
      setAgent(agentData);
      appointmentModal.open();
    } catch (error) {
      addError('Non è attualmente possibile creare un appuntamento di valutazione');
    }
  }, [appointmentModal, rumor, addError]);

  const onSubmit = useCallback(async (values: AppointmentFormModel) => {
    setIsSaving(true);

    try {
      const [{ placeId }] = await getAddressSuggestions(rumor.propertyAddress!);
      const place = await getPlaceDetails(placeId);
      const agentId = agent?.id!;
      const lead = await createLead({
        contactId: rumor.contactId!,
        status: LeadStatus.APPOINTMENT_SCHEDULED,
        propertyType: rumor.propertyType!,
        propertyAddress: rumor.propertyAddress!,
        propertyFloor: rumor.propertyFloor!,
        propertySize: rumor.propertySize!,
        propertyStatus: rumor.propertyStatus!,
        propertyFeatures: {},
        locality: place.locality,
        postalCode: place.postalCode,
        plateCode: place.plateCode,
        latitude: place.latitude,
        longitude: place.longitude,
        source: LeadSource.PERSONAL,
        willingness: LeadWillingness.NOW,
        residualMortgage: false,
        otherAgencies: false,
        inheritedProperty: false,
        giftedProperty: false,
        multiOwner: false,
        ownedByCompany: false,
        registryDiscrepancies: false,
        subsidizedHousing: false,
        originAgentId: rumor.originAgentId,
      });

      const prospect = await createProspect({
        id: null,
        leadId: lead.id!,
        contactId: rumor.contactId!,
        agentId,
        status: ProspectStatus.IN_PROGRESS,
        willingness: 3,
        sellability: 3,
      });

      const startDate = formDateTimeToDate(values.date!, values.time!);

      await createAppointment({
        contactId: contact?.id!,
        agentId,
        referenceId: prospect.id!,
        referenceType: ReferenceType.PROSPECT,
        startDate: startDate.toISOString(),
        endDate: getAppointmentEndDate(ReferenceType.PROSPECT, startDate, values.virtual).toISOString(),
        status: AppointmentStatus.TODO,
        virtual: values.virtual,
        notes: values.notes?.trim() || undefined,
      });

      await updateRumor({
        ...rumor,
        prospectId: prospect.id!,
        status: RumorStatus.APPOINTMENT_SCHEDULED,
      });

      setIsSaving(false);
      addSuccess('Appuntamento di valutazione creato con successo.');
      appointmentModal.close();

      if (onSuccess) {
        onSuccess(prospect);
      }
    } catch (error) {
      setIsSaving(false);
      addError('Si è verificato un errore.');
    }
  }, [getAddressSuggestions, rumor, getPlaceDetails, agent?.id, contact?.id, addSuccess, appointmentModal, onSuccess, addError]);

  if (
    rumor.status !== RumorStatus.IN_PROGRESS || rumor.completeness !== RumorCompleteness.COMPLETE
  ) {
    return null;
  }

  return (
    <>
      {children
        ? children({ scheduleAppointment })
        : (
          <ActionIcon
            icon={{ path: ICON_CALENDAR_PLUS_OUTLINE }}
            label="Appuntamento"
            onClick={scheduleAppointment}
            loading={isSaving}
            disabled={disabled || !isAppointmentSchedulable}
          />
        )}
      <Portal>
        <SimpleModal
          {...appointmentModal}
          title="Crea nuovo appuntamento di valutazione"
        >
          <AppointmentForm
            submitLabel="Crea"
            onSubmit={onSubmit}
            initialValues={{}}
            loading={isSaving}
            withVirtual
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ScheduleAppointment;
