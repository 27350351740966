import React from 'react';
import {
  Action,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import EditProspectAction from '../../prospect/containers/edit-prospect-action/EditProspectAction';
import { Notice, ProspectNoticeData, Severity } from '../../providers/api/dtos/notice';
import { BuildProspectNoticeCtx } from './types';
import UpsertReminderAction from '../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import { Appointment, Reminder } from '../../providers/api/dtos';
import { AppointmentStatus, ProspectApprovalStatus, ReferenceType } from '../../domain/types';
import UpsertAppointmentAction from '../../appointment/containers/upsert-appointment-action/UpsertAppointmentAction';
import ConfirmProspectApprovalAction from '../../prospect/containers/confirm-prospect-approval-action/ConfirmProspectApprovalAction';
import EditProspectApprovalAction from '../../prospect/containers/edit-prospect-approval-action/EditProspectApprovalAction';
import RequestProspectApprovalAction from '../../prospect/containers/request-prospect-approval-action/RequestProspectApprovalAction';
import SendNudgeAction from '../../containers/send-nudge-action/SendNudgeAction';

export function buildProspectMissingEvaluationNotice(noticeData: ProspectNoticeData, _: BuildProspectNoticeCtx): Notice {
  const { data: { prospect } } = noticeData;
  return {
    type: 'PROSPECT_MISSING_EVALUATION',
    severity: Severity.WARNING,
    priority: 3,
    message: 'Valutazione mancante',
    children: "Aggiungi la tua valutazione dell'immobile per abilitare l'accesso al matching con i potenziali acquirenti.",
    actions: [
      <EditProspectAction
        prospect={prospect}
      >
        {({ onClick }) => (
          <Action
            label="Aggiungi valutazione"
            color="warning"
            size="S"
            emphasis="high"
            onClick={onClick}
          />
        )}
      </EditProspectAction>,
    ],
  };
}

export function buildProspectNoNextActionDefinedSupervisor(noticeData: ProspectNoticeData, ctx: BuildProspectNoticeCtx): Notice {
  const { data: { prospect } } = noticeData;
  const { onProspectNudgeSent } = ctx;
  return {
    type: 'PROSPECT_NO_NEXT_ACTION_DEFINED_SUPERVISOR',
    severity: Severity.WARNING,
    priority: 2,
    message: 'Non è stata definita una prossima azione',
    children: "Sollecita l'agente con una notifica al fine di definire la prossima azione sulla valutazione.",
    actions: [
      <SendNudgeAction
        reference={prospect}
        referenceType={ReferenceType.PROSPECT}
        referenceLabel="valutazione"
        onSuccess={onProspectNudgeSent}
      >
        {({ sendNudge, disabled, loading }) => (
          <Action
            label="Sollecita"
            aria-label="Bottone per sollecitare un'azione"
            color="warning"
            size="S"
            emphasis="high"
            onClick={sendNudge}
            loading={loading}
            disabled={disabled}
          />
        )}
      </SendNudgeAction>],
  };
}

export function buildProspectNoNextActionDefinedAgent(noticeData: ProspectNoticeData, ctx: BuildProspectNoticeCtx): Notice {
  const { data: { prospect } } = noticeData;
  const { onReminderCreate, onAppointmentCreate } = ctx;

  const reminderToCreate = {
    agentId: prospect.agentId,
    referenceId: prospect.id!,
    referenceType: ReferenceType.PROSPECT,
    resolved: false,
  } as Reminder;

  const appointmentToCreate = {
    agentId: prospect.agentId,
    contactId: prospect.contactId,
    referenceId: prospect.id!,
    referenceType: ReferenceType.PROSPECT,
    status: AppointmentStatus.TODO,
  } as Appointment;

  return {
    type: 'PROSPECT_NO_NEXT_ACTION_DEFINED_AGENT',
    severity: Severity.WARNING,
    priority: 2,
    message: 'Non è stata definita una prossima azione',
    children: 'Definisci la prossima azione sulla valutazione per mantenere alta la probabilità di conversione in mandato.',
    actions: [
      <UpsertReminderAction
        reminder={reminderToCreate}
        onSuccess={onReminderCreate}
        aria-label="Pulsante per aggiungere un promemoria"
      >
        {({ upsert }) => (
          <Action
            label="Aggiungi promemoria"
            aria-label="Pulsante per creare un promemoria"
            size="S"
            color="warning"
            emphasis="high"
            onClick={upsert}
          />
        )}
      </UpsertReminderAction>,
      <UpsertAppointmentAction
        appointment={appointmentToCreate}
        onSuccess={onAppointmentCreate}
      >
        {({ upsert }) => (
          <Action
            label="Aggiungi appuntamento"
            aria-label="Pulsante per creare un appuntamento"
            size="S"
            color="warning"
            emphasis="low"
            onClick={upsert}
          />
        )}
      </UpsertAppointmentAction>,
    ],
  };
}

export function buildProspectPendingApprovalNotice(noticeData: ProspectNoticeData, ctx: BuildProspectNoticeCtx): Notice {
  const { data: { prospect } } = noticeData;
  const { approval } = prospect;
  const { mutateProspect } = ctx;

  return {
    type: 'PROSPECT_PENDING_APPROVAL',
    message: approval?.status === ProspectApprovalStatus.WAITING_FOR_AGENT
      ? 'Valutazione da accettare'
      : 'Richiesta di approvazione valutazione',
    children: `${approval?.status === ProspectApprovalStatus.WAITING_FOR_AGENT ? 'Accetta' : 'Approva'} la valutazione di ${formatEuro(prospect.agentEvaluation!)} o proponi un nuovo valore per poter convertire la valutazione in mandato.`,
    priority: 1,
    severity: Severity.WARNING,
    actions: [
      <ConfirmProspectApprovalAction
        prospect={prospect}
        onSuccess={mutateProspect}
      >
        {({ confirmApproval, isSaving }) => (
          <Action
            label={approval?.status === ProspectApprovalStatus.WAITING_FOR_AGENT ? 'Accetta valutazione' : 'Approva valutazione'}
            aria-label={`Pulsante per ${approval?.status === ProspectApprovalStatus.WAITING_FOR_AGENT ? 'accettare' : 'approvare'} la valutazione proposta`}
            size="S"
            color="warning"
            emphasis="high"
            loading={isSaving}
            onClick={confirmApproval}
          />
        )}
      </ConfirmProspectApprovalAction>,
      <EditProspectApprovalAction
        prospect={prospect}
        onSuccess={mutateProspect}
      >
        {({ editApproval, isSaving }) => (
          <Action
            label="Proponi nuova valutazione"
            aria-label="Pulsante per proporre una valutazione"
            size="S"
            color="warning"
            loading={isSaving}
            onClick={editApproval}
          />
        )}
      </EditProspectApprovalAction>,
    ],
  };
}

export function buildProspectRequestApprovalNotice(noticeData: ProspectNoticeData, ctx: BuildProspectNoticeCtx): Notice {
  const { data: { prospect } } = noticeData;
  const { mutateProspect } = ctx;

  return {
    type: 'PROSPECT_REQUEST_APPROVAL',
    message: 'Richiesta di approvazione valutazione non eseguita',
    children: "Richiedi l'approvazione della valutazione da parte del tuo supervisore per poter convertire la valutazione in mandato.",
    priority: 1,
    severity: Severity.WARNING,
    actions: [
      <RequestProspectApprovalAction
        prospect={prospect}
        onSuccess={mutateProspect}
      >
        {({ requestApproval, isSaving }) => (
          <Action
            label="Richiedi approvazione"
            aria-label="Pulsante per richiedere l'approvazione di una valutazione"
            size="S"
            color="warning"
            emphasis="high"
            loading={isSaving}
            onClick={requestApproval}
          />
        )}
      </RequestProspectApprovalAction>,
    ],
  };
}

export function buildProspectNotice(noticeData: ProspectNoticeData, ctx: BuildProspectNoticeCtx): Notice {
  switch (noticeData.type) {
    case 'PROSPECT_MISSING_EVALUATION':
      return buildProspectMissingEvaluationNotice(noticeData, ctx);
    case 'PROSPECT_NO_NEXT_ACTION_DEFINED_SUPERVISOR':
      return buildProspectNoNextActionDefinedSupervisor(noticeData, ctx);
    case 'PROSPECT_NO_NEXT_ACTION_DEFINED_AGENT':
      return buildProspectNoNextActionDefinedAgent(noticeData, ctx);
    case 'PROSPECT_PENDING_APPROVAL':
      return buildProspectPendingApprovalNotice(noticeData, ctx);
    case 'PROSPECT_REQUEST_APPROVAL':
      return buildProspectRequestApprovalNotice(noticeData, ctx);
    default:
      throw new Error('Unknown prospect notice type');
  }
}
