import * as Yup from 'yup';
import { formatSquareMeters } from '@doveit/hammer';
import { capitalize } from 'lodash';
import { EnergyClass } from '@doveit/bricks';
import {
  RoomType, Status, propertyTypesWithoutFloor, OwnershipType, Aggregator, PropertyType, ResidentialPropertyTypeValue, NonResidentialPropertyTypeValue, HeatingType, HeatingSystem, HeatingSupply, AirConditioningType, AirConditioningMode, KitchenType,
  DocumentStatus,
} from '../../domain/types';
import { Room } from '../../providers/api/dtos/room';
import {
  AirConditioning, Heating, Property, PropertyPreview,
} from '../../providers/api/dtos/property';
import { PropertySection } from '../types';
import {
  airConditioningModeLabels, airConditioningTypeLabels, kitchenTypeLabels, propertyTypeLabels,
} from '../../labels';
import { ownershipTypeLabels } from '../../labels/ownershipTypeLabels';
import { Document, Garage, PublishedOn } from '../../providers/api/dtos';
import { heatingTypeLabels } from '../labels';
import { heatingSystemLabels } from '../../labels/heatingSystemLabels';
import { heatingSupplyLabels } from '../../labels/heatingSupplyLabels';
import { garageTypeLabels } from '../../labels/garageTypeLabels';
import { MANDATORY_PROPERTY_DOCUMENT_TYPES } from '../../document/constants';

export interface ShootingStatus {
  color: 'neutral' | 'primary' | 'info' | 'success' | 'warning' | 'critical',
  text: string,
  icon: string,
}

const COUNT_ROOMS_TYPES: RoomType[] = [
  RoomType.SOGGIORNO,
  RoomType.STANZA_DA_LETTO,
  RoomType.ALTRO,
];

export const APARTMENT_ALIKE_TYPES: PropertyType[] = [
  ResidentialPropertyTypeValue.APPARTAMENTO,
  ResidentialPropertyTypeValue.ATTICO,
  ResidentialPropertyTypeValue.MANSARDA,
  ResidentialPropertyTypeValue.LOFT,
];

export const statusFilterMap: { [key in PropertySection]: Status } = {
  live: Status.LIVE,
  draft: Status.DRAFT,
  offer: Status.PROPOSTA,
  sold: Status.VENDUTO,
  retired: Status.RITIRATO,
  preview: Status.ANTEPRIMA,
};

export const NO_VALUE_SYMBOL = '-';

export const DEFAULT_ADDRESS = 'Via Borromei 6, 20123 Milano';
export const DEFAULT_PHONE_NUMBER = '02 87178289';
export const DEFAULT_COMPANY_NAME = 'Agenzia immobiliare Dove.it s.r.l.';

export function countRooms(rooms: Room[]): number {
  return rooms.reduce(
    (count, room) => (COUNT_ROOMS_TYPES.includes(room.roomType) ? count + 1 : count),
    0,
  );
}

export const floorLabels: {
  [key: number]: string,
} = {
  [-0.5]: 'Seminterrato',
  0: 'Terra',
  0.5: 'Rialzato',
};

export function isPublishedOn(publishedOn: PublishedOn[], aggregator: Aggregator): boolean {
  return publishedOn.some((element) => element.aggregator === aggregator) ?? false;
}

export function hasAStatusVisibleInSerp(status: Status): boolean {
  return status === Status.LIVE || status === Status.VENDUTO || status === Status.PROPOSTA || status === Status.ANTEPRIMA;
}

export function canAddIntentToProperty(status: Status): boolean {
  return status !== Status.RITIRATO && status !== Status.VENDUTO;
}

export function getFloorRange(rooms: Room[]): [number, number] {
  return rooms.reduce((result, room) => (
    [
      Math.min(room.floorNumber, result[0]),
      Math.max(room.floorNumber, result[1]),
    ] as [number, number]
  ), [Infinity, -Infinity]);
}

export function formatFloor(floor: number, shortForm: boolean = false): string {
  switch (floor) {
    case -0.5:
      return shortForm ? 'S' : 'Seminterrato';
    case 0.0:
      return shortForm ? 'T' : 'Terra';
    case 0.5:
      return shortForm ? 'R' : 'Rialzato';
    case Infinity:
    case -Infinity:
      return NO_VALUE_SYMBOL;
    default:
      return shortForm ? `${floor.toFixed(0)}P` : `${floor.toFixed(0)}`;
  }
}

export function formatFloorRange(range: [number, number]): string {
  const formattedMinFloor = formatFloor(range[0]);
  const formattedMaxFloor = formatFloor(range[1]);

  if (formattedMinFloor === formattedMaxFloor) {
    return formattedMinFloor;
  }

  return `${formattedMinFloor}-${formattedMaxFloor}`;
}

export function formatPropertyType(type: PropertyType) {
  return propertyTypeLabels[type];
}

export function formatOwnershipType(ownershipType: OwnershipType) {
  return ownershipTypeLabels[ownershipType];
}

export function getPropertyDetails(property: Property | PropertyPreview, rooms: Room[]) {
  const sortedRooms = rooms?.sort((a, b) => (a.floorNumber > b.floorNumber ? 1 : -1));
  return [
    formatPropertyType(property.propertyType),
    !propertyTypesWithoutFloor.includes(property.propertyType) && sortedRooms.length > 0 ? formatFloor(sortedRooms[0].floorNumber, true) : '',
    property.propertySize && formatSquareMeters(property.propertySize)]
    .filter((part) => part !== null && part !== '')
    .join(', ');
}

export function shortNormalizedAddress(property: Property | PropertyPreview): string {
  return [property.geo?.route, property.geo?.streetNumber, property.geo?.locality]
    .filter((part) => !!part)
    .join(', ');
}

export function isApartmentAlike(propertyType: PropertyType) {
  return APARTMENT_ALIKE_TYPES.includes(propertyType);
}

export function isNonResidential(propertyType: PropertyType): boolean {
  return propertyType in NonResidentialPropertyTypeValue;
}

export function buildHeatingLabels(heating: Heating): string {
  const { type, system, supply } = heating;
  const heatingFeatures = Object.entries({ type, system, supply })
    .filter(([_, value]) => value !== null)
    .map(([key, value]) => {
      switch (key) {
        case 'type':
          return heatingTypeLabels[value as HeatingType];
        case 'system':
          return heatingSystemLabels[value as HeatingSystem].toLowerCase();
        case 'supply':
          return heatingSupplyLabels[value as HeatingSupply].toLowerCase();
        default:
          return null;
      }
    });

  return heatingFeatures.join(', ');
}

export function buildAirConditioningLabels(airConditioning: AirConditioning): string {
  const { type, mode } = airConditioning;
  const airConditioningFeature = Object.entries({ type, mode })
    .filter(([_, value]) => value !== null)
    .map(([key, value]) => {
      switch (key) {
        case 'type':
          return airConditioningTypeLabels[value as AirConditioningType];
        case 'mode':
          return airConditioningModeLabels[value as AirConditioningMode].toLowerCase();
        default:
          return null;
      }
    });

  return airConditioningFeature.join(', ');
}

export function buildGarageLabels(garages: Garage[]) {
  const types = garages
    .filter((({ type }) => type !== null))
    .map(({ type }) => garageTypeLabels[type!].toLowerCase());

  if (types.length > 0) {
    return capitalize(types.join(', '));
  }

  return 'Sì';
}

export function buildKitchenLabels(rooms: Room[]) {
  const kitchens = rooms
    .filter(({ roomType }) => roomType in KitchenType)
    .map(({ roomType }) => kitchenTypeLabels[roomType as KitchenType]);

  if (kitchens.length > 0) {
    return capitalize(kitchens.join(', '));
  }

  return NO_VALUE_SYMBOL;
}

export function buildFloorLabel(mainFloor?: number, buildingFloors?: number): string | undefined {
  return mainFloor === undefined
    ? undefined
    : `${floorLabels[mainFloor] ? floorLabels[mainFloor] : mainFloor} di ${buildingFloors}`;
}

const geoValidation = Yup.object({
  route: Yup.string().required(),
  locality: Yup.string().required(),
  administrativeAreaLevelOne: Yup.string().required(),
  administrativeAreaLevelTwo: Yup.string().required(),
  postalCode: Yup.string().required(),
  latitude: Yup.number().required(),
  longitude: Yup.number().required(),
});

export const previewOrLivePropertyValidationSchema = Yup.object({
  id: Yup.number().required(),
  propertyType: Yup.string().required(),
  propertySize: Yup.number().positive().required(),
  walkableSize: Yup.number().positive().required(),
  abstract: Yup.string().trim().min(1).required(),
  description: Yup.string().trim().min(1).required(),
  composition: Yup.string().trim().min(1).required(),
  registryCategory: Yup.string().required(),
  registryStatus: Yup.string().required(),
  propertyStatus: Yup.string().required(),
  price: Yup.number().positive().required(),
  geo: geoValidation.required(),
  energyValue: Yup.number().positive().when('energyClass', {
    is: (energyClass: EnergyClass) => !!energyClass,
    then: (schema) => schema.max(999),
    otherwise: (schema) => schema,
  }),
  buildingFloors: Yup.number().positive().when('propertyType', {
    is: (propertyType: PropertyType) => isApartmentAlike(propertyType),
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
});

type CanPropertyBeInStatusOptions = {
  checkPropertyStatus?: boolean
};

export const canPropertyBeInPreview = (
  property: Property,
  opts: CanPropertyBeInStatusOptions = {},
): boolean => {
  const { checkPropertyStatus = false } = opts;

  return (!checkPropertyStatus || property.status === Status.ANTEPRIMA) && previewOrLivePropertyValidationSchema.isValidSync(property);
};

export const canPropertyBeLive = (
  property: Property,
  documents: Document[] = [],
  opts: CanPropertyBeInStatusOptions = {},
): boolean => {
  const { checkPropertyStatus = false } = opts;

  const arePropertyFieldsValid = previewOrLivePropertyValidationSchema.isValidSync(property);
  const hasAllMandatoryDocumentsApproved = MANDATORY_PROPERTY_DOCUMENT_TYPES.every((mandatoryDocumentType) => documents
    .filter(({ status }) => status === DocumentStatus.APPROVED)
    .some(({ type }) => type === mandatoryDocumentType));

  return (!checkPropertyStatus || property.status === Status.LIVE) && arePropertyFieldsValid && hasAllMandatoryDocumentsApproved;
};
