import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Action,
  ActionIconDropdown,
  AvatarSkeleton,
  CardSkeleton,
  Dropdown,
  FontWeight, HStack, Message, Skeleton, Spacing, Stack, Text,
} from '@doveit/bricks';
import useProspect from '../../hooks/use-prospect/useProspect';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { raise } from '../../../utils';
import useAssignmentByProspectId from '../../hooks/use-assignment-by-prospect-id/useAssignmentByProspectId';
import ViewPropertyPopoverPreview from '../../../property/containers/view-property-popover-preview/ViewPropertyPopoverPreview';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import ProspectAvatar from '../../components/prospect-avatar/ProspectAvatar';
import useLead from '../../../lead/hooks/use-lead/useLead';
import useContact from '../../../contact/hooks/use-contact/useContact';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import { useProspectMatchingSearchCriteria } from '../../hooks/use-prospect-matching-search-criteria/useProspectMatchingSearchCriteria';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import usePagination from '../../../hooks/use-pagination/usePagination';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import { DEFAULT_PAGINATION_SIZE } from '../../../utils/querystring/querystring';
import MatchingSearchCriteriaPreviewCard from '../../../search-criteria/component/matching-search-criteria-preview-card/MatchingSearchCriteriaPreviewCard';
import MatchingSearchCriteriaPreviewTableRow from '../../../search-criteria/component/matching-search-criteria-preview-table-row/MatchingSearchCriteriaPreviewTableRow';

export const NO_MATCHING_SEARCH_CRITERIA_MESSAGE = 'Non sono presenti ricerche correlate alla valutazione.';
export const LOAD_PROSPECT_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni della valutazione.';
export const LOAD_MATCHING_SEARCH_CRITERIA_ERROR_MESSAGE = 'Non è stato possibile caricare le ricerche correlate alla valutazione.';
export const PROSPECT_MATCHING_FAILING_PRECONDITIONS_ERROR_MESSAGE = 'Non è possibile visualizzare le informazioni di matching della valutazione.';

const ViewProspectMatchingPage: React.FC = () => {
  const params = useParams();
  const isMobile = useIsDevice('mobile');

  const prospectId = params.id ?? raise('missing prospect id');

  const { page, goToPage } = usePagination();
  const { userIsAdmin } = useRBAC();

  const {
    data: prospect,
    isLoading: isProspectLoading,
    error: prospectError,
  } = useProspect(prospectId);

  const {
    data: assignment,
    isLoading: isAssignmentLoading,
  } = useAssignmentByProspectId(prospect?.id);

  const {
    data: lead,
    isLoading: isLeadLoading,
  } = useLead(prospect?.leadId);

  const {
    data: contact,
    isLoading: isContactLoading,
  } = useContact(lead?.contactId);

  const {
    data: paginatedSearchCriteria,
    isLoading: areSearchCriteriaLoading,
    error: searchCriteriaError,
  } = useProspectMatchingSearchCriteria(prospect?.id, {
    page,
    size: DEFAULT_PAGINATION_SIZE,
  });

  const userIsProspectAgentOrManager = useCurrentAgentIsSameAgentOrManager(prospect?.agentId);

  const goToProspectPage = React.useCallback(() => {
    goToDetailPage(prospectId, ReferenceType.PROSPECT);
  }, [prospectId]);

  const searchCriteriaWithContact = React.useMemo(
    () => (paginatedSearchCriteria?.content || [])
      .flatMap(({
        searchCriteria,
        contactId,
        email,
        name,
        phoneNumber,
      }) => searchCriteria
        .map((searchCriterion) => ({
          searchCriterion,
          contact: {
            id: contactId,
            name,
            email,
            phoneNumber,
          },
        }))),
    [paginatedSearchCriteria?.content],
  );

  const canView = React.useMemo(
    () => userIsAdmin || userIsProspectAgentOrManager,
    [userIsAdmin, userIsProspectAgentOrManager],
  );

  const isPreviewDataLoading = React.useMemo(
    () => isAssignmentLoading || isLeadLoading || isContactLoading,
    [isAssignmentLoading, isContactLoading, isLeadLoading],
  );

  if (!canView) {
    return <GenericErrorPage title={PROSPECT_MATCHING_FAILING_PRECONDITIONS_ERROR_MESSAGE} />;
  }

  if (prospectError) {
    return <GenericErrorPage title={LOAD_PROSPECT_ERROR_MESSAGE} />;
  }

  if (!prospect || isProspectLoading) {
    return null;
  }

  return (
    <GenericPageLayout aria-label="Matching valutazione">
      <GenericPageLayout.Bar>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <HStack
            gap={200}
            wrap="nowrap"
            aria-label="Anteprima"
          >
            {isPreviewDataLoading && (
              <HStack aria-label="Informazioni anteprima in caricamento">
                <AvatarSkeleton />
                <Stack>
                  <Skeleton width="15rem" height="1rem" />
                  <Skeleton width="10rem" height="1rem" />
                </Stack>
              </HStack>
            )}
            {!isPreviewDataLoading && assignment && (
              <ViewPropertyPopoverPreview
                propertyId={assignment.propertyId}
                trigger={(
                  <PropertyAvatar
                    aria-label="Avatar immobile"
                    size="M"
                  />
                )}
              />
            )}
            {!isPreviewDataLoading && !assignment && (
              <ProspectAvatar
                aria-label="Avatar valutazione"
                size="M"
              />
            )}
            {!isPreviewDataLoading && lead && contact && (
              <div>
                <div>
                  <Text.H4
                    fontWeight={FontWeight.REGULAR}
                    as="div"
                  >
                    {lead.propertyAddress}
                  </Text.H4>
                </div>
                <Text.Body as="div">
                  {contact.name}
                </Text.Body>
              </div>
            )}
          </HStack>

          <div aria-label="Azioni">
            {!isMobile && (
              <Action
                label="Vedi valutazione"
                aria-label="Vai alla pagina di dettaglio della valutazione"
                onClick={goToProspectPage}
                emphasis="high"
                size="M"
              />
            )}

            {isMobile && (
              <ActionIconDropdown
                label="Azioni sulla valutazione"
                aria-label="Dropdown azioni sulla valutazione"
              >
                <Dropdown.Option
                  label="Vedi valutazione"
                  aria-label="Vai alla pagina di dettaglio della valutazione"
                  onClick={goToProspectPage}
                />
              </ActionIconDropdown>
            )}
          </div>
        </HStack>
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <Text.H4>
          Ricerche attive ({paginatedSearchCriteria?.totalElements || 0})
        </Text.H4>

        <Spacing margin={[300, 0, 0]}>
          {areSearchCriteriaLoading && !isMobile && (
            <SimpleTableSkeleton aria-label="Ricerche in caricamento" />
          )}
          {areSearchCriteriaLoading && isMobile && (
            <Stack aria-label="Ricerche in caricamento">
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </Stack>
          )}
          {searchCriteriaError && (
            <Message
              message={LOAD_MATCHING_SEARCH_CRITERIA_ERROR_MESSAGE}
              type="critical"
              boxed
            />
          )}
          {(!areSearchCriteriaLoading && (paginatedSearchCriteria && paginatedSearchCriteria.totalElements > 0)) && (
            <PaginatedList
              {...paginatedSearchCriteria}
              aria-label="Ricerche"
              emptyResultMessage={NO_MATCHING_SEARCH_CRITERIA_MESSAGE}
              goToPage={goToPage}
              hideResultCount
            >
              {isMobile && searchCriteriaWithContact.map(({
                searchCriterion,
                contact: scContact,
              }) => (
                <MatchingSearchCriteriaPreviewCard
                  key={searchCriterion.id}
                  matchingSearchCriteria={searchCriterion}
                  contact={scContact}
                />
              ))}
              {!isMobile && (
                <SimpleTable>
                  <SimpleTable.Body>
                    {searchCriteriaWithContact.map(({
                      searchCriterion,
                      contact: scContact,
                    }) => (
                      <SimpleTable.Row asChild>
                        <MatchingSearchCriteriaPreviewTableRow
                          key={searchCriterion.id}
                          searchCriteria={searchCriterion}
                          contact={scContact}
                        />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </PaginatedList>
          )}
        </Spacing>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default ViewProspectMatchingPage;
