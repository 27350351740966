import { FirebaseOptions, FirebaseApp, initializeApp } from 'firebase/app';
import { Messaging, getMessaging } from 'firebase/messaging';
import firebaseConfig from '../../../config/firebase/firebase-config';

export type FirebaseMessagingClient = Messaging;

let firebaseMessagingClient: FirebaseMessagingClient;

export async function initFCMClient(): Promise<FirebaseMessagingClient> {
  if (firebaseMessagingClient) {
    return firebaseMessagingClient;
  }

  const config: FirebaseOptions = {
    apiKey: firebaseConfig.FIREBASE_API_KEY,
    projectId: firebaseConfig.FIREBASE_PROJECT_ID,
    messagingSenderId: firebaseConfig.FIREBASE_MESSAGE_SENDER_ID,
    appId: firebaseConfig.FIREBASE_MESSAGE_CENTER_APP_ID,
  };

  const firebaseApp: FirebaseApp = initializeApp(config);
  firebaseMessagingClient = getMessaging(firebaseApp);

  return firebaseMessagingClient;
}
