import { ResidentialPropertyTypeValue } from '../../domain/types';

export const propertyTypesRadio = [
  {
    value: ResidentialPropertyTypeValue.APPARTAMENTO,
    label: 'Appartamento',
  },
  {
    value: ResidentialPropertyTypeValue.VILLA,
    label: 'Villa',
  },
];
