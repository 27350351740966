import {
  Action,
  ActionIcon, FormHandlers, ICON_BELL_PLUS_OUTLINE, ICON_PENCIL_OUTLINE, Portal, useModal,
  useNotifications,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback } from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Reminder } from '../../../providers/api/dtos/reminder';
import { formDateTimeToDate, formatInputDate, formatInputTime } from '../../../utils/form';
import { createReminder, updateReminder } from '../../../providers/api/reminder/reminderProvider';
import ReminderForm from '../../components/reminder-form/ReminderForm';
import { ReminderFormModel } from '../../../prospect/models/ReminderFormModel';

export const UPSERT_REMINDER_ERROR_MESSAGE = 'Non è stato possibile effettuare l\'operazione';
export const UPSERT_REMINDER_SUCCESS_MESSAGE = 'Operazione effettuata con successo';

interface ChildFnProps {
  upsert: VoidFunction,
}

export interface UpsertReminderActionProps {
  reminder: Reminder,
  onSuccess?: (reminder: Reminder, operationType: 'create' | 'update') => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const UpsertReminderAction: FunctionComponent<UpsertReminderActionProps> = ({
  reminder,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const formRef = React.useRef<FormHandlers>() as React.MutableRefObject<FormHandlers>;

  const openModal = useCallback(
    () => modal.open(),
    [modal],
  );

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onSubmit = React.useCallback(async (values: ReminderFormModel) => {
    setIsSaving(true);

    try {
      const payload: Reminder = {
        ...reminder,
        startDate: formDateTimeToDate(values.date!, values.time!).toISOString(),
        endDate: formDateTimeToDate(values.date!, values.time!).toISOString(),
        notes: values.notes!,
      };

      const result = await (reminder.id
        ? updateReminder(reminder.id, payload)
        : createReminder(payload)
      );

      onSuccess?.(result, reminder.id ? 'update' : 'create');
      modal.close();
      addSuccess(UPSERT_REMINDER_SUCCESS_MESSAGE);
    } catch (error) {
      addError(UPSERT_REMINDER_ERROR_MESSAGE);
    }

    setIsSaving(false);
  }, [reminder, onSuccess, modal, addSuccess, addError]);

  const initialValues = React.useMemo(() => {
    if (reminder.id) {
      return {
        id: reminder.id?.toString(),
        date: reminder.startDate ? formatInputDate(new Date(reminder.startDate)) : '',
        time: reminder.startDate ? formatInputTime(new Date(reminder.startDate)) : '',
        notes: reminder.notes ? reminder.notes : '',
      };
    }

    return {};
  }, [reminder]);

  return (
    <>
      {children
        ? children({
          upsert: openModal,
        })
        : (
          <ActionIcon
            icon={reminder.id
              ? { path: ICON_PENCIL_OUTLINE }
              : { path: ICON_BELL_PLUS_OUTLINE }}
            label={reminder.id
              ? 'Modifica'
              : 'Aggiungi'}
            aria-label={
              reminder.id
                ? 'Pulsante per modificare il promemoria'
                : 'Pulsante per aggiungere un promemoria'
            }
            size="S"
            onClick={openModal}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title={
            reminder.id
              ? 'Modifica promemoria'
              : 'Aggiungi promemoria'
          }
          aria-label={
            reminder.id
              ? 'Modale per modificare il promemoria'
              : 'Modale per aggiungere un promemoria'
          }
          footer={(
            <Action
              label={
                reminder.id
                  ? 'Modifica'
                  : 'Aggiungi'
              }
              color="primary"
              emphasis="high"
              onClick={submitForm}
              loading={isSaving}
            />
          )}
        >
          <ReminderForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            formRef={formRef}
            loading={isSaving}
            hideSubmit
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpsertReminderAction;
