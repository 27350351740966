import * as Yup from 'yup';

const stringNumberSchema = Yup.string()
  .matches(/^\d+$/, 'Valore non valido. Es: 150000');

const schema = Yup.object().shape({
  agent: Yup
    .object({
      id: Yup.number().required('È necessario selezionare un agente'),
    })
    .nullable(),
  ownerEvaluation: stringNumberSchema,
  agentEvaluation: stringNumberSchema,
});

export default schema;
