import { Message, useNotifications } from '@doveit/bricks';
import React, { FunctionComponent, useState, useCallback } from 'react';
import { getNextCallCenterContact } from '../../../providers/api/call-center/callCenterProvider';
import WelcomeBox from '../../../components/welcome-box/WelcomeBox';
import { ProcessableContact } from '../../../providers/api/dtos';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useNextCallCenterContactsCount } from '../../hooks/use-next-callcenter-contacts-count/useNextCallCenterContactsCount';
import { WidgetCardSkeleton } from '../../../components/card/Card.skeleton';

export const LOAD_ERROR_MESSAGE = 'Impossibile caricare i dati: ricarica la pagina e riprova.';
export const START_ERROR_MESSAGE = 'Errore durante il caricamento del prossimo contatto: riprova più tardi.';

export interface StartContactProcessProps {
  onNextContact: (processableContact?: ProcessableContact) => void,
}

const StartContactProcess: FunctionComponent<StartContactProcessProps> = ({
  onNextContact,
}) => {
  const [isStarting, setIsStarting] = useState(false);
  const { user } = useRBAC();
  const { data: count, mutate: mutateCount, error: countError } = useNextCallCenterContactsCount();
  const { addError } = useNotifications();

  const start = useCallback(async () => {
    setIsStarting(true);

    try {
      const processableContact = await getNextCallCenterContact();
      setIsStarting(false);

      onNextContact(processableContact);
    } catch (err) {
      setIsStarting(false);

      addError(START_ERROR_MESSAGE);
    }
  }, [addError, onNextContact]);

  if (countError) {
    return (
      <Message
        type="critical"
        boxed
        message={LOAD_ERROR_MESSAGE}
      />
    );
  }

  if (!count) {
    return (<WidgetCardSkeleton />);
  }

  return (
    <WelcomeBox
      userName={user.name}
      loading={isStarting}
      count={count.count}
      onStartClick={start}
      onRefreshClick={mutateCount}
    />
  );
};

export default StartContactProcess;
