import * as Yup from 'yup';
import { ShootingStatus, ShootingType } from '../../../domain/types';
import type { UpsertShootingFormModel } from './UpsertShootingForm';
import { Shooting } from '../../../providers/api/dtos';

export const upsertShootingValidationSchema = (shooting?: Shooting) => (
  hiddenFields?: Partial<Record<keyof UpsertShootingFormModel, boolean>>,
  disabledFields?: Partial<Record<keyof UpsertShootingFormModel, boolean>>,
) => Yup.object({
  type: (hiddenFields?.type || disabledFields?.type)
    ? Yup.mixed().notRequired()
    : Yup.string().oneOf(Object.values(ShootingType)).required('Il campo è obbligatorio'),
  photographerName: (hiddenFields?.photographerName || disabledFields?.photographerName)
    ? Yup.mixed().notRequired()
    : Yup.string().when('type', {
      is: (type: ShootingType | '') => type === ShootingType.DIRECT,
      then: (schema) => schema.trim().required('Il campo è obbligatorio'),
      otherwise: (schema) => schema.length(0),
    }),
  date: (hiddenFields?.date || disabledFields?.date)
    ? Yup.mixed().notRequired()
    : Yup.date().required('Il campo è obbligatorio'),
  time: (hiddenFields?.time || disabledFields?.time)
    ? Yup.mixed().notRequired()
    : Yup.string().required('Il campo è obbligatorio'),
  photoPrice: (hiddenFields?.photoPrice || disabledFields?.photoPrice)
    ? Yup.mixed().notRequired()
    : Yup.number().nullable(),
  extraServicesPrice: (hiddenFields?.extraServicesPrice || disabledFields?.extraServicesPrice)
    ? Yup.mixed().notRequired()
    : Yup.number().when('postProcessingRequest', {
      is: (request: boolean) => request,
      then: (schema) => schema.required('Il campo è obbligatorio'),
      otherwise: (schema) => schema.nullable(),
    }),
  expensesRefund: (hiddenFields?.expensesRefund || disabledFields?.expensesRefund)
    ? Yup.mixed().notRequired()
    : Yup.number().nullable(),
  matterportDeliveryDate: (hiddenFields?.matterportDeliveryDate || disabledFields?.matterportDeliveryDate)
    ? Yup.mixed().notRequired()
    : Yup
      .date()
      .when('matterportRequest', {
        is: (matterportRequest: boolean) => matterportRequest,
        then: (schema) => schema
          .min(Yup.ref('date'), 'La data di consegna di Matterport deve essere successiva alla data dello shooting')
          .nullable(),
        otherwise: (schema) => schema.notRequired(),
      }),
  photoDeliveryDate: (hiddenFields?.photoDeliveryDate || disabledFields?.photoDeliveryDate)
    ? Yup.mixed().notRequired()
    : Yup
      .date()
      .when([], {
        is: () => shooting?.status === ShootingStatus.BOOKED,
        then: (schema) => schema
          .min(Yup.ref('date'), 'La data di consegna di Matterport deve essere successiva alla data dello shooting')
          .required('Il campo è obbligatorio'),
        otherwise: (schema) => schema.notRequired(),
      }),
  notes: (hiddenFields?.notes || disabledFields?.notes)
    ? Yup.mixed().notRequired()
    : Yup.string(),
});
