import { useMemo } from 'react';
import useRBAC from '../use-rbac/useRBAC';
import { UserRole } from '../../types';

export default function useUserIsOnlyAreaManager() {
  const {
    userIsAreaManager, userIsHR, userIsAdmin, userHasAnyRole,
  } = useRBAC();

  return useMemo(
    () => userIsAreaManager && !userIsHR && !userIsAdmin && !userHasAnyRole(UserRole.DEVELOPER),
    [userHasAnyRole, userIsAdmin, userIsAreaManager, userIsHR],
  );
}
