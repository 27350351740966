import React from 'react';
import useContactMatches from '../use-contact-matches/useContactMatches';
import useContact from '../use-contact/useContact';
import { ContactToProcessExistingMatchesNoticeData } from '../../../providers/api/dtos/notice';

export function useContactSimilarMatchesNoticesData(contactId?: string) {
  const [noticeData, setNoticeData] = React.useState<ContactToProcessExistingMatchesNoticeData | undefined>(undefined);

  const {
    data: contact,
    isLoading: isContactLoading,
    error: contactError,
    mutate: mutateContact,
  } = useContact(contactId);

  const {
    data: contactMatches,
    isLoading: isContactMatchesLoading,
    error: contactMatchesError,
    mutate: mutateContactMatches,
  } = useContactMatches(contact ? {
    email: contact.email,
    phoneNumber: contact.phoneNumber,
  } : undefined);

  const similarContacts = React.useMemo(() => contactMatches
    ?.filter(({ id: similarContactId }) => similarContactId !== contactId)
    ?.sort((b, a) => Date.parse(a.createdAt!) - Date.parse(b.createdAt!)), [contactId, contactMatches]);

  React.useEffect(() => {
    function computeNotices() {
      if ((similarContacts?.length ?? 0) > 0) {
        const internalNoticeData: ContactToProcessExistingMatchesNoticeData = {
          type: 'CONTACT_TO_PROCESS_EXISTING_MATCHES',
          referenceId: contactId!,
          data: {
            contactMatches: similarContacts!,
          },
        };

        setNoticeData(internalNoticeData);
      }
    }

    computeNotices();
  }, [contactId, similarContacts]);

  const isLoading = React.useMemo(() => isContactLoading || isContactMatchesLoading, [isContactLoading, isContactMatchesLoading]);

  const hookErrors = React.useMemo(() => [contactError, contactMatchesError].filter((item) => item !== undefined), [contactError, contactMatchesError]);

  const mutate = React.useCallback(async () => {
    Promise.all([
      mutateContact,
      mutateContactMatches,
    ]);
  }, [mutateContact, mutateContactMatches]);

  return {
    data: (!isLoading) ? noticeData : undefined,
    errors: hookErrors.length > 0 ? hookErrors : undefined,
    isLoading,
    mutate,
  };
}
