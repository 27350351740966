import { ICON_HOME_CITY_OUTLINE, ICON_HOME_OUTLINE } from '@doveit/bricks';
import { ResidentialPropertyType, ResidentialPropertyTypeValue } from '../../domain/types';

const independentPropertyTypes: ResidentialPropertyType[] = [
  ResidentialPropertyTypeValue.PALAZZO,
  ResidentialPropertyTypeValue.RUSTICO_CASALE,
  ResidentialPropertyTypeValue.VILLA,
  ResidentialPropertyTypeValue.VILLETTA_A_SCHIERA,
  ResidentialPropertyTypeValue.CASA_INDIPENDENTE,
];

export default function getIconByPropertyType(propertyType?: ResidentialPropertyType) {
  if (propertyType && independentPropertyTypes.includes(propertyType)) {
    return ICON_HOME_OUTLINE;
  }

  return ICON_HOME_CITY_OUTLINE;
}
