import { Skeleton } from '@doveit/bricks';
import React, { FunctionComponent } from 'react';
import Card, { CardProps } from '../Card';

/**
 * @deprecated Use CardSkeleton from Bricks
 */
const CardSkeleton: FunctionComponent<CardProps> = (props) => (
  <Card {...props}>
    <Card.Header>
      <Skeleton
        width="60%"
        height="1.5rem"
      />
    </Card.Header>
    <Card.Box>
      <Skeleton count={3} />
    </Card.Box>
  </Card>
);

export default CardSkeleton;
