/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  ICON_HOME_OUTLINE, ICON_CASH_MULTIPLE, Message,
  Dropdown,
  ICON_RULER,
} from '@doveit/bricks';
import { formatSquareMetersRange, formatEuroRange } from '@doveit/hammer';
import { orderBy } from 'lodash';
import type { ViewContactActivitiesSummaryProps } from '../../ViewContactActivitiesSummary';
import Divider from '../../../../../components/divider/Divider';
import { propertyTypeLabels } from '../../../../../labels';
import { SearchCriteria } from '../../../../../providers/api/dtos';
import ContactActivityCard from '../../../../components/contact-activity-card/ContactActivityCard';
import ContactActivityCardSkeleton from '../../../../components/contact-activity-card/ContactActivityCard.skeleton';
import useContactSearchCriteria from '../../../../hooks/use-contact-search-criteria/useContactSearchCriteria';
import { CONTACT_SEARCH_CRITERIA_LOAD_ERROR } from '../constants';
import * as styles from '../../ViewContactActivitiesSummary.style';
import { NO_VALUE_SYMBOL } from '../../../../../property/utils';

const ContactSearchCriteriaSummaryGroup: React.FC<ViewContactActivitiesSummaryProps> = ({
  contactId,
}) => {
  const { data: searchCriteria, error: searchCriteriaError } = useContactSearchCriteria(contactId);

  const searchCriteriaActions = React.useCallback((searchCriteriaId: SearchCriteria['id']) => [
    {
      label: 'Vedi ricerca',
      onClick: () => window.open(`/search-criteria/${searchCriteriaId}`),
    },
  ], []);

  if (searchCriteriaError) {
    return (
      <Message
        type="critical"
        message={CONTACT_SEARCH_CRITERIA_LOAD_ERROR}
      />
    );
  }

  if (!searchCriteria) {
    return <ContactActivityCardSkeleton />;
  }

  if (searchCriteria.length === 0) {
    return null;
  }

  return (
    <div aria-label="Lista delle ricerche del contatto">
      <Divider>
        Ricerche
      </Divider>

      <div css={styles.activityGroupWrapper}>
        {orderBy(searchCriteria, ['active', 'updatedAt'], ['desc', 'desc']).map(({
          id, active, createdAt, updatedAt, propertyTypes, minSize, maxSize, minPrice, maxPrice,
        }) => (
          <ContactActivityCard
            key={id}
            type="search-criteria"
            status={active ? 'Attivo' : 'Non attivo'}
            statusColor={active ? 'success' : 'neutral'}
            createdAt={createdAt}
            updatedAt={updatedAt}
            features={[{
              icon: ICON_HOME_OUTLINE,
              title: 'Tipologia immobile',
              label: `${propertyTypes.length > 0
                ? propertyTypes.slice(0, 2).map((type) => propertyTypeLabels[type]).join(', ')
                : ' — '}${propertyTypes.length > 2 ? ', ...' : ''}`,
            }, {
              icon: ICON_RULER,
              title: 'Superficie immobile',
              label: `${formatSquareMetersRange(minSize, maxSize) ?? NO_VALUE_SYMBOL}`,
            }, {
              icon: ICON_CASH_MULTIPLE,
              title: 'Budget d\'acquisto',
              label: `${formatEuroRange(minPrice, maxPrice) ?? NO_VALUE_SYMBOL}`,
            }]}
            actions={searchCriteriaActions(id).map((action) => (
              <Dropdown.Option {...action} />
            ))}
          />
        ))}
      </div>
    </div>
  );
};

export default ContactSearchCriteriaSummaryGroup;
