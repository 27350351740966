import useSWR, { SWRResponse } from 'swr';
import type { AxiosError } from 'axios';
import { Rumor } from '../../../providers/api/dtos/rumor';
import { getRumor } from '../../../providers/api/rumor/rumorProvider';

export const RUMORS_V1_RID = '/v1/rumors/';

export default function useRumor(rumorId?: number): SWRResponse<Rumor, AxiosError> {
  return useSWR(rumorId ? [RUMORS_V1_RID, rumorId] : null, () => getRumor(rumorId!));
}
