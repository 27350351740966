/* eslint-disable react/no-unknown-property */
import { ExpandableContent, ExpandableContentProps } from '@doveit/bricks';
import React, { FunctionComponent } from 'react';
import * as styles from './MultilineContent.style';

export interface MultilineContentProps extends ExpandableContentProps {
  maxLines?: number,
  children: string
}

const MultilineContent: FunctionComponent<MultilineContentProps> = ({
  maxLines = 3,
  children,
  ...rest
}) => {
  const lines = children.split('\n');

  return (
    <div css={styles.main}>
      {lines.slice(0, maxLines).join('\n')}
      {lines.length > maxLines! && (
        <ExpandableContent {...rest}>
          {lines.slice(maxLines).join('\n')}
        </ExpandableContent>
      )}
    </div>
  );
};

export default MultilineContent;
