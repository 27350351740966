export const AgentCommissionSchema = {
  '50_15': '50_15',
  '60_20': '60_20',
  '60_25': '60_25',
  '70_30': '70_30',
  '50_30_OLD': '50_30_OLD',
  '60_20_OLD': '60_20_OLD',
  '70_20_OLD': '70_20_OLD',
  AREA_MANAGER: 'AREA_MANAGER',
  CUSTOM: 'CUSTOM',
} as const;

export const AgencyCommissionSchema = {
  SILVER: 'SILVER',
  GOLD: 'GOLD',
  CUSTOM: 'CUSTOM',
} as const;

export const CommissionSchema = { ...AgentCommissionSchema, ...AgencyCommissionSchema };

export type AgentCommissionSchemaType = keyof typeof AgentCommissionSchema;
export type AgencyCommissionSchemaType = keyof typeof AgencyCommissionSchema;
export type CommissionSchemaType = AgentCommissionSchemaType | AgencyCommissionSchemaType;
