/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as styles from './ConversationMessageCard.style';
import { formatInputTime } from '../../../utils/form';

export interface ConversationMessageCardProps {
  text: string,
  date: string,
  author?: string,
  inbound?: boolean,
}

const ConversationMessageCard: React.FC<ConversationMessageCardProps> = ({
  text,
  date,
  author,
  inbound,
}) => (
  <div>
    <div
      css={styles.bubble}
      $inbound={inbound}
    >
      {text}
      <div css={styles.date}>
        {formatInputTime(new Date(date))}
      </div>
    </div>
    {author && (
      <div css={styles.author}>
        Inviato da <strong>{author}</strong>
      </div>
    )}
  </div>
);

export default ConversationMessageCard;
