import { FurnitureType } from '../domain/types';

export const furnitureTypeLabels: {
  [key in FurnitureType]: string
} = {
  ARREDATO: 'Arredato',
  NON_ARREDATO: 'Non arredato',
  PARZIALE: 'Parziale',
  CONTRATTABILE: 'Contrattabile',
};
