import React from 'react';
import {
  Action, FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import EditAgentRegistryForm, { EditAgentRegistryFormModel } from '../../components/edit-agent-registry-form/EditAgentRegistryForm';
import { updateAgent } from '../../../providers/api/agent/agentProvider';
import { agentToEditAgentRegistryFormModel, editAgentRegistryFormModelToAgent } from './mappers';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface EditAgentRegistryActionProps {
  agent: Agent,
  onSuccess?: (updatedAgent: Agent) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_AGENT_REGISTRY_SUCCESS_MESSAGE = "L'anagrafica dell'agente è stata modificata con successo";
export const EDIT_AGENT_REGISTRY_ERROR_MESSAGE = "Non è stato possibile modificare l'anagrafica dell'agente";

const EditAgentRegistryAction: React.FC<EditAgentRegistryActionProps> = (props) => {
  const { agent, onSuccess, children } = props;

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;

  const openEditModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onSubmit = React.useCallback(async (formValues: EditAgentRegistryFormModel) => {
    try {
      setIsSaving(true);

      const agentToUpdate = editAgentRegistryFormModelToAgent(formValues, agent);

      const updatedAgent = await updateAgent(agent.id!, agentToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_AGENT_REGISTRY_SUCCESS_MESSAGE);
      modal.close();

      if (onSuccess) {
        onSuccess(updatedAgent);
      }
    } catch (error) {
      setIsSaving(false);
      addError(EDIT_AGENT_REGISTRY_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agent, modal, onSuccess]);

  return (
    <>
      {children
        ? children({ openEditModal, isSaving })
        : (
          <Action
            aria-label="Modifica l'anagrafica dell'agente"
            size="S"
            title="Modifica l'anagrafica dell'agente"
            label="Modifica"
            onClick={openEditModal}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Modifica anagrafica"
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Modifica i dati"
              onClick={submitForm}
            />
          )}
        >
          <EditAgentRegistryForm
            initialValues={agentToEditAgentRegistryFormModel(agent)}
            onSubmit={onSubmit}
            innerRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAgentRegistryAction;
