import React from 'react';
import {
  Action, FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agency } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { updateAgency } from '../../../providers/api/agency/agencyProvider';
import { agencyToEditAgencyRegistryFormModel, editAgencyRegistryFormModelToAgency } from './mappers';
import EditAgencyRegistryForm, { EditAgencyRegistryFormModel } from '../../components/edit-agency-registry-form/EditAgencyRegistryForm';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface EditAgencyRegistryActionProps {
  agency: Agency,
  onSuccess?: (updatedAgency: Agency) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_AGENCY_REGISTRY_SUCCESS_MESSAGE = "L'anagrafica dell'agenzia è stata modificata con successo";
export const EDIT_AGENCY_REGISTRY_ERROR_MESSAGE = "Non è stato possibile modificare l'anagrafica dell'agenzia";

const EditAgencyRegistryAction: React.FC<EditAgencyRegistryActionProps> = (props) => {
  const { agency, onSuccess, children } = props;

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;

  const openEditModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onSubmit = React.useCallback(async (formValues: EditAgencyRegistryFormModel) => {
    try {
      setIsSaving(true);

      const agencyToUpdate = editAgencyRegistryFormModelToAgency(formValues, agency);

      const updatedAgency = await updateAgency(agency.id!, agencyToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_AGENCY_REGISTRY_SUCCESS_MESSAGE);
      modal.close();

      if (onSuccess) {
        onSuccess(updatedAgency);
      }
    } catch (error) {
      setIsSaving(false);
      addError(EDIT_AGENCY_REGISTRY_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agency, modal, onSuccess]);

  return (
    <>
      {children
        ? children({ openEditModal, isSaving })
        : (
          <Action
            aria-label="Modifica l'anagrafica dell'agenzia"
            title="Modifica l'anagrafica dell'agenzia"
            size="S"
            label="Modifica"
            onClick={openEditModal}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Modifica anagrafica"
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Modifica i dati"
              onClick={submitForm}
            />
          )}
        >
          <EditAgencyRegistryForm
            initialValues={agencyToEditAgencyRegistryFormModel(agency)}
            onSubmit={onSubmit}
            innerRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAgencyRegistryAction;
