import { FontWeight, ThemeDefinition } from '@doveit/bricks';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  body {
    font-family: 'Roboto', sans-serif;
    color: ${({ theme }: { theme: ThemeDefinition }) => theme.color.foreground.neutral.default.low};
  }

  strong {
    font-weight: ${FontWeight.MEDIUM};
  }

  a {
    color: currentColor;
  }
`;
