export const experiencesRadioOptions = [
  {
    label: 'Nessuna esperienza',
    value: 'Nessuna esperienza',
  },
  {
    label: 'Da 1 a 3 anni di esperienza',
    value: 'Da 1 a 3 anni di esperienza',
  },
  {
    label: 'Da 4 a 10 anni di esperienza',
    value: 'Da 4 a 10 anni di esperienza',
  },
  {
    label: '+10 anni di esperienza',
    value: '+10 anni di esperienza',
  },
];
