import { Unsubscribe, onMessage } from 'firebase/messaging';
import { initFCMClient } from './init-fcm-client';
import { OnMessage } from './types';

const handlers: Record<string, OnMessage> = {};

export const registerMessageHandler = (key: string, handler: OnMessage) => {
  handlers[key] = handler;
};

export async function onFCMMessageListener(): Promise<Unsubscribe> {
  const client = await initFCMClient();
  return onMessage(client, (payload) => {
    Object.keys(handlers).forEach((key) => handlers[key](payload));
  });
}
