import { ThemedFunctor, TokenColor, rgba } from '@doveit/bricks';
import { css } from 'styled-components';

export const basic: ThemedFunctor = ({
  theme,
  $success,
  $error,
}) => css`
  background: ${theme.color.background.neutral.default.low};
  padding-top: 100%;
  position: relative;
  user-select: none;
  border: 2px solid ${theme.color.border.info.default.high};

  ${$success && `
    border-color: ${theme.color.border.success.default.high};
  `}

  ${$error && `
    border-color: ${theme.color.border.critical.default.high};
  `}
`;

export const actionsBar: ThemedFunctor = () => css`
  position: absolute;
  z-index: 2;
  top:  0;
  left: 0;
  right: 0;
`;

export const innerOverlay: ThemedFunctor = ({
  theme,
}) => css`
  position: absolute;
  padding: ${theme.remSize[400]} ${theme.remSize[100]} ${theme.remSize[100]};
  gap: ${theme.remSize[75]};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, ${rgba(theme.color.background.neutral.default.lowest, 0)}, ${theme.color.background.neutral.default.lowest});
  text-shadow: ${theme.color.background.neutral.default.lowest} 1px 0 2px;
`;

export const overlay: ThemedFunctor = () => css`
  position: absolute;
  z-index: 2;
  top:  0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const cover: ThemedFunctor = () => css`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const icon: ThemedFunctor = ({
  theme,
  $color,
}) => css`
  width: ${theme.remSize[300]};
  height: ${theme.remSize[300]};
  border-radius: ${theme.remSize[150]};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.color.background[$color as keyof TokenColor['background']].default.high};
  color: ${theme.color.foreground[$color as keyof TokenColor['foreground']].default.low};
  flex-shrink: 0;
`;
