import { Action, ICON_MAGNIFY_PLUS_OUTLINE } from '@doveit/bricks';
import React from 'react';

export interface NoContactViewProps {
  label?: string,
  disabled?: boolean,
  openContactFinder: VoidFunction,
}

export const NoContactView: React.FC<NoContactViewProps> = ({ openContactFinder, label, disabled }) => (
  <Action
    dataRef="open-search-modal"
    label={label || 'Cerca o aggiungi contatto'}
    aria-label={label || 'Cerca o aggiungi contatto'}
    iconLeft={{ path: ICON_MAGNIFY_PLUS_OUTLINE }}
    onClick={openContactFinder}
    disabled={disabled}
  />
);

export default NoContactView;
