/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  FunctionComponent, useContext, useState, PropsWithChildren,
} from 'react';
import {
  ICON_ARROW_UP, ActionIcon, ICON_DOTS_VERTICAL,
  Text,
} from '@doveit/bricks';
import GenericPageLayoutContext from '../GenericPageLayoutContext';
import GenericPageLayoutWrapper, { GenericPageLayoutWrapperWidth } from '../wrapper/GenericPageLayoutWrapper';
import * as styles from './GenericPageLayoutContent.style';

export interface GenericPageLayoutContentState {
  isSideOpen: boolean,
  setSideOpen: (open: boolean) => void,
}

export type GenericPageLayoutContentProps = PropsWithChildren<{
  maxWrapperWidth?: GenericPageLayoutWrapperWidth,
  title?: React.ReactNode,
  subtitle?: React.ReactNode,
  headerSlot?: React.ReactNode,
  renderSideNavigation?: (state: GenericPageLayoutContentState) => React.ReactNode,
  inPageNavigation?: React.ReactNode,
  fitMaxAvailableHeight?: boolean,
}>;

const GenericPageLayoutContent: FunctionComponent<GenericPageLayoutContentProps> = ({
  maxWrapperWidth,
  title,
  subtitle,
  headerSlot,
  children,
  renderSideNavigation,
  inPageNavigation,
  fitMaxAvailableHeight,
}) => {
  const context = useContext(GenericPageLayoutContext);
  const [isSideOpen, setSideOpen] = useState(false);

  const { maxWrapperWidth: contextMaxWrapperWidth } = context;

  return (
    <div
      css={styles.content}
      $fitMaxAvailableHeight={fitMaxAvailableHeight}
      data-ref="generic-page-layout-content"
    >
      {renderSideNavigation && (
        <>
          <button
            css={styles.sideCloseArea}
            mobileMenuOpen={isSideOpen}
            onClick={() => setSideOpen(false)}
          />
          <aside
            css={styles.sideNavigation}
            mobileMenuOpen={isSideOpen}
          >
            {renderSideNavigation({
              isSideOpen,
              setSideOpen,
            })}
          </aside>
        </>
      )}
      <main css={styles.main}>
        {inPageNavigation && (
          <div
            css={styles.inPageNavigation}
            hasSide={renderSideNavigation}
          >
            <GenericPageLayoutWrapper
              maxWidth={maxWrapperWidth || contextMaxWrapperWidth}
            >
              {inPageNavigation}
            </GenericPageLayoutWrapper>
          </div>
        )}
        <GenericPageLayoutWrapper
          maxWidth={maxWrapperWidth || contextMaxWrapperWidth}
          fitMaxAvailableHeight={fitMaxAvailableHeight}
        >
          <div
            css={styles.mainContent}
            hasInPageNavigation={inPageNavigation}
            $fitMaxAvailableHeight={fitMaxAvailableHeight}
          >
            {(title || subtitle || headerSlot) && (
              <div css={styles.header}>
                <div css={styles.headerContent}>
                  <div css={styles.headerPrimary}>
                    {title && (
                      <Text.H3 as="h1">
                        {title}
                      </Text.H3>
                    )}
                    {subtitle && (
                      <div
                        data-ref="generic-page-layout-subtitle"
                        css={styles.subtitle}
                      >
                        {subtitle}
                      </div>
                    )}
                  </div>
                  {headerSlot && (
                    <div css={styles.headerSecondary}>
                      {headerSlot}
                    </div>
                  )}
                </div>
              </div>
            )}
            {children}
          </div>
        </GenericPageLayoutWrapper>
        {renderSideNavigation && (
          <div
            css={styles.sideOpenArea}
            hasInPageNavigation={inPageNavigation}
          >
            <ActionIcon
              data-ref="open-close-button"
              label={isSideOpen ? 'Chiudi' : 'Apri'}
              icon={isSideOpen ? {
                path: ICON_ARROW_UP,
                direction: 'left',
              } : {
                path: ICON_DOTS_VERTICAL,
                direction: 'top',
              }}
              emphasis="high"
              size="S"
              onClick={() => setSideOpen(!isSideOpen)}
            />
          </div>
        )}
      </main>
    </div>
  );
};

export default GenericPageLayoutContent;
