/* eslint-disable no-nested-ternary */
import {
  Portal, useModal, useNotifications, ModalActions, ICON_CLOSE, ICON_CHECK, Action,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { SHOW_DEED_MODAL_URL_PARAM_CONFIRM_VALUE, SHOW_DEED_MODAL_URL_PARAM_EDIT_VALUE } from '../../../constants';
import { OfferDeedStatus } from '../../../domain/types';
import useFilters from '../../../hooks/use-filters/useFilters';
import { Offer } from '../../../providers/api/dtos';
import { updateOffer } from '../../../providers/api/offer/offerProvider';
import { ModalControl } from '../../../types';
import OfferDeedForm, { OfferDeedFormModel } from '../../components/offer-deed-form/OfferDeedForm';

export interface EditOfferDeedActionCustomViewProps {
  openEditOfferDeedModal?: ModalActions<unknown>['open'],
}

export interface EditOfferDeedActionProps extends ModalControl {
  offer: Offer,
  isDone?: boolean,
  modalTitle?: string,
  children?: (customViewProps: EditOfferDeedActionCustomViewProps) => React.ReactNode,
  onSuccess?: (updatedOffer: Offer) => void,
}

export const CONFIRM_DEED_OFFER_SUCCESS_MESSAGE = 'La data del rogito è stata confermata correttamente';
export const CONFIRM_DEED_OFFER_ERROR_MESSAGE = 'Non è stato possibile confermare la data del rogito';
export const EDIT_DEED_OFFER_SUCCESS_MESSAGE = 'La data del rogito è stata modificata correttamente';
export const EDIT_DEED_OFFER_ERROR_MESSAGE = 'Non è stato possibile salvare la data del rogito';

interface Filters {
  showDeed?: string
}

const EditOfferDeedAction: React.FC<EditOfferDeedActionProps> = ({
  offer,
  isDone = false,
  modalTitle,
  allowModalControl,
  children,
  onSuccess,
}) => {
  const {
    filters,
    addFilters,
    removeFilter,
  } = useFilters<Filters>();
  const [isSaving, setIsSaving] = React.useState(false);
  const editOfferDeedModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const openEditOfferDeedModal = React.useCallback(() => {
    editOfferDeedModal.open();
    addFilters({ showDeed: isDone ? SHOW_DEED_MODAL_URL_PARAM_CONFIRM_VALUE : SHOW_DEED_MODAL_URL_PARAM_EDIT_VALUE });
  }, [addFilters, editOfferDeedModal, isDone]);

  const closeEditOfferDeedModal = React.useCallback(() => {
    editOfferDeedModal.close();
    removeFilter('showDeed');
  }, [editOfferDeedModal, removeFilter]);

  React.useEffect(
    () => {
      if (allowModalControl && ((filters.showDeed === SHOW_DEED_MODAL_URL_PARAM_CONFIRM_VALUE && isDone) || (filters.showDeed === SHOW_DEED_MODAL_URL_PARAM_EDIT_VALUE && !isDone))) {
        editOfferDeedModal.open();
      }
    },
    // Don't remove the comment: The modal must be opened once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmit = React.useCallback(async ({ date, status }: OfferDeedFormModel) => {
    setIsSaving(true);

    const offerToSave: Offer = {
      ...offer,
      deed: {
        date,
        status: isDone ? OfferDeedStatus.CONFIRMED : status as OfferDeedStatus,
      },
    };

    try {
      const offerWithConfirmedDeed = await updateOffer(offerToSave);

      if (onSuccess) {
        onSuccess(offerWithConfirmedDeed);
      }

      setIsSaving(false);
      addSuccess(offerToSave.deed?.status === OfferDeedStatus.CONFIRMED
        ? CONFIRM_DEED_OFFER_SUCCESS_MESSAGE
        : EDIT_DEED_OFFER_SUCCESS_MESSAGE);
      editOfferDeedModal.close();
    } catch (error) {
      setIsSaving(false);

      addError(offerToSave.deed?.status === OfferDeedStatus.CONFIRMED
        ? CONFIRM_DEED_OFFER_ERROR_MESSAGE
        : EDIT_DEED_OFFER_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, editOfferDeedModal, isDone, offer, onSuccess]);

  const dateRange = React.useMemo(() => {
    if (isDone) {
      return ({
        min: new Date(offer.acceptedAt!),
        max: new Date(),
      });
    }

    return undefined;
  }, [isDone, offer.acceptedAt]);

  const formInitialValues: OfferDeedFormModel | undefined = React.useMemo(() => ({
    date: offer.deed?.date || '',
    status: isDone ? OfferDeedStatus.CONFIRMED : '',
  }), [isDone, offer.deed?.date]);

  const editOfferDeedModalTitle: string = React.useMemo(() => {
    if (modalTitle) {
      return modalTitle;
    }

    return isDone
      ? 'Rogito avvenuto. Conferma o modifica la data'
      : (offer.deed?.date ? 'Modifica data rogito' : 'Inserisci data rogito');
  }, [isDone, modalTitle, offer.deed?.date]);

  return (
    <>
      {children
        ? children({ openEditOfferDeedModal })
        : (
          <Action
            label={isDone ? 'Avvenuto' : 'Non avvenuto'}
            color={isDone ? 'success' : 'critical'}
            iconLeft={{ path: isDone ? ICON_CHECK : ICON_CLOSE }}
            size="S"
            onClick={openEditOfferDeedModal}
          />
        )}
      <Portal>
        <SimpleModal
          title={editOfferDeedModalTitle}
          {...editOfferDeedModal}
          close={closeEditOfferDeedModal}
        >
          <OfferDeedForm
            initialValues={formInitialValues}
            hideStatusRadio={isDone}
            dateRange={dateRange}
            loading={isSaving}
            submitLabel={
              isDone
                ? 'Conferma'
                : (offer.deed?.date ? 'Modifica' : 'Inserisci')
            }
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditOfferDeedAction;
