import useSWR, { SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { LeadAssignedAgent } from '../../../providers/api/dtos';
import { getLeadAssignedAgent } from '../../../providers/api/lead/leadProvider';

export const LEAD_ASSIGNED_AGENT_RID = '/leads/:id/assign';

export function useLeadAssignedAgent(leadId?: string): SWRResponse<LeadAssignedAgent, AxiosError> {
  return useSWR(leadId ? [LEAD_ASSIGNED_AGENT_RID, leadId] : null, () => getLeadAssignedAgent(leadId!));
}
