import React from 'react';
import { Badge, BadgeProps } from '@doveit/bricks';
import { MembershipStatus } from '../../../domain/types';
import { membershipStatusLabels } from '../../../labels';
import { membershipStatusBadgeColor } from '../../containers/update-agent-status/constants';
import { membershipStatusBadgeIcon } from '../../constants';

export interface MembershipStatusBadgeProps {
  status: MembershipStatus,
  emphasis?: BadgeProps['emphasis'],
  showIcon?: boolean,
}

const MembershipStatusBadge: React.FC<MembershipStatusBadgeProps> = ({
  status,
  emphasis = 'low',
  showIcon = false,
}) => (
  <Badge
    label={membershipStatusLabels[status]}
    color={membershipStatusBadgeColor[status]}
    icon={showIcon ? membershipStatusBadgeIcon[status] : undefined}
    emphasis={emphasis}
  />
);

export default MembershipStatusBadge;
