import React from 'react';
import { AxiosError } from 'axios';
import { ProspectNoticeData } from '../../../providers/api/dtos/notice';
import { Prospect } from '../../../providers/api/dtos/prospect';
import { useAgent, useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import {
  AppointmentStatus, ProspectApprovalStatus, ProspectStatus, ReferenceType,
} from '../../../domain/types';
import useContactAppointments from '../../../appointment/hooks/use-contact-appointments/useContactAppointments';
import { isReminderExpired } from '../../../utils/reminder/reminder';
import useAssignmentWizardData from '../use-assignment-wizard-data/useAssignmentWizardData';
import { WithRequiredKeys } from '../../../types';
import { useProspectReminders } from '../use-prospect-reminders/useProspectReminders';

export type UseProspectNoticesDataResult = {
  data?: ProspectNoticeData[],
  isLoading: boolean,
  errors?: Error[] | undefined,
  mutate: () => Promise<void>
};

export const useProspectNoticesData = (prospect?: WithRequiredKeys<Prospect, 'id'>): UseProspectNoticesDataResult => {
  const [errors, setErrors] = React.useState<AxiosError[] | undefined>();

  const handleApiError = React.useCallback((err: AxiosError) => {
    if (err.response?.status === 404) { return; }

    setErrors((prev) => {
      if (!prev) return [err];
      return [...prev, err];
    });
  }, []);

  const currentAgent = useCurrentAgent();

  const { data: prospectAgent, isLoading: isProspectAgentLoading } = useAgent(prospect?.agentId);

  const {
    data: appointments,
    isLoading: isAppointmentsLoading,
    mutate: mutateContactAppointments,
  } = useContactAppointments(prospect?.contactId, {
    referenceId: prospect?.id,
    referenceType: ReferenceType.PROSPECT,
  });

  const {
    data: reminders,
    mutate: mutateProspectReminders,
    isLoading: isRemindersLoading,
  } = useProspectReminders(prospect?.id, undefined, { onError: handleApiError });

  const {
    data: assignmentWizard,
    isLoading: isAssignmentWizardLoading,
  } = useAssignmentWizardData(prospect?.id, { onError: handleApiError });

  const isLoading = React.useMemo(() => (
    isProspectAgentLoading
    || isAppointmentsLoading
    || isRemindersLoading
    || isAssignmentWizardLoading
  ), [isAppointmentsLoading, isAssignmentWizardLoading, isProspectAgentLoading, isRemindersLoading]);

  const mutate = React.useCallback(async () => {
    Promise.all([
      mutateProspectReminders(),
      mutateContactAppointments(),
    ]);
  }, [mutateContactAppointments, mutateProspectReminders]);

  const data = React.useMemo(() => {
    const noticesData: ProspectNoticeData[] = [];

    /** PROSPECT MISSING EVALUATION */
    if (prospect
      && !prospect.agentEvaluation
      && currentAgent?.id === prospect.agentId
    ) {
      noticesData.push({
        type: 'PROSPECT_MISSING_EVALUATION',
        referenceId: prospect.id,
        data: {
          prospect,
        },
      });
    }

    /** PROSPECT_NO_NEXT_ACTION */
    const todoAppointments = !!appointments && appointments.content.some((appointment) => appointment.status === AppointmentStatus.TODO);
    const allRemindersExpired = !!reminders && reminders.content.every((reminder) => isReminderExpired(reminder));

    const nextActionHasNotBeenDefined = prospect
      && prospect.status === ProspectStatus.IN_PROGRESS
      && (!prospect.approval || prospect.approval.status === ProspectApprovalStatus.APPROVED)
      && !todoAppointments
      && allRemindersExpired;

    const prospectDigitalAssignmentOngoing = prospect
      && assignmentWizard
      && prospect.status === ProspectStatus.IN_PROGRESS
      && assignmentWizard.isDigitallySigned;

    /** PROSPECT_NO_NEXT_ACTION_DEFINED_SUPERVISOR */
    if (prospectAgent
      && currentAgent
      && currentAgent.isSupervisor
      && currentAgent.id === prospectAgent.supervisorId
      && nextActionHasNotBeenDefined
      && !prospectDigitalAssignmentOngoing
    ) {
      noticesData.push({
        type: 'PROSPECT_NO_NEXT_ACTION_DEFINED_SUPERVISOR',
        referenceId: prospect.id,
        data: {
          prospect,
        },
      });
    }

    /** PROSPECT_NO_NEXT_ACTION_DEFINED_AGENT */
    if (prospectAgent
      && currentAgent?.id === prospectAgent?.id
      && !currentAgent?.isSupervisor
      && nextActionHasNotBeenDefined
      && !prospectDigitalAssignmentOngoing
    ) {
      noticesData.push({
        type: 'PROSPECT_NO_NEXT_ACTION_DEFINED_AGENT',
        referenceId: prospect.id,
        data: {
          prospect,
        },
      });
    }

    /** PROSPECT_PENDING_APPROVAL_CONSULTANT */
    if (currentAgent
      && currentAgent.id === prospectAgent?.id
      && currentAgent.needsSupervisor
      && prospect?.approval?.status === ProspectApprovalStatus.WAITING_FOR_AGENT) {
      noticesData.push({
        type: 'PROSPECT_PENDING_APPROVAL',
        referenceId: prospect.id,
        data: {
          prospect,
        },
      });
    }

    /** PROSPECT_PENDING_APPROVAL_SUPERVISOR */
    if (currentAgent
      && currentAgent.id === prospectAgent?.supervisorId
      && currentAgent.isSupervisor
      && prospect?.approval?.status === ProspectApprovalStatus.WAITING_FOR_SUPERVISOR) {
      noticesData.push({
        type: 'PROSPECT_PENDING_APPROVAL',
        referenceId: prospect.id,
        data: {
          prospect,
        },
      });
    }

    /** PROSPECT_REQUEST_APPROVAL */
    if (prospect
      && currentAgent
      && currentAgent.id === prospectAgent?.id
      && !currentAgent.isSupervisor
      && currentAgent.needsSupervisor
      && !prospect.approval) {
      noticesData.push({
        type: 'PROSPECT_REQUEST_APPROVAL',
        referenceId: prospect.id,
        data: {
          prospect,
        },
      });
    }

    return noticesData;
  }, [appointments, assignmentWizard, currentAgent, prospect, prospectAgent, reminders]);

  return {
    data,
    isLoading,
    errors,
    mutate,
  };
};
