import { Form, FormSelectProps, SelectOption } from '@doveit/bricks';
import React, {
  ChangeEvent, FunctionComponent, useCallback, useMemo,
} from 'react';
import { JobPosition } from '../../../providers/api/dtos';
import useJobPositions from '../../hooks/use-job-positions/useJobPositions';

export interface JobPositionsSelectProps extends Omit<FormSelectProps, 'options'> {
  value?: JobPosition,
  filterJobPositions?: (jobPosition: JobPosition) => boolean,
}

export const JOB_POSITIONS_LOAD_ERROR = 'Non è stato possibile caricare i ruoli';

const JobPositionsSelect: FunctionComponent<JobPositionsSelectProps> = ({
  name,
  value,
  onChange,
  filterJobPositions = () => true,
  ...rest
}) => {
  const { data: jobPositions = [], error: jobPositionsError } = useJobPositions();

  const jobPositionSelectOptions: SelectOption[] = useMemo(
    () => jobPositions
      .filter(filterJobPositions)
      .reduce((acc, { id: jobPositionId, name: jobPositionName }) => {
        acc.push({
          value: jobPositionId,
          label: jobPositionName,
        });

        return acc;
      },
      [{
        value: '',
        label: 'Seleziona un ruolo',
        disabled: true,
      }] as SelectOption[]),
    [filterJobPositions, jobPositions],
  );

  const searchJobPosition = useCallback(
    (jobPositionId: string) => jobPositions?.find(({ id }) => id === jobPositionId),
    [jobPositions],
  );

  const onInternalChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const jobPosition = searchJobPosition(e.target.value);

    const customEvent = ({
      target: {
        name,
        value: jobPosition,
      },
    } as any as React.ChangeEvent<HTMLSelectElement>);

    if (onChange) {
      onChange(customEvent);
    }
  }, [searchJobPosition, name, onChange]);

  return (
    <Form.Select
      {...rest}
      name={name}
      value={value?.id}
      options={jobPositionSelectOptions}
      loading={(!jobPositions.length && !jobPositionsError) || !jobPositionSelectOptions}
      error={!!jobPositionsError}
      help={jobPositionsError && JOB_POSITIONS_LOAD_ERROR}
      onChange={onInternalChange}
    />
  );
};

export default JobPositionsSelect;
