/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Action, HStack, Icon, ICON_EMAIL_OUTLINE, ICON_MAP_MARKER_OUTLINE, ICON_PHONE_OUTLINE, Link, Message, Popover, PopoverProps, Spacing, Stack, Text,
} from '@doveit/bricks';
import { useNavigate } from 'react-router-dom';
import PopoverPreview from '../../../components/popover-preview/PopoverPreview';
import { Agent } from '../../../providers/api/dtos';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import PopoverPreviewSkeleton from '../../../components/popover-preview/PopoverPreview.skeleton';
import { jobTitleLabels } from '../../../labels';
import * as styles from './ViewAgentPopoverPreview.style';
import AgentAvatar from '../../components/agent-avatar/AgentAvatar';
import UserActions from '../../../components/user-actions/UserActions';
import { getAgentName } from '../../utils/utils';

interface BaseViewAgentPopoverPreviewProps extends React.AriaAttributes {
  trigger: PopoverProps['trigger'],
  hideAction?: boolean,
}

export type ViewAgentPopoverPreviewProps = BaseViewAgentPopoverPreviewProps & ({
  agentId: NonNullable<Agent['id']>,
  agent?: undefined,
} | {
  agent: Agent,
  agentId?: undefined,
});

export const LOAD_AGENT_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare i dati dell\'agente';

const ViewAgentPopoverPreview: React.FC<ViewAgentPopoverPreviewProps> = ({
  agentId,
  agent: externalAgent,
  trigger,
  hideAction = true,
  ...rest
}) => {
  const navigate = useNavigate();
  const { data: loadedAgent, isLoading: isAgentLoading, error: agentError } = useAgent(agentId);
  const agent = externalAgent || loadedAgent;

  const agentEmail = React.useMemo(() => agent?.email.work || agent?.email.personal, [agent]);
  const agentPhoneNumber = React.useMemo(() => agent?.mobile.work || agent?.mobile.personal, [agent]);

  const goToAgentPage = React.useCallback(() => {
    navigate(`/admin/agents/${agent!.id}`);
  }, [agent, navigate]);

  return (
    <Popover
      trigger={trigger}
      hasArrow
      {...rest}
    >
      <div css={styles.wrapper}>
        {(isAgentLoading && !agentError) && (
          <PopoverPreviewSkeleton />
        )}
        {(agentError) && (
          <Spacing padding={200}>
            <Message
              type="critical"
              message={LOAD_AGENT_DATA_ERROR_MESSAGE}
            />
          </Spacing>
        )}
        {agent && (
          <PopoverPreview
            title={getAgentName(agent)!}
            details={jobTitleLabels[agent.jobTitle]}
            avatar={(
              <AgentAvatar
                inTrial={agent.inTrial}
                needsSupervisor={agent.needsSupervisor}
                photoId={agent.photoId}
                size="L"
              />
            )}
          >
            <Stack gap={150}>
              <HStack wrap="nowrap" gap={75}>
                <Icon path={ICON_PHONE_OUTLINE} size={20} />
                <Text.Body>
                  <Link href={`tel:${agentPhoneNumber}`}>
                    {agentPhoneNumber}
                  </Link>
                </Text.Body>
              </HStack>
              <HStack wrap="nowrap" gap={75}>
                <Icon path={ICON_EMAIL_OUTLINE} size={20} />
                <Text.Body>
                  <Link href={`mailto:${agentEmail}`}>
                    {agentEmail}
                  </Link>
                </Text.Body>
              </HStack>
              <div css={styles.geo}>
                <Icon path={ICON_MAP_MARKER_OUTLINE} size={20} />
                <Text.Body>{agent.coveredGeo?.map((geo) => geo.province).join(', ')}</Text.Body>
              </div>
              <HStack gap={75} wrap="nowrap">
                {!hideAction && (
                  <div css={styles.mainAction}>
                    <Action
                      label="Visualizza"
                      aria-label="Pulsante per visualizzare i dettagli di un agente"
                      size="S"
                      color="primary"
                      onClick={goToAgentPage}
                      expanded
                    />
                  </div>
                )}
                <UserActions
                  user={{
                    phoneNumber: agentPhoneNumber,
                    email: agentEmail,
                  }}
                  userIsAgent
                />
              </HStack>
            </Stack>
          </PopoverPreview>
        )}
      </div>
    </Popover>
  );
};

export default ViewAgentPopoverPreview;
