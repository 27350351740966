import React, { ComponentType } from 'react';

import SearchAgentAreas from '../../containers/agent-areas/SearchAgentAreas';
import AgentCoveredAreasContainer from '../../containers/agent-covered-areas/AgentCoveredAreasContainer';
import { AreaType } from '../../../domain/types';

export interface SearchSectionType {
  id: string;
  label: string;
  path?: string;
  component: ComponentType<any>;
  props?: {
    [key: string]: any,
  };
}

export const AGENT_SEARCH_ZIPCODE_SECTION: SearchSectionType = {
  id: 'agents-search-zipcode',
  label: 'CAP',
  path: '/zipcode',
  component: () => <SearchAgentAreas areaType={AreaType.ZIP_CODE} />,
};

export const AGENT_SEARCH_LOCALITY_SECTION: SearchSectionType = {
  id: 'agents-search-locality',
  label: 'Città',
  path: '/locality',
  component: () => <SearchAgentAreas areaType={AreaType.LOCALITY} />,
};

export const AGENT_SEARCH_COVERED_AREAS_SECTION: SearchSectionType = {
  id: 'agents-search-areas',
  label: 'Agenti',
  path: '/areas',
  component: () => <AgentCoveredAreasContainer />,
};

export const AGENT_SEARCH_SECTIONS: SearchSectionType[] = [
  AGENT_SEARCH_ZIPCODE_SECTION,
  AGENT_SEARCH_LOCALITY_SECTION,
  AGENT_SEARCH_COVERED_AREAS_SECTION,
];
