import {
  ActionIcon, Form, FormActionsAlign, ICON_ARCHIVE_OUTLINE, Message, Modal, Portal, useModal, useNotifications, Text,
} from '@doveit/bricks';
import React, {
  FunctionComponent, useState, useCallback, useMemo, ReactChild,
} from 'react';
import { IntentStatus } from '../../../domain/types';
import { OfferStatus } from '../../../domain/types/offerStatus';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { hasPendingOffers } from '../../../offer/utils';
import { Intent } from '../../../providers/api/dtos/intent';
import { Offer } from '../../../providers/api/dtos/offer';
import { updateIntent } from '../../../providers/api/intent/intentProvider';
import validationSchema from './ArchiveIntent.schema';
import { koOfferRefusedReasonOptions, koReasonOptions } from './koReasonOptions';

export interface ArchiveIntentProps {
  intent: Intent,
  intentOffers: Offer[],
  disabled?: boolean,
  onSuccess?: (intent: Intent) => void,
  children?: ({ openArchiveModal }: { openArchiveModal: VoidFunction }) => ReactChild,
}

export const ARCHIVE_INTENT_DEFAULT_NOTES = 'Interesse archiviato';
export const ARCHIVE_INTENT_SUCCESS_MESSAGE = 'L\'interesse è stato archiviato';
export const ARCHIVE_INTENT_ERROR_MESSAGE = 'Non è stato possibile archiviare l\'interesse';
export const CANCEL_APPOINTMENTS_REMINDERS_WARNING_MESSAGE = 'Archiviando l\'interesse verranno automaticamente annullati gli appuntamenti programmati e i promemoria futuri, qualora presenti.';

const ArchiveIntent: FunctionComponent<ArchiveIntentProps> = ({
  intent,
  intentOffers,
  disabled,
  onSuccess,
  children,
}) => {
  const [isSaving, setSaving] = useState(false);
  const { user, mainUserRole } = useRBAC();
  const confirmModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const openConfirmModal = useCallback(
    () => confirmModal.open(),
    [confirmModal],
  );

  const hasOnePendingOffer = useMemo(() => hasPendingOffers(intentOffers), [intentOffers]);
  const hasRefusedOffer = useMemo(() => intentOffers[0] && intentOffers[0].status === OfferStatus.REFUSED,
    [intentOffers]);

  const onSubmit = useCallback(async (values: { notes: string, status?: IntentStatus }) => {
    setSaving(true);

    const text = values.notes
      ? `${ARCHIVE_INTENT_DEFAULT_NOTES}: ${values.notes}`
      : ARCHIVE_INTENT_DEFAULT_NOTES;

    try {
      const updatedIntent = await updateIntent(intent.id!, {
        ...intent,
        status: values.status!,
        notes: [{
          author: user!.name,
          role: mainUserRole!,
          date: new Date().toISOString(),
          text,
        }, ...(intent.notes || [])],
      });

      addSuccess(ARCHIVE_INTENT_SUCCESS_MESSAGE);
      await confirmModal.closeWithDelay();

      if (onSuccess) {
        onSuccess(updatedIntent);
      }
    } catch (error) {
      addError(ARCHIVE_INTENT_ERROR_MESSAGE);
    }

    setSaving(false);
  }, [intent, user, mainUserRole, addSuccess, confirmModal, onSuccess, addError]);

  if (
    intent.status !== IntentStatus.IN_PROGRESS || hasOnePendingOffer
  ) {
    return null;
  }

  return (
    <>
      {children
        ? children({ openArchiveModal: openConfirmModal })
        : (
          <ActionIcon
            label="Archivia"
            icon={{ path: ICON_ARCHIVE_OUTLINE }}
            color="critical"
            disabled={disabled}
            onClick={openConfirmModal}
          />
        )}
      <Portal>
        <Modal
          open={confirmModal.isOpen}
          onCloseHandler={confirmModal.close}
          loading={!user}
        >
          <Modal.Header>
            <Text.H4>
              Archivia interesse d&apos;acquisto
            </Text.H4>
          </Modal.Header>
          <Modal.Close />
          <Modal.Body padded>
            <Form
              initialValues={{ notes: '' }}
              loading={isSaving}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              <Form.Item size={{ LG: 1 / 2 }}>
                <Form.Select
                  name="status"
                  label="Motivazione"
                  aria-label="Campo per inserire Ragione dell'archiviazione"
                  options={hasRefusedOffer ? koOfferRefusedReasonOptions : koReasonOptions}
                  required
                />
              </Form.Item>
              <Form.Item>
                <Form.TextArea
                  label="Note"
                  aria-label="Campo per inserire eventuali note aggiuntive"
                  name="notes"
                  placeholder="Inserisci eventuali note aggiuntive"
                />
              </Form.Item>
              <Form.Item>
                <Message
                  type="warning"
                  message={CANCEL_APPOINTMENTS_REMINDERS_WARNING_MESSAGE}
                />
              </Form.Item>
              <Form.Actions align={FormActionsAlign.RIGHT}>
                <Form.Submit
                  label="Conferma"
                />
              </Form.Actions>
            </Form>
          </Modal.Body>
        </Modal>
      </Portal>
    </>
  );
};

export default ArchiveIntent;
