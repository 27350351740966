import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, ICON_PLAYLIST_CHECK } from '@doveit/bricks';
import { Contact } from '../../../providers/api/dtos/contact';
import useContactLeads from '../../hooks/use-contact-leads/useContactLeads';

interface ChildFnProps {
  disabled: boolean,
  process: VoidFunction
}

export interface ProcessContactProps {
  contact: Contact,
  children?: (props: ChildFnProps) => React.ReactNode
}

const ProcessContact: React.FC<ProcessContactProps> = ({
  contact,
  children,
}) => {
  const navigate = useNavigate();
  const { data: leads, error: leadsError } = useContactLeads(contact.id!);

  const goToProcessPage = React.useCallback(() => navigate(`/call-center/contacts/process/${contact.id}`),
    [navigate, contact]);

  const isDisabled = React.useMemo(
    () => !!(leadsError || leads?.length === 0 || !contact.phoneNumber),
    [leadsError, leads, contact],
  );

  return (
    children
      ? children({ disabled: isDisabled, process: goToProcessPage })
      : (
        <ActionIcon
          icon={{ path: ICON_PLAYLIST_CHECK }}
          label="Processa"
          disabled={isDisabled}
          onClick={goToProcessPage}
        />
      ));
};

export default ProcessContact;
