export enum OwnershipType {
  INTERA_PROPRIETA = 'INTERA_PROPRIETA',
  NUDA_PROPRIETA = 'NUDA_PROPRIETA',
  PARZIALE_PROPRIETA = 'PARZIALE_PROPRIETA',
  USUFRUTTO = 'USUFRUTTO',
  MULTIPROPRIETA = 'MULTIPROPRIETA',
  DIRITTO_DI_SUPERFICIE = 'DIRITTO_DI_SUPERFICIE',
}

export const wizardOwnershipTypes = [OwnershipType.NUDA_PROPRIETA, OwnershipType.INTERA_PROPRIETA, 'ALTRO'] as const;

export type OwnershipTypeWizard = typeof wizardOwnershipTypes[number];
