/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, ReactNode } from 'react';
import { ICON_HOME_OUTLINE, ActionIcon, HStack } from '@doveit/bricks';
import { useNavigate } from 'react-router-dom';
import * as styles from './TopBar.styles';

export interface TopBarProps {
  leftSlot?: ReactNode,
  rightSlot?: ReactNode,
}

const TopBar: FunctionComponent<TopBarProps> = ({
  leftSlot,
  rightSlot,
}) => {
  const navigate = useNavigate();

  return (
    <HStack alignItems="center" wrap="wrap">
      <div css={styles.leftColumn}>
        <HStack>
          <ActionIcon
            label="Vai in homepage"
            icon={{
              path: ICON_HOME_OUTLINE,
            }}
            onClick={() => navigate('/')}
          />
          {leftSlot !== undefined && leftSlot}
        </HStack>
      </div>
      {rightSlot && (
        <div css={styles.rightColumn}>
          <HStack wrap="wrap" justifyContent="flex-end">
            {rightSlot}
          </HStack>
        </div>
      )}
    </HStack>
  );
};

export default TopBar;
