/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
  Grid, Typography, Icon, ICON_FORMAT_LIST_BULLETED_SQUARE, ICON_MAGNIFY, ICON_PHONE_OUTLINE, ICON_PLUS,
  ICON_ACCOUNT_SEARCH_OUTLINE,
  ICON_COG_OUTLINE,
  ICON_MAP_SEARCH_OUTLINE,
  ICON_ACCOUNT_MULTIPLE_OUTLINE,
  Action,
  Stack,
  ICON_MESSAGE_PROCESSING_OUTLINE,
  ICON_HEART_OUTLINE,
  ICON_HOME_PLUS_OUTLINE,
  ICON_ARROW_UP,
  ICON_DOMAIN,
  ICON_MAP_CHECK_OUTLINE,
  ICON_ACCOUNT_COG_OUTLINE,
  ICON_CASH_SYNC,
  ICON_ACCOUNT_OUTLINE,
} from '@doveit/bricks';
import { useNavigate } from 'react-router-dom';
import GenericPageLayout from '../../layouts/generic-page-layout/GenericPageLayout';
import { NavigationItem } from '../../types';
import Xmas, { isXmasTime } from './Xmas';
import Card from '../../components/card/Card';
import * as styles from './DashboardPage.style';
import useRBAC from '../../hooks/use-rbac/useRBAC';
import { useCurrentAgent } from '../../hooks/use-agent/useAgent';
import { isTrialAgent } from '../../utils/agent/isTrialAgent';

type PanelType = {
  id: string,
  icon: string,
  title: string,
  renderConditions: boolean,
  menu: NavigationItem[],
};

const DashboardPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const {
    user,
    userIsAdmin,
    userIsAgent,
    userIsCallCenter,
    userIsContentEditor,
    userIsFinance,
    userIsHR,
    userIsAreaManager,
  } = useRBAC();
  const currentAgent = useCurrentAgent();

  const goToPage = useCallback((url: string) => navigate(url), [navigate]);

  const showProspectsPanel = userIsAdmin || userIsAgent || userIsCallCenter;
  const showGeographyPanel = userIsAdmin || userIsCallCenter || userIsContentEditor;
  const showLeadsPanel = userIsAdmin || userIsCallCenter;
  const showAdministrationPanel = userIsAdmin || userIsFinance || userIsCallCenter || userIsContentEditor || userIsHR;
  const showHRPanel = true;
  const enableAdminMenuItem = userIsAdmin;
  const enableFinanceMenuItem = userIsFinance;
  const enableRumorMenu = userIsAdmin || (userIsAgent && !currentAgent?.inTrial) || userIsCallCenter;
  const enableManageJobApplications = userIsAdmin || userIsHR || userIsAreaManager;
  const enableSearchJobApplication = userIsAdmin || userIsHR || userIsAreaManager;

  const isXmas = useMemo(() => isXmasTime(), []);
  const panels: PanelType[] = useMemo(() => [
    {
      id: 'call-center-panel',
      icon: ICON_PHONE_OUTLINE,
      title: 'Call Center',
      renderConditions: !!showLeadsPanel,
      menu: [
        {
          id: 'add-lead',
          label: 'Aggiungi lead',
          icon: ICON_PLUS,
          path: '/call-center/contacts/create',
        },
        {
          id: 'handle-call-center',
          label: 'Gestisci lead',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
          path: '/call-center/contacts/process',
        },
        {
          id: 'messaging',
          label: 'Centro messaggi',
          icon: ICON_MESSAGE_PROCESSING_OUTLINE,
          path: '/call-center/messaging',
        },
      ],
    },
    {
      id: 'contacts-panel',
      icon: ICON_ACCOUNT_OUTLINE,
      renderConditions: !!user && !isTrialAgent(currentAgent),
      title: 'Contatti',
      menu: [{
        id: 'search-contact',
        label: 'Cerca contatto',
        icon: ICON_MAGNIFY,
        path: '/contacts/search',
      }],
    },
    {
      id: 'intents-panel',
      icon: ICON_HEART_OUTLINE,
      title: 'Interessi',
      renderConditions: !!user,
      menu: [
        {
          id: 'add-intent',
          label: 'Aggiungi interesse',
          icon: ICON_PLUS,
          path: '/intents/create',
        },
        {
          id: 'process-intents',
          label: 'Gestisci interessi',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
          path: '/intents/explore',
        },
        {
          id: 'search-criteria-add',
          label: 'Aggiungi ricerca',
          icon: ICON_HOME_PLUS_OUTLINE,
          path: '/search-criteria/create',
        },
      ],
    },
    {
      id: 'prospects-panel',
      icon: ICON_ARROW_UP,
      title: 'Acquisizione',
      renderConditions: !!showProspectsPanel,
      menu: [
        {
          id: 'add-rumor',
          label: 'Aggiungi notizia',
          icon: ICON_PLUS,
          path: '/rumors/create',
          disabled: !enableRumorMenu,
        },
        {
          id: 'handle-rumors',
          label: 'Gestisci notizie',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
          path: '/rumors/explore',
          disabled: !enableRumorMenu,
        },
        {
          id: 'handle-prospects',
          label: 'Gestisci valutazioni',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
          path: '/prospects/explore',
        },
      ],
    },
    {
      id: 'properties-panel',
      icon: ICON_DOMAIN,
      title: 'Immobili',
      renderConditions: !!user,
      menu: [
        {
          id: 'process-properties',
          label: 'Attività immobili',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
          path: '/properties/process',
        },
        {
          id: 'browse-properties',
          label: 'Consulta immobili',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
          path: '/properties/browse',
        },
        {
          id: 'search-properties',
          label: 'Cerca immobile',
          icon: ICON_MAGNIFY,
          path: '/properties/search',
          disabled: currentAgent?.inTrial,
        },
      ],
    },
    {
      id: 'geographies-panel',
      icon: ICON_MAP_CHECK_OUTLINE,
      title: 'Geografie',
      renderConditions: !!showGeographyPanel,
      menu: [
        {
          id: 'search-agent-by-cap',
          label: 'Cerca CAP',
          icon: ICON_MAGNIFY,
          path: '/agent/search/zipcode',
        },
        {
          id: 'search-agent-by-locality',
          label: 'Cerca città',
          icon: ICON_MAP_SEARCH_OUTLINE,
          path: '/agent/search/locality',
        },
        {
          id: 'search-agent-by-areas',
          label: 'Cerca agente',
          icon: ICON_ACCOUNT_SEARCH_OUTLINE,
          path: '/agent/search/areas',
        },
      ],
    },
    {
      id: 'administration-panel',
      icon: ICON_COG_OUTLINE,
      title: 'Amministrazione',
      renderConditions: !!showAdministrationPanel,
      menu: [
        {
          id: 'handle-agents',
          label: 'Gestisci agenti',
          icon: ICON_ACCOUNT_COG_OUTLINE,
          path: '/admin/agents',
          disabled: !(userIsAdmin || userIsContentEditor),
        },
        {
          id: 'search-agent',
          label: 'Cerca agente',
          icon: ICON_MAGNIFY,
          path: '/admin/agents/search',
          disabled: !(userIsAdmin || userIsCallCenter || userIsHR || userIsContentEditor),
        },
        {
          id: 'handle-invoices',
          label: 'Gestisci fatturazione',
          icon: ICON_CASH_SYNC,
          path: '/admin/invoices/todo',
          disabled: !enableAdminMenuItem && !enableFinanceMenuItem,
        },
      ],
    },
    {
      id: 'hr-panel',
      icon: ICON_ACCOUNT_MULTIPLE_OUTLINE,
      title: 'HR',
      renderConditions: showHRPanel && !currentAgent?.inTrial,
      menu: [
        {
          id: 'add-job-application',
          label: 'Aggiungi candidatura',
          icon: ICON_PLUS,
          path: '/job-applications/create',
        },
        {
          id: 'handle-job-applications',
          label: 'Gestisci candidature',
          icon: ICON_FORMAT_LIST_BULLETED_SQUARE,
          path: '/job-applications/process/todo',
          disabled: !enableManageJobApplications,
        },
        {
          id: 'search-job-application',
          label: 'Cerca candidatura',
          icon: ICON_MAGNIFY,
          path: '/job-applications/search',
          disabled: !enableSearchJobApplication,
        },
      ],
    },
  ], [currentAgent, enableAdminMenuItem, enableFinanceMenuItem, enableManageJobApplications, enableRumorMenu, enableSearchJobApplication, showAdministrationPanel, showGeographyPanel, showHRPanel, showLeadsPanel, showProspectsPanel, user, userIsAdmin, userIsCallCenter, userIsContentEditor, userIsHR]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content>
        {user && (
          <div
            css={styles.user}
            isXmasTime={isXmas}
          >
            {isXmas && (
              <Xmas />
            )}
            <h1>
              {isXmas ? 'Buone feste' : 'Bentornato'} <strong>{user.name}</strong>
            </h1>
          </div>
        )}
        <Grid gutter={150}>
          {panels.map((panel) => panel.renderConditions && (
            <Grid.Unit
              key={panel.id}
              size={{ LG: 1 / 3 }}
            >
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Icon path={panel.icon} />
                    <Typography.HEADING_3 color="brand.primary">
                      {panel.title}
                    </Typography.HEADING_3>
                  </Card.Title>
                </Card.Header>
                <Card.Box>
                  <Stack>
                    {panel.menu.map((menuItem) => (
                      <div key={menuItem.id}>
                        <Action
                          label={menuItem.label}
                          iconLeft={menuItem.icon ? { path: menuItem.icon } : undefined}
                          alignment="left"
                          color="neutral"
                          onClick={() => goToPage(menuItem.path!)}
                          disabled={menuItem.disabled}
                          expanded
                        />
                      </div>
                    ))}
                  </Stack>
                </Card.Box>
              </Card>
            </Grid.Unit>
          ))}
        </Grid>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default DashboardPage;
