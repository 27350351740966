/* eslint-disable react/no-unknown-property */
import React, { MutableRefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import { Action, FormHandlers, HStack } from '@doveit/bricks';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import useFilters, { Filters, FiltersMapper } from '../../../hooks/use-filters/useFilters';
import { Intent } from '../../../providers/api/dtos';
import CreateIntentWithAppointment from '../../containers/create-intent-with-appointment/CreateIntentWithAppointment';
import Divider from '../../../components/divider/Divider';

interface CreateIntentPageFilters extends Filters {
  contactId?: string;
  propertyId?: number;
}

export const CREATE_INTENT_SUCCESS_MESSAGE = 'Interesse d\'acquisto salvato.';
export const CREATE_INTENT_ERROR_MESSAGE = 'Non é stato possibile salvare l\'interesse d\'acquisto.';
export const LOAD_CONTACT_ERROR_MESSAGE = 'Errore durante il caricamento del contatto. Riprova inserendo i dati manualmente.';
export const DUPLICATED_INTENT_WARNING_MESSAGE = 'Non é stato possibile creare un nuovo interesse perché già esistente.';

export const filtersMapper: FiltersMapper<CreateIntentPageFilters> = {
  contactId: (value) => value,
  propertyId: (value) => (!Number.isNaN(Number(value)) ? Number(value) : undefined),
};

const CreateIntentPage: React.FC = () => {
  const navigate = useNavigate();
  const { filters: { contactId, propertyId } } = useFilters(filtersMapper);
  const [intentIsCreated, setIntentIsCreated] = React.useState(false);
  const formRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;

  const onIntentCreated = React.useCallback(
    () => setIntentIsCreated(true),
    [],
  );

  const onSuccess = React.useCallback(
    (intent: Intent) => navigate(`/intents/${intent.id}`),
    [navigate],
  );

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent>
          <CreateIntentWithAppointment
            propertyId={propertyId}
            contactId={contactId}
            onIntentCreated={onIntentCreated}
            onAppointmentCreated={onSuccess}
            onAppointmentAborted={onSuccess}
            createIntentFormRef={formRef}
          />
          {!intentIsCreated && (
            <>
              <Divider />
              <HStack alignItems="center" justifyContent="flex-end">
                <Action
                  label="Aggiungi interesse"
                  color="primary"
                  emphasis="high"
                  aria-label="Pulsante per aggiungere un nuovo interesse d'acquisto"
                  onClick={submitForm}
                />
              </HStack>
            </>
          )}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default CreateIntentPage;
