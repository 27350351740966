import React from 'react';
import {
  HStack, Icon, ICON_CLOCK_OUTLINE, Text,
} from '@doveit/bricks';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import { Event } from '../../interfaces';
import { computeEventIcon, printEventDuration } from './utils';
import DateViewer from '../../../components/date-viewer/DateViewer';

export interface ViewEventRegistryTableRowProps {
  event: Event,
}

const ViewEventRegistryTableRow: React.FC<ViewEventRegistryTableRowProps> = ({
  event,
}) => (
  <SimpleTable.Row>
    <SimpleTable.Cell width="7rem">
      <HStack gap={75} wrap="nowrap">
        <Icon path={computeEventIcon(event.type)} size={20} />
        <Text.BodySmall aria-label="event-today-date">
          <DateViewer
            stringDate={event.date}
            checkIfToday
            withStrong
          />
        </Text.BodySmall>
      </HStack>
    </SimpleTable.Cell>
    <SimpleTable.Cell>
      <HStack gap={75} wrap="nowrap">
        <Icon path={ICON_CLOCK_OUTLINE} size={20} />
        {printEventDuration(event.status, event.duration)}
      </HStack>
    </SimpleTable.Cell>
  </SimpleTable.Row>
);

export default ViewEventRegistryTableRow;
