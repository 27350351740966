import { IntentSection } from '../intent/types';
import {
  Intent, MatchingIntents, MatchingSearchCriteria, PropertyUnit,
} from '../providers/api/dtos';

export enum PropertySection {
  LIVE = 'live',
  DRAFT = 'draft',
  OFFER = 'offer',
  SOLD = 'sold',
  RETIRED = 'retired',
  PREVIEW = 'preview',
}

export enum PropertyMatchAdditionalSection {
  SEARCH_CRITERIA = 'search-criteria',
  SIMILAR_INTENTS = 'similar-intents',
  INTENT_ARCHIVED_WITH_DONE_APPOINTMENTS = 'with-done-appointments',
  INTENT_ARCHIVED_WITHOUT_DONE_APPOINTMENTS = 'without-done-appointments',
}

export type PropertyMatchesSection = PropertyMatchAdditionalSection | IntentSection;

export type PropertyMatches = MatchingSearchCriteria | MatchingIntents | Intent;

export type PropertyMatchesSectionCounters = {
  [key in PropertyMatchesSection]: number;
};

export enum PropertySellabilityRank {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST',
}

export type PropertyUnitModalData = {
  propertyUnit?: PropertyUnit,
  index?: number,
};
