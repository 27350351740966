import { MainRoomType } from '../domain/types';

export const propertyMainRoomLabels: Record<MainRoomType, string> = {
  [MainRoomType.STANZA_DA_LETTO]: 'Stanza da letto',
  [MainRoomType.SOGGIORNO]: 'Soggiorno',
  [MainRoomType.CUCINA]: 'Cucina',
  [MainRoomType.BAGNO]: 'Bagno',
  [MainRoomType.RIPOSTIGLIO]: 'Ripostiglio',
  [MainRoomType.CORRIDOIO]: 'Corridoio',
  [MainRoomType.MANSARDA]: 'Mansarda',
  [MainRoomType.TAVERNA]: 'Taverna',
  [MainRoomType.ALTRO]: 'Altro',
};
