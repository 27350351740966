import {
  ThemedFunctor, SpacingName, getSpacing, getTypography, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const user: ThemedFunctor = ({
  theme,
  isXmasTime,
}) => css`
  ${getSpacing.padding(theme, [0, 0, SpacingName.MEDIUM])}
  background-color: ${isXmasTime ? 'darkgreen' : 'transparent'};
  ${getTypography(theme, TypographyName.HEADING_4)}
  font-weight: normal;
  text-align: center;

  h1 {
    color: ${isXmasTime ? theme.palettes.neutrals.lightest : theme.palettes.neutrals.darkest};
    ${getSpacing.padding(theme, [0, SpacingName.SMALLER])}
  }
`;
