import { API_CONTACTS_V2, getClient } from '../api';
import { Contact, Property } from '../dtos';

export async function addContactMatchToBlacklist(
  contactId: NonNullable<Contact['id']>,
  propertyId: NonNullable<Property['id']>,
): Promise<void> {
  const client = await getClient();

  await client.post<void>(`${API_CONTACTS_V2}/${contactId}/match-blacklist/${propertyId}`);
}

export async function removeContactMatchFromBlacklist(
  contactId: NonNullable<Contact['id']>,
  propertyId: NonNullable<Property['id']>,
): Promise<void> {
  const client = await getClient();

  await client.delete<void>(`${API_CONTACTS_V2}/${contactId}/match-blacklist/${propertyId}`);
}
