import {
  Action,
  ActionProps,
  ModalActions, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { MultimediaForm } from '../../multimedia/components/multimedia-form/MultimediaForm';
import { Content, Property } from '../../../providers/api/dtos';
import { MultimediaModel } from '../../multimedia/models/multimediaModel';
import { ContentType } from '../../../domain/types';
import { createContent, deleteContent, updateContent } from '../../../providers/api/content/contentProvider';
import { contentTypeLabels } from '../../../labels';

export interface UpsertPropertyVideoActionCustomViewProps {
  edit?: ModalActions<unknown>['open'],
}

export interface UpsertPropertyVideoActionProps extends React.AriaAttributes {
  label?: string,
  size?: ActionProps['size'],
  video?: Content,
  propertyId: NonNullable<Property['id']>
  contentType: ContentType.VIDEO | ContentType.VIRTUAL_TOUR,
  children?: (customViewProps: UpsertPropertyVideoActionCustomViewProps) => React.ReactNode,
  onSuccess?: VoidFunction,
}

export const SAVE_VIDEO_CONTENT_SUCCESS_MESSAGE = 'Il __CONTENT_TYPE__ è stato salvato correttamente.';
export const SAVE_VIDEO_CONTENT_ERROR_MESSAGE = 'Non è stato possibile salvare il __CONTENT_TYPE__.';
export const DELETE_VIDEO_CONTENT_SUCCESS_MESSAGE = 'Il __CONTENT_TYPE__ è stato eliminato correttamente.';
export const DELETE_VIDEO_CONTENT_ERROR_MESSAGE = 'Non è stato possibile eliminare il __CONTENT_TYPE__ associato.';

const UpsertPropertyVideoAction: React.FC<UpsertPropertyVideoActionProps> = ({
  label,
  size = 'S',
  video,
  propertyId,
  contentType,
  children,
  onSuccess,
  'aria-label': ariaLabel = 'Azione per gestire il video',
  ...rest
}) => {
  const upsertPropertyNotesModal = useModal();
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();

  const onSubmit = React.useCallback(async ({ url }: MultimediaModel) => {
    setIsSaving(true);
    const isDeleteOperation = video?.id && video.url === url;

    try {
      if (isDeleteOperation) {
        await deleteContent(video.id!);
      } else {
        const payload: Content = {
          id: null,
          position: 0, // @TODO: remove
          ...video,
          contentType,
          propertyId,
          url: url!,
          fileName: url!,
        };

        await (video?.id ? updateContent(video.id, payload) : createContent(payload));
      }
      addSuccess((isDeleteOperation
        ? DELETE_VIDEO_CONTENT_SUCCESS_MESSAGE
        : SAVE_VIDEO_CONTENT_SUCCESS_MESSAGE
      ).replace('__CONTENT_TYPE__', contentTypeLabels[contentType]));
      onSuccess?.();
    } catch (err) {
      addError((
        isDeleteOperation
          ? DELETE_VIDEO_CONTENT_ERROR_MESSAGE
          : SAVE_VIDEO_CONTENT_ERROR_MESSAGE
      ).replace('__CONTENT_TYPE__', contentTypeLabels[contentType]));
    }

    setIsSaving(false);
  }, [video, addSuccess, contentType, onSuccess, propertyId, addError]);

  return (
    <>
      {children
        ? children({ edit: upsertPropertyNotesModal.open })
        : (
          <Action
            {...rest}
            aria-label={ariaLabel}
            label={label || 'Gestisci'}
            size={size}
            onClick={upsertPropertyNotesModal.open}
          />
        )}
      <Portal>
        <SimpleModal
          {...upsertPropertyNotesModal}
          aria-label="Modale di gestione video"
          title={`${video ? 'Modifica' : 'Aggiungi'} ${contentTypeLabels[contentType]}`}
        >
          <MultimediaForm
            initialValues={{ url: video?.url ?? undefined }}
            contentType={contentType}
            loading={isSaving}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpsertPropertyVideoAction;
