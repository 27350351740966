import { LeadStatus } from '../../../domain/types';
import { getClient, API_LEADS } from '../api';
import { Lead, LeadAssignedAgent } from '../dtos';

export interface LeadsProviderFilters {
  excludeStatus?: LeadStatus | LeadStatus[]
}

export async function getLead(leadId: string): Promise<Lead> {
  const client = await getClient();
  const { data } = await client.get<Lead>(`${API_LEADS}/${leadId}`);
  return data;
}

export async function createLead(lead: Lead): Promise<Lead> {
  const client = await getClient();
  const { data } = await client.post<Lead>(API_LEADS, lead);
  return data;
}

export async function updateLead(leadId: string, lead: Lead): Promise<Lead> {
  const client = await getClient();
  const { data } = await client.put<Lead>(`${API_LEADS}/${leadId}`, lead);
  return data;
}

export async function updateLeads(leads: Lead[]): Promise<Lead[]> {
  const client = await getClient();
  const { data } = await client.put<Lead[]>(API_LEADS, leads);
  return data;
}

export async function getLeadAssignedAgent(leadId: string): Promise<LeadAssignedAgent> {
  const client = getClient();
  const { data } = await (await client).get(`${API_LEADS}/${leadId}/assign`);
  return data;
}
