/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
import {
  Action, ActionIcon, Badge, BreakpointQueryName, Form, FormHandlers, FormSubmitType, HStack, ICON_ARROW_UP, ICON_CONTENT_COPY, ICON_MESSAGE_REPLY_TEXT_OUTLINE, Message, ModalSize, Portal, Skeleton, Spacing, Stack, Text, useModal,
  useNotifications,
} from '@doveit/bricks';
import React from 'react';
import * as Yup from 'yup';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import SimpleModal from '../../components/simple-modal/SimpleModal';
import * as styles from './SendWhatsappAction.style';
import { copyToClipboard } from '../../utils/text/text';

type ChildFnProps = {
  onClick: VoidFunction,
  disabled?: boolean,
};

type FormModel = {
  message: string,
};

export type WhatsappMessage = { id: string; title: string; text: string };

export interface SendWhatsappActionProps {
  phoneNumber: string,
  name?: string,
  initialMessage?: string | (() => Promise<string>),
  disabled?: boolean
  fetchMessages?: () => Promise<WhatsappMessage[]>,
  children?: (props: ChildFnProps) => React.ReactNode,
  onMessageSent?: (message: string) => void,
}

export const WHATSAPP_URL = 'https://wa.me/';
export const COPY_TEXT_SUCCESS_MESSAGE = 'Messaggio copiato negli appunti.';
export const COPY_TEXT_ERROR_MESSAGE = 'Non è stato possibile copiare il messaggio negli appunti.';
export const LOADING_MESSAGES_ERROR_MESSAGE = 'Non è stato possibile caricare i messaggi predefiniti.';
export const NO_MESSAGES_INFO_MESSAGE = 'Non sono presenti messaggi predefiniti.';

function formatMessage(message: string) {
  return message.replace(/<br\s*\/?>/gi, '\n');
}

const validationSchema = Yup.object().shape({
  message: Yup.string().required('Non puoi inviare un messaggio vuoto.').min(2, 'Messaggio troppo corto.'),
});

const SendWhatsappAction: React.FC<SendWhatsappActionProps> = ({
  phoneNumber,
  name,
  initialMessage,
  disabled,
  fetchMessages,
  onMessageSent,
  children,
}) => {
  const [currentMessage, setCurrentMessage] = React.useState<string>(typeof initialMessage === 'string' ? initialMessage : '');
  const modal = useModal();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(BreakpointQueryName.LG));
  const [isCopying, setIsCopying] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const [showDefaultMessages, setShowDefaultMessages] = React.useState(false);
  const [areMessagesLoading, setAreMessagesLoading] = React.useState(false);
  const [loadingMessagesError, setLoadingMessagesError] = React.useState<string | undefined>();
  const [messages, setMessages] = React.useState<WhatsappMessage[] | undefined>();
  const [initialMessageLoading, setInitialMessageLoading] = React.useState(false);

  const initialValues = React.useMemo(
    () => ({ message: currentMessage || '' }),
    [currentMessage],
  );

  const openModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const onClose = React.useCallback(() => {
    modal.close();
    setCurrentMessage('');
    setShowDefaultMessages(false);
  }, [modal]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const redirectToWhatsapp = React.useCallback((message?: string) => {
    const encodedMessage = message ? encodeURI(message) : undefined;
    window.open(`${WHATSAPP_URL}${phoneNumber}${encodedMessage ? `?text=${encodedMessage}` : ''}`);
  }, [phoneNumber]);

  const onMessageChange: React.ChangeEventHandler<HTMLTextAreaElement> = React.useCallback(
    ({ target: { value } }) => { setCurrentMessage(value); },
    [],
  );

  const disableAction = React.useMemo(() => disabled || !phoneNumber || parsePhoneNumber(phoneNumber, 'IT')?.getType() !== 'MOBILE', [disabled, phoneNumber]);

  const onClick = React.useCallback(
    async () => {
      let messageValue;
      if (typeof initialMessage === 'function') {
        setInitialMessageLoading(true);

        try {
          messageValue = await initialMessage();
          setCurrentMessage(messageValue);
        } finally {
          setInitialMessageLoading(false);
        }
      }

      if (fetchMessages) {
        openModal();
      } else {
        redirectToWhatsapp(messageValue ?? currentMessage);
      }
    },
    [currentMessage, fetchMessages, initialMessage, openModal, redirectToWhatsapp],
  );

  const onSubmit: FormSubmitType<FormModel> = React.useCallback((values) => {
    redirectToWhatsapp(values.message);
    onClose();
    onMessageSent?.(values.message);
  }, [onClose, onMessageSent, redirectToWhatsapp]);

  const onMessageClick = React.useCallback((textMessage: string) => () => {
    setCurrentMessage(formatMessage(textMessage));
    setShowDefaultMessages(false);
  }, []);

  const onCopyMessageText = React.useCallback((message: string) => async () => {
    setIsCopying(true);

    try {
      await copyToClipboard(formatMessage(message));

      setIsCopying(false);
      addSuccess(COPY_TEXT_SUCCESS_MESSAGE);
    } catch (error) {
      setIsCopying(false);
      addError(COPY_TEXT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess]);

  const openMessagesView = React.useCallback(async () => {
    setShowDefaultMessages(true);

    if (!messages) {
      setAreMessagesLoading(true);

      try {
        const result = await fetchMessages?.();
        setAreMessagesLoading(false);
        setMessages(result);
      } catch {
        setAreMessagesLoading(false);
        setLoadingMessagesError(LOADING_MESSAGES_ERROR_MESSAGE);
      }
    }
  }, [fetchMessages, messages]);

  return (
    <>
      {children
        ? children({ onClick, disabled: disableAction })
        : (
          <Action
            label="Invia whatsapp"
            aria-label="Pulsante per inviare whatsapp"
            size="S"
            onClick={onClick}
            disabled={disableAction}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title={`Invia whatsapp a ${name || phoneNumber}`}
          size={ModalSize.MEDIUM}
          aria-label="Finestra per comporre e inviare il messaggio whatsapp"
          close={onClose}
          footerContentAlignment={showDefaultMessages ? 'left' : 'right'}
          footer={!showDefaultMessages ? (
            <Action
              label="Invia whatsapp"
              color="primary"
              emphasis="high"
              aria-label="Pulsante di invio messaggio whatsapp"
              onClick={submitForm}
            />
          ) : (
            <Action
              label="Torna al messaggio"
              color="neutral"
              iconLeft={{ path: ICON_ARROW_UP, direction: 'left' }}
              emphasis="high"
              aria-label="Pulsante per tornare alla scrittura del messaggio"
              onClick={() => setShowDefaultMessages(false)}
            />
          )}
        >
          {!showDefaultMessages && (
            <div aria-label="Box di scrittura messaggio">
              <Form
                aria-label="Modulo di scrittura messaggio whatsapp"
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                innerRef={formRef}
                loading={initialMessageLoading}
                enableReinitialize
                preventEnterKeypress
              >
                <Form.Item>
                  <Stack gap={75}>
                    <HStack justifyContent="flex-end">
                      <Action
                        label="Seleziona predefinito"
                        aria-label="Seleziona template predefinito"
                        size="XS"
                        onClick={openMessagesView}
                        iconLeft={{ path: ICON_MESSAGE_REPLY_TEXT_OUTLINE }}
                      />
                    </HStack>
                    <Form.TextArea
                      name="message"
                      aria-label="Area di testo per comporre il messaggio"
                      rows={8}
                      placeholder="Inserisci il messaggio da inviare o selezionane uno da quelli predefiniti"
                      onChange={onMessageChange}
                    />
                  </Stack>
                </Form.Item>
              </Form>
            </div>
          )}
          {showDefaultMessages && (
            <div aria-label="Box di selezione messaggi predefiniti">
              {(!areMessagesLoading && loadingMessagesError) && (
                <Message
                  type="critical"
                  message={loadingMessagesError}
                />
              )}
              {areMessagesLoading && (
                <div
                  aria-label="Messaggi predefiniti in caricamento"
                  css={styles.messages}
                >
                  <div css={styles.message} $skeleton>
                    <Skeleton count={3} />
                  </div>
                  <div css={styles.message} $skeleton>
                    <Skeleton count={2} />
                  </div>
                  <div css={styles.message} $skeleton>
                    <Skeleton count={3} />
                  </div>
                </div>
              )}
              {(!areMessagesLoading && messages && messages.length === 0) && (
                <Message
                  type="neutral"
                  message={NO_MESSAGES_INFO_MESSAGE}
                />
              )}
              {(!areMessagesLoading && messages && messages.length > 0) && (
                <div aria-label="Selezione messaggio predefinito">
                  <Stack gap={75}>
                    <Text.H4 color="primary.default.low">
                      Seleziona il messaggio. Potrai modificarlo successivamente.
                    </Text.H4>
                  </Stack>
                  {currentMessage && !isDesktop && (
                    <Spacing margin={[300, 0, 0]}>
                      <Message
                        type="info"
                        message="La selezione di un messaggio sovrascrive il contenuto dell'area di testo."
                      />
                    </Spacing>
                  )}
                  <ul css={styles.messages}>
                    {messages?.map((message) => (
                      <li
                        key={`${message.title}-${message.id}`}
                        id={`${message.title}-${message.id}`}
                        css={styles.message}
                        aria-label="Messaggio predefinito"
                        $hasWarning={!!currentMessage}
                      >
                        <div
                          role="button"
                          aria-label="Messaggio predefinito (area cliccabile)"
                          css={styles.overlay}
                          onClick={onMessageClick(message.text)}
                        >
                          {isDesktop && (
                            <Badge
                              size="XS"
                              color={currentMessage ? 'warning' : 'primary'}
                              label={currentMessage
                                ? 'Seleziona e sostituisci'
                                : 'Seleziona'}
                            />
                          )}
                        </div>
                        <div css={styles.messageTitle}>
                          {message.title}
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: message.text }} />
                        <div css={styles.copy}>
                          <ActionIcon
                            icon={{ path: ICON_CONTENT_COPY }}
                            label="Copia il messaggio"
                            aria-label="Pulsante per copiare il messaggio negli appunti"
                            size="S"
                            loading={isCopying}
                            onClick={onCopyMessageText(message.text)}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </SimpleModal>
      </Portal>
    </>
  );
};

export default SendWhatsappAction;
