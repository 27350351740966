import React from 'react';
import {
  ActionDropDown, Badge, Dropdown, Skeleton,
} from '@doveit/bricks';
import { ComputedProspectStatus, ComputedProspectStatusCanBeUpdatedTo, useComputedProspectStatus } from '../../hooks/use-computed-prospect-status/useComputedProspectStatus';
import { Appointment, Prospect } from '../../../providers/api/dtos';
import { computedProspectStatusLabels } from '../../../labels/prospect';
import { computedProspectStatusActionColors, computedProspectStatusActionIcon } from './constants';
import UpsertAppointmentAction from '../../../appointment/containers/upsert-appointment-action/UpsertAppointmentAction';
import { ProspectStatus, ReferenceType } from '../../../domain/types';
import RestoreProspectAction from '../restore-prospect-action/RestoreProspectAction';
import ArchiveProspectAction from '../archive-prospect-action/ArchiveProspectAction';
import GoToAssignmentCreationPageAction from '../../../property/assignment/containers/go-to-assignment-creation-page-action/GoToAssignmentCreationPageAction';
import CancelAppointmentAction from '../../../containers/cancel-appointment-action/CancelAppointmentAction';

export interface UpdateProspectStatusProps {
  prospect: Prospect,
  onSuccess?: (updatedProspect: Prospect) => void,
  emphasis?: 'low' | 'high',
  size?: 'XS' | 'S',
  showIcon?: boolean,
  showAsBadge?: boolean,
}

const UpdateProspectStatus: React.FC<UpdateProspectStatusProps> = ({
  prospect,
  onSuccess,
  emphasis = 'low',
  size = 'S',
  showIcon = false,
  showAsBadge = false,
}) => {
  const {
    data: computedProspectStatus, isLoading: isComputedProspectStatusLoading, errors: computedProspectStatusError, mutate: mutateComputedProspectStatus,
  } = useComputedProspectStatus(prospect);

  const canBeUpdatedTo = React.useCallback(
    (action: ComputedProspectStatusCanBeUpdatedTo) => computedProspectStatus && computedProspectStatus.canBeUpdatedTo.includes(action),
    [computedProspectStatus],
  );

  const onChangeAppointment = React.useCallback(() => {
    mutateComputedProspectStatus();
    onSuccess?.(prospect);
  }, [mutateComputedProspectStatus, onSuccess, prospect]);

  if (!computedProspectStatus || computedProspectStatusError || computedProspectStatus.status === 'NO_STATUS') {
    return null;
  }

  if (isComputedProspectStatusLoading) {
    return (
      <Skeleton width="7rem" />
    );
  }

  return (
    (showAsBadge || !computedProspectStatus.canBeUpdated)
      ? (
        <Badge
          label={computedProspectStatusLabels[computedProspectStatus.status as ComputedProspectStatus]}
          color={computedProspectStatusActionColors[computedProspectStatus.status as ComputedProspectStatus]}
          icon={showIcon ? computedProspectStatusActionIcon[computedProspectStatus.status as ComputedProspectStatus] : undefined}
          emphasis={emphasis}
          size={size}
          aria-label="Badge per mostrare lo stato della valutazione"
        />
      )
      : (
        <ActionDropDown
          label={computedProspectStatusLabels[computedProspectStatus.status as ComputedProspectStatus]}
          color={computedProspectStatusActionColors[computedProspectStatus.status as ComputedProspectStatus]}
          iconLeft={showIcon ? { path: computedProspectStatusActionIcon[computedProspectStatus.status as ComputedProspectStatus] } : undefined}
          emphasis={emphasis}
          size={size}
          aria-label="Selezione per aggiornare lo stato della valutazione"
        >
          {canBeUpdatedTo('APPOINTMENT') && (
            <UpsertAppointmentAction
              aria-label="Pulsante di aggiunta dell'appuntamento"
              appointment={{
                agentId: prospect.agentId,
                contactId: prospect.contactId,
                referenceId: prospect.id!,
                referenceType: ReferenceType.PROSPECT,
              } as Appointment}
              onSuccess={onChangeAppointment}
            >
              {({ upsert }) => (
                <Dropdown.Option
                  label="Aggiungi appuntamento"
                  onClick={upsert}
                />
              )}
            </UpsertAppointmentAction>
          )}

          {canBeUpdatedTo('APPOINTMENT_CANCELLED') && (
            <CancelAppointmentAction
              contactId={prospect.contactId}
              referenceId={prospect.id!}
              referenceType={ReferenceType.PROSPECT}
              onSuccess={onChangeAppointment}
            >
              {({ cancel }) => (
                <Dropdown.Option
                  label="Annulla appuntamento"
                  onClick={cancel}
                />
              )}
            </CancelAppointmentAction>
          )}

          {canBeUpdatedTo(ProspectStatus.ASSIGNMENT_CREATED) && (
            <GoToAssignmentCreationPageAction prospect={prospect}>
              {(props) => (
                <Dropdown.Option
                  label="Crea mandato"
                  {...props}
                />
              )}
            </GoToAssignmentCreationPageAction>
          )}

          {canBeUpdatedTo('RESTORED') && (
            <RestoreProspectAction
              prospect={prospect}
              onSuccess={onSuccess}
            >
              {({ restore }) => (
                <Dropdown.Option
                  label="Ripristina"
                  onClick={restore}
                />
              )}
            </RestoreProspectAction>
          )}

          {canBeUpdatedTo('ARCHIVED') && (
            <ArchiveProspectAction
              prospect={prospect}
              onSuccess={onSuccess}
            >
              {({ openArchiveProspectModal }) => (
                <Dropdown.Option
                  label="Archivia"
                  onClick={openArchiveProspectModal}
                />
              )}
            </ArchiveProspectAction>
          )}
        </ActionDropDown>
      )
  );
};

export default UpdateProspectStatus;
