import React from 'react';
import { HStack, Text } from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { Property } from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import { energyClassLabels, propertyUnitIntendedUseLabels } from '../../../labels';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Status } from '../../../domain/types';
import UpsertPropertyUnitAction from '../upsert-property-unit-action/UpsertPropertyUnitAction';
import DeletePropertyUnitAction from '../delete-property-unit-action/DeletePropertyUnitAction';
import { NO_VALUE_SYMBOL } from '../../utils';

export interface ViewPropertyUnitTableRowProps {
  property: Property,
  unitIndex: number,
  onPropertyUpdate?: (updatedProperty: Property) => void
}

const ViewPropertyUnitTableRow: React.FC<ViewPropertyUnitTableRowProps> = ({
  property,
  unitIndex,
  onPropertyUpdate,
}) => {
  const { status } = property;
  const { userIsAdmin, userIsAgent, userIsContentEditor } = useRBAC();

  const propertyUnit = React.useMemo(() => property.cadastralRegistry?.units?.[unitIndex], [property.cadastralRegistry?.units, unitIndex]);

  const showAction = React.useMemo(() => (userIsAdmin || userIsContentEditor)
    || (userIsAgent && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE, Status.PROPOSTA].includes(status)), [status, userIsAdmin, userIsAgent, userIsContentEditor]);

  if (!propertyUnit) {
    return null;
  }

  return (
    <SimpleTable.Row aria-label="Riga di una unità catastale">
      <SimpleTable.Cell>
        <Text.Body>
          {propertyUnit.section || NO_VALUE_SYMBOL}
        </Text.Body>
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Text.Body>
          {propertyUnit.sheet || NO_VALUE_SYMBOL}
        </Text.Body>
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Text.Body>
          {propertyUnit.parcel || NO_VALUE_SYMBOL}
        </Text.Body>
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Text.Body>
          {propertyUnit.subunit || NO_VALUE_SYMBOL}
        </Text.Body>
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Text.Body>
          {propertyUnit.category || NO_VALUE_SYMBOL}
        </Text.Body>
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Text.Body>
          {propertyUnit.intendedUse ? propertyUnitIntendedUseLabels[propertyUnit.intendedUse] : NO_VALUE_SYMBOL}
        </Text.Body>
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Text.Body>
          {propertyUnit.value ? formatEuro(propertyUnit.value) : NO_VALUE_SYMBOL}
        </Text.Body>
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Text.Body>
          {propertyUnit.energyClass ? energyClassLabels[propertyUnit.energyClass] : NO_VALUE_SYMBOL}
        </Text.Body>
      </SimpleTable.Cell>
      {showAction && (
        <SimpleTable.Cell align="right">
          <HStack gap={75}>
            <UpsertPropertyUnitAction
              property={property}
              unitIndex={unitIndex}
              onSuccess={onPropertyUpdate}
            />
            <DeletePropertyUnitAction
              property={property}
              unitIndex={unitIndex}
              onSuccess={onPropertyUpdate}
            />
          </HStack>
        </SimpleTable.Cell>
      )}
    </SimpleTable.Row>
  );
};

export default ViewPropertyUnitTableRow;
