import hash from 'object-hash';
import useSWR from 'swr';
import { getContactIntents } from '../../../providers/api/contact/contactProvider';
import { Intent } from '../../../providers/api/dtos/intent';
import { IntentsProviderFilters } from '../../../providers/api/intent/intentProvider';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

const CONTACT_INTENTS_RID = '/contacts/:id/intents';

export default function useContactIntents(
  contactId?: string,
  filters?: Pick<IntentsProviderFilters, 'excludeStatus'>,
): SWRCustomResponse<Intent[], Error> {
  const matchMutate = useMatchMutate();
  const rid = [CONTACT_INTENTS_RID, contactId].join();
  const key = contactId ? [rid, hash(filters || {})] : null;
  const result = useSWR(key, () => getContactIntents(contactId!, filters ?? {}));

  return {
    ...result,
    mutate: () => matchMutate(rid),
  };
}
