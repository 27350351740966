import * as Yup from 'yup';
import { BooleanString } from '../../../../utils/boolean-string/booleanString';
import { NUMBER_REGEX } from '../../../../constants';

export const currencyValidation = Yup.string()
  .matches(NUMBER_REGEX, 'Il campo deve contenere un valore numerico intero. Es. 210000');

const validationSchema = Yup.object().shape({
  price: currencyValidation.required('Il campo è obbligatorio'),
  minimumPrice: currencyValidation
    .test('lte-price', (value, context) => {
      if (value !== undefined && Number(context.parent.price) < Number(value)) {
        return context.createError({
          path: 'minimumPrice',
          message: 'Il prezzo minimo deve essere minore del prezzo',
        });
      }
      return true;
    })
    .required('Il campo è obbligatorio'),
  evaluation: currencyValidation.required('Il campo è obbligatorio'),
  exclusive: Yup.string()
    .oneOf(['true', 'false'])
    .required('Il campo è obbligatorio'),
  autoRenew: Yup.string()
    .oneOf(['true', 'false'])
    .required('Il campo è obbligatorio'),
  keysHanded: Yup.string()
    .oneOf(['true', 'false'])
    .required('Il campo è obbligatorio'),
  isMortgageInProgress: Yup.string()
    .oneOf(['true', 'false'])
    .required('Il campo è obbligatorio'),
  residualMortgage: Yup.string().when('isMortgageInProgress', {
    is: (isMortgageInProgress: BooleanString) => isMortgageInProgress === 'true',
    then: (schema) => schema.required('Il campo è obbligatorio'),
    otherwise: (schema) => schema.notRequired(),
  }),
  endDate: Yup.string()
    .required('Il campo è obbligatorio'),
});

export default validationSchema;
