import React from 'react';
import { AgentArea } from '../../providers/api/dtos/agentArea';
import Table, { DEFAULT_TABLE_PAGE_SIZE } from '../table/Table';
import areasTableColumns from './columnsSettings';
import Card from '../card/Card';

export interface AreasTableProps extends React.AriaAttributes {
  data: AgentArea[],
}

const AreasTable: React.FC<AreasTableProps> = ({
  data,
  ...rest
}) => (
  <Card>
    <Card.Box>
      <Table
        {...rest}
        data={data}
        columns={areasTableColumns}
        pageable={data.length > DEFAULT_TABLE_PAGE_SIZE}
        sortable
        filterable
      />
    </Card.Box>
  </Card>
);

export default AreasTable;
