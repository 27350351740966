import { ActionProps } from '@doveit/bricks';
import { AdditionalService } from './additionalService';
import { Offer } from './offer';
import { Appointment } from './appointment';
import { Document } from './document';
import { Intent } from './intent';
import { Contact } from './contact';
import { Agent } from './agent';
import { Property, PropertyPreview } from './property';
import { Lead } from './lead';
import { Assignment, AssignmentWizardData, Signer } from './assignment';
import { Shooting } from './shooting';
import { Prospect } from './prospect';
import { Rumor } from './rumor';

type NoticeType =
  | 'CONTACT_TO_PROCESS_NON_ASSIGNABLE_LEADS'
  | 'CONTACT_TO_PROCESS_EXISTING_MATCHES'
  | 'CONTACT_MISSING_THIRD_PARTIES_OPT_IN'
  | 'INTENT_APPOINTMENT_OUTCOME'
  | 'INTENT_MISSING_OFFERS_APPOINTMENTS_AND_REMINDERS'
  | 'PROPERTY_EXISTING_ACCEPTED_OFFER'
  | 'OFFER_MISSING_MANDATORY_DOCUMENT_TYPES'
  | 'OFFER_REFUSED'
  | 'OFFER_MORTGAGE_ACCEPTANCE_DATE'
  | 'OFFER_DEED_DATE_EXPIRED'
  | 'OFFER_EXPIRED'
  | 'PROPERTY_ASSIGNMENT_EXPIRED'
  | 'PROPERTY_ASSIGNMENT_EXPIRING'
  | 'PROPERTY_MISSING_MANDATORY_DOCUMENTS'
  | 'PROPERTY_MANDATORY_DOCUMENTS_AWAITING_APPROVAL'
  | 'PROPERTY_MANDATORY_DOCUMENTS_REJECTED'
  | 'PROPERTY_LIVE_WITH_ACCEPTED_OFFER'
  | 'PROPERTY_IN_NEGOTIATION_WITH_IN_PROGRESS_OFFER'
  | 'PROPERTY_OFFER_DEED_NOT_CONFIRMED'
  | 'PROPERTY_REVIEWS_TO_BE_REQUESTED'
  | 'PROPERTY_PRIVACY_HIDDEN_INFO'
  | 'PROPERTY_ASSIGNMENT_SIGNATURE_ONGOING'
  | 'PROPERTY_CONTENTS_AND_SHOOTING_MISSING'
  | 'PROPERTY_MISSING_CONTENTS_AND_EXPIRED_SHOOTING'
  | 'PROPERTY_NO_PUBLISHED_ONS'
  | 'PROSPECT_MISSING_EVALUATION'
  | 'PROSPECT_NO_NEXT_ACTION_DEFINED_SUPERVISOR'
  | 'PROSPECT_NO_NEXT_ACTION_DEFINED_AGENT'
  | 'PROSPECT_PENDING_APPROVAL'
  | 'PROSPECT_REQUEST_APPROVAL'
  | 'RUMOR_NO_NEXT_ACTION_DEFINED_SUPERVISOR'
  | 'RUMOR_MISSING_INFO'
  | 'RUMOR_NO_CONTACT_ASSOCIATED'
  | 'RUMOR_NO_NEXT_ACTION_DEFINED_AGENT';

export type IntentNoticeData =
  | ContactMissingThirdPartiesOptInNoticeData
  | IntentAppointmentOutcomeNoticeData
  | IntentMissingOffersAppointmentsAndRemindersNoticeData
  | PropertyExistingAcceptedOfferNoticeData
  | OfferMissingMandatoryDocumentTypesNoticeData
  | OfferRefusedNoticeData
  | OfferMortgageAcceptanceDateNoticeData
  | OfferDeedDateExpiredNoticeData
  | OfferExpiredNoticeData;

export type ContactToProcessNoticeData =
  | ContactToProcessNonAssignableLeadsNoticeData
  | ContactToProcessExistingMatchesNoticeData;

export type PropertyNoticeData =
  | PropertyAssignmentExpiredNoticeData
  | PropertyAssignmentExpiringNoticeData
  | PropertyMissingMandatoryDocumentsNoticeData
  | PropertyMandatoryDocumentsAwaitingApprovalNoticeData
  | PropertyMandatoryDocumentsRejectedNoticeData
  | PropertyLiveWithAcceptedOfferNoticeData
  | PropertyInNegotiationWithInProgressOfferNoticeData
  | PropertyOfferDeedNotConfirmedNoticeData
  | PropertyReviewsToBeRequestedNoticeData
  | PropertyPrivacyHiddenInfoNoticeData
  | PropertyAssignmentSignatureOngoingNoticeData
  | PropertyContentAndShootingMissingNoticeData
  | PropertyMissingContentsAndExpiredShootingNoticeData
  | PropertyNoPublishedOnesNoticeData;

export type ProspectNoticeData =
  | ProspectMissingEvaluationNoticeData
  | ProspectNoNextActionDefinedSupervisorNoticeData
  | ProspectNoNextActionDefinedAgentNoticeData
  | ProspectPendingApprovalNoticeData
  | ProspectRequestApprovalNoticeData;

export type RumorNoticeData =
  | RumorNoNextActionDefinedSupervisorNoticeData
  | RumorMissingInfoNoticeData
  | RumorNoContactAssociatedNoticeData
  | RumorNoNextActionDefinedAgentNoticeData;

export interface RumorNoNextActionDefinedSupervisorNoticeData {
  type: 'RUMOR_NO_NEXT_ACTION_DEFINED_SUPERVISOR',
  referenceId: string,
  data: {
    rumor: Rumor,
  }
}

export interface RumorNoNextActionDefinedAgentNoticeData {
  type: 'RUMOR_NO_NEXT_ACTION_DEFINED_AGENT',
  referenceId: string,
  data: {
    rumor: Rumor,
  }
}

export interface RumorMissingInfoNoticeData {
  type: 'RUMOR_MISSING_INFO',
  referenceId: string,
  data: {
    rumor: Rumor,
  }
}

export interface RumorNoContactAssociatedNoticeData {
  type: 'RUMOR_NO_CONTACT_ASSOCIATED',
  referenceId: string,
  data: {
    rumor: Rumor,
  }
}

export interface ProspectMissingEvaluationNoticeData {
  type: 'PROSPECT_MISSING_EVALUATION',
  referenceId: string,
  data: {
    prospect: Prospect,
  }
}

export interface ProspectPendingApprovalNoticeData {
  type: 'PROSPECT_PENDING_APPROVAL',
  referenceId: string,
  data: {
    prospect: Prospect,
  }
}

export interface ProspectRequestApprovalNoticeData {
  type: 'PROSPECT_REQUEST_APPROVAL',
  referenceId: string,
  data: {
    prospect: Prospect,
  }
}

export interface ProspectNoNextActionDefinedSupervisorNoticeData {
  type: 'PROSPECT_NO_NEXT_ACTION_DEFINED_SUPERVISOR',
  referenceId: string,
  data: {
    prospect: Prospect,
  }
}

export interface ProspectNoNextActionDefinedAgentNoticeData {
  type: 'PROSPECT_NO_NEXT_ACTION_DEFINED_AGENT',
  referenceId: string,
  data: {
    prospect: Prospect,
  }
}

export interface ContactToProcessNonAssignableLeadsNoticeData {
  type: 'CONTACT_TO_PROCESS_NON_ASSIGNABLE_LEADS',
  referenceId: string,
  data: {
    nonAssignableLeads: Lead[],
  }
}

export interface ContactToProcessExistingMatchesNoticeData {
  type: 'CONTACT_TO_PROCESS_EXISTING_MATCHES',
  referenceId: string,
  data: {
    contactMatches: Contact[],
  }
}

export interface ContactMissingThirdPartiesOptInNoticeData {
  type: 'CONTACT_MISSING_THIRD_PARTIES_OPT_IN',
  referenceId: string,
  data: {
    additionalServices: AdditionalService[],
  }
}

export interface IntentAppointmentOutcomeNoticeData {
  type: 'INTENT_APPOINTMENT_OUTCOME',
  referenceId: string,
  data: {
    appointments: Appointment[],
  }
}

export interface IntentMissingOffersAppointmentsAndRemindersNoticeData {
  type: 'INTENT_MISSING_OFFERS_APPOINTMENTS_AND_REMINDERS',
  referenceId: string,
  data: {
    property: PropertyPreview,
    agent: Agent,
    contact: Contact,
  }
}

export interface PropertyExistingAcceptedOfferNoticeData {
  type: 'PROPERTY_EXISTING_ACCEPTED_OFFER',
  referenceId: number,
  data: {
    intent: Intent,
  }
}

export interface OfferMissingMandatoryDocumentTypesNoticeData {
  type: 'OFFER_MISSING_MANDATORY_DOCUMENT_TYPES',
  referenceId: number,
  data: {
    documents: Document[],
  }
}

export interface OfferRefusedNoticeData {
  type: 'OFFER_REFUSED',
  referenceId: number,
  data: {
    offer: Offer,
  }
}

export interface OfferMortgageAcceptanceDateNoticeData {
  type: 'OFFER_MORTGAGE_ACCEPTANCE_DATE',
  referenceId: number,
  data: {
    offer: Offer,
  }
}

export interface OfferDeedDateExpiredNoticeData {
  type: 'OFFER_DEED_DATE_EXPIRED',
  referenceId: number,
  data: {
    offer: Offer,
  }
}

export interface OfferExpiredNoticeData {
  type: 'OFFER_EXPIRED',
  referenceId: number,
  data: {
    offer: Offer,
  }
}

export interface PropertyAssignmentExpiredNoticeData {
  type: 'PROPERTY_ASSIGNMENT_EXPIRED',
  referenceId: NonNullable<Property['id']>,
  data: {
    assignment: Assignment,
  }
}

export interface PropertyAssignmentExpiringNoticeData {
  type: 'PROPERTY_ASSIGNMENT_EXPIRING',
  referenceId: NonNullable<Property['id']>,
  data: {
    assignment: Assignment,
  }
}

export interface PropertyMissingMandatoryDocumentsNoticeData {
  type: 'PROPERTY_MISSING_MANDATORY_DOCUMENTS',
  referenceId: NonNullable<Property['id']>,
  data: {
    missingDocuments: Document[],
  }
}

export interface PropertyMandatoryDocumentsAwaitingApprovalNoticeData {
  type: 'PROPERTY_MANDATORY_DOCUMENTS_AWAITING_APPROVAL',
  referenceId: NonNullable<Property['id']>,
  data: {
    documentsAwaitingApproval: Document[],
  }
}

export interface PropertyMandatoryDocumentsRejectedNoticeData {
  type: 'PROPERTY_MANDATORY_DOCUMENTS_REJECTED',
  referenceId: NonNullable<Property['id']>,
  data: {
    documentsRejected: Document[],
  }
}

export interface PropertyLiveWithAcceptedOfferNoticeData {
  type: 'PROPERTY_LIVE_WITH_ACCEPTED_OFFER',
  referenceId: NonNullable<Property['id']>,
  data: {
    property: Property,
  }
}

export interface PropertyInNegotiationWithInProgressOfferNoticeData {
  type: 'PROPERTY_IN_NEGOTIATION_WITH_IN_PROGRESS_OFFER',
  referenceId: NonNullable<Property['id']>,
  data: {
    property: Property,
  }
}

export interface PropertyOfferDeedNotConfirmedNoticeData {
  type: 'PROPERTY_OFFER_DEED_NOT_CONFIRMED',
  referenceId: NonNullable<Property['id']>,
  data: {
    offer: Offer,
  }
}

export interface PropertyReviewsToBeRequestedNoticeData {
  type: 'PROPERTY_REVIEWS_TO_BE_REQUESTED',
  referenceId: NonNullable<Property['id']>,
  data: {
    property: Property,
  }
}

export interface PropertyPrivacyHiddenInfoNoticeData {
  type: 'PROPERTY_PRIVACY_HIDDEN_INFO',
  referenceId: NonNullable<Property['id']>,
  data: {
    property: Property,
  }
}

export interface PropertyAssignmentSignatureOngoingNoticeData {
  type: 'PROPERTY_ASSIGNMENT_SIGNATURE_ONGOING',
  referenceId: NonNullable<Property['id']>
  data: {
    signers: Signer[]
    assignmentWizardData: AssignmentWizardData,
  }
}

export interface PropertyContentAndShootingMissingNoticeData {
  type: 'PROPERTY_CONTENTS_AND_SHOOTING_MISSING',
  referenceId: NonNullable<Property['id']>
  data: {
    property: Property
  }
}

export interface PropertyMissingContentsAndExpiredShootingNoticeData {
  type: 'PROPERTY_MISSING_CONTENTS_AND_EXPIRED_SHOOTING',
  referenceId: NonNullable<Property['id']>
  data: {
    property: Property,
    shooting: Shooting,
  }
}

export interface PropertyNoPublishedOnesNoticeData {
  type: 'PROPERTY_NO_PUBLISHED_ONS',
  referenceId: NonNullable<Property['id']>
  data: {
    property: Property,
  }
}

export enum Severity {
  CRITICAL = 2,
  WARNING = 1,
  INFO = 0,
}

export type Priority = 0 | 1 | 2 | 3 | 4;

export interface Notice {
  type: NoticeType,
  severity: Severity,
  /**
   * Currently, the lower the number, the higher priority
   * @todo - make so: the higher the number, the higher priority
   */
  priority: number,
  message: string,
  children?: React.ReactNode,
  actions?: [React.ReactElement<ActionProps>] | [React.ReactElement<ActionProps>, React.ReactElement<ActionProps>],
}
