import { API_PROPERTIES_PATH, API_GARAGES_PATH, getClient } from '../api';
import { Garage } from '../dtos';

export async function createGarage(garage: Garage): Promise<Garage> {
  const client = await getClient();
  const { data } = await client.post<Garage>(API_GARAGES_PATH, garage);
  return data;
}

export async function getGarage(garageId: string | number): Promise<Garage> {
  const client = await getClient();
  const { data } = await client.get<Garage>(`${API_GARAGES_PATH}/${garageId}`);
  return data;
}

export async function updateGarage(garage: Garage): Promise<Garage> {
  const client = await getClient();
  const { data } = await client.put<Garage>(`${API_GARAGES_PATH}/${garage.id}`, garage);
  return data;
}

export async function deleteGarage(garageId: string | number): Promise<{ id: number | string }> {
  const client = await getClient();
  const { data } = await client.delete(`${API_GARAGES_PATH}/${garageId}`);
  return data;
}

export async function getGaragesByPropertyId(propertyId: number): Promise<Garage[]> {
  const client = await getClient();
  const { data } = await client.get(`${API_PROPERTIES_PATH}/${propertyId}/garages`);
  return data;
}
