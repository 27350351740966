import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import {
  ActionIcon, ConfirmModal, ICON_FILE_SIGN, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { JobApplicationStatus } from '../../../domain/types/jobApplicationStatus';
import { JobApplication } from '../../../providers/api/dtos';
import { updateJobApplication } from '../../../providers/api/job-application/jobApplicationProvider';

interface ChildFnProps {
  offer: VoidFunction,
  loading: boolean,
}

export interface OfferJobApplicationProps {
  jobApplication: JobApplication,
  onSuccess?: (jobApplication: JobApplication) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const OFFER_JOB_APPLICATION_WARNING_MESSAGE = 'Confermi di voler avanzare una proposta di assunzione al candidato?';
export const OFFER_JOB_APPLICATION_SUCCESS_MESSAGE = 'Candidato correttamente segnalato come proposta di assuzione';
export const OFFER_JOB_APPLICATION_ERROR_MESSAGE = 'Non è stato possibile segnalare il candidato come proposta di assunzione';

const OfferJobApplication: FunctionComponent<OfferJobApplicationProps> = ({
  jobApplication,
  onSuccess,
  children,
}) => {
  const offerModal = useModal();
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const isAssignedJobApplication = useMemo(
    () => jobApplication?.status === JobApplicationStatus.ASSIGNED,
    [jobApplication?.status],
  );

  const onConfirm = useCallback(async () => {
    setIsSaving(true);

    try {
      const updatedJobApplication = await updateJobApplication(
        jobApplication.id!,
        {
          ...jobApplication,
          status: JobApplicationStatus.OFFER,
        },
      );

      setIsSaving(false);
      offerModal.close();
      addSuccess(OFFER_JOB_APPLICATION_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedJobApplication);
      }
    } catch (error) {
      setIsSaving(false);
      addError(OFFER_JOB_APPLICATION_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, offerModal, jobApplication, onSuccess]);

  if (
    !(isAssignedJobApplication)
  ) {
    return null;
  }

  return (
    <> {children
      ? children({ offer: offerModal.open, loading: isSaving })
      : (
        <ActionIcon
          label="Proposta"
          onClick={offerModal.open}
          icon={{ path: ICON_FILE_SIGN }}
        />
      )}
      <Portal>
        <ConfirmModal
          isOpen={offerModal.isOpen}
          title="Conferma proposta di assunzione"
          onConfirm={onConfirm}
          onAbort={offerModal.close}
          loading={isSaving}
        >
          {OFFER_JOB_APPLICATION_WARNING_MESSAGE}
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default OfferJobApplication;
