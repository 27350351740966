import React from 'react';
import { Form, FormProps, FormSubmitType } from '@doveit/bricks';
import { BooleanString } from '../../../../utils/boolean-string/booleanString';
import { asSelectOptions } from '../../../../select-options/utils/asSelectOptions';
import { paymentMethodWizardLabels } from '../../../../labels';
import { PaymentMethod } from '../../../../providers/api/dtos';
import { formatInputDate } from '../../../../utils/form';
import { computeBalanceValue } from './utils';

export interface OfferWizardPaymentsFormModel {
  // Offer
  offerValue: string,
  offerNotaryPriceDeposit: BooleanString,

  // Deposit
  depositValue: string,
  depositType: string,
  depositBank: string,
  depositCheckNumber: string,

  // Preliminary
  preliminary: BooleanString,
  preliminaryPaymentDateWithin: string,
  depositIntegration: BooleanString,
  integrationValue: string,
  decay: BooleanString,
  daysFromDecay: string,

  // Balance
  balanceValue: string,
  balanceExpiresAt: string,
}

export interface OfferWizardPaymentsFormProps {
  initialValues?: Partial<OfferWizardPaymentsFormModel>,
  disabled?: FormProps<OfferWizardPaymentsFormModel>['disabled'],
  formRef?: FormProps<OfferWizardPaymentsFormModel>['innerRef'],
  onSubmit: FormSubmitType<OfferWizardPaymentsFormModel>,
}

export const DEFAULT_INITIAL_VALUES: OfferWizardPaymentsFormModel = {
  offerValue: '',
  offerNotaryPriceDeposit: 'false',
  depositValue: '',
  depositType: '',
  depositBank: '',
  depositCheckNumber: '',
  preliminary: '',
  preliminaryPaymentDateWithin: '',
  depositIntegration: '',
  integrationValue: '',
  decay: '',
  daysFromDecay: '',
  balanceValue: '',
  balanceExpiresAt: '',
};

const OfferWizardPaymentsForm: React.FC<OfferWizardPaymentsFormProps> = ({
  initialValues,
  disabled,
  onSubmit,
  formRef,
}) => {
  const mergedInitialValues = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      onSubmit={onSubmit}
      innerRef={formRef}
      disabled={disabled}
      initialValues={mergedInitialValues}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const onDepositPaymentMethodChange = () => {
          setFieldValue('depositBank', '', false);
          setFieldValue('depositCheckNumber', '', false);
        };

        const onPreliminaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value === 'false') {
            setFieldValue('preliminaryPaymentDateWithin', '', false);
          }
          setFieldValue('decay', '', false);
          setFieldValue('daysFromDecay', '', false);

          const computedBalanceValue = computeBalanceValue(
            values.offerValue,
            values.depositValue,
            values.integrationValue,
            e.target.value as BooleanString,
            values.depositIntegration,
          );

          setFieldValue('balanceValue', computedBalanceValue);
        };

        const onDepositIntegrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('integrationValue', '', false);
          setFieldValue('decay', '', false);
          setFieldValue('daysFromDecay', '', false);

          const computedBalanceValue = computeBalanceValue(
            values.offerValue,
            values.depositValue,
            values.integrationValue,
            values.preliminary,
            e.target.value as BooleanString,
          );

          setFieldValue('balanceValue', computedBalanceValue);
        };

        const onDecayChange = () => {
          setFieldValue('daysFromDecay', '', false);
        };

        const onOfferValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const computedBalanceValue = computeBalanceValue(
            e.target.value,
            values.depositValue,
            values.integrationValue,
            values.preliminary,
            values.depositIntegration,
          );

          setFieldValue('balanceValue', computedBalanceValue);
        };

        const onDepositValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const computedBalanceValue = computeBalanceValue(
            values.offerValue,
            e.target.value,
            values.integrationValue,
            values.preliminary,
            values.depositIntegration,
          );

          setFieldValue('balanceValue', computedBalanceValue);
        };

        const onIntegrationValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const computedBalanceValue = computeBalanceValue(
            values.offerValue,
            values.depositValue,
            e.target.value,
            values.preliminary,
            values.depositIntegration,
          );

          setFieldValue('balanceValue', computedBalanceValue);
        };

        return (
          <>
            <Form.Fieldset legend="Proposta">
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.PriceField
                  name="offerValue"
                  label="Importo proposta"
                  aria-label="Campo per inserire l'importo della proposta"
                  placeholder="Es: 100000"
                  required
                  onChange={onOfferValueChange}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.YesOrNotButtons
                  name="offerNotaryPriceDeposit"
                  label="Deposito del prezzo presso il notaio"
                  aria-label="Campo per indicare se verrà svolto il deposito del prezzo presso il notaio"
                  required
                />
              </Form.Item>
            </Form.Fieldset>

            <Form.Fieldset legend="Caparra / Deposito">
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.PriceField
                  name="depositValue"
                  label="Importo"
                  aria-label="Campo per inserire l'importo della caparra/deposito"
                  placeholder="Es: 100000"
                  required
                  onChange={onDepositValueChange}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.RadioButtonGroup
                  name="depositType"
                  label="Tipologia pagamento"
                  itemSize={{ MD: 1 / Object.keys(paymentMethodWizardLabels).length }}
                  onChange={onDepositPaymentMethodChange}
                  items={asSelectOptions(paymentMethodWizardLabels, false)}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="depositBank"
                  label="Banca"
                  aria-label="Campo per inserire il nome della banca"
                  placeholder="Inserisci nome della banca"
                  disabled={values.depositType !== PaymentMethod.CHECK}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="depositCheckNumber"
                  label="Numero assegno"
                  aria-label="Campo per inserire il numero dell'assegno"
                  placeholder="Inserisci numero assegno"
                  disabled={values.depositType !== PaymentMethod.CHECK}
                />
              </Form.Item>
            </Form.Fieldset>

            <Form.Fieldset legend="Preliminare e integrazione">
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.YesOrNotButtons
                  name="preliminary"
                  label="Preliminare"
                  aria-label="Campo per indicare se verrà svolto il preliminare"
                  onChange={onPreliminaryChange}
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.YesOrNotButtons
                  name="depositIntegration"
                  label="Integrazione caparra"
                  aria-label="Campo per indicare se è prevista l'integrazione della caparra"
                  onChange={onDepositIntegrationChange}
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.PriceField
                  name="integrationValue"
                  label="Importo"
                  aria-label="Campo per inserire l'importo dell'integrazione"
                  placeholder="Es: 100000"
                  onChange={onIntegrationValueChange}
                  disabled={
                    !values.depositIntegration
                    || values.depositIntegration === 'false'
                  }
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="preliminaryPaymentDateWithin"
                  label="Scadenza del preliminare o dell'integrazione"
                  type="date"
                  aria-label="Campo per inserire la data di scadenza del preliminare"
                  min={formatInputDate(new Date())}
                  disabled={values.preliminary === 'false' && values.depositIntegration === 'false'}
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.YesOrNotButtons
                  name="decay"
                  label="Condizione di avveramento"
                  aria-label="Campo per indicare se è prevista una condizione di avveramento"
                  disabled={!values.preliminary || values.preliminary === 'false'}
                  onChange={onDecayChange}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="daysFromDecay"
                  type="number"
                  label="Giorni dall'avveramento"
                  aria-label="Campo per inserire il numero di giorni dall'avveramento"
                  placeholder="Es. 10"
                  min={1}
                  disabled={
                    !values.preliminary
                    || values.preliminary === 'false'
                    || !values.decay
                    || values.decay === 'false'
                  }
                />
              </Form.Item>
            </Form.Fieldset>

            <Form.Fieldset legend="Saldo">
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.PriceField
                  name="balanceValue"
                  label="Importo"
                  aria-label="Campo per inserire l'importo del saldo"
                  placeholder="Es: 100000"
                  required
                  disabled
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="balanceExpiresAt"
                  label="Data del rogito"
                  type="date"
                  aria-label="Campo per inserire la data del rogito"
                  min={formatInputDate(new Date())}
                  required
                />
              </Form.Item>
            </Form.Fieldset>
          </>
        );
      }}
    </Form>
  );
};

export default OfferWizardPaymentsForm;
