import React from 'react';
import {
  ActionIcon, ConfirmModal, ICON_HOME_PLUS_OUTLINE, Portal, Text, useModal, useNotifications,
} from '@doveit/bricks';
import { OfferStatus, Status } from '../../../domain/types';
import { Property } from '../../../providers/api/dtos';
import { updateProperty } from '../../../providers/api/property/propertyProvider';
import { WithRequiredKeys } from '../../../types';
import { MANDATORY_PROPERTY_DOCUMENT_TYPES } from '../../../document/constants';
import useOffersWithStatusExists from '../../../offer/hooks/use-offers-with-status-exists/useOffersWithStatusExists';

interface ChildFnProps {
  disabled: boolean,
  loading: boolean,
  publish: VoidFunction,
}

export interface PublishPropertyActionProps {
  property: Property,
  disabled?: boolean,
  onSuccess?: (publishedProperty: Property) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const PUBLISH_PROPERTY_SUCCESS_MESSAGE = 'Immobile pubblicato con successo';
export const PUBLISH_PROPERTY_ERROR_MESSAGE = 'Non è stato possibile pubblicare l\'immobile';

const PublishPropertyAction: React.FC<PublishPropertyActionProps> = ({
  property,
  disabled = false,
  onSuccess,
  children,
}) => {
  const { id } = property as WithRequiredKeys<Property, 'id'>;

  const [isSaving, setIsSaving] = React.useState(false);
  const confirmModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const { exist: existsAcceptedOffer } = useOffersWithStatusExists({ propertyId: property.id, offerStatus: OfferStatus.ACCEPTED });

  const isPublishable = React.useMemo(() => {
    const { status, approvedMandatoryDocumentCounter } = property;

    return (status === Status.ANTEPRIMA && approvedMandatoryDocumentCounter === MANDATORY_PROPERTY_DOCUMENT_TYPES.length) || status === Status.PROPOSTA;
  }, [property]);

  const props = React.useMemo(() => {
    if (property.status === Status.PROPOSTA) {
      return {
        action: {
          ariaLabel: 'Nascondi trattativa',
          title: isPublishable ? 'Nascondi trattativa' : 'Non è possibile pubblicare l\'immobile',
        },
        modal: {
          ariaLabel: 'Nascondi la trattaiva',
          title: 'Nascondi la trattativa',
          body: existsAcceptedOffer
            ? 'Nascondendo la trattativa, l\'annuncio sarà nuovamente visibile sui portali e sul sito di Dove.it senza banner di trattativa in corso, nonostante l\'eventuale presenza di una proposta accettata.'
            : 'Nascondendo la trattativa, l\'annuncio sarà nuovamente visibile sui portali e sul sito di Dove.it senza banner di trattativa in corso.',
        },
      };
    }
    return {
      action: {
        ariaLabel: 'Pubblica l\'immobile',
        title: 'Pubblica l\'immobile',
      },
      modal: {
        ariaLabel: "Conferma la pubblicazione dell'immobile",
        title: 'Pubblica immobile',
        body: 'Confermando l\'azione, l\'immobile sarà pubblicato su Dove.it e sui portali.',
      },
    };
  }, [isPublishable, property.status, existsAcceptedOffer]);

  const openConfirmModal = React.useCallback(() => {
    confirmModal.open();
  }, [confirmModal]);

  const onConfirm = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const propertyToPublish: Property = {
        ...property,
        status: Status.LIVE,
      };

      const publishedProperty = await updateProperty(id, propertyToPublish);

      setIsSaving(false);
      confirmModal.close();
      addSuccess(PUBLISH_PROPERTY_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(publishedProperty);
      }
    } catch (error) {
      setIsSaving(false);
      addError(PUBLISH_PROPERTY_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, confirmModal, id, onSuccess, property]);

  return (
    <>
      {children
        ? children({
          publish: openConfirmModal,
          disabled: disabled || !isPublishable,
          loading: isSaving,
        }) : (
          <ActionIcon
            icon={{ path: ICON_HOME_PLUS_OUTLINE }}
            label="Pubblica"
            title={props.action.title}
            onClick={openConfirmModal}
            aria-label={props.action.ariaLabel}
            disabled={disabled || !isPublishable}
          />
        )}
      <Portal>
        <ConfirmModal
          isOpen={confirmModal.isOpen}
          aria-label={props.modal.ariaLabel}
          title={props.modal.title}
          saving={isSaving}
          onConfirm={onConfirm}
          onAbort={confirmModal.close}
        >
          <Text.Body>
            Confermando l&apos;azione, l&apos;immobile sarà pubblicato su Dove.it e sui portali.
          </Text.Body>
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default PublishPropertyAction;
