import {
  BreakpointQueryName,
  getSpacing, SpacingName, ThemedFunctor,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const moreGeoCriterion: ThemedFunctor = ({ theme }) => css`
  color: ${theme.palettes.brand.primary};
`;

export const more: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.margin(theme, [SpacingName.SMALLEST, SpacingName.NEGATIVE_SMALLEST, SpacingName.NEGATIVE_SMALLEST])}
  ${getSpacing.padding(theme, SpacingName.SMALLER)}
  background-color: ${theme.palettes.neutrals.lighter};
  border-radius: 10px;
  text-align: center;
  color: ${theme.palettes.brand.primary};
  cursor: pointer;
`;

export const contactWrapper: ThemedFunctor = ({ theme }) => css`
  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    width: 15rem;
  }

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    width: 25rem;
  }
`;

export const budget: ThemedFunctor = ({ theme, $withinBudget }) => css`
  ${$withinBudget !== undefined && `
    color: ${$withinBudget ? theme.palettes.brand.secondary : theme.palettes.status.alert}
  `}
`;
