import React from 'react';
import { Form, FormActionsAlign, FormProps } from '@doveit/bricks';
import validationSchema from './HireApplicantForm.schema';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { jobTitleLabels } from '../../../labels/jobTitleLabels';
import { formatInputDate } from '../../../utils/form';
import { JobTitle } from '../../../domain/types';

export interface HireApplicantFormModel {
  name: string,
  surname: string,
  email: string,
  mobile: string,
  role: string,
  startDate: string,
  inTrial: boolean,
}

export interface HireApplicantFormProps {
  initialValues?: HireApplicantFormModel,
  onSubmit: FormProps<HireApplicantFormModel>['onSubmit'],
  hiddenFields?: 'inTrial'[],
}

export const HIRE_APPLICANT_FORM_DEFAULT_VALUES: HireApplicantFormModel = {
  name: '',
  surname: '',
  email: '',
  mobile: '',
  role: '',
  startDate: '',
  inTrial: false,
};

const HireApplicantForm: React.FC<HireApplicantFormProps> = ({
  initialValues = {},
  onSubmit,
  hiddenFields = [],
}) => {
  const mergedInitialValues = React.useMemo(() => ({
    ...HIRE_APPLICANT_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  const hiddenFieldsMap = React.useMemo(() => hiddenFields.reduce((acc, curr) => ({
    ...acc,
    [curr]: true,
  }), {} as Record<keyof HireApplicantFormModel, boolean>), [hiddenFields]);

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      preventEnterKeypress
    >
      {({ values }) => (
        <>
          <Form.Item size={{ MD: 1 / 2 }}>
            <Form.Input
              id="name"
              name="name"
              label="Nome"
              aria-label="Campo per inserire il nome"
              placeholder="Es. Gianni"
              required
            />
          </Form.Item>
          <Form.Item size={{ MD: 1 / 2 }}>
            <Form.Input
              id="surname"
              name="surname"
              label="Cognome"
              aria-label="Campo per inserire il cognome"
              placeholder="Es. Rossi"
              required
            />
          </Form.Item>
          <Form.Item size={{ MD: 1 / 2 }}>
            <Form.Input
              name="mobile"
              aria-label="Campo per inserire il telefono"
              label="Telefono"
              placeholder="Es. +39 0000000000"
              required
            />
          </Form.Item>
          <Form.Item size={{ MD: 1 / 2 }}>
            <Form.Input
              name="email"
              aria-label="Campo per inserire l'email"
              label="Email"
              placeholder="Es. mario.rossi@email.it"
              required
            />
          </Form.Item>
          <Form.Item size={{ MD: 1 / 2 }}>
            <Form.Select
              name="role"
              aria-label="Campo per inserire il ruolo"
              label="Ruolo"
              options={asSelectOptions(jobTitleLabels, {
                label: 'Seleziona',
                value: '',
                disabled: true,
              })}
              required
            />
          </Form.Item>
          <Form.Item size={{ MD: 1 / 2 }}>
            <Form.Input
              name="startDate"
              type="date"
              aria-label="Campo per inserire la data di inizio"
              label="Data di inizio"
              min={formatInputDate(new Date())}
              required
            />
          </Form.Item>
          {values.role === JobTitle.AGENTE && !hiddenFieldsMap.inTrial && (
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.CheckboxButton
                name="inTrial"
                text="Modalità trial"
                aria-label="Campo per specificare se l'agente verrà assunto in modalità trial"
              />
            </Form.Item>
          )}
          <Form.Actions align={FormActionsAlign.RIGHT}>
            <Form.Submit
              label="Assumi"
              aria-label="Assumi il candidato"
            />
          </Form.Actions>
        </>
      )}
    </Form>
  );
};

export default HireApplicantForm;
