import React from 'react';
import {
  Action,
  DetailItemList,
  HStack,
  ICON_EMAIL_OUTLINE,
  ICON_PHONE_OUTLINE,
  Message,
  Popover,
  PopoverProps,
  Spacing,
  Stack,
  Text,
} from '@doveit/bricks';
import PopoverPreview from '../../../components/popover-preview/PopoverPreview';
import { Contact } from '../../../providers/api/dtos';
import PopoverPreviewSkeleton from '../../../components/popover-preview/PopoverPreview.skeleton';
import useContact from '../../hooks/use-contact/useContact';
import ContactAvatar from '../../components/contact-avatar/ContactAvatar';
import UserActions from '../../../components/user-actions/UserActions';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';

interface BaseViewContactPopoverPreviewProps extends React.AriaAttributes {
  trigger: PopoverProps['trigger'],
  hideAction?: boolean,
}

export type ViewContactPopoverPreviewProps = BaseViewContactPopoverPreviewProps & ({
  contactId: NonNullable<Contact['id']>,
  contact?: undefined,
} | {
  contact: Contact,
  contactId?: undefined,
});

export const LOAD_CONTACT_ERROR_MESSAGE = 'Non è stato possibile caricare i dati del contatto';

const ViewContactPopoverPreview: React.FC<ViewContactPopoverPreviewProps> = ({
  contactId,
  contact: externalContact,
  trigger,
  ...rest
}) => {
  const {
    data: loadedContact,
    isLoading: isContactLoading,
    error: contactError,
  } = useContact(contactId);

  const contact = externalContact || loadedContact;

  const goToContactPage = React.useCallback(() => {
    if (!contact) return;

    goToDetailPage(contact.id!, ReferenceType.CONTACT);
  }, [contact]);

  return (
    <Popover
      trigger={trigger}
      hasArrow
      {...rest}
    >
      <div style={{ minWidth: '18rem' }}>
        {(isContactLoading && !contactError) && (
          <PopoverPreviewSkeleton />
        )}

        {(contactError) && (
          <Spacing padding={200}>
            <Message
              type="critical"
              message={LOAD_CONTACT_ERROR_MESSAGE}
            />
          </Spacing>
        )}

        {contact && (
          <PopoverPreview
            title={contact.name || 'Contatto'}
            details={contact.name && 'Contatto'}
            avatar={(
              <ContactAvatar size="L" />
            )}
          >
            <Stack gap={150}>
              <DetailItemList>
                {contact.phoneNumber && (
                  <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
                    <Text.Body>{contact.phoneNumber}</Text.Body>
                  </DetailItemList.Item>
                )}
                {contact.email && (
                  <DetailItemList.Item icon={ICON_EMAIL_OUTLINE}>
                    <Text.Body>{contact.email}</Text.Body>
                  </DetailItemList.Item>
                )}
              </DetailItemList>

              <HStack gap={75} wrap="nowrap">
                <div style={{ flex: 1 }}>
                  <Action
                    size="S"
                    color="primary"
                    label="Visualizza"
                    aria-label="Vai alla pagina del contatto"
                    onClick={goToContactPage}
                    expanded
                  />
                </div>
                <UserActions user={contact} />
              </HStack>
            </Stack>
          </PopoverPreview>
        )}
      </div>
    </Popover>
  );
};

export default ViewContactPopoverPreview;
