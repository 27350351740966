import hash from 'object-hash';
import useSWR, { SWRConfiguration } from 'swr';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';
import { getConversationMessages } from '../../../providers/api/conversation/conversationProvider';
import { ConversationMessagePaginationParams, PaginatedConversationMessageResult } from '../../../providers/conversationMessagePagination';

export const CONVERSATION_MESSAGES_RID = '/conversation/:id/messages';

export function useConversationMessages(
  conversationId: string,
  pagination?: ConversationMessagePaginationParams,
  onSuccess?: SWRConfiguration['onSuccess'],
): SWRCustomResponse<PaginatedConversationMessageResult, Error> {
  const matchMutate = useMatchMutate();

  const rid = [CONVERSATION_MESSAGES_RID, conversationId].join();
  const key = [rid, hash({ ...pagination })];

  const result = useSWR(key, () => getConversationMessages(conversationId, pagination), { onSuccess });

  return {
    ...result,
    mutate: () => matchMutate(rid),
  };
}
