import React from 'react';
import {
  Typography, Icon, Spacing, Portal, useModal, useNotifications, FormSkeleton, Message, Action, ICON_HOME_CITY_OUTLINE, FormHandlers, Fieldset, HStack, DetailItemList,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/card/Card';
import { PropertyPreview } from '../../../providers/api/dtos/property';
import PropertyAutoComplete from '../../../containers/property-autocomplete/PropertyAutoComplete';
import { Status, IntentStatus, IntentSource } from '../../../domain/types';
import { propertyTypeLabels } from '../../../labels';
import SelectContact from '../../../contact/containers/select-contact/SelectContact';
import CreateIntentForm, { CreateIntentFormModel } from '../../components/create-intent-form/CreateIntentForm';
import { Contact } from '../../../providers/api/dtos/contact';
import { createIntent, getIntents } from '../../../providers/api/intent/intentProvider';
import { Intent } from '../../../providers/api/dtos/intent';
import { SearchContactFormModel } from '../../../contact/components/search-contact-form/SearchContactForm';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { SortOrder } from '../../../providers/pagination';
import useContact from '../../../contact/hooks/use-contact/useContact';
import createOrUpdateContact from '../../../contact/utils/createOrUpdateContact';
import { AppointmentAvailability, PropertySearchItem } from '../../../providers/api/dtos';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import usePropertyPreview from '../../../property/hooks/use-property-preview/usePropertyPreview';
import useMarkIntentAsSeen from '../../../hooks/use-mark-intent-as-seen/useMarkIntentAsSeen';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';

export const SAVE_INTENT_SUCCESS_MESSAGE = 'Interesse d\'acquisto salvato. <a href="__LINK__" target="_blank">Visualizza</a>';
export const SAVE_INTENT_ERROR_MESSAGE = 'Non è stato possibile salvare l\'interesse d\'acquisto';
export const LOAD_PREFILLED_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare i dati necessari per creare l\'interesse';
export const DUPLICATED_INTENT_WARNING_MESSAGE = 'Non è stato possibile creare un nuovo interesse perché già esistente.';

export interface CreateIntentProps {
  propertyId?: number,
  contactId?: string,
  source?: IntentSource,
  formRef?: React.MutableRefObject<FormHandlers>,
  onSuccess?: (intent: Intent) => void,
}

const CreateIntent: React.FC<CreateIntentProps> = ({
  propertyId,
  contactId,
  source,
  formRef,
  onSuccess,
}) => {
  const navigate = useNavigate();
  const { data: agent } = useAgentByPropertyId(propertyId);
  const [selectedProperty, setSelectedProperty] = React.useState<PropertySearchItem | PropertyPreview | null>(null);
  const { markAsSeen } = useMarkIntentAsSeen(propertyId ? agent?.id : (selectedProperty as PropertySearchItem)?.agentId);
  const [selectedContact, setSelectedContact] = React.useState<Contact | undefined>();
  const [overwriteValues, setOverwriteValues] = React.useState<SearchContactFormModel | undefined>();
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const {
    user, userIsAdmin, userIsAgent, userIsCallCenter, mainUserRole,
  } = useRBAC();
  const existingIntentModal = useModal();
  const [existingIntent, setExistingIntent] = React.useState<Intent | undefined>();
  const {
    data: requestedProperty,
    isLoading: isRequestedPropertyLoading,
    error: requestedPropertyError,
  } = usePropertyPreview(propertyId);
  const {
    data: requestedContact,
    isLoading: isRequestedContactLoading,
    error: requestedContactError,
  } = useContact(contactId);

  React.useEffect(() => {
    if (requestedProperty) {
      setSelectedProperty(requestedProperty);
    }
  }, [requestedProperty, requestedPropertyError]);

  React.useEffect(() => {
    if (requestedContact) {
      setSelectedContact(requestedContact);
    }
  }, [requestedContact, requestedContactError]);

  const warningMessage = React.useMemo(() => {
    if ((propertyId && selectedProperty) || (contactId && selectedContact)) {
      let message = 'Stai inserendo un interesse';

      if (selectedContact) {
        message += ` del contatto <strong>${selectedContact.name || selectedContact.phoneNumber || selectedContact.email}</strong>`;
      }

      if (selectedProperty) {
        message += ` per l'immobile <strong>${selectedProperty.referenceId}</strong>`;
      }

      return (
        <Message
          type="warning"
          aria-label="Messaggio utente dati prepopolati"
          message={message}
        />
      );
    }

    return undefined;
  }, [contactId, propertyId, selectedContact, selectedProperty]);

  const onPropertyAutoCompleteChange = React.useCallback(
    () => setSelectedProperty(null),
    [],
  );

  const onContactChange = React.useCallback((contactData?: Contact, search?: SearchContactFormModel) => {
    setSelectedContact(contactData);
    setOverwriteValues(search);
  }, []);

  const onPropertySelected = React.useCallback(
    (property: PropertyPreview | PropertySearchItem | null) => setSelectedProperty(property),
    [],
  );

  const viewExistingIntent = React.useCallback(
    () => navigate(`/intents/${existingIntent!.id}`),
    [navigate, existingIntent],
  );

  const abortPropertySelection = React.useCallback(
    () => setSelectedProperty(null),
    [],
  );

  const onSubmit = React.useCallback(async (values: CreateIntentFormModel) => {
    setIsSaving(true);

    try {
      setExistingIntent(undefined);
      let contact = selectedContact;

      const searchResults = await getIntents(
        {
          contactId: contact && contact.id ? contact.id : undefined,
          propertyId: selectedProperty!.id!,
          excludeStatus: IntentStatus.KO_DUPLICATE,
        },
        {
          page: 0,
          sort: {
            createdAt: SortOrder.ASC,
          },
        },
      );

      if (contact?.id && searchResults.content.length > 0) {
        setExistingIntent(searchResults.content[0]);
        existingIntentModal.open();
      } else {
        contact = await createOrUpdateContact(overwriteValues, selectedContact);

        const payload: Intent = {
          contactId: contact!.id!,
          propertyId: selectedProperty!.id!,
          source: values.source!,
          status: IntentStatus.IN_PROGRESS,
          appointmentAvailabilities: Object.keys(values.availabilities).filter(
            (availability) => values.availabilities[availability as AppointmentAvailability],
          ) as AppointmentAvailability[],
          notes: values.notes
            ? [{
              author: user!.name,
              role: mainUserRole!,
              date: new Date().toISOString(),
              text: values.notes,
            }]
            : [],
        };

        let intent;
        intent = await createIntent(payload);
        intent = await markAsSeen(intent);

        addSuccess(SAVE_INTENT_SUCCESS_MESSAGE.replace('__LINK__', `/intents/${intent.id}`), 5000);
        onSuccess?.(intent);
      }
    } catch (error) {
      addError(SAVE_INTENT_ERROR_MESSAGE);
    }
    setIsSaving(false);
  }, [selectedContact, selectedProperty, existingIntentModal, overwriteValues, user, mainUserRole, markAsSeen, addSuccess, onSuccess, addError]);

  if (requestedPropertyError || requestedContactError) {
    return (
      <Message
        type="critical"
        message={LOAD_PREFILLED_DATA_ERROR_MESSAGE}
      />
    );
  }

  if ((propertyId && isRequestedPropertyLoading) || (contactId && isRequestedContactLoading)) {
    return <FormSkeleton aria-label="form-skeleton" />;
  }

  return (
    <div>
      {warningMessage && (
        <Spacing margin={[0, 0, 200]}>
          {warningMessage}
        </Spacing>
      )}
      {!propertyId && (
        <Fieldset legend="Dati immobile">
          {!selectedProperty && (
            <PropertyAutoComplete
              dataRef="property-autocomplete"
              aria-label="Seleziona un immobile"
              placeholder="Cerca immobile"
              status={[Status.LIVE, Status.DRAFT, Status.ANTEPRIMA]}
              onChange={onPropertyAutoCompleteChange}
              onSuggestionSelected={onPropertySelected}
              maxSuggestions={10}
            />
          )}
          {selectedProperty && (
            <Card aria-label="Anteprima dell'immobile selezionato">
              <Card.Header>
                <Card.Title>
                  <Icon path={ICON_HOME_CITY_OUTLINE} />
                  <Typography.HEADING_3 color="brand.primary">
                    Immobile
                  </Typography.HEADING_3>
                </Card.Title>
                <HStack>
                  <Action
                    label="Visualizza"
                    aria-label="Visualizza la pagina dell'immobile"
                    href={`/properties/${selectedProperty.id}`}
                    target="_blank"
                  />
                  {!propertyId && (
                    <Action
                      label="Rimuovi"
                      aria-label="Annulla la selezione dell'immobile"
                      onClick={abortPropertySelection}
                    />
                  )}
                </HStack>
              </Card.Header>
              <Card.Box>
                <DetailItemList columns={2}>
                  <DetailItemList.Item label="Ref.">
                    DV-{selectedProperty.id}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Tipologia">
                    {propertyTypeLabels[selectedProperty.propertyType]}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Indirizzo">
                    {selectedProperty.geo?.normalizedAddress || '-'}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Superficie">
                    {selectedProperty.propertySize ? `${selectedProperty.propertySize} m²` : undefined}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Richiesta">
                    {selectedProperty.price
                      ? formatEuro(selectedProperty.price)
                      : undefined}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Val. agente">
                    {selectedProperty.evaluation
                      ? formatEuro(selectedProperty.evaluation)
                      : undefined}
                  </DetailItemList.Item>
                </DetailItemList>
              </Card.Box>
            </Card>
          )}
        </Fieldset>
      )}
      {!contactId && (
        <Spacing margin={[300, 0, 0]}>
          <Fieldset legend="Dati contatto">
            <SelectContact
              onContactChange={onContactChange}
              initialContact={requestedContact}
              showClearContact={!contactId}
            />
          </Fieldset>
        </Spacing>
      )}
      <Spacing margin={[300, 0, 0]}>
        <Fieldset legend="Dati interesse">
          <CreateIntentForm
            initialValues={source && { source }}
            onSubmit={onSubmit}
            loading={!user || isSaving}
            showAvailabilities={userIsAdmin || userIsAgent || userIsCallCenter}
            hideSource={!!source}
            disabled={!selectedProperty || (!selectedContact && !overwriteValues)}
            innerRef={formRef}
          />
        </Fieldset>
      </Spacing>
      <Portal>
        <SimpleModal
          {...existingIntentModal}
          title="Interesse d'acquisto duplicato"
          aria-label="duplicated-intent-modal"
        >
          <Message type="warning" message={DUPLICATED_INTENT_WARNING_MESSAGE} />
          <Spacing margin={[400, 0, 0]}>
            <Action
              dataRef="view-existing-intent"
              label="Visualizza interesse"
              emphasis="high"
              onClick={viewExistingIntent}
            />
          </Spacing>
        </SimpleModal>
      </Portal>
    </div>
  );
};

export default CreateIntent;
