/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent } from 'react';
import * as styles from './Divider.style';

export interface DividerProps extends React.AriaAttributes {
  children?: string,
}

const Divider: FunctionComponent<DividerProps> = ({
  children,
  ...rest
}) => (
  <div
    {...rest}
    css={styles.base}
    $hasChildren={!!children}
  >
    {children && (
      children
    )}
  </div>
);

export default Divider;
