export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortParams {
  [field: string]: SortOrder;
}

export interface PaginationParams {
  page?: number;
  size?: number;
  sort?: SortParams;
}

export interface SerializedPagination {
  page?: number;
  size?: number;
  sort?: string[];
}

export interface PaginatedResult<T> {
  content: T[];
  number: number;
  first: boolean;
  last: boolean;
  size: number;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
}

export type PaginatedResultWithInfoAndStats<T> = PaginatedResult<T> & {
  stats: {
    averageSquareMeterPrice: number,
  },
  info: {
    searchRadius: number
  }
};

export function serializeSortParams(sort:SortParams):string[] {
  return Object.entries(sort).map(([field, order]) => `${field},${order}`);
}

export function serializePagination(params: PaginationParams): SerializedPagination {
  const pagination: SerializedPagination = {};

  if (params.page || params.size) {
    pagination.page = params.page || 0;
  }

  if (params.size) {
    pagination.size = params.size;
  }

  if (params.sort) {
    pagination.sort = serializeSortParams(params.sort);
  }

  return pagination;
}
