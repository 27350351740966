import React from 'react';
import { Skeleton, UserPreviewSkeleton } from '@doveit/bricks';
import { useTheme } from 'styled-components';
import SimpleTable from './SimpleTable';

export type SimpleTableRowSkeletonProps = React.AriaAttributes;

const SimpleTableRowSkeleton: React.FC<SimpleTableRowSkeletonProps> = (props) => {
  const theme = useTheme();

  return (
    <SimpleTable.Row {...props}>
      <SimpleTable.Cell>
        <div style={{ width: '10rem' }}>
          <UserPreviewSkeleton size="S" />
        </div>
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Skeleton
          width="10rem"
          height={theme.remHeight.XS}
        />
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Skeleton
          width="10rem"
          height={theme.remHeight['2XS']}
        />
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <Skeleton
          width={theme.remHeight.S}
          height={theme.remHeight.S}
        />
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default SimpleTableRowSkeleton;
