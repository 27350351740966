import { jobTitleLabels } from '../../labels';
import { NO_VALUE_SYMBOL } from '../../property/utils';
import { Agent } from '../../providers/api/dtos';

export function formatAgentDetails(jobTitle?: Agent['jobTitle'], coveredGeo: Agent['coveredGeo'] = []): string | undefined {
  if (!jobTitle && coveredGeo.length === 0) {
    return undefined;
  }

  const jobTitleText = jobTitle ? jobTitleLabels[jobTitle] : '';
  const coveredGeoText = coveredGeo.length > 0 ? `${coveredGeo[0].province}${coveredGeo.length > 1 ? `, +${coveredGeo.length - 1}` : ''}` : '';

  return [jobTitleText, coveredGeoText].filter(Boolean).join(` ${NO_VALUE_SYMBOL} `);
}
