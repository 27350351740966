/* eslint-disable react/no-unknown-property */
import { ICON_ALERT_CIRCLE_OUTLINE, Icon } from '@doveit/bricks';
import React, { FunctionComponent } from 'react';

import * as styles from './GenericErrorPage.style';

export interface GenericErrorPageProps {
  title: string;
  description?: string;
}

const GenericErrorPage: FunctionComponent<GenericErrorPageProps> = ({
  title,
  description,
}) => (
  <div css={styles.base}>
    <div css={styles.icon}>
      <Icon
        path={ICON_ALERT_CIRCLE_OUTLINE}
        size={80}
      />
    </div>
    <h1 css={styles.title}>
      {title}
    </h1>
    {
      description && (
        <p css={styles.description}>{description}</p>
      )
    }
  </div>
);

export default GenericErrorPage;
