import useSWR, { SWRResponse } from 'swr';
import { JobPosition } from '../../../providers/api/dtos/jobPosition';
import { getJobPosition } from '../../../providers/api/job-position/jobPositionProvider';

export function getjobPositionRID(id: string) {
  return ['/v1/job-positions', id];
}

export default function useJobPosition(id?: string): SWRResponse<JobPosition, Error> {
  return useSWR(id ? getjobPositionRID(id) : null, () => getJobPosition(id!));
}
