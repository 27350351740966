import React from 'react';
import { Message, MessageType } from '@doveit/bricks';
import { Notice, Severity } from '../../../providers/api/dtos/notice';

const noticeSeverityToMessageType: Record<Severity, MessageType> = {
  [Severity.CRITICAL]: 'critical',
  [Severity.WARNING]: 'warning',
  [Severity.INFO]: 'neutral',
};

export interface NoticeBoardProps {
  notices: Notice[],
}

/**
 * @todo handle loading state and errors
 */
const NoticeBoard: React.FC<NoticeBoardProps> = ({ notices }) => {
  const highlightedNotice = React.useMemo(() => {
    if (!notices.length) return null;

    return notices.sort((a, b) => {
      if (a.severity !== b.severity) {
        return b.severity - a.severity;
      }

      return a.priority - b.priority;
    })[0];
  }, [notices]);

  if (!highlightedNotice) return null;

  return (
    <Message
      boxed
      type={noticeSeverityToMessageType[highlightedNotice.severity]}
      message={highlightedNotice.message}
      actions={highlightedNotice.actions}
    >
      {highlightedNotice.children}
    </Message>
  );
};

export default NoticeBoard;
