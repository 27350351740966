import React from 'react';
import {
  DndContext, DragEndEvent, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Identifiable } from '../../../types';
import { getEnv } from '../../../utils/env';

export interface SortableListProps extends React.PropsWithChildren {
  items: Identifiable[],
  disabled?: boolean,
  onDragEnd?: (oldIdx: number, newIdx: number) => void,
}

const SortableList: React.FC<SortableListProps> = (props) => {
  const {
    items,
    disabled = false,
    children,
    onDragEnd,
  } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  React.useEffect(() => {
    if (getEnv('NODE_ENV') !== 'production') {
      const childrenCount = React.Children.count(children);
      if (items.length !== childrenCount) {
        // eslint-disable-next-line no-console
        console.warn('SortableList: The number of items does not match the number of children.');
      }
    }
  }, [children, items.length]);

  const handleDragEnd = React.useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIdx = items.findIndex(({ id }) => id === active.id);
      const newIdx = items.findIndex(({ id }) => id === over?.id);

      onDragEnd?.(oldIdx, newIdx);
    }
  }, [items, onDragEnd]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items}
        disabled={disabled}
      >
        {React.Children.map(children, (child, index) => {
          if (index < items.length && React.isValidElement(child)) {
            return React.cloneElement(child, { key: items[index].id });
          }

          return null;
        })}
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;
