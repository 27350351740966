export type WithCoordinates<T> = T & { latitude: number, longitude: number };
export type WithCoordinatesGroup<T> = WithCoordinates<T> & { items: T[] };

export default function groupDataByCoordinates<T>(items: WithCoordinates<T>[]): Map<string, WithCoordinatesGroup<T>> {
  return items.reduce((result, item) => {
    const key = `${item.latitude}-${item.longitude}`;

    result.set(key, {
      latitude: item.latitude,
      longitude: item.longitude,
      items: result.has(key) ? [...result.get(key)!.items, item] : [item],
    } as WithCoordinatesGroup<T>);

    return result;
  }, new Map<string, WithCoordinatesGroup<T>>());
}
