import { HeatingSupply } from '../domain/types';

export const heatingSupplyLabels: Record<HeatingSupply, string> = {
  GAS: 'Gas',
  METANO: 'Metano',
  GPL: 'GPL',
  GASOLIO: 'Gasolio',
  SOLARE: 'Solare',
  FOTOVOLTAICO: 'Fotovoltaico',
  TELERISCALDAMENTO: 'Teleriscaldamento',
  PELLET: 'Pellet',
  LEGNA: 'Legna',
  POMPA_DI_CALORE: 'Pompa di calore',
};
