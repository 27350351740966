/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as styles from './SimpleTableCell.style';

export interface SimpleTableCellProps extends React.PropsWithChildren, React.AriaAttributes {
  width?: React.CSSProperties['width'],
  align?: React.CSSProperties['textAlign'],
  colSpan?: number,
  withDivider?: boolean,
}

export const SimpleTableCell: React.FC<SimpleTableCellProps> = ({
  children,
  align,
  width,
  withDivider = false,
  ...rest
}) => (
  <td
    aria-label="simple-table-cell"
    $width={width}
    $align={align}
    $withDivider={withDivider}
    css={styles.td}
    {...rest}
  >
    <div
      $width={width}
      css={styles.tdContent}
    >
      {children}
    </div>
  </td>
);
