import { css, keyframes } from 'styled-components';
import {
  ThemedFunctor, getSpacing, SpacingName, rgba,
  BreakpointQueryName,
} from '@doveit/bricks';
import { NavigationDesktopHeight, NavigationMobileHeight } from '../../constants';

const flip = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(-180deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  75% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

const color = (color1: string, color2: string) => keyframes`
  0% {
    color: ${color1};
  }
  25% {
    color: ${color2};
  }
  50% {
    color: ${color1};
  }
  75% {
    color: ${color2};
  }
  100% {
    color: ${color1}; /* Torna al colore iniziale */
  }
`;

export const base = () => css`
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
`;
export const side: ThemedFunctor = ({ theme, $open }) => css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16rem;
  background: ${theme.color.background.neutral.default.lowest};
  border-right: 1px solid ${theme.color.border.neutral.default.high};
  padding-top: ${NavigationMobileHeight};
  transition: transform .4s ease-in-out, visibility .4s ease-in-out;
  transform: translateX(0);
  will-change: transform;
  box-sizing: border-box;
  z-index: ${theme.zIndex.navbar + 2};

  ${!$open && `
    visibility: hidden;
    transform: translateX(-100%);
  `}

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    padding-top: ${NavigationDesktopHeight};
  }

  ${theme.breakpoints.up(BreakpointQueryName.XXL)} {
    padding-top: 0;
    position: relative;
    display: flex;
    visibility: visible;
    transform: translateX(0);
  }
`;

export const sideMenuOverlay: ThemedFunctor = ({
  theme,
  $open,
}) => css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0;
  outline: 0;
  appearance: none;
  display: block;
  z-index: ${theme.zIndex.navbar + 2};
  background-color: ${rgba(theme.color.background.neutral.default.high, 0.6)};
  cursor: pointer;
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
  will-change: opacity;

  ${!$open && `
    opacity: 0;
    visibility: hidden;
  `}

  ${theme.breakpoints.up(BreakpointQueryName.XXL)} {
    display: none;
  }
`;

export const main = () => css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const navigation: ThemedFunctor = ({ theme }) => css`
  flex-shrink: 0;
  padding: ${theme.remSize[200]};
  background-color: ${theme.palettes.brand.primary};
  height: ${NavigationMobileHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: ${theme.zIndex.navbar + 3};
  position: relative;
  color: ${theme.color.foreground.neutral.default.lowest};
  gap: ${theme.remSize[200]};

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    padding: ${theme.remSize[300]};
  }

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    height: ${NavigationDesktopHeight};
  }
`;

export const navigationControl: ThemedFunctor = ({ theme }) => css`
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 0;
  color: currentColor;

  &:hover {
    cursor: pointer;
  }

  ${theme.breakpoints.up(BreakpointQueryName.XXL)} {
    display: none;
  }
`;

export const sideLogo: ThemedFunctor = ({ theme }) => css`
  background-color: #E31D93; // Map to color palette
  color: ${theme.color.foreground.neutral.default.lowest};
  align-items: center;
  padding: 0 ${theme.remSize[300]};
  height: ${NavigationDesktopHeight};
  display: none;
  flex-shrink: 0;

  ${theme.breakpoints.up(BreakpointQueryName.XXL)} {
    display: flex;
  }

  > button {
    width: 6.25rem;
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    line-height: 0;
    color: currentColor;
    cursor: pointer;
  }
`;

export const userBox: ThemedFunctor = ({ theme }) => css`
  padding: ${theme.remSize[300]};
  border-bottom: 1px solid ${theme.color.border.neutral.default.high};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;

export const items: ThemedFunctor = ({ theme }) => css`
  padding: ${theme.remSize[200]};
  overflow-y: auto;
  flex-grow: 1;
`;

export const page = () => css`
  flex: 1;
  position: relative;
`;

export const notifications: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, [0, SpacingName.SMALL])}
  z-index: ${theme.zIndex.overthetop};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`;

export const notificationsBox: ThemedFunctor = () => css`
  pointer-events: all;
  max-width: 50rem;
  margin: 0 auto;
`;

export const loadingContainer: ThemedFunctor = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const loading: ThemedFunctor = ({ theme }) => css`
  animation: ${flip} 3s infinite ease-in-out;
  width: 4rem;

  svg {
    animation: ${color(theme.color.foreground.primary.default.low, rgba(theme.color.foreground.primary.default.low, 0.5))} 3s infinite ease-in-out;
  }
`;
