import { EnergyClass } from '../domain/types';

export const energyClassLabels: Record<EnergyClass, string> = {
  A1: 'A1',
  A2: 'A2',
  A3: 'A3',
  A4: 'A4',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
};
