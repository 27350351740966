import React from 'react';
import {
  Action,
  ConfirmModal,
  Portal,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import { ShootingStatus } from '../../../domain/types';
import { Shooting } from '../../../providers/api/dtos';
import { updateShooting } from '../../../providers/api/shooting/shootingProvider';
import { useComputedShootingStatus } from '../../hooks/use-computed-shooting-status/useComputedShootingStatus';

interface ChildFnProps {
  isSaving: boolean,
  complete: VoidFunction,
}

export interface CompleteShootingActionProps extends React.AriaAttributes {
  shooting: Shooting,
  onSuccess?: (completedShooting: Shooting) => void,
  children?: (props: ChildFnProps) => React.ReactNode
}

export const COMPLETE_SHOOTING_ERROR_MESSAGE = 'Non è stato possibile confermare il caricamento delle foto.';
export const COMPLETE_SHOOTING_SUCCESS_MESSAGE = 'Il caricamento delle foto è stato confermato con successo.';

const CompleteShootingAction: React.FC<CompleteShootingActionProps> = ({
  shooting,
  onSuccess,
  children,
  ...rest
}) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const { data } = useComputedShootingStatus(shooting);

  const canBeCompleted = data?.canBeUpdatedTo(ShootingStatus.COMPLETED);

  const handleConfirm = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const shootingToComplete: Shooting = {
        ...shooting,
        status: ShootingStatus.COMPLETED,
      };

      const completedShooting = await updateShooting(shootingToComplete);

      setIsSaving(false);
      modal.close();

      addSuccess(COMPLETE_SHOOTING_SUCCESS_MESSAGE);
      onSuccess?.(completedShooting);
    } catch (error) {
      addError(COMPLETE_SHOOTING_ERROR_MESSAGE);
      setIsSaving(false);
    }
  }, [shooting, modal, addSuccess, onSuccess, addError]);

  if (!canBeCompleted) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          isSaving,
          complete: modal.open,
        })
        : (
          <Action
            label="Conferma caricamento foto"
            aria-label="Pulsante per confermare il caricamento delle foto"
            onClick={() => modal.open()}
            loading={isSaving}
            {...rest}
          />
        )}
      <Portal>
        <ConfirmModal
          isOpen={modal.isOpen}
          title="Conferma caricamento foto"
          aria-label="Conferma caricamento foto"
          onConfirm={handleConfirm}
          onAbort={modal.close}
          loading={isSaving}
        >
          Confermando l&apos;operazione si conferma che tutte le fotografie sono state postprocessate e caricate.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default CompleteShootingAction;
