import React from 'react';
import {
  ActionDropDown, Badge, ConfirmModal, ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE,
  ICON_PROGRESS_CHECK,
  Portal,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import { isFuture } from 'date-fns';
import { BadgeColor } from '@doveit/bricks/dist/typings/components/badge/types';
import { formatDate } from '@doveit/hammer';
import { Reminder } from '../../../providers/api/dtos';
import { updateReminder } from '../../../providers/api/reminder/reminderProvider';

export const RESOLVED_REMINDER_SUCCESS_MESSAGE = 'Il promemoria è stato risolto con successo.';
export const RESOLVE_REMINDER_ERROR_MESSAGE = 'Non è stato possibile risolvere il promemoria.';

export interface UpdateReminderStatusProps {
  reminder: Reminder,
  showAsBadge?: boolean,
  showIcon?: boolean,
  emphasis?: 'low' | 'high',
  onSuccess?: (updatedReminder: Reminder) => void,
}

interface StatusProps {
  label: string,
  icon: string,
  color: BadgeColor,
  showAsBadge: boolean,
}

const UpdateReminderStatus: React.FC<UpdateReminderStatusProps> = ({
  showAsBadge = false,
  reminder,
  emphasis = 'low',
  showIcon = false,
  onSuccess,
}) => {
  const resolveReminderModal = useModal<Reminder>();
  const { addSuccess, addError } = useNotifications();
  const onClick = React.useCallback(() => {
    resolveReminderModal.open(reminder);
  }, [reminder, resolveReminderModal]);

  const resolveReminder = React.useCallback(async () => {
    try {
      const updatedReminder = await updateReminder(reminder.id!, {
        ...reminder,
        resolved: true,
      });

      resolveReminderModal.close();
      addSuccess(RESOLVED_REMINDER_SUCCESS_MESSAGE);

      onSuccess?.(updatedReminder);
    } catch {
      addError(RESOLVE_REMINDER_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, onSuccess, reminder, resolveReminderModal]);

  const statusProps: StatusProps = React.useMemo(() => {
    if (reminder.resolved) {
      return {
        label: 'Risolto',
        icon: ICON_CHECK_CIRCLE_OUTLINE,
        color: 'success',
        showAsBadge: true,
      };
    }
    if (isFuture(new Date(reminder.startDate))) {
      return {
        label: 'Attivo',
        icon: ICON_PROGRESS_CHECK,
        color: 'info',
        showAsBadge,
      };
    }
    return {
      label: 'Scaduto',
      icon: ICON_ALERT_CIRCLE_OUTLINE,
      color: 'neutral',
      showAsBadge,
    };
  }, [reminder.resolved, reminder.startDate, showAsBadge]);
  return (
    <>
      {(statusProps.showAsBadge) && (
        <Badge
          aria-label="Badge per visualizzare lo stato del promemoria"
          label={statusProps.label}
          icon={showIcon ? statusProps.icon : undefined}
          color={statusProps.color}
          emphasis={emphasis}
          size="XS"
        />
      )}

      {!statusProps.showAsBadge && (
        <ActionDropDown
          aria-label="Azione per risolvere il promemoria"
          label={statusProps.label}
          iconLeft={showIcon ? { path: statusProps.icon } : undefined}
          color={statusProps.color}
          size="XS"
          emphasis={emphasis}
          options={[{
            key: 'resolve',
            label: 'Risolvi',
            onClick,
          }]}
        />
      )}
      {resolveReminderModal.data && (
        <Portal>
          <ConfirmModal
            {...resolveReminderModal}
            title="Conferma risoluzione promemoria"
            onConfirm={resolveReminder}
            onAbort={resolveReminderModal.close}
            dismissable={false}
            aria-label="Modale per risolvere il promemoria"
          >
            {`Confermando l'operazione il promemoria previsto per il giorno ${formatDate(new Date(resolveReminderModal.data.startDate), { time: true })} sarà risolto.`}
          </ConfirmModal>
        </Portal>
      )}
    </>
  );
};

export default UpdateReminderStatus;
