import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const basic: ThemedFunctor = ({ theme }) => css`
  background: ${theme.color.background.neutral.default.lowest};
  border-radius: ${theme.remSize[75]};
  overflow: hidden;
`;

export const header: ThemedFunctor = ({ theme }) => css`
  padding: ${theme.remSize[150]};
  background: ${theme.color.background.neutral.default.low};
  display: flex;
  align-items: center;
  gap: ${theme.remSize[200]};
`;

export const avatar: ThemedFunctor = () => css`
  flex-shrink: 0;
`;

export const info: ThemedFunctor = ({ theme }) => css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.remSize[50]};
`;

export const wrapper: ThemedFunctor = ({ theme }) => css`
  display: flex;
  flex-direction: column;
  padding: ${theme.remSize[150]};
  gap: ${theme.remSize[150]};
`;

export const content: ThemedFunctor = ({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.remSize[100]};
`;
