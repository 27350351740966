import React from 'react';
import { Modal, ModalProps } from '@doveit/bricks';
import { ModalFooterProps } from '@doveit/bricks/dist/typings/components/modal/ModalFooter';

export type SimpleModalProps = React.PropsWithChildren<{
  title?: string,
  size?: ModalProps['size'],
  fillAvailableHeight?: ModalProps['fillAvailableHeight'],
  isOpen: boolean,
  close: VoidFunction,
  header?: React.ReactElement,
  footer?: React.ReactElement,
  footerContentAlignment?: ModalFooterProps['contentAlignment'],
  padded?: boolean,
  keepMount?: boolean,
  loading?: boolean,
  bodyRef?: React.MutableRefObject<HTMLDivElement | null>,
}> & React.AriaAttributes;

const SimpleModal: React.FC<SimpleModalProps> = ({
  title,
  size,
  fillAvailableHeight,
  isOpen,
  close,
  children,
  header,
  footer,
  footerContentAlignment,
  padded = true,
  keepMount,
  loading,
  bodyRef,
  'aria-label': ariaLabel,
}) => (
  <Modal
    open={isOpen}
    size={size}
    fillAvailableHeight={fillAvailableHeight}
    onCloseHandler={close}
    loading={loading}
    aria-label={ariaLabel}
  >
    <Modal.Close />
    {(header || title) && (
      <Modal.Header title={title}>
        {header}
      </Modal.Header>
    )}
    <Modal.Body
      padded={padded}
      keepMount={keepMount}
      forwardRef={bodyRef}
    >
      {children}
    </Modal.Body>
    {footer && (
      <Modal.Footer
        padded
        contentAlignment={footerContentAlignment}
      >
        {footer}
      </Modal.Footer>
    )}
  </Modal>
);

export default SimpleModal;
