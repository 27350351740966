import {
  Avatar, AvatarProps, ICON_SHIELD_CHECK_OUTLINE, ICON_TIMER_OUTLINE,
} from '@doveit/bricks';
import React from 'react';
import { AgentPreviewStatus } from '../types';
import { mapAgentPreviewStatusToColor } from '../constants';
import { HYGRAPH_ASSET_BASE_URL } from '../../../providers/api/api';

export type AgentAvatarProps = Omit<AvatarProps, 'color' | 'icon'> & {
  status?: AgentPreviewStatus,
  inTrial?: boolean,
  needsSupervisor?: boolean,
  photoId?: string,
};

export const AgentAvatar: React.FC<AgentAvatarProps> = ({
  status,
  inTrial,
  needsSupervisor,
  src,
  photoId,
  ...rest
}) => {
  const avatarUrl = React.useMemo(() => {
    if (src) {
      return src;
    }

    return photoId
      ? `${HYGRAPH_ASSET_BASE_URL}/${photoId}`
      : undefined;
  }, [photoId, src]);

  const icon = React.useMemo(() => {
    if (inTrial) {
      return ICON_TIMER_OUTLINE;
    }
    return needsSupervisor
      ? ICON_SHIELD_CHECK_OUTLINE
      : undefined;
  }, [inTrial, needsSupervisor]);

  return (
    <Avatar
      {...rest}
      src={avatarUrl}
      icon={icon}
      color={status ? mapAgentPreviewStatusToColor[status] : undefined}
    />
  );
};

export default AgentAvatar;
