/* eslint-disable react/no-unknown-property */
import {
  ActionIcon,
  Card,
  FontWeight,
  HStack,
  ICON_EYE_OUTLINE,
  Stack,
  Text,
} from '@doveit/bricks';
import React from 'react';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';

const generableResources: {
  title: string,
  description: string,
  url: string,
}[] = [{
  title: 'Locandine immobili',
  description: 'Genera la locandina dei tuoi immobili o degli immobili degli agenti che gestisci.',
  url: '/marketing/generate/showcase',
},
{
  title: 'Brochure agenti',
  description: 'Genera la brochure di Agente Dove.it',
  url: '/marketing/generate/agent-brochure',
}];

const GenerateMaterialsPage: React.FC = () => {
  const isDesktop = useIsDevice('desktop');

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content
        subtitle="In questa sezione troverai gli strumenti per generare i materiali promozionali di Dove.it."
      >
        <GenericPageLayout.InnerContent>
          {isDesktop && (
            <SimpleTable>
              <SimpleTable.Body>
                {generableResources.map((resource) => (
                  <SimpleTable.Row key={resource.title}>
                    <SimpleTable.Cell>
                      <Text.Body fontWeight={FontWeight.REGULAR}>
                        {resource.title}
                      </Text.Body>
                    </SimpleTable.Cell>
                    <SimpleTable.Cell>
                      <Text.Body>
                        {resource.description}
                      </Text.Body>
                    </SimpleTable.Cell>
                    <SimpleTable.Cell>
                      <HStack>
                        <ActionIcon
                          aria-label={`Genera ${resource.title}`}
                          label={`Genera ${resource.title}`}
                          size="S"
                          icon={{ path: ICON_EYE_OUTLINE }}
                          href={resource.url}
                        />
                      </HStack>
                    </SimpleTable.Cell>
                  </SimpleTable.Row>
                ))}
              </SimpleTable.Body>
            </SimpleTable>
          )}

          {!isDesktop && (
            <Stack gap={150}>
              {generableResources.map((resource) => (
                <Card key={resource.title}>
                  <Card.Header
                    title={resource.title}
                    primaryActions={[
                      <ActionIcon
                        aria-label={`Genera ${resource.title}`}
                        label={`Genera ${resource.title}`}
                        size="S"
                        icon={{ path: ICON_EYE_OUTLINE }}
                        href={resource.url}
                      />,
                    ]}
                  />
                  <Card.Content>
                    <Text.Body>
                      {resource.description}
                    </Text.Body>
                  </Card.Content>
                </Card>
              ))}
            </Stack>
          )}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default GenerateMaterialsPage;
