import React, { FunctionComponent } from 'react';
import {
  Form,
  FormActionsAlign,
  FormProps,
} from '@doveit/bricks';
import validationSchema from './ProspectFormByLead.schema';
import { ProspectFormByLeadModel } from '../../models/ProspectFormByLeadModel';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';
import { AgentStatus } from '../../../domain/types';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { prospectWillingnessLabels } from '../../../labels';

export interface ProspectFormByLeadProps {
  onSubmit(values: ProspectFormByLeadModel): void,
  initialValues?: Partial<ProspectFormByLeadModel>,
  disabled?: boolean,
  loading?: boolean,
  hideSubmit?: boolean,
  innerRef?: FormProps<ProspectFormByLeadModel>['innerRef'],
}

export const PROPERTY_PLACE_ERROR = 'Attenzione: L\'indirizzo dell\'immobile non è indirizzo google valido';

const DEFAULT_INITIAL_VALUES: ProspectFormByLeadModel = {
  willingness: '3',
  date: '',
  time: '',
  virtual: false,
};

const ProspectFormByLead: FunctionComponent<ProspectFormByLeadProps> = ({
  initialValues,
  onSubmit,
  loading,
  innerRef,
  hideSubmit = false,
}) => {
  const initialValuesMerged: ProspectFormByLeadModel = {
    ...DEFAULT_INITIAL_VALUES,
    ...(initialValues || {}),
  };

  return (
    <Form
      initialValues={initialValuesMerged}
      onSubmit={onSubmit}
      loading={loading}
      validationSchema={validationSchema}
      innerRef={innerRef}
      preventEnterKeypress
    >
      <Form.Item>
        <AgentAutoComplete
          name="agent"
          label="Agente incaricato"
          aria-label="Campo per inserire l'agente incaricato"
          placeholder="Cerca per nome"
          status={AgentStatus.ACTIVE}
        />
      </Form.Item>
      <Form.Item>
        <Form.Select
          name="willingness"
          label="Quanto pensi sia motivato il contatto a vendere l'immobile?"
          options={asSelectOptions(prospectWillingnessLabels, false)}
        />
      </Form.Item>
      <Form.Fieldset legend="Appuntamento">
        <Form.Group>
          <Form.Item size={{ MD: 1 / 2 }}>
            <Form.CheckboxButton
              name="virtual"
              aria-label="Seleziona appuntamento con videochiamata"
              text="Tramite videochiamata"
            />
          </Form.Item>
        </Form.Group>
        <Form.Item size={1 / 2}>
          <Form.Input
            name="date"
            type="date"
            label="Data"
            aria-label="Campo per inserire data appuntamento"
            required
          />
        </Form.Item>
        <Form.Item size={1 / 2}>
          <Form.Input
            name="time"
            type="time"
            label="Ora"
            aria-label="Campo per inserire ora appuntamento"
            required
          />
        </Form.Item>
        <Form.Item>
          <Form.TextArea
            name="notes"
            label="Note per l'appuntamento"
            aria-label="Campo per inserire le note dell'appuntamento"
          />
        </Form.Item>
      </Form.Fieldset>
      {!hideSubmit && (
        <Form.Actions align={FormActionsAlign.RIGHT}>
          <Form.Submit
            label="Crea"
            aria-label="Salva i dati della valutazione"
          />
        </Form.Actions>
      )}
    </Form>
  );
};

export default ProspectFormByLead;
