/* eslint-disable react/no-unknown-property */
import {
  ActionIcon,
  ICON_BELL_OUTLINE, ICON_TEXT_BOX_OUTLINE, Popover, Card, Dropdown, FontWeight, HStack, Icon, ICON_CALENDAR_OUTLINE, ICON_CASH_MULTIPLE, ICON_EYE_OUTLINE, ICON_MAP_MARKER_OUTLINE, Stack, Text, useNotifications, UserPreview,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import React from 'react';
import {
  isFuture, isPast, isSameMinute, isToday,
} from 'date-fns';
import { AgentPreview } from '../../../agent/components';
import UpdateIntentStatus from '../update-intent-status/UpdateIntentStatus';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { IntentStatus, OfferStatus, ReferenceType } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import UpdateOfferStatus from '../../../offer/containers/update-offer-status/UpdateOfferStatus';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import ViewPropertyPopoverPreview from '../../../property/containers/view-property-popover-preview/ViewPropertyPopoverPreview';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import {
  Appointment, Intent, Note, Reminder, SerpIntent,
} from '../../../providers/api/dtos';
import UpdateAppointmentStatus from '../../../reminders/components/update-appointment-status/UpdateAppointmentStatus';
import { goToDetailPage } from '../../../utils/navigate/utils';
import * as styles from './ViewSerpIntentCard.style';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import ViewContactPopoverPreview from '../../../contact/containers/view-contact-popover-preview/ViewContactPopoverPreview';
import { UserRole } from '../../../types';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import UpsertReminderAction from '../../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import UpsertNoteAction from '../../../notes/containers/UpsertNoteAction';
import { updateIntent } from '../../../providers/api/intent/intentProvider';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import { intentMessagesProvider } from '../../../containers/send-whatsapp-action/messages/intentMessagesProvider';
import { buildWhatsappMessageSentNote } from '../../utils/buildWhatsappMessageSentNote';
import useMarkIntentAsSeen from '../../../hooks/use-mark-intent-as-seen/useMarkIntentAsSeen';
import Dot from '../../../components/dot/Dot';

const MAX_NOTE_LENGTH = 100;
export const UPSERT_NOTE_ERROR_MESSAGE = 'Non è stato possibile aggiungere la nota';
export const UPSERT_NOTE_SUCCESS_MESSAGE = 'Nota aggiunta con successo';

export interface ViewSerpIntentCardProps extends React.AriaAttributes {
  serpIntent: SerpIntent,
  onIntentUpdate?: (updatedIntent: Intent) => void,
  onAppointmentUpdate?: (updatedAppointment: Appointment) => void,
  onOfferUpdate?: VoidFunction,
  onReminderCreated?: (reminder: Reminder) => void,
}

const ViewSerpIntentCard: React.FC<ViewSerpIntentCardProps> = ({
  serpIntent,
  onIntentUpdate,
  onAppointmentUpdate,
  onOfferUpdate,
  onReminderCreated,
  ...rest
}) => {
  const {
    intent, property, agent, contact, appointment, offer,
  } = serpIntent;

  const {
    user, mainUserRole, userIsAdmin, userIsCallCenter,
  } = useRBAC();
  const { addSuccess, addError } = useNotifications();

  const { markAsSeen } = useMarkIntentAsSeen(serpIntent.agent?.id);

  const userIsIntentAgentOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);
  const userIsAdminOrOwnerOrManager = React.useMemo(
    () => userIsAdmin || userIsIntentAgentOrManager,
    [userIsAdmin, userIsIntentAgentOrManager],
  );
  const userIsAdminOrCallCenterOrOwnerOrManager = React.useMemo(
    () => userIsCallCenter || userIsAdminOrOwnerOrManager,
    [userIsAdminOrOwnerOrManager, userIsCallCenter],
  );

  const showUpdatedAt = React.useMemo(
    () => !isSameMinute(intent.createdAt!, intent.updatedAt!),
    [intent.createdAt, intent.updatedAt],
  );

  const reminderToCreate: Reminder = React.useMemo(() => ({
    agentId: serpIntent.agent!.id!,
    referenceId: intent.id!,
    referenceType: ReferenceType.INTENT,
    resolved: false,
  } as Reminder), [intent.id, serpIntent.agent]);

  const goToIntentPage = React.useCallback(() => {
    goToDetailPage(intent.id!, ReferenceType.INTENT, true);
  }, [intent.id]);

  const dateToDisplayWithOffer = React.useMemo(() => {
    if (!offer) return undefined;

    if (offer.status === OfferStatus.IN_PROGRESS && offer.validUntil) {
      return {
        label: 'Scad. accettazione',
        value: offer.validUntil,
        color: (isPast(offer.validUntil) && !isToday(offer.validUntil))
          ? 'critical.default.low'
          : 'neutral.default.low',
      } as const;
    }

    if (offer.status === OfferStatus.ACCEPTED && offer.mortgage?.maxAcceptanceDate) {
      return {
        label: 'Scad. sospensiva',
        value: offer.mortgage.maxAcceptanceDate,
        color: (isPast(offer.mortgage.maxAcceptanceDate) && !isToday(offer.mortgage.maxAcceptanceDate))
          ? 'critical.default.low'
          : 'neutral.default.low',
      } as const;
    }

    return {
      label: 'Creata',
      value: offer.createdAt!,
      color: 'neutral.default.low',
    } as const;
  }, [offer]);

  const showReminder = React.useMemo(
    () => intent.status === IntentStatus.IN_PROGRESS && !!serpIntent.reminder,
    [intent.status, serpIntent.reminder],
  );

  const noteToDisplay = React.useMemo(
    () => intent.notes
      ?.filter(({ role }) => role !== UserRole.CUSTOMER)
      .at(0),
    [intent.notes],
  );

  const showNote = React.useMemo(
    () => intent.status === IntentStatus.IN_PROGRESS && !showReminder && !!noteToDisplay,
    [intent.status, noteToDisplay, showReminder],
  );
  const handleReminderCreated = React.useCallback(async (reminder: Reminder) => {
    await markAsSeen(intent, { onSuccess: onIntentUpdate });

    onReminderCreated?.(reminder);
  }, [intent, markAsSeen, onIntentUpdate, onReminderCreated]);

  const handleNoteCreated = React.useCallback(async (note: Note) => {
    if (!intent) return;

    try {
      let updatedIntent;

      updatedIntent = await updateIntent(intent.id!, {
        ...intent,
        notes: [note, ...(intent.notes || [])],
      });

      updatedIntent = await markAsSeen(updatedIntent);
      onIntentUpdate?.(updatedIntent);
      addSuccess(UPSERT_NOTE_SUCCESS_MESSAGE);
    } catch (err) {
      addError(UPSERT_NOTE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, intent, markAsSeen, onIntentUpdate]);

  const onMessageSent = React.useCallback(async (message: string) => {
    if (!intent) return;

    let updatedIntent;

    updatedIntent = await updateIntent(intent.id!, {
      ...intent,
      notes: [{
        text: buildWhatsappMessageSentNote(message),
        author: user.name,
        role: mainUserRole,
      }, ...(intent.notes || [])],
    });

    updatedIntent = await markAsSeen(updatedIntent);

    onIntentUpdate?.(updatedIntent);
  }, [intent, mainUserRole, markAsSeen, onIntentUpdate, user.name]);

  return (
    <Card {...rest}>
      <Card.Header
        primaryActions={[
          !intent.seen && (
            <Dot size="S" aria-label="Interesse segnato come non visto" />
          ),
          userIsAdminOrCallCenterOrOwnerOrManager && (
            <ActionIcon
              aria-label="Visualizza interesse"
              label="Visualizza"
              size="S"
              icon={{ path: ICON_EYE_OUTLINE }}
              onClick={goToIntentPage}
            />
          )]}
        secondaryActions={[
          userIsAdminOrOwnerOrManager && contact.phoneNumber && (
            <SendWhatsappAction
              phoneNumber={contact.phoneNumber}
              name={contact.name}
              fetchMessages={intentMessagesProvider(intent, agent)}
              onMessageSent={onMessageSent}
            >
              {({ onClick }) => (
                <Dropdown.Option
                  label="Invia whatsapp"
                  onClick={onClick}
                />
              )}
            </SendWhatsappAction>
          ),
          userIsAdminOrOwnerOrManager && (
            <UpsertReminderAction
              reminder={reminderToCreate}
              onSuccess={handleReminderCreated}
              aria-label="Pulsante per aggiungere un promemoria"
            >
              {({ upsert }) => (
                <Dropdown.Option
                  label="Aggiungi promemoria"
                  onClick={upsert}
                />
              )}
            </UpsertReminderAction>
          ),
          <UpsertNoteAction
            upsertNote={handleNoteCreated}
            aria-label="Pulsante per aggiungere una nota"
          >
            {({ upsert }) => (
              <Dropdown.Option
                label="Aggiungi nota"
                onClick={upsert}
              />
            )}
          </UpsertNoteAction>,
        ]}
      >
        <ViewPropertyPopoverPreview
          property={property}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <PropertyAvatar
                size="S"
                icon={property.overriddenGeo ? ICON_MAP_MARKER_OUTLINE : undefined}
              />
              <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                {property.overriddenGeo?.normalizedAddress || property.geo?.normalizedAddress}
              </Text.BodySmall>
            </HStack>
          )}
          showMatchingAction={userIsAdminOrOwnerOrManager}
        />
      </Card.Header>

      <Card.Content aria-label="Informazioni interesse">
        <Stack gap={150}>
          <div>
            <UpdateIntentStatus
              intent={intent}
              size="XS"
              showAsBadge={!userIsAdminOrCallCenterOrOwnerOrManager}
              onSuccess={onIntentUpdate}
            />
          </div>

          <HStack gap={150}>
            <HStack
              gap={100}
              wrap="nowrap"
              css={styles.firstRowWrapper}
            >
              <HStack gap={150} wrap="nowrap">
                <ViewContactPopoverPreview
                  contact={contact}
                  trigger={(
                    <div css={styles.userPreview}>
                      <UserPreview
                        name={contact.name || NO_VALUE_SYMBOL}
                        size="S"
                      >
                        {contact.phoneNumber}
                      </UserPreview>
                    </div>
                  )}
                />

                <Stack gap={50}>
                  <Text.Mini transform="uppercase">
                    {showUpdatedAt ? 'Aggiornato' : 'Aggiunto'}
                  </Text.Mini>
                  <Text.BodySmall fontWeight={FontWeight.REGULAR}>
                    <DateViewer
                      stringDate={showUpdatedAt ? intent.updatedAt! : intent.createdAt!}
                      checkIfToday
                      checkIfTomorrow
                    />
                  </Text.BodySmall>
                </Stack>
              </HStack>

              {agent && (
                <ViewAgentPopoverPreview
                  agent={agent}
                  trigger={(
                    <AgentPreview
                      {...toAgentPreviewProps(agent, { omit: { name: true } })}
                      size="S"
                    />
                  )}
                />
              )}
            </HStack>

            {(!appointment && !offer && showReminder && (
              <HStack
                gap={150}
                css={styles.secondRowWrapper}
                aria-label="Dettagli promemoria"
              >
                <HStack wrap="nowrap">
                  <Icon path={ICON_BELL_OUTLINE} size={20} />
                  <div css={styles.dateWrapper}>
                    <Stack gap={25}>
                      <Text.Mini transform="uppercase">
                        Data promemoria
                      </Text.Mini>
                      <Text.Caption fontWeight={FontWeight.REGULAR}>
                        <DateViewer
                          stringDate={serpIntent.reminder!.startDate}
                          checkIfToday
                          checkIfTomorrow
                          withHour={isFuture(serpIntent.reminder!.startDate)}
                        />
                      </Text.Caption>
                    </Stack>
                  </div>
                </HStack>
                <Popover
                  aria-label="Visualizza dettagli promemoria"
                  trigger={(
                    <Text.Caption>
                      {truncateTextWithSuffix(serpIntent.reminder!.notes, MAX_NOTE_LENGTH)}
                    </Text.Caption>
                  )}
                  hasArrow
                >
                  <div css={styles.noteAndReminderPopoverWrapper}>
                    <Text.BodySmall>{serpIntent.reminder!.notes}</Text.BodySmall>
                  </div>
                </Popover>
              </HStack>
            ))}

            {(!appointment && !offer && showNote && (
              <Popover
                aria-label="Visualizza dettagli note"
                css={styles.secondRowWrapper}
                trigger={(
                  <HStack wrap="nowrap">
                    <div>
                      <Icon
                        path={ICON_TEXT_BOX_OUTLINE}
                        size={20}
                      />
                    </div>
                    <Text.Caption>
                      {truncateTextWithSuffix(noteToDisplay!.text, MAX_NOTE_LENGTH)}
                    </Text.Caption>
                  </HStack>
                )}
                hasArrow
              >
                <div css={styles.noteAndReminderPopoverWrapper}>
                  <Text.Body fontWeight={FontWeight.REGULAR}>{noteToDisplay?.author}</Text.Body>
                  <Text.BodySmall>{noteToDisplay!.text}</Text.BodySmall>
                </div>
              </Popover>
            ))}

            {(appointment || offer) && (
              <HStack gap={150} css={styles.secondRowWrapper}>
                {!offer && appointment && (
                  <HStack>
                    <Icon
                      path={ICON_CALENDAR_OUTLINE}
                      size={20}
                    />
                    <div css={styles.dateWrapper}>
                      <Stack gap={25}>
                        <Text.Mini transform="uppercase">
                          Data appuntamento
                        </Text.Mini>
                        <Text.Caption fontWeight={FontWeight.REGULAR}>
                          <DateViewer
                            stringDate={appointment.startDate}
                            checkIfToday
                            checkIfTomorrow
                            withHour={isFuture(appointment.startDate)}
                          />
                        </Text.Caption>
                      </Stack>
                    </div>
                  </HStack>
                )}

                {!offer && appointment && (
                  <UpdateAppointmentStatus
                    appointment={appointment}
                    onSuccess={onAppointmentUpdate}
                    showAsBadge={!userIsAdminOrCallCenterOrOwnerOrManager}
                  />
                )}

                {offer && dateToDisplayWithOffer && (
                  <HStack gap={100}>
                    <Icon path={ICON_CASH_MULTIPLE} size={20} />
                    <div css={styles.dateWrapper}>
                      <Stack gap={25} aria-label="Data">
                        <Text.Mini transform="uppercase">
                          {dateToDisplayWithOffer.label}
                        </Text.Mini>
                        <Text.Caption
                          fontWeight={FontWeight.REGULAR}
                          color={dateToDisplayWithOffer.color}
                        >
                          <DateViewer
                            stringDate={dateToDisplayWithOffer.value}
                            checkIfToday
                            checkIfTomorrow
                          />
                        </Text.Caption>
                      </Stack>
                    </div>
                  </HStack>
                )}

                {offer && (
                  <>
                    <UpdateOfferStatus
                      lastOffer={offer}
                      intentId={offer.intentId}
                      size="XS"
                      onSuccess={onOfferUpdate}
                      showAsBadge={!userIsAdminOrCallCenterOrOwnerOrManager}
                    />
                    <Stack
                      gap={25}
                      aria-label="Valore della proposta"
                    >
                      <Text.Mini transform="uppercase">
                        Proposta
                      </Text.Mini>
                      <Text.Caption
                        fontWeight={FontWeight.REGULAR}
                      >
                        {formatEuro(offer.value)}
                      </Text.Caption>
                    </Stack>
                  </>
                )}
              </HStack>
            )}
          </HStack>
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ViewSerpIntentCard;
