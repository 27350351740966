import useSWR, { BareFetcher, Key } from 'swr';
import hash from 'object-hash';
import { endOfYesterday } from 'date-fns';
import { IntentSection } from '../../types';
import { Intent } from '../../../providers/api/dtos/intent';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import {
  getIntentsWithOffer, getNewIntents, getRecallIntents, getScheduledIntents, IntentsProviderFilters, IntentsWithOfferProviderFilters, ScheduledIntentsProviderFilters,
} from '../../../providers/api/intent/intentProvider';
import { AppointmentStatus } from '../../../domain/types';
import { OfferStatus } from '../../../domain/types/offerStatus';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';
import { formatInputDate } from '../../../utils/form';

type AdditionalIntentsProviderFilters = ScheduledIntentsProviderFilters | IntentsWithOfferProviderFilters;
interface IntentsToProcessSWRParams {
  key: Key,
  fetcher: BareFetcher<PaginatedResult<Intent>>,
}

export const INTENTS_NEW_RID = '/api/v2/intents/new';
export const INTENTS_RECALL_RID = '/api/v2/intents/recall';
export const INTENTS_SCHEDULED_RID = '/api/v2/intents/scheduled';
export const INTENTS_WITH_OFFERS_RID = '/api/v2/intents/offers';

export const INTENTS_SECTION_TO_RID: Record<IntentSection, string> = {
  [IntentSection.TODO]: INTENTS_NEW_RID,
  [IntentSection.RECALL]: INTENTS_RECALL_RID,
  [IntentSection.SCHEDULED_TODO]: INTENTS_SCHEDULED_RID,
  [IntentSection.SCHEDULED_EXPIRED]: INTENTS_SCHEDULED_RID,
  [IntentSection.SCHEDULED_PENDING]: INTENTS_SCHEDULED_RID,
  [IntentSection.OFFERS_IN_PROGRESS]: INTENTS_WITH_OFFERS_RID,
  [IntentSection.OFFERS_ACCEPTED]: INTENTS_WITH_OFFERS_RID,
  [IntentSection.OFFERS_REFUSED]: INTENTS_WITH_OFFERS_RID,
  [IntentSection.OFFERS_WITHDRAWN]: INTENTS_WITH_OFFERS_RID,
};

export const INTENTS_SECTION_TO_FETCHER_FUNCTION: Record<IntentSection, BareFetcher<PaginatedResult<Intent>>> = {
  [IntentSection.TODO]: getNewIntents,
  [IntentSection.RECALL]: getRecallIntents,
  [IntentSection.SCHEDULED_TODO]: getScheduledIntents,
  [IntentSection.SCHEDULED_EXPIRED]: getScheduledIntents,
  [IntentSection.SCHEDULED_PENDING]: getScheduledIntents,
  [IntentSection.OFFERS_IN_PROGRESS]: getIntentsWithOffer,
  [IntentSection.OFFERS_ACCEPTED]: getIntentsWithOffer,
  [IntentSection.OFFERS_REFUSED]: getIntentsWithOffer,
  [IntentSection.OFFERS_WITHDRAWN]: getIntentsWithOffer,
};

const additionalFilters: {
  [K in IntentSection]?: () => AdditionalIntentsProviderFilters
} = {
  [IntentSection.SCHEDULED_TODO]: () => ({
    appointmentStatus: [AppointmentStatus.TODO],
    appointmentStartDate: formatInputDate(new Date()),
  }),
  [IntentSection.SCHEDULED_EXPIRED]: () => ({
    appointmentStatus: [AppointmentStatus.TODO],
    appointmentEndDate: formatInputDate(endOfYesterday()),
  }),
  [IntentSection.SCHEDULED_PENDING]: () => ({
    appointmentStatus: [AppointmentStatus.DONE, AppointmentStatus.CANCELLED],
    excludeAppointmentStatus: AppointmentStatus.TODO,
  }),
  [IntentSection.OFFERS_IN_PROGRESS]: () => ({
    offerStatus: OfferStatus.IN_PROGRESS,
  }),
  [IntentSection.OFFERS_ACCEPTED]: () => ({
    offerStatus: OfferStatus.ACCEPTED,
    excludeOfferStatus: OfferStatus.IN_PROGRESS,
  }),
  [IntentSection.OFFERS_REFUSED]: () => ({
    offerStatus: OfferStatus.REFUSED,
    excludeOfferStatus: [OfferStatus.IN_PROGRESS, OfferStatus.ACCEPTED],
  }),
  [IntentSection.OFFERS_WITHDRAWN]: () => ({
    offerStatus: OfferStatus.WITHDRAWN,
    excludeOfferStatus: [OfferStatus.IN_PROGRESS, OfferStatus.ACCEPTED],
  }),
};

export function getIntentsToProcessSWRParams(
  section: IntentSection,
  filters: IntentsProviderFilters = {},
  pagination: PaginationParams = {},
): IntentsToProcessSWRParams {
  const addFilters = additionalFilters[section]
    ? additionalFilters[section]!()
    : {};

  const extendedFilters = {
    ...filters,
    ...addFilters,
  };

  const key: Key = [INTENTS_SECTION_TO_RID[section], hash({ ...extendedFilters, ...pagination })];
  const fetcher: BareFetcher<PaginatedResult<Intent>> = () => INTENTS_SECTION_TO_FETCHER_FUNCTION[section](extendedFilters, pagination);

  return {
    key,
    fetcher,
  };
}

export default function useIntentsToProcess(
  section: IntentSection,
  filters?: IntentsProviderFilters,
  pagination?: PaginationParams,
): SWRCustomResponse<PaginatedResult<Intent>, Error> {
  const matchMutate = useMatchMutate();

  const { key, fetcher } = getIntentsToProcessSWRParams(section, filters, pagination);

  const swrResponse = useSWR(key, fetcher);

  return {
    ...swrResponse,
    mutate: () => matchMutate(INTENTS_SECTION_TO_RID[section]),
  };
}
