import { ProspectStatus } from '../domain/types/prospectStatus';

export const prospectStatusLabels: { [key in ProspectStatus]: string } = {
  IN_PROGRESS: 'In lavorazione',
  ONLY_EVALUATION: 'Solo valutazione',
  OVER_PRICED: 'Sovrapprezzo',
  CANCELLED: 'Annullato',
  UNSELLABLE: 'Invendibile',
  NOT_INTERESTED: 'Non interessato',
  ASSIGNMENT_CREATED: 'Mandato',
  SOLD: 'Venduto da altri',
};
