export enum ApplicationNotificationType {
  // Documents
  PROPERTY_DOCUMENT_REVIEW = 'PROPERTY_DOCUMENT_REVIEW',
  PROPERTY_DOCUMENT_APPROVED = 'PROPERTY_DOCUMENT_APPROVED',
  PROPERTY_DOCUMENT_REJECTED = 'PROPERTY_DOCUMENT_REJECTED',
  OFFER_DOCUMENT_REVIEW = 'OFFER_DOCUMENT_REVIEW',
  OFFER_DOCUMENT_APPROVED = 'OFFER_DOCUMENT_APPROVED',
  OFFER_DOCUMENT_REJECTED = 'OFFER_DOCUMENT_REJECTED',
  // Intents
  INTENT_NUDGE = 'INTENT_NUDGE',
  // Job applications
  JOB_APPLICATION_ASSIGNED = 'JOB_APPLICATION_ASSIGNED',
  // Offers
  OFFER_DEED_TO_CONFIRM = 'OFFER_DEED_TO_CONFIRM',
  // Reviews
  REVIEW_SUBMITTED = 'REVIEW_SUBMITTED',
  // Prospects
  PROSPECT_APPROVAL_WAITING_FOR_SUPERVISOR = 'PROSPECT_APPROVAL_WAITING_FOR_SUPERVISOR',
  PROSPECT_APPROVAL_WAITING_FOR_AGENT = 'PROSPECT_APPROVAL_WAITING_FOR_AGENT',
  PROSPECT_APPROVAL_APPROVED = 'PROSPECT_APPROVAL_APPROVED',
  PROSPECT_NUDGE = 'PROSPECT_NUDGE',
  // Invoices
  INVOICE_FORECASTED_DATE_EXPIRING = 'INVOICE_FORECASTED_DATE_EXPIRING',
  // Property
  PROPERTY_WITHDRAWN = 'PROPERTY_WITHDRAWN',
  PROPERTY_PUBLISHED = 'PROPERTY_PUBLISHED',
  PROPERTY_PREVIEW = 'PROPERTY_PREVIEW',
  // Assignment
  ASSIGNMENT_DIGITALLY_SIGNED = 'ASSIGNMENT_DIGITALLY_SIGNED',
}
