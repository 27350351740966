import { Form, useNotifications } from '@doveit/bricks';
import React, { useCallback, FunctionComponent, useState } from 'react';
import { copyToClipboard } from '../../../utils/text/text';

export interface CopyLinkProps {
  url: string,
  label: string,
  recommended?: boolean,
}

export const COPY_LINK_SUCCESS_MESSAGE = 'Link copiato negli appunti!';
export const COPY_LINK_ERROR_MESSAGE = 'Non è stato possibile copiare il link negli appunti. Prova con il classico copia e incolla.';

const CopyLink: FunctionComponent<CopyLinkProps> = ({
  url,
  label,
  recommended,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const onSubmit = useCallback(async (values: { url: string }) => {
    setIsSaving(true);

    try {
      await copyToClipboard(values.url);

      setIsSaving(false);
      addSuccess(COPY_LINK_SUCCESS_MESSAGE);
    } catch (error) {
      setIsSaving(false);
      addError(COPY_LINK_ERROR_MESSAGE);
    }
  }, [addError, addSuccess]);

  return (
    <Form
      initialValues={{ url }}
      onSubmit={onSubmit}
      loading={isSaving}
    >
      <Form.Group label={`${label}${recommended ? ' (consigliato)' : ''}`}>
        <Form.Item size={{ MD: 4 / 6, LG: 5 / 6 }}>
          <Form.Input
            name="url"
            value={url}
            aria-label="Campo con l'url da copiare"
            disabled
          />
        </Form.Item>
        <Form.Item size={{ MD: 2 / 6, LG: 1 / 6 }}>
          <Form.Submit expanded label="Copia link" />
        </Form.Item>
      </Form.Group>
    </Form>
  );
};

export default CopyLink;
