import { Message, Spacing, Stack } from '@doveit/bricks';
import React from 'react';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { slugIt } from '../../../utils/string';
import BuildWebsiteTrackedLink from '../../containers/build-website-tracked-link/BuildWebsiteTrackedLink';
import CopyLink from '../../containers/copy-link/CopyLink';
import buildTrackedLink from '../../utils/build-tracked-link/buildTrackedLink';

export const AGENT_PERSONAL_PAGE_INFO_MESSAGE = 'Prima di utilizzare l\'url controlla l\'esistenza della pagina. Se la pagina non è raggiungibile probabilmente devi ancora fornire foto e biografia al tuo responsabile.';

const CreateTrackedLinksPage: React.FC = () => {
  const { user } = useRBAC();

  return user! && (
    <GenericPageLayout>
      <GenericPageLayout.Content
        subtitle="Crea un link tracciato personalizzato e utilizzalo sui tuoi account social lavorativi in modo che possano esserti riconosciute le lead personali provenienti dalle tue campagne. Ricordati di controllare che il link sia funzionante."
      >
        <GenericPageLayout.InnerContent>
          <Stack>
            <div>
              <CopyLink
                data-ref="personal-page-link"
                label="Pagina personale"
                url={`https://www.dove.it/agenti-immobiliari/${slugIt(user.name)}`}
              />
              <Spacing margin={[100, 0, 0]}>
                <Message
                  type="info"
                  message={AGENT_PERSONAL_PAGE_INFO_MESSAGE}
                />
              </Spacing>
            </div>
            <CopyLink
              data-ref="selling-property-link"
              label="Landing page vendita casa"
              url={buildTrackedLink('https://www.dove.it/l/vendita-casa', slugIt(user.name))}
              recommended
            />
            <CopyLink
              data-ref="property-evaluation-link"
              label="Valutazione immobile"
              url={buildTrackedLink('https://www.dove.it/valutazione-immobile', slugIt(user.name))}
            />
          </Stack>
        </GenericPageLayout.InnerContent>
        <GenericPageLayout.InnerContent>
          <BuildWebsiteTrackedLink userName={user.name} />
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default CreateTrackedLinksPage;
