import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const wrapper: ThemedFunctor = ({ theme }) => css`
  min-width: 18rem;
  padding: ${theme.remSize[25]};
`;

export const address: ThemedFunctor = ({ theme }) => css`
  display: flex;
  gap: ${theme.remSize[75]};

  > *:first-child {
    flex-shrink: 0;
  }

  > *:last-child {
    flex-grow: 1;
  }
`;

export const features: ThemedFunctor = () => css`
  display: flex;
`;

export const feature: ThemedFunctor = ({ theme }) => css`
  padding: 0 ${theme.remSize[100]};
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.color.border.neutral.default.low};
  gap: ${theme.remSize[25]};

  &:last-child {
    border-right: 0;
  }
`;
