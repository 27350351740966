import React from 'react';
import {
  Action, FormContextValue, HStack, ModalSize, Portal, useModal,
} from '@doveit/bricks';
import { Lead, Prospect } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import CreateLead from '../create-lead/CreateLead';
import CreateProspectByLead from '../../../prospect/containers/create-prospect-by-lead/CreateProspectByLead';

type ChildFnProps = {
  createLead: VoidFunction,
};

export interface CreateLeadActionProps {
  contactId: Lead['contactId'],
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (lead: Lead, prospect?: Prospect) => void,
}

const CreateLeadAction: React.FC<CreateLeadActionProps> = ({
  contactId,
  children,
  onSuccess,
}) => {
  const modal = useModal();
  const [createdLead, setCreatedLead] = React.useState<Lead>();
  const leadFormRef = React.useRef<FormContextValue>(null) as React.MutableRefObject<FormContextValue>;
  const prospectFormRef = React.useRef<FormContextValue>(null) as React.MutableRefObject<FormContextValue>;

  const openModal = React.useCallback(() => modal.open(), [modal]);

  const resetFlow = React.useCallback(() => {
    modal.close();

    setCreatedLead(undefined);
  }, [modal]);

  const handleAppointmentCreationSkip = React.useCallback(() => {
    if (!createdLead) return;

    resetFlow();

    onSuccess?.(createdLead);
  }, [createdLead, onSuccess, resetFlow]);

  const handleProspectCreate = React.useCallback((prospect: Prospect) => {
    if (!createdLead) return;

    resetFlow();
    onSuccess?.(createdLead, prospect);
  }, [createdLead, onSuccess, resetFlow]);

  const handleLeadCreate = React.useCallback((lead: Lead) => {
    setCreatedLead(lead);
  }, []);

  const submitActiveForm = React.useCallback(() => {
    if (createdLead) {
      prospectFormRef?.current?.handleSubmit();
    } else {
      leadFormRef?.current?.handleSubmit();
    }
  }, [createdLead]);

  return (
    <>
      {children
        ? children({ createLead: openModal })
        : (
          <Action
            label="Aggiungi"
            aria-label="Aggiungi nuova lead, con eventuale appuntamento di valutazione"
            size="S"
            onClick={openModal}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          aria-label={createdLead ? 'Fissa appuntamento di valutazione' : 'Aggiungi nuova lead'}
          title={createdLead ? 'Vuoi fissare un appuntamento?' : 'Aggiungi nuova lead'}
          size={ModalSize.LARGE}
          close={resetFlow}
          footer={(
            <HStack>
              {createdLead && (
                <Action
                  label="Fissa successivamente"
                  aria-label="Fissa l'appuntamento in un secondo momento"
                  onClick={handleAppointmentCreationSkip}
                />
              )}

              <Action
                label={createdLead ? 'Fissa appuntamento' : 'Aggiungi lead'}
                color="primary"
                emphasis="high"
                aria-label={createdLead ? 'Pulsante per fissare un appuntamento' : 'Pulsante per aggiungere una nuova lead'}
                onClick={submitActiveForm}
              />
            </HStack>
          )}
        >
          {createdLead ? (
            <CreateProspectByLead
              lead={createdLead}
              onSuccess={handleProspectCreate}
              hideFormSubmit
              formRef={prospectFormRef}
            />
          ) : (
            <CreateLead
              contactId={contactId}
              onSuccess={(_, lead) => handleLeadCreate(lead)}
              hideFormSubmit
              formRef={leadFormRef}
            />
          )}
        </SimpleModal>
      </Portal>
    </>
  );
};

export default CreateLeadAction;
