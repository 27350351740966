import { Avatar, AvatarProps, ICON_ACCOUNT_OUTLINE } from '@doveit/bricks';
import React from 'react';

export type ContactAvatarProps = Pick<AvatarProps, 'size' | 'placeholder'>;

export const ContactAvatar: React.FC<ContactAvatarProps> = ({ size }) => (
  <Avatar
    placeholder={ICON_ACCOUNT_OUTLINE}
    size={size}
  />
);

export default ContactAvatar;
