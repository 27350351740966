/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Message } from '@doveit/bricks';
import useContactIntents from '../../../../hooks/use-contact-intents/useContactIntents';
import type { ViewContactActivitiesSummaryProps } from '../../ViewContactActivitiesSummary';
import { CONTACT_ACTIVITIES_SUMMARY_INTENTS_FILTERS, CONTACT_INTENTS_LOAD_ERROR, INTENT_STATUS_SORT_ORDER } from '../constants';
import ContactActivityCardSkeleton from '../../../../components/contact-activity-card/ContactActivityCard.skeleton';
import Divider from '../../../../../components/divider/Divider';
import * as styles from '../../ViewContactActivitiesSummary.style';
import { orderByStatus } from '../../utils';
import ContactIntentSummaryCard from '../contact-intent-summary-card/ContactIntentSummaryCard';

const ContactIntentsSummaryGroup: React.FC<ViewContactActivitiesSummaryProps> = ({
  contactId,
}) => {
  const { data: intents, error: intentsError, mutate: mutateIntents } = useContactIntents(contactId, CONTACT_ACTIVITIES_SUMMARY_INTENTS_FILTERS);

  const onIntentUpdate = React.useCallback(async () => {
    await mutateIntents();
  }, [mutateIntents]);

  if (intentsError) {
    return (
      <Message
        type="critical"
        message={CONTACT_INTENTS_LOAD_ERROR}
      />
    );
  }

  if (!intents) {
    return <ContactActivityCardSkeleton />;
  }

  if (intents.length === 0) {
    return null;
  }

  return (
    <div aria-label="Lista degli interessi del contatto">
      <Divider>
        Interessi
      </Divider>

      <div css={styles.activityGroupWrapper}>
        {orderByStatus(intents, INTENT_STATUS_SORT_ORDER).map((intent) => (
          <ContactIntentSummaryCard
            key={intent.id}
            intent={intent}
            onIntentUpdate={onIntentUpdate}
          />
        ))}
      </div>
    </div>
  );
};

export default ContactIntentsSummaryGroup;
