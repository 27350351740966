/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import * as Yup from 'yup';
import { useLocation, useParams } from 'react-router-dom';
import {
  StepNavigation,
  StepNavigationVariant,
  useCarousel,
  useNotifications,
  Link,
  Spacing,
  Typography,
  Message,
  Action,
  ICON_ARROW_UP,
  ActionIcon,
  HStack,
} from '@doveit/bricks';
import objectHash from 'object-hash';
import { inRange } from 'lodash';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import useExtendedNavigate from '../../../hooks/use-extended-navigate/useExtendedNavigate';
import { GenericPageLayoutWrapperWidth } from '../../../layouts/generic-page-layout/wrapper/GenericPageLayoutWrapper';
import * as styles from './ManageOfferPage.style';
import { OfferWizardData } from '../../../providers/api/dtos';
import Divider from '../../../components/divider/Divider';
import useIntent from '../../../intent/hooks/use-intent/useIntent';
import useOfferWizardData from '../../hooks/use-offer-wizard-data/useOfferWizardData';
import {
  AT_LEAST_ONE_OFFER_WIZARD_VALIDATION_ERROR_MESSAGE,
  LOAD_OFFER_WIZARD_DATA_ERROR_MESSAGE, OFFER_CREATION_FAILING_PRECONDITIONS_ERROR_MESSAGE, OFFER_DIGITAL_CREATE_WIZARD_NAVIGATION, OFFER_DIGITAL_CREATE_WIZARD_STEPS, OFFER_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE, OFFER_DIGITAL_WIZARD_VALIDATION_SCHEMAS, OFFER_MANUAL_CREATE_WIZARD_NAVIGATION, OFFER_MANUAL_CREATE_WIZARD_STEPS, OFFER_MANUAL_WIZARD_VALIDATION_SCHEMAS, SAVE_OFFER_WIZARD_DATA_ERROR_MESSAGE,
  SIGNATURE_ALREADY_REQUESTED_ERROR_MESSAGE,
} from './constants';
import { createOfferFromManualWizard, saveOfferWizardData } from '../../../providers/api/offer/offerProvider';
import { hasPendingOffers } from '../../utils';
import { IntentStatus, OFFER_KO_STATUSES } from '../../../domain/types';
import useOffersByIntentId from '../../hooks/use-offers-by-intent-id/useOffersByIntentId';
import { raise } from '../../../utils';
import PreviewOfferWizardPDFAction from '../../containers/preview-offer-wizard-pdf-action/PreviewOfferWizardPDFAction';
import { useCurrentAgent, useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import useMarkIntentAsSeen from '../../../hooks/use-mark-intent-as-seen/useMarkIntentAsSeen';

interface StepValidationStatus {
  hasError: boolean,
  additionalInfo: Record<string, string>,
}

const ManageOfferPage: React.FC = () => {
  const navigate = useExtendedNavigate();
  const location = useLocation();
  const { addError } = useNotifications();
  const { id, mode } = useParams();
  const intentId = id ?? raise('No intent id provided');
  const { data: intent, isLoading: isIntentLoading, error: intentError } = useIntent(intentId);
  const { data: offers = [], isLoading: areOffersLoading, error: offersError } = useOffersByIntentId(intentId);
  const currentAgent = useCurrentAgent();
  const { data: agent } = useAgentByPropertyId(intent?.propertyId);
  const { forceAsSeen } = useMarkIntentAsSeen(agent?.id);

  const hasDigitalOfferEnabled = React.useMemo(
    () => !currentAgent?.franchising || (currentAgent?.agencies?.some((agency) => agency.configuration?.digitalOffer?.enabled === true) ?? false),
    [currentAgent?.agencies, currentAgent?.franchising],
  );

  const isDigitalCreation = React.useMemo(() => mode === 'digital', [mode]);

  const {
    data: wizardData = { intentId },
    isLoading: isWizardDataLoading,
    error: wizardDataError,
    mutate: mutateWizardData,
  } = useOfferWizardData(intentId);

  const [isSaving, setIsSaving] = React.useState(false);
  const formRef = React.useRef<any>() as React.MutableRefObject<any>; // @TODO type
  const [triedToGenerateOffer, setTriedToGenerateOffer] = React.useState(false);

  const { activePosition = 0, jump } = useCarousel({ totalElements: OFFER_DIGITAL_CREATE_WIZARD_NAVIGATION.length });
  const [shouldSubmitAndGoToStep, setShouldSubmitAndGoToStep] = React.useState<[boolean, number | 'heaven']>([false, activePosition]);

  const [OFFER_WIZARD_NAVIGATION, OFFER_WIZARD_STEPS] = React.useMemo(() => (isDigitalCreation
    ? [OFFER_DIGITAL_CREATE_WIZARD_NAVIGATION, OFFER_DIGITAL_CREATE_WIZARD_STEPS]
    : [OFFER_MANUAL_CREATE_WIZARD_NAVIGATION, OFFER_MANUAL_CREATE_WIZARD_STEPS]
  ), [isDigitalCreation]);

  React.useEffect(() => {
    const [shouldSubmit] = shouldSubmitAndGoToStep;

    if (shouldSubmit) {
      const formEvent = {} as unknown as React.FormEvent<HTMLFormElement>;
      formRef?.current?.handleSubmit(formEvent);
    }
  }, [shouldSubmitAndGoToStep]);

  React.useEffect(() => {
    const hashStep = Number(location.hash.substring(1));

    if (!Number.isNaN(hashStep) && inRange(hashStep, 1, Object.values(OFFER_WIZARD_STEPS).length + 1)) {
      jump?.(hashStep - 1);
    } else {
      jump?.(0);
    }
  }, [OFFER_WIZARD_STEPS, jump, location.hash]);

  const [stepsValidationStatus, setStepsValidationStatus] = React.useState({
    digital: Object.keys(OFFER_DIGITAL_CREATE_WIZARD_STEPS).reduce<Record<number, StepValidationStatus>>((acc, step) => ({
      ...acc,
      [step]: { hasError: false },
    }), {}),
    manual: Object.keys(OFFER_MANUAL_CREATE_WIZARD_STEPS).reduce<Record<number, StepValidationStatus>>((acc, step) => ({
      ...acc,
      [step]: { hasError: false },
    }), {}),
  });

  const hasAtLeastOneError = React.useMemo(
    () => Object.values(stepsValidationStatus[mode as keyof typeof stepsValidationStatus]).some(({ hasError }) => hasError),
    [mode, stepsValidationStatus],
  );

  const goBackToIntentPage = React.useCallback(
    () => {
      navigate(`/intents/${intentId}`);
    },
    [navigate, intentId],
  );

  const goToFlow = React.useCallback(
    () => navigate(`/intents/${intentId}/offer/${isDigitalCreation ? 'manual' : 'digital'}`),
    [intentId, isDigitalCreation, navigate],
  );

  const validateData = React.useCallback(async (data: OfferWizardData): Promise<boolean> => {
    const errors: Yup.ValidationError[] = [];

    await Promise.all(Object.entries(
      isDigitalCreation
        ? OFFER_DIGITAL_WIZARD_VALIDATION_SCHEMAS
        : OFFER_MANUAL_WIZARD_VALIDATION_SCHEMAS,
    ).map(async ([step, schema]) => {
      try {
        await schema.validate(data, { abortEarly: false });

        setStepsValidationStatus((prev) => ({
          ...prev,
          [mode as keyof typeof stepsValidationStatus]: {
            ...prev[mode as keyof typeof stepsValidationStatus],
            [step]: {
              hasError: false,
            },
          },
        }));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          errors.push(err);

          const additionalInfo = err.inner.reduce<Record<string, string>>((acc, { path, message }) => {
            if (path) {
              return {
                ...acc,
                [path]: message,
              };
            }

            return acc;
          }, {});

          setStepsValidationStatus((prev) => ({
            ...prev,
            [mode as keyof typeof stepsValidationStatus]: {
              ...prev[mode as keyof typeof stepsValidationStatus],
              [step]: {
                hasError: true,
                additionalInfo,
              },
            },
          }));
        }
      }
    }));

    return errors.length > 0;
  }, [isDigitalCreation, mode]);

  const saveData = React.useCallback(async (data: OfferWizardData) => {
    const [, nextPosition] = shouldSubmitAndGoToStep;

    try {
      setIsSaving(true);

      if (!isDigitalCreation && nextPosition === 'heaven') {
        await createOfferFromManualWizard(data);
      } else {
        await saveOfferWizardData(data);
      }

      setIsSaving(false);
      if (nextPosition !== 'heaven') {
        navigate(`${location.pathname}#${nextPosition + 1}`);
      } else {
        await forceAsSeen(intent!);
        goBackToIntentPage();
      }

      await mutateWizardData();
    } catch (_) {
      setIsSaving(false);
      addError(SAVE_OFFER_WIZARD_DATA_ERROR_MESSAGE);
    }
  }, [shouldSubmitAndGoToStep, isDigitalCreation, mutateWizardData, navigate, location.pathname, forceAsSeen, intent, goBackToIntentPage, addError]);

  const onSubmit = React.useCallback(async (data: OfferWizardData, isDirty: boolean) => {
    const [, nextPosition] = shouldSubmitAndGoToStep;

    /**
     * Validate every step when we create the offer
    */
    if (nextPosition === 'heaven' || triedToGenerateOffer) {
      setTriedToGenerateOffer(true);
      const hasAtLeastAnError = await validateData(data);

      if (nextPosition === 'heaven' && hasAtLeastAnError) {
        return;
      }
    }

    /**
     * Don't make any API call but just jump to the `nextPosition` when:
     * 1. The form values have not been touched
     * 2. `nextPosition` is a valid one
     */
    if (!isDirty && nextPosition !== 'heaven') {
      navigate(`${location.pathname}#${nextPosition + 1}`);
      return;
    }

    await saveData(data);

    setShouldSubmitAndGoToStep(([_, prevNextPosition]) => [false, prevNextPosition]);
  }, [location.pathname, navigate, saveData, shouldSubmitAndGoToStep, triedToGenerateOffer, validateData]);

  const jumpToStep = React.useCallback((step: number | 'heaven') => () => {
    setShouldSubmitAndGoToStep([true, step]);
  }, []);

  const navigationItems = React.useMemo(() => OFFER_WIZARD_NAVIGATION.map((navigationStep, i) => ({
    ...navigationStep,
    hasError: stepsValidationStatus[mode as keyof typeof stepsValidationStatus][i].hasError,
  })), [OFFER_WIZARD_NAVIGATION, mode, stepsValidationStatus]);

  const [isLastStepCompleted, setIsLastStepCompleted] = React.useState(false);

  const handleStepWorkComplete = React.useCallback(() => {
    setIsLastStepCompleted(true);
  }, []);

  if (intentError || offersError || (wizardDataError && wizardDataError.response?.status !== 404)) {
    return <GenericErrorPage title={LOAD_OFFER_WIZARD_DATA_ERROR_MESSAGE} />;
  }

  if (!(OFFER_KO_STATUSES as any).includes(offers[0]?.status) && wizardData.isDigitallySigned) {
    return <GenericErrorPage title={SIGNATURE_ALREADY_REQUESTED_ERROR_MESSAGE} />;
  }

  if (isIntentLoading || areOffersLoading || isWizardDataLoading) {
    return null;
  }

  if (intent?.status !== IntentStatus.IN_PROGRESS || hasPendingOffers(offers)) {
    return <GenericErrorPage title={OFFER_CREATION_FAILING_PRECONDITIONS_ERROR_MESSAGE} />;
  }

  return intent && (
    <GenericPageLayout>
      <GenericPageLayout.Content
        title={isDigitalCreation ? 'Proposta digitale' : 'Proposta cartacea'}
        maxWrapperWidth={GenericPageLayoutWrapperWidth.MEDIUM}
        subtitle={(
          (
            <Link
              aria-label="Vai alla pagina dell\'interesse"
              onClick={goBackToIntentPage}
            >
              ← Vedi interesse
            </Link>
          )
        )}
        headerSlot={hasDigitalOfferEnabled && (
          <HStack>
            <Typography.BODY>
              {isDigitalCreation ? 'Hai già il documento cartaceo' : 'Vuoi creare la proposta digitale'}?
            </Typography.BODY>
            <Action
              label={isDigitalCreation ? 'Carica proposta' : 'Crea proposta'}
              aria-label={`Vai alla procedura ${isDigitalCreation ? 'manuale' : 'digitale'}`}
              size="S"
              emphasis="high"
              onClick={goToFlow}
              iconRight={{
                path: ICON_ARROW_UP,
                direction: 'right',
              }}
            />
          </HStack>
        )}
      >
        <div css={styles.wizardNavigation}>
          <div>
            <StepNavigation
              items={navigationItems}
              onChange={(pos) => jumpToStep(pos)()}
              variant={StepNavigationVariant.COMPACT}
              currentPosition={activePosition}
            />
          </div>
          <PreviewOfferWizardPDFAction intentId={intentId!} />
        </div>
        <GenericPageLayout.InnerContent>
          {OFFER_WIZARD_NAVIGATION.map((_, i) => {
            const Step = OFFER_WIZARD_STEPS[activePosition];

            if (activePosition >= Object.keys(OFFER_WIZARD_STEPS).length) {
              return null;
            }

            const { hasError, additionalInfo } = stepsValidationStatus[mode as keyof typeof stepsValidationStatus][activePosition];

            return i === activePosition ? (
              <div key={activePosition}>
                {hasError && (
                  <Spacing margin={[0, 0, 200, 0]}>
                    {/** @todo Replace with Message when it will handle children */}
                    <Message
                      type="critical"
                      boxed
                      message={OFFER_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE}
                    >
                      <ul>
                        {Object.values(additionalInfo).map((message) => (
                          <li key={message}>
                            {message}
                          </li>
                        ))}
                      </ul>
                    </Message>
                  </Spacing>
                )}
                <Step
                  intent={intent}
                  initialData={wizardData}
                  formRef={formRef}
                  onSubmit={onSubmit}
                  loading={isSaving}
                  handleStepWorkComplete={handleStepWorkComplete}
                />

                {activePosition === OFFER_WIZARD_NAVIGATION.length - 1 && hasAtLeastOneError && (
                  <>
                    <Divider />
                    <div aria-label="Elenco degli errori di validazione">
                      <Message
                        type="critical"
                        message={AT_LEAST_ONE_OFFER_WIZARD_VALIDATION_ERROR_MESSAGE}
                      />

                      <Spacing margin={[200, 0, 0, 0]}>
                        <strong>Correggi i seguenti step prima di poter procedere:</strong>

                        <Spacing margin={[200, 0, 0, 0]}>
                          {OFFER_WIZARD_NAVIGATION.map(({ title }, j) => {
                            if (stepsValidationStatus[mode as keyof typeof stepsValidationStatus][j].hasError) {
                              const humanStepNumber = j + 1;

                              return (
                                <div
                                  key={objectHash([j, title])}
                                  css={styles.erroredStepListItem}
                                  onClick={() => navigate(`${location.pathname}#${j + 1}`)}
                                  aria-label={`Correggi i dati dello step ${humanStepNumber}`}
                                  role="button"
                                >
                                  <div>{humanStepNumber}. {title}</div>
                                  <strong>Correggi</strong>
                                </div>
                              );
                            }

                            return null;
                          })}
                        </Spacing>
                      </Spacing>
                    </div>
                  </>
                )}

                <div css={styles.wizardStepFooter}>
                  <div>
                    {i !== 0 && (
                      <ActionIcon
                        aria-label="Salva i dati e torna allo step precedente"
                        label="Salva i dati e torna allo step precedente"
                        onClick={jumpToStep(activePosition - 1)}
                        disabled={isSaving}
                        icon={{
                          path: ICON_ARROW_UP,
                          direction: 'left',
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {i < OFFER_WIZARD_NAVIGATION.length - 1 ? (
                      <Action
                        label="Salva e continua"
                        aria-label="Salva i dati e procedi allo step successivo"
                        color="primary"
                        emphasis="high"
                        onClick={jumpToStep(activePosition + 1)}
                        loading={isSaving}
                        iconRight={{
                          path: ICON_ARROW_UP,
                          direction: 'right',
                        }}
                      />
                    ) : (
                      <Action
                        label="Crea proposta"
                        aria-label="Crea proposta"
                        onClick={jumpToStep('heaven')}
                        emphasis="high"
                        color="primary"
                        loading={isSaving}
                        disabled={!isLastStepCompleted || activePosition !== (OFFER_WIZARD_NAVIGATION.length - 1)}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default ManageOfferPage;
