import {
  ThemedFunctor, BreakpointQueryName, getSpacing, SpacingName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const content: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, SpacingName.SMALL)};
  margin: 0 ${theme.spaceUnits.mobile['-SMALL']} ${theme.spaceUnits.mobile.SMALL};
  background-color: ${theme.palettes.neutrals.lightest};

  &:last-child {
    margin-bottom: 0;
  }

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    margin: 0 0 ${theme.spaceUnits.desktop.SMALL};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
