import { RumorCompleteness, RumorStatus } from '../../../domain/types';
import { PaginatedResult, PaginationParams, serializePagination } from '../../pagination';
import { getClient, API_RUMORS_PATH } from '../api';

import { Rumor, Document } from '../dtos';
import { MatchCount } from '../dtos/matchCount';
import { MatchingSearchCriteria } from '../dtos/matchingSearchCriteria';
import { serializeParams } from '../utils/serializer';

export interface RumorsProviderFilters {
  agentEmail?: string | string[],
  status?: RumorStatus | RumorStatus[],
  completeness?: RumorCompleteness,
}

export async function createRumor(rumor: Rumor): Promise<Rumor> {
  const client = await getClient();
  const { data } = await client.post<Rumor>(API_RUMORS_PATH, rumor);

  return data;
}

export async function getRumor(id: NonNullable<Rumor['id']>): Promise<Rumor> {
  const client = await getClient();
  const { data } = await client.get<Rumor>(`${API_RUMORS_PATH}/${id}`);
  return data;
}

export async function updateRumor(rumor: Rumor): Promise<Rumor> {
  const client = await getClient();
  const { data } = await client.put<Rumor>(`${API_RUMORS_PATH}/${rumor.id}`, rumor);
  return data;
}

export async function getRumors(
  filter: RumorsProviderFilters = {},
  pagination: PaginationParams = {
    size: 50,
  },
): Promise<PaginatedResult<Rumor>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Rumor>>(API_RUMORS_PATH, {
    params: {
      ...serializeParams(filter),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getRumorMatchingSearchCriteria(
  rumorId: NonNullable<Rumor['id']>,
  pagination: PaginationParams,
): Promise<PaginatedResult<MatchingSearchCriteria>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<MatchingSearchCriteria>>(`${API_RUMORS_PATH}/${rumorId}/match/search-criteria`, {
    params: {
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getRumorMatchCount(rumorId: NonNullable<Rumor['id']>): Promise<MatchCount> {
  const client = await getClient();
  const { data } = await client.get<MatchCount>(`${API_RUMORS_PATH}/${rumorId}/match-count`);

  return data;
}

export async function getRumorDocuments(rumorId: NonNullable<Rumor['id']>): Promise<Document[]> {
  const client = await getClient();

  const { data } = await client.get<Document[]>(`${API_RUMORS_PATH}/${rumorId}/documents`);

  return data;
}
