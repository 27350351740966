/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, PropsWithChildren } from 'react';
import * as styles from './CardScrollable.style';

export type CardScrollableProps = PropsWithChildren<{
  height: string,
}>;

const CardScrollable: FunctionComponent<CardScrollableProps> = ({
  height,
  children,
}) => (
  <div
    css={styles.base}
    $height={height}
  >
    <div>
      {children}
    </div>
  </div>
);

export default CardScrollable;
