import * as Yup from 'yup';
import { inRange } from 'lodash';
import {
  ExtraPackageDoveIt, ExtraPackagePriceType,
} from '../../../../../domain/types';
import type { DoveItPackageFormModel } from './AssignmentWizardPackagesForm';

const MIN_PACKAGE_VALUE: Record<ExtraPackageDoveIt, number | undefined> = {
  [ExtraPackageDoveIt.EXTRA_VISIBILITY]: undefined,
  [ExtraPackageDoveIt.INTERIOR_RESTYLING]: undefined,
  [ExtraPackageDoveIt.DRONE]: undefined,
  [ExtraPackageDoveIt.PHOTO_PLUS]: undefined,
  [ExtraPackageDoveIt.PRESTIGE]: 2499,
  [ExtraPackageDoveIt.PERSONALIZED_MARKETING]: undefined,
  [ExtraPackageDoveIt.PHOTO_PRO]: undefined,
};

const doveItExtraPackageWithPriceValidation = Yup.object({
  price: Yup
    .string()
    .matches(/^\d*\.?\d*$/)
    .test('isValid', 'Il prezzo deve avere un valore valido', (value, ctx) => {
      const { type, priceType } = ctx.parent as DoveItPackageFormModel;
      const min = MIN_PACKAGE_VALUE[type];

      const numericValue = value ? parseFloat(value) : -1;

      if (typeof min !== 'undefined' && priceType === ExtraPackagePriceType.FIXED && numericValue < min) {
        return ctx.createError({
          path: ctx.path,
          message: `Il valore minimo è ${min}`,
        });
      }

      if (priceType === ExtraPackagePriceType.PERCENTAGE && !inRange(numericValue, 0.01, 100.01)) {
        return ctx.createError({
          path: ctx.path,
          message: 'Il valore deve essere compreso tra 0 e 100%',
        });
      }

      return true;
    }),
  priceType: Yup
    .string()
    .oneOf(Object.values(ExtraPackagePriceType))
    .required('Il campo è obbligatorio'),
});

export default Yup.object().shape({
  noPackagesDoveIt: Yup.bool().required('Il campo è obbligatorio'),
  noPackagesSPF: Yup.bool().required('Il campo è obbligatorio'),
  doveItPackages: Yup.array(doveItExtraPackageWithPriceValidation),
});
