import { isAfter, isToday } from 'date-fns';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { JobTitle } from '../../../domain/types';
import { COUNTRY } from '../../../constants';

export default Yup.object().shape({
  name: Yup.string().trim()
    .required('Il nome è obbligatorio'),
  surname: Yup.string().trim()
    .required('Il cognome è obbligatorio'),
  email: Yup.string().trim().email()
    .required('L\'email è obbligatoria'),
  mobile: Yup.string().trim()
    .required('Il campo è obbligatorio')
    .test(
      'validPhoneNumber',
      'Inserisci un numero di telefono valido',
      (value: string | undefined) => !value || isValidPhoneNumber(value, COUNTRY),
    ),
  role: Yup.string()
    .oneOf(Object.values(JobTitle))
    .required('Il ruolo è obbligatorio'),
  startDate: Yup.date()
    .test('isDateInTheFuture',
      'La data di inizio deve essere nel futuro',
      (value) => value && (isToday(value) || isAfter(value, new Date())))
    .required('La data di inizio è obbligatoria'),
});
