import {
  Action, ActionIcon, ICON_BELL_OUTLINE, ICON_PENCIL_OUTLINE, Message, Portal, Spacing, Icon, Typography, useModal,
  HStack,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { formatDate } from '@doveit/hammer';
import Card from '../../../components/card/Card';
import { WidgetCardSkeleton } from '../../../components/card/Card.skeleton';
import Divider from '../../../components/divider/Divider';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { MAX_PAGED_SIZE } from '../../../constants';
import { ReferenceType } from '../../../domain/types';
import { ReminderV3 } from '../../../providers/api/dtos';
import { SortOrder } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import useRemindersV3, { REMINDERS_RID } from '../../hooks/use-reminders/useRemindersV3';
import DeleteReminderActionV3 from '../delete-reminder-action/DeleteReminderActionV3';
import UpsertReminderActionV3 from '../upsert-reminder-action/UpsertReminderActionV3';

export interface RemindersWidgetV3Props {
  referenceId: string | number,
  referenceType: ReferenceType,
  userEmail: string,
  canCreate?: boolean,
  canEdit?: boolean,
  canDelete?: boolean,
}

export const REMINDERS_LOAD_ERROR_MESSAGE = 'Impossibile caricare i promemoria associati.';
export const NO_REMINDERS_MESSAGE = 'Nessun promemoria inserito.';

const RemindersWidgetV3: FunctionComponent<RemindersWidgetV3Props> = ({
  referenceId,
  referenceType,
  userEmail,
  canCreate,
  canEdit,
  canDelete,
}) => {
  const matchMutate = useMatchMutate();
  const { data: reminders, error: remindersError } = useRemindersV3({
    referenceId,
    referenceType,
  }, {
    size: MAX_PAGED_SIZE,
    sort: {
      createdAt: SortOrder.DESC,
    },
  });

  const viewAllRemindersModal = useModal<ReminderV3[]>();

  const lastCreatedReminder = useMemo(() => {
    if (!reminders) {
      return null;
    }

    return reminders.content.length > 0 ? reminders.content[0] : null;
  }, [reminders]);

  const hasActiveReminder = useMemo(
    () => lastCreatedReminder && !lastCreatedReminder.expired,
    [lastCreatedReminder],
  );

  const bottomAction = useMemo(() => {
    if (reminders && reminders.content.length > 1) {
      return ({
        label: 'Vedi tutti',
        onClick: () => viewAllRemindersModal.open(),
      });
    }

    return undefined;
  }, [reminders, viewAllRemindersModal]);

  const onUpsertReminderSuccess = useCallback(
    () => matchMutate(REMINDERS_RID),
    [matchMutate],
  );

  if (!reminders && !remindersError) {
    return (
      <WidgetCardSkeleton />
    );
  }

  if (remindersError) {
    return (
      <Message
        type="critical"
        boxed
        message={REMINDERS_LOAD_ERROR_MESSAGE}
      />
    );
  }

  return reminders! && (
    <Card
      collapsed
      data-ref="reminders-section"
      bottomAction={bottomAction}
    >
      <Card.Header
        primaryActions={[
          (canCreate && !hasActiveReminder) && (
            <UpsertReminderActionV3
              data-ref="add-reminder-action"
              reminder={{
                referenceId,
                referenceType,
                userEmail,
              }}
              onSuccess={onUpsertReminderSuccess}
            >
              {({ openUpsertReminderModal, disabled }) => (
                <Action
                  label="Aggiungi"
                  size="S"
                  dataRef="add-reminder-action"
                  onClick={openUpsertReminderModal}
                  disabled={disabled}
                />
              )}
            </UpsertReminderActionV3>
          ),
        ]}
      >
        <Card.Title>
          <Icon path={ICON_BELL_OUTLINE} />
          <Typography.HEADING_3 color="brand.primary">
            Promemoria
          </Typography.HEADING_3>
        </Card.Title>
      </Card.Header>
      <Card.Box>
        {reminders.content.length === 0 && (
          <Message
            data-ref="no-reminders-message"
            message={NO_REMINDERS_MESSAGE}
          />
        )}
        {reminders.content.length > 0 && (
          <Card data-ref="reminder">
            <Card.Header>
              <Card.Title>
                <Typography.BODY>
                  <strong>
                    {formatDate(new Date(reminders.content[0].startDate), {
                      time: true,
                    })}
                  </strong>
                </Typography.BODY>
              </Card.Title>
              <div>
                {canEdit && hasActiveReminder && (
                  <UpsertReminderActionV3
                    data-ref="edit-reminder-action"
                    reminder={reminders.content[0]}
                    onSuccess={onUpsertReminderSuccess}
                  >
                    {({ openUpsertReminderModal, disabled }) => (
                      <ActionIcon
                        icon={{ path: ICON_PENCIL_OUTLINE }}
                        label="Modifica promemoria"
                        size="S"
                        dataRef="edit-reminder-action"
                        onClick={openUpsertReminderModal}
                        disabled={disabled}
                      />
                    )}
                  </UpsertReminderActionV3>
                )}
                {canDelete && (
                  <DeleteReminderActionV3
                    data-ref="edit-reminder-action"
                    reminderId={reminders.content[0].id!}
                    onSuccess={onUpsertReminderSuccess}
                  />
                )}
              </div>
            </Card.Header>
            <Card.Box>
              <Divider>Note</Divider>
              {reminders.content[0].notes}
            </Card.Box>
          </Card>
        )}
      </Card.Box>
      {reminders?.content && viewAllRemindersModal.isOpen && (
        <Portal>
          <SimpleModal
            {...viewAllRemindersModal}
            data-ref="view-all-reminders-modal"
            keepMount
            header={(
              <div>
                <HStack>
                  <Typography.HEADING_3>
                    Promemoria
                  </Typography.HEADING_3>
                  <Typography.BODY>
                    ({reminders.content.length})
                  </Typography.BODY>
                </HStack>
                {canCreate && !hasActiveReminder && (
                  <UpsertReminderActionV3
                    data-ref="add-reminder-action-modal"
                    reminder={{
                      referenceId,
                      referenceType,
                      userEmail,
                    }}
                    onSuccess={onUpsertReminderSuccess}
                  />
                )}
              </div>
            )}
          >
            {reminders.content.map((reminder) => (
              <Spacing
                key={reminder.id}
                margin={[200, 0, 0, 0]}
              >
                <Card data-ref="reminder">
                  <Card.Header>
                    <Card.Title>
                      <Typography.BODY>
                        <strong>
                          {formatDate(new Date(reminder.startDate), {
                            time: true,
                          })}
                        </strong>
                      </Typography.BODY>
                    </Card.Title>
                    <div>
                      {canEdit && hasActiveReminder && (
                        <UpsertReminderActionV3
                          data-ref="edit-reminder-action"
                          reminder={reminder}
                          onActionClick={viewAllRemindersModal.close}
                          onSuccess={onUpsertReminderSuccess}
                        >
                          {({ openUpsertReminderModal, disabled }) => (
                            <ActionIcon
                              icon={{ path: ICON_PENCIL_OUTLINE }}
                              size="S"
                              label="Modifica promemoria"
                              dataRef="edit-reminder-action"
                              onClick={openUpsertReminderModal}
                              disabled={disabled}
                            />
                          )}
                        </UpsertReminderActionV3>
                      )}
                      {canDelete && (
                        <DeleteReminderActionV3
                          data-ref="edit-reminder-action"
                          reminderId={reminder.id!}
                          onSuccess={onUpsertReminderSuccess}
                        />
                      )}
                    </div>
                  </Card.Header>
                  <Card.Box>
                    <Divider>Note</Divider>
                    {reminder.notes}
                  </Card.Box>
                </Card>
              </Spacing>
            ))}
          </SimpleModal>
        </Portal>
      )}
    </Card>
  );
};

export default RemindersWidgetV3;
