import React from 'react';
import {
  Action, FormHandlers, Message, Portal, Spacing, useModal,
  useNotifications,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { Prospect } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import ProspectApprovalRequestForm, { ProspectApprovalRequestFormModel } from '../../components/prospect-approval-request-form-v2/ProspectApprovalRequestForm';
import { useComputedProspectApprovalStatus } from '../../hooks/use-computed-prospect-approval-status/useComputedProspectApprovalStatus';
import { ProspectApprovalStatus } from '../../../domain/types';
import { updateProspect } from '../../../providers/api/prospect/prospectProvider';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { getAgentName } from '../../../agent/utils/utils';

interface ChildFnProps {
  editApproval: VoidFunction,
  label: string,
  ariaLabel: string,
  isSaving: boolean,
}

export interface EditProspectApprovalActionProps extends React.AriaAttributes {
  prospect: Prospect,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (prospect: Prospect) => void,
}

export const EDIT_PROSPECT_APPROVAL_SUCCESS_MESSAGE = 'La nuova valutazione è stata presentata con successo';
export const EDIT_PROSPECT_APPROVAL_ERROR_MESSAGE = 'Non è stato possibile presentare la nuova valutazione';

const EditProspectApprovalAction: React.FC<EditProspectApprovalActionProps> = ({
  prospect,
  children,
  onSuccess,
  ...rest
}) => {
  const modal = useModal();
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);
  const { data: { status, canBeUpdatedTo } } = useComputedProspectApprovalStatus(prospect);
  const { data: agent } = useAgent(prospect.agentId);

  const isWaitingForSupervisor = status === ProspectApprovalStatus.WAITING_FOR_SUPERVISOR;
  const shouldBeUpdatedTo = isWaitingForSupervisor
    ? ProspectApprovalStatus.WAITING_FOR_AGENT
    : ProspectApprovalStatus.WAITING_FOR_SUPERVISOR;

  const canBeEdited = React.useMemo(
    () => canBeUpdatedTo(shouldBeUpdatedTo),
    [canBeUpdatedTo, shouldBeUpdatedTo],
  );

  const initialValues = React.useMemo(() => ({
    agentEvaluation: prospect.agentEvaluation?.toString() || '',
  }), [prospect.agentEvaluation]);

  const handleSubmit = React.useCallback(async (values: ProspectApprovalRequestFormModel) => {
    try {
      setIsSaving(true);

      const updatedProspect = await updateProspect(prospect.id!, {
        ...prospect,
        approval: {
          ...prospect.approval!,
          status: shouldBeUpdatedTo,
        },
        agentEvaluation: parseInt(values.agentEvaluation, 10),
      });

      setIsSaving(false);
      modal.close();
      addSuccess(EDIT_PROSPECT_APPROVAL_SUCCESS_MESSAGE);

      onSuccess?.(updatedProspect);
    } catch (err) {
      setIsSaving(false);
      addError(EDIT_PROSPECT_APPROVAL_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, modal, onSuccess, prospect, shouldBeUpdatedTo]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  if (!canBeEdited) return null;

  return (
    <>
      {children ? children({
        editApproval: modal.open,
        label: 'Proponi nuova valutazione',
        ariaLabel: 'Azione per proporre una nuova valutazione',
        isSaving,
      }) : (
        <Action
          label="Proponi nuova valutazione"
          aria-label="Azione per proporre una nuova valutazione"
          size="S"
          loading={isSaving}
          onClick={() => modal.open()}
          {...rest}
        />
      )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Proponi nuova valutazione"
          aria-label="Modale per proporre una nuova valutazione"
          footer={(
            <Action
              label="Proponi nuova valutazione"
              color="primary"
              emphasis="high"
              aria-label="Azione per confermare la proposta di valutazione"
              onClick={submitForm}
            />
          )}
        >
          <Message
            type="neutral"
            message={isWaitingForSupervisor
              ? `Proponi una nuova valutazione all'agente${agent ? ` ${getAgentName(agent)}` : ''}, che ha proposto ${formatEuro(prospect.agentEvaluation!)}.`
              : `Proponi una nuova valutazione al tuo supervisore, che ha proposto ${formatEuro(prospect.agentEvaluation!)}.`}
          />

          <Spacing margin={[300, 0, 0]} />

          <ProspectApprovalRequestForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            loading={isSaving}
            innerRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditProspectApprovalAction;
