import React from 'react';
import { useNotifications } from '@doveit/bricks';
import { Agent, Intent } from '../../providers/api/dtos';
import { updateIntent } from '../../providers/api/intent/intentProvider';
import { useCurrentAgentIsSameAgentOrManager } from '../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';

type Options = {
  forceMark?: boolean,
  sendNotification?: boolean,
  onSuccess?: (updatedIntent: Intent) => void,
};

export function useMarkIntentAsSeen(intentAgentId: Agent['id']) {
  const [isSaving, setIsSaving] = React.useState(false);
  const canMarkAsUser = useCurrentAgentIsSameAgentOrManager(intentAgentId);
  const { addSuccess } = useNotifications();

  const canMark = React.useCallback(
    () => intentAgentId && canMarkAsUser,
    [canMarkAsUser, intentAgentId],
  );

  const internalCanMarkAsSeenOnly = React.useCallback(
    (
      intent: Intent,
    ) => intentAgentId && !intent.seen && canMarkAsUser,
    [canMarkAsUser, intentAgentId],
  );

  const internalMarkAs = React.useCallback(
    (
      seen: Intent['seen'],
      { forceMark, sendNotification, onSuccess }: Options = { forceMark: false, sendNotification: false, onSuccess: undefined },
    ) => async (intent: Intent): Promise<Intent> => {
      let returnedIntent = intent;

      if (forceMark
        || (canMark() && intent.seen !== seen)
        || (internalCanMarkAsSeenOnly(intent) && intent.seen !== seen)) {
        setIsSaving(true);

        try {
          returnedIntent = await updateIntent(intent.id!, { ...intent, seen });

          if (sendNotification) {
            addSuccess(`Segnato come ${seen ? 'visto' : 'non visto'}`);
          }
          onSuccess?.(returnedIntent);
        } catch {
          // Silent error
        } finally {
          setIsSaving(false);
        }
      }

      return returnedIntent;
    }, [addSuccess, canMark, internalCanMarkAsSeenOnly],
  );

  const markAs = React.useCallback(
    (intent: Intent, seen: Intent['seen'], options?: Options) => internalMarkAs(seen, options)(intent),
    [internalMarkAs],
  );

  const markAsSeen = React.useCallback(
    (intent: Intent, options?: Omit<Options, 'forceMark'>) => markAs(intent, true, options),
    [markAs],
  );

  const markAsNotSeen = React.useCallback(
    (intent: Intent, options?: Omit<Options, 'forceMark'>) => markAs(intent, false, options),
    [markAs],
  );

  const forceAsSeen = React.useCallback(
    (intent: Intent, options?: Omit<Options, 'forceMark'>) => markAs(intent, true, {
      ...options,
      forceMark: true,
    }), [markAs],
  );

  const forceAsNotSeen = React.useCallback(
    (intent: Intent, options?: Omit<Options, 'forceMark'>) => markAs(intent, false, {
      ...options,
      forceMark: true,
    }),
    [markAs],
  );

  return {
    isSaving,
    canMark,
    markAs,
    markAsSeen,
    markAsNotSeen,
    forceAsSeen,
    forceAsNotSeen,
  };
}

export default useMarkIntentAsSeen;
