export type StrictBooleanString = 'true' | 'false';
export type BooleanString = StrictBooleanString | '';

export function boolToBooleanString(bool: boolean): BooleanString;
export function boolToBooleanString(bool?: boolean): BooleanString | undefined;

export function boolToBooleanString(bool?: boolean): BooleanString | undefined {
  if (typeof bool === 'undefined') {
    return undefined;
  }

  return bool ? 'true' : 'false';
}

export function booleanStringToBool(boolStr: BooleanString): boolean;
export function booleanStringToBool(boolStr?: BooleanString): boolean | undefined;

export function booleanStringToBool(boolStr?: BooleanString): boolean | undefined {
  return boolStr !== '' && typeof boolStr !== 'undefined'
    ? boolStr === 'true'
    : undefined;
}
