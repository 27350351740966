import useSWR from 'swr';
import hash from 'object-hash';
import { JobApplication } from '../../../providers/api/dtos';
import { searchJobApplications } from '../../../providers/api/job-application/jobApplicationProvider';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

export const SEARCH_JOB_APPLICATIONS_RID = '/v1/job-applications/search';

export default function useSearchJobApplications(
  query?: string,
  assignee?: number,
  pagination: PaginationParams = {},
): SWRCustomResponse<PaginatedResult<JobApplication>, Error> {
  const matchMutate = useMatchMutate();

  const key = query ? [SEARCH_JOB_APPLICATIONS_RID, hash({ query, assignee, ...pagination })] : null;
  const swrResponse = useSWR(key, () => searchJobApplications(query!, assignee, pagination));

  return {
    ...swrResponse,
    mutate: () => matchMutate(SEARCH_JOB_APPLICATIONS_RID),
  };
}
