import { SortParams, serializeSortParams } from './pagination';
import { ConversationMessage } from './api/dtos';

export interface ConversationMessagePaginationParams {
  pageToken?: string,
  size?: number,
  sort?: SortParams,
}

export interface SerializedConversationMessagePagination {
  pageToken?: string,
  size?: number,
  sort?: string[],
}

export interface PaginatedConversationMessageResult {
  content: ConversationMessage[],
  size: number,
  first: boolean,
  last: boolean,
  numberOfElements: number,
  currentPageToken?: string,
  nextPageToken?: string,
  previousPageToken?: string,
}

export function serializeConversationMessagePagination(params: ConversationMessagePaginationParams): SerializedConversationMessagePagination {
  const pagination: SerializedConversationMessagePagination = {};

  if (params.pageToken) {
    pagination.pageToken = params.pageToken;
  }

  if (params.size) {
    pagination.size = params.size;
  }

  if (params.sort) {
    pagination.sort = serializeSortParams(params.sort);
  }

  return pagination;
}
