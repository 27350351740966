export const crawledPropertyColor = {
  LIVE_WITH_ADDRESS: '#0064DC',
  LIVE_WITHOUT_ADDRESS: '#5EA3F5',
  EXPIRED_WITH_ADDRESS: '#6A6969',
  EXPIRED_WITHOUT_ADDRESS: '#B5B5B5',
};

export default function getCrawledPropertyColor(expired: boolean, address?: string | null) {
  if (!expired) {
    return address
      ? crawledPropertyColor.LIVE_WITH_ADDRESS
      : crawledPropertyColor.LIVE_WITHOUT_ADDRESS;
  }

  return address
    ? crawledPropertyColor.EXPIRED_WITH_ADDRESS
    : crawledPropertyColor.EXPIRED_WITHOUT_ADDRESS;
}
