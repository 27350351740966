import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const baseStyle: ThemedFunctor = ({
  theme,
  $isDragActive,
}) => css`
  padding: ${theme.remSize[300]} ${theme.remSize[300]} ${theme.remSize[400]};
  border: 1px dashed ${theme.color.border.neutral.default.high};
  background-color: ${theme.color.background.neutral.default.low};
  color: ${theme.color.foreground.neutral.default.low};
  text-align: center;

  ${$isDragActive && `
    border-color: ${theme.color.border.primary.default.high};
    background-color: ${theme.color.background.primary.default.low};
    color: ${theme.color.foreground.primary.default.low};
  `}
`;

export const text: ThemedFunctor = () => css`
  color: currentColor;
`;
