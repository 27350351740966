import React from 'react';
import {
  Action,
  Badge,
  Card,
  CardSkeleton,
  Dropdown,
  Grid,
  HStack,
  ICON_ALERT_CIRCLE_OUTLINE,
  ICON_BELL_OUTLINE,
  ICON_CALENDAR_MONTH_OUTLINE,
  ICON_CALENDAR_OUTLINE,
  Icon,
  Message,
  Spacing,
  Stack,
  Text,
  useToggle,
} from '@doveit/bricks';
import { partition } from 'lodash';
import { isPast } from 'date-fns';
import {
  Agent, Appointment, CalendarEvent, Contact,
  Reminder,
} from '../../providers/api/dtos';
import { CalendarEventsFilters } from '../../providers/api/contact/contactProvider';
import { CalendarEventType, useCalendarEvents } from '../../hooks/use-calendar-events/useCalendarEvents';
import { MAX_PAGED_SIZE } from '../../constants';
import UpsertAppointmentAction from '../../appointment/containers/upsert-appointment-action/UpsertAppointmentAction';
import UpsertReminderAction from '../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import DeleteReminderAction from '../../reminders/containers/delete-reminder-action/DeleteReminderAction';
import UpdateReminderStatus from '../../reminders/components/update-reminder-status/UpdateReminderStatus';
import UpdateAppointmentStatus from '../../reminders/components/update-appointment-status/UpdateAppointmentStatus';
import { AppointmentStatus } from '../../domain/types';
import { computeResolvableStatus } from '../../utils/reminder/reminder';
import { ResolvableReminderStatus } from '../../types';
import useIsDevice from '../../hooks/use-is-device/useIsDevice';
import DateViewer from '../date-viewer/DateViewer';
import SimpleTableSkeleton from '../simple-table/SimpleTable.skeleton';
import SimpleTable from '../simple-table/SimpleTable';
import CalendarEventTableRow from '../../calendar-event/components/calendar-event-table-row/CalendarEventTableRow';

export const EMPTY_CALENDAR_EVENTS = 'Non sono presenti eventi in calendario.';
const MAX_SHOWN_EVENTS_ON_MOBILE = 1;
const FILTERS = ['all', 'resolved', 'unresolved'] as const;

type Filter = typeof FILTERS[number];

const filterLabels: Record<Filter, string> = {
  all: 'Tutti',
  resolved: 'Risolti',
  unresolved: 'Non risolti',
};

export interface CalendarWidgetProps {
  contactId?: NonNullable<Contact['id']>,
  agentId: NonNullable<Agent['id']>,
  filters: Required<CalendarEventsFilters>,
  canAddReminder?: boolean,
  canEditReminder?: boolean,
  canDeleteReminder?: boolean,
  canAddAppointment?: boolean,
  canEditAppointment?: boolean,
  onLoadError?: (errors: Error[]) => void,
  onEventCreate?: (event: CalendarEvent, type: CalendarEventType) => void,
  onEventUpdate?: (event: CalendarEvent, type: CalendarEventType) => void,
  onEventDelete?: (eventId: NonNullable<CalendarEvent['id']>, type: CalendarEventType) => void,
}

/**
 * @todo move under `calendar-event/containers` folder
 */
const CalendarWidget: React.FC<CalendarWidgetProps> = ({
  contactId,
  agentId,
  filters,
  canAddReminder = false,
  canEditReminder = false,
  canDeleteReminder = false,
  canAddAppointment = false,
  canEditAppointment = false,
  onLoadError,
  onEventCreate,
  onEventDelete,
  onEventUpdate,
}) => {
  const isMobile = useIsDevice('mobile');

  // For mobile
  const { opened: areAllEventsShown, toggle: toggleShowAllEvents } = useToggle(false);

  const {
    data: calendarEvents = [],
    isLoading: areCalendarEventsLoading,
    errors: calendarEventsErrors,
    mutate: mutateCalendarEvents,
  } = useCalendarEvents(contactId, filters, {
    size: MAX_PAGED_SIZE,
  }, {
    onErrors: onLoadError,
  });

  const [activeFilter, setActiveFilter] = React.useState<Filter>('all');

  const filteredEvents = React.useMemo(() => {
    switch (activeFilter) {
      case 'resolved':
        return calendarEvents.filter(({ type, event }) => (type === 'appointment'
          ? [AppointmentStatus.CANCELLED, AppointmentStatus.DONE].includes(event.status)
          : computeResolvableStatus(event) === ResolvableReminderStatus.RESOLVED));
      case 'unresolved':
        return calendarEvents.filter(({ type, event }) => (type === 'appointment'
          ? [AppointmentStatus.TODO].includes(event.status)
          : [ResolvableReminderStatus.ACTIVE, ResolvableReminderStatus.EXPIRED].includes(computeResolvableStatus(event))));
      default:
        return [...calendarEvents];
    }
  }, [activeFilter, calendarEvents]);

  const eventsToShow = React.useMemo(() => {
    const sorted = filteredEvents?.sort((b, a) => Date.parse(a.event.startDate) - Date.parse(b.event.startDate));

    if (isMobile) {
      const [future, past] = partition(sorted, ({ event }) => !isPast(event.startDate));
      const toAdd = (areAllEventsShown || past.length === 0) ? past : [past[0]];

      return {
        calendarEvents: [...future, ...toAdd],
        hasMore: past.length > MAX_SHOWN_EVENTS_ON_MOBILE,
      };
    }

    return {
      calendarEvents: sorted,
      hasMore: false,
    };
  }, [areAllEventsShown, filteredEvents, isMobile]);

  const appointmentToCreate: Appointment = React.useMemo(() => ({
    ...filters,
    agentId,
    contactId,
    virtual: false,
  } as Appointment), [agentId, contactId, filters]);

  const reminderToCreate: Reminder = React.useMemo(() => ({
    ...filters,
    agentId,
    resolved: false,
  } as Reminder), [agentId, filters]);

  const handleEventCreate = React.useCallback((type: CalendarEventType) => async (event: CalendarEvent) => {
    await mutateCalendarEvents();

    onEventCreate?.(event, type);
  }, [mutateCalendarEvents, onEventCreate]);

  const handleEventUpdate = React.useCallback((type: CalendarEventType) => async (event: CalendarEvent) => {
    await mutateCalendarEvents();

    onEventUpdate?.(event, type);
  }, [mutateCalendarEvents, onEventUpdate]);

  const handleEventDelete = React.useCallback((type: CalendarEventType) => async (eventId: NonNullable<CalendarEvent['id']>) => {
    await mutateCalendarEvents();

    onEventDelete?.(eventId, type);
  }, [mutateCalendarEvents, onEventDelete]);

  if (areCalendarEventsLoading) {
    return (
      <CardSkeleton aria-label="Eventi del calendario in caricamento">
        {!isMobile && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  if (calendarEventsErrors && calendarEventsErrors?.length > 0) {
    return (
      <Card aria-label="Errore caricamento degli eventi del calendario">
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Calendario"
          color="critical"
        />
        <Card.Content>
          <Message message="Non è stato possibile caricare gli eventi del calendario." />
        </Card.Content>
      </Card>
    );
  }

  if (isMobile) {
    return (
      <Card aria-label="Calendario (Visualizzazione mobile)">
        <Card.Header
          icon={{ path: ICON_CALENDAR_MONTH_OUTLINE }}
          title="Calendario"
          secondaryActions={[
            canAddReminder && (
              <UpsertReminderAction
                reminder={reminderToCreate}
                onSuccess={handleEventCreate('reminder')}
                aria-label="Pulsante per aggiungere un promemoria"
              >
                {({ upsert }) => (
                  <Dropdown.Option
                    label="Aggiungi promemoria"
                    onClick={upsert}
                  />
                )}
              </UpsertReminderAction>
            ),
            canAddAppointment && (
              <UpsertAppointmentAction
                appointment={appointmentToCreate}
                onSuccess={handleEventCreate('appointment')}
                aria-label="Pulsante per aggiungere un appuntamento"
              >
                {({ upsert }) => (
                  <Dropdown.Option
                    label="Aggiungi appuntamento"
                    onClick={upsert}
                  />
                )}
              </UpsertAppointmentAction>
            ),
          ]}
        />
        <Card.Content>
          {eventsToShow.calendarEvents.length === 0 && (
            <Message
              type="neutral"
              message={EMPTY_CALENDAR_EVENTS}
            />
          )}

          {eventsToShow.calendarEvents.length > 0 && (
            <Stack aria-label="Lista eventi del calendario">
              {eventsToShow.calendarEvents.map(({ event, type }) => (
                <Card key={event.id} aria-label="Informazioni evento">
                  <Card.Header
                    secondaryActions={type === 'reminder' ? [
                      canEditReminder && (
                        <UpsertReminderAction
                          aria-label="Pulsante di modifica del promemoria"
                          reminder={event}
                          onSuccess={handleEventUpdate(type)}
                        >
                          {({ upsert }) => (
                            <Dropdown.Option
                              label="Modifica"
                              onClick={upsert}
                            />
                          )}
                        </UpsertReminderAction>
                      ),
                      canDeleteReminder && (
                        <DeleteReminderAction
                          aria-label="Pulsante di cancellazione del promemoria"
                          reminderId={event.id!}
                          onSuccess={handleEventDelete(type)}
                        >
                          {({ disabled, openDeleteReminderModal }) => (
                            <Dropdown.Option
                              label="Elimina"
                              onClick={openDeleteReminderModal}
                              disabled={disabled}
                            />
                          )}
                        </DeleteReminderAction>
                      ),
                    ] : [
                      canEditAppointment && (
                        <UpsertAppointmentAction
                          aria-label="Pulsante di modifica dell'appuntamento"
                          appointment={event}
                          onSuccess={handleEventUpdate(type)}
                          withStatus={false}
                        />
                      ),
                    ]}
                  >
                    <HStack>
                      <Icon
                        path={type === 'appointment' ? ICON_CALENDAR_OUTLINE : ICON_BELL_OUTLINE}
                        color="primary.default.low"
                      />
                      <Text.H4 color="primary.default.low">
                        <DateViewer
                          stringDate={event.startDate}
                          checkIfToday
                          checkIfTomorrow
                          withHour
                        />
                      </Text.H4>
                    </HStack>
                  </Card.Header>

                  <Card.Content>
                    <Grid gutter={150}>
                      <Grid.Unit>
                        {type === 'reminder' && canEditReminder && (
                          <UpdateReminderStatus
                            reminder={event}
                            onSuccess={handleEventUpdate(type)}
                          />
                        )}
                        {type === 'appointment' && canEditAppointment && (
                          <UpdateAppointmentStatus
                            appointment={event}
                            onSuccess={handleEventUpdate(type)}
                          />
                        )}
                      </Grid.Unit>
                      {event.notes && (
                        <Grid.Unit>
                          <Stack>
                            <Text.Mini>
                              NOTE
                            </Text.Mini>
                            <Text.BodySmall>
                              {event.notes}
                            </Text.BodySmall>
                          </Stack>
                        </Grid.Unit>
                      )}
                    </Grid>
                  </Card.Content>
                </Card>
              ))}
            </Stack>
          )}
        </Card.Content>

        {eventsToShow.hasMore && (
          <Card.Content>
            <Action
              expanded
              size="S"
              label={areAllEventsShown ? 'Vedi meno' : 'Vedi tutti'}
              onClick={toggleShowAllEvents}
            />
          </Card.Content>
        )}
      </Card>
    );
  }

  return (
    <Card aria-label="Calendario (Visualizzazione desktop)">
      <Card.Header
        icon={{ path: ICON_CALENDAR_MONTH_OUTLINE }}
        title="Calendario"
        primaryActions={[
          canAddReminder && (
            <UpsertReminderAction
              reminder={reminderToCreate}
              onSuccess={handleEventCreate('reminder')}
              aria-label="Pulsante per aggiungere un promemoria"
            >
              {({ upsert }) => (
                <Action
                  label="Aggiungi promemoria"
                  onClick={upsert}
                  size="S"
                />
              )}
            </UpsertReminderAction>
          ),
          canAddAppointment && (
            <UpsertAppointmentAction
              appointment={appointmentToCreate}
              onSuccess={handleEventCreate('appointment')}
              aria-label="Pulsante per aggiungere un appuntamento"
            >
              {({ upsert }) => (
                <Action
                  label="Aggiungi appuntamento"
                  onClick={upsert}
                  size="S"
                />
              )}
            </UpsertAppointmentAction>
          ),
        ]}
      />

      <Card.Content>
        <Stack aria-label="Lista eventi del calendario" gap={150}>
          {calendarEvents.length > 0 && (
            <Spacing margin={[0, 0, 150]}>
              <HStack aria-label="Filtri">
                {FILTERS.map(((filter) => (
                  <Badge
                    label={filterLabels[filter]}
                    key={filter}
                    emphasis="low"
                    onClick={filter !== activeFilter ? () => setActiveFilter(filter) : undefined}
                    color={activeFilter === filter ? 'primary' : 'neutral'}
                  />
                )))}
              </HStack>
            </Spacing>
          )}
          {eventsToShow.calendarEvents.length === 0 && (
            <Message
              type="neutral"
              message={EMPTY_CALENDAR_EVENTS}
            />
          )}

          {eventsToShow.calendarEvents.length > 0 && (
            <SimpleTable aria-label="Tabella eventi del calendario">
              <SimpleTable.Body>
                {eventsToShow.calendarEvents.map((discreteEvent) => (
                  <SimpleTable.Row
                    asChild
                    key={discreteEvent.event.id}
                  >
                    <CalendarEventTableRow
                      calendarEvent={discreteEvent}
                      onEventUpdate={handleEventUpdate(discreteEvent.type)}
                      onEventDelete={handleEventDelete(discreteEvent.type)}
                      canEditReminder={canEditReminder}
                      canDeleteReminder={canDeleteReminder}
                      canEditAppointment={canEditAppointment}
                    />
                  </SimpleTable.Row>
                ))}
              </SimpleTable.Body>
            </SimpleTable>
          )}
        </Stack>

      </Card.Content>
    </Card>
  );
};

export default CalendarWidget;
