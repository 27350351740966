import {
  Message, ICON_TEXT_BOX_OUTLINE, Action, Portal, useModal, ConfirmModal, BreakpointQueryName, Card, useToggle, Dropdown, Spacing, Text, Stack,
} from '@doveit/bricks';
import React from 'react';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import objectHash from 'object-hash';
import { formatDate } from '@doveit/hammer';
import { Note } from '../../../providers/api/dtos';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import MultilineContent from '../../../prospect/components/multiline-content/MultilineContent';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewNoteTableRow, { NoteAuthor } from './ViewNoteTableRow';
import UpsertNoteAction from '../../containers/UpsertNoteAction';
import { uniqueByField } from '../../../utils/array';

export const MAX_SHOWN_NOTES = 3;

export interface NotesSectionProps extends React.AriaAttributes {
  notes?: Note[],
  canCreate?: boolean,
  canEdit?: boolean,
  canDelete?: boolean,
  showNudgeCheckbox?: boolean,
  onNotesUpdate?: (updatedNotes: Note[]) => Promise<void>,
}

type RenderedNotes = {
  notes: Note[],
  hasMore: boolean,
};

const NotesSection: React.FC<NotesSectionProps> = ({
  canCreate = true,
  canEdit = true,
  canDelete = true,
  showNudgeCheckbox = false,
  notes = [],
  onNotesUpdate,
}) => {
  const { user, mainUserRole, userIsAdmin } = useRBAC();
  const { opened: areAllNotesShown, toggle: showAllNotes } = useToggle(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  const deleteNoteModal = useModal<{ note: Note }>();

  const onDeleteClick = React.useCallback((noteToDelete: Note) => () => {
    deleteNoteModal.open({ note: noteToDelete });
  }, [deleteNoteModal]);

  const handleUpsert = React.useCallback(async (updatedNote: Note) => {
    // the updated note goes to the top otherwise the old ones remain as is
    const updatedNotes = uniqueByField([updatedNote, ...notes], 'date');
    await onNotesUpdate?.(updatedNotes);
  }, [notes, onNotesUpdate]);

  const handleDelete = React.useCallback((noteToDelete: Note) => () => {
    const updatedNotes = notes.filter((note) => note.date !== noteToDelete.date);
    onNotesUpdate?.(updatedNotes);
    deleteNoteModal.close();
  }, [deleteNoteModal, notes, onNotesUpdate]);

  const renderedNotes: RenderedNotes = React.useMemo(() => {
    const sortedNotes = notes.sort((b, a) => Date.parse(a.date!) - Date.parse(b.date!));

    if (!isMobile || areAllNotesShown) {
      return {
        notes: sortedNotes,
        hasMore: sortedNotes.length > MAX_SHOWN_NOTES,
      };
    }

    return {
      notes: sortedNotes.slice(0, MAX_SHOWN_NOTES),
      hasMore: sortedNotes.length > MAX_SHOWN_NOTES,
    };
  }, [areAllNotesShown, isMobile, notes]);

  return (
    <>
      <Card>
        <Card.Header
          icon={{ path: ICON_TEXT_BOX_OUTLINE }}
          title="Note"
          primaryActions={[
            canCreate && !isMobile && (
              <UpsertNoteAction
                showNudgeCheckbox={showNudgeCheckbox}
                upsertNote={handleUpsert}
              >
                {({ upsert }) => (
                  <Action
                    label="Aggiungi"
                    size="S"
                    aria-label="Aggiungi nuova nota"
                    onClick={upsert}
                  />
                )}
              </UpsertNoteAction>
            ),
          ]}
          secondaryActions={[
            canCreate && isMobile && (
              <UpsertNoteAction
                showNudgeCheckbox={showNudgeCheckbox}
                upsertNote={handleUpsert}
              >
                {({ upsert }) => (
                  <Action
                    label="Aggiungi"
                    size="S"
                    aria-label="Aggiungi nuova nota"
                    onClick={upsert}
                  />
                )}
              </UpsertNoteAction>
            ),
          ]}
        />

        <Card.Content>
          {isMobile && renderedNotes.notes.length === 0 && (
            <Message
              type="neutral"
              message="Non sono presenti note"
            />
          )}

          {isMobile && renderedNotes.notes.length > 0 && (
            <Stack>
              {renderedNotes.notes.map((note, i) => (
                <Card
                  key={objectHash({ note, i })}
                  aria-label="Informazioni nota (Mobile)"
                >
                  <Card.Header
                    secondaryActions={((note.author === user.name && note.role === mainUserRole) || userIsAdmin)
                      ? [
                        canEdit && (
                          <UpsertNoteAction
                            showNudgeCheckbox={showNudgeCheckbox}
                            upsertNote={handleUpsert}
                            note={note}
                          >
                            {({ upsert }) => (
                              <Dropdown.Option
                                label="Modifica"
                                aria-label="Pulsante per modificare una nota"
                                onClick={upsert}
                              />
                            )}
                          </UpsertNoteAction>
                        ),
                        canDelete && (
                          <Dropdown.Option
                            label="Elimina"
                            aria-label="Pulsante per eliminare una nota"
                            onClick={onDeleteClick(note)}
                          />
                        ),
                      ] : undefined}
                  >
                    <Stack>
                      <Text.Mini transform="uppercase">
                        Nota
                      </Text.Mini>
                      <Text.H4>
                        {note.date ? formatDate(new Date(note.date)) : NO_VALUE_SYMBOL}
                      </Text.H4>
                    </Stack>
                  </Card.Header>

                  <Card.Content>
                    <NoteAuthor note={note} />
                    <Spacing margin={[150, 0, 0]} />
                    <Text.BodySmall>
                      <MultilineContent maxLines={2}>
                        {note.text}
                      </MultilineContent>
                    </Text.BodySmall>
                  </Card.Content>
                </Card>
              ))}
            </Stack>
          )}
          {!isMobile && (
            <>
              {renderedNotes.notes.length === 0 && (
                <Message
                  type="neutral"
                  message="Non sono presenti note"
                />
              )}
              {renderedNotes.notes.length > 0 && (
                <SimpleTable aria-label="Tabella delle note">
                  <SimpleTable.Body>
                    {renderedNotes.notes.map((note, i) => (
                      <SimpleTable.Row
                        key={objectHash({ note, i })}
                        asChild
                      >
                        <ViewNoteTableRow
                          note={note}
                          canEdit={canEdit}
                          canDelete={canDelete}
                          onDeleteClick={onDeleteClick}
                          onEditClick={handleUpsert}
                        />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </>
          )}
        </Card.Content>
        {isMobile && renderedNotes.hasMore && (
          <Card.Content>
            <Action
              size="S"
              label={areAllNotesShown ? 'Vedi meno' : 'Vedi tutte'}
              onClick={showAllNotes}
            />
          </Card.Content>
        )}
      </Card>
      <Portal>
        {deleteNoteModal.data && (
          <ConfirmModal
            {...deleteNoteModal}
            title="Conferma eliminazione nota"
            variant="critical"
            dismissable={false}
            onConfirm={handleDelete(deleteNoteModal.data.note)}
            onAbort={() => deleteNoteModal.close()}
            aria-label="Modale per eliminare una nota"
          >
            <Text.Body>
              Confermando l&apos;azione, la nota sarà eliminata e i dati non potranno essere recuperati.
            </Text.Body>
          </ConfirmModal>
        )}
      </Portal>
    </>
  );
};

export default NotesSection;
