import {
  Action,
  FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import React, { MutableRefObject } from 'react';
import { Agency } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { MembershipStatus } from '../../../domain/types';
import { calculateMembershipStatus } from '../../../agent/utils/calculateMembershipStatus';
import EditMembershipContractForm, { EditMembershipContractFormModel } from '../../../agent/components/edit-membership-contract-from/EditMembershipContractForm';
import { editMembershipContractFormModelToAgency } from './mappers';
import { updateAgency } from '../../../providers/api/agency/agencyProvider';

export interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface EditAgencyContractActionProps {
  agency: Agency,
  onSuccess?: (updatedAgency: Agency) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_AGENCY_CONTRACT_SUCCESS_MESSAGE = "La contrattualistica dell'agenzia è stata modificata con successo";
export const EDIT_AGENCY_CONTRACT_ERROR_MESSAGE = "Non è stato possibile modificare la contrattualistica dell'agenzia";

const EditAgencyContractAction: React.FC<EditAgencyContractActionProps> = (props) => {
  const { agency, onSuccess, children } = props;

  const { userIsHR } = useRBAC();
  const editAgencyContractModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const formRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;
  const [isSaving, setIsSaving] = React.useState(false);

  const openEditAgencyContractModal = React.useCallback(() => {
    editAgencyContractModal.open();
  }, [editAgencyContractModal]);

  const agencyStatus = React.useMemo(() => calculateMembershipStatus(
    new Date(agency.startDate),
    agency.endDate ? new Date(agency.endDate) : undefined,
  ), [agency.endDate, agency.startDate]);

  const disabledFields: Array<keyof EditMembershipContractFormModel> = React.useMemo(() => (
    agencyStatus === MembershipStatus.REVOKED && userIsHR
      ? ['companyName', 'role', 'vatNumber']
      : []
  ), [agencyStatus, userIsHR]);

  const submitForm = React.useCallback(() => formRef.current?.handleSubmit(), []);

  const onSubmit = React.useCallback(async (formValues: EditMembershipContractFormModel) => {
    try {
      setIsSaving(true);

      const agencyToUpdate = editMembershipContractFormModelToAgency(agency, formValues);

      const updatedAgency = await updateAgency(agency.id!, agencyToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_AGENCY_CONTRACT_SUCCESS_MESSAGE);

      editAgencyContractModal.close();

      onSuccess?.(updatedAgency);
    } catch (err) {
      setIsSaving(false);
      addError(EDIT_AGENCY_CONTRACT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agency, editAgencyContractModal, onSuccess]);

  return (
    <>
      {
        children
          ? children({ openEditModal: openEditAgencyContractModal, isSaving })
          : (
            <Action
              aria-label="Modifica la contrattualistica dell'agenzia"
              label="Modifica"
              size="S"
              onClick={openEditAgencyContractModal}
              loading={isSaving}
            />
          )
      }
      <Portal>
        <SimpleModal
          {...editAgencyContractModal}
          title="Modifica contrattualistica"
          aria-label="Modale per modificare la contrattualistica dell'agenzia"
          footer={(
            <Action
              label="Modifica"
              aria-label="Modifica i dati"
              color="primary"
              emphasis="high"
              onClick={submitForm}
            />
          )}
        >
          <EditMembershipContractForm
            onSubmit={onSubmit}
            initialValues={{
              ...agency.legal,
              ...agency.bankAccount,
            }}
            disabledFields={disabledFields}
            innerRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAgencyContractAction;
