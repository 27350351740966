import { AgentStatus, DescendantsStrategy, JobTitle } from '../../../domain/types';
import {
  getClient, API_AGENTS_PATH, API_PROPERTIES_PATH,
} from '../api';
import { AgentArea, Agent } from '../dtos';
import {
  PaginationParams, serializePagination, PaginatedResult,
} from '../../pagination';
import { serializeParams } from '../utils/serializer';

export interface AgentsProviderFilters {
  active?: AgentStatus,
  jobTitle?: JobTitle | JobTitle[],
  agencyId?: number | number[],
  coordinatedBy?: string,
}

export interface AgentSearchParams extends AgentsProviderFilters {
  q: string,
  startFrom?: string | number,
  exclude?: string | string[],
}

export interface GetAgentOptions {
  descendantsStrategy?: DescendantsStrategy,
}

export async function getAgents(
  filters: AgentsProviderFilters = {},
  pagination: PaginationParams = { size: 50 },
  options: GetAgentOptions = {},
): Promise<PaginatedResult<Agent>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Agent>>(
    API_AGENTS_PATH,
    {
      params: {
        ...serializeParams(filters),
        ...serializePagination(pagination),
        ...serializeParams(options),
      },
    },
  );

  return data;
}

export async function createAgent(agent: Agent): Promise<Agent> {
  const client = await getClient();
  const { data } = await client.post<Agent>(API_AGENTS_PATH, agent);
  return data;
}

export async function updateAgent(idOrEmail: number | string, agent: Agent): Promise<Agent> {
  const client = await getClient();
  const { data } = await client.put<Agent>(`${API_AGENTS_PATH}/${idOrEmail}`, agent);
  return data;
}

export async function getAgent(idOrEmail: number | string, options: GetAgentOptions = {}): Promise<Agent> {
  const client = await getClient();
  const { data } = await client.get<Agent>(`${API_AGENTS_PATH}/${idOrEmail}`, { params: { ...serializeParams(options) } });
  return data;
}

export async function getAgentByPropertyId(propertyId: number): Promise<Agent> {
  const client = await getClient();
  const { data } = await client.get(`${API_PROPERTIES_PATH}/${propertyId}/agent`);

  return data;
}

export async function getAgentAreasByMunicipalityCodes(municipalityCodes?: string[]): Promise<AgentArea[]> {
  const client = await getClient();
  const { data } = await client.get(`${API_AGENTS_PATH}/areas`, {
    params: {
      ...serializeParams({ municipalityCode: municipalityCodes ? municipalityCodes.join(',') : undefined }),
    },
  });

  return data;
}

export async function getAgentAreasByAgentId(id: number): Promise<AgentArea[]> {
  const client = await getClient();
  const { data } = await client.get(`${API_AGENTS_PATH}/${id}/areas`);

  return data;
}

export async function createOrUpdateAgentAreas(id: number, agentAreas: AgentArea[]): Promise<AgentArea[]> {
  const client = await getClient();
  const { data } = await client.put(`${API_AGENTS_PATH}/${id}/areas`, agentAreas);

  return data;
}

export async function deleteAgentAreas(ids: number[]): Promise<void> {
  const client = await getClient();
  await client.delete(`${API_AGENTS_PATH}/areas`, {
    params: {
      ids,
    },
  });
}

export async function searchAgents(
  params: AgentSearchParams,
  pagination: PaginationParams = { size: 50 },
  options: GetAgentOptions = {},
): Promise<PaginatedResult<Agent>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Agent>>(`${API_AGENTS_PATH}/search`, {
    params: {
      q: params.q,
      ...serializeParams(params),
      ...serializePagination(pagination),
      ...serializeParams(options),
    },
  });

  return data;
}

export async function generateAgentBrochure(id: number): Promise<ArrayBuffer> {
  const client = await getClient();
  const { data } = await client.get(`${API_AGENTS_PATH}/${id}/generate-brochure`, {
    responseType: 'arraybuffer',
  });
  return data;
}
