import { JobTitle } from '../../../domain/types';
import {
  Applicant, Hire, JobApplication,
} from '../../../providers/api/dtos';
import { HireApplicantFormModel } from '../../components/hire-applicant-form/HireApplicantForm';

export const getInitialValuesFromApplicant = (applicant: Applicant): HireApplicantFormModel => {
  const nameWords = applicant.name.trim().split(' ').filter((word) => word !== '');
  const name = nameWords[0];
  const surname = nameWords.slice(1).join(' ');

  return {
    name,
    surname,
    email: applicant.email || '',
    mobile: applicant.phoneNumber || '',
    role: '',
    startDate: '',
    inTrial: false,
  };
};

export const applicantToHire = (hireApplicantFormModel: HireApplicantFormModel, jobApplication: JobApplication): Hire => ({
  name: hireApplicantFormModel.name,
  surname: hireApplicantFormModel.surname,
  email: hireApplicantFormModel.email,
  phoneNumber: hireApplicantFormModel.mobile,
  jobApplicationId: jobApplication.id!,
  jobTitle: hireApplicantFormModel.role as JobTitle,
  startDate: hireApplicantFormModel.startDate,
  hasLicense: hireApplicantFormModel.role !== JobTitle.CONSULENTE,
  needsSupervisor: hireApplicantFormModel.role === JobTitle.CONSULENTE,
});
