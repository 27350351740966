import {
  ThemedFunctor, getTypography, getSpacing, TypographyName, SpacingName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = () => css`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const name: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.BODY)}
  ${getSpacing.marginLeft(theme, SpacingName.SMALLER)}
  font-weight: bold;
  color: currentColor;
`;

export const avatar: ThemedFunctor = ({ theme, $image }) => css`
  width: 2rem;
  height: 2rem;
  background: ${theme.palettes.neutrals.light};
  border-radius: 100%;
  overflow: hidden;
  flex-shrink: 0;
  background-image: url("${$image}");
  background-size: cover;
  background-position: center center;
`;

export const loading: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.paddingRight(theme, SpacingName.SMALL)}
  display: flex;
  align-items: center;
  height: 100%;
`;

export const user: ThemedFunctor = () => css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const logout: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.paddingLeft(theme, SpacingName.SMALL)}
`;
