import { Property, Showcase } from '../dtos';
import { getClient, API_PROPERTIES_PATH } from '../api';

export async function generateShowcase(propertyId: NonNullable<Property['id']>, showcase: Showcase): Promise<ArrayBuffer> {
  const client = await getClient();
  const { data } = await client.post<ArrayBuffer>(`${API_PROPERTIES_PATH}/${propertyId}/showcase`, showcase, {
    responseType: 'arraybuffer',
  });
  return data;
}
