/* eslint-disable react/no-unknown-property */
import React, {
  FunctionComponent, ReactNode, RefObject,
} from 'react';
import ContextualNavigation, { ContextualNavigationItem } from '../../../components/contextual-navigation/ContextualNavigation';
import * as styles from './GenericPageLayoutTabs.style';

export interface GenericPageLayoutTabsProps {
  items: ContextualNavigationItem[],
  innerRef?: RefObject<HTMLDivElement>
  children?: ReactNode,
}

const GenericPageLayoutTabs: FunctionComponent<GenericPageLayoutTabsProps> = ({
  items,
  innerRef,
  children,
}) => (
  <div
    css={styles.tabs}
    ref={innerRef}
  >
    <ContextualNavigation items={items} />
    {children}
  </div>
);

export default GenericPageLayoutTabs;
