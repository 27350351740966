import { ThemedFunctor, type TokenRemSize } from '@doveit/bricks';
import { css } from 'styled-components';
import type { DotProps } from './Dot';

const sizeMapper: Record<NonNullable<DotProps['size']>, keyof TokenRemSize> = {
  S: 100,
  M: 150,
};

export const dot: ThemedFunctor = ({ theme, $size }) => {
  const remSize = theme.remSize[sizeMapper[$size as NonNullable<DotProps['size']>]];

  return css`
    background-color: ${theme.color.foreground.primary.default.low};
    height: ${remSize};
    width: ${remSize};
    border-radius: ${remSize};
  `;
};
