import useSWR, { SWRResponse } from 'swr';
import hash from 'object-hash';
import { AgentArea } from '../../../providers/api/dtos/agentArea';
import { getAreaSuggestions } from '../../../providers/public-api/location/locationProvider';
import { AreaType } from '../../../domain/types';
import { getAgentAreasByMunicipalityCodes } from '../../../providers/api/agent/agentProvider';
import { slugIt } from '../../../utils/string';

export interface CoveredAgentAreaParams {
  postalCode: string,
  locality: string,
}

export interface CoveredAgentAreaResult {
  covered?: AgentArea;
}

export const COVERED_AGENT_AREA_RID = '/agents/area/covered';

export default function useCoveredAgentArea(params: CoveredAgentAreaParams | null): SWRResponse<CoveredAgentAreaResult, Error> {
  return useSWR(params ? [COVERED_AGENT_AREA_RID, hash(params)] : null, async () => {
    const { postalCode, locality } = params!;
    const areas = await getAreaSuggestions(postalCode, AreaType.ZIP_CODE);
    const municipalityCodes = areas.map((item) => item.municipalityCodes).reduce((accumulator:string[], item) => accumulator.concat(item), []);
    const agentAreas = municipalityCodes.length > 0 ? await getAgentAreasByMunicipalityCodes(municipalityCodes) : [];

    const covered = agentAreas
      .filter((area) => slugIt(area.locality) === slugIt(locality ?? ''))
      .filter((area) => area.zipCode === postalCode)[0];

    return { covered } as CoveredAgentAreaResult;
  });
}
