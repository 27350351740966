import * as Yup from 'yup';

export default Yup.object().shape({
  agent: Yup
    .object({
      id: Yup.number().required('È necessario selezionare un agente'),
    })
    .nullable(),
  description: Yup.string().required('La descrizione è richiesta'),
});
