import { OwnershipType } from '../domain/types';

export const ownershipTypeLabels: {
  [key in OwnershipType]: string
} = {
  INTERA_PROPRIETA: 'Intera proprietà',
  NUDA_PROPRIETA: 'Nuda proprietà',
  PARZIALE_PROPRIETA: 'Parziale proprietà',
  USUFRUTTO: 'Usufrutto',
  MULTIPROPRIETA: 'Multiproprietà',
  DIRITTO_DI_SUPERFICIE: 'Diritto di superficie',
};
