import React from 'react';
import {
  Action, BreakpointQueryName, DetailItemList, FormHandlers, ICON_ACCOUNT_OUTLINE, ICON_EYE_OUTLINE, ICON_PHONE_OUTLINE, Message, Spacing, Stack,
} from '@doveit/bricks';
import objectHash from 'object-hash';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { AssignmentWizardStepProps } from '../types';
import useAssignmentWizardPDFPreview from '../../../../../prospect/hooks/use-assignment-wizard-pdf-preview/useAssignmentWizardPDFPreview';
import { raise } from '../../../../../utils';
import PDFViewer from '../../../../../components/pdf-viewer/PDFViewer';
import CardSkeleton from '../../../../../components/card/skeleton/CardSkeleton';

export const NO_SIGNERS_INFO_MESSAGE = 'Non sono presenti firmatari';
export const LOAD_PDF_PREVIEW_ERROR_MESSAGE = "Non è stato possibile caricare l'anteprima del mandato";

const AssignmentWizardDigitalRequest: React.FC<AssignmentWizardStepProps> = ({
  initialData,
  prospect,
  formRef,
  onSubmit,
  handleStepWorkComplete,
}) => {
  const { id } = prospect;
  const { contacts = [] } = initialData;
  const prospectId = id ?? raise('missing prospect id');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  const {
    data: preview,
    isLoading: isPreviewLoading,
    error: previewError,
  } = useAssignmentWizardPDFPreview(prospectId, handleStepWorkComplete);

  const previewUrl = React.useMemo(() => {
    if (!preview) {
      return '';
    }

    return URL.createObjectURL(new Blob([preview], { type: 'application/pdf' }));
  }, [preview]);

  React.useImperativeHandle(formRef, () => ({
    handleSubmit: (_: React.FormEvent<HTMLFormElement>) => {
      onSubmit({
        ...initialData,
        isDigitallySigned: true,
      }, false);
    },
  } as FormHandlers), [initialData, onSubmit]);

  return (
    <Stack>
      <div aria-label="Firmatari del mandato">
        <strong>Elenco firmatari</strong>

        <Spacing margin={[200, 0, 0, 0]}>
          {contacts.length === 0 && (
            <Message message={NO_SIGNERS_INFO_MESSAGE} />
          )}
          {contacts.length > 0 && (
            <Stack aria-label="Elenco dei firmatari del mandato">
              {contacts.map((contact) => (
                <Spacing
                  key={objectHash(contact)}
                  margin={[0, 0, 200, 0]}
                >
                  <DetailItemList>
                    <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
                      {contact.name}
                    </DetailItemList.Item>
                    <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
                      {contact.phoneNumber}
                    </DetailItemList.Item>
                  </DetailItemList>
                </Spacing>
              ))}
            </Stack>
          )}
        </Spacing>
      </div>

      <Spacing margin={[200, 0, 0, 0]}>
        {previewError && (
          <Message
            type="critical"
            message={LOAD_PDF_PREVIEW_ERROR_MESSAGE}
          />
        )}
        {isPreviewLoading && <CardSkeleton aria-label="Anteprima del mandato in caricamento" />}
        {preview && (
          <div aria-label="Anteprima del mandato">
            <strong>Anteprima mandato</strong>

            <Spacing margin={[200, 0, 0, 0]}>

              {(isMobile ? (
                <Action
                  label="Visualizza anteprima"
                  aria-label="Visualizza anteprima mandato"
                  size="S"
                  iconLeft={{ path: ICON_EYE_OUTLINE }}
                  href={previewUrl}
                  target="_blank"
                />
              ) : (
                <PDFViewer
                  src={previewUrl}
                  title="Anteprima mandato"
                  height="50rem"
                />
              ))}
            </Spacing>
          </div>
        )}
      </Spacing>
    </Stack>
  );
};

export default AssignmentWizardDigitalRequest;
