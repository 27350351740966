import React, { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HStack } from '@doveit/bricks';
import StartContactProcess from '../../containers/start-contact-process/StartContactProcess';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import ContactTopBar from '../../components/contact-top-bar/ContactTopBar';
import { PageType } from '../../types';
import { ProcessableContact } from '../../../providers/api/dtos';

const CallCenterContactProcessPage: FunctionComponent = () => {
  const navigate = useNavigate();

  const gotoContact = useCallback((processableContact?: ProcessableContact) => {
    if (processableContact) {
      navigate(`/call-center/contacts/process/${processableContact.id}?correlation_id=${processableContact.correlationId}`);
    }
  }, [navigate]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Bar>
        <ContactTopBar page={PageType.PROCESS_WELCOME} />
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <HStack justifyContent="center">
          <StartContactProcess
            onNextContact={gotoContact}
          />
        </HStack>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default CallCenterContactProcessPage;
