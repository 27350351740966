import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const wrapper: ThemedFunctor = () => css`
  min-width: 18rem;
`;

export const features: ThemedFunctor = () => css`
  display: flex;
`;

export const type: ThemedFunctor = () => css`
  max-width: 7.5rem;
`;

export const feature: ThemedFunctor = ({ theme }) => css`
  padding: 0 ${theme.remSize[100]};
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${theme.color.border.neutral.default.low};
  gap: ${theme.remSize[25]};
`;
