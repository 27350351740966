import {
  ActionIcon, ICON_PENCIL_OUTLINE, Message, Portal, Spacing, useModal, useNotifications,
} from '@doveit/bricks';
import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { PropertyContact, PropertyContactReference } from '../../../providers/api/dtos';
import { updatePropertyContact } from '../../../providers/api/property-contact/propertyContactProvider';
import PropertyContactForm, { PropertyContactFormModel } from '../../components/property-contact-form/PropertyContactForm';

export interface ChildFnProps {
  onEditAction: VoidFunction
}

export interface EditPropertyContactProps {
  propertyContact: PropertyContact,
  onSuccess?: (propertyContact: PropertyContact) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const PROPERTY_CONTACT_EDIT_ERROR_MESSAGE = 'Non è stato possibile modificare il referente';
export const PROPERTY_CONTACT_EDIT_SUCCESS_MESSAGE = 'La modifica è stata eseguita con successo';
export const UPDATE_CONTACT_MESSAGE = 'Se vuoi modificare il contatto <u>clicca qui<u>';

const EditPropertyContact: FunctionComponent<EditPropertyContactProps> = ({
  propertyContact,
  onSuccess,
  children,
}) => {
  const navigate = useNavigate();
  const editPropertyContactModal = useModal();
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const initialValues: PropertyContactFormModel = useMemo(() => ({
    relationship: propertyContact.relationship,
  }), [propertyContact.relationship]);

  const onViewPropertyContact = useCallback(() => {
    navigate(`/contacts/${propertyContact.contact.id}`);
  }, [navigate, propertyContact.contact.id]);

  const onEditAction = useCallback(() => {
    editPropertyContactModal.open();
  }, [editPropertyContactModal]);

  const onSubmit = useCallback(async (formValues: PropertyContactFormModel) => {
    setIsSaving(true);

    try {
      const propertyContactReferenceToUpdate: PropertyContactReference = {
        contactId: propertyContact.contact.id!,
        propertyId: propertyContact.property.id!,
        relationship: formValues.relationship!,
      };

      const updatedPropertyContactReference = await updatePropertyContact(propertyContactReferenceToUpdate);

      setIsSaving(false);
      addSuccess(PROPERTY_CONTACT_EDIT_SUCCESS_MESSAGE);
      editPropertyContactModal.close();

      if (onSuccess) {
        onSuccess({
          ...propertyContact,
          relationship: updatedPropertyContactReference.relationship,
        });
      }
    } catch (err) {
      addError(PROPERTY_CONTACT_EDIT_ERROR_MESSAGE);
      setIsSaving(false);
    }
  }, [addError, addSuccess, editPropertyContactModal, onSuccess, propertyContact]);

  return (
    <>
      {children
        ? children({ onEditAction })
        : (
          <ActionIcon
            label="Modifica referente"
            icon={{ path: ICON_PENCIL_OUTLINE }}
            size="S"
            onClick={onEditAction}
            data-ref="edit-property-contact-action"
            aria-label="Pulsante per modificare il contatto di un immobile"
          />
        )}
      <Portal>
        <SimpleModal
          {...editPropertyContactModal}
          title="Modifica referente"
        >
          <Message
            message={UPDATE_CONTACT_MESSAGE}
            onClick={onViewPropertyContact}
          />
          <Spacing margin={[400, 0, 0]}>
            <PropertyContactForm
              submitLabel="Modifica"
              initialValues={initialValues}
              loading={isSaving}
              onSubmit={onSubmit}
            />
          </Spacing>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditPropertyContact;
