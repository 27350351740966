/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, ReactNode, RefObject } from 'react';
import * as styles from './GenericPageLayoutInnerContent.style';

export interface GenericPageLayoutInnerContentProps {
  innerRef?: RefObject<HTMLDivElement>,
  children?: ReactNode,
}

const GenericPageLayoutInnerContent: FunctionComponent<GenericPageLayoutInnerContentProps> = ({
  innerRef,
  children,
}) => (
  <div
    css={styles.content}
    ref={innerRef}
  >
    {children}
  </div>
);

export default GenericPageLayoutInnerContent;
