import React from 'react';
import {
  Form,
  Spacing,
  Message,
  CheckboxButton,
  FormHandlers,
  FormProps,
} from '@doveit/bricks';
import {
  AirConditioningMode,
  AirConditioningType,
  FurnitureType, HeatingSupply, HeatingSystem, HeatingType, OwnershipType, PropertyClassType, Status, PropertyType, RegistryCategory, RegistryStatus, TV, WindowExposure,
  PropertyStatus,
  FeatureType,
  ResidentialPropertyTypeValue,
  EnergyClass,
} from '../../../domain/types';
import { GeoFormModel } from '../../geo/models/geoModel';
import { PropertyGeoFieldNames, renderPropertyGeoFields } from './geo/renderPropertyGeoFields';
import ContextualNavigation, { ContextualNavigationItem } from '../../../components/contextual-navigation/ContextualNavigation';
import { editPropertyInfoValidationSchema } from './EditPropertyInfoForm.schema';
import { isApartmentAlike } from '../../utils';
import { DEFAULT_PROPERTY_GEO_FORM_VALUES } from './geo/constants';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import {
  airConditioningModeLabels,
  airConditioningTypeLabels,
  energyClassLabels,
  furnitureTypeLabels,
  heatingSupplyLabels,
  heatingSystemLabels,
  ownershipTypeLabels,
  propertyClassTypeLabels,
  propertyStatusLabels,
  propertyTypeLabels,
  propertyWindowExposureLabels,
  registryStatusLabels,
  tvLabels,
} from '../../../labels';
import { registryCategoryLabels } from '../../../labels/registryCategoryLabels';
import { featureTypeLabels, heatingTypeLabels } from '../../labels';
import { flattenObject } from '../../../utils/object/object';

export interface EditPropertyInfoFormModel {
  enableGeoOverride: boolean,
  status: Status | '',
  geo: GeoFormModel,
  overriddenGeo: GeoFormModel,
  propertyType: PropertyType | '',
  ownershipType: OwnershipType | '',
  propertySize: string,
  walkableSize: string,
  energyClass: EnergyClass | '',
  energyValue: string,
  yearOfDevelopment: string,
  registryCategory: RegistryCategory | '',
  registryStatus: RegistryStatus | '',
  propertyStatus: PropertyStatus | '',
  privateGarden: string,
  additionalExpenses: string,
  furniture: FurnitureType | '',
  windowExposure: WindowExposure | '',
  tv: TV | '',
  price: string,
  minimumPrice: string,
  evaluation: string,
  mainFloor: string,
  buildingFloors: string,
  heatingType: HeatingType | '',
  heatingSystem: HeatingSystem | '',
  heatingSupply: HeatingSupply | '',
  airConditioningType: AirConditioningType | '',
  airConditioningMode: AirConditioningMode | '',
  features: FeatureType[],
  isBannerPlaced: boolean,
  bannerNotes: string,
  classType: PropertyClassType | '',
}

export interface EditPropertyInfoFormProps {
  initialValues?: Partial<EditPropertyInfoFormModel>,
  loading?: FormProps<EditPropertyInfoFormModel>['loading'],
  disabled?: FormProps<EditPropertyInfoFormModel>['disabled'],
  innerRef?: React.MutableRefObject<FormHandlers>,
  onSubmit: FormProps<EditPropertyInfoFormModel>['onSubmit'],
}

export const OVERRIDE_GEO_WARNING_MESSAGE = 'Nel caso non si voglia pubblicizzare l\'indirizzo reale inserisci qui l\'indirizzo da visualizzare sui portali.';

function geoFieldNames(prefix: 'geo' | 'overriddenGeo'): PropertyGeoFieldNames {
  return {
    geoCodeResult: `${prefix}.geoCodeResult`,
    administrativeAreaLevelOne: `${prefix}.administrativeAreaLevelOne`,
    administrativeAreaLevelTwo: `${prefix}.administrativeAreaLevelTwo`,
    locality: `${prefix}.locality`,
    postalCode: `${prefix}.postalCode`,
    plateCode: `${prefix}.plateCode`,
    route: `${prefix}.route`,
    streetNumber: `${prefix}.streetNumber`,
    suburb: `${prefix}.suburb`,
    latitude: `${prefix}.latitude`,
    longitude: `${prefix}.longitude`,
  };
}

export const EDIT_PROPERTY_INFO_FORM_DEFAULT_VALUES: EditPropertyInfoFormModel = {
  enableGeoOverride: false,
  status: '',
  geo: DEFAULT_PROPERTY_GEO_FORM_VALUES,
  overriddenGeo: DEFAULT_PROPERTY_GEO_FORM_VALUES,
  propertyType: '',
  ownershipType: '',
  propertySize: '',
  walkableSize: '',
  energyClass: '',
  energyValue: '',
  yearOfDevelopment: '',
  registryCategory: '',
  registryStatus: '',
  propertyStatus: '',
  privateGarden: '',
  additionalExpenses: '',
  furniture: '',
  windowExposure: '',
  tv: '',
  price: '',
  minimumPrice: '',
  evaluation: '',
  mainFloor: '',
  buildingFloors: '',
  heatingType: '',
  heatingSystem: '',
  heatingSupply: '',
  airConditioningType: '',
  airConditioningMode: '',
  features: [],
  isBannerPlaced: false,
  bannerNotes: '',
  classType: '',
};

const EditPropertyInfoForm: React.FC<EditPropertyInfoFormProps> = ({
  initialValues,
  ...rest
}) => {
  const [activeGeoTab, setActiveGeoTab] = React.useState<'geo' | 'overriddenGeo'>('overriddenGeo');
  const [selectedPropertyType, setSelectedPropertyType] = React.useState(initialValues?.propertyType || '');

  const geoSectionTabItems: ContextualNavigationItem[] = React.useMemo(() => [
    {
      id: 'overriddenGeo',
      label: 'Indirizzo di pubblicazione',
      active: activeGeoTab === 'overriddenGeo',
      onClick: () => setActiveGeoTab('overriddenGeo'),
    },
    {
      id: 'geo',
      label: 'Indirizzo reale',
      active: activeGeoTab === 'geo',
      onClick: () => setActiveGeoTab('geo'),
    },
  ], [activeGeoTab]);

  const mergedInitialValues = React.useMemo(() => ({
    ...EDIT_PROPERTY_INFO_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  const validationType = React.useMemo(() => {
    if ((Object.values(ResidentialPropertyTypeValue) as string[]).includes(selectedPropertyType)) {
      switch (mergedInitialValues.status) {
        case Status.LIVE:
        case Status.ANTEPRIMA:
        case Status.PROPOSTA:
          return 'residential-published';
        case Status.DRAFT:
        case Status.RITIRATO:
          return 'residential-not-published';
        case Status.VENDUTO:
          return 'residential-sold';
        default:
          return 'not-residential';
      }
    }

    return 'not-residential';
  }, [mergedInitialValues.status, selectedPropertyType]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={editPropertyInfoValidationSchema(validationType)}
      {...rest}
      enableReinitialize
    >
      {({
        values, handleChange, setFieldValue, errors, submitCount,
      }) => {
        const onPropertyTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          const propertyType = e.target.value as PropertyType;
          setSelectedPropertyType(propertyType);

          if (!isApartmentAlike(propertyType)) {
            setFieldValue('mainFloor', '', true);
          }
        };

        return (
          <>
            <Form.Item>
              <Form.CheckboxToggle
                name="enableGeoOverride"
                text="Inserisci un indirizzo diverso di pubblicazione"
                aria-label="Campo per attivare l'inserimento di un diverso indirizzo di pubblicazione"
                onChange={(e) => {
                  setActiveGeoTab('overriddenGeo');

                  if (!e.target.checked) {
                    setFieldValue('overriddenGeo', DEFAULT_PROPERTY_GEO_FORM_VALUES);
                  }
                }}
              />
              {values.enableGeoOverride && (
                <>
                  <ContextualNavigation items={geoSectionTabItems} />
                  {activeGeoTab === 'overriddenGeo' && (
                    <Spacing margin={[200, 0, 0]}>
                      <Message
                        type="warning"
                        message={OVERRIDE_GEO_WARNING_MESSAGE}
                      />
                    </Spacing>
                  )}
                </>
              )}
            </Form.Item>
            {!values.enableGeoOverride && (
              <Form.Fieldset legend="Geo">
                {renderPropertyGeoFields({
                  ...DEFAULT_PROPERTY_GEO_FORM_VALUES,
                  ...values.geo,
                }, handleChange, setFieldValue, geoFieldNames('geo'))}
              </Form.Fieldset>
            )}
            {values.enableGeoOverride && (
              <Form.Group>
                {activeGeoTab === 'overriddenGeo' && (
                  <>
                    {renderPropertyGeoFields({
                      ...DEFAULT_PROPERTY_GEO_FORM_VALUES,
                      ...values.overriddenGeo,
                    }, handleChange, setFieldValue, geoFieldNames('overriddenGeo'))}
                  </>
                )}
                {activeGeoTab === 'geo' && renderPropertyGeoFields({
                  ...DEFAULT_PROPERTY_GEO_FORM_VALUES,
                  ...values.geo,
                }, handleChange, setFieldValue, geoFieldNames('geo'))}
              </Form.Group>
            )}
            <Form.Fieldset legend="Dettagli">
              <Form.Group>
                <Form.Item size={{ MD: 4 / 10 }}>
                  <Form.Select
                    name="propertyType"
                    label="Tipologia"
                    options={asSelectOptions(propertyTypeLabels, {
                      value: '',
                      label: 'Seleziona',
                      disabled: true,
                    })}
                    aria-label="Campo per inserire la tipologia dell'immobile"
                    onChange={onPropertyTypeChange}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 4 / 10 }}>
                  <Form.Select
                    name="ownershipType"
                    label="Tipo proprietà"
                    options={asSelectOptions(ownershipTypeLabels, {
                      value: '',
                      label: 'Seleziona',
                      disabled: true,
                    })}
                    aria-label="Campo per inserire il tipo di proprietà dell'immobile"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 2 / 10 }}>
                  <Form.Select
                    name="classType"
                    label="Classe immobile"
                    options={asSelectOptions(propertyClassTypeLabels)}
                    aria-label="Campo per inserire la classe dell'immobile"
                  />
                </Form.Item>
              </Form.Group>
              <Form.Group>
                <Form.Item size={{ MD: 2 / 10 }}>
                  <Form.Select
                    name="registryCategory"
                    label="Categoria catastale"
                    options={asSelectOptions(registryCategoryLabels)}
                    aria-label="Campo per inserire la categoria catastale dell'immobile"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 2 / 10 }}>
                  <Form.Input
                    name="yearOfDevelopment"
                    label="Anno di costruzione"
                    aria-label="Campo per inserire l'anno di costruzione dell'immobile"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 2 / 10 }}>
                  <Form.Input
                    name="mainFloor"
                    label="Piano principale"
                    aria-label="Campo per inserire il piano principale dell'immobile"
                    disabled={!values.propertyType || !isApartmentAlike(values.propertyType)}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 2 / 10 }}>
                  <Form.Input
                    name="buildingFloors"
                    label="Piani totali"
                    aria-label="Campo per inserire i piani totali dell'immobile"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 2 / 10 }}>
                  <Form.Select
                    name="windowExposure"
                    label="Esposizione"
                    options={asSelectOptions(propertyWindowExposureLabels)}
                    aria-label="Campo per inserire l'esposizione dell'immobile"
                  />
                </Form.Item>
              </Form.Group>
              <Form.Item size={{ MD: 2 / 10 }}>
                <Form.Select
                  name="heatingType"
                  label="Riscaldamento"
                  options={asSelectOptions(heatingTypeLabels)}
                  aria-label="Campo per inserire il riscaldamento dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 2 / 10 }}>
                <Form.Select
                  name="heatingSystem"
                  label="Tipologia riscaldamento"
                  options={asSelectOptions(heatingSystemLabels)}
                  aria-label="Campo per inserire la tipologia di riscaldamento dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 2 / 10 }}>
                <Form.Select
                  name="heatingSupply"
                  label="Alimentazione"
                  options={asSelectOptions(heatingSupplyLabels)}
                  aria-label="Campo per inserire la tipologia di alimentazione del riscaldamento dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 2 / 10 }}>
                <Form.Select
                  name="airConditioningType"
                  label="Impianto climatizzazione"
                  options={asSelectOptions(airConditioningTypeLabels)}
                  aria-label="Campo per inserire l'impianto di climatizzazione dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 2 / 10 }}>
                <Form.Select
                  name="airConditioningMode"
                  label="Tipologia climatizzazione"
                  options={asSelectOptions(airConditioningModeLabels)}
                  aria-label="Campo per inserire la tipologia di climatizzazione dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Select
                  name="energyClass"
                  label="Classe energetica"
                  options={asSelectOptions(energyClassLabels)}
                  aria-label="Campo per la classe energetica dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Input
                  name="energyValue"
                  label="Indice prest. energetica"
                  aria-label="Campo per l'indice di prestazione energetica dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Select
                  name="propertyStatus"
                  label="Stato immobile"
                  options={asSelectOptions(propertyStatusLabels)}
                  aria-label="Campo per inserire lo stato dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Select
                  name="registryStatus"
                  label="Stato proprietà"
                  options={asSelectOptions(registryStatusLabels)}
                  aria-label="Campo per inserire lo stato di proprietà dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Input
                  name="walkableSize"
                  label="Dimensione calpestabile"
                  aria-label="Campo per inserire la dimensione calpestabile dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Input
                  name="propertySize"
                  label="Dimensione commerciale"
                  aria-label="Campo per inserire la dimensione commerciale dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 2 / 10 }}>
                <Form.Input
                  name="privateGarden"
                  label="Giardino privato"
                  aria-label="Campo per indicare la dimensione di un eventuale giardino privato dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Select
                  name="furniture"
                  label="Arredamento"
                  options={asSelectOptions(furnitureTypeLabels)}
                  aria-label="Campo per inserire l'arredamento dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Select
                  name="tv"
                  label="Allaccio TV"
                  options={asSelectOptions(tvLabels)}
                  aria-label="Campo per l'allaccio TV dell'immobile"
                />
              </Form.Item>
            </Form.Fieldset>
            <Form.Fieldset legend="Prezzo e spese">
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Input
                  name="price"
                  label="Prezzo"
                  aria-label="Campo per inserire il prezzo dell'immobile"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Input
                  name="minimumPrice"
                  label="Prezzo minimo accettato"
                  aria-label="Campo per inserire il prezzo minimo accettato dal proprietario"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Input
                  name="evaluation"
                  label="Valutazione agente"
                  aria-label="Campo per inserire la valutazione dell'agente"
                />
              </Form.Item>
              <Form.Item size={{ MD: 4 / 10 }}>
                <Form.Input
                  name="additionalExpenses"
                  label="Spese condominiali mensili"
                  aria-label="Campo per inserire le spese condominiali mensili dell'immobile"
                />
              </Form.Item>
            </Form.Fieldset>
            <Form.Fieldset legend="Caratteristiche">
              <Form.FieldArray
                name="features"
                render={({ push, remove }) => Object
                  .values(FeatureType)
                  .map((featureType) => (
                    <Form.Item
                      key={featureType}
                      size={{ XS: 1 / 2, MD: 1 / 4 }}
                    >
                      <CheckboxButton
                        text={featureTypeLabels[featureType]}
                        checked={values.features.includes(featureType)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            push(featureType);
                          } else {
                            remove(values.features.indexOf(featureType));
                          }
                        }}
                      />
                    </Form.Item>
                  ))}
              />
            </Form.Fieldset>
            <Form.Fieldset legend="Cartello vendesi">
              <Form.Group>
                <Form.Item>
                  <Form.Checkbox
                    name="isBannerPlaced"
                    text="Cartello posizionato"
                    aria-label="Checkbox per indicare se il cartello è stato posizionato"
                  />
                </Form.Item>
                <Form.Item>
                  <Form.TextArea
                    name="bannerNotes"
                    label="Note sul posizionamento"
                    aria-label="Campo per inserire eventuali note sul posizionamento del cartello"
                    rows={2}
                  />
                </Form.Item>
              </Form.Group>
            </Form.Fieldset>
            {submitCount > 0 && Object.keys(errors).length > 0 && (
              Object.entries(flattenObject(errors)).map(([key, message]) => (
                <Form.Item key={key}>
                  <Form.ErrorMessage message={`${message} (<a href="#${key}">Correggi</a>)`} />
                </Form.Item>
              ))
            )}
          </>
        );
      }}
    </Form>
  );
};

export default EditPropertyInfoForm;
