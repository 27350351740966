import React from 'react';
import {
  ActionIcon, Card, CardSkeleton, Dropdown, FontWeight, Grid, HStack, ICON_ALERT_CIRCLE_OUTLINE, ICON_EYE_OUTLINE, ICON_MAP_MARKER_OUTLINE, Message, Stack, Text,
  useNotifications,
  UserPreview,
  UserPreviewSkeleton,
} from '@doveit/bricks';
import { Intent, Note, Reminder } from '../../../providers/api/dtos';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import UpdateIntentStatus from '../update-intent-status/UpdateIntentStatus';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import ViewPropertyPopoverPreview from '../../../property/containers/view-property-popover-preview/ViewPropertyPopoverPreview';
import usePropertyPreview from '../../../property/hooks/use-property-preview/usePropertyPreview';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import useContact from '../../../contact/hooks/use-contact/useContact';
import useMarkIntentAsSeen from '../../../hooks/use-mark-intent-as-seen/useMarkIntentAsSeen';
import { updateIntent } from '../../../providers/api/intent/intentProvider';
import { buildWhatsappMessageSentNote } from '../../utils/buildWhatsappMessageSentNote';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import UpsertReminderAction from '../../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import UpsertNoteAction from '../../../notes/containers/UpsertNoteAction';
import { intentMessagesProvider } from '../../../containers/send-whatsapp-action/messages/intentMessagesProvider';

export const UPSERT_NOTE_ERROR_MESSAGE = 'Non è stato possibile aggiungere la nota';
export const UPSERT_NOTE_SUCCESS_MESSAGE = 'Nota aggiunta con successo';

export interface ViewContactIntentMobileCardProps extends React.AriaAttributes {
  intent: Intent,
  onIntentUpdate?: (intent: Intent) => void,
}

export const LOAD_OFFER_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare i dati.';

const ViewContactIntentMobileCard: React.FC<ViewContactIntentMobileCardProps> = ({
  intent,
  onIntentUpdate,
  ...rest
}) => {
  const { addSuccess, addError } = useNotifications();
  const {
    user, mainUserRole, userIsAdmin, userIsCallCenter,
  } = useRBAC();

  const { data: property, isLoading: isPropertyLoading, error: propertyError } = usePropertyPreview(intent.propertyId);
  const { data: agent, isLoading: isAgentLoading, error: agentError } = useAgentByPropertyId(property ? intent.propertyId : undefined);

  const { markAsSeen } = useMarkIntentAsSeen(agent?.id);
  const { data: contact } = useContact(intent.contactId);

  const isOwnerOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);
  const isAdminOrOwnerOrManager = React.useMemo(() => userIsAdmin || isOwnerOrManager,
    [isOwnerOrManager, userIsAdmin]);
  const isAdminOrCallCenterOrOwnerOrManger = React.useMemo(() => userIsAdmin || userIsCallCenter || isOwnerOrManager,
    [isOwnerOrManager, userIsAdmin, userIsCallCenter]);

  const reminderToCreate: Reminder = React.useMemo(() => ({
    agentId: agent?.id!,
    referenceId: intent.id!,
    referenceType: ReferenceType.INTENT,
    resolved: false,
  } as Reminder), [agent?.id, intent.id]);

  const goToIntentPage = React.useCallback(() => {
    goToDetailPage(intent.id!, ReferenceType.INTENT, true);
  }, [intent.id]);

  const handleReminderCreated = React.useCallback(async () => {
    await markAsSeen(intent, { onSuccess: onIntentUpdate });
  }, [intent, markAsSeen, onIntentUpdate]);

  const handleNoteCreated = React.useCallback(async (note: Note) => {
    if (!intent) return;

    try {
      let updatedIntent;

      updatedIntent = await updateIntent(intent.id!, {
        ...intent,
        notes: [note, ...(intent.notes || [])],
      });

      updatedIntent = await markAsSeen(updatedIntent);
      onIntentUpdate?.(updatedIntent);
      addSuccess(UPSERT_NOTE_SUCCESS_MESSAGE);
    } catch (err) {
      addError(UPSERT_NOTE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, intent, markAsSeen, onIntentUpdate]);

  const onMessageSent = React.useCallback(async (message: string) => {
    if (!intent) return;

    let updatedIntent;

    updatedIntent = await updateIntent(intent.id!, {
      ...intent,
      notes: [{
        text: buildWhatsappMessageSentNote(message),
        author: user.name,
        role: mainUserRole,
      }, ...(intent.notes || [])],
    });

    updatedIntent = await markAsSeen(updatedIntent);

    onIntentUpdate?.(updatedIntent);
  }, [intent, mainUserRole, markAsSeen, onIntentUpdate, user.name]);

  if (isPropertyLoading) {
    return (
      <CardSkeleton />
    );
  }

  if (propertyError) {
    return (
      <Card aria-label="Errore caricamento dati proposta">
        <Card.Header
          title="Proposte"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          color="critical"
        />
        <Card.Content>
          <Message message={LOAD_OFFER_DATA_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  return property! && (
    <Card {...rest}>
      <Card.Header
        primaryActions={[
          isAdminOrCallCenterOrOwnerOrManger && (
            <ActionIcon
              aria-label="Visualizza interesse"
              label="Visualizza"
              size="S"
              icon={{ path: ICON_EYE_OUTLINE }}
              onClick={goToIntentPage}
            />
          )]}
        secondaryActions={[
          isAdminOrOwnerOrManager && contact?.phoneNumber && (
            <SendWhatsappAction
              phoneNumber={contact.phoneNumber}
              name={contact.name}
              fetchMessages={intentMessagesProvider(intent, agent)}
              onMessageSent={onMessageSent}
            >
              {({ onClick }) => (
                <Dropdown.Option
                  label="Invia whatsapp"
                  onClick={onClick}
                />
              )}
            </SendWhatsappAction>
          ),
          isAdminOrOwnerOrManager && (
            <UpsertReminderAction
              reminder={reminderToCreate}
              onSuccess={handleReminderCreated}
              aria-label="Pulsante per aggiungere un promemoria"
            >
              {({ upsert }) => (
                <Dropdown.Option
                  label="Aggiungi promemoria"
                  onClick={upsert}
                />
              )}
            </UpsertReminderAction>
          ),
          <UpsertNoteAction
            upsertNote={handleNoteCreated}
            aria-label="Pulsante per aggiungere una nota"
          >
            {({ upsert }) => (
              <Dropdown.Option
                label="Aggiungi nota"
                onClick={upsert}
              />
            )}
          </UpsertNoteAction>,
        ]}
      >
        <ViewPropertyPopoverPreview
          propertyId={intent.propertyId}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <PropertyAvatar
                size="S"
                icon={property.overriddenGeo ? ICON_MAP_MARKER_OUTLINE : undefined}
              />
              <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                {property.overriddenGeo?.normalizedAddress || property.geo?.normalizedAddress}
              </Text.BodySmall>
            </HStack>
          )}
        />
      </Card.Header>
      <Card.Content>
        <Grid gutter={150}>
          <Grid.Unit>
            <UpdateIntentStatus
              showAsBadge={!isAdminOrCallCenterOrOwnerOrManger}
              intent={intent}
              size="XS"
            />
          </Grid.Unit>
          <Grid.Unit size={1 / 2}>
            {isAgentLoading && (
              <div style={{ width: '10rem' }}>
                <UserPreviewSkeleton size="S" />
              </div>
            )}
            {agentError && (
              <UserPreview
                size="S"
                name="Errore agente"
              />
            )}
            {agent && (
              <ViewAgentPopoverPreview
                agent={agent}
                trigger={(
                  <AgentPreview
                    {...toAgentPreviewProps(agent)}
                    size="S"
                    expanded
                  />
                )}
              />
            )}
          </Grid.Unit>
          <Grid.Unit size={1 / 2}>
            <Stack gap={50}>
              <Text.Mini transform="uppercase">
                Aggiunto
              </Text.Mini>
              <Text.Caption>
                <DateViewer
                  stringDate={intent.updatedAt!}
                  checkIfToday
                />
              </Text.Caption>
            </Stack>
          </Grid.Unit>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default ViewContactIntentMobileCard;
