import {
  Message, Grid, useNotifications,
  Spacing,
  Action,
  Skeleton,
  HStack,
} from '@doveit/bricks';
import React from 'react';
import arrayMove from 'array-move';
import SortableItem from '../../../components/sortable/item/SortableItem';
import SortableList from '../../../components/sortable/list/SortableList';
import { ContentType } from '../../../domain/types';
import { Content, Property } from '../../../providers/api/dtos';
import useContentsByPropertyId from '../../../property/hooks/use-contents-by-property-id/useContentsByPropertyId';
import { toMedia } from '../../../property/contents/mappers/contentMapper';
import { updateContentsForPropertyId } from '../../../providers/api/content/contentProvider';
import MultimediaContentThumbnail from '../../components/MultimediaContentThumbnail';

export interface SortMultimediaContentsProps {
  propertyId: NonNullable<Property['id']>,
  contentType: ContentType.FOTO | ContentType.PLANIMETRIA,
  canSeeMissingAboutError?: boolean,
  onMediaSortChange?: VoidFunction,
  onSuccess?: VoidFunction,
  onAbort?: VoidFunction,
}

const SortMultimediaContents: React.FC<SortMultimediaContentsProps> = ({
  propertyId,
  contentType,
  canSeeMissingAboutError = false,
  onMediaSortChange,
  onSuccess,
  onAbort,
}) => {
  const [updatedContents, setUpdatedContents] = React.useState<Content[]>([]);
  const {
    data: contents,
    error: contentsError,
    isLoading: isContentsLoading,
    mutate: mutateContents,
  } = useContentsByPropertyId(
    propertyId,
    contentType,
  );

  React.useEffect(() => {
    setUpdatedContents(contents ?? []);
  }, [contents]);

  const [enableSaveOrder, setEnableSaveOrder] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();

  const typeLabel = React.useMemo(
    () => (contentType === ContentType.FOTO ? 'fotografie' : 'planimetrie'),
    [contentType],
  );

  const onInternalMediaSortChange = React.useCallback((oldIndex: number, newIndex: number) => {
    const movedContents = arrayMove(updatedContents, oldIndex, newIndex);
    setUpdatedContents(movedContents);

    setEnableSaveOrder(true);

    onMediaSortChange?.();
  }, [onMediaSortChange, updatedContents]);

  const saveSortedContents = React.useCallback(async () => {
    setIsSaving(true);

    const indexedContents = updatedContents.map((content, index) => ({
      ...content,
      position: index,
    }));

    try {
      await updateContentsForPropertyId(indexedContents, propertyId);

      setIsSaving(false);
      setEnableSaveOrder(false);

      mutateContents();

      addSuccess(`Le ${typeLabel} sono state salvate con successo.`);

      onSuccess?.();
    } catch (err) {
      setIsSaving(false);

      addError(`Non è stato possibile salvare le ${typeLabel}.`);
    }
  }, [addError, addSuccess, mutateContents, onSuccess, propertyId, typeLabel, updatedContents]);

  const abortSortedContents = React.useCallback(async () => {
    setUpdatedContents(contents ?? []);
    setEnableSaveOrder(false);

    onAbort?.();
  }, [contents, onAbort]);

  const multimediaContents = React.useMemo(
    () => updatedContents?.map(toMedia),
    [updatedContents],
  );

  if (isContentsLoading) {
    return (
      <Skeleton
        count={3}
        aria-label="Contenuti in caricamento"
      />
    );
  }

  if (contentsError) {
    return (
      <Message
        type="critical"
        message={`Non è stato possibile caricare le ${typeLabel}`}
      />
    );
  }

  return (
    <>
      {multimediaContents.length === 0 && (
        <Message message={`Non sono presenti ${typeLabel} dell'immobile.`} />
      )}
      {multimediaContents.length > 0 && (
        <>
          <Grid gutter={25} aria-label="Contenuti">
            <SortableList
              items={multimediaContents}
              onDragEnd={onInternalMediaSortChange}
              disabled={isSaving}
            >
              {multimediaContents.map(({ id, url, about }, i) => (
                <Grid.Unit
                  key={id}
                  size={{
                    XS: 1 / 4, SM: 1 / 5, MD: 1 / 8,
                  }}
                >
                  <SortableItem
                    id={id}
                    disabled={isSaving}
                  >
                    <MultimediaContentThumbnail
                      picture={{ src: url, alt: `Contenuto con id ${id}` }}
                      aria-label="Card contenuto"
                      operation="drag"
                      index={i + 1}
                      interactive
                      hasError={!about && canSeeMissingAboutError}
                    />
                  </SortableItem>
                </Grid.Unit>
              ))}
            </SortableList>
          </Grid>
          <Spacing margin={[400, 0, 0]}>
            <HStack>
              <Action
                label="Salva ordinamento"
                color="primary"
                disabled={!enableSaveOrder}
                emphasis="high"
                aria-label={`Pulsante per salvare l'ordinamento delle ${typeLabel}`}
                onClick={saveSortedContents}
              />
              <Action
                label="Annulla modifiche"
                color="neutral"
                disabled={!enableSaveOrder}
                emphasis="low"
                aria-label={`Pulsante per annullare le modifiche sull'ordinamento delle ${typeLabel}`}
                onClick={abortSortedContents}
              />
            </HStack>
          </Spacing>
        </>
      )}
    </>
  );
};

export default SortMultimediaContents;
