import {
  Form, FormActionsAlign, FormHandlers, FormSubmitType,
} from '@doveit/bricks';
import React from 'react';
import { Agent } from '../../../providers/api/dtos';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';
import { AgentStatus } from '../../../domain/types';
import { editAssignedAgentFormValidationSchema } from './EditAssignedAgentForm.schema';

export interface EditAssignedAgentFormModel {
  assignee?: Agent,
}

export interface EditAssignedAgentFormProps {
  onSubmit: FormSubmitType<EditAssignedAgentFormModel>,
  initialValues?: Partial<EditAssignedAgentFormModel>,
  innerRef?: React.MutableRefObject<FormHandlers>
  disabled?: boolean,
  loading?: boolean,
  noSubmit?: boolean,
}

export const DEFAULT_INITIAL_VALUES: EditAssignedAgentFormModel = {
  assignee: {} as Agent,
};

const EditAssignedAgentForm: React.FC<EditAssignedAgentFormProps> = ({
  initialValues,
  ...rest
}) => {
  const mergedInitialValues: EditAssignedAgentFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      enableReinitialize
      validationSchema={editAssignedAgentFormValidationSchema}
      {...rest}
    >
      <Form.Item>
        <AgentAutoComplete
          name="assignee"
          label="Agente assegnatario"
          aria-label="Seleziona l'agente assegnatario"
          placeholder="Cerca per nome"
          status={AgentStatus.ACTIVE}
        />
      </Form.Item>
      {!rest.innerRef && (
        <Form.Actions align={FormActionsAlign.RIGHT}>
          <Form.Submit label="Modifica" />
        </Form.Actions>
      )}
    </Form>
  );
};

export default EditAssignedAgentForm;
