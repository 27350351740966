import { Form, FormActionsAlign, FormProps } from '@doveit/bricks';
import React, { useMemo } from 'react';
import { OfferDeedStatus } from '../../../domain/types';
import { OfferDeed } from '../../../providers/api/dtos';
import { offerDeedFormValidationSchema } from './OfferDeedForm.schema';
import computeMonthYearSelectOptions from '../../../utils/time/generic-month-year-select-options/computeMonthYearSelectOptions';
import { formatInputDate } from '../../../utils/form';

export interface OfferDeedFormModel {
  date: OfferDeed['date'],
  status: OfferDeedStatus | '',
}

export interface OfferDeedFormProps {
  initialValues?: Partial<OfferDeedFormModel>,
  disabled?: FormProps<OfferDeedFormModel>['disabled'],
  loading?: FormProps<OfferDeedFormModel>['loading'],
  submitLabel?: string,
  onSubmit: FormProps<OfferDeedFormModel>['onSubmit'],
  dateRange?: {
    min: Date,
    max: Date,
  },
  hideStatusRadio?: boolean,
}

const DEFAULT_INITIAL_VALUES: OfferDeedFormModel = {
  date: '',
  status: '',
};

const OfferDeedForm: React.FC<OfferDeedFormProps> = ({
  initialValues,
  hideStatusRadio,
  dateRange,
  submitLabel = 'Salva',
  ...rest
}) => {
  const mergedInitialValues: OfferDeedFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...(initialValues || {}),
  }), [initialValues]);

  const inputFormDateRange = useMemo(() => {
    if (dateRange) {
      return {
        min: formatInputDate(dateRange.min),
        max: formatInputDate(dateRange.max),
      };
    }
    return {
      min: formatInputDate(new Date()),
      max: undefined,
    };
  }, [dateRange]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={offerDeedFormValidationSchema}
      {...rest}
    >
      {({ values }: { values: OfferDeedFormModel }) => (
        <>
          <Form.Item>
            {!hideStatusRadio && (
              <Form.RadioButtonGroup
                name="status"
                label="l rogito è già stato fissato e conosci la data esatta (giorno e mese)? In caso contrario potrai comunque procedere inserendo una data indicativa."
                aria-label="Campo per specificare lo stato del rogito"
                itemSize={{ XS: 1 / 2, MD: 1 / 4 }}
                items={[{
                  label: 'Si',
                  value: OfferDeedStatus.EXACT,
                }, {
                  label: 'No',
                  value: OfferDeedStatus.VAGUE,
                }]}
              />
            )}
            {(values.status === OfferDeedStatus.EXACT || values.status === OfferDeedStatus.CONFIRMED) && (
              <Form.Item>
                <Form.Input
                  {...inputFormDateRange}
                  name="date"
                  type="date"
                  label="Data del rogito"
                  aria-label="Campo per inserire la data del rogito"
                />
              </Form.Item>
            )}
            {values.status === OfferDeedStatus.VAGUE && (
              <Form.Item>
                <Form.Select
                  name="date"
                  label="Inserisci il mese indicativo del rogito. Potrai comunque cambiare questa informazione più avanti."
                  aria-label="Campo per inserire il mese indicativo del rogito"
                  options={computeMonthYearSelectOptions()}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Actions align={FormActionsAlign.RIGHT}>
            <Form.Submit label={submitLabel} />
          </Form.Actions>
        </>
      )}
    </Form>
  );
};

export default OfferDeedForm;
