import React from 'react';
import { UserPreview, UserPreviewProps } from '@doveit/bricks';
import { AgentPreviewStatus } from '../types';
import AgentAvatar from '../agent-avatar/AgentAvatar';

export interface AgentPreviewProps extends Omit<UserPreviewProps, 'children' | 'avatar'> {
  status?: AgentPreviewStatus,
  needsSupervisor?: boolean,
  inTrial?: boolean,
  details?: React.ReactNode,
  photoId?: string,
}

export const AgentPreview: React.FC<AgentPreviewProps> = ({
  status,
  name,
  size,
  needsSupervisor,
  inTrial,
  details,
  photoId,
  ...rest
}) => (
  <UserPreview
    aria-label={name ? `Anteprima di ${name}` : undefined}
    {...rest}
    size={size}
    name={name}
    renderAvatar={(avatarProps) => (
      <AgentAvatar
        {...avatarProps}
        status={status}
        needsSupervisor={needsSupervisor}
        inTrial={inTrial}
        photoId={photoId}
      />
    )}
  >
    {details}
  </UserPreview>
);

export default AgentPreview;
