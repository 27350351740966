import { EditCommissionsFormModel } from '../../../agent/components/edit-commissions-form/EditCommissionsForm';
import { Agency, Commissions } from '../../../providers/api/dtos';

export const editCommissionFormModelToAgency = (commissionFormModel: EditCommissionsFormModel, existingAgency: Agency): Agency => {
  const commissions: Commissions = {
    fixedSalary: Number.parseInt(commissionFormModel.fixedSalary || '0', 10),
    managementCost: Number.parseInt(commissionFormModel.managementCost || '0', 10) / 100,
    fee: Number.parseInt(commissionFormModel.fee || '0', 10),
    photoPrice: {
      personal: Number.parseInt(commissionFormModel.photoPricePersonal || '0', 10),
      dove: Number.parseInt(commissionFormModel.photoPriceDove || '0', 10),
    },
    commissionSplit: {
      personal: Number.parseInt(commissionFormModel.commissionSplitPersonal || '0', 10) / 100,
      dove: Number.parseInt(commissionFormModel.commissionSplitDove || '0', 10) / 100,
    },
    overCommissionSplit: {
      personal: Number.parseInt(commissionFormModel.overCommissionSplitPersonal || '0', 10) / 100,
      dove: Number.parseInt(commissionFormModel.overCommissionSplitDove || '0', 10) / 100,
    },
    overCommissionThreshold: Number.parseInt(commissionFormModel.overCommissionThreshold || '0', 10),
    notes: commissionFormModel.notes,
  };

  return {
    ...existingAgency,
    commissions,
  };
};
