import {
  ThemedFunctor,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({
  theme,
}) => css`
  margin: ${theme.spaceUnits.mobile.SMALLER} 0;
  width: 100%;
  height: 1px;
  background-color: ${theme.palettes.neutrals.light};
  display: block;
`;
