import * as Yup from 'yup';
import { FLOAT_REGEX } from '../../../constants';
import { OfferKoStatus, OfferStatus } from '../../../domain/types';

export const KO_STATUS_TO_ARCHIVE_OFFER_ACTION_LABELS: Record<OfferKoStatus, {
  actionLabel: string,
  modalTitle: string,
  modalBody: string,
  successNotification: string,
}> = {
  [OfferStatus.WITHDRAWN]: {
    actionLabel: 'Annulla proposta',
    modalTitle: 'Conferma annullamento proposta',
    modalBody: "Conferma l'operazione per archiviare la proposta con stato Annullata.",
    successNotification: 'La proposta è stata annullata con successo.',
  },
  [OfferStatus.REFUSED]: {
    actionLabel: 'Rifiuta proposta',
    modalTitle: 'Conferma rifiuto proposta',
    modalBody: "Conferma l'operazione per archiviare la proposta con stato Rifiutata.",
    successNotification: 'La proposta è stata rifiutata con successo.',
  },
  [OfferStatus.MORTGAGE_REFUSED]: {
    actionLabel: 'Archivia causa mutuo',
    modalTitle: 'Conferma archivio causa mutuo',
    modalBody: "Conferma l'operazione per archiviare la proposta con stato Mutuo non concesso.",
    successNotification: 'La proposta è stata archiviata causa "Mutuo non concesso" con successo.',
  },
  [OfferStatus.OTHER_CONSTRAINTS]: {
    actionLabel: 'Archivia causa altri vincoli',
    modalTitle: 'Conferma archivio causa altri vincoli',
    modalBody: "Conferma l'operazione per archiviare la proposta con stato Altri vincoli.",
    successNotification: 'La proposta è stata archiviata causa "Altri vincoli" con successo.',
  },
};

export const REFUSE_OFFER_FORM_DEFAULT_VALUES = {
  counterOffer: '',
};

export const refuseOfferFormValidationSchema = (offerValue: number) => Yup.object({
  counterOffer: Yup
    .string()
    .trim()
    .required('Il campo è obbligatorio')
    .matches(FLOAT_REGEX)
    .test(
      'validCounterOffer',
      'Il valore della controproposta deve essere maggiore del valore della proposta',
      (value) => parseFloat(value) > offerValue,
    ),
});

export const ARCHIVE_OFFER_ERROR_MESSAGE = 'Non è stato possibile archiviare la proposta.';
