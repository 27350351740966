import useSWR, { SWRResponse } from 'swr';
import { getContactSearchCriteria } from '../../../providers/api/contact/contactProvider';
import { SearchCriteria } from '../../../providers/api/dtos/searchCriteria';

export const CONTACT_SEARCH_CRITERIA_RID = '/contacts/:id/search-criteria';

export default function useContactSearchCriteria(
  contactId?: string,
): SWRResponse<SearchCriteria[], Error> {
  return useSWR(
    contactId ? [CONTACT_SEARCH_CRITERIA_RID, contactId] : null,
    () => getContactSearchCriteria(contactId!),
  );
}
