import {
  useState, useEffect, useMemo, useCallback,
} from 'react';

const useTimer = () => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const start = useCallback(
    () => setIsActive(true),
    [],
  );

  const stop = useCallback(
    () => setIsActive(false),
    [],
  );

  const reset = useCallback(
    () => {
      stop();
      setSeconds(0);
      setIsActive(false);
    },
    [stop],
  );

  const result = useMemo(() => ({
    isActive,
    start,
    stop,
    reset,
    seconds,
  }), [seconds, isActive, stop, start, reset]);

  useEffect(() => {
    let interval: number | undefined;

    if (isActive) {
      interval = window.setInterval(() => {
        setSeconds((time) => time + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return result;
};

export default useTimer;
