import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { PropertyPreview } from '../../../providers/api/dtos/property';
import { getPropertyPreview } from '../../../providers/api/property/propertyProvider';

export const PROPERTY_PREVIEW_RID = '/property/preview';

export default function usePropertyPreview(
  propertyId?: number,
  options?: SWRConfiguration<PropertyPreview, AxiosError>,
): SWRResponse<PropertyPreview, AxiosError> {
  return useSWR(propertyId ? [PROPERTY_PREVIEW_RID, propertyId] : null, () => getPropertyPreview(propertyId!), options);
}
