import { Action, ICON_CALENDAR_OUTLINE } from '@doveit/bricks';
import React, { FunctionComponent, useState, useCallback } from 'react';
import { mutate } from 'swr';
import { saveNoAnswer } from '../../../providers/api/call-center/callCenterProvider';
import { CONTACT_RID } from '../../../contact/hooks/use-contact/useContact';

export interface NoAnswerActionProps {
  contactId: string,
  disabled?: boolean,
  onSuccess?: () => void,
  onError?: (error: string) => void,
}

const DEFAULT_ERROR_MESSAGE = 'Non è stato possibile aggiornare il contatto e le sue lead.';

const NoAnswerAction: FunctionComponent<NoAnswerActionProps> = ({
  contactId,
  disabled,
  onSuccess,
  onError,
}) => {
  const [loading, setLoading] = useState(false);

  const onButtonClick = useCallback(async () => {
    setLoading(true);

    try {
      await saveNoAnswer(contactId);
      setLoading(false);

      mutate([CONTACT_RID, contactId]);

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      setLoading(false);

      if (onError) {
        onError(DEFAULT_ERROR_MESSAGE);
      }
    }
  }, [contactId, onError, onSuccess]);

  return (
    <Action
      label="Non risponde"
      color="warning"
      emphasis="high"
      iconLeft={{ path: ICON_CALENDAR_OUTLINE }}
      loading={loading}
      disabled={disabled}
      onClick={onButtonClick}
    />
  );
};

export default NoAnswerAction;
