import axios, { AxiosInstance } from 'axios';
import qs from 'qs';

export const PUBLIC_API_V1_BASE_URL = `${(process.env.REACT_APP_PUBLIC_API_BASE_URL?.toString() ?? 'https://dev.dove.it')}/api/v1`;
export const PUBLIC_API_V2_BASE_URL = `${(process.env.REACT_APP_PUBLIC_API_BASE_URL?.toString() ?? 'https://dev.dove.it')}/api/v2`;
export const PUBLIC_API_LOCATIONS_PATH = '/location';
export const PUBLIC_API_SUGGEST_PATH = '/location/suggest';
export const PUBLIC_API_EVALUATE_PATH = '/evaluate';
export const PUBLIC_API_PLACE_SUGGEST_PATH = '/place/suggest';

export const paramsSerializer = (params: any): string => qs.stringify(params, {
  arrayFormat: 'repeat',
});

const createClient = (() => {
  let client: AxiosInstance;

  return async (baseURL: string, cache: boolean = true) => {
    if (client && cache) {
      return client;
    }

    client = axios.create({
      baseURL,
      paramsSerializer,
    });

    return client;
  };
})();

export async function getClientV1() {
  return createClient(PUBLIC_API_V1_BASE_URL, false);
}

export async function getClientV2() {
  return createClient(PUBLIC_API_V2_BASE_URL, false);
}
