/* eslint-disable react/no-unknown-property */
import {
  Action, ActionIcon, Badge, Card, ConfirmModal, FontWeight, Grid, ICON_PLUS, ICON_TRASH_CAN_OUTLINE, ModalSize, Portal, Stack, Text, useModal, useNotifications,
} from '@doveit/bricks';
import React from 'react';
import { formatDate } from '@doveit/hammer';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Concession, Contact } from '../../../providers/api/dtos';
import { createConcession, deleteConcession } from '../../../providers/api/concession/concessionProvider';
import { concessionLabels } from '../../../labels';
import { optInSourceIcon } from '../../constants';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import useContactConcessions from '../../hooks/use-contact-concessions/useContactConcessions';
import { ConcessionType, OptInSource } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import SimpleTable from '../../../components/simple-table/SimpleTable';

export const DELETE_CONCESSION_SUCCESS_MESSAGE = 'L\'agevolazione è stata revocata correttamente.';
export const DELETE_CONCESSION_ERROR_MESSAGE = 'Non è stato possibile revocare l\'agevolazione.';
export const CREATE_CONCESSION_SUCCESS_MESSAGE = 'L\'agevolazione è stata aggiunta correttamente.';
export const CREATE_CONCESSION_ERROR_MESSAGE = 'Non è stato possibile aggiungere l\'agevolazione.';

export type SelectedConcession = {
  concession: Concession,
  selected: boolean,
};

type ChildFnProps = {
  update: VoidFunction,
  label: string,
  isSaving: boolean,
};

export interface UpdateContactConcessionsActionProps {
  contactId: NonNullable<Contact['id']>,
  canDelete?: boolean,
  canAdd?: boolean,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: () => void,
}

const UpdateContactConcessionsAction: React.FC<UpdateContactConcessionsActionProps> = ({
  contactId,
  canDelete = false,
  canAdd = false,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const updateConcessionModal = useModal();
  const removeConcessionConfirmationModal = useModal<Concession>();
  const addConcessionConfirmationModal = useModal<Concession | undefined>();
  const isMobile = useIsDevice('mobile');
  const { addSuccess, addError } = useNotifications();
  const { user } = useRBAC();

  const {
    data: concessions,
    isLoading: areConcessionsLoading,
    error: concessionsError,
    mutate: mutateConcessions,
  } = useContactConcessions(contactId);

  const selectedConcessions: SelectedConcession[] = React.useMemo(() => Object.values(ConcessionType)
    .map((concessionType) => {
      const foundConcession = concessions?.find(({ type }) => type === concessionType);
      if (!foundConcession) {
        return ({
          concession: {
            type: concessionType,
            contactId,
            sourceType: OptInSource.BACKOFFICE,
            sourceValue: user.email,
            givenAt: new Date().toISOString(),
          },
          selected: false,
        });
      }

      return {
        concession: foundConcession,
        selected: true,
      };
    })
    .sort((a, b) => concessionLabels[a.concession.type].localeCompare(concessionLabels[b.concession.type], 'it')), [concessions, contactId, user.email]);

  const onEditConcessionClick = React.useCallback(() => {
    updateConcessionModal.open();
  }, [updateConcessionModal]);

  const onAddClick = React.useCallback((missingConcession: Concession) => () => {
    addConcessionConfirmationModal.open(missingConcession);
  }, [addConcessionConfirmationModal]);

  const onAddConfirm = React.useCallback(async () => {
    try {
      setIsSaving(true);

      await createConcession(addConcessionConfirmationModal.data!);

      addSuccess(CREATE_CONCESSION_SUCCESS_MESSAGE);
      addConcessionConfirmationModal.close();
      setIsSaving(false);

      onSuccess?.();

      await mutateConcessions();
    } catch (err) {
      addError(CREATE_CONCESSION_ERROR_MESSAGE);
      setIsSaving(false);
    }
  }, [addConcessionConfirmationModal, addError, addSuccess, mutateConcessions, onSuccess]);

  const onRemoveClick = React.useCallback((concession: Concession) => () => {
    removeConcessionConfirmationModal.open(concession);
  }, [removeConcessionConfirmationModal]);

  const onRemoveConfirm = React.useCallback(async () => {
    try {
      setIsSaving(true);

      await deleteConcession(removeConcessionConfirmationModal.data!.id!);

      addSuccess(DELETE_CONCESSION_SUCCESS_MESSAGE);
      removeConcessionConfirmationModal.close();
      setIsSaving(false);

      onSuccess?.();

      await mutateConcessions();
    } catch (err) {
      addError(DELETE_CONCESSION_ERROR_MESSAGE);
      setIsSaving(false);
    }
  }, [addError, addSuccess, mutateConcessions, onSuccess, removeConcessionConfirmationModal]);

  const label = React.useMemo(() => (
    selectedConcessions.some(({ selected }) => selected)
      ? 'Visualizza'
      : 'Aggiungi'
  ), [selectedConcessions]);

  if (concessionsError || areConcessionsLoading || !concessions) return null;

  return (
    <>
      {children
        ? children({
          isSaving,
          label,
          update: onEditConcessionClick,
        })
        : (
          <Action
            aria-label="Pulsante per modificare le agevolazioni"
            label={label}
            onClick={onEditConcessionClick}
            size="S"
          />
        )}
      <Portal>
        <SimpleModal
          {...updateConcessionModal}
          title="Agevolazioni"
          size={ModalSize.MEDIUM}
          aria-label="Modale per modificare le agevolazioni"
        >
          {!isMobile && (
            <SimpleTable>
              <SimpleTable.Header>
                <SimpleTable.HeaderCell>
                  Agevolazione
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell>
                  Data prestazione
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell>
                  Sorgente
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell />
              </SimpleTable.Header>

              <SimpleTable.Body>
                {selectedConcessions.map(({ concession, selected }) => (
                  <SimpleTable.Row key={concession.id}>
                    <SimpleTable.Cell>
                      <Text.Body fontWeight={FontWeight.MEDIUM}>
                        {concessionLabels[concession.type]}
                      </Text.Body>
                    </SimpleTable.Cell>

                    <SimpleTable.Cell>
                      <Text.Body>
                        {concession.updatedAt ? formatDate(new Date(concession.updatedAt)) : '-'}
                      </Text.Body>
                    </SimpleTable.Cell>

                    <SimpleTable.Cell>
                      <Text.Body>
                        {selected ? (
                          <Badge
                            icon={optInSourceIcon[concession.sourceType]}
                            label={concession.sourceValue}
                            size="XS"
                          />
                        ) : '-'}
                      </Text.Body>
                    </SimpleTable.Cell>

                    <SimpleTable.Cell align="right">
                      {(canDelete && selected) && (
                        <ActionIcon
                          label="Rimuovi"
                          size="S"
                          onClick={onRemoveClick(concession)}
                          icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                          aria-label={`Pulsante per rimuovere l'agevolazione (${concession.type})`}
                        />
                      )}
                      {(canAdd && !selected) && (
                        <ActionIcon
                          label="Aggiungi"
                          size="S"
                          onClick={onAddClick(concession)}
                          icon={{ path: ICON_PLUS }}
                          aria-label={`Pulsante per aggiungere l'agevolazione (${concession.type})`}
                        />
                      )}
                    </SimpleTable.Cell>
                  </SimpleTable.Row>
                ))}
              </SimpleTable.Body>
            </SimpleTable>
          )}
          {isMobile && (
            <Stack gap={150}>
              {selectedConcessions.map(({ concession, selected }) => (
                <Card
                  key={concession.type}
                  aria-label={`Informazioni sull'agevolazione ${concession.type} (Mobile)`}
                >
                  <Card.Header
                    caption="Agevolazione"
                    title={concessionLabels[concession.type]}
                    primaryActions={[
                      (canDelete && selected) && (
                        <ActionIcon
                          label="Rimuovi"
                          size="S"
                          onClick={onRemoveClick(concession)}
                          icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                          aria-label={`Pulsante per rimuovere l'agevolazione (${concession.type})`}
                        />
                      ),
                      (canAdd && !selected) && (
                        <ActionIcon
                          label="Aggiungi"
                          size="S"
                          onClick={onAddClick(concession)}
                          icon={{ path: ICON_PLUS }}
                          aria-label={`Pulsante per aggiungere l'agevolazione (${concession.type})`}
                        />
                      ),
                    ]}
                  />
                  {selected && (
                    <Card.Content>
                      <Grid>
                        <Grid.Unit
                          size={{ XS: 1 / 2 }}
                        >
                          <Stack>
                            <Text.Mini transform="uppercase">
                              Data indicazione
                            </Text.Mini>
                            <Text.Body>
                              {concession.updatedAt ? formatDate(new Date(concession.updatedAt)) : '-'}
                            </Text.Body>
                          </Stack>
                        </Grid.Unit>
                        <Grid.Unit size={{ XS: 1 / 2 }}>
                          <Stack>
                            <Text.Mini>
                              Sorgente
                            </Text.Mini>
                            <div>
                              <Badge
                                icon={optInSourceIcon[concession.sourceType]}
                                label={concession.sourceValue}
                                size="XS"
                              />
                            </div>
                          </Stack>
                        </Grid.Unit>
                      </Grid>
                    </Card.Content>
                  )}
                </Card>
              ))}
            </Stack>
          )}
        </SimpleModal>
        <ConfirmModal
          isOpen={removeConcessionConfirmationModal.isOpen}
          title="Conferma rimozione agevolazione"
          onConfirm={onRemoveConfirm}
          onAbort={removeConcessionConfirmationModal.close}
          aria-label="Modale per confermare la rimozione di un'agevolazione"
        >
          Cliccando sul pulsante Conferma rimuoverai l&apos;agevolazione
        </ConfirmModal>
        <ConfirmModal
          isOpen={addConcessionConfirmationModal.isOpen}
          title="Conferma aggiunta agevolazione"
          onConfirm={onAddConfirm}
          onAbort={addConcessionConfirmationModal.close}
          aria-label="Modale per confermare l'aggiunta di un'agevolazione"
        >
          {canDelete
            ? 'Cliccando sul pulsante Conferma aggiungerai l\'agevolazione.'
            : 'Una volta confermata l\'aggiunta dell\'agevolazione non sarà possibile rimuoverla.'}
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default UpdateContactConcessionsAction;
