/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, PropsWithChildren } from 'react';
import * as styles from './CardOverlayLabel.style';

export enum CardOverlayLabelType {
  NEUTRAL = 'neutrals.medium',
  SUCCESS = 'status.success',
  INFO = 'status.info',
  WARNING = 'status.warning',
  ERROR = 'status.alert',
}

export type CardOverlayLabelProps = PropsWithChildren<{
  type?: CardOverlayLabelType
}>;

const CardOverlayLabel: FunctionComponent<CardOverlayLabelProps> = ({
  type = CardOverlayLabelType.NEUTRAL,
  children,
}) => (
  <div
    css={styles.base}
    $backgroundColor={type}
  >
    {children}
  </div>
);

export default CardOverlayLabel;
