import React from 'react';
import { BadgeProps } from '@doveit/bricks';
import useGeolocate from '../../../hooks/use-geolocate/useGeolocate';
import { mapToSellabilityRange } from '../../../prospect/utils/mapToSellabilityRange';
import { PropertySellabilityRank } from '../../types';
import { Point } from '../../../providers/public-api/dtos';
import PropertySellabilityIndicator from '../../components/property-sellability-indicator/PropertySellabilityIndicator';

export interface ViewPropertySellabilityProps {
  geo?: Point,
  propertySize?: number,
  size?: BadgeProps['size']
}

const ViewPropertySellability: React.FC<ViewPropertySellabilityProps> = ({
  geo,
  propertySize,
  size,
}) => {
  const { data: geoData } = useGeolocate(geo);

  const propertySellability = React.useMemo(() => {
    if (!geoData || !propertySize) {
      return null;
    }

    const sellabilityRange = mapToSellabilityRange(propertySize!);

    return geoData.locationResult.sellability?.data[sellabilityRange]?.rank as PropertySellabilityRank;
  }, [geoData, propertySize]);

  if (!propertySellability) {
    return null;
  }

  return (
    <PropertySellabilityIndicator
      rank={propertySellability}
      size={size}
    />
  );
};

export default ViewPropertySellability;
