import { API_APPLICANTS_PATH, getClient } from '../api';
import { Applicant, Hire } from '../dtos';

export async function getApplicant(applicantId: number): Promise<Applicant> {
  const client = await getClient();
  const { data } = await client.get<Applicant>(`${API_APPLICANTS_PATH}/${applicantId}`);

  return data;
}

export async function getSimilarApplicants(applicantId: number): Promise<Applicant[]> {
  const client = await getClient();
  const { data } = await client.get<Applicant[]>(`${API_APPLICANTS_PATH}/${applicantId}/similar`);

  return data;
}

export async function updateApplicant(applicantId: number, applicant: Applicant): Promise<Applicant> {
  const client = await getClient();
  const { data } = await client.put<Applicant>(`${API_APPLICANTS_PATH}/${applicantId}`, applicant);

  return data;
}

export async function createApplicant(applicant: Applicant): Promise<Applicant> {
  const client = await getClient();
  const { data } = await client.post<Applicant>(API_APPLICANTS_PATH, applicant);

  return data;
}

export async function hireApplicant(hire: Hire): Promise<{ id: number }> {
  const client = await getClient();
  const { data } = await client.post<{ id: number }>(`${API_APPLICANTS_PATH}/hire`, hire);
  return data;
}
