import React, {
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {
  FormHandlers, FormSkeleton, Message, useNotifications,
} from '@doveit/bricks';
import { updateLead } from '../../../providers/api/lead/leadProvider';
import LeadForm from '../../components/lead-form/LeadForm';
import { toLeadFormModel } from '../../mappers/toLeadFormModel';
import { Lead } from '../../../providers/api/dtos/lead';
import useLead from '../../../lead/hooks/use-lead/useLead';
import { getEvaluation } from '../../../providers/public-api/evaluation/evaluationProvider';
import useErrorNotification from '../../../hooks/use-error-notification/useErrorNotification';
import useGoogleMapsAPI from '../../../hooks/use-google-maps-api/useGoogleMapsAPI';
import useAddressSuggestions from '../../../hooks/use-address-suggestion/useAddressSuggestions';
import { noop } from '../../../utils';
import { LeadFormModel } from '../../../lead/models/LeadFormModel';
import { geolocate } from '../../../providers/public-api/location/locationProvider';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useAgent } from '../../../hooks/use-agent/useAgent';

export interface EditLeadProps {
  leadId: string,
  onSaveSuccess?(lead: Lead): void,
  onSaveError?(errorMessage: string): void,
  formRef: React.MutableRefObject<FormHandlers>,
}

export const LOADING_ERROR_MESSAGE = 'Non è stato possibile caricare i dati dell\'immobile';
export const SAVING_ERROR_MESSAGE = 'Non è stato possibile aggiornare i dati dell\'immobile';
export const SAVING_SUCCESS_MESSAGE = 'Dati immobile aggiornati';

const EditLead: FunctionComponent<EditLeadProps> = ({
  leadId,
  formRef,
  onSaveSuccess,
  onSaveError,
}) => {
  const { userIsAdmin, userIsCallCenter } = useRBAC();
  const { getPlaceDetails } = useGoogleMapsAPI();
  const { data: lead, error: leadError, mutate } = useLead(leadId);
  const { data: originAgent, error: originAgentError } = useAgent(lead?.originAgentId);
  const { data: addressSuggestion, error: addressSuggestionError } = useAddressSuggestions(lead?.propertyAddress);
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();
  const onSubmitErrors = useErrorNotification();

  const onSubmit = useCallback(
    async (values: LeadFormModel) => {
      setIsSaving(true);

      try {
        const place = await getPlaceDetails(values.propertyAddress?.placeId!);

        const { suburb } = (place.latitude && place.longitude)
          ? await geolocate({
            latitude: `${place.latitude}`,
            longitude: `${place.longitude}`,
          }).catch((_) => ({ suburb: undefined }))
          : { suburb: undefined };

        const newData: Lead = {
          ...lead!,
          propertyAddress: values.propertyAddress!.description,
          propertyType: values.propertyType!,
          propertyStatus: values.propertyStatus!,
          propertyFloor: parseFloat(values.propertyFloor!),
          propertySize: parseInt(values.propertySize!, 10),
          propertyFeatures: values.propertyFeatures.reduce((acc, curr) => ({
            ...acc,
            [curr]: true,
          }), {}),
          locality: place.locality ?? null,
          area: suburb?.area,
          plateCode: place.plateCode ?? null,
          postalCode: place.postalCode ?? null,
          latitude: place.latitude,
          longitude: place.longitude,
          willingness: values.willingness,
          notes: values.notes,
          buildingFloors: values.buildingFloors
            ? parseInt(values.buildingFloors, 10)
            : undefined,
          residualMortgage: values.residualMortgage ?? false,
          otherAgencies: values.otherAgencies ?? false,
          multiOwner: values.multiOwner ?? false,
          inheritedProperty: values.inheritedProperty ?? false,
          giftedProperty: values.giftedProperty ?? false,
          ownedByCompany: values.ownedByCompany ?? false,
          registryDiscrepancies: values.registryDiscrepancies ?? false,
          subsidizedHousing: values.subsidizedHousing ?? false,
          registryStatus: values.registryStatus,
          originAgentId: values.originAgent?.id,
        };

        if (values.borsinoSquareMeterEvaluation
          && (+values.borsinoSquareMeterEvaluation.min !== lead?.borsinoSquareMeterEvaluation?.min
            || +values.borsinoSquareMeterEvaluation.max !== lead?.borsinoSquareMeterEvaluation?.max)
        ) {
          const date = new Date().toISOString();
          const squareMeterEvaluation = await getEvaluation({
            floor: newData.propertyFloor ?? undefined,
            size: newData.propertySize,
            propertyType: newData.propertyType,
            propertyStatus: newData.propertyStatus,
            latitude: place.latitude!,
            longitude: place.longitude!,
          }).catch(noop);

          newData.borsinoSquareMeterEvaluation = {
            min: +values.borsinoSquareMeterEvaluation.min,
            max: +values.borsinoSquareMeterEvaluation.max,
            date,
          };
          newData.squareMeterEvaluation = squareMeterEvaluation
            ? {
              ...squareMeterEvaluation,
              date,
            }
            : undefined;
        }

        const updatedLead = await updateLead(leadId, newData);

        setIsSaving(false);
        mutate(updatedLead);
        addSuccess(SAVING_SUCCESS_MESSAGE);

        if (onSaveSuccess) {
          onSaveSuccess(updatedLead);
        }
      } catch (err) {
        setIsSaving(false);
        addError(SAVING_ERROR_MESSAGE);

        if (onSaveError) {
          onSaveError(SAVING_ERROR_MESSAGE);
        }
      }
    },
    [leadId, lead, onSaveSuccess, onSaveError, mutate, addSuccess, addError, getPlaceDetails],
  );

  if (leadError || addressSuggestionError || originAgentError) {
    return (
      <Message
        type="critical"
        message={LOADING_ERROR_MESSAGE}
      />
    );
  }

  if (!lead || !addressSuggestion) {
    return (
      <FormSkeleton />
    );
  }

  return (
    <LeadForm
      initialValues={toLeadFormModel(lead, addressSuggestion[0], originAgent)}
      loading={isSaving}
      onSubmit={onSubmit}
      onSubmitErrors={onSubmitErrors}
      innerRef={formRef}
      canSetAsPersonal={userIsAdmin || userIsCallCenter}
    />
  );
};

export default EditLead;
