import { formatEuro, formatSquareMeters, formatEnergyValue } from '@doveit/hammer';
import { ContentType } from '../../../../domain/types';
import { propertyStatusLabels } from '../../../../labels';
import { Content } from '../../../../providers/api/dtos/content';
import { Property } from '../../../../providers/api/dtos/property';
import { Room } from '../../../../providers/api/dtos/room';
import { featureTypeLabels, heatingTypeLabels } from '../../../labels';
import BuyerBrochureModel from '../../../pdf/buyer-brochure/models/buyerBrochure';
import {
  countRooms, formatFloorRange, getFloorRange, NO_VALUE_SYMBOL, DEFAULT_ADDRESS, DEFAULT_PHONE_NUMBER, DEFAULT_COMPANY_NAME,
} from '../../../utils';
import { Agent } from '../../../../providers/api/dtos';
import { getAgentName } from '../../../../agent/utils/utils';

function composeDescription(abstract?: string, composition?: string): string | null {
  let description = '';

  if (abstract) {
    description += abstract;
  }

  if (composition) {
    description = abstract ? `${abstract} ${composition}` : composition;
  }

  return description.length === 0 ? null : description;
}

export function toBuyerBrochure(
  property: Property,
  agent: Agent | undefined,
  contents: Content[],
  rooms: Room[],
): BuyerBrochureModel {
  return {
    logo: '/images/logo-magenta.png',
    refId: property.referenceId || NO_VALUE_SYMBOL,
    normalizedAddress: property.geo?.normalizedAddress || NO_VALUE_SYMBOL,
    price: property.price ? formatEuro(property.price) : NO_VALUE_SYMBOL,
    images: (contents || [])
      .filter((c) => c.contentType === ContentType.FOTO && c.url)
      .sort((a, b) => a.position! - b.position!)
      .slice(0, 2)
      .map((b) => b.url!),
    blueprints: (contents || []).filter((c) => c.contentType === ContentType.PLANIMETRIA && c.url).map((b) => b.url!),
    description: composeDescription(property.abstract, property.composition),
    details: [
      ['Quartiere', property.geo?.suburb || NO_VALUE_SYMBOL],
      ['Stato proprietà', property.propertyStatus ? propertyStatusLabels[property.propertyStatus] : NO_VALUE_SYMBOL],
      ['Tipo transazione', 'Proprietà intera'],
      ['Superficie', property.propertySize ? formatSquareMeters(property.propertySize) : NO_VALUE_SYMBOL],
      ['Locali', countRooms(rooms).toString()],
      ['Categoria catastale', property.registryCategory || NO_VALUE_SYMBOL],
      ['Riscaldamento', property.heating ? heatingTypeLabels[property.heating.type] : NO_VALUE_SYMBOL],
      ['Piano', `${formatFloorRange(getFloorRange(rooms))} di ${property.buildingFloors}`],
      ['Spese condominiali', property.additionalExpenses ? `${formatEuro(property.additionalExpenses)}/mese` : NO_VALUE_SYMBOL],
    ],
    energyClass: property.energyClass ? property.energyClass : undefined,
    energyValue: property.energyValue ? `${formatEnergyValue(property.energyValue)}` : NO_VALUE_SYMBOL,
    features: (property.features || []).map((f) => featureTypeLabels[f.type]),
    latitude: property.geo?.latitude || 0,
    longitude: property.geo?.longitude || 0,
    agent: {
      name: agent ? getAgentName(agent)! : NO_VALUE_SYMBOL,
      phone: agent?.mobile.work || NO_VALUE_SYMBOL,
      email: agent?.email.work || NO_VALUE_SYMBOL,
    },
    company: {
      name: agent?.agencies?.[0]?.name || DEFAULT_COMPANY_NAME,
      address: agent?.agencies?.[0]?.address.normalizedAddress || DEFAULT_ADDRESS,
      phone: agent?.agencies?.[0]?.phoneNumber || DEFAULT_PHONE_NUMBER,
    },
  };
}
