import { Badge } from '@doveit/bricks';
import { capitalizeWords } from '@doveit/hammer';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { EvaluationType } from '../../domain/types';
import { evaluationTypeLabels } from '../../labels';
import { AgentArea } from '../../providers/api/dtos/agentArea';

const areasTableColumns: ColumnDef<AgentArea, string>[] = [
  {
    id: 'locality',
    header: 'Città',
    accessorKey: 'locality',
    accessorFn: ({ locality }) => capitalizeWords(locality),
  },
  {
    id: 'zipCode',
    header: 'CAP',
    accessorKey: 'zipCode',
    accessorFn: ({ zipCode }) => zipCode,
  },
  {
    id: 'evaluationType',
    header: 'Valutazione',
    accessorKey: 'evaluationType',
    cell: ({ getValue }) => (getValue() ? (
      <Badge
        label={evaluationTypeLabels[getValue() as EvaluationType]}
        color="info"
      />
    ) : null),
    meta: {
      filter: {
        type: 'select',
        options: evaluationTypeLabels,
      },
    },
  },
];

export default areasTableColumns;
