import { API_PROPERTIES_PATH, API_SHOOTING_PATH, getClient } from '../api';
import { Property, Shooting } from '../dtos';

export async function createShooting(shooting: Shooting): Promise<Shooting> {
  const client = await getClient();
  const { data } = await client.post<Shooting>(API_SHOOTING_PATH, shooting);
  return data;
}

export async function updateShooting(shooting: Shooting): Promise<Shooting> {
  const client = await getClient();
  const { data } = await client.put<Shooting>(`${API_SHOOTING_PATH}/${shooting.id}`, shooting);
  return data;
}

export async function getLatestShootingByPropertyId(propertyId: Property['id']): Promise<Shooting> {
  const client = await getClient();
  const { data } = await client.get(`${API_PROPERTIES_PATH}/${propertyId}/shooting`);
  return data;
}

export async function deleteShooting(shootingId: Shooting['id']): Promise<void> {
  const client = await getClient();
  await client.delete(`${API_SHOOTING_PATH}/${shootingId}`);
}
