export enum HeatingSupply {
  GAS = 'GAS',
  METANO = 'METANO',
  GPL = 'GPL',
  GASOLIO = 'GASOLIO',
  SOLARE = 'SOLARE',
  FOTOVOLTAICO = 'FOTOVOLTAICO',
  TELERISCALDAMENTO = 'TELERISCALDAMENTO',
  PELLET = 'PELLET',
  LEGNA = 'LEGNA',
  POMPA_DI_CALORE = 'POMPA_DI_CALORE',
}
