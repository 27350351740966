import {
  ThemedFunctor, SpacingName, getSpacing, getTypography, TypographyName, FontWeight,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const basic: ThemedFunctor = ({ theme, $active }) => css`
  ${getSpacing.padding(theme, SpacingName.SMALLER)}
  background-color: ${$active ? theme.palettes.neutrals.light : theme.palettes.neutrals.lightest};
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &:hover {
    background-color: ${theme.palettes.neutrals.light};
  }
`;

export const avatarContainer: ThemedFunctor = () => css`
  flex-shrink: 0;
  margin-right: 1rem;
`;

export const avatar: ThemedFunctor = ({ theme, $hasName }) => css`
  ${getTypography(theme, TypographyName.HEADING_4)}
  color: ${theme.palettes.neutrals.medium};
  background-color: ${theme.palettes.neutrals.lighter};
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${$hasName && `
    background-color: ${theme.palettes.brand.primary};
    color: ${theme.palettes.neutrals.lightest};
    font-weight: ${FontWeight.MEDIUM};
  `}
`;

export const primary: ThemedFunctor = () => css`
  flex-grow: 1;
  min-width: 0;
`;

export const secondary: ThemedFunctor = () => css`
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  gap: .3rem;
  padding: .25rem 0 0 1rem;
`;

export const message: ThemedFunctor = ({ theme, $unread }) => css`
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  ${getSpacing.marginTop(theme, SpacingName.SMALLEST)}
  color: ${theme.palettes.neutrals.dark};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${$unread && `
    font-weight: ${FontWeight.MEDIUM};
    color: ${theme.palettes.neutrals.darkest};
  `}
`;

export const date: ThemedFunctor = ({ theme, $unread }) => css`
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  color: ${theme.palettes.neutrals.dark};

  ${$unread && `
    font-weight: ${FontWeight.MEDIUM};
    color: ${theme.palettes.neutrals.darkest};
  `}
`;

export const unread: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  color: ${theme.palettes.neutrals.lightest};
  width: .5rem;
  height: .5rem;
  background-color: ${theme.palettes.brand.primary};
  border-radius: 100%;
`;
