import { ActionIcon, ICON_FILE_PDF_BOX, useNotifications } from '@doveit/bricks';
import React from 'react';
import { getOfferWizardPreviewPDF } from '../../../providers/api/offer/offerProvider';

export const PDF_PREVIEW_ERROR_MESSAGE = "Non è stato possibile generare l'anteprima della proposta";

export interface ChildFnProps {
  onClick: VoidFunction,
  isLoading?: boolean,
}

export interface PreviewOfferWizardPDFActionProps {
  intentId: string,
  children?: ({ onClick, isLoading }: ChildFnProps) => React.ReactNode,
}

const PreviewOfferWizardPDFAction: React.FC<PreviewOfferWizardPDFActionProps> = ({
  intentId,
  children,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { addError } = useNotifications();

  const onGeneratePreviewClick = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getOfferWizardPreviewPDF(intentId);

      setIsLoading(false);

      const fileURL = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      window.open(fileURL, '_blank');
    } catch (err) {
      addError(PDF_PREVIEW_ERROR_MESSAGE);
    }
  }, [addError, intentId]);

  return (
    children
      ? children({ onClick: onGeneratePreviewClick, isLoading })
      : (
        <ActionIcon
          label="Genera l'anteprima della proposta"
          icon={{ path: ICON_FILE_PDF_BOX }}
          size="S"
          emphasis="high"
          onClick={onGeneratePreviewClick}
          loading={isLoading}
          aria-label="Genera l'anteprima della proposta"
        />
      )
  );
};

export default PreviewOfferWizardPDFAction;
