import { ThemeDefinition } from '@doveit/bricks';
import type { Style } from '@react-pdf/types';
import { EnergyClass } from '../../../../domain/types';

export const wrapper: Style = {
  display: 'flex',
  flexDirection: 'row',
  marginHorizontal: -10,
  alignItems: 'center',
  alignContent: 'center',
};

export const box: Style = {
  width: '50%',
  paddingHorizontal: 10,
};

export const tag = (theme: ThemeDefinition, type: EnergyClass): Style => ({
  position: 'relative',
  height: 22,
  lineHeight: 1,
  backgroundColor: theme.palettes.energy[type],
  paddingTop: 2,
  paddingHorizontal: 12,
  display: 'flex',
  width: 110,
});

export const tagText: Style = {
  fontWeight: 'bold',
  color: 'white',
  fontSize: 14,
};

export const arrow = (theme: ThemeDefinition, type: EnergyClass): Style => ({
  backgroundColor: theme.palettes.energy[type],
  height: 16,
  width: 16,
  transform: 'rotate(45deg)',
  transformOrigin: '50% 50%',
  position: 'absolute',
  top: 3,
  right: -8,
});

export const label: Style = {
  fontWeight: 'bold',
  fontSize: 10,
};

export const value: Style = {
  fontSize: 10,
  marginTop: 3,
};
