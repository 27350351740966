import {
  ActionIcon,
  Form,
  FormActionsAlign,
  ICON_ARCHIVE_SYNC_OUTLINE,
  Modal,
  Portal,
  useModal,
  useNotifications,
  Text,
} from '@doveit/bricks';
import React, { useState, FunctionComponent, useCallback } from 'react';
import { IntentStatus } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Intent } from '../../../providers/api/dtos/intent';
import { updateIntent } from '../../../providers/api/intent/intentProvider';
import { NOT_ALLOWED_INTENT_STATUS_FOR_RECOVERY, NOT_ALLOWED_PROPERTY_STATUS_FOR_RECOVERY } from './constants';
import usePropertyPreview from '../../../property/hooks/use-property-preview/usePropertyPreview';

interface ChildFnProps {
  disabled: boolean,
  loading: boolean,
  recover: VoidFunction,
}

export const RECOVERY_INTENT_DEFAULT_NOTES = 'Interesse ripristinato';
export const RECOVERY_INTENT_SUCCESS_MESSAGE = "L'interesse è stato ripristinato";
export const RECOVERY_INTENT_ERROR_MESSAGE = "Non è stato possibile ripristinare l'interesse";

export interface RecoveryIntentProps {
  intent: Intent,
  disabled?: boolean,
  onSuccess?: (intent: Intent) => void,
  children?: (props: ChildFnProps) => React.ReactNode
}

const RecoveryIntent: FunctionComponent<RecoveryIntentProps> = ({
  intent,
  disabled = false,
  onSuccess,
  children,
}) => {
  const [isSaving, setSaving] = useState(false);
  const { user, userIsAgent, mainUserRole } = useRBAC();
  const confirmModal = useModal();
  const { addSuccess, addError } = useNotifications();
  const { data: property } = usePropertyPreview(intent.propertyId);

  const openConfirmModal = useCallback(
    () => confirmModal.open(),
    [confirmModal],
  );

  const onSubmit = useCallback(async (values: { notes: string }) => {
    setSaving(true);

    const text = values.notes
      ? `${RECOVERY_INTENT_DEFAULT_NOTES}: ${values.notes}`
      : RECOVERY_INTENT_DEFAULT_NOTES;

    try {
      const updatedIntent = await updateIntent(intent.id!, {
        ...intent,
        status: IntentStatus.IN_PROGRESS,
        notes: [
          {
            author: user!.name,
            role: mainUserRole!,
            date: new Date().toISOString(),
            text,
          },
          ...(intent.notes || []),
        ],
      });

      confirmModal.closeWithDelay();
      addSuccess(RECOVERY_INTENT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedIntent);
      }
    } catch (error) {
      addError(RECOVERY_INTENT_ERROR_MESSAGE);
    }

    setSaving(false);
  }, [intent, user, mainUserRole, confirmModal, addSuccess, onSuccess, addError]);

  if (
    !property
    || intent.status === IntentStatus.IN_PROGRESS
    || NOT_ALLOWED_PROPERTY_STATUS_FOR_RECOVERY.includes(property!.status)
    || (userIsAgent && NOT_ALLOWED_INTENT_STATUS_FOR_RECOVERY.includes(intent.status))
  ) {
    return null;
  }

  return (
    <>
      {children
        ? children({ disabled, loading: isSaving, recover: openConfirmModal })
        : (
          <ActionIcon
            label="Ripristina"
            icon={{ path: ICON_ARCHIVE_SYNC_OUTLINE }}
            disabled={disabled}
            onClick={openConfirmModal}
          />
        )}
      <Portal>
        <Modal
          open={confirmModal.isOpen}
          onCloseHandler={confirmModal.close}
          loading={!user}
        >
          <Modal.Header>
            <Text.H4>
              Ripristina interesse d&apos;acquisto
            </Text.H4>
          </Modal.Header>
          <Modal.Close />
          <Modal.Body padded>
            <Form
              initialValues={{ notes: '' }}
              onSubmit={onSubmit}
              loading={isSaving}
            >
              <Form.Item>
                <Form.TextArea
                  label="Note"
                  aria-label="Campo per inserire note aggiuntive sul ripristino dell'interesse d'acquisto"
                  name="notes"
                  placeholder="Inserisci eventuali note sul ripristino dell'interesse d'acquisto"
                />
              </Form.Item>
              <Form.Actions align={FormActionsAlign.RIGHT}>
                <Form.Submit label="Conferma" />
              </Form.Actions>
            </Form>
          </Modal.Body>
        </Modal>
      </Portal>
    </>
  );
};

export default RecoveryIntent;
