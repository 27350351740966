import { isAfter } from 'date-fns';
import * as Yup from 'yup';
import { InvoiceModel } from '../../models/invoiceModel';
import type { InvoiceFormProps } from './InvoiceForm';
import { FLOAT_REGEX, NUMBER_REGEX, PERCENTAGE_REGEX } from '../../../constants';

const ERROR_MESSAGES = {
  intValue: 'Il dato inserito deve essere un valore numerico intero, es. "457000"',
  floatValue: 'Il dato inserito deve essere un valore numerico, anche decimale',
  percentageValue: 'Il dato inserito deve essere una percentuale, es: "2.99"',
  requiredField: 'Il campo è obbligatorio',
  futureDate: 'La data deve essere nel futuro',
};

type InvoiceFormValidationSchemaOptions = Pick<InvoiceFormProps, 'hideForecastedDateField'>;

const invoiceFormValidationSchema = (options: InvoiceFormValidationSchemaOptions = {}): Yup.ObjectSchema<Omit<Partial<InvoiceModel>, 'collectedAt'>> => {
  const { hideForecastedDateField = false } = options;

  return Yup.object({
    soldPrice: Yup.string().matches(NUMBER_REGEX, ERROR_MESSAGES.intValue).required(ERROR_MESSAGES.requiredField),
    buyerFee: Yup.string().matches(FLOAT_REGEX, ERROR_MESSAGES.floatValue).required(ERROR_MESSAGES.requiredField),
    sellerFee: Yup.string().matches(FLOAT_REGEX, ERROR_MESSAGES.floatValue),
    agentFee: Yup.string().matches(PERCENTAGE_REGEX, ERROR_MESSAGES.percentageValue).required(ERROR_MESSAGES.requiredField),
    forecastedInvoiceDate: hideForecastedDateField
      ? Yup.string()
      : Yup.string()
        .test('should-be-in-the-future', ERROR_MESSAGES.futureDate, (value) => {
          if (!value) {
            return true;
          }

          return isAfter(new Date(value), new Date());
        })
        .required(ERROR_MESSAGES.requiredField),
  });
};

export default invoiceFormValidationSchema;
