import axios from 'axios';

export const REBRANDLY_API_URL = 'https://api.rebrandly.com';
export const REBRANDLY_API_KEY = process.env.REACT_APP_REBRANDLY_API_KEY || '';
export const REBRANDLY_WORKSPACE_ID = process.env.REACT_APP_REBRANDLY_WORKSPACE_ID || '';

export interface Link {
  shortUrl: string,
}

export async function getShortURL(url: string): Promise<string> {
  const headers = {
    'Content-Type': 'application/json',
    apikey: REBRANDLY_API_KEY,
    workspace: REBRANDLY_WORKSPACE_ID,
  };

  const { data } = await axios.post<Link>(`${REBRANDLY_API_URL}/v1/links`, {
    destination: url,
    domain: {
      fullName: 'dv.dove.it',
    },
  }, {
    headers,
  });

  return /^https?:\/\//.test(data.shortUrl)
    ? data.shortUrl
    : `https://${data.shortUrl}`;
}
