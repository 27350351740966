import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const signersWrapper: ThemedFunctor = () => css`
  width: 100%;
`;

export const signerItemDivider: ThemedFunctor = ({ theme }) => css`
  border-top: 1px solid ${theme.palettes.neutral[100]};
`;
