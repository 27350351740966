import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const flex: ThemedFunctor = ({ theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.remSize[75]};
`;

export const notesPopover: ThemedFunctor = ({ theme }) => css`
  min-width: 25rem;
  padding: ${theme.spaceUnits.desktop.SMALLER};
  background-color: ${theme.color.background.neutral.default.lowest};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border: 1px solid ${theme.color.border.neutral.default.high};
  border-radius: 4px;
`;
