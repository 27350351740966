import { AvatarColor } from '@doveit/bricks';
import { AgentPreviewStatus } from './types';
import { MembershipStatus } from '../../domain/types';

export const mapAgentPreviewStatusToColor: Record<AgentPreviewStatus, AvatarColor> = {
  [AgentPreviewStatus.UNDER_ACTIVATION]: 'info',
  [AgentPreviewStatus.UNDER_REVOCATION]: 'warning',
  [AgentPreviewStatus.REVOKED]: 'critical',
};

export const mapToAgentPreviewStatus: Record<MembershipStatus, AgentPreviewStatus | undefined> = {
  [MembershipStatus.UNDER_ACTIVATION]: AgentPreviewStatus.UNDER_ACTIVATION,
  [MembershipStatus.ACTIVE]: undefined,
  [MembershipStatus.UNDER_REVOCATION]: AgentPreviewStatus.UNDER_REVOCATION,
  [MembershipStatus.REVOKED]: AgentPreviewStatus.REVOKED,
};
