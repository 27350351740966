import React from 'react';
import { useParams } from 'react-router-dom';
import {
  ActionIconDropdown, Dropdown, HStack, Stack, Text,
} from '@doveit/bricks';
import RightColumnPageLayout from '../../../layouts/right-column-page-layout/RightColumnPageLayout';
import useContact from '../../hooks/use-contact/useContact';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { raise } from '../../../utils';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import AdditionalServicesWidget from '../../containers/additional-services-widget/AdditionalServicesWidget';
import OptInsWidget from '../../containers/opt-ins-widget/OptInsWidget';
import ContactAvatar from '../../components/contact-avatar/ContactAvatar';
import ProcessContact from '../../containers/process-contact/ProcessContact';
import ContactIntentsWidget from '../../containers/contact-intents-widget/ContactIntentsWidget';
import ContactRumorsWidget from '../../containers/contact-rumors-widget/ContactRumorsWidget';
import ContactWidget from '../../components/contact-widget/ContactWidget';
import ContactLeadsWidget from '../../containers/contact-leads-widget/ContactLeadsWidget';
import { LeadStatus, ProspectStatus, RumorStatus } from '../../../domain/types';
import ContactProspectsWidget from '../../containers/contact-prospects-widget/ContactProspectsWidget';
import ContactSearchCriteriaWidget from '../../containers/contact-search-criteria-widget/ContactSearchCriteriaWidget';
import { buildContactExistingMatchesNotice } from '../../../utils/notice/buildContactNotice';
import NoticeBoard from '../../../notice/components/notice-board/NoticeBoard';
import { useContactSimilarMatchesNoticesData } from '../../hooks/use-contact-similar-matches-notices-data/useContactSimilarMatchesNoticesData';
import ContactPropertiesWidget from '../../containers/contact-properties-widget/ContactPropertiesWidget';
import RightColumnPageSkeleton from '../../../components/page-skeleton/RightColumnPageSkeleton';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';

export const LOAD_CONTACT_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni del contatto';

const ViewContactPage: React.FC = () => {
  const { id } = useParams();
  const { userIsAdmin, userIsCallCenter, userIsAgent } = useRBAC();
  const isMobile = useIsDevice('mobile');

  const canActWithHighPrivileges = React.useMemo(() => userIsAdmin || userIsCallCenter || userIsAgent,
    [userIsAdmin, userIsCallCenter, userIsAgent]);

  const contactId = id || raise('Missing contact id');

  const {
    data: contact, isLoading: isContactLoading, mutate: mutateContact, error: contactError,
  } = useContact(contactId);

  const { data: contactToProcessNoticesData } = useContactSimilarMatchesNoticesData(contactId);

  const contactToProcessNotices = React.useMemo(() => (contactToProcessNoticesData && [buildContactExistingMatchesNotice(contactToProcessNoticesData, { isMobile })]) ?? [],
    [contactToProcessNoticesData, isMobile]);

  const canUserProcessContact = React.useMemo(() => userIsAdmin || userIsCallCenter, [userIsAdmin, userIsCallCenter]);

  const onContactUpdate = React.useCallback(() => {
    mutateContact();
  }, [mutateContact]);

  if (contactError) {
    return <GenericErrorPage title={LOAD_CONTACT_ERROR_MESSAGE} />;
  }

  if (!contact || isContactLoading) {
    return <RightColumnPageSkeleton />;
  }

  return contact && (
    <RightColumnPageLayout
      title={(
        <HStack
          data-testid="page-title"
          gap={200}
          wrap="nowrap"
        >
          <ContactAvatar size="M" />
          <div>
            <Text.H4 as="div">
              {contact.name || 'Contatto'}
            </Text.H4>
            <Text.Body as="div">
              {contact.phoneNumber || contact.email}
            </Text.Body>
          </div>
        </HStack>
      )}
      actions={canUserProcessContact && (
        <ActionIconDropdown
          label="Azioni sul contatto"
          aria-label="Azioni sul contatto"
        >
          <ProcessContact contact={contact}>
            {({ disabled, process }) => (
              <Dropdown.Option
                label="Processa"
                aria-label="Processa contatto"
                disabled={disabled}
                onClick={process}
              />
            )}
          </ProcessContact>
        </ActionIconDropdown>
      )}
      primarySide={(
        <ContactWidget
          contact={contact}
          hideViewAction
          onUpdate={onContactUpdate}
        />
      )}
      secondarySide={(
        <Stack gap={300}>
          <OptInsWidget
            contactId={contact.id!}
            canSeeDetails={canActWithHighPrivileges}
            canAdd={canActWithHighPrivileges}
            canDelete={canActWithHighPrivileges}
          />

          <AdditionalServicesWidget
            contactId={contact.id!}
            canSeeDetails={canActWithHighPrivileges}
            canAdd={canActWithHighPrivileges}
            canDelete={canActWithHighPrivileges}
          />
        </Stack>
      )}
      topContent={<NoticeBoard notices={contactToProcessNotices} />}
    >
      <ContactPropertiesWidget contactId={contactId} />
      <ContactProspectsWidget
        contactId={contactId}
        excludeProspectStatus={ProspectStatus.ASSIGNMENT_CREATED}
      />
      <ContactRumorsWidget
        contactId={contactId}
        excludeRumorStatus={RumorStatus.APPOINTMENT_SCHEDULED}
      />
      <ContactLeadsWidget
        contactId={contactId}
        excludeLeadStatus={LeadStatus.APPOINTMENT_SCHEDULED}
      />
      <ContactIntentsWidget contactId={contactId} />
      <ContactSearchCriteriaWidget contactId={contactId} />
    </RightColumnPageLayout>
  );
};

export default ViewContactPage;
