import { ShootingStatus } from '../domain/types';

export const shootingStatusLabels: Record<ShootingStatus, string> = {
  BOOKED: 'Prenotato',
  REVIEWED: 'Revisionato',
  DELIVERED: 'Consegnato',
  COMPLETED: 'Foto caricate',
  CANCELLED: 'Annullato',
  NOT_EXECUTED: 'Non eseguito',
};
