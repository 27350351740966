export const DEFAULT_PAGINATION_SIZE = 20;

export function setQueryParam(query: string, key: string, value: any): string {
  const params = new URLSearchParams(query);
  params.set(key, `${value}`);
  return params.toString();
}

export function setAllQueryParams(values: {
  [key: string]: any;
}): string {
  const params = new URLSearchParams();
  Object.entries(values).forEach(([key, value]) => params.set(key, `${value}`));
  return params.toString();
}

export function removeQueryParams(query: string, ...keys: string[]): string {
  const params = new URLSearchParams(query);
  keys.forEach((key) => params.delete(key));

  return params.toString();
}

export function composeURL(path: string, query: { [key: string]: any }) {
  const parsed = Object.fromEntries(Object.entries(query).filter(([_, value]) => value !== undefined));
  return `${path}?${new URLSearchParams(parsed).toString()}`;
}
