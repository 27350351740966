import useSWR from 'swr';
import Axios from 'axios';

export const BOWSER_URL = 'https://bowser-production-6rqp7ia4ya-ew.a.run.app/points';

export interface Points {
  _id: string;
  value: number;
}

export async function getPoints(): Promise<Points[]> {
  const { data } = await Axios.get<Points[]>(BOWSER_URL);
  return data;
}

export async function savePoints(user: string, value: number): Promise<Points> {
  try {
    const { data } = await Axios.get<Points>(`${BOWSER_URL}/${user}`);
    if (data.value >= value) {
      return data;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  const { data: updated } = await Axios.put(`${BOWSER_URL}/${user}/${value}`);
  return updated;
}

export function usePoints() {
  return useSWR(BOWSER_URL, () => getPoints());
}
