import { useNotifications } from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { AgentsPerArea } from '../../models/AgentsPerArea';
import { AreaType } from '../../../domain/types/areaType';
import AgentAreasAutocomplete from '../../components/agent-areas-autocomplete/AgentAreasAutocomplete';
import AgentAreasList from '../../components/agent-areas-list/AgentAreasList';
import {
  getAgentsPerArea, filterByZipCode, sortBy, noFilter,
} from '../../services/agentsPerArea';
import LoadingBlock from '../../../components/loading-block/LoadingBlock';
import { AreaSuggestion } from '../../../providers/public-api/dtos';

export interface SearchAgentAreasProps {
  areaType: AreaType;
}

export const LOADING_ERROR = 'Non è stato possibile caricare i dati!';

// @todo This component should be revised during https://doveit.atlassian.net/browse/DOVE-9757 epic
const SearchAgentAreas: FunctionComponent<SearchAgentAreasProps> = ({
  areaType,
}) => {
  const { addError } = useNotifications();

  const [isLoading, setIsLoading] = useState(false);
  const [suggestionSelected, setSuggestionSelected] = useState<string>();
  const [data, setData] = useState<AgentsPerArea[]>([]);

  const onSuggestionSelected = useCallback(async (areaSuggestion: AreaSuggestion | null) => {
    if (areaSuggestion === null) return;

    const { municipalityCodes, suggestion } = areaSuggestion;
    setIsLoading(true);
    setSuggestionSelected(suggestion);

    try {
      const agentsPerArea = await getAgentsPerArea(
        municipalityCodes,
        areaType === AreaType.ZIP_CODE ? filterByZipCode(suggestion) : noFilter,
        areaType === AreaType.ZIP_CODE ? sortBy('zipCode') : sortBy('locality'),
      );

      setData(agentsPerArea);
      setIsLoading(false);
    } catch (error) {
      setSuggestionSelected(undefined);
      setIsLoading(false);
      addError(LOADING_ERROR);
    }
  }, [addError, areaType]);

  return (
    <>
      <AgentAreasAutocomplete
        areaType={areaType}
        onSuggestionSelected={onSuggestionSelected}
      />

      {isLoading && (<LoadingBlock />)}

      { !isLoading && suggestionSelected && (
        <AgentAreasList
          areaType={areaType}
          data={data}
          dataFor={suggestionSelected}
        />
      )}
    </>
  );
};

export default SearchAgentAreas;
