import {
  ThemedFunctor, FontWeight, getSpacing, SpacingName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const table: ThemedFunctor = ({
  theme,
}) => css`
  width: 100%;

  th {
    font-weight: ${FontWeight.MEDIUM};
    text-align: left;
    white-space: nowrap;
  }

  th, td {
    ${getSpacing.padding(theme, [SpacingName.SMALLEST, SpacingName.SMALLER])}
    vertical-align: middle;
  }
`;
