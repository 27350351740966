import {
  Form, FormHandlers, FormProps,
} from '@doveit/bricks';
import React from 'react';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { membershipLegalRoleLabels } from '../../../labels';

export interface EditMembershipContractFormModel {
  iban: string,
  bic: string,
  swift: string,
  role: string,
  companyName: string,
  vatNumber: string,
}

export interface EditMembershipContractFormProps {
  initialValues?: Partial<EditMembershipContractFormModel>,
  innerRef?: React.MutableRefObject<FormHandlers>
  disabledFields?: Array<keyof EditMembershipContractFormModel>,
  loading?: FormProps<EditMembershipContractFormModel>['loading'],
  disabled?: FormProps<EditMembershipContractFormModel>['disabled'],
  onSubmit: FormProps<EditMembershipContractFormModel>['onSubmit'],
}

export const EDIT_MEMBERSHIP_CONTRACT_FORM_DEFAULT_VALUES: EditMembershipContractFormModel = {
  iban: '',
  bic: '',
  swift: '',
  role: '',
  companyName: '',
  vatNumber: '',
};

const EditMembershipContractForm: React.FC<EditMembershipContractFormProps> = (props) => {
  const {
    initialValues,
    disabledFields = [],
    innerRef,
    ...rest
  } = props;

  const mergedValues: EditMembershipContractFormModel = React.useMemo(() => ({
    ...EDIT_MEMBERSHIP_CONTRACT_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  const disabledFieldsMap: Partial<Record<keyof EditMembershipContractFormModel, boolean>> = React.useMemo(
    () => disabledFields.reduce((acc, curr) => ({
      ...acc,
      [curr]: true,
    }), {}), [disabledFields],
  );

  return (
    <Form
      {...rest}
      initialValues={mergedValues}
      innerRef={innerRef}
      aria-label="Modifica contrattualistica"
    >
      <Form.Fieldset legend="Firmatario collaborazione">
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Select
            name="role"
            label="Tipologia"
            disabled={disabledFieldsMap.role}
            options={asSelectOptions(membershipLegalRoleLabels)}
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Input
            name="companyName"
            label="Ragione sociale"
            placeholder="Es. Company S.R.L."
            disabled={disabledFieldsMap.companyName}
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Input
            name="vatNumber"
            label="Partita IVA"
            placeholder="Es. 86334519757"
            disabled={disabledFieldsMap.vatNumber}
          />
        </Form.Item>
      </Form.Fieldset>

      <Form.Fieldset legend="Conto corrente">
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Input
            name="iban"
            label="IBAN"
            placeholder="Es. IT60X0542811101000000123456"
            disabled={disabledFieldsMap.iban}
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Input
            name="bic"
            label="BIC"
            placeholder="Es. ABCDITMM123"
            disabled={disabledFieldsMap.bic}
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Input
            name="swift"
            label="Swift"
            placeholder="Es. SWIFTBANK123"
            disabled={disabledFieldsMap.swift}
          />
        </Form.Item>
      </Form.Fieldset>
    </Form>
  );
};

export default EditMembershipContractForm;
