import { JobApplicationStatus } from '../domain/types';

export type JobApplicationStatusMap = {
  [key in JobApplicationStatus]: string;
};

export const jobApplicationStatusLabels: JobApplicationStatusMap = {
  IN_PROGRESS: 'Aperta',
  ASSIGNED: 'Assegnata',
  OFFER: 'Proposta di assunzione',
  HIRED: 'Candidato assunto',
  KO_DUPLICATE: 'Candidatura duplicata',
  KO_NOT_ELIGIBLE: 'Non idoneo',
  KO_NOT_INTERESTED: 'Non interessato',
  KO_OFFER_REFUSED: 'Offerta rifiutata',
  KO_GEO: 'Geografia non coperta',
};
