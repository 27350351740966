import React from 'react';
import {
  Action,
  ActionIcon,
  FormHandlers,
  ICON_PENCIL_OUTLINE,
  ICON_PLAYLIST_CHECK,
  ModalSize,
  Portal,
  useModal,
} from '@doveit/bricks';
import { ActionIconSize } from '@doveit/bricks/dist/typings/components/action-icon/types';
import { Lead } from '../../../providers/api/dtos';
import EditLead from '../../../call-center/containers/edit-lead/EditLead';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { LeadStatus } from '../../../domain/types';
import { LEAD_KO_STATUSES } from '../../../contact/containers/view-contact-activities-summary/leads/constants';

export interface EditLeadActionProps {
  lead: Lead,
  size?: ActionIconSize,
  onSuccess?: (lead: Lead) => void,
  disabled?: boolean,
  children?: ({ disabled, edit }: { disabled?: boolean, edit: VoidFunction }) => React.ReactNode
}

export const EditLeadAction: React.FunctionComponent<EditLeadActionProps> = ({
  lead,
  size,
  onSuccess,
  disabled,
  children,
}) => {
  const editLeadModal = useModal<Lead>();
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;

  const isEdit = React.useMemo(() => lead.status === LeadStatus.APPOINTMENT_SCHEDULED || LEAD_KO_STATUSES.includes(lead.status), [lead.status]);

  const onInternalSuccess = React.useCallback((updatedLead: Lead) => {
    if (onSuccess) {
      onSuccess(updatedLead);
    }

    editLeadModal.close();
  }, [editLeadModal, onSuccess]);

  const onEditLeadClick = React.useCallback(() => {
    editLeadModal.open();
  }, [editLeadModal]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  return (
    <>
      {children
        ? children({ disabled, edit: onEditLeadClick })
        : (
          <ActionIcon
            label={isEdit ? 'Modifica' : 'Processa'}
            icon={{ path: isEdit ? ICON_PENCIL_OUTLINE : ICON_PLAYLIST_CHECK }}
            size={size}
            disabled={disabled}
            onClick={onEditLeadClick}
            aria-label={`Pulsante per ${isEdit ? 'modificare' : 'processare'} la lead`}
          />
        )}
      <Portal>
        <SimpleModal
          {...editLeadModal}
          title={`${isEdit ? 'Modifica' : 'Processa'} lead`}
          size={ModalSize.XLARGE}
          aria-label={`Modale per ${isEdit ? 'modificare' : 'processare'} la lead`}
          footer={(
            <Action
              label={`${isEdit ? 'Modifica' : 'Processa'} lead`}
              color="primary"
              emphasis="high"
              aria-label={`Pulsante per ${isEdit ? 'modificare' : 'processare'} la lead`}
              onClick={submitForm}
            />
          )}
        >
          <EditLead
            leadId={lead.id!}
            onSaveSuccess={onInternalSuccess}
            formRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};
