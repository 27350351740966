import {
  Form, FormActionsAlign, FormSubmitType,
} from '@doveit/bricks';
import React, {
  ChangeEvent, FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import { Department } from '../../../domain/types';
import JobPositionsSelect from '../../../job-position/containers/job-positions-select/JobPositionsSelect';
import { JobPosition } from '../../../providers/api/dtos';
import { provinceSelectOptions } from '../../../select-options';
import { BooleanString } from '../../../utils/boolean-string/booleanString';
import { experiencesRadioOptions } from './fieldPresets';
import { jobApplicationValidationSchema, salesJobApplicationValidationSchema } from './JobApplicationForm.schema';

export interface JobApplicationFormModel {
  jobPosition?: JobPosition,
  province?: string,
  experience?: string,
  agency?: BooleanString,
  license?: BooleanString,
  message?: string,
}

export interface JobApplicationFormProps {
  initialValues?: JobApplicationFormModel,
  onSubmit: FormSubmitType<JobApplicationFormModel>
  loading?: boolean,
}

const DEFAULT_INITIAL_VALUES: JobApplicationFormModel = {
  province: '',
  experience: '',
  agency: '',
  license: '',
  message: '',
};

const JobApplicationForm: FunctionComponent<JobApplicationFormProps> = ({ initialValues, onSubmit, loading }) => {
  const [selectedPosition, setSelectedPosition] = useState(initialValues?.jobPosition);

  const mergedInitialValues = useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...(initialValues || {}),
  }), [initialValues]);

  const isSales = useCallback(
    (jobPosition?: JobPosition) => jobPosition?.department === Department.SALES,
    [],
  );

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      loading={loading}
      validationSchema={selectedPosition && isSales(selectedPosition)
        ? salesJobApplicationValidationSchema
        : jobApplicationValidationSchema}
    >
      {({ setFieldValue, values }) => {
        const onRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
          const newJobPosition = e.target.value as any as JobPosition;
          setFieldValue('jobPosition', newJobPosition);

          if (isSales(selectedPosition) && !isSales(newJobPosition)) {
            setFieldValue('agency', DEFAULT_INITIAL_VALUES.agency);
            setFieldValue('license', DEFAULT_INITIAL_VALUES.license);
            setFieldValue('province', DEFAULT_INITIAL_VALUES.province);
          }

          setSelectedPosition(newJobPosition);
        };

        return (
          <>
            <Form.Item>
              <JobPositionsSelect
                name="jobPosition"
                label="Ruolo"
                aria-label="Campo per specificare il ruolo"
                value={values.jobPosition}
                onChange={onRoleChange}
                required
              />
            </Form.Item>
            {selectedPosition && isSales(selectedPosition) && (
              <Form.Item>
                <Form.Select
                  name="province"
                  label="Zona di riferimento"
                  aria-label="Campo per specifica la zona di riferimento"
                  options={provinceSelectOptions}
                />
              </Form.Item>
            )}
            <Form.Item>
              <Form.RadioButtonGroup
                name="experience"
                label="Livello di esperienza"
                aria-label="Campo per specificare l'esperienza nella compravendita immobiliare"
                itemSize={{ MD: 1 / 2, LG: 1 / 4 }}
                items={experiencesRadioOptions}
              />
            </Form.Item>
            {selectedPosition && isSales(selectedPosition) && (
              <>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.YesOrNotButtons
                    name="license"
                    label="Hai il patentino da agente?"
                    aria-label="Campo per specificare se si dispone di patentino"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.YesOrNotButtons
                    name="agency"
                    label="Attualmente lavora presso un'agenzia?"
                    aria-label="Campo per specificare se attualmente lavora presso un'agenzia"
                  />
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Form.TextArea
                name="message"
                label="Messaggio"
                aria-label="Campo per inserire il messaggio"
              />
            </Form.Item>
            <Form.Actions align={FormActionsAlign.RIGHT}>
              <Form.Submit label="Salva" />
            </Form.Actions>
          </>
        );
      }}
    </Form>
  );
};

export default JobApplicationForm;
