import React, {
  createContext, PropsWithChildren, useContext, useEffect, useMemo, useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useApplicationNotificationsCount from '../../hooks/use-application-notifications-count/useApplicationNotificationsCount';

export interface ApplicationNotificationsContextValue {
  newNotificationsCount: number,
  refreshNotificationsCount: VoidFunction,
  lastRetrieveFailed: boolean,
}

const DEFAULT_APPLICATION_NOTIFICATIONS_CONTEXT: ApplicationNotificationsContextValue = {
  newNotificationsCount: 0,
  refreshNotificationsCount: () => { },
  lastRetrieveFailed: false,
};

export const ApplicationNotificationsContext = createContext<ApplicationNotificationsContextValue>(DEFAULT_APPLICATION_NOTIFICATIONS_CONTEXT);

export const useApplicationNotificationsContext = () => useContext(ApplicationNotificationsContext);

export const ApplicationNotificationsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const [lastCount, setLastCount] = useState(0);
  const [lastRetrieveFailed, setLastRetrieveFailed] = useState(false);

  const shouldFetchWith = useMemo(() => (isAuthenticated ? { read: false } : undefined),
    [isAuthenticated]);

  const { data, mutate, error } = useApplicationNotificationsCount(shouldFetchWith);

  useEffect(() => {
    if (data) {
      setLastCount(data.count);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setLastRetrieveFailed(true);
    }
  }, [error]);

  const contextValue = useMemo(() => {
    if (!data && !error) {
      return {
        newNotificationsCount: lastCount,
        refreshNotificationsCount: mutate,
        lastRetrieveFailed,
      };
    }

    if (error) {
      return {
        newNotificationsCount: lastCount,
        refreshNotificationsCount: mutate,
        lastRetrieveFailed: true,
      };
    }

    return {
      newNotificationsCount: data!.count,
      refreshNotificationsCount: mutate,
      lastRetrieveFailed: false,
    };
  }, [data, error, lastCount, lastRetrieveFailed, mutate]);

  return (
    <ApplicationNotificationsContext.Provider value={contextValue}>
      {children}
    </ApplicationNotificationsContext.Provider>
  );
};
