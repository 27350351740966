import { getAgentName } from '../../../agent/utils/utils';
import { Agent } from '../../../providers/api/dtos/agent';
import { WhatsappMessage } from '../SendWhatsappAction';

export function rumorMessagesProvider(agent?: Agent): () => Promise<WhatsappMessage[]> {
  return async () => {
    const agentName = agent?.name ? getAgentName(agent) : '<nome agente>';
    const agentMobile = agent?.mobile.work ?? '<numero telefono agente>';
    const incipit = `Salve, sono ${agentName} di Dove.it`;

    return Promise.resolve([
      {
        id: '1',
        title: 'Presentazione azienda',
        text: `${incipit}, un'agenzia immobiliare nazionale che offre ai proprietari un servizio di alta qualità senza applicare commissioni sulla vendita, permettendo così un risparmio medio di oltre 7.000€.<br /><br />Ho notato che ha messo in vendita il suo immobile privatamente sui portali immobiliari e vorrei offrirle, gratuitamente e senza alcun impegno, una valutazione professionale della sua casa.<br /><br />Se avrà piacere di concedermi questa opportunità, potrò illustrarle di persona i numerosi servizi gratuiti di cui potrebbe beneficiare, tra cui:<br />- SERVIZIO FOTOGRAFICO e VIRTUAL TOUR;<br />- MARKETING per la promozione dell'immobile;<br />- CONSULENTE DEDICATO per l'intero processo di compravendita.<br /><br />Resto a disposizione per qualsiasi informazione e spero di poterla incontrare presto.<br /><br />Cordiali saluti`,
      },
      {
        id: '2',
        title: 'Presentazione azienda',
        text: `${incipit}, agenzia immobiliare con zero commissioni per il venditore (www.dove.it). La contatto in merito al suo immobile, per poterla aiutare seguendola passo dopo passo in tutte le fasi della compravendita.<br /><br />I servizi inclusi, senza costo per il venditore, sono foto e virtual tour professionali, pubblicità sui principali portali immobiliari e agente dedicato fino al rogito, con un risparmio medio per il venditore di oltre 7.000€.<br /><br />Mi faccia sapere se posso esserle di aiuto o se vuole maggiori informazioni.<br /><br />Cordiali saluti`,
      },
      {
        id: '3',
        title: 'Secondo messaggio di presentazione',
        text: 'Salve, le avevo scritto qualche giorno fa per offrirle, gratuitamente e senza impegno, la possibilità di fissare un appuntamento per valutare il suo immobile.<br /><br />Mi farebbe piacere incontrarla di persona per illustrarle i servizi di Dove.it e capire insieme se possiamo esserle di aiuto nella vendita.<br /><br />Resto a disposizione per qualsiasi informazione e in attesa di un suo gentile riscontro, le porgo cordiali saluti.',
      },
      {
        id: '4',
        title: 'Presentazione no agenzie',
        text: `${incipit}, un'agenzia immobiliare che opera a livello nazionale.<br /><br />Ho visto il suo annuncio e rispetto la sua decisione di vendere l'immobile senza agenzie. Tuttavia, mi permetto di contattarla perché posso offrirle un'opzione diversa: il supporto di un agente dedicato per la vendita del suo immobile, senza alcuna commissione.<br /><br />Se fosse interessato ad approfondire, sarei felice di spiegarle come funziona, senza alcun impegno da parte sua. Rimango a disposizione e la ringrazio per il suo tempo.<br /><br />Cordiali saluti`,
      },
      {
        id: '5',
        title: 'Riepilogo vantaggi chiamata non andata a buon fine',
        text: `${incipit}, la ringrazio per l'attenzione che mi ha dedicato. Le riepilogo brevemente i vantaggi che la nostra azienda mette a disposizione dei nostri clienti:<br />- ZERO COMMISSIONI per la parte venditrice;<br />- SERVIZIO FOTOGRAFICO e VIRTUAL TOUR gratuiti;<br />- MARKETING per la promozione dell'immobile;<br />- CONSULENTE DEDICATO per l'intero processo di compravendita.<br /><br />Le auguro di concludere la sua trattativa, ma resto a disposizione al ${agentMobile} per fissare eventualmente un appuntamento conoscitivo.<br /><br />A presto`,
      },
      {
        id: '6',
        title: 'Proposta valutazione per clientela',
        text: `${incipit}. La contatto in merito al suo immobile in vendita. Abbiamo diversi clienti che cercano casa nella sua zona e vorremmo capire se il suo soddisfa i loro requisiti.<br /><br />Sarò in zona per valutare un altro immobile e vorrei visionare anche il suo. La nostra agenzia Dove.it opera a ZERO COMMISSIONI quindi, in caso di vendita, non avrà alcun costo.<br /><br />Resto in attesa di un suo gentile riscontro.<br /><br />Cordiali saluti`,
      },
    ]);
  };
}
