import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import { AxiosError } from 'axios';
import { useNotifications } from '@doveit/bricks';
import { Applicant } from '../../../providers/api/dtos';
import ApplicantForm, { ApplicantFormModel } from '../../components/applicant-form/ApplicantForm';
import { updateApplicant } from '../../../providers/api/applicant/applicantProvider';

export interface EditApplicantProps {
  applicant: Applicant,
  onSuccess?: (applicant: Applicant) => void,
}

export const EDIT_APPLICANT_SUCCESS_MESSAGE = 'Candidato aggiornato correttamente';
export const EDIT_APPLICANT_ERROR_MESSAGE = 'Non è stato possibile aggiornare il candidato ';
export const EDIT_APPLICANT_CONFLICT_ERROR_MESSAGE = 'Non è stato possibile aggiornare il candidato con numero di telefono e email di un altro candidato già esistente';

const EditApplicant : FunctionComponent<EditApplicantProps> = ({
  applicant,
  onSuccess,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();
  const initialValues = useMemo(() => ({
    name: applicant.name,
    email: applicant.email || undefined,
    phoneNumber: applicant.phoneNumber || undefined,
    linkedIn: applicant.linkedIn || undefined,
  }), [applicant.email, applicant.linkedIn, applicant.name, applicant.phoneNumber]);

  const onSubmit = useCallback(async (values: ApplicantFormModel) => {
    setIsSaving(true);

    try {
      const updatedApplicant = await updateApplicant(
        applicant.id!,
        {
          ...applicant,
          name: values.name,
          phoneNumber: values.phoneNumber || undefined,
          email: values.email || undefined,
          linkedIn: values.linkedIn || undefined,
        },
      );

      setIsSaving(false);
      addSuccess(EDIT_APPLICANT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedApplicant);
      }
    } catch (error) {
      setIsSaving(false);
      if ((error as AxiosError<Applicant>).response?.status === 409) {
        addError(EDIT_APPLICANT_CONFLICT_ERROR_MESSAGE);
      } else {
        addError(EDIT_APPLICANT_ERROR_MESSAGE);
      }
    }
  }, [addError, addSuccess, applicant, onSuccess]);

  return (
    <ApplicantForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      loading={isSaving}
    />
  );
};

export default EditApplicant;
