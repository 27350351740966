/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as styles from './CountLabel.style';

export interface CountLabelProps extends React.AriaAttributes {
  count: number,
  label: string,
}

const CountLabel: React.FC<CountLabelProps> = ({
  count,
  label,
  ...rest
}) => (
  <div
    {...rest}
    css={styles.basic}
  >
    <strong>{count}</strong> <span>{label}</span>
  </div>
);

export default CountLabel;
