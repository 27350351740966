/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Action, FontWeight, HStack, Message, ModalSize, Portal, Spacing, Text, useModal,
} from '@doveit/bricks';
import { formatDate } from '@doveit/hammer';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Review } from '../../../providers/api/dtos';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { reviewUserTypeLabels } from '../../../labels';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import useTrustpilotServiceReview from '../../../trustpilot/hooks/use-trustpilot-service-review/useTrustpilotServiceReview';
import * as style from './ViewTrustpilotReviewAction.style';
import TrustpilotRating from '../../../trustpilot/components/trustpilot-rating/TrustpilotRating';

export const PROPERTY_REVIEW_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare la recensione';

interface ChildFnProps {
  openModal: VoidFunction,
}

export interface ViewTrustpilotReviewActionProps {
  review: Review,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const ViewTrustpilotReviewAction: React.FC<ViewTrustpilotReviewActionProps> = ({
  review,
  children,
}) => {
  const { userIsContentEditor, userIsAdmin } = useRBAC();
  const { data: agent, isLoading: isAgentLoading } = useAgentByPropertyId(review.propertyId);
  const isProspectAgentOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);

  const canShow = React.useMemo(() => userIsContentEditor || userIsAdmin || isProspectAgentOrManager, [userIsContentEditor, userIsAdmin, isProspectAgentOrManager]);

  const {
    data: readReview,
    isLoading: isReadReviewLoading,
    error: readReviewError,
  } = useTrustpilotServiceReview(canShow ? review.trustpilotId : undefined);

  const modal = useModal();

  const openModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  if (!canShow || isAgentLoading || isReadReviewLoading) {
    return null;
  }

  return (
    <>
      {children?.({ openModal }) ?? (
        <Action
          aria-label="view-trustpilot-review"
          label="Visualizza"
          onClick={openModal}
          size="XS"
        />
      )}
      <Portal>
        <SimpleModal
          isOpen={modal.isOpen}
          close={modal.close}
          aria-label="Modale di visualizzazione recensione"
          title={`Recensione ${reviewUserTypeLabels[review.userType].toLowerCase()} verificata su Trustpilot`}
          size={ModalSize.MEDIUM}
          loading={isReadReviewLoading}
          footer={(
            <Action
              label="Chiudi"
              emphasis="high"
              onClick={modal.close}
            />
          )}
        >
          {readReviewError && (
            <Message
              type="critical"
              message={PROPERTY_REVIEW_LOAD_ERROR_MESSAGE}
            />
          )}
          {readReview && (
            <>
              <HStack alignItems="end" justifyContent="space-between">
                <div>
                  <Text.Body fontWeight={FontWeight.MEDIUM}>
                    {readReview.consumer.displayName}
                  </Text.Body>
                  <HStack>
                    <TrustpilotRating stars={readReview.stars} size={16} />
                    <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                      {readReview.stars}
                    </Text.BodySmall>
                  </HStack>
                </div>
                <Text.BodySmall>
                  Ricevuta il {formatDate(new Date(readReview.createdAt))}
                </Text.BodySmall>
              </HStack>
              <Spacing margin={[400, 0, 0, 0]}>
                <div css={style.reviewText}>
                  <Text.Body fontWeight={FontWeight.MEDIUM}>
                    {readReview.title}
                  </Text.Body>
                  <Spacing margin={[200, 0, 0, 0]}>
                    <Text.Body>
                      {readReview.text}
                    </Text.Body>
                  </Spacing>
                </div>
              </Spacing>
            </>
          )}
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ViewTrustpilotReviewAction;
