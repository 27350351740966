import { PhoneCallEventDirection } from '../../domain/types';
import { EventRegistryProps } from '../components/event-registry/EventRegistry';
import { Call, CallMedium } from '../../providers/api/dtos/call';
import { EventRegistryType } from '../types';

const ANSWERED_STATUS = 'Telefonata';
const NO_ANSWER_STATUS = 'Non risponde';

export function callsToEventRegistryItems(calls: Call[]): EventRegistryProps['events'] {
  return calls.map((call) => {
    let type: EventRegistryType = call.direction === PhoneCallEventDirection.OUTBOUND
      ? EventRegistryType.PHONE_CALL_OUTBOUND
      : EventRegistryType.PHONE_CALL_INBOUND;

    if (call.medium === CallMedium.MOBILE) {
      type = EventRegistryType.MOBILE_CALL_OUTBOUND;
    }

    return {
      type,
      date: call.startedAt,
      duration: call.duration ?? null,
      status: call.answered ? ANSWERED_STATUS : NO_ANSWER_STATUS,
    };
  });
}
