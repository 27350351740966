/* eslint-disable react/no-unknown-property */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  Grid, Message, Skeleton, Stack,
} from '@doveit/bricks';
import { usePoints } from '../bowser';
import * as styles from './Leaderboard.style';

export const LOADING_ERROR_MESSAGE = 'Errore caricamento dati';

const Leaderboard: React.FC = () => {
  const { data, error } = usePoints();

  if (error) {
    return (
      <Message
        type="critical"
        message={LOADING_ERROR_MESSAGE}
      />
    );
  }

  if (!data) {
    return (
      <Stack>
        <Skeleton height="2rem" />
        {[...Array(5)].map(() => (
          <Grid gutter={150}>
            <Grid.Unit size={{ MD: 3 / 4 }}>
              <Skeleton />
            </Grid.Unit>
            <Grid.Unit size={{ MD: 1 / 4 }}>
              <Skeleton />
            </Grid.Unit>
          </Grid>
        ))}
      </Stack>
    );
  }

  return (
    <div>
      <h2 css={styles.header}>Classifica</h2>
      <ul css={styles.list}>
        {data.sort((a, b) => b.value - a.value).map((player) => (
          <li
            key={player._id}
            css={styles.item}
          >
            <span css={styles.name}>
              {player._id}
            </span>
            <strong css={styles.points}>
              &euro; {player.value / 1e3}K
            </strong>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Leaderboard;
