import { CommissionPrice, OfferWizardData, OfferCommissions } from '../../../../providers/api/dtos';
import { OfferWizardCommissionsFormModel } from './offer-wizard-commissions-form/OfferWizardCommissionsForm';

export const toOfferWizardData = (initialData: OfferWizardData, formValues: OfferWizardCommissionsFormModel): OfferWizardData => {
  const { type, value, buyerCommission } = formValues;

  const newCommissions: OfferCommissions = {
    ...initialData.commissions,
    noBuyerCommission: buyerCommission !== 'true',
    type,
    value: value.trim() ? parseFloat(value.trim()) : undefined,
  };

  return {
    ...initialData,
    commissions: newCommissions,
  };
};

export const toOfferWizardCommissionsFormModel = ({ commissions }: OfferWizardData): OfferWizardCommissionsFormModel => ({
  value: typeof commissions?.value !== 'undefined' ? `${commissions.value}` : '',
  type: commissions?.type || CommissionPrice.FIXED,
  buyerCommission: commissions?.noBuyerCommission ? 'false' : 'true',
});
