import { FirebaseError } from 'firebase/app';

export const firebaseErrorToMessage = (err: FirebaseError): string => {
  switch (err.code) {
    case 'messaging/permission-blocked':
      return 'Le notifiche sono disabilitate. Verifica di aver fornito le autorizzazioni necessarie nelle impostazioni del browser e/o di sistema.';
    default:
      return 'Si è verificato un errore';
  }
};
