import React from 'react';
import { Action, ICON_CREATION_OUTLINE, useNotifications } from '@doveit/bricks';
import { EntityRelatedGenerationRequest, PropertyTransformationContext, TextProcessing } from '../../providers/api/dtos/contentProcessing';
import { generateForEntity } from '../../providers/api/content-processing/contentProcessingProvider';

export const GENERATE_ENTITY_INFO_ERROR_MESSAGE = 'Non è stato possibile generare il contenuto';

interface ChildFnProps {
  disabled: boolean,
  generate: VoidFunction,
}

export interface GenerateEntityInfoProps<TEntityId = unknown> {
  entityId: TEntityId,
  context: PropertyTransformationContext,
  disabled?: boolean,
  children?: (props: ChildFnProps) => React.ReactNode,
  onClick?: VoidFunction,
  onSuccess?: (res: TextProcessing) => void,
  onError?: (err: Error) => void,
}

const GenerateEntityInfoAction: React.FC<GenerateEntityInfoProps> = ({
  entityId,
  context,
  disabled = false,
  children,
  onClick,
  onSuccess,
  onError,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { addError } = useNotifications();

  const generate = React.useCallback(async () => {
    try {
      setIsLoading(true);
      onClick?.();

      const generationRequest: EntityRelatedGenerationRequest = {
        context,
        entityId,
      };

      const generationResponse = await generateForEntity(generationRequest);

      setIsLoading(false);
      onSuccess?.(generationResponse);
    } catch (err) {
      setIsLoading(false);
      onError?.(err as Error);
      addError(GENERATE_ENTITY_INFO_ERROR_MESSAGE);
    }
  }, [addError, context, entityId, onClick, onError, onSuccess]);

  return children?.({ disabled, generate }) ?? (
    <Action
      label="Genera"
      aria-label="Genera il contenuto"
      disabled={disabled}
      title={disabled ? 'Non è possibile generare il contenuto' : 'Genera il contenuto utilizzando l\'AI'}
      loading={isLoading}
      iconLeft={{ path: ICON_CREATION_OUTLINE }}
      size="S"
      color="primary"
      onClick={generate}
    />
  );
};

export default GenerateEntityInfoAction;
