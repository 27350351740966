import {
  Action, FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Property } from '../../../providers/api/dtos';
import { updateProperty } from '../../../providers/api/property/propertyProvider';
import EditPropertyPrivacyForm, { EditPropertyPrivacyFormModel } from '../../components/edit-property-privacy-form/EditPropertyPrivacyForm';

interface ChildFnProps {
  edit: VoidFunction,
  isSaving: boolean,
}

export interface EditPropertyPrivacyActionProps {
  property: Property,
  onSuccess?: (updatedProperty: Property) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_PROPERTY_PRIVACY_SUCCESS_MESSAGE = "Le impostazioni di privacy dell'immobile sono state modificate con successo";
export const EDIT_PROPERTY_PRIVACY_ERROR_MESSAGE = "Non è stato possibile modificare le impostazioni di privacy dell'immobile";

const EditPropertyPrivacyAction: React.FC<EditPropertyPrivacyActionProps> = ({
  property,
  onSuccess,
  children,
}) => {
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onSubmit = React.useCallback(async (formValues: EditPropertyPrivacyFormModel) => {
    try {
      setIsSaving(true);

      const propertyToUpdate: Property = {
        ...property,
        privacy: formValues,
      };

      const updatedProperty = await updateProperty(propertyToUpdate.id!, propertyToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_PROPERTY_PRIVACY_SUCCESS_MESSAGE);
      modal.close();

      onSuccess?.(updatedProperty);
    } catch (error) {
      setIsSaving(false);
      addError(EDIT_PROPERTY_PRIVACY_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, modal, onSuccess, property]);

  return (
    <>
      {children
        ? children({
          edit: modal.open,
          isSaving,
        })
        : (
          <Action
            aria-label="Modifica le impostazioni di privacy dell'immobile"
            label="Modifica privacy"
            size="S"
            onClick={() => modal.open()}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Modifica impostazioni di privacy"
          aria-label="Modale per modificare le impostazioni di privacy dell'immobile"
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per modificare le impostazioni di privacy dell'immobile"
              onClick={submitForm}
            />
          )}
        >
          <EditPropertyPrivacyForm
            innerRef={formRef}
            initialValues={property.privacy}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditPropertyPrivacyAction;
