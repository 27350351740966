import React from 'react';
import { Form, FormActionsAlign, FormProps } from '@doveit/bricks';
import { AgentStatus, JobTitle } from '../../../domain/types';
import { Agency, Agent } from '../../../providers/api/dtos';
import { searchAgencies } from '../../../providers/api/agency/agencyProvider';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';

export interface EditAgentTeamFormModel {
  jobTitle: string,
  areaManager?: Agent,
  parent?: Agent,
  agencies: Agency[],
}

export interface EditAgentTeamFormProps {
  initialValues?: Partial<EditAgentTeamFormModel>,
  onSubmit: FormProps<EditAgentTeamFormModel>['onSubmit']
}

export const EDIT_AGENT_TEAM_FORM_DEFAULT_VALUES: EditAgentTeamFormModel = {
  jobTitle: '',
  agencies: [],
};

const EditAgentTeamForm: React.FC<EditAgentTeamFormProps> = ({
  initialValues = {},
  onSubmit,
}) => {
  const mergedInitialValues = React.useMemo(() => ({
    ...EDIT_AGENT_TEAM_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  const fetchSuggestions = React.useCallback(async (search: string) => {
    const { content } = await searchAgencies({
      q: search,
    });

    return content;
  }, []);

  const printAgenciesSuggestion = React.useCallback((agency: Agency) => agency.name, []);

  return (
    <Form
      initialValues={mergedInitialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {mergedInitialValues.jobTitle !== JobTitle.EXECUTIVE && (
        <Form.Item>
          <Form.AutoCompleteV2
            label="Agenzie"
            name="agencies"
            aria-label="Campo per specificare le agenzie"
            isMulti
            printSuggestion={printAgenciesSuggestion}
            suggestions={fetchSuggestions}
          />
        </Form.Item>
      )}

      <Form.Item size={{ MD: 1 / 2 }}>
        <AgentAutoComplete
          label="AM"
          name="areaManager"
          aria-label="Campo per specificare l'area manager"
          placeholder="Cerca per nome"
          status={AgentStatus.ACTIVE}
          jobTitles={[JobTitle.AREA_MANAGER, JobTitle.EXECUTIVE, JobTitle.BROKER]}
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 2 }}>
        <AgentAutoComplete
          label="Gestito da"
          name="parent"
          aria-label="Campo per specificare il manager"
          placeholder="Cerca per nome"
          status={AgentStatus.ACTIVE}
        />
      </Form.Item>

      <Form.Actions align={FormActionsAlign.RIGHT}>
        <Form.Submit
          label="Modifica"
          aria-label="Modifica dati"
        />
      </Form.Actions>
    </Form>
  );
};

export default EditAgentTeamForm;
