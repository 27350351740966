import React from 'react';
import * as Yup from 'yup';
import { FileMimeType, Language } from './types';
import GenericErrorPage from './pages/errors/generic/GenericErrorPage';

export const PASS_THROUGH_VALIDATION_SCHEMA = Yup.object({});

export const FISCAL_CODE_REGEX = /^([A-Za-z]{6}[0-9LMNPQRSTUVlmnpqrstuv]{2}[ABCDEHLMPRSTabcdehlmprst]{1}[0-9LMNPQRSTUVlmnpqrstuv]{2}[A-Za-z]{1}[0-9LMNPQRSTUVlmnpqrstuv]{3}[A-Za-z]{1})$|([0-9]{11})/;
export const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export const CAP_REGEX = /[0-9]{5}/;
export const NUMBER_REGEX = /^\d*$/;
export const FLOAT_REGEX = /^\d+(\.\d{1,2}){0,1}$/;
export const PERCENTAGE_REGEX = /^\d{1,2}(\.\d{1,2}){0,1}$/;
export const PERCENTAGE_WITHOUT_ZERO = /^([1-9]{1,2}[0]?|100)$/;
export const LATITUDE_REGEX = /^(\+|-)?(?:90(?:(?:\.0{1,20})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,20})?))$/;
export const LONGITUDE_REGEX = /^(\+|-)?(?:180(?:(?:\.0{1,20})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,20})?))$/;
export const YEARS_REGEX = /^\d{4}$/;
export const ZIP_CODE_REGEX = /^\d{5}$/;
export const FLOOR_REGEX = /^-?(0\.5|\d+)$/;

export const NavigationMobileHeight = '3rem';
export const NavigationDesktopHeight = '3.5rem';
export const NavigationInPageHeight = '3.5rem';

export const MAX_PAGED_SIZE = 99999;

export const DEFAULT_MARKER_SIZE = 48;
export const DEFAULT_MARKER_ICON = 24;

export const GMAP_API_KEY = process.env.REACT_APP_GMAP_API_KEY || 'AIzaSyAPaLYQvA3Hyj-UQwrJW-7Bl9eJfp_GA5E';

export const COUNTRY = 'IT';

// URL query params standard values
export const SHOW_DEED_MODAL_URL_PARAM_CONFIRM_VALUE = 'confirm';
export const SHOW_DEED_MODAL_URL_PARAM_EDIT_VALUE = 'edit';

// FileMimeType
export const FileMimeTypeExtensions: Record<FileMimeType, string[]> = {
  [FileMimeType.JPEG]: ['.jpeg', '.jpg'],
  [FileMimeType.PNG]: ['.png'],
  [FileMimeType.PDF]: ['.pdf'],
  [FileMimeType.WEBP]: ['.webp'],
  [FileMimeType.DOC]: ['.doc'],
  [FileMimeType.DOCX]: ['.docx'],
};

export const DOVE_LOGO_ASCII = `
  \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
  \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0#B#&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0&&&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
  \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0G555\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0B555B\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0#GB\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
  \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0G5P5\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0G55P\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
  \u00A0\u00A0\u00A0#BPP5PP#\u00A0G5P5\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0&BGPP5PPB#\u00A0\u00A0\u00A0\u00A0\u00A0GPP#\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0#PPP#\u00A0\u00A0\u00A0\u00A0&BGPP5PPB&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0&GB#\u00A0\u00A0\u00A0\u00A0\u00A0\u00A05PPPPPPPPG
  \u00A0&P55PB##BPPP5P5\u00A0\u00A0\u00A0\u00A0#P55GB##BP55P#\u00A0\u00A0\u00A0#555&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0P555#\u00A0\u00A0&P55GB##BG55P&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0#555#\u00A0\u00A0\u00A0\u00A0\u00A0555P#####\u00A0
  &555G\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0#5PP5\u00A0\u00A0\u00A0B555#\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0B555B\u00A0\u00A0\u00A0G55G\u00A0\u00A0\u00A0\u00A0\u00A0B5P5G\u00A0\u00A0&555#\u00A0\u00A0\u00A0\u00A0\u00A0&P55P\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0#5P5#\u00A0\u00A0\u00A0\u00A0\u00A0555G\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
  G5P5&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0P5P5\u00A0\u00A0\u00A0P55P\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0P55P\u00A0\u00A0\u00A0\u00A0P55#\u00A0\u00A0\u00A0#5P5P\u00A0\u00A0\u00A0G5PPPPPPPP5555B\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0#5P5#\u00A0\u00A0\u00A0\u00A0\u00A0555G\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
  B5P5#\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0P5P5\u00A0\u00A0\u00A0P55P\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0P55P\u00A0\u00A0\u00A0\u00A0&P55&\u00A0&555P\u00A0\u00A0\u00A0\u00A0G5P5G#BBB###&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0#5P5#\u00A0\u00A0\u00A0\u00A0\u00A0555G\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
  \u00A0P555#\u00A0\u00A0\u00A0\u00A0\u00A0#555G\u00A0\u00A0\u00A0#555G\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0G555&\u00A0\u00A0\u00A0\u00A0\u00A0&P5PBP55P\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0P55G&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0#5P5B\u00A0\u00A0\u00A0\u00A0\u00A0P55P&\u00A0\u00A0\u00A0\u00A0\u00A0
  \u00A0\u00A0BP555PGGP55PB\u00A0\u00A0\u00A0\u00A0\u00A0&G555PGGP555G&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0G5555G\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0BP55PPGGGPPP&\u00A0\u00A0\u00A0#GG#\u00A0\u00A0\u00A0\u00A0\u00A0P555PGB\u00A0\u00A0&P555PGGPG
  \u00A0\u00A0\u00A0\u00A0&#BBGBB#&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0&BBGGB#&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0&BG#\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0&#BBGBB#&\u00A0\u00A0\u00A0\u00A0\u00A0#GB&\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0&BBGB&\u00A0\u00A0\u00A0\u00A0&#BBGB#\u00A0
  \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
`;

export const LANGUAGE_LABELS: Record<Language, string> = {
  it: 'Italiano',
  en: 'Inglese',
};

export const INPUT_PLACEHOLDERS = {
  NAME: 'Es: Mario',
  AGENCY_NAME: 'Es: Agenzia Dove.it Milano',
  SURNAME: 'Es: Rossi',
  DOVE_IT_EMAIL: 'Es: mario.rossi@dove.it',
  PEC_EMAIL: 'Es: mario.rossi@pec.it',
  CONTACT_NAME_SURNAME: 'Es: Mario Rossi',
  CONTACT_PHONE_NUMBER: 'Es: +391234567890',
  CONTACT_EMAIL: 'Es: mario.rossi@gmail.com',
  CONTACT_BIRTHPLACE: 'Es: Roma',
  CONTACT_FISCALCODE: 'Es. RSSNMO80A01H501T',
  CONTACT_CITIZENSHIP: 'Es. Italiana',
  CONTACT_IDENTIY_CARD_NUMBER: 'Es. CA12345AA',
  CONTACT_PASSPORT_NUMBER: 'Es. YA1234567',
  CONTACT_DRIVER_LICENSE_NUMBER: 'Es. DD1234567X',
  CONTACT_IDENTIY_CARD_ISSUED_BY: 'Es. Ministero dell\'Interno',
  CONTACT_PASSPORT_ISSUED_BY: 'Es. Ministero degli Affari Esteri',
  CONTACT_DRIVER_LICENCE_ISSUED_BY: 'Es. Motorizzazione Civile',
  RESIDENCE_ADDRESS: 'Es. Via del Corso, 16, 00187, Roma',
  PROPERTY_PRICE: 'Es: 150.000',
  PROPERTY_MINIMUM_PRICE: 'Es: 130.000',
  PROPERTY_EVALUATION_PRICE: 'Es: 140.000',
  PROPERTY_RESIDUAL_MORTGAGE: 'Es. 50.000',
  PROPERTY_LIEN_VALUE: 'Es. 200.000',
  PROPERTY_ANNUAL_RENT_FEE: 'Es. 12.000',
  PROPERTY_ANNUAL_CONDOMINIUM_EXPENSES: 'Es. 3.000',
  PROPERTY_ADDRESS: 'Es. Via Borromei, 6, 20123, Milano',
  PROPERTY_PROVINCE: 'Es. Milano',
  PROPERTY_PLATE_CODE: 'Es. MI',
  PROPERTY_LOCALITY: 'Es. Milano',
  PROPERTY_ROUTE: 'Es. Via Borromei',
  PROPERTY_STREET_NUMBER: 'Es. 6',
  PROPERTY_POSTAL_CODE: 'Es. 20123',
  PROPERTY_BLOCK: 'Es. 2',
  PROPERTY_STAIRWELL: 'Es. A',
  PROPERTY_UNIT_SECTION: 'Es. SEZ 3',
  PROPERTY_UNIT_SHEET: 'Es. 50',
  PROPERTY_UNIT_PARCEL: 'Es. 115',
  PROPERTY_UNIT_SUBUNIT: 'Es. 2',
  PROPERTY_UNIT_CADASTRAL_INCOME: 'Es. 300',
  PROPERTY_ASSIGNMENT_CANCELLATION_FEE: 'Es. 5.000',
  COMPANY_NAME: 'Es. Dove.it S.R.L.',
  COMPANY_VAT_NUMBER: 'Es. 10461690967',
  COMPANY_ADDRESS: 'Es. Via Borromei, 6, 20123, Milano',
  AGENCY_OPENING_HOURS: 'Es. Lun-Ven 10-13 / 15.30-19.30\nSab 10-13',
};

export const NotFoundPage = () => <GenericErrorPage title="Errore 404. La pagina non esiste." />;
