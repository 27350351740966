import {
  Form, FormActionsAlign, FormHandlers, FormSubmitType, Message, Spacing,
} from '@doveit/bricks';
import React, { FunctionComponent, MutableRefObject } from 'react';
import { InvoiceModel } from '../../models/invoiceModel';
import IntentFormSchema from './InvoiceForm.schema';
import computeMonthYearSelectOptions from '../../../utils/time/generic-month-year-select-options/computeMonthYearSelectOptions';
import { isForecastedDateInThePast } from '../../containers/edit-invoice/utils';

export const EXPIRED_FORECASTED_INVOICE_DATE_WARNING_MESSAGE = 'La data di presunta fatturazione è scaduta. Aggiornala, selezionando una nuova data.';

const DEFAULT_INITIAL_VALUES: InvoiceModel = {
  agentFee: '',
  sellerFee: '',
  buyerFee: '',
  soldPrice: '',
  collectedAt: '',
  forecastedInvoiceDate: '',
};

export interface InvoiceFormProps {
  initialValues?: Partial<InvoiceModel>,
  loading?: boolean,
  disabled?: boolean,
  disableCollectedAt?: boolean,
  innerRef?: MutableRefObject<FormHandlers>,
  submitLabel?: string,
  hideAdministrativeFields?: boolean,
  hideForecastedDateField?: boolean,
  onSubmit: FormSubmitType<InvoiceModel>,
}

const InvoiceForm: FunctionComponent<InvoiceFormProps> = ({
  initialValues,
  disabled,
  disableCollectedAt,
  loading,
  innerRef,
  submitLabel = 'Salva',
  hideAdministrativeFields = false,
  hideForecastedDateField = false,
  onSubmit,
}) => {
  const initialValuesMerged: InvoiceModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...(initialValues || {}),
  }), [initialValues]);

  const isForecastedDatePast = React.useMemo(
    () => initialValuesMerged && isForecastedDateInThePast(initialValuesMerged.forecastedInvoiceDate),
    [initialValuesMerged],
  );

  return (
    <Form
      enableReinitialize
      innerRef={innerRef}
      initialValues={initialValuesMerged}
      onSubmit={onSubmit}
      validationSchema={IntentFormSchema({ hideForecastedDateField })}
      disabled={disabled}
      loading={loading}
    >
      <Form.Item>
        <Spacing margin={[0, 0, 100]}>
          <Message
            type="info"
            message="Le commissioni specificate <strong>non comprendono</strong> IVA."
          />
        </Spacing>
        {!hideForecastedDateField && isForecastedDatePast && (
          <Spacing margin={[100, 0, 0]}>
            <Message
              type="warning"
              message={EXPIRED_FORECASTED_INVOICE_DATE_WARNING_MESSAGE}
            />
          </Spacing>
        )}
      </Form.Item>
      <Form.Group>
        {!hideAdministrativeFields && (
          <Form.Item size={{ MD: 1 / 2 }}>
            <Form.Input
              name="soldPrice"
              label="Prezzo d'acquisto"
              aria-label="Campo per inserire il prezzo d'acquisto"
            />
          </Form.Item>
        )}
        {!hideForecastedDateField && (
          <Form.Item size={{ MD: 1 / 2 }}>
            <Form.Select
              name="forecastedInvoiceDate"
              label="Data di presunta fatturazione"
              aria-label="Campo per l'inserimento della data di presunta fatturazione"
              options={computeMonthYearSelectOptions()}
            />
          </Form.Item>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Input
            name="buyerFee"
            label="Commissione compratore (€)"
            aria-label="Campo per inserire la commissione del compratore in Euro"
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Input
            name="sellerFee"
            label="Commissione venditore (€)"
            aria-label="Campo per inserire la commissione del venditore in Euro"
          />
        </Form.Item>
      </Form.Group>
      {!hideAdministrativeFields && (
        <Form.Group>
          <Form.Item size={{ MD: 1 / 2 }}>
            <Form.Input
              name="agentFee"
              label="Commissione agente (%)"
              aria-label="Campo per inserire la percentuale di commissione dell'agente"
            />
          </Form.Item>
          <Form.Item size={1 / 2}>
            <Form.Input
              type="date"
              name="collectedAt"
              label="Data di incasso"
              aria-label="Campo per l'inserimento della data di incasso"
              disabled={disabled || disableCollectedAt}
            />
          </Form.Item>
        </Form.Group>
      )}
      {!innerRef && (
        <Form.Actions align={FormActionsAlign.RIGHT}>
          <Form.Submit label={submitLabel} />
        </Form.Actions>
      )}
    </Form>
  );
};

export default InvoiceForm;
