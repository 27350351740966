import React from 'react';
import { Skeleton } from '@doveit/bricks';
import Card from './Card';

export function InlineCardSkeleton() {
  return (
    <Card>
      <Card.Inline>
        <Card.Box>
          <Skeleton />
          <Skeleton width="90%" />
        </Card.Box>
        <Card.ColumnDivider />
        <Card.Box>
          <Skeleton width="30%" />
          <Skeleton width="50%" />
        </Card.Box>
        <Card.ColumnDivider />
        <Card.Box fit>
          <Skeleton
            width="2rem"
            height="2rem"
          />
        </Card.Box>
      </Card.Inline>
    </Card>
  );
}

/**
 * @deprecated
 */
export type WidgetCardSkeletonProps = React.HTMLAttributes<HTMLDivElement>;

/**
 * @deprecated User CardSkeleton instead
 */
export const WidgetCardSkeleton: React.FC<WidgetCardSkeletonProps> = (props) => (
  <Card {...props}>
    <Card.Header>
      <Card.Title>
        <Skeleton width="10rem" />
      </Card.Title>
    </Card.Header>
    <Card.Box>
      <Skeleton count={3} />
      <Skeleton width="80%" />
    </Card.Box>
  </Card>
);
