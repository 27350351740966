import { AxiosError } from 'axios';
import {
  BareFetcher, Revalidator, RevalidatorOptions,
} from 'swr';
import { PublicConfiguration } from 'swr/_internal';

export function swrOnErrorRetry(err: AxiosError<any>, _: string, config: Readonly<PublicConfiguration<any, any, BareFetcher<any>>>, revalidate: Revalidator, revalidateOpts: Required<RevalidatorOptions>) {
  const maxRetryCount = config.errorRetryCount;
  const currentRetryCount = revalidateOpts.retryCount;
  const { response } = err;

  if (
    (maxRetryCount && currentRetryCount > maxRetryCount)
              || (err.code === 'ERR_NETWORK')
              || (response?.data?.status >= 400 && response?.data?.status <= 499)
  ) {
    return;
  }

  // Exponential backoff
  const timeout = (
    (Math.random() + 0.5) ** (currentRetryCount < 8 ? currentRetryCount : 8)
  ) * config.errorRetryInterval;

  setTimeout(revalidate, timeout, revalidateOpts);
}
