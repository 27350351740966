import React, { FunctionComponent } from 'react';
import { Form, FormSubmitType } from '@doveit/bricks';
import { AgentStatus } from '../../domain/types';
import AgentAutoComplete from '../../containers/agent-autocomplete/AgentAutoComplete';
import { Agent } from '../../providers/api/dtos';

export interface AgentFilterFormModel {
  agent?: Agent
}

export interface AgentFilterFormProps {
  onSubmit: FormSubmitType<AgentFilterFormModel>,
  self?: Agent,
  startFrom?: string | number,
  status?: AgentStatus,
  excludeAgentsByEmail?: string[],
  coordinatedBy?: string,
}

const AgentFilterForm: FunctionComponent<AgentFilterFormProps> = ({
  onSubmit,
  startFrom,
  status,
  excludeAgentsByEmail = [],
  coordinatedBy,
}) => (
  <Form
    initialValues={{}}
    onSubmit={onSubmit}
  >
    {({ values }) => (
      <>
        <Form.Item>
          <AgentAutoComplete
            name="agent"
            aria-label="Cerca agenti"
            startFrom={startFrom}
            maxSuggestions={3}
            status={status}
            excludeAgentsByEmail={excludeAgentsByEmail}
            coordinatedBy={coordinatedBy}
          />
        </Form.Item>
        <Form.Item>
          <Form.Submit
            label="Aggiungi filtro"
            disabled={!values.agent}
            expanded
          />
        </Form.Item>
      </>
    )}
  </Form>
);

export default AgentFilterForm;
