import React from 'react';
import { AxiosError } from 'axios';
import { Lead } from '../../../providers/api/dtos';
import useContactLeads from '../use-contact-leads/useContactLeads';
import { LeadStatus } from '../../../domain/types';
import { getLeadAssignedAgent } from '../../../providers/api/lead/leadProvider';
import { ContactToProcessNoticeData } from '../../../providers/api/dtos/notice';
import useContact from '../use-contact/useContact';
import useContactMatches from '../use-contact-matches/useContactMatches';

export type UseContactToProcessNoticesDataResult = {
  data?: ContactToProcessNoticeData[],
  isLoading: boolean,
  errors?: Error[] | undefined,
  mutate: VoidFunction
};

export function useContactToProcessNoticesData(contactId?: string): UseContactToProcessNoticesDataResult {
  const [noticeData, setNoticeData] = React.useState<ContactToProcessNoticeData[]>([]);
  const [errors, setErrors] = React.useState<AxiosError[] | undefined>();
  const [isAssignmentLoading, setIsAssignmentLoading] = React.useState<boolean>(true);

  const handleError = React.useCallback((err: AxiosError) => {
    setErrors((prev) => {
      if (!prev) return [err];
      return [...prev, err];
    });
  }, []);

  const {
    data: contact,
    isLoading: isContactLoading,
    mutate: mutateContact,
  } = useContact(contactId, { onError: handleError });

  const {
    data: contactMatches,
    isLoading: areContactMatchesLoading,
    mutate: mutateContactMatches,
  } = useContactMatches(contact ? {
    email: contact.email,
    phoneNumber: contact.phoneNumber,
  } : undefined, { onError: handleError });

  const similarContacts = React.useMemo(
    () => contactMatches
      ?.filter((({ id }) => id !== contactId))
      ?.sort((b, a) => Date.parse(a.createdAt!) - Date.parse(b.createdAt!)),
    [contactId, contactMatches],
  );

  const {
    data: contactLeads,
    isLoading: areContactLeadsLoading,
    mutate: mutateContactLeads,
  } = useContactLeads(contactId!, undefined, { onError: handleError });

  const openContactLeads = React.useMemo(() => contactLeads?.filter(({ status }) => [
    LeadStatus.IN_PROGRESS,
    LeadStatus.KO_ONLY_EVALUATION].includes(status)), [contactLeads]);

  React.useEffect(() => {
    async function computeNotices() {
      const internalNoticeData: ContactToProcessNoticeData[] = [];
      const nonAssignableLeads: Lead[] = [];
      const leadAssignmentPromises = openContactLeads
        ?.map((lead) => getLeadAssignedAgent(lead.id!)
          .catch((error) => {
            if (error instanceof AxiosError) {
              const errorStatus = error.response?.status;
              switch (errorStatus) {
                case 404:
                  break;
                case 412:
                  nonAssignableLeads.push(lead);
                  break;
                default:
                  handleError(error);
              }
            }
          })) ?? [];
      await Promise.all(leadAssignmentPromises);

      setIsAssignmentLoading(false);

      if (nonAssignableLeads.length > 0) {
        internalNoticeData.push({
          type: 'CONTACT_TO_PROCESS_NON_ASSIGNABLE_LEADS',
          referenceId: contactId!,
          data: {
            nonAssignableLeads,
          },
        });
      }

      if ((similarContacts?.length ?? 0) > 0) {
        internalNoticeData.push({
          type: 'CONTACT_TO_PROCESS_EXISTING_MATCHES',
          referenceId: contactId!,
          data: {
            contactMatches: similarContacts!,
          },
        });
      }

      setNoticeData(internalNoticeData);
    }

    computeNotices();
  }, [contactId, similarContacts, handleError, openContactLeads]);

  const isLoading = React.useMemo(
    () => isContactLoading
      || areContactLeadsLoading
      || isAssignmentLoading
      || areContactMatchesLoading,
    [
      isContactLoading,
      areContactLeadsLoading,
      isAssignmentLoading,
      areContactMatchesLoading,
    ],
  );

  const mutate = React.useCallback(async () => {
    Promise.all([
      mutateContact,
      mutateContactMatches,
      mutateContactLeads,
    ]);
  }, [mutateContact, mutateContactLeads, mutateContactMatches]);

  return {
    data: (!errors && !isLoading) ? noticeData : undefined,
    isLoading,
    errors,
    mutate,
  };
}
