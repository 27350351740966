export interface TemplateMap { [key: string]: any }

export const TEMPLATE_REGEX = /\{\w+\}/g;
export const TEMPLATE_BOUNDARIES_REGEX = /\{|\}/g;
export const TEMPLATE_HTML_TAG_REGEX = /<\/?[^>]+(>|$)/g;

export function truncateTextWithSuffix(text: string, limit: number, suffix: string = '...') {
  if (limit === 0) {
    return suffix;
  }

  if (text.length <= limit) {
    return text;
  }

  const truncatedText = text.substring(0, limit);
  const truncatedTextlastEmptyCharacterIndex = truncatedText.lastIndexOf(' ');

  if (truncatedTextlastEmptyCharacterIndex > 0 && text.charAt(truncatedText.length) !== ' ') {
    return `${truncatedText.substring(0, truncatedTextlastEmptyCharacterIndex)}${suffix}`;
  }

  return `${truncatedText}${suffix}`;
}

export function template(text: string, map: TemplateMap) {
  return text.replace(TEMPLATE_REGEX, (match) => map[match.replace(TEMPLATE_BOUNDARIES_REGEX, '')]);
}

export async function copyToClipboard(text: string) {
  await navigator.clipboard.writeText(text);
}

export function cleanText(text: string) {
  return text.replace(TEMPLATE_HTML_TAG_REGEX, '');
}

export function truncateHTMLTextWithSuffix(text: string, limit: number, suffix: string = '...') {
  const matches = [...text.matchAll(TEMPLATE_HTML_TAG_REGEX)];

  const cleanedText = cleanText(text);
  if (cleanedText.length <= limit) {
    return text;
  }

  let truncateText = truncateTextWithSuffix(cleanText(text), limit, '');

  matches.forEach((match) => {
    const tag = match[0];
    const position = match.index!;

    if (position <= truncateText.length) {
      truncateText = `${truncateText.substring(0, position)}${tag}${truncateText.substring(position)}`;
    }
  });

  return `${truncateText}${suffix}`;
}

export function getCharactersCount(text: string = '', limit = 250): string {
  return `${text.length}/${limit} caratteri`;
}

export function splitTextToNearestString(
  textToSplit?: string,
  searchString: string = '.',
  maxLength: number = 300,
): [] | [string] | [string, string] {
  if (!textToSplit) return [];

  if (textToSplit.length > maxLength) {
    let splitIndex = textToSplit.lastIndexOf(searchString, maxLength) + 1;

    if (splitIndex === 0 || splitIndex >= maxLength) {
      splitIndex = maxLength;
    }

    const firstPart = textToSplit.substring(0, splitIndex);
    const secondPart = textToSplit.substring(splitIndex).trim();

    return [firstPart, secondPart];
  }

  return [textToSplit];
}
