import {
  Action, FormHandlers, ModalSize, Portal, useModal,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Rumor } from '../../../providers/api/dtos';
import CreateRumor from '../create-rumor/CreateRumor';

type ChildFnProps = {
  createRumor: VoidFunction,
};

export interface CreateRumorActionProps {
  contactId?: Rumor['contactId'],
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (rumor: Rumor) => void,
}

const CreateRumorAction: React.FC<CreateRumorActionProps> = ({
  contactId,
  children,
  onSuccess,
}) => {
  const modal = useModal();
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;

  const onInternalSuccess = React.useCallback((rumor: Rumor) => {
    modal.close();
    onSuccess?.(rumor);
  }, [modal, onSuccess]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  return (
    <>
      {children
        ? children({
          createRumor: () => modal.open(),
        })
        : (
          <Action
            label="Aggiungi"
            aria-label="Aggiungi una nuova notizia"
            size="S"
            onClick={() => modal.open()}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Aggiungi notizia"
          size={ModalSize.LARGE}
          aria-label="Modale per aggiungere una nuova notizia"
          close={modal.close}
          footer={(
            <Action
              label="Aggiungi notizia"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per aggiungere una nuova notizia"
              onClick={submitForm}
            />
          )}
        >
          <CreateRumor
            contactId={contactId}
            onSuccess={onInternalSuccess}
            formRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default CreateRumorAction;
