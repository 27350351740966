import { ProspectApprovalStatus, ProspectStatus } from '../../../domain/types';
import { PaginatedResult, PaginationParams, serializePagination } from '../../pagination';
import { API_PROSPECTS_SERP_PATH, getClient } from '../api';
import { SerpProspect } from '../dtos';
import { serializeParams } from '../utils/serializer';

export interface SerpProspectProviderFilters {
  q?: string,
  status?: ProspectStatus | ProspectStatus[],
  toBeManaged?: boolean,
  latestAppointmentGt?: Date,
  latestAppointmentLt?: Date,
  approvalStatus?: ProspectApprovalStatus | ProspectApprovalStatus[],
  agentEmail?: string | string[],
}

export async function getSerpProspects(
  filters: SerpProspectProviderFilters = {},
  pagination: PaginationParams = { size: 20 },
): Promise<PaginatedResult<SerpProspect>> {
  const client = await getClient();

  const { data } = await client.get(API_PROSPECTS_SERP_PATH, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}
