import { IntentStatus } from '../../../domain/types';

export enum AdditionalIntentStatus {
  WITH_FUTURE_APPOINTMENT = 'WITH_FUTURE_APPOINTMENT',
  WITH_APPOINTMENT = 'WITH_APPOINTMENT',
  WITH_CANCELLED_APPOINTMENT = 'WITH_CANCELLED_APPOINTMENT',
  WITH_OFFER = 'WITH_OFFER',
}

export type UpdateIntentActionStatus = IntentStatus | AdditionalIntentStatus;

export enum IntentStatusAction {
  ADD_APPOINTMENT = 'ADD_APPOINTMENT',
  CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT',
  ADD_OFFER = 'ADD_OFFER',
  ARCHIVE_INTENT = 'ARCHIVE_INTENT',
  RESTORE_INTENT = 'RESTORE_INTENT',
}
