/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Action,
  Dropdown,
  ExpandableContent,
  FontWeight,
  ICON_CARD_ACCOUNT_DETAILS_OUTLINE,
  ICON_HANDSHAKE_OUTLINE,
  Message,
  Spacing,
  Card,
  Stack,
  HStack,
  BreakpointQueryName,
  ICON_ACCOUNT_MULTIPLE_OUTLINE,
  ICON_MAP_CHECK_OUTLINE,
  Text,
  DetailItemList,
} from '@doveit/bricks';
import { formatDate, pluralizeWord } from '@doveit/hammer';
import { isBefore } from 'date-fns';
import { capitalize } from 'lodash';
import hash from 'object-hash';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import * as styles from './ViewAgentPage.style';
import EditAgentRegistryAction from '../../containers/edit-agent-registry-action/EditAgentRegistryAction';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { membershipLegalRoleLabels, jobTitleLabels } from '../../../labels';
import { MembershipLegalRole, DescendantsStrategy, MembershipStatus } from '../../../domain/types';
import EditCoveredGeoAction from '../../containers/edit-covered-geo-action/EditCoveredGeoAction';
import EditAgentTeamAction from '../../containers/edit-agent-team-action/EditAgentTeamAction';
import UpdateAgentStatus from '../../containers/update-agent-status/UpdateAgentStatus';
import EditAgentContractAction from '../../containers/edit-agent-contract-action/EditAgentContractAction';
import AgentPreview from '../../components/agent-preview/AgentPreview';
import { toAgentPreviewProps } from '../../mappers/toAgentPreviewProps';
import { getCommissionSchema } from '../../utils/getCommissionSchema';
import { commissionSchemaLabels } from '../../../labels/commissionSchemaLabels';
import ViewAgentDescendantsAction from '../../containers/view-agent-descendants-action/ViewAgentDescendantsAction';
import { Agency, Agent } from '../../../providers/api/dtos';
import { JobTitle } from '../../../domain/types/jobTitle';
import EditAgentCommissionsAction from '../../containers/edit-agent-commissions-action/EditAgentCommissionsAction';
import EditAgentBioAction from '../../containers/edit-agent-bio-action/EditAgentBioAction';
import { AgentAvatar } from '../../components';
import { calculateMembershipStatus } from '../../utils/calculateMembershipStatus';
import { buildPrintableCommissionsDetails } from './mappers';
import { mapToAgentPreviewStatus } from '../../components/constants';
import { splitTextToNearestString } from '../../../utils/text/text';
import RightColumnPageLayout from '../../../layouts/right-column-page-layout/RightColumnPageLayout';
import EditAgentDetailsAction from '../../containers/edit-agent-details-action/EditAgentDetailsAction';
import AgencyPreview from '../../../agency/components/agency-preview/AgencyPreview';
import ViewAgentAgenciesAction from '../../containers/view-agent-agencies-action/ViewAgentAgenciesAction';
import MembershipStatusBadge from '../../components/membership-status-badge/MembershipStatusBadge';
import MigrateAgentDataAction from '../../containers/migrate-agent-data-action/MigrateAgentDataAction';
import RightColumnPageSkeleton from '../../../components/page-skeleton/RightColumnPageSkeleton';
import { getAgentName, getAgentRealName } from '../../utils/utils';

export const LOAD_AGENT_ERROR_MESSAGE = "Non è stato possibile caricare le informazioni dell'agente";

const ViewAgentPage: React.FC = () => {
  const { id: agentId } = useParams();
  const navigate = useNavigate();

  const { breakpoints } = useTheme();
  const isTabletOrBigger = useMediaQuery(breakpoints.up(BreakpointQueryName.LG));

  const {
    userIsAdmin, userIsHR, userIsContentEditor, userIsCallCenter,
  } = useRBAC();
  const {
    data: agent,
    isLoading: isAgentLoading,
    error: agentError,
    mutate: mutateAgent,
  } = useAgent(agentId, { descendantsStrategy: DescendantsStrategy.DIRECT });

  const { data: areaManager } = useAgent(agent?.areaManagerId);
  const { data: parent } = useAgent(agent?.idParent);

  const agentStatus = React.useMemo(() => {
    if (agent) {
      return calculateMembershipStatus(
        new Date(agent.contract.startDate),
        agent.contract.endDate ? new Date(agent.contract.endDate) : undefined,
      );
    }

    return undefined;
  }, [agent]);

  const isFranchising = React.useMemo(() => (agent?.agencies || []).some((agency) => agency.franchising), [agent?.agencies]);

  const internalMutateAgent = React.useCallback(async () => {
    await mutateAgent();
  }, [mutateAgent]);

  const goToAgentPage = React.useCallback((id: NonNullable<Agent['id']>) => () => {
    navigate(`/admin/agents/${id}`);
  }, [navigate]);

  const goToAgencyPage = React.useCallback((id: Agency['id']) => () => {
    navigate(`/admin/agencies/${id}`);
  }, [navigate]);

  const commissionsDetails = React.useMemo(() => buildPrintableCommissionsDetails(agent?.commissions), [agent]);

  const agenciesLabel = React.useMemo(() => {
    const agencies = (agent?.agencies || []);
    const agentIsBroker = agent?.jobTitle === JobTitle.BROKER;
    const selectedAgencyIsFranchising = agencies.some(({ franchising }) => franchising);

    return (agentIsBroker || selectedAgencyIsFranchising) ? 'Franchising' : 'Agenzia';
  }, [agent?.agencies, agent?.jobTitle]);

  const agentBio = React.useMemo(
    () => splitTextToNearestString(agent?.personalInfo?.bio, '.', 300),
    [agent?.personalInfo],
  );

  const showBioAction = React.useMemo(() => userIsAdmin || userIsHR || userIsContentEditor, [userIsAdmin, userIsContentEditor, userIsHR]);

  const formattedEndDate = React.useMemo(() => {
    const { endDate } = agent?.contract || {};

    if (!endDate) {
      return undefined;
    }

    const endDateValue = formatDate(new Date(endDate));

    return isBefore(new Date(endDate), new Date())
      ? endDateValue
      : (
        <Text.Body color="critical.default.low" fontWeight={FontWeight.REGULAR}>
          {endDateValue}
        </Text.Body>
      );
  }, [agent]);

  const showAgencyItem = React.useMemo(() => agent && (agent.jobTitle !== JobTitle.EXECUTIVE && (agent.agencies || []).length > 0), [agent]);

  if (agentError) {
    return <GenericErrorPage title={LOAD_AGENT_ERROR_MESSAGE} />;
  }

  if (isAgentLoading) {
    return <RightColumnPageSkeleton />;
  }

  return agent! && (
    <RightColumnPageLayout
      title={(
        <HStack gap={200}>
          <AgentAvatar
            photoId={agent.photoId}
            status={agentStatus && mapToAgentPreviewStatus[agentStatus]}
            needsSupervisor={agent.needsSupervisor}
            inTrial={agent.inTrial}
            size="M"
          />
          <div>
            <Text.H4
              fontWeight={FontWeight.LIGHT}
              as="div"
            >
              Dettaglio agente
            </Text.H4>
            <HStack>
              <Text.H3 as="div">
                {getAgentName(agent)}
              </Text.H3>
              {agent.realName && (
                <Text.H4
                  fontWeight={FontWeight.LIGHT}
                  as="div"
                >
                  ({getAgentRealName(agent)})
                </Text.H4>
              )}
            </HStack>
          </div>
        </HStack>
      )}
      actions={agentStatus && agentStatus === MembershipStatus.REVOKED && (
        <MigrateAgentDataAction agent={agent} onSuccess={internalMutateAgent} />
      )}
      primarySide={(
        <>
          {/** Agent team section */}
          <Card>
            <Card.Header
              primaryActions={isTabletOrBigger ? [
                (userIsAdmin || userIsHR) && (
                  <EditAgentDetailsAction
                    agent={agent}
                    onSuccess={internalMutateAgent}
                  />
                )] : []}
              secondaryActions={isTabletOrBigger
                ? undefined : [
                  (userIsAdmin || userIsHR) && (
                    <EditAgentDetailsAction
                      agent={agent}
                      onSuccess={internalMutateAgent}
                    >
                      {({ openEditModal, isSaving }) => (
                        <Dropdown.Option
                          label="Modifica"
                          aria-label="Modifica le informazioni dell'agente"
                          onClick={openEditModal}
                          loading={isSaving}
                        />
                      )}
                    </EditAgentDetailsAction>
                  )]}
              isSecondary
            >
              {(userIsAdmin || userIsHR) ? (
                <UpdateAgentStatus
                  agent={agent}
                  onSuccess={internalMutateAgent}
                  emphasis="high"
                  showIcon
                />
              ) : (
                <MembershipStatusBadge
                  status={agentStatus!}
                  emphasis="high"
                  showIcon
                />
              )}
            </Card.Header>
            <Card.Content aria-label="Sezione dello stato dell'agente">
              <DetailItemList>
                <DetailItemList.Item label="Data inizio">
                  {formatDate(new Date(agent.contract.startDate))}
                </DetailItemList.Item>
                {agent.contract.endDate && (
                  <DetailItemList.Item label="Data fine">
                    {formattedEndDate}
                  </DetailItemList.Item>
                )}
                <DetailItemList.Item
                  label="Ruolo"
                  aria-label="Dettaglio ruolo"
                >
                  {jobTitleLabels[agent.jobTitle]}{agent.inTrial && ' (trial)'}
                </DetailItemList.Item>
                <DetailItemList.BooleanItem
                  label="Patentino"
                  value={agent.hasLicense}
                />
                {[JobTitle.TEAM_LEADER, JobTitle.AGENTE].includes(agent.jobTitle) && (
                  <DetailItemList.BooleanItem
                    label="Approvazione manager"
                    value={agent.needsSupervisor}
                  />
                )}
                <DetailItemList.Item label="Sorgente">
                  {capitalize(agent.source)}
                </DetailItemList.Item>
              </DetailItemList>
            </Card.Content>
            <Card.Divider />
            <Card.Header
              title="Team"
              icon={{ path: ICON_ACCOUNT_MULTIPLE_OUTLINE }}
              primaryActions={isTabletOrBigger ? [
                (userIsAdmin || userIsHR) && (
                  <EditAgentTeamAction
                    agent={agent}
                    onSuccess={internalMutateAgent}
                  />
                ),
              ] : undefined}
              secondaryActions={[
                !isTabletOrBigger && (userIsAdmin || userIsHR) && (
                  <EditAgentTeamAction
                    agent={agent}
                    onSuccess={internalMutateAgent}
                  >
                    {({ openEditModal, isSaving }) => (
                      <Dropdown.Option
                        label="Modifica"
                        aria-label="Modifica le informazioni del team dell'agente"
                        onClick={openEditModal}
                        loading={isSaving}
                      />
                    )}
                  </EditAgentTeamAction>
                ),
                <ViewAgentDescendantsAction
                  agentId={agent.areaManagerId || agent.id!}
                  strategy={DescendantsStrategy.ALL}
                  showSelf
                >{({ viewDescendants }) => (
                  <Dropdown.Option
                    aria-label="Visualizza le informazioni del team dell'agente"
                    label="Visualizza"
                    onClick={viewDescendants}
                  />
                )}
                </ViewAgentDescendantsAction>,
              ]}
            />
            <Card.Content>
              <DetailItemList>
                {showAgencyItem && agent.agencies?.length === 1 && (
                  <DetailItemList.Item label={agenciesLabel} withoutPadding>
                    <AgencyPreview
                      name={agent.agencies[0].name}
                      franchising={agent.agencies[0].franchising}
                      onClick={goToAgencyPage(agent.agencies[0].id)}
                      size="S"
                      expanded
                    />
                  </DetailItemList.Item>
                )}
                {showAgencyItem && agent.agencies!.length > 1 && (
                  <DetailItemList.Item label={agenciesLabel} withoutPadding>
                    <ViewAgentAgenciesAction agent={agent}>
                      {({ viewAgencies }) => (
                        <Action
                          label="Vedi agenzie"
                          onClick={viewAgencies}
                          size="S"
                          badgeLabel={agent.agencies!.length}
                        />
                      )}
                    </ViewAgentAgenciesAction>
                  </DetailItemList.Item>
                )}
                <DetailItemList.Item
                  label="AM"
                  withoutPadding
                >
                  {areaManager
                    ? (
                      <AgentPreview
                        {...toAgentPreviewProps(areaManager)}
                        size="S"
                        onClick={goToAgentPage(areaManager.id!)}
                        expanded
                      />
                    )
                    : (
                      <EditAgentTeamAction
                        agent={agent}
                        onSuccess={internalMutateAgent}
                      >
                        {({ openEditModal }) => (
                          <AgentPreview
                            name="Non assegnato"
                            size="S"
                            onClick={openEditModal}
                            expanded
                          />
                        )}
                      </EditAgentTeamAction>
                    )}
                </DetailItemList.Item>
                <DetailItemList.Item
                  label="Gestito da"
                  withoutPadding
                >
                  {parent
                    ? (
                      <AgentPreview
                        {...toAgentPreviewProps(parent)}
                        size="S"
                        onClick={goToAgentPage(parent.id!)}
                        expanded
                      />
                    )
                    : (
                      <EditAgentTeamAction
                        agent={agent}
                        onSuccess={internalMutateAgent}
                      >
                        {({ openEditModal }) => (
                          <AgentPreview
                            name="Non assegnato"
                            size="S"
                            onClick={openEditModal}
                            expanded
                          />
                        )}
                      </EditAgentTeamAction>
                    )}
                </DetailItemList.Item>
                {(agent.descendants || []).length > 0 && (
                  <DetailItemList.Item label="Gestiti" withoutPadding>
                    <ViewAgentDescendantsAction
                      agentId={agent.id!}
                      strategy={DescendantsStrategy.DIRECT}
                      modalTitle="Consulenti gestiti"
                    >
                      {({ viewDescendants }) => (
                        <Action
                          badgeLabel={(agent.descendants || []).length}
                          label={`Vedi ${pluralizeWord('consulent', 'e', 'i', (agent.descendants || []).length)}`}
                          onClick={viewDescendants}
                          size="S"
                        />
                      )}
                    </ViewAgentDescendantsAction>
                  </DetailItemList.Item>
                )}
              </DetailItemList>
            </Card.Content>
          </Card>
        </>
      )}
      secondarySide={(
        <Card aria-label="Province coperte">
          <Card.Header
            title="Province coperte"
            icon={{ path: ICON_MAP_CHECK_OUTLINE }}
            primaryActions={isTabletOrBigger ? [
              (userIsAdmin || userIsHR) && (
                <EditCoveredGeoAction
                  agent={agent}
                  onSuccess={internalMutateAgent}
                />
              ),
            ] : undefined}
            secondaryActions={isTabletOrBigger
              ? undefined : [
                (userIsAdmin || userIsHR) && (
                  <EditCoveredGeoAction
                    agent={agent}
                    onSuccess={internalMutateAgent}
                  >
                    {({ openEditModal, isSaving, provinceAlreadyInserted }) => (
                      <Dropdown.Option
                        label={provinceAlreadyInserted ? 'Modifica' : 'Aggiungi'}
                        aria-label="Pulsante per modificare le province coperte"
                        onClick={openEditModal}
                        loading={isSaving}
                      />
                    )}
                  </EditCoveredGeoAction>
                ),
              ]}
          />

          <Card.Content>
            {(agent.coveredGeo || []).length === 0 && (
              <Message type="warning" message="Nessuna provincia coperta" />
            )}
            <Stack>
              {agent.coveredGeo?.map(({ plateCode, province, weight }) => (
                <HStack
                  key={plateCode}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text.Body>
                    {province}
                  </Text.Body>

                  <hr css={styles.coveredGeoDashedLine} />

                  <HStack>
                    <Text.Body
                      as="strong"
                      fontWeight={FontWeight.MEDIUM}
                    >
                      {Math.round(weight * 100).toFixed(0)}%
                    </Text.Body>
                  </HStack>
                </HStack>
              ))}
            </Stack>
          </Card.Content>
        </Card>
      )}
    >
      {/** Agent Registry section */}
      <Card>
        <Card.Header
          title="Anagrafica"
          icon={{ path: ICON_CARD_ACCOUNT_DETAILS_OUTLINE }}
          primaryActions={isTabletOrBigger ? [
            (userIsAdmin || userIsHR) && (
              <EditAgentRegistryAction
                agent={agent}
                onSuccess={internalMutateAgent}
              />
            ),
          ] : undefined}
          secondaryActions={isTabletOrBigger
            ? undefined : [
              (userIsAdmin || userIsHR) && (
                <EditAgentRegistryAction
                  agent={agent}
                  onSuccess={internalMutateAgent}
                >
                  {({ openEditModal, isSaving }) => (
                    <Dropdown.Option
                      label="Modifica"
                      title="Modifica l'anagrafica dell'agente"
                      aria-label="Modifica l'anagrafica dell'agente"
                      onClick={openEditModal}
                      loading={isSaving}
                    />
                  )}
                </EditAgentRegistryAction>
              ),
            ]}
        />

        <Card.Content>
          <DetailItemList
            columns={2}
            withoutDividers
          >
            <DetailItemList.Group label="Telefono">
              <DetailItemList.Item label="Lavorativo">
                {agent.mobile?.work}
              </DetailItemList.Item>
              <DetailItemList.Item label="Personale">
                {agent.mobile?.personal}
              </DetailItemList.Item>
            </DetailItemList.Group>

            <DetailItemList.Group label="Email">
              <DetailItemList.Item
                label="Lavorativa"
                inline={!agent.email?.work}
              >
                {agent.email?.work}
              </DetailItemList.Item>
              <DetailItemList.Item
                label="Personale"
                inline={!agent.email?.personal}
              >
                {agent.email?.personal}
              </DetailItemList.Item>
              <DetailItemList.Item
                label="PEC"
                inline={!agent.email?.pec}
              >
                {agent.email?.pec}
              </DetailItemList.Item>
            </DetailItemList.Group>
          </DetailItemList>

          <Spacing margin={[400, 0, 0]} />

          <DetailItemList
            columns={2}
            withoutDividers
          >
            <DetailItemList.Group label="Indirizzi">
              <DetailItemList.Item
                label="Residenza"
                inline={!agent.address?.residence?.normalizedAddress}
              >
                {agent.address?.residence?.normalizedAddress}
              </DetailItemList.Item>
              <DetailItemList.Item
                label="Invio materiale"
                inline={!agent.address?.shipping?.normalizedAddress}
              >
                {agent.address?.shipping?.normalizedAddress}
              </DetailItemList.Item>
            </DetailItemList.Group>

            <DetailItemList.Group label="Codice fiscale">
              <DetailItemList.Item>
                {agent.fiscalCode}
              </DetailItemList.Item>
            </DetailItemList.Group>
          </DetailItemList>
        </Card.Content>
        <Card.Divider />
        <div aria-label="Sezione della biografia dell'agente">
          <Card.Header
            title="Biografia"
            isSecondary
            primaryActions={isTabletOrBigger ? [
              showBioAction && (
                <EditAgentBioAction
                  agent={agent}
                  modalTitle={!agent.personalInfo?.bio ? 'Aggiungi biografia' : 'Modifica biografia'}
                  onSuccess={internalMutateAgent}
                >
                  {({ openEditModal, isSaving: isLoading }) => (
                    <Action
                      aria-label={!agent.personalInfo?.bio ? "Aggiungi la biografia dell'agente" : "Modifica la biografia dell'agente"}
                      label={!agent.personalInfo?.bio ? 'Aggiungi' : 'Modifica'}
                      onClick={openEditModal}
                      size="S"
                      loading={isLoading}
                    />
                  )}
                </EditAgentBioAction>
              ),
            ] : undefined}
            secondaryActions={isTabletOrBigger
              ? undefined : [
                showBioAction && (
                  <EditAgentBioAction
                    agent={agent}
                    modalTitle={!agent.personalInfo?.bio ? 'Aggiungi biografia' : 'Modifica biografia'}
                    onSuccess={internalMutateAgent}
                  >
                    {({ openEditModal, isSaving: isLoading }) => (
                      <Dropdown.Option
                        aria-label={!agent.personalInfo?.bio ? "Aggiungi la biografia dell'agente" : "Modifica la biografia dell'agente"}
                        label={!agent.personalInfo?.bio ? 'Aggiungi' : 'Modifica'}
                        onClick={openEditModal}
                        loading={isLoading}
                      />
                    )}
                  </EditAgentBioAction>
                ),
              ]}
          />
          <Card.Content>
            {agentBio.length === 0 && (
              <Message
                type="warning"
                message="La biografia non è presente"
              />
            )}
            {agentBio.length === 1 && agentBio[0]}
            {agentBio.length === 2 && (
              <>
                {agentBio[0]}
                <ExpandableContent openLabel="Mostra altro">
                  {agentBio[1]}
                </ExpandableContent>
              </>
            )}
          </Card.Content>
        </div>
      </Card>

      {/** Agent Contract section */}
      {
        !userIsCallCenter && (
          <Card aria-label="Sezione della contrattualistica dell'agente">
            <Card.Header
              title="Contrattualistica"
              icon={{ path: ICON_HANDSHAKE_OUTLINE }}
              primaryActions={isTabletOrBigger ? [
                ((userIsAdmin || userIsHR) && !agent.franchising) && (
                  <EditAgentContractAction
                    agent={agent}
                    onSuccess={internalMutateAgent}
                  />
                )] : undefined}
              secondaryActions={isTabletOrBigger
                ? undefined : [
                  ((userIsAdmin || userIsHR) && !agent.franchising) && (
                    <EditAgentContractAction
                      agent={agent}
                      onSuccess={internalMutateAgent}
                    >
                      {({ openEditModal, isSaving }) => (
                        <Dropdown.Option
                          label="Modifica"
                          title="Modifica la contrattualistica dell'agente"
                          aria-label="Pulsante per modificare la contrattualistica dell'agente"
                          onClick={openEditModal}
                          loading={isSaving}
                        />
                      )}
                    </EditAgentContractAction>
                  )]}
            />
            <Card.Content>
              {isFranchising && (
                <Spacing padding={[0, 0, 400]}>
                  <Message
                    type="info"
                    message={`Dati contrattuali del franchising ${agent.agencies?.[0].name}`}
                  />
                </Spacing>
              )}
              <DetailItemList
                columns={2}
                withoutDividers
              >
                <DetailItemList.Group label="Firmatario collaborazione">
                  <DetailItemList.Item label="Tipologia">
                    {agent.contract.legal?.role
                      ? membershipLegalRoleLabels[(agent.contract.legal.role) as MembershipLegalRole]
                      : undefined}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Ragione sociale">
                    {agent.contract.legal?.companyName}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Partita IVA">
                    {agent.contract.legal?.vatNumber}
                  </DetailItemList.Item>
                </DetailItemList.Group>

                <DetailItemList.Group label="Conto corrente">
                  <DetailItemList.Item
                    label="IBAN"
                    textToCopy={agent.contract.bankAccount?.iban}
                    inline={!agent.contract.bankAccount?.iban}
                  >
                    {agent.contract.bankAccount?.iban}
                  </DetailItemList.Item>
                  <DetailItemList.Item
                    label="BIC"
                    textToCopy={agent.contract.bankAccount?.bic}
                    inline={!agent.contract.bankAccount?.bic}
                  >
                    {agent.contract.bankAccount?.bic}
                  </DetailItemList.Item>
                  <DetailItemList.Item
                    label="SWIFT"
                    textToCopy={agent.contract.bankAccount?.swift}
                    inline={!agent.contract.bankAccount?.swift}
                  >
                    {agent.contract.bankAccount?.swift}
                  </DetailItemList.Item>
                </DetailItemList.Group>
              </DetailItemList>
            </Card.Content>
            <Card.Divider />
            <Card.Header
              title="Provvigioni"
              isSecondary
              primaryActions={isTabletOrBigger ? [
                ((userIsAdmin || userIsHR) && !agent.franchising) && (
                  <EditAgentCommissionsAction
                    agent={agent}
                    onSuccess={internalMutateAgent}
                  />
                ),
              ] : undefined}
              secondaryActions={isTabletOrBigger
                ? undefined : [
                  ((userIsAdmin || userIsHR) && !agent.franchising) && (
                    <EditAgentCommissionsAction
                      agent={agent}
                      onSuccess={internalMutateAgent}
                    >
                      {({ openEditModal, isSaving }) => (
                        <Dropdown.Option
                          label="Modifica"
                          aria-label="Modifica le provvigioni dell'agente"
                          title="Modifica le provvigioni dell'agente"
                          onClick={openEditModal}
                          loading={isSaving}
                        />
                      )}
                    </EditAgentCommissionsAction>
                  ),
                ]}
            />
            <Card.Content>
              <DetailItemList>
                <DetailItemList.Item label="Schema">
                  {agent.commissions
                    ? commissionSchemaLabels[getCommissionSchema(agent.commissions)]
                    : undefined}
                </DetailItemList.Item>
                <DetailItemList.Item label="Dettagli">
                  {commissionsDetails.length > 0
                    ? commissionsDetails.map((detail, i) => <span key={hash({ detail, i })}>{detail.label}: <strong>{detail.value}</strong>. </span>)
                    : undefined}
                </DetailItemList.Item>
                {agent.commissions?.notes && (
                  <DetailItemList.Item label="Note">
                    {agent.commissions.notes}
                  </DetailItemList.Item>
                )}
              </DetailItemList>
            </Card.Content>
          </Card>
        )
      }
    </RightColumnPageLayout>
  );
};

export default ViewAgentPage;
