import React from 'react';
import { Skeleton } from '@doveit/bricks';
import Card from '../../../components/card/Card';

const ContactActivityCardSkeleton = () => (
  <Card compact>
    <Card.Box>
      <Skeleton width="50%" />
    </Card.Box>
    <Card.Box>
      <Skeleton width="80%" />
    </Card.Box>
  </Card>
);

export default ContactActivityCardSkeleton;
