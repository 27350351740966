import { FeatureType, ResidentialPropertyType } from '../../domain/types';
import {
  Balcony, Garage, Property, Terrace,
} from '../../providers/api/dtos';
import { SearchCriteriaFormModel } from '../component/search-criteria-form/SearchCriteriaForm';

const LOWER_BOUND = 0.9;
const UPPER_BOUND = 1.1;

export function toSearchCriteriaFormModel(property: Property, balconies: Balcony[] = [], terraces: Terrace[] = [], garages: Garage[] = []): SearchCriteriaFormModel {
  return {
    propertyTypes: [property.propertyType as ResidentialPropertyType],
    propertyStatus: property.propertyStatus ? [property.propertyStatus] : [],
    price: {
      min: property.price ? (LOWER_BOUND * property.price).toString() : undefined,
      max: property.price ? (UPPER_BOUND * property.price).toString() : undefined,
    },
    size: {
      min: property.propertySize ? (LOWER_BOUND * property.propertySize).toString() : undefined,
      max: property.propertySize ? (UPPER_BOUND * property.propertySize).toString() : undefined,
    },
    balcony: !!balconies.length,
    terrace: !!terraces.length,
    garage: !!garages.length,
    privateGarden: !!property.privateGarden,
    features: {
      ASCENSORE: property.features.map(({ type }) => type).includes(FeatureType.ASCENSORE),
    },
  };
}
