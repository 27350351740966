import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const counterItem: ThemedFunctor = ({
  theme,
}) => css`
  display: flex;
  align-items: center;

  &:after {
    content: '';
    width: 1px;
    height: .75rem;
    display: block;
    background-color: ${theme.palettes.brand.primary};
    margin-left: .5rem;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
`;
