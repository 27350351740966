import React from 'react';
import {
  Action, HStack, Select, useNotifications,
} from '@doveit/bricks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InvoiceSection } from '../../types';
import { NavigationItem } from '../../../types';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import useProperties from '../../../property/hooks/use-properties/useProperties';
import { InvoiceStatus, INVOICEABLE_STATUSES } from '../../../domain/types';
import { SortOrder } from '../../../providers/pagination';
import usePagination from '../../../hooks/use-pagination/usePagination';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import PageSkeleton from '../../../components/page-skeleton/PageSkeleton';
import ViewPropertyWithInvoiceCard from '../../../property/containers/view-property-with-invoice-card/ViewPropertyWithInvoiceCard';
import { Invoice } from '../../../providers/api/dtos';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';

const invoiceProcessNavigation: NavigationItem[] = [
  {
    id: 'invoice-todo',
    label: 'Da fatturare',
    path: '/admin/invoices/todo',
  },
  {
    id: 'invoice-to-collect',
    label: 'Da incassare',
    path: '/admin/invoices/to-collect',
  },
  {
    id: 'invoice-collected',
    label: 'Incassate',
    path: '/admin/invoices/collected',
  },
];

const mapSectionToStatusFilter: { [key in InvoiceSection]: InvoiceStatus } = {
  [InvoiceSection.TODO]: InvoiceStatus.TODO,
  [InvoiceSection.TO_COLLECT]: InvoiceStatus.TO_COLLECT,
  [InvoiceSection.COLLECTED]: InvoiceStatus.COLLECTED,
};

export const mapSectionToNoResultMessage: { [key in InvoiceSection]: string } = {
  [InvoiceSection.TODO]: 'Non sono presenti immobili con pagamenti da effettuare',
  [InvoiceSection.TO_COLLECT]: 'Non sono presenti immobili con fatture da incassare',
  [InvoiceSection.COLLECTED]: 'Non sono presenti immobili con fatture incassate',
};

const sections = [InvoiceSection.TODO, InvoiceSection.TO_COLLECT, InvoiceSection.COLLECTED];

export const LOADING_ERROR_MESSAGE = 'Errore caricamento dati';
export const INVOICE_MOVED_UNDER_TO_COLLECT_TAB_MESSAGE = 'L\'immobile è stato spostato nella sezione "Da incassare"';
export const INVOICE_MOVED_UNDER_COLLECTED_TAB_MESSAGE = 'L\'immobile è stato spostato nella sezione "Incassate"';

const InvoiceProcessPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const section: InvoiceSection = React.useMemo(() => {
    if (!params.section) {
      return InvoiceSection.TODO;
    }

    return params.section as InvoiceSection;
  }, [params]);

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const { page, goToPage } = usePagination('page', scrollRef);
  const { addSuccess } = useNotifications();
  const { data: properties, error, mutate: mutateProperties } = useProperties({
    invoiceStatus: mapSectionToStatusFilter[section],
    status: INVOICEABLE_STATUSES,
  }, {
    size: 20,
    page,
    sort: {
      id: SortOrder.DESC,
    },
  });

  React.useEffect(() => {
    if (!sections.includes(section)) {
      navigate('/admin/invoices/todo');
    }
  }, [navigate, section]);

  const isDesktop = useIsDevice('desktop');

  const tabItems = React.useMemo(() => invoiceProcessNavigation.map((item) => ({
    id: item.id,
    label: item.label,
    active: location.pathname === item.path,
    onClick: () => navigate(item.path!),
  })), [navigate, location.pathname]);

  const onInvoiceUpdate = React.useCallback((updatedInvoice: Invoice, previousStatus?: InvoiceStatus) => {
    mutateProperties();

    if (updatedInvoice.status === InvoiceStatus.TO_COLLECT && updatedInvoice.status !== previousStatus) {
      addSuccess(INVOICE_MOVED_UNDER_TO_COLLECT_TAB_MESSAGE);
    }

    if (updatedInvoice.status === InvoiceStatus.COLLECTED) {
      addSuccess(INVOICE_MOVED_UNDER_COLLECTED_TAB_MESSAGE);
    }
  }, [addSuccess, mutateProperties]);

  if (error) {
    return (<GenericErrorPage title={LOADING_ERROR_MESSAGE} />);
  }

  if (!properties) {
    return (
      <PageSkeleton />
    );
  }

  return (
    <GenericPageLayout>
      <GenericPageLayout.Bar>
        {isDesktop && (
          <HStack gap={100}>
            {tabItems.map((item) => (
              <Action
                key={item.id}
                label={item.label}
                aria-label={`Tab ${item.label}`}
                onClick={item.onClick}
                size="M"
                color={item.active ? 'primary' : 'neutral'}
              />
            ))}
          </HStack>
        )}
        {!isDesktop && (
          <Select
            value={tabItems.find((item) => item.active)?.label}
            aria-label="Seleziona un filtro della notizia (mobile)"
            options={tabItems.map(({ label }) => ({
              label,
              value: label,
            }))}
            size="S"
            onChange={({ target }) => tabItems.find((item) => item.label === target.value)?.onClick?.()}
          />
        )}

      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent innerRef={scrollRef}>
          <PaginatedList
            {...properties}
            emptyResultMessage={mapSectionToNoResultMessage[section]}
            goToPage={goToPage}
          >
            {properties.content.map((property) => (
              <ViewPropertyWithInvoiceCard
                key={property.id}
                property={property}
                onInvoiceUpdate={onInvoiceUpdate}
              />
            ))}
          </PaginatedList>
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default InvoiceProcessPage;
