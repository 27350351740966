import React from 'react';
import { AssignmentWizardStepProps } from '../types';
import { toAssignmentWizardData, toAssignmentWizardPackagesFormModel } from '../../../mappers/assignmentMapper';
import { useCurrentAgent } from '../../../../../hooks/use-agent/useAgent';
import { ExtraPackageConfig } from '../../../../../providers/api/dtos';
import AssignmentWizardPackagesForm, { AssignmentWizardPackagesFormModel } from './AssignmentWizardPackagesForm';
import { getAllowedDoveItPackages } from '../../../../../utils/extra-packages/getAllowedDoveitPackages';
import { DOVEIT_EXTRA_PACKAGES_DEFAULT } from '../../../../../domain/types';

const AssignmentWizardPackages: React.FC<AssignmentWizardStepProps> = ({
  initialData,
  loading = false,
  formRef,
  onSubmit,
}) => {
  const currentAgent = useCurrentAgent();

  const onInternalSubmit = React.useCallback((formValues: AssignmentWizardPackagesFormModel) => {
    const values = toAssignmentWizardData(initialData, formValues);

    // TODO: Fix the type in bricks
    const isFormDirty = (formRef?.current as any)?.dirty ?? false;

    onSubmit(values, isFormDirty);
  }, [formRef, initialData, onSubmit]);

  const allowedDoveItPackages: ExtraPackageConfig[] = React.useMemo(() => {
    if (currentAgent) {
      return getAllowedDoveItPackages(currentAgent);
    }
    return DOVEIT_EXTRA_PACKAGES_DEFAULT;
  }, [currentAgent]);

  return (
    <AssignmentWizardPackagesForm
      initialValues={toAssignmentWizardPackagesFormModel(initialData)}
      innerRef={formRef}
      allowedDoveItPackages={allowedDoveItPackages}
      canAdd
      canEdit
      onSubmit={onInternalSubmit}
      loading={loading}
    />
  );
};

export default AssignmentWizardPackages;
