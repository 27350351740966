import React from 'react';
import { AssignmentWizardStepProps } from '../types';
import { toAssignmentWizardData, toAssignmentWizardWithdrawalFormModel } from './mappers';
import AssignmentWizardWithdrawalForm, { AssignmentWizardWithdrawalFormModel } from './AssignmentWizardWithdrawalForm';
import { useCurrentAgent } from '../../../../../hooks/use-agent/useAgent';

const AssignmentWizardWithdrawal: React.FC<AssignmentWizardStepProps> = ({
  initialData,
  loading = false,
  formRef,
  onSubmit,
}) => {
  const currentAgent = useCurrentAgent();
  const agencyConfig = React.useMemo(() => currentAgent?.agencies?.[0]?.configuration, [currentAgent]);
  const onInternalSubmit = React.useCallback((formValues: AssignmentWizardWithdrawalFormModel) => {
    const values = toAssignmentWizardData(initialData, formValues);

    const isFormDirty = (formRef?.current as any)?.dirty ?? false;

    onSubmit(values, isFormDirty);
  }, [formRef, initialData, onSubmit]);

  return (
    <AssignmentWizardWithdrawalForm
      initialValues={toAssignmentWizardWithdrawalFormModel(initialData, agencyConfig?.digitalAssignment)}
      formRef={formRef}
      onSubmit={onInternalSubmit}
      loading={loading}
    />
  );
};

export default AssignmentWizardWithdrawal;
