import { Spinner, SpinnerSize } from '@doveit/bricks';
import { LoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { GMAP_API_KEY } from '../../constants';
import { GoogleMapsContextProvider } from '../../contexts/google-maps-context/GoogleMapsContext';

const LoadGoogleMapsAPI: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const libraries: Libraries = useMemo(() => ['places'], []);

  return (
    <LoadScript
      id="google-maps-tag"
      googleMapsApiKey={GMAP_API_KEY}
      libraries={libraries}
      language="IT"
      loadingElement={(
        <Spinner
          size={SpinnerSize.MEDIUM}
          color="neutrals.light"
        />
    )}
    >
      <GoogleMapsContextProvider>
        {children}
      </GoogleMapsContextProvider>
    </LoadScript>
  );
};

export default LoadGoogleMapsAPI;
