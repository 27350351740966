import { Sellability } from './sellability';

export enum PlaceType {
  PROVINCE = 'PROVINCE',
  LOCALITY = 'LOCALITY',
  AREA = 'AREA',
}

export interface PlaceSuggestion {
  highlightedSuggestion: string,
  place: Place,
}

export interface Place {
  type: PlaceType,
  id?: string,
  name: string,
  slug: string,
  parent?: Place,
  squareMeterEvaluation?: number,
  sellability?: Sellability,
  plateCode?: string,
}
