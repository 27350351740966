import {
  BreakpointQueryName,
  FontWeight, getTypography, ThemedFunctor, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const messages: ThemedFunctor = ({ theme }) => css`
  margin-top: ${theme.remSize[300]};
  display: flex;
  flex-direction: column;
  gap: ${theme.remSize[150]};
`;

export const messageTitle: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.CAPTION, { fontWeight: FontWeight.MEDIUM })};
`;

export const message: ThemedFunctor = ({ theme, $skeleton, $hasWarning }) => css`
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  color: ${theme.color.foreground.neutral.default.low};
  background-color: ${theme.color.background.neutral.default.low};
  padding: ${theme.remSize[200]} ${theme.remSize[600]} ${theme.remSize[200]} ${theme.remSize[200]};
  border-radius: ${theme.remSize[100]};
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${theme.remSize[150]};
  transition: background-color .2s ease-in-out, color .2s ease-in-out; /** TODO: Create token for animation */

  ${!$skeleton && `
    &:hover {
      color: ${theme.color.foreground[$hasWarning ? 'warning' : 'primary'].hover.low};
      background-color: ${theme.color.background[$hasWarning ? 'warning' : 'primary'].hover.low};
    }
  `}
`;

export const overlay: ThemedFunctor = ({ theme }) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: ${theme.remSize[100]};
  padding: ${theme.remSize[200]};
  opacity: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    &:hover {
      opacity: 1;
    }
  }
`;

export const copy: ThemedFunctor = ({ theme }) => css`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${theme.remSize[100]};
  z-index: 2;
`;
