/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Action,
  Card, CardSkeleton, FontWeight, Grid, HStack, Icon, ICON_ALERT_CIRCLE_OUTLINE, ICON_VIDEO_OUTLINE, ICON_VIEW_GALLERY_OUTLINE, IframeWrapper, Message,
  Spacing,
  Text,
} from '@doveit/bricks';
import { Content, Property } from '../../../providers/api/dtos';
import useContentsByPropertyId from '../../hooks/use-contents-by-property-id/useContentsByPropertyId';
import { ContentType } from '../../../domain/types';
import ManageMultimediaContentsAction from '../../../content/containers/manage-multimedia-contents-action/ManageMultimediaContentsAction';
import PropertyGalleryPreview from '../../components/property-gallery-preview/PropertyGalleryPreview';
import * as styles from './PropertyMultimediaWidget.style';
import UpsertPropertyVideoAction from '../upsert-property-video-action/UpsertPropertyVideoAction';

export interface PropertyMultimediaWidgetProps {
  propertyId: NonNullable<Property['id']>,
  canSort?: boolean,
  canEdit?: boolean,
  canAdd?: boolean,
  canDelete?: boolean,
  canSeeMissingAboutError?: boolean,
}

const PropertyMultimediaWidget: React.FC<PropertyMultimediaWidgetProps> = ({
  propertyId,
  canSort = false,
  canEdit = false,
  canAdd = false,
  canDelete = false,
  canSeeMissingAboutError = false,
}) => {
  const {
    data: contents,
    error: contentsError,
    isLoading: isContentsLoading,
    mutate: mutateContents,
  } = useContentsByPropertyId(propertyId, [ContentType.FOTO, ContentType.PLANIMETRIA, ContentType.VIRTUAL_TOUR, ContentType.VIDEO]);

  const contentGrouped = React.useMemo(() => (contents || []).reduce<{
    photos: Content[];
    blueprints: Content[];
    video: Content | undefined;
    virtualTour: Content | undefined;
  }>(
    (acc, c) => {
      switch (c.contentType) {
        case ContentType.FOTO:
          acc.photos.push(c);
          break;
        case ContentType.PLANIMETRIA:
          acc.blueprints.push(c);
          break;
        case ContentType.VIRTUAL_TOUR:
          if (!acc.virtualTour) acc.virtualTour = c;
          break;
        case ContentType.VIDEO:
          if (!acc.video) acc.video = c;
          break;
        default:
          break;
      }
      return acc;
    },
    {
      photos: [], blueprints: [], video: undefined, virtualTour: undefined,
    },
  ), [contents]);

  if (isContentsLoading) {
    return (
      <CardSkeleton aria-label="Contenuti multimediali in caricamento" />
    );
  }

  if (contentsError) {
    return (
      <Card aria-label="Errore caricamento dei contenuti multimediali">
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Calendario"
          color="critical"
        />
        <Card.Content>
          <Message message="Non è stato possibile caricare i contenuti multimediali dell'immobile." />
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card aria-label="Contenuti multimediali">
      <Card.Header
        icon={{ path: ICON_VIEW_GALLERY_OUTLINE }}
        title="Contenuti multimediali"
      />
      <Card.Content>
        <Grid gutter={75}>
          <Grid.Unit size={{ MD: 1 / 2 }}>
            <ManageMultimediaContentsAction
              propertyId={propertyId}
              contentType={ContentType.FOTO}
              canSort={canSort}
              canEdit={canEdit}
              canAdd={canAdd}
              canDelete={canDelete}
              canSeeMissingAboutError={canSeeMissingAboutError}
              onContentChange={() => mutateContents()}
            >
              {({ openManageModal }) => (
                <>
                  <HStack alignItems="center" justifyContent="space-between">
                    <Text.Body fontWeight={FontWeight.REGULAR}>
                      Fotografie ({contentGrouped.photos.length})
                    </Text.Body>
                    <Action
                      aria-label="view-photo-gallery-action"
                      label={(canSort || canEdit || canAdd || canDelete) ? 'Gestisci' : 'Visualizza'}
                      onClick={() => openManageModal()}
                      size="S"
                    />
                  </HStack>
                  <Spacing margin={[150, 0, 0]}>
                    <PropertyGalleryPreview
                      aria-label="photo-gallery"
                      contents={contentGrouped.photos}
                      onContentClick={(index) => openManageModal('gallery', index)}
                      onAddClick={canAdd ? () => openManageModal('upload') : undefined}
                    />
                  </Spacing>
                </>
              )}
            </ManageMultimediaContentsAction>
          </Grid.Unit>
          <Grid.Unit size={{ MD: 1 / 2 }}>
            <ManageMultimediaContentsAction
              propertyId={propertyId}
              contentType={ContentType.PLANIMETRIA}
              canSort={canSort}
              canEdit={canEdit}
              canAdd={canAdd}
              canDelete={canDelete}
              canSeeMissingAboutError={canSeeMissingAboutError}
              onContentChange={() => mutateContents()}
            >
              {({ openManageModal }) => (
                <>
                  <HStack alignItems="center" justifyContent="space-between">
                    <Text.Body fontWeight={FontWeight.REGULAR}>
                      Planimetrie ({contentGrouped.blueprints.length})
                    </Text.Body>
                    <Action
                      aria-label="view-blueprint-gallery-action"
                      label={(canSort || canEdit || canAdd || canDelete) ? 'Gestisci' : 'Visualizza'}
                      onClick={() => openManageModal()}
                      size="S"
                    />
                  </HStack>
                  <Spacing margin={[150, 0, 0]}>
                    <PropertyGalleryPreview
                      aria-label="blueprint-gallery"
                      contents={contentGrouped.blueprints}
                      onContentClick={(index) => openManageModal('gallery', index)}
                      onAddClick={canAdd ? () => openManageModal('upload') : undefined}
                    />
                  </Spacing>
                </>
              )}
            </ManageMultimediaContentsAction>
          </Grid.Unit>
        </Grid>
        <Spacing margin={[300, 0, 0]}>
          <Grid gutter={75}>
            <Grid.Unit size={{ MD: 1 / 2 }}>
              <HStack alignItems="center" justifyContent="space-between">
                <Text.Body fontWeight={FontWeight.REGULAR}>
                  Virtual tour
                </Text.Body>
                {((contentGrouped.virtualTour && canEdit) || (!contentGrouped.virtualTour && canAdd)) && (
                  <UpsertPropertyVideoAction
                    aria-label="Azione per gestire il virtual tour dell'immobile"
                    label={contentGrouped.virtualTour ? 'Modifica' : 'Aggiungi'}
                    propertyId={propertyId}
                    contentType={ContentType.VIRTUAL_TOUR}
                    video={contentGrouped.virtualTour}
                    onSuccess={() => mutateContents()}
                  />
                )}
              </HStack>
              <Spacing margin={[150, 0, 0]}>
                {contentGrouped.virtualTour && (
                  <IframeWrapper
                    aria-label="Virtual tour"
                    src={contentGrouped.virtualTour.url!}
                    title="Virtual tour"
                    width={384}
                    height={288}
                  />
                )}
                {!contentGrouped.virtualTour && (
                  <div
                    css={styles.virtualTourPlaceholder}
                    aria-label="Virtual tour placeholder"
                    $width={384}
                    $height={288}
                  >
                    <div>
                      <Icon
                        path={ICON_VIDEO_OUTLINE}
                        size={48}
                      />
                    </div>
                    <div />
                  </div>
                )}
              </Spacing>
            </Grid.Unit>
            <Grid.Unit size={{ MD: 1 / 2 }}>
              <HStack alignItems="center" justifyContent="space-between">
                <Text.Body fontWeight={FontWeight.REGULAR}>
                  Video
                </Text.Body>
                {((contentGrouped.video && canEdit) || (!contentGrouped.video && canAdd)) && (
                  <UpsertPropertyVideoAction
                    aria-label="Azione per gestire il video dell'immobile"
                    label={contentGrouped.video ? 'Modifica' : 'Aggiungi'}
                    propertyId={propertyId}
                    contentType={ContentType.VIDEO}
                    video={contentGrouped.video}
                    onSuccess={() => mutateContents()}
                  />
                )}
              </HStack>
              <Spacing margin={[150, 0, 0]}>
                {contentGrouped.video && (
                  <IframeWrapper
                    aria-label="Video"
                    src={contentGrouped.video.url!}
                    title="Video"
                    width={384}
                    height={288}
                  />
                )}
                {!contentGrouped.video && (
                  <div
                    css={styles.virtualTourPlaceholder}
                    aria-label="Video placeholder"
                    $width={384}
                    $height={288}
                  >
                    <div>
                      <Icon
                        path={ICON_VIDEO_OUTLINE}
                        size={48}
                      />
                    </div>
                    <div />
                  </div>
                )}
              </Spacing>
            </Grid.Unit>
          </Grid>
        </Spacing>
      </Card.Content>
    </Card>
  );
};

export default PropertyMultimediaWidget;
