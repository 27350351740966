/* eslint-disable react/no-unknown-property */
import {
  ActionIcon,
  ICON_ARROW_UP,
  ICON_DOWNLOAD_OUTLINE,
  Typography,
  useCarousel,
} from '@doveit/bricks';
import React, { FunctionComponent } from 'react';
import { DocumentFile } from '../../../providers/api/dtos';
import DocumentFilePreview from '../document-file-preview/DocumentFilePreview';
import * as styles from './DocumentFilesGallery.styles';
import { FileMimeType } from '../../../types';

export interface DocumentFilesGalleryProps {
  dataRef?: string,
  files: DocumentFile[],
}

const DocumentFilesGallery: FunctionComponent<DocumentFilesGalleryProps> = ({
  dataRef = 'document-files-gallery',
  files,
}) => {
  const { activePosition, next, prev } = useCarousel({ totalElements: files.length, initialActivePosition: 0 });

  if (files.length === 0 || activePosition === undefined) {
    return null;
  }

  return (
    <div data-ref={dataRef}>
      <div css={styles.header}>
        {files.length > 1 && (
          <>
            <ActionIcon
              aria-label="Documento precedente"
              label="Documento precedente"
              size="S"
              onClick={prev}
              icon={{
                path: ICON_ARROW_UP,
                direction: 'left',
              }}
            />
            <div css={styles.status}>
              {activePosition + 1} / {files.length}
            </div>
            <ActionIcon
              aria-label="Documento successivo"
              label="Documento successivo"
              size="S"
              icon={{
                path: ICON_ARROW_UP,
                direction: 'right',
              }}
              onClick={next}
            />
          </>
        )}
        <Typography.BODY>
          {files[activePosition].filename}
        </Typography.BODY>
        {files[activePosition].mimeType !== FileMimeType.PDF && files[activePosition].url && (
          <ActionIcon
            icon={{ path: ICON_DOWNLOAD_OUTLINE }}
            size="S"
            label="Scarica"
            href={files[activePosition].url}
            target="_blank"
            download
          />
        )}
      </div>
      <DocumentFilePreview
        file={files[activePosition]}
        height="600px"
      />
    </div>
  );
};

export default DocumentFilesGallery;
