import { Skeleton } from '@doveit/bricks';
import React from 'react';
import Card from '../../../components/card/Card';

export function ViewIntentCardSkeleton() {
  return (
    <Card>
      <Card.Inline>
        <Card.Box>
          <Skeleton />
          <Skeleton width="90%" />
        </Card.Box>
        <Card.ColumnDivider />
        <Card.Box>
          <Skeleton />
        </Card.Box>
        <Card.ColumnDivider />
        <Card.Box fit>
          <Skeleton
            width="2rem"
            height="2rem"
          />
        </Card.Box>
      </Card.Inline>
    </Card>
  );
}
