import { DocumentType } from '../domain/types';

export const documentTypeLabels: Record<DocumentType, string> = {
  ASSIGNMENT: 'Incarico',
  IDENTITY_DOCUMENT: 'Doc. identità venditore',
  APE: 'APE',
  FISCAL_CODE: 'Codice fiscale',
  CADASTRAL_SURVEY: 'Visura catastale',
  PLANIMETRY: 'Planimetria',
  DEED_OF_PROVENANCE: 'Atto di provenienza',
  APARTMENT_BLOCK_RULES: 'Regolamento condominiale',
  PURCHASE_PROPOSAL: 'Proposta con accettazione',
  PENDING_PROPOSAL: 'Proposta',
  ANTI_MONEY_LAUNDERING: 'Antiriciclaggio',
  BUYER_IDENTITY_DOCUMENT: 'Doc. identità compratore',
  RESTYLING: 'Restyling',
  PROSPECT_REPORT: 'Report di valutazione',
  MORTGAGE_SURVEY: 'Visura ipotecaria',
  PLANIMETRY_AUTHORIZATION: 'Delega planimetria',
};
