import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const wrapper: ThemedFunctor = ({
  theme,
  $height,
}) => css`
  border-bottom: 1px solid ${theme.palettes.neutrals.dark};

  ${$height && `
    height: ${$height};
  `}

  iframe {
    display: block;
  }
`;
