import React from 'react';
import {
  Action, ActionIcon, FormHandlers, ICON_FILE_EDIT_OUTLINE, ModalSize, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import useAssignmentByPropertyId from '../../hooks/use-assignment-by-property-id/useAssignmentByPropertyId';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { computeExtraPackages, toPackagesFormModel } from '../../assignment/mappers/assignmentMapper';
import { Assignment, ExtraPackageConfig } from '../../../providers/api/dtos';
import { updateAssignment } from '../../../providers/api/assignment/assignmentProvider';
import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import AssignmentWizardPackagesForm, { AssignmentWizardPackagesFormModel } from '../../assignment/components/assignment-wizard/assignment-wizard-packages/AssignmentWizardPackagesForm';
import { getAllowedDoveItPackages } from '../../../utils/extra-packages/getAllowedDoveitPackages';
import { DOVEIT_EXTRA_PACKAGES_DEFAULT } from '../../../domain/types';

export interface EditAssignmentExtraPackagesActionProps {
  propertyId: number,
  canAdd?: boolean,
  canEdit?: boolean,
  onSuccess?: (updatedAssignment: Assignment) => void,
  children?: ({ openEditExtraPackagesModal }: { openEditExtraPackagesModal: VoidFunction }) => React.ReactNode;
}

export const SAVE_EXTRA_PACKAGES_ERROR_MESSAGE = 'Non è stato possibile modificare i pacchetti extra';
export const SAVE_EXTRA_PACKAGES_SUCCESS_MESSAGE = 'I pacchetti extra sono stati modificati con successo';

const EditAssignmentExtraPackagesAction: React.FC<EditAssignmentExtraPackagesActionProps> = ({
  propertyId,
  canAdd = true,
  canEdit = true,
  onSuccess,
  children,
}) => {
  const editExtraPackagesModal = useModal();
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const currentAgent = useCurrentAgent();

  const {
    data: assignment,
    isLoading: isAssignmentLoading,
    error: assignmentError,
  } = useAssignmentByPropertyId(propertyId);
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();

  const extraPackages = React.useMemo(() => toPackagesFormModel(assignment?.extraPackages || []), [assignment]);

  const openEditExtraPackagesModal = React.useCallback(() => {
    editExtraPackagesModal.open();
  }, [editExtraPackagesModal]);

  const onSubmit = React.useCallback(async ({ doveItPackages, spfPackages }: AssignmentWizardPackagesFormModel) => {
    setIsSaving(true);

    try {
      const updatedExtraPackages = computeExtraPackages(doveItPackages, spfPackages);
      const updatedAssignment = await updateAssignment({
        ...assignment!,
        extraPackages: updatedExtraPackages,
      });

      setIsSaving(false);
      addSuccess(SAVE_EXTRA_PACKAGES_SUCCESS_MESSAGE);
      editExtraPackagesModal.close();

      onSuccess?.(updatedAssignment);
    } catch (error) {
      setIsSaving(false);
      addError(SAVE_EXTRA_PACKAGES_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, assignment, editExtraPackagesModal, onSuccess]);

  const submitForm = React.useCallback(() => {
    formRef.current.handleSubmit();
  }, []);

  const allowedDoveItPackages: ExtraPackageConfig[] = React.useMemo(() => {
    if (currentAgent) {
      return getAllowedDoveItPackages(currentAgent);
    }

    return DOVEIT_EXTRA_PACKAGES_DEFAULT;
  }, [currentAgent]);

  if (isAssignmentLoading || assignmentError) {
    return null;
  }

  return (
    <>
      {children?.({ openEditExtraPackagesModal }) ?? (
        <ActionIcon
          label="Modifica"
          icon={{ path: ICON_FILE_EDIT_OUTLINE }}
          aria-label="Azione per modificare i pacchetti extra"
          onClick={openEditExtraPackagesModal}
        />
      )}
      <Portal>
        <SimpleModal
          {...editExtraPackagesModal}
          aria-label="Modifica i pacchetti extra"
          title="Modifica i pacchetti extra"
          size={ModalSize.MEDIUM}
          footer={(
            <Action
              label="Modifica"
              aria-label="Salva i pacchetti extra"
              color="primary"
              emphasis="high"
              onClick={submitForm}
              disabled={!canAdd && !canEdit}
            />
          )}
        >
          <AssignmentWizardPackagesForm
            initialValues={extraPackages}
            allowedDoveItPackages={allowedDoveItPackages}
            canAdd={canAdd}
            canEdit={canEdit}
            innerRef={formRef}
            loading={isSaving}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAssignmentExtraPackagesAction;
