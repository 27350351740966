import React from 'react';
import {
  Badge,
  Card, CardSkeleton, Dropdown, HStack, ICON_ACCOUNT_ARROW_RIGHT_OUTLINE, ICON_ALERT_CIRCLE_OUTLINE, Message,
  Stack,
} from '@doveit/bricks';
import { Contact, Lead } from '../../../providers/api/dtos';
import useContactLeads from '../../hooks/use-contact-leads/useContactLeads';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewContactLeadRow from './ViewContactLeadRow';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import { LeadStatus } from '../../../domain/types';
import { LEAD_ACTIVE_STATUSES, LEAD_KO_STATUSES } from '../view-contact-activities-summary/leads/constants';
import ViewContactLeadCard from '../view-contact-lead-card/ViewContactLeadCard';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import CreateLeadAction from '../../../lead/containers/create-lead-action/CreateLeadAction';

export const CONTACT_LEADS_ERROR_MESSAGE = 'Non è stato possibile recuperare le lead del contatto.';
export const EMPTY_CONTACT_LEADS = 'Non sono presenti lead associate al contatto.';

export interface ContactLeadsWidgetProps {
  contactId: NonNullable<Contact['id']>,
  excludeLeadStatus?: LeadStatus | LeadStatus[],
  onLeadUpdate?: (lead: Lead) => void,
}

const FILTERS = ['all', 'active', 'inactive'] as const;

type Filter = typeof FILTERS[number];

const filterLabels: Record<Filter, string> = {
  all: 'Tutte',
  active: 'Attive',
  inactive: 'Non attive',
};

const ContactLeadsWidget: React.FC<ContactLeadsWidgetProps> = ({
  contactId,
  excludeLeadStatus,
  onLeadUpdate,
}) => {
  const { userIsAdmin, userIsCallCenter, userIsFinance } = useRBAC();
  const [activeFilter, setActiveFilter] = React.useState<Filter>('all');

  const {
    data: contactLeads = [],
    isLoading: isContactLeadsLoading,
    error: contactLeadsError,
    mutate: mutateContactLeads,
  } = useContactLeads(contactId, { excludeStatus: excludeLeadStatus });

  const onInternalLeadUpdate = React.useCallback(async (lead: Lead) => {
    await mutateContactLeads();
    onLeadUpdate?.(lead);
  }, [mutateContactLeads, onLeadUpdate]);

  const isMobile = useIsDevice('mobile');

  const filteredContactLeads = React.useMemo(() => {
    let includeStatusFilter = Object.values(LeadStatus);
    switch (activeFilter) {
      case 'active':
        includeStatusFilter = LEAD_ACTIVE_STATUSES;
        break;
      case 'inactive':
        includeStatusFilter = LEAD_KO_STATUSES;
        break;
      default:
        break;
    }

    return contactLeads.filter(({ status }) => includeStatusFilter.includes(status));
  }, [activeFilter, contactLeads]);

  const sortedContactLeads = React.useMemo(
    () => filteredContactLeads && filteredContactLeads.sort((a, b) => Date.parse(b.updatedAt!) - Date.parse(a.updatedAt!)),
    [filteredContactLeads],
  );

  const canViewWidget = React.useMemo(() => userIsAdmin || userIsCallCenter || userIsFinance, [userIsAdmin, userIsCallCenter, userIsFinance]);

  if (isContactLeadsLoading) {
    return (
      <CardSkeleton aria-label="Sezione lead in caricamento">
        {!isMobile && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  if (contactLeadsError) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Lead"
          color="critical"
        />
        <Card.Content>
          <Message
            message={CONTACT_LEADS_ERROR_MESSAGE}
          />
        </Card.Content>
      </Card>
    );
  }

  return sortedContactLeads && canViewWidget && (
    <Card aria-label="Sezione lead">
      <Card.Header
        title="Lead"
        icon={{ path: ICON_ACCOUNT_ARROW_RIGHT_OUTLINE }}
        primaryActions={[
          (!isMobile && (userIsAdmin || userIsCallCenter) && (
            <CreateLeadAction
              aria-label="Pulsante per aggiungere una nuova lead"
              contactId={contactId}
              onSuccess={() => mutateContactLeads()}
            />
          )),
        ]}
        secondaryActions={[
          (isMobile && (userIsAdmin || userIsCallCenter) && (
            <CreateLeadAction
              aria-label="Pulsante per aggiungere una nuova lead"
              contactId={contactId}
              onSuccess={() => mutateContactLeads()}
            >
              {({ createLead }) => (
                <Dropdown.Option
                  aria-label="Pulsante per aggiungere una nuova lead"
                  label="Aggiungi"
                  onClick={createLead}
                />
              )}
            </CreateLeadAction>
          )),
        ]}
      />
      <Card.Content>
        <Stack gap={150} aria-label="Lista lead">
          {isMobile && sortedContactLeads.length > 0 && (
            sortedContactLeads.map((lead) => (
              <ViewContactLeadCard
                key={lead.id}
                lead={lead}
                onLeadUpdate={onInternalLeadUpdate}
              />
            ))
          )}
          {!isMobile && (
            <>
              {contactLeads.length > 0 && (
                <HStack gap={75} aria-label="Filtri">
                  {FILTERS.map((filter) => (
                    <Badge
                      key={filter}
                      label={filterLabels[filter]}
                      color={filter === activeFilter ? 'primary' : 'neutral'}
                      emphasis="low"
                      onClick={filter !== activeFilter ? () => setActiveFilter(filter) : undefined}
                    />
                  ))}
                </HStack>
              )}

              {sortedContactLeads.length > 0 && (
                <SimpleTable aria-label="Tabella delle lead di un contatto">
                  <SimpleTable.Body>
                    {sortedContactLeads.map((contactLead) => (
                      <SimpleTable.Row
                        key={contactLead.id}
                        asChild
                      >
                        <ViewContactLeadRow
                          lead={contactLead}
                          onLeadUpdate={onInternalLeadUpdate}
                        />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </>
          )}

          {sortedContactLeads.length === 0 && (
            <Message message={EMPTY_CONTACT_LEADS} />
          )}
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ContactLeadsWidget;
