import { IntentSection } from '../../../intent/types';
import { PropertyMatchAdditionalSection, PropertyMatchesSection } from '../../types';
import { PropertyMatchesNavigationItem } from './types';

export const MAIN_NAVIGATION_ITEMS: PropertyMatchesNavigationItem[] = [
  {
    id: IntentSection.TODO,
    label: 'Da fare',
  },
  {
    id: PropertyMatchAdditionalSection.SEARCH_CRITERIA,
    label: 'Possibili acquirenti',
  },
  {
    id: IntentSection.SCHEDULED_TODO,
    label: 'Appuntamenti',
  },
  {
    id: IntentSection.OFFERS_IN_PROGRESS,
    label: 'Proposte',
  },
  {
    id: PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITH_DONE_APPOINTMENTS,
    label: 'Archiviati',
  },
];

export const PENDING_INTENTS_NAVIGATION_ITEMS: PropertyMatchesNavigationItem[] = [
  {
    id: IntentSection.TODO,
    label: 'Nuovi',
  },
  {
    id: IntentSection.RECALL,
    label: 'Richiamare',
  },
];

export const POTENTIAL_BUYERS_NAVIGATION_ITEMS: PropertyMatchesNavigationItem[] = [
  {
    id: PropertyMatchAdditionalSection.SEARCH_CRITERIA,
    label: 'Ricerche',
  },
  {
    id: PropertyMatchAdditionalSection.SIMILAR_INTENTS,
    label: 'Interessi simili',
  },
];

export const SCHEDULED_INTENTS_NAVIGATION_ITEMS: PropertyMatchesNavigationItem[] = [
  {
    id: IntentSection.SCHEDULED_TODO,
    label: 'Prossimi',
  },
  {
    id: IntentSection.SCHEDULED_EXPIRED,
    label: 'Scaduti',
  },
  {
    id: IntentSection.SCHEDULED_PENDING,
    label: 'In sospeso',
  },
];

export const OFFERS_NAVIGATION_ITEMS: PropertyMatchesNavigationItem[] = [
  {
    id: IntentSection.OFFERS_IN_PROGRESS,
    label: 'Presentate',
  },
  {
    id: IntentSection.OFFERS_ACCEPTED,
    label: 'Accettate',
  },
  {
    id: IntentSection.OFFERS_REFUSED,
    label: 'Rifiutate',
  },
  {
    id: IntentSection.OFFERS_WITHDRAWN,
    label: 'Annullate',
  },
];

export const ARCHIVED_INTENTS_NAVIGATION_ITEMS: PropertyMatchesNavigationItem[] = [
  {
    id: PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITH_DONE_APPOINTMENTS,
    label: 'Con appuntamento',
  },
  {
    id: PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITHOUT_DONE_APPOINTMENTS,
    label: 'Senza appuntamento',
  },
];

export const PENDING_INTENTS_SECTIONS: PropertyMatchesSection[] = [
  IntentSection.TODO,
  IntentSection.RECALL,
];

export const POTENTIAL_BUYERS_SECTIONS: PropertyMatchesSection[] = [
  PropertyMatchAdditionalSection.SEARCH_CRITERIA,
  PropertyMatchAdditionalSection.SIMILAR_INTENTS,
];

export const SCHEDULED_INTENTS_SECTIONS: PropertyMatchesSection[] = [
  IntentSection.SCHEDULED_TODO,
  IntentSection.SCHEDULED_EXPIRED,
  IntentSection.SCHEDULED_PENDING,
];

export const OFFERS_SECTIONS: PropertyMatchesSection[] = [
  IntentSection.OFFERS_IN_PROGRESS,
  IntentSection.OFFERS_ACCEPTED,
  IntentSection.OFFERS_REFUSED,
  IntentSection.OFFERS_WITHDRAWN,
];

export const ARCHIVED_INTENTS_SECTIONS: PropertyMatchesSection[] = [
  PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITH_DONE_APPOINTMENTS,
  PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITHOUT_DONE_APPOINTMENTS,
];
