import * as Yup from 'yup';

const INVALID_PERCENTAGE_RANGE_ERROR_MESSAGE = 'La percentuale deve essere compresa tra 0 e 100';
const REQUIRED_FIELD_ERROR_MESSAGE = 'Il campo è obbligatorio';

const percentageValidation = Yup
  .number()
  .min(0, INVALID_PERCENTAGE_RANGE_ERROR_MESSAGE)
  .max(100, INVALID_PERCENTAGE_RANGE_ERROR_MESSAGE)
  .required(REQUIRED_FIELD_ERROR_MESSAGE);

const priceValidation = Yup.number().required(REQUIRED_FIELD_ERROR_MESSAGE);

const editCommissionsValidationSchema = Yup.object({
  commissionSplitPersonal: percentageValidation,
  commissionSplitDove: percentageValidation,
  photoPricePersonal: priceValidation,
  photoPriceDove: priceValidation,
  overCommissionThreshold: priceValidation,
  overCommissionSplitPersonal: percentageValidation,
  overCommissionSplitDove: percentageValidation,
  fixedSalary: priceValidation,
  fee: priceValidation,
  managementCost: percentageValidation,
});

export default editCommissionsValidationSchema;
