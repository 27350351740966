/* eslint-disable react/no-unknown-property */
import {
  ActionIcon, Form, FormActions, FormActionsAlign, FormProps, Help, HStack, ICON_PERCENT, ICON_TRASH_CAN_OUTLINE, Message,
} from '@doveit/bricks';
import { omit } from 'lodash';
import React from 'react';
import PlaceAutoComplete from '../../../components/place-autocomplete/PlaceAutoComplete';
import { PlaceType } from '../../../domain/types';
import { CoveredGeo } from '../../../providers/api/dtos';
import { PlaceSuggestion } from '../../../providers/public-api/dtos';
import { Stringify } from '../../../types';
import editAgentCoveredGeoFormValidationSchema from './EditAgentCoveredGeoForm.schema';

export const parseWeight = (weight: string): number => (weight === '' ? 0 : Number.parseInt(weight, 10));
export const stringifyWeight = (weight: number): string => (weight === 0 ? '' : `${weight}`);

export interface EditAgentCoveredGeoFormModel {
  provinces: Record<string, Stringify<Omit<CoveredGeo, 'plateCode'>>>,
}

interface InternalEditAgentCoveredGeoFormModel extends EditAgentCoveredGeoFormModel {
  current: string | null,
  errors: {},
}

export interface EditAgentCoveredGeoFormProps {
  initialValues?: Partial<EditAgentCoveredGeoFormModel>,
  loading?: FormProps<EditAgentCoveredGeoFormModel>['loading'],
  disabled?: FormProps<EditAgentCoveredGeoFormModel>['disabled'],
  onSubmit: FormProps<EditAgentCoveredGeoFormModel>['onSubmit'],
}

const EditAgentCoveredGeoForm: React.FC<EditAgentCoveredGeoFormProps> = (props) => {
  const { initialValues = {}, onSubmit, ...rest } = props;

  const mergedInitialValues: InternalEditAgentCoveredGeoFormModel = React.useMemo(() => ({
    current: null,
    provinces: {},
    errors: {},
    ...initialValues,
  }), [initialValues]);

  const onInternalSubmit = React.useCallback((
    { provinces }: InternalEditAgentCoveredGeoFormModel,
    actions: FormActions<InternalEditAgentCoveredGeoFormModel>,
  ) => {
    onSubmit({ provinces }, actions as FormActions<EditAgentCoveredGeoFormModel>);
  }, [onSubmit]);

  return (
    <Form
      {...rest}
      onSubmit={onInternalSubmit}
      initialValues={mergedInitialValues}
      validationSchema={editAgentCoveredGeoFormValidationSchema}
    >
      {({
        values, setFieldValue, setValues, errors,
      }) => {
        const calculateResidualWeight = (): number => {
          const summedWeights = Object.values(values.provinces).reduce((acc, { weight }) => acc + parseWeight(weight), 0);

          const residual = 100 - summedWeights;

          return residual < 0 ? 0 : residual;
        };

        const addProvince = (sugg: PlaceSuggestion | null) => {
          if (!sugg?.place.plateCode) return;

          setFieldValue('provinces', {
            ...values.provinces,
            [sugg.place.plateCode]: {
              province: sugg.place.name,
              weight: stringifyWeight(calculateResidualWeight()),
            },
          });
        };

        const removeProvince = (plateCode: string) => () => {
          setValues({
            ...values,
            provinces: omit(values.provinces, plateCode),
          });
        };

        return (
          <>
            <Form.Item>
              <PlaceAutoComplete
                name="current"
                placeType={PlaceType.PROVINCE}
                excludedSuggestions={Object
                  .values(values.provinces)
                  .map(({ province }) => province)}
                onSuggestionSelected={addProvince}
              />
            </Form.Item>

            <Form.Item>
              <Message type="info" message="Inserisci per ogni provincia la percentuale di tempo dedicata allo sviluppo (la somma dei valori deve essere pari a 100)" />
            </Form.Item>

            <Form.Group aria-label="Province coperte">
              {Object
                .entries(values.provinces)
                .map(([plateCode, { province }]) => (
                  <Form.Item key={plateCode}>
                    <HStack alignItems="center" justifyContent="space-between">
                      {province}

                      <HStack wrap="nowrap">
                        <Form.PriceField
                          name={`provinces.${plateCode}.weight`}
                          icon={ICON_PERCENT}
                        />
                        <ActionIcon
                          label="Rimuovi provincia coperta"
                          aria-label="Rimuovi provincia coperta"
                          icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                          color="critical"
                          emphasis="high"
                          onClick={removeProvince(plateCode)}
                        />
                      </HStack>
                    </HStack>
                  </Form.Item>
                ))}

              {errors.errors && (
                <Form.Item>
                  <Help error>
                    {Object.entries(errors.errors).map(([key, err]) => {
                      if (typeof err === 'string') {
                        return (
                          <div key={key}>
                            {err}
                          </div>
                        );
                      }

                      return null;
                    })}
                  </Help>
                </Form.Item>
              )}
            </Form.Group>

            <Form.Actions align={FormActionsAlign.RIGHT}>
              <Form.Submit
                label="Modifica"
                aria-label="Modifica"
              />
            </Form.Actions>
          </>
        );
      }}
    </Form>
  );
};

export default EditAgentCoveredGeoForm;
