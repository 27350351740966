import React from 'react';
import {
  Dropdown, ICON_HOME_OUTLINE, ICON_MAP_MARKER_OUTLINE, Message,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import { Appointment, Prospect } from '../../../../../providers/api/dtos';
import { prospectStatusLabels } from '../../../../../labels';
import useLead from '../../../../../lead/hooks/use-lead/useLead';
import { formatPropertyType } from '../../../../../property/utils';
import { ProspectStatusColors } from '../../../../../prospect/components/prospect-status-badge/ProspectStatusBadge';
import ContactActivityCard from '../../../../components/contact-activity-card/ContactActivityCard';
import ContactActivityCardSkeleton from '../../../../components/contact-activity-card/ContactActivityCard.skeleton';
import { CONTACT_LEADS_LOAD_ERROR } from '../../leads/constants';
import useContactAppointments from '../../../../../appointment/hooks/use-contact-appointments/useContactAppointments';
import { AppointmentStatus, ReferenceType } from '../../../../../domain/types';
import { raise } from '../../../../../utils';
import { SortOrder } from '../../../../../providers/pagination';
import UpsertAppointmentAction from '../../../../../appointment/containers/upsert-appointment-action/UpsertAppointmentAction';
import { goToDetailPage } from '../../../../../utils/navigate/utils';

export interface ContactProspectSummaryCardProps {
  prospect: Prospect
}

const ContactProspectSummaryCard: React.FC<ContactProspectSummaryCardProps> = ({
  prospect,
}) => {
  const {
    id, contactId, leadId, agentId, status, createdAt, updatedAt,
  } = prospect;

  const prospectId = id ?? raise('No prospect id provided');

  const { data: lead, error: leadError } = useLead(leadId);
  const { data: appointments, mutate: mutateAppointments } = useContactAppointments(
    contactId,
    {
      referenceType: ReferenceType.PROSPECT,
      referenceId: prospectId,
    },
    { sort: { startDate: SortOrder.DESC } },
  );

  const lastAppointmentStatus = React.useMemo(() => {
    if (appointments && appointments.content.length > 0) return appointments.content[0].status;
    return undefined;
  }, [appointments]);

  const appointment: Appointment = React.useMemo(() => {
    if (appointments && lastAppointmentStatus === AppointmentStatus.TODO) {
      return appointments.content[0];
    }

    return {
      contactId,
      agentId,
      referenceId: prospectId,
      referenceType: ReferenceType.PROSPECT,
    } as Appointment;
  }, [agentId, appointments, contactId, lastAppointmentStatus, prospectId]);

  const goToProspectPage = React.useCallback(() => {
    goToDetailPage(prospectId, ReferenceType.PROSPECT);
  }, [prospectId]);

  if (leadError) {
    return (
      <Message
        type="critical"
        message={CONTACT_LEADS_LOAD_ERROR}
      />
    );
  }

  if (!lead) return <ContactActivityCardSkeleton />;

  return (
    <ContactActivityCard
      key={id}
      type="prospect"
      status={prospectStatusLabels[status]}
      statusColor={ProspectStatusColors[status]}
      createdAt={createdAt}
      updatedAt={updatedAt}
      features={[{
        icon: ICON_HOME_OUTLINE,
        title: 'Dettagli immobile',
        label: `${formatPropertyType(lead.propertyType)}, ${formatSquareMeters(lead.propertySize)}`,
      }, {
        icon: ICON_MAP_MARKER_OUTLINE,
        title: 'Indirizzo immobile',
        label: lead.propertyAddress,
      }]}
      actions={[
        <Dropdown.Option
          label="Vedi valuzatione"
          aria-label="Vedi valutazione"
          onClick={goToProspectPage}
        />,
        <UpsertAppointmentAction
          appointment={appointment}
          onSuccess={() => mutateAppointments()}
        >
          {({ upsert }) => (
            <Dropdown.Option
              label={appointment.id ? 'Modifica appuntamento' : 'Aggiungi appuntamento'}
              onClick={upsert}
            />
          )}
        </UpsertAppointmentAction>,
      ]}
    />
  );
};

export default ContactProspectSummaryCard;
