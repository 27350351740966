import { ICON_FILE_DOCUMENT_ALERT_OUTLINE, ICON_FILE_DOCUMENT_OUTLINE } from '@doveit/bricks';
import { DocumentsStatus, DocumentsStatusLabelStyle } from './types';

export const documentsStatusLabelStyles: Record<DocumentsStatus, DocumentsStatusLabelStyle> = {
  approved: {
    icon: ICON_FILE_DOCUMENT_OUTLINE,
    color: 'status.success',
  },
  pending: {
    icon: ICON_FILE_DOCUMENT_ALERT_OUTLINE,
    color: 'status.warning',
  },
  todo: {
    icon: ICON_FILE_DOCUMENT_ALERT_OUTLINE,
    color: 'status.alert',
  },
};
