import {
  Badge,
  Card,
  CardSkeleton,
  HStack,
  ICON_ALERT_CIRCLE_OUTLINE,
  ICON_DOMAIN,
  Message,
  Stack,
} from '@doveit/bricks';
import React from 'react';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import ViewPropertyPreviewTableRow from '../../../property/containers/view-property-preview-table-row/ViewPropertyPreviewTableRow';
import { Contact } from '../../../providers/api/dtos';
import { SortOrder } from '../../../providers/pagination';
import useContactProperties from '../../hooks/use-contact-properties/useContactProperties';
import { Status } from '../../../domain/types';
import ViewPropertyPreviewCard from '../../../property/containers/view-property-preview-card/ViewPropertyPreviewCard';

export const LOAD_CONTACT_PROPERTIES_ERROR_MESSAGE = 'Non è stato possibile recuperare gli immobili del contatto.';
export const EMPTY_CONTACT_PROPERTIES_INFO_MESSAGE = 'Non sono presenti immobili associati al contatto.';

export interface ContactPropertiesWidgetProps {
  contactId: NonNullable<Contact['id']>,
}

const FILTERS = ['all', 'active', 'inactive'] as const;

type Filter = typeof FILTERS[number];

const filterLabels: Record<Filter, string> = {
  all: 'Tutti',
  active: 'Attivi',
  inactive: 'Non attivi',
};

const ContactPropertiesWidget: React.FC<ContactPropertiesWidgetProps> = ({
  contactId,
}) => {
  const [activeFilter, setActiveFilter] = React.useState<Filter>('all');

  const isMobile = useIsDevice('mobile');

  const {
    data: propertyContacts,
    isLoading: arePropertyContactsLoading,
    error: propertyContactsError,
    mutate: mutatePropertyContacts,
  } = useContactProperties(
    contactId,
    { createdAt: SortOrder.DESC },
  );

  const filteredProperties = React.useMemo(() => {
    const properties = (propertyContacts || []).map(({ property }) => property);

    switch (activeFilter) {
      case 'active':
        return properties.filter(({ status }) => ![Status.RITIRATO, Status.VENDUTO].includes(status));
      case 'inactive':
        return properties.filter(({ status }) => [Status.RITIRATO, Status.VENDUTO].includes(status));
      default:
        return properties;
    }
  }, [activeFilter, propertyContacts]);

  if (arePropertyContactsLoading) {
    return (
      <CardSkeleton aria-label="Sezione immobili in caricamento">
        {!isMobile && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  if (propertyContactsError) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Immobili"
          color="critical"
        />
        <Card.Content>
          <Message message={LOAD_CONTACT_PROPERTIES_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card aria-label="Sezione immobili">
      <Card.Header
        title="Immobili"
        icon={{ path: ICON_DOMAIN }}
      />
      <Card.Content>
        <Stack gap={150} aria-label="Lista immobili">
          {isMobile && filteredProperties.length > 0 && (
            filteredProperties.map((property) => (
              <ViewPropertyPreviewCard
                key={property.id}
                property={property}
                onPropertyUpdate={() => mutatePropertyContacts()}
              />
            ))
          )}
          {!isMobile && (
            <>
              {propertyContacts && propertyContacts.length > 0 && (
                <HStack aria-label="Filtri">
                  {FILTERS.map((filter) => (
                    <Badge
                      key={filter}
                      label={filterLabels[filter]}
                      color={filter === activeFilter ? 'primary' : 'neutral'}
                      emphasis="low"
                      onClick={filter !== activeFilter ? () => setActiveFilter(filter) : undefined}
                    />
                  ))}
                </HStack>
              )}

              {filteredProperties.length > 0 && (
                <SimpleTable aria-label="Tabella immobili">
                  <SimpleTable.Body>
                    {filteredProperties.map((property) => (
                      <SimpleTable.Row
                        key={property.id}
                        asChild
                      >
                        <ViewPropertyPreviewTableRow
                          property={property}
                          onPropertyUpdate={() => mutatePropertyContacts()}
                        />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </>
          )}
          {filteredProperties.length === 0 && <Message message={EMPTY_CONTACT_PROPERTIES_INFO_MESSAGE} />}
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ContactPropertiesWidget;
