import { ComputedOfferStatus, OfferStatus } from '../domain/types/offerStatus';
import { OfferPropertyStatus } from '../providers/api/dtos';

export const offerStatusLabels: Record<OfferStatus, string> = {
  IN_PROGRESS: 'Proposta presentata',
  ACCEPTED: 'Proposta accettata',
  CONFIRMED: 'Immobile acquistato',
  REFUSED: 'Proposta rifiutata',
  WITHDRAWN: 'Proposta annullata',
  OTHER_CONSTRAINTS: 'Altri impedimenti',
  MORTGAGE_REFUSED: 'Mutuo non concesso',
};

export const computedOfferStatusLabels: Record<ComputedOfferStatus, string> = {
  WAITING_FOR_SIGNATURE: 'Proposta in firma',
  IN_PROGRESS: 'Proposta presentata',
  WAITING_FOR_ACCEPTANCE: 'Accettazione in firma',
  ACCEPTED: 'Proposta accettata',
  CONFIRMED: 'Immobile acquistato',
  DEED_DONE: 'Rogito avvenuto',
  REFUSED: 'Proposta rifiutata',
  WITHDRAWN: 'Proposta annullata',
  MORTGAGE_REFUSED: 'Mutuo non concesso',
  OTHER_CONSTRAINTS: 'Altri vincoli',
};

export const computedOfferStatusShortLabels: Record<ComputedOfferStatus, string> = {
  WAITING_FOR_SIGNATURE: 'In firma',
  IN_PROGRESS: 'Presentata',
  WAITING_FOR_ACCEPTANCE: 'Accettazione in firma',
  ACCEPTED: 'Accettata',
  CONFIRMED: 'Immobile acquistato',
  DEED_DONE: 'Rogito avvenuto',
  REFUSED: 'Rifiutata',
  WITHDRAWN: 'Annullata',
  MORTGAGE_REFUSED: 'Mutuo non concesso',
  OTHER_CONSTRAINTS: 'Altri vincoli',
};

export const offerPropertyStatusLabels: Record<OfferPropertyStatus, string> = {
  FREE: 'Libero',
  FREE_UPON_DEED: 'Libero al rogito',
  RENTAL: 'Concesso in locazione',
  OCCUPIED: 'Occupato',
  UNDER_CONSTRUCTION: 'In costruzione',
  OTHER: 'Altro',
};
