import { getAgentName } from '../../agent/utils/utils';
import { Agent } from '../../providers/api/dtos';
import { Property, PropertyPreview } from '../../providers/api/dtos/property';

export default function buildWhatsAppIntentMessage(
  agent: Agent,
  property: Property | PropertyPreview,
  numberOfEvents: number,
  numberOfCalendarEvents: number,
  numberOfOffers: number,
): string {
  if (numberOfEvents === 0
    && numberOfCalendarEvents === 0
    && numberOfOffers === 0) {
    return `Salve, sono ${getAgentName(agent)} di Dove.it.`
      + ` La contatto per la sua richiesta relativa all'immobile ${property.referenceId} in ${property.geo!.normalizedAddress}.`
      + ` https://www.dove.it/dv/${property.id}`;
  }
  return '';
}
