import React from 'react';
import { AxiosError } from 'axios';
import { CalendarEventsFilters } from '../../providers/api/contact/contactProvider';
import { Appointment, Contact, Reminder } from '../../providers/api/dtos';
import { PaginationParams } from '../../providers/pagination';
import useContactAppointments from '../../appointment/hooks/use-contact-appointments/useContactAppointments';
import useReminders from '../../reminders/hooks/use-reminders/useReminders';

export type CalendarEventType = 'appointment' | 'reminder';

export type DiscreteCalendarEvent =
  | { event: Appointment; type: 'appointment' }
  | { event: Reminder; type: 'reminder' };

export type UseCalendarEventOptions = {
  onErrors?: (errors: Error[]) => void,
};

export type UseCalendarEventsResult = {
  data: DiscreteCalendarEvent[] | undefined,
  isLoading: boolean,
  errors: Error[] | undefined,
  mutate: () => Promise<void>,
};

export const useCalendarEvents = (
  contactId?: Contact['id'],
  filters?: CalendarEventsFilters,
  pagination?: PaginationParams,
  options: UseCalendarEventOptions = {},
): UseCalendarEventsResult => {
  const [errors, setErrors] = React.useState<Error[]>();

  const handleNewError = React.useCallback((err: AxiosError) => {
    setErrors((prev) => {
      if (!prev) return [err];
      return [...prev, err];
    });
  }, []);

  const {
    data: appointments,
    isLoading: areAppointmentsLoading,
    mutate: mutateAppointments,
  } = useContactAppointments(contactId, filters, pagination, {
    onError: handleNewError,
  });

  const {
    data: reminders,
    isLoading: areRemindersLoading,
    mutate: mutateReminders,
  } = useReminders(filters, pagination, {
    onError: handleNewError,
  });

  const isLoading = React.useMemo(
    () => areAppointmentsLoading || areRemindersLoading,
    [areAppointmentsLoading, areRemindersLoading],
  );

  const data = React.useMemo(() => {
    if (isLoading || errors) {
      return undefined;
    }

    const discreteAppointments: DiscreteCalendarEvent[] = (appointments?.content || []).map((event) => ({
      event,
      type: 'appointment',
    }));

    const discreteReminders: DiscreteCalendarEvent[] = (reminders?.content || []).map((event) => ({
      event,
      type: 'reminder',
    }));

    return [...discreteAppointments, ...discreteReminders];
  }, [appointments?.content, errors, isLoading, reminders?.content]);

  const mutate = React.useCallback(async () => {
    setErrors(undefined);

    await mutateAppointments();
    await mutateReminders();
  }, [mutateAppointments, mutateReminders]);

  React.useEffect(() => {
    const { onErrors } = options;

    if (!isLoading && errors) {
      onErrors?.(errors);
    }
  }, [errors, isLoading, options]);

  return React.useMemo(() => ({
    data,
    isLoading,
    errors,
    mutate,
  }), [data, errors, isLoading, mutate]);
};
