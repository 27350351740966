import React, {
  useMemo, PropsWithChildren, ReactNode, useCallback, useState,
} from 'react';
import { DropzoneOptions, FileError, FileRejection } from 'react-dropzone';
import update from 'immutability-helper';
import { Grid, Message, Spacing } from '@doveit/bricks';
import FileUploader, { DEFAULT_MAX_FILES } from '../file-uploader/FileUploader';
import MediaCard from '../media-card/MediaCard';
import formatBytes from '../../utils/format-bytes/formatBytes';
import { duplicatedFileNameValidator, getNormalizedFileName } from '../../utils/file/file';
import { FileMimeType } from '../../types';

export interface UploadModel {
  loading: boolean,
  error: boolean,
  success: boolean,
  preview: string,
  fileName: string,
}

export interface FileUploadManagerProps<T> extends React.AriaAttributes {
  startPosition?: number,
  maxUploadQueueLength?: number,
  allowedMimeTypes?: FileMimeType[],
  onFileUploadRequest: (upload: UploadModel, file: File) => Promise<T>,
  onUploadQueueEnd?: VoidFunction,
  onFileUploadAddedToQueue?: (upload: UploadModel) => void,
  onFileUploadSuccess?: (upload: UploadModel, requestResponse: T) => void,
  onFileUploadError?: (error: Error) => void,
  validator?: DropzoneOptions['validator'],
  additionalErrorLabels?: Record<string, string>,
  render?: {
    queue?: (uploads: UploadModel[]) => ReactNode,
  },
}

export function toUploadModel(file: File): UploadModel {
  return ({
    loading: true,
    error: false,
    success: false,
    preview: URL.createObjectURL(file),
    fileName: file.name,
  });
}

function FileUploadManager<T>({
  maxUploadQueueLength = DEFAULT_MAX_FILES,
  allowedMimeTypes,
  onFileUploadRequest,
  onUploadQueueEnd,
  onFileUploadAddedToQueue,
  onFileUploadSuccess,
  onFileUploadError,
  validator,
  render,
  additionalErrorLabels = {},
  ...rest
}: PropsWithChildren<FileUploadManagerProps<T>>) {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [uploadsQueue, setUploadsQueue] = useState<UploadModel[]>([]);

  const fileCodeErrorLabels: { [key in FileError['code']]: string } = useMemo(() => ({
    'file-too-large': 'ATTENZIONE i seguenti file non sono stati caricati perché eccedono nelle dimensioni',
    'too-many-files': `È consentito caricare un massimo di ${maxUploadQueueLength} file per volta`,
    'file-invalid-type': 'ATTENZIONE i seguenti file non sono stati caricati perché hanno un formato non consentito',
    'file-already-exists': 'ATTENZIONE i seguenti file non sono stati caricati perché esiste già un file con lo stesso nome',
    ...additionalErrorLabels,
  }), [maxUploadQueueLength, additionalErrorLabels]);

  const rejectionDetails = useCallback((errorCode: FileError['code'], file: File) => {
    switch (errorCode) {
      case 'file-too-large':
        return `<li>- ${file.name} w${formatBytes(file.size)})</li>`;
      case 'too-many-files':
        return '';
      default:
        return `<li>- ${file.name}</li>`;
    }
  }, []);

  const rejectionsGroupedByErrorCode = useMemo(() => rejectedFiles
    .flatMap((rejectedFile) => rejectedFile.errors.map((error) => ({
      errorCode: error.code,
      file: rejectedFile.file,
    })))
    .reduce((acc, element) => {
      if (!acc.has(element.errorCode)) {
        acc.set(element.errorCode, []);
      }

      acc.get(element.errorCode)!.push(element.file);
      return acc;
    }, new Map<FileError['code'], File[]>()), [rejectedFiles]);

  const updateAcceptedFile = useCallback((file: File, uploadPosition: number) => {
    const uploadObject = toUploadModel(file);
    let toUpdate: Partial<UploadModel>;

    setUploadsQueue((prevState) => ([...prevState, uploadObject]));

    return async () => {
      if (onFileUploadAddedToQueue) {
        onFileUploadAddedToQueue(uploadObject);
      }

      try {
        const response = await onFileUploadRequest(uploadObject, file);

        toUpdate = {
          loading: false,
          success: true,
        };

        if (onFileUploadSuccess) {
          onFileUploadSuccess(uploadObject, response);
        }
      } catch (error) {
        toUpdate = {
          loading: false,
          error: true,
        };

        if (onFileUploadError) {
          onFileUploadError(error as Error);
        }
      }

      setUploadsQueue((prevState) => update(prevState, { [uploadPosition]: { $merge: toUpdate } }));
    };
  }, [onFileUploadAddedToQueue, onFileUploadError, onFileUploadRequest, onFileUploadSuccess]);

  const onDrop = useCallback(async (accepted: File[], rejected: FileRejection[]) => {
    setRejectedFiles(rejected);

    await Promise.all(accepted.map((file, i) => updateAcceptedFile(file, uploadsQueue.length + i)()));

    if (onUploadQueueEnd) {
      onUploadQueueEnd();
    }
  }, [onUploadQueueEnd, updateAcceptedFile, uploadsQueue.length]);

  const internalValidator = useCallback((file: File) => {
    const errors: FileError[] = [];

    if (validator) {
      const validationErrors = validator(file);

      if (validationErrors) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        Array.isArray(validationErrors) ? errors.push(...validationErrors) : errors.push(validationErrors);
      }
    }

    const queuedFileNames = uploadsQueue.filter((upload) => !upload.success).map((upload) => upload.fileName);
    const duplicatesValidationsErrors = duplicatedFileNameValidator(file.name, queuedFileNames, getNormalizedFileName);

    if (duplicatesValidationsErrors) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Array.isArray(duplicatesValidationsErrors) ? errors.push(...duplicatesValidationsErrors) : errors.push(duplicatesValidationsErrors);
    }

    return errors.length ? errors : null;
  }, [uploadsQueue, validator]);

  return (
    <div {...rest}>
      <FileUploader
        allowedMimeTypes={allowedMimeTypes}
        maxFiles={maxUploadQueueLength}
        onDrop={onDrop}
        validator={internalValidator}
      />
      {[...rejectionsGroupedByErrorCode.keys()].map((errorCode) => (
        <Spacing key={errorCode} margin={[200, 0, 0]}>
          <Message
            type="critical"
            data-ref={errorCode}
            message={`${fileCodeErrorLabels[errorCode]}: <ul>${rejectionsGroupedByErrorCode.get(errorCode)?.map((rejectedFile) => rejectionDetails(errorCode, rejectedFile)).join('')}</ul>`}
          />
        </Spacing>
      ))}
      {render?.queue && render.queue(uploadsQueue)}
      {!render?.queue && uploadsQueue.length > 0 && (
        <Spacing margin={[400, 0, 0]}>
          <Grid gutter={25}>
            {uploadsQueue.map((upload: UploadModel) => (
              <Grid.Unit
                key={upload.fileName}
                size={{
                  XS: 1 / 4, SM: 1 / 5, MD: 1 / 8, XL: 1 / 10,
                }}
              >
                <MediaCard
                  imageSrc={upload.preview}
                  loading={upload.loading}
                  error={upload.error}
                  success={upload.success}
                />
              </Grid.Unit>
            ))}
          </Grid>
        </Spacing>
      )}
    </div>
  );
}

export default FileUploadManager;
