import React from 'react';
import {
  ActionDropDown,
  ActionDropdownProps,
  ConfirmModal, Input, Label, Message, Spacing, useModal, useNotifications,
} from '@doveit/bricks';
import {
  endOfDay, isFuture, isValid, max, startOfDay,
} from 'date-fns';
import { MembershipStatus } from '../../../domain/types';
import { membershipStatusLabels } from '../../../labels';
import { Agent } from '../../../providers/api/dtos';
import { calculateMembershipStatus } from '../../utils/calculateMembershipStatus';
import { updateAgent } from '../../../providers/api/agent/agentProvider';
import {
  optionsByStatus, AGENT_STATUS_UPDATED_SUCCESS_MESSAGE, AGENT_STATUS_UPDATED_ERROR_MESSAGE, membershipStatusColor, confirmModalTextByStatus, AGENT_NAME_PLACEHOLDER, NOT_VALID_DATE_ERROR_MESSAGE, NOT_FUTURE_DATE_ERROR_MESSAGE, agentStatusActionLabels,
} from './constants';
import { AgentStatusAction } from './types';
import { formatInputDate } from '../../../utils/form';
import { membershipStatusBadgeIcon } from '../../constants';
import { getAgentName } from '../../utils/utils';

export interface UpdateAgentStatusProps {
  agent: Agent,
  onSuccess?: (updatedStatus: MembershipStatus) => void,
  emphasis?: ActionDropdownProps['emphasis'],
  showIcon?: boolean,
}

const UpdateAgentStatus: React.FC<UpdateAgentStatusProps> = ({
  agent,
  onSuccess,
  emphasis = 'low',
  showIcon = false,
}) => {
  const confirmModal = useModal();
  const [selectedAction, setSelectedAction] = React.useState<AgentStatusAction>();
  const [startDate, setStartDate] = React.useState(new Date(agent.contract.startDate));
  const [endDate, setEndDate] = React.useState<Date | undefined>(agent.contract.endDate ? new Date(agent.contract?.endDate) : undefined);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dateError, setDateError] = React.useState<string | undefined>();
  const { addSuccess, addError } = useNotifications();

  const currentStatus = React.useMemo(
    () => calculateMembershipStatus(new Date(agent.contract.startDate), agent.contract.endDate ? new Date(agent.contract.endDate) : undefined),
    [agent.contract?.endDate, agent.contract.startDate],
  );

  const options = React.useMemo(() => Object.values(AgentStatusAction).map((action) => ({
    key: action,
    label: agentStatusActionLabels[action],
    onClick: () => {
      setSelectedAction(action);

      switch (action) {
        case AgentStatusAction.ACTIVATE: {
          setStartDate(currentStatus === MembershipStatus.UNDER_ACTIVATION ? new Date(agent.contract.startDate) : new Date());
          setEndDate(undefined);
          break;
        }
        case AgentStatusAction.REVOKE: {
          setEndDate(agent.contract.endDate ? new Date(agent.contract.endDate) : undefined);
          break;
        }
        case AgentStatusAction.REACTIVATE: {
          setEndDate(undefined);
          break;
        }
        default: break;
      }

      confirmModal.open();
    },
  })).filter((option) => optionsByStatus[currentStatus].includes(option.key)), [agent.contract.endDate, agent.contract.startDate, confirmModal, currentStatus]);

  const onConfirm = React.useCallback(async () => {
    if (dateError) {
      return;
    }

    setIsSaving(true);

    try {
      await updateAgent(agent.id!, {
        ...agent,
        contract: {
          ...agent.contract,
          startDate: startDate.toISOString(),
          endDate: endDate && endDate.toISOString(),
        },
        detached: false,
      });

      setIsSaving(false);
      addSuccess(AGENT_STATUS_UPDATED_SUCCESS_MESSAGE);

      if (onSuccess) {
        const updatedStatus = calculateMembershipStatus(new Date(startDate), endDate ? new Date(endDate) : undefined);
        onSuccess(updatedStatus);
      }
    } catch (error) {
      setIsSaving(false);
      addError(AGENT_STATUS_UPDATED_ERROR_MESSAGE);
    }

    confirmModal.close();
  }, [addError, addSuccess, agent, confirmModal, dateError, endDate, onSuccess, startDate]);

  const onAbort = React.useCallback(() => {
    setDateError(undefined);
    confirmModal.close();
  }, [confirmModal]);

  const onDateSet: React.FocusEventHandler<HTMLInputElement> = ({ target }) => {
    const dateToSave = new Date(target.value);
    const dateIsValid = isValid(dateToSave);

    setDateError(undefined);

    if (dateIsValid && isFuture(endOfDay(dateToSave))) {
      if (selectedAction === AgentStatusAction.ACTIVATE) {
        setStartDate(startOfDay(dateToSave));
      } else {
        setEndDate(endOfDay(dateToSave));
      }
    } else {
      setDateError(!dateIsValid ? NOT_VALID_DATE_ERROR_MESSAGE : NOT_FUTURE_DATE_ERROR_MESSAGE);
    }
  };

  return (
    <>
      <ActionDropDown
        aria-label="Selezione dello stato agente"
        label={membershipStatusLabels[currentStatus]}
        iconLeft={showIcon ? { path: membershipStatusBadgeIcon[currentStatus] } : undefined}
        options={options}
        emphasis={emphasis}
        size="S"
        color={membershipStatusColor[currentStatus]}
      />
      {selectedAction && (
        <ConfirmModal
          isOpen={confirmModal.isOpen}
          saving={isSaving}
          aria-label="Modale di conferma cambio di stato agente"
          title={confirmModalTextByStatus[selectedAction].title}
          onConfirm={onConfirm}
          onAbort={onAbort}
        >
          <div dangerouslySetInnerHTML={{ __html: confirmModalTextByStatus[selectedAction].message.replace(AGENT_NAME_PLACEHOLDER, getAgentName(agent)!) }} />

          <Spacing margin={[200, 0, 0]}>
            {selectedAction === AgentStatusAction.ACTIVATE && (
              <>
                <Label text="Data di inizio" />
                <Input
                  type="date"
                  defaultValue={currentStatus === MembershipStatus.REVOKED ? '' : formatInputDate(startDate)}
                  aria-label="Campo per inserire la data"
                  onBlur={onDateSet}
                  error={!!dateError}
                  min={formatInputDate(new Date())}
                />
              </>
            )}
            {selectedAction === AgentStatusAction.REVOKE && (
              <>
                <Label text="Data di fine" />
                <Input
                  type="date"
                  defaultValue={endDate ? formatInputDate(endDate) : ''}
                  aria-label="Campo per inserire la data"
                  onBlur={onDateSet}
                  error={!!dateError}
                  min={formatInputDate(max([new Date(), startDate]))}
                />
              </>
            )}
            {dateError && (
              <Spacing margin={[100, 0, 0]}>
                <Message
                  type="critical"
                  message={dateError}
                />
              </Spacing>
            )}
          </Spacing>
        </ConfirmModal>
      )}
    </>
  );
};

export default UpdateAgentStatus;
