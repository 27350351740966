/* eslint-disable react/no-unknown-property */
import {
  Badge,
  FontWeight,
  HStack,
  Icon,
  ICON_CASH_MULTIPLE,
  ICON_CHECK,
  Message, Popover, PopoverProps, Spacing,
  Stack,
  Text,
} from '@doveit/bricks';
import React from 'react';
import { formatEuro, formatSquareMeters } from '@doveit/hammer';
import { Prospect } from '../../../providers/api/dtos';
import useProspect from '../../hooks/use-prospect/useProspect';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { LeadPropertyFeature, ReferenceType } from '../../../domain/types';
import * as styles from './ViewProspectPopoverPreview.style';
import PopoverPreviewSkeleton from '../../../components/popover-preview/PopoverPreview.skeleton';
import PopoverPreview from '../../../components/popover-preview/PopoverPreview';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import useLead from '../../../lead/hooks/use-lead/useLead';
import ProspectAvatar from '../../components/prospect-avatar/ProspectAvatar';
import { leadFeatureLabels, propertyTypeLabels } from '../../../labels';
import { formatFloor, NO_VALUE_SYMBOL } from '../../../property/utils';

interface BaseViewProspectPopoverPreviewProps extends React.AriaAttributes {
  trigger: PopoverProps['trigger'],
  hideAction?: boolean,
}

export type ViewProspectPopoverPreviewProps = BaseViewProspectPopoverPreviewProps & ({
  prospectId: NonNullable<Prospect['id']>,
  prospect?: undefined,
} | {
  prospect: Prospect,
  prospectId?: undefined,
});

export const LOAD_PROSPECT_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni della valutazione';

const ViewProspectPopoverPreview: React.FC<ViewProspectPopoverPreviewProps> = ({
  prospectId,
  prospect: externalProspect,
  trigger,
  hideAction = false,
  ...rest
}) => {
  const {
    data: loadedProspect,
    isLoading: isProspectLoading,
    error: prospectError,
  } = useProspect(prospectId);

  const prospect = externalProspect || loadedProspect;

  const { data: lead, isLoading: isLeadLoading, error: leadError } = useLead(prospect?.leadId);

  const features = React.useMemo(() => ([
    {
      key: 'propertySize',
      label: 'Superficie',
      value: lead?.propertySize ? formatSquareMeters(lead.propertySize) : NO_VALUE_SYMBOL,
    },
    {
      key: 'propertyFloor',
      label: 'Piano',
      value: lead?.propertyFloor ? formatFloor(lead.propertyFloor) : NO_VALUE_SYMBOL,
    },
  ]), [lead?.propertyFloor, lead?.propertySize]);

  const goToProspectPage = React.useCallback(
    () => goToDetailPage(prospect?.id!, ReferenceType.PROSPECT),
    [prospect?.id],
  );

  return (
    <Popover
      trigger={trigger}
      hasArrow
      {...rest}
    >
      <div css={styles.wrapper}>
        {(isProspectLoading || isLeadLoading) && <PopoverPreviewSkeleton aria-label="Caricamento informazioni valutazione in corso" />}

        {(prospectError || leadError) && (
          <Spacing padding={200}>
            <Message
              type="critical"
              message={LOAD_PROSPECT_DATA_ERROR_MESSAGE}
            />
          </Spacing>
        )}

        {prospect && lead && (
          <PopoverPreview
            aria-label="Informazioni valutazione"
            title={truncateTextWithSuffix(lead.propertyAddress, 60)}
            avatar={<ProspectAvatar size="L" />}
            action={!hideAction ? {
              label: 'Visualizza valutazione',
              'aria-label': 'Vai alla pagina della valutazione',
              onClick: goToProspectPage,
            } : undefined}
          >
            <Stack gap={150}>
              {prospect.agentEvaluation !== undefined && (
                <HStack wrap="nowrap" gap={75}>
                  <Icon path={ICON_CASH_MULTIPLE} size={20} />
                  <Text.Body fontWeight={FontWeight.MEDIUM}>{formatEuro(prospect.agentEvaluation)}</Text.Body>
                </HStack>
              )}
              <HStack gap={150} wrap="nowrap">
                <Text.Body fontWeight={FontWeight.MEDIUM}>
                  {propertyTypeLabels[lead.propertyType]}
                </Text.Body>

                {features.length > 0 && (
                  <div css={styles.features}>
                    {features.map(({ key, label, value }) => (
                      <div
                        key={key}
                        css={styles.feature}
                      >
                        <Text.BodySmall
                          fontWeight={FontWeight.MEDIUM}
                          lineHeight="1"
                          as="div"
                        >
                          {value}
                        </Text.BodySmall>
                        <Text.Mini
                          transform="uppercase"
                          as="div"
                        >
                          {label}
                        </Text.Mini>
                      </div>
                    ))}
                  </div>
                )}
              </HStack>

              <HStack>
                {Object.keys(lead.propertyFeatures)
                  .filter((feat) => feat)
                  .map((feature) => (
                    <Badge
                      key={feature}
                      icon={ICON_CHECK}
                      label={leadFeatureLabels[feature as LeadPropertyFeature]}
                      size="XS"
                    />
                  ))}
              </HStack>
            </Stack>
          </PopoverPreview>
        )}
      </div>
    </Popover>
  );
};

export default ViewProspectPopoverPreview;
