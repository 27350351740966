import {
  Form, FormActions, FormActionsAlign, FormSubmitType,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import parsePhoneNumber from 'libphonenumber-js/max';
import validationSchema from './ContactForm.schema';
import { AddressSuggestion } from '../../../providers/geo/dtos';
import AddressSuggestionAutoComplete from '../../../components/address-suggestion-autocomplete/AddressSuggestionAutoComplete';
import { COUNTRY } from '../../../constants';

export interface ContactFormModel {
  id?: string,
  name?: string,
  phoneNumber?: string,
  email?: string,
  fiscalCode?: string,
  address?: AddressSuggestion,
  notes?: string,
}

export interface ContactFormProps {
  onSubmit: FormSubmitType<ContactFormModel>,
  initialValues?: ContactFormModel,
  loading?: boolean,
  submitLabel?: string,
}

const ContactForm: FunctionComponent<ContactFormProps> = ({
  initialValues,
  loading,
  onSubmit,
  submitLabel = 'Salva',
}) => {
  const mergedInitialValues = useMemo<ContactFormModel>(() => ({
    name: initialValues?.name ?? '',
    phoneNumber: initialValues?.phoneNumber ?? '',
    email: initialValues?.email ?? '',
    fiscalCode: initialValues?.fiscalCode ?? '',
    address: initialValues?.address,
    notes: initialValues?.notes ?? '',
  }), [initialValues]);

  const onInternalSubmit = useCallback((values: ContactFormModel, actions: FormActions<ContactFormModel>) => {
    let { phoneNumber } = values;

    if (values.phoneNumber) {
      const parsed = parsePhoneNumber(values.phoneNumber, COUNTRY);
      phoneNumber = parsed?.number.toString();
    }

    onSubmit({
      ...values,
      phoneNumber,
    }, actions);
  }, [onSubmit]);

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={onInternalSubmit}
      validationSchema={validationSchema}
      loading={loading}
      enableReinitialize
      preventEnterKeypress
    >
      <Form.Item>
        <Form.Input
          name="name"
          aria-label="Nome e cognome"
          label="Nome e cognome"
          placeholder="Es. Mario Rossi"
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="phoneNumber"
          required
          aria-label="Telefono"
          label="Telefono"
          placeholder="Es. 3923456789"
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="email"
          aria-label="Email"
          label="Email"
          placeholder="Es. mario.rossi@gmail.com"
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="fiscalCode"
          aria-label="Codice fiscale"
          label="Codice fiscale"
          placeholder="Es. MRLMRL80A01F839I"
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <AddressSuggestionAutoComplete
          name="address"
          label="Indirizzo"
          aria-label="Campo per inserire l'indirizzo del contatto"
          placeholder="Es: Piazza Armando Diaz, 6, Milano"
        />
      </Form.Item>
      <Form.Item>
        <Form.TextArea
          name="notes"
          aria-label="Note"
          label="Note"
          placeholder="Inserisci eventuali note sul contatto"
        />
      </Form.Item>
      <Form.Actions align={FormActionsAlign.RIGHT}>
        <Form.Submit label={submitLabel} />
      </Form.Actions>
    </Form>
  );
};

export default ContactForm;
