import useSWR, { SWRResponse } from 'swr';
import hash from 'object-hash';
import { JobApplication } from '../../../providers/api/dtos';
import { getJobApplications, JobApplicationFilters } from '../../../providers/api/job-application/jobApplicationProvider';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';

export const JOB_APPLICATIONS_RID = '/job-applications';

export default function useJobApplications(filters: JobApplicationFilters | null, pagination?: PaginationParams): SWRResponse<PaginatedResult<JobApplication>, Error> {
  return useSWR(filters && [JOB_APPLICATIONS_RID, hash({ ...filters, ...pagination })], () => getJobApplications(filters!, pagination));
}
