import {
  ThemedFunctor,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({ theme }) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.background.neutral.default.lowest};
`;
