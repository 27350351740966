import {
  Action, Badge, ConfirmModal, HStack, ICON_IMAGE_OUTLINE, ICON_PROGRESS_UPLOAD, ICON_VIEW_GRID_OUTLINE, Modal, ModalSize, Portal, Select, Spacing, Text, useModal,
} from '@doveit/bricks';
import { ActionIconSize } from '@doveit/bricks/dist/typings/components/action-icon/types';
import React from 'react';
import { ContentType } from '../../../domain/types';
import { Property } from '../../../providers/api/dtos';
import PropertyContentsUploadManager from '../../../property/contents/containers/property-contents-upload-manager/PropertyContentsUploadManager';
import SortMultimediaContents from '../sort-multimedia-contents/SortMultimediaContents';
import ViewMultimediaPropertyContentsGallery from '../../../property/containers/view-multimedia-property-contents-gallery/ViewMultimediaPropertyContentsGallery';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';

export interface ManageMultimediaContentsActionProps {
  propertyId: NonNullable<Property['id']>,
  contentType: ContentType.FOTO | ContentType.PLANIMETRIA,
  size?: ActionIconSize,
  label?: string,
  disabled?: boolean,
  children?: ({ openManageModal }: { openManageModal: (navigationItem?: MultimediaNavigationItem, contentIndex?: number) => void, disabled: boolean }) => React.ReactNode,
  canSort?: boolean,
  canAdd?: boolean,
  canDelete?: boolean,
  canEdit?: boolean,
  canSeeMissingAboutError?: boolean,
  onContentChange?: VoidFunction,
}

const MULTIMEDIA_NAVIGATION_ITEMS = ['gallery', 'sorting', 'upload'] as const;

type MultimediaNavigationItem = typeof MULTIMEDIA_NAVIGATION_ITEMS[number];

const tabSettings: Record<MultimediaNavigationItem, { icon: string, label: string }> = {
  gallery: {
    icon: ICON_IMAGE_OUTLINE,
    label: 'Galleria',
  },
  sorting: {
    icon: ICON_VIEW_GRID_OUTLINE,
    label: 'Ordinamento',
  },
  upload: {
    icon: ICON_PROGRESS_UPLOAD,
    label: 'Caricamento',
  },
};

const ManageMultimediaContentsAction: React.FC<ManageMultimediaContentsActionProps> = ({
  propertyId,
  contentType,
  size,
  label,
  disabled = false,
  children,
  canSort = false,
  canAdd = false,
  canDelete = false,
  canEdit = false,
  canSeeMissingAboutError = false,
  onContentChange,
}) => {
  const manageMultimediaContentsModal = useModal<{ contentIndex?: number }>();
  const confirmCloseModal = useModal();
  const [activeNavigationItem, setActiveNavigationItem] = React.useState<MultimediaNavigationItem>('gallery');
  const isMobile = useIsDevice('mobile');

  const [sortingInProgress, setSortingInProgress] = React.useState(false);
  const [uploadInProgress, setUploadInProgress] = React.useState(false);
  const [uploadStarted, setUploadStarted] = React.useState(false);

  const typeLabel = React.useMemo(
    () => (contentType === ContentType.FOTO ? 'fotografie' : 'planimetrie'),
    [contentType],
  );

  const navigationItems = React.useMemo(
    () => MULTIMEDIA_NAVIGATION_ITEMS.filter((tab) => tab === 'gallery' || (canAdd && tab === 'upload') || (canSort && tab === 'sorting')),
    [canAdd, canSort],
  );

  const isDisabled = React.useCallback((tab: MultimediaNavigationItem) => {
    if (tab === activeNavigationItem) {
      return true;
    }

    if (tab === 'gallery' && (uploadInProgress || sortingInProgress)) {
      return true;
    }

    if (tab === 'sorting' && uploadInProgress) {
      return true;
    }

    if (tab === 'upload' && sortingInProgress) {
      return true;
    }

    return false;
  }, [activeNavigationItem, sortingInProgress, uploadInProgress]);

  const openManageModal = React.useCallback((navigationItem: MultimediaNavigationItem = 'gallery', contentIndex?: number) => {
    if (navigationItem) {
      setActiveNavigationItem(navigationItem);
    }

    manageMultimediaContentsModal.open({ contentIndex });
  }, [manageMultimediaContentsModal]);

  const checkOnClose = React.useCallback(() => {
    if (sortingInProgress || uploadInProgress) {
      confirmCloseModal.open();
      return;
    }

    manageMultimediaContentsModal.close();
  }, [confirmCloseModal, manageMultimediaContentsModal, sortingInProgress, uploadInProgress]);

  const onModalCloseConfirm = React.useCallback(() => {
    manageMultimediaContentsModal.close();
    confirmCloseModal.close();
  }, [confirmCloseModal, manageMultimediaContentsModal]);

  const onMediaSortChangeSuccess = React.useCallback(() => {
    setSortingInProgress(false);
    onContentChange?.();
  }, [onContentChange]);

  return (
    <>
      {children
        ? children({ openManageModal, disabled })
        : (
          <Action
            label={label || `Gestisci ${typeLabel}`}
            size={size}
            disabled={disabled}
            onClick={() => openManageModal()}
            aria-label={`Pulsante per gestire le ${typeLabel}`}
          />
        )}
      <Portal>
        <Modal
          open={manageMultimediaContentsModal.isOpen}
          onCloseHandler={checkOnClose}
          size={ModalSize.LARGE}
          aria-label={`Modale per gestire le ${typeLabel}`}
        >
          <Modal.Close aria-label="Chiudi la finestra" />
          <Modal.Header>
            <Text.H4>Gestisci {typeLabel}</Text.H4>
            {(canSort || canAdd) && (
              <Spacing margin={[200, 0, 0]}>
                {!isMobile && (
                  <HStack aria-label="modal-tab-navigation">
                    {navigationItems.map(((tab) => (
                      <Badge
                        label={tabSettings[tab].label}
                        aria-label={`Tab ${tabSettings[tab].label}`}
                        icon={tabSettings[tab].icon}
                        key={tab}
                        onClick={isDisabled(tab) ? undefined : () => setActiveNavigationItem(tab)}
                        color={tab === activeNavigationItem ? 'primary' : 'neutral'}
                        emphasis={tab === activeNavigationItem ? 'high' : 'low'}
                      />
                    )))}
                  </HStack>
                )}
                {isMobile && (
                  <Select
                    value={activeNavigationItem}
                    aria-label="modal-select-navigation"
                    options={navigationItems.map((tab) => ({ label: tabSettings[tab].label, value: tab, disabled: isDisabled(tab) }))}
                    onChange={(event) => { setActiveNavigationItem(event.target.value as MultimediaNavigationItem); }}
                  />
                )}
              </Spacing>
            )}
          </Modal.Header>
          <Modal.Body padded>
            {activeNavigationItem === 'gallery' && (
              <ViewMultimediaPropertyContentsGallery
                propertyId={propertyId}
                contentType={contentType}
                canDelete={canDelete}
                canEdit={canEdit}
                initialSlideIndex={manageMultimediaContentsModal.data?.contentIndex}
                onContentDelete={onContentChange}
                canSeeMissingAboutError={canSeeMissingAboutError}
              />
            )}
            {activeNavigationItem === 'sorting' && canSort && (
              <SortMultimediaContents
                propertyId={propertyId}
                contentType={contentType}
                onMediaSortChange={() => setSortingInProgress(true)}
                onSuccess={onMediaSortChangeSuccess}
                onAbort={() => setSortingInProgress(false)}
                canSeeMissingAboutError={canSeeMissingAboutError}
              />
            )}
            {activeNavigationItem === 'upload' && canAdd && (
              <PropertyContentsUploadManager
                propertyId={propertyId}
                contentType={contentType}
                onUploadQueueEnd={() => setUploadInProgress(false)}
                onFileUploadAddedToQueue={() => {
                  setUploadStarted(true);
                  setUploadInProgress(true);
                }}
                onCreateSuccess={onContentChange}
              />
            )}
          </Modal.Body>
        </Modal>
        <ConfirmModal
          {...confirmCloseModal}
          title="Confermi la chiusura della finestra?"
          aria-label="Conferma chiusura della finestra"
          onAbort={confirmCloseModal.close}
          onConfirm={onModalCloseConfirm}
        >
          {sortingInProgress && `Chiudendo la finestra perderai le modifiche sull'ordinamento delle ${typeLabel}.`}
          {uploadStarted && `La chiusura della finestra non interrompe l'eventuale caricamento in corso delle ${typeLabel}.`}
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default ManageMultimediaContentsAction;
