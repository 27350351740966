import { Invoice } from '../dtos';
import { API_INVOICES_PATH, getClient, API_PROPERTIES_PATH } from '../api';
import { InvoiceStatus } from '../../../domain/types/invoiceStatus';

export interface InvoicesProviderFilters {
  agentEmail?: string | string[],
  propertyId?: number,
  invoiceStatus?: InvoiceStatus,
}

export async function createInvoice(invoice: Invoice): Promise<Invoice> {
  const client = await getClient();
  const { data } = await client.post<Invoice>(API_INVOICES_PATH, invoice);

  return data;
}

export async function updateInvoice(invoice: Invoice): Promise<Invoice> {
  const client = await getClient();
  const { data } = await client.put<Invoice>(`${API_INVOICES_PATH}/${invoice.id}`, invoice);

  return data;
}

export async function getInvoiceByPropertyId(propertyId: number): Promise<Invoice> {
  const client = await getClient();
  const { data } = await client.get(`${API_PROPERTIES_PATH}/${propertyId}/invoice`);

  return data;
}
