import {
  Action,
  ActionIcon, FormHandlers, ICON_PENCIL_OUTLINE, ICON_PLUS, Portal, useModal,
  useNotifications,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Property } from '../../../providers/api/dtos';
import PropertyUnitForm, { PropertyUnitFormModel } from '../../components/property-unit-form/PropertyUnitForm';
import { updateProperty } from '../../../providers/api/property/propertyProvider';
import { toPropertyUnit, toPropertyUnitFormModel } from '../../components/property-unit-form/mappers';

export const UPSERT_UNIT_ERROR_MESSAGE = 'Non è stato possibile effettuare l\'operazione';
export const UPSERT_UNIT_SUCCESS_MESSAGE = 'Operazione effettuata con successo';

interface ChildFnProps {
  upsert: VoidFunction,
}

export interface UpsertPropertyUnitActionProps {
  property: Property,
  unitIndex?: number,
  onSuccess?: (updatedProperty: Property) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const UpsertPropertyUnitAction: React.FC<UpsertPropertyUnitActionProps> = ({
  property,
  unitIndex,
  onSuccess,
  children,
}) => {
  const isUpdate = React.useMemo(() => unitIndex !== undefined, [unitIndex]);
  const initialPropertyUnits = React.useMemo(() => property.cadastralRegistry?.units ?? [], [property.cadastralRegistry?.units]);
  const normalizedIndex = React.useMemo(() => Math.max(Math.min(unitIndex!, initialPropertyUnits.length - 1), 0), [unitIndex, initialPropertyUnits]);

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);
  const formRef = React.useRef<FormHandlers>() as React.MutableRefObject<FormHandlers>;

  const openModal = React.useCallback(
    () => modal.open(),
    [modal],
  );

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onSubmit = React.useCallback(async (values: PropertyUnitFormModel) => {
    setIsSaving(true);

    const propertyUnitToSave = toPropertyUnit(values);
    let propertyUnitsUpdated = initialPropertyUnits;

    if (isUpdate) {
      propertyUnitsUpdated[normalizedIndex] = propertyUnitToSave;
    } else {
      propertyUnitsUpdated = [...initialPropertyUnits, propertyUnitToSave];
    }
    try {
      const payload: Property = {
        ...property,
        cadastralRegistry: {
          ...property.cadastralRegistry,
          units: propertyUnitsUpdated,
        },
      };

      const result = await updateProperty(property.id!, payload);

      onSuccess?.(result);
      modal.close();
      addSuccess(UPSERT_UNIT_SUCCESS_MESSAGE);
    } catch (error) {
      addError(UPSERT_UNIT_ERROR_MESSAGE);
    }

    setIsSaving(false);
  }, [initialPropertyUnits, isUpdate, normalizedIndex, property, onSuccess, modal, addSuccess, addError]);

  return (
    <>
      {children
        ? children({
          upsert: openModal,
        })
        : (
          <ActionIcon
            icon={isUpdate
              ? { path: ICON_PENCIL_OUTLINE }
              : { path: ICON_PLUS }}
            label={isUpdate
              ? 'Modifica'
              : 'Aggiungi'}
            aria-label={
              isUpdate
                ? "Pulsante per modificare l'unità catastale"
                : "Pulsante per aggiungere un'unità catastale"
            }
            size="S"
            onClick={openModal}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title={
            isUpdate
              ? 'Modifica unità catastale'
              : 'Aggiungi unità catastale'
          }
          aria-label={
            isUpdate
              ? "Modale per modificare l'unità catastale"
              : "Modale per aggiungere un'unità catastale"
          }
          footer={(
            <Action
              label={
                isUpdate
                  ? 'Modifica'
                  : 'Aggiungi'
              }
              color="primary"
              emphasis="high"
              onClick={submitForm}
              loading={isSaving}
            />
          )}
        >
          <PropertyUnitForm
            onSubmit={onSubmit}
            initialValues={isUpdate
              ? toPropertyUnitFormModel(property.cadastralRegistry!.units![normalizedIndex!])
              : {}}
            innerRef={formRef}
            hideSubmit
            loading={isSaving}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpsertPropertyUnitAction;
