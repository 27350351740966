import React from 'react';
import { ActionDropDown, Dropdown } from '@doveit/bricks';
import { Lead } from '../../../providers/api/dtos';
import { ComputedLeadStatusCanBeUpdatedTo, useComputedLeadStatus } from '../../hooks/use-computed-lead-status/useComputedLeadStatus';
import LeadStatusBadge from '../../components/lead-status-badge/LeadStatusBadge';
import { leadStatusColors, leadStatusIcons, leadStatusLabels } from '../../constants';
import { LeadStatus } from '../../../domain/types';
import { SetLeadOnlyEvaluationAction } from '../set-lead-only-evaluation-action/SetLeadOnlyEvaluationAction';
import ArchiveLeadAction from '../archive-lead-action/ArchiveLeadAction';
import RestoreLeadAction from '../restore-lead-action/RestoreLeadAction';
import CreateProspectWithAppointmentAction from '../../../prospect/containers/create-prospect-with-appointment-action/CreateProspectWithAppointmentAction';

export interface UpdateLeadStatusProps {
  lead: Lead,
  showAsBadge?: boolean,
  emphasis?: 'low' | 'high',
  size?: 'XS' | 'S',
  showIcon?: boolean,
  onSuccess?: (updatedLead: Lead) => void,
}

const UpdateLeadStatus: React.FC<UpdateLeadStatusProps> = ({
  lead,
  showAsBadge = false,
  emphasis = 'low',
  size = 'S',
  showIcon = false,
  onSuccess,
}) => {
  const { status, canBeUpdated, canBeUpdatedTo } = useComputedLeadStatus(lead);

  const canTransitionTo = React.useCallback(
    (newStatus: ComputedLeadStatusCanBeUpdatedTo) => canBeUpdatedTo.includes(newStatus),
    [canBeUpdatedTo],
  );

  if (!canBeUpdated || showAsBadge) {
    return (
      <LeadStatusBadge
        status={status}
        size={size}
        emphasis={emphasis}
        showIcon={showIcon}
      />
    );
  }

  return (
    <ActionDropDown
      aria-label="Seleziona un nuovo stato della lead"
      label={leadStatusLabels[status]}
      color={leadStatusColors[status]}
      iconLeft={showIcon ? { path: leadStatusIcons[status] } : undefined}
      emphasis={emphasis}
      size={size}
    >
      {canTransitionTo(LeadStatus.APPOINTMENT_SCHEDULED) && (
        <CreateProspectWithAppointmentAction
          lead={lead}
          onSuccess={() => onSuccess?.(lead)}
          aria-label="Pulsante per aggiungere un appuntamento"
        >
          {({ openModal, disabled }) => (
            <Dropdown.Option
              label="Aggiungi appuntamento"
              onClick={openModal}
              disabled={disabled}
            />
          )}
        </CreateProspectWithAppointmentAction>
      )}
      {canTransitionTo(LeadStatus.KO_ONLY_EVALUATION) && (
        <SetLeadOnlyEvaluationAction
          lead={lead}
          onSuccess={onSuccess}
          aria-label="Pulsante per segnare la lead come solo valutazione"
        >
          {({ openEvaluationModal, disabled }) => (
            <Dropdown.Option
              label="Segna come solo valutazione"
              disabled={disabled}
              onClick={openEvaluationModal}
            />
          )}
        </SetLeadOnlyEvaluationAction>
      )}
      {canTransitionTo('ARCHIVED') && (
        <ArchiveLeadAction
          lead={lead}
          onSuccess={onSuccess}
          aria-label="Pulsante per archiviare la lead"
        >
          {({ openArchiveModal, disabled }) => (
            <Dropdown.Option
              label="Archivia"
              disabled={disabled}
              onClick={openArchiveModal}
            />
          )}
        </ArchiveLeadAction>
      )}
      {canTransitionTo(LeadStatus.IN_PROGRESS) && (
        <RestoreLeadAction
          lead={lead}
          onSuccess={onSuccess}
          aria-label="Pulsante per ripristinare la lead"
        >
          {({ openRestoreLeadModal, disabled }) => (
            <Dropdown.Option
              label="Ripristina"
              disabled={disabled}
              onClick={openRestoreLeadModal}
            />
          )}
        </RestoreLeadAction>
      )}
    </ActionDropDown>
  );
};

export default UpdateLeadStatus;
