import {
  ICON_PHONE_INCOMING_OUTLINE, ICON_PHONE_OUTGOING_OUTLINE, ICON_PHONE_OUTLINE, ICON_EMAIL_OUTLINE, ICON_EMPTY,
  Text,
} from '@doveit/bricks';
import { format } from 'date-fns';
import React from 'react';
import { EventRegistryType } from '../../types';

export const computeEventIcon = (type: EventRegistryType): string => {
  let path;
  switch (type as EventRegistryType) {
    case EventRegistryType.PHONE_CALL_INBOUND:
      path = ICON_PHONE_INCOMING_OUTLINE;
      break;
    case EventRegistryType.PHONE_CALL_OUTBOUND:
      path = ICON_PHONE_OUTGOING_OUTLINE;
      break;
    case EventRegistryType.MOBILE_CALL_OUTBOUND:
      path = ICON_PHONE_OUTLINE;
      break;
    case EventRegistryType.EMAIL_OUTBOUND:
      path = ICON_EMAIL_OUTLINE;
      break;
    default:
      path = ICON_EMPTY;
      break;
  }

  return path;
};

export const printEventDuration = (status: string | null, duration: number | null): React.ReactElement => (
  <Text.BodySmall aria-label="event-duration">
    {status === 'Telefonata' && duration ? format(duration * 1000, 'mm:ss') : '-'}
  </Text.BodySmall>
);
