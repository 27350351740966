import {
  Action,
  Card,
  CardSkeleton,
  Dropdown,
  ICON_ALERT_CIRCLE_OUTLINE,
  ICON_LAND_PLOTS,
  Message,
  Stack,
  Text,
} from '@doveit/bricks';
import objectHash from 'object-hash';
import React from 'react';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import { Property } from '../../../providers/api/dtos';
import useProperty from '../../hooks/use-property/useProperty';
import ViewPropertyUnitCard from '../view-property-unit-card/ViewPropertyUnitCard';
import ViewPropertyUnitTableRow from '../view-property-unit-table-row/ViewPropertyUnitTableRow';
import UpsertPropertyUnitAction from '../upsert-property-unit-action/UpsertPropertyUnitAction';

export const LOAD_PROPERTY_ERROR_MESSAGE = 'Non è stato possibile recuperare i dati dell\'immobile.';
export const EMPTY_PROPERTY_CADASTRAL_UNITS_INFO_MESSAGE = 'Non sono presenti unità catastali.';

export interface PropertyCadastralRegistryWidgetProps {
  propertyId: NonNullable<Property['id']>,
}

const PropertyCadastralRegistryWidget: React.FC<PropertyCadastralRegistryWidgetProps> = ({
  propertyId,
}) => {
  const isMobile = useIsDevice('mobile');

  const {
    data: property,
    isLoading: isPropertyLoading,
    error: propertyError,
    mutate: mutateProperty,
  } = useProperty(propertyId);

  const cadastralUnits = property?.cadastralRegistry?.units || [];

  if (isPropertyLoading) {
    return (
      <CardSkeleton aria-label="Dati catastali in caricamento">
        {!isMobile && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  if (propertyError) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Dati catastali"
          color="critical"
        />
        <Card.Content>
          <Message message={LOAD_PROPERTY_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  return property! && (
    <Card aria-label="Sezione dati catastali">
      <Card.Header
        title="Dati catastali"
        icon={{ path: ICON_LAND_PLOTS }}
        primaryActions={[
          (!isMobile && (
            <UpsertPropertyUnitAction
              property={property}
              onSuccess={() => mutateProperty()}
            >
              {({ upsert }) => (
                <Action
                  label="Aggiungi"
                  aria-label="Pulsante per aggiungere una nuova unità catastale"
                  size="S"
                  onClick={upsert}
                />
              )}
            </UpsertPropertyUnitAction>
          )),
        ]}
        secondaryActions={[
          (isMobile && (
            <UpsertPropertyUnitAction
              property={property}
              onSuccess={() => mutateProperty()}
            >
              {({ upsert }) => (
                <Dropdown.Option
                  label="Aggiungi"
                  aria-label="Pulsante per aggiungere una nuova unità catastale"
                  onClick={upsert}
                />
              )}
            </UpsertPropertyUnitAction>
          )),
        ]}
      />
      <Card.Content>
        <Stack gap={150} aria-label="Lista unità">
          {isMobile && cadastralUnits.length > 0 && (
            cadastralUnits.map((unit, idx) => (
              <ViewPropertyUnitCard
                key={objectHash(unit)}
                property={property}
                unitIndex={idx}
                onPropertyUpdate={() => mutateProperty()}
              />
            ))
          )}
          {!isMobile && cadastralUnits.length > 0 && (
            <SimpleTable aria-label="Tabella dati catastali">
              <SimpleTable.Header>
                <SimpleTable.HeaderCell>
                  <Text.BodySmall>Sezione</Text.BodySmall>
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell>
                  <Text.BodySmall>Foglio</Text.BodySmall>
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell>
                  <Text.BodySmall>Part.</Text.BodySmall>
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell>
                  <Text.BodySmall>Sub.</Text.BodySmall>
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell>
                  <Text.BodySmall>Cat.</Text.BodySmall>
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell>
                  <Text.BodySmall>Dest. d&apos;uso</Text.BodySmall>
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell>
                  <Text.BodySmall>Rendita</Text.BodySmall>
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell>
                  <Text.BodySmall>Classe Energ.</Text.BodySmall>
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell />
              </SimpleTable.Header>
              <SimpleTable.Body>
                {cadastralUnits.map((unit, idx) => (
                  <SimpleTable.Row
                    key={objectHash(unit)}
                    asChild
                  >
                    <ViewPropertyUnitTableRow
                      property={property}
                      unitIndex={idx}
                      onPropertyUpdate={() => mutateProperty()}
                    />
                  </SimpleTable.Row>
                ))}
              </SimpleTable.Body>
            </SimpleTable>
          )}
          {cadastralUnits.length === 0 && <Message message={EMPTY_PROPERTY_CADASTRAL_UNITS_INFO_MESSAGE} />}
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default PropertyCadastralRegistryWidget;
