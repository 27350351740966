import useSWR, { Fetcher, Key } from 'swr';
import hash from 'object-hash';
import {
  getIntentsToProcessSWRParams, INTENTS_NEW_RID, INTENTS_RECALL_RID, INTENTS_SCHEDULED_RID, INTENTS_SECTION_TO_RID, INTENTS_WITH_OFFERS_RID,
} from '../../../intent/hooks/use-intents-to-process/useIntentsToProcess';
import { IntentSection } from '../../../intent/types';
import { getIntents, IntentsProviderFilters } from '../../../providers/api/intent/intentProvider';
import { PropertiesMatchesFilters, getPropertyMatchingIntents, getPropertyMatchingSearchCriteria } from '../../../providers/api/property/propertyProvider';
import { PaginatedResult, PaginationParams, SortOrder } from '../../../providers/pagination';
import { PropertyMatches, PropertyMatchesSection, PropertyMatchAdditionalSection } from '../../types';
import { DEFAULT_PAGINATION_SIZE } from '../../../utils/querystring/querystring';
import { INTENTS_RID } from '../../../intent/hooks/use-intents/useIntents';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';
import { Property } from '../../../providers/api/dtos';
import { NOT_ALLOWED_INTENT_STATUS_FOR_RECOVERY } from '../../../intent/containers/recovery-intent/constants';

export const PROPERTY_MATCHING_SEARCH_CRITERIA_RID = '/properties/:id/match/search-criteria';
export const PROPERTY_MATCHING_INTENTS_RID = '/properties/:id/match/intents';

export const ALL_PROPERTY_MATCHES_RIDS = [
  INTENTS_RID,
  INTENTS_NEW_RID,
  INTENTS_RECALL_RID,
  INTENTS_SCHEDULED_RID,
  INTENTS_WITH_OFFERS_RID,
  PROPERTY_MATCHING_SEARCH_CRITERIA_RID,
  PROPERTY_MATCHING_INTENTS_RID,
] as const;

export const PROPERTY_MATCHES_SECTION_TO_RID = (
  section: PropertyMatchesSection,
  propertyId?: NonNullable<Property['id']>,
): string => {
  const rids: Record<PropertyMatchesSection, string> = {
    [PropertyMatchAdditionalSection.SIMILAR_INTENTS]: [PROPERTY_MATCHING_INTENTS_RID, propertyId].join(),
    [PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITH_DONE_APPOINTMENTS]: INTENTS_RID,
    [PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITHOUT_DONE_APPOINTMENTS]: INTENTS_RID,
    [PropertyMatchAdditionalSection.SEARCH_CRITERIA]: [PROPERTY_MATCHING_SEARCH_CRITERIA_RID, propertyId].join(),
    ...INTENTS_SECTION_TO_RID,
  };

  return rids[section];
};

const paginationParamsBuilder = (section: PropertyMatchesSection, page?: number, size?: number) => {
  const pagination: PaginationParams = {
    page,
    size: size || DEFAULT_PAGINATION_SIZE,
  };

  switch (section) {
    case PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITH_DONE_APPOINTMENTS:
    case PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITHOUT_DONE_APPOINTMENTS:
      return {
        ...pagination,
        sort: {
          createdAt: SortOrder.DESC,
        },
      };
    case IntentSection.SCHEDULED_TODO:
      return {
        ...pagination,
        sort: {
          appointment_start_date: SortOrder.ASC,
        },
      };
    case IntentSection.SCHEDULED_PENDING:
    case IntentSection.SCHEDULED_EXPIRED:
      return {
        ...pagination,
        sort: {
          appointment_start_date: SortOrder.DESC,
        },
      };
    default:
      return pagination;
  }
};

export default function usePropertyMatches(
  section: PropertyMatchesSection,
  propertyId?: number,
  filters: PropertiesMatchesFilters = {},
  page?: number,
  size?: number,
): SWRCustomResponse<PaginatedResult<PropertyMatches>, Error> {
  const matchMutate = useMatchMutate();

  let key: Key = null;
  let fetch: Fetcher<PaginatedResult<PropertyMatches>>;
  const pagination = paginationParamsBuilder(section, page, size);

  switch (section) {
    case PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITH_DONE_APPOINTMENTS:
    case PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITHOUT_DONE_APPOINTMENTS: {
      const baseFilters: IntentsProviderFilters = {
        excludeStatus: NOT_ALLOWED_INTENT_STATUS_FOR_RECOVERY,
        propertyId,
        withDoneAppointments: section === PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITH_DONE_APPOINTMENTS,
      };

      key = propertyId ? [INTENTS_RID, hash({ ...baseFilters, ...pagination })] : null;
      fetch = () => getIntents(baseFilters!, pagination);
      break;
    }
    case PropertyMatchAdditionalSection.SIMILAR_INTENTS: {
      key = propertyId ? [PROPERTY_MATCHES_SECTION_TO_RID(PropertyMatchAdditionalSection.SIMILAR_INTENTS, propertyId), propertyId, hash(pagination)] : null;
      fetch = () => getPropertyMatchingIntents(propertyId!, filters, pagination);
      break;
    }
    case PropertyMatchAdditionalSection.SEARCH_CRITERIA: {
      key = propertyId ? [PROPERTY_MATCHES_SECTION_TO_RID(PropertyMatchAdditionalSection.SEARCH_CRITERIA, propertyId), propertyId, hash(pagination)] : null;
      fetch = () => getPropertyMatchingSearchCriteria(propertyId!, filters, pagination);
      break;
    }
    case IntentSection.TODO:
    case IntentSection.RECALL:
    case IntentSection.SCHEDULED_TODO:
    case IntentSection.SCHEDULED_EXPIRED:
    case IntentSection.SCHEDULED_PENDING:
    case IntentSection.OFFERS_IN_PROGRESS:
    case IntentSection.OFFERS_ACCEPTED:
    case IntentSection.OFFERS_REFUSED:
    case IntentSection.OFFERS_WITHDRAWN: {
      const {
        key: intentsToProcessKey,
        fetcher: intentsToProcessFetch,
      } = getIntentsToProcessSWRParams(section as IntentSection, {
        propertyId,
      }, pagination);

      key = propertyId ? intentsToProcessKey : null;
      fetch = intentsToProcessFetch;
      break;
    }
    default:
      break;
  }

  const swrResponse = useSWR(key, fetch!);

  return {
    ...swrResponse,
    mutate: () => matchMutate(PROPERTY_MATCHES_SECTION_TO_RID(section, propertyId)),
  };
}
