import useSWR from 'swr';
import hash from 'object-hash';
import { Intent } from '../../../providers/api/dtos/intent';
import { getIntents, IntentsProviderFilters } from '../../../providers/api/intent/intentProvider';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

export const INTENTS_RID = '/api/v2/intents';

export default function useIntents(
  filters?: IntentsProviderFilters,
  pagination?: PaginationParams,
): SWRCustomResponse<PaginatedResult<Intent>, Error> {
  const matchMutate = useMatchMutate();
  const key = filters ? [INTENTS_RID, hash({ ...filters, ...pagination })] : null;
  const result = useSWR(key, () => getIntents(filters, pagination));

  return {
    ...result,
    mutate: () => matchMutate(INTENTS_RID),
  };
}
