import { JobTitle } from '../../domain/types';

export const jobTitleRank: Record<JobTitle, number> = {
  EXECUTIVE: 1,
  AREA_MANAGER: 2,
  BROKER: 3,
  TEAM_LEADER: 4,
  AGENTE: 5,
  CONSULENTE: 6,
};
