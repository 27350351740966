import React from 'react';
import {
  Action, ConfirmModal, FormSubmitType, HStack, Label, Message, Spacing, Stack, Tooltip, Typography, useModal, DetailItemList,
} from '@doveit/bricks';
import hash from 'object-hash';
import { formatDate } from '@doveit/hammer';
import Divider from '../../../../components/divider/Divider';
import SelectContact, { SelectContactRef } from '../../../../contact/containers/select-contact/SelectContact';
import NoContactView from '../../../../contact/containers/select-contact/components/no-contact-view/NoContactView';
import { Contact, OfferWizardData, WizardContact } from '../../../../providers/api/dtos';
import Card from '../../../../components/card/Card';
import { isWizardContactComplete } from '../../../../property/assignment/components/assignment-wizard/assignment-wizard-seller/utils';
import AssignmentWizardSellerForm, { AssignmentWizardSellerFormModel } from '../../../../property/assignment/components/assignment-wizard/assignment-wizard-seller/AssignmentWizardSellerForm';
import {
  mapToWizardContact, toPropertyContact, toWizardContact,
} from '../../../../property/assignment/components/assignment-wizard/assignment-wizard-seller/mappers';
import SimpleModal from '../../../../components/simple-modal/SimpleModal';
import AssignmentWizardContactForm, { AssignmentWizardContactFormModel } from '../../../../property/assignment/components/assignment-wizard/assignment-wizard-seller/AssignmentWizardContactForm';
import { OfferWizardStepProps } from '../types';
import { toAssignmentWizardSellerModel, toOfferWizardData } from './mapper';
import { SearchContactFormModel } from '../../../../contact/components/search-contact-form/SearchContactForm';
import { usePropertyContacts } from '../../../../property/hooks/use-property-contacts/usePropertyContacts';
import { PropertyRelationshipType } from '../../../../domain/types';

export const NO_CONTACTS_INFO_MESSAGE = 'Aggiungi il/i proprietario/i dell\'immobile.';
export const NO_LEGAL_INFO_MESSAGE = 'Aggiungi il rappresentante legale.';
export const MISSING_CONTACT_INFORMATION_INFO_MESSAGE = 'Inserisci i dati mancanti del contatto.';
export const LEGAL_PERSON_WITH_MANY_CONTACTS_TOOLTIP = 'Non è possibile indicare la tipologia Persona giuridica in presenza di più contatti.';

const OfferWizardSellers: React.FC<OfferWizardStepProps> = ({
  intent,
  initialData,
  formRef,
  loading = false,
  onSubmit,
}) => {
  const [contacts, setContacts] = React.useState<WizardContact[]>([]);
  const [isLegalPersonOnly, setIsLegalPersonOnly] = React.useState(initialData.isSellerLegalPersonOnly || false);
  const selectContactRef = React.useRef<SelectContactRef>(null);
  const updateContactModal = useModal<number | undefined>();
  const { data: propertyContacts, isLoading: propertyContactsLoading } = usePropertyContacts(intent.propertyId);
  const removeContactConfirmModal = useModal<number | undefined>();
  const [areContactsDirty, setAreContactsDirty] = React.useState(false);
  const [isContactSaving, setIsContactSaving] = React.useState(false);
  const isLegalPersonDisabled = React.useMemo(() => contacts.length > 1, [contacts.length]);
  const propertyOwners = React.useMemo(() => {
    const owners = propertyContacts?.filter((owner) => owner.relationship === PropertyRelationshipType.PROPRIETARIO) || [];

    return owners.map((owner) => mapToWizardContact(owner.contact));
  }, [propertyContacts]);

  React.useEffect(() => {
    let contactsToShow: WizardContact[] = [];

    if (initialData.sellerContacts) {
      contactsToShow = initialData.sellerContacts;
    } else if (propertyOwners) {
      contactsToShow = propertyOwners;
    }

    setContacts(contactsToShow);
    setAreContactsDirty(true);
  }, [initialData.sellerContacts, propertyOwners]);

  const updateContact = React.useCallback((index: number) => async (formValues: AssignmentWizardContactFormModel) => {
    setContacts((current) => {
      current[index] = toWizardContact(current[index], formValues);

      return current;
    });

    setAreContactsDirty(true);
    setIsContactSaving(false);

    updateContactModal.close();
  }, [updateContactModal]);

  const onInternalSubmit: FormSubmitType<AssignmentWizardSellerFormModel> = React.useCallback(async (formValues: AssignmentWizardSellerFormModel) => {
    const values: OfferWizardData = await toOfferWizardData(initialData, formValues, contacts, isLegalPersonOnly);

    const isSellerInfoFormDirty = (formRef?.current as any)?.dirty ?? false;
    const isLegalPersonOnlyDirty = initialData.isSellerLegalPersonOnly !== isLegalPersonOnly;

    onSubmit(values, isSellerInfoFormDirty || areContactsDirty || isLegalPersonOnlyDirty);
  }, [areContactsDirty, contacts, formRef, initialData, isLegalPersonOnly, onSubmit]);

  const openUpdateContactModal = React.useCallback(
    (index: number) => () => updateContactModal.open(index),
    [updateContactModal],
  );

  const openRemoveContactConfirmModal = React.useCallback(
    (index: number) => () => removeContactConfirmModal.open(index),
    [removeContactConfirmModal],
  );

  const onContactChange = React.useCallback((contactData?: Contact, searchContact?: SearchContactFormModel) => {
    let newWizardContact: WizardContact;

    if (contactData) {
      const {
        id, name, email, phoneNumber, fiscalCode, birthday, birthplace, residence,
      } = contactData;
      const { name: overriddenName, email: overridenEmail, phoneNumber: overridenPhoneNumber } = searchContact || {};

      newWizardContact = {
        id: id ?? undefined,
        name: overriddenName || name,
        email: overridenEmail || email,
        phoneNumber: overridenPhoneNumber || phoneNumber,
        birthday,
        birthplace,
        fiscalCode,
        placeOfResidence: residence,
      };
    } else {
      const { name, phoneNumber, email } = searchContact!;

      newWizardContact = {
        name,
        email,
        phoneNumber,
      };
    }

    setContacts((prev) => [...prev, newWizardContact]);
    selectContactRef.current?.resetInternalState();
    setAreContactsDirty(true);
  }, []);

  const onPersonTypeChange = React.useCallback((isLegalPerson: boolean) => () => {
    setIsLegalPersonOnly(isLegalPerson);
  }, []);

  const removeContact = React.useCallback((index: number) => () => {
    setContacts((prev) => {
      const toSplice = prev.slice();
      toSplice.splice(index, 1);

      return toSplice;
    });

    setAreContactsDirty(true);
    removeContactConfirmModal.close();
  }, [removeContactConfirmModal]);

  return (
    <>
      <Label text="Tipologia" />
      <Spacing margin={[0, 0, 200]}>
        <HStack>
          <Action
            label="Persona fisica"
            aria-label="Selezione persona fisica"
            size="S"
            emphasis="high"
            color={!isLegalPersonOnly ? 'primary' : 'neutral'}
            onClick={onPersonTypeChange(false)}
          />
          {isLegalPersonDisabled && (
            <Tooltip content={LEGAL_PERSON_WITH_MANY_CONTACTS_TOOLTIP}>
              <span>
                &nbsp;&nbsp;&nbsp;
                <Action
                  label="Persona giuridica"
                  aria-label="Selezione persona giuridica"
                  size="S"
                  emphasis="high"
                  color={isLegalPersonOnly ? 'primary' : 'neutral'}
                  disabled
                />
              </span>
            </Tooltip>
          )}
          {!isLegalPersonDisabled && (
            <Action
              label="Persona giuridica"
              aria-label="Selezione persona giuridica"
              size="S"
              emphasis="high"
              color={isLegalPersonOnly ? 'primary' : 'neutral'}
              onClick={onPersonTypeChange(true)}
            />
          )}
        </HStack>
      </Spacing>

      <Divider>
        {isLegalPersonOnly ? 'Rappresentante legale' : 'Elenco venditori'}
      </Divider>

      {(!isLegalPersonOnly || contacts.length === 0) && (
        <SelectContact
          onContactChange={onContactChange}
          componentRef={selectContactRef}
          render={{
            noContactView: ({ openContactFinder }) => (
              <NoContactView
                label="Cerca o aggiungi"
                openContactFinder={openContactFinder}
              />
            ),
          }}
        />
      )}
      {contacts.length === 0 && (
        <Spacing margin={[200, 0, 0]}>
          <Message
            type="info"
            message={isLegalPersonOnly ? NO_LEGAL_INFO_MESSAGE : NO_CONTACTS_INFO_MESSAGE}
          />
        </Spacing>
      )}
      {contacts.length > 0 && (
        <Spacing margin={[200, 0, 0]}>
          <Stack>
            {contacts.map((propertyContact, index) => (
              <Card
                key={hash([propertyContact, index])}
                aria-label="property-contact-card"
              >
                <Card.Header>
                  <Card.Title>
                    <Typography.HEADING_3 color="brand.primary">
                      Contatto
                    </Typography.HEADING_3>
                  </Card.Title>
                  <HStack>
                    <Action
                      label="Modifica"
                      size="S"
                      aria-label="edit-property-contact"
                      onClick={openUpdateContactModal(index)}
                    />
                    <Action
                      label="Dissocia"
                      size="S"
                      color="critical"
                      aria-label="remove-property-contact"
                      onClick={openRemoveContactConfirmModal(index)}
                    />
                  </HStack>
                </Card.Header>
                <Card.Box>
                  {!isWizardContactComplete(propertyContact) && (
                    <Spacing margin={[0, 0, 200]}>
                      <Message
                        type="info"
                        message={MISSING_CONTACT_INFORMATION_INFO_MESSAGE}
                      />
                    </Spacing>
                  )}
                  <DetailItemList columns={2}>
                    <DetailItemList.Item label="Nome e cognome">
                      {propertyContact.name}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Telefono">
                      {propertyContact.phoneNumber}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Email">
                      {propertyContact.email}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Residenza">
                      {propertyContact.placeOfResidence?.normalizedAddress}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Data di nascita">
                      {propertyContact.birthday ? formatDate(new Date(propertyContact.birthday)) : undefined}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Luogo di nascita">
                      {propertyContact.birthplace}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Codice fiscale">
                      {propertyContact.fiscalCode}
                    </DetailItemList.Item>
                  </DetailItemList>
                </Card.Box>
              </Card>
            ))}
          </Stack>
        </Spacing>
      )}
      <div style={{ display: isLegalPersonOnly && contacts.length > 0 ? 'block' : 'none' }}>
        <Spacing margin={[200, 0, 0]}>
          <AssignmentWizardSellerForm
            initialValues={toAssignmentWizardSellerModel(initialData, propertyOwners)}
            formRef={formRef}
            onSubmit={onInternalSubmit}
            disabled={contacts.length === 0}
            loading={loading || propertyContactsLoading}
          />
        </Spacing>
      </div>
      {updateContactModal.data !== undefined && (
        <SimpleModal
          {...updateContactModal}
          title="Modifica contatto"
          loading={isContactSaving}
        >
          <AssignmentWizardContactForm
            initialValues={toPropertyContact(contacts[updateContactModal.data])}
            onSubmit={updateContact(updateContactModal.data)}
          />
        </SimpleModal>
      )}
      {removeContactConfirmModal.data !== undefined && contacts.length > 0 && (
        <ConfirmModal
          isOpen={removeContactConfirmModal.isOpen}
          title={`Dissocia ${isLegalPersonOnly ? 'rappresentante legale' : 'venditore'}`}
          onConfirm={removeContact(removeContactConfirmModal.data!)}
          onAbort={removeContactConfirmModal.close}
        >
          Confermando l&apos;operazione, il contatto sarà rimosso {isLegalPersonOnly ? 'dalla posizione di rappresentante legale' : 'dall\'elenco venditori'}.
        </ConfirmModal>
      )}
    </>
  );
};

export default OfferWizardSellers;
