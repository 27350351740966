/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import * as styles from './ConversationPreview.style';
import { formatTimeDistance } from '../../../utils/time/format-time-distance/formatTimeDistance';
import { NO_VALUE_SYMBOL } from '../../../property/utils';

export interface ConversationPreviewProps {
  name?: string,
  phoneNumber?: string,
  message?: string,
  date?: string,
  onClick: VoidFunction,
  active?: boolean,
  hasUnreadMessages?: boolean,
}

const ConversationPreview: React.FunctionComponent<ConversationPreviewProps> = ({
  name,
  phoneNumber,
  message,
  date,
  onClick,
  active,
  hasUnreadMessages,
}) => (
  <div
    css={styles.basic}
    $active={active}
    onClick={onClick}
    role="button"
  >
    <div css={styles.avatarContainer}>
      <div
        css={styles.avatar}
        $hasName={!!name}
      >
        {name && name.substring(0, 1).toUpperCase()}
        {/** TODO: Not still defined in FIGMA */}
        {!name && (
          <img
            src="data:image/svg+xml,%3csvg viewBox='0 0 84 84' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M0 0H84V84H0V0Z' fill='%23F7F7F7'/%3e %3cpath d='M42.2479 36.4444C46.5924 36.4444 50.0679 32.9944 50.0679 28.7778C50.0679 24.5611 46.5924 21.1111 42.2479 21.1111C37.9035 21.1111 34.4279 24.5611 34.4279 28.7778C34.4279 32.9944 37.9035 36.4444 42.2479 36.4444ZM42.2479 41.5556C35.0924 41.5556 29.3168 35.8311 29.3168 28.7778C29.3168 21.7244 35.0924 16 42.2479 16C49.4035 16 55.179 21.7244 55.179 28.7778C55.179 35.8311 49.4035 41.5556 42.2479 41.5556ZM24.359 62H60.1368V58.6011C60.1368 54.1289 54.2335 49.5033 42.2479 49.5033C30.2624 49.5033 24.359 54.1289 24.359 58.6011V62ZM42.2479 44.3922C59.2679 44.3922 65.2479 52.9022 65.2479 58.6011V67.1111H19.2479V58.6011C19.2479 52.9022 25.2279 44.3922 42.2479 44.3922Z' fill='%23DDDDDD'/%3e %3c/svg%3e"
            alt="Avatar"
          />
        )}
      </div>
    </div>
    <div css={styles.primary}>
      <div>
        {name || phoneNumber || NO_VALUE_SYMBOL}
      </div>
      {message && (
        <div
          css={styles.message}
          $unread={hasUnreadMessages}
        >
          {message}
        </div>
      )}
    </div>
    <div css={styles.secondary}>
      {date && (
        <div
          css={styles.date}
          $unread={hasUnreadMessages}
        >
          {formatTimeDistance(date, Date.now())}
        </div>
      )}
      {hasUnreadMessages && (
        <div css={styles.unread} />
      )}
    </div>
  </div>
);

export default ConversationPreview;
