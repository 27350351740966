import React from 'react';
import {
  Action, Form, FormActionsAlign, Portal, useModal,
  useNotifications,
} from '@doveit/bricks';
import { Offer } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { updateOffer } from '../../../providers/api/offer/offerProvider';
import validationSchema from './OfferValidUntilDateForm.schema';

export const UPDATE_VALID_UNTIL_DATE_SUCCESS_MESSAGE = 'La data massima di validità dell\'offerta è stata modificata con successo';
export const UPDATE_VALID_UNTIL_DATE_ERROR_MESSAGE = 'Non è stato possibile modificare la data massima di validità dell\'offerta';

export interface ChildFnProps {
  onClick: VoidFunction,
  loading?: boolean,
}

export interface UpdateOfferValidUntilDateActionProps {
  offer: Offer,
  onSuccess?: (updatedOffer: Offer) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export interface OfferValiUntilDateFormModel {
  validUntil?: string,
}

const UpdateOfferValidUntilDateAction: React.FC<UpdateOfferValidUntilDateActionProps> = ({
  offer,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();

  const updateValidUntilModal = useModal();

  const onUpdateValidUntilDateAction = React.useCallback(() => {
    updateValidUntilModal.open();
  }, [updateValidUntilModal]);

  const onSubmit = React.useCallback(async (formValues: OfferValiUntilDateFormModel) => {
    setIsSaving(true);

    try {
      const updatedOffer = await updateOffer({
        ...offer,
        validUntil: formValues.validUntil,
      });

      onSuccess?.(updatedOffer);

      setIsSaving(false);
      updateValidUntilModal.close();
      addSuccess(UPDATE_VALID_UNTIL_DATE_SUCCESS_MESSAGE);
    } catch (err) {
      setIsSaving(false);

      addError(UPDATE_VALID_UNTIL_DATE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, offer, onSuccess, updateValidUntilModal]);

  return (
    <> {
      children
        ? children({ onClick: onUpdateValidUntilDateAction, loading: isSaving })
        : (
          <Action
            label="Proroga scadenza"
            size="S"
            loading={isSaving}
            onClick={onUpdateValidUntilDateAction}
            aria-label="Pulsante per modificare la data massima di validità dell'offerta"
          />
        )
    }
      <Portal>
        <SimpleModal
          {...updateValidUntilModal}
          title="Modifica data massima di validità dell'offerta "
          aria-label="Modale per modificare la data massima di validità dell'offerta"
        >
          <Form
            onSubmit={onSubmit}
            initialValues={{ validUntil: offer.validUntil }}
            validationSchema={validationSchema}
          >
            <Form.Item>
              <Form.Input
                label="Data massima di validità"
                name="validUntil"
                type="date"
                min={new Date().toISOString()}
              />
            </Form.Item>
            <Form.Actions align={FormActionsAlign.RIGHT}>
              <Form.Submit
                label="Modifica"
              />
            </Form.Actions>

          </Form>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpdateOfferValidUntilDateAction;
