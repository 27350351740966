/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import * as styles from './Game.style';

enum ItemType {
  CHARACTER,
  OBSTACLE,
  BACKGROUND,
}

interface Item {
  type: ItemType;
  x: number;
  y: number;
  img: string;
  rotate?: number;
}

const WIDTH = 320;
const HEIGHT = 180;
const SIDE = 20;
const JUMP = 100;
const JUMP_TIME = 1e3;
const VELOCITY = 100;
const POINTS = 5e3;

const PAOLO: Item = {
  type: ItemType.CHARACTER,
  x: SIDE,
  y: 0,
  img: 'https://emoji.slack-edge.com/TF4Q4NHAP/paolino/38c807e83c1167f6.jpg',
};

const OBSTACLE = [
  'https://a.slack-edge.com/production-standard-emoji-assets/10.2/google-large/1f3e0@2x.png',
  'https://a.slack-edge.com/production-standard-emoji-assets/10.2/google-large/1f3e1@2x.png',
  'https://a.slack-edge.com/production-standard-emoji-assets/10.2/google-large/1f3d7-fe0f@2x.png',
  'https://a.slack-edge.com/production-standard-emoji-assets/10.2/google-large/1f3da-fe0f@2x.png',
  'https://a.slack-edge.com/production-standard-emoji-assets/10.2/google-large/1f3e2@2x.png',
];

const BACKGROUND = [
  'https://a.slack-edge.com/production-standard-emoji-assets/10.2/google-large/2601-fe0f@2x.png',
  'https://a.slack-edge.com/production-standard-emoji-assets/10.2/google-large/1f324-fe0f@2x.png',
  'https://a.slack-edge.com/production-standard-emoji-assets/10.2/google-large/2600-fe0f@2x.png',
];

function weight(cur: number, from: number, to: number) {
  return (cur - from) / (to - from);
}

function lerp(from: number, to: number, w: number) {
  const cw = Math.max(Math.min(w, 1), 0);
  return ((1 - cw) * from) + (to * cw);
}

function rand(from: number, to: number) {
  return lerp(from, to, Math.random());
}

function collide(item: Item, other: Item) {
  const imx = item.x + (SIDE / 2);
  const imy = item.y + (SIDE / 2);
  const omx = other.x + (SIDE / 2);
  const omy = other.y + (SIDE / 2);
  return Math.abs(imx - omx) <= SIDE
    && Math.abs(imy - omy) <= SIDE;
}

export default function Game({
  onEnd,
}: {
  onEnd: (points: number) => void
}) {
  const [shownItems, setShownItems] = useState<Item[]>();
  const [shownPoints, setShownPoints] = useState<number>();
  const [shownStarted, setShownStarted] = useState<boolean>(false);

  useEffect(() => {
    let obstacles: Item[];
    let background: Item[];
    let points: number;
    let timer: number;
    let lastFrame = 0;
    let started = false;
    let jump = 0;
    let lastX = WIDTH;

    const frame = () => {
      const now = Date.now();
      const elapsed = now - lastFrame;

      if (lastFrame) {
        if (jump) {
          const w = weight(now, jump, jump + JUMP_TIME);

          const up = lerp(0, JUMP, w * 2);
          const down = lerp(JUMP, 0, (w - 0.5) * 2);
          PAOLO.y = lerp(up, down, w); // up & down on my body, make me feel your somebody, movin on, shakin on, are you ready?
          PAOLO.rotate = lerp(0, 360, w);

          if (now >= jump + JUMP_TIME) {
            jump = 0;
            PAOLO.y = 0;
            PAOLO.rotate = 0;
          }
        }

        let lastBackground = -Infinity;
        background.forEach((b) => {
          b.x -= lerp(0, VELOCITY / 2, elapsed / 1e3);
          lastBackground = Math.max(lastBackground, b.x);
        });

        background.forEach((b) => {
          if (b.x < -SIDE * 2) {
            b.x = lastBackground + WIDTH;
            b.img = BACKGROUND[Math.floor(rand(0, BACKGROUND.length))];
          }
        });

        lastX = -Infinity;
        obstacles.forEach((o) => {
          o.x -= lerp(0, VELOCITY, elapsed / 1e3);
          lastX = Math.max(lastX, o.x);
        });

        obstacles.forEach((o) => {
          started = started && !collide(PAOLO, o);
          if (o.x <= 0) {
            o.x = lastX + rand(SIDE * 3, SIDE * 10);
            lastX = o.x;
            o.img = OBSTACLE[Math.floor(rand(0, OBSTACLE.length))];
            points += POINTS;
          }
        });

        setShownItems([...background, PAOLO, ...obstacles]);
        setShownPoints(points);
        setShownStarted(started);
      }

      if (!started) {
        if (onEnd) {
          onEnd(points);
        }

        clearInterval(timer);
      }

      lastFrame = Date.now();
    };

    const start = () => {
      lastX = WIDTH;

      obstacles = [...new Array(5)].map(() => {
        const x = lastX + rand(SIDE * 3, SIDE * 10);
        lastX = x;
        return {
          type: ItemType.OBSTACLE,
          x,
          y: 0,
          img: OBSTACLE[Math.floor(rand(0, OBSTACLE.length - 1))],
        };
      });

      background = [...new Array(3)].map((_, index) => ({
        type: ItemType.BACKGROUND,
        x: WIDTH + (WIDTH * index),
        y: 0,
        img: BACKGROUND[Math.floor(rand(0, BACKGROUND.length - 1))],
      }));

      PAOLO.y = 0;
      PAOLO.rotate = 0;
      points = 0;
      lastFrame = 0;
      started = true;
      jump = 0;
      timer = window.setInterval(frame, 1e3 / 60);
      setShownStarted(true);
    };

    const input = (e: KeyboardEvent | TouchEvent) => {
      if (e instanceof KeyboardEvent && e.key !== ' ') {
        return;
      }

      if (!started) {
        start();
        return;
      }

      jump = jump || Date.now();
    };

    window.addEventListener('keyup', input);
    window.addEventListener('touchstart', input);

    return () => {
      clearInterval(timer);
      window.removeEventListener('keyup', input);
      window.removeEventListener('touchstart', input);
    };
  }, [onEnd]);

  return (
    <div
      css={styles.wrapper}
    >
      <main css={styles.main}>
        <header css={styles.header}>
          {shownPoints !== undefined && (
            <>
              Fatturato:
              <span css={styles.points}>
                &euro; {`${shownPoints / 1e3}K`}
              </span>
            </>
          )}
        </header>
        {shownItems && shownItems.map((item, index) => {
          const backgroundStyle = item.type === ItemType.BACKGROUND ? {
            top: 0,
            bottom: 'auto',
            width: '25%',
            zIndex: 0,
            opacity: 0.8,
            filter: 'blur(4px)',
          } : {};

          return (
            <img
              style={{
                position: 'absolute',
                zIndex: 1,
                left: `${(item.x / WIDTH) * 100}%`,
                width: `${(SIDE / WIDTH) * 100}%`,
                bottom: `${(item.y / HEIGHT) * 100}%`,
                transform: `${item.rotate ? `rotate(${item.rotate}deg)` : ''}`,
                borderRadius: item.type === ItemType.CHARACTER ? '50%' : '',
                ...backgroundStyle,
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              src={item.img}
              alt="fun"
            />
          );
        })}
      </main>
      {!shownStarted && (
        <h2 css={styles.start}>Press space to start &amp; jump</h2>
      )}
    </div>
  );
}
