import React, { MutableRefObject } from 'react';
import {
  Action, FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agency } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { updateAgency } from '../../../providers/api/agency/agencyProvider';
import { commissionsToEditCommissionsFormModel } from '../../../agent/containers/edit-agent-commissions-action/mappers';
import { editCommissionFormModelToAgency } from './mappers';
import EditCommissionsForm, { EditCommissionsFormModel } from '../../../agent/components/edit-commissions-form/EditCommissionsForm';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface EditAgencyCommissionsActionProps {
  agency: Agency,
  onSuccess?: (updatedAgency: Agency) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_AGENCY_COMMISSIONS_SUCCESS_MESSAGE = 'Le provvigioni sono state modificate con successo';
export const EDIT_AGENCY_COMMISSIONS_ERROR_MESSAGE = 'Non è stato possibile modificare le provvigioni';

const EditAgencyCommissionsAction: React.FC<EditAgencyCommissionsActionProps> = (props) => {
  const { agency, onSuccess, children } = props;

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const formRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;

  const openEditModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const initialFormValues = React.useMemo(() => (agency.commissions ? commissionsToEditCommissionsFormModel(agency.commissions) : {}), [agency.commissions]);

  const onSubmit = React.useCallback(async (formValues: EditCommissionsFormModel) => {
    try {
      setIsSaving(true);

      const agencyToUpdate = editCommissionFormModelToAgency(formValues, agency);

      const updatedAgency = await updateAgency(agency.id!, agencyToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_AGENCY_COMMISSIONS_SUCCESS_MESSAGE);
      modal.close();

      onSuccess?.(updatedAgency);
    } catch (error) {
      setIsSaving(false);
      addError(EDIT_AGENCY_COMMISSIONS_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agency, modal, onSuccess]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  return (
    <>
      {children
        ? children({ openEditModal, isSaving })
        : (
          <Action
            aria-label="Modifica le provvigioni dell'agenzia"
            title="Modifica le provvigioni dell'agenzia"
            label="Modifica"
            size="S"
            onClick={openEditModal}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Modifica provvigioni"
          aria-label="Modale per modificare le provvigioni dell'agenzia"
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per modificare le provvigioni dell'agenzia"
              onClick={submitForm}
            />
          )}
        >
          <EditCommissionsForm
            isAgency
            initialValues={initialFormValues}
            onSubmit={onSubmit}
            innerRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAgencyCommissionsAction;
