import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import { Agent } from '../../../providers/api/dtos';
import { EMAIL_REGEX } from '../../../constants';

export const useCurrentAgentIsSameAgentOrManager = (
  agentIdOrEmail?: Agent['id'] | Agent['email']['work'] | undefined | null,
): boolean => {
  const currentAgent = useCurrentAgent();

  const isEmail = typeof agentIdOrEmail === 'string' && agentIdOrEmail.match(EMAIL_REGEX);

  if (isEmail) {
    return (currentAgent?.email.work === agentIdOrEmail || currentAgent?.canManage?.some(({ email }) => email.work === agentIdOrEmail)) || false;
  }

  return (currentAgent?.id === agentIdOrEmail || currentAgent?.canManage?.some(({ id }) => id === agentIdOrEmail)) || false;
};
