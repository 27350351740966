import {
  ICON_EMAIL_OUTLINE, ICON_PHONE_OUTLINE, ICON_WHATSAPP, ActionIcon,
  HStack,
} from '@doveit/bricks';
import React from 'react';
import { ThemeIcons } from '../../theme/icons';
import SendWhatsappAction from '../../containers/send-whatsapp-action/SendWhatsappAction';

export interface UserActionsProps extends React.AriaAttributes {
  user: {
    phoneNumber?: string,
    email?: string,
    linkedIn?: string,
  },
  userIsAgent?: boolean,
  hideCallAction?: boolean,
  hideWhatsAppAction?: boolean,
  hideEmailAction?: boolean,
  hideLinkedInAction?: boolean,
  whatsAppMessage?: string,
  onCallActionClick?: VoidFunction,
  onEmailActionClick?: VoidFunction,
  onLinkedInActionClick?: VoidFunction,
}

const UserActions: React.FC<UserActionsProps> = ({
  user,
  userIsAgent = false,
  hideCallAction,
  hideWhatsAppAction,
  hideEmailAction,
  hideLinkedInAction = true,
  whatsAppMessage,
  onCallActionClick: onCallClick,
  onEmailActionClick: onEmailClick,
  onLinkedInActionClick: onLinkedInClick,
  'aria-label': ariaLabel = 'user-actions',
  ...rest
}) => (
  <HStack
    {...rest}
    aria-label={ariaLabel}
  >
    {!hideCallAction && (
      <ActionIcon
        label="Chiama il contatto"
        dataRef="call-action"
        size="S"
        icon={{ path: ICON_PHONE_OUTLINE }}
        href={`tel:${user.phoneNumber}`}
        disabled={!user.phoneNumber}
        onClick={onCallClick}
      />
    )}
    {!hideWhatsAppAction && user.phoneNumber && (
      <SendWhatsappAction
        phoneNumber={user.phoneNumber}
        initialMessage={whatsAppMessage}
      >
        {({ onClick }) => (
          <ActionIcon
            label="Invia un messaggio WhatsApp"
            dataRef="whatsApp-action"
            size="S"
            icon={{ path: ICON_WHATSAPP }}
            onClick={onClick}
          />
        )}
      </SendWhatsappAction>
    )}
    {!hideEmailAction && (
      <ActionIcon
        label={user.email
          ? `Invia un'email a ${user.email}`
          : `${userIsAgent ? 'Il contatto' : "L'agente"} non ha fornito l'email`}
        dataRef="email-action"
        size="S"
        icon={{ path: ICON_EMAIL_OUTLINE }}
        href={user.email ? `mailto:${user.email}` : undefined}
        disabled={!user.email}
        onClick={onEmailClick}
      />
    )}
    {!hideLinkedInAction && (
      <ActionIcon
        label="Vai al profilo Linkedin del contatto"
        dataRef="linkedIn-action"
        size="S"
        icon={{ path: ThemeIcons.LINKEDIN }}
        disabled={!user.linkedIn}
        href={user.linkedIn}
        target="_blank"
        onClick={onLinkedInClick}
      />
    )}
  </HStack>
);

export default UserActions;
