import React from 'react';
import {
  Action,
  FormHandlers,
  Portal,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import { ShootingStatus } from '../../../domain/types';
import { Shooting } from '../../../providers/api/dtos';
import { updateShooting } from '../../../providers/api/shooting/shootingProvider';
import { useComputedShootingStatus } from '../../hooks/use-computed-shooting-status/useComputedShootingStatus';
import UpsertShootingForm, { UpsertShootingFormModel } from '../../components/upsert-shooting-form/UpsertShootingForm';
import { shootingToUpsertShootingFormModel, upsertShootingFormModelToShooting } from '../../utils';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { upsertShootingValidationSchema } from '../../components/upsert-shooting-form/UpsertShootingForm.schema';

interface ChildFnProps {
  isSaving: boolean,
  markAsReviewed: VoidFunction,
}

export interface MarkShootingAsReviewedActionProps extends React.AriaAttributes {
  shooting: Shooting,
  onSuccess?: (shooting: Shooting) => void,
  children?: (props: ChildFnProps) => React.ReactNode
}

export const MARK_SHOOTING_AS_REVIEWED_ERROR_MESSAGE = 'Non è stato possibile segnare lo shooting come non eseguito.';
export const MARK_SHOOTING_AS_REVIEWED_SUCCESS_MESSAGE = 'Lo shooting è stato segnato come non eseguito con successo.';

const MarkShootingAsReviewedAction: React.FC<MarkShootingAsReviewedActionProps> = ({
  shooting,
  onSuccess,
  children,
  ...rest
}) => {
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const [isSaving, setIsSaving] = React.useState(false);

  const { userIsContentEditor, userIsAdmin } = useRBAC();
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const { data } = useComputedShootingStatus(shooting);

  const canBeMarkedAsReviewed = React.useMemo(() => data?.canBeUpdatedTo(ShootingStatus.REVIEWED), [data]);

  const confirm = React.useCallback(async (formValues: UpsertShootingFormModel) => {
    setIsSaving(true);

    try {
      const shootingToMarkAsReviewed: Shooting = upsertShootingFormModelToShooting(formValues, {
        ...shooting,
        status: ShootingStatus.REVIEWED,
      });

      const shootingMarkedAsReviewed = await updateShooting(shootingToMarkAsReviewed);

      setIsSaving(false);
      modal.close();

      addSuccess(MARK_SHOOTING_AS_REVIEWED_SUCCESS_MESSAGE);
      onSuccess?.(shootingMarkedAsReviewed);
    } catch (error) {
      addError(MARK_SHOOTING_AS_REVIEWED_ERROR_MESSAGE);
      setIsSaving(false);
    }
  }, [shooting, modal, addSuccess, onSuccess, addError]);

  const handleConfirm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  if (!canBeMarkedAsReviewed) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          isSaving,
          markAsReviewed: modal.open,
        })
        : (
          <Action
            label="Inserisci dettagli shooting"
            aria-label="Pulsante per inserire i dettagli dello shooting"
            onClick={() => modal.open()}
            loading={isSaving}
            {...rest}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Inserisci dettagli shooting"
          aria-label="Modale per inserire i dettagli dello shooting"
          footer={(
            <Action
              label="Inserisci"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per confermare i dettagli dello shooting"
              onClick={handleConfirm}
            />
          )}
        >
          <UpsertShootingForm
            initialValues={shootingToUpsertShootingFormModel(shooting)}
            innerRef={formRef}
            onSubmit={confirm}
            validationSchema={upsertShootingValidationSchema(shooting)}
            loading={isSaving}
            hiddenFields={(!userIsContentEditor && !userIsAdmin) ? {
              matterportDeliveryDate: true,
              matterportRequest: true,
              postProcessingRequest: true,
              photoDeliveryDate: true,
              photoPrice: true,
              expensesRefund: true,
              extraServicesPrice: true,
            } : undefined}
            size="S"
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default MarkShootingAsReviewedAction;
