/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Form, FormHandlers, FormProps, Grid, HStack, Link, Spacing,
} from '@doveit/bricks';
import React from 'react';
import { AddressSuggestion } from '../../../providers/geo/dtos';
import { INPUT_PLACEHOLDERS } from '../../../constants';
import AddressSuggestionAutoComplete from '../../../components/address-suggestion-autocomplete/AddressSuggestionAutoComplete';
import { geocodeByAddress } from '../../../providers/geo/geocode/geocodeProvider';
import { isKeyOf } from '../../../utils/object/object';
import editAgencyRegistryValidationSchema from './EditAgencyRegistryForm.schema';

export interface EditAgencyRegistryFormModel {
  name: string,
  phoneNumber: string,
  openingHours: string,
  address: {
    administrativeAreaLevelOne?: string,
    administrativeAreaLevelTwo: string,
    locality: string,
    plateCode: string,
    postalCode: string,
    route: string,
    streetNumber: string,
    latitude?: string,
    longitude?: string,
    normalizedAddress?: AddressSuggestion,
  },
}

export interface EditAgencyRegistryFormProps {
  initialValues?: Partial<EditAgencyRegistryFormModel>,
  loading?: FormProps<EditAgencyRegistryFormModel>['loading'],
  disabled?: FormProps<EditAgencyRegistryFormModel>['disabled'],
  innerRef?: React.MutableRefObject<FormHandlers>,
  onSubmit: FormProps<EditAgencyRegistryFormModel>['onSubmit'],
}

export const EDIT_AGENCY_REGISTRY_FORM_DEFAULT_VALUES: EditAgencyRegistryFormModel = {
  name: '',
  phoneNumber: '',
  openingHours: '',
  address: {
    administrativeAreaLevelOne: '',
    administrativeAreaLevelTwo: '',
    locality: '',
    plateCode: '',
    postalCode: '',
    route: '',
    streetNumber: '',
    latitude: '',
    longitude: '',
  },
};

const GEO_FIELDS = [
  'administrativeAreaLevelOne',
  'administrativeAreaLevelTwo',
  'locality',
  'postalCode',
  'plateCode',
  'route',
  'streetNumber',
  'latitude',
  'longitude',
];

const EditAgencyRegistryForm: React.FC<EditAgencyRegistryFormProps> = (props) => {
  const { initialValues = {}, innerRef, ...rest } = props;

  const [
    showAddressGeoFields,
    setShowAddressGeoFields,
  ] = React.useState(
    !initialValues.address?.latitude
    && !initialValues.address?.longitude
    && !!initialValues.address?.normalizedAddress,
  );

  const mergedValues: EditAgencyRegistryFormModel = React.useMemo(() => ({
    ...EDIT_AGENCY_REGISTRY_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedValues}
      validationSchema={editAgencyRegistryValidationSchema}
      enableReinitialize
      innerRef={innerRef}
      {...rest}
    >
      {({ setFieldValue }) => {
        const onAddressChange = async (suggestion: AddressSuggestion | null) => {
          GEO_FIELDS.forEach((field) => {
            setFieldValue(`address.${field}`, '');
          });

          if (!suggestion) return;

          const { description } = suggestion;
          const [geoCode] = await geocodeByAddress(description);

          GEO_FIELDS.forEach((field) => {
            if (isKeyOf(geoCode, field)) {
              setFieldValue(`address.${field}`, geoCode[field] || '');
            }
          });
        };

        const toggleAddressGeoFieldsBox = (open: boolean) => () => {
          setShowAddressGeoFields(open);
          setFieldValue('address.normalizedAddress', null);

          GEO_FIELDS.forEach((field) => {
            setFieldValue(`address.${field}`, '');
          });
        };

        return (
          <>
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Input
                name="name"
                label="Nome"
                aria-label="Campo per inserire il nome dell'agenzia"
                placeholder={INPUT_PLACEHOLDERS.AGENCY_NAME}
                required
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Input
                name="phoneNumber"
                label="Telefono"
                aria-label="Campo per inserire il telefono dell'agenzia"
                placeholder={INPUT_PLACEHOLDERS.CONTACT_PHONE_NUMBER}
              />
            </Form.Item>

            <Form.Item>
              <Grid gutter={75}>
                <Grid.Unit>
                  <AddressSuggestionAutoComplete
                    name="address.normalizedAddress"
                    aria-label="Campo per inserire l'indirizzo dell'agenzia"
                    label="Indirizzo"
                    placeholder={INPUT_PLACEHOLDERS.RESIDENCE_ADDRESS}
                    onSuggestionSelected={onAddressChange}
                    disabled={showAddressGeoFields}
                  />
                  <Spacing margin={[100, 0, 0]}>
                    <HStack>
                      {showAddressGeoFields ? ' Vuoi cercare un nuovo indirizzo?' : 'Non hai trovato l\'indirizzo?'}
                      <Link
                        role="button"
                        aria-label={showAddressGeoFields ? 'Cerca un nuovo indirizzo' : 'Inserisci l\'indirizzo manualmente'}
                        onClick={toggleAddressGeoFieldsBox(!showAddressGeoFields)}
                      >
                        <strong>Clicca qui</strong>
                      </Link>
                    </HStack>
                  </Spacing>
                </Grid.Unit>
              </Grid>
            </Form.Item>
            {showAddressGeoFields && (
              <>
                <Form.Item size={{ MD: 2 / 3 }}>
                  <Form.Input
                    name="address.route"
                    label="Strada"
                    aria-label="Campo per inserire la strada dell'agenzia"
                    placeholder={INPUT_PLACEHOLDERS.PROPERTY_ROUTE}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 3 }}>
                  <Form.Input
                    name="address.streetNumber"
                    label="Numero civico"
                    aria-label="Campo per inserire il numero civico dell'agenzia"
                    placeholder={INPUT_PLACEHOLDERS.PROPERTY_STREET_NUMBER}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 2 / 3 }}>
                  <Form.Input
                    name="address.locality"
                    label="Comune"
                    aria-label="Campo per inserire il comune dell'agenzia"
                    placeholder={INPUT_PLACEHOLDERS.PROPERTY_LOCALITY}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 3 }}>
                  <Form.Input
                    name="address.plateCode"
                    label="Provincia (sigla)"
                    aria-label="Campo per inserire la provincia dell'agenzia"
                    placeholder={INPUT_PLACEHOLDERS.PROPERTY_PLATE_CODE}
                    minLength={2}
                    maxLength={2}
                  />
                </Form.Item>
              </>
            )}

            <Form.Item>
              <Form.TextArea
                name="openingHours"
                label="Orari di apertura"
                aria-label="Campo per inserire gli orari di apertura dell'agenzia"
                placeholder={INPUT_PLACEHOLDERS.AGENCY_OPENING_HOURS}
                rows={5}
              />
            </Form.Item>
          </>
        );
      }}
    </Form>
  );
};

export default EditAgencyRegistryForm;
