import {
  ThemedFunctor,
  SpacingName,
  getSpacing,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const image: ThemedFunctor = ({
  theme,
  height,
}) => css`
  ${getSpacing.padding(theme, SpacingName.SMALL)}
  background-color: ${theme.palettes.neutrals.dark};
  border-top: 1px solid ${theme.palettes.neutrals.dark};
  border-bottom: 1px solid ${theme.palettes.neutrals.dark};
  text-align: center;
  overflow-y: scroll;

  ${height && `
    height: ${height};
  `}
`;
