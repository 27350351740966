import { ThemeDefinition } from '@doveit/bricks';
import type { Style } from '@react-pdf/types';

export const page = (theme: ThemeDefinition):Style => ({
  paddingVertical: 30,
  paddingHorizontal: 50,
  fontFamily: 'Exo',
  lineHeight: 1.3,
  color: theme.palettes.neutrals.darkest,
});

export const gallery: Style = {
  paddingTop: 10,
  paddingBottom: 15,
};

export const row: Style = {
  paddingVertical: 15,
  display: 'flex',
  flexDirection: 'row',
  marginHorizontal: -10,
};

export const column: Style = {
  width: '50%',
  paddingHorizontal: 10,
};

export const features: Style = {
  paddingVertical: 20,
};

export const blueprintPage: Style = {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  flexDirection: 'row',
};

export const blueprint: Style = {
  width: '100%',
};
