/* eslint-disable react/no-unknown-property */
import {
  BreakpointQueryName, Form, Typography,
} from '@doveit/bricks';
import React, {
  ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Circle } from '@react-google-maps/api';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { formatMeters } from '@doveit/hammer';
import { useTheme } from 'styled-components';
import SimpleGoogleMap from '../../../components/simple-google-map/SimpleGoogleMap';
import { GeoSearchCriterionType, PointGeoSearchCriterion } from '../../../providers/api/dtos/searchCriteria';
import { AddressSuggestion, GeoCodeResult, Place } from '../../../providers/geo/dtos';
import { noop } from '../../../utils';
import { radiusMetersToStep, stepToRadiusMeters } from './utils/radius';
import AddressSuggestionAutoComplete from '../../../components/address-suggestion-autocomplete/AddressSuggestionAutoComplete';
import { reverseGeocode } from '../../../providers/geo/geocode/geocodeProvider';
import * as styles from './PointGeoSearchCriterionForm.style';
import useGoogleMapsAPI from '../../../hooks/use-google-maps-api/useGoogleMapsAPI';

export interface PointGeoSearchCriterionFormProps {
  initialValues?: PointGeoSearchCriterion,
  onChange: (values: PointGeoSearchCriterion) => void,
}

const DEFAULT_VALUES: Omit<PointGeoSearchCriterion, 'latitude' | 'longitude'> = {
  type: GeoSearchCriterionType.POINT,
  address: '',
  radius: 500,
};

// Maps to Rome city center
const DEFAULT_LATITUDE = 41.8982253;
const DEFAULT_LONGITUDE = 12.4685699;

const PointGeoSearchCriterionForm: FunctionComponent<PointGeoSearchCriterionFormProps> = ({
  initialValues,
  onChange,
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.up(BreakpointQueryName.MD));
  const { getPlaceDetails } = useGoogleMapsAPI();

  const mergedInitialValues = useMemo(() => ({
    ...DEFAULT_VALUES,
    ...initialValues,
    address: {
      placeId: '',
      description: initialValues?.address ?? '',
    },
  }), [initialValues]);

  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

  const normalizeAddress = useCallback(
    (place: Place | GeoCodeResult) => [place.route, place.streetNumber, place.locality]
      .filter((i) => i)
      .join(', '),
    [],
  );

  useEffect(() => {
    if (map && !initialValues) {
      map.setZoom(6);
    }
  }, [initialValues, map]);

  const setMapCenter = useCallback((latitude?: number, longitude?: number, radius?: number) => {
    if (map && latitude && longitude) {
      const circle = new google.maps.Circle({
        radius: (radius ?? 500),
        center: {
          lat: latitude,
          lng: longitude,
        },
      });

      const circleBounds = circle.getBounds();

      if (!circleBounds) {
        return;
      }

      map.fitBounds(circleBounds);
    }
  }, [map]);

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={noop}
      enableReinitialize
    >
      {({
        values,
        setFieldValue,
      }) => {
        const onAddressSuggestionSelected = async (suggestion: AddressSuggestion | null) => {
          if (!suggestion) {
            return;
          }

          const place = await getPlaceDetails(suggestion.placeId);

          onChange({
            ...values,
            latitude: place.latitude ?? DEFAULT_LATITUDE,
            longitude: place.longitude ?? DEFAULT_LONGITUDE,
            address: normalizeAddress(place),
          });

          setMapCenter(place.latitude, place.longitude, values.radius);
          setFieldValue('latitude', place.latitude, false);
          setFieldValue('longitude', place.longitude, false);
          setFieldValue('address', suggestion, false);
        };

        const onRadiusChange = (event: ChangeEvent<HTMLInputElement>) => {
          const radius = stepToRadiusMeters(+(event.currentTarget.value));

          onChange({
            ...values,
            latitude: values.latitude ?? DEFAULT_LATITUDE,
            longitude: values.longitude ?? DEFAULT_LONGITUDE,
            radius,
            address: values.address?.description,
          });

          setFieldValue('radius', radius, false);
          setMapCenter(values.latitude, values.longitude, radius);
        };

        const onDragEnd = async (latitude: number, longitude: number) => {
          const [result] = await reverseGeocode(latitude, longitude);
          const address = normalizeAddress(result);

          onChange({
            ...values,
            latitude,
            longitude,
            address,
          });

          setMapCenter(latitude, longitude, values.radius);
          setFieldValue('latitude', latitude, false);
          setFieldValue('longitude', longitude, false);
          setFieldValue('address', {
            placeId: result.placeId,
            description: address,
          }, false);
        };

        return (
          <>
            <Form.Item size={{ MD: 3 / 4 }}>
              <AddressSuggestionAutoComplete
                aria-label="Indirizzo"
                name="address"
                onSuggestionSelected={onAddressSuggestionSelected}
              />
              <div css={styles.map}>
                <SimpleGoogleMap
                  latitude={values.latitude ?? DEFAULT_LATITUDE}
                  longitude={values.longitude ?? DEFAULT_LONGITUDE}
                  searchRadius={values.latitude && values.longitude ? values.radius : undefined}
                  onLoad={setMap}
                  marker={{
                    draggable: true,
                    onDragEnd,
                  }}
                  options={{
                    draggable: !isMobile,
                    fullscreenControl: !isMobile,
                    mapTypeControl: !isMobile,
                    panControl: !isMobile,
                    rotateControl: !isMobile,
                    streetViewControl: !isMobile,
                    zoomControl: !isMobile,
                  }}
                >
                  {values.latitude && values.longitude && values.radius && (
                    <Circle
                      center={{
                        lat: values.latitude,
                        lng: values.longitude,
                      }}
                      radius={values.radius}
                    />
                  )}
                </SimpleGoogleMap>
              </div>
            </Form.Item>
            <Form.Item size={{ MD: 1 / 4 }}>
              <div css={styles.radius}>
                Raggio di ricerca
                <div css={styles.radiusLegend}>
                  <Typography.BODY_SMALL>100m</Typography.BODY_SMALL>
                  <Typography.BODY_SMALL>50km</Typography.BODY_SMALL>
                </div>
                <Form.InputRange
                  text="Raggio di ricerca"
                  type="range"
                  name="radius"
                  min={1}
                  max={23}
                  value={radiusMetersToStep(values.radius)}
                  disabled={!(values.latitude && values.longitude)}
                  onChange={onRadiusChange}
                />
                <Typography.BODY_SMALL>
                  {formatMeters(values.radius)}
                </Typography.BODY_SMALL>
              </div>
            </Form.Item>
          </>
        );
      }}
    </Form>
  );
};

export default PointGeoSearchCriterionForm;
