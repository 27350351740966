import { AssignmentWizardData, Geo } from '../../../../../providers/api/dtos';
import { GeoCodeResult } from '../../../../../providers/geo/dtos';
import { geocodeByAddress } from '../../../../../providers/geo/geocode/geocodeProvider';
import { PropertyGeoFormModel } from '../../../../components/property-geo-form/PropertyGeoForm';

export const toPropertyGeoFormModel = (
  initialData: AssignmentWizardData,
  leadPropertyAddress?: string,
  geoCodeResult?: GeoCodeResult,
): PropertyGeoFormModel => {
  const { property } = initialData;

  const geoFields = property?.geo ? {
    normalizedAddress: property.geo.normalizedAddress ? {
      description: property.geo.normalizedAddress,
      placeId: '',
    } : undefined,
    locality: property.geo.locality ?? '',
    postalCode: property.geo.postalCode ?? '',
    administrativeAreaLevelTwo: property.geo.administrativeAreaLevelTwo ?? '',
    route: property.geo.route ?? '',
    streetNumber: property.geo.streetNumber ?? '',
  } : {
    normalizedAddress: leadPropertyAddress ? {
      description: leadPropertyAddress,
      placeId: '',
    } : undefined,
    locality: geoCodeResult?.locality ?? '',
    postalCode: geoCodeResult?.postalCode ?? '',
    administrativeAreaLevelTwo: geoCodeResult?.administrativeAreaLevelTwo ?? '',
    route: geoCodeResult?.route ?? '',
    streetNumber: geoCodeResult?.streetNumber ?? '',
  };

  return {
    ...geoFields,
    stairwell: property?.stairwell ?? '',
    unit: property?.unit ?? '',
  };
};

export const toAssignmentWizardData = async (
  existingData: AssignmentWizardData,
  propertyGeoValues: PropertyGeoFormModel,
): Promise<AssignmentWizardData> => {
  const {
    administrativeAreaLevelTwo,
    locality,
    normalizedAddress,
    postalCode,
    route,
    streetNumber,
    unit,
    stairwell,
  } = propertyGeoValues;

  let latitude: string | undefined;
  let longitude: string | undefined;
  let administrativeAreaLevelOne: string | undefined;
  let suburb: string | undefined | null;

  if (normalizedAddress) {
    [{
      latitude, longitude, administrativeAreaLevelOne, suburb,
    }] = await geocodeByAddress(normalizedAddress.description);
  }

  const newPropertyGeoValues: Geo = {
    administrativeAreaLevelTwo: administrativeAreaLevelTwo || undefined,
    locality: locality || undefined,
    normalizedAddress: normalizedAddress?.description || undefined,
    postalCode: postalCode || undefined,
    route: route || undefined,
    streetNumber: streetNumber || undefined,
    latitude: latitude ? Number(latitude) : undefined,
    longitude: longitude ? Number(longitude) : undefined,
    administrativeAreaLevelOne,
    suburb: suburb || undefined,
  };

  const newPropertyValues: Partial<AssignmentWizardData['property']> = {
    unit: unit || undefined,
    stairwell: stairwell || undefined,
  };

  const newPropertyCadastralRegistryValues = {
    locality: locality || undefined,
  };

  const newValues: AssignmentWizardData = {
    ...existingData,
    isDigitallySigned: false,
    property: {
      ...existingData.property,
      ...newPropertyValues,
      cadastralRegistry: {
        ...existingData.property?.cadastralRegistry,
        ...newPropertyCadastralRegistryValues,
      },
      geo: {
        ...existingData.property?.geo,
        ...newPropertyGeoValues,
      },
    },
  };

  return newValues;
};
