import { formatDate, pluralizeWord } from '@doveit/hammer';
import { formatDistanceStrict } from 'date-fns';

function buildLabel(count: number, formattedUnit: string): string {
  return `${count}${formattedUnit}`;
}

export enum TimeDistanceUnit {
  EXTENDED,
  SHORT,
}

export function formatTimeDistance(
  date: Date | number | string,
  baseDate: Date | number | string,
  distanceUnit: TimeDistanceUnit = TimeDistanceUnit.SHORT,
  skipDaysLimiter: boolean = false,
): string {
  return formatDistanceStrict(new Date(date), new Date(baseDate), {
    addSuffix: true,
    locale: {
      formatDistance: (token, count) => {
        switch (token) {
          case 'xSeconds': {
            return distanceUnit === TimeDistanceUnit.EXTENDED
              ? buildLabel(count, pluralizeWord(' second', 'o', 'i', count))
              : buildLabel(count, 's');
          }
          case 'xMinutes': {
            return distanceUnit === TimeDistanceUnit.EXTENDED
              ? buildLabel(count, pluralizeWord(' minut', 'o', 'i', count))
              : buildLabel(count, 'm');
          }
          case 'xHours': {
            return distanceUnit === TimeDistanceUnit.EXTENDED
              ? buildLabel(count, pluralizeWord(' or', 'a', 'e', count))
              : buildLabel(count, 'h');
          }
          case 'xDays': {
            if (skipDaysLimiter || count < 7) {
              return distanceUnit === TimeDistanceUnit.EXTENDED
                ? buildLabel(count, pluralizeWord(' giorn', 'o', 'i', count))
                : buildLabel(count, pluralizeWord('g', '', 'g', count));
            }

            return formatDate(new Date(date));
          }
          default:
            return formatDate(new Date(date));
        }
      },
    },
  });
}
