import hash from 'object-hash';
import useSWR, { SWRResponse } from 'swr';
import { MatchCount } from '../../../providers/api/dtos/matchCount';
import { getProspectMatchCount } from '../../../providers/api/prospect/prospectProvider';

export const PROSPECT_MATCH_COUNT_RID = '/prospect/:id/match-count';

export function useProspectMatchCount(prospectId?: string): SWRResponse<MatchCount, Error> {
  return useSWR(prospectId ? [PROSPECT_MATCH_COUNT_RID, hash(prospectId)] : null, () => getProspectMatchCount(prospectId!));
}
