import { Form, FormActionsAlign, FormSubmitType } from '@doveit/bricks';
import React, { FunctionComponent, useMemo } from 'react';
import { FeatureType, PropertyStatus, ResidentialPropertyType } from '../../../domain/types';
import { featureTypeLabels } from '../../../property/labels';
import { OptionalBoolean } from '../../../types';
import SearchCriteriaSchema from './SearchCriteriaForm.schema';
import { propertyStatusLabels, residentialPropertyTypeLabels } from '../../../labels';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';

export type SearchCriteriaFormModel = {
  features: { [key in FeatureType]?: boolean },
  propertyTypes: ResidentialPropertyType[],
  propertyStatus: PropertyStatus[],
  balcony: boolean,
  terrace: boolean,
  garage: boolean,
  privateGarden: boolean,
  mortgage?: OptionalBoolean,
  auction?: OptionalBoolean,
  size?: {
    min?: string,
    max?: string,
  },
  price?: {
    min?: string,
    max?: string,
  },
  notes?: string,
};

export interface SearchCriteriaFormProps {
  initialValues?: SearchCriteriaFormModel,
  disabled?: boolean,
  loading?: boolean,
  onSubmit: FormSubmitType<SearchCriteriaFormModel>,
}

const SearchCriteriaForm: FunctionComponent<SearchCriteriaFormProps> = ({
  initialValues,
  disabled,
  loading,
  onSubmit,
}) => {
  const mergedInitialValues = useMemo(() => (initialValues || {
    features: {},
    propertyStatus: [],
    propertyTypes: [],
    balcony: false,
    terrace: false,
    garage: false,
    privateGarden: false,
    size: {
      min: '',
      max: '',
    },
    price: {
      min: '',
      max: '',
    },
  }), [initialValues]);
  return (
    <Form
      initialValues={mergedInitialValues}
      disabled={disabled}
      onSubmit={onSubmit}
      loading={loading}
      validationSchema={SearchCriteriaSchema}
      enableReinitialize
      preventEnterKeypress
    >

      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.SizeRange
          name="size"
          label="Dimensione (min/max)"
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.PriceRange
          name="price"
          label="Budget (min/max)"
        />
      </Form.Item>

      <Form.Item>
        <Form.MultiSelect
          name="propertyTypes"
          label="Tipologia immobile"
          aria-label="Campo per la selezione della tipologia immobile"
          options={asSelectOptions(residentialPropertyTypeLabels, { value: '', label: 'Seleziona', disabled: true })}
        />
      </Form.Item>

      <Form.Item>
        <Form.MultiSelect
          name="propertyStatus"
          label="Stato dell'immobile"
          aria-label="Campo per la selezione dello stato immobile"
          options={asSelectOptions(propertyStatusLabels, false)}
        />
      </Form.Item>

      <Form.Group label="Caratteristiche">
        <Form.Item size={{ MD: 1 / 5 }}>
          <Form.Checkbox
            name="privateGarden"
            text="Giardino"
            aria-label="Giardino"
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 5 }}>
          <Form.Checkbox
            name="balcony"
            text="Balcone"
            aria-label="Balcone"
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 5 }}>
          <Form.Checkbox
            name="terrace"
            text="Terrazzo"
            aria-label="Terrazzo"
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 5 }}>
          <Form.Checkbox
            name="features.ASCENSORE"
            text={featureTypeLabels[FeatureType.ASCENSORE]}
            aria-label={featureTypeLabels[FeatureType.ASCENSORE]}
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 5 }}>
          <Form.Checkbox
            name="garage"
            text="Box Auto"
            aria-label="Box Auto"
          />
        </Form.Item>
      </Form.Group>

      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Select
          name="mortgage"
          label="Necessita mutuo"
          aria-label="Necessita mutuo"
          defaultValue={OptionalBoolean.UNKNOWN}
          options={[
            { value: OptionalBoolean.UNKNOWN, label: OptionalBoolean.UNKNOWN },
            { value: OptionalBoolean.YES, label: OptionalBoolean.YES },
            { value: OptionalBoolean.NO, label: OptionalBoolean.NO },
          ]}
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Select
          name="auction"
          label="Interessato ad aste"
          aria-label="Interessato ad aste"
          defaultValue={OptionalBoolean.UNKNOWN}
          options={[
            { value: OptionalBoolean.UNKNOWN, label: OptionalBoolean.UNKNOWN },
            { value: OptionalBoolean.YES, label: OptionalBoolean.YES },
            { value: OptionalBoolean.NO, label: OptionalBoolean.NO },
          ]}
        />
      </Form.Item>

      <Form.Item>
        <Form.TextArea
          name="notes"
          label="Note"
          aria-label="Note"
        />
      </Form.Item>

      <Form.Actions align={FormActionsAlign.RIGHT}>
        <Form.Submit label="Aggiungi ricerca" />
      </Form.Actions>
    </Form>
  );
};

export default SearchCriteriaForm;
