import React from 'react';
import {
  Action, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import EditAgentCoveredGeoForm, { EditAgentCoveredGeoFormModel } from '../../components/edit-agent-covered-geo-form/EditAgentCoveredGeoForm';
import { updateAgent } from '../../../providers/api/agent/agentProvider';
import { agentToEditCoveredGeoFormModel, editCoveredGeoFormModelToAgent } from './mappers';

export const UPDATE_AGENT_COVERED_GEO_ERROR_MESSAGE = 'Non è stato possibile aggiornare le province coperte';
export const UPDATE_AGENT_COVERED_GEO_SUCCESS_MESSAGE = 'Le province coperte sono state modificate con successo';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
  provinceAlreadyInserted: boolean,
}

export interface EditCoveredGeoActionProps {
  agent: Agent,
  onSuccess?: (updatedAgent: Agent) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const EditCoveredGeoAction: React.FC<EditCoveredGeoActionProps> = ({
  agent,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const editCoveredGeoModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const openEditModal = React.useCallback(() => editCoveredGeoModal.open(), [editCoveredGeoModal]);

  const provinceAlreadyInserted = React.useMemo(() => (agent.coveredGeo ?? []).length > 0, [agent.coveredGeo]);

  const onSubmit = React.useCallback(async (values: EditAgentCoveredGeoFormModel) => {
    setIsSaving(true);

    try {
      const agentToUpdate = editCoveredGeoFormModelToAgent(values, agent);
      const updatedAgent = await updateAgent(agent.id!, agentToUpdate);

      setIsSaving(false);
      addSuccess(UPDATE_AGENT_COVERED_GEO_SUCCESS_MESSAGE);

      editCoveredGeoModal.close();

      onSuccess?.(updatedAgent);
    } catch {
      setIsSaving(false);
      addError(UPDATE_AGENT_COVERED_GEO_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agent, editCoveredGeoModal, onSuccess]);

  return (
    <>
      {children
        ? children({ openEditModal, isSaving, provinceAlreadyInserted })
        : (
          <Action
            onClick={openEditModal}
            label={provinceAlreadyInserted ? 'Modifica' : 'Aggiungi'}
            size="S"
            loading={isSaving}
            aria-label="Pulsante per modificare le province coperte"
          />
        )}
      <Portal>
        <SimpleModal
          {...editCoveredGeoModal}
          title="Modifica province coperte"
        >
          <EditAgentCoveredGeoForm
            onSubmit={onSubmit}
            initialValues={agentToEditCoveredGeoFormModel(agent)}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditCoveredGeoAction;
