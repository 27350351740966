import {
  ThemedFunctor, TypographyName, getTypography, rem, theme,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const td: ThemedFunctor = ({
  $width = 'auto',
  $align = 'left',
  $pointerEvents = 'all',
  $withDivider,
}) => css`
  ${getTypography(theme, TypographyName.BODY, { lineHeight: '1' })};
  padding: ${theme.remSize[100]};
  vertical-align: middle;
  text-align: ${$align};
  pointer-events: ${$pointerEvents};
  line-height: 0 !important;
  width: ${$width};
  position: relative;

  ${$withDivider && `
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: calc(100% - ${rem(theme.size[100] * 2)});
      width: 1px;
      background-color: ${theme.color.border.neutral.default.low};
      left: 0;
      top: ${theme.remSize[100]};
      bottom: ${theme.remSize[100]};
    }
  `}

  &:first-child {
    padding-left: ${theme.remSize[150]};
  }

  &:last-child {
    padding-right: ${theme.remSize[150]};
  }
`;

export const tdContent: ThemedFunctor = ({
  $width = 'auto',
}) => css`
  display: inline-block;
  position: relative;
  width: ${$width};
`;
