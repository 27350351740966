export enum IntentStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  BOUGHT = 'BOUGHT',
  KO_DUPLICATE = 'KO_DUPLICATE',
  KO_INFO = 'KO_INFO',
  KO_OVERPRICED = 'KO_OVERPRICED',
  KO_MORTGAGE = 'KO_MORTGAGE',
  KO_PROPERTY = 'KO_PROPERTY',
  KO_PROPERTY_NOT_AVAILABLE = 'KO_PROPERTY_NOT_AVAILABLE',
  KO_BOUGHT_OTHER = 'KO_BOUGHT_OTHER',
  KO_AREA = 'KO_AREA',
  KO_REFUSED = 'KO_REFUSED',
  KO_NOT_INTERESTED = 'KO_NOT_INTERESTED',
  KO_OTHER_CONSTRAINTS = 'KO_OTHER_CONSTRAINTS',
}

export const INTENT_KO_STATUS: IntentStatus[] = Object.values(IntentStatus)
  .filter((value) => value !== IntentStatus.BOUGHT && value !== IntentStatus.IN_PROGRESS);
