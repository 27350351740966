import {
  getClient, API_APPOINTMENTS_PATH,
} from '../api';
import { Appointment } from '../dtos';

export async function createAppointment(appointment: Appointment): Promise<Appointment> {
  const client = await getClient();
  const { data } = await client.post<Appointment>(API_APPOINTMENTS_PATH, appointment);
  return data;
}

export async function updateAppointment(appointmentId: number, appointment: Appointment): Promise<Appointment> {
  const client = await getClient();
  const { data } = await client.put<Appointment>(`${API_APPOINTMENTS_PATH}/${appointmentId}`, appointment);
  return data;
}
