/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Action, Icon, HStack } from '@doveit/bricks';
import { Aggregator } from '../../../../domain/types';
import { aggregatorLabels } from '../../../../labels';
import { AggregatorIcon } from '../../../constants';
import * as styles from './PublishedOnList.style';
import { Property } from '../../../../providers/api/dtos';
import ManagePublishedOnsAction, { ManagePublishedOnsActionProps } from '../manage-published-ons-action/ManagePublishedOnsAction';

export type PublishedOnListProps = {
  property: Partial<Pick<Property, 'publishedOn'>>,
  showEditAction?: undefined,
  onEdit?: undefined,
} | {
  property: Property,
  showEditAction: boolean,
  onEdit?: ManagePublishedOnsActionProps['onSuccess'],
};

const PublishedOnList: React.FC<PublishedOnListProps> = ({
  property,
  showEditAction,
  onEdit,
}) => (
  <div aria-label="Lista degli aggregatori su cui è pubblicato l'immobile">
    <HStack>
      <HStack>
        {Object.values(Aggregator)
          .sort((a, b) => ((a < b) ? -1 : 1))
          .map((agg) => {
            const aggregator = property.publishedOn?.find((a) => a.aggregator === agg);

            return (
              <div
                key={agg}
                aria-label={`Aggregatore ${aggregatorLabels[agg]}`}
                title={`${aggregator ? 'Pubblicato su ' : ''}${aggregatorLabels[agg]}`}
                css={styles.aggregator}
                $enabled={!!aggregator}
              >
                <Icon
                  path={AggregatorIcon[agg]}
                  size={20}
                />
              </div>
            );
          })}
      </HStack>
      {showEditAction && (
        <ManagePublishedOnsAction
          property={property}
          onSuccess={onEdit}
        >
          {({ openPublishedOnsModal }) => (
            <Action
              aria-label="Gestisci le pubblicazioni dell'immobile"
              title="Gestisci le pubblicazioni dell'immobile"
              label="Modifica"
              onClick={openPublishedOnsModal}
              size="S"
            />
          )}
        </ManagePublishedOnsAction>
      )}
    </HStack>
  </div>
);

export default PublishedOnList;
