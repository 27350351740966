import { MembershipLegalRole, MembershipStatus } from '../domain/types';

export const membershipStatusLabels: Record<MembershipStatus, string> = {
  [MembershipStatus.UNDER_ACTIVATION]: 'In attivazione',
  [MembershipStatus.ACTIVE]: 'Attivo',
  [MembershipStatus.UNDER_REVOCATION]: 'In revoca',
  [MembershipStatus.REVOKED]: 'Revocato',
};

export const membershipLegalRoleLabels: Record<MembershipLegalRole, string> = {
  [MembershipLegalRole.COMPANY]: 'Società',
  [MembershipLegalRole.UNIPERSONAL]: 'Unipersonale',
};

export enum ExtraPackagePayment {
  AT_OFFER_ACCEPTANCE = 'AT_OFFER_ACCEPTANCE',
  AT_ASSIGNMENT_SIGNATURE = 'AT_ASSIGNMENT_SIGNATURE',
}

export const extraPackagePaymentLabels: Record<ExtraPackagePayment, string> = {
  [ExtraPackagePayment.AT_OFFER_ACCEPTANCE]: "All'accettazione",
  [ExtraPackagePayment.AT_ASSIGNMENT_SIGNATURE]: 'Alla firma',
};
