import React, { FunctionComponent, useMemo } from 'react';
import {
  Badge, BadgeProps, Spinner, SpinnerSize,
} from '@doveit/bricks';
import { OfferStatus } from '../../../domain/types/offerStatus';
import useOffersWithStatusExists from '../../hooks/use-offers-with-status-exists/useOffersWithStatusExists';

export interface ActiveOfferBadgeProps {
  propertyId: number,
  size?: BadgeProps['size'],
}

export type ActiveOfferStatus = 'existsAcceptedOffer' | 'existsInProgressOffer' | 'loading' | 'error' | 'missing';
export type ActiveOfferBadgeMetadata = {
  color?: BadgeProps['color'],
  label?: string,
  isLoading: boolean,
} | undefined;

export const activeOfferBadgeMetadata: Record<ActiveOfferStatus, ActiveOfferBadgeMetadata> = {
  loading: {
    color: undefined,
    label: undefined,
    isLoading: true,
  },
  existsAcceptedOffer: {
    color: 'success',
    label: 'Proposta Accettata',
    isLoading: false,
  },
  existsInProgressOffer: {
    color: 'warning',
    label: 'Proposta Presentata',
    isLoading: false,
  },
  error: {
    color: 'critical',
    label: 'Errore',
    isLoading: false,
  },
  missing: undefined,
};

const ActiveOfferBadge: FunctionComponent<ActiveOfferBadgeProps> = ({
  propertyId,
  size,
}) => {
  const existsAcceptedOffer = useOffersWithStatusExists({ propertyId, offerStatus: OfferStatus.ACCEPTED });
  const existsInProgressOffer = useOffersWithStatusExists({ propertyId, offerStatus: OfferStatus.IN_PROGRESS });

  const statusBadgeResult = useMemo(() => {
    if (existsAcceptedOffer.isLoading || existsInProgressOffer.isLoading) {
      return activeOfferBadgeMetadata.loading;
    }

    if (existsAcceptedOffer.exist && !existsAcceptedOffer.error) {
      return activeOfferBadgeMetadata.existsAcceptedOffer;
    }

    if (existsInProgressOffer.exist && !existsInProgressOffer.error) {
      return activeOfferBadgeMetadata.existsInProgressOffer;
    }

    if (existsAcceptedOffer.error || existsInProgressOffer.error) {
      return activeOfferBadgeMetadata.error;
    }

    return activeOfferBadgeMetadata.missing;
  }, [existsAcceptedOffer, existsInProgressOffer]);

  if (statusBadgeResult && statusBadgeResult.isLoading) {
    return (
      <Spinner
        size={SpinnerSize.SMALLER}
        color="neutrals.medium"
      />
    );
  }

  if (statusBadgeResult && !statusBadgeResult.isLoading) {
    return (
      <Badge
        label={statusBadgeResult.label!}
        color={statusBadgeResult.color!}
        size={size}
      />
    );
  }

  return null;
};

export default ActiveOfferBadge;
