/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Skeleton } from '@doveit/bricks';
import * as styles from './EventRegistryList.style';
import { columns } from './EventRegistryList';

export interface EventRegistryListSkeletonProps {
  rows?: number,
}

const EventRegistryListSkeleton: React.FC<EventRegistryListSkeletonProps> = ({
  rows = 5,
}) => (
  <table css={styles.table}>
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={`th-${column.header}`}>
            {column.header}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {[...Array(rows).keys()].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={`event-${index}`}>
          {columns.map((column) => (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <td key={`column-${column.header}`} data-testid={`column-${column.header}`}>
              <Skeleton />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default EventRegistryListSkeleton;
