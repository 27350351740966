import { AppointmentAvailability } from '../providers/api/dtos';

export const appointmentAvailabilityLabels: {
  [key in AppointmentAvailability]: string
} = {
  MORNING: 'Mattina (9-12)',
  LUNCH: 'Pranzo (12-14)',
  AFTERNOON: 'Pomeriggio (14-17)',
  EVENING: 'Sera (17-20)',
};
