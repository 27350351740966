import { Message, Spacing } from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FullTextSearchForm, { FullTextSearchFormModel } from '../../../components/full-text-search-form/FullTextSearchForm';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import useFilters, { Filters } from '../../../hooks/use-filters/useFilters';
import usePagination from '../../../hooks/use-pagination/usePagination';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import useUserIsOnlyAreaManager from '../../../hooks/use-user-is-only-area-manager/useUserIsOnlyAreaManager';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { SortOrder } from '../../../providers/pagination';
import ViewJobApplicationCard from '../../containers/view-job-application-card/ViewJobApplicationCard';
import useSearchJobApplications from '../../hooks/use-search-job-applications/useSearchJobApplications';

export const JOB_APPLICATIONS_SEARCH_ERROR_MESSAGE = 'Errore durante la ricerca delle candidature';

interface SearchJobApplicationPageFilter extends Filters {
  q?: string,
}

const SearchJobApplicationPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { filters: { q: searchQuery }, addFilters, removeFilter } = useFilters<SearchJobApplicationPageFilter>();
  const scrollRef = useRef<HTMLDivElement>(null);
  const jobApplicationsPagination = usePagination<HTMLDivElement>('page', scrollRef);

  const { user } = useRBAC();
  const isOnlyAreaManager = useUserIsOnlyAreaManager();
  const { data: areaManager } = useAgent(isOnlyAreaManager ? user.email : undefined);
  const { data: jobApplications, error: jobApplicationsError } = useSearchJobApplications(
    searchQuery,
    areaManager?.id || undefined,
    {
      size: 20,
      page: jobApplicationsPagination.page,
      sort: {
        createdAt: SortOrder.DESC,
      },
    },
  );

  const onSearchSubmit = useCallback(({ search }: FullTextSearchFormModel) => {
    const newFilter: SearchJobApplicationPageFilter = {
      q: search,
    };
    addFilters(newFilter);
    removeFilter(jobApplicationsPagination.paginationQueryParam);
  }, [addFilters, removeFilter, jobApplicationsPagination.paginationQueryParam]);

  const onViewActionClick = useCallback((jobApplicationId: string) => () => {
    navigate(`/job-applications/${jobApplicationId}`);
  }, [navigate]);

  const isSearching = !!searchQuery && !jobApplications && !jobApplicationsError;

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent innerRef={scrollRef}>
          <FullTextSearchForm
            onSubmit={onSearchSubmit}
            initialValues={{ search: searchQuery }}
            placeholder="Cerca per nome, telefono o email"
            loading={isSearching}
          />
          {!jobApplications && jobApplicationsError && (
            <Spacing margin={[200, 0, 0]}>
              <Message
                type="critical"
                message={JOB_APPLICATIONS_SEARCH_ERROR_MESSAGE}
              />
            </Spacing>
          )}

          {jobApplications && (
            <Spacing margin={[200, 0, 0]}>
              <PaginatedList
                {...jobApplications}
                query={searchQuery}
                goToPage={jobApplicationsPagination.goToPage}
              >
                {jobApplications.content.map((jobApplication) => (
                  <ViewJobApplicationCard
                    key={jobApplication.id}
                    jobApplication={jobApplication}
                    onView={onViewActionClick(jobApplication.id!)}
                    compact
                  />

                ))}
              </PaginatedList>
            </Spacing>
          )}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default SearchJobApplicationPage;
