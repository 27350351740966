import { LeadPropertyFeature } from '../../domain/types';
import { LeadFormModel } from '../../lead/models/LeadFormModel';
import { Agent } from '../../providers/api/dtos';
import { Lead } from '../../providers/api/dtos/lead';
import { AddressSuggestion } from '../../providers/geo/dtos';

export function toLeadFormModel(lead: Lead, addressSuggestion?: AddressSuggestion, originAgent?: Agent): LeadFormModel {
  return ({
    propertyAddress: addressSuggestion && {
      placeId: addressSuggestion.placeId,
      description: addressSuggestion.description,
    },
    propertyType: lead.propertyType,
    propertyStatus: lead.propertyStatus,
    propertyFloor: lead.propertyFloor?.toString() ?? '',
    propertySize: lead.propertySize.toString(),
    propertyFeatures: Object.entries(lead.propertyFeatures).reduce<LeadPropertyFeature[]>((acc, [feature, isPresent]) => {
      if (isPresent) {
        acc.push(feature as LeadPropertyFeature);
      }

      return acc;
    }, []),
    willingness: lead.willingness,
    notes: lead.notes ?? undefined,
    buildingFloors: lead.buildingFloors?.toString(),
    otherAgencies: lead.otherAgencies ?? undefined,
    residualMortgage: lead.residualMortgage ?? undefined,
    multiOwner: lead.multiOwner,
    giftedProperty: lead.giftedProperty,
    inheritedProperty: lead.inheritedProperty,
    ownedByCompany: lead.ownedByCompany,
    registryDiscrepancies: lead.registryDiscrepancies,
    subsidizedHousing: lead.subsidizedHousing,
    registryStatus: lead.registryStatus,
    borsinoSquareMeterEvaluation: lead.borsinoSquareMeterEvaluation
      ? {
        min: lead.borsinoSquareMeterEvaluation.min.toString(),
        max: lead.borsinoSquareMeterEvaluation.max.toString(),
      } : undefined,
    originAgent,
  });
}
