import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const aggregator: ThemedFunctor = ({
  theme,
  $enabled,
}) => css`
  line-height: 0;
  cursor: not-allowed;
  color: ${theme.palettes.neutrals.light};

  ${$enabled && `
    cursor: default;
    color: ${theme.palettes.neutrals.darkest};
  `}
`;
