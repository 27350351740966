import { Document } from '../dtos';
import { getClient, API_DOCUMENTS_PATH } from '../api';

export async function createDocument(document: Document): Promise<Document> {
  const client = await getClient();
  const { data } = await client.post<Document>(API_DOCUMENTS_PATH, document);
  return data;
}

export async function getDocument(id: number): Promise<Document> {
  const client = await getClient();
  const { data } = await client.get<Document>(`${API_DOCUMENTS_PATH}/${id}`);
  return data;
}

export async function updateDocument(documentId: number, document: Document): Promise<Document> {
  const client = await getClient();
  const { data } = await client.put<Document>(`${API_DOCUMENTS_PATH}/${documentId}`, document);
  return data;
}

export async function deleteDocument(documentId: number): Promise<void> {
  const client = await getClient();
  await client.delete(`${API_DOCUMENTS_PATH}/${documentId}`);
}
