import {
  FontWeight,
  SpacingName,
  ThemedFunctor, TypographyName, getSpacing, getTypography,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const conversationItem: ThemedFunctor = ({ theme }) => css`
  border-bottom: 1px solid ${theme.palettes.neutrals.light};
`;

export const chatPlaceholderContainer: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, [SpacingName.BIGGEST, SpacingName.SMALL])}
  display: flex;
  justify-content: center;
  color: ${theme.palettes.neutrals.light};
  text-align: center;
`;

export const chatPlaceholder: ThemedFunctor = ({ theme }) => css`
  width: 28rem;

  > *:last-child {
    ${getSpacing.marginTop(theme, SpacingName.SMALL)}
    color: ${theme.palettes.neutrals.medium};
  }
`;

export const side: ThemedFunctor = ({ theme }) => css`
  position: relative;
  background-color: ${theme.palettes.neutrals.lightest};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border: 1px solid ${theme.palettes.neutrals.light};
`;

export const search: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, SpacingName.SMALLER)}
  border-bottom: 1px solid ${theme.palettes.neutrals.light};
`;

export const searchLabel: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLER)}
  ${getTypography(theme, TypographyName.BODY_SMALL, { fontWeight: FontWeight.MEDIUM })}
`;

export const conversationsWrapper: ThemedFunctor = () => css`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const conversationsLoading: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, [SpacingName.SMALLER, SpacingName.SMALL])}
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: linear-gradient(to top, ${theme.palettes.neutrals.lightest} 0, transparent 100%);
  z-index: ${theme.zIndex.tooltip};
  text-align: center;
`;
