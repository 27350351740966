export function stepToRadiusMeters(radiusStep: number): number {
  let result = 0;

  if (radiusStep < 1 || radiusStep > 23) {
    throw new Error('"radiusStep" should be between 1 and 23 inclusive');
  }

  if (radiusStep <= 10) {
    result = radiusStep * 100;
  }
  if (radiusStep > 10) {
    result = (radiusStep - 9) * 1000;
  }
  if (radiusStep > 19) {
    result = (radiusStep - 18) * 10_000;
  }

  return result;
}

export function radiusMetersToStep(radius: number) {
  let result = 0;
  if (radius <= 1000) {
    result = radius / 100;
  }
  if (radius > 1000) {
    result = (radius / 1000) + 9;
  }
  if (radius > 10_000) {
    result = (radius / 10_000) + 18;
  }

  return result;
}
