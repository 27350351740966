/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, useMemo } from 'react';
import { Icon } from '@doveit/bricks';
import { MANDATORY_PROPERTY_DOCUMENT_TYPES } from '../../constants';
import { documentsStatusLabelStyles } from './constants';
import * as styles from './PropertyDocumentsStatusLabel.style';
import { DocumentsStatusLabelProps, DocumentsStatus } from './types';

const PropertyDocumentsStatusLabel: FunctionComponent<DocumentsStatusLabelProps> = ({
  status: { approvedMandatoryDocumentCounter, pendingMandatoryDocumentCounter },
}) => {
  const status: DocumentsStatus = useMemo(() => {
    if (approvedMandatoryDocumentCounter === MANDATORY_PROPERTY_DOCUMENT_TYPES.length) {
      return 'approved';
    }

    if (pendingMandatoryDocumentCounter + approvedMandatoryDocumentCounter === 0) {
      return 'todo';
    }

    return 'pending';
  }, [approvedMandatoryDocumentCounter, pendingMandatoryDocumentCounter]);

  const { icon, color } = documentsStatusLabelStyles[status];

  return (
    <div
      title="Stato dei documenti obbligatori"
      css={styles.base}
      $color={color}
    >
      <Icon path={icon} /> Documenti
    </div>
  );
};

export default PropertyDocumentsStatusLabel;
