import React from 'react';
import {
  Action,
  Badge,
  Card, CardSkeleton, DetailItemList, Dropdown, ExpandableContent, HStack, ICON_ALERT_CIRCLE_OUTLINE, ICON_CAMERA_OUTLINE, ICON_CHECK, Message,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { Property, Shooting } from '../../../providers/api/dtos';
import useLatestShootingByPropertyId from '../../hooks/use-latest-shooting-by-property-id/useLatestShootingByPropertyId';
import useProperty from '../../hooks/use-property/useProperty';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { ShootingStatus, ShootingType, Status } from '../../../domain/types';
import UpdateShootingStatus from '../../../shooting/containers/update-shooting-status/UpdateShootingStatus';
import { shootingTypeLabels } from '../../../labels';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { splitTextToNearestString } from '../../../utils/text/text';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import UpsertPropertyShootingAction from '../upsert-property-shooting-action/UpsertPropertyShootingAction';

export interface PropertyShootingWidgetProps extends React.AriaAttributes {
  propertyId: NonNullable<Property['id']>,
  onUpsert?: (shooting: Shooting) => void,
}

export const LOAD_PROPERTY_SHOOTING_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni dello shooting.';
export const NO_SHOOTING_MESSAGE = 'Nessuno shooting programmato';

const PropertyShootingWidget: React.FC<PropertyShootingWidgetProps> = ({
  propertyId,
  onUpsert,
  ...rest
}) => {
  const isMobile = useIsDevice('mobile');
  const { userIsAdmin, userIsContentEditor } = useRBAC();

  const {
    data: property,
    isLoading: isPropertyLoading,
    error: propertyError,
  } = useProperty(propertyId);

  const userIsPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(property?.agentEmail);

  const canManage = React.useMemo(() => {
    if (!property) return false;

    const userIsAdminOrContent = userIsAdmin || userIsContentEditor;

    return userIsAdminOrContent || (userIsPropertyAgentOrManager && [Status.ANTEPRIMA, Status.DRAFT].includes(property.status));
  }, [property, userIsAdmin, userIsContentEditor, userIsPropertyAgentOrManager]);

  const {
    data: shooting,
    isLoading: isShootingLoading,
    error: shootingError,
    mutate: mutateShooting,
  } = useLatestShootingByPropertyId(canManage ? property?.id : undefined);

  const totalPrice = React.useMemo(() => {
    if (!shooting?.price) return 0;

    return Object.values(shooting.price).reduce((acc, curr) => acc + curr, 0);
  }, [shooting]);

  const notes = React.useMemo(
    () => splitTextToNearestString(shooting?.notes, '.', 300),
    [shooting?.notes],
  );

  const canCreateANewShooting = React.useMemo(
    () => shooting && [
      ShootingStatus.CANCELLED,
      ShootingStatus.NOT_EXECUTED,
      ShootingStatus.COMPLETED,
    ].includes(shooting.status),
    [shooting],
  );

  const handleUpsert = React.useCallback((s: Shooting) => {
    mutateShooting();
    onUpsert?.(s);
  }, [mutateShooting, onUpsert]);

  if (isShootingLoading || isPropertyLoading) {
    return <CardSkeleton aria-label="Dati shooting in caricamento" />;
  }

  if (propertyError || (shootingError && shootingError?.response?.status !== 404)) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Shooting"
          color="critical"
        />
        <Card.Content>
          <Message message={LOAD_PROPERTY_SHOOTING_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  if (!canManage) {
    return null;
  }

  return (
    <Card
      aria-label="Sezione dati shooting"
      {...rest}
    >
      <Card.Header
        title="Shooting"
        icon={{ path: ICON_CAMERA_OUTLINE }}
        primaryActions={[
          (!isMobile && canManage && (
            <UpsertPropertyShootingAction
              aria-label={shooting ? 'Modifica shooting esistente' : 'Aggiungi shooting'}
              propertyId={propertyId}
              shooting={shooting}
              onSuccess={handleUpsert}
            >
              {({ isSaving, upsert }) => (
                <Action
                  label={shooting ? 'Modifica' : 'Aggiungi'}
                  onClick={upsert}
                  loading={isSaving}
                  size="S"
                />
              )}
            </UpsertPropertyShootingAction>
          )),
        ]}
        secondaryActions={[
          (isMobile && canManage && (
            <UpsertPropertyShootingAction
              aria-label={shooting ? 'Modifica shooting esistente' : 'Aggiungi shooting'}
              propertyId={propertyId}
              shooting={shooting}
              onSuccess={handleUpsert}
            >
              {({ isSaving, upsert }) => (
                <Dropdown.Option
                  label={shooting ? 'Modifica' : 'Aggiungi'}
                  onClick={upsert}
                  loading={isSaving}
                />
              )}
            </UpsertPropertyShootingAction>
          )),
          (canCreateANewShooting && (
            <UpsertPropertyShootingAction
              aria-label="Aggiungi nuovo shooting"
              propertyId={propertyId}
              onSuccess={handleUpsert}
            >
              {({ isSaving, upsert }) => (
                <Dropdown.Option
                  label="Aggiungi nuovo"
                  onClick={upsert}
                  loading={isSaving}
                />
              )}
            </UpsertPropertyShootingAction>
          )),
        ]}
      />
      <Card.Content aria-label="Dati shooting">
        {!shooting && <Message message={NO_SHOOTING_MESSAGE} />}

        {shooting && (
          <DetailItemList>
            <DetailItemList.Item>
              <UpdateShootingStatus
                shooting={shooting}
                onSuccess={handleUpsert}
                size="XS"
              />
            </DetailItemList.Item>
            <DetailItemList.Item label="Tipologia">
              {shootingTypeLabels[shooting.type]}
            </DetailItemList.Item>
            {shooting.type === ShootingType.DIRECT && (
              <DetailItemList.Item label="Fotografo">
                {shooting.photographerName}
              </DetailItemList.Item>
            )}
            <DetailItemList.Item label="Data shooting">
              <DateViewer
                stringDate={shooting.date}
                checkIfToday
                checkIfTomorrow
                withHour
              />
            </DetailItemList.Item>
            {shooting.photoDeliveryDate && (
              <DetailItemList.Item label="Consegna foto">
                <DateViewer
                  stringDate={shooting.photoDeliveryDate}
                  checkIfToday
                  checkIfTomorrow
                />
              </DetailItemList.Item>
            )}
            {shooting.matterportDeliveryDate && (
              <DetailItemList.Item label="Consegna Matterport">
                <DateViewer
                  stringDate={shooting.matterportDeliveryDate}
                  checkIfToday
                  checkIfTomorrow
                />
              </DetailItemList.Item>
            )}
            {(userIsAdmin || userIsContentEditor) && (
              <DetailItemList.Item label="Costo">
                {totalPrice > 0 && formatEuro(totalPrice)}
              </DetailItemList.Item>
            )}
            {notes.length && (
              <DetailItemList.Item label="Note" inline={false}>
                {notes.length === 1 && notes[0]}
                {notes.length === 2 && (
                  <>
                    {notes[0]}
                    <ExpandableContent openLabel="Mostra altro">
                      {notes[1]}
                    </ExpandableContent>
                  </>
                )}
              </DetailItemList.Item>
            )}
          </DetailItemList>
        )}
      </Card.Content>

      {(shooting?.matterportRequest || shooting?.postProcessingRequest) && (
        <>
          <Card.Divider />

          <Card.Content>
            <HStack>
              {shooting.matterportRequest && (
                <Badge
                  icon={ICON_CHECK}
                  label="Matterport"
                  size="XS"
                />
              )}
              {shooting.postProcessingRequest && (
                <Badge
                  icon={ICON_CHECK}
                  label="Post produzione"
                  size="XS"
                />
              )}
            </HStack>
          </Card.Content>
        </>
      )}
    </Card>
  );
};

export default PropertyShootingWidget;
