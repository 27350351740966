import * as Yup from 'yup';
import {
  addYears, isAfter, isBefore, isToday, addDays,
} from 'date-fns';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  CAP_REGEX, COUNTRY, EMAIL_REGEX, FISCAL_CODE_REGEX,
} from '../../../constants';
import { ExtraPackage, OwnershipType } from '../../../domain/types';
import {
  AssignmentPropertyStatus, AssignmentSubscriptionPlace, IdentityDocumentType, PropertySource, WizardContact,
} from '../../../providers/api/dtos';
import { ExtraPackageProvider } from '../../../domain/types/extraPackageType';

const identityDocumentValidation = Yup.object().shape({
  type: Yup
    .string()
    .required('Il campo "Tipo di documento" è obbligatorio')
    .oneOf([IdentityDocumentType.DRIVER_LICENSE, IdentityDocumentType.IDENTITY_CARD, IdentityDocumentType.PASSPORT]),
  number: Yup
    .string()
    .trim()
    .required('Il campo "Numero" è obbligatorio'),
  issuedBy: Yup
    .string()
    .trim()
    .required('Il campo "Rilasciato da" è obbligatorio'),
  issuedAt: Yup.date()
    .required('Il campo "Data rilascio" è obbligatorio')
    .test('isWithin11Years',
      'La data di rilascio del documento non deve essere più vecchia di 11 anni',
      (value) => value
        && (isToday(value) || isBefore(value, new Date()))
        && isBefore(new Date(), addDays(addYears(value, 11), 1))),

});

export const registeredOfficeValidation = Yup.object({
  route: Yup
    .string()
    .required('Il campo "Strada" è obbligatorio per la Sede Legale'),
  locality: Yup
    .string()
    .required('Il campo "Comune" è obbligatorio per la Sede Legale'),
  postalCode: Yup
    .string()
    .required('Il campo "CAP" è obbligatorio per la Sede Legale')
    .matches(CAP_REGEX, 'Inserisci un CAP valido per la Sede Legale'),
});

const contactPlaceOfResidenceValidation = Yup.object({
  route: Yup
    .string()
    .required('Il campo "Strada" è obbligatorio per l\'indirizzo di residenza del contatto'),
  locality: Yup
    .string()
    .required('Il campo "Comune" è obbligatorio per l\'indirizzo di residenza del contatto'),
  plateCode: Yup
    .string()
    .when(['latitude', 'longitude'], {
      is: (latitude?: number, longitude?: number) => !!latitude && !!longitude,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required('Il campo "Provincia" è obbligatorio per l\'indirizzo di residenza del contatto'),
    }),
});

const geoValidation = Yup.object({
  route: Yup
    .string()
    .required('Il campo "Strada" è obbligatorio'),
  locality: Yup
    .string()
    .required('Il campo "Comune" è obbligatorio'),
  administrativeAreaLevelTwo: Yup
    .string()
    .required('Il campo "Provincia" è obbligatorio'),
  postalCode: Yup
    .string()
    .required('Il campo "CAP" è obbligatorio')
    .matches(CAP_REGEX, 'Inserisci un CAP valido'),
});

export const contactValidationSchema = (withIdentityDocument: boolean, withCitizenship: boolean) => Yup.object({
  name: Yup
    .string()
    .trim()
    .required('Il campo "Nome e cognome" è obbligatorio')
    .test(
      'containsSpaces',
      'Inserisci il nome completo',
      (value) => value?.includes(' '),
    ),
  email: Yup
    .string()
    .required('Il campo "Email" è obbligatorio')
    .matches(EMAIL_REGEX, 'Inserisci una email valida'),
  phoneNumber: Yup
    .string()
    .required('Il campo "Telefono" è obbligatorio')
    .test(
      'isValid',
      'Inserisci un numero di telefono valido',
      (value: string | undefined) => !value || isValidPhoneNumber(value, COUNTRY),
    ),
  fiscalCode: Yup
    .string()
    .required('Il campo "Codice fiscale" è obbligatorio')
    .matches(FISCAL_CODE_REGEX, 'Inserisci un codice fiscale valido'),
  birthday: Yup
    .date()
    .required('Il campo "Data di nascita" è obbligatorio')
    .test('isAtLeast18YearsInThePast',
      'Il contatto deve essere maggiorenne',
      (value) => value && isBefore(addYears(value, 18), new Date())),
  birthplace: Yup
    .string()
    .required('Il campo "Luogo di nascita" è obbligatorio'),
  placeOfResidence: contactPlaceOfResidenceValidation,
  identityDocument: withIdentityDocument ? identityDocumentValidation : Yup.object(),
  citizenship: Yup
    .string()
    .when('withCitizenship', {
      is: () => withCitizenship,
      then: (schema) => schema.required('Il campo "Cittadinanza" è obbligatorio'),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export const contactValidation = contactValidationSchema(false, false);
export const contactValidationWithAdditionalFields = contactValidationSchema(true, true);

export const AssignmentWizardDetailsValidationSchema = Yup.object({
  assignment: Yup.object({
    autoRenew: Yup
      .bool()
      .required('Il campo "Rinnovo automatico" è obbligatorio'),
    exclusive: Yup
      .bool()
      .required('Il campo "In esclusiva" è obbligatorio'),
    keysHanded: Yup
      .bool()
      .required('Il campo "Chiavi consegnate" è obbligatorio'),
    extraPackages: Yup.array(),
    endDate: Yup
      .date()
      .required('Il campo "Data del mandato" è obbligatorio')
      .test('isDateInTheFuture',
        'La data del mandato deve essere nel futuro',
        (value) => value && isAfter(value, new Date())),
  }),
  property: Yup.object({
    isMortgageInProgress: Yup
      .bool()
      .required('Il campo "Mutuo in corso" è obbligatorio'),
    residualMortgage: Yup
      .number()
      .positive()
      .when('isMortgageInProgress', {
        is: (isMortgageInProgress: boolean) => isMortgageInProgress,
        then: (schema) => schema.required('Il campo "Mutuo residuo" è obbligatorio'),
        otherwise: (schema) => schema.notRequired(),
      }),
    hasLien: Yup
      .bool()
      .required('Il campo "Ipoteca" è obbligatorio'),
    lienValue: Yup
      .number()
      .positive()
      .when('hasLien', {
        is: (hasLien: boolean) => hasLien,
        then: (schema) => schema.required('Il campo Valore ipoteca è obbligatorio'),
        otherwise: (schema) => schema.notRequired(),
      }),
    price: Yup
      .number()
      .positive()
      .required('Il campo "Prezzo di pubblicazione" è obbligatorio'),
    minimumPrice: Yup
      .number()
      .positive()
      .required('Il campo "Prezzo di mandato" è obbligatorio')
      .test('ltePrice', (value, context) => {
        if (value !== undefined && Number(context.parent.price) < Number(value)) {
          return context.createError({
            path: 'minimumPrice',
            message: 'Il prezzo di mandato deve essere minore o uguale al prezzo di pubblicazione',
          });
        }
        return true;
      }),
    evaluation: Yup
      .number()
      .positive()
      .required('Il campo "Valutazione" è obbligatorio'),
  }),
});

const hasAtLeastOnePackageOfProvider = (
  extraPackages: ExtraPackage[],
  provider: ExtraPackageProvider,
): boolean => extraPackages?.some(({ providedBy }) => providedBy === provider);

export const AssignmentWizardPackagesValidationSchema = Yup.object({
  assignment: Yup.object({
    extraPackages: Yup.array().test('extraPackages', 'È necessario selezionare almeno un\'opzione per ogni tipologia di pacchetto', (value, ctx) => {
      const { extraPackagesDoveItEnabled, extraPackagesSPFEnabled } = ctx.parent;
      const extraPackages = value as ExtraPackage[];
      const haveExtraPackagesDoveItErrors = extraPackagesDoveItEnabled && !hasAtLeastOnePackageOfProvider(extraPackages, ExtraPackageProvider.DOVE_IT);
      const haveExtraPackagesSPFErrors = extraPackagesSPFEnabled && !hasAtLeastOnePackageOfProvider(extraPackages, ExtraPackageProvider.SPF);

      if (haveExtraPackagesDoveItErrors) {
        ctx.createError({
          path: 'extraPackages',
          message: 'È necessario selezionare almeno un\'opzione per i pacchetti Dove.it',
        });
      }

      if (haveExtraPackagesSPFErrors) {
        ctx.createError({
          path: 'extraPackages',
          message: 'È necessario selezionare almeno un\'opzione per i pacchetti SPF',
        });
      }

      return !(haveExtraPackagesDoveItErrors || haveExtraPackagesSPFErrors);
    }),
  }),
});

export const AssignmentWizardSellerValidationSchema = Yup.object({
  isLegalPersonOnly: Yup
    .bool()
    .required('Il campo "Tipologia" è obbligatorio'),
  contacts: Yup
    .array()
    .when('isLegalPersonOnly', {
      is: (isLegalPersonOnly: boolean) => isLegalPersonOnly,
      then: (schema) => {
        const legalContactValidation = Yup.object({
          companyName: Yup
            .string()
            .required('Il campo "Ragione sociale" è obbligatorio'),
          registeredOffice: registeredOfficeValidation,
          fiscalCode: Yup
            .string()
            .required('Il campo "Partita IVA" è obbligatorio'),
        });

        return schema.of(contactValidation.shape({
          legal: legalContactValidation,
        }));
      },
      otherwise: (schema) => schema.of(contactValidation),
    }).when('isLegalPersonOnly', {
      is: (isLegalPersonOnly: boolean) => isLegalPersonOnly,
      then: (schema) => schema.min(1, 'È necessario inserire il rappresentante legale'),
      otherwise: (schema) => schema.min(1, 'È necessario inserire almeno un venditore'),
    })
    .test(
      'noDuplicates',
      'Sono presenti più contatti con la stessa email e numero di telefono',
      (contacts: WizardContact[] = []) => {
        const occs = new Map<string, number>();

        contacts.forEach((contact) => {
          const key = `${contact.email?.trim()?.toLowerCase()}-${contact.phoneNumber?.trim()}`;
          const count = occs.get(key) || 0;
          occs.set(key, count + 1);
        });

        const hasDuplicates = Array.from(occs.entries()).some(([_, count]) => count > 1);

        return !hasDuplicates;
      },
    ),
});

export const AssignmentWizardPropertyValidationSchema = Yup.object({
  property: Yup.object({
    geo: geoValidation,
    cadastralRegistry: Yup.object({
      units: Yup
        .array()
        .min(1, "È necessario inserire almeno un'unità immobiliare")
        .required('Il campo "Unità immobiliari" è obbligatorio"'),
    }),
  }),
});

export const AssignmentWizardPropertyTypeValidationSchema = Yup.object({
  property: Yup.object({
    ownership: Yup.object({
      type: Yup
        .string()
        .required('Il campo "Tipologia" è obbligatorio')
        .oneOf([OwnershipType.NUDA_PROPRIETA, OwnershipType.INTERA_PROPRIETA, 'ALTRO']),
      details: Yup
        .string()
        .when('type', {
          is: (type: string) => type === 'ALTRO',
          then: (schema) => schema.required('È necessario specificare la tipologia non prevista'),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
  }),
});

export const AssignmentWizardPropertyInfoValidationSchema = Yup.object({
  assignment: Yup.object({
    subscriptionPlace: Yup
      .string()
      .required('Il campo "Luogo di stipula" è obbligatorio')
      .oneOf(Object.values(AssignmentSubscriptionPlace)),
  }),
  property: Yup.object({
    source: Yup.object({
      type: Yup
        .string()
        .required('Il campo "Provenienza" è obbligatorio')
        .oneOf(Object.values(PropertySource)),
      details: Yup
        .string()
        .when('type', {
          is: (type: string) => type === PropertySource.OTHER,
          then: (schema) => schema.required('È necessario specificare la provenienza'),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
    status: Yup.object({
      type: Yup
        .string()
        .required('Il campo "Stato dell\'immobile" è obbligatorio')
        .oneOf(Object.values(AssignmentPropertyStatus)),
    }),
    rent: Yup.object().when('status.type', {
      is: (status: string) => status === AssignmentPropertyStatus.RENTAL,
      then: (schema) => schema.shape({
        annualFee: Yup
          .number()
          .positive()
          .required('Il campo "Canone annuo" è obbligatorio'),
        expiresAt: Yup
          .date()
          .required('Il campo "Scadenza" è obbligatorio')
          .test('isDateInTheFuture',
            'La data di scadenza del contratto di locazione deve essere nel futuro',
            (value) => value && (isToday(value) || isAfter(value, new Date()))),
      }),
      otherwise: (schema) => schema.notRequired(),
    }),
    compliance: Yup.object({
      isUrbanCompliant: Yup
        .bool()
        .required('Il campo "È conforme" è obbligatorio'),
      areSystemsCompliant: Yup
        .bool()
        .required('Il campo "Ha impianti conformi" è obbligatorio'),
      hasOccupancyCertificate: Yup
        .bool()
        .required('Il campo "Ha il certificato di agibilità" è obbligatorio'),
      technicianNeeded: Yup
        .bool()
        .required('Il campo "Conferisce incarico a tecnico per verifica conformità urbanistica" è obbligatorio'),
      technicianByDoveIt: Yup
        .bool()
        .when('technicianNeeded', {
          is: (technicianNeeded: boolean) => technicianNeeded,
          then: (schema) => schema.required('È necessario specificare il valore del campo "Nomina tecnico"'),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
    constraints: Yup.object({
      legalIssues: Yup
        .bool()
        .required('Il campo "Controversie legali" è obbligatorio'),
      isConstraintsFree: Yup
        .bool()
        .required('Il campo "È privo di pregiudizi e vincoli" è obbligatorio'),
      details: Yup
        .string()
        .when('isConstraintsFree', {
          is: (isConstraintsFree: boolean) => !isConstraintsFree,
          then: (schema) => schema.required('È necessario specificare i Pregiudizi, servitù o vincoli'),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
    additionalExpenses: Yup.object({
      extraDeliberated: Yup
        .bool()
        .required('Il campo "Previste spese straordinarie" è obbligatorio'),
      value: Yup
        .number()
        .nullable()
        .min(0, 'Le spese condominiali devono essere maggiori o uguali a 0€'),
    }),
  }),
});

export const AssignmentWizardWithdrawalValidationSchema = Yup.object({
  assignment: Yup.object({
    rightOfWithdrawal: Yup
      .bool()
      .required('Il campo "Diritto di recesso" è obbligatorio'),
    startDate: Yup
      .date()
      .when('rightOfWithdrawal', {
        is: (rightOfWithdrawal: boolean) => !rightOfWithdrawal,
        then: (schema) => schema
          .test('isDateInTheFuture',
            'La data di decorrenza del mandato deve essere nel futuro',
            (value) => value && (isToday(value) || isAfter(value, new Date())))
          .required('Il campo "Data di decorrenza" è obbligatorio'),
        otherwise: (schema) => schema.notRequired(),
      }),
    cancellationFee: Yup
      .number()
      .positive()
      .required('Il campo "Penale" è obbligatorio'),
    notes: Yup.string(),
  }),
});

export const AssignmentWizardPlanimetryDelegationValidationSchema = Yup.object({
  planimetryDelegationRequired: Yup
    .bool()
    .required('Il campo "Aggiungi delega" è obbligatorio'),
  contacts: Yup
    .array()
    .when('planimetryDelegationRequired', {
      is: (planimetryDelegationRequired: boolean) => planimetryDelegationRequired,
      then: (schema) => schema
        .test(
          'hasExactlyOneDelegator',
          'È necessario selezionare un delegante',
          (value: WizardContact[] | undefined = []) => value.filter((contact) => !!contact.planimetryDelegating).length === 1,
        )
        .of(
          Yup.object().shape({
            planimetryDelegating: Yup.boolean().required('Il campo "Delegante" è obbligatorio'),
            identityDocument: Yup.object()
              .when('planimetryDelegating', {
                is: (planimetryDelegating: boolean) => planimetryDelegating,
                then: () => identityDocumentValidation,
                otherwise: (internalSchema) => internalSchema.notRequired(),
              }),
          }),
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
});
