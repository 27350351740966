import {
  FormSkeleton,
  Message,
  Spacing,
  useNotifications,
} from '@doveit/bricks';
import React, {
  FunctionComponent, useCallback, useState,
} from 'react';
import { ReferenceType } from '../../../domain/types';
import { createAppointment } from '../../../providers/api/appointment/appointmentProvider';
import { Appointment, Intent } from '../../../providers/api/dtos';
import { formDateTimeToDate } from '../../../utils/form';
import useContact from '../../../contact/hooks/use-contact/useContact';
import { AppointmentFormModel } from '../../../appointment/models/appointmentFormModel';
import AppointmentForm from '../../../appointment/components/appointment-form/AppointmentForm';
import { getAppointmentEndDate } from '../../../appointment/utils/getAppointmentEndDate';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import usePropertyPreview from '../../../property/hooks/use-property-preview/usePropertyPreview';

export interface CreateIntentAppointmentProps {
  intent: Intent,
  onAbort?: VoidFunction,
  onSuccess?: (appointment: Appointment) => void,
}

export const SAVE_APPOINTMENT_SUCCESS_MESSAGE = 'Appuntamento salvato con successo. <a href="__LINK__" target="_blank">Visualizza</a>';
export const SAVE_APPOINTMENT_ERROR_MESSAGE = 'Non è stato possibile salvare l\'appuntamento';
export const LOAD_PREFILLED_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare i dati necessari per creare l\'appuntamento';

const CreateIntentAppointment: FunctionComponent<CreateIntentAppointmentProps> = ({
  intent,
  onSuccess,
  onAbort,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();
  const { data: property } = usePropertyPreview(intent.propertyId);
  const { data: contact } = useContact(intent.contactId);
  const { data: agent, isLoading: isAgentLoading, error: agentError } = useAgentByPropertyId(intent?.propertyId);

  const onSubmit = useCallback(async (values: AppointmentFormModel) => {
    setIsSaving(true);

    try {
      const startDate = formDateTimeToDate(values.date!, values.time!);
      const payload: Appointment = {
        contactId: intent.contactId,
        status: values.status!,
        notes: values.notes?.trim() || undefined,
        agentId: agent?.id!,
        referenceId: intent.id!,
        referenceType: ReferenceType.INTENT,
        startDate: startDate.toISOString(),
        endDate: getAppointmentEndDate(ReferenceType.INTENT, startDate).toISOString(),
      };

      const result = await createAppointment(payload);
      setIsSaving(false);
      addSuccess(SAVE_APPOINTMENT_SUCCESS_MESSAGE.replace('__LINK__', `/intents/${intent.id}`));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      setIsSaving(false);
      addError(SAVE_APPOINTMENT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agent?.id, intent.contactId, intent.id, onSuccess]);

  if (agentError) {
    return (
      <Message
        type="critical"
        message={LOAD_PREFILLED_DATA_ERROR_MESSAGE}
      />
    );
  }

  if (isAgentLoading) {
    return <FormSkeleton aria-label="form-skeleton" />;
  }

  return (
    <div>
      {property && contact && (
        <Spacing margin={[0, 0, 200]}>
          <Message
            type="info"
            aria-label="Messaggio informativo creazione appuntamento interesse"
            message={`Stai inserendo un appuntamento del contatto <strong>${contact.name || contact.phoneNumber || contact.email}</strong> per l'immobile <strong>${property.referenceId}</strong>.${onAbort ? ' Puoi saltare questo passaggio e fissare la data dell\'appuntamento in un secondo momento.' : ''}`}
          />
        </Spacing>
      )}

      <AppointmentForm
        submitLabel="Crea"
        onSubmit={onSubmit}
        loading={isSaving}
        extraActions={onAbort ? [
          {
            id: 'abort-appointment-creation',
            label: 'Salta',
            onClick: onAbort,
          },
        ] : undefined}
      />
    </div>
  );
};

export default CreateIntentAppointment;
