export enum FeatureType {
  VIDEOCITOFONO = 'VIDEOCITOFONO',
  PORTA_BLINDATA = 'PORTA_BLINDATA',
  ALLARME = 'ALLARME',
  ARMADI_A_MURO = 'ARMADI_A_MURO',
  GIARDINO_CONDOMINIALE = 'GIARDINO_CONDOMINIALE',
  FIBRA_OTTICA = 'FIBRA_OTTICA',
  CANCELLO_ELETTRICO = 'CANCELLO_ELETTRICO',
  ASCENSORE = 'ASCENSORE',
  PORTIERE = 'PORTIERE',
  PANNELLI_SOLARI = 'PANNELLI_SOLARI',
  CAMINO = 'CAMINO',
  DOMOTICA = 'DOMOTICA',
  IDROMASSAGGIO = 'IDROMASSAGGIO',
  EDIFICIO_STORICO = 'EDIFICIO_STORICO',
  PISCINA = 'PISCINA',
  PALESTRA = 'PALESTRA',
  CAMPO_DA_TENNIS = 'CAMPO_DA_TENNIS',
  VISTA_MARE = 'VISTA_MARE',
  VISTA_MONTAGNA = 'VISTA_MONTAGNA',
  VICINANZA_IMPIANTI_SCIISTICI = 'VICINANZA_IMPIANTI_SCIISTICI',
  SCUDERIE = 'SCUDERIE',
  FATTORIA = 'FATTORIA',
  VIGNETO = 'VIGNETO',
  CANTINA_VINI = 'CANTINA_VINI',
  MOLO = 'MOLO',
  ELIPORTO = 'ELIPORTO',
}
