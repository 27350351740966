/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon, Link, Tooltip } from '@doveit/bricks';
import React from 'react';
import {
  DocumentStatus, DocumentType,
} from '../../../domain/types';
import { documentStatusLabels, documentTypeLabels } from '../../../labels';
import {
  documentStatusIcons, DEFAULT_MANDATORY_DOCUMENT_STATUS_ICON, DEFAULT_OPTIONAL_DOCUMENT_STATUS_ICON, DEFAULT_OPTIONAL_DOCUMENT_STATUS_ICON_EXISTING_FILES,
} from './constants';
import * as styles from './DocumentLabel.style';
import { DocumentStatusIcon } from './types';
import { DocumentReferenceType } from '../../../domain/types/documentReferenceType';
import { isMandatory } from '../../utils/documentUtils';

export interface DocumentLabelProps {
  documentStatus?: DocumentStatus,
  referenceType: DocumentReferenceType,
  documentType: DocumentType,
  referenceContext?: DocumentReferenceType,
  numFiles: number,
  disabled?: boolean,
  onClick?: VoidFunction,
}

export const MISSING_DOCUMENT_LABEL = 'Da caricare';

const DocumentLabel: React.FC<DocumentLabelProps> = ({
  documentStatus,
  referenceType,
  documentType,
  referenceContext,
  numFiles,
  disabled = false,
  onClick,
}) => {
  const documentStatusIcon: DocumentStatusIcon = React.useMemo(() => {
    const isDocumentMandatory = isMandatory(referenceContext ?? referenceType, documentType);

    if (documentStatus) {
      if (numFiles && documentStatus === DocumentStatus.DRAFT) {
        return DEFAULT_OPTIONAL_DOCUMENT_STATUS_ICON_EXISTING_FILES;
      }

      return documentStatusIcons[documentStatus][isDocumentMandatory ? 'mandatory' : 'optional'];
    }

    return isDocumentMandatory ? DEFAULT_MANDATORY_DOCUMENT_STATUS_ICON : DEFAULT_OPTIONAL_DOCUMENT_STATUS_ICON;
  }, [documentStatus, documentType, numFiles, referenceContext, referenceType]);

  return (
    <Link
      css={styles.base}
      onClick={onClick}
      disabled={disabled}
    >
      <Tooltip content={`Documento ${(documentStatus
        ? `${documentStatusLabels[documentStatus]} (${numFiles})`
        : MISSING_DOCUMENT_LABEL).toLowerCase()}`}
      >
        <div
          data-ref="document-status-icon"
          aria-label="Descrizione del documento"
          css={styles.icon}
          $color={documentStatusIcon.color}
        >
          <Icon
            path={documentStatusIcon.icon}
            size={20}
          />
        </div>
      </Tooltip>
      <div css={styles.label}>
        {documentTypeLabels[documentType]}
      </div>
    </Link>
  );
};

export default DocumentLabel;
