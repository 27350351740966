import React from 'react';
import {
  Badge,
  Typography,
  Tooltip,
  ICON_EMAIL_OUTLINE,
  ICON_PHONE_OUTLINE,
  Message,
  ActionIcon,
  ICON_EYE_OUTLINE,
  ICON_ACCOUNT_OUTLINE,
  HStack,
  DetailItemList,
} from '@doveit/bricks';
import { Link, useNavigate } from 'react-router-dom';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import Card from '../../../components/card/Card';
import { InlineCardSkeleton } from '../../../components/card/Card.skeleton';
import { Agent } from '../../../providers/api/dtos';
import { DescendantsStrategy } from '../../../domain/types';
import { getAgentName } from '../../utils/utils';

export interface ViewAgentCardProps {
  agent: Agent,
}

export const LOADING_ERROR = 'Non è stato possibile caricare i dati dell\'agente';

const ViewAgentCard: React.FC<ViewAgentCardProps> = ({
  agent,
}) => {
  const navigate = useNavigate();
  const { data: parent, error, isValidating } = useAgent(agent.idParent, { descendantsStrategy: DescendantsStrategy.ALL });

  const goToAgentPage = React.useCallback(
    () => navigate(`/admin/agents/${agent.id}`),
    [agent.id, navigate],
  );

  const showLicenseBadge: boolean = React.useMemo(() => agent.hasLicense, [agent.hasLicense]);

  const showNeedsSupervisorApprovalBadge: boolean = React.useMemo(
    () => showLicenseBadge && !!agent.active && agent.needsSupervisor,
    [agent.active, agent.needsSupervisor, showLicenseBadge],
  );

  if (error) {
    return (
      <Message
        type="critical"
        message={LOADING_ERROR}
      />
    );
  }

  if (isValidating && !parent) {
    return <InlineCardSkeleton />;
  }

  return (
    <Card>
      <Card.Inline>
        <Card.Box>
          <DetailItemList>
            <DetailItemList.Item>
              <HStack>
                <Badge
                  label={(agent.descendants?.length ?? 0) > 0 ? 'Manager' : 'Agente'}
                  color="info"
                  size="XS"
                />
                {showLicenseBadge && (
                  <Badge
                    color={agent.active ? 'success' : 'info'}
                    label="Patentino"
                    size="XS"
                  />
                )}
                {showNeedsSupervisorApprovalBadge && (
                  <Badge
                    color="critical"
                    label="Approvazione"
                    size="XS"
                  />
                )}
              </HStack>
            </DetailItemList.Item>
            {!agent.active && (
              <DetailItemList.Item>
                <Badge
                  label="Non attivo"
                  color="neutral"
                  size="XS"
                />
              </DetailItemList.Item>
            )}
            <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
              {getAgentName(agent)}
            </DetailItemList.Item>
          </DetailItemList>
        </Card.Box>
        <Card.Box>
          <DetailItemList>
            <DetailItemList.Item
              key="email"
              icon={ICON_EMAIL_OUTLINE}
            >
              <Tooltip
                data-ref="agent-email-tooltip"
                content={(
                  <div data-ref="agent-email-info">
                    <Typography.BODY_SMALL>
                      {agent.email.work}
                    </Typography.BODY_SMALL>
                  </div>
                )}
                addCSS={{ maxWidth: '30rem' }}
              >
                <div>
                  {agent.email.work}
                </div>
              </Tooltip>
            </DetailItemList.Item>
            {agent.mobile && (
              <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
                {agent.mobile.work}
              </DetailItemList.Item>
            )}
          </DetailItemList>
        </Card.Box>
        <Card.Box>
          {parent ? (
            <>
              <span>Manager</span>
              <Typography.BODY>
                <Link to={`/admin/agents/${parent.id}`}>
                  <strong>{parent.name?.name} {parent.name?.surname}</strong>
                </Link>
              </Typography.BODY>
            </>
          ) : '—'}
        </Card.Box>
        <Card.ColumnDivider />
        <Card.Box fit>
          <ActionIcon
            label="Visualizza"
            aria-label={`Vai alla pagina di ${getAgentName(agent)}`}
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToAgentPage}
          />
        </Card.Box>
      </Card.Inline>
    </Card>
  );
};

export default ViewAgentCard;
