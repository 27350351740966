import {
  ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE, ICON_PROGRESS_CHECK,
} from '@doveit/bricks';
import { BadgeColor } from '@doveit/bricks/dist/typings/components/badge/types';
import { SignatureStatus } from '../../../providers/api/dtos';

export const computedDigitalAssignmentSignatureStatusActionIcon: Record<SignatureStatus, string> = {
  ONGOING: ICON_PROGRESS_CHECK,
  EXPIRED: ICON_ALERT_CIRCLE_OUTLINE,
  CANCELED: ICON_ALERT_CIRCLE_OUTLINE,
  SIGNED: ICON_CHECK_CIRCLE_OUTLINE,
};

export const computedDigitalAssignmentSignatureStatusActionColor: Record<SignatureStatus, BadgeColor> = {
  ONGOING: 'info',
  EXPIRED: 'critical',
  CANCELED: 'warning',
  SIGNED: 'success',
};
