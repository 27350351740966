import { Avatar, AvatarProps, ICON_BULLHORN_OUTLINE } from '@doveit/bricks';
import React from 'react';

export type RumorAvatarProps = Pick<AvatarProps, 'size' | 'placeholder'>;

export const RumorAvatar: React.FC<RumorAvatarProps> = ({ size }) => (
  <Avatar
    placeholder={ICON_BULLHORN_OUTLINE}
    size={size}
  />
);

export default RumorAvatar;
