import useSWR from 'swr';
import hash from 'object-hash';
import { getContactProperties } from '../../../providers/api/contact/contactProvider';
import { PropertyContact } from '../../../providers/api/dtos';
import { SortParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

const CONTACT_PROPERTIES_RID = '/contacts/:id/properties';

export default function useContactProperties(
  contactId?: string,
  sort?: SortParams,
): SWRCustomResponse<PropertyContact[], Error> {
  const matchMutate = useMatchMutate();
  const rid = [CONTACT_PROPERTIES_RID, contactId].join();
  const key = contactId ? [rid, hash(sort || {})] : null;
  const result = useSWR(key, () => getContactProperties(contactId!, sort));

  return {
    ...result,
    mutate: () => matchMutate(rid),
  };
}
