export enum ShowcaseVariant {
  STANDARD = 'STANDARD',
  LUXURY = 'LUXURY',
}

export enum ShowcaseOrientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export enum ShowcaseFormat {
  A4 = 'A4',
  A3 = 'A3',
  _16_9 = '_16_9',
}
