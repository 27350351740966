import {
  ThemedFunctor, getTypography, TypographyName, getSpacing, SpacingName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.padding(theme, [SpacingName.BIGGER, SpacingName.SMALL])}
  text-align: center;
`;

export const icon: ThemedFunctor = ({
  theme,
}) => css`
  color: ${theme.palettes.status.alert};
  ${getSpacing.marginBottom(theme, SpacingName.SMALL)}
`;

export const title: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.HEADING_2)}
`;

export const description: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.BODY)}
  ${getSpacing.marginTop(theme, SpacingName.MEDIUM)}
`;
