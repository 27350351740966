/* eslint-disable react/no-unknown-property */
import {
  Action, Message, CardSkeleton, Card, ActionDropDown, Dropdown,
  DetailItemList,
  TextColor,
  Text,
  Skeleton,
  Icon,
  HStack,
  ActionIcon,
  ICON_WHATSAPP,
  useNotifications,
  ICON_CONTENT_COPY,
  Spacing,
  ICON_CHECK_CIRCLE_OUTLINE,
  ICON_PROGRESS_CHECK,
  ICON_CLOSE_CIRCLE_OUTLINE,
  FontWeight,
} from '@doveit/bricks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '@doveit/hammer';
import CancelAssignmentSignatureRequestAction from '../../../assignment/containers/cancel-assignment-signature-request-action/CancelAssignmentSignatureRequestAction';
import PreviewAssignmentWizardPDFAction from '../../../assignment/containers/preview-assignment-wizard-pdf-action/PreviewAssignmentWizardPDFAction';
import ReactivateAssignmentSignatureRequestAction from '../../../assignment/containers/reactivate-assignment-signature-request-action/ReactivateAssignmentSignatureRequestAction';
import * as styles from './ProspectDigitalAssignmentWidget.style';
import { SignatureStatus, Signer, SingleSignatureStatus } from '../../../providers/api/dtos';
import { isNotifiedOrAlreadyInitiated } from '../../../utils/digital-signature/signatureStatus';
import useAssignmentSignatureStatus from '../../hooks/use-assignment-signature-status/useAssignmentSignatureStatus';
import useAssignmentWizardData from '../../hooks/use-assignment-wizard-data/useAssignmentWizardData';
import { computedDigitalAssignmentSignatureStatusActionColor, computedDigitalAssignmentSignatureStatusActionIcon } from './constants';
import { signatureStatusLabels } from '../../../labels';
import { formatTimeDistance, TimeDistanceUnit } from '../../../utils/time/format-time-distance/formatTimeDistance';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import { getShortURL } from '../../../providers/rebrandly/rebrandlyProvider';
import { copyToClipboard } from '../../../utils/text/text';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';

const signerStatusBadge = (signerStatus: SingleSignatureStatus) => {
  switch (signerStatus) {
    case SingleSignatureStatus.SIGNED: {
      return {
        icon: ICON_CHECK_CIRCLE_OUTLINE,
        color: 'success.default.low',
      } as const;
    }
    case SingleSignatureStatus.NOTIFIED: {
      return {
        icon: ICON_PROGRESS_CHECK,
        color: 'warning.default.low',
      } as const;
    }
    default: {
      return {
        icon: ICON_CLOSE_CIRCLE_OUTLINE,
        color: 'critical.default.low',
      } as const;
    }
  }
};

export interface ProspectDigitalAssignmentWidgetProps {
  prospectId: string,
}

export const PROSPECT_DIGITAL_ASSIGNMENT_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare i dati della richiesta di firma digitale.';
export const COPY_LINK_SUCCESS_MESSAGE = 'Link copiato negli appunti!';
export const COPY_LINK_ERROR_MESSAGE = 'Non è stato possibile copiare il link negli appunti. Prova con il classico copia e incolla.';
export const BASE_MESSAGE = 'Gentile __FIRST_NAME__ __LAST_NAME__, di seguito il link per firmare l\'incarico di vendita: __SIGNATURE_LINK__';

const ProspectDigitalAssignmentWidget: React.FC<ProspectDigitalAssignmentWidgetProps> = ({ prospectId }) => {
  const isMobile = useIsDevice('mobile');
  const navigate = useNavigate();
  const { addSuccess, addError } = useNotifications();
  const { data: assignmentWizardData, mutate: mutateAssignmentWizardData } = useAssignmentWizardData(prospectId);
  const {
    data: signatureRequest,
    isLoading: isSignatureRequestLoading,
    error: signatureStatusError,
    mutate: mutateSignatureStatus,
  } = useAssignmentSignatureStatus(prospectId, {
    refreshInterval: (data) => (data?.signers.every(isNotifiedOrAlreadyInitiated) ? 0 : 1000),
  });
  const [shortenedLink, setShortenedLink] = React.useState<Map<string, string>>(new Map());

  const mutate = React.useCallback(async () => {
    await mutateAssignmentWizardData();
    await mutateSignatureStatus();
  }, [mutateAssignmentWizardData, mutateSignatureStatus]);

  const goToAssignmentWizardPage = React.useCallback(() => {
    navigate(`/prospects/${prospectId}/assignment/create/digital`);
  }, [navigate, prospectId]);

  const expirationInfo = React.useMemo(() => {
    if (signatureRequest) {
      const formattedValue = formatDate(new Date(signatureRequest.activationDate));
      const formattedTimeSpan = formatTimeDistance(signatureRequest.expirationDate, Date.now(), TimeDistanceUnit.EXTENDED, true);
      const color: TextColor = formattedTimeSpan.includes('giorn') ? 'neutral.default.low' : 'critical.default.low';
      return {
        formattedValue,
        formattedTimeSpan,
        color,
      };
    }
    return undefined;
  }, [signatureRequest]);

  const getSignerLink = React.useCallback(async (signer: Signer) => {
    const shortLink = shortenedLink.get(signer.email) || await getShortURL(signer.signatureLink!);
    setShortenedLink(shortenedLink.set(signer.email, shortLink));
    return shortLink;
  }, [shortenedLink]);

  const onCopyLink = React.useCallback((signer: Signer) => async () => {
    try {
      const shortLink = await getSignerLink(signer);
      await copyToClipboard(shortLink);
      addSuccess(COPY_LINK_SUCCESS_MESSAGE);
    } catch (_) {
      addError(COPY_LINK_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, getSignerLink]);

  const buildMessage = React.useCallback((signer: Signer) => async () => {
    const { firstName, lastName } = signer;

    const shortLink = await getSignerLink(signer);

    const formattedMessage = BASE_MESSAGE
      .replace('__FIRST_NAME__', firstName)
      .replace('__LAST_NAME__', lastName)
      .replace('__SIGNATURE_LINK__', shortLink);

    return formattedMessage;
  }, [getSignerLink]);

  if (signatureStatusError) {
    return (
      <Message
        type="critical"
        message={PROSPECT_DIGITAL_ASSIGNMENT_LOAD_ERROR_MESSAGE}
      />
    );
  }

  if (isSignatureRequestLoading && !signatureRequest) {
    return <CardSkeleton aria-label="skeleton" />;
  }

  return signatureRequest && (
    <Card aria-label="Mandato digitale">
      <Card.Header
        isSecondary
        primaryActions={[!isMobile && (
          <PreviewAssignmentWizardPDFAction prospectId={prospectId}>
            {({ onClick, isLoading }) => (
              <Action
                label="Visualizza anteprima"
                size="S"
                aria-label="Visualizza anteprima mandato"
                loading={isLoading}
                onClick={onClick}
              />
            )}
          </PreviewAssignmentWizardPDFAction>
        ),
        ]}
        secondaryActions={[
          isMobile && (
            <PreviewAssignmentWizardPDFAction prospectId={prospectId}>
              {({ onClick }) => (
                <Dropdown.Option
                  label="Visualizza anteprima"
                  aria-label="Visualizza anteprima mandato"
                  onClick={onClick}
                />
              )}
            </PreviewAssignmentWizardPDFAction>
          ),
        ]}
      >
        <ActionDropDown
          label={signatureStatusLabels[signatureRequest?.status as SignatureStatus]}
          color={computedDigitalAssignmentSignatureStatusActionColor[signatureRequest?.status as SignatureStatus]}
          iconLeft={{ path: computedDigitalAssignmentSignatureStatusActionIcon[signatureRequest?.status as SignatureStatus] }}
          aria-label="Selezione per aggiornare lo stato della richiesta di firma"
          size="S"
          emphasis="high"
        >
          {assignmentWizardData && signatureRequest?.status === SignatureStatus.ONGOING && (
            <CancelAssignmentSignatureRequestAction
              assignmentWizardData={assignmentWizardData!}
              onSuccess={mutate}
            >
              {({ cancelSignatureRequest }) => (
                <Dropdown.Option
                  label="Annulla richiesta"
                  aria-label="Annulla richiesta di firma"
                  onClick={cancelSignatureRequest}
                />
              )}
            </CancelAssignmentSignatureRequestAction>
          )}
          {signatureRequest.status === SignatureStatus.EXPIRED && (
            <ReactivateAssignmentSignatureRequestAction
              prospectId={prospectId}
              assignmentSignature={signatureRequest}
              onSuccess={mutate}
            >
              {({ reactivateSignatureRequest }) => (
                <Dropdown.Option
                  label="Ripristina"
                  aria-label="Ripristina richiesta di firma"
                  onClick={reactivateSignatureRequest}
                />
              )}
            </ReactivateAssignmentSignatureRequestAction>
          )}
          {signatureRequest.status === SignatureStatus.CANCELED && (
            <Dropdown.Option
              label="Modifica"
              aria-label="Modifica informazioni mandato"
              onClick={goToAssignmentWizardPage}
            />
          )}
        </ActionDropDown>
      </Card.Header>
      <Card.Content>
        <DetailItemList columns={2}>
          <DetailItemList.Group>
            {isSignatureRequestLoading && (
              <DetailItemList.Item>
                <Skeleton width="5rem" />
                <Skeleton />
              </DetailItemList.Item>
            )}
            {expirationInfo && (
              <DetailItemList.Item label="Data richiesta">
                {expirationInfo.formattedValue}
                {signatureRequest.status === SignatureStatus.ONGOING && (
                  <>
                    {' • '}
                    <Text.Body color={expirationInfo.color} fontWeight={FontWeight.LIGHT}>
                      {`${expirationInfo.formattedTimeSpan} alla scadenza`}
                    </Text.Body>
                  </>
                )}
              </DetailItemList.Item>
            )}
          </DetailItemList.Group>

          {signatureRequest && (
            <DetailItemList.Group
              label="Firmatari"
              aria-label="Lista firmatari proposta"
            >
              <DetailItemList.Item fullWidth>
                {signatureRequest.signers.map((signer, index) => {
                  const {
                    email, status, signatureLink, firstName, lastName,
                  } = signer;

                  const signerNotNotifiedYet = status === SingleSignatureStatus.INITIATED && !signatureLink;

                  const { color, icon } = signerStatusBadge(status);

                  return (
                    <div key={email} css={styles.signersWrapper}>
                      <HStack alignItems="center" justifyContent="space-between">
                        <HStack>
                          <Icon
                            path={icon}
                            color={color}
                          />
                          {firstName} {lastName}
                        </HStack>
                        {signatureRequest.status === SignatureStatus.ONGOING && (
                          <HStack>
                            <ActionIcon
                              size="S"
                              icon={{ path: ICON_CONTENT_COPY }}
                              aria-label="Pulsante per copiare il link di firma di YouSign"
                              label="Copia link"
                              onClick={onCopyLink(signer)}
                              loading={signerNotNotifiedYet}
                              disabled={status !== SingleSignatureStatus.NOTIFIED}
                            />
                            <SendWhatsappAction
                              phoneNumber={signer.phoneNumber}
                              name={`${signer.firstName} ${signer.lastName}`}
                              initialMessage={buildMessage(signer)}
                            >
                              {({ onClick, disabled }) => (
                                <ActionIcon
                                  aria-label="Pulsante per condividere il link di firma di YouSign"
                                  size="S"
                                  icon={{ path: ICON_WHATSAPP }}
                                  label="Condividi link su WhatsApp"
                                  onClick={onClick}
                                  disabled={disabled || status !== SingleSignatureStatus.NOTIFIED}
                                  loading={signerNotNotifiedYet}
                                />
                              )}
                            </SendWhatsappAction>
                          </HStack>
                        )}
                      </HStack>
                      {index < signatureRequest.signers.length - 1 && (
                        <Spacing
                          margin={[75, 0, 0]}
                          css={styles.signerItemDivider}
                        />
                      )}
                    </div>
                  );
                })}
              </DetailItemList.Item>
            </DetailItemList.Group>
          )}
        </DetailItemList>
      </Card.Content>
    </Card>
  );
};

export default ProspectDigitalAssignmentWidget;
