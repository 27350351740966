import { IntentSection } from '../../intent/types';
import { PropertyMatchCounters } from '../../providers/api/dtos/propertyMatchCounters';
import { PropertyMatchesSectionCounters, PropertyMatchAdditionalSection } from '../types';

export function toPropertyMatchSectionCounters({
  intents,
  searchCriteria,
}: PropertyMatchCounters): PropertyMatchesSectionCounters {
  return {
    [IntentSection.TODO]: intents.new,
    [IntentSection.RECALL]: intents.recall,
    [PropertyMatchAdditionalSection.SEARCH_CRITERIA]: searchCriteria.total,
    [PropertyMatchAdditionalSection.SIMILAR_INTENTS]: intents.similarIntents,
    [IntentSection.SCHEDULED_TODO]: intents.scheduledTodo,
    [IntentSection.SCHEDULED_EXPIRED]: intents.scheduledExpired,
    [IntentSection.SCHEDULED_PENDING]: intents.scheduledPending,
    [IntentSection.OFFERS_IN_PROGRESS]: intents.offersInProgress,
    [IntentSection.OFFERS_ACCEPTED]: intents.offersAccepted,
    [IntentSection.OFFERS_REFUSED]: intents.offersRefused,
    [IntentSection.OFFERS_WITHDRAWN]: intents.offersWithdrawn,
    [PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITH_DONE_APPOINTMENTS]: intents.archivedWithDoneAppointments,
    [PropertyMatchAdditionalSection.INTENT_ARCHIVED_WITHOUT_DONE_APPOINTMENTS]: intents.archivedWithoutDoneAppointments,
  };
}
