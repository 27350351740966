import {
  EnergyClass, ExtraPackage, PropertyUnitIntendedUse, RegistryCategory,
} from '../../../domain/types';
import { Contact } from './contact';
import { Geo } from './geo';
import { Prospect } from './prospect';

export interface Assignment {
  readonly id: number,
  agentId: number,
  propertyId: number,
  exclusive?: boolean,
  autoRenew?: boolean,
  personal?: boolean,
  keysHanded?: boolean,
  endDate?: string,
  prospectId?: string,
  extraPackages: ExtraPackage[],
}

// TODO: Move enums and other interfaces on related parent folders/files
export interface CadastralRegistry {
  locality?: string,
  units?: PropertyUnit[],
}

export interface Ownership {
  type?: string,
  details?: string,
}

export interface Status {
  type?: AssignmentPropertyStatus,
  details?: string,
}

export interface Rent {
  annualFee?: number,
  expiresAt?: string,
}

export interface Compliance {
  isUrbanCompliant?: boolean,
  areSystemsCompliant?: boolean,
  hasOccupancyCertificate?: boolean,
  technicianNeeded?: boolean,
  technicianByDoveIt?: boolean,
  systemsCompliantNotes?: string,
  urbanCompliantNotes?: string,
  occupancyCertificateNotes?: string,
}

export interface Constraints {
  legalIssues?: boolean,
  isConstraintsFree?: boolean,
  details?: string
}

export interface AdditionalExpenses {
  extraDeliberated?: boolean,
  value?: number,
  extraDetails?: string,
}

export interface IdentityDocument {
  type?: IdentityDocumentType,
  number?: string,
  issuedBy?: string,
  issuedAt?: string,
}

export interface Legal {
  personRole?: string,
  companyName?: string,
  registeredOffice?: Geo,
  fiscalCode?: string,
}

export interface WizardContact {
  id?: NonNullable<Contact['id']>,
  name?: string,
  email?: string,
  phoneNumber?: string,
  fiscalCode?: string,
  birthday?: string,
  birthplace?: string,
  placeOfResidence?: Geo,
  identityDocument?: IdentityDocument,
  legal?: Legal,
  planimetryDelegating?: boolean,
  citizenship?: string,
}

export interface PropertyData {
  price?: number,
  minimumPrice?: number,
  evaluation?: number,
  isMortgageInProgress?: boolean,
  residualMortgage?: number,
  hasLien?: boolean,
  lienValue?: number,
  geo?: Partial<Geo>,
  unit?: string,
  stairwell?: string,
  cadastralRegistry?: CadastralRegistry,
  ownership?: Ownership,
  status?: Status,
  rent?: Rent,
  compliance?: Compliance,
  constraints?: Constraints,
  additionalExpenses?: AdditionalExpenses,
  source?: {
    type?: PropertySource,
    details?: string,
  }
}

export interface AssignmentData {
  subscriptionPlace?: AssignmentSubscriptionPlace,
  keysHanded?: boolean,
  autoRenew?: boolean,
  exclusive?: boolean,
  startDate?: string,
  endDate?: string,
  rightOfWithdrawal?: boolean,
  notes?: string,
  extraPackages?: ExtraPackage[],
  extraPackagesDoveItEnabled?: boolean,
  extraPackagesSPFEnabled?: boolean,
  cancellationFee?: number,
}

export interface AssignmentWizardData {
  prospectId: NonNullable<Prospect['id']>,
  property?: PropertyData,
  assignment?: AssignmentData,
  contacts?: WizardContact[],
  isLegalPersonOnly?: boolean,
  signaturePlace?: string,
  isDigitallySigned?: boolean,
  planimetryDelegationRequired: boolean,
  updatedAt?: string
}

export type Signature = Readonly<{
  id: string,
  activationDate: string,
  expirationDate: string,
  signers: Signer[],
  status: SignatureStatus,
}>;

export type Signer = Readonly<{
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  status: SingleSignatureStatus,
  signatureLink: string | null,
}>;

export enum SignatureStatus {
  SIGNED = 'SIGNED',
  ONGOING = 'ONGOING',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
}

export enum SingleSignatureStatus {
  INITIATED = 'INITIATED',
  NOTIFIED = 'NOTIFIED',
  VERIFIED = 'VERIFIED',
  PROCESSING = 'PROCESSING',
  CONSENT_GIVEN = 'CONSENT_GIVEN',
  SIGNED = 'SIGNED',
  ABORTED = 'ABORTED',
  ERROR = 'ERROR',
}

export interface PropertyUnit {
  section?: string,
  sheet?: string,
  parcel?: string,
  subunit?: string,
  category?: RegistryCategory,
  intendedUse?: PropertyUnitIntendedUse,
  value?: number,
  energyClass?: EnergyClass,
}

export enum AssignmentPropertyStatus {
  FREE = 'FREE',
  OCCUPIED = 'OCCUPIED',
  UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION',
  RENTAL = 'RENTAL',
  OTHER = 'OTHER',
}

export enum PropertySource {
  SALE = 'SALE',
  SUCCESSION = 'SUCCESSION',
  DONATION = 'DONATION',
  OTHER = 'OTHER',
}

export enum AssignmentSubscriptionPlace {
  IN_AGENT_LOCALES = 'IN_AGENT_LOCALES',
  OUT_OF_AGENT_LOCALES = 'OUT_OF_AGENT_LOCALES',
  REMOTE = 'REMOTE',
}

export enum IdentityDocumentType {
  IDENTITY_CARD = 'IDENTITY_CARD',
  PASSPORT = 'PASSPORT',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
}
