export enum AgentStatus {
  ACTIVE = 'true',
  INACTIVE = 'false',
}

export enum MembershipStatus {
  UNDER_ACTIVATION = 'UNDER_ACTIVATION',
  ACTIVE = 'ACTIVE',
  UNDER_REVOCATION = 'UNDER_REVOCATION',
  REVOKED = 'REVOKED',
}

export enum MembershipLegalRole {
  COMPANY = 'COMPANY',
  UNIPERSONAL = 'UNIPERSONAL',
}
