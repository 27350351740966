import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const row: ThemedFunctor = ({ theme }) => css`
  position: relative;
  background: ${theme.color.background.neutral.default.lowest};

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.color.border.neutral.default.low};
  }
`;
