import React from 'react';
import { To, useNavigate, NavigateOptions } from 'react-router-dom';
import { raise } from '../../utils';

interface NavigateInSameTabOptions extends NavigateOptions {
  newTab?: false,
}

interface NavigateInNewTabOptions {
  newTab: true,
}

export type NavigateFnOptions = NavigateInSameTabOptions | NavigateInNewTabOptions;

export type NavigateFn = (to: To, options?: NavigateFnOptions) => void;

/**
 * Custom hook for navigation that provides a callback function to navigate to a specified location.
 *
 * @returns {NavigateFn} A function to navigate to a new page.
 */
const useExtendedNavigate = (): NavigateFn => {
  const navigate = useNavigate();

  const extendedNavigate: NavigateFn = React.useCallback((to, options = {}) => {
    const { newTab = false, ...rest } = options;

    if (newTab) {
      if (typeof to !== 'string') {
        raise('useNavigate: Invalid type for parameter `to`.');
        return;
      }

      window.open(to);
    } else {
      navigate(to, rest);
    }
  }, [navigate]);

  return extendedNavigate;
};

export default useExtendedNavigate;
