/* eslint-disable react/no-unknown-property */
import { Icon, IconProps } from '@doveit/bricks';
import React from 'react';
import { ThemeIcons } from '../../../theme/icons';
import * as styles from './TrustpilotRating.style';

export interface TrustpilotRatingProps {
  stars: number,
  size?: IconProps['size'],
}

const STAR_BACKGROUND_COLORS: Record<number, string> = {
  1: '#FF3722', // Red
  2: '#FF8622', // Orange
  3: '#FFCE00', // Yellow
  4: '#73CF11', // Green
  5: '#00B67A', // Teal
};

const DEFAULT_STAR_BACKGROUND_COLOR = '#DCDCE6'; // Grey

const TrustpilotRating: React.FC<TrustpilotRatingProps> = ({
  stars,
  size = 24,
}) => {
  const normalizedStars = Math.min(Math.max(1, stars), 5);

  return (
    <div css={styles.starsWrapper} $size={size}>
      {[...Array(5).keys()].map((key, i) => (
        <div
          key={key}
          css={styles.starBackground}
          $size={size}
          $color={i < normalizedStars ? STAR_BACKGROUND_COLORS[normalizedStars] : DEFAULT_STAR_BACKGROUND_COLOR}
        >
          <Icon
            path={ThemeIcons.TRUSTPILOT}
            size={size}
          />
        </div>
      ))}
    </div>
  );
};

export default TrustpilotRating;
