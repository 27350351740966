import {
  BadgeProps, ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE, ICON_PROGRESS_CHECK,
} from '@doveit/bricks';
import { ShootingStatus } from '../domain/types';

// Shooting status colors and icons for direct or partner
export const colorsTypeDirectOrPartner: Record<ShootingStatus, BadgeProps['color']> = {
  BOOKED: 'warning',
  REVIEWED: 'info',
  DELIVERED: 'info',
  COMPLETED: 'success',
  CANCELLED: 'critical',
  NOT_EXECUTED: 'critical',
};
export const iconsTypeDirectOrPartner: Record<ShootingStatus, string> = {
  BOOKED: ICON_PROGRESS_CHECK,
  REVIEWED: ICON_PROGRESS_CHECK,
  DELIVERED: ICON_PROGRESS_CHECK,
  COMPLETED: ICON_CHECK_CIRCLE_OUTLINE,
  CANCELLED: ICON_ALERT_CIRCLE_OUTLINE,
  NOT_EXECUTED: ICON_ALERT_CIRCLE_OUTLINE,
};

// Shooting status colors and icons for agent
export const colorsTypeAgent: Record<ShootingStatus, BadgeProps['color']> = {
  DELIVERED: 'info',
  COMPLETED: 'success',
  BOOKED: undefined,
  REVIEWED: undefined,
  CANCELLED: undefined,
  NOT_EXECUTED: undefined,
};
export const iconsTypeAgent: Record<ShootingStatus, string | undefined> = {
  DELIVERED: ICON_PROGRESS_CHECK,
  COMPLETED: ICON_CHECK_CIRCLE_OUTLINE,
  BOOKED: undefined,
  REVIEWED: undefined,
  CANCELLED: undefined,
  NOT_EXECUTED: undefined,
};
