import React from 'react';
import {
  FormSkeleton, Message, useNotifications,
} from '@doveit/bricks';
import { InvoiceStatus } from '../../../domain/types';
import { Invoice } from '../../../providers/api/dtos';
import InvoiceForm from '../../components/invoice-form/InvoiceForm';
import { InvoiceModel } from '../../models/invoiceModel';
import { updateInvoice } from '../../../providers/api/invoice/invoiceProvider';
import { INVOICEABLE_STATUSES } from '../../../domain/types/status';
import { computeLabels, computeTargetInvoiceStatus } from './utils';
import usePropertyPreview from '../../../property/hooks/use-property-preview/usePropertyPreview';

export interface EditInvoiceProps {
  invoice: Invoice,
  hideAdministrativeFields?: boolean,
  allowStatusChange?: boolean,
  onSuccess?: (updatedInvoice: Invoice) => void,
}

export const PROPERTY_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare alcuni dati relativi al pagamento';
export const PROPERTY_WITH_NOT_SELLABLE_STATUS_MESSAGE = `L'immobile non si trova in uno degli stati ammessi (${INVOICEABLE_STATUSES.join(', ')}). Cambia lo stato prima di poter inserire i dati del pagamento`;

const EditInvoice: React.FC<EditInvoiceProps> = ({
  invoice,
  hideAdministrativeFields = false,
  allowStatusChange = false,
  onSuccess,
}) => {
  const { data: property, error: propertyError } = usePropertyPreview(invoice.propertyId);
  const [isSaving, setIsSaving] = React.useState(false);
  const { addError, addSuccess } = useNotifications();

  const invoiceFormInitialValues: InvoiceModel = React.useMemo(() => ({
    agentFee: invoice?.agentFee?.toString() || '',
    buyerFee: invoice?.buyerFee?.toString() || '',
    sellerFee: invoice?.sellerFee?.toString() || '',
    collectedAt: invoice?.collectedAt || '',
    soldPrice: invoice?.soldPrice?.toString() || '',
    forecastedInvoiceDate: invoice?.forecastedInvoiceDate || '',
  }), [invoice]);

  const hideForecastedDateField = React.useMemo(() => [InvoiceStatus.TO_COLLECT, InvoiceStatus.COLLECTED].includes(invoice.status), [invoice.status]);

  const {
    formSubmitLabel,
    successNotification,
    errorNotification,
  } = React.useMemo(() => computeLabels(invoice.status, allowStatusChange), [allowStatusChange, invoice.status]);

  const onInvoiceFormSubmit = React.useCallback(async (formValues: InvoiceModel) => {
    setIsSaving(true);

    try {
      const {
        agentFee,
        buyerFee,
        soldPrice,
        sellerFee,
        collectedAt,
        forecastedInvoiceDate,
      } = formValues;

      const invoiceToUpdate: Invoice = {
        ...invoice,
        agentFee: parseFloat(agentFee),
        buyerFee: parseFloat(buyerFee),
        sellerFee: sellerFee ? parseFloat(sellerFee) : undefined,
        soldPrice: parseInt(soldPrice, 10),
        collectedAt: collectedAt || undefined,
        forecastedInvoiceDate,
        status: computeTargetInvoiceStatus(invoice.status, collectedAt, allowStatusChange),
      };

      const updatedInvoice = await updateInvoice(invoiceToUpdate);

      setIsSaving(false);
      addSuccess(successNotification);

      if (onSuccess) {
        onSuccess(updatedInvoice);
      }
    } catch (error) {
      setIsSaving(false);
      addError(errorNotification);
    }
  }, [addError, addSuccess, allowStatusChange, errorNotification, invoice, onSuccess, successNotification]);

  if (propertyError) {
    return (
      <Message
        type="critical"
        message={PROPERTY_LOAD_ERROR_MESSAGE}
      />
    );
  }

  if (!property) {
    return <FormSkeleton />;
  }

  return (
    <InvoiceForm
      initialValues={invoiceFormInitialValues}
      loading={isSaving}
      hideAdministrativeFields={hideAdministrativeFields}
      hideForecastedDateField={hideForecastedDateField}
      submitLabel={formSubmitLabel}
      onSubmit={onInvoiceFormSubmit}
      disabled={invoice.status === InvoiceStatus.COLLECTED}
      disableCollectedAt={invoice.status === InvoiceStatus.TODO || !allowStatusChange}
    />
  );
};

export default EditInvoice;
