import { ActionIcon, ICON_CONTENT_COPY, useNotifications } from '@doveit/bricks';
import React from 'react';
import { getShortURL } from '../../providers/rebrandly/rebrandlyProvider';
import { copyToClipboard } from '../../utils/text/text';

export const COPY_LINK_SUCCESS_MESSAGE = 'Link copiato negli appunti!';
export const COPY_LINK_ERROR_MESSAGE = 'Non è stato possibile copiare il link negli appunti. Prova con il classico copia e incolla.';

export interface ChildFnProps {
  onCopy: () => void,
}

export interface CopyYouSignLinkActionProps {
  link: string,
  shortUrl?: boolean,
  disabled?: boolean,
  loading?: boolean,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const CopyLinkAction: React.FC<CopyYouSignLinkActionProps> = ({
  link,
  shortUrl,
  disabled,
  loading,
  children,
}) => {
  const { addSuccess, addError } = useNotifications();

  const onCopyLink = React.useCallback(async () => {
    try {
      let tempLink = link;

      if (shortUrl) {
        tempLink = await getShortURL(link);
      }

      await copyToClipboard(tempLink);

      addSuccess(COPY_LINK_SUCCESS_MESSAGE);
    } catch (_) {
      addError(COPY_LINK_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, link, shortUrl]);

  return (
    children?.({ onCopy: onCopyLink })
    ?? (
      <ActionIcon
        size="S"
        icon={{ path: ICON_CONTENT_COPY }}
        aria-label="Pulsante per abbreviare e copiare il link"
        label="Copia link"
        onClick={onCopyLink}
        loading={loading}
        disabled={disabled}
      />
    )
  );
};

export default CopyLinkAction;
