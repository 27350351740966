import React from 'react';
import { AssignmentWizardStepProps } from '../types';
import { toAssignmentWizardData, toPropertyInfoWizardStep } from './mappers';
import PropertyInfoForm, { PropertyInfoFormModel } from '../../../../components/property-info-form/PropertyInfoForm';

const AssignmentWizardPropertyInfo: React.FC<AssignmentWizardStepProps> = ({
  initialData,
  loading = false,
  formRef,
  onSubmit,
}) => {
  const onInternalSubmit = React.useCallback((formValues: PropertyInfoFormModel) => {
    const values = toAssignmentWizardData(initialData, formValues);

    const isFormDirty = (formRef?.current as any)?.dirty ?? false;

    onSubmit(values, isFormDirty);
  }, [formRef, initialData, onSubmit]);

  return (
    <PropertyInfoForm
      initialValues={toPropertyInfoWizardStep(initialData)}
      formRef={formRef}
      onSubmit={onInternalSubmit}
      loading={loading}
    />
  );
};

export default AssignmentWizardPropertyInfo;
