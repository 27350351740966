/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent } from 'react';
import * as styles from './Xmas.styles';

export function isXmasTime() {
  const now = new Date();

  return (now).getDate() >= 24 && now.getMonth() === 11;
}

const Xmas: FunctionComponent = () => (
  <div css={styles.lights}>
    <ul>
      <li css={styles.setColor('red', 1)} />
      <li css={styles.setColor('yellow', 0.9)} />
      <li css={styles.setColor('blue', 0.8)} />
      <li css={styles.setColor('pink', 1.2)} />
      <li css={styles.setColor('red', 1)} />
      <li css={styles.setColor('blue', 0.8)} />
      <li css={styles.setColor('yellow', 0.8)} />
      <li css={styles.setColor('red', 1)} />
      <li css={styles.setColor('pink', 1.2)} />
    </ul>
  </div>
);

export default Xmas;
