import {
  ActionIcon,
  ConfirmModal, ICON_HOME_SWITCH_OUTLINE, Portal, Text, useModal, useNotifications,
} from '@doveit/bricks';
import React from 'react';
import { Status } from '../../../domain/types';
import { Property } from '../../../providers/api/dtos';
import { updateProperty } from '../../../providers/api/property/propertyProvider';
import { WithRequiredKeys } from '../../../types';
import { useComputedPropertyStatus } from '../../hooks/use-computed-property-status/useComputedPropertyStatus';

export const RESTORE_PROPERTY_SUCCESS_MESSAGE = 'Immobile ripristinato con successo';
export const RESTORE_PROPERTY_ERROR_MESSAGE = 'Non è stato possibile ripristinare l\'immobile';

interface ChildFnProps {
  disabled: boolean,
  loading: boolean,
  onDraft: VoidFunction,
}

export interface DraftPropertyActionProps {
  property: Property,
  disabled?: boolean,
  onSuccess?: (draftProperty: Property) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const DraftPropertyAction: React.FC<DraftPropertyActionProps> = ({
  property,
  disabled = false,
  onSuccess,
  children,
}) => {
  const {
    id,
    status,
  } = property as WithRequiredKeys<Property, 'id'>;

  const {
    data: computedPropertyStatus,
    isLoading: isComputedPropertyStatusLoading,
  } = useComputedPropertyStatus(property);

  const [isSaving, setIsSaving] = React.useState(false);
  const confirmModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const isWithdrawned = React.useMemo(() => status === Status.RITIRATO, [status]);
  const canBeRestored = React.useMemo(() => computedPropertyStatus?.canBeUpdatedTo(Status.DRAFT), [computedPropertyStatus]);

  const openConfirmModal = React.useCallback(() => {
    confirmModal.open();
  }, [confirmModal]);

  const onConfirm = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const propertyToRestore: Property = {
        ...property,
        status: Status.DRAFT,
      };

      const restoredProperty = await updateProperty(id, propertyToRestore);

      setIsSaving(false);
      confirmModal.close();
      addSuccess(RESTORE_PROPERTY_SUCCESS_MESSAGE);

      onSuccess?.(restoredProperty);
    } catch (error) {
      setIsSaving(false);
      addError(RESTORE_PROPERTY_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, confirmModal, id, onSuccess, property]);

  if (!canBeRestored) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          onDraft: openConfirmModal,
          disabled,
          loading: isComputedPropertyStatusLoading || isSaving,
        }) : (
          <ActionIcon
            icon={{ path: ICON_HOME_SWITCH_OUTLINE }}
            label="Ripristina"
            title={isWithdrawned ? 'Ripristina l\'immobile' : 'Sposta in bozza l\'immobile'}
            onClick={openConfirmModal}
            aria-label={isWithdrawned ? 'Ripristina l\'immobile' : 'Sposta in bozza l\'immobile'}
            disabled={disabled}
          />
        )}
      <Portal>
        <ConfirmModal
          isOpen={confirmModal.isOpen}
          aria-label={isWithdrawned ? 'Conferma il ripristino dell\'immobile' : 'Conferma lo spostamento in bozza dell\'immobile '}
          title={isWithdrawned ? 'Ripristino immobile' : 'Sposta in bozza'}
          saving={isSaving}
          onConfirm={onConfirm}
          onAbort={confirmModal.close}
        >
          <Text.Body>
            {isWithdrawned
              ? 'Ripristinando l\'immobile, l\'annuncio tornerà in stato Bozza.'
              : 'Spostando l\'immobile in bozza, l\'annuncio non sarà visibile sul sito di Dove.it con effetto immediato, mentre la rimozione dai portali, se selezionati, potrà richiedere fino a 24 ore.'}
          </Text.Body>
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default DraftPropertyAction;
