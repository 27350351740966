import { Avatar, AvatarProps, ICON_DOMAIN } from '@doveit/bricks';
import React from 'react';

export type PropertyAvatarProps = Pick<AvatarProps, 'size' | 'placeholder' | 'icon'>;

export const PropertyAvatar: React.FC<PropertyAvatarProps> = (props) => (
  <Avatar
    {...props}
    placeholder={ICON_DOMAIN}
  />
);

export default PropertyAvatar;
