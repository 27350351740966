import useSWR from 'swr';
import hash from 'object-hash';
import { EventV2 } from '../../../providers/api/dtos/event';
import { getIntentEvents, IntentEventsProviderFilters } from '../../../providers/api/intent/intentProvider';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';

export const INTENT_EVENTS_RID = '/api/v2/intents/:id/events';

export default function useIntentEvents(intentId?: string, filters?: IntentEventsProviderFilters): SWRCustomResponse<EventV2[], Error> {
  const matchMutate = useMatchMutate();
  const rid = [INTENT_EVENTS_RID, intentId].join();

  const key = intentId ? [rid, hash({ ...filters })] : null;
  const swrResponse = useSWR(key, () => getIntentEvents(intentId!, filters));

  return {
    ...swrResponse,
    mutate: () => matchMutate(rid),
  };
}
