import * as Yup from 'yup';
import { ResidentialPropertyType, propertyTypesWithoutFloor, ResidentialPropertyTypeValue } from '../../../domain/types';

export default Yup.object({
  propertyType: Yup
    .string()
    .required('La tipologia dell\'immobile è richiesta'),
  propertyAddress: Yup
    .object()
    .required('L\'indirizzo dell\'immobile è richiesto'),
  propertyFloor: Yup
    .string()
    .when('propertyType', {
      is: (propertyType: ResidentialPropertyType) => propertyTypesWithoutFloor.includes(propertyType),
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required('Il piano dell\'immobile è richiesto'),
    })
    .test('is-valid',
      'Il piano non può essere maggiore del totale piani',
      (value, context) => (!value || value in ['Seminterrato', 'Terra', 'Rialzato'] || !context.parent.buildingFloors
        ? true
        : +value <= context.parent.buildingFloors
      )),
  buildingFloors: Yup
    .number()
    .min(1)
    .when('propertyType', {
      is: (val: ResidentialPropertyType | undefined) => val === ResidentialPropertyTypeValue.APPARTAMENTO,
      then: (schema) => schema.positive('Totale piani dell\'immobile non valido'),
      otherwise: (schema) => schema.notRequired(),
    }),
  propertySize: Yup
    .number()
    .positive('La dimensione dell\'immobile non è valida')
    .required('I mq dell\'immobile sono richiesti'),
  propertyStatus: Yup
    .string()
    .required('Lo stato dell\'immobile è richiesto'),
  isPersonal: Yup
    .string()
    .required('Specificare se la lead è personale'),
  originAgent: Yup
    .object()
    .when('isPersonal', {
      is: (isPersonal: string) => isPersonal === 'true',
      then: (schema) => schema.required('L\'agente gestore della lead è richiesto'),
      otherwise: (schema) => schema.notRequired(),
    }),
});
