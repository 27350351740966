import { isEqual } from 'lodash';
import { CommissionSchema, CommissionSchemaType } from '../../domain/types';
import { Commissions } from '../../providers/api/dtos';
import { commissionSchemaDictionary } from '../domain/commissionSchemaDictionary';

export function getCommissionSchema(commissions: Commissions): CommissionSchemaType {
  const [commissionSchema] = Object.entries(commissionSchemaDictionary)
    .find(([_, value]) => isEqual({ ...commissions, notes: undefined }, { ...value, notes: undefined })) || [];

  return commissionSchema ? commissionSchema as CommissionSchemaType : CommissionSchema.CUSTOM;
}
