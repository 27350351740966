import hash from 'object-hash';
import useSWR from 'swr';
import { AxiosError } from 'axios';
import { PropertiesProviderFilters, searchProperties } from '../../../providers/api/property/propertyProvider';
import { PaginationParams, PaginatedResult } from '../../../providers/pagination';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';
import { PropertySearchItem } from '../../../providers/api/dtos';

export const SEARCH_PROPERTIES_RID = '/v3/properties/search';

export default function useSearchProperties(
  query?: string,
  filters: PropertiesProviderFilters = {},
  pagination?: PaginationParams,
): SWRCustomResponse<PaginatedResult<PropertySearchItem>, AxiosError> {
  const matchMutate = useMatchMutate();
  const key = query ? [SEARCH_PROPERTIES_RID, hash({ query, ...filters, ...pagination })] : null;
  const result = useSWR(key, () => searchProperties(query!, filters, pagination));

  return {
    ...result,
    mutate: () => matchMutate(SEARCH_PROPERTIES_RID),
  };
}
