import { ActionIcon, ICON_FILE_PDF_BOX, useNotifications } from '@doveit/bricks';
import React from 'react';
import { Prospect } from '../../../providers/api/dtos';
import { getAssignmentWizardPDFPreview } from '../../../providers/api/assignment/assignmentProvider';

export const PDF_PREVIEW_ERROR_MESSAGE = 'Non è stato possibile generare l\'anteprima del mandato';

interface ChildFnProps {
  onClick: VoidFunction,
  isLoading?: boolean,
}

export interface PreviewAssignmentWizardPDFActionProps {
  prospectId: NonNullable<Prospect['id']>,
  children?: ({ onClick, isLoading }: ChildFnProps) => React.ReactNode,
}

const PreviewAssignmentWizardPDFAction: React.FC<PreviewAssignmentWizardPDFActionProps> = ({
  prospectId,
  children,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { addError } = useNotifications();

  const previewPDF = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const data = await getAssignmentWizardPDFPreview(prospectId);

      setIsLoading(false);

      const fileURL = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      window.open(fileURL, '_blank');
    } catch (error) {
      setIsLoading(false);
      addError(PDF_PREVIEW_ERROR_MESSAGE);
    }
  }, [addError, prospectId]);

  return (
    children
      ? children({ onClick: previewPDF, isLoading })
      : (
        <ActionIcon
          label="Genera l'anteprima del mandato"
          aria-label="Genera l'anteprima del mandato"
          onClick={previewPDF}
          icon={{ path: ICON_FILE_PDF_BOX }}
          emphasis="high"
          size="S"
          loading={isLoading}
        />
      )
  );
};

export default PreviewAssignmentWizardPDFAction;
