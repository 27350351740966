import React from 'react';
import {
  Action, Form, FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Assignment } from '../../../providers/api/dtos';
import { formatInputDate } from '../../../utils/form';
import { updateAssignment } from '../../../providers/api/assignment/assignmentProvider';
import SimpleModal from '../../../components/simple-modal/SimpleModal';

type ChildFnProps = {
  editEndDate: VoidFunction,
  isSaving: boolean,
};

type FormValues = Pick<Assignment, 'endDate'>;

export interface UpdateAssignmentEndDateActionProps {
  assignment: Assignment,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (updatedAssignment: Assignment) => void,
}

export const UPDATE_ASSIGNMENT_END_DATE_SUCCESS_MESSAGE = 'La data di scadenza del mandato è stata aggiornata con successo';
export const UPDATE_ASSIGNMENT_END_DATE_ERROR_MESSAGE = 'Non è stato possibile aggiornare la data di scadenza del mandato';

const UpdateAssignmentEndDateAction: React.FC<UpdateAssignmentEndDateActionProps> = ({
  assignment,
  children,
  onSuccess,
}) => {
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const [isSaving, setIsSaving] = React.useState(false);
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();

  const initialValues: FormValues = React.useMemo(() => ({
    endDate: assignment.endDate ? formatInputDate(new Date(assignment.endDate)) : '',
  }), [assignment.endDate]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onSubmit = React.useCallback(async ({ endDate }: FormValues) => {
    setIsSaving(true);

    try {
      const assignmentToUpdate: Assignment = {
        ...assignment,
        endDate: endDate ? new Date(endDate).toISOString() : undefined,
      };

      const updatedAssignment = await updateAssignment(assignmentToUpdate);

      setIsSaving(false);
      modal.close();
      addSuccess(UPDATE_ASSIGNMENT_END_DATE_SUCCESS_MESSAGE);

      onSuccess?.(updatedAssignment);
    } catch (_) {
      setIsSaving(false);
      addError(UPDATE_ASSIGNMENT_END_DATE_ERROR_MESSAGE);
    }
  }, [assignment, modal, addSuccess, onSuccess, addError]);

  return (
    <>
      {children
        ? children({ editEndDate: modal.open, isSaving })
        : (
          <Action
            aria-label="Aggiorna data di scadenza del mandato"
            title="Aggiorna data di scadenza del mandato"
            label="Aggiorna data di scadenza"
            size="S"
            onClick={() => modal.open()}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Aggiorna la data di scadenza del mandato"
          aria-label="Aggiorna la data di scadenza del mandato"
          footer={(
            <Action
              label="Aggiorna"
              color="primary"
              emphasis="high"
              loading={isSaving}
              onClick={submitForm}
            />
          )}
        >
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            loading={isSaving}
            innerRef={formRef}
          >
            <Form.Item>
              <Form.Input
                type="date"
                label="Data di scadenza del mandato"
                aria-label="Data di scadenza del mandato"
                name="endDate"
              />
            </Form.Item>
          </Form>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpdateAssignmentEndDateAction;
