import {
  Form, FormProps, FormSubmitType,
} from '@doveit/bricks';
import React from 'react';
import { BooleanString } from '../../../../../utils/boolean-string/booleanString';
import { INPUT_PLACEHOLDERS } from '../../../../../constants';
import { formatInputDate } from '../../../../../utils/form';

export interface AssignmentWizardWithdrawalFormModel {
  rightOfWithdrawal: BooleanString,
  startDate: string,
  cancellationFee: string,
  notes: string,
}

export interface AssignmentWizardWithdrawalFormProps {
  initialValues?: Partial<AssignmentWizardWithdrawalFormModel>,
  loading?: FormProps<AssignmentWizardWithdrawalFormModel>['loading'],
  disabled?: FormProps<AssignmentWizardWithdrawalFormModel>['disabled'],
  onSubmit: FormSubmitType<AssignmentWizardWithdrawalFormModel>,
  formRef: FormProps<AssignmentWizardWithdrawalFormModel>['innerRef'],
}

const DEFAULT_INITIAL_VALUES: AssignmentWizardWithdrawalFormModel = {
  rightOfWithdrawal: '',
  startDate: '',
  cancellationFee: '',
  notes: '',
};

const AssignmentWizardWithdrawalForm: React.FC<AssignmentWizardWithdrawalFormProps> = ({
  initialValues = {},
  formRef,
  ...rest
}) => {
  const mergedInitialValues: AssignmentWizardWithdrawalFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      innerRef={formRef}
      enableReinitialize
      {...rest}
    >
      {({ values, setFieldValue }) => {
        const onRightOfWithdrawalFieldChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
          if (target.value === 'true') {
            setFieldValue('startDate', '');
          }
        };

        return (
          <>
            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.YesOrNotButtons
                name="rightOfWithdrawal"
                label="Diritto di recesso"
                aria-label="Campo per indicare il diritto di recesso"
                onChange={onRightOfWithdrawalFieldChange}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.Input
                name="startDate"
                label="Data di decorrenza"
                type="date"
                aria-label="Campo per inserire la data di decorrenza"
                disabled={values.rightOfWithdrawal !== 'false'}
                min={formatInputDate(new Date())}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.DecimalPriceField
                name="cancellationFee"
                label="Penale"
                aria-label="Campo per inserire l'importo della penale'"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_ASSIGNMENT_CANCELLATION_FEE}
              />
            </Form.Item>

            <Form.Item>
              <Form.TextArea
                name="notes"
                label="Note"
                aria-label="Campo per inserire eventuali note"
              />
            </Form.Item>
          </>
        );
      }}
    </Form>
  );
};

export default AssignmentWizardWithdrawalForm;
