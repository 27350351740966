import { Form, FormHandlers, FormProps } from '@doveit/bricks';
import React from 'react';
import upsertPropertySpaceWithSizeValidationSchema from './UpsertPropertySpaceWithSizeForm.schema';

export interface UpsertPropertySpaceWithSizeFormModel {
  size: string;
}

export interface UpsertPropertySpaceWithSizeFormProps {
  isSizeRequired?: boolean;
  initialValues?: Partial<UpsertPropertySpaceWithSizeFormModel>;
  disabled?: boolean;
  innerRef?: React.MutableRefObject<FormHandlers>,
  loading?: FormProps<UpsertPropertySpaceWithSizeFormModel>['loading'],
  onSubmit: FormProps<UpsertPropertySpaceWithSizeFormModel>['onSubmit'],
}

const UPSERT_PROPERTY_SPACE_WITH_SIZE_FORM_DEFAULT_VALUES = {
  size: '',
};

const UpsertPropertySpaceWithSizeForm: React.FC<UpsertPropertySpaceWithSizeFormProps> = ({
  initialValues,
  isSizeRequired = false,
  ...rest
}) => {
  const mergedInitialValues: UpsertPropertySpaceWithSizeFormModel = {
    ...UPSERT_PROPERTY_SPACE_WITH_SIZE_FORM_DEFAULT_VALUES,
    ...(initialValues || {}),
  };

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={upsertPropertySpaceWithSizeValidationSchema(isSizeRequired)}
      {...rest}
    >
      <Form.Item>
        <Form.Input
          size="S"
          name="size"
          type="number"
          min="0"
          aria-label="Campo per specificare la dimensione dello spazio"
          placeholder="Es. 200"
          prefixElement="m²"
          hasErrorTooltip
          required={isSizeRequired}
        />
      </Form.Item>
    </Form>
  );
};

export default UpsertPropertySpaceWithSizeForm;
