import React from 'react';
import {
  Action, ActionIcon, Card, ConfirmModal, FormHandlers, Grid, HStack, ICON_CHECK, ICON_CLOSE, ICON_PENCIL_OUTLINE, ICON_TRASH_CAN_OUTLINE, Message, Portal, Spacing, Stack, Text,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import { Property, Terrace } from '../../../../providers/api/dtos';
import useIsDevice from '../../../../hooks/use-is-device/useIsDevice';
import SimpleTableSkeleton from '../../../../components/simple-table/SimpleTable.skeleton';
import SimpleTable from '../../../../components/simple-table/SimpleTable';
import { NO_VALUE_SYMBOL } from '../../../utils';
import Divider from '../../../../components/divider/Divider';
import useTerraces from '../../../hooks/use-terraces/useTerraces';
import UpsertPropertySpaceWithSizeForm, { UpsertPropertySpaceWithSizeFormModel } from '../../../spaces/components/upsert-property-space-with-size-form/UpsertPropertySpaceWithSizeForm';
import { createTerrace, deleteTerrace, updateTerrace } from '../../../../providers/api/terrace/terraceProvider';

export interface ManagePropertyTerracesProps {
  propertyId: NonNullable<Property['id']>,
  onCreate?: (createdTerrace: Terrace) => void,
  onUpdate?: (updatedTerrace: Terrace) => void,
  onDelete?: (deletedTerraceId: NonNullable<Terrace['id']>) => void,
}

const ManagePropertyTerraces: React.FC<ManagePropertyTerracesProps> = ({
  propertyId,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const isMobile = useIsDevice('mobile');
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const [isEditing, setIsEditing] = React.useState<{ terrace: Terrace } | false>(false);
  const createTerraceFormRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const updateTerraceFormRefs = React.useRef<{ [key: number]: React.MutableRefObject<any> }>({});
  const deleteTerraceModal = useModal<{ terrace: Terrace }>();

  const {
    data: terraces,
    isLoading: areTerracesLoading,
    error: terracesError,
    mutate: mutateTerraces,
  } = useTerraces(propertyId);

  const addTerrace = React.useCallback(async ({ size }: UpsertPropertySpaceWithSizeFormModel) => {
    try {
      setIsSaving(true);

      const terraceToCreate: Terrace = {
        id: null,
        propertyId,
        size: size ? parseFloat(size) : null,
      };

      const createdTerrace = await createTerrace(terraceToCreate);

      setIsSaving(false);
      addSuccess('Il terrazzo è stato aggiunto con successo.');

      onCreate?.(createdTerrace);
      await mutateTerraces();
    } catch (error) {
      setIsSaving(false);
      addError('Non è stato possibile aggiungere il terrazzo.');
    }
  }, [addError, addSuccess, mutateTerraces, onCreate, propertyId]);

  const editTerrace = React.useCallback(async ({ size }: UpsertPropertySpaceWithSizeFormModel) => {
    if (!isEditing) return;

    try {
      setIsSaving(true);

      const terraceToUpdate: Terrace = {
        ...isEditing.terrace,
        size: size ? parseFloat(size) : null,
      };

      const updatedTerrace = await updateTerrace(terraceToUpdate);

      setIsSaving(false);
      addSuccess('Il terrazzo è stato modificato con successo.');
      setIsEditing(false);

      onUpdate?.(updatedTerrace);
      await mutateTerraces();
    } catch (_) {
      setIsSaving(false);
      addError('Non è stato possibile modificare il terrazzo.');
    }
  }, [addError, addSuccess, isEditing, mutateTerraces, onUpdate]);

  const removeTerrace = React.useCallback(async () => {
    if (!deleteTerraceModal.data?.terrace) return;

    try {
      setIsSaving(true);

      await deleteTerrace(deleteTerraceModal.data.terrace.id!);

      setIsSaving(false);
      addSuccess('Il terrazzo è stato rimosso con successo.');
      deleteTerraceModal.close();

      onDelete?.(deleteTerraceModal.data.terrace.id!);
      await mutateTerraces();
    } catch (error) {
      setIsSaving(false);
      addError('Non è stato possibile rimuovere il terrazzo.');
    }
  }, [addError, addSuccess, deleteTerraceModal, mutateTerraces, onDelete]);

  const getUpdateTerraceFormRef = React.useCallback((id: number) => {
    if (!updateTerraceFormRefs.current[id]) {
      updateTerraceFormRefs.current[id] = React.createRef();
    }
    return updateTerraceFormRefs.current[id];
  }, []);

  const submitCreateTerraceForm = React.useCallback(() => {
    createTerraceFormRef.current?.handleSubmit();
  }, []);

  const submitUpdateTerraceForm = React.useCallback((id: number) => {
    if (updateTerraceFormRefs.current[id]) {
      updateTerraceFormRefs.current[id].current.handleSubmit();
    }
  }, []);

  if (areTerracesLoading && !isMobile) {
    return <SimpleTableSkeleton aria-label="Terrazzi in caricamento" />;
  }

  if (terracesError) {
    return (
      <Message
        type="critical"
        message="Non è stato possibile caricare i terrazzi."
      />
    );
  }

  return (
    <>
      <Stack gap={200} data-testid="manage-property-terraces">
        {!isMobile && (
          <SimpleTable aria-label="Tabella terrazzi">
            <SimpleTable.Body>
              {terraces?.length === 0 && (
                <SimpleTable.Row>
                  <SimpleTable.Cell>
                    <Message
                      type="neutral"
                      message="Nessun terrazzo inserito."
                    />
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              )}

              {terraces?.map((terrace) => (
                <SimpleTable.Row
                  key={terrace.id}
                  aria-label={`Informazioni terrazzo ${terrace.id}`}
                >
                  {(isEditing && isEditing.terrace.id === terrace.id) ? (
                    <>
                      <SimpleTable.Cell colSpan={3}>
                        <UpsertPropertySpaceWithSizeForm
                          loading={isSaving}
                          initialValues={{ size: terrace.size?.toString() }}
                          innerRef={getUpdateTerraceFormRef(terrace.id!)}
                          onSubmit={editTerrace}
                        />
                      </SimpleTable.Cell>
                      <SimpleTable.Cell align="right">
                        <HStack>
                          <ActionIcon
                            size="S"
                            color="primary"
                            icon={{ path: ICON_CHECK }}
                            label="Conferma modifiche"
                            aria-label="Conferma modifiche"
                            onClick={() => submitUpdateTerraceForm(terrace.id!)}
                          />
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_CLOSE }}
                            label="Annulla modifiche"
                            aria-label="Annulla modifiche"
                            onClick={() => setIsEditing(false)}
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </>
                  ) : (
                    <>
                      <SimpleTable.Cell colSpan={3}>
                        <Text.BodySmall>
                          {terrace.size ? formatSquareMeters(terrace.size) : NO_VALUE_SYMBOL}
                        </Text.BodySmall>
                      </SimpleTable.Cell>
                      <SimpleTable.Cell align="right">
                        <HStack>
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_PENCIL_OUTLINE }}
                            label="Modifica terrazzo"
                            aria-label="Modifica terrazzo"
                            onClick={() => setIsEditing({ terrace })}
                          />
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                            label="Rimuovi terrazzo"
                            aria-label="Rimuovi terrazzo"
                            onClick={() => deleteTerraceModal.open({ terrace })}
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </>
                  )}
                </SimpleTable.Row>
              ))}
              <SimpleTable.Row aria-label="Aggiungi informazioni nuovo terrazzo">
                <SimpleTable.Cell colSpan={3}>
                  <UpsertPropertySpaceWithSizeForm
                    loading={isSaving}
                    innerRef={createTerraceFormRef}
                    onSubmit={(values, actions) => {
                      actions.resetForm();

                      addTerrace(values);
                    }}
                  />
                </SimpleTable.Cell>
                <SimpleTable.Cell align="right">
                  <Action
                    size="S"
                    label="Aggiungi"
                    aria-label="Aggiungi terrazzo"
                    onClick={submitCreateTerraceForm}
                  />
                </SimpleTable.Cell>
              </SimpleTable.Row>
            </SimpleTable.Body>
          </SimpleTable>
        )}
        {isMobile && (
          <Stack
            gap={150}
            aria-label="Lista card terrazzi"
          >
            {terraces?.length === 0 && (
              <Spacing margin={[0, 0, 400]}>
                <Message
                  type="neutral"
                  message="Nessun terrazzo inserito."
                />
              </Spacing>
            )}
            {terraces?.map((terrace, idx) => (
              <Card
                key={terrace.id}
                aria-label={`Informazioni terrazzo ${terrace.id}`}
              >
                <Card.Header
                  title={`Terrazzo (${idx + 1})`}
                  primaryActions={
                    !(isEditing && isEditing.terrace.id === terrace.id) ? [
                      <ActionIcon
                        size="S"
                        icon={{ path: ICON_PENCIL_OUTLINE }}
                        label="Modifica terrazzo"
                        aria-label="Modifica terrazzo"
                        onClick={() => setIsEditing({ terrace })}
                      />,
                      <ActionIcon
                        size="S"
                        icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                        label="Rimuovi terrazzo"
                        aria-label="Rimuovi terrazzo"
                        onClick={() => deleteTerraceModal.open({ terrace })}
                      />,
                    ] : undefined
                  }
                />
                <Card.Content>
                  {(isEditing && isEditing.terrace.id === terrace.id) ? (
                    <>
                      <UpsertPropertySpaceWithSizeForm
                        loading={isSaving}
                        initialValues={{ size: terrace.size?.toString() }}
                        innerRef={getUpdateTerraceFormRef(terrace.id!)}
                        onSubmit={editTerrace}
                      />
                      <Divider />
                      <Grid gutter={150}>
                        <Grid.Unit size={1 / 2}>
                          <Action
                            expanded
                            size="S"
                            color="primary"
                            label="Salva"
                            aria-label="Conferma modifiche"
                            onClick={() => submitUpdateTerraceForm(terrace.id!)}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={1 / 2}>
                          <Action
                            expanded
                            size="S"
                            label="Annulla"
                            aria-label="Annulla modifiche"
                            onClick={() => setIsEditing(false)}
                          />
                        </Grid.Unit>
                      </Grid>
                    </>
                  ) : (
                    <Text.BodySmall>
                      {terrace.size ? formatSquareMeters(terrace.size) : NO_VALUE_SYMBOL}
                    </Text.BodySmall>
                  )}
                </Card.Content>
              </Card>
            ))}
            <Card aria-label="Aggiungi informazioni nuovo terrazzo">
              <Card.Header title="Aggiungi nuovo terrazzo" />
              <Card.Content>
                <>
                  <UpsertPropertySpaceWithSizeForm
                    loading={isSaving}
                    innerRef={createTerraceFormRef}
                    onSubmit={(values, actions) => {
                      actions.resetForm();

                      addTerrace(values);
                    }}
                  />
                  <Divider />
                  <Action
                    expanded
                    size="S"
                    label="Aggiungi"
                    aria-label="Aggiungi terrazzo"
                    onClick={submitCreateTerraceForm}
                  />
                </>
              </Card.Content>
            </Card>
          </Stack>
        )}
      </Stack>
      <Portal>
        {deleteTerraceModal.data?.terrace && (
          <ConfirmModal
            variant="critical"
            isOpen={deleteTerraceModal.isOpen}
            title="Conferma rimozione terrazzo"
            aria-label="Conferma la rimozione del terrazzo"
            onConfirm={removeTerrace}
            onAbort={deleteTerraceModal.close}
          >
            Confermando l&apos;operazione, il terrazzo sarà rimosso.
          </ConfirmModal>
        )}
      </Portal>
    </>
  );
};

export default ManagePropertyTerraces;
