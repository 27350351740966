import React from 'react';
import { Form, FormHandlers, FormProps } from '@doveit/bricks';
import { RoomType } from '../../../../domain/types';
import { asSelectOptions } from '../../../../select-options/utils/asSelectOptions';
import { kitchenTypeLabels, propertyMainRoomLabels } from '../../../../labels';
import { upsertPropertyRoomValidationSchema } from './UpsertPropertyRoomForm.schema';

export interface UpsertPropertyRoomFormModel {
  type: RoomType | '',
  size: string,
  floor: string,
}

export interface UpsertPropertyRoomFormProps {
  initialValues?: Partial<UpsertPropertyRoomFormModel>,
  innerRef?: React.MutableRefObject<FormHandlers>,
  loading?: FormProps<UpsertPropertyRoomFormModel>['loading'],
  onSubmit: FormProps<UpsertPropertyRoomFormModel>['onSubmit'],
}

export const UPSERT_PROPERTY_ROOM_FORM_DEFAULT_VALUES: UpsertPropertyRoomFormModel = {
  type: '',
  size: '',
  floor: '',
};

const UpsertPropertyRoomForm: React.FC<UpsertPropertyRoomFormProps> = ({
  initialValues,
  ...rest
}) => {
  const mergedInitialValues: UpsertPropertyRoomFormModel = React.useMemo(() => ({
    ...UPSERT_PROPERTY_ROOM_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      validationSchema={upsertPropertyRoomValidationSchema}
      initialValues={mergedInitialValues}
      {...rest}
    >
      <Form.Item size={{ MD: 2 / 4 }}>
        <Form.Select
          hasErrorTooltip
          size="S"
          name="type"
          aria-label="Campo per specificare la tipologia della stanza"
          options={asSelectOptions({
            ...kitchenTypeLabels,
            ...propertyMainRoomLabels,
          }, {
            value: '',
            label: 'Seleziona tipologia stanza',
            disabled: true,
          })}
        />
      </Form.Item>

      <Form.Item size={{ XS: 1 / 2, MD: 1 / 4 }}>
        <Form.Input
          size="S"
          name="size"
          aria-label="Campo per specificare la dimensione della stanza"
          placeholder="Es. 200"
          prefixElement="m²"
          type="number"
          min="0"
        />
      </Form.Item>

      <Form.Item size={{ XS: 1 / 2, MD: 1 / 4 }}>
        <Form.Input
          hasErrorTooltip
          size="S"
          name="floor"
          aria-label="Campo per specificare il piano in cui si trova la stanza"
          placeholder="Es. 1"
          prefixElement="Piano"
          type="number"
        />
      </Form.Item>
    </Form>
  );
};

export default UpsertPropertyRoomForm;
