import {
  FormAutoCompleteV2Props, Form, ICON_MAGNIFY, AutoCompleteV2OnSuggestionSelected,
} from '@doveit/bricks';
import React, { useCallback, FunctionComponent } from 'react';
import { AgentStatus } from '../../domain/types';
import { searchAgents } from '../../providers/api/agent/agentProvider';
import { Agent } from '../../providers/api/dtos';
import { JobTitle } from '../../domain/types/jobTitle';
import { SortOrder } from '../../providers/pagination';
import { getAgentName } from '../../agent/utils/utils';

export type AgentAutoCompleteProps = Omit<FormAutoCompleteV2Props<Agent>, 'suggestions' | 'printSuggestion'> & {
  status?: AgentStatus,
  startFrom?: string | number,
  self?: Agent,
  maxSuggestions?: number,
  jobTitles?: JobTitle[]
  excludeAgentsByEmail?: string[],
  coordinatedBy?: string,
  onSuggestionSelected?: AutoCompleteV2OnSuggestionSelected<Agent>
};

const AgentAutoComplete: FunctionComponent<AgentAutoCompleteProps> = ({
  status,
  startFrom,
  maxSuggestions,
  jobTitles = [],
  excludeAgentsByEmail = [],
  coordinatedBy,
  onSuggestionSelected,
  ...rest
}) => {
  const fetchSuggestions = useCallback(async (search: string) => {
    const { content } = await searchAgents({
      q: search,
      active: status,
      startFrom,
      jobTitle: jobTitles,
      exclude: excludeAgentsByEmail,
      coordinatedBy,
    },
    {
      size: maxSuggestions,
      sort: {
        name: SortOrder.ASC,
        surname: SortOrder.ASC,
      },
    });

    return content;
  }, [status, startFrom, jobTitles, excludeAgentsByEmail, coordinatedBy, maxSuggestions]);

  const printSuggestion = useCallback((agent: Agent) => getAgentName(agent)!, []);

  return (
    <Form.AutoCompleteV2
      {...rest}
      aria-label="Campo per inserimento agente"
      icon={ICON_MAGNIFY}
      suggestions={fetchSuggestions}
      printSuggestion={printSuggestion}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default AgentAutoComplete;
