import React, { MutableRefObject } from 'react';
import {
  Action, FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agency } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { updateAgency } from '../../../providers/api/agency/agencyProvider';
import EditAgencyDescriptionForm, { EditAgencyDescriptionFormModel } from '../../components/edit-agency-description-form/EditAgencyDescriptionForm';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isLoading: boolean,
}

export interface EditAgencyDescriptionActionProps {
  agency: Agency,
  modalTitle?: string,
  onSuccess?: (updatedAgency: Agency) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_AGENCY_DESCRIPTION_SUCCESS_MESSAGE = "La descrizione dell'agenzia è stata modificata con successo";
export const EDIT_AGENCY_DESCRIPTION_ERROR_MESSAGE = "Non è stato possibile modificare la descrizione dell'agenzia";

const EditAgencyDescriptionAction: React.FC<EditAgencyDescriptionActionProps> = (props) => {
  const {
    agency, onSuccess, modalTitle = 'Modifica descrizione', children,
  } = props;

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isLoading, setIsLoading] = React.useState(false);

  const formRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const openEditModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const onSubmit = React.useCallback(async (formValues: EditAgencyDescriptionFormModel) => {
    try {
      setIsLoading(true);

      const agencyToUpdate: Agency = {
        ...agency,
        description: formValues.description,
      };

      const updatedAgency = await updateAgency(agency.id!, agencyToUpdate);

      setIsLoading(false);
      addSuccess(EDIT_AGENCY_DESCRIPTION_SUCCESS_MESSAGE);
      modal.close();

      if (onSuccess) {
        onSuccess(updatedAgency);
      }
    } catch (error) {
      setIsLoading(false);
      addError(EDIT_AGENCY_DESCRIPTION_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agency, modal, onSuccess]);

  return (
    <>
      {children
        ? children({ openEditModal, isLoading })
        : (
          <Action
            aria-label="Modifica la descrizione dell'agenzia"
            label="Modifica"
            onClick={openEditModal}
            size="S"
            loading={isLoading}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title={modalTitle}
          aria-label="Modale per modificare la descrizione dell'agenzia"
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per salvare la descrizione dell'agenzia"
              onClick={submitForm}
            />
          )}
        >
          <EditAgencyDescriptionForm
            innerRef={formRef}
            initialValues={agency}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAgencyDescriptionAction;
