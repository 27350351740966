import { FontWeight, ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';
import { DocumentStatusColor } from './types';

export const base: ThemedFunctor = ({ theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.remSize[50]};
  color: ${theme.color.foreground.neutral.default.low};
  font-weight: ${FontWeight.MEDIUM};
  max-width: inherit;  
  &:hover {
    color: ${theme.color.foreground.neutral.hover.low};
  }
`;

export const label: ThemedFunctor = () => css`
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const icon: ThemedFunctor = ({
  theme,
  $color,
}) => {
  const color = $color as DocumentStatusColor;

  return css`
    color: ${theme.color.foreground[color].default.low};
    display: flex;
    align-items: center;
  `;
};
