import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const inTrialCheckbox: ThemedFunctor = () => css`
  display: flex;
  align-items: flex-end;

  > * {
    width: 100%;
  }
`;
