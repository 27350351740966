import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Action, ActionIconDropdown, CardSkeleton, Dropdown, FontWeight, HStack, Message, Spacing, Stack, Text,
} from '@doveit/bricks';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { raise } from '../../../utils';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import usePagination from '../../../hooks/use-pagination/usePagination';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import { DEFAULT_PAGINATION_SIZE } from '../../../utils/querystring/querystring';
import useRumor from '../../hooks/use-rumor/useRumor';
import MatchingSearchCriteriaCard from '../../../matching-search-criteria/components/matching-search-criteria-card/MatchingSearchCriteriaCard';
import { useRumorMatchingSearchCriteria } from '../../hooks/use-rumor-matching-search-criteria/useRumorMatchingSearchCriteria';
import ViewRumorPopoverPreview from '../../containers/view-rumor-popover-preview/ViewRumorPopoverPreview';
import RumorAvatar from '../../components/rumor-avatar/RumorAvatar';
import { ForbiddenErrorPage } from '../../../containers/require-auth/RequireAuth';

export const NO_MATCHING_SEARCH_CRITERIA_MESSAGE = 'Non sono presenti ricerche correlate alla notizia.';
export const LOAD_RUMOR_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni della notizia.';
export const LOAD_MATCHING_SEARCH_CRITERIA_ERROR_MESSAGE = 'Non è stato possibile caricare le ricerche correlate alla notizia.';

const ViewRumorMatchingPage: React.FC = () => {
  const params = useParams();
  const isMobile = useIsDevice('mobile');

  const rumorIdParam = params.id ?? raise('missing rumor id');
  const rumorId = parseInt(rumorIdParam, 10);
  const {
    data: rumor,
    isLoading: isRumorLoading,
    error: rumorError,
  } = useRumor(rumorId);

  const { userIsAdmin } = useRBAC();
  const userIsRumorAgentOrManager = useCurrentAgentIsSameAgentOrManager(rumor?.agentId);

  const { page, goToPage } = usePagination();

  const {
    data: paginatedSearchCriteria,
    error: searchCriteriaError,
    isLoading: areSearchCriteriaLoading,
  } = useRumorMatchingSearchCriteria(
    rumorId,
    {
      page,
      size: DEFAULT_PAGINATION_SIZE,
    },
  );

  const canView = React.useMemo(
    () => userIsAdmin || userIsRumorAgentOrManager,
    [userIsAdmin, userIsRumorAgentOrManager],
  );

  const goToRumorPage = React.useCallback(() => {
    goToDetailPage(rumorId, ReferenceType.RUMOR);
  }, [rumorId]);

  const goToSearchCriteriaPage = React.useCallback((searchCriteriaId: number) => {
    goToDetailPage(searchCriteriaId, ReferenceType.SEARCH_CRITERIA);
  }, []);

  if (!canView) {
    return <ForbiddenErrorPage />;
  }

  if (rumorError) {
    return <GenericErrorPage title={LOAD_RUMOR_ERROR_MESSAGE} />;
  }

  if (!rumor || isRumorLoading) {
    return null;
  }

  return (
    <GenericPageLayout aria-label="Matching notizia">
      <GenericPageLayout.Bar>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <HStack
            gap={200}
            wrap="nowrap"
            aria-label="Anteprima"
          >
            <ViewRumorPopoverPreview
              rumorId={rumor.id!}
              trigger={(
                <HStack gap={100} wrap="nowrap">
                  <RumorAvatar size="S" />
                  <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                    {rumor.propertyAddress || 'Indirizzo non disponibile'}
                  </Text.BodySmall>
                </HStack>
              )}
            />
          </HStack>

          <div aria-label="Azioni">
            {!isMobile && (
              <Action
                label="Vedi notizia"
                aria-label="Vai alla pagina di dettaglio della notizia"
                onClick={goToRumorPage}
                emphasis="high"
                size="M"
              />
            )}

            {isMobile && (
              <ActionIconDropdown
                label="Azioni sulla notizia"
                aria-label="Dropdown azioni sulla notizia"
              >
                <Dropdown.Option
                  label="Vedi notizia"
                  aria-label="Vai alla pagina di dettaglio della notizia"
                  onClick={goToRumorPage}
                />
              </ActionIconDropdown>
            )}
          </div>
        </HStack>
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <Text.H4>
          Ricerche attive ({paginatedSearchCriteria?.totalElements || 0})
        </Text.H4>

        <Spacing margin={[300, 0, 0]}>
          {areSearchCriteriaLoading && !isMobile && (
            <SimpleTableSkeleton aria-label="Ricerche in caricamento" />
          )}
          {areSearchCriteriaLoading && isMobile && (
            <Stack aria-label="Ricerche in caricamento">
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </Stack>
          )}
          {searchCriteriaError && (
            <Message
              message={LOAD_MATCHING_SEARCH_CRITERIA_ERROR_MESSAGE}
              type="critical"
              boxed
            />
          )}
          {(!areSearchCriteriaLoading && (paginatedSearchCriteria && paginatedSearchCriteria.totalElements > 0)) && (
            <PaginatedList
              {...paginatedSearchCriteria}
              aria-label="Ricerche"
              emptyResultMessage={NO_MATCHING_SEARCH_CRITERIA_MESSAGE}
              goToPage={goToPage}
              hideResultCount
            >
              {paginatedSearchCriteria.content.map((item) => (
                <MatchingSearchCriteriaCard
                  key={item.contactId}
                  matchingSearchCriteria={item}
                  propertyType={rumor.propertyType}
                  onClickVisitAction={goToSearchCriteriaPage}
                />
              ))}
            </PaginatedList>
          )}
        </Spacing>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default ViewRumorMatchingPage;
