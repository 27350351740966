/* eslint-disable react/no-unknown-property */
import { Skeleton } from '@doveit/bricks';
import React, { FunctionComponent } from 'react';
import * as styles from './ApplicationNotificationCard.style';

const ApplicationNotificationCardSkeleton: FunctionComponent = () => (
  <div css={styles.base}>
    <div css={styles.avatar} $skeleton />
    <div css={styles.info}>
      <div css={styles.header}>
        <div css={styles.title}>
          <Skeleton width="10rem" />
        </div>
      </div>
      <div css={styles.message}>
        <div>
          <Skeleton
            width="90%"
            count={2}
          />
        </div>
      </div>
    </div>
  </div>
);

export default ApplicationNotificationCardSkeleton;
