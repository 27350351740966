import { createContact, updateContact } from '../../providers/api/contact/contactProvider';
import { Contact } from '../../providers/api/dtos/contact';
import { SearchContactFormModel } from '../components/search-contact-form/SearchContactForm';

export type ContactFormValues = Pick<SearchContactFormModel, 'name' | 'phoneNumber' | 'email'>;

export default async function createOrUpdateContact(values?: ContactFormValues, existingContact?: Contact): Promise<Contact> {
  if (!values && !existingContact) {
    throw new Error('One of values or existingContact parameter is mandatory.');
  }

  if (values && !existingContact) {
    const contact = await createContact({
      name: values?.name?.trim() || undefined,
      email: values?.email?.trim() || undefined,
      phoneNumber: values?.phoneNumber || undefined,
      id: undefined,
    });

    return contact;
  }

  if (existingContact?.id && values) {
    const contact = await updateContact(existingContact.id!, {
      ...existingContact,
      name: values?.name?.trim() || existingContact?.name,
      email: values?.email?.trim() || existingContact?.email,
      phoneNumber: values?.phoneNumber || existingContact?.phoneNumber,
    });

    return contact;
  }

  return Promise.resolve(existingContact!);
}
