export enum ExtraPackageDoveIt {
  EXTRA_VISIBILITY = 'EXTRA_VISIBILITY',
  INTERIOR_RESTYLING = 'INTERIOR_RESTYLING',
  PRESTIGE = 'PRESTIGE',
  DRONE = 'DRONE',
  PHOTO_PLUS = 'PHOTO_PLUS',
  PERSONALIZED_MARKETING = 'PERSONALIZED_MARKETING',
  PHOTO_PRO = 'PHOTO_PRO',
}

export enum ExtraPackageSPF {
  VERIFICATION_SERVICE = 'VERIFICATION_SERVICE',
  DOCUMENTS_ACCESS = 'DOCUMENTS_ACCESS',
  APE = 'APE',
}

export type ExtraPackageType = ExtraPackageDoveIt | ExtraPackageSPF;

export enum ExtraPackageProvider {
  DOVE_IT = 'DOVE_IT',
  SPF = 'SPF',
}

export enum ExtraPackagePriceType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}
