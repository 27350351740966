export const logo = {
  width: 105,
};

export const logoWrapper = {
  alignItems: 'center',
  display: 'flex',
  width: '100%',
};

export const title = {
  fontSize: 14,
  marginTop: 20,
};

export const price = {
  fontSize: 14,
  fontWeight: 'bold',
  marginTop: 4,
};
