import {
  ActionIcon,
  ICON_ARCHIVE_OUTLINE,
  Message,
  Portal,
  Spacing,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import React from 'react';
import { formatDate } from '@doveit/hammer';
import EditAppointmentStatusActionBar from '../../../appointment/containers/edit-appointment-status-action-bar/EditAppointmentStatusActionBar';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { AppointmentStatus, ProspectStatus } from '../../../domain/types';
import { Appointment, Prospect } from '../../../providers/api/dtos';
import { updateProspect } from '../../../providers/api/prospect/prospectProvider';
import ArchiveProspectForm, { ArchiveProspectFormModel } from '../../components/archive-prospect-form/ArchiveProspectForm';
import useRBAC from '../../../hooks/use-rbac/useRBAC';

interface ChildFnProps {
  openArchiveProspectModal: VoidFunction,
  disabled?: boolean,
}

export interface ArchiveProspectActionProps {
  prospect: Prospect,
  appointments?: Appointment[],
  disabled?: boolean,
  children?: ({ openArchiveProspectModal, disabled }: ChildFnProps) => React.ReactElement,
  onAppointmentUpdate?: (appointment: Appointment) => void,
  onSuccess?: (prospect: Prospect) => void,
}

export const ARCHIVE_PROSPECT_SUCCESS_MESSAGE = 'Valutazione archiviata con successo';
export const ARCHIVE_PROSPECT_ERROR_MESSAGE = 'Non è stato possibile archiviare la valutazione';

const ArchiveProspectAction: React.FC<ArchiveProspectActionProps> = ({
  prospect,
  appointments,
  disabled,
  children,
  onAppointmentUpdate,
  onSuccess,
}) => {
  const archiveModal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);
  const { user, mainUserRole } = useRBAC();

  const initialValues: ArchiveProspectFormModel = React.useMemo(() => ({
    agentEvaluation: prospect.agentEvaluation?.toString(),
    ownerEvaluation: prospect.ownerEvaluation?.toString(),
    willingness: prospect.willingness.toString(),
    sellability: prospect.sellability.toString(),
  }), [prospect.agentEvaluation, prospect.ownerEvaluation, prospect.sellability, prospect.willingness]);

  const pendingAppointment = React.useMemo(() => appointments?.find(({ status }) => status === AppointmentStatus.TODO),
    [appointments]);

  const onAppointmentStatusUpdateSuccess = React.useCallback((appointment: Appointment, _: AppointmentStatus) => {
    if (onAppointmentUpdate) {
      onAppointmentUpdate(appointment);
    }
  }, [onAppointmentUpdate]);

  const onSubmit = React.useCallback(async (values: ArchiveProspectFormModel) => {
    setIsSaving(true);

    try {
      const {
        notes: alreadyExistingNotes = [],
      } = prospect;

      const trimmedNote = values.notes?.trim();

      const newNote = trimmedNote ? {
        author: user.name,
        role: mainUserRole,
        date: new Date().toISOString(),
        text: trimmedNote,
      } : undefined;

      const payload: Prospect = {
        ...prospect,
        status: values.status as ProspectStatus,
        willingness: parseInt(values.willingness, 10),
        sellability: parseInt(values.sellability, 10),
        agentEvaluation: values.agentEvaluation
          ? parseInt(values.agentEvaluation, 10)
          : undefined,
        ownerEvaluation: values.ownerEvaluation
          ? parseInt(values.ownerEvaluation, 10)
          : undefined,
        notes: newNote ? [...alreadyExistingNotes, newNote] : alreadyExistingNotes,
      };

      const updatedProspect = await updateProspect(prospect.id!, payload);

      setIsSaving(false);
      archiveModal.close();
      addSuccess(ARCHIVE_PROSPECT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedProspect);
      }
    } catch (err) {
      setIsSaving(false);
      addError(ARCHIVE_PROSPECT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, archiveModal, mainUserRole, onSuccess, prospect, user]);

  if (prospect.status !== ProspectStatus.IN_PROGRESS) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          openArchiveProspectModal: archiveModal.open,
          disabled,
        }) : (
          <ActionIcon
            label="Archivia"
            icon={{ path: ICON_ARCHIVE_OUTLINE }}
            color="critical"
            disabled={disabled}
            onClick={archiveModal.open}
          />
        )}
      <Portal>
        <SimpleModal
          {...archiveModal}
          title="Archivia valutazione"
        >
          <Spacing margin={[0, 0, 400]}>
            {pendingAppointment && (
              <Message
                type="warning"
                message={`Definisci esito appuntamento del <strong>${formatDate(new Date(pendingAppointment.startDate), { time: true })}</strong>`}
                actions={[
                  <EditAppointmentStatusActionBar
                    appointment={pendingAppointment}
                    onSuccess={onAppointmentStatusUpdateSuccess}
                  />,
                ]}
              />
            )}
          </Spacing>
          <ArchiveProspectForm
            initialValues={initialValues}
            loading={isSaving}
            disabled={!!pendingAppointment}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ArchiveProspectAction;
