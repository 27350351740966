import { Avatar, AvatarProps, ICON_CURRENCY_EUR } from '@doveit/bricks';
import React from 'react';

export type ProspectAvatarProps = Pick<AvatarProps, 'size' | 'placeholder' | 'aria-label'>;

export const ProspectAvatar: React.FC<ProspectAvatarProps> = (props) => (
  <Avatar
    {...props}
    placeholder={ICON_CURRENCY_EUR}
  />
);

export default ProspectAvatar;
