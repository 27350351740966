import {
  ActionProps, BadgeProps,
} from '@doveit/bricks';
import { MembershipStatus } from '../../../domain/types';
import { AgentStatusAction } from './types';

export const AGENT_NAME_PLACEHOLDER = '__AGENT_NAME__';
export const AGENT_STATUS_UPDATED_SUCCESS_MESSAGE = 'Lo stato dell\'agente è stato aggiornato con successo';
export const AGENT_STATUS_UPDATED_ERROR_MESSAGE = 'Non è stato possibile aggiornare lo stato dell\'agente';
export const NOT_VALID_DATE_ERROR_MESSAGE = 'La data non è valida';
export const NOT_FUTURE_DATE_ERROR_MESSAGE = 'La data deve essere nel futuro';

export const membershipStatusColor: Record<MembershipStatus, ActionProps['color']> = {
  [MembershipStatus.UNDER_ACTIVATION]: 'info',
  [MembershipStatus.ACTIVE]: 'success',
  [MembershipStatus.UNDER_REVOCATION]: 'warning',
  [MembershipStatus.REVOKED]: 'critical',
};

export const membershipStatusBadgeColor: Record<MembershipStatus, BadgeProps['color']> = {
  [MembershipStatus.UNDER_ACTIVATION]: 'info',
  [MembershipStatus.ACTIVE]: 'success',
  [MembershipStatus.UNDER_REVOCATION]: 'warning',
  [MembershipStatus.REVOKED]: 'critical',
};

export const optionsByStatus: Record<MembershipStatus, AgentStatusAction[]> = {
  [MembershipStatus.UNDER_ACTIVATION]: [AgentStatusAction.ACTIVATE, AgentStatusAction.REVOKE],
  [MembershipStatus.ACTIVE]: [AgentStatusAction.REVOKE],
  [MembershipStatus.UNDER_REVOCATION]: [AgentStatusAction.REACTIVATE, AgentStatusAction.REVOKE],
  [MembershipStatus.REVOKED]: [AgentStatusAction.ACTIVATE],
};

export const confirmModalTextByStatus: Record<AgentStatusAction, { title: string, message: string }> = {
  [AgentStatusAction.ACTIVATE]: {
    title: 'Attivazione agente',
    message: `Scegli la data di inizio collaborazione con l'agente <strong>${AGENT_NAME_PLACEHOLDER}</strong>`,
  },
  [AgentStatusAction.REVOKE]: {
    title: 'Revoca agente',
    message: `Scegli la data di fine collaborazione con l'agente <strong>${AGENT_NAME_PLACEHOLDER}</strong>`,
  },
  [AgentStatusAction.REACTIVATE]: {
    title: 'Annullamento revoca agente',
    message: `Confermando l’operazione, annullerai la revoca dell’agente <strong>${AGENT_NAME_PLACEHOLDER}</strong>`,
  },

};

export const agentStatusActionLabels: Record<AgentStatusAction, string> = {
  [AgentStatusAction.ACTIVATE]: 'Attiva',
  [AgentStatusAction.REVOKE]: 'Revoca',
  [AgentStatusAction.REACTIVATE]: 'Attiva',
};
