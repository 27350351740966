import * as Yup from 'yup';
import type { EditAgentCoveredGeoFormModel } from './EditAgentCoveredGeoForm';

const parseWeight = (weight?: string): number => ((typeof weight === 'undefined' || weight === '')
  ? 0
  : Number.parseInt(weight, 10));

const editAgentCoveredGeoFormValidationSchema = Yup.object({
  provinces: Yup
    .object()
    .test(
      'provinces-validation',
      'Invalid provinces object',
      (obj: EditAgentCoveredGeoFormModel['provinces'], ctx) => {
        if (Object.keys(obj).length === 0) {
          return true;
        }

        const errors: { path: string, message: string }[] = [];

        const totalWeight = Object.values(obj).reduce((acc, { weight }) => {
          const numericWeight = parseWeight(weight);

          if (numericWeight < 1 || numericWeight > 100 || Number.isNaN(numericWeight)) {
            errors.push({
              path: 'invalidWeight',
              message: 'La percentuale di copertura deve essere un valore compreso tra 1 e 100',
            });
          }

          return acc + numericWeight;
        }, 0);

        if (totalWeight !== 100) {
          errors.push({
            path: 'invalidTotalWeight',
            message: 'La somma delle percentuali di copertura deve essere pari a 100',
          });
        }

        if (errors.length > 0) {
          return ctx.createError({
            path: 'errors',
            message: errors.reduce((acc, { path, message }) => ({
              ...acc,
              [path]: message,
            }), {}),
          });
        }

        return true;
      },
    ),
});

export default editAgentCoveredGeoFormValidationSchema;
