import { DEFAULT_INITIAL_VALUES, PropertyGeoFormModel } from '../../../../property/components/property-geo-form/PropertyGeoForm';
import { Geo, OfferWizardData, Property } from '../../../../providers/api/dtos';
import { geocodeByAddress } from '../../../../providers/geo/geocode/geocodeProvider';

export const toOfferWizardData = async (
  existingData: OfferWizardData,
  existingProperty: Property,
  propertyGeoValues: PropertyGeoFormModel,
): Promise<OfferWizardData> => {
  const {
    administrativeAreaLevelTwo,
    locality,
    normalizedAddress,
    postalCode,
    route,
    streetNumber,
    unit,
    stairwell,
  } = propertyGeoValues;

  let latitude: string | undefined;
  let longitude: string | undefined;
  let administrativeAreaLevelOne: string | undefined;
  let suburb: string | undefined | null;

  if (normalizedAddress) {
    [{
      latitude, longitude, administrativeAreaLevelOne, suburb,
    }] = await geocodeByAddress(normalizedAddress.description);
  }

  const newPropertyGeoValues: Geo = {
    administrativeAreaLevelTwo: administrativeAreaLevelTwo || undefined,
    locality: locality || undefined,
    normalizedAddress: normalizedAddress?.description || undefined,
    postalCode: postalCode || undefined,
    route: route || undefined,
    streetNumber: streetNumber || undefined,
    latitude: latitude ? Number(latitude) : undefined,
    longitude: longitude ? Number(longitude) : undefined,
    administrativeAreaLevelOne,
    suburb: suburb || undefined,
  };

  const newPropertyValues: Partial<OfferWizardData['property']> = {
    unit: unit || undefined,
    stairwell: stairwell || undefined,
  };

  const newPropertyCadastralRegistryValues = {
    locality: locality || undefined,
    units: [],
  };

  const newValues: OfferWizardData = {
    ...existingData,
    isDigitallySigned: false,
    property: {
      ...existingData.property,
      ...newPropertyValues,
      cadastralRegistry: {
        ...(existingData.property?.cadastralRegistry ?? existingProperty?.cadastralRegistry ?? newPropertyCadastralRegistryValues),
      },
      geo: {
        ...existingData.property?.geo,
        ...newPropertyGeoValues,
      },
    },
  };

  return newValues;
};

export const toPropertyGeoFormModel = (
  initialData: OfferWizardData,
  existingProperty?: Property,
): PropertyGeoFormModel => {
  const { property } = initialData;

  const geoFields = property?.geo ? {
    normalizedAddress: property.geo.normalizedAddress ? {
      description: property.geo.normalizedAddress,
      placeId: '',
    } : undefined,
    locality: property.geo.locality ?? '',
    postalCode: property.geo.postalCode ?? '',
    administrativeAreaLevelTwo: property.geo.administrativeAreaLevelTwo ?? '',
    route: property.geo.route ?? '',
    streetNumber: property.geo.streetNumber ?? '',
  } : {
    ...DEFAULT_INITIAL_VALUES,
    administrativeAreaLevelTwo: existingProperty?.geo?.administrativeAreaLevelTwo ?? '',
    locality: existingProperty?.geo?.locality ?? '',
    postalCode: existingProperty?.geo?.postalCode ?? '',
    route: existingProperty?.geo?.route ?? '',
    streetNumber: existingProperty?.geo?.streetNumber ?? '',
    normalizedAddress: {
      description: existingProperty?.geo?.normalizedAddress ?? '',
      placeId: '',
    },
  };

  return {
    ...geoFields,
    unit: property?.unit ?? existingProperty?.unit ?? '',
    stairwell: property?.stairwell ?? existingProperty?.stairwell ?? '',
  };
};
