import React, { FunctionComponent } from 'react';
import {
  Form, FormActionsAlign, Message, Action,
  FormProps,
  HStack,
} from '@doveit/bricks';
import { isPast } from 'date-fns';
import schema from './AppointmentForm.schema';
import { AppointmentFormModel } from '../../models/appointmentFormModel';
import { AppointmentStatus } from '../../../domain/types';
import { ExtraAction } from '../../../types';
import { appointmentStatusLabels } from '../../../labels';
import { formDateTimeToDate } from '../../../utils/form';

export interface AppointmentFormProps {
  initialValues?: AppointmentFormModel,
  loading?: boolean,
  withStatus?: boolean,
  withVirtual?: boolean,
  submitLabel?: string,
  onSubmit: (values: AppointmentFormModel) => void,
  extraActions?: ExtraAction[],
  hideSubmit?: boolean,
  formRef?: FormProps<AppointmentFormModel>['innerRef'],
}

export const PAST_APPOINTMENT_DATE_WARNING_MESSAGE = 'Stai creando un appuntamento nel passato.';

export const statusSelectOptions = Object.keys(AppointmentStatus).map((key) => ({
  value: key,
  label: appointmentStatusLabels[key as AppointmentStatus],
}));

const AppointmentForm: FunctionComponent<AppointmentFormProps> = ({
  initialValues,
  loading,
  withStatus = true,
  withVirtual,
  onSubmit,
  submitLabel = 'Salva',
  extraActions,
  hideSubmit = false,
  formRef,
}) => {
  const initialValuesMerged = {
    ...{
      id: undefined,
      date: '',
      time: '',
      notes: '',
      status: AppointmentStatus.TODO,
    },
    ...(initialValues || {}),
  };

  const isDisabled = React.useMemo(() => !!initialValuesMerged.id && initialValuesMerged.status !== AppointmentStatus.TODO, [initialValuesMerged.id, initialValuesMerged.status]);
  const hideStatus = React.useMemo(() => !withStatus || !initialValues?.id || initialValuesMerged.status !== AppointmentStatus.TODO, [initialValues?.id, initialValuesMerged.status, withStatus]);
  return (
    <Form
      aria-label="Modulo di salvataggio appuntamento"
      initialValues={initialValuesMerged}
      validationSchema={schema}
      loading={loading}
      onSubmit={onSubmit}
      innerRef={formRef}
      preventEnterKeypress
    >
      {({ values }) => (
        <>
          {!hideStatus && (
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Select
                label="Stato"
                name="status"
                aria-label="Campo per selezionare lo stato dell'appuntamento"
                options={statusSelectOptions}
              />
            </Form.Item>
          )}
          {withVirtual && (
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.CheckboxButton
                label="Virtuale"
                name="virtual"
                aria-label="Appuntamento con videochiamata"
                text="Videochiamata"
                disabled={isDisabled}
              />
            </Form.Item>
          )}
          <Form.Group>
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Input
                label="Data"
                type="date"
                aria-label="Campo per l'inserimento della data"
                name="date"
                disabled={isDisabled}
                required
              />
            </Form.Item>
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Input
                label="Ora"
                type="time"
                aria-label="Campo per l'inserimento dell'ora"
                name="time"
                disabled={isDisabled}
                required
              />
            </Form.Item>
          </Form.Group>
          <Form.Item>
            <Form.TextArea
              label="Note"
              aria-label="Campo per l'inserimento delle note"
              name="notes"
              rows={6}
            />
          </Form.Item>
          {isPast(formDateTimeToDate(values.date, values.time)) && values.status === AppointmentStatus.TODO && (
            <Form.Item>
              <Message
                type="warning"
                message={PAST_APPOINTMENT_DATE_WARNING_MESSAGE}
              />
            </Form.Item>
          )}
          {!hideSubmit && (
            <Form.Actions align={FormActionsAlign.RIGHT}>
              <HStack>
                <Form.Submit label={submitLabel} aria-label="Pulsante per salvare le modifiche" />
                {extraActions && extraActions.map((action) => (
                  <Action key={action.id} {...action} />
                ))}
              </HStack>
            </Form.Actions>
          )}
        </>
      )}
    </Form>
  );
};

export default AppointmentForm;
