import { Text, View } from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';

import * as styles from './Description.style';

export interface DescriptionProps {
  description: string;
}

export const Description: FunctionComponent<DescriptionProps> = ({
  description,
}) => (
  <View>
    <Text style={styles.description}>{description}</Text>
  </View>
);
