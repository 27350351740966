import {
  Form, FormAutoCompleteV2Props, ICON_MAGNIFY,
} from '@doveit/bricks';
import React from 'react';
import { AddressSuggestion } from '../../providers/geo/dtos';
import useGoogleMapsAPI from '../../hooks/use-google-maps-api/useGoogleMapsAPI';

export type FormAddressAutoCompleteProps = Omit<FormAutoCompleteV2Props<AddressSuggestion>, 'suggestions' | 'printSuggestion'>;

const AddressSuggestionAutoComplete: React.FC<FormAddressAutoCompleteProps> = (props) => {
  const { getAddressSuggestions } = useGoogleMapsAPI();

  const printSuggestion = React.useCallback(
    (result: AddressSuggestion) => result.description,
    [],
  );

  return (
    <Form.AutoCompleteV2
      {...props}
      aria-label="Campo per inserimento indirizzo"
      icon={ICON_MAGNIFY}
      suggestions={getAddressSuggestions}
      printSuggestion={printSuggestion}
    />
  );
};

export default AddressSuggestionAutoComplete;
