import {
  Form, FormActionsAlign, FormProps, Icon,
} from '@doveit/bricks';
import React, { FunctionComponent, useMemo } from 'react';
import { ThemeIcons } from '../../../theme/icons';
import validationSchema from './ApplicantForm.schema';

export interface ApplicantFormModel {
  name: string,
  phoneNumber?: string,
  email?: string,
  linkedIn?: string,
}

export interface ApplicantFormProps {
  initialValues?: ApplicantFormModel,
  loading?: FormProps<ApplicantFormModel>['loading'],
  onSubmit: FormProps<ApplicantFormModel>['onSubmit']
}

const DEFAULT_INITIAL_VALUES: ApplicantFormModel = {
  name: '',
  phoneNumber: '',
  email: '',
  linkedIn: '',
};

const ApplicantForm: FunctionComponent<ApplicantFormProps> = ({
  initialValues,
  loading,
  onSubmit,
}) => {
  const mergedInitialValues = useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      loading={loading}
      onSubmit={onSubmit}
    >
      <Form.Item>
        <Form.Input
          name="name"
          label="Nome e cognome"
          placeholder="Es. Mario Rossi"
          aria-label="Campo per inserire il nome e il cognome"
          type="text"
          required
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="email"
          label="Email"
          placeholder="Es. mario.rossi@gmail.com"
          aria-label="Campo per inserire l'indirizzo email"
          type="text"
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="phoneNumber"
          label="Numero di telefono"
          placeholder="Es. 3923456789"
          aria-label="Campo per inserire il numero di telefono"
          type="text"
        />
      </Form.Item>

      <Form.Item>
        <Form.Input
          name="linkedIn"
          label="Profilo LinkedIn"
          placeholder="Es. https://www.linkedin.com/in/mariorossi"
          prefixElement={<Icon path={ThemeIcons.LINKEDIN} />}
          aria-label="Campo per inserire il profilo LinkedIn"
          type="text"
        />
      </Form.Item>

      <Form.Actions align={FormActionsAlign.RIGHT}>
        <Form.Submit label="Salva" />
      </Form.Actions>
    </Form>
  );
};

export default ApplicantForm;
