import React from 'react';
import { UserPreview, UserPreviewProps } from '@doveit/bricks';
import { AgencyPreviewStatus } from '../types';
import AgencyAvatar from '../agency-avatar/AgencyAvatar';

export interface AgencyPreviewProps extends Omit<UserPreviewProps, 'children' | 'avatar'> {
  status?: AgencyPreviewStatus,
  franchising?: boolean,
  address?: string,
  photoId?: string,
}

export const AgencyPreview: React.FC<AgencyPreviewProps> = ({
  status,
  name,
  franchising,
  address,
  size,
  photoId,
  ...rest
}) => (
  <UserPreview
    aria-label={name ? `Anteprima di ${name}` : undefined}
    {...rest}
    size={size}
    name={name}
    renderAvatar={(avatarProps) => (
      <AgencyAvatar
        {...avatarProps}
        photoId={photoId}
        status={status}
        franchising={franchising}
      />
    )}
  >
    {address}
  </UserPreview>
);

export default AgencyPreview;
