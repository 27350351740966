import React from 'react';
import {
  ActionIcon, Card, CardSkeleton, FontWeight, Grid, HStack, ICON_ALERT_CIRCLE_OUTLINE, ICON_EYE_OUTLINE, Message,
  Stack,
  Text,
  UserPreview,
  UserPreviewSkeleton,
} from '@doveit/bricks';
import { Prospect } from '../../../providers/api/dtos';
import useLead from '../../../lead/hooks/use-lead/useLead';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import UpdateProspectStatus from '../../../prospect/containers/update-prospect-status/UpdateProspectStatus';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import ViewProspectPopoverPreview from '../../../prospect/containers/view-prospect-popover-preview/ViewProspectPopoverPreview';
import ProspectAvatar from '../../../prospect/components/prospect-avatar/ProspectAvatar';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';

export interface ViewContactProspectCardProps {
  prospect: Prospect,
  onProspectUpdate?: (updatedProspect: Prospect) => void,
}

export const LOAD_LEAD_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare i dati.';

const ViewContactProspectCard: React.FC<ViewContactProspectCardProps> = ({
  prospect,
  onProspectUpdate,
}) => {
  const { data: lead, isLoading: isLeadLoading, error: leadError } = useLead(prospect.leadId);
  const { data: agent, isLoading: isAgentLoading, error: agentError } = useAgent(prospect.agentId);

  const { userIsAdmin, userIsCallCenter, userIsContentEditor } = useRBAC();
  const isProspectAgentOrManager = useCurrentAgentIsSameAgentOrManager(prospect.agentId);

  const canViewAction = React.useMemo(() => userIsAdmin || userIsCallCenter || userIsContentEditor || isProspectAgentOrManager,
    [isProspectAgentOrManager, userIsAdmin, userIsCallCenter, userIsContentEditor]);

  const goToProspectPage = React.useCallback(() => {
    goToDetailPage(prospect.id!, ReferenceType.PROSPECT, true);
  }, [prospect.id]);

  if (leadError) {
    return (
      <Card>
        <Card.Header
          title="Valutazioni"
          color="critical"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
        />
        <Card.Content>
          <Message message={LOAD_LEAD_DATA_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  if (isLeadLoading) {
    return (
      <CardSkeleton aria-label="Valutazioni in caricamento" />
    );
  }

  return (
    lead && (
      <Card aria-label="Card della valutazione">
        <Card.Header
          primaryActions={[
            canViewAction && (
              <ActionIcon
                label="Visualizza"
                icon={{ path: ICON_EYE_OUTLINE }}
                size="XS"
                onClick={goToProspectPage}
                aria-label="Azione per visualizzare la pagina di dettaglio"
              />
            ),
          ]}
        >
          <ViewProspectPopoverPreview
            prospectId={prospect.id!}
            trigger={(
              <HStack gap={100} wrap="nowrap">
                <ProspectAvatar size="S" />
                <Text.BodySmall fontWeight={FontWeight.MEDIUM} css={setVerticalEllipsis()}>
                  {lead.propertyAddress}
                </Text.BodySmall>
              </HStack>
            )}
            hideAction={!canViewAction}
          />
        </Card.Header>
        <Card.Content>
          <Grid gutter={150}>
            <Grid.Unit>
              <UpdateProspectStatus
                prospect={prospect}
                size="XS"
                onSuccess={onProspectUpdate}
              />
            </Grid.Unit>

            <Grid.Unit size={1 / 2}>
              {isAgentLoading && (
                <UserPreviewSkeleton
                  size="S"
                  aria-label="Informazioni sull'agente in caricamento"
                />
              )}
              {agentError && (
                <UserPreview
                  name="Errore agente"
                />
              )}
              {agent && (
                <ViewAgentPopoverPreview
                  agent={agent}
                  trigger={(
                    <AgentPreview
                      {...toAgentPreviewProps(agent)}
                      size="S"
                    />
                  )}
                />
              )}
            </Grid.Unit>

            <Grid.Unit size={1 / 2}>
              <Stack gap={50}>
                <Text.Mini transform="uppercase">
                  Aggiunta
                </Text.Mini>
                <Text.Caption>
                  <DateViewer
                    checkIfToday
                    withHour={false}
                    stringDate={prospect.createdAt!}
                  />
                </Text.Caption>
              </Stack>
            </Grid.Unit>
          </Grid>
        </Card.Content>
      </Card>
    ));
};

export default ViewContactProspectCard;
