import {
  BadgeProps, ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE, ICON_PROGRESS_CHECK,
} from '@doveit/bricks';
import { RumorStatus } from '../domain/types';

export const RUMOR_KO_STATUSES = [
  RumorStatus.KO_NOT_CONFIRMED,
  RumorStatus.KO_NOT_INTERESTED,
  RumorStatus.KO_DUPLICATE_LEAD,
] as const;

export const rumorStatusLabels: Record<RumorStatus, string> = {
  [RumorStatus.IN_PROGRESS]: 'In lavorazione',
  [RumorStatus.KO_NOT_INTERESTED]: 'Non interessato',
  [RumorStatus.KO_NOT_CONFIRMED]: 'Non confermata',
  [RumorStatus.APPOINTMENT_SCHEDULED]: 'Appuntamento fissato',
  [RumorStatus.KO_DUPLICATE_LEAD]: 'Duplicato lead',
};

export const rumorStatusColors: Record<RumorStatus, BadgeProps['color']> = {
  [RumorStatus.IN_PROGRESS]: 'info',
  [RumorStatus.KO_NOT_INTERESTED]: 'critical',
  [RumorStatus.KO_NOT_CONFIRMED]: 'critical',
  [RumorStatus.APPOINTMENT_SCHEDULED]: 'success',
  [RumorStatus.KO_DUPLICATE_LEAD]: 'critical',
};

export const rumorStatusIcons: Record<RumorStatus, string> = {
  [RumorStatus.IN_PROGRESS]: ICON_PROGRESS_CHECK,
  [RumorStatus.KO_NOT_INTERESTED]: ICON_CHECK_CIRCLE_OUTLINE,
  [RumorStatus.KO_NOT_CONFIRMED]: ICON_ALERT_CIRCLE_OUTLINE,
  [RumorStatus.APPOINTMENT_SCHEDULED]: ICON_ALERT_CIRCLE_OUTLINE,
  [RumorStatus.KO_DUPLICATE_LEAD]: ICON_ALERT_CIRCLE_OUTLINE,
};
