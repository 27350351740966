/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Grid, ICON_CLOSE, ICON_MAGNIFY, ICON_MESSAGE_TEXT_OUTLINE, Input, Message, Spacing, Spinner, SpinnerSize, Icon,
} from '@doveit/bricks';
import { FirebaseError } from 'firebase/app';
import { ItemContent, Virtuoso } from 'react-virtuoso';
import { useConversationsInfinite } from '../../hooks/use-conversations/useConversations';
import ConversationPreview from '../../components/conversation-preview/ConversationPreview';
import ConversationPreviewSkeleton from '../../components/conversation-preview/ConversationPreview.skeleton';
import * as styles from './ManageConversations.style';
import { Conversation } from '../../../providers/api/dtos';
import { useFCM } from '../../../hooks/use-fcm/useFCM';
import { firebaseErrorToMessage } from '../../../utils/firebase/firebase';
import ViewConversationChat from '../view-conversation-chat/ViewConversationChat';
import { DEFAULT_PAGINATION_SIZE } from '../../../utils/querystring/querystring';

export const ConversationsSkeleton: React.FC<{ count?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 }> = ({ count = 1 }) => (
  <div aria-label="conversations-loading">
    {Array.from(Array(count).keys()).map((key) => (
      <div key={key} css={styles.conversationItem}>
        <ConversationPreviewSkeleton />
      </div>
    ))}
  </div>
);

interface Context {
  activeConversation?: Conversation,
  onConversationClick: (conversation: Conversation) => void,
}

const ConversationRow: ItemContent<Conversation, Context> = (_, conversation, ctx) => {
  const {
    id, contact, lastMessage, hasUnreadMessages,
  } = conversation;
  const { activeConversation, onConversationClick } = ctx;

  return (
    <div
      key={id}
      css={styles.conversationItem}
    >
      <ConversationPreview
        name={contact.name}
        phoneNumber={contact.phoneNumber}
        date={lastMessage.createdAt}
        message={lastMessage.body}
        onClick={() => onConversationClick(conversation)}
        active={id === activeConversation?.id}
        hasUnreadMessages={hasUnreadMessages}
      />
    </div>
  );
};

export const ERROR_MESSAGE = 'Si è verificato un errore durante il recupero dati.';
export const NO_ACTIVE_CONVERSATION_WARNING_MESSAGE = 'Seleziona una conversione o ricerca un contatto per nome o numero di telefono';
export const NO_CONVERSATIONS_FOUND_INFO_MESSAGE = 'Non sono presenti conversazioni';

const ManageConversations: React.FC = () => {
  const [activeConversation, setActiveConversation] = React.useState<Conversation>();
  const [querySearch, setQuerySearch] = React.useState('');

  const {
    data: conversations, error: conversationsError, mutate: mutateConversations, setSize, size,
  } = useConversationsInfinite({
    size: DEFAULT_PAGINATION_SIZE,
  }, querySearch.length > 3 ? querySearch : undefined);

  const refreshUI = React.useCallback(async () => {
    await mutateConversations();
    conversations?.forEach(({ content: conversation }) => {
      const activeConversationId = activeConversation?.id;
      const updatedConversation = conversation.find((c) => c.id === activeConversationId);
      if (conversation) {
        setActiveConversation(updatedConversation);
      }
    });
  }, [activeConversation?.id, conversations, mutateConversations]);

  const { error } = useFCM(ManageConversations.name, refreshUI, refreshUI);

  const onConversationClick = React.useCallback(async (conversation: Conversation) => {
    setActiveConversation(conversation);
  }, []);

  // Returns true if the first chunk of conversations has not been fetched yet.
  const isLoading = React.useMemo(() => !error && !conversations, [conversations, error]);

  // Returns true if the next chunks of conversations have not been fetched yet.
  const isLoadingMore = React.useMemo(
    () => isLoading || (size > 0 && conversations && typeof conversations[size - 1] === 'undefined'),
    [conversations, isLoading, size],
  );

  // Returns true if there are no conversations to fetch.
  const isEmpty = React.useMemo(() => conversations?.[0]?.numberOfElements === 0, [conversations]);

  // Returns true if there are no more conversations to fetch.
  const hasReachedEnd = React.useMemo(() => isEmpty || (conversations && conversations[size - 1]?.last), [conversations, isEmpty, size]);

  const loadNextConversations = React.useCallback(() => {
    if (!hasReachedEnd && !isLoadingMore) {
      setSize((prev) => prev + 1);
    }
  }, [hasReachedEnd, isLoadingMore, setSize]);

  const onSearchInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuerySearch(event.target.value as string);
  }, []);

  const clearSearchInput = React.useCallback(() => {
    setQuerySearch('');
  }, []);

  const errorMessage = React.useMemo(() => {
    if (!error && !conversationsError) return '';

    if (error instanceof FirebaseError) {
      return firebaseErrorToMessage(error);
    }

    return ERROR_MESSAGE;
  }, [conversationsError, error]);

  if (errorMessage) {
    return (
      <Message
        type="critical"
        boxed
        message={errorMessage}
      />
    );
  }

  return (
    <Grid
      gutter={150}
      addCSS={{ height: '100%' }}
    >
      <Grid.Unit size={{ MD: 1 / 3 }}>
        <div css={styles.side}>
          <div css={styles.search}>
            <Input
              aria-label="Cerca conversazione per nome o numero di telefono"
              onChange={onSearchInput}
              icon={querySearch ? ICON_CLOSE : ICON_MAGNIFY}
              onClickIcon={clearSearchInput}
              placeholder="Cerca per nome o numero di telefono"
              value={querySearch}
              loading={!!querySearch && isLoading}
            />
            {querySearch && conversations && (
              <div css={styles.searchLabel}>
                Risultati ricerca
              </div>
            )}
          </div>
          <div css={styles.conversationsWrapper}>
            {isLoadingMore && (
              <div
                aria-label="loading"
                css={styles.conversationsLoading}
              >
                <Spinner
                  size={SpinnerSize.SMALL}
                  color="neutrals.medium"
                />
              </div>
            )}
            {isLoading && (
              <ConversationsSkeleton count={5} />
            )}
            {!isLoading && !isEmpty && (
              <Virtuoso
                data={conversations?.flatMap((conv) => conv.content)}
                context={{
                  activeConversation,
                  onConversationClick,
                }}
                endReached={loadNextConversations}
                itemContent={ConversationRow}
              />
            )}
            {!isLoading && isEmpty && (
              <Spacing padding={100}>
                <Message type="info" message={NO_CONVERSATIONS_FOUND_INFO_MESSAGE} />
              </Spacing>
            )}
          </div>
        </div>
      </Grid.Unit>
      <Grid.Unit size={{ MD: 2 / 3 }}>
        {!activeConversation && (
          <div
            aria-label="chat-placeholder"
            css={styles.chatPlaceholderContainer}
          >
            <div css={styles.chatPlaceholder}>
              <Icon
                path={ICON_MESSAGE_TEXT_OUTLINE}
                size={165}
              />
              <div>
                {NO_ACTIVE_CONVERSATION_WARNING_MESSAGE}
              </div>
            </div>
          </div>
        )}
        {activeConversation && (
          <ViewConversationChat
            conversation={activeConversation}
            onMessagesRead={refreshUI}
          />
        )}
      </Grid.Unit>
    </Grid>
  );
};

export default ManageConversations;
