import {
  Action, ICON_FILTER_OUTLINE, Portal, useModal,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import JobApplicationFiltersForm, { JobApplicationFiltersFormModel } from '../../components/job-application-filters-form/JobApplicationFiltersForm';
import useFilters, { Filters } from '../../../hooks/use-filters/useFilters';
import { getJobApplicationFilterMapper } from '../../utils/filterMapper';
import { JobApplicationFilterKeys } from '../../types';

const FilterJobApplications: FunctionComponent = () => {
  const { filters, addFilters, removeFilters } = useFilters(getJobApplicationFilterMapper());
  const { data: agent, error: agentError } = useAgent(filters.assignee);
  const filterModal = useModal();

  const initialValues = useMemo(() => ({
    department: filters.department,
    assignee: agent,
    province: filters.province,
    license: filters.license,
  }), [agent, filters.department, filters.license, filters.province]);

  const onClick = useCallback(() => {
    filterModal.open();
  }, [filterModal]);

  const onSubmit = useCallback((values: JobApplicationFiltersFormModel) => {
    const toAdd: Filters = {};
    const toRemove: JobApplicationFilterKeys[] = [];

    Object.entries(values).forEach(([k, value]) => {
      const key = k as JobApplicationFilterKeys;
      if (!value) {
        toRemove.push(key);
      } else if (key === 'assignee') {
        toAdd[key] = value.id;
      } else {
        toAdd[key] = value;
      }
    });

    addFilters(toAdd);
    removeFilters(toRemove);

    filterModal.close();
  }, [addFilters, removeFilters, filterModal]);

  if (agentError || (filters.assignee && !agent)) {
    return null;
  }

  return (
    <>
      <Action
        iconLeft={{ path: ICON_FILTER_OUTLINE }}
        label="Filtra"
        emphasis="high"
        onClick={onClick}
      />
      <Portal>
        <SimpleModal
          {...filterModal}
          title="Filtra candidature"
        >
          <JobApplicationFiltersForm
            initialValues={initialValues}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default FilterJobApplications;
