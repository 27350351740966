import * as Yup from 'yup';

export const DOVE_IT_REGEXP = /^(https?:\/\/)?((www|\w\w)\.)?dove\.it/gm;

const validationSchema = Yup.object().shape({
  url: Yup.string()
    .required('Il campo è obbligatorio')
    .matches(DOVE_IT_REGEXP, 'Devi inserire un link del sito Dove.it'),
});

export default validationSchema;
