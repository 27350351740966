import { AvatarColor } from '@doveit/bricks';
import { MembershipStatus } from '../../domain/types';
import { AgencyPreviewStatus } from './types';

export const mapAgencyPreviewStatusToColor: Record<AgencyPreviewStatus, AvatarColor> = {
  [AgencyPreviewStatus.UNDER_ACTIVATION]: 'info',
  [AgencyPreviewStatus.UNDER_REVOCATION]: 'warning',
  [AgencyPreviewStatus.REVOKED]: 'critical',
};

export const mapToAgencyPreviewStatus: Record<MembershipStatus, AgencyPreviewStatus | undefined> = {
  [MembershipStatus.UNDER_ACTIVATION]: AgencyPreviewStatus.UNDER_ACTIVATION,
  [MembershipStatus.ACTIVE]: undefined,
  [MembershipStatus.UNDER_REVOCATION]: AgencyPreviewStatus.UNDER_REVOCATION,
  [MembershipStatus.REVOKED]: AgencyPreviewStatus.REVOKED,
};
