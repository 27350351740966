/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Action, ICON_FILE_PLUS_OUTLINE, ICON_FILE_REPLACE_OUTLINE, Link, Spacing, Icon, Tooltip, Typography,
} from '@doveit/bricks';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import Card from '../../../components/card/Card';
import * as styles from './AssignmentModePage.style';
import { GenericPageLayoutWrapperWidth } from '../../../layouts/generic-page-layout/wrapper/GenericPageLayoutWrapper';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import useAssignmentWizardData from '../../hooks/use-assignment-wizard-data/useAssignmentWizardData';
import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import useAssignmentByProspectId from '../../hooks/use-assignment-by-prospect-id/useAssignmentByProspectId';
import useProspect from '../../hooks/use-prospect/useProspect';
import { ProspectStatus } from '../../../domain/types';
import { NotFoundPage } from '../../../constants';

export const LOAD_PROSPECT_ERROR_MESSAGE = 'Non è stato possibile caricare alcuni dati relativi alla valutazione';
export const ASSIGNMENT_ALREADY_CREATED_ERROR_MESSAGE = 'Un mandato è già presente per questa valutazione.';
export const DIGITAL_SIGNATURE_ALREADY_REQUESTED_ERROR_MESSAGE = 'Non è possibile procedere con la creazione del mandato digitale. Una procedura di firma digitale è attualmente in corso.';

const AssignmentModePage: React.FC = () => {
  const agent = useCurrentAgent();
  const navigate = useNavigate();
  const { id: prospectId, action } = useParams();
  const { data: prospect, isLoading: isProspectLoading, error: prospectError } = useProspect(prospectId);
  const { data: assignmentWizardData, isLoading: isAssignmentWizardDataLoading } = useAssignmentWizardData(prospectId);
  const { data: assignment } = useAssignmentByProspectId(action !== 'create' ? prospectId : undefined);

  const goToProspectPage = React.useCallback(() => {
    navigate(`/prospects/${prospectId}`);
  }, [navigate, prospectId]);

  const goToPropertyPage = React.useCallback(() => {
    navigate(`/properties/${assignment?.propertyId}`);
  }, [assignment?.propertyId, navigate]);

  const goToManageAssignmentPage = React.useCallback((type: 'manual' | 'digital') => () => {
    navigate(`/prospects/${prospectId}/assignment/${action}/${type}`);
  }, [action, navigate, prospectId]);

  const hasDigitalAssignmentEnabled = React.useMemo(
    () => (!agent?.franchising || (agent?.agencies?.some((agency) => agency.configuration?.digitalAssignment?.enabled === true) ?? false)),
    [agent?.agencies, agent?.franchising],
  );

  React.useEffect(() => {
    if (!hasDigitalAssignmentEnabled) {
      goToManageAssignmentPage('manual')();
    }
  }, [agent, goToManageAssignmentPage, hasDigitalAssignmentEnabled]);

  const manualActionButtonLabel = React.useMemo(() => {
    switch (`${action}`) {
      case 'create':
        return 'Crea l\'immobile';
      case 'renew':
        return 'Modifica mandato';
      case 'edit':
        return 'Carica mandato';
      case 'edit-extra-packages':
        return 'Modifica pacchetti';
      default:
        return '';
    }
  }, [action]);

  const digitalActionButtonLabel = React.useMemo(() => {
    switch (`${action}`) {
      case 'create':
      case 'edit':
        return 'Genera mandato';
      case 'renew':
        return 'Modifica mandato';
      case 'edit-extra-packages':
        return 'Modifica pacchetti';
      default:
        return '';
    }
  }, [action]);

  if (!action || !['create', 'renew', 'edit', 'edit-extra-packages', 'edit-extra-packages'].includes(action)) {
    return <NotFoundPage />;
  }

  if (prospectError) {
    return <GenericErrorPage title={LOAD_PROSPECT_ERROR_MESSAGE} />;
  }

  if (action === 'create' && assignmentWizardData?.isDigitallySigned) {
    return <GenericErrorPage title={DIGITAL_SIGNATURE_ALREADY_REQUESTED_ERROR_MESSAGE} />;
  }

  if (action === 'create' && prospect?.status === ProspectStatus.ASSIGNMENT_CREATED) {
    return <GenericErrorPage title={ASSIGNMENT_ALREADY_CREATED_ERROR_MESSAGE} />;
  }

  if (isProspectLoading || isAssignmentWizardDataLoading) {
    return null;
  }

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content
        maxWrapperWidth={GenericPageLayoutWrapperWidth.MEDIUM}
        title="Mandato di vendita"
        subtitle={
          assignment ? (
            <Link
              aria-label="Vai alla pagina dell'immobile"
              onClick={goToPropertyPage}
            >
              ← Vedi immobile
            </Link>
          ) : (
            <Link
              aria-label="Vai alla pagina della valutazione"
              onClick={goToProspectPage}
            >
              ← Vedi valutazione
            </Link>
          )
        }
      >
        <Card>
          <Card.Inline>
            <Card.Box>
              <div css={styles.assignmentBox}>
                <Icon path={ICON_FILE_PLUS_OUTLINE} size={50} />
                <Typography.HEADING_4 tagName="h1">
                  Mandato cartaceo
                </Typography.HEADING_4>
                <Spacing margin={[200, 0]}>
                  <Typography.BODY>
                    {action === 'create' && 'Crea la scheda immobile e inserisci il mandato firmato nella sezione documenti'}
                    {action === 'edit' && 'Modifica i dati e carica il nuovo mandato firmato nella sezione documenti'}
                    {action === 'renew' && 'Modifica il mandato per cambiare durata e/o prezzo minimo accettato e carica il nuovo documento'}
                    {action === 'edit-extra-packages' && 'Modifica il mandato includendo nuovi pacchetti extra e carica il nuovo documento'}
                  </Typography.BODY>
                </Spacing>
                <Action
                  color="primary"
                  emphasis="high"
                  label={manualActionButtonLabel}
                  onClick={goToManageAssignmentPage('manual')}
                />
              </div>
            </Card.Box>
            <Card.ColumnDivider />
            <Card.Box>
              <div css={styles.assignmentBox}>
                <Icon path={ICON_FILE_REPLACE_OUTLINE} size={50} />
                <Typography.HEADING_4 tagName="h1">
                  Mandato digitale
                </Typography.HEADING_4>
                <Spacing margin={[200, 0]}>
                  <Typography.BODY>
                    {['create', 'edit'].includes(action) && (
                      <>
                        Compila il mandato, raccogli le firme digitalmente o stampa il documento e&nbsp;
                        <Tooltip
                          data-ref="assignment-generate-tooltip"
                          content={(
                            <div data-ref="assignment-generate-info">
                              <Typography.BODY_SMALL>
                                Raccogli le firme a distanza, risparmia tempo nella compilazione del preliminare, della proposta d&apos;acquisto e nella richiesta del proforma
                              </Typography.BODY_SMALL>
                            </div>
                          )}
                          addCSS={{ maxWidth: '30rem' }}
                        >
                          <strong>
                            risparmia tempo<sup>*</sup>
                          </strong>
                        </Tooltip>
                      </>
                    )}
                    {action === 'renew' && 'Modifica il mandato per estenderne la durata o cambiare il prezzo minimo accettato dai venditori'}
                    {action === 'edit-extra-packages' && 'Modifica il mandato includendo nuovi pacchetti extra e genera un nuovo documento'}
                  </Typography.BODY>
                </Spacing>
                <Action
                  color="primary"
                  emphasis="high"
                  label={digitalActionButtonLabel}
                  onClick={goToManageAssignmentPage('digital')}
                />
              </div>
            </Card.Box>
          </Card.Inline>
        </Card>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default AssignmentModePage;
