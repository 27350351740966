/* eslint-disable react/no-unknown-property */
import {
  ExpandableContent, Message, Select, Skeleton, Spacing, Spinner, SpinnerSize, Typography,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { InfoWindow, Marker } from '@react-google-maps/api';
import React from 'react';
import { DEFAULT_MARKER_SIZE } from '../../../constants';
import groupDataByCoordinates, { WithCoordinatesGroup } from '../../../utils/group-data-by-coordinates/groupDataByCoordinates';
import CrawledPropertyCard from '../../components/crawled-property-card/CrawledPropertyCard';
import getIconByPropertyType from '../../../utils/get-icon-by-property-type/getIconByPropertyType';
import getMarkerIcon, { DEFAULT_SCALE_RATIO } from '../../../utils/get-marker-icon/getMarkerIcon';
import useCrawledProperties from '../../hooks/use-crawled-properties/useCrawledProperties';
import getCrawledPropertyColor, { crawledPropertyColor } from '../../utils/get-crawled-property-color/getCrawledPropertyColor';
import SimpleGoogleMap from '../../../components/simple-google-map/SimpleGoogleMap';
import { CrawledProperty } from '../../../providers/api/dtos/crawledProperties';
import { ResidentialPropertyType } from '../../../domain/types';
import * as styles from './ViewCrawledProperties.style';
import { expiredOptions } from '../../select-options/expiredOptions';
import { getExpiredValueFromString } from '../../utils/get-expired-value-from-string/getExpiredValueFromString';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { residentialPropertyTypeLabels } from '../../../labels';
import { NO_VALUE_SYMBOL } from '../../../property/utils';

export interface CrawledPropertyGroup {
  latitude: number,
  longitude: number,
  items: CrawledProperty[],
}

export interface ViewCrawledPropertiesProps {
  latitude: number,
  longitude: number,
}

export const PROPERTIES_GROUP_MARKER_COLOR = '#E31D93';
export const PROPERTY_REFERENCE_MARKER_COLOR = '#000';
export const DEFAULT_RADIUS = 300;
export const MAX_TOTAL_ELEMENTS = 50;
export const CRAWLED_PROPERTIES_ERROR_MESSAGE = 'Impossibile caricare gli annunci dai portali';
export const MAX_TOTAL_ELEMENTS_WARNING_MESSAGE = `Sono mostrati solo i primi ${MAX_TOTAL_ELEMENTS} risultati`;
export const EMPTY_RESULT_MESSAGE = 'Non ci sono annunci dai portali in linea con la ricerca';

const MARKER_SIZE = DEFAULT_MARKER_SIZE * DEFAULT_SCALE_RATIO;

const containerStyle = {
  width: '100%',
  height: '100%',
};

const Legend: React.FC = () => (
  <div css={styles.legend}>
    <ul css={styles.legendItems}>
      <li
        css={styles.legendItem}
        $color={crawledPropertyColor.LIVE_WITH_ADDRESS}
      >
        Live, con indirizzo
      </li>
      <li
        css={styles.legendItem}
        $color={crawledPropertyColor.LIVE_WITHOUT_ADDRESS}
      >
        Live, no indirizzo
      </li>
      <li
        css={styles.legendItem}
        $color={crawledPropertyColor.EXPIRED_WITH_ADDRESS}
      >
        Scaduto, con indrizzo
      </li>
      <li
        css={styles.legendItem}
        $color={crawledPropertyColor.EXPIRED_WITHOUT_ADDRESS}
      >
        Scaduto, no indirizzo
      </li>
    </ul>
  </div>
);

const ViewCrawledProperties: React.FC<ViewCrawledPropertiesProps> = ({
  latitude,
  longitude,
}) => {
  const [currentMarker, setCurrentMarker] = React.useState<CrawledPropertyGroup | undefined>();
  const [propertyType, setPropertyType] = React.useState<ResidentialPropertyType | undefined>();
  const [expired, setExpired] = React.useState<string>('');

  const { data, error } = useCrawledProperties({
    latitude,
    longitude,
    propertyType,
    expired: getExpiredValueFromString(expired),
    radius: DEFAULT_RADIUS,
  });

  const propertiesGroupsEntries = React.useMemo(
    () => Array.from(groupDataByCoordinates(data?.content || []).entries()),
    [data],
  );

  const closeMarkerWindow = React.useCallback(() => setCurrentMarker(undefined), []);
  const onMarkerClick = React.useCallback((value: WithCoordinatesGroup<CrawledProperty>) => () => setCurrentMarker(value), []);

  const onPropertyTypeChange = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setPropertyType(e.target.value as ResidentialPropertyType);
    } else {
      setPropertyType(undefined);
    }

    closeMarkerWindow();
  }, [closeMarkerWindow]);

  const onExpiredChange = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setExpired(e.target.value);
    closeMarkerWindow();
  }, [closeMarkerWindow]);

  if (error) {
    return (
      <Message
        type="critical"
        message={CRAWLED_PROPERTIES_ERROR_MESSAGE}
      />
    );
  }

  if (!data) {
    return (
      <div css={styles.base}>
        <div css={styles.content}>
          <div css={styles.main}>
            <Spinner
              size={SpinnerSize.BIGGER}
            />
          </div>
          <div
            css={styles.side}
            $height={containerStyle.height}
          >
            <div css={styles.info}>
              <Skeleton width="50%" />
              <Skeleton width="60%" />

              <Spacing margin={[200, 0]}>
                <Skeleton
                  width="100%"
                  height="2.5rem"
                />
              </Spacing>
            </div>
            <div css={styles.list}>
              <ul>
                {[...Array(10).keys()].map((i) => (
                  <li
                    key={i}
                    css={styles.item}
                  >
                    <Skeleton width="20%" />
                    <Skeleton width="100%" />
                  </li>
                ))}
              </ul>
            </div>
            <Legend />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div css={styles.base}>
      <div css={styles.content}>
        <div css={styles.main}>
          <SimpleGoogleMap
            latitude={latitude}
            longitude={longitude}
            searchRadius={data?.info.searchRadius}
          >
            {propertiesGroupsEntries && propertiesGroupsEntries.map(([key, value]) => {
              const itemsCount = value.items.length;
              const backgroundColor = itemsCount > 1
                ? PROPERTIES_GROUP_MARKER_COLOR
                : getCrawledPropertyColor(value.items[0].expired, value.items[0].address);
              const symbol = itemsCount > 1
                ? undefined
                : getIconByPropertyType(value.items[0].propertyType);

              return (
                <Marker
                  key={key}
                  data-ref={key}
                  onClick={onMarkerClick(value)}
                  position={{
                    lat: value.latitude,
                    lng: value.longitude,
                  }}
                  icon={{
                    url: getMarkerIcon(backgroundColor, symbol),
                    size: {
                      width: MARKER_SIZE,
                      height: MARKER_SIZE,
                    } as google.maps.Size,
                    origin: {
                      x: 0,
                      y: 0,
                    } as google.maps.Point,
                    anchor: {
                      x: MARKER_SIZE / 2,
                      y: MARKER_SIZE / 2,
                    } as google.maps.Point,
                  }}
                  label={itemsCount > 1 ? {
                    color: '#fff',
                    fontSize: '15px',
                    text: `${itemsCount}`,
                  } : undefined}
                />
              );
            })}
            {currentMarker && (
              <InfoWindow
                position={{
                  lat: currentMarker.latitude,
                  lng: currentMarker.longitude,
                }}
                onCloseClick={closeMarkerWindow}
              >
                <ul data-ref="window-crawled-properties-list">
                  {currentMarker.items.map((transaction) => (
                    <li
                      key={transaction.id}
                      css={styles.item}
                    >
                      <CrawledPropertyCard
                        data={transaction}
                      />
                    </li>
                  ))}
                </ul>
              </InfoWindow>
            )}
          </SimpleGoogleMap>
        </div>
        <div
          css={styles.side}
          $height={containerStyle.height}
        >
          <div css={styles.info}>
            <div
              css={styles.filters}
              $visible={data}
            >
              <Select
                options={expiredOptions}
                value={expired}
                onChange={onExpiredChange}
                dataRef="expired-select"
              />
              <ExpandableContent
                openLabel="Tutti i filtri"
                closeLabel="Nascondi"
              >
                <Spacing margin={[100, 0, 0, 0]}>
                  <Select
                    options={asSelectOptions(residentialPropertyTypeLabels, { value: '', label: 'Tutte le tipologie' })}
                    value={propertyType ?? ''}
                    onChange={onPropertyTypeChange}
                    dataRef="property-type-select"
                  />
                </Spacing>
              </ExpandableContent>
            </div>
            {data.totalElements > 0 && (
              <Spacing margin={[100, 0, 0, 0]}>
                <Typography.BODY
                  color="neutrals.medium"
                  data-ref="summary"
                >
                  {data.totalElements} immobili nel raggio di {data.info.searchRadius ?? NO_VALUE_SYMBOL} m
                  {' · '}
                  {formatEuro(data.stats.averageSquareMeterPrice)}/m²
                </Typography.BODY>
              </Spacing>
            )}
          </div>
          <div css={styles.list}>
            <ul>
              {data.content.length > 0 && (
                data.content.map((crawledProperty) => (
                  <li
                    key={crawledProperty.id}
                    css={styles.item}
                  >
                    <CrawledPropertyCard data={crawledProperty} />
                  </li>
                ))
              )}
            </ul>

            {data.totalElements === 0 && (
              <Spacing margin={[200, 0, 0, 0]}>
                <Message
                  type="warning"
                  message={EMPTY_RESULT_MESSAGE}
                />
              </Spacing>
            )}

            {data.totalElements > MAX_TOTAL_ELEMENTS && (
              <Spacing margin={[200, 0]}>
                <Message
                  type="warning"
                  message={MAX_TOTAL_ELEMENTS_WARNING_MESSAGE}
                />
              </Spacing>
            )}
          </div>
          <Legend />
        </div>
      </div>
    </div>
  );
};

export default ViewCrawledProperties;
