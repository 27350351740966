import React from 'react';
import {
  Action,
  ConfirmModal, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agent } from '../../../providers/api/dtos';
import { updateAgent } from '../../../providers/api/agent/agentProvider';

export const AGENT_DATA_MIGRATION_SUCCESS_MESSAGE = "Il trasferimento dei dati dell'agente è avvenuto con successo";
export const AGENT_DATA_MIGRATION_ERROR_MESSAGE = "Non è stato possibile trasferire i dati dell'agente";

interface ChildFnProps {
  cancelSignatureRequest: VoidFunction,
  isLoading: boolean,
}

export interface MigrateAgentDataActionProps {
  agent: Agent,
  onSuccess?: (agent: Agent) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const MigrateAgentDataAction: React.FC<MigrateAgentDataActionProps> = (props) => {
  const { agent, onSuccess, children } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const confirmMigrationModal = useModal();

  const openConfirmModal = React.useCallback(() => {
    confirmMigrationModal.open();
  }, [confirmMigrationModal]);

  const confirmAgentDataMigration = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const updatedAgent = await updateAgent(agent.id!, {
        ...agent,
        detached: true,
      });

      setIsLoading(false);
      confirmMigrationModal.close();

      addSuccess(AGENT_DATA_MIGRATION_SUCCESS_MESSAGE);
      onSuccess?.(updatedAgent);
    } catch (_) {
      setIsLoading(false);
      addError(AGENT_DATA_MIGRATION_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agent, confirmMigrationModal, onSuccess]);

  return (
    <>
      {
        children
          ? children({ cancelSignatureRequest: openConfirmModal, isLoading })
          : (
            <Action
              aria-label="Trasferisci i dati dell'agente"
              title="Trasferisci i dati dell'agente"
              label="Trasferisci"
              emphasis="high"
              onClick={openConfirmModal}
              loading={isLoading}
            />
          )
      }
      <Portal>
        <ConfirmModal
          isOpen={confirmMigrationModal.isOpen}
          title="Conferma il trasferimento dei dati dell'agente"
          aria-label="Conferma il trasferimento dei dati dell'agente"
          onConfirm={confirmAgentDataMigration}
          onAbort={confirmMigrationModal.close}
        >
          Cliccando sul pulsante OK trasferirai i dati dell&apos;agente al suo manager
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default MigrateAgentDataAction;
