import { AssignmentPropertyStatus } from '../providers/api/dtos';

export const assignmentPropertyStatusLabels: {
  [key in AssignmentPropertyStatus]: string
} = {
  FREE: 'Libero',
  OCCUPIED: 'Occupato',
  UNDER_CONSTRUCTION: 'In costruzione',
  RENTAL: 'Concesso in locazione',
  OTHER: 'Altro',
};
