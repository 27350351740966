import useSWR, { SWRConfiguration } from 'swr';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';
import hash from 'object-hash';
import { AxiosError } from 'axios';
import { getConversations, searchConversations } from '../../../providers/api/conversation/conversationProvider';
import { Conversation } from '../../../providers/api/dtos/conversation';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

export const CONVERSATIONS_RID = '/conversations';

export default function useConversations(
  pagination?: PaginationParams,
  onSuccess?: SWRConfiguration['onSuccess'],
  query?: string,
): SWRCustomResponse<PaginatedResult<Conversation>, Error> {
  const matchMutate = useMatchMutate();
  const key = [CONVERSATIONS_RID, hash({ ...pagination }), query];

  const result = useSWR(key, () => (query ? searchConversations(query, pagination) : getConversations(pagination)), { onSuccess });

  return {
    ...result,
    mutate: () => matchMutate(CONVERSATIONS_RID),
  };
}

const getKey = (pagination?: Omit<PaginationParams, 'page'>, query?: string) => (
  pageIndex: number,
  _previousPageData: PaginatedResult<Conversation> | null,
) => {
  if (!pagination && !query) return null;

  return {
    pagination: {
      ...pagination,
      page: pageIndex,
    },
    query,
  };
};

export const useConversationsInfinite = (pagination?: Omit<PaginationParams, 'page'>, query?: string): SWRInfiniteResponse<PaginatedResult<Conversation>, AxiosError> => {
  const key = getKey(pagination, query);

  return useSWRInfinite(key, ({ pagination: p, query: q }) => (q
    ? searchConversations(q, p)
    : getConversations(p)));
};
