/* eslint-disable react/no-unknown-property */
import {
  IframeWrapper, Icon, Form, ICON_VIRTUAL_TOUR,
  ICON_PLAY_CIRCLE_OUTLINE,
} from '@doveit/bricks';
import React, { FunctionComponent, useMemo } from 'react';
import { MultimediaModel } from '../../models/multimediaModel';
import { virtualTourSchema, videoSchema } from './MultimediaForm.schema';
import { ContentType } from '../../../../domain/types';
import { transformToLabel } from '../../../../utils/property/property';

export interface MultimediaFormProps {
  initialValues: MultimediaModel,
  disabled?: boolean,
  loading?: boolean,
  onSubmit: (values: MultimediaModel) => void,
  contentType: ContentType.VIRTUAL_TOUR | ContentType.VIDEO,
}

export const MultimediaForm: FunctionComponent<MultimediaFormProps> = ({
  initialValues,
  disabled,
  loading,
  onSubmit,
  contentType,
}) => {
  const verbatimMedia = useMemo(() => transformToLabel(contentType), [contentType]);

  const validationSchema = useMemo(() => {
    switch (contentType) {
      case ContentType.VIRTUAL_TOUR:
        return virtualTourSchema;
      case ContentType.VIDEO:
        return videoSchema;
      default:
        return undefined;
    }
  }, [contentType]);

  const icon = useMemo(
    () => (contentType === ContentType.VIDEO
      ? ICON_PLAY_CIRCLE_OUTLINE
      : ICON_VIRTUAL_TOUR),
    [contentType],
  );

  return (
    <Form
      onSubmit={onSubmit}
      disabled={disabled}
      loading={loading}
      initialValues={{ url: initialValues.url || '' }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, errors }) => (
        <>
          <Form.Item size={{ MD: 8 / 10 }}>
            <Form.Input
              aria-label={`Inserisci url del ${verbatimMedia}`}
              name="url"
              disabled={!!initialValues.url}
              prefixElement={(
                <Icon path={icon} />
              )}
            />
          </Form.Item>
          <Form.Item size={{ MD: 2 / 10 }}>
            <Form.Submit
              label={initialValues.url ? 'Elimina' : 'Salva'}
              color={initialValues.url ? 'critical' : 'primary'}
              expanded
            />
          </Form.Item>
          {!errors.url && (
            <Form.Item>
              <IframeWrapper
                src={values.url!}
                title={verbatimMedia}
                width={384}
                height={288}
              />
            </Form.Item>
          )}
        </>
      )}
    </Form>
  );
};
