/* eslint-disable react/no-unknown-property */
import { Form } from '@doveit/bricks';
import React from 'react';
import { DEFAULT_FORM_INITIAL_VALUES } from './constants';
import { ReviewRequestFormModel, ReviewRequestFormProps } from './types';
import reviewRequestFormValidationSchema from './ReviewRequestForm.schema';
import * as styles from './ReviewRequestForm.style';

const ReviewRequestForm: React.FC<ReviewRequestFormProps> = ({
  reviewType,
  submitLabel = 'Invia richiesta',
  initialValues = {},
  ...rest
}) => {
  const mergedInitialValues: ReviewRequestFormModel = React.useMemo(() => ({
    ...DEFAULT_FORM_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);
  const userTypeLabel = React.useMemo(() => (reviewType === 'BUYER' ? 'compratore' : 'venditore'), [reviewType]);

  return (
    <Form<ReviewRequestFormModel>
      initialValues={mergedInitialValues}
      validationSchema={reviewRequestFormValidationSchema}
      {...rest}
    >
      <Form.Item size={{ MD: 3 / 8 }}>
        <Form.Input
          name="userName"
          label={`Nome e cognome ${userTypeLabel}`}
          aria-label={`Campo per inserire il nome e cognome del ${userTypeLabel}`}
        />
      </Form.Item>
      <Form.Item size={{ MD: 3 / 8 }}>
        <Form.Input
          name="userEmail"
          label={`Email ${userTypeLabel}`}
          aria-label={`Campo per inserire l'indirizzo email del ${userTypeLabel}`}
        />
      </Form.Item>
      <Form.Item size={{ MD: 2 / 8 }}>
        <div css={styles.submit}>
          <Form.Submit
            label={submitLabel}
            aria-label={submitLabel}
            expanded
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default ReviewRequestForm;
