import * as Yup from 'yup';
import { EnergyClass } from '@doveit/bricks';

import {
  FurnitureType, OwnershipType, PropertyClass, PropertyStatus, PropertyType, propertyTypesWithoutFloor, PropertyTypeValue, RegistryCategory, RegistryStatus,
} from '../../../domain/types';
import {
  LATITUDE_REGEX, LONGITUDE_REGEX, ZIP_CODE_REGEX, FLOAT_REGEX, NUMBER_REGEX, YEARS_REGEX,
} from '../../../constants';

const ERROR_MESSAGES: { [key: string]: any } = {
  route: 'Il campo indirizzo è obbligatorio',
  postalCode: 'Il CAP deve essere un numero intero di 5 cifre',
  locality: 'È necessario inserire il comune dell\'immobile',
  propertySize: 'La dimensione deve essere un valore intero',
  walkableSize: 'La dimensione deve essere un valore intero',
  yearOfDevelopment: 'L\'anno deve essere un valore numerico intero (maggiore di 1000)',
  privateGarden: 'La dimensione deve essere un valore numerico intero',
  additionalExpenses: 'Le spese condominiali devono essere un valore intero',
  price: 'Il prezzo deve essere un valore numerico intero "457000"',
  minimumPrice: 'Il prezzo minimo accettato deve essere un valore numerico intero "457000"',
  evaluation: 'La valutazione dell\'agente deve essere un valore numerico intero "457000"',
  mainFloor: {
    invalidFloat: 'Il piano principale deve essere un valore numerico valido',
    tooHighValue: 'Il piano principale non deve essere superiore al numero dei piani totali',
  },
  buildingFloors: 'Il numero di piani deve essere un valore numerico',
  coordinate: 'Inserire la coordinata corretta',
  energyValue: 'L\'indice di prestazione energetica deve essere numerico',
  required: (label: string) => `Il campo "${label}" è richiesto`,
};

export const ABSTRACT_MAX_LENGTH = 1000;
export const COMPOSITION_MAX_LENGTH = 1000;
export const DESCRIPTION_MAX_LENGTH = 3000;

export type ValidationType = 'not-residential' | 'residential-not-published' | 'residential-published' | 'residential-sold';

const residentialNotPublishedGeoValidation = {
  route: Yup.string().required(ERROR_MESSAGES.route),
  latitude: Yup.string().matches(LATITUDE_REGEX, ERROR_MESSAGES.coordinate).required(ERROR_MESSAGES.required('latitudine')), // @TODO: remove constraint after that the geoLocate call is handled on Braniac
  longitude: Yup.string().matches(LONGITUDE_REGEX, ERROR_MESSAGES.coordinate).required(ERROR_MESSAGES.required('longitudine')), // @TODO: remove constraint after that the geoLocate call is handled on Braniac
  postalCode: Yup.string().matches(ZIP_CODE_REGEX, ERROR_MESSAGES.postalCode).required(ERROR_MESSAGES.required('codice postale')), // @TODO: remove constraint after that the geoLocate call is handled on Braniac
};

const residentialPublishedGeoValidation = {
  ...residentialNotPublishedGeoValidation,
  latitude: residentialNotPublishedGeoValidation.latitude.required(ERROR_MESSAGES.required('latitudine')),
  longitude: residentialNotPublishedGeoValidation.longitude.required(ERROR_MESSAGES.required('longitudine')),
  postalCode: residentialNotPublishedGeoValidation.postalCode.required(ERROR_MESSAGES.required('codice postale')),
  streetNumber: Yup.string().required(ERROR_MESSAGES.required('numero civico')),
  administrativeAreaLevelOne: Yup.string().required(ERROR_MESSAGES.required('regione')),
  administrativeAreaLevelTwo: Yup.string().required(ERROR_MESSAGES.required('provincia')),
};

const basicValidation = {
  propertySize: Yup.string().matches(FLOAT_REGEX, ERROR_MESSAGES.propertySize),
  price: Yup.string().matches(NUMBER_REGEX, ERROR_MESSAGES.price),
  walkableSize: Yup.string().matches(FLOAT_REGEX, ERROR_MESSAGES.walkableSize),
  yearOfDevelopment: Yup.string().matches(YEARS_REGEX, ERROR_MESSAGES.yearOfDevelopment),
  privateGarden: Yup.string().matches(NUMBER_REGEX, ERROR_MESSAGES.privateGarden),
  additionalExpenses: Yup.string().matches(NUMBER_REGEX, ERROR_MESSAGES.additionalExpenses),
  soldPrice: Yup.string().matches(NUMBER_REGEX, ERROR_MESSAGES.price),
  minimumPrice: Yup.string().matches(NUMBER_REGEX, ERROR_MESSAGES.minimumPrice),
  evaluation: Yup.string().matches(NUMBER_REGEX, ERROR_MESSAGES.evaluation),
  mainFloor: Yup.string()
    .test('is-valid-float',
      ERROR_MESSAGES.mainFloor.invalidFloat,
      (value) => !value || /^((-?0.5)|([0-9]+))$/.test(value))
    .test('is-not-too-high',
      ERROR_MESSAGES.mainFloor.tooHighValue,
      (value, context) => !value || +value <= (context.parent.buildingFloors ?? Number.MAX_SAFE_INTEGER)),
  buildingFloors: Yup.string().matches(NUMBER_REGEX, ERROR_MESSAGES.buildingFloors),
  energyValue: Yup.string().matches(FLOAT_REGEX, ERROR_MESSAGES.energyValue),
  energyClass: Yup.string().oneOf(Object.values(EnergyClass)),
  ownershipType: Yup.string().oneOf(Object.values(OwnershipType)),
  registryCategory: Yup.string().oneOf(Object.values(RegistryCategory)),
  registryStatus: Yup.string().oneOf(Object.values(RegistryStatus)),
  propertyStatus: Yup.string().oneOf(Object.values(PropertyStatus)),
  furniture: Yup.string().oneOf(Object.values(FurnitureType)),
  classType: Yup.string().oneOf(Object.values(PropertyClass)),
};

const residentialNotPublishedValidation = {
  ...basicValidation,
  geo: Yup.object().shape(residentialNotPublishedGeoValidation),
  overriddenGeo: Yup.object().when('enableGeoOverride', ([enableGeoOverride], schema) => (enableGeoOverride ? Yup.object().shape(residentialNotPublishedGeoValidation).required(ERROR_MESSAGES.required('indirizzo di pubblicazione')) : schema)),
  propertyType: Yup.string().oneOf(Object.values(PropertyTypeValue)).required(ERROR_MESSAGES.required('tipologia')),
  propertySize: basicValidation.propertySize.required(ERROR_MESSAGES.required('superficie')),
  price: basicValidation.price.required(ERROR_MESSAGES.required('prezzo')),
};

const residentialPublishedFloorValidation = (
  schema: Yup.StringSchema,
  propertyType: PropertyType,
  label: string,
) => (propertyTypesWithoutFloor.includes(propertyType) ? schema : schema.required(ERROR_MESSAGES.required(label)));

const residentialPublishedValidation = {
  ...residentialNotPublishedValidation,
  geo: Yup.object().shape(residentialPublishedGeoValidation),
  overriddenGeo: Yup.object().when('enableGeoOverride', ([enableGeoOverride], schema) => (enableGeoOverride ? Yup.object().shape(residentialPublishedGeoValidation).required(ERROR_MESSAGES.required('indirizzo di pubblicazione')) : schema)),
  walkableSize: residentialNotPublishedValidation.walkableSize.required(ERROR_MESSAGES.required('area calpestabile')),
  evaluation: residentialNotPublishedValidation.evaluation.required(ERROR_MESSAGES.required('valutazione')),
  mainFloor: residentialNotPublishedValidation.mainFloor.when('propertyType', ([propertyType], schema) => residentialPublishedFloorValidation(schema, propertyType, 'piano')),
  buildingFloors: residentialNotPublishedValidation.buildingFloors.when('propertyType', ([propertyType], schema) => residentialPublishedFloorValidation(schema, propertyType, 'totale piani')),
  energyValue: residentialNotPublishedValidation.energyValue.required(ERROR_MESSAGES.required('indice prest. energetica')),
  energyClass: residentialNotPublishedValidation.energyClass.required(ERROR_MESSAGES.required('classe energetica')),
  ownershipType: residentialNotPublishedValidation.ownershipType.required(ERROR_MESSAGES.required('tipo proprietà')),
  registryCategory: residentialNotPublishedValidation.registryCategory.required(ERROR_MESSAGES.required('categoria catastale')),
  registryStatus: residentialNotPublishedValidation.registryStatus.required(ERROR_MESSAGES.required('stato proprietà')),
  propertyStatus: residentialNotPublishedValidation.propertyStatus.required(ERROR_MESSAGES.required('stato immobile')),
  furniture: residentialNotPublishedValidation.furniture.required(ERROR_MESSAGES.required('arredamento')),
  classType: residentialNotPublishedValidation.classType.required(ERROR_MESSAGES.required('classe immobile')),
};

const residentialSoldValidation = residentialPublishedValidation;

export const editPropertyInfoValidationSchema = (type?: ValidationType) => {
  let shape: Yup.ObjectShape;

  switch (type) {
    case 'residential-not-published':
      shape = residentialNotPublishedValidation;
      break;
    case 'residential-published':
      shape = residentialPublishedValidation;
      break;
    case 'residential-sold':
      shape = residentialSoldValidation;
      break;
    default:
      shape = basicValidation;
  }

  return Yup.object().shape(shape);
};
