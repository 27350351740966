import { DocumentType } from '../domain/types';
import { documentTypeLabels } from '../labels';

export function noop() { }

export function getFormattedSeconds(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  return `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`;
}

export function rejectsNotImplemented() {
  return Promise.reject(new Error('Not implemented'));
}

export const raise = (err: string): never => {
  throw new Error(err);
};

export const sortDocumentsByLabel = (a: DocumentType, b: DocumentType) => documentTypeLabels[a].localeCompare(documentTypeLabels[b]);
