import React from 'react';
import { Action, ICON_PHONE_OUTLINE, useNotifications } from '@doveit/bricks';
import useRBAC from '../../hooks/use-rbac/useRBAC';
import { dialNumber } from '../../providers/aircall/aircallProvider';

export interface AircallButtonProps {
  phoneNumber: string,
  onSuccess?: VoidFunction,
}

export const CALL_CONTACT_ERROR_MESSAGE = 'Non è stato possibile chiamare il contatto';

const AircallButton: React.FC<AircallButtonProps> = ({
  phoneNumber,
  onSuccess,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { addError } = useNotifications();
  const { user } = useRBAC();

  const callContact = React.useCallback(async () => {
    setLoading(true);

    try {
      await dialNumber(user.email, phoneNumber);

      setLoading(false);

      onSuccess?.();
    } catch (error) {
      setLoading(false);
      addError(CALL_CONTACT_ERROR_MESSAGE);
    }
  }, [addError, onSuccess, phoneNumber, user.email]);

  return (
    <Action
      label="Aircall"
      emphasis="high"
      color="primary"
      iconLeft={{ path: ICON_PHONE_OUTLINE }}
      loading={loading}
      onClick={callContact}
    />
  );
};

export default AircallButton;
