import { ApplicationNotificationType } from '../../domain/types';
import ApplicationNotification, {
  IntentNudgeNotification, JobApplicationAssignedNotification, OfferDeedToConfirmNotification, PropertyDocumentUpdatedNotification, ReviewSubmittedNotification,
  ProspectApprovalNotification,
  InvoiceForecastedDateExpiringNotification,
  OfferDocumentUpdatedNotification,
  ProspectNudgeNotification,
  PropertyWithdrawnNotification,
  PropertyPublishedNotification,
  PropertyPreviewNotification,
  AssignmentDigitallySignedNotification,
} from '../../providers/api/dtos/applicationNotification';
import {
  USER_NAME_PLACEHOLDER, applicationNotificationMetadata, DOCUMENT_TYPE_PLACEHOLDER, PROPERTY_ID_PLACEHOLDER, ADDITIONAL_INFO_PLACEHOLDER, CONTACT_NAME_PLACEHOLDER, INTENT_ID_PLACEHOLDER, JOB_APPLICATION_APPLICANT_NAME_PLACEHOLDER, JOB_APPLICATION_POSITION_PLACEHOLDER, JOB_APPLICATION_ID_PLACEHOLDER, PROPERTY_ADDRESS_PLACEHOLDER, TRUSTPILOT_REVIEW_ID_PLACEHOLDER, PROSPECT_ID_PLACEHOLDER, INVOICE_ID_PLACEHOLDER,
} from '../constants';
import { documentTypeLabels } from '../../labels/documentTypeLabels';
import { template, TemplateMap } from '../../utils/text/text';
import { ApplicationNotificationMetadata, PrintableApplicationNotification } from '../types';

interface TemplateMaps {
  title?: TemplateMap,
  message?: TemplateMap,
  url?: TemplateMap,
  actionUrl?: TemplateMap,
  additionalInfo?: TemplateMap,
}

function buildApplicationNotification(
  notification: ApplicationNotification,
  metadata: ApplicationNotificationMetadata,
  templateMaps: TemplateMaps,
): PrintableApplicationNotification {
  const printableNotification = {
    ...metadata,
    id: notification.id,
    senderName: notification.sender?.name,
    date: notification.notificationDate,
    title: templateMaps.title
      ? template(metadata.title, templateMaps.title)
      : metadata.title,
    message: templateMaps.message
      ? template(metadata.message, templateMaps.message)
      : metadata.message,
    read: notification.read,
  };

  if (metadata.url) {
    printableNotification.url = templateMaps.url
      ? template(metadata.url, templateMaps.url)
      : metadata.url;
  }

  if (metadata.actions) {
    printableNotification.actions = metadata.actions.map((action) => ({
      ...action,
      url: templateMaps.actionUrl
        ? template(action.url, templateMaps.actionUrl)
        : action.url,
    }));
  }

  if (notification.additionalInfo) {
    if (metadata.additionalInfo) {
      printableNotification.additionalInfo = templateMaps.additionalInfo
        ? template(metadata.additionalInfo, templateMaps.additionalInfo)
        : metadata.additionalInfo;
    } else {
      printableNotification.additionalInfo = notification.additionalInfo;
    }
  }

  return printableNotification;
}

function buildPrintablePropertyDocumentNotification(
  notification: PropertyDocumentUpdatedNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  return buildApplicationNotification(
    notification,
    metadata,
    {
      title: { [DOCUMENT_TYPE_PLACEHOLDER]: documentTypeLabels[notification.documentType] },
      message: {
        [USER_NAME_PLACEHOLDER]: notification.sender?.name,
        [DOCUMENT_TYPE_PLACEHOLDER]: documentTypeLabels[notification.documentType],
        [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
      },
      url: { [PROPERTY_ID_PLACEHOLDER]: notification.propertyId.toString() },
      actionUrl: { [PROPERTY_ID_PLACEHOLDER]: notification.propertyId.toString() },
      additionalInfo: { [ADDITIONAL_INFO_PLACEHOLDER]: notification.additionalInfo },
    },
  );
}

function buildPrintableOfferDocumentNotification(
  notification: OfferDocumentUpdatedNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  return buildApplicationNotification(
    notification,
    metadata,
    {
      title: { [DOCUMENT_TYPE_PLACEHOLDER]: documentTypeLabels[notification.documentType] },
      message: {
        [USER_NAME_PLACEHOLDER]: notification.sender?.name,
        [DOCUMENT_TYPE_PLACEHOLDER]: documentTypeLabels[notification.documentType],
        [INTENT_ID_PLACEHOLDER]: notification.intentId,
        [CONTACT_NAME_PLACEHOLDER]: notification.contactName,
        [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
      },
      url: { [INTENT_ID_PLACEHOLDER]: notification.intentId.toString() },
      actionUrl: { [INTENT_ID_PLACEHOLDER]: notification.intentId.toString() },
      additionalInfo: { [ADDITIONAL_INFO_PLACEHOLDER]: notification.additionalInfo },
    },
  );
}

function buildPrintableIntentNudgeNotification(
  notification: IntentNudgeNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = { [INTENT_ID_PLACEHOLDER]: notification.intentId };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      message: {
        [USER_NAME_PLACEHOLDER]: notification.sender?.name,
        [CONTACT_NAME_PLACEHOLDER]: notification.contact,
        [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
      additionalInfo: { [ADDITIONAL_INFO_PLACEHOLDER]: notification.additionalInfo },
    },
  );
}

function buildPrintableProspectNudgeNotification(
  notification: ProspectNudgeNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = { [PROSPECT_ID_PLACEHOLDER]: notification.prospectId };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      message: {
        [USER_NAME_PLACEHOLDER]: notification.sender?.name,
        [CONTACT_NAME_PLACEHOLDER]: notification.contact,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
      additionalInfo: { [ADDITIONAL_INFO_PLACEHOLDER]: notification.additionalInfo },
    },
  );
}

function buildPrintableJobApplicationAssignedNotification(
  notification: JobApplicationAssignedNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = { [JOB_APPLICATION_ID_PLACEHOLDER]: notification.jobApplicationId };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      message: {
        [JOB_APPLICATION_APPLICANT_NAME_PLACEHOLDER]: notification.applicant,
        [JOB_APPLICATION_POSITION_PLACEHOLDER]: notification.jobPosition,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
    },
  );
}

function buildPrintableOfferDeedToConfirmNotification(
  notification: OfferDeedToConfirmNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = { [PROPERTY_ID_PLACEHOLDER]: notification.propertyId };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      title: { [PROPERTY_ID_PLACEHOLDER]: notification.propertyId },
      message: {
        [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
        [PROPERTY_ADDRESS_PLACEHOLDER]: notification.propertyAddress,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
    },
  );
}

function buildPrintableReviewSubmittedNotification(
  notification: ReviewSubmittedNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = {
    [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
    [TRUSTPILOT_REVIEW_ID_PLACEHOLDER]: notification.trustpilotReviewId,
  };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      title: { [PROPERTY_ID_PLACEHOLDER]: notification.propertyId },
      message: {
        [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
        [PROPERTY_ADDRESS_PLACEHOLDER]: notification.propertyAddress,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
    },
  );
}

function buildPrintableProspectApprovalNotification(
  notification: ProspectApprovalNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = { [PROSPECT_ID_PLACEHOLDER]: notification.prospectId };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      message: {
        [USER_NAME_PLACEHOLDER]: notification.sender?.name,
        [PROPERTY_ADDRESS_PLACEHOLDER]: notification.propertyAddress,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
    },
  );
}

function buildPrintableInvoiceForecastedDateExpiringNotification(
  notification: InvoiceForecastedDateExpiringNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = {
    [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
    [INVOICE_ID_PLACEHOLDER]: notification.invoiceId,
  };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      message: {
        [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
        [PROPERTY_ADDRESS_PLACEHOLDER]: notification.propertyAddress,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
    },
  );
}

function buildPrintablePropertyWithdrawnNotification(
  notification: PropertyWithdrawnNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = { [PROPERTY_ID_PLACEHOLDER]: notification.propertyId };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      message: {
        [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
    },
  );
}

function buildPrintablePropertyPublishedNotification(
  notification: PropertyPublishedNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = { [PROPERTY_ID_PLACEHOLDER]: notification.propertyId };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      message: {
        [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
    },
  );
}

function buildPrintablePropertyPreviewNotification(
  notification: PropertyPreviewNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = { [PROPERTY_ID_PLACEHOLDER]: notification.propertyId };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      message: {
        [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
    },
  );
}

function buildPrintableAssignmentDigitallySignedNotification(
  notification: AssignmentDigitallySignedNotification,
  metadata: ApplicationNotificationMetadata,
): PrintableApplicationNotification {
  const urlPlaceholders = { [PROPERTY_ID_PLACEHOLDER]: notification.propertyId };

  return buildApplicationNotification(
    notification,
    metadata,
    {
      message: {
        [CONTACT_NAME_PLACEHOLDER]: notification.contact,
        [PROPERTY_ID_PLACEHOLDER]: notification.propertyId,
      },
      url: urlPlaceholders,
      actionUrl: urlPlaceholders,
    },
  );
}

// Printable Application Notification
export function buildPrintableApplicationNotification(applicationNotification: ApplicationNotification): PrintableApplicationNotification | undefined {
  const { type } = applicationNotification;

  const metadata = applicationNotificationMetadata[type];

  switch (type) {
    case ApplicationNotificationType.PROPERTY_DOCUMENT_REVIEW:
    case ApplicationNotificationType.PROPERTY_DOCUMENT_APPROVED:
    case ApplicationNotificationType.PROPERTY_DOCUMENT_REJECTED:
      return buildPrintablePropertyDocumentNotification(applicationNotification as PropertyDocumentUpdatedNotification, metadata);
    case ApplicationNotificationType.OFFER_DOCUMENT_APPROVED:
    case ApplicationNotificationType.OFFER_DOCUMENT_REVIEW:
    case ApplicationNotificationType.OFFER_DOCUMENT_REJECTED:
      return buildPrintableOfferDocumentNotification(applicationNotification as OfferDocumentUpdatedNotification, metadata);
    case ApplicationNotificationType.INTENT_NUDGE:
      return buildPrintableIntentNudgeNotification(applicationNotification as IntentNudgeNotification, metadata);
    case ApplicationNotificationType.PROSPECT_NUDGE:
      return buildPrintableProspectNudgeNotification(applicationNotification as ProspectNudgeNotification, metadata);
    case ApplicationNotificationType.JOB_APPLICATION_ASSIGNED:
      return buildPrintableJobApplicationAssignedNotification(applicationNotification as JobApplicationAssignedNotification, metadata);
    case ApplicationNotificationType.OFFER_DEED_TO_CONFIRM:
      return buildPrintableOfferDeedToConfirmNotification(applicationNotification as OfferDeedToConfirmNotification, metadata);
    case ApplicationNotificationType.REVIEW_SUBMITTED:
      return buildPrintableReviewSubmittedNotification(applicationNotification as ReviewSubmittedNotification, metadata);
    case ApplicationNotificationType.PROSPECT_APPROVAL_WAITING_FOR_SUPERVISOR:
    case ApplicationNotificationType.PROSPECT_APPROVAL_WAITING_FOR_AGENT:
    case ApplicationNotificationType.PROSPECT_APPROVAL_APPROVED:
      return buildPrintableProspectApprovalNotification(applicationNotification as ProspectApprovalNotification, metadata);
    case ApplicationNotificationType.INVOICE_FORECASTED_DATE_EXPIRING:
      return buildPrintableInvoiceForecastedDateExpiringNotification(applicationNotification as InvoiceForecastedDateExpiringNotification, metadata);
    case ApplicationNotificationType.PROPERTY_WITHDRAWN:
      return buildPrintablePropertyWithdrawnNotification(applicationNotification as PropertyWithdrawnNotification, metadata);
    case ApplicationNotificationType.PROPERTY_PUBLISHED:
      return buildPrintablePropertyPublishedNotification(applicationNotification as PropertyPublishedNotification, metadata);
    case ApplicationNotificationType.PROPERTY_PREVIEW:
      return buildPrintablePropertyPreviewNotification(applicationNotification as PropertyPreviewNotification, metadata);
    case ApplicationNotificationType.ASSIGNMENT_DIGITALLY_SIGNED:
      return buildPrintableAssignmentDigitallySignedNotification(applicationNotification as AssignmentDigitallySignedNotification, metadata);
    default:
      return undefined;
  }
}
