import { API_TERRACE_PATH, API_PROPERTIES_PATH, getClient } from '../api';
import { Terrace } from '../dtos';

export async function createTerrace(terrace: Terrace): Promise<Terrace> {
  const client = await getClient();
  const { data } = await client.post<Terrace>(API_TERRACE_PATH, terrace);
  return data;
}

export async function getTerrace(terraceId: string | number): Promise<Terrace> {
  const client = await getClient();
  const { data } = await client.get<Terrace>(`${API_TERRACE_PATH}/${terraceId}`);
  return data;
}

export async function updateTerrace(terrace: Terrace): Promise<Terrace> {
  const client = await getClient();
  const { data } = await client.put<Terrace>(`${API_TERRACE_PATH}/${terrace.id}`, terrace);
  return data;
}

export async function deleteTerrace(terraceId: string | number): Promise<{ id: number | string }> {
  const client = await getClient();
  const { data } = await client.delete(`${API_TERRACE_PATH}/${terraceId}`);
  return data;
}

export async function getTerracesByPropertyId(propertyId: number): Promise<Terrace[]> {
  const client = await getClient();
  const { data } = await client.get(`${API_PROPERTIES_PATH}/${propertyId}/terraces`);
  return data;
}
