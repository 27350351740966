import hash from 'object-hash';
import useSWR from 'swr';
import { SWRCustomResponse } from '../../../hooks/types';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SerpRumor } from '../../../providers/api/dtos';
import { getSerpRumors, SerpRumorProviderFilters } from '../../../providers/api/serp-rumors/getSerpRumors';

export const RUMORS_SERP_RID = '/api/v1/serp/rumors';

export function useSerpRumors(
  filters: SerpRumorProviderFilters = {},
  pagination: PaginationParams = { size: 20 },
): SWRCustomResponse<PaginatedResult<SerpRumor>, Error> {
  const matchMutate = useMatchMutate();
  const key = [RUMORS_SERP_RID, hash({ ...filters, ...pagination })];
  const result = useSWR(key, () => getSerpRumors(filters, pagination));

  return {
    ...result,
    mutate: () => matchMutate(RUMORS_SERP_RID),
  };
}
