import { API_PROPERTIES_CONTACTS_PATH, getClient } from '../api';
import { PropertyContactReference } from '../dtos';

export async function createPropertyContact(propertyContactReference: PropertyContactReference): Promise<PropertyContactReference> {
  const client = await getClient();
  const { data } = await client.post<PropertyContactReference>(API_PROPERTIES_CONTACTS_PATH, propertyContactReference);

  return data;
}

export async function updatePropertyContact(propertyContactReference: PropertyContactReference): Promise<PropertyContactReference> {
  const client = await getClient();
  const { data } = await client.put<PropertyContactReference>(API_PROPERTIES_CONTACTS_PATH, propertyContactReference);

  return data;
}

export async function deletePropertyContact(propertyContactReference: PropertyContactReference): Promise<void> {
  const client = await getClient();
  await client.delete(API_PROPERTIES_CONTACTS_PATH, {
    data: propertyContactReference,
  });
}
