export enum ReminderType {
  TOMORROW = 'TOMORROW',
  NEXT_WEEK = 'NEXT_WEEK',
  OTHER = 'OTHER',
}

export interface ReminderFormModel {
  id?: string;
  type?: ReminderType;
  date?: string;
  time?: string;
  notes?: string;
}
