export enum Status {
  LIVE = 'LIVE',
  DRAFT = 'DRAFT',
  ANTEPRIMA = 'ANTEPRIMA',
  VENDUTO = 'VENDUTO',
  RITIRATO = 'RITIRATO',
  PROPOSTA = 'PROPOSTA',
}

export const ALL_STATUS = Object.values(Status);

export const INVOICEABLE_STATUSES = [Status.LIVE, Status.PROPOSTA, Status.VENDUTO];
