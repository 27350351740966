import useSWR, { SWRConfiguration } from 'swr';
import hash from 'object-hash';
import { AxiosError } from 'axios';
import { OfferStatus } from '../../../domain/types';
import { Offer } from '../../../providers/api/dtos/offer';
import { getOffersByPropertyId } from '../../../providers/api/offer/offerProvider';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';

export const OFFERS_BY_PROPERTY_ID_RID = '/property/:id/offers';

export default function useOffersByPropertyId(
  propertyId?: number,
  status?: OfferStatus | OfferStatus[],
  options?: SWRConfiguration<Offer[], AxiosError>,
): SWRCustomResponse<Offer[], AxiosError> {
  const matchMutate = useMatchMutate();

  const rid = [OFFERS_BY_PROPERTY_ID_RID, propertyId].join();
  const key = propertyId ? [rid, status && hash({ status })] : null;
  const result = useSWR(key, () => getOffersByPropertyId(propertyId!, status), options);

  return {
    ...result,
    mutate: () => matchMutate(rid),
  };
}
