import { ProspectStatus } from '../../../../domain/types';
import { ContactProspectsFilters } from '../../../../providers/api/contact/contactProvider';

export const CONTACT_PROSPECTS_LOAD_ERROR = 'Non è stato possibile caricare i dati relativi alle valutazioni del contatto.';

export const EXCLUDED_PROSPECT_STATUS = [ProspectStatus.ASSIGNMENT_CREATED];

export const CONTACT_ACTIVITIES_SUMMARY_PROSPECTS_FILTERS: ContactProspectsFilters = {
  excludeStatus: EXCLUDED_PROSPECT_STATUS,
};

// The lower the number, the higher the priority
export const PROSPECT_STATUS_SORT_ORDER: Record<ProspectStatus, number> = {
  [ProspectStatus.IN_PROGRESS]: 0,
  [ProspectStatus.ONLY_EVALUATION]: 1,
  [ProspectStatus.OVER_PRICED]: 2,
  [ProspectStatus.CANCELLED]: 2,
  [ProspectStatus.UNSELLABLE]: 2,
  [ProspectStatus.NOT_INTERESTED]: 2,
  [ProspectStatus.SOLD]: 2,
  [ProspectStatus.ASSIGNMENT_CREATED]: Number.POSITIVE_INFINITY,
};

export const PROSPECT_KO_STATUSES = [
  ProspectStatus.UNSELLABLE,
  ProspectStatus.CANCELLED,
  ProspectStatus.OVER_PRICED,
  ProspectStatus.NOT_INTERESTED,
  ProspectStatus.SOLD,
];

export const PROSPECT_ACTIVE_STATUSES = [
  ProspectStatus.IN_PROGRESS,
  ProspectStatus.ONLY_EVALUATION,
  ProspectStatus.ASSIGNMENT_CREATED,
];
