import { WhatsAppTemplate } from '../../../types';
import { ConversationMessagePaginationParams, PaginatedConversationMessageResult, serializeConversationMessagePagination } from '../../conversationMessagePagination';
import { PaginatedResult, PaginationParams, serializePagination } from '../../pagination';
import { API_CONVERSATION_PATH, getClient } from '../api';
import { Conversation } from '../dtos/conversation';
import { ConversationStart } from '../dtos/conversationStart';
import { ConversationTemplate } from '../dtos/conversationTemplate';
import { serializeParams } from '../utils/serializer';

export interface ConversationTemplateFilters {
  includeNames?: WhatsAppTemplate[],
}

export async function getConversations(pagination: PaginationParams = {}): Promise<PaginatedResult<Conversation>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Conversation>>(API_CONVERSATION_PATH, {
    params: {
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getConversationMessages(conversationId: string, pagination: ConversationMessagePaginationParams = {}): Promise<PaginatedConversationMessageResult> {
  const client = await getClient();

  const { data } = await client.get<PaginatedConversationMessageResult>(`${API_CONVERSATION_PATH}/${conversationId}/messages`, {
    params: {
      ...serializeConversationMessagePagination(pagination),
    },
  });

  return data;
}

export async function createConversationsMessagingSubscription(payload: { token: string }): Promise<void> {
  const client = await getClient();
  await client.post<void>(`${API_CONVERSATION_PATH}/messaging-subscriptions`, payload);
}

export async function markAllConversationMessagesAsRead(conversationId: string): Promise<void> {
  const client = await getClient();

  await client.put<void>(`${API_CONVERSATION_PATH}/${conversationId}/messages/mark-all-read`);
}

export async function createConversationMessage(conversationId: string, message: string): Promise<void> {
  const client = await getClient();

  await client.post<void>(`${API_CONVERSATION_PATH}/${conversationId}/messages`, { body: message });
}

export async function getConversationTemplates(filters: ConversationTemplateFilters = {}): Promise<ConversationTemplate[]> {
  const client = await getClient();

  const { data } = await client.get<ConversationTemplate[]>(`${API_CONVERSATION_PATH}/templates`, {
    params: serializeParams(filters),
  });

  return data;
}

export async function searchConversations(
  query: string,
  pagination: PaginationParams = {},
): Promise<PaginatedResult<Conversation>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Conversation>>(`${API_CONVERSATION_PATH}/search`, {
    params: {
      q: query,
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function startConversation(payload: ConversationStart): Promise<void> {
  const client = await getClient();

  await client.post<Conversation>(`${API_CONVERSATION_PATH}/start`, payload);
}
