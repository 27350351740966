import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import objectHash from 'object-hash';
import { Contact } from '../../../providers/api/dtos';
import { matchContact } from '../../../providers/api/contact/contactProvider';

export type UseContactMatchesFilters = {
  email?: string,
  phoneNumber?: string,
};

export const CONTACT_MATCHES_RID = '/contacts/match';

export default function useContactMatches(filters?: UseContactMatchesFilters, options?: SWRConfiguration): SWRResponse<Contact[], Error> {
  const key = filters ? [CONTACT_MATCHES_RID, objectHash(filters)] : null;

  return useSWR(key, () => matchContact(filters!), options);
}
