import hash from 'object-hash';
import useSWR from 'swr';
import { MatchingSearchCriteria } from '../../../providers/api/dtos';
import { getRumorMatchingSearchCriteria } from '../../../providers/api/rumor/rumorProvider';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

const RUMOR_MATCHING_SEARCH_CRITERIA_RID = '/rumors/:id/matching-search-criteria';

export function useRumorMatchingSearchCriteria(rumorId?: number, pagination: PaginationParams = {}): SWRCustomResponse<PaginatedResult<MatchingSearchCriteria>, Error> {
  const matchMutate = useMatchMutate();
  const rid = [RUMOR_MATCHING_SEARCH_CRITERIA_RID, rumorId].join();
  const key = rumorId ? [rid, hash(pagination)] : null;
  const result = useSWR(key, () => getRumorMatchingSearchCriteria(rumorId!, pagination));
  return {
    ...result,
    mutate: () => matchMutate(rid),
  };
}
