import { Avatar, AvatarProps, ICON_HOME_SEARCH_OUTLINE } from '@doveit/bricks';
import React from 'react';

export type SearchCriteriaAvatarProps = Pick<AvatarProps, 'size' | 'placeholder'>;

export const SearchCriteriaAvatar: React.FC<SearchCriteriaAvatarProps> = ({ size }) => (
  <Avatar
    placeholder={ICON_HOME_SEARCH_OUTLINE}
    size={size}
  />
);

export default SearchCriteriaAvatar;
