import { EnergyClass, PropertyUnitIntendedUse, RegistryCategory } from '../../../domain/types';
import { PropertyUnit } from '../../../providers/api/dtos';
import { PropertyUnitFormModel } from './PropertyUnitForm';

export const toPropertyUnit = (propertyUnitValues: PropertyUnitFormModel): PropertyUnit => {
  const {
    category,
    energyClass,
    intendedUse,
    parcel,
    section,
    sheet,
    subunit,
    value,
  } = propertyUnitValues;

  return {
    category: category in RegistryCategory ? category as RegistryCategory : undefined,
    energyClass: energyClass in EnergyClass ? energyClass as EnergyClass : undefined,
    intendedUse: intendedUse in PropertyUnitIntendedUse ? intendedUse as PropertyUnitIntendedUse : undefined,
    parcel: parcel || undefined,
    section: section || undefined,
    sheet: sheet || undefined,
    subunit: subunit || undefined,
    value: value ? parseFloat(value) : undefined,
  };
};

export const toPropertyUnitFormModel = (propertyUnit: PropertyUnit): PropertyUnitFormModel => {
  const {
    category,
    energyClass,
    intendedUse,
    parcel,
    section,
    sheet,
    subunit,
    value,
  } = propertyUnit;

  return {
    category: typeof category !== 'undefined' ? category : '',
    energyClass: typeof energyClass !== 'undefined' ? energyClass : '',
    intendedUse: typeof intendedUse !== 'undefined' ? intendedUse : '',
    parcel: typeof parcel !== 'undefined' ? parcel : '',
    section: typeof section !== 'undefined' ? section : '',
    sheet: typeof sheet !== 'undefined' ? sheet : '',
    subunit: typeof subunit !== 'undefined' ? subunit : '',
    value: typeof value !== 'undefined' ? `${value}` : '',
  };
};
