import React from 'react';
import {
  Badge, BadgeProps, ICON_CHART_LINE_VARIANT,
} from '@doveit/bricks';
import { propertySellabilityRankLabels } from '../../../labels';
import { PropertySellabilityRank } from '../../types';

export interface PropertySellabilityIndicatorProps {
  rank: PropertySellabilityRank,
  size?: BadgeProps['size']
}

export const PropertySellabilityIndicator: React.FC<PropertySellabilityIndicatorProps> = ({
  rank,
  size,
}) => {
  const hasHighRank = React.useMemo(
    () => [PropertySellabilityRank.HIGH, PropertySellabilityRank.HIGHEST].includes(rank),
    [rank],
  );

  return (
    <Badge
      icon={ICON_CHART_LINE_VARIANT}
      label={propertySellabilityRankLabels[rank]}
      size={size}
      color={hasHighRank ? 'success' : 'critical'}
    />
  );
};

export default PropertySellabilityIndicator;
