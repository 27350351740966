import { Text, View } from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';

import * as styles from './Reference.style';

export interface ReferenceProps {
  reference: string;
  agent: {
    name: string;
    phone: string;
    email: string;
  };
  company: {
    name: string;
    address: string;
    phone: string;
  };
}

export const Reference: FunctionComponent<ReferenceProps> = ({
  reference,
  agent,
  company,
}) => (
  <View>
    <View style={styles.box}>
      <Text style={styles.label as any}>Riferimento</Text>
      <Text style={styles.value}>{reference}</Text>
    </View>
    <View style={styles.box}>
      <Text style={styles.label as any}>Agente</Text>
      <Text style={styles.value}>{agent.name}</Text>
      <Text style={styles.value}>
        Tel:&nbsp;{agent.phone}
      </Text>
      <Text style={styles.value}>
        Email:&nbsp;{agent.email}
      </Text>
    </View>
    <View style={styles.lastBox}>
      <Text style={styles.label as any}>{company.name}</Text>
      <Text style={styles.value}>{company.address}</Text>
      <Text style={styles.value}>
        Tel:&nbsp;{company.phone}
      </Text>
    </View>
  </View>
);
