import { FileMimeType, NavigationItem } from '../../../types';

export const RESOURCES_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    id: 'documentation',
    label: 'Documentazione',
    path: '/documentation',
  },
  {
    id: 'partners',
    label: 'Partner',
    path: '/partners',
  },
  {
    id: 'training',
    label: 'Formazione',
    path: '/training',
  },
];

export const AGENT_TRIAL_RESOURCES_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    id: 'documentation',
    label: 'Documentazione',
    path: '/documentation',
  },
];

export const DOCUMENTATION_STORAGE_BASE_URL = 'https://storage.cloud.google.com/doveit-documentation/';

export const DOCUMENTATION_DOCUMENTS = [
  {
    title: 'Incarico di mediazione',
    files: [
      {
        type: FileMimeType.DOCX,
        name: 'Incarico di mediazione.docx',
      },
      {
        type: FileMimeType.PDF,
        name: 'Incarico di mediazione.pdf',
      },
    ],
  },
  {
    title: 'Incarico di mediazione - bozza',
    files: [
      {
        type: FileMimeType.PDF,
        name: 'Incarico di mediazione - bozza.pdf',
      },
    ],
  },
  {
    title: 'Incarico di mediazione - lusso',
    files: [
      {
        type: FileMimeType.DOCX,
        name: 'Incarico di mediazione - lusso.docx',
      },
      {
        type: FileMimeType.PDF,
        name: 'Incarico di mediazione - lusso.pdf',
      },
    ],
  },
  {
    title: 'Allegato incarico - visibilità extra',
    files: [
      {
        type: FileMimeType.DOCX,
        name: 'Allegato incarico - Visibilità Extra.docx',
      },
      {
        type: FileMimeType.PDF,
        name: 'Allegato incarico - Visibilità Extra.pdf',
      },
    ],
  },
  {
    title: 'Allegato incarico - documentazione',
    files: [
      {
        type: FileMimeType.DOCX,
        name: 'Allegato incarico - documentazione.docx',
      },
      {
        type: FileMimeType.PDF,
        name: 'Allegato incarico - documentazione.pdf',
      },
    ],
  },
  {
    title: 'Proposta di acquisto',
    files: [
      {
        type: FileMimeType.DOCX,
        name: 'Proposta di acquisto.docx',
      },
      {
        type: FileMimeType.PDF,
        name: 'Proposta di acquisto.pdf',
      },
    ],
  },
  {
    title: 'Proposta di acquisto - bozza',
    files: [
      {
        type: FileMimeType.PDF,
        name: 'Proposta di acquisto - bozza.pdf',
      },
    ],
  },
  {
    title: 'Antiriciclaggio - scheda valutazione rischio',
    files: [
      {
        type: FileMimeType.PDF,
        name: 'Antiriciclaggio - scheda valutazione rischio.pdf',
      },
    ],
  },
  {
    title: 'Antiriciclaggio - dichiarazione cliente',
    files: [
      {
        type: FileMimeType.PDF,
        name: 'Antiriciclaggio - dichiarazione cliente.pdf',
      },
    ],
  },
  {
    title: 'Delega per l\'accesso alle planimetrie',
    files: [
      {
        type: FileMimeType.PDF,
        name: 'Delega accesso planimetrie.pdf',
      },
    ],
  },
  {
    title: 'Delega a SPF per accesso agli atti',
    files: [
      {
        type: FileMimeType.DOCX,
        name: 'Delega accesso atti.docx',
      },
    ],
  },
];

export const TRAINING_DOCUMENTS = [
  {
    title: 'Thanos',
    description: 'Come funziona il gestionale di Dove.it: video di formazione periodici e approfondimenti specifici sulle nuove funzionalità.',
    link: 'https://drive.google.com/drive/u/1/folders/1khEVz3-JI4SxHDG0KlVWH62HGvO5OMMT?usp=drive_link',
  },
  {
    title: 'Modulo 1 - Obiettivi e Ricerca Notizie',
    description: 'Cos\'è Dove.it, come settare obiettivi di notizie e come ricercare potenziali venditori.',
    link: 'https://drive.google.com/drive/folders/1pnG6yr2i81aX0NcxXo3EgM-IQTTYXj35?usp=drive_link',
  },
  {
    title: 'Modulo 2 - Preparazione Valutazione immobile',
    description: 'Come rispondere alle obiezioni pre appuntamento, quali fonti guardare per preparare la valutazione e come utilizzare il compravenduto.',
    link: 'https://drive.google.com/drive/folders/1dOzGVlGWStP2nN4P16p1tSPrEgQclhMb?usp=drive_link',
  },
  {
    title: 'Modulo 3 - Acquisizione e gestione del mandato di vendita',
    description: 'Come prendere il mandato e rispondere alle obiezioni in fase di valutazione; utilizzo Thanos nell\'inserimento del mandato e relative info necessarie; vendita pacchetti restyling e extra visibilità; documentazione necessaria e servizio CasaOk.',
    link: 'https://drive.google.com/drive/folders/1LY02d7bv8vRTAPYKB7HHN733c4kagGyF?usp=drive_link',
  },
  {
    title: 'Modulo 4 - Gestione venditore, acquirente, proposta e vendita',
    description: 'Gestione venditore per abbassamento prezzo e relativi servizi extra che possono essere offerti per velocizzare la vendita (consulenza mutuo), per avere un preventivo notarile o di ristrutturazione; info su proposta e come gestire la trattativa, fatturazione e post vendita.',
    link: 'https://drive.google.com/drive/u/0/folders/1w4xdVUCiCLMnPilzAdoCasBPcQ-Gmlxg?usp=drive_link',
  },
  {
    title: 'Documentazione tecnica SPF',
    description: 'La documentazione necessaria per concludere una vendita e in cosa consiste il pacchetto CasaOk.',
    link: 'https://drive.google.com/drive/u/0/folders/11U27gQ2-8a8qBEJW6pJRP2MPK2iY9f1z?usp=drive_link',
  },
];

export const PARTNERS = [
  {
    name: 'Credipass',
    tag: 'Mutui',
    description: '',
    link: 'https://www.credipass.it/landing-broker/?tab=mutuo&Canale=DOVE.IT&Sottocanale=&user=&TipoDiTasso=F&importoRichiesto=130000&valoreImmobile=150000&durataAnni=30&CodiceImmobile=nd&Email_Conv={AGENT_EMAIL}',
  },
  {
    name: 'Notaio.io',
    tag: 'Notaio',
    description: 'Partnership attiva solo nelle province di: BG, BO, BS, CO, CR, LC, LO, LT, MB, MI, NA, PC, PD, PV, RM, TO, TV, VA, VI, VR',
    link: 'https://notaio.io/login',
  },
  {
    name: 'Semplicasa',
    tag: 'Ristrutturazioni',
    description: 'Partnership attiva solo nelle province di Milano, Monza, Bergamo, Lodi, Pavia, Varese',
    link: 'https://semplicasa.com/segnalazione-immobile-dv-ag/',
  },
  {
    name: 'Metacasa',
    tag: 'Ristrutturazioni',
    description: 'Partnership attiva solo in Emilia Romagna',
    link: 'https://4yhax95wc7e.typeform.com/to/AY9qhcm9?typeform-source=www.google.com',
  },
];
