import hash from 'object-hash';
import useSWR, { SWRConfiguration } from 'swr';
import { Reminder } from '../../../providers/api/dtos/reminder';
import { getReminders, RemindersProviderFilters } from '../../../providers/api/reminder/reminderProvider';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

export const REMINDERS_RID = '/v2/reminders/';

export default function useReminders(
  filters?: RemindersProviderFilters,
  pagination?: PaginationParams,
  options?: SWRConfiguration,
): SWRCustomResponse<PaginatedResult<Reminder>, Error> {
  const matchMutate = useMatchMutate();
  const key = [REMINDERS_RID, hash({ ...filters, ...pagination })];
  const result = useSWR(key, () => getReminders(filters, pagination), options);

  return {
    ...result,
    mutate: () => matchMutate(REMINDERS_RID),
  };
}
