import { SelectOption } from '@doveit/bricks';

export interface EmptySelectOption extends SelectOption {
  value: '';
}

export const DEFAULT_EMPTY_SELECT_OPTION: EmptySelectOption = {
  value: '',
  label: 'Seleziona',
  disabled: false,
};

export const asSelectOptions = <T extends Record<SelectOption['value'], SelectOption['label']>>(
  options: T,
  emptyOption: EmptySelectOption | false = DEFAULT_EMPTY_SELECT_OPTION,
): SelectOption[] => {
  const selectOptions = Object.entries(options).map(([value, label]) => ({
    value,
    label,
  }));

  return emptyOption ? [emptyOption, ...selectOptions] : selectOptions;
};
