export const CommonDocumentTypeValue = {
  ASSIGNMENT: 'ASSIGNMENT',
  IDENTITY_DOCUMENT: 'IDENTITY_DOCUMENT',
  CADASTRAL_SURVEY: 'CADASTRAL_SURVEY',
  MORTGAGE_SURVEY: 'MORTGAGE_SURVEY',
  DEED_OF_PROVENANCE: 'DEED_OF_PROVENANCE',
  PROSPECT_REPORT: 'PROSPECT_REPORT',
} as const;

export const PropertyDocumentTypeValue = {
  ...CommonDocumentTypeValue,
  APE: 'APE',
  FISCAL_CODE: 'FISCAL_CODE',
  PLANIMETRY: 'PLANIMETRY',
  APARTMENT_BLOCK_RULES: 'APARTMENT_BLOCK_RULES',
  RESTYLING: 'RESTYLING',
} as const;

export const OfferDocumentTypeValue = {
  PURCHASE_PROPOSAL: 'PURCHASE_PROPOSAL',
  PENDING_PROPOSAL: 'PENDING_PROPOSAL',
  ANTI_MONEY_LAUNDERING: 'ANTI_MONEY_LAUNDERING',
  BUYER_IDENTITY_DOCUMENT: 'BUYER_IDENTITY_DOCUMENT',
} as const;

export const ProspectDocumentTypeValue = {
  ...CommonDocumentTypeValue,
  PLANIMETRY_AUTHORIZATION: 'PLANIMETRY_AUTHORIZATION',
} as const;

export const RumorDocumentTypeValue = {
  CADASTRAL_SURVEY: 'CADASTRAL_SURVEY',
  PROSPECT_REPORT: 'PROSPECT_REPORT',
} as const;

export const DocumentTypeValue = { ...PropertyDocumentTypeValue, ...OfferDocumentTypeValue, ...ProspectDocumentTypeValue } as const;
export type PropertyDocumentType = keyof typeof PropertyDocumentTypeValue;
export type OfferDocumentType = keyof typeof OfferDocumentTypeValue;
export type ProspectDocumentType = keyof typeof ProspectDocumentTypeValue;
export type RumorDocumentType = keyof typeof RumorDocumentTypeValue;

export type DocumentType = OfferDocumentType | PropertyDocumentType | ProspectDocumentType | RumorDocumentType;
