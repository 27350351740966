import { Grid } from '@doveit/bricks';
import React, { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { Applicant, JobApplication } from '../../../providers/api/dtos';
import CreateJobApplicationWithApplicant from '../../containers/create-job-application-with-applicant/CreateJobApplicationWithApplicant';

const CreateJobApplicationWithApplicantPage: FunctionComponent = () => {
  const navigate = useNavigate();

  const navigateTo = useCallback((_: Applicant, jobApplication: JobApplication) => {
    navigate(`/job-applications/${jobApplication.id}`);
  }, [navigate]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent>
          <Grid>
            <Grid.Unit>
              <CreateJobApplicationWithApplicant
                onSuccess={navigateTo}
              />
            </Grid.Unit>
          </Grid>
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default CreateJobApplicationWithApplicantPage;
