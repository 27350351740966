import useSWR from 'swr';
import hash from 'object-hash';
import { matchProperties } from '../../../providers/api/property/propertyProvider';
import { PaginationParams, PaginatedResult } from '../../../providers/pagination';
import { Property } from '../../../providers/api/dtos/property';
import { SearchCriteria } from '../../../providers/api/dtos/searchCriteria';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';
import { WithRequiredKeys } from '../../../types';

const MATCH_PROPERTIES_RID = '/properties/match';

export default function useMatchProperties(
  searchCriteria?: SearchCriteria,
  pagination?: PaginationParams,
  exactMatch: boolean = false,
): SWRCustomResponse<PaginatedResult<WithRequiredKeys<Property, 'id' | 'referenceId'>>, Error> {
  const matchMutate = useMatchMutate();

  const key = searchCriteria ? [MATCH_PROPERTIES_RID, hash({ ...searchCriteria, ...pagination, exactMatch })] : null;
  const result = useSWR(key, () => matchProperties(searchCriteria!, pagination, exactMatch));

  return {
    ...result,
    mutate: () => matchMutate(MATCH_PROPERTIES_RID),
  };
}
