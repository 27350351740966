import { getTrustpilotClient } from '../../clients/trustpilotClient';
import { TrustpilotServiceReview } from '../dtos';

export async function getTrustpilotServiceReview(id: TrustpilotServiceReview['id']) {
  const trustpilotClient = await getTrustpilotClient();

  const { data } = await trustpilotClient.get<TrustpilotServiceReview>(`/reviews/${id}`);

  return data;
}
