/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ExpandableContent, FontWeight, Message, Action, ICON_HANDSHAKE_OUTLINE, ICON_CARD_ACCOUNT_DETAILS_OUTLINE, Card, BreakpointQueryName, Dropdown, Text, HStack, Stack, DetailItemList, Typography,
} from '@doveit/bricks';
import hash from 'object-hash';
import { formatDate } from '@doveit/hammer';
import { isBefore } from 'date-fns';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import useAgency from '../../hooks/use-agency/useAgency';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import { splitTextToNearestString } from '../../../utils/text/text';
import { MembershipLegalRole, AgentStatus, JobTitle } from '../../../domain/types';
import { membershipLegalRoleLabels } from '../../../labels';
import { commissionSchemaLabels } from '../../../labels/commissionSchemaLabels';
import { getCommissionSchema } from '../../../agent/utils/getCommissionSchema';
import { buildPrintableCommissionsDetails } from '../../../agent/pages/view/mappers';
import useAgents from '../../../hooks/use-agents/useAgents';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import { Agent } from '../../../providers/api/dtos';
import ViewAgentsByFiltersAction from '../../../agent/containers/view-agents-by-filters-action/ViewAgentsByFiltersAction';
import RightColumnPageLayout from '../../../layouts/right-column-page-layout/RightColumnPageLayout';
import UpdateAgencyStatus from '../../containers/update-agency-status/UpdateAgencyStatus';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import EditAgencyContractAction from '../../containers/edit-agency-contract-action/EditAgencyContractAction';
import EditAgencyCommissionsAction from '../../containers/edit-agency-commissions-action/EditAgencyCommissionsAction';
import CreateAgentAction from '../../../agent/containers/create-agent-action/CreateAgentAction';
import EditAgencyRegistryAction from '../../containers/edit-agency-registry-action/EditAgencyRegistryAction';
import { AgencyAvatar } from '../../components';
import { mapToAgencyPreviewStatus } from '../../components/constants';
import EditAgencyDescriptionAction from '../../containers/edit-agency-description-action/EditAgencyDescriptionAction';
import MembershipStatusBadge from '../../../agent/components/membership-status-badge/MembershipStatusBadge';
import { calculateMembershipStatus } from '../../../agent/utils/calculateMembershipStatus';
import RightColumnPageSkeleton from '../../../components/page-skeleton/RightColumnPageSkeleton';

export const LOAD_AGENCY_ERROR_MESSAGE = "Non è stato possibile caricare le informazioni dell'agenzia";

const ViewAgencyPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { breakpoints } = useTheme();
  const isTabletOrBigger = useMediaQuery(breakpoints.up(BreakpointQueryName.LG));

  const { userIsAdmin, userIsHR, userIsCallCenter } = useRBAC();

  const agencyId = id ? Number.parseInt(id, 10) : undefined;

  const {
    data: agency,
    isLoading: isAgencyLoading,
    mutate: mutateAgency,
    error: agencyError,
  } = useAgency(agencyId);

  const agencyDescription = React.useMemo(
    () => splitTextToNearestString(agency?.description, '.', 300),
    [agency?.description],
  );

  const commissionsDetails = React.useMemo(() => buildPrintableCommissionsDetails(agency?.commissions), [agency]);

  const formattedEndDate = React.useMemo(() => {
    const { endDate } = agency || {};

    if (!endDate) {
      return undefined;
    }

    const endDateValue = formatDate(new Date(endDate));

    return isBefore(new Date(endDate), new Date())
      ? endDateValue
      : (
        <Typography.BODY color="status.alert">
          {endDateValue}
        </Typography.BODY>
      );
  }, [agency]);

  const { data: brokers } = useAgents({
    agencyId,
    jobTitle: JobTitle.BROKER,
    active: AgentStatus.ACTIVE,
  });

  const agencyStatus = React.useMemo(() => {
    if (agency) {
      return calculateMembershipStatus(
        new Date(agency.startDate),
        agency.endDate ? new Date(agency.endDate) : undefined,
      );
    }

    return undefined;
  }, [agency]);

  const internalMutateAgency = React.useCallback(async () => {
    await mutateAgency();
  }, [mutateAgency]);

  const goToAgentPage = React.useCallback((agentId: NonNullable<Agent['id']>) => () => {
    navigate(`/admin/agents/${agentId}`);
  }, [navigate]);

  const onNewAgent = React.useCallback(async (newAgent: Agent) => {
    goToAgentPage(newAgent.id!)();
  }, [goToAgentPage]);

  if (agencyError) {
    return <GenericErrorPage title={LOAD_AGENCY_ERROR_MESSAGE} />;
  }

  if (isAgencyLoading) {
    return <RightColumnPageSkeleton />;
  }

  return agency! && (
    <RightColumnPageLayout
      title={(
        <HStack gap={200}>
          <AgencyAvatar
            status={agencyStatus && mapToAgencyPreviewStatus[agencyStatus]}
            franchising={agency.franchising}
            photoId={agency.photoId}
            size="M"
          />
          <div>
            <Text.H4
              fontWeight={FontWeight.LIGHT}
              as="div"
            >
              Dettaglio agenzia
            </Text.H4>
            <Text.H4>
              {agency.name}
            </Text.H4>
          </div>
        </HStack>
      )}
      actions={(userIsAdmin || userIsHR) && (
        <CreateAgentAction
          agencies={[agency]}
          modalTitle="Assumi agente"
          onSuccess={onNewAgent}
        >
          {({ isLoading, openModal }) => (
            <Action
              aria-label="Assumi nuovo agente"
              label="Assumi"
              onClick={openModal}
              emphasis="high"
              loading={isLoading}
            />
          )}
        </CreateAgentAction>
      )}
      primarySide={(
        <>
          {/** Agency team section */}
          <Card>
            <Card.Header isSecondary>
              {(userIsAdmin || userIsHR) ? (
                <UpdateAgencyStatus
                  agency={agency}
                  onSuccess={internalMutateAgency}
                  emphasis="high"
                />
              ) : (
                <MembershipStatusBadge
                  status={agencyStatus!}
                  emphasis="high"
                />
              )}
            </Card.Header>
            <Card.Content aria-label="Sezione stato agenzia">
              <DetailItemList>
                <DetailItemList.Item label="Data inizio">
                  {formatDate(new Date(agency.startDate))}
                </DetailItemList.Item>
                {agency.endDate && (
                  <DetailItemList.Item label="Data fine">
                    {formattedEndDate}
                  </DetailItemList.Item>
                )}
                <DetailItemList.BooleanItem label="Franchising" value={agency.franchising} />
              </DetailItemList>
            </Card.Content>
            <Card.Divider />
            <Card.Header
              title="Team"
              icon={{ path: 'M13.07 10.41A5 5 0 0 0 13.07 4.59A3.39 3.39 0 0 1 15 4A3.5 3.5 0 0 1 15 11A3.39 3.39 0 0 1 13.07 10.41M5.5 7.5A3.5 3.5 0 1 1 9 11A3.5 3.5 0 0 1 5.5 7.5M7.5 7.5A1.5 1.5 0 1 0 9 6A1.5 1.5 0 0 0 7.5 7.5M16 17V19H2V17S2 13 9 13 16 17 16 17M14 17C13.86 16.22 12.67 15 9 15S4.07 16.31 4 17M15.95 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13Z' }}
              primaryActions={isTabletOrBigger ? [
                <ViewAgentsByFiltersAction
                  filters={{
                    active: AgentStatus.ACTIVE,
                    agencyId: agency.id,
                  }}
                />,
              ] : undefined}
              secondaryActions={isTabletOrBigger
                ? undefined : [
                  <ViewAgentsByFiltersAction
                    filters={{
                      active: AgentStatus.ACTIVE,
                      agencyId: agency.id,
                    }}
                  >
                    {({ viewAgentsByFilters }) => (
                      <Dropdown.Option
                        label="Vedi"
                        aria-label="Visualizza le informazioni degli agenti"
                        onClick={viewAgentsByFilters}
                      />
                    )}
                  </ViewAgentsByFiltersAction>,
                ]}
            />
            <Card.Content>
              <DetailItemList>
                <DetailItemList.Item label="Broker" withoutPadding>
                  {brokers && brokers.totalElements === 0 && (
                    <AgentPreview
                      name="Non assegnato"
                      size="S"
                    />
                  )}
                  {brokers && brokers.totalElements === 1 && (
                    <AgentPreview
                      {...toAgentPreviewProps(brokers.content[0])}
                      size="S"
                      onClick={goToAgentPage(brokers.content[0].id!)}
                    />
                  )}
                  {brokers && brokers.totalElements > 1 && (
                    <ViewAgentsByFiltersAction
                      filters={{
                        active: AgentStatus.ACTIVE,
                        jobTitle: JobTitle.BROKER,
                        agencyId: agency.id,
                      }}
                      modalTitle="Broker"
                    >
                      {({ viewAgentsByFilters }) => (
                        <Action
                          label="Vedi broker"
                          badgeLabel={brokers.totalElements}
                          size="S"
                          onClick={viewAgentsByFilters}
                        />
                      )}
                    </ViewAgentsByFiltersAction>
                  )}
                </DetailItemList.Item>
              </DetailItemList>
            </Card.Content>
          </Card>
        </>
      )}
    >
      {/** Agency Registry section */}
      <Card aria-label="Sezione anagrafica agenzia">
        <Card.Header
          icon={{ path: ICON_CARD_ACCOUNT_DETAILS_OUTLINE }}
          title="Anagrafica"
          primaryActions={isTabletOrBigger ? [
            (userIsAdmin || userIsHR) && (
              <EditAgencyRegistryAction
                agency={agency}
                onSuccess={internalMutateAgency}
              />
            ),
          ] : undefined}
          secondaryActions={isTabletOrBigger
            ? undefined : [
              (userIsAdmin || userIsHR) && (
                <EditAgencyRegistryAction
                  agency={agency}
                  onSuccess={internalMutateAgency}
                >
                  {({ openEditModal, isSaving }) => (
                    <Dropdown.Option
                      label="Modifica"
                      aria-label="Modifica l'anagrafica dell'agenzia"
                      title="Modifica l'anagrafica dell'agenzia"
                      onClick={openEditModal}
                      loading={isSaving}
                    />
                  )}
                </EditAgencyRegistryAction>
              ),
            ]}
        />

        <Card.Content>
          <DetailItemList>
            <DetailItemList.Item label="Telefono">
              {agency.phoneNumber}
            </DetailItemList.Item>
            <DetailItemList.Item label="Indirizzo">
              {agency.address.normalizedAddress}
            </DetailItemList.Item>
            <DetailItemList.Item label="Orari di apertura">
              {(agency.openingHours && agency.openingHours.length > 0) ? (
                <Stack>
                  {agency.openingHours?.map((hour, i) => (
                    <div key={hash({ hour, i })}>{hour}</div>
                  ))}
                </Stack>
              ) : undefined}
            </DetailItemList.Item>
          </DetailItemList>
        </Card.Content>

        <Card.Divider />

        <div aria-label="Sezione descrizione agenzia">
          <Card.Header
            title="Descrizione"
            primaryActions={isTabletOrBigger ? [
              <EditAgencyDescriptionAction
                agency={agency}
                modalTitle={!agency.description ? 'Aggiungi descrizione' : 'Modifica descrizione'}
                onSuccess={internalMutateAgency}
              >
                {({ openEditModal, isLoading }) => (
                  <Action
                    aria-label={!agency.description ? "Aggiungi la descrizione dell'agenzia" : "Modifica la descrizione dell'agenzia"}
                    label={!agency.description ? 'Aggiungi' : 'Modifica'}
                    size="S"
                    onClick={openEditModal}
                    loading={isLoading}
                  />
                )}
              </EditAgencyDescriptionAction>,
            ] : undefined}
            secondaryActions={isTabletOrBigger
              ? undefined : [
                <EditAgencyDescriptionAction
                  agency={agency}
                  modalTitle={!agency.description ? 'Aggiungi descrizione' : 'Modifica descrizione'}
                  onSuccess={internalMutateAgency}
                >
                  {({ openEditModal, isLoading }) => (
                    <Dropdown.Option
                      aria-label={!agency.description ? "Aggiungi la descrizione dell'agenzia" : "Modifica la descrizione dell'agenzia"}
                      label={!agency.description ? 'Aggiungi' : 'Modifica'}
                      onClick={openEditModal}
                      loading={isLoading}
                    />
                  )}
                </EditAgencyDescriptionAction>,
              ]}
            isSecondary
          />
          <Card.Content>
            {agencyDescription.length === 0 && (
              <Message
                type="warning"
                message="La descrizione non è presente"
              />
            )}
            {agencyDescription.length === 1 && agencyDescription[0]}
            {agencyDescription.length === 2 && (
              <>
                {agencyDescription[0]}
                <ExpandableContent openLabel="Mostra altro">
                  {agencyDescription[1]}
                </ExpandableContent>
              </>
            )}
          </Card.Content>
        </div>
      </Card>
      {/** Agency Contract section */}
      {(!userIsCallCenter && agency.franchising) && (
        <Card aria-label="Sezione contrattualistica">
          <Card.Header
            icon={{ path: ICON_HANDSHAKE_OUTLINE }}
            title="Contrattualistica"
            primaryActions={isTabletOrBigger ? [
              (userIsAdmin || userIsHR) && (
                <EditAgencyContractAction
                  agency={agency}
                  onSuccess={internalMutateAgency}
                />
              ),
            ] : undefined}
            secondaryActions={isTabletOrBigger
              ? undefined : [
                (userIsAdmin || userIsHR) && (
                  <EditAgencyContractAction
                    agency={agency}
                    onSuccess={internalMutateAgency}
                  >
                    {({ openEditModal, isSaving }) => (
                      <Dropdown.Option
                        label="Modifica"
                        aria-label="Modifica la contrattualistica dell'agenzia"
                        onClick={openEditModal}
                        loading={isSaving}
                      />
                    )}
                  </EditAgencyContractAction>
                ),
              ]}
          />
          <Card.Content>
            <DetailItemList
              columns={2}
              withoutDividers
            >
              <DetailItemList.Group label="Firmatario collaborazione">
                <DetailItemList.Item label="Tipologia">
                  {agency.legal?.role
                    ? membershipLegalRoleLabels[(agency.legal.role) as MembershipLegalRole]
                    : undefined}
                </DetailItemList.Item>
                <DetailItemList.Item label="Ragione sociale">
                  {agency.legal?.companyName}
                </DetailItemList.Item>
                <DetailItemList.Item label="Partita IVA">
                  {agency.legal?.vatNumber}
                </DetailItemList.Item>
              </DetailItemList.Group>
              <DetailItemList.Group label="Conto corrente">
                <DetailItemList.Item
                  label="IBAN"
                  textToCopy={agency.bankAccount?.iban}
                  inline={!agency.bankAccount?.iban}
                >
                  {agency.bankAccount?.iban}
                </DetailItemList.Item>
                <DetailItemList.Item
                  label="BIC"
                  textToCopy={agency.bankAccount?.bic}
                  inline={!agency.bankAccount?.bic}
                >
                  {agency.bankAccount?.bic}
                </DetailItemList.Item>
                <DetailItemList.Item
                  label="SWIFT"
                  textToCopy={agency.bankAccount?.swift}
                  inline={!agency.bankAccount?.swift}
                >
                  {agency.bankAccount?.swift}
                </DetailItemList.Item>
              </DetailItemList.Group>
            </DetailItemList>
          </Card.Content>
          <Card.Divider />
          <Card.Header
            title="Provvigioni"
            primaryActions={isTabletOrBigger ? [
              (userIsHR || userIsAdmin) && (
                <EditAgencyCommissionsAction
                  agency={agency}
                  onSuccess={internalMutateAgency}
                />
              ),
            ] : undefined}
            secondaryActions={isTabletOrBigger
              ? undefined : [
                (userIsHR || userIsAdmin) && (
                  <EditAgencyCommissionsAction
                    agency={agency}
                    onSuccess={internalMutateAgency}
                  >
                    {({ openEditModal, isSaving }) => (
                      <Dropdown.Option
                        label="Modifica"
                        aria-label="Modifica le provvigioni dell'agenzia"
                        title="Modifica le provvigioni dell'agenzia"
                        onClick={openEditModal}
                        loading={isSaving}
                      />
                    )}
                  </EditAgencyCommissionsAction>
                ),
              ]}
            isSecondary
          />
          <Card.Content>
            <DetailItemList>
              <DetailItemList.Item label="Schema">
                {agency.commissions
                  ? commissionSchemaLabels[getCommissionSchema(agency.commissions)]
                  : undefined}
              </DetailItemList.Item>
              <DetailItemList.Item label="Dettagli">
                {commissionsDetails.length > 0
                  ? commissionsDetails.map((detail, i) => <span key={hash({ detail, i })}>{detail.label}: <strong>{detail.value}</strong>. </span>)
                  : undefined}
              </DetailItemList.Item>
              {agency.commissions?.notes && (
                <DetailItemList.Item label="Note">
                  {agency.commissions.notes}
                </DetailItemList.Item>
              )}
            </DetailItemList>
          </Card.Content>
        </Card>
      )}
    </RightColumnPageLayout>
  );
};

export default ViewAgencyPage;
