import {
  Action, FormHandlers, Message, Portal, Spacing, useModal, useNotifications,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { updateAssignment } from '../../../providers/api/assignment/assignmentProvider';
import { Assignment, Property } from '../../../providers/api/dtos';
import EditPropertyAssignmentForm, { EditPropertyAssignmentFormModel } from '../../assignment/components/edit-property-assignment-form/EditPropertyAssignmentForm';
import { toAssignment, toPropertyAssignmentModel } from '../../assignment/mappers/assignmentMapper';
import useAssignmentByPropertyId from '../../hooks/use-assignment-by-property-id/useAssignmentByPropertyId';
import { useAgent } from '../../../hooks/use-agent/useAgent';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface EditPropertyAssignmentActionProps {
  propertyId: NonNullable<Property['id']>,
  limitEdit?: boolean,
  onSuccess?: (updatedAssignment: Assignment) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_PROPERTY_ASSIGNMENT_IS_LIMITED_WARNING_MESSAGE = 'Se hai necessità di fare ulteriori modifiche contatta il team content.';
export const EDIT_PROPERTY_ASSIGNMENT_SUCCESS_MESSAGE = 'Il mandato è stato modificato con successo';
export const EDIT_PROPERTY_ASSIGNMENT_ERROR_MESSAGE = 'Non è stato possibile modificare il mandato';

const EditPropertyAssignmentAction: React.FC<EditPropertyAssignmentActionProps> = ({
  propertyId,
  limitEdit = false,
  children,
  onSuccess,
}) => {
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const { data: assignment } = useAssignmentByPropertyId(propertyId);
  const { data: agent } = useAgent(assignment?.agentId);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const openEditModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const onSubmit = React.useCallback(async (formValues: EditPropertyAssignmentFormModel) => {
    if (!assignment) return;

    setIsSaving(true);

    try {
      const payload = toAssignment(formValues, propertyId, assignment);
      const updatedAssignment = await updateAssignment(payload);

      setIsSaving(false);
      addSuccess(EDIT_PROPERTY_ASSIGNMENT_SUCCESS_MESSAGE);
      modal.close();

      onSuccess?.(updatedAssignment);
    } catch (error) {
      setIsSaving(false);
      addError(EDIT_PROPERTY_ASSIGNMENT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, assignment, modal, onSuccess, propertyId]);

  if (!assignment) return null;

  return (
    <>
      {children
        ? children({ openEditModal, isSaving })
        : (
          <Action
            aria-label="Modifica mandato"
            label="Modifica"
            size="S"
            onClick={openEditModal}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Modifica mandato"
          aria-label="Modale per modificare il mandato"
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per modificare il mandato"
              onClick={submitForm}
            />
          )}
        >
          {limitEdit && (
            <Spacing margin={[0, 0, 300]}>
              <Message
                type="warning"
                message={EDIT_PROPERTY_ASSIGNMENT_IS_LIMITED_WARNING_MESSAGE}
              />
            </Spacing>
          )}
          <EditPropertyAssignmentForm
            innerRef={formRef}
            initialValues={toPropertyAssignmentModel(assignment, agent)}
            onSubmit={onSubmit}
            loading={isSaving}
            noSubmit
            hiddenFields={limitEdit ? {
              exclusive: true,
              autoRenew: true,
            } : undefined}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditPropertyAssignmentAction;
