import React from 'react';
import {
  AutoCompleteV2,
  AutoCompleteV2Props,
  ICON_MAGNIFY,
  InputAction,
  InputProps,
} from '@doveit/bricks';
import { PropertiesProviderFilters, searchProperties } from '../../providers/api/property/propertyProvider';
import { PropertyPreview, PropertySearchItem } from '../../providers/api/dtos';
import usePropertyPreview from '../../property/hooks/use-property-preview/usePropertyPreview';

function normalizeSuggestionValue(value: Pick<PropertyPreview, 'referenceId' | 'geo'>) {
  return `(${value.referenceId}) - ${value.geo?.normalizedAddress || ''}`;
}

export interface PropertyAutoCompleteProps extends InputProps {
  maxSuggestions?: number,
  status?: PropertiesProviderFilters['status'],
  agentEmail?: PropertiesProviderFilters['agentEmail'],
  onSuggestionSelected?: AutoCompleteV2Props<PropertyPreview | PropertySearchItem>['onSuggestionSelected'],
}

const PropertyAutoComplete: React.FC<PropertyAutoCompleteProps> = ({
  name,
  value: initialValue,
  status,
  agentEmail,
  maxSuggestions,
  onSuggestionSelected,
  ...rest
}) => {
  const propertyId = initialValue
    ? Number.parseInt(`${initialValue}`, 10)
    : NaN;

  const { data: property, isLoading: isPropertyLoading } = usePropertyPreview(propertyId);

  const [value, setValue] = React.useState<PropertyPreview | PropertySearchItem | null>(null);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    if (property) {
      setValue(property);
      setInputValue(normalizeSuggestionValue(property));
    }
  }, [property]);

  const fetchSuggestions = React.useCallback(async (search: string) => {
    const { content } = await searchProperties(
      search,
      { status, agentEmail },
      maxSuggestions ? { size: maxSuggestions } : undefined,
    );

    return content;
  }, [status, agentEmail, maxSuggestions]);

  const onInputChange = React.useCallback(
    (newValue: string, action: InputAction) => {
      switch (action) {
        case 'input-blur':
          setInputValue(value ? normalizeSuggestionValue(value) : '');
          break;
        case 'input-change':
          setInputValue(newValue);
          setValue(newValue ? value : null);
          break;
        default:
          break;
      }
    },
    [value],
  );

  const onInternalSuggestionSelected = React.useCallback((suggestion: PropertyPreview | PropertySearchItem | null) => {
    setInputValue(suggestion ? normalizeSuggestionValue(suggestion) : '');
    setValue(suggestion);

    onSuggestionSelected?.(suggestion);
  }, [onSuggestionSelected]);

  return (
    <AutoCompleteV2
      {...rest}
      name={name}
      suggestions={fetchSuggestions}
      loading={!!propertyId && isPropertyLoading}
      printSuggestion={normalizeSuggestionValue}
      value={value || undefined}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onSuggestionSelected={onInternalSuggestionSelected}
      icon={ICON_MAGNIFY}
    />
  );
};

export default PropertyAutoComplete;
