import React from 'react';
import { ActionDropDown, Dropdown } from '@doveit/bricks';
import { Rumor } from '../../../providers/api/dtos';
import { useComputedRumorStatus } from '../../hooks/use-computed-rumor-status/useComputedRumorStatus';
import RumorStatusBadge from '../../components/rumor-status-badge/RumorStatusBadge';
import { rumorStatusColors, rumorStatusIcons, rumorStatusLabels } from '../../constants';
import { RumorStatus } from '../../../domain/types';
import ArchiveRumorAction from '../archive-rumor-action/ArchiveRumorAction';
import RestoreRumorAction from '../restore-rumor-action/RestoreRumorAction';
import ScheduleAppointment from '../schedule-appointment/ScheduleAppointment';

export interface UpdateRumorStatusProps {
  rumor: Rumor,
  emphasis?: 'low' | 'high',
  size?: 'XS' | 'S',
  showIcon?: boolean,
  onSuccess?: (updatedRumor: Rumor) => void,
}

const UpdateRumorStatus: React.FC<UpdateRumorStatusProps> = ({
  rumor,
  emphasis = 'low',
  size = 'S',
  showIcon = false,
  onSuccess,
}) => {
  const { status, canBeUpdated, canBeUpdatedTo } = useComputedRumorStatus(rumor);

  if (!canBeUpdated) {
    return (
      <RumorStatusBadge
        status={status}
        size={size}
        emphasis={emphasis}
        showIcon={showIcon}
      />
    );
  }

  return (
    <ActionDropDown
      aria-label="Seleziona un nuovo stato della notizia"
      label={rumorStatusLabels[status]}
      color={rumorStatusColors[status]}
      iconLeft={showIcon ? { path: rumorStatusIcons[status] } : undefined}
      emphasis={emphasis}
      size={size}
    >
      {canBeUpdatedTo(RumorStatus.APPOINTMENT_SCHEDULED) && rumor.contactId && (
        <ScheduleAppointment
          rumor={rumor}
          onSuccess={() => onSuccess?.(rumor)}
          aria-label="Pulsante per aggiungere un appuntamento"
        >
          {({ scheduleAppointment }) => (
            <Dropdown.Option
              label="Aggiungi appuntamento"
              onClick={scheduleAppointment}
            />
          )}
        </ScheduleAppointment>
      )}
      {canBeUpdatedTo('ARCHIVED') && (
        <ArchiveRumorAction
          rumor={rumor}
          onSuccess={onSuccess}
          aria-label="Pulsante per archiviare la notizia"
        >
          {({ archiveRumor, isLoading }) => (
            <Dropdown.Option
              label="Archivia"
              loading={isLoading}
              onClick={archiveRumor}
            />
          )}
        </ArchiveRumorAction>
      )}
      {canBeUpdatedTo(RumorStatus.KO_DUPLICATE_LEAD) && (
        <ArchiveRumorAction
          rumor={rumor}
          toStatus={RumorStatus.KO_DUPLICATE_LEAD}
          onSuccess={onSuccess}
          aria-label="Pulsante per archiviare la notizia come duplicato lead"
        >
          {({ archiveRumor, isLoading }) => (
            <Dropdown.Option
              label="Archivia come duplicato lead"
              loading={isLoading}
              onClick={archiveRumor}
            />
          )}
        </ArchiveRumorAction>
      )}
      {canBeUpdatedTo(RumorStatus.IN_PROGRESS) && (
        <RestoreRumorAction
          rumor={rumor}
          onSuccess={onSuccess}
          aria-label="Pulsante per ripristinare la notizia"
        >
          {({ restoreRumor, loading }) => (
            <Dropdown.Option
              label="Ripristina"
              loading={loading}
              onClick={restoreRumor}
            />
          )}
        </RestoreRumorAction>
      )}
    </ActionDropDown>
  );
};

export default UpdateRumorStatus;
