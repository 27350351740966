import {
  ActionDropDown, Badge, Dropdown,
  Skeleton,
  theme,
} from '@doveit/bricks';
import React from 'react';
import { ShootingStatus } from '../../../domain/types';
import { Shooting } from '../../../providers/api/dtos';
import { useComputedShootingStatus } from '../../hooks/use-computed-shooting-status/useComputedShootingStatus';
import MarkShootingAsNotExecutedAction from '../mark-shooting-as-not-executed-action/MarkShootingAsNotExecutedAction';
import MarkShootingAsDeliveredAction from '../mark-shooting-as-delivered-action/MarkShootingAsDeliveredAction';
import CancelShootingAction from '../cancel-shooting-action/CancelShootingAction';
import CompleteShootingAction from '../complete-shooting-action/CompleteShootingAction';
import MarkShootingAsReviewedAction from '../mark-shooting-as-reviewed-action/MarkShootingAsReviewedAction';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { computeShootingStatusStyles } from '../../utils';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';

export interface UpdateShootingStatusProps {
  shooting: Shooting,
  showAsBadge?: boolean,
  emphasis?: 'high' | 'low',
  size?: 'XS' | 'S',
  showIcon?: boolean,
  onSuccess?: (updatedShooting: Shooting) => void,
}

const UpdateShootingStatus: React.FC<UpdateShootingStatusProps> = ({
  shooting,
  showAsBadge = false,
  emphasis = 'low',
  size = 'S',
  showIcon = false,
  onSuccess,
}) => {
  const { userIsAdmin, userIsContentEditor } = useRBAC();
  const { data: agent } = useAgentByPropertyId(shooting.propertyId);
  const userIsPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);
  const { data, isLoading, errors } = useComputedShootingStatus(shooting);
  const { status, canBeUpdated, canBeUpdatedTo } = React.useMemo(
    () => data ?? {
      status: undefined,
      canBeUpdated: false,
      canBeUpdatedTo: () => false,
    }, [data],
  );

  const { icon, color, label } = React.useMemo(
    () => (status
      ? computeShootingStatusStyles(
        status,
        shooting.type,
        userIsAdmin || userIsContentEditor || userIsPropertyAgentOrManager,
      )
      : {
        icon: undefined,
        color: undefined,
        label: undefined,
      }),
    [status, shooting.type, userIsAdmin, userIsContentEditor, userIsPropertyAgentOrManager],
  );

  if (isLoading) {
    return <Skeleton width="10rem" height={theme.remHeight[size]} />;
  }

  if (errors && errors.length > 0) {
    return null;
  }

  if (!status || status === 'NO_STATUS') {
    return null;
  }

  return (
    <>
      {(showAsBadge || !canBeUpdated) && (
        <Badge
          label={label!}
          icon={showIcon ? icon : undefined}
          color={color}
          emphasis={emphasis}
          size={size}
        />
      )}

      {!showAsBadge && canBeUpdated && (
        <ActionDropDown
          aria-label="Selezione dello stato shooting"
          label={label!}
          iconLeft={showIcon && icon ? { path: icon } : undefined}
          emphasis={emphasis}
          size={size}
          color={color}
        >

          {canBeUpdatedTo(ShootingStatus.CANCELLED) && (
            <CancelShootingAction
              shooting={shooting}
              onSuccess={onSuccess}
              aria-label="Pulsante per annullare lo shooting"
            >
              {({ cancel }) => (
                <Dropdown.Option
                  label="Annulla shooting"
                  onClick={cancel}
                />
              )}
            </CancelShootingAction>
          )}

          {canBeUpdatedTo(ShootingStatus.NOT_EXECUTED) && (
            <MarkShootingAsNotExecutedAction
              shooting={shooting}
              onSuccess={onSuccess}
              aria-label="Pulsante per segnare come non eseguito"
            >
              {({ markAsNotExecuted }) => (
                <Dropdown.Option
                  label="Segna come non eseguito"
                  onClick={markAsNotExecuted}
                />
              )}
            </MarkShootingAsNotExecutedAction>
          )}

          {canBeUpdatedTo(ShootingStatus.REVIEWED) && (
            <MarkShootingAsReviewedAction
              shooting={shooting}
              onSuccess={onSuccess}
              aria-label="Pulsante per inserire i dettagli dello shooting"
            >
              {({ markAsReviewed }) => (
                <Dropdown.Option
                  label="Inserisci dettagli shooting"
                  onClick={markAsReviewed}
                />
              )}
            </MarkShootingAsReviewedAction>
          )}

          {canBeUpdatedTo(ShootingStatus.DELIVERED) && (
            <MarkShootingAsDeliveredAction
              shooting={shooting}
              onSuccess={onSuccess}
              aria-label="Pulsante per segnare come consegnato"
            >
              {({ markAsDelivered }) => (
                <Dropdown.Option
                  label="Segna come consegnato"
                  onClick={markAsDelivered}
                />
              )}
            </MarkShootingAsDeliveredAction>
          )}

          {canBeUpdatedTo(ShootingStatus.COMPLETED) && (
            <CompleteShootingAction
              shooting={shooting}
              onSuccess={onSuccess}
              aria-label="Pulsante per confermare il caricamento delle foto"
            >
              {({ complete }) => (
                <Dropdown.Option
                  label="Conferma caricamento foto"
                  onClick={complete}
                />
              )}
            </CompleteShootingAction>
          )}

        </ActionDropDown>
      )}
    </>
  );
};

export default UpdateShootingStatus;
