import React from 'react';
import {
  ActionIcon, Card, FontWeight, HStack, ICON_EYE_OUTLINE, Stack, Text, UserPreview,
} from '@doveit/bricks';
import { SearchCriteria } from '../../../providers/api/dtos';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import { getGeoCriterionLabel } from '../../utils/getGeoCriterionLabel';
import { MatchingSearchCriteriaContact } from '../../types';
import ViewContactPopoverPreview from '../../../contact/containers/view-contact-popover-preview/ViewContactPopoverPreview';
import ViewSearchCriteriaPopoverPreview from '../../container/view-search-criteria-popover-preview/ViewSearchCriteriaPopoverPreview';
import SearchCriteriaAvatar from '../search-criteria-avatar/SearchCriteriaAvatar';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import { residentialPropertyTypeLabels } from '../../../labels';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import DateViewer from '../../../components/date-viewer/DateViewer';

export interface MatchingSearchCriteriaPreviewCardProps {
  matchingSearchCriteria: SearchCriteria,
  contact: MatchingSearchCriteriaContact,
}

const MatchingSearchCriteriaPreviewCard: React.FC<MatchingSearchCriteriaPreviewCardProps> = ({
  matchingSearchCriteria,
  contact,
}) => {
  const {
    id, geo, createdAt,
  } = matchingSearchCriteria;

  const propertyTypes = React.useMemo(() => matchingSearchCriteria?.propertyTypes
    .map((propertyType) => residentialPropertyTypeLabels[propertyType])
    .join(', '), [matchingSearchCriteria?.propertyTypes]);

  const goToSearchCriteriaPage = React.useCallback(() => goToDetailPage(id!, ReferenceType.SEARCH_CRITERIA), [id]);

  return (
    <Card>
      <Card.Header
        primaryActions={[
          <ActionIcon
            label="Visualizza"
            aria-label="Azione per visualizzare la pagina di dettaglio del criterio di ricerca"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToSearchCriteriaPage}
          />,
        ]}
      >
        <ViewContactPopoverPreview
          contact={contact}
          trigger={(
            <UserPreview
              name={contact.name || NO_VALUE_SYMBOL}
              size="S"
            >
              {contact.phoneNumber || NO_VALUE_SYMBOL}
            </UserPreview>
          )}
        />

      </Card.Header>
      <Card.Content>
        <HStack
          gap={150}
          justifyContent="space-between"
          wrap="nowrap"
        >
          <ViewSearchCriteriaPopoverPreview
            searchCriteria={matchingSearchCriteria}
            trigger={(
              <HStack gap={100} wrap="nowrap">
                <SearchCriteriaAvatar size="S" />
                <Stack gap={0}>
                  <Text.BodySmall css={setVerticalEllipsis(1)}>
                    {propertyTypes}
                  </Text.BodySmall>
                  <Text.BodySmall
                    fontWeight={FontWeight.MEDIUM}
                    css={setVerticalEllipsis(1)}
                  >
                    {geo.map(getGeoCriterionLabel).join(', ')}
                  </Text.BodySmall>
                </Stack>
              </HStack>
            )}
          />
          <Stack>
            <Text.Mini transform="uppercase"> Aggiunto </Text.Mini>
            <Text.BodySmall fontWeight={FontWeight.REGULAR}>
              <DateViewer stringDate={createdAt!} />
            </Text.BodySmall>
          </Stack>
        </HStack>
      </Card.Content>
    </Card>
  );
};

export default MatchingSearchCriteriaPreviewCard;
