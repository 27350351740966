import {
  BreakpointQueryName, getSpacing, SpacingName, ThemedFunctor,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const map: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLER)}
  height: 20rem;
`;

export const radius: ThemedFunctor = ({ theme }) => css`
  text-align: center;

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    margin-top: ${theme.spaceUnits.desktop.SMALLER};
  }
`;

export const radiusLegend: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.margin(theme, [SpacingName.SMALL, 0, SpacingName.SMALLEST, 0])}
  display: flex;
  justify-content: space-between;
`;
