import { Badge, BadgeProps } from '@doveit/bricks';
import React, { FunctionComponent } from 'react';
import { DocumentStatus } from '../../../domain/types';
import { documentStatusLabels } from '../../../labels';

export interface DocumentStatusBadgeProps {
  status: DocumentStatus,
}

const documentStatusColors: Record<DocumentStatus, BadgeProps['color']> = {
  [DocumentStatus.DRAFT]: 'critical',
  [DocumentStatus.REVIEW]: 'warning',
  [DocumentStatus.APPROVED]: 'success',
  [DocumentStatus.REJECTED]: 'critical',
};

const DocumentStatusBadge: FunctionComponent<DocumentStatusBadgeProps> = ({
  status,
}) => (
  <Badge
    label={documentStatusLabels[status]}
    color={documentStatusColors[status]}
  />
);

export default DocumentStatusBadge;
