/* eslint-disable react/no-unknown-property */
import { Skeleton, UserPreview } from '@doveit/bricks';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as styles from './UserStatus.style';

const UserStatus: React.FC<React.AriaAttributes> = (props) => {
  const {
    user,
    isLoading,
  } = useAuth0();

  if (isLoading) {
    return (
      <div css={styles.loading}>
        <Skeleton width="7rem" />
      </div>
    );
  }

  return (
    <UserPreview
      {...props}
      size="S"
      avatar={{ src: user?.picture }}
      name={user?.name || 'Utente'}
    />
  );
};

export default UserStatus;
