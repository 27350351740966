/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Tooltip,
  Message,
  ICON_HOME_THERMOMETER_OUTLINE,
  Stack,
  HStack,
  DetailItemList,
  Card,
  Spacing,
  Skeleton,
  Text,
  FontWeight,
  ICON_ALERT_CIRCLE_OUTLINE,
  CardSkeleton,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { Point } from '../../providers/public-api/dtos';
import useGeolocate from '../../hooks/use-geolocate/useGeolocate';
import { SellabilityRange } from '../../domain/types';
import { sellabilityRangeLabel } from '../../labels';
import * as styles from './LocationSellabilityWidget.style';
import { sellabilityRanges } from './constants';
import PropertySellabilityIndicator from '../../property/components/property-sellability-indicator/PropertySellabilityIndicator';
import { mapPropertySellabilityRankToColor } from '../../components/costants';
import ViewPropertySellability from '../../property/containers/view-property-sellability/ViewPropertySellability';
import { NO_VALUE_SYMBOL } from '../../property/utils';

export interface LocationSellabilityWidgetProps extends Point, React.AriaAttributes {
  propertySize?: number,
  asNestedComponent?: boolean,
  header?: boolean,
}

const LocationSellabilityWidget: React.FC<LocationSellabilityWidgetProps> = ({
  latitude,
  longitude,
  propertySize,
  asNestedComponent = false,
  header = true,
  ...rest
}) => {
  const {
    data: geolocationResult,
    isLoading: isGeolocationResultLoading,
    error: geolocationError,
  } = useGeolocate({ latitude, longitude });

  if (geolocationError) {
    return (
      <Card
        aria-label="Errore caricamento informazioni vendibilità"
        asContent={asNestedComponent}
      >
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Valutazione"
          color="critical"
        />
        <Card.Content>
          <Message message="Non è stato possibile caricare le informazioni relative alla vendibilità." />
        </Card.Content>
      </Card>
    );
  }

  if (!geolocationResult || isGeolocationResultLoading) {
    return (
      <CardSkeleton
        aria-label="Informazioni vendibilità in caricamento"
        asContent={asNestedComponent}
      />
    );
  }

  return (
    <Card
      aria-label="Informazioni vendibilità"
      asContent={asNestedComponent}
      {...rest}
    >
      {header && (
        <Card.Header
          title="Vendibilità"
          icon={{ path: ICON_HOME_THERMOMETER_OUTLINE }}
        />
      )}

      <Card.Content aria-label="Dettagli vendibilità">
        <Spacing margin={[0, 0, 150]}>
          <ViewPropertySellability
            geo={{ latitude, longitude }}
            propertySize={propertySize}
            size="XS"
          />
        </Spacing>
        <DetailItemList>
          <DetailItemList.Item
            label="Comune"
            aria-label="Comune"
          >
            {geolocationResult.locationResult.name}
          </DetailItemList.Item>
          <DetailItemList.Item
            label="Costo m²"
            aria-label="Costo m² della zona"
          >
            {geolocationResult.locationResult.squareMeterEvaluation ? formatEuro(geolocationResult?.locationResult.squareMeterEvaluation) : NO_VALUE_SYMBOL}
          </DetailItemList.Item>

          <DetailItemList.Item
            label="Vendibilità m²"
            aria-label="Vendibilità m² della zona"
            inline={false}
            fullWidth
            loading={isGeolocationResultLoading}
            skeleton={<Skeleton count={5} inline width="4.2rem" height="1.5rem" />}
          >
            {geolocationResult.locationResult.sellability ? (
              <HStack gap={25}>
                {Object.values(SellabilityRange).map((sellabilityRange) => {
                  const { rank } = geolocationResult.locationResult.sellability!.data[sellabilityRange];
                  const [lowerBound, upperBound] = sellabilityRanges[sellabilityRange];
                  const isPropertySizeInTheRange = propertySize && (propertySize >= lowerBound && propertySize <= upperBound);

                  return (
                    <Tooltip
                      key={sellabilityRange}
                      content={(
                        <Stack>
                          <Text.BodySmall>
                            Vendibilità {sellabilityRangeLabel(sellabilityRange, { withUnit: true })}:
                          </Text.BodySmall>
                          <PropertySellabilityIndicator
                            rank={rank}
                            size="XS"
                          />
                        </Stack>
                      )}
                    >
                      <div
                        css={styles.sellabilityRangeRank}
                        $color={mapPropertySellabilityRankToColor[rank]}
                        $highlighted={isPropertySizeInTheRange}
                      >
                        <Text.Body fontWeight={FontWeight.REGULAR}>
                          {sellabilityRangeLabel(sellabilityRange)}
                        </Text.Body>
                      </div>
                    </Tooltip>
                  );
                })}
              </HStack>
            ) : NO_VALUE_SYMBOL}
          </DetailItemList.Item>
        </DetailItemList>
      </Card.Content>
    </Card>
  );
};

export default LocationSellabilityWidget;
