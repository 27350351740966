import { LocationSellabilityRank } from '../types';

export const locationSellabilityRankLabels: Record<LocationSellabilityRank, string> = {
  [LocationSellabilityRank.LOWEST]: 'Bassa',
  [LocationSellabilityRank.LOWER]: 'Media',
  [LocationSellabilityRank.LOW]: 'Media',
  [LocationSellabilityRank.MEDIUM]: 'Media',
  [LocationSellabilityRank.HIGH]: 'Alta',
  [LocationSellabilityRank.HIGHEST]: 'Molto alta',
};
