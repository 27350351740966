import { PhoneCallEventDirection } from '../../../domain/types';

export enum CallContextType {
  LEAD = 'LEAD',
  INTENT = 'INTENT',
}

export enum CallMedium {
  AIRCALL = 'AIRCALL',
  MOBILE = 'MOBILE',
}

export interface Call {
  id?: string,
  correlationId?: string,
  medium?: CallMedium,
  direction: PhoneCallEventDirection,
  startedAt: string,
  duration?: number,
  answered: boolean,
  leadTransformationSpecialist: string,
  inboundNumber?: string,
  outboundNumber?: string,
  context: {
    type: CallContextType,
    id: string,
  }
}
