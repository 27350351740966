import React from 'react';
import {
  Action, Form, FormHandlers, Message, ModalSize, Portal, Spacing, Stack, Typography, useModal, useNotifications,
} from '@doveit/bricks';
import { isError } from 'lodash';
import { Property, PublishedOnBatch } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Language } from '../../../types';
import { Aggregator, PropertyClass } from '../../../domain/types';
import ContextualNavigation, { ContextualNavigationItem } from '../../../components/contextual-navigation/ContextualNavigation';
import validationSchema, { PROPERTY_ABSTRACT_MAX_LENGTH, PROPERTY_COMPOSITION_MAX_LENGTH, PROPERTY_DESCRIPTION_MAX_LENGTH } from './EditPropertyDescriptionsAction.schema';
import { raise } from '../../../utils';
import { updateProperty } from '../../../providers/api/property/propertyProvider';
import { updatePublishedOns } from '../../../providers/api/publishedOn/publishedOnProvider';
import * as styles from './EditPropertyDescriptionsAction.style';
import PropertyTextualInfo from '../../components/property-textual-info/PropertyTextualInfo';
import { PropertyDescriptionsFormValues } from '../../../providers/api/dtos/contentProcessing';

export const MISSING_ENGLISH_DESCRIPTION_WARNING_MESSAGE = "Per pubblicare l'annuncio sui portali luxury è necessario compilare il campo Descrizione in lingua inglese";
export const REMOVE_ENGLISH_DESCRIPTIONS_WARNING_MESSAGE = "<strong>Attenzione</strong>: hai rimosso la descrizione in inglese dell'immobile";
export const UPDATE_PROPERTY_DESCRIPTIONS_SUCCESS_MESSAGE = "Le descrizioni dell'immobile sono state aggiornate con successo";
export const UPDATE_PROPERTY_DESCRIPTIONS_ERROR_MESSAGE = "Non è stato possibile aggiornare le descrizioni dell'immobile";
export const CONFIRM_UNPUBLISH_FROM_JE_MESSAGE = "Confermando l'operazione l'annuncio dell'immobile verrà rimosso dal portale luxury James Edition. Annulla e ripristina le condizioni precedenti se vuoi mantenere la pubblicazione.";
export const UNPUBLISH_FROM_JE_SUCCESS_MESSAGE = "La rimozione dell'immobile dal portale JamesEdition è avvenuta con successo";
export const UNPUBLISH_FROM_JE_ERROR_MESSAGE = "Non è stato possibile rimuovere l'immobile dal portale JamesEdition";

interface ChildFnProps {
  disabled: boolean,
  openEditDescriptionsModal: VoidFunction,
}

export interface EditPropertyDescriptionsActionProps {
  property: Property,
  disabled?: boolean,
  onSuccess?: (updatedProperty: Property) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const EditPropertyDescriptionsAction: React.FC<EditPropertyDescriptionsActionProps> = ({
  property,
  disabled = false,
  onSuccess,
  children,
}) => {
  const {
    id,
    price,
    abstract = '',
    abstractEn = '',
    composition = '',
    compositionEn = '',
    description = '',
    descriptionEn = '',
    classType,
    publishedOn,
  } = property;

  const propertyId = id ?? raise('missing property id');

  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const [isSaving, setIsSaving] = React.useState(false);
  const [language, setLanguage] = React.useState<Language>('it');
  const [isJERemovalConfirmOpen, setIsJERemovalConfirmOpen] = React.useState(false);
  const [jeMightBeRemoved, setJEMightBeRemoved] = React.useState(false);

  const editDescriptionsModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const enableEnglishDescriptions = React.useMemo(() => typeof price !== 'undefined'
    && price >= 500_000
    && classType === PropertyClass.LUXURY, [classType, price]);

  const languageSectionTabItems: ContextualNavigationItem[] = React.useMemo(() => [
    {
      id: 'it',
      label: 'Italiano',
      active: language === 'it',
      onClick: () => setLanguage('it'),
    },
    {
      id: 'en',
      label: 'Inglese',
      active: language === 'en',
      onClick: () => setLanguage('en'),
    },
  ], [language]);

  const initialValues: PropertyDescriptionsFormValues = React.useMemo(() => ({
    abstract,
    abstractEn,
    composition,
    compositionEn,
    description,
    descriptionEn,
  }), [abstract, abstractEn, composition, compositionEn, description, descriptionEn]);

  const openEditDescriptionsModal = React.useCallback(() => {
    editDescriptionsModal.open();
  }, [editDescriptionsModal]);

  const updatePropertyDescriptions = React.useCallback(async (values: PropertyDescriptionsFormValues) => {
    const withNewDescriptions: Property = {
      ...property,
      abstract: values.abstract || undefined,
      abstractEn: values.abstractEn || undefined,
      composition: values.composition || undefined,
      compositionEn: values.compositionEn || undefined,
      description: values.description || undefined,
      descriptionEn: values.descriptionEn || undefined,
    };

    const updatedProperty = await updateProperty(propertyId, withNewDescriptions)
      .then((v) => {
        addSuccess(UPDATE_PROPERTY_DESCRIPTIONS_SUCCESS_MESSAGE);
        return v;
      }).catch(() => {
        throw new Error(UPDATE_PROPERTY_DESCRIPTIONS_ERROR_MESSAGE);
      });

    return updatedProperty;
  }, [addSuccess, property, propertyId]);

  const unpublishFromJE = React.useCallback(async () => {
    setIsJERemovalConfirmOpen(false);
    setJEMightBeRemoved(false);

    const publishedOnBatch: PublishedOnBatch = {
      propertyId,
      publishedOns: publishedOn.filter(({ aggregator }) => aggregator !== Aggregator.JAMES_EDITION),
    };

    const updatedPublishedOns = await updatePublishedOns(publishedOnBatch)
      .then((v) => {
        addSuccess(UNPUBLISH_FROM_JE_SUCCESS_MESSAGE);
        return v;
      }).catch(() => {
        throw new Error(UNPUBLISH_FROM_JE_ERROR_MESSAGE);
      });

    return updatedPublishedOns;
  }, [addSuccess, propertyId, publishedOn]);

  const editProperty = React.useCallback(async (values: PropertyDescriptionsFormValues) => {
    try {
      setIsSaving(true);

      let updatedProperty = await updatePropertyDescriptions(values);

      if (jeMightBeRemoved) {
        const updatedPublishedOns = await unpublishFromJE();

        updatedProperty = {
          ...updatedProperty,
          publishedOn: updatedPublishedOns.publishedOns,
        };
      }

      setIsSaving(false);
      editDescriptionsModal.close();

      onSuccess?.(updatedProperty);
    } catch (err) {
      setIsSaving(false);

      if (isError(err)) {
        addError(err.message);
      }
    }
  }, [addError, editDescriptionsModal, jeMightBeRemoved, onSuccess, unpublishFromJE, updatePropertyDescriptions]);

  const isToBeRemovedFromJE = React.useCallback(
    (descEn?: string) => publishedOn.some(({ aggregator }) => aggregator === Aggregator.JAMES_EDITION) && !descEn,
    [publishedOn],
  );

  const submitForm = React.useCallback(() => {
    formRef.current.handleSubmit();
  }, []);

  const onFormSubmit = React.useCallback(async (values: PropertyDescriptionsFormValues) => {
    const showJERemovalConfirm = isToBeRemovedFromJE(values.descriptionEn);

    if (showJERemovalConfirm) {
      setIsJERemovalConfirmOpen(true);
      setJEMightBeRemoved(true);
    } else {
      await editProperty(values);
    }
  }, [isToBeRemovedFromJE, editProperty]);

  return (
    <>
      {children?.({ disabled, openEditDescriptionsModal }) ?? (
        <Action
          label="Modifica"
          size="S"
          aria-label="Modifica descrizioni dell'immobile"
          disabled={disabled}
          onClick={openEditDescriptionsModal}
        />
      )}
      <Portal>
        <SimpleModal
          isOpen={editDescriptionsModal.isOpen}
          close={editDescriptionsModal.close}
          aria-label="Modifica le descrizioni dell'immobile"
          title="Modifica descrizioni"
          size={ModalSize.LARGE}
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Salva le descrizioni aggiornate dell'immobile"
              title="Salva le descrizioni aggiornate dell'immobile"
              onClick={submitForm}
            />
          )}
        >
          {enableEnglishDescriptions && (
            <Spacing margin={[0, 0, 300]}>
              <ContextualNavigation items={languageSectionTabItems} />
            </Spacing>
          )}
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            disabled={isSaving}
            aria-label="Form per la compilazione delle descrizioni dell'immobile"
            innerRef={formRef}
            css={styles.form}
          >
            {({ values, setFieldValue }) => {
              const restoreField = (field: keyof PropertyDescriptionsFormValues) => setFieldValue(field, initialValues[field]);

              return (
                <>
                  <Form.Item>
                    <Stack>
                      <Message
                        type="info"
                        message="Le modifiche saranno apportate al salvataggio"
                      />

                      {classType === PropertyClass.LUXURY && !values.descriptionEn && (
                        <Message
                          type="warning"
                          message={MISSING_ENGLISH_DESCRIPTION_WARNING_MESSAGE}
                        />
                      )}
                    </Stack>
                  </Form.Item>

                  {language === 'it' && (
                    <>
                      <PropertyTextualInfo
                        propertyId={id!}
                        currentValue={values.abstract}
                        fieldLabel="Abstract"
                        fieldName="abstract"
                        fieldMaxLength={PROPERTY_ABSTRACT_MAX_LENGTH}
                        initialValue={initialValues.abstract}
                        language={language}
                        rows={2}
                        onRestore={() => restoreField('abstract')}
                        onValueChange={(value) => setFieldValue('abstract', value)}
                      />
                      <PropertyTextualInfo
                        propertyId={id!}
                        currentValue={values.composition}
                        fieldLabel="Composizione"
                        fieldName="composition"
                        fieldMaxLength={PROPERTY_COMPOSITION_MAX_LENGTH}
                        initialValue={initialValues.composition}
                        language={language}
                        rows={5}
                        onRestore={() => restoreField('composition')}
                        onValueChange={(value) => setFieldValue('composition', value)}
                      />
                      <PropertyTextualInfo
                        propertyId={id!}
                        currentValue={values.description}
                        fieldLabel="Descrizione"
                        fieldName="description"
                        fieldMaxLength={PROPERTY_DESCRIPTION_MAX_LENGTH}
                        initialValue={initialValues.description}
                        language={language}
                        rows={14}
                        onRestore={() => restoreField('description')}
                        onValueChange={(value) => setFieldValue('description', value)}
                      />
                    </>
                  )}
                  {language === 'en' && (
                    <>
                      <PropertyTextualInfo
                        propertyId={id!}
                        currentValue={values.abstractEn}
                        fieldLabel="Abstract"
                        fieldName="abstractEn"
                        fieldMaxLength={PROPERTY_ABSTRACT_MAX_LENGTH}
                        initialValue={initialValues.abstractEn}
                        toTranslate={values.abstract}
                        language={language}
                        rows={2}
                        onRestore={() => restoreField('abstractEn')}
                        onValueChange={(value) => setFieldValue('abstractEn', value)}
                      />
                      <PropertyTextualInfo
                        propertyId={id!}
                        currentValue={values.compositionEn}
                        fieldLabel="Composizione"
                        fieldName="compositionEn"
                        fieldMaxLength={PROPERTY_COMPOSITION_MAX_LENGTH}
                        initialValue={initialValues.compositionEn}
                        toTranslate={values.composition}
                        language={language}
                        rows={5}
                        onRestore={() => restoreField('compositionEn')}
                        onValueChange={(value) => setFieldValue('compositionEn', value)}
                      />
                      <PropertyTextualInfo
                        propertyId={id!}
                        currentValue={values.descriptionEn}
                        fieldLabel="Descrizione"
                        fieldName="descriptionEn"
                        fieldMaxLength={PROPERTY_DESCRIPTION_MAX_LENGTH}
                        initialValue={initialValues.descriptionEn}
                        toTranslate={values.description}
                        language={language}
                        rows={14}
                        onRestore={() => restoreField('descriptionEn')}
                        onValueChange={(value) => setFieldValue('descriptionEn', value)}
                      />
                    </>
                  )}
                  {isToBeRemovedFromJE(values.descriptionEn) && (
                    <Form.Confirm
                      aria-label="Conferma rimozione immobile dal portale JamesEdition"
                      title="Conferma rimozione immobile dal portale JamesEdition"
                      onConfirm={editProperty}
                      onAbort={() => setIsJERemovalConfirmOpen(false)}
                      visible={isJERemovalConfirmOpen}
                      description={(
                        <div>
                          <Message
                            type="warning"
                            message={REMOVE_ENGLISH_DESCRIPTIONS_WARNING_MESSAGE}
                          />
                          <Spacing margin={[200, 0, 0]}>
                            <Typography.BODY>
                              {CONFIRM_UNPUBLISH_FROM_JE_MESSAGE}
                            </Typography.BODY>
                          </Spacing>
                        </div>
                      )}
                      confirmLabel="Conferma"
                    />
                  )}
                </>
              );
            }}
          </Form>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditPropertyDescriptionsAction;
