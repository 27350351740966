/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  ActionIcon,
  Helper, HStack, ICON_PENCIL_OUTLINE, Label, ModalSize, Portal, Spacing, Text, useModal, useNotifications,
} from '@doveit/bricks';
import { DocumentStatus, DocumentType } from '../../../domain/types';
import { Document } from '../../../providers/api/dtos';
import EditDocument, { UpdateDocumentEventType } from '../edit-document/EditDocument';
import * as styles from './ManageDocumentAction.style';
import DocumentLabel from '../../components/document-label/DocumentLabel';
import { documentMetadata } from '../../constants';
import { createDocument } from '../../../providers/api/document/documentProvider';
import { DocumentReferenceType } from '../../../domain/types/documentReferenceType';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { documentTypeLabels } from '../../../labels';
import Divider from '../../../components/divider/Divider';
import DocumentStatusBadge from '../../components/document-status-badge/DocumentStatusBadge';
import ReviewDocument, { EditableFieldState } from '../review-document/ReviewDocument';
import DocumentFilesGallery from '../../components/document-files-gallery/DocumentFilesGallery';
import { documentExists } from './utils';
import { useIsAreaManagerOfDocumentOwnerAgent } from '../../../agent/hooks/use-is-area-manager-of-document-owner-agent/useIsAreaManagerOfDocumentOwnerAgent';

interface ChildrenFnProps {
  isLoading: boolean,
  manageDocument: () => Promise<void>,
}

export interface ManageDocumentActionProps {
  referenceId: string,
  referenceType: DocumentReferenceType,
  documentType: DocumentType,
  document?: Document,
  referenceContext?: DocumentReferenceType,
  children?: (props: ChildrenFnProps) => React.ReactElement,
  onCreate?: (document: Document) => void,
  onUpdate?: (document: Document) => void,
  canManage?: boolean,
}

export const CREATE_DOCUMENT_ERROR_MESSAGE = 'Non è stato possibile aggiungere il documento.';

const ManageDocumentAction: React.FC<ManageDocumentActionProps> = ({
  referenceId,
  referenceType,
  documentType,
  referenceContext,
  document: existingDocument,
  children,
  onCreate,
  onUpdate,
  canManage = true,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    user, userIsAdmin, userIsAgent, userIsContentEditor,
  } = useRBAC();
  const { addError } = useNotifications();
  const editDocumentModal = useModal<Document>();
  const viewDocumentModal = useModal<Document>();

  const isAreaManagerOfOwnerAgent = useIsAreaManagerOfDocumentOwnerAgent(referenceId, referenceType);

  const document: Document = React.useMemo(() => {
    if (existingDocument) {
      return existingDocument;
    }

    const documentToCreate: Document = {
      reference: {
        id: referenceId,
        type: referenceType,
      },
      type: documentType,
      files: [],
      status: DocumentStatus.DRAFT,
      createdBy: user.email,
    };

    return documentToCreate;
  }, [documentType, existingDocument, referenceId, referenceType, user]);

  const canEditDocumentFile = React.useCallback(({ status }: Document) => () => {
    if (userIsAdmin
      || (userIsContentEditor && status !== DocumentStatus.APPROVED)
      || (userIsAgent && (status === DocumentStatus.DRAFT || status === DocumentStatus.REJECTED))
    ) {
      return true;
    }

    return false;
  }, [userIsAdmin, userIsAgent, userIsContentEditor]);

  const manageDocument = React.useCallback(async () => {
    if (!documentExists(document)) {
      try {
        setIsLoading(true);

        const createdDocument = await createDocument(document);

        if (onCreate) {
          onCreate(createdDocument);
        }

        setIsLoading(false);
        editDocumentModal.open(createdDocument);
      } catch (error) {
        setIsLoading(false);
        addError(CREATE_DOCUMENT_ERROR_MESSAGE);
      }

      return;
    }

    if ([DocumentStatus.DRAFT, DocumentStatus.REJECTED].includes(document.status)) {
      editDocumentModal.open(document);

      return;
    }

    viewDocumentModal.open(document);
  }, [addError, document, editDocumentModal, onCreate, viewDocumentModal]);

  const openManageModal = React.useCallback((doc: Document) => () => {
    viewDocumentModal.close();

    editDocumentModal.open(doc);
  }, [editDocumentModal, viewDocumentModal]);

  const canReview = React.useMemo(() => userIsAdmin || userIsContentEditor || isAreaManagerOfOwnerAgent, [isAreaManagerOfOwnerAgent, userIsAdmin, userIsContentEditor]);

  const getReviewNotesState = React.useCallback((doc: Document): EditableFieldState => {
    if (doc && canReview && [DocumentStatus.REVIEW, DocumentStatus.APPROVED].includes(doc.status)) {
      return 'editable';
    }

    if ((userIsAgent || canReview) && doc.status === DocumentStatus.REJECTED) {
      return 'disabled';
    }

    return 'hidden';
  }, [canReview, userIsAgent]);

  const onDocumentReviewed = React.useCallback((doc: Document) => {
    viewDocumentModal.close();

    if (onUpdate) {
      onUpdate(doc);
    }
  }, [onUpdate, viewDocumentModal]);

  const onDocumentUpdated = React.useCallback((doc: Document, operationType: UpdateDocumentEventType) => {
    if (operationType === UpdateDocumentEventType.DOCUMENT_UPDATED) {
      editDocumentModal.close();
    }

    if (onUpdate) {
      onUpdate(doc);
    }
  }, [onUpdate, editDocumentModal]);

  return (
    <>
      <div css={styles.documentLabelWrapper}>
        {children
          ? children({
            isLoading,
            manageDocument,
          })
          : (
            <DocumentLabel
              documentType={documentType}
              referenceType={referenceType}
              referenceContext={referenceContext}
              documentStatus={existingDocument?.status}
              numFiles={existingDocument?.files.length ?? 0}
              onClick={manageDocument}
              disabled={!canManage || isLoading}
            />
          )}
        <Helper content={(documentMetadata[referenceType] as any)[documentType].description} />
      </div>
      <Portal>
        {editDocumentModal.data && (
          <SimpleModal
            {...editDocumentModal}
            aria-label="Modale per l'aggiunta o modifica del documento"
            title={`${editDocumentModal.data.files.length ? 'Modifica' : 'Aggiungi'} ${documentTypeLabels[editDocumentModal.data.type].toLowerCase()}`}
          >
            <Text.Body>
              {(documentMetadata[referenceType] as any)[editDocumentModal.data.type].description}
            </Text.Body>

            <Divider />

            <EditDocument
              documentId={editDocumentModal.data.id!}
              referenceContext={referenceContext}
              canAutoApprove={userIsAdmin || userIsContentEditor || isAreaManagerOfOwnerAgent}
              onSuccess={onDocumentUpdated}
            />
          </SimpleModal>
        )}

        {viewDocumentModal.data && (
          <SimpleModal
            {...viewDocumentModal}
            aria-label="Modale per la visualizzazione del documento"
            title={documentTypeLabels[viewDocumentModal.data.type]}
            size={ModalSize.LARGE}
            fillAvailableHeight
            padded={false}
          >
            <Spacing padding={[0, 200, 200]}>
              <div css={styles.viewDocumentModalHeader}>
                <div>
                  <DocumentStatusBadge status={viewDocumentModal.data.status} />
                </div>
                <HStack>
                  <ActionIcon
                    aria-label="Modifica documento"
                    icon={{ path: ICON_PENCIL_OUTLINE }}
                    label="Modifica"
                    disabled={!canEditDocumentFile(viewDocumentModal.data)}
                    onClick={openManageModal(viewDocumentModal.data)}
                  />
                </HStack>
              </div>
              {viewDocumentModal.data.notes && (
                <Spacing margin={[200, 0, 0]}>
                  <Label text="Note documento" />
                  <Text.Body>
                    {viewDocumentModal.data.notes}
                  </Text.Body>
                </Spacing>
              )}
              <Spacing margin={[200, 0, 0]}>
                <ReviewDocument
                  documentId={viewDocumentModal.data.id!}
                  canReview={userIsAdmin || userIsContentEditor || isAreaManagerOfOwnerAgent}
                  reviewNotesState={getReviewNotesState(viewDocumentModal.data)}
                  onSuccess={onDocumentReviewed}
                />
              </Spacing>
            </Spacing>
            <DocumentFilesGallery files={viewDocumentModal.data.files} />
          </SimpleModal>
        )}
      </Portal>
    </>
  );
};

export default ManageDocumentAction;
