import {
  ThemedFunctor, SpacingName, getSpacing, BreakpointQueryName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const logo: ThemedFunctor = ({ theme }) => css`
  max-width: 10rem;
  margin: 0 auto;

  img {
    width: 100%;
  }

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    max-width: 20rem;
  }
`;

export const game: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.marginTop(theme, SpacingName.MEDIUM)}
`;
