/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as styles from './SimpleTableHeader.style';
import { SimpleTableHeaderCell } from '../header-cell/SimpleTableHeaderCell';

export interface SimpleTableHeaderProps extends React.PropsWithChildren, React.AriaAttributes {
}

export const SimpleTableHeader: React.FC<SimpleTableHeaderProps> = ({
  children,
  ...rest
}) => {
  React.Children.forEach(children as any, (child, i) => {
    if (!child) {
      return null;
    }

    if (child.type !== SimpleTableHeaderCell) {
      throw new Error(`The ${i}-th component child is not a SimpleTable.HeaderCell component`);
    }

    return null;
  });

  return (
    <thead
      aria-label="simple-table-header"
      css={styles.thead}
      {...rest}
    >
      <tr>
        {children}
      </tr>
    </thead>
  );
};
