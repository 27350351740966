import React from 'react';
import {
  ActionIcon,
  ICON_CALENDAR_PLUS_OUTLINE,
  Message, Portal, Spacing, useModal,
} from '@doveit/bricks';
import { Lead } from '../../../providers/api/dtos/lead';
import CreateProspectByLead, { CreateProspectByLeadProps } from '../create-prospect-by-lead/CreateProspectByLead';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { LeadStatus } from '../../../domain/types';
import useFilters, { Filters } from '../../../hooks/use-filters/useFilters';
import useContact from '../../../contact/hooks/use-contact/useContact';

interface ChildFnProps {
  disabled?: boolean,
  openModal: VoidFunction,
}
interface ProspectByLeadFilters extends Filters {
  correlationId?: string;
}

export interface CreateProspectWithAppointmentActionProps {
  lead: Lead,
  disabled?: boolean,
  onSuccess?: VoidFunction,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const SAVE_PROSPECT_INFO_MESSAGE = 'Stai creando una nuova valutazione del contatto __CONTACT_NAME__ in __PROPERTY_ADDRESS__';

const CreateProspectWithAppointmentAction: React.FC<CreateProspectWithAppointmentActionProps> = ({
  lead,
  disabled = false,
  onSuccess,
  children,
}) => {
  const { filters: { correlationId } } = useFilters<ProspectByLeadFilters>();
  const { data: contact } = useContact(lead.contactId);
  const createProspectWithAppointmentModal = useModal<CreateProspectByLeadProps>();

  const actionDisabled = React.useMemo(
    () => disabled || (lead && lead.status === LeadStatus.APPOINTMENT_SCHEDULED),
    [disabled, lead],
  );

  const openModal = React.useCallback(() => {
    createProspectWithAppointmentModal.open();
  }, [createProspectWithAppointmentModal]);

  const onInternalSuccess = React.useCallback(async () => {
    createProspectWithAppointmentModal.close();

    if (onSuccess) {
      onSuccess();
    }
  }, [createProspectWithAppointmentModal, onSuccess]);

  return (
    <>
      {children?.({ disabled: actionDisabled, openModal }) ?? (
        <ActionIcon
          aria-label="Crea valutazione con appuntamento"
          icon={{ path: ICON_CALENDAR_PLUS_OUTLINE }}
          label="Appuntamento"
          disabled={actionDisabled}
          onClick={openModal}
        />
      )}
      <Portal>
        <SimpleModal
          {...createProspectWithAppointmentModal}
          aria-label="Modale per creare una nuova valutazione con relativo appuntamento"
          title="Crea nuova valutazione"
        >
          {contact && (
            <Spacing margin={[0, 0, 200]}>
              <Message
                type="info"
                message={SAVE_PROSPECT_INFO_MESSAGE
                  .replace('__CONTACT_NAME__', contact?.name || contact?.phoneNumber || contact?.email || '')
                  .replace('__PROPERTY_ADDRESS__', lead.propertyAddress)}
              />
            </Spacing>
          )}
          {/**
           * @todo pass `hideSubmit` and handle the submit from here so that the action is always visible
           */}
          <CreateProspectByLead
            lead={lead}
            correlationId={correlationId}
            onSuccess={onInternalSuccess}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default CreateProspectWithAppointmentAction;
