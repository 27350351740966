import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { COUNTRY } from '../../../constants';

const editAgencyRegistryValidationSchema = Yup.object({
  name: Yup
    .string()
    .trim()
    .required('Il campo è obbligatorio'),
  phoneNumber: Yup
    .string()
    .trim()
    .test(
      'validPhoneNumber',
      'Inserisci un numero di telefono valido',
      (value: string | undefined) => !value || isValidPhoneNumber(value, COUNTRY),
    ),
});

export default editAgencyRegistryValidationSchema;
