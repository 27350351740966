import {
  Action,
  useNotifications,
} from '@doveit/bricks';
import React from 'react';
import { Property } from '../../../providers/api/dtos';
import { updateProperty } from '../../../providers/api/property/propertyProvider';

type ChildFnProps = {
  toggle: VoidFunction,
  isSaving: boolean,
};

export interface ToggleHideRequestReviewsPropertyNoticeActionProps {
  property: Property,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (updatedProperty: Property) => void,
}

const ToggleHideRequestReviewsPropertyNoticeAction: React.FC<ToggleHideRequestReviewsPropertyNoticeActionProps> = ({
  property,
  children,
  onSuccess,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();

  const toggle = React.useCallback(async () => {
    setIsSaving(true);

    const propertyToUpdate: Property = {
      ...property,
      hideRequestReviewsNotice: !property.hideRequestReviewsNotice,
    };

    try {
      const updatedProperty = await updateProperty(property.id!, propertyToUpdate);

      setIsSaving(false);
      addSuccess(updatedProperty.hideRequestReviewsNotice
        ? "Le preferenze sono state salvate con successo. D'ora in poi la notifica non sarà più visibile"
        : 'Le preferenze sono state salvate con successo. La notifica è ora visibile');

      onSuccess?.(updatedProperty);
    } catch (_) {
      setIsSaving(false);

      addError('Non è stato possibile salvare le preferenze');
    }
  }, [addError, addSuccess, onSuccess, property]);

  return (
    children
      ? children({
        toggle,
        isSaving,
      })
      : (
        <Action
          label={property.hideRequestReviewsNotice ? 'Mostra richiesta' : 'Salta richiesta'}
          aria-label={property.hideRequestReviewsNotice ? 'Mostra richiesta' : 'Salta richiesta'}
          size="S"
          onClick={toggle}
          loading={isSaving}
        />
      ));
};

export default ToggleHideRequestReviewsPropertyNoticeAction;
