import React from 'react';
import {
  ActionDropDown, Badge, Dropdown, Skeleton,
} from '@doveit/bricks';
import {
  UpdateOfferStatusAction, computedOfferStatusAction, computedOfferStatusActionColors, computedOfferStatusActionIcon,
} from './constants';
import { useComputedOfferStatus } from '../../hooks/use-computed-offer-status/useComputedOfferStatus';
import { ComputedOfferStatus, OfferStatus } from '../../../domain/types';
import ArchiveOfferAction from '../archive-offer-action/ArchiveOfferAction';
import useOfferWizardData from '../../hooks/use-offer-wizard-data/useOfferWizardData';
import CancelOfferSignatureRequestAction from '../cancel-offer-signature-request-action/CancelOfferSignatureRequestAction';
import ConfirmOfferAction from '../confirm-offer-action/ConfirmOfferAction';
import { computedOfferStatusLabels, computedOfferStatusShortLabels } from '../../../labels';
import { Offer } from '../../../providers/api/dtos';
import EditOfferDeedAction from '../edit-offer-deed-action/EditOfferDeedAction';

export interface UpdateOfferStatusProps {
  intentId: string,
  lastOffer?: Offer,
  showAsBadge?: boolean,
  showShortLabel?: boolean,
  showIcon?: boolean,
  onSuccess?: VoidFunction,
  emphasis?: 'low' | 'high',
  size?: 'XS' | 'S',
}

const UpdateOfferStatus: React.FC<UpdateOfferStatusProps> = ({
  intentId,
  lastOffer,
  showAsBadge = false,
  showShortLabel = false,
  showIcon = false,
  onSuccess,
  emphasis = 'low',
  size = 'S',
}) => {
  const { data: offerWizardData } = useOfferWizardData(intentId);
  const { data: offerStatus, isLoading: isComputedOfferStatusLoading } = useComputedOfferStatus(intentId, lastOffer);

  const canDoAction = React.useCallback(
    (action: UpdateOfferStatusAction) => offerStatus && computedOfferStatusAction[offerStatus.status as ComputedOfferStatus].includes(action),
    [offerStatus],
  );

  const label = React.useMemo(() => {
    if (!offerStatus) {
      return '';
    }

    const labels = showShortLabel ? computedOfferStatusShortLabels : computedOfferStatusLabels;
    return labels[offerStatus.status as ComputedOfferStatus];
  }, [offerStatus, showShortLabel]);

  if (isComputedOfferStatusLoading) {
    return (
      <Skeleton width="7rem" />
    );
  }

  if (!offerStatus || offerStatus.status === 'NO_STATUS') {
    return null;
  }

  if (!lastOffer && !offerWizardData) {
    return null;
  }

  return (
    (!offerStatus.canBeUpdated || showAsBadge)
      ? (
        <Badge
          label={label}
          color={computedOfferStatusActionColors[offerStatus.status as ComputedOfferStatus]}
          icon={showIcon ? computedOfferStatusActionIcon[offerStatus.status as ComputedOfferStatus] : undefined}
          emphasis={emphasis}
          size={size}
          aria-label="Badge per mostrare lo stato della proposta"
        />
      )
      : (
        <ActionDropDown
          label={label}
          color={computedOfferStatusActionColors[offerStatus.status as ComputedOfferStatus]}
          iconLeft={showIcon ? { path: computedOfferStatusActionIcon[offerStatus.status as ComputedOfferStatus] } : undefined}
          emphasis={emphasis}
          size={size}
          aria-label="Selezione per aggiornare lo stato della proposta"
        >
          {lastOffer && canDoAction(UpdateOfferStatusAction.CONFIRM_OFFER) && (
            <ConfirmOfferAction
              offer={lastOffer}
              onSuccess={onSuccess}
            >
              {({ openConfirmOfferModal }) => (
                <Dropdown.Option
                  label="Conferma acquisto"
                  onClick={openConfirmOfferModal}
                  aria-label="Azione per confermare l'acquisto di un immobile"
                />
              )}
            </ConfirmOfferAction>
          )}

          {lastOffer && canDoAction(UpdateOfferStatusAction.REFUSED_OFFER) && (
            <ArchiveOfferAction
              offer={lastOffer}
              toStatus={OfferStatus.REFUSED}
              aria-label="Azione per rifiutare una proposta"
              onSuccess={onSuccess}
            >
              {({ updateStatus }) => (
                <Dropdown.Option
                  label="Rifiuta proposta"
                  onClick={updateStatus}
                />
              )}
            </ArchiveOfferAction>
          )}

          {lastOffer && canDoAction(UpdateOfferStatusAction.WITHDRAW_OFFER) && (
            <ArchiveOfferAction
              offer={lastOffer}
              toStatus={OfferStatus.WITHDRAWN}
              aria-label="Azione per annullare una proposta"
              onSuccess={onSuccess}
            >
              {({ updateStatus }) => (
                <Dropdown.Option
                  label="Annulla proposta"
                  onClick={updateStatus}
                />
              )}
            </ArchiveOfferAction>
          )}

          {lastOffer && canDoAction(UpdateOfferStatusAction.ARCHIVE_FOR_MORTGAGE) && (
            <ArchiveOfferAction
              offer={lastOffer}
              toStatus={OfferStatus.MORTGAGE_REFUSED}
              aria-label="Azione per archiviare una proposta causa mutuo non concesso"
              onSuccess={onSuccess}
            >
              {({ updateStatus }) => (
                <Dropdown.Option
                  label="Archivia causa mutuo"
                  onClick={updateStatus}
                />
              )}
            </ArchiveOfferAction>
          )}

          {lastOffer && canDoAction(UpdateOfferStatusAction.ARCHIVE_FOR_CONSTRAINT) && (
            <ArchiveOfferAction
              offer={lastOffer}
              toStatus={OfferStatus.OTHER_CONSTRAINTS}
              aria-label="Azione per archiviare una proposta causa altri vincoli"
              onSuccess={onSuccess}
            >
              {({ updateStatus }) => (
                <Dropdown.Option
                  label="Archivia causa altri vincoli"
                  onClick={updateStatus}
                />
              )}
            </ArchiveOfferAction>
          )}

          {offerWizardData && canDoAction(UpdateOfferStatusAction.CANCEL_REQUEST) && (
            <CancelOfferSignatureRequestAction
              offerWizardData={offerWizardData}
              aria-label="Azione per cancellare la richiesta di firma della proposta digitale"
              onSuccess={onSuccess}
            >
              {({ cancelSignatureRequest }) => (
                <Dropdown.Option
                  label="Annulla richiesta"
                  onClick={cancelSignatureRequest}
                />
              )}
            </CancelOfferSignatureRequestAction>
          )}

          {lastOffer && canDoAction(UpdateOfferStatusAction.CONFIRM_DEED) && (
            <EditOfferDeedAction
              offer={lastOffer}
              onSuccess={onSuccess}
              isDone
            >
              {({ openEditOfferDeedModal }) => (
                <Dropdown.Option
                  label="Conferma rogito"
                  aria-label="Azione per confermare il rogito"
                  onClick={openEditOfferDeedModal}
                />
              )}
            </EditOfferDeedAction>
          )}
        </ActionDropDown>
      )
  );
};

export default UpdateOfferStatus;
