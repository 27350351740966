import React from 'react';
import { OfferWizardStepProps } from '../types';
import OfferWizardMortgageForm, { OfferWizardMortgageFormModel } from './OfferWizardMortgageForm';
import { toOfferWizardData, toOfferWizardMortgageModel } from './mappers';

const OfferWizardMortgage: React.FC<OfferWizardStepProps> = ({
  initialData,
  formRef,
  loading = false,
  onSubmit,
}) => {
  const onInternalSubmit = React.useCallback(async (formValues: OfferWizardMortgageFormModel) => {
    const values = toOfferWizardData(initialData, formValues);

    const isFormDirty = (formRef.current as any)?.dirty || false;

    onSubmit(values, isFormDirty);
  }, [formRef, initialData, onSubmit]);

  return (
    <OfferWizardMortgageForm
      initialValues={toOfferWizardMortgageModel(initialData)}
      onSubmit={onInternalSubmit}
      loading={loading}
      formRef={formRef}
    />
  );
};

export default OfferWizardMortgage;
