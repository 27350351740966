/* eslint-disable react/no-unknown-property */
import {
  ModalSize, Portal, useModal, ActionIcon, ICON_EYE_OUTLINE,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../simple-modal/SimpleModal';
import ViewCrawledProperties from '../../crawled-properties/containers/view-crawled-properties/ViewCrawledProperties';

interface ChildFnProps {
  viewNeighborhood: VoidFunction,
}

export interface ViewNeighborhoodsActionProps {
  latitude: number,
  longitude: number,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const ViewNeighborhoodsAction: React.FC<ViewNeighborhoodsActionProps> = ({
  children,
  latitude,
  longitude,
}) => {
  const neighborhoodModal = useModal();
  const viewNeighborhood = React.useCallback(() => {
    neighborhoodModal.open();
  }, [neighborhoodModal]);

  return (
    <>
      {children
        ? children({ viewNeighborhood })
        : (
          <ActionIcon
            label="Visualizza gli immobili in vendita nelle vicinanze"
            icon={{ path: ICON_EYE_OUTLINE }}
            aria-label="Visualizza gli immobili in vendita nelle vicinanze"
            size="S"
            onClick={viewNeighborhood}
          />
        )}
      <Portal>
        <SimpleModal
          {...neighborhoodModal}
          size={ModalSize.FULL}
          title="Annunci immobiliare.it nelle vicinanze"
        >
          <ViewCrawledProperties latitude={latitude} longitude={longitude} />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ViewNeighborhoodsAction;
