import React from 'react';
import {
  ActionIcon,
  FontWeight, HStack, ICON_EYE_OUTLINE, Message, Stack, Text, UserPreview, UserPreviewSkeleton,
} from '@doveit/bricks';
import { Prospect } from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ProspectAvatar from '../../../prospect/components/prospect-avatar/ProspectAvatar';
import UpdateProspectStatus from '../../../prospect/containers/update-prospect-status/UpdateProspectStatus';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import ViewProspectPopoverPreview from '../../../prospect/containers/view-prospect-popover-preview/ViewProspectPopoverPreview';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import useLead from '../../../lead/hooks/use-lead/useLead';
import SimpleTableRowSkeleton from '../../../components/simple-table/SimpleTableRow.skeleton';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';

export interface ViewContactProspectTableRowProps extends React.AriaAttributes {
  prospect: Prospect,
  onProspectUpdate?: (updatedProspect: Prospect) => void,
}

const ViewContactProspectTableRow: React.FC<ViewContactProspectTableRowProps> = ({
  prospect,
  onProspectUpdate,
  ...rest
}) => {
  const {
    data: agent,
    isLoading: isAgentLoading,
    error: agentError,
  } = useAgent(prospect.agentId);

  const { userIsAdmin, userIsCallCenter, userIsContentEditor } = useRBAC();
  const isProspectAgentOrManager = useCurrentAgentIsSameAgentOrManager(prospect.agentId);

  const canViewAction = React.useMemo(() => userIsAdmin || userIsCallCenter || userIsContentEditor || isProspectAgentOrManager, [isProspectAgentOrManager, userIsAdmin, userIsCallCenter, userIsContentEditor]);

  const { data: lead, isLoading: isLeadLoading, error: leadError } = useLead(prospect.leadId);

  const goToProspectPage = React.useCallback(() => {
    goToDetailPage(prospect.id!, ReferenceType.PROSPECT);
  }, [prospect.id]);

  if (isLeadLoading) {
    return (
      <SimpleTable.Row key={`table-row-${prospect.id}`} asChild>
        <SimpleTableRowSkeleton />
      </SimpleTable.Row>
    );
  }

  if (!lead || leadError) {
    return (
      <SimpleTable.Row>
        <SimpleTable.Cell>
          <Message type="critical" message="Errore caricamento dati" />
        </SimpleTable.Cell>
      </SimpleTable.Row>
    );
  }
  return (
    <SimpleTable.Row aria-label="Informazioni valutazione" {...rest}>
      <SimpleTable.Cell aria-label="Indirizzo immobile">
        <ViewProspectPopoverPreview
          prospectId={prospect.id!}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <ProspectAvatar size="S" />
              <Text.BodySmall fontWeight={FontWeight.MEDIUM} css={setVerticalEllipsis()}>
                {lead!.propertyAddress}
              </Text.BodySmall>
            </HStack>
          )}
          hideAction={!canViewAction}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Stato valutazione">
        <UpdateProspectStatus
          prospect={prospect}
          size="XS"
          onSuccess={onProspectUpdate}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell width="10rem" aria-label="Informazioni agente">
        {isAgentLoading && (
          <UserPreviewSkeleton
            size="S"
            aria-label="Agente in caricamento"
          />
        )}
        {agentError && (
          <UserPreview
            size="S"
            name="Errore agente"
          />
        )}
        {agent && (
          <ViewAgentPopoverPreview
            agent={agent}
            trigger={(
              <AgentPreview
                {...toAgentPreviewProps(agent)}
                size="S"
                expanded
              />
            )}
          />
        )}
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Data di creazione">
        <Stack gap={50}>
          <Text.Mini transform="uppercase">
            Aggiunta
          </Text.Mini>
          <Text.Caption>
            <DateViewer
              stringDate={prospect.createdAt!}
              checkIfToday
              withHour={false}
            />
          </Text.Caption>
        </Stack>
      </SimpleTable.Cell>

      {canViewAction && (
        <SimpleTable.Cell
          aria-label="Azioni"
          align="right"
        >
          <ActionIcon
            aria-label="Visualizza valutazione"
            label="Visualizza"
            size="S"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToProspectPage}
          />
        </SimpleTable.Cell>
      )}
    </SimpleTable.Row>
  );
};

export default ViewContactProspectTableRow;
