import { useNotifications } from '@doveit/bricks';
import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import { LeadStatus } from '../../../domain/types';
import useAddressSuggestions from '../../../hooks/use-address-suggestion/useAddressSuggestions';
import { updateContact } from '../../../providers/api/contact/contactProvider';
import { Contact } from '../../../providers/api/dtos/contact';
import ContactForm, { ContactFormModel } from '../../components/contact-form/ContactForm';
import useContactLeads from '../../hooks/use-contact-leads/useContactLeads';
import { geocodeByAddress } from '../../../providers/geo/geocode/geocodeProvider';
import { GeoCodeResult, Place } from '../../../providers/geo/dtos';
import { geoCodeResultToGeo } from '../../../utils/geo/utils';
import useGoogleMapsAPI from '../../../hooks/use-google-maps-api/useGoogleMapsAPI';

export interface UpsertContactProps {
  contact?: Contact,
  onSuccess?: (updatedContact: Contact) => void,
  submitLabel?: string,
}

export const UPSERT_CONTACT_SUCCESS_MESSAGE = 'Il contatto è stato salvato';
export const UPSERT_CONTACT_ERROR_MESSAGE = 'Non è stato possibile salvare il contatto';
export const UPSERT_CONTACT_UPDATE_ERROR_MESSAGE = 'Non è stato possibile modificare il contatto. Inserisci un nuovo numero di telefono o chiudi la modale per ripristinare il numero precedente';

const UpsertContact: FunctionComponent<UpsertContactProps> = ({
  contact,
  onSuccess,
  submitLabel,
}) => {
  const { getPlaceDetails } = useGoogleMapsAPI();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = useState(false);
  const { data: leads } = useContactLeads(contact?.id ? contact.id : undefined);
  const { data: suggestions } = useAddressSuggestions(contact?.residence?.normalizedAddress);

  const initialValues = useMemo(() => contact && ({
    id: contact.id ? contact.id : undefined,
    name: contact.name,
    phoneNumber: contact.phoneNumber,
    email: contact.email,
    fiscalCode: contact.fiscalCode,
    address: suggestions?.[0],
    notes: contact.notes,
  }), [contact, suggestions]);

  const onSubmit = useCallback(async (values: ContactFormModel) => {
    if (contact?.id) {
      setIsSaving(true);

      if (!values.phoneNumber && leads?.find((lead) => lead.status === LeadStatus.IN_PROGRESS)) {
        setIsSaving(false);
        addError(UPSERT_CONTACT_UPDATE_ERROR_MESSAGE);

        return;
      }

      try {
        let geoCodeResult: GeoCodeResult | undefined;
        let placeDetails: Place | undefined;

        if (values.address?.description) {
          placeDetails = await getPlaceDetails(values.address.placeId);
          [geoCodeResult] = await geocodeByAddress(values.address.description);
        }

        const updatedContact = await updateContact(contact.id, {
          ...contact,
          name: values.name || undefined,
          phoneNumber: values.phoneNumber || undefined,
          email: values.email || undefined,
          fiscalCode: values.fiscalCode || undefined,
          notes: values.notes || undefined,
          residence: geoCodeResultToGeo(geoCodeResult, {
            plateCode: placeDetails?.plateCode,
          }),
        });

        setIsSaving(false);
        addSuccess(UPSERT_CONTACT_SUCCESS_MESSAGE);

        if (onSuccess) {
          onSuccess(updatedContact);
        }
      } catch (error) {
        setIsSaving(false);
        addError(UPSERT_CONTACT_ERROR_MESSAGE);
      }
    }
  }, [contact, leads, addError, addSuccess, onSuccess, getPlaceDetails]);

  return (
    <ContactForm
      loading={isSaving}
      initialValues={initialValues}
      onSubmit={onSubmit}
      submitLabel={submitLabel}
    />
  );
};

export default UpsertContact;
