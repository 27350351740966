import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const activitiesSummaryWrapper: ThemedFunctor = () => css`
  max-height: 20rem;
  overflow-y: auto;
`;

export const activityGroupWrapper: ThemedFunctor = () => css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
