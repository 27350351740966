import useSWR, { SWRResponse } from 'swr';
import { TrustpilotServiceReview } from '../../../providers/api/dtos';
import { getTrustpilotServiceReview } from '../../../providers/api/trustpilot/trustpilotProvider';

export const TRUSTPILOT_SERVICE_REVIEW_RID = '/trustpilot/reviews/:id';

export default function useTrustpilotServiceReview(id?: TrustpilotServiceReview['id']): SWRResponse<TrustpilotServiceReview, Error> {
  const key = id ? [TRUSTPILOT_SERVICE_REVIEW_RID, id] : null;

  return useSWR(key, () => getTrustpilotServiceReview(id!));
}
