import {
  Card,
  ICON_CHECK,
  Message,
  Badge,
  CardSkeleton,
  ICON_ALERT_CIRCLE_OUTLINE,
  HStack,
  Dropdown,
  ICON_CUBE_OUTLINE,
} from '@doveit/bricks';
import React from 'react';
import { AdditionalServiceType, OptInSource } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { additionalServicesLabels } from '../../../labels';
import { AdditionalService } from '../../../providers/api/dtos';
import useContactAdditionalServices from '../../hooks/use-contact-additional-services/useContactAdditionalServices';
import EditAdditionalServicesAction from '../edit-additional-services-action/EditAdditionalServicesAction';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';

export interface AdditionalServicesWidgetProps {
  contactId: string,
  canAdd?: boolean,
  canDelete?: boolean,
  canSeeDetails?: boolean,
  onLoadError?: VoidFunction,
}

export const LOAD_ADDITIONAL_SERVICES_ERROR_MESSAGE = 'Non è stato possibile caricare i dati dei servizi aggiuntivi.';
export const NO_ADDITIONAL_SERVICES_MESSAGE = 'Nessun servizio aggiuntivo.';

const AdditionalServicesWidget: React.FC<AdditionalServicesWidgetProps> = ({
  contactId,
  canAdd = false,
  canDelete = false,
  canSeeDetails = false,
  onLoadError,
}) => {
  const isMobile = useIsDevice('mobile');
  const { user } = useRBAC();
  const {
    data: additionalServices,
    isLoading: areAdditionalServicesLoading,
    error: additionalServicesError,
    mutate: mutateAdditionalServices,
  } = useContactAdditionalServices(contactId || undefined, { onError: onLoadError });

  const allAdditionalServices = React.useMemo(() => Object.values(AdditionalServiceType)
    .map((additionalServiceType) => {
      const foundAdditionalService = additionalServices?.find((addSer) => addSer.type === additionalServiceType);
      if (!foundAdditionalService) {
        return ({
          additionalService: {
            contactId,
            sourceType: OptInSource.BACKOFFICE,
            sourceValue: user.email,
            type: additionalServiceType,
          } as AdditionalService,
          selected: false,
        });
      }

      return {
        additionalService: foundAdditionalService,
        selected: true,
      };
    })
    .sort((a, b) => additionalServicesLabels[a.additionalService.type].localeCompare(additionalServicesLabels[b.additionalService.type], 'it')), [additionalServices, contactId, user.email]);

  if (areAdditionalServicesLoading) {
    return <CardSkeleton aria-label="Dati servizi aggiuntivi in caricamento" />;
  }

  if (additionalServicesError) {
    return (
      <Card aria-label="Errore nel caricamento dei servizi aggiuntivi">
        <Card.Header
          title="Servizi"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          color="critical"
        />
        <Card.Content>
          <Message message={LOAD_ADDITIONAL_SERVICES_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  return additionalServices! && (
    <Card aria-label="Sezione servizi aggiuntivi">
      <Card.Header
        title="Servizi"
        icon={{ path: ICON_CUBE_OUTLINE }}
        primaryActions={
          isMobile
            ? undefined : [canSeeDetails && (
              <EditAdditionalServicesAction
                selectedAdditionalServices={allAdditionalServices}
                onSuccess={mutateAdditionalServices}
                canDelete={canDelete}
                canAdd={canAdd}
              />
            )]
        }
        secondaryActions={
          isMobile
            ? [canSeeDetails && (
              <EditAdditionalServicesAction
                selectedAdditionalServices={allAdditionalServices}
                onSuccess={mutateAdditionalServices}
                canDelete={canDelete}
                canAdd={canAdd}
              >
                {({ openModal }) => (
                  <Dropdown.Option
                    label={allAdditionalServices.some(({ selected }) => selected)
                      ? 'Visualizza'
                      : 'Aggiungi'}
                    onClick={openModal}
                  />
                )}
              </EditAdditionalServicesAction>
            )]
            : undefined
        }
      />
      <Card.Content>
        {additionalServices.length === 0 && (
          <Message
            message={NO_ADDITIONAL_SERVICES_MESSAGE}
            type="neutral"
          />
        )}
        <HStack>
          {additionalServices?.sort((a, b) => additionalServicesLabels[a.type]
            .localeCompare(additionalServicesLabels[b.type], 'it'))
            .map((additionalService) => (
              <Badge
                key={additionalService.id}
                label={additionalServicesLabels[additionalService.type]}
                icon={ICON_CHECK}
                size="XS"
              />
            ))}
        </HStack>
      </Card.Content>
    </Card>
  );
};

export default AdditionalServicesWidget;
