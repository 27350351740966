import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import {
  ActionIcon, ConfirmModal, ICON_ACCOUNT_MULTIPLE_CHECK_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { JobApplicationStatus } from '../../../domain/types/jobApplicationStatus';
import { JobApplication } from '../../../providers/api/dtos';
import { updateJobApplication } from '../../../providers/api/job-application/jobApplicationProvider';

interface ChildFnProps {
  hire: VoidFunction,
  loading: boolean,
}

export interface HireJobApplicationProps {
  jobApplication: JobApplication,
  onSuccess?: (jobApplication: JobApplication) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const HIRE_JOB_APPLICATION_WARNING_MESSAGE = 'Confermi di voler assumere il candidato?';
export const HIRE_JOB_APPLICATION_SUCCESS_MESSAGE = 'Candidato correttamente segnalato come assunto';
export const HIRE_JOB_APPLICATION_ERROR_MESSAGE = 'Non è stato possibile segnalare il candidato come assunto';

const HireJobApplication: FunctionComponent<HireJobApplicationProps> = ({
  jobApplication,
  onSuccess,
  children,
}) => {
  const hireModal = useModal();
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const isOfferJobApplication = useMemo(
    () => jobApplication?.status === JobApplicationStatus.OFFER,
    [jobApplication?.status],
  );

  const onConfirm = useCallback(async () => {
    setIsSaving(true);

    try {
      const updatedJobApplication = await updateJobApplication(
        jobApplication.id!,
        {
          ...jobApplication,
          status: JobApplicationStatus.HIRED,
        },
      );

      setIsSaving(false);
      hireModal.close();
      addSuccess(HIRE_JOB_APPLICATION_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedJobApplication);
      }
    } catch (error) {
      setIsSaving(false);
      addError(HIRE_JOB_APPLICATION_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, hireModal, jobApplication, onSuccess]);

  if (
    !(isOfferJobApplication)
  ) {
    return null;
  }

  return (
    <>
      {children
        ? children({ hire: hireModal.open, loading: isSaving })
        : (
          <ActionIcon
            label="Assumi"
            onClick={hireModal.open}
            icon={{ path: ICON_ACCOUNT_MULTIPLE_CHECK_OUTLINE }}
          />
        )}
      <Portal>
        <ConfirmModal
          isOpen={hireModal.isOpen}
          title="Conferma assunzione"
          onConfirm={onConfirm}
          onAbort={hireModal.close}
          loading={isSaving}
        >
          {HIRE_JOB_APPLICATION_WARNING_MESSAGE}
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default HireJobApplication;
