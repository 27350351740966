import { AssignmentWizardSellerFormModel } from '../../../../property/assignment/components/assignment-wizard/assignment-wizard-seller/AssignmentWizardSellerForm';
import {
  Geo, Legal, OfferWizardData, WizardContact,
} from '../../../../providers/api/dtos';
import { geocodeByAddress } from '../../../../providers/geo/geocode/geocodeProvider';
import { getPlaceDetails } from '../../../../providers/geo/places/places';

export const toAssignmentWizardSellerModel = (initialData: OfferWizardData, propertyOwners?: WizardContact[]) => {
  const contacts: WizardContact[] = initialData.sellerContacts || propertyOwners || [];

  const firstLegalContact = contacts.find((contact) => contact.legal !== undefined)?.legal;

  const formData: AssignmentWizardSellerFormModel = {
    companyName: firstLegalContact?.companyName || '',
    companyAddress: {
      description: firstLegalContact?.registeredOffice?.normalizedAddress || '',
      placeId: '',
    },
    companyFiscalCode: firstLegalContact?.fiscalCode || '',
  };

  return formData;
};

export async function toOfferWizardData(existingData: OfferWizardData, values: AssignmentWizardSellerFormModel, updatedContacts: WizardContact[], isSellerLegalPerson: boolean): Promise<OfferWizardData> {
  const newContacts: WizardContact[] = updatedContacts.map((contact) => ({ ...contact, legal: undefined }));

  if (isSellerLegalPerson && updatedContacts.length > 0) {
    const legalContact = newContacts[0];
    let registeredOffice: Geo | undefined;
    const placeId = values.companyAddress?.placeId;

    if (placeId) {
      const place = values.companyAddress ? await getPlaceDetails(placeId) : undefined;
      registeredOffice = {
        route: place?.route,
        streetNumber: place?.streetNumber,
        locality: place?.locality,
        postalCode: place?.postalCode,
        latitude: place?.latitude,
        longitude: place?.longitude,
      };
    } else if (values.companyAddress?.description) {
      const [geoCode] = await geocodeByAddress(values.companyAddress.description);
      registeredOffice = {
        route: geoCode.route,
        streetNumber: geoCode.streetNumber,
        locality: geoCode.locality,
        postalCode: geoCode.postalCode,
        latitude: geoCode.latitude ? Number(geoCode.latitude) : undefined,
        longitude: geoCode.longitude ? Number(geoCode.longitude) : undefined,
      };
    }

    const legal: Legal = {
      companyName: values.companyName || undefined,
      fiscalCode: values.companyFiscalCode || undefined,
      registeredOffice,
    };

    newContacts[0] = { ...legalContact, legal };
  }

  const newValues: OfferWizardData = {
    ...existingData,
    isSellerLegalPersonOnly: isSellerLegalPerson,
    sellerContacts: newContacts,
    isDigitallySigned: false,
  };

  return newValues;
}
