import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Prospect } from '../../../providers/api/dtos';
import { API_ASSIGNMENTS_PATH } from '../../../providers/api/api';
import { getAssignmentWizardPDFPreview } from '../../../providers/api/assignment/assignmentProvider';

export const ASSIGNMENT_WIZARD_PDF_PREVIEW_RID = `${API_ASSIGNMENTS_PATH}/from-prospect/wizard/preview`;

export default function useAssignmentWizardPDFPreview(
  prospectId?: NonNullable<Prospect['id']>,
  onSuccess?: SWRConfiguration['onSuccess'],
): SWRResponse<ArrayBuffer, AxiosError> {
  const key = prospectId ? [ASSIGNMENT_WIZARD_PDF_PREVIEW_RID, prospectId] : null;

  return useSWR(key, () => getAssignmentWizardPDFPreview(prospectId!), { onSuccess });
}
