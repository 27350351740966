import React, { FunctionComponent, useState, MutableRefObject } from 'react';
import {
  Portal, useModal, useNotifications, ICON_TRASH_CAN_OUTLINE, ActionIcon, Form, Action, FormHandlers,
} from '@doveit/bricks';
import { Appointment } from '../../providers/api/dtos/appointment';
import { updateAppointment } from '../../providers/api/appointment/appointmentProvider';
import useContactAppointments from '../../appointment/hooks/use-contact-appointments/useContactAppointments';
import { AppointmentStatus, ReferenceType } from '../../domain/types';
import { SortOrder } from '../../providers/pagination';
import SimpleModal from '../../components/simple-modal/SimpleModal';
import { cancelAppointmentActionValidationSchema } from './CancelAppointmentAction.schema';
import { Contact } from '../../providers/api/dtos';

interface ChildFnProps {
  disabled: boolean,
  loading: boolean,
  cancel: VoidFunction,
}

export interface CancelAppointmentActionProps {
  contactId: Contact['id'],
  referenceId: string,
  referenceType: ReferenceType,
  disabled?: boolean,
  onSuccess?: VoidFunction,
  children?: (props: ChildFnProps) => React.ReactNode
}

export const CANCEL_APPOINTMENT_ERROR_MESSAGE = 'Non è stato possibile annullare l\'appuntamento.';
export const CANCEL_APPOINTMENT_SUCCESS_MESSAGE = "L'appuntamento è stato annullato con successo.";

const CancelAppointmentAction: FunctionComponent<CancelAppointmentActionProps> = ({
  contactId,
  referenceId,
  referenceType,
  disabled = false,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const formRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;

  const { data: appointments } = useContactAppointments(
    contactId,
    { referenceId, referenceType },
    { sort: { createdAt: SortOrder.DESC } },
  );

  const lastAppointment = React.useMemo(
    () => appointments?.content?.[0],
    [appointments],
  );

  const lastAppointmentStatus = React.useMemo(
    () => lastAppointment?.status,
    [lastAppointment],
  );

  const openModal = React.useCallback(
    () => modal.open(),
    [modal],
  );

  const onSubmit = React.useCallback(async (values: { notes: string }) => {
    setIsSaving(true);

    try {
      const appointmentToCancel: Appointment = {
        ...lastAppointment!,
        status: AppointmentStatus.CANCELLED,
        notes: values.notes.trim() || undefined,
      };

      await updateAppointment(appointmentToCancel.id!, appointmentToCancel);

      modal.close();
      addSuccess(CANCEL_APPOINTMENT_SUCCESS_MESSAGE);
      onSuccess?.();
    } catch (error) {
      addError(CANCEL_APPOINTMENT_ERROR_MESSAGE);
    }

    setIsSaving(false);
  }, [lastAppointment, modal, addSuccess, onSuccess, addError]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  if (!lastAppointmentStatus || lastAppointmentStatus !== AppointmentStatus.TODO) {
    return null;
  }

  return (
    <>
      {children
        ? children({ disabled, loading: isSaving, cancel: openModal })
        : (
          <ActionIcon
            label="Annulla"
            aria-label="Pulsante per annullare l'appuntamento"
            icon={{ path: ICON_TRASH_CAN_OUTLINE }}
            disabled={disabled}
            onClick={openModal}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Annulla appuntamento"
          aria-label="Modale per annullare l'appuntamento"
          footer={(
            <Action
              label="Conferma"
              color="primary"
              emphasis="high"
              aria-label="Conferma per annullare l'appuntamento"
              onClick={submitForm}
            />
          )}
        >
          <Form
            initialValues={{ notes: '' }}
            innerRef={formRef}
            loading={isSaving}
            onSubmit={onSubmit}
            validationSchema={cancelAppointmentActionValidationSchema}
          >
            <Form.Item>
              <Form.TextArea
                label="Note"
                aria-label="Campo per inserire note aggiuntive sull'annullamento dell'appuntamento"
                name="notes"
                placeholder="Inserisci eventuali note sull'annullamento dell'appuntamento"
                required
              />
            </Form.Item>
          </Form>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default CancelAppointmentAction;
