import { API_PROPERTIES_PATH, API_REVIEWS_PATH, getClient } from '../api';
import { Property, Review, ReviewRequest } from '../dtos';

export async function getReviewsByPropertyId(propertyId: NonNullable<Property['id']>): Promise<Review[]> {
  const client = await getClient();
  const { data } = await client.get<Review[]>(`${API_PROPERTIES_PATH}/${propertyId}/reviews`);

  return data;
}

export async function createReview(reviewRequest: ReviewRequest): Promise<Review> {
  const client = await getClient();
  const { data } = await client.post<Review>(API_REVIEWS_PATH, reviewRequest);

  return data;
}
