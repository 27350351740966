import { SingleSignatureStatus } from '../../providers/api/dtos';

export const isNotifiedOrAlreadyInitiated = ({
  status,
  signatureLink,
}: {
  status: SingleSignatureStatus,
  signatureLink: string | null,
}) => {
  const isAlreadyInitiated = status !== SingleSignatureStatus.INITIATED;
  const isNotified = status === SingleSignatureStatus.NOTIFIED && signatureLink !== null;

  return isNotified || isAlreadyInitiated;
};
