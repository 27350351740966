import React from 'react';
import {
  Action, FormHandlers, useNotifications,
} from '@doveit/bricks';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import useFilters, { FiltersMapper } from '../../../hooks/use-filters/useFilters';
import ShowcaseForm, { ShowcaseFormModel, ShowcaseFormProps } from '../../../showcase/components/showcase-form/ShowcaseForm';
import useProperty from '../../../property/hooks/use-property/useProperty';
import { propertyTypeLabels } from '../../../labels';
import Divider from '../../../components/divider/Divider';
import { generateShowcase } from '../../../providers/api/showcase/showcaseProvider';
import { ShowcaseFormat } from '../../../domain/types/showcaseTypes';
import { downloadFile } from '../../../utils/file/file';
import { useAgent, useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import { PropertyClass, Status } from '../../../domain/types';
import { removeNullAndUndefinedValues } from '../../../utils/array';
import useRBAC from '../../../hooks/use-rbac/useRBAC';

export const LOAD_PROPERTY_INFO_ERROR_MESSAGE = "Non è stato possibile recuperare i dati dell'immobile, seleziona l'immobile manualmente.";
export const GENERATE_PROPERTY_SHOWCASE_ERROR_MESSAGE = "Non è stato possibile scaricare la locandina dell'immobile";
export const GENERATE_PROPERTY_SHOWCASE_SUCCESS_MESSAGE = "La locandina dell'immobile è stata scaricata con successo";

const filtersMapper: FiltersMapper<{ propertyId?: number }> = {
  propertyId: (v) => (!Number.isNaN(Number(v)) ? Number(v) : undefined),
};

const GenerateShowcasePage: React.FC = () => {
  const { userIsAdmin, userIsContentEditor, userIsAgent } = useRBAC();
  const currentAgent = useCurrentAgent();
  const isAdminOrContent = React.useMemo(() => (userIsAdmin || userIsContentEditor), [userIsAdmin, userIsContentEditor]);

  const statusFilter = React.useMemo(() => {
    if (isAdminOrContent) {
      return [Status.ANTEPRIMA, Status.LIVE, Status.PROPOSTA, Status.VENDUTO];
    }
    return undefined;
  }, [isAdminOrContent]);

  const agentEmailsFilter = React.useMemo(() => {
    if (userIsAgent && currentAgent) {
      if (currentAgent.canManage && currentAgent.canManage.length > 0) {
        return currentAgent.canManage.map((agent) => agent.email.work!);
      }
      return [currentAgent.email.work!];
    }
    return undefined;
  }, [currentAgent, userIsAgent]);

  const { filters: { propertyId } } = useFilters(filtersMapper);
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const { addError, addSuccess } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const { data: property } = useProperty(propertyId, {
    onError: () => addError(LOAD_PROPERTY_INFO_ERROR_MESSAGE),
  });

  const { data: agent } = useAgent(property?.agentEmail);

  const showVariant = React.useMemo(
    () => property?.classType === PropertyClass.LUXURY || agent?.agencies?.some(({ luxury }) => luxury),
    [agent?.agencies, property?.classType],
  );

  const extraPhoneNumbersOptions = React.useMemo(() => {
    const agentPhoneNumber = agent?.mobile.work;
    const agenciesPhoneNumbers = (agent?.agencies || []).map(({ phoneNumber }) => phoneNumber);

    const phoneNumbers = removeNullAndUndefinedValues([agentPhoneNumber, ...agenciesPhoneNumbers]);

    return phoneNumbers.map((pn) => ({
      value: pn,
      label: pn,
    }));
  }, [agent?.agencies, agent?.mobile.work]);

  const initialValues: ShowcaseFormProps['initialValues'] = React.useMemo(() => {
    if (!property) return undefined;

    return {
      propertyId,
      title: propertyTypeLabels[property.propertyType],
      abstract: property.abstract,
    };
  }, [property, propertyId]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const handleSubmit = React.useCallback(async (formValues: ShowcaseFormModel) => {
    try {
      setIsSaving(true);

      const { propertyId: formPropertyId, ...showcase } = formValues;
      const generatedShowcaseData = await generateShowcase(formPropertyId!, showcase);

      // eslint-disable-next-line @typescript-eslint/dot-notation
      const mimeType = showcase.format === ShowcaseFormat['_16_9']
        ? 'image/jpeg'
        : 'application/pdf';
      const fileName = `Vetrina DV-${formPropertyId} - ${showcase.format}`;

      downloadFile(generatedShowcaseData, mimeType, fileName);

      setIsSaving(false);
      addSuccess(GENERATE_PROPERTY_SHOWCASE_SUCCESS_MESSAGE);
    } catch (_) {
      setIsSaving(false);
      addError(GENERATE_PROPERTY_SHOWCASE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content title="Genera locandina immobile">
        <GenericPageLayout.InnerContent>
          <ShowcaseForm
            innerRef={formRef}
            initialValues={initialValues}
            propertyProviderFilters={{ status: statusFilter, agentEmail: agentEmailsFilter }}
            onSubmit={handleSubmit}
            loading={isSaving}
            hideVariant={!showVariant}
            extraPhoneNumber={extraPhoneNumbersOptions}
          />
          <Divider />
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
          >
            <Action
              label="Scarica locandina"
              aria-label="Scarica locandina"
              color="primary"
              emphasis="high"
              onClick={submitForm}
              loading={isSaving}
            />
          </div>
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default GenerateShowcasePage;
