import {
  ConfirmModal, Portal, Action, ActionProps,
} from '@doveit/bricks';
import React, { FunctionComponent, useState, useCallback } from 'react';

export interface ExitSessionProps extends Pick<ActionProps, 'title' | 'badgeLabel'> {
  onExit: VoidFunction;
}

const ExitSession: FunctionComponent<ExitSessionProps> = ({
  onExit,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <Action
        {...rest}
        label="Termina sessione"
        color="critical"
        onClick={openModal}
      />
      <Portal>
        <ConfirmModal
          title="Sei sicuro di voler terminare la sessione?"
          isOpen={isOpen}
          onConfirm={onExit}
          onAbort={closeModal}
        >
          Alcuni dati potrebbero andare persi.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default ExitSession;
