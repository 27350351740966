import React from 'react';
import { RumorStatus } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Rumor } from '../../../providers/api/dtos';
import useContact from '../../../contact/hooks/use-contact/useContact';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';

type ComputedRumorStatusCanBeUpdatedTo = RumorStatus | 'ARCHIVED';

export type UseComputedRumorStatusResult = {
  status: RumorStatus,
  canBeUpdated: boolean,
  canBeUpdatedTo: (status: ComputedRumorStatusCanBeUpdatedTo) => boolean,
};

const adminAndAgentAllowedStatusUpdates: Record<RumorStatus, ComputedRumorStatusCanBeUpdatedTo[]> = {
  [RumorStatus.IN_PROGRESS]: [RumorStatus.APPOINTMENT_SCHEDULED, 'ARCHIVED'],
  [RumorStatus.APPOINTMENT_SCHEDULED]: [],
  [RumorStatus.KO_NOT_INTERESTED]: [RumorStatus.IN_PROGRESS],
  [RumorStatus.KO_DUPLICATE_LEAD]: [],
  [RumorStatus.KO_NOT_CONFIRMED]: [RumorStatus.IN_PROGRESS],
};

const callCenterAllowedStatusUpdates: Record<RumorStatus, ComputedRumorStatusCanBeUpdatedTo[]> = {
  [RumorStatus.IN_PROGRESS]: [RumorStatus.KO_DUPLICATE_LEAD, RumorStatus.APPOINTMENT_SCHEDULED],
  [RumorStatus.APPOINTMENT_SCHEDULED]: [],
  [RumorStatus.KO_NOT_INTERESTED]: [],
  [RumorStatus.KO_DUPLICATE_LEAD]: [],
  [RumorStatus.KO_NOT_CONFIRMED]: [],
};

export const useComputedRumorStatus = (rumor: Rumor): UseComputedRumorStatusResult => {
  const { userIsAdmin, userIsCallCenter } = useRBAC();
  const { data: contact } = useContact(rumor.contactId);
  const isRumorCreatorOrManager = useCurrentAgentIsSameAgentOrManager(rumor.agentId);

  const canBeUpdatedTo = React.useMemo(() => {
    if (userIsAdmin || isRumorCreatorOrManager) {
      return adminAndAgentAllowedStatusUpdates[rumor.status];
    }

    if (userIsCallCenter) {
      return callCenterAllowedStatusUpdates[rumor.status];
    }

    return [];
  }, [isRumorCreatorOrManager, rumor.status, userIsAdmin, userIsCallCenter]);

  const canBeUpdatedToFn = React.useCallback(
    (status: ComputedRumorStatusCanBeUpdatedTo) => {
      switch (status) {
        case RumorStatus.APPOINTMENT_SCHEDULED:
          return !!contact?.phoneNumber && canBeUpdatedTo.includes(RumorStatus.APPOINTMENT_SCHEDULED);
        default:
          return canBeUpdatedTo.includes(status);
      }
    },
    [canBeUpdatedTo, contact?.phoneNumber],
  );

  return React.useMemo(() => ({
    status: rumor.status,
    canBeUpdated: canBeUpdatedTo.length > 0,
    canBeUpdatedTo: canBeUpdatedToFn,
  }), [canBeUpdatedTo.length, canBeUpdatedToFn, rumor.status]);
};
