export enum IntentSection {
  TODO = 'todo',
  RECALL = 'recall',
  SCHEDULED_TODO = 'scheduled-todo',
  SCHEDULED_EXPIRED = 'scheduled-expired',
  SCHEDULED_PENDING = 'scheduled-pending',
  OFFERS_IN_PROGRESS = 'offers-in-progress',
  OFFERS_ACCEPTED = 'offers-accepted',
  OFFERS_REFUSED = 'offers-refused',
  OFFERS_WITHDRAWN = 'offers-withdrawn',
}
