import {
  ICON_ACCOUNT_MULTIPLE_PLUS_OUTLINE,
  ICON_CALENDAR_ALERT_OUTLINE,
  ICON_CASH_MULTIPLE,
  ICON_CLOSE_OCTAGON_OUTLINE, ICON_FILE_SIGN, ICON_HOME_PLUS_OUTLINE, ICON_HOME_REMOVE_OUTLINE, ICON_HOME_SEARCH_OUTLINE, ICON_INVOICE_OUTLINE, ICON_MESSAGE_ALERT_OUTLINE, ICON_SHIELD_CHECK_OUTLINE, ICON_SHIELD_EDIT_OUTLINE,
} from '@doveit/bricks';
import { SHOW_DEED_MODAL_URL_PARAM_CONFIRM_VALUE, SHOW_DEED_MODAL_URL_PARAM_EDIT_VALUE } from '../constants';
import { ApplicationNotificationType } from '../domain/types';
import { ApplicationNotificationMetadata } from './types';
import { ThemeIcons } from '../theme/icons';

export const DOCUMENT_TYPE_PLACEHOLDER = 'DOCUMENT_TYPE';
export const USER_NAME_PLACEHOLDER = 'USER_NAME';
export const CONTACT_NAME_PLACEHOLDER = 'CONTACT_NAME';
export const PROPERTY_ID_PLACEHOLDER = 'PROPERTY_ID';
export const PROPERTY_ADDRESS_PLACEHOLDER = 'PROPERTY_ADDRESS';
export const INTENT_ID_PLACEHOLDER = 'INTENT_ID';
export const JOB_APPLICATION_ID_PLACEHOLDER = 'JOB_APPLICATION_ID';
export const TRUSTPILOT_REVIEW_ID_PLACEHOLDER = 'TRUSTPILOT_REVIEW_ID';
export const ADDITIONAL_INFO_PLACEHOLDER = 'ADDITIONAL_INFO';
export const JOB_APPLICATION_APPLICANT_NAME_PLACEHOLDER = 'JOB_APPLICATION_APPLICANT_NAME';
export const JOB_APPLICATION_POSITION_PLACEHOLDER = 'JOB_APPLICATION_POSITION';
export const PROSPECT_ID_PLACEHOLDER = 'PROSPECT_ID';
export const INVOICE_ID_PLACEHOLDER = 'INVOICE_ID';

const propertyIdTemplateUrl = `/properties/{${PROPERTY_ID_PLACEHOLDER}}`;
const intentIdTemplateUrl = `/intents/{${INTENT_ID_PLACEHOLDER}}`;
const prospectIdTemplateUrl = `/prospects/{${PROSPECT_ID_PLACEHOLDER}}`;
const jobApplicationIdTemplateUrl = `/job-applications/{${JOB_APPLICATION_ID_PLACEHOLDER}}`;
const reviewIdTemplateUrl = `${propertyIdTemplateUrl}?show_review={${TRUSTPILOT_REVIEW_ID_PLACEHOLDER}}`;
const confirmDeedTemplateUrl = `${propertyIdTemplateUrl}?show_deed=${SHOW_DEED_MODAL_URL_PARAM_CONFIRM_VALUE}`;
const editDeedTemplateUrl = `${propertyIdTemplateUrl}?show_deed=${SHOW_DEED_MODAL_URL_PARAM_EDIT_VALUE}`;
const editInvoiceTemplateUrl = `${propertyIdTemplateUrl}?show_invoice={${INVOICE_ID_PLACEHOLDER}}`;

export const applicationNotificationMetadata: Record<ApplicationNotificationType, ApplicationNotificationMetadata> = {
  [ApplicationNotificationType.PROPERTY_DOCUMENT_REVIEW]: {
    title: `Revisione {${DOCUMENT_TYPE_PLACEHOLDER}}`,
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ha richiesto la revisione del documento "{${DOCUMENT_TYPE_PLACEHOLDER}}" dell'immobile DV-{${PROPERTY_ID_PLACEHOLDER}}`,
    icon: ICON_SHIELD_EDIT_OUTLINE,
    iconColor: 'status.alert',
    url: propertyIdTemplateUrl,
    actions: [{
      label: 'Revisiona',
      url: propertyIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.PROPERTY_DOCUMENT_APPROVED]: {
    title: `{${DOCUMENT_TYPE_PLACEHOLDER}} approvato`,
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ha approvato il documento "{${DOCUMENT_TYPE_PLACEHOLDER}}" dell'immobile DV-{${PROPERTY_ID_PLACEHOLDER}}`,
    icon: ICON_SHIELD_CHECK_OUTLINE,
    iconColor: 'status.info',
    url: propertyIdTemplateUrl,
  },
  [ApplicationNotificationType.PROPERTY_DOCUMENT_REJECTED]: {
    title: `{${DOCUMENT_TYPE_PLACEHOLDER}} respinto`,
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ha respinto il documento "{${DOCUMENT_TYPE_PLACEHOLDER}}" dell'immobile DV-{${PROPERTY_ID_PLACEHOLDER}}`,
    icon: ICON_CLOSE_OCTAGON_OUTLINE,
    iconColor: 'status.alert',
    url: propertyIdTemplateUrl,
    additionalInfo: `Motivazione: {${ADDITIONAL_INFO_PLACEHOLDER}}`,
    actions: [{
      label: 'Aggiorna documento',
      url: propertyIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.INTENT_NUDGE]: {
    title: 'Sollecito interesse',
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ti ha inviato un sollecito relativo all'interesse di {${CONTACT_NAME_PLACEHOLDER}} per l'immobile DV-{${PROPERTY_ID_PLACEHOLDER}}`,
    icon: ICON_MESSAGE_ALERT_OUTLINE,
    iconColor: 'status.alert',
    url: intentIdTemplateUrl,
    additionalInfo: `Nota: {${ADDITIONAL_INFO_PLACEHOLDER}}`,
    actions: [{
      label: 'Vedi nota',
      url: intentIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.PROSPECT_NUDGE]: {
    title: 'Sollecito valutazione',
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ti ha inviato un sollecito relativo alla valutazione di {${CONTACT_NAME_PLACEHOLDER}}`,
    icon: ICON_MESSAGE_ALERT_OUTLINE,
    iconColor: 'status.alert',
    url: prospectIdTemplateUrl,
    additionalInfo: `Nota: {${ADDITIONAL_INFO_PLACEHOLDER}}`,
    actions: [{
      label: 'Vedi nota',
      url: prospectIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.JOB_APPLICATION_ASSIGNED]: {
    title: 'Ti è stato assegnato un candidato',
    message: `Ti è stato assegnato il candidato {${JOB_APPLICATION_APPLICANT_NAME_PLACEHOLDER}} per la posizione {${JOB_APPLICATION_POSITION_PLACEHOLDER}}`,
    icon: ICON_ACCOUNT_MULTIPLE_PLUS_OUTLINE,
    iconColor: 'status.warning',
    url: jobApplicationIdTemplateUrl,
    actions: [{
      label: 'Vedi candidatura',
      url: jobApplicationIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.OFFER_DEED_TO_CONFIRM]: {
    title: `Conferma la data del rogito per il DV-{${PROPERTY_ID_PLACEHOLDER}}`,
    message: `Conferma che il rogito per il DV-{${PROPERTY_ID_PLACEHOLDER}} in {${PROPERTY_ADDRESS_PLACEHOLDER}} è avvenuto o modifica la data`,
    icon: ICON_CALENDAR_ALERT_OUTLINE,
    iconColor: 'status.alert',
    url: propertyIdTemplateUrl,
    actions: [{
      label: 'Avvenuto',
      url: confirmDeedTemplateUrl,
      color: 'primary',
    }, {
      label: 'Non avvenuto',
      url: editDeedTemplateUrl,
    }],
  },
  [ApplicationNotificationType.REVIEW_SUBMITTED]: {
    title: 'Nuova recensione verificata su Trustpilot',
    message: `Hai ricevuto una nuova recensione su Trustpilot per l'immobile DV-{${PROPERTY_ID_PLACEHOLDER}} in {${PROPERTY_ADDRESS_PLACEHOLDER}}`,
    icon: ThemeIcons.TRUSTPILOT,
    iconColor: 'status.info',
    url: reviewIdTemplateUrl,
    actions: [{
      label: 'Leggi la recensione',
      url: reviewIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.PROSPECT_APPROVAL_WAITING_FOR_SUPERVISOR]: {
    title: 'Richiesta di approvazione valutazione',
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ha richiesto l'approvazione della valutazione per l'immobile in {${PROPERTY_ADDRESS_PLACEHOLDER}}.`,
    icon: ICON_CASH_MULTIPLE,
    iconColor: 'status.alert',
    url: prospectIdTemplateUrl,
    actions: [{
      label: 'Vedi valutazione',
      url: prospectIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.PROSPECT_APPROVAL_WAITING_FOR_AGENT]: {
    title: 'Valutazione respinta',
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ha proposto una nuova valutazione per l'immobile in {${PROPERTY_ADDRESS_PLACEHOLDER}}.`,
    icon: ICON_CASH_MULTIPLE,
    iconColor: 'status.alert',
    url: prospectIdTemplateUrl,
    actions: [{
      label: 'Vedi valutazione',
      url: prospectIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.PROSPECT_APPROVAL_APPROVED]: {
    title: 'Valutazione approvata',
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ha approvato la tua proposta di valutazione per l'immobile in {${PROPERTY_ADDRESS_PLACEHOLDER}}.`,
    icon: ICON_CASH_MULTIPLE,
    iconColor: 'status.info',
    url: prospectIdTemplateUrl,
    actions: [{
      label: 'Vedi valutazione',
      url: prospectIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.INVOICE_FORECASTED_DATE_EXPIRING]: {
    title: 'Conferma o modifica data fatturazione',
    message: `Hai indicato che l'immobile DV-{${PROPERTY_ID_PLACEHOLDER}} in {${PROPERTY_ADDRESS_PLACEHOLDER}} verrà fatturato entro la fine di questo mese. Se non dovesse essere più così, ti chiediamo di modificare la data di fatturazione.`,
    icon: ICON_INVOICE_OUTLINE,
    iconColor: 'status.warning',
    url: propertyIdTemplateUrl,
    actions: [{
      label: 'Gestisci fatturazione',
      url: editInvoiceTemplateUrl,
    }],
  },
  [ApplicationNotificationType.OFFER_DOCUMENT_REVIEW]: {
    title: `Revisione {${DOCUMENT_TYPE_PLACEHOLDER}}`,
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ha richiesto la revisione del documento "{${DOCUMENT_TYPE_PLACEHOLDER}}" per la proposta d'acquisto dell'interesse di {${CONTACT_NAME_PLACEHOLDER}} per l'immobile DV-{${PROPERTY_ID_PLACEHOLDER}}`,
    icon: ICON_SHIELD_EDIT_OUTLINE,
    iconColor: 'status.info',
    url: intentIdTemplateUrl,
    actions: [{
      label: 'Revisiona',
      url: intentIdTemplateUrl,
    }],
  },

  [ApplicationNotificationType.OFFER_DOCUMENT_APPROVED]: {
    title: `{${DOCUMENT_TYPE_PLACEHOLDER}} approvato`,
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ha approvato il documento "{${DOCUMENT_TYPE_PLACEHOLDER}}" per la proposta d'acquisto dell'interesse di {${CONTACT_NAME_PLACEHOLDER}} per l'immobile DV-{${PROPERTY_ID_PLACEHOLDER}}.`,
    icon: ICON_SHIELD_CHECK_OUTLINE,
    iconColor: 'status.info',
    url: intentIdTemplateUrl,
  },

  [ApplicationNotificationType.OFFER_DOCUMENT_REJECTED]: {
    title: `{${DOCUMENT_TYPE_PLACEHOLDER}} rifiutato`,
    message: `<strong>{${USER_NAME_PLACEHOLDER}}</strong> ha respinto il documento "{${DOCUMENT_TYPE_PLACEHOLDER}}" per la proposta d'acquisto dell'interesse di {${CONTACT_NAME_PLACEHOLDER}} per l'immobile DV-{${PROPERTY_ID_PLACEHOLDER}}.`,
    icon: ICON_CLOSE_OCTAGON_OUTLINE,
    iconColor: 'status.alert',
    url: intentIdTemplateUrl,
    additionalInfo: `Motivazione: {${ADDITIONAL_INFO_PLACEHOLDER}}`,
    actions: [{
      label: 'Aggiorna documento',
      url: intentIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.PROPERTY_WITHDRAWN]: {
    title: 'Ritiro immobile',
    message: `L'immobile DV-{${PROPERTY_ID_PLACEHOLDER}} è stato ritirato.`,
    icon: ICON_HOME_REMOVE_OUTLINE,
    iconColor: 'status.info',
    url: propertyIdTemplateUrl,
    actions: [{
      label: 'Vedi immobile',
      url: propertyIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.PROPERTY_PUBLISHED]: {
    title: 'Pubblicazione immobile',
    message: `L'immobile DV-{${PROPERTY_ID_PLACEHOLDER}} è stato pubblicato.`,
    icon: ICON_HOME_PLUS_OUTLINE,
    iconColor: 'status.info',
    url: propertyIdTemplateUrl,
    actions: [{
      label: 'Vedi immobile',
      url: propertyIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.PROPERTY_PREVIEW]: {
    title: 'Anteprima immobile',
    message: `L'immobile DV-{${PROPERTY_ID_PLACEHOLDER}} è disponibile in anteprima.`,
    icon: ICON_HOME_SEARCH_OUTLINE,
    iconColor: 'status.info',
    url: propertyIdTemplateUrl,
    actions: [{
      label: 'Vedi immobile',
      url: propertyIdTemplateUrl,
    }],
  },
  [ApplicationNotificationType.ASSIGNMENT_DIGITALLY_SIGNED]: {
    title: 'Incarico firmato',
    message: `L'utente <strong>{${CONTACT_NAME_PLACEHOLDER}}</strong> ha firmato l'incarico.`,
    icon: ICON_FILE_SIGN,
    iconColor: 'status.info',
    url: propertyIdTemplateUrl,
    actions: [{
      label: 'Vedi immobile',
      url: propertyIdTemplateUrl,
    }],
  },
};
