import React, { FunctionComponent, useMemo } from 'react';
import { Form, FormActionsAlign, FormProps } from '@doveit/bricks';
import { Note } from '../../../providers/api/dtos/note';
import schema from './NoteForm.schema';

export type NoteFormModel = Partial<Note>;

export interface NoteFormProps extends React.AriaAttributes {
  initialValues?: NoteFormModel,
  allowBlankText?: boolean,
  showNudgeCheckbox?: boolean,
  loading?: FormProps<NoteFormModel>['loading'],
  innerRef?: FormProps<NoteFormModel>['innerRef'],
  onSubmit: FormProps<NoteFormModel>['onSubmit']
}

const NoteForm: FunctionComponent<NoteFormProps> = ({
  initialValues,
  showNudgeCheckbox = false,
  allowBlankText = false,
  innerRef,
  loading,
  onSubmit,
  ...rest
}) => {
  const mergedInitialValues = useMemo<NoteFormModel>(() => ({
    text: '',
    nudge: false,
    ...(initialValues || {}),
  }), [initialValues]);

  return (
    <Form
      {...rest}
      innerRef={innerRef}
      initialValues={mergedInitialValues}
      validationSchema={schema({ allowBlankText })}
      loading={loading}
      onSubmit={onSubmit}
    >
      <Form.Item>
        <Form.TextArea
          name="text"
          label="Testo"
          aria-label="Inserisci il testo della nota"
        />
      </Form.Item>
      {showNudgeCheckbox && (
        <Form.Item>
          <Form.Checkbox
            name="nudge"
            text="Invia notifica all'agente"
            aria-label="Checkbox per confermare l'invio della notifica all'agente"
          />
        </Form.Item>
      )}

      <Form.Actions align={FormActionsAlign.RIGHT}>
        <Form.Submit label={initialValues?.text ? 'Modifica' : 'Aggiungi'} />
      </Form.Actions>
    </Form>
  );
};

export default NoteForm;
