import { Form, FormAutoCompleteV2Props, ICON_MAGNIFY } from '@doveit/bricks';
import React, { FunctionComponent, useCallback } from 'react';
import { PlaceSuggestion, Place, PlaceType } from '../../providers/public-api/dtos';
import { getPlaceSuggestions } from '../../providers/public-api/location/locationProvider';

export interface PlaceAutoCompleteProps extends Omit<FormAutoCompleteV2Props<PlaceSuggestion | null>, 'aria-label' | 'icon' | 'suggestions' | 'printSuggestion'> {
  placeType?: PlaceType | PlaceType[],
  maxSuggestions?: number,
  excludedSuggestions?: string | string[],
}

const PlaceAutoComplete: FunctionComponent<PlaceAutoCompleteProps> = ({
  name,
  placeType,
  maxSuggestions,
  onSuggestionSelected,
  excludedSuggestions,
  ...rest
}) => {
  const getPlaceSuffix = useCallback((place: Place) => {
    switch (place.type) {
      case PlaceType.PROVINCE:
        return 'Provincia';
      case PlaceType.LOCALITY:
        return 'Comune';
      case PlaceType.AREA:
        return `${place.parent ? place.parent.name : 'Quartiere'}`;
      default:
        throw new Error('Unreachable');
    }
  }, []);

  const fetchSuggestions = useCallback(async (query: string) => getPlaceSuggestions(query, {
    type: placeType,
    size: maxSuggestions,
    excludeName: excludedSuggestions,
  }), [placeType, maxSuggestions, excludedSuggestions]);

  const printSuggestion = useCallback(
    (suggestion: PlaceSuggestion) => `${suggestion.place.name} - ${getPlaceSuffix(suggestion.place)}`,
    [getPlaceSuffix],
  );

  const printLabelSuggestion = useCallback(
    (suggestion: PlaceSuggestion) => (<span dangerouslySetInnerHTML={{ __html: `${suggestion.highlightedSuggestion} - ${getPlaceSuffix(suggestion.place)}` }} />),
    [getPlaceSuffix],
  );

  return (
    <Form.AutoCompleteV2
      {...rest}
      name={name}
      aria-label="Campo di ricerca per località"
      icon={ICON_MAGNIFY}
      suggestions={fetchSuggestions}
      printLabelSuggestion={printLabelSuggestion}
      printSuggestion={printSuggestion}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default PlaceAutoComplete;
