import { ICON_MAP_MARKER_OUTLINE } from '@doveit/bricks';
import {
  GoogleMap, GoogleMapProps, Marker,
} from '@react-google-maps/api';
import React, { FunctionComponent, useCallback } from 'react';
import { DEFAULT_MARKER_SIZE } from '../../constants';
import getMarkerIcon, { DEFAULT_SCALE_RATIO } from '../../utils/get-marker-icon/getMarkerIcon';

export interface SimpleGoogleMapProps extends GoogleMapProps {
  latitude: number,
  longitude: number,
  searchRadius?: number,
  marker?: {
    draggable?: boolean,
    onDragEnd?: (latitude: number, longitude: number) => void
  }
}

const containerStyle = {
  width: '100%',
  height: '100%',
};

export const PROPERTY_REFERENCE_MARKER_COLOR = '#000';
const MARKER_SIZE = DEFAULT_MARKER_SIZE * DEFAULT_SCALE_RATIO;

export const MAP_WITH_CUSTOM_STYLES: google.maps.MapTypeStyle[] = [
  {
    featureType: 'poi',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'transit.station.bus',
    stylers: [
      { visibility: 'off' },
    ],
  },
  {
    featureType: 'transit.station.rail',
    stylers: [
      { visibility: 'off' },
    ],
  },
];

const SimpleGoogleMap: FunctionComponent<SimpleGoogleMapProps> = ({
  latitude,
  longitude,
  searchRadius,
  children,
  options = {},
  marker,
  zoom = 10,
  onLoad,
  ...rest
}) => {
  const onInternalLoad = useCallback((mapInstance: google.maps.Map) => {
    if (searchRadius) {
      const circle = new google.maps.Circle({
        radius: searchRadius,
        center: {
          lat: latitude,
          lng: longitude,
        },
      });

      const bounds = circle.getBounds();

      if (!bounds) {
        return;
      }

      mapInstance.fitBounds(bounds);
    }

    if (onLoad) {
      onLoad(mapInstance);
    }
  }, [searchRadius, latitude, longitude, onLoad]);

  const onMarkerDragEnd = useCallback((event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat();
    const long = event.latLng?.lng();

    if (lat && long) {
      marker!.onDragEnd!(lat, long);
    }
  }, [marker]);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={{
        ...options,
        styles: MAP_WITH_CUSTOM_STYLES,
      }}
      center={{
        lat: latitude,
        lng: longitude,
      }}
      clickableIcons={false}
      onLoad={onInternalLoad}
      zoom={zoom}
      {...rest}
    >
      <Marker
        draggable={marker?.draggable}
        onDragEnd={marker?.onDragEnd && onMarkerDragEnd}
        position={{
          lat: latitude,
          lng: longitude,
        }}
        icon={{
          url: getMarkerIcon(PROPERTY_REFERENCE_MARKER_COLOR, ICON_MAP_MARKER_OUTLINE),
          size: {
            width: MARKER_SIZE,
            height: MARKER_SIZE,
          } as google.maps.Size,
          origin: {
            x: 0,
            y: 0,
          } as google.maps.Point,
          anchor: {
            x: MARKER_SIZE / 2,
            y: MARKER_SIZE / 2,
          } as google.maps.Point,
        }}
      />
      {children}
    </GoogleMap>
  );
};

export default SimpleGoogleMap;
