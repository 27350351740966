import { ConversationMessage } from '../../../providers/api/dtos';

export enum ConversationChatActionType {
  ADD_NEW_MESSAGES = 'ADD_NEW_MESSAGES',
  ADD_PREVIOUS_MESSAGES = 'ADD_PREVIOUS_MESSAGES',
  RESET_ALL_MESSAGES = 'RESET_ALL_MESSAGES',
}

export interface ConversationChatAction {
  type: ConversationChatActionType,
  payload: ConversationMessage[],
}

export function conversationChatReducer(messages: ConversationMessage[], action: ConversationChatAction): ConversationMessage[] {
  switch (action.type) {
    case ConversationChatActionType.ADD_NEW_MESSAGES: {
      return [
        ...messages,
        ...action.payload,
      ];
    }
    case ConversationChatActionType.ADD_PREVIOUS_MESSAGES: {
      return [
        ...action.payload,
        ...messages,
      ];
    }
    case ConversationChatActionType.RESET_ALL_MESSAGES: {
      return action.payload;
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}
