/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid, ICON_PLUS } from '@doveit/bricks';
import { Content } from '../../../providers/api/dtos';
import MultimediaContentThumbnail from '../../../content/components/MultimediaContentThumbnail';
import { ContentType } from '../../../domain/types';

export interface PropertyGalleryPreviewProps extends React.AriaAttributes {
  contents: Content[],
  displayedSlots?: number,
  onContentClick?: (index: number) => void,
  onAddClick?: VoidFunction,
}

const PropertyGalleryPreview: React.FC<PropertyGalleryPreviewProps> = ({
  contents,
  displayedSlots = 5,
  onContentClick,
  onAddClick,
  'aria-label': ariaLabel = 'property-gallery-preview',
  ...rest
}) => {
  const inline = React.useMemo(
    () => (onAddClick ? displayedSlots - 1 : displayedSlots),
    [displayedSlots, onAddClick],
  );

  return (
    <Grid
      {...rest}
      gutter={25}
      aria-label={ariaLabel}
    >
      {contents.slice(0, inline).map((content, index) => (
        <Grid.Unit
          key={content.id}
          size={1 / displayedSlots}
        >
          <MultimediaContentThumbnail
            aria-label={`${ariaLabel} (content-thumbnail)`}
            picture={[ContentType.FOTO, ContentType.PLANIMETRIA].includes(content.contentType)
              ? { src: content.thumbnail!, alt: content.about || '' }
              : undefined}
            index={index + 1}
            onClick={onContentClick ? () => onContentClick(index) : undefined}
          />
        </Grid.Unit>
      ))}
      {Array.from({ length: inline - Math.min(contents.length, displayedSlots) }).map((_, index) => (
        <Grid.Unit
          key={`${ariaLabel} content-${index}`}
          size={1 / displayedSlots}
        >
          <MultimediaContentThumbnail
            aria-label={`${ariaLabel} (content-thumbnail-placeholder)`}
          />
        </Grid.Unit>
      ))}
      {!!onAddClick && (
        <Grid.Unit size={1 / displayedSlots}>
          <MultimediaContentThumbnail
            aria-label={`${ariaLabel} (content-thumbnail-add)`}
            operation="add"
            onClick={onAddClick}
            placeholderIcon={ICON_PLUS}
          />
        </Grid.Unit>
      )}
    </Grid>
  );
};

export default PropertyGalleryPreview;
