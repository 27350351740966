export enum Aggregator {
  DOVE_IT = 'DOVE_IT',
  IMMOBILIARE_IT = 'IMMOBILIARE_IT',
  IDEALISTA = 'IDEALISTA',
  CASA_IT = 'CASA_IT',
  WIKICASA = 'WIKICASA',
  GATE_AWAY = 'GATE_AWAY',
  JAMES_EDITION = 'JAMES_EDITION',
}

export const LUXURY_AGGREGATORS: Aggregator[] = [
  Aggregator.JAMES_EDITION,
];

export const AGGREGATORS: Aggregator[] = Object.values(Aggregator).filter((aggregator) => !LUXURY_AGGREGATORS.includes(aggregator));
