/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Message } from '@doveit/bricks';
import type { ViewContactActivitiesSummaryProps } from '../../ViewContactActivitiesSummary';
import useContactProspects from '../../../../hooks/use-contact-prospects/useContactProspects';
import { CONTACT_ACTIVITIES_SUMMARY_PROSPECTS_FILTERS, CONTACT_PROSPECTS_LOAD_ERROR, PROSPECT_STATUS_SORT_ORDER } from '../constants';
import Divider from '../../../../../components/divider/Divider';
import ContactActivityCardSkeleton from '../../../../components/contact-activity-card/ContactActivityCard.skeleton';
import { orderByStatus } from '../../utils';
import ContactProspectSummaryCard from '../contact-prospect-summary-card/ContactProspectSummaryCard';
import * as styles from '../../ViewContactActivitiesSummary.style';

const ContactProspectsSummaryGroup: React.FC<ViewContactActivitiesSummaryProps> = ({
  contactId,
}) => {
  const { data: prospects, error: prospectsError } = useContactProspects(contactId, CONTACT_ACTIVITIES_SUMMARY_PROSPECTS_FILTERS);

  if (prospectsError) {
    return (
      <Message
        type="critical"
        message={CONTACT_PROSPECTS_LOAD_ERROR}
      />
    );
  }

  if (!prospects) {
    return <ContactActivityCardSkeleton />;
  }

  if (prospects.length === 0) {
    return null;
  }

  return (
    <div aria-label="Lista delle valutazioni del contatto">
      <Divider>
        Valutazioni
      </Divider>
      <div css={styles.activityGroupWrapper}>
        {orderByStatus(prospects, PROSPECT_STATUS_SORT_ORDER).map((prospect) => (
          <ContactProspectSummaryCard
            key={prospect.id}
            prospect={prospect}
          />
        ))}
      </div>
    </div>
  );
};

export default ContactProspectsSummaryGroup;
