import React from 'react';
import { useParams } from 'react-router-dom';
import { raise } from '../../../utils';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import PropertyMatchesWidget from '../../containers/property-matches-widget/PropertyMatchesWidget';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';

export const LOAD_PROPERTY_MATCHING_FAILING_PRECONDITIONS_ERROR_MESSAGE = "Non è possibile visualizzare le informazioni di matching dell'immobile";

const ViewPropertyMatchingPage: React.FC = () => {
  const { id } = useParams();

  const propertyId = id
    ? parseInt(id, 10)
    : raise('missing property id');

  const { data: agent } = useAgentByPropertyId(propertyId);
  const { userIsAdmin, userIsContentEditor, userIsCallCenter } = useRBAC();
  const userIsPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);

  const canViewPage = React.useMemo(
    () => userIsAdmin || userIsContentEditor || userIsCallCenter || userIsPropertyAgentOrManager,
    [userIsAdmin, userIsContentEditor, userIsCallCenter, userIsPropertyAgentOrManager],
  );

  if (!canViewPage) {
    return <GenericErrorPage title={LOAD_PROPERTY_MATCHING_FAILING_PRECONDITIONS_ERROR_MESSAGE} />;
  }

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content
        title="Matching"
      >
        <PropertyMatchesWidget
          id="property-matching-section"
          propertyId={propertyId}
        />
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default ViewPropertyMatchingPage;
