import React from 'react';
import {
  Action, ActionIcon, Card, ConfirmModal, FormHandlers, Grid, HStack, ICON_CHECK, ICON_CLOSE, ICON_PENCIL_OUTLINE, ICON_TRASH_CAN_OUTLINE, Message, Portal, Spacing, Stack, Text,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import { Property, Cellar } from '../../../../providers/api/dtos';
import useIsDevice from '../../../../hooks/use-is-device/useIsDevice';
import SimpleTableSkeleton from '../../../../components/simple-table/SimpleTable.skeleton';
import SimpleTable from '../../../../components/simple-table/SimpleTable';
import { NO_VALUE_SYMBOL } from '../../../utils';
import Divider from '../../../../components/divider/Divider';
import useCellars from '../../../hooks/use-cellars/useCellars';
import UpsertPropertySpaceWithSizeForm, { UpsertPropertySpaceWithSizeFormModel } from '../../../spaces/components/upsert-property-space-with-size-form/UpsertPropertySpaceWithSizeForm';
import { createCellar, deleteCellar, updateCellar } from '../../../../providers/api/cellar/cellarProvider';

export interface ManagePropertyCellarsProps {
  propertyId: NonNullable<Property['id']>,
  onCreate?: (createdCellar: Cellar) => void,
  onUpdate?: (updatedCellar: Cellar) => void,
  onDelete?: (deletedCellarId: NonNullable<Cellar['id']>) => void,
}

const ManagePropertyCellars: React.FC<ManagePropertyCellarsProps> = ({
  propertyId,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const isMobile = useIsDevice('mobile');
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const [isEditing, setIsEditing] = React.useState<{ cellar: Cellar } | false>(false);
  const createCellarFormRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const updateCellarFormRefs = React.useRef<{ [key: number]: React.MutableRefObject<any> }>({});
  const deleteCellarModal = useModal<{ cellar: Cellar }>();

  const {
    data: cellars,
    isLoading: areCellarsLoading,
    error: cellarsError,
    mutate: mutateCellars,
  } = useCellars(propertyId);

  const addCellar = React.useCallback(async ({ size }: UpsertPropertySpaceWithSizeFormModel) => {
    try {
      setIsSaving(true);

      const cellarToCreate: Cellar = {
        id: null,
        propertyId,
        size: size ? parseFloat(size) : null,
      };

      const createdCellar = await createCellar(cellarToCreate);

      setIsSaving(false);
      addSuccess('La cantina è stata aggiunta con successo.');

      onCreate?.(createdCellar);
      await mutateCellars();
    } catch (error) {
      setIsSaving(false);
      addError('Non è stato possibile aggiungere la cantina.');
    }
  }, [addError, addSuccess, mutateCellars, onCreate, propertyId]);

  const editCellar = React.useCallback(async ({ size }: UpsertPropertySpaceWithSizeFormModel) => {
    if (!isEditing) return;

    try {
      setIsSaving(true);

      const cellarToUpdate: Cellar = {
        ...isEditing.cellar,
        size: size ? parseFloat(size) : null,
      };

      const updatedCellar = await updateCellar(cellarToUpdate);

      setIsSaving(false);
      addSuccess('La cantina è stata modificata con successo.');
      setIsEditing(false);

      onUpdate?.(updatedCellar);
      await mutateCellars();
    } catch (_) {
      setIsSaving(false);
      addError('Non è stato possibile modificare la cantina.');
    }
  }, [addError, addSuccess, isEditing, mutateCellars, onUpdate]);

  const removeCellar = React.useCallback(async () => {
    if (!deleteCellarModal.data?.cellar) return;

    try {
      setIsSaving(true);

      await deleteCellar(deleteCellarModal.data.cellar.id!);

      setIsSaving(false);
      addSuccess('La cantina è stata rimossa con successo.');
      deleteCellarModal.close();

      onDelete?.(deleteCellarModal.data.cellar.id!);
      await mutateCellars();
    } catch (error) {
      setIsSaving(false);
      addError('Non è stato possibile rimuovere la cantina.');
    }
  }, [addError, addSuccess, deleteCellarModal, mutateCellars, onDelete]);

  const getUpdateCellarFormRef = React.useCallback((id: number) => {
    if (!updateCellarFormRefs.current[id]) {
      updateCellarFormRefs.current[id] = React.createRef();
    }
    return updateCellarFormRefs.current[id];
  }, []);

  const submitCreateCellarForm = React.useCallback(() => {
    createCellarFormRef.current?.handleSubmit();
  }, []);

  const submitUpdateCellarForm = React.useCallback((id: number) => {
    if (updateCellarFormRefs.current[id]) {
      updateCellarFormRefs.current[id].current.handleSubmit();
    }
  }, []);

  if (areCellarsLoading && !isMobile) {
    return <SimpleTableSkeleton aria-label="Cantine in caricamento" />;
  }

  if (cellarsError) {
    return (
      <Message
        type="critical"
        message="Non è stato possibile caricare le cantine."
      />
    );
  }

  return (
    <>
      <Stack gap={200} data-testid="manage-property-cellars">
        {!isMobile && (
          <SimpleTable aria-label="Tabella cantine">
            <SimpleTable.Body>
              {cellars?.length === 0 && (
                <SimpleTable.Row>
                  <SimpleTable.Cell>
                    <Message
                      type="neutral"
                      message="Nessuna cantina inserita."
                    />
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              )}
              {cellars?.map((cellar) => (
                <SimpleTable.Row
                  key={cellar.id}
                  aria-label={`Informazioni cantina ${cellar.id}`}
                >
                  {(isEditing && isEditing.cellar.id === cellar.id) ? (
                    <>
                      <SimpleTable.Cell colSpan={3}>
                        <UpsertPropertySpaceWithSizeForm
                          loading={isSaving}
                          initialValues={{ size: cellar.size?.toString() }}
                          innerRef={getUpdateCellarFormRef(cellar.id!)}
                          onSubmit={editCellar}
                        />
                      </SimpleTable.Cell>
                      <SimpleTable.Cell align="right">
                        <HStack>
                          <ActionIcon
                            size="S"
                            color="primary"
                            icon={{ path: ICON_CHECK }}
                            label="Conferma modifiche"
                            aria-label="Conferma modifiche"
                            onClick={() => submitUpdateCellarForm(cellar.id!)}
                          />
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_CLOSE }}
                            label="Annulla modifiche"
                            aria-label="Annulla modifiche"
                            onClick={() => setIsEditing(false)}
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </>
                  ) : (
                    <>
                      <SimpleTable.Cell colSpan={3}>
                        <Text.BodySmall>
                          {cellar.size ? formatSquareMeters(cellar.size) : NO_VALUE_SYMBOL}
                        </Text.BodySmall>
                      </SimpleTable.Cell>
                      <SimpleTable.Cell align="right">
                        <HStack>
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_PENCIL_OUTLINE }}
                            label="Modifica cantina"
                            aria-label="Modifica cantina"
                            onClick={() => setIsEditing({ cellar })}
                          />
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                            label="Rimuovi cantina"
                            aria-label="Rimuovi cantina"
                            onClick={() => deleteCellarModal.open({ cellar })}
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </>
                  )}
                </SimpleTable.Row>
              ))}
              <SimpleTable.Row aria-label="Aggiungi informazioni nuova cantina">
                <SimpleTable.Cell colSpan={3}>
                  <UpsertPropertySpaceWithSizeForm
                    loading={isSaving}
                    innerRef={createCellarFormRef}
                    onSubmit={(values, actions) => {
                      actions.resetForm();

                      addCellar(values);
                    }}
                  />
                </SimpleTable.Cell>
                <SimpleTable.Cell align="right">
                  <Action
                    size="S"
                    label="Aggiungi"
                    aria-label="Aggiungi cantina"
                    onClick={submitCreateCellarForm}
                  />
                </SimpleTable.Cell>
              </SimpleTable.Row>
            </SimpleTable.Body>
          </SimpleTable>
        )}
        {isMobile && (
          <Stack
            gap={150}
            aria-label="Lista card cantine"
          >
            {cellars?.length === 0 && (
              <Spacing margin={[0, 0, 400]}>
                <Message
                  type="neutral"
                  message="Nessuna cantina inserita."
                />
              </Spacing>
            )}
            {cellars?.map((cellar, idx) => (
              <Card
                key={cellar.id}
                aria-label={`Informazioni cantina ${cellar.id}`}
              >
                <Card.Header
                  title={`Cantina (${idx + 1})`}
                  primaryActions={
                    !(isEditing && isEditing.cellar.id === cellar.id) ? [
                      <ActionIcon
                        size="S"
                        icon={{ path: ICON_PENCIL_OUTLINE }}
                        label="Modifica cantina"
                        aria-label="Modifica cantina"
                        onClick={() => setIsEditing({ cellar })}
                      />,
                      <ActionIcon
                        size="S"
                        icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                        label="Rimuovi cantina"
                        aria-label="Rimuovi cantina"
                        onClick={() => deleteCellarModal.open({ cellar })}
                      />,
                    ] : undefined
                  }
                />
                <Card.Content>
                  {(isEditing && isEditing.cellar.id === cellar.id) ? (
                    <>
                      <UpsertPropertySpaceWithSizeForm
                        loading={isSaving}
                        initialValues={{ size: cellar.size?.toString() }}
                        innerRef={getUpdateCellarFormRef(cellar.id!)}
                        onSubmit={editCellar}
                      />
                      <Divider />
                      <Grid gutter={150}>
                        <Grid.Unit size={1 / 2}>
                          <Action
                            expanded
                            size="S"
                            color="primary"
                            label="Salva"
                            aria-label="Conferma modifiche"
                            onClick={() => submitUpdateCellarForm(cellar.id!)}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={1 / 2}>
                          <Action
                            expanded
                            size="S"
                            label="Annulla"
                            aria-label="Annulla modifiche"
                            onClick={() => setIsEditing(false)}
                          />
                        </Grid.Unit>
                      </Grid>
                    </>
                  ) : (
                    <Text.BodySmall>
                      {cellar.size ? formatSquareMeters(cellar.size) : NO_VALUE_SYMBOL}
                    </Text.BodySmall>
                  )}
                </Card.Content>
              </Card>
            ))}
            <Card aria-label="Aggiungi informazioni nuova cantina">
              <Card.Header title="Aggiungi nuova cantina" />
              <Card.Content>
                <>
                  <UpsertPropertySpaceWithSizeForm
                    loading={isSaving}
                    innerRef={createCellarFormRef}
                    onSubmit={(values, actions) => {
                      actions.resetForm();

                      addCellar(values);
                    }}
                  />
                  <Divider />
                  <Action
                    expanded
                    size="S"
                    label="Aggiungi"
                    aria-label="Aggiungi cantina"
                    onClick={submitCreateCellarForm}
                  />
                </>
              </Card.Content>
            </Card>
          </Stack>
        )}
      </Stack>
      <Portal>
        {deleteCellarModal.data?.cellar && (
          <ConfirmModal
            variant="critical"
            isOpen={deleteCellarModal.isOpen}
            title="Conferma rimozione cantina"
            aria-label="Conferma la rimozione della cantina"
            onConfirm={removeCellar}
            onAbort={deleteCellarModal.close}
          >
            Confermando l&apos;operazione, la cantina sarà rimossa.
          </ConfirmModal>
        )}
      </Portal>
    </>
  );
};

export default ManagePropertyCellars;
