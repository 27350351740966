import { SelectOption } from '@doveit/bricks';
import { IntentStatus } from '../../../domain/types';
import { intentStatusLabels } from '../../../labels';

export const koReasonOptions: SelectOption[] = [
  {
    value: '',
    label: 'Seleziona una motivazione',
  },
  ...[
    IntentStatus.KO_INFO,
    IntentStatus.KO_PROPERTY,
    IntentStatus.KO_AREA,
    IntentStatus.KO_OVERPRICED,
    IntentStatus.KO_BOUGHT_OTHER,
    IntentStatus.KO_NOT_INTERESTED,
  ].map((key) => ({
    value: key,
    label: intentStatusLabels[key as IntentStatus],
  })),
];

export const koOfferRefusedReasonOptions: SelectOption[] = [
  {
    value: '',
    label: 'Seleziona una motivazione',
  },
  ...[
    IntentStatus.KO_OVERPRICED,
    IntentStatus.KO_BOUGHT_OTHER,
  ].map((key) => ({
    value: key,
    label: intentStatusLabels[key as IntentStatus],
  })),
];
