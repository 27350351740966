import React from 'react';
import { Form, FormProps } from '@doveit/bricks';
import { ownershipTypeWizardLabels } from '../../../../labels/ownershipTypeWizardLabels';
import { asSelectOptions } from '../../../../select-options/utils/asSelectOptions';

export interface PropertyOwnershipTypeFormModel {
  type: string,
  details: string,
}

export interface PropertyOwnershipTypeFormProps extends React.AriaAttributes {
  initialValues?: Partial<PropertyOwnershipTypeFormModel>,
  loading?: FormProps<PropertyOwnershipTypeFormModel>['loading'],
  disabled?: FormProps<PropertyOwnershipTypeFormModel>['disabled'],
  innerRef: FormProps<PropertyOwnershipTypeFormModel>['innerRef'],
  onSubmit: FormProps<PropertyOwnershipTypeFormModel>['onSubmit'],
}

export const DEFAULT_INITIAL_VALUES: PropertyOwnershipTypeFormModel = {
  type: '',
  details: '',
};

const PropertyOwnershipTypeForm: React.FC<PropertyOwnershipTypeFormProps> = (props) => {
  const { initialValues = {}, ...rest } = props;

  const mergedInitialValues: PropertyOwnershipTypeFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      {...rest}
    >
      {({ values, setFieldValue }) => {
        const onOwnershipTypeChange = () => {
          setFieldValue('details', '', false);
        };
        return (
          <>
            <Form.Item>
              <Form.RadioButtonGroup
                name="type"
                label="Tipologia"
                itemSize={{ MD: 1 / Object.keys(ownershipTypeWizardLabels).length }}
                onChange={onOwnershipTypeChange}
                items={asSelectOptions(ownershipTypeWizardLabels, false)}
              />
            </Form.Item>

            <Form.Item>
              <Form.Input
                name="details"
                aria-label="Campo per inserire la tipologia di proprietà non prevista"
                placeholder="Inserisci tipologia non prevista"
                disabled={values.type !== 'ALTRO'}
              />
            </Form.Item>
          </>
        );
      }}

    </Form>
  );
};

export default PropertyOwnershipTypeForm;
