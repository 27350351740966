/* eslint-disable react/no-unknown-property */
import {
  Action, HStack, ICON_EMAIL_OUTLINE, ICON_MESSAGE_PROCESSING_OUTLINE, ICON_WHATSAPP, Message, Modal, ModalSize, Portal, Spacing, Text, useModal, useNotifications,
} from '@doveit/bricks';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import { Medium } from '../../../domain/types';
import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import { createUpdateContactInfoNotification } from '../../../providers/api/notification/notificationProvider';
import useContact from '../../hooks/use-contact/useContact';
import { createJWTSignature } from '../../../providers/api/jwt-signature/jwtSignatureProvider';
import { getShortURL } from '../../../providers/rebrandly/rebrandlyProvider';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';

interface ChildFnProps {
  isLoading: boolean,
  openModal: VoidFunction,
}

export interface UpdateContactInfoActionProps {
  contactId: string,
  modalTitle: string,
  userEmail?: string,
  hasServices?: boolean,
  canSendWhatsApp?: boolean,
  canSendSMS?: boolean,
  canSendEmail?: boolean,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const SEND_UPDATE_CONTACT_INFO_EMAIL_ERROR_MESSAGE = 'Non è stato possibile inviare l\'email.';
export const SEND_UPDATE_CONTACT_INFO_SMS_ERROR_MESSAGE = 'Non è stato possibile inviare l\'SMS.';
export const SEND_UPDATE_CONTACT_INFO_EMAIL_SUCCESS_MESSAGE = 'L\'email è stata inviata correttamente.';
export const SEND_UPDATE_CONTACT_INFO_SMS_SUCCESS_MESSAGE = 'L\'SMS è stato inviato correttamente.';
export const IMPOSSIBLE_GET_PHONE_NUMBER_ERROR_MESSAGE = 'Non è possibile inviare messaggi WhatsApp. Riprova più tardi.';
export const SERVICES_NO_THIRD_PARTIES_WHATSAPP_MESSAGE = 'Gentile cliente, per usufruire dei servizi di Dove.it e dei suoi partner, ti chiedo cortesemente di compilare le tue preferenze e di rilasciare i consensi al trattamento dei dati a $UPDATE_LINK. Grazie e a presto.';
export const NO_SERVICES_NO_THIRD_PARTIES_WHATSAPP_MESSAGE = 'Gentile cliente, sappiamo che la compravendita di un immobile è un evento molto importante che richiede particolare attenzione. Dove.it e i suoi partner ti offrono alcuni servizi accessori per semplificare e velocizzare la tua esperienza. Per usufruire dei servizi, ti chiedo cortesemente di compilare le tue preferenze e di rilasciare i consensi al trattamento dei dati a $UPDATE_LINK. Grazie e a presto.';

const UpdateContactInfoAction: FunctionComponent<UpdateContactInfoActionProps> = ({
  contactId,
  modalTitle,
  userEmail,
  hasServices,
  canSendWhatsApp,
  canSendSMS,
  canSendEmail,
  children,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { data: contact, error: contactError } = useContact(contactId);
  const agent = useCurrentAgent();
  const actionModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const getWhatsAppMessage = useCallback(async () => {
    const jwtSignature = await createJWTSignature({
      type: 'TRIGGER',
      contactId,
      operator: userEmail,
    });
    const shortURL = await getShortURL(`https://www.dove.it/aggiorna-info-contatto/${jwtSignature}?utm_campaign=WHATSAPP_UPDATE_CONTACT_INFO&amp;utm_medium=whatsapp&amp;utm_source=crm`);

    const message = hasServices
      ? SERVICES_NO_THIRD_PARTIES_WHATSAPP_MESSAGE
      : NO_SERVICES_NO_THIRD_PARTIES_WHATSAPP_MESSAGE;

    return message.replace('$UPDATE_LINK', shortURL);
  }, [contactId, hasServices, userEmail]);

  const disabledSMSaction = useMemo(
    () => contact && (!contact.phoneNumber || parsePhoneNumber(contact.phoneNumber, 'IT')?.getType() !== 'MOBILE'),
    [contact],
  );

  const sendUpdateContactEmail = useCallback(async () => {
    setIsSaving(true);

    try {
      await createUpdateContactInfoNotification({
        medium: Medium.EMAIL,
        contactId,
        agentId: agent ? agent.id! : undefined,
      });

      setIsSaving(false);
      addSuccess(SEND_UPDATE_CONTACT_INFO_EMAIL_SUCCESS_MESSAGE);

      actionModal.close();
    } catch (error) {
      setIsSaving(false);
      addError(SEND_UPDATE_CONTACT_INFO_EMAIL_ERROR_MESSAGE);
    }
  }, [actionModal, addError, addSuccess, agent, contactId]);

  const sendUpdateContactSMS = useCallback(async () => {
    setIsSaving(true);

    try {
      await createUpdateContactInfoNotification({
        medium: Medium.SMS,
        contactId,
        agentId: agent ? agent.id! : undefined,
      });

      setIsSaving(false);
      addSuccess(SEND_UPDATE_CONTACT_INFO_SMS_SUCCESS_MESSAGE);

      actionModal.close();
    } catch (error) {
      setIsSaving(false);
      addError(SEND_UPDATE_CONTACT_INFO_SMS_ERROR_MESSAGE);
    }
  }, [actionModal, addError, addSuccess, agent, contactId]);

  if (!canSendWhatsApp && !canSendSMS && !canSendEmail) {
    return null;
  }

  return (
    <>
      {children ? children({
        openModal: actionModal.open,
        isLoading: isSaving,
      }) : (
        <Action
          label="Invia"
          size="S"
          iconLeft={{ path: ICON_EMAIL_OUTLINE }}
          color="warning"
          emphasis="high"
          onClick={actionModal.open}
          loading={isSaving}
        />
      )}
      <Portal>
        <Modal
          open={actionModal.isOpen}
          size={ModalSize.MEDIUM}
          onCloseHandler={actionModal.close}
        >
          <Modal.Close />
          <Modal.Header>
            <Text.H4>
              {modalTitle}
            </Text.H4>
          </Modal.Header>
          <Modal.Body padded>
            {contactError && (
              <Spacing margin={[0, 0, 200]}>
                <Message
                  type="critical"
                  message={IMPOSSIBLE_GET_PHONE_NUMBER_ERROR_MESSAGE}
                />
              </Spacing>
            )}
            <HStack justifyContent="center">
              <HStack>
                {canSendEmail && (
                  <Action
                    label="Email"
                    iconLeft={{ path: ICON_EMAIL_OUTLINE }}
                    onClick={sendUpdateContactEmail}
                    expanded
                  />
                )}
                {canSendWhatsApp && contact?.phoneNumber && (
                  <SendWhatsappAction
                    phoneNumber={contact?.phoneNumber}
                    name={contact?.name}
                    initialMessage={getWhatsAppMessage}
                  >
                    {({ onClick, disabled }) => (
                      <Action
                        label="WhatsApp"
                        iconLeft={{ path: ICON_WHATSAPP }}
                        onClick={onClick}
                        disabled={disabled}
                        expanded
                      />
                    )}
                  </SendWhatsappAction>
                )}
                {canSendSMS && (
                  <Action
                    label="SMS"
                    iconLeft={{ path: ICON_MESSAGE_PROCESSING_OUTLINE }}
                    onClick={sendUpdateContactSMS}
                    disabled={disabledSMSaction}
                    expanded
                  />
                )}
              </HStack>
            </HStack>
          </Modal.Body>
          <Modal.Footer padded>
            <Action
              label="Annulla"
              onClick={actionModal.close}
            />
          </Modal.Footer>
        </Modal>
      </Portal>
    </>
  );
};

export default UpdateContactInfoAction;
