import {
  ActionDropDown, ActionProps, Badge, Card, CardSkeleton, Dropdown, HStack, ICON_ACCOUNT_CIRCLE_OUTLINE,
  ICON_ALERT_CIRCLE_OUTLINE,
  ICON_CHECK,
  Message,
} from '@doveit/bricks';
import React from 'react';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import UpdateContactAdditionalServicesAction from '../update-contact-additional-services-action/UpdateContactAdditionalServicesAction';
import { Contact } from '../../../providers/api/dtos';
import UpdateContactOptInsAction from '../update-contact-opt-ins-action/UpdateContactOptInsAction';
import UpdateContactConcessionsAction from '../update-contact-concessions-action/UpdateContactConcessionsAction';
import ContactInfo from '../../components/contact-info/ContactInfo';
import useContact from '../../hooks/use-contact/useContact';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import useContactConcessions from '../../hooks/use-contact-concessions/useContactConcessions';
import { concessionLabels } from '../../../labels';
import UpdateContactAction from '../update-contact-action/UpdateContactAction';
import { slugIt } from '../../../utils/string';

export interface ContactWidgetProps extends React.AriaAttributes {
  contactId: NonNullable<Contact['id']>,
  canEdit?: boolean,
  canAddAdditionalServices?: boolean,
  canDeleteAdditionalServices?: boolean,
  canAddOptIns?: boolean,
  canDeleteOptIns?: boolean,
  canAddConcessions?: boolean,
  canDeleteConcessions?: boolean,
  onContactUpdated?: (updatedContact: Contact) => void,
  asNestedComponent?: boolean,
  extraActions?: ActionProps[],
}

const ContactWidget: React.FC<ContactWidgetProps> = ({
  contactId,
  canEdit = false,
  canAddAdditionalServices = false,
  canDeleteAdditionalServices = false,
  canAddOptIns = false,
  canDeleteOptIns = false,
  canAddConcessions = false,
  canDeleteConcessions = false,
  onContactUpdated,
  asNestedComponent = false,
  extraActions = [],
}) => {
  const isMobile = useIsDevice('mobile');
  const {
    data: contact,
    isLoading: isContactLoading,
    error: contactError,
    mutate: mutateContact,
  } = useContact(contactId);
  const { data: contactConcessions = [] } = useContactConcessions(contactId);

  const showActionDropdownOnDesktop = React.useMemo(
    () => (!isMobile && (canEdit || canAddAdditionalServices || canDeleteAdditionalServices || canAddOptIns || canDeleteOptIns || canAddConcessions || canDeleteConcessions)),
    [canAddAdditionalServices, canAddConcessions, canAddOptIns, canDeleteAdditionalServices, canDeleteConcessions, canDeleteOptIns, canEdit, isMobile],
  );

  const goToContactPage = React.useCallback(
    () => goToDetailPage(contactId, ReferenceType.CONTACT),
    [contactId],
  );

  const onInternalContactUpdated = React.useCallback((updatedContact: Contact) => {
    mutateContact();
    onContactUpdated?.(updatedContact);
  }, [mutateContact, onContactUpdated]);

  if (isContactLoading) {
    return (
      <CardSkeleton
        aria-label="Contatto in caricamento"
        asContent={asNestedComponent}
      >
        {(!isMobile && !asNestedComponent) && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  if (contactError) {
    return (
      <Card aria-label="Errore caricamento contatto" asContent={asNestedComponent}>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Contatto"
          color="critical"
        />
        <Card.Content>
          <Message message="Non è stato possibile caricare il contatto." />
        </Card.Content>
      </Card>
    );
  }

  return contact && (
    <Card aria-label="Sezione del contatto" asContent={asNestedComponent}>
      <Card.Header
        title="Contatto"
        icon={{ path: ICON_ACCOUNT_CIRCLE_OUTLINE }}
        primaryActions={(!isMobile && showActionDropdownOnDesktop) ? [
          <ActionDropDown
            label="Modifica"
            size="S"
            emphasis="low"
            aria-label="Azioni di modifica del contatto"
          >
            {canEdit && (
              <UpdateContactAction
                contact={contact}
                onSuccess={onInternalContactUpdated}
              >
                {({ onClick }) => (
                  <Dropdown.Option
                    onClick={onClick}
                    label="Modifica contatto"
                  />
                )}
              </UpdateContactAction>
            )}
            {(canAddAdditionalServices || canDeleteAdditionalServices) && (
              <UpdateContactAdditionalServicesAction
                contactId={contactId}
                canAdd={canAddAdditionalServices}
                canDelete={canDeleteAdditionalServices}
              >
                {({ isSaving, update }) => (
                  <Dropdown.Option
                    onClick={update}
                    label="Modifica servizi"
                    loading={isSaving}
                  />
                )}
              </UpdateContactAdditionalServicesAction>
            )}
            {(canAddOptIns || canDeleteOptIns) && (
              <UpdateContactOptInsAction
                contactId={contactId}
                canAdd={canAddOptIns}
                canDelete={canDeleteOptIns}
              >
                {({ isSaving, update }) => (
                  <Dropdown.Option
                    onClick={update}
                    label="Modifica consensi"
                    loading={isSaving}
                  />
                )}
              </UpdateContactOptInsAction>
            )}
            {(canAddConcessions || canDeleteConcessions) && (
              <UpdateContactConcessionsAction
                contactId={contactId}
                canAdd={canAddConcessions}
                canDelete={canDeleteConcessions}
              >
                {({ isSaving, update }) => (
                  <Dropdown.Option
                    onClick={update}
                    label="Modifica agevolazioni"
                    loading={isSaving}
                  />
                )}
              </UpdateContactConcessionsAction>
            )}
          </ActionDropDown>,
        ] : undefined}
        secondaryActions={[
          <Dropdown.Option
            label="Visualizza"
            aria-label="Visualizza dettagli contatto"
            onClick={goToContactPage}
          />,
          (isMobile && canEdit) && (
            <UpdateContactAction
              contact={contact}
              onSuccess={onInternalContactUpdated}
            >
              {({ onClick }) => (
                <Dropdown.Option
                  onClick={onClick}
                  label="Modifica contatto"
                />
              )}
            </UpdateContactAction>
          ),
          (isMobile && (canAddAdditionalServices || canDeleteAdditionalServices)) && (
            <UpdateContactAdditionalServicesAction
              contactId={contactId}
              canAdd={canAddAdditionalServices}
              canDelete={canDeleteAdditionalServices}
            >
              {({ isSaving, update }) => (
                <Dropdown.Option
                  onClick={update}
                  label="Modifica servizi"
                  loading={isSaving}
                />
              )}
            </UpdateContactAdditionalServicesAction>
          ),
          (isMobile && (canAddOptIns || canDeleteOptIns)) && (
            <UpdateContactOptInsAction
              contactId={contactId}
              canAdd={canAddOptIns}
              canDelete={canDeleteOptIns}
            >
              {({ isSaving, update }) => (
                <Dropdown.Option
                  onClick={update}
                  label="Modifica consensi"
                  loading={isSaving}
                />
              )}
            </UpdateContactOptInsAction>
          ),
          (isMobile && (canAddConcessions || canDeleteConcessions)) && (
            <UpdateContactConcessionsAction
              contactId={contactId}
              canAdd={canAddConcessions}
              canDelete={canDeleteConcessions}
            >
              {({ isSaving, update }) => (
                <Dropdown.Option
                  onClick={update}
                  label="Modifica agevolazioni"
                  loading={isSaving}
                />
              )}
            </UpdateContactConcessionsAction>
          ),
          ...extraActions.map((actionConfig) => (
            <Dropdown.Option
              key={slugIt(actionConfig.label)}
              label={actionConfig.label}
              onClick={actionConfig.onClick}
              href={actionConfig.href}
            />
          )),
        ]}
      />
      <Card.Content>
        <ContactInfo contact={contact} />
      </Card.Content>

      {contactConcessions.length > 0 && (
        <>
          <Card.Divider />
          <Card.Content>
            <HStack aria-label="Lista agevolazioni">
              {contactConcessions.map(({ id: concessionId, type }) => (
                <Badge
                  key={concessionId}
                  aria-label="concession-badge"
                  icon={ICON_CHECK}
                  label={concessionLabels[type]}
                  size="XS"
                  color="primary"
                />
              ))}
            </HStack>
          </Card.Content>
        </>
      )}
    </Card>
  );
};

export default ContactWidget;
