import { GeoFormModel } from '../models/geoModel';
import { DEFAULT_PROPERTY_GEO_FORM_VALUES } from '../../components/edit-property-info-form/geo/constants';
import { Geo } from '../../../providers/api/dtos';
import { isEmpty } from '../../../utils/object/object';

export function toGeoFormModel(geo?: Geo): GeoFormModel {
  if (typeof geo === 'undefined') {
    return DEFAULT_PROPERTY_GEO_FORM_VALUES;
  }

  return {
    route: geo.route || '',
    streetNumber: geo.streetNumber || '',
    locality: geo.locality || '',
    administrativeAreaLevelOne: geo.administrativeAreaLevelOne || '',
    administrativeAreaLevelTwo: geo.administrativeAreaLevelTwo || '',
    postalCode: geo.postalCode || '',
    latitude: geo.latitude?.toString() || '',
    longitude: geo.longitude?.toString() || '',
    suburb: geo.suburb || '',
    geoCodeResult: (geo.route || geo.streetNumber || geo.locality || geo.postalCode) ? {
      route: geo.route || '',
      streetNumber: geo.streetNumber || '',
      locality: geo.locality || '',
      postalCode: geo.postalCode || '',
    } : null,
  };
}

export function toGeo(geoFormModel?: GeoFormModel): Geo | undefined {
  const latitude = geoFormModel?.latitude ? Number.parseFloat(geoFormModel.latitude) : undefined;
  const longitude = geoFormModel?.longitude ? Number.parseFloat(geoFormModel.longitude) : undefined;

  const geo = {
    administrativeAreaLevelOne: geoFormModel?.administrativeAreaLevelOne || undefined,
    administrativeAreaLevelTwo: geoFormModel?.administrativeAreaLevelTwo || undefined,
    postalCode: geoFormModel?.postalCode || undefined,
    streetNumber: geoFormModel?.streetNumber || undefined,
    suburb: geoFormModel?.suburb || undefined,
    route: geoFormModel?.route || undefined,
    locality: geoFormModel?.locality || undefined,
    latitude: !Number.isNaN(latitude) ? latitude : undefined,
    longitude: !Number.isNaN(longitude) ? longitude : undefined,
  };

  return isEmpty(geo) ? undefined : geo;
}
