import {
  ActionIcon, ICON_BELL_PLUS_OUTLINE, Portal, useModal,
} from '@doveit/bricks';
import React, {
  FunctionComponent, ReactChild, useCallback, useMemo,
} from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { ReminderV3 } from '../../../providers/api/dtos/reminder';
import UpsertReminderV3, { PartialReminder } from '../upsert-reminder/UpsertReminderV3';

export interface UpsertReminderActionV3Props {
  reminder: PartialReminder,
  label?: string,
  disabled?: boolean,
  onSuccess?: (reminder: ReminderV3) => void,
  onActionClick?: VoidFunction,
  children?: ({ disabled, openUpsertReminderModal }: { disabled: boolean, openUpsertReminderModal?: VoidFunction }) => ReactChild | null,
}

const UpsertReminderActionV3: FunctionComponent<UpsertReminderActionV3Props> = ({
  reminder,
  label,
  disabled,
  onSuccess,
  onActionClick,
  children,
}) => {
  const upsertReminderModal = useModal<PartialReminder>();

  const openUpsertReminderModal = useCallback(() => {
    if (onActionClick) {
      onActionClick();
    }

    upsertReminderModal.open(reminder);
  }, [upsertReminderModal, reminder, onActionClick]);

  const onUpsertReminderSuccess = useCallback((savedReminder: ReminderV3) => {
    upsertReminderModal.close();

    if (onSuccess) {
      onSuccess(savedReminder);
    }
  }, [upsertReminderModal, onSuccess]);

  const computedLabel = useMemo(() => {
    if (label) {
      return label;
    }

    return reminder.id ? 'Modifica' : 'Aggiungi';
  }, [label, reminder.id]);

  return (
    <>
      {children
        ? children({
          disabled: !!disabled,
          openUpsertReminderModal: disabled
            ? undefined
            : openUpsertReminderModal,
        })
        : (
          <ActionIcon
            icon={{ path: ICON_BELL_PLUS_OUTLINE }}
            label={computedLabel}
            dataRef="upsert-reminder-action"
            onClick={openUpsertReminderModal}
            disabled={disabled}
          />
        )}

      {upsertReminderModal.data && (
        <Portal>
          <SimpleModal
            {...upsertReminderModal}
            title={`${upsertReminderModal.data.id ? 'Modifica' : 'Aggiungi'} promemoria`}
          >
            <UpsertReminderV3
              reminder={upsertReminderModal.data}
              onSuccess={onUpsertReminderSuccess}
            />
          </SimpleModal>
        </Portal>
      )}
    </>
  );
};

export default UpsertReminderActionV3;
