import { ShootingStatus, ShootingType } from '../../../domain/types';
import { Shooting } from '../../../providers/api/dtos';
import { UPSERT_SHOOTING_FORM_DEFAULT_VALUES, UpsertShootingFormModel } from '../../../shooting/components/upsert-shooting-form/UpsertShootingForm';
import { WithRequiredKeys } from '../../../types';
import { formatInputDate, formatInputTime, formDateTimeToDate } from '../../../utils/form';

export const shootingToUpsertShootingFormModel = (shooting?: Shooting): UpsertShootingFormModel => {
  if (!shooting) return UPSERT_SHOOTING_FORM_DEFAULT_VALUES;

  return {
    type: shooting.type ?? '',
    date: formatInputDate(new Date(shooting.date)),
    time: formatInputTime(new Date(shooting.date)),
    photoPrice: shooting.price?.photo?.toString() ?? '',
    expensesRefund: shooting.price?.refund?.toString() ?? '',
    extraServicesPrice: shooting.price?.extraServices?.toString() ?? '',
    matterportDeliveryDate: shooting.matterportDeliveryDate
      ? formatInputDate(new Date(shooting.matterportDeliveryDate))
      : '',
    photoDeliveryDate: shooting.photoDeliveryDate
      ? formatInputDate(new Date(shooting.photoDeliveryDate))
      : '',
    matterportRequest: shooting.matterportRequest,
    postProcessingRequest: shooting?.postProcessingRequest,
    notes: shooting.notes ?? '',
    photographerName: shooting?.photographerName ?? '',
  };
};

export const upsertShootingFormModelToShooting = (
  formValues: UpsertShootingFormModel,
  existingShooting: WithRequiredKeys<Partial<Shooting>, 'propertyId'>,
): Shooting => {
  let shootingStatus;
  switch (true) {
    // Initial status of an agent shooting
    case !existingShooting.status && formValues.type === ShootingType.AGENT:
      shootingStatus = ShootingStatus.DELIVERED;
      break;
    // Initial status of a direct/partner shooting
    case !existingShooting.status && [ShootingType.DIRECT, ShootingType.PARTNER].includes(formValues.type as ShootingType):
      shootingStatus = ShootingStatus.BOOKED;
      break;
    // Reset status in case of agent to direct/partner type change
    case existingShooting.type === ShootingType.AGENT && existingShooting.status === ShootingStatus.DELIVERED
      && (formValues.type as ShootingType) !== ShootingType.AGENT:
      shootingStatus = ShootingStatus.BOOKED;
      break;
    // Reset status in case of direct/partner to agent type change
    case existingShooting.type && [ShootingType.DIRECT, ShootingType.PARTNER].includes(existingShooting.type)
      && existingShooting.status === ShootingStatus.BOOKED && formValues.type === ShootingType.AGENT:
      shootingStatus = ShootingStatus.DELIVERED;
      break;
    // In all the other cases, we keep the existing status
    default:
      shootingStatus = existingShooting.status;
      break;
  }

  return {
    ...existingShooting,
    status: shootingStatus!,
    date: formDateTimeToDate(formValues.date, formValues.time).toISOString(),
    matterportRequest: formValues.matterportRequest,
    postProcessingRequest: formValues.postProcessingRequest,
    price: {
      photo: formValues.photoPrice.trim() ? parseFloat(formValues.photoPrice.trim()) : undefined,
      extraServices: formValues.extraServicesPrice.trim() ? parseFloat(formValues.extraServicesPrice.trim()) : undefined,
      refund: formValues.expensesRefund.trim() ? parseFloat(formValues.expensesRefund.trim()) : undefined,
    },
    type: formValues.type as ShootingType,
    matterportDeliveryDate: formValues.matterportDeliveryDate ? new Date(formValues.matterportDeliveryDate).toISOString() : undefined,
    photoDeliveryDate: formValues.photoDeliveryDate ? new Date(formValues.photoDeliveryDate).toISOString() : undefined,
    notes: formValues.notes.trim() || undefined,
    photographerName: formValues.photographerName.trim() || undefined,
  };
};
