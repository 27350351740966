import {
  Form, FormHandlers, FormProps, ICON_PERCENT, Spacing,
} from '@doveit/bricks';
import React, { ChangeEvent, MutableRefObject } from 'react';
import { CommissionSchema, CommissionSchemaType } from '../../../domain/types';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { agencyCommissionSchemaLabels, agentCommissionSchemaLabels } from '../../../labels/commissionSchemaLabels';
import { commissionSchemaDictionary } from '../../domain/commissionSchemaDictionary';
import editCommissionsValidationSchema from './EditCommissions.schema';
import { commissionsToEditCommissionsFormModel } from '../../containers/edit-agent-commissions-action/mappers';

export interface EditCommissionsFormModel {
  schema?: CommissionSchemaType,
  fixedSalary?: string,
  commissionSplitPersonal?: string,
  commissionSplitDove?: string,
  managementCost?: string,
  fee?: string,
  photoPricePersonal?: string,
  photoPriceDove?: string,
  overCommissionSplitPersonal?: string,
  overCommissionSplitDove?: string,
  overCommissionThreshold?: string,
  notes?: string,
}

export interface EditCommissionsFormProps {
  initialValues?: Partial<EditCommissionsFormModel>,
  loading?: FormProps<EditCommissionsFormModel>['loading'],
  innerRef?: MutableRefObject<FormHandlers>,
  onSubmit: FormProps<EditCommissionsFormModel>['onSubmit'],
  isAgency?: boolean,
}

export const DEFAULT_INITIAL_VALUES = {
  commissionSplitPersonal: '',
  commissionSplitDove: '',
  photoPricePersonal: '',
  photoPriceDove: '',
  overCommissionThreshold: '',
  overCommissionSplitPersonal: '',
  overCommissionSplitDove: '',
  fixedSalary: '',
  fee: '',
  managementCost: '',
};

const EditCommissionsForm: React.FC<EditCommissionsFormProps> = (props) => {
  const {
    initialValues = {}, innerRef, isAgency = false, onSubmit, ...rest
  } = props;

  const mergedInitialValues: EditCommissionsFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  const [isFieldDisabled, setIsFieldDisabled] = React.useState(mergedInitialValues.schema !== CommissionSchema.CUSTOM);

  return (
    <Form
      {...rest}
      innerRef={innerRef}
      onSubmit={onSubmit}
      initialValues={mergedInitialValues}
      validationSchema={editCommissionsValidationSchema}
      enableReinitialize
    >
      {({ values, setValues }) => {
        const onSchemaChange = async (e: ChangeEvent<HTMLSelectElement>) => {
          const { value: selectedSchema } = e.target;
          const { notes } = values;

          if (selectedSchema !== CommissionSchema.CUSTOM && selectedSchema !== '') {
            const selectedCommissions = commissionSchemaDictionary[selectedSchema as CommissionSchemaType]!;

            setValues(commissionsToEditCommissionsFormModel({
              ...selectedCommissions,
              notes,
            }, selectedSchema as CommissionSchemaType));
            setIsFieldDisabled(true);
          } else {
            setValues({
              ...DEFAULT_INITIAL_VALUES,
              schema: selectedSchema as CommissionSchemaType,
              notes,
            });
            setIsFieldDisabled(false);
          }
        };

        return (
          <>
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Select
                name="schema"
                aria-label="Campo per l'inserimento dello schema di commissioni"
                label="Schema"
                options={asSelectOptions(isAgency ? agencyCommissionSchemaLabels : agentCommissionSchemaLabels)}
                required
                onChange={onSchemaChange}
              />
            </Form.Item>
            <Spacing padding={[200, 0, 0, 0]}>
              <Form.Group>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Input
                    name="commissionSplitPersonal"
                    aria-label="Campo per l'inserimento delle commissioni su lead personal"
                    label="Commissioni personal"
                    icon={ICON_PERCENT}
                    placeholder="Es. 70"
                    required
                    type="number"
                    disabled={isFieldDisabled}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Input
                    name="commissionSplitDove"
                    aria-label="Campo per l'inserimento delle commissioni su lead Dove"
                    label="Commissioni Dove"
                    icon={ICON_PERCENT}
                    placeholder="Es. 30"
                    required
                    type="number"
                    disabled={isFieldDisabled}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.PriceField
                    name="photoPricePersonal"
                    aria-label="Campo per l'inserimento del costo delle foto su lead personal"
                    label="Costo foto personal"
                    placeholder="Es. 99"
                    required
                    disabled={isFieldDisabled}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.PriceField
                    name="photoPriceDove"
                    aria-label="Campo per l'inserimento del costo delle foto su lead Dove"
                    label="Costo foto Dove"
                    placeholder="Es. 99"
                    required
                    disabled={isFieldDisabled}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.PriceField
                    name="overCommissionThreshold"
                    aria-label="Campo per l'inserimento della soglia di commissioni personal"
                    label="Soglia"
                    placeholder="Es. 20.000"
                    required
                    disabled={isFieldDisabled}
                  />
                </Form.Item>
              </Form.Group>
            </Spacing>
            <Spacing padding={[200, 0, 0, 0]}>
              <Form.Group>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Input
                    name="overCommissionSplitPersonal"
                    aria-label="Campo per l'inserimento delle commissioni sopra soglia su lead personal"
                    label="Sopra soglia personal"
                    icon={ICON_PERCENT}
                    placeholder="Es. 70"
                    required
                    type="number"
                    disabled={isFieldDisabled}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Input
                    name="overCommissionSplitDove"
                    aria-label="Campo per l'inserimento delle commissioni sopra soglia su lead Dove"
                    label="Sopra soglia Dove"
                    icon={ICON_PERCENT}
                    placeholder="Es. 30"
                    required
                    type="number"
                    disabled={isFieldDisabled}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.PriceField
                    name="fixedSalary"
                    aria-label="Campo per l'inserimento del salario fisso"
                    label="Fisso"
                    placeholder="Es. 1.500"
                    required
                    disabled={isFieldDisabled}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.PriceField
                    name="fee"
                    aria-label="Campo per l'inserimento delle fee"
                    label="Fee"
                    placeholder="Es. 999"
                    required
                    disabled={isFieldDisabled}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Input
                    name="managementCost"
                    aria-label="Campo per l'inserimento dei costi di gestione"
                    label="Coordinamento"
                    icon={ICON_PERCENT}
                    placeholder="Es. 10"
                    required
                    type="number"
                    disabled={isFieldDisabled}
                  />
                </Form.Item>
              </Form.Group>
            </Spacing>
            <Form.Item>
              <Form.TextArea
                name="notes"
                aria-label="Campo per l'inserimento delle note"
                label="Note"
                rows={6}
              />
            </Form.Item>
          </>
        );
      }}
    </Form>
  );
};

export default EditCommissionsForm;
