import * as Yup from 'yup';
import { FLOOR_REGEX } from '../../../../constants';

export const upsertPropertyRoomValidationSchema = Yup.object().shape({
  type: Yup
    .string()
    .required('Il campo è obbligatorio'),
  floor: Yup
    .string()
    .matches(FLOOR_REGEX, 'Il campo deve essere un valore numerico intero oppure 0.5 / -0.5')
    .required('Il campo è obbligatorio'),
  size: Yup
    .number()
    .positive(),
});
