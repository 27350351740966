import hash from 'object-hash';
import useSWR, { SWRResponse } from 'swr';
import { MatchCount } from '../../../providers/api/dtos/matchCount';
import { getRumorMatchCount } from '../../../providers/api/rumor/rumorProvider';

export const RUMOR_MATCH_COUNT_RID = '/rumors/:id/match-count';

export function useRumorMatchCount(rumorId?: number): SWRResponse<MatchCount, Error> {
  return useSWR(rumorId ? [RUMOR_MATCH_COUNT_RID, hash(rumorId)] : null, () => getRumorMatchCount(rumorId!));
}
