import {
  ActionIcon,
  ConfirmModal, ICON_AUTORENEW, Message, Portal, Spacing, Text, useModal, useNotifications,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Status } from '../../../domain/types';
import { Property } from '../../../providers/api/dtos';
import { updateProperty } from '../../../providers/api/property/propertyProvider';

export const REPUBLISH_PROPERTY_SUCCESS_MESSAGE = 'L\'annuncio è stato ripubblicato correttamente.';
export const REPUBLISH_PROPERTY_ERROR_MESSAGE = 'Non è stato possibile ripubblicare l\'annuncio.';
export const REPUBLISH_PROPERTY_WARNING_MESSAGE = "L'azione di ripubblicazione genera una nuova versione dell'annuncio sui portali immobiliari. Cliccando il pulsante 'OK' l'annuncio attualmente pubblicato sarà sostituito da una nuova versione.";

interface ChildFnProps {
  loading: boolean,
  republish: VoidFunction,
}
export interface RepublishPropertyActionProps {
  property: Property,
  onSuccess?: (updatedProperty: Property) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const RepublishPropertyAction: FunctionComponent<RepublishPropertyActionProps> = ({
  property,
  onSuccess,
  children,
}) => {
  const confirmModal = useModal();
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const notToShow = React.useMemo(() => property.status !== Status.LIVE, [property.status]);

  const onConfirm = useCallback(async () => {
    setIsSaving(true);

    try {
      const updatedProperty = await updateProperty(property.id!, { ...property, version: property.version + 1 });

      setIsSaving(false);
      confirmModal.close();
      addSuccess(REPUBLISH_PROPERTY_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedProperty);
      }
    } catch (error) {
      setIsSaving(false);
      addError(REPUBLISH_PROPERTY_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, confirmModal, onSuccess, property]);

  if (notToShow) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          republish: confirmModal.open,
          loading: isSaving,
        }) : (
          <ActionIcon
            label="Ripubblica"
            aria-label="republish-action"
            icon={{ path: ICON_AUTORENEW }}
            onClick={confirmModal.open}
          />
        )}
      <Portal>
        <ConfirmModal
          isOpen={confirmModal.isOpen}
          aria-label="republish-confirm-modal"
          title="Ripubblica l'annuncio sui portali"
          onConfirm={onConfirm}
          onAbort={confirmModal.close}
          loading={isSaving}
        >
          <Text.Body>
            Confermi di voler ripubblicare l&apos;annuncio su tutti i portali?
          </Text.Body>
          <Spacing margin={[200, 0, 0]}>
            <Message
              type="warning"
              message={REPUBLISH_PROPERTY_WARNING_MESSAGE}
            />
          </Spacing>
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default RepublishPropertyAction;
