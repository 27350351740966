import { Aggregator, PropertyClass } from '../../../../domain/types';
import { Property } from '../../../../providers/api/dtos';
import { isNonResidential } from '../../../utils';

export function getDisabledAggregators(property?: Property): Aggregator[] {
  let disabledAggregators: Aggregator[] = [];

  if (property) {
    const {
      propertyType,
      classType,
      descriptionEn,
      price,
      privacy: {
        hidePrice,
        hideStreetNumber,
        hideRoute,
      },
    } = property;

    if (isNonResidential(propertyType)) {
      const additionalDisabledAggregators = Object.values(Aggregator).filter((aggregator) => aggregator !== Aggregator.IMMOBILIARE_IT);

      disabledAggregators = [...disabledAggregators, ...additionalDisabledAggregators];
    }

    if (hidePrice) {
      disabledAggregators = [...disabledAggregators, Aggregator.IDEALISTA];
    }

    if (classType !== PropertyClass.LUXURY || !descriptionEn || price! < 500_000 || hideStreetNumber || hideRoute) {
      disabledAggregators = [...disabledAggregators, Aggregator.JAMES_EDITION];
    }
  }

  return [...new Set(disabledAggregators)];
}
