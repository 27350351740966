/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as styles from './PDFViewer.style';

export interface PDFViewerProps extends React.AriaAttributes {
  src: string,
  title?: string,
  height?: string,
}

const PDFViewer: React.FC<PDFViewerProps> = ({
  src,
  title,
  height,
}) => (
  <div
    data-ref="pdf-wrapper"
    css={styles.wrapper}
    $height={height}
  >
    <iframe
      src={src}
      title={title}
      width="100%"
      height="100%"
    />
  </div>
);

export default PDFViewer;
