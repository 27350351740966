import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const agentFilterActionPopoverWrapper: ThemedFunctor = ({ theme }) => css`
  min-width: 20rem;
  padding: ${theme.remSize[100]};
  background-color: ${theme.color.background.neutral.default.lowest};
  border: 1px solid ${theme.color.border.neutral.default.high};
  border-radius: ${theme.remSize[75]};
  transition: opacity 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: ${theme.remSize[100]};
`;
