import { SelectOption } from '@doveit/bricks';
import { asSelectOptions } from '../../select-options/utils/asSelectOptions';

const expiredCrawledPropertiesLabels: Record<'true' | 'false', string> = {
  true: 'Scaduti',
  false: 'Live',
};

export const expiredOptions: SelectOption[] = asSelectOptions(expiredCrawledPropertiesLabels, {
  value: '',
  label: 'Tutti gli annunci',
});
