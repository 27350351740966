import { useCallback, useContext, useEffect } from 'react';
import { GoogleMapsContext } from '../../contexts/google-maps-context/GoogleMapsContext';
import {
  getAutoCompleteSessionToken,
  getAddressSuggestions as getAddressSuggestionsProvider,
  getPlaceDetails as getPlaceDetailsProvider,
} from '../../providers/geo/places/places';

export default function useGoogleMapsAPI() {
  const { autoCompleteSessionToken, setAutoCompleteSessionToken } = useContext(GoogleMapsContext);

  useEffect(() => {
    if (!autoCompleteSessionToken) {
      setAutoCompleteSessionToken(getAutoCompleteSessionToken());
    }
  }, [autoCompleteSessionToken, setAutoCompleteSessionToken]);

  const getAddressSuggestions = useCallback(
    async (query: string) => getAddressSuggestionsProvider(query, { sessionToken: autoCompleteSessionToken! }),
    [autoCompleteSessionToken],
  );

  const getPlaceDetails = useCallback(async (placeId: string) => {
    const place = await getPlaceDetailsProvider(placeId, { sessionToken: autoCompleteSessionToken! });
    setAutoCompleteSessionToken(getAutoCompleteSessionToken());

    return place;
  }, [autoCompleteSessionToken, setAutoCompleteSessionToken]);

  return {
    sessionToken: autoCompleteSessionToken,
    getAddressSuggestions,
    getPlaceDetails,
  };
}
