import React from 'react';
import {
  Action, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { updateAgent } from '../../../providers/api/agent/agentProvider';
import { boolToBooleanString, booleanStringToBool } from '../../../utils/boolean-string/booleanString';
import { JobTitle } from '../../../domain/types';
import EditAgentDetailsForm, { EditAgentDetailsFormModel } from '../../components/edit-agent-details-form/EditAgentDetailsForm';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface EditAgentDetailsActionProps {
  agent: Agent,
  onSuccess?: (updatedAgent: Agent) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_AGENT_DETAILS_SUCCESS_MESSAGE = "Le informazioni dell'agente sono state modificate con successo";
export const EDIT_AGENT_DETAILS_ERROR_MESSAGE = "Non è stato possibile modificare le informazioni dell'agente";

const EditAgentDetailsAction: React.FC<EditAgentDetailsActionProps> = (props) => {
  const { agent, onSuccess, children } = props;

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const openEditModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const formInitialValues: EditAgentDetailsFormModel = React.useMemo(() => ({
    jobTitle: agent.jobTitle,
    hasLicense: boolToBooleanString(agent.hasLicense),
    needsSupervisor: boolToBooleanString(agent.needsSupervisor),
    inTrial: agent.inTrial,
  }), [agent.hasLicense, agent.jobTitle, agent.needsSupervisor, agent.inTrial]);

  const onSubmit = React.useCallback(async (formValues: EditAgentDetailsFormModel) => {
    try {
      setIsSaving(true);

      const {
        hasLicense, jobTitle, needsSupervisor, inTrial,
      } = formValues;

      const agentToUpdate: Agent = {
        ...agent,
        hasLicense: booleanStringToBool(hasLicense),
        needsSupervisor: booleanStringToBool(needsSupervisor),
        jobTitle: jobTitle as JobTitle,
        inTrial,
      };

      const updatedAgent = await updateAgent(agent.id!, agentToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_AGENT_DETAILS_SUCCESS_MESSAGE);
      modal.close();

      if (onSuccess) {
        onSuccess(updatedAgent);
      }
    } catch (error) {
      setIsSaving(false);
      addError(EDIT_AGENT_DETAILS_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agent, modal, onSuccess]);

  return (
    <>
      {children
        ? children({ openEditModal, isSaving })
        : (
          <Action
            aria-label="Modifica le informazioni dell'agente"
            size="S"
            label="Modifica"
            onClick={openEditModal}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Modifica dettagli"
        >
          <EditAgentDetailsForm
            initialValues={formInitialValues}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAgentDetailsAction;
