import {
  SpacingName, ThemedFunctor, getSpacing,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const basic: ThemedFunctor = () => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const chatWrapper: ThemedFunctor = ({ theme }) => css`
  position: relative;
  background-color: ${theme.palettes.neutrals.lightest};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid ${theme.palettes.neutrals.light};
`;

export const messagesWrapper: ThemedFunctor = () => css`
  position: relative;
  flex-grow: 1;
`;

export const newMessages: ThemedFunctor = ({ theme }) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  flex-grow: 1;
  background: linear-gradient(to top, ${theme.palettes.neutrals.lightest} 0, transparent 100%);
  ${getSpacing.padding(theme, [SpacingName.SMALLER, SpacingName.SMALL])}
  display: flex;
  justify-content: center;
`;

export const messagesLoading: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, [SpacingName.SMALLER, SpacingName.SMALL])}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: linear-gradient(to bottom, ${theme.palettes.neutrals.lightest} 0, transparent 100%);
  z-index: ${theme.zIndex.tooltip};
  text-align: center;
`;

export const messageItem: ThemedFunctor = ({ theme, $inbound }) => css`
  ${getSpacing.paddingTop(theme, SpacingName.SMALLER)}
  ${getSpacing.paddingBottom(theme, SpacingName.SMALLER)}
  ${getSpacing.paddingLeft(theme, $inbound ? SpacingName.SMALL : SpacingName.MEDIUM)}
  ${getSpacing.paddingRight(theme, $inbound ? SpacingName.MEDIUM : SpacingName.SMALL)}
  display: flex;

  ${!$inbound && `
    justify-content: flex-end;
  `}
`;

export const messageWrapper: ThemedFunctor = () => css`
  max-width: 31rem;
  word-wrap: break-word;

  > * {
    flex-shrink: 0;
  }
`;

export const sendMessage: ThemedFunctor = ({ theme }) => css`
  position: relative;
  z-index: 1;
  ${getSpacing.padding(theme, [SpacingName.SMALLER, SpacingName.SMALL, SpacingName.SMALLEST])}
  text-align: right;
  flex-shrink: 0;
  box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.05);
`;

export const messagesDivider: ThemedFunctor = ({ theme }) => css`
  display: flex;
  align-items: center;
  color: ${theme.palettes.neutrals.medium};
`;

export const messagesDividerHalf: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.margin(theme, [0, SpacingName.SMALL])};
  background-color: ${theme.palettes.neutrals.light};
  height: 1px;
  border: 0;
  flex-grow: 1;
`;
