import React from 'react';
import { AxiosError } from 'axios';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import { ContentType, ShootingStatus, ShootingType } from '../../../domain/types';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Content, Shooting } from '../../../providers/api/dtos';
import useContentsByPropertyId from '../../../property/hooks/use-contents-by-property-id/useContentsByPropertyId';
import { removeNullAndUndefinedValues } from '../../../utils/array';

export type ComputedShootingStatus = ShootingStatus | 'NO_STATUS';

export type UseComputedShootingStatusData = {
  status: ComputedShootingStatus,
  canBeUpdated: boolean,
  canBeUpdatedTo: (status: ShootingStatus) => boolean,
};

export type UseComputedShootingStatusResult = {
  data?: UseComputedShootingStatusData | undefined,
  isLoading: boolean,
  errors?: Error[] | undefined,
  mutate: () => Promise<void>,
};

type Meta = {
  status: ComputedShootingStatus,
  canBeUpdatedTo: ComputedShootingStatus[],
};

/**
 * User role: ADMIN/CONTENT
 * Shooting type: DIRECT/PARTNER
 */
const adminContentTypeDirectPartnerMeta = (
  shooting: Shooting,
  propertyPhotos: Content[] = [],
): Meta => {
  const meta: Record<ShootingStatus, Meta> = {
    [ShootingStatus.BOOKED]: {
      status: ShootingStatus.BOOKED,
      canBeUpdatedTo: [ShootingStatus.REVIEWED, ShootingStatus.CANCELLED, ShootingStatus.NOT_EXECUTED],
    },
    [ShootingStatus.REVIEWED]: {
      status: ShootingStatus.REVIEWED,
      canBeUpdatedTo: [ShootingStatus.DELIVERED, ShootingStatus.CANCELLED, ShootingStatus.NOT_EXECUTED],
    },
    [ShootingStatus.DELIVERED]: {
      status: ShootingStatus.DELIVERED,
      canBeUpdatedTo: removeNullAndUndefinedValues([
        propertyPhotos.length > 0 ? ShootingStatus.COMPLETED : undefined,
      ]),
    },
    [ShootingStatus.COMPLETED]: {
      status: ShootingStatus.COMPLETED,
      canBeUpdatedTo: [],
    },
    [ShootingStatus.CANCELLED]: {
      status: ShootingStatus.CANCELLED,
      canBeUpdatedTo: [],
    },
    [ShootingStatus.NOT_EXECUTED]: {
      status: ShootingStatus.NOT_EXECUTED,
      canBeUpdatedTo: [],
    },
  };

  return meta[shooting.status];
};

/**
 * User role: AGENT
 * Shooting type: DIRECT/PARTNER
 */
const agentTypeDirectPartnerMeta: Record<ShootingStatus, Meta> = {
  [ShootingStatus.BOOKED]: {
    status: ShootingStatus.BOOKED,
    canBeUpdatedTo: [ShootingStatus.CANCELLED, ShootingStatus.NOT_EXECUTED],
  },
  [ShootingStatus.REVIEWED]: {
    status: ShootingStatus.REVIEWED,
    canBeUpdatedTo: [ShootingStatus.CANCELLED, ShootingStatus.NOT_EXECUTED],
  },
  [ShootingStatus.DELIVERED]: {
    status: ShootingStatus.DELIVERED,
    canBeUpdatedTo: [],
  },
  [ShootingStatus.COMPLETED]: {
    status: ShootingStatus.COMPLETED,
    canBeUpdatedTo: [],
  },
  [ShootingStatus.CANCELLED]: {
    status: ShootingStatus.CANCELLED,
    canBeUpdatedTo: [],
  },
  [ShootingStatus.NOT_EXECUTED]: {
    status: ShootingStatus.NOT_EXECUTED,
    canBeUpdatedTo: [],
  },
};

/**
 * User role: ADMIN/CONTENT/AGENT
 * Shooting type: AGENT
 */
const adminContentAgentTypeAgentMeta = (
  shooting: Shooting,
  propertyPhotos: Content[] = [],
): Meta => {
  const meta: Record<ShootingStatus, Meta> = {
    [ShootingStatus.BOOKED]: {
      status: 'NO_STATUS',
      canBeUpdatedTo: [],
    },
    [ShootingStatus.REVIEWED]: {
      status: 'NO_STATUS',
      canBeUpdatedTo: [],
    },
    [ShootingStatus.DELIVERED]: {
      status: ShootingStatus.DELIVERED,
      canBeUpdatedTo: removeNullAndUndefinedValues([
        propertyPhotos.length > 0 ? ShootingStatus.COMPLETED : undefined,
      ]),
    },
    [ShootingStatus.COMPLETED]: {
      status: ShootingStatus.COMPLETED,
      canBeUpdatedTo: [],
    },
    [ShootingStatus.CANCELLED]: {
      status: 'NO_STATUS',
      canBeUpdatedTo: [],
    },
    [ShootingStatus.NOT_EXECUTED]: {
      status: 'NO_STATUS',
      canBeUpdatedTo: [],
    },
  };

  return meta[shooting.status];
};

export const useComputedShootingStatus = (shooting: Shooting): UseComputedShootingStatusResult => {
  const [errors, setErrors] = React.useState<Error[] | undefined>();

  const handleNewError = React.useCallback((err: AxiosError) => {
    setErrors((prev) => {
      if (!prev) return [err];
      return [...prev, err];
    });
  }, []);

  const { userIsAgent, userIsAdmin, userIsContentEditor } = useRBAC();

  const {
    data: propertyAgent,
    isLoading: isPropertyAgentLoading,
    mutate: mutatePropertyAgent,
  } = useAgentByPropertyId(
    userIsAgent ? shooting.propertyId : undefined,
    { onError: handleNewError },
  );

  const {
    data: propertyPhotos,
    isLoading: arePropertyPhotosLoading,
    mutate: mutatePropertyPhotos,
  } = useContentsByPropertyId(
    shooting.propertyId,
    ContentType.FOTO,
    { onError: handleNewError },
  );

  const userIsPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(propertyAgent?.id);

  const mutate = React.useCallback(async () => {
    await mutatePropertyAgent();
    await mutatePropertyPhotos();
  }, [mutatePropertyAgent, mutatePropertyPhotos]);

  const isLoading = React.useMemo(
    () => isPropertyAgentLoading || arePropertyPhotosLoading,
    [isPropertyAgentLoading, arePropertyPhotosLoading],
  );

  const meta: Meta = React.useMemo(() => {
    if ((userIsAdmin || userIsContentEditor) && [ShootingType.DIRECT, ShootingType.PARTNER].includes(shooting.type)) {
      return adminContentTypeDirectPartnerMeta(shooting, propertyPhotos);
    }

    if (userIsPropertyAgentOrManager && [ShootingType.DIRECT, ShootingType.PARTNER].includes(shooting.type)) {
      return agentTypeDirectPartnerMeta[shooting.status];
    }

    if (userIsAgent && [ShootingType.DIRECT, ShootingType.PARTNER].includes(shooting.type)) {
      return {
        ...agentTypeDirectPartnerMeta[shooting.status],
        canBeUpdatedTo: [],
      };
    }

    if ((userIsAdmin || userIsContentEditor || userIsPropertyAgentOrManager) && shooting.type === ShootingType.AGENT) {
      return adminContentAgentTypeAgentMeta(shooting, propertyPhotos);
    }

    return {
      status: 'NO_STATUS',
      canBeUpdatedTo: [],
    };
  }, [propertyPhotos, shooting, userIsAdmin, userIsAgent, userIsContentEditor, userIsPropertyAgentOrManager]);

  const data: UseComputedShootingStatusData | undefined = React.useMemo(() => {
    if (errors || isLoading) return undefined;

    return {
      status: meta.status,
      canBeUpdated: meta.canBeUpdatedTo.length > 0,
      canBeUpdatedTo: (status: ComputedShootingStatus) => meta.canBeUpdatedTo.includes(status),
    };
  }, [errors, isLoading, meta.canBeUpdatedTo, meta.status]);

  return React.useMemo(() => ({
    data,
    isLoading,
    errors,
    mutate,
  }), [data, errors, isLoading, mutate]);
};
