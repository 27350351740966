import {
  Form, FormProps, FormSubmitType,
} from '@doveit/bricks';
import React from 'react';
import { formatInputDate } from '../../../../utils/form';

export interface OfferWizardNotesFormModel {
  signaturePlace: string,
  validUntil: string,
  notes: string,
}

export interface OfferWizardNotesFormProps {
  initialValues?: Partial<OfferWizardNotesFormModel>,
  formRef: FormProps<OfferWizardNotesFormModel>['innerRef'],
  onSubmit: FormSubmitType<OfferWizardNotesFormModel>,
}

export const DEFAULT_INITIAL_VALUES: OfferWizardNotesFormModel = {
  signaturePlace: '',
  validUntil: '',
  notes: '',
};

const TODAY = formatInputDate(new Date());

const OfferWizardNotesForm: React.FC<OfferWizardNotesFormProps> = ({
  initialValues = {},
  formRef,
  ...rest
}) => {
  const mergedInitialValues: OfferWizardNotesFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      innerRef={formRef}
      {...rest}
    >
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="signaturePlace"
          label="Luogo proposta"
          aria-label="Campo per inserire il luogo della proposta"
          placeholder="Es: Milano"
          required
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="validUntil"
          label="Valida fino al"
          min={TODAY}
          type="date"
          aria-label="Campo per inserire la data fino a cui la proposta è valida"
          required
        />
      </Form.Item>

      <Form.Item>
        <Form.TextArea
          name="notes"
          label="Note"
          aria-label="Campo per inserire eventuali note"
        />
      </Form.Item>
    </Form>
  );
};

export default OfferWizardNotesForm;
