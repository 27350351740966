import {
  Action, FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import React, { MutableRefObject } from 'react';
import { Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { updateAgent } from '../../../providers/api/agent/agentProvider';
import EditMembershipContractForm, { EditMembershipContractFormModel } from '../../components/edit-membership-contract-from/EditMembershipContractForm';
import { editMembershipContractFormModelToAgent, agentToEditMembershipContractFormModel } from './mappers';
import { calculateMembershipStatus } from '../../utils/calculateMembershipStatus';
import { MembershipStatus } from '../../../domain/types';

export interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface EditAgentContractActionProps {
  agent: Agent,
  onSuccess?: (updatedAgent: Agent) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_AGENT_CONTRACT_SUCCESS_MESSAGE = "La contrattualistica dell'agente è stata modificata con successo";
export const EDIT_AGENT_CONTRACT_ERROR_MESSAGE = "Non è stato possibile modificare la contrattualistica dell'agente";

const EditAgentContractAction: React.FC<EditAgentContractActionProps> = (props) => {
  const { agent, onSuccess, children } = props;
  const { contract: { startDate, endDate } } = agent;

  const { userIsHR } = useRBAC();

  const editAgentContractModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const formRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;

  const [isSaving, setIsSaving] = React.useState(false);

  const openEditAgentContractModal = React.useCallback(() => editAgentContractModal.open(), [editAgentContractModal]);

  const agentStatus = React.useMemo(() => calculateMembershipStatus(new Date(startDate), endDate ? new Date(endDate) : undefined), [endDate, startDate]);

  const disabledFields = React.useMemo(() => (agentStatus === MembershipStatus.REVOKED && userIsHR ? ['companyName', 'role', 'vatNumber'] as Array<keyof EditMembershipContractFormModel> : []), [agentStatus, userIsHR]);

  const submitForm = React.useCallback(() => formRef.current?.handleSubmit(), []);

  const onSubmit = React.useCallback(async (formValues: EditMembershipContractFormModel) => {
    try {
      setIsSaving(true);

      const agentToUpdate = editMembershipContractFormModelToAgent(formValues, agent);

      const updatedAgent = await updateAgent(agent.id!, agentToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_AGENT_CONTRACT_SUCCESS_MESSAGE);

      editAgentContractModal.close();

      onSuccess?.(updatedAgent);
    } catch (err) {
      setIsSaving(false);
      addError(EDIT_AGENT_CONTRACT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agent, editAgentContractModal, onSuccess]);

  return (
    <>
      {
        children
          ? children({ openEditModal: openEditAgentContractModal, isSaving })
          : (
            <Action
              title="Modifica la contrattualistica dell'agente"
              aria-label="Pulsante per modificare la contrattualistica dell'agente"
              size="S"
              label="Modifica"
              onClick={openEditAgentContractModal}
              loading={isSaving}
            />
          )
      }
      <Portal>
        <SimpleModal
          {...editAgentContractModal}
          title="Modifica contrattualistica"
          aria-label="Modale per modificare la contrattualistica dell'agente"
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Modifica i dati"
              onClick={submitForm}
            />
          )}
        >
          <EditMembershipContractForm
            onSubmit={onSubmit}
            initialValues={agentToEditMembershipContractFormModel(agent)}
            disabledFields={disabledFields}
            innerRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAgentContractAction;
