import React from 'react';
import { ActionIcon, ICON_HANDSHAKE_OUTLINE } from '@doveit/bricks';
import { Prospect } from '../../../../providers/api/dtos';
import { ProspectStatus } from '../../../../domain/types';
import { needsSupervisorApproval } from '../../../../prospect/utils/needsSupervisorApproval';
import { useAgent } from '../../../../hooks/use-agent/useAgent';
import useRBAC from '../../../../hooks/use-rbac/useRBAC';
import useExtendedNavigate from '../../../../hooks/use-extended-navigate/useExtendedNavigate';
import useAssignmentWizardData from '../../../../prospect/hooks/use-assignment-wizard-data/useAssignmentWizardData';
import { raise } from '../../../../utils';

interface ChildFnProps {
  disabled?: boolean,
  onClick: VoidFunction,
}

export interface CreateAssignmentActionProps {
  prospect: Prospect,
  children?: ({ onClick, disabled }: ChildFnProps) => React.ReactElement,
}

export const CREATE_PROSPECT_TO_PROPERTY_SUCCESS_MESSAGE = 'Mandato creato con successo';
export const CREATE_PROSPECT_TO_PROPERTY_ERROR_MESSAGE = 'Errore durante la creazione del mandato';
export const LOAD_EXTRA_PACKAGES_WARNING_MESSAGE = 'Sarà possibile selezionare i pacchetti extra in un secondo momento';

const GoToAssignmentCreationPageAction: React.FC<CreateAssignmentActionProps> = ({
  prospect,
  children,
}) => {
  const prospectId = prospect.id ?? raise('missing prospect id');

  const { agentId } = prospect;
  const navigate = useExtendedNavigate();
  const { data: agent, error: agentError } = useAgent(agentId);
  const { data: assignmentWizardData } = useAssignmentWizardData(prospectId);
  const { userIsAdmin } = useRBAC();

  const isDisabled = React.useMemo(() => {
    const agentIsMissing = !agent || !!agentError;
    const needSupervisor = !!agent && needsSupervisorApproval(prospect, agent);

    return (!userIsAdmin && (agentIsMissing || needSupervisor)) || assignmentWizardData?.isDigitallySigned;
  }, [agent, agentError, assignmentWizardData?.isDigitallySigned, prospect, userIsAdmin]);

  const goToAssignmentCreationPage = React.useCallback(() => {
    navigate(`/prospects/${prospectId}/assignment/create`, {
      newTab: true,
    });
  }, [navigate, prospectId]);

  if (prospect.status !== ProspectStatus.IN_PROGRESS) {
    return null;
  }

  return (
    children
      ? children({
        onClick: goToAssignmentCreationPage,
        disabled: isDisabled,
      }) : (
        <ActionIcon
          label="Mandato"
          icon={{ path: ICON_HANDSHAKE_OUTLINE }}
          disabled={isDisabled}
          onClick={goToAssignmentCreationPage}
        />
      )
  );
};

export default GoToAssignmentCreationPageAction;
