import {
  Action, Message, Portal, Spacing, useModal,
} from '@doveit/bricks';
import React from 'react';
import { Contact } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import UpsertContact from '../upsert-contact/UpsertContact';

type ChildFnProps = {
  onClick: VoidFunction,
};

export const UPDATE_CONTACT_WARNING_MESSAGE = 'Stai modificando il contatto {{contactName}}. Le modifiche saranno visibili in ogni interesse, notizia, valutazione o immobile associato al contatto.';

export interface UpdateContactActionProps {
  contact: Contact,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (contact: Contact) => void,
}
const UpdateContactAction: React.FC<UpdateContactActionProps> = ({
  contact,
  onSuccess,
  children,
}) => {
  const editContactModal = useModal();

  const onSuccessContactEdit = React.useCallback((updatedContact: Contact) => {
    editContactModal.close();
    onSuccess?.(updatedContact);
  }, [editContactModal, onSuccess]);

  const onClick = React.useCallback(
    () => editContactModal.open(),
    [editContactModal],
  );

  return (
    <>
      {children
        ? children({ onClick })
        : (
          <Action
            label="Modifica contatto"
            aria-label="Pulsante per modificare i dati del contatto"
            size="S"
            onClick={onClick}
          />
        )}
      <Portal>
        <SimpleModal
          {...editContactModal}
          aria-label="Modale per modificare i dati del contatto"
          title="Modifica contatto"
        >
          <Spacing margin={[0, 0, 200]}>
            <Message
              type="warning"
              message={UPDATE_CONTACT_WARNING_MESSAGE.replace('{{contactName}}', ` ${contact.name}` || '')}
            />
          </Spacing>
          <UpsertContact
            contact={contact}
            onSuccess={onSuccessContactEdit}
            submitLabel="Modifica"
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpdateContactAction;
