import * as Yup from 'yup';
import type { NoteFormModel, NoteFormProps } from './NoteForm';

type NoteFormValidationSchemaOptions = Pick<NoteFormProps, 'allowBlankText'>;

const noteFormValidationSchema = (options: NoteFormValidationSchemaOptions = {}): Yup.Schema<Pick<NoteFormModel, 'text'>> => {
  const { allowBlankText = false } = options;

  return Yup.object({
    text: Yup
      .string()
      .when([], {
        is: () => allowBlankText,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Inserisci il testo della nota'),
      }),
  });
};

export default noteFormValidationSchema;
