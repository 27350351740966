import {
  Badge, Card, CardSkeleton, Dropdown, ExpandableContent, HStack, ICON_ALERT_CIRCLE_OUTLINE, ICON_CARD_BULLETED_OUTLINE, ICON_CHECK, Message, Spacing, DetailItemList,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import React from 'react';
import { Status } from '../../../domain/types';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import {
  energyClassLabels, furnitureTypeLabels, ownershipTypeLabels, propertyClassTypeLabels, propertyStatusLabels, propertyTypeLabels,
  propertyWindowExposureLabels,
  tvLabels,
} from '../../../labels';
import { Property } from '../../../providers/api/dtos';
import useProperty from '../../hooks/use-property/useProperty';
import useRooms from '../../hooks/use-rooms/useRooms';
import { featureTypeLabels } from '../../labels';
import {
  buildAirConditioningLabels, buildHeatingLabels, buildKitchenLabels,
} from '../../utils';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import EditPropertyInfoAction from '../edit-property-info-action/EditPropertyInfoAction';

export const LOAD_PROPERTY_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni dell\'immobile.';

export interface PropertyInfoWidgetProps extends React.AriaAttributes {
  propertyId: NonNullable<Property['id']>,
  onPropertyInfoUpdate?: (updatedProperty: Property) => void,
}

const PropertyInfoWidget: React.FC<PropertyInfoWidgetProps> = ({
  propertyId,
  onPropertyInfoUpdate,
  ...rest
}) => {
  const isMobile = useIsDevice('mobile');
  const { userIsAdmin, userIsContentEditor } = useRBAC();
  const { data: propertyAgent } = useAgentByPropertyId(propertyId);
  const { data: rooms } = useRooms(propertyId);
  const {
    data: property,
    isLoading: isPropertyLoading,
    error: propertyError,
    mutate: mutateProperty,
  } = useProperty(propertyId);
  const userIsPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(propertyAgent?.id);

  const onUpdate = React.useCallback((updatedProperty: Property) => {
    mutateProperty();

    onPropertyInfoUpdate?.(updatedProperty);
  }, [mutateProperty, onPropertyInfoUpdate]);

  const canUpdateInfo = React.useMemo(() => {
    if (!property) return false;

    if ([Status.DRAFT, Status.ANTEPRIMA].includes(property.status)) {
      return userIsAdmin || userIsContentEditor || userIsPropertyAgentOrManager;
    }

    return userIsAdmin || userIsContentEditor;
  }, [property, userIsAdmin, userIsContentEditor, userIsPropertyAgentOrManager]);

  if (isPropertyLoading) {
    return <CardSkeleton aria-label="Dati immobile in caricamento" />;
  }

  if (!property || propertyError) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Dati immobile"
          color="critical"
        />
        <Card.Content>
          <Message message={LOAD_PROPERTY_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card
      aria-label="Sezione dati immobile"
      {...rest}
    >
      <Card.Header
        title="Dati immobile"
        icon={{ path: ICON_CARD_BULLETED_OUTLINE }}
        primaryActions={[
          (!isMobile && canUpdateInfo) && (
            <EditPropertyInfoAction
              property={property}
              onSuccess={onUpdate}
            />
          ),
        ]}
        secondaryActions={[
          (isMobile && canUpdateInfo) && (
            <EditPropertyInfoAction
              property={property}
              onSuccess={onUpdate}
            >
              {({ edit, isSaving }) => (
                <Dropdown.Option
                  aria-label="Modifica l'immobile"
                  label="Modifica"
                  onClick={edit}
                  loading={isSaving}
                />
              )}
            </EditPropertyInfoAction>
          ),
        ]}
      />
      <Card.Content aria-label="Dati immobile">
        <DetailItemList columns={2}>
          <DetailItemList.Item label="Indirizzo">
            {property.geo?.normalizedAddress}
          </DetailItemList.Item>

          {property.overriddenGeo?.normalizedAddress && (
            <DetailItemList.Item
              label="Indirizzo portali"
              aria-label="Indirizzo portali"
            >
              {property.overriddenGeo.normalizedAddress}
            </DetailItemList.Item>
          )}

          {(property.stairwell || property.unit) && (
            <DetailItemList.Item
              label={property.stairwell ? 'Scala' : 'Interno'}
              aria-label={property.stairwell ? 'Scala' : 'Interno'}
              ellipsis
            >
              {[
                property.stairwell,
                property.unit && (property.stairwell ? `(interno ${property.unit})` : property.unit),
              ]
                .filter(Boolean)
                .join(' ')
                .trim()}
            </DetailItemList.Item>
          )}

          <DetailItemList.Item label="Tipologia" ellipsis>
            {(property.propertyType || property.ownershipType) && `${propertyTypeLabels[property.propertyType]} ${property.ownershipType && `(${ownershipTypeLabels[property.ownershipType].split(' ').at(0)})`}`}
          </DetailItemList.Item>

          <DetailItemList.Item label="Superficie" ellipsis>
            {property.propertySize && formatSquareMeters(property.propertySize)}
          </DetailItemList.Item>

          <DetailItemList.Item label="Stato immobile" ellipsis>
            {property.propertyStatus && propertyStatusLabels[property.propertyStatus]}
          </DetailItemList.Item>
        </DetailItemList>
        <Spacing margin={[50, 0, 0]}>

          <ExpandableContent>
            <DetailItemList columns={2}>
              <DetailItemList.Item label="Classe dell'immobile" ellipsis>
                {property.classType && propertyClassTypeLabels[property.classType]}
              </DetailItemList.Item>

              <DetailItemList.Item label="Stato della proprietà" ellipsis>
                {property.ownershipType && ownershipTypeLabels[property.ownershipType]}
              </DetailItemList.Item>

              <DetailItemList.Item label="Anno di costruzione" ellipsis>
                {property.yearOfDevelopment}
              </DetailItemList.Item>

              <DetailItemList.Item label="Classe energetica" ellipsis>
                {property.energyClass && energyClassLabels[property.energyClass]}
              </DetailItemList.Item>

              <DetailItemList.Item label="Riscaldamento">
                {property.heating && buildHeatingLabels(property.heating)}
              </DetailItemList.Item>

              <DetailItemList.Item label="Arredamento">
                {property.furniture && furnitureTypeLabels[property.furniture]}
              </DetailItemList.Item>

              <DetailItemList.Item label="Impianto TV">
                {property.tv && tvLabels[property.tv]}
              </DetailItemList.Item>

              <DetailItemList.Item label="Cucina">
                {rooms && buildKitchenLabels(rooms)}
              </DetailItemList.Item>

              <DetailItemList.Item label="Climatizzazione">
                {property.airConditioning && buildAirConditioningLabels(property.airConditioning)}
              </DetailItemList.Item>

              <DetailItemList.Item label="Esposizione">
                {property.windowExposure && propertyWindowExposureLabels[property.windowExposure]}
              </DetailItemList.Item>

              <DetailItemList.BooleanItem
                label="Giardino privato"
                value={(property.privateGarden ?? 0) > 0}
              />
            </DetailItemList>
          </ExpandableContent>
        </Spacing>
      </Card.Content>
      {property.features.length > 0 && (
        <>
          <Card.Divider />

          <Card.Content>
            <HStack>
              {property.features.map(({ id, type }) => (
                <Badge
                  key={id}
                  icon={ICON_CHECK}
                  label={featureTypeLabels[type]}
                  size="XS"
                />
              ))}
            </HStack>
          </Card.Content>
        </>
      )}
    </Card>
  );
};

export default PropertyInfoWidget;
