import { Concession } from '../dtos';
import { getClient, API_CONCESSIONS_PATH } from '../api';

export async function createConcession(concession: Concession): Promise<Concession> {
  const client = await getClient();
  const { data } = await client.post<Concession>(API_CONCESSIONS_PATH, concession);
  return data;
}

export async function deleteConcession(concessionId: number): Promise<{}> {
  const client = await getClient();
  const { data } = await client.delete<{}>(`${API_CONCESSIONS_PATH}/${concessionId}`);
  return data;
}
