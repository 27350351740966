import useSWR from 'swr';
import hash from 'object-hash';
import { getContactRumors } from '../../../providers/api/contact/contactProvider';
import { Rumor } from '../../../providers/api/dtos/rumor';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';
import { RumorStatus } from '../../../domain/types';

export const CONTACT_RUMORS_RID = '/contacts/:id/rumors';

export default function useContactRumors(
  contactId?: string,
  filters: { excludeStatus?: RumorStatus | RumorStatus[] } = {},
): SWRCustomResponse<Rumor[], Error> {
  const matchMutate = useMatchMutate();
  const rid = [CONTACT_RUMORS_RID, contactId].join();
  const key = contactId ? [rid, hash(filters)] : null;
  const result = useSWR(key, () => getContactRumors(contactId!, filters));
  return {
    ...result,
    mutate: () => matchMutate(rid),
  };
}
