/* eslint-disable react/no-unknown-property */import React from 'react';
import {
  ItemContent, ListRange, Virtuoso, VirtuosoHandle,
} from 'react-virtuoso';
import { MessagePayload } from 'firebase/messaging';
import { getDate } from 'date-fns';
import {
  Action,
  ActionIcon,
  Dropdown,
  ExpandableContent,
  ICON_ACCOUNT_CIRCLE_OUTLINE,
  ICON_ARROW_UP,
  Message,
  Spinner,
  SpinnerSize,
  Icon,
  Typography,
  useNotifications,
} from '@doveit/bricks';
import { formatDate, pluralizeWord } from '@doveit/hammer';
import { Conversation, ConversationMessage } from '../../../providers/api/dtos';
import { ConversationChatActionType, conversationChatReducer } from '../../reducers/conversation-chat-reducer/conversationChatReducer';
import { DEFAULT_PAGINATION_SIZE } from '../../../utils/querystring/querystring';
import { PaginatedConversationMessageResult } from '../../../providers/conversationMessagePagination';
import { useConversationMessages } from '../../hooks/use-conversation-messages/useConversationMessages';
import ConversationMessageCard from '../../components/conversation-message-card/ConversationMessageCard';
import * as styles from './ViewConversationChat.style';
import SendConversationMessage from '../send-conversation-message/SendConversationMessage';
import { useFCM } from '../../../hooks/use-fcm/useFCM';
import { markAllConversationMessagesAsRead } from '../../../providers/api/conversation/conversationProvider';
import ViewContactActivitiesSummary from '../../../contact/containers/view-contact-activities-summary/ViewContactActivitiesSummary';
import { noop } from '../../../utils';
import Card from '../../../components/card/Card';
import CardTitle from '../../../components/card/title/CardTitle';
import CardSkeleton from '../../../components/card/skeleton/CardSkeleton';

const buildUnreadMessagesMessage = (unreadMessagesCount: number): string => `
  ${unreadMessagesCount}
  ${pluralizeWord('nuov', 'o', 'i', unreadMessagesCount)}
  ${pluralizeWord('messagg', 'io', 'i', unreadMessagesCount)}
`;

export interface ViewConversationChatProps {
  conversation: Conversation,
  onMessagesRead?: VoidFunction,
}

interface Context {
  currentMessages: ConversationMessage[],
  unreadMessagesCount: number
}

// Workaround for when the total number of rendered items is unknown/dynamic
const START_INDEX = Number.MAX_SAFE_INTEGER;

export const LOAD_PREVIOUS_CONVERSATION_MESSAGES_ERROR = 'Non è stato possibile caricare i messaggi scambiati.';
export const LOAD_NEW_CONVERSATION_MESSAGES_ERROR = 'C\'è stato un problema nella ricezione dei nuovi messaggi.';

const ChatRow: ItemContent<ConversationMessage, Context> = (i, message, ctx) => {
  const {
    inbound, body, createdAt, isBot,
  } = message;
  const { currentMessages, unreadMessagesCount } = ctx;

  const showDaysDivider = (currMessage: ConversationMessage, prevMessage: ConversationMessage): boolean => {
    const currMessageDate = getDate(new Date(currMessage.createdAt));
    const prevMessageDate = getDate(new Date(prevMessage.createdAt));

    return currMessageDate !== prevMessageDate;
  };

  const prevMessage = currentMessages[currentMessages.length - (START_INDEX - i + 1)];

  return (
    <>
      {prevMessage && showDaysDivider(message, prevMessage) && (
        <div css={styles.messagesDivider}>
          <hr css={styles.messagesDividerHalf} />
          <span>{formatDate(new Date(createdAt))}</span>
          <hr css={styles.messagesDividerHalf} />
        </div>
      )}

      {unreadMessagesCount > 0 && START_INDEX - i === unreadMessagesCount && (
        <div css={styles.messagesDivider}>
          <hr css={styles.messagesDividerHalf} />
          <span>{buildUnreadMessagesMessage(unreadMessagesCount)}</span>
          <hr css={styles.messagesDividerHalf} />
        </div>
      )}

      <div
        css={styles.messageItem}
        $inbound={inbound === 'true'}
      >
        <div css={styles.messageWrapper}>
          <ConversationMessageCard
            text={body}
            date={createdAt}
            inbound={inbound === 'true'}
            author={isBot === 'true' ? 'BOT' : undefined}
          />
        </div>
      </div>
    </>
  );
};

export const CONVERSATION_EXPIRED_WARNING_MESSAGE = 'ATTENZIONE: Non è possible inviare messaggi perché sono intercorse più di 24 ore dall\'ultimo messaggio dell\'utente';

const ViewConversationChat: React.FC<ViewConversationChatProps> = ({
  conversation,
  onMessagesRead,
}) => {
  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState(0);
  const [firstItemIndex, setFirstItemIndex] = React.useState(START_INDEX);
  const [viewListRange, setViewListRange] = React.useState<ListRange>({ startIndex: 0, endIndex: 0 });
  const [pageToken, setPageToken] = React.useState<string>();
  const [currentMessages, dispatch] = React.useReducer(conversationChatReducer, []);
  const virtuosoRef = React.useRef<VirtuosoHandle>(null);
  const { addError } = useNotifications();

  const onMessagesLoaded = React.useCallback((data: PaginatedConversationMessageResult) => {
    dispatch({
      type: ConversationChatActionType.ADD_PREVIOUS_MESSAGES,
      payload: data?.content.reverse() || [],
    });

    // Each time new messages are pushed in the queue we must also update the `firstItemIndex`,
    // which is the index of the top-most item, used by react-virtuoso to correctly display the scrollable content.
    setFirstItemIndex((prev) => prev - (data?.content.length || 0));
  }, []);

  const { data: messages, error: messagesError } = useConversationMessages(
    conversation.id,
    {
      size: DEFAULT_PAGINATION_SIZE,
      pageToken,
    },
    onMessagesLoaded,
  );

  // Returns true if we scrolled past the last page, aka if we are looking at the second chunk of loaded items.
  const pastLastPage = React.useMemo(() => START_INDEX - viewListRange.startIndex > DEFAULT_PAGINATION_SIZE, [viewListRange]);

  const loadPreviousMessages = React.useCallback(() => {
    if (messages?.nextPageToken) {
      setPageToken(messages?.nextPageToken);
    }
  }, [messages]);

  const scrollToIndex = React.useCallback((index: number | 'LAST') => {
    virtuosoRef.current?.scrollToIndex({
      index,
      behavior: 'smooth',
    });
  }, []);

  const onMessage = React.useCallback((where: 'foreground' | 'background') => (payload: MessagePayload) => {
    const message = payload.data as any as ConversationMessage;

    if (message.conversationId === conversation.id) {
      dispatch({
        type: ConversationChatActionType.ADD_NEW_MESSAGES,
        payload: [message],
      });

      setFirstItemIndex((prev) => prev - 1);
      setUnreadMessagesCount((prev) => prev + 1);

      const lookingAtLastPage = !pastLastPage && where === 'foreground';

      if (message.inbound === 'false' || lookingAtLastPage) {
        scrollToIndex('LAST');
      }
    }
  }, [conversation.id, pastLastPage, scrollToIndex]);

  const { error } = useFCM(ViewConversationChat.name, onMessage('foreground'), onMessage('background'));

  // When a different conversation is selected, it is necessary
  // to empty the conversation messages list and reset the page token.
  React.useEffect(() => {
    dispatch({
      type: ConversationChatActionType.RESET_ALL_MESSAGES,
      payload: [],
    });

    setPageToken(undefined);
    setFirstItemIndex(START_INDEX);
    setUnreadMessagesCount(0);
  }, [conversation.id]);

  React.useEffect(() => {
    if ((START_INDEX - viewListRange.endIndex) <= 2 && (unreadMessagesCount > 0 || conversation.hasUnreadMessages)) {
      markAllConversationMessagesAsRead(conversation.id)
        .then(() => {
          setTimeout(() => {
            setUnreadMessagesCount(0);

            if (onMessagesRead) {
              onMessagesRead();
            }
          }, 3000);
        })
        .catch(() => noop());
    }
  }, [conversation.hasUnreadMessages, conversation.id, onMessagesRead, unreadMessagesCount, viewListRange.endIndex]);

  const contactActions = React.useMemo(() => {
    const actions = [
      {
        label: 'Vedi contatto',
        onClick: () => window.open(`/contacts/${conversation.contact.id}`),
      },
      {
        label: 'Aggiungi interesse',
        onClick: () => window.open(`/intents/create?contact_id=${conversation.contact.id}`),
      },
      {
        label: 'Aggiungi ricerca',
        onClick: () => window.open(`/search-criteria/create?contact_id=${conversation.contact.id}`),
      },
    ];

    return actions;
  }, [conversation.contact.id]);

  return (
    <div css={styles.basic}>
      <div>
        <Card>
          <Card.Header
            secondaryActions={contactActions.map((action) => (
              <Dropdown.Option {...action} />
            ))}
          >
            <CardTitle>
              <Icon path={ICON_ACCOUNT_CIRCLE_OUTLINE} />
              <Typography.HEADING_4 color="brand.primary">
                {conversation.contact.name || conversation.contact.phoneNumber}
              </Typography.HEADING_4>
            </CardTitle>
          </Card.Header>
          <Card.Box>
            {conversation.contact.id && (
              <ExpandableContent openLabel="Mostra attività" closeLabel="Nascondi">
                <ViewContactActivitiesSummary contactId={conversation.contact.id} />
              </ExpandableContent>
            )}
          </Card.Box>
        </Card>
      </div>

      {currentMessages.length === 0 && !error && (
        <CardSkeleton aria-label="first-loading" />
      )}

      {error && (
        <Message
          type="critical"
          boxed
          message={LOAD_NEW_CONVERSATION_MESSAGES_ERROR}
        />
      )}

      {messagesError && (
        <Message
          type="critical"
          boxed
          message={LOAD_PREVIOUS_CONVERSATION_MESSAGES_ERROR}
        />
      )}

      {conversation.expired && (
        <Message
          type="warning"
          boxed
          message={CONVERSATION_EXPIRED_WARNING_MESSAGE}
        />
      )}

      {currentMessages.length > 0 && (
        <div css={styles.chatWrapper}>
          {!messages && !messagesError && (
            <div
              aria-label="loading"
              css={styles.messagesLoading}
            >
              <Spinner
                size={SpinnerSize.SMALL}
                color="neutrals.medium"
              />
            </div>
          )}
          <div css={styles.messagesWrapper}>
            <Virtuoso
              ref={virtuosoRef}
              firstItemIndex={Math.max(0, firstItemIndex)}
              initialTopMostItemIndex={DEFAULT_PAGINATION_SIZE - 1}
              data={currentMessages}
              followOutput="smooth"
              alignToBottom
              context={{
                currentMessages,
                unreadMessagesCount,
              }}
              startReached={loadPreviousMessages}
              itemContent={ChatRow}
              rangeChanged={setViewListRange}
            />
            {pastLastPage && (
              <div css={styles.newMessages}>
                {unreadMessagesCount > 0 ? (
                  <Action
                    label={buildUnreadMessagesMessage(unreadMessagesCount)}
                    size="S"
                    emphasis="high"
                    onClick={() => scrollToIndex('LAST')}
                    iconLeft={{
                      path: ICON_ARROW_UP,
                      direction: 'bottom',
                    }}
                  />
                ) : (
                  <ActionIcon
                    label="Vedi nuovi messaggi"
                    size="S"
                    emphasis="high"
                    onClick={() => scrollToIndex('LAST')}
                    icon={{
                      path: ICON_ARROW_UP,
                      direction: 'bottom',
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div css={styles.sendMessage}>
            <SendConversationMessage
              conversation={conversation}
              onError={addError}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewConversationChat;
