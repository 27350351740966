import {
  FontWeight, ThemedFunctor, TypographyName, getTypography,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const table: ThemedFunctor = ({ theme }) => css`
  width: 100%;
  color: ${theme.color.foreground.neutral.default.low};
  display: table;
`;

export const thead: ThemedFunctor = ({ theme }) => css`
  background-color: ${theme.color.background.neutral.default.low};
  display: table-header-group;
`;

export const thRow: ThemedFunctor = () => css`
  display: table-row;
`;

export const header: ThemedFunctor = () => css`
  cursor: pointer;
`;

export const row: ThemedFunctor = ({ theme, $interactive }) => css`
  position: relative;
  display: table-row;
  background: ${theme.color.background.neutral.default.lowest};


  &:not(:last-child) {
    border-bottom: 1px solid ${theme.color.border.neutral.default.low};
  }

  ${$interactive && `
    cursor: pointer;

    &:hover {
      background-color: ${theme.color.background.neutral.default.low};
    }
  `}
`;

export const rowClickArea: ThemedFunctor = () => css`
  cursor: pointer;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

export const tbody: ThemedFunctor = () => css`
  display: table-row-group;
`;

export const th: ThemedFunctor = ({ theme, $align = 'left', $width = 'auto' }) => css`
  ${getTypography(theme, TypographyName.BODY, { fontWeight: FontWeight.REGULAR, lineHeight: '1' })}
  padding: ${theme.remSize[150]} ${theme.remSize[100]};
  text-align: ${$align};
  vertical-align: middle;
  display: table-cell;
  width: ${$width};

  &:first-child {
    padding-left: ${theme.remSize[150]};
  }

  &:last-child {
    padding-right: ${theme.remSize[150]};
  }
`;

export const td: ThemedFunctor = ({
  theme,
  $align = 'left',
  $width = 'auto',
  $pointerEvents = 'all',
}) => css`
  ${getTypography(theme, TypographyName.BODY, { lineHeight: '1' })};
  padding: ${theme.remSize[100]};
  vertical-align: middle;
  display: table-cell;
  text-align: ${$align};
  width: ${$width};
  pointer-events: ${$pointerEvents};

  &:first-child {
    padding-left: ${theme.remSize[150]};
  }

  &:last-child {
    padding-right: ${theme.remSize[150]};
  }
`;

export const tdContent: ThemedFunctor = ({ theme }) => css`
  display: inline-block;
  position: relative;
  z-index: 1; /** It needs to put the table content over the clickable area */

  &:hover {
    z-index: ${theme.zIndex.overthetop}; /** It needs to put the table content overall the table elements */
  }
`;

export const pagination: ThemedFunctor = ({ theme }) => css`
  background-color: ${theme.color.background.neutral.default.low};
  padding: ${theme.remSize[150]};
  justify-content: space-between;
  border-top: 1px solid ${theme.color.border.neutral.default.low};
`;
