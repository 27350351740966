import { Text, View, Image } from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';

import * as styles from './Header.style';

export interface HeaderProps {
  logo: string;
  title: string;
  price: string;
}

export const Header: FunctionComponent<HeaderProps> = ({
  logo,
  title,
  price,
}) => (
  <View>
    <View style={styles.logoWrapper as any}>
      <Image style={styles.logo} src={logo} />
    </View>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.price as any}>{price}</Text>
  </View>
);
