/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Form, FormHandlers, ICON_MAGNIFY, ICON_MAP, Maps, Icon,
  Action,
  FormActions,
  Grid,
} from '@doveit/bricks';
import { GeoCodeResult } from '../../../../providers/geo/dtos';
import { GMAP_API_KEY } from '../../../../constants';
import { geocodeByAddress } from '../../../../providers/geo/geocode/geocodeProvider';
import { DEFAULT_PROPERTY_GEO_FIELD_NAMES, GEO_CODE_FIELDS } from './constants';
import * as styles from './renderPropertyGeoFields.style';
import { GeoFormModel } from '../../../geo/models/geoModel';

export type PropertyGeoFields = Required<GeoFormModel>;

export type PropertyGeoFieldNames = Record<keyof Required<PropertyGeoFields>, string>;

export function getNormalizedAddress(fields: Pick<GeoCodeResult, 'route' | 'streetNumber' | 'postalCode' | 'locality'>) {
  return [fields.route, fields.streetNumber, fields.postalCode, fields.locality].filter((i) => i).join(', ');
}

function onNormalizedAddressChanged(handleChange: FormHandlers['handleChange'], fieldNames: PropertyGeoFieldNames) {
  return (result: GeoCodeResult | null) => {
    if (result) {
      GEO_CODE_FIELDS.forEach((field) => handleChange(
        { target: { name: fieldNames[field], value: result[field] || '' } },
      ));
    }
  };
}

function clearAddressFields(setFieldValue: FormActions['setFieldValue'], fieldNames: PropertyGeoFieldNames) {
  return () => {
    [fieldNames.route, fieldNames.streetNumber, fieldNames.locality, fieldNames.postalCode].forEach((name) => {
      setFieldValue(name, '');
    });
  };
}

export const renderPropertyGeoFields = (
  values: PropertyGeoFields,
  handleChange: FormHandlers['handleChange'],
  setFieldValue: FormActions['setFieldValue'],
  overrideFieldNames: Partial<PropertyGeoFieldNames> = {},
) => {
  const fieldNames: PropertyGeoFieldNames = {
    ...DEFAULT_PROPERTY_GEO_FIELD_NAMES,
    ...overrideFieldNames,
  };

  const addressParams = {
    route: values.route,
    streetNumber: values.streetNumber,
    postalCode: values.postalCode,
    locality: values.locality,
  };

  const normalizedAddress = getNormalizedAddress(addressParams);

  return (
    <>
      <Form.Item>
        <Grid gutter={50}>
          <Grid.Unit size={{ MD: 7 / 10, LG: 8 / 10 }}>
            <Form.AutoCompleteV2
              aria-label="Campo per inserire l'indirizzo"
              name={fieldNames.geoCodeResult}
              icon={ICON_MAGNIFY}
              value={addressParams}
              suggestions={geocodeByAddress}
              printSuggestion={getNormalizedAddress}
              isClearable={false}
              onSuggestionSelected={onNormalizedAddressChanged(handleChange, fieldNames)}
            />
          </Grid.Unit>
          <Grid.Unit size={{ MD: 3 / 10, LG: 2 / 10 }}>
            <Action
              label="Pulisci campi indirizzo"
              aria-label="Pulsante per pulire i campi dell'indirizzo immobile"
              onClick={clearAddressFields(setFieldValue, fieldNames)}
              disabled={!normalizedAddress}
              expanded
            />
          </Grid.Unit>
        </Grid>
      </Form.Item>
      <Form.Item>
        <div css={styles.mapWrapper}>
          <Icon
            path={ICON_MAP}
            size={70}
          />
          {values.route && (
            <Maps
              apiKey={GMAP_API_KEY}
              params={normalizedAddress}
              fit
              title="Maps"
              tabIndex="-1"
            />
          )}
        </div>
      </Form.Item>
      <Form.Item size={{ MD: 4 / 10 }}>
        <Form.Input
          id={fieldNames.administrativeAreaLevelOne}
          name={fieldNames.administrativeAreaLevelOne}
          label="Regione"
          aria-label="Campo per inserire la regione dell'immobile"
        />
      </Form.Item>
      <Form.Item size={{ MD: 4 / 10 }}>
        <Form.Input
          id={fieldNames.administrativeAreaLevelTwo}
          name={fieldNames.administrativeAreaLevelTwo}
          label="Provincia"
          aria-label="Campo per inserire la provincia dell'immobile"
        />
      </Form.Item>
      <Form.Item size={{ MD: 6 / 10 }}>
        <Form.Input
          id={fieldNames.locality}
          name={fieldNames.locality}
          label="Comune"
          aria-label="Campo per inserire il Comune dell'immobile"
        />
      </Form.Item>
      <Form.Item size={{ MD: 2 / 10 }}>
        <Form.Input
          id={fieldNames.postalCode}
          name={fieldNames.postalCode}
          label="CAP"
          aria-label="Campo per inserire il CAP dell'immobile"
          required
        />
      </Form.Item>
      <Form.Item size={{ MD: 8 / 10 }}>
        <Form.Input
          id={fieldNames.suburb}
          name={fieldNames.suburb}
          label="Quartiere"
          aria-label="Campo per inserire il quartiere dell'immobile"
        />
      </Form.Item>
      <Form.Item size={{ MD: 8 / 10 }}>
        <Form.Input
          id={fieldNames.route}
          name={fieldNames.route}
          label="Strada"
          aria-label="Campo per inserire la strada dell'immobile"
          required
        />
      </Form.Item>
      <Form.Item size={{ MD: 2 / 10 }}>
        <Form.Input
          id={fieldNames.streetNumber}
          name={fieldNames.streetNumber}
          label="Numero civico"
          aria-label="Campo per inserire il civico dell'immobile"
        />
      </Form.Item>
      <Form.Item size={{ MD: 3 / 10 }}>
        <Form.Input
          id={fieldNames.latitude}
          name={fieldNames.latitude}
          label="Latitudine"
          aria-label="Campo per inserire la latitudine dell'immobile"
        />
      </Form.Item>
      <Form.Item size={{ MD: 3 / 10 }}>
        <Form.Input
          id={fieldNames.longitude}
          name={fieldNames.longitude}
          label="Longitudine"
          aria-label="Campo per inserire la longitudine dell'immobile"
        />
      </Form.Item>
    </>
  );
};
