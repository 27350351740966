import {
  FormSkeleton, Message, useNotifications,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useAgent } from '../../../../hooks/use-agent/useAgent';
import { updateAssignment } from '../../../../providers/api/assignment/assignmentProvider';
import { Assignment } from '../../../../providers/api/dtos';
import useAssignmentByPropertyId from '../../../hooks/use-assignment-by-property-id/useAssignmentByPropertyId';
import EditPropertyAssignmentForm, { EditPropertyAssignmentFormModel, EditPropertyAssignmentFormProps } from '../../components/edit-property-assignment-form/EditPropertyAssignmentForm';
import { toPropertyAssignmentModel, toAssignment } from '../../mappers/assignmentMapper';

export interface EditPropertyAssignmentProps {
  propertyId: number,
  onSuccess?: (updatedAssignment: Assignment) => void,
  hiddenFields?: EditPropertyAssignmentFormProps['hiddenFields'],
}

export const LOAD_ASSIGNMENT_ERROR_MESSAGE = 'Non è possibile reperire il mandato';
export const SAVE_ASSIGNMENT_ERROR_MESSAGE = 'Errore durante il salvataggio del mandato';
export const SAVE_ASSIGNMENT_SUCCESS_MESSAGE = 'Il mandato è stato aggiornato correttamente';

/**
 * @deprecated Use `EditPropertyAssignmentAction`
 */
const EditPropertyAssignment: FunctionComponent<EditPropertyAssignmentProps> = ({
  propertyId,
  onSuccess,
  hiddenFields,
}) => {
  const { data: assignment, error: assignmentError } = useAssignmentByPropertyId(propertyId);
  const { data: agent, error: agentError } = useAgent(assignment?.agentId);
  const [isSaving, setIsSaving] = useState(false);
  const { addError, addSuccess } = useNotifications();

  const onSubmit = useCallback(async (formValues: EditPropertyAssignmentFormModel) => {
    setIsSaving(true);

    try {
      const payload = toAssignment(formValues, propertyId, assignment!);
      const updatedAssignment = await updateAssignment(payload);

      setIsSaving(false);
      addSuccess(SAVE_ASSIGNMENT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedAssignment);
      }
    } catch (error) {
      setIsSaving(false);
      addError(SAVE_ASSIGNMENT_ERROR_MESSAGE);
    }
  }, [propertyId, assignment, addSuccess, onSuccess, addError]);

  if ((!assignment && !assignmentError)
    || (!agent && !agentError)) {
    return <FormSkeleton />;
  }

  if (assignmentError || agentError) {
    return (
      <Message
        type="critical"
        message={LOAD_ASSIGNMENT_ERROR_MESSAGE}
      />
    );
  }

  return assignment! && agent! && (
    <EditPropertyAssignmentForm
      initialValues={toPropertyAssignmentModel(assignment, agent)}
      onSubmit={onSubmit}
      loading={isSaving}
      hiddenFields={hiddenFields}
    />
  );
};

export default EditPropertyAssignment;
