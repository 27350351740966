import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { getContactConcessions } from '../../../providers/api/contact/contactProvider';
import { Concession } from '../../../providers/api/dtos/concession';

export const CONTACT_CONCESSIONS_RID = '/contacts/:id/concessions';

export default function useContactConcessions(
  contactId?: string | undefined | null,
  options?: SWRConfiguration<Concession[], AxiosError>,
): SWRResponse<Concession[], AxiosError> {
  return useSWR(
    contactId ? [CONTACT_CONCESSIONS_RID, contactId] : null,
    () => getContactConcessions(contactId!),
    options,
  );
}
