import React from 'react';
import { AxiosError } from 'axios';
import { Contact } from '../../../providers/api/dtos';
import { SortOrder } from '../../../providers/pagination';
import { usePropertyContacts } from '../use-property-contacts/usePropertyContacts';
import { PropertyRelationshipType } from '../../../domain/types';
import { SWRCustomResponse } from '../../../hooks/types';

export function usePropertySellers(
  propertyId?: number,
): SWRCustomResponse<Contact[], AxiosError> {
  const result = usePropertyContacts(propertyId, { createdAt: SortOrder.ASC });

  const data = React.useMemo(
    () => result.data
      ?.filter((propertyContact) => propertyContact.relationship === PropertyRelationshipType.PROPRIETARIO)
      .map((propertyContact) => propertyContact.contact),
    [result.data],
  );

  return {
    ...result,
    data,
  };
}
