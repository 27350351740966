import { PropertyRelationshipType } from '../domain/types/propertyRelationshipType';

export const propertyRelationshipTypeLabels: { [key in PropertyRelationshipType]: string } = {
  [PropertyRelationshipType.PROPRIETARIO]: 'Proprietario',
  [PropertyRelationshipType.INQUILINO]: 'Inquilino',
  [PropertyRelationshipType.PARENTE]: 'Parente',
  [PropertyRelationshipType.AVVOCATO]: 'Avvocato',
  [PropertyRelationshipType.CUSTODE]: 'Custode',
  [PropertyRelationshipType.ALTRO]: 'Altro',
};
