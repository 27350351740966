/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import {
  Action, Form, FormHandlers, HStack, Label, LinkOnClickEvent, Message, Skeleton, Stack, Typography, useNotifications,
} from '@doveit/bricks';
import { DocumentStatus } from '../../../domain/types';
import { updateDocument } from '../../../providers/api/document/documentProvider';
import { Document } from '../../../providers/api/dtos';
import useDocument from '../../hooks/use-document/useDocument';
import DocumentFileManager from '../../../property/containers/document-file-manager/DocumentFileManager';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { isMandatory } from '../../utils/documentUtils';
import { DocumentReferenceType } from '../../../domain/types/documentReferenceType';

export enum UpdateDocumentEventType {
  DOCUMENT_UPDATED = 'DOCUMENT_UPDATED',
  DOCUMENT_FILE_ADDED = 'DOCUMENT_FILE_ADDED',
  DOCUMENT_FILE_DELETED = 'DOCUMENT_FILE_DELETED',
}

export interface EditDocumentProps {
  documentId: number,
  referenceContext?: DocumentReferenceType,
  canAutoApprove?: boolean,
  onSuccess?: (updatedDocument: Document, operationType: UpdateDocumentEventType) => void,
}

export const DOCUMENT_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare il documento';
export const DOCUMENT_UPDATE_SUCCESS_MESSAGE = 'Documento salvato con successo';
export const DOCUMENT_UPDATE_ERROR_MESSAGE = 'Non è stato possibile salvare il documento';

const EditDocument: React.FC<EditDocumentProps> = ({
  documentId,
  referenceContext,
  canAutoApprove,
  onSuccess,
}) => {
  const formRef = React.useRef<FormHandlers>() as React.MutableRefObject<FormHandlers>;
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const { data: document, error: documentError, mutate: mutateDocument } = useDocument(documentId);
  const [documentStatus, setDocumentStatus] = React.useState<DocumentStatus>();
  const { user } = useRBAC();

  const isMandatoryInContext = React.useMemo(
    () => (document ? isMandatory(referenceContext ?? document.reference.type, document.type) : undefined),
    [document, referenceContext],
  );

  React.useEffect(() => {
    if (document) {
      setDocumentStatus(document.status);
    }
  }, [document]);

  const onSubmit = React.useCallback(async ({ notes }: { notes: Document['notes'] }) => {
    try {
      setIsSaving(true);

      const documentToUpdate: Document = {
        ...document!,
        notes: notes || undefined,
        reviewNotes: documentStatus === DocumentStatus.APPROVED ? undefined : document?.reviewNotes,
        status: documentStatus!,
        approvedBy: documentStatus === DocumentStatus.APPROVED ? user?.email : undefined,
        approvedAt: documentStatus === DocumentStatus.APPROVED ? new Date().toISOString() : undefined,
      };

      const updatedDocument = await updateDocument(document!.id!, documentToUpdate);

      setIsSaving(false);
      addSuccess(DOCUMENT_UPDATE_SUCCESS_MESSAGE);
      mutateDocument();

      if (onSuccess) {
        onSuccess(updatedDocument, UpdateDocumentEventType.DOCUMENT_UPDATED);
      }
    } catch (err) {
      setIsSaving(false);
      setDocumentStatus(document!.status);
      addError(DOCUMENT_UPDATE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, document, documentStatus, mutateDocument, onSuccess, user?.email]);

  const setStatusAndSave = React.useCallback((status?: DocumentStatus) => (e: LinkOnClickEvent) => {
    if (status) {
      setDocumentStatus(status);
    }

    const formEvent = e as any as React.FormEvent<HTMLFormElement>;
    formRef.current.handleSubmit(formEvent);
  }, [formRef]);

  const onDocumentFileChange = React.useCallback((operationType: UpdateDocumentEventType.DOCUMENT_FILE_ADDED | UpdateDocumentEventType.DOCUMENT_FILE_DELETED) => async () => {
    const updatedDocument = await mutateDocument();

    if (onSuccess) {
      onSuccess(updatedDocument!, operationType);
    }
  }, [mutateDocument, onSuccess]);

  if (documentError) {
    return (
      <Message
        type="critical"
        message={DOCUMENT_LOAD_ERROR_MESSAGE}
      />
    );
  }

  if (!document) {
    return <Skeleton count={5} />;
  }

  return (
    <Stack>
      {[DocumentStatus.REJECTED, DocumentStatus.DRAFT].includes(documentStatus!) && (
        <div>
          <Label text="Note di revisione" />
          <Typography.BODY data-ref="review-notes">
            {document.reviewNotes || 'Non presenti'}
          </Typography.BODY>
        </div>
      )}
      <Form
        innerRef={formRef}
        initialValues={{ notes: document?.notes || '' }}
        loading={isSaving}
        onSubmit={onSubmit}
      >
        <Form.Item>
          <Form.TextArea
            aria-label="Campo per l'inserimento delle note"
            label="Note documento"
            name="notes"
            rows={3}
          />
        </Form.Item>
      </Form>
      <DocumentFileManager
        documentId={document.id!}
        documentStatus={documentStatus!}
        existingFiles={document.files}
        onCreateSuccess={onDocumentFileChange(UpdateDocumentEventType.DOCUMENT_FILE_ADDED)}
        onDeleteSuccess={onDocumentFileChange(UpdateDocumentEventType.DOCUMENT_FILE_DELETED)}
      />
      <HStack>
        <Action
          label="Salva bozza"
          emphasis="high"
          loading={isSaving}
          onClick={setStatusAndSave(DocumentStatus.REJECTED
            ? DocumentStatus.DRAFT
            : undefined)}
        />

        {!!document.files.length
          && canAutoApprove
          && isMandatoryInContext
          && document.status !== DocumentStatus.APPROVED && (
            <Action
              label="Salva e approva"
              color="success"
              emphasis="high"
              loading={isSaving}
              onClick={setStatusAndSave(DocumentStatus.APPROVED)}
            />
          )}
        {!!document.files.length
          && !canAutoApprove
          && isMandatoryInContext
          && [DocumentStatus.DRAFT, DocumentStatus.REJECTED].includes(document.status) && (
            <Action
              label="Salva e chiedi revisione"
              color="warning"
              emphasis="high"
              loading={isSaving}
              onClick={setStatusAndSave(DocumentStatus.REVIEW)}
            />
          )}
      </HStack>
    </Stack>
  );
};

export default EditDocument;
