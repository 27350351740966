import {
  InvoiceStatus, Status, Aggregator, PropertyType,
} from '../../../domain/types';
import { WithRequiredKeys } from '../../../types';
import {
  PaginationParams, serializePagination, PaginatedResult, SortParams, serializeSortParams,
} from '../../pagination';
import { API_PROPERTIES_PATH, getClient } from '../api';
import {
  MatchingSearchCriteria, Property, PropertyMatchCounters, SearchCriteria, PropertyContact, Document, MatchingIntents, PropertySearchItem,
  PropertyPreview,
} from '../dtos';
import { serializeParams } from '../utils/serializer';

export interface PropertiesProviderFilters {
  agentEmail?: string | string[],
  id?: number,
  status?: Status | Status[],
  propertyType?: PropertyType,
  normalizedAddress?: string,
  publishedOn?: Aggregator | Aggregator[],
  invoiceStatus?: InvoiceStatus,
}

export interface PropertiesMatchesFilters {
  showHiddenMatches?: boolean,
}

export async function getProperties(
  filters: PropertiesProviderFilters = {},
  pagination: PaginationParams = { size: 50 },
): Promise<PaginatedResult<WithRequiredKeys<Property, 'id'>>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<WithRequiredKeys<Property, 'id'>>>(API_PROPERTIES_PATH, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });
  return data;
}

export async function getProperty(propertyId: number): Promise<Property> {
  const client = await getClient();
  const { data } = await client.get<Property>(`${API_PROPERTIES_PATH}/${propertyId}`);
  return data;
}

export async function getPropertyPreview(propertyId: number): Promise<PropertyPreview> {
  const client = await getClient();
  const { data } = await client.get<PropertyPreview>(`${API_PROPERTIES_PATH}/${propertyId}/preview`);
  return data;
}

export async function searchProperties(
  query: string,
  filters: PropertiesProviderFilters = {},
  pagination: PaginationParams = { size: 50 },
): Promise<PaginatedResult<PropertySearchItem>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<PropertySearchItem>>(`${API_PROPERTIES_PATH}/search`, {
    params: {
      q: query,
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function matchProperties(
  searchCriteria: SearchCriteria,
  pagination: PaginationParams = { size: 50 },
  exactMatch: boolean = false,
): Promise<PaginatedResult<WithRequiredKeys<Property, 'id' | 'referenceId'>>> {
  const client = await getClient();
  const { data } = await client.post<PaginatedResult<WithRequiredKeys<Property, 'id' | 'referenceId'>>>(`${API_PROPERTIES_PATH}/match`, searchCriteria, {
    params: { ...serializePagination(pagination), ...serializeParams({ exactMatch }) },
  });

  return data;
}

export async function getPropertyMatchingSearchCriteria(
  propertyId: number,
  filters: PropertiesMatchesFilters = {},
  pagination: PaginationParams = { size: 50 },
): Promise<PaginatedResult<MatchingSearchCriteria>> {
  const client = await getClient();

  const { data } = await client.get<PaginatedResult<MatchingSearchCriteria>>(`${API_PROPERTIES_PATH}/${propertyId}/match/search-criteria`, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getPropertyMatchingIntents(
  propertyId: number,
  filters: PropertiesMatchesFilters = {},
  pagination: PaginationParams = { size: 50 },
): Promise<PaginatedResult<MatchingIntents>> {
  const client = await getClient();

  const { data } = await client.get<PaginatedResult<MatchingIntents>>(`${API_PROPERTIES_PATH}/${propertyId}/match/intents`, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function getPropertyMatchCounters(
  propertyId: number,
  filters: PropertiesMatchesFilters = {},
): Promise<PropertyMatchCounters> {
  const client = await getClient();

  const { data } = await client.get<PropertyMatchCounters>(`${API_PROPERTIES_PATH}/${propertyId}/match-counters`, {
    params: serializeParams(filters),
  });

  return data;
}

export async function createProperty(property: Property): Promise<Property> {
  const client = await getClient();
  const { data } = await client.post<Property>(API_PROPERTIES_PATH, property);

  return data;
}

export async function updateProperty(id: number, property: Property): Promise<Property> {
  const client = await getClient();
  const { data } = await client.put<Property>(`${API_PROPERTIES_PATH}/${id}`, property);

  return data;
}

export async function getPropertyContacts(propertyId: number, sort?: SortParams): Promise<PropertyContact[]> {
  const client = await getClient();
  const { data } = await client.get<PropertyContact[]>(`${API_PROPERTIES_PATH}/${propertyId}/contacts`, sort && {
    params: {
      sort: serializeSortParams(sort),
    },
  });

  return data;
}

export async function getPropertyDocuments(propertyId: number): Promise<Document[]> {
  const client = await getClient();

  const { data } = await client.get<Document[]>(`${API_PROPERTIES_PATH}/${propertyId}/documents`);

  return data;
}
