/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  FontWeight,
  HStack,
  ICON_SELECT_ALL,
  Icon,
  Message, Popover, PopoverProps, Spacing,
  Stack,
  Text,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import { Rumor } from '../../../providers/api/dtos';
import PopoverPreviewSkeleton from '../../../components/popover-preview/PopoverPreview.skeleton';
import { goToDetailPage } from '../../../utils/navigate/utils';
import PopoverPreview from '../../../components/popover-preview/PopoverPreview';
import * as styles from './ViewRumorPopoverPreview.style';
import { NO_VALUE_SYMBOL, formatFloor } from '../../../property/utils';
import { propertyStatusLabels, propertyTypeLabels } from '../../../labels';
import useRumor from '../../hooks/use-rumor/useRumor';
import { PropertyStatus, ReferenceType } from '../../../domain/types';
import RumorAvatar from '../../components/rumor-avatar/RumorAvatar';

export interface ViewRumorPopoverPreviewProps {
  rumorId: NonNullable<Rumor['id']>,
  trigger: PopoverProps['trigger'],
}

const ViewRumorPopoverPreview: React.FC<ViewRumorPopoverPreviewProps> = ({
  rumorId,
  trigger,
}) => {
  const { data: rumor, isLoading: isRumorLoading, error: rumorError } = useRumor(rumorId);

  const features = React.useMemo(() => ([
    {
      key: 'propertySize',
      label: 'Superficie',
      value: rumor?.propertySize ? formatSquareMeters(rumor.propertySize) : NO_VALUE_SYMBOL,
    },
    {
      key: 'propertyFloor',
      label: 'Piano',
      value: rumor?.propertyFloor ? formatFloor(rumor.propertyFloor) : NO_VALUE_SYMBOL,
    },
  ]), [rumor?.propertyFloor, rumor?.propertySize]);

  return (
    <Popover
      trigger={trigger}
      hasArrow
    >
      <Spacing padding={25} css={styles.wrapper}>
        {(isRumorLoading) && <PopoverPreviewSkeleton aria-label="Caricamento informazioni notizia in corso" />}

        {rumorError && (
          <Spacing padding={150}>
            <Message
              type="critical"
              message="Non è stato possibile caricare le informazioni della notizia."
            />
          </Spacing>
        )}

        {rumor && (
          <PopoverPreview
            aria-label="Informazioni notizia"
            title={rumor.propertyAddress || 'Indirizzo non disponibile'}
            details={rumor.locality}
            avatar={<RumorAvatar size="L" />}
            action={{
              label: 'Visualizza notizia',
              'aria-label': 'Vai alla pagina della notizia',
              onClick: () => goToDetailPage(rumorId, ReferenceType.RUMOR),
            }}
          >
            <Stack gap={150}>
              <HStack gap={150}>
                <Text.Body fontWeight={FontWeight.MEDIUM} css={styles.type}>
                  {rumor.propertyType ? propertyTypeLabels[rumor.propertyType] : 'Tipologia non disponibile'}
                </Text.Body>

                {features.length > 0 && (
                  <div css={styles.features}>
                    {features.map(({ key, label, value }) => (
                      <div
                        key={key}
                        css={styles.feature}
                      >
                        <Text.BodySmall
                          fontWeight={FontWeight.MEDIUM}
                          lineHeight="1"
                          as="div"
                        >
                          {value}
                        </Text.BodySmall>
                        <Text.Mini
                          transform="uppercase"
                          as="div"
                        >
                          {label}
                        </Text.Mini>
                      </div>
                    ))}
                  </div>
                )}
              </HStack>

              {rumor.propertyStatus && (
                <HStack>
                  <Icon path={ICON_SELECT_ALL} />
                  <Text.Body>{propertyStatusLabels[rumor.propertyStatus as PropertyStatus]}</Text.Body>
                </HStack>
              )}
            </Stack>
          </PopoverPreview>
        )}
      </Spacing>
    </Popover>
  );
};

export default ViewRumorPopoverPreview;
