import { FeatureType, PropertyStatus, ResidentialPropertyType } from '../../../domain/types';

export enum GeoSearchCriterionType {
  POINT = 'POINT',
  LOCALITY = 'LOCALITY',
  AREA = 'AREA',
  PROVINCE = 'PROVINCE',
}

export interface GeoSearchCriterion {
  type: GeoSearchCriterionType,
}

export interface LocalityGeoSearchCriterion extends GeoSearchCriterion {
  type: GeoSearchCriterionType.LOCALITY,
  name: string,
}

export interface ProvinceGeoSearchCriterion extends GeoSearchCriterion {
  type: GeoSearchCriterionType.PROVINCE,
  name: string,
}

export interface AreaGeoSearchCriterion extends GeoSearchCriterion {
  type: GeoSearchCriterionType.AREA,
  name: string,
  locality: string,
}

export interface PointGeoSearchCriterion extends GeoSearchCriterion {
  type: GeoSearchCriterionType.POINT,
  address: string,
  latitude: number,
  longitude: number,
  radius: number,
}

export type PlaceGeoSearchCriterion = LocalityGeoSearchCriterion | AreaGeoSearchCriterion | ProvinceGeoSearchCriterion;

export type CompositeGeoSearchCriterion = PlaceGeoSearchCriterion | PointGeoSearchCriterion;

export interface SearchCriteria {
  readonly id?: number,
  contactId: string
  geo: Array<CompositeGeoSearchCriterion>,
  features: FeatureType[],
  propertyStatus: PropertyStatus[],
  propertyTypes: ResidentialPropertyType[],
  balcony: boolean,
  terrace: boolean,
  garage: boolean,
  privateGarden: boolean,
  mortgage?: boolean,
  auction?: boolean,
  minSize?: number,
  maxSize?: number,
  minPrice?: number,
  maxPrice?: number,
  notes?: string,
  active: boolean,
  source?: string,
  readonly createdAt?: string,
  readonly updatedAt?: string,
}
