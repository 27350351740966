import React from 'react';
import {
  Action, ConfirmModal, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { OfferWizardData } from '../../../providers/api/dtos';
import { cancelOfferSignatureRequest } from '../../../providers/api/offer/offerProvider';

export const CANCEL_OFFER_SIGNATURE_REQUEST_SUCCESS_MESSAGE = 'La richiesta di firma digitale della proposta è stata annullata con successo.';
export const CANCEL_OFFER_SIGNATURE_REQUEST_ERROR_MESSAGE = 'Non è stato possibile annullare la richiesta di firma digitale della proposta.';

interface ChildFnProps {
  cancelSignatureRequest: VoidFunction,
  isLoading: boolean,
}

export interface CancelOfferSignatureRequestActionProps {
  offerWizardData: OfferWizardData,
  onSuccess?: (offerWizardData: OfferWizardData) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const CancelOfferSignatureRequestAction: React.FC<CancelOfferSignatureRequestActionProps> = (props) => {
  const { offerWizardData, onSuccess, children } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const confirmCancelSignatureRequestModal = useModal();

  const openConfirmModal = React.useCallback(() => {
    confirmCancelSignatureRequestModal.open();
  }, [confirmCancelSignatureRequestModal]);

  const cancelSignatureRequest = React.useCallback(async () => {
    try {
      setIsLoading(true);

      await cancelOfferSignatureRequest(offerWizardData.intentId);

      setIsLoading(false);
      confirmCancelSignatureRequestModal.close();

      addSuccess(CANCEL_OFFER_SIGNATURE_REQUEST_SUCCESS_MESSAGE);
      onSuccess?.(offerWizardData);
    } catch (_) {
      setIsLoading(false);
      addError(CANCEL_OFFER_SIGNATURE_REQUEST_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, confirmCancelSignatureRequestModal, offerWizardData, onSuccess]);

  if (!offerWizardData.isDigitallySigned) {
    return null;
  }

  return (
    <>
      {
      children
        ? children({ cancelSignatureRequest: openConfirmModal, isLoading })
        : (
          <Action
            aria-label="Annulla la richiesta di firma digitale della proposta"
            label="Annulla richiesta"
            onClick={openConfirmModal}
            size="S"
            loading={isLoading}
          />
        )
    }
      <Portal>
        <ConfirmModal
          isOpen={confirmCancelSignatureRequestModal.isOpen}
          title="Conferma annullamento richiesta di firma"
          aria-label="Conferma annullamento richiesta di firma digitale della proposta"
          onConfirm={cancelSignatureRequest}
          variant="critical"
          onAbort={confirmCancelSignatureRequestModal.close}
        >
          Confermando l&apos;operazione, la richiesta di firma digitale della proposta sarà annullata.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default CancelOfferSignatureRequestAction;
