import { RumorStatus } from '../../../domain/types';
import { PaginatedResult, PaginationParams, serializePagination } from '../../pagination';
import { API_RUMORS_SERP_PATH, getClient } from '../api';
import { SerpRumor } from '../dtos';
import { serializeParams } from '../utils/serializer';

export interface SerpRumorProviderFilters {
  q?: string,
  status?: RumorStatus | RumorStatus[],
  toBeManaged?: boolean,
  latestReminderGt?: Date,
  latestReminderLt?: Date,
  agentEmail?: string | string[],
}

export async function getSerpRumors(
  filters: SerpRumorProviderFilters = {},
  pagination: PaginationParams = { size: 20 },
): Promise<PaginatedResult<SerpRumor>> {
  const client = await getClient();

  const { data } = await client.get(API_RUMORS_SERP_PATH, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}
