import { DocumentStatus } from '../domain/types';

export const documentStatusLabels: {
  [key in DocumentStatus]: string;
} = {
  [DocumentStatus.APPROVED]: 'Approvato',
  [DocumentStatus.REVIEW]: 'Da approvare',
  [DocumentStatus.REJECTED]: 'Rifiutato',
  [DocumentStatus.DRAFT]: 'In bozza',
};
