import useSWR, { SWRConfiguration } from 'swr';
import hash from 'object-hash';
import { Reminder, Rumor } from '../../../providers/api/dtos';
import { getReminders } from '../../../providers/api/reminder/reminderProvider';
import { ReferenceType } from '../../../domain/types';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';
import { REMINDERS_RID } from '../../../reminders/hooks/use-reminders/useReminders';

export function useRumorReminders(rumorId?: Rumor['id'], pagination?: PaginationParams, config?: SWRConfiguration): SWRCustomResponse<PaginatedResult<Reminder>, Error> {
  const matchMutate = useMatchMutate();
  const key = rumorId ? [REMINDERS_RID, hash(pagination || {})] : undefined;

  const result = useSWR(key, () => getReminders({
    referenceId: rumorId?.toString(),
    referenceType: ReferenceType.RUMOR,
  }, pagination), config);

  return {
    ...result,
    mutate: () => matchMutate(REMINDERS_RID),
  };
}
