import {
  addDays, isFuture, isPast,
} from 'date-fns';
import { formatDate, formatEuro } from '@doveit/hammer';
import { AppointmentStatus, ProspectStatus, ReferenceType } from '../../../domain/types';
import { getContactAppointments } from '../../../providers/api/contact/contactProvider';
import { Agent } from '../../../providers/api/dtos/agent';
import { WhatsappMessage } from '../SendWhatsappAction';
import { Prospect } from '../../../providers/api/dtos/prospect';
import { SortOrder } from '../../../providers/pagination';
import { getLead } from '../../../providers/api/lead/leadProvider';
import { getAgentName } from '../../../agent/utils/utils';

export function prospectMessagesProvider(prospect: Prospect, agent?: Agent): () => Promise<WhatsappMessage[]> {
  return async () => {
    const agentName = agent?.name ? getAgentName(agent) : '<nome agente>';

    const messages: WhatsappMessage[] = [];

    if (prospect.status === ProspectStatus.IN_PROGRESS) {
      const filters = {
        referenceId: prospect.id!,
        referenceType: ReferenceType.PROSPECT,
      };

      const leadAddress = await getLead(prospect.leadId).then((lead) => `${lead.propertyAddress}, ${lead.locality}`);
      const appointments = await getContactAppointments(prospect.contactId, filters, { sort: { createdAt: SortOrder.DESC } });// default 50 appointments

      const isFirstAppointment = !appointments.content.some((appointment) => appointment.status === AppointmentStatus.DONE);

      // prospect does not allow more than one TODO appointment at the same time, so checking only the latest created one is enough
      const appointmentTodo = appointments.content[0].status === AppointmentStatus.TODO ? new Date(appointments.content[0].startDate) : undefined;
      const appointmentCancelled = appointments.content[0].status === AppointmentStatus.CANCELLED ? new Date(appointments.content[0].startDate) : undefined;
      const appointmentDone = appointments.content[0].status === AppointmentStatus.DONE ? new Date(appointments.content[0].startDate) : undefined;

      const incipit = `Salve, sono ${agentName} di Dove.it.`;

      if (isFirstAppointment && appointmentTodo && isFuture(appointmentTodo)) {
        const appointmentDate = `${formatDate(appointmentTodo, { time: true })}`;
        messages.push({
          id: '1',
          title: 'Organizzazione primo appuntamento via whatsapp',
          text: `${incipit} La contatto in merito alla sua richiesta di valutazione dell'immobile situato in ${leadAddress}.<br /><br />Confermo il nostro appuntamento in data ${appointmentDate} presso l'immobile. Per qualsiasi comunicazione in merito all'appuntamento può contattarmi a questo numero.<br /><br />Cordiali saluti`,
        }, {
          id: '2',
          title: 'Organizzazione primo appuntamento via whatsapp con richiesta documenti',
          text: `${incipit} La contatto in merito alla sua richiesta di valutazione dell'immobile situato in ${leadAddress}.<br /><br />Confermo il nostro appuntamento in data ${appointmentDate} presso l'immobile. Le chiedo cortesemente di farmi avere in sede di appuntamento la documentazione dell'immobile in suo possesso (planimetria, atto di provenienza, etc.).<br /><br />Per qualsiasi comunicazione in merito all'appuntamento può contattarmi a questo numero.<br /><br />Cordiali saluti`,
        }, {
          id: '3',
          title: 'Organizzazione primo appuntamento con telefonata',
          text: `${incipit} La contatto in merito alla sua richiesta di valutazione dell'immobile situato in ${leadAddress}.<br /><br />Vorrei farle alcune domande in preparazione al nostro incontro del giorno ${appointmentDate}. Quando sarebbe disponibile per una chiamata telefonica?<br /><br />Cordiali saluti`,
        }, {
          id: '4',
          title: 'Organizzazione primo appuntamento con mancata risposta',
          text: `${incipit} Ho cercato di contattarla telefonicamente in merito alla sua richiesta di valutazione dell'immobile situato in ${leadAddress}.<br /><br />Vorrei farle alcune domande in preparazione al nostro incontro del giorno ${appointmentDate}. Quando sarebbe disponibile per una chiamata telefonica?<br /><br />Cordiali saluti`,
        }, {
          id: '5',
          title: 'Promemoria appuntamento',
          text: `${incipit} Le scrivo per ricordarle l'appuntamento del ${appointmentDate} per la valutazione dell'immobile in ${leadAddress}.<br /><br />Cordiali saluti`,
        });
      }

      if ((!!appointmentTodo && isFuture(appointmentTodo)) || (!!appointmentCancelled && isFuture(appointmentCancelled))) {
        const date = formatDate((appointmentTodo || appointmentCancelled)!, { time: true });
        messages.push(
          {
            id: '6',
            title: 'Annullamento appuntamento generico',
            text: `${incipit} Le scrivo per informarla che sono costretto ad annullare l'appuntamento del ${date} per la valutazione dell'immobile in ${leadAddress} a causa di un imprevisto.<br /><br />Sarà mia premura ricontattarla al più presto per concordare una nuova data. Mi scuso per il disagio e la ringrazio per la comprensione.<br /><br />Cordiali saluti`,
          },
        );
      }

      if (appointmentDone) {
        const twoWeeksAgo = isPast(addDays(appointmentDone, 14));
        messages.push(
          {
            id: '7',
            title: 'Proposta con telefonata',
            text: `${incipit} La contatto in merito alla valutazione dell'immobile situato in ${leadAddress}.<br /><br />Vorrei fissare un secondo incontro per presentarle la nostra valutazione e per darle tutte le informazioni che possano aiutarla a valutare i nostri servizi.<br /><br />Quando sarebbe disponibile per una chiamata telefonica al fine di organizzare l'incontro?<br /><br />Cordiali saluti`,
          },
          {
            id: '8',
            title: 'Richiesta disponibilità',
            text: `${incipit} La contatto in merito alla valutazione dell'immobile situato in ${leadAddress}.<br /><br />Vorrei fissare un secondo incontro per consegnarle e presentarle la nostra valutazione e per darle tutte le informazioni che possano aiutarla a valutare i nostri servizi.<br /><br />Mi farebbe sapere in quali giorni e a che ora sarebbe disponibile per la consegna della valutazione?<br /><br />Cordiali saluti`,
          },
          {
            id: '9',
            title: 'Feedback appuntamento',
            text: `${incipit} La contatto in merito alla valutazione dell'immobile situato in ${leadAddress}.<br /><br />Vorrei chiederle se ha avuto modo di ragionare sulla valutazione proposta e se sia sua intenzione procedere con la vendita dell'immobile. Sono a sua disposizione per qualsiasi informazione o chiarimento che possa aiutarla a valutare i nostri servizi.<br /><br />Cordiali saluti`,
          },
        );
        if (twoWeeksAgo) {
          messages.push({
            id: '10',
            title: 'Feedback appuntamento a lungo termine',
            text: `${incipit} La contatto in merito alla valutazione dell'immobile situato in ${leadAddress}.<br /><br />È passato diverso tempo dal nostro appuntamento e volevo chiederle se ha avuto modo di ragionare sulla valutazione proposta. Sono a sua disposizione per qualsiasi informazione o chiarimento che possa aiutarla a valutare i nostri servizi.<br /><br />Cordiali saluti`,
          });
        }
        if (prospect.agentEvaluation) {
          messages.push({
            id: '11',
            title: 'Immobile già in vendita tramite altra agenzia',
            text: `${incipit} La contatto in merito alla valutazione dell'immobile situato in ${leadAddress}.<br /><br />Vorrei chiederle se la vendita dell'immobile è andata a buon fine. Qualora non lo fosse, le ricordo la nostra valutazione di ${formatEuro(prospect.agentEvaluation)} e sono a sua disposizione per qualsiasi informazione o chiarimento che possa aiutarla a valutare i nostri servizi.<br /><br />Cordiali saluti`,
          });
        }
      }
    }
    return messages;
  };
}
