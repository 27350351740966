import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import {
  ActionIcon, ConfirmModal, ICON_ARCHIVE_SYNC_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { updateProspect } from '../../../providers/api/prospect/prospectProvider';
import { ProspectStatus } from '../../../domain/types';
import { Prospect } from '../../../providers/api/dtos';
import { isArchived } from '../../utils/isArchived';

interface ChildFnProps {
  restore: VoidFunction,
  loading: boolean,
}

export interface RestoreProspectActionProps {
  prospect: Prospect,
  onSuccess?: (prospect: Prospect) => void,
  children?: (props: ChildFnProps) => React.ReactNode
}

export const RESTORE_PROSPECT_WARNING_MESSAGE = 'Confermi di voler ripristinare la valutazione?';
export const RESTORE_PROSPECT_SUCCESS_MESSAGE = 'Valutazione ripristinata con successo';
export const RESTORE_PROSPECT_ERROR_MESSAGE = 'Non è stato possibile ripristinare la valutazione';

const RestoreProspectAction: FunctionComponent<RestoreProspectActionProps> = ({
  prospect,
  onSuccess,
  children,
}) => {
  const restoreModal = useModal();
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const isArchivedProspect = useMemo(
    () => isArchived(prospect.status),
    [prospect],
  );

  const onConfirm = useCallback(async () => {
    setIsSaving(true);

    try {
      const updatedProspect = await updateProspect(
        prospect.id!,
        {
          ...prospect,
          status: ProspectStatus.IN_PROGRESS,
        },
      );

      setIsSaving(false);
      restoreModal.close();
      addSuccess(RESTORE_PROSPECT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedProspect);
      }
    } catch (error) {
      setIsSaving(false);
      addError(RESTORE_PROSPECT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, restoreModal, prospect, onSuccess]);

  if (
    !(isArchivedProspect)
  ) {
    return null;
  }

  return (
    <>
      {children
        ? children({ loading: isSaving, restore: restoreModal.open })
        : (
          <ActionIcon
            label="Ripristina"
            onClick={restoreModal.open}
            icon={{ path: ICON_ARCHIVE_SYNC_OUTLINE }}
          />
        )}
      <Portal>
        <ConfirmModal
          isOpen={restoreModal.isOpen}
          title="Ripristina valutazione"
          onConfirm={onConfirm}
          onAbort={restoreModal.close}
          loading={isSaving}
        >
          {RESTORE_PROSPECT_WARNING_MESSAGE}
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default RestoreProspectAction;
