import React from 'react';
import {
  Action, BreakpointQueryName, FormHandlers, ICON_EYE_OUTLINE, Message, Spacing,
} from '@doveit/bricks';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import PDFViewer from '../../../../../components/pdf-viewer/PDFViewer';
import { AssignmentWizardStepProps } from '../types';
import useAssignmentWizardPDFPreview from '../../../../../prospect/hooks/use-assignment-wizard-pdf-preview/useAssignmentWizardPDFPreview';
import { raise } from '../../../../../utils';
import CardSkeleton from '../../../../../components/card/skeleton/CardSkeleton';

export const NO_SIGNERS_INFO_MESSAGE = 'Non sono presenti firmatari';
export const LOAD_PDF_PREVIEW_ERROR_MESSAGE = "Non è stato possibile caricare l'anteprima del mandato";

const AssignmentWizardManualRequest: React.FC<AssignmentWizardStepProps> = ({
  initialData,
  prospect,
  formRef,
  onSubmit,
  handleStepWorkComplete,
}) => {
  const { id } = prospect;
  const prospectId = id ?? raise('missing prospect id');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  const {
    data: preview,
    isLoading: isPreviewLoading,
    error: previewError,
  } = useAssignmentWizardPDFPreview(prospectId, handleStepWorkComplete);

  const previewUrl = React.useMemo(() => {
    if (!preview) {
      return '';
    }

    return URL.createObjectURL(new Blob([preview], { type: 'application/pdf' }));
  }, [preview]);

  React.useImperativeHandle(formRef, () => ({
    handleSubmit: (_: React.FormEvent<HTMLFormElement>) => {
      onSubmit(initialData, false);
    },
  } as FormHandlers), [initialData, onSubmit]);

  return (
    <div>
      {previewError && (
        <Message
          type="critical"
          message={LOAD_PDF_PREVIEW_ERROR_MESSAGE}
        />
      )}
      {isPreviewLoading && <CardSkeleton aria-label="Anteprima del mandato in caricamento" />}
      {preview && (
        <div aria-label="Anteprima del mandato">
          <strong>Anteprima mandato</strong>

          <Spacing margin={[200, 0, 0, 0]}>

            {(isMobile ? (
              <Action
                label="Visualizza anteprima"
                aria-label="Visualizza anteprima mandato"
                size="S"
                iconLeft={{ path: ICON_EYE_OUTLINE }}
                href={previewUrl}
                target="_blank"
              />
            ) : (
              <PDFViewer
                src={previewUrl}
                title="Anteprima mandato"
                height="50rem"
              />
            ))}
          </Spacing>
        </div>
      )}
    </div>
  );
};

export default AssignmentWizardManualRequest;
