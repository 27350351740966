import useSWR, { SWRResponse, SWRConfiguration } from 'swr';
import { AxiosError } from 'axios';
import { API_OFFERS_PATH } from '../../../providers/api/api';
import { getOfferWizardPreviewPDF } from '../../../providers/api/offer/offerProvider';

export const OFFER_WIZARD_PDF_PREVIEW_RID = `${API_OFFERS_PATH}/wizard/:intentId/preview`;

export function useOfferWizardPDFPreview(
  intentId?: string,
  options?: SWRConfiguration<ArrayBuffer, AxiosError>,
): SWRResponse<ArrayBuffer, AxiosError> {
  const key = intentId ? [OFFER_WIZARD_PDF_PREVIEW_RID, intentId] : undefined;

  return useSWR(key, () => getOfferWizardPreviewPDF(intentId!), options);
}
