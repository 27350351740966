import { Flatten } from '@doveit/bricks';

export const isKeyOf = <T extends object>(obj: T, key: string | number | symbol): key is keyof T => key in obj;

export function flattenObject<TObj extends Record<string, any>>(obj: TObj, prefix: string = ''): Flatten<TObj> {
  return Object.keys(obj).reduce((acc, key) => {
    if (isKeyOf(obj, key)) {
      const value = obj[key];

      const path = `${prefix ? `${prefix}.` : ''}${key}` as string & keyof Flatten<TObj>;

      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        return {
          ...acc,
          ...flattenObject(value, path),
        };
      }

      acc[path] = value;
    }

    return acc;
  }, {} as Flatten<TObj>);
}

export function isEmpty(object:any): boolean {
  return Object.values(object).every((el) => el === undefined || el === null);
}
