/* eslint-disable react/no-unknown-property */
import {
  ActionIcon,
  Card,
  FontWeight,
  HStack,
  ICON_EYE_OUTLINE,
  ModalSize,
  Portal,
  Stack,
  Text,
  useModal,
} from '@doveit/bricks';
import React from 'react';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { DOCUMENTATION_STORAGE_BASE_URL } from '../../../resources/pages/view/constants';
import * as styles from './CataloguePage.style';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { ThemeIcons } from '../../../theme/icons';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';

interface MarketingResource {
  title: string,
  description: string,
  fileName: string,
}

export const marketingResources: {
  title: string,
  description: string,
  fileName: string,
}[] = [{
  title: 'Catalogo materiali promozionali',
  description: 'Scegli e ordina i materiali per promuovere la tua attività sul territorio.',
  fileName: 'Materiali promozionali Dove.it',
},
{
  title: 'Flyer pacchetti extra',
  description: 'Stampa e condividi con i tuoi clienti il volantino di presentazione dei pacchetti extra per chi vende casa.',
  fileName: 'Flyer pacchetti extra',
}];

const CataloguePage: React.FC = () => {
  const isDesktop = useIsDevice('desktop');
  const pdfPreviewModal = useModal<MarketingResource>();
  const onPreviewOpenModal = React.useCallback((resource: MarketingResource) => () => pdfPreviewModal.open(resource), [pdfPreviewModal]);

  return (
    <>
      <GenericPageLayout>
        <GenericPageLayout.Content
          subtitle="In questa sezione troverai il catalogo dei materiali promozionali di Dove.it e le istruzioni per ordinarli."
        >
          <GenericPageLayout.InnerContent>
            {isDesktop && (
              <SimpleTable>
                <SimpleTable.Body>
                  {marketingResources.map((resource) => (
                    <SimpleTable.Row key={resource.title}>

                      <SimpleTable.Cell>
                        <Text.Body fontWeight={FontWeight.REGULAR}>
                          {resource.title}
                        </Text.Body>
                      </SimpleTable.Cell>

                      <SimpleTable.Cell>
                        <Text.Body>
                          {resource.description}
                        </Text.Body>
                      </SimpleTable.Cell>

                      <SimpleTable.Cell>
                        <HStack>
                          <ActionIcon
                            aria-label={`Anteprima ${resource.title}`}
                            label={`Anteprima ${resource.title}`}
                            size="S"
                            onClick={onPreviewOpenModal(resource)}
                            icon={{ path: ICON_EYE_OUTLINE }}
                          />
                          <ActionIcon
                            aria-label={`Scarica ${resource.title}`}
                            label={`Scarica ${resource.title}`}
                            size="S"
                            icon={{ path: ThemeIcons.DOWNLOAD }}
                            href={`${DOCUMENTATION_STORAGE_BASE_URL}${resource.fileName}.pdf`}
                            download
                            target="_blank"
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </SimpleTable.Row>
                  ))}
                </SimpleTable.Body>
              </SimpleTable>
            )}

            {!isDesktop && (
              <Stack gap={150}>
                {marketingResources.map((resource) => (
                  <Card key={resource.title}>
                    <Card.Header
                      title={resource.title}
                      primaryActions={[
                        <ActionIcon
                          aria-label={`Scarica ${resource.title}`}
                          label={`Scarica ${resource.title}`}
                          size="S"
                          icon={{ path: ThemeIcons.DOWNLOAD }}
                          href={`${DOCUMENTATION_STORAGE_BASE_URL}${resource.fileName}.pdf`}
                          download
                          target="_blank"
                        />,
                      ]}
                    />
                    <Card.Content>
                      <Text.Body>
                        {resource.description}
                      </Text.Body>
                    </Card.Content>
                  </Card>
                ))}
              </Stack>
            )}
          </GenericPageLayout.InnerContent>
        </GenericPageLayout.Content>
      </GenericPageLayout>

      <Portal>
        <SimpleModal
          {...pdfPreviewModal}
          size={ModalSize.LARGE}
          title={pdfPreviewModal.data?.title}
          padded={false}
          fillAvailableHeight
        >
          <div
            data-ref="pdf-wrapper"
            css={styles.iframe}
            height="100%"
          >
            <iframe
              src={`${DOCUMENTATION_STORAGE_BASE_URL}${pdfPreviewModal.data?.fileName}.pdf`}
              title={pdfPreviewModal.data?.fileName}
              width="100%"
              height="100%"
            />
          </div>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default CataloguePage;
