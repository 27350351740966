import { Department } from '../../domain/types';
import { FiltersMapper } from '../../hooks/use-filters/useFilters';
import { JobApplicationFilters } from '../types';
import { BooleanString, booleanStringToBool } from '../../utils/boolean-string/booleanString';

export function getJobApplicationFilterMapper(): FiltersMapper<JobApplicationFilters> {
  return {
    department: (value) => ((value && value in Department) ? value as Department : undefined),
    assignee: (value) => (!Number.isNaN(Number(value)) ? Number(value) : undefined),
    province: (value) => value,
    license: (value = '') => booleanStringToBool(value as BooleanString),
  };
}
