import useSWR, { SWRResponse } from 'swr';
import hash from 'object-hash';
import ApplicationNotification from '../../../providers/api/dtos/applicationNotification';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { getApplicationNotifications } from '../../../providers/api/application-notification/applicationNotificationProvider';

export const APPLICATION_NOTIFICATIONS_RID = '/application-notifications';

export function getApplicationNotificationsKey(pagination?: PaginationParams) {
  return [APPLICATION_NOTIFICATIONS_RID, hash({ ...pagination })];
}

export default function useApplicationNotifications(pagination?: PaginationParams): SWRResponse<PaginatedResult<ApplicationNotification>, Error> {
  return useSWR(getApplicationNotificationsKey(pagination), () => getApplicationNotifications(pagination));
}
