/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Card, ActionIcon, HStack, ICON_CHECK_CIRCLE_OUTLINE, ICON_CLOSE_CIRCLE_OUTLINE, ICON_CONTENT_COPY, ICON_PROGRESS_CHECK, ICON_WHATSAPP, Skeleton, Spacing, Icon, useNotifications, Message, CardSkeleton, ICON_ALERT_CIRCLE_OUTLINE, Action, DetailItemList,
} from '@doveit/bricks';
import { formatDate, formatEuro } from '@doveit/hammer';
import {
  CommissionPrice,
  Document,
  Intent, SignatureStatus, Signer, SingleSignatureStatus,
} from '../../../providers/api/dtos';
import useOfferSignatureStatus from '../../../prospect/hooks/use-offer-signature-status/useOfferSignatureStatus';
import { isNotifiedOrAlreadyInitiated } from '../../../utils/digital-signature/signatureStatus';
import { getShortURL } from '../../../providers/rebrandly/rebrandlyProvider';
import { copyToClipboard } from '../../../utils/text/text';
import useOfferWizardData from '../../hooks/use-offer-wizard-data/useOfferWizardData';
import * as styles from './OfferWidget.style';
import useOffersByIntentId from '../../hooks/use-offers-by-intent-id/useOffersByIntentId';
import UpdateOfferStatus from '../update-offer-status/UpdateOfferStatus';
import { MANDATORY_OFFER_DOCUMENT_TYPES, OPTIONAL_OFFER_DOCUMENT_TYPES } from '../../../document/constants';
import { sortDocumentsByLabel } from '../../../utils';
import { useOfferDocuments } from '../../hooks/use-offer-documents/useOfferDocuments';
import ManageDocumentAction from '../../../document/containers/manage-document-action/ManageDocumentAction';
import { DocumentReferenceType } from '../../../domain/types/documentReferenceType';
import { DigitalOfferStatus, DocumentStatus, OfferStatus } from '../../../domain/types';
import { ComputedOfferStatus, useComputedOfferStatus } from '../../hooks/use-computed-offer-status/useComputedOfferStatus';
import PreviewOfferWizardPDFAction from '../preview-offer-wizard-pdf-action/PreviewOfferWizardPDFAction';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';

const signerStatusBadge = (signerStatus: SingleSignatureStatus) => {
  switch (signerStatus) {
    case SingleSignatureStatus.SIGNED: {
      return {
        icon: ICON_CHECK_CIRCLE_OUTLINE,
        color: 'success.default.low',
      } as const;
    }
    case SingleSignatureStatus.NOTIFIED: {
      return {
        icon: ICON_PROGRESS_CHECK,
        color: 'warning.default.low',
      } as const;
    }
    default: {
      return {
        icon: ICON_CLOSE_CIRCLE_OUTLINE,
        color: 'critical.default.low',
      } as const;
    }
  }
};

export const LOAD_OFFER_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare i dati di eventuali proposte di acquisto.';
export const OFFER_WIZARD_DATA_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni della proposta.';
export const COPY_LINK_SUCCESS_MESSAGE = 'Link copiato negli appunti con successo.';
export const COPY_LINK_ERROR_MESSAGE = 'Non è stato possibile copiare il link negli appunti. Prova con il classico copia e incolla.';
export const BASE_MESSAGE = 'Gentile __FIRST_NAME__ __LAST_NAME__, di seguito il link per firmare la proposta d\'acquisto: __SIGNATURE_LINK__';
export const LOAD_OFFER_DOCUMENTS_ERROR_MESSAGE = 'Non è stato possibile caricare i documenti relativi alla proposta';

export interface OfferWidgetProps {
  intentId: NonNullable<Intent['id']>,
  onLoadError?: (message: string) => void,
  onOfferStatusChange?: VoidFunction,
}

const OfferWidget: React.FC<OfferWidgetProps> = ({
  intentId,
  onLoadError,
  onOfferStatusChange,
}) => {
  const { addSuccess, addError } = useNotifications();

  const {
    data: offers,
    isLoading: areOffersLoading,
    error: offersError,
    mutate: mutateOffers,
  } = useOffersByIntentId(intentId, undefined, {
    onError: () => onLoadError?.(OFFER_WIZARD_DATA_LOAD_ERROR_MESSAGE),
  });

  const lastOffer = React.useMemo(() => offers?.[0], [offers]);

  const {
    data: wizardData,
    isLoading: isWizardDataLoading,
    error: wizardDataError,
  } = useOfferWizardData(intentId, {
    onError: (err) => {
      if (err.response?.status !== 404) {
        onLoadError?.(OFFER_WIZARD_DATA_LOAD_ERROR_MESSAGE);
      }
    },
  });

  const {
    data: signatureStatus,
    isLoading: isSignatureStatusLoading,
  } = useOfferSignatureStatus(intentId, {
    refreshInterval: (data) => (data?.signers.every(isNotifiedOrAlreadyInitiated) ? 0 : 1000),
  });

  const {
    data: offerDocuments,
    isLoading: areOfferDocumentsLoading,
    error: offerDocumentsError,
    mutate: mutateOfferDocuments,
  } = useOfferDocuments(lastOffer?.id);

  const {
    data: computedOfferStatus,
    isLoading: isComputedOfferStatusLoading,
    errors: computedOfferStatusError,
    mutate: mutateComputedOfferStatus,
  } = useComputedOfferStatus(intentId, lastOffer);

  const mutateOffersRelatedData = React.useCallback(async () => {
    await mutateOffers();
    await mutateComputedOfferStatus();

    if (onOfferStatusChange) {
      onOfferStatusChange();
    }
  }, [mutateComputedOfferStatus, mutateOffers, onOfferStatusChange]);

  const handleDocumentCreate = React.useCallback(async () => {
    await mutateOfferDocuments();
  }, [mutateOfferDocuments]);

  const handleDocumentUpdate = React.useCallback(async (document: Document) => {
    await mutateOfferDocuments();

    if (document.status === DocumentStatus.APPROVED) {
      await mutateOffers();
      await mutateComputedOfferStatus();
    }
  }, [mutateComputedOfferStatus, mutateOfferDocuments, mutateOffers]);

  const [shortenedLink, setShortenedLink] = React.useState<Map<string, string>>(new Map());

  const onCopyLink = React.useCallback((signer: Signer) => async () => {
    try {
      const shortLink = shortenedLink.get(signer.email) || await getShortURL(signer.signatureLink!);
      setShortenedLink(shortenedLink.set(signer.email, shortLink));
      await copyToClipboard(shortLink);
      addSuccess(COPY_LINK_SUCCESS_MESSAGE);
    } catch (_) {
      addError(COPY_LINK_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, shortenedLink]);

  const buildMessage = React.useCallback((signer: Signer) => async () => {
    const { firstName, lastName, signatureLink } = signer;

    const shortLink = shortenedLink.get(signer.email) || await getShortURL(signatureLink!);
    setShortenedLink(shortenedLink.set(signer.email, shortLink));

    const formattedMessage = BASE_MESSAGE
      .replace('__FIRST_NAME__', firstName)
      .replace('__LAST_NAME__', lastName)
      .replace('__SIGNATURE_LINK__', shortLink);

    return formattedMessage;
  }, [shortenedLink]);

  const commissions = React.useMemo(() => {
    if (!wizardData?.offer?.value) return null;

    return (typeof wizardData?.commissions?.value !== 'undefined' && wizardData?.commissions?.type === CommissionPrice.FIXED)
      ? `${formatEuro(wizardData.commissions.value)} (${((wizardData.commissions.value / wizardData.offer.value) * 100).toFixed(1)}%)`
      : `${wizardData?.commissions?.value}%`;
  }, [wizardData?.commissions?.type, wizardData?.commissions?.value, wizardData?.offer]);

  if ((wizardDataError && wizardDataError.response?.status !== 404) || offersError || computedOfferStatusError) {
    return (
      <Card aria-label="Errore caricamento dati proposta">
        <Card.Header
          title="Proposte"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          color="critical"
        />
        <Card.Content>
          <Message message={LOAD_OFFER_DATA_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  if (isWizardDataLoading || areOffersLoading || isComputedOfferStatusLoading) {
    return <CardSkeleton aria-label="Informazioni in caricamento" />;
  }

  if (computedOfferStatus?.status === DigitalOfferStatus.WAITING_FOR_SIGNATURE) {
    return (
      <Card aria-label="Proposta digitale in firma">
        <Card.Header
          isSecondary
          primaryActions={[(
            <PreviewOfferWizardPDFAction intentId={intentId}>
              {({ onClick, isLoading }) => (
                <Action
                  label="Visualizza anteprima"
                  size="S"
                  aria-label="Visualizza anteprima proposta"
                  loading={isLoading}
                  onClick={onClick}
                />
              )}
            </PreviewOfferWizardPDFAction>
          )]}
        >
          <UpdateOfferStatus
            intentId={intentId}
            onSuccess={mutateOffersRelatedData}
            emphasis="high"
          />
        </Card.Header>
        <Card.Content>
          <DetailItemList columns={2}>
            <DetailItemList.Group>
              <DetailItemList.Item
                label="Data proposta"
                aria-label="offer-date-detail-item"
              >
                {wizardData?.updatedAt && formatDate(new Date(wizardData.updatedAt))}
              </DetailItemList.Item>
              <DetailItemList.Item
                label="Importo"
                aria-label="offer-value-detail-item"
              >
                {wizardData?.offer?.value && formatEuro(wizardData.offer.value)}
              </DetailItemList.Item>
              <DetailItemList.Item
                label="Scadenza accettazione"
                aria-label="offer-valid-until-detail-item"
              >
                {wizardData?.offer?.validUntil && formatDate(new Date(wizardData.offer.validUntil))}
              </DetailItemList.Item>
              <DetailItemList.BooleanItem
                label="Mutuo"
                aria-label="mortgage-detail-item"
                value={wizardData?.mortgage?.mandatoryMortgageAcceptance}
              />
            </DetailItemList.Group>

            {isSignatureStatusLoading && (
              <DetailItemList.Item>
                <Skeleton width="5rem" />
                <Skeleton />
                <Skeleton />
              </DetailItemList.Item>
            )}
            {signatureStatus && (
              <DetailItemList.Group
                label="Firmatari"
                aria-label="Lista firmatari proposta"
              >
                <DetailItemList.Item fullWidth>
                  {signatureStatus.signers.map((signer, index) => {
                    const {
                      email, status, signatureLink, firstName, lastName,
                    } = signer;

                    const signerNotNotifiedYet = status === SingleSignatureStatus.INITIATED && !signatureLink;

                    const { color, icon } = signerStatusBadge(status);

                    return (
                      <div key={email} css={styles.signersWrapper}>
                        <HStack alignItems="center" justifyContent="space-between">
                          <HStack>
                            <Icon
                              path={icon}
                              color={color}
                            />
                            {firstName} {lastName}
                          </HStack>
                          {signatureStatus.status === SignatureStatus.ONGOING && (
                            <HStack>
                              <ActionIcon
                                size="S"
                                icon={{ path: ICON_CONTENT_COPY }}
                                aria-label="Pulsante per copiare il link di firma di YouSign"
                                label="Copia link"
                                onClick={onCopyLink(signer)}
                                loading={signerNotNotifiedYet}
                                disabled={status !== SingleSignatureStatus.NOTIFIED}
                              />
                              <SendWhatsappAction
                                phoneNumber={signer.phoneNumber}
                                name={`${signer.firstName} ${signer.lastName}`}
                                initialMessage={buildMessage(signer)}
                              >
                                {({ onClick, disabled }) => (
                                  <ActionIcon
                                    aria-label="Pulsante per condividere il link di firma di YouSign"
                                    size="S"
                                    icon={{ path: ICON_WHATSAPP }}
                                    label="Condividi link su WhatsApp"
                                    onClick={onClick}
                                    disabled={disabled || status !== SingleSignatureStatus.NOTIFIED}
                                    loading={signerNotNotifiedYet}
                                  />
                                )}
                              </SendWhatsappAction>
                            </HStack>
                          )}
                        </HStack>
                        {index < signatureStatus.signers.length - 1 && (
                          <Spacing
                            margin={[75, 0, 0]}
                            css={styles.signerItemDivider}
                          />
                        )}
                      </div>
                    );
                  })}
                </DetailItemList.Item>
              </DetailItemList.Group>
            )}
          </DetailItemList>
        </Card.Content>
      </Card>
    );
  }

  if (lastOffer && computedOfferStatus) {
    return (
      <Card aria-label="Dettagli proposta">
        <Card.Header isSecondary>
          <UpdateOfferStatus
            intentId={intentId}
            lastOffer={lastOffer}
            onSuccess={mutateOffersRelatedData}
            emphasis="high"
          />
        </Card.Header>
        <Card.Content>
          <DetailItemList columns={2}>
            <DetailItemList.Item
              label="Data proposta"
              aria-label="offer-date-detail-item"
            >
              {lastOffer.createdAt && formatDate(new Date(lastOffer.createdAt))}
            </DetailItemList.Item>
            <DetailItemList.Item
              label="Importo"
              aria-label="offer-value-detail-item"
            >
              {lastOffer.value && formatEuro(lastOffer.value)}
            </DetailItemList.Item>
            {computedOfferStatus.status === OfferStatus.IN_PROGRESS && (
              <DetailItemList.Item
                label="Scadenza accettazione"
                aria-label="offer-valid-until-detail-item"
              >
                {lastOffer.validUntil && formatDate(new Date(lastOffer.validUntil))}
              </DetailItemList.Item>
            )}
            {([OfferStatus.IN_PROGRESS, OfferStatus.WITHDRAWN, OfferStatus.ACCEPTED] as ComputedOfferStatus[]).includes(computedOfferStatus.status) && (
              <DetailItemList.BooleanItem
                label="Mutuo"
                aria-label="mortgage-detail-item"
                value={lastOffer.mortgage !== undefined}
              />
            )}
            {computedOfferStatus.status === OfferStatus.ACCEPTED && (
              <DetailItemList.Item
                label="Scadenza delibera mutuo"
                aria-label="mortgage-max-acceptance-date-detail-item"
              >
                {lastOffer.mortgage?.maxAcceptanceDate && formatDate(new Date(lastOffer.mortgage.maxAcceptanceDate))}
              </DetailItemList.Item>
            )}
            {computedOfferStatus.status === OfferStatus.REFUSED && (
              <DetailItemList.Item
                label="Controproposta"
                aria-label="counter-offer-detail-item"
              >
                {lastOffer.counterOffer && formatEuro(lastOffer.counterOffer)}
              </DetailItemList.Item>
            )}
            {([OfferStatus.IN_PROGRESS, OfferStatus.ACCEPTED] as ComputedOfferStatus[]).includes(computedOfferStatus.status) && (
              <DetailItemList.Item
                label="Data presunta di rogito"
                aria-label="deed-date-detail-item"
              >
                {lastOffer.deed?.date && formatDate(new Date(lastOffer.deed.date))}
              </DetailItemList.Item>
            )}
            {([OfferStatus.CONFIRMED, 'DEED_DONE'] as ComputedOfferStatus[]).includes(computedOfferStatus.status) && (
              <DetailItemList.Item
                label="Data di rogito"
                aria-label="deed-date-detail-item"
              >
                {lastOffer.deed?.date && formatDate(new Date(lastOffer.deed.date))}
              </DetailItemList.Item>
            )}
            {([OfferStatus.IN_PROGRESS, OfferStatus.ACCEPTED, OfferStatus.CONFIRMED, 'DEED_DONE'] as ComputedOfferStatus[]).includes(computedOfferStatus.status) && (
              <DetailItemList.Item
                label="Commissioni venditore"
                aria-label="seller-fee-detail-item"
              >
                {wizardData?.commissions?.noBuyerCommission && commissions}
              </DetailItemList.Item>
            )}
            {([OfferStatus.IN_PROGRESS, OfferStatus.ACCEPTED, OfferStatus.CONFIRMED, 'DEED_DONE'] as ComputedOfferStatus[]).includes(computedOfferStatus.status) && (
              <DetailItemList.Item
                label="Commissioni compratore"
                aria-label="buyer-fee-detail-item"
              >
                {!wizardData?.commissions?.noBuyerCommission && commissions}
              </DetailItemList.Item>
            )}
          </DetailItemList>
        </Card.Content>

        {(offerDocuments || areOfferDocumentsLoading || offerDocumentsError) && (
          <>
            <Card.Divider />
            <Card.Content>
              {areOfferDocumentsLoading && (
                <HStack
                  gap={0}
                  aria-label="Caricamento documenti offerta"
                >
                  <Skeleton width="15rem" />
                  <Skeleton width="10rem" />
                  <Skeleton width="13rem" />
                </HStack>
              )}

              {!areOfferDocumentsLoading && offerDocumentsError && (
                <Message
                  message={LOAD_OFFER_DOCUMENTS_ERROR_MESSAGE}
                  type="critical"
                />
              )}

              {(offerDocuments && !offerDocumentsError) && (
                <DetailItemList
                  columns={2}
                  aria-label="Lista documenti offerta"
                >
                  <DetailItemList.Group label="Obbligatori">
                    {MANDATORY_OFFER_DOCUMENT_TYPES
                      .sort(sortDocumentsByLabel)
                      .map((type) => {
                        const existingDocument = offerDocuments.find((document) => document.type === type);

                        return (
                          <DetailItemList.Item key={type}>
                            <ManageDocumentAction
                              referenceId={existingDocument?.reference.id ?? lastOffer.id!.toString()}
                              referenceType={existingDocument?.reference.type ?? DocumentReferenceType.OFFER}
                              referenceContext={DocumentReferenceType.OFFER}
                              documentType={type}
                              document={existingDocument}
                              onCreate={handleDocumentCreate}
                              onUpdate={handleDocumentUpdate}
                            />
                          </DetailItemList.Item>
                        );
                      })}
                  </DetailItemList.Group>
                  <DetailItemList.Group label="Facoltativi">
                    {OPTIONAL_OFFER_DOCUMENT_TYPES
                      .sort(sortDocumentsByLabel)
                      .map((type) => {
                        const existingDocument = offerDocuments.find((document) => document.type === type);

                        return (
                          <DetailItemList.Item key={type}>
                            <ManageDocumentAction
                              referenceId={existingDocument?.reference.id ?? lastOffer.id!.toString()}
                              referenceType={existingDocument?.reference.type ?? DocumentReferenceType.OFFER}
                              referenceContext={DocumentReferenceType.OFFER}
                              documentType={type}
                              document={existingDocument}
                              onCreate={handleDocumentCreate}
                              onUpdate={handleDocumentUpdate}
                            />
                          </DetailItemList.Item>
                        );
                      })}
                  </DetailItemList.Group>
                </DetailItemList>
              )}
            </Card.Content>
          </>
        )}
      </Card>
    );
  }

  return null;
};

export default OfferWidget;
