/* eslint-disable react/no-unknown-property */
import {
  Action, ActionIcon, Badge, Card, ConfirmModal, FontWeight, Grid, ICON_PLUS, ICON_TRASH_CAN_OUTLINE, ModalSize, Portal, Stack, Text, useModal, useNotifications,
} from '@doveit/bricks';
import React from 'react';
import { formatDate } from '@doveit/hammer';
import objectHash from 'object-hash';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { AdditionalService } from '../../../providers/api/dtos';
import { createAdditionalService, deleteAdditionalService } from '../../../providers/api/additional-service/additionalServiceProvider';
import { additionalServicesLabels } from '../../../labels';
import { optInSourceIcon } from '../../constants';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import SimpleTable from '../../../components/simple-table/SimpleTable';

export const DELETE_SERVICE_SUCCESS_MESSAGE = 'L\'interesse al servizio è stato revocato correttamente.';
export const DELETE_SERVICE_ERROR_MESSAGE = 'Non è stato possibile revocare l\'interesse al servizio.';
export const CREATE_SERVICE_SUCCESS_MESSAGE = 'L\'interesse al servizio è stato aggiunto correttamente.';
export const CREATE_SERVICE_ERROR_MESSAGE = 'Non è stato possibile aggiungere l\'interesse al servizio.';

export type SelectedAdditionalService = {
  additionalService: AdditionalService,
  selected: boolean,
};

type ChildFnProps = {
  openModal: VoidFunction,
};

export interface EditAdditionalServicesActionProps {
  selectedAdditionalServices: SelectedAdditionalService[],
  canDelete?: boolean,
  canAdd?: boolean,
  children?: (props: ChildFnProps) => React.ReactNode;
  onSuccess?: () => void,
}

/**
 * @deprecated Use `UpdateContactAdditionalServicesAction`
 */
const EditAdditionalServicesAction: React.FC<EditAdditionalServicesActionProps> = ({
  selectedAdditionalServices = [],
  canDelete,
  canAdd,
  onSuccess,
  children,
}) => {
  const editAdditionalServiceModal = useModal();
  const removeAdditionalServiceConfirmationModal = useModal<AdditionalService>();
  const addAdditionalServiceConfirmationModal = useModal<AdditionalService | undefined>();
  const isMobile = useIsDevice('mobile');

  const { addSuccess, addError } = useNotifications();

  const sortedAdditionalServices = React.useMemo(
    () => selectedAdditionalServices.sort((a, b) => additionalServicesLabels[a.additionalService.type].localeCompare(additionalServicesLabels[b.additionalService.type], 'it')),
    [selectedAdditionalServices],
  );

  const onEditAdditionalServiceClick = React.useCallback(() => {
    editAdditionalServiceModal.open();
  }, [editAdditionalServiceModal]);

  const onAddClick = React.useCallback((missingAdditionalService: AdditionalService) => () => {
    addAdditionalServiceConfirmationModal.open(missingAdditionalService);
  }, [addAdditionalServiceConfirmationModal]);

  const onAddConfirm = React.useCallback(async () => {
    try {
      await createAdditionalService(addAdditionalServiceConfirmationModal.data!);
      addSuccess(CREATE_SERVICE_SUCCESS_MESSAGE);
      addAdditionalServiceConfirmationModal.close();

      onSuccess?.();
    } catch (err) {
      addError(CREATE_SERVICE_ERROR_MESSAGE);
      addAdditionalServiceConfirmationModal.close();
    }
  }, [addAdditionalServiceConfirmationModal, addError, addSuccess, onSuccess]);

  const onRemoveClick = React.useCallback((additionalService: AdditionalService) => () => {
    removeAdditionalServiceConfirmationModal.open(additionalService);
  }, [removeAdditionalServiceConfirmationModal]);

  const onRemoveConfirm = React.useCallback(async () => {
    try {
      await deleteAdditionalService(removeAdditionalServiceConfirmationModal.data!.id!);
      addSuccess(DELETE_SERVICE_SUCCESS_MESSAGE);
      removeAdditionalServiceConfirmationModal.close();

      onSuccess?.();
    } catch (err) {
      addError(DELETE_SERVICE_ERROR_MESSAGE);
      removeAdditionalServiceConfirmationModal.close();
    }
  }, [addError, addSuccess, onSuccess, removeAdditionalServiceConfirmationModal]);

  return (
    <>
      {children
        ? children({ openModal: onEditAdditionalServiceClick })
        : (
          <Action
            aria-label="Pulsante per modificare i servizi"
            label={selectedAdditionalServices.some(({ selected }) => selected)
              ? 'Visualizza'
              : 'Aggiungi'}
            onClick={onEditAdditionalServiceClick}
            size="S"
          />
        )}
      <Portal>
        <SimpleModal
          {...editAdditionalServiceModal}
          title="Servizi"
          size={ModalSize.MEDIUM}
          aria-label="Modale per modificare i servizi"
        >
          {!isMobile && (
            <SimpleTable>
              <SimpleTable.Header>
                <SimpleTable.HeaderCell> Servizio </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell> Data prestazione
                </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell> Sorgente </SimpleTable.HeaderCell>
                <SimpleTable.HeaderCell />
              </SimpleTable.Header>

              <SimpleTable.Body>
                {sortedAdditionalServices.map(({ additionalService, selected }, i) => (
                  <SimpleTable.Row key={objectHash({ additionalService, i })}>
                    <SimpleTable.Cell>
                      <Text.Body fontWeight={FontWeight.MEDIUM}>
                        {additionalServicesLabels[additionalService.type]}
                      </Text.Body>
                    </SimpleTable.Cell>
                    <SimpleTable.Cell>
                      <Text.Body>{additionalService.updatedAt ? formatDate(new Date(additionalService.updatedAt)) : '-'}</Text.Body>
                    </SimpleTable.Cell>
                    <SimpleTable.Cell>
                      {selected
                        ? (
                          <Badge
                            icon={optInSourceIcon[additionalService.sourceType]}
                            label={additionalService.sourceValue}
                            size="XS"
                          />
                        )
                        : '-'}
                    </SimpleTable.Cell>
                    <SimpleTable.Cell align="right">
                      {canDelete && selected && (
                        <ActionIcon
                          label="Rimuovi"
                          size="S"
                          onClick={onRemoveClick(additionalService)}
                          icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                          aria-label={`Pulsante per rimuovere il servizio (${additionalService.type})`}
                        />
                      )}
                      {canAdd && !selected && (
                        <ActionIcon
                          label="Aggiungi"
                          size="S"
                          onClick={onAddClick(additionalService)}
                          icon={{ path: ICON_PLUS }}
                          aria-label={`Pulsante per aggiungere il servizio (${additionalService.type})`}
                        />
                      )}
                    </SimpleTable.Cell>
                  </SimpleTable.Row>
                ))}
              </SimpleTable.Body>
            </SimpleTable>
          )}
          {isMobile && (
            <Stack gap={150}>
              {sortedAdditionalServices.map(({ additionalService, selected }) => (
                <Card
                  key={additionalService.type}
                  aria-label={`Informazioni sul servizio ${additionalService.type} (Mobile)`}
                >
                  <Card.Header
                    caption="Servizio"
                    title={additionalServicesLabels[additionalService.type]}
                    primaryActions={[
                      (canDelete && selected) && (
                        <ActionIcon
                          label="Rimuovi"
                          size="S"
                          onClick={onRemoveClick(additionalService)}
                          icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                          aria-label={`Pulsante per rimuovere il servizio (${additionalService.type})`}
                        />
                      ),
                      (canAdd && !selected) && (
                        <ActionIcon
                          label="Aggiungi"
                          size="S"
                          onClick={onAddClick(additionalService)}
                          icon={{ path: ICON_PLUS }}
                          aria-label={`Pulsante per aggiungere il servizio (${additionalService.type})`}
                        />
                      ),
                    ]}
                  />
                  {selected && (
                    <Card.Content>
                      <Grid>
                        <Grid.Unit
                          size={{ XS: 1 / 2 }}
                        >
                          <Stack>
                            <Text.Mini transform="uppercase">
                              Data prestazione
                            </Text.Mini>
                            <Text.Body>
                              {additionalService.updatedAt ? formatDate(new Date(additionalService.updatedAt)) : '-'}
                            </Text.Body>
                          </Stack>
                        </Grid.Unit>
                        <Grid.Unit size={{ XS: 1 / 2 }}>
                          <Stack>
                            <Text.Mini>
                              Sorgente
                            </Text.Mini>
                            <div>
                              <Badge
                                icon={optInSourceIcon[additionalService.sourceType]}
                                label={additionalService.sourceValue}
                                size="XS"
                              />
                            </div>
                          </Stack>
                        </Grid.Unit>
                      </Grid>
                    </Card.Content>
                  )}
                </Card>
              ))}
            </Stack>
          )}
        </SimpleModal>
        <ConfirmModal
          isOpen={removeAdditionalServiceConfirmationModal.isOpen}
          title="Conferma rimozione servizio"
          onConfirm={onRemoveConfirm}
          onAbort={removeAdditionalServiceConfirmationModal.close}
          aria-label="Modale per confermare la rimozione di un servizio"
        >
          Cliccando sul pulsante Conferma rimuoverai l&apos;interesse al servizio
        </ConfirmModal>
        <ConfirmModal
          isOpen={addAdditionalServiceConfirmationModal.isOpen}
          title="Conferma aggiunta servizio"
          onConfirm={onAddConfirm}
          onAbort={addAdditionalServiceConfirmationModal.close}
          aria-label="Modale per confermare l'aggiunta di un servizio"
        >
          {canDelete
            ? 'Cliccando sul pulsante Conferma aggiungerai l\'interesse al servizio.'
            : 'Una volta confermata l\'aggiunta dell\'interesse al servizio non sarà possibile rimuoverlo.'}
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default EditAdditionalServicesAction;
