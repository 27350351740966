import {
  ThemedFunctor, SpacingName, getSpacing,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const wrapper: ThemedFunctor = ({
  theme,
  width,
  $fitMaxAvailableHeight,
}) => css`
  ${getSpacing.padding(theme, [0, SpacingName.SMALL])}
  max-width: ${width};
  margin: 0 auto;

  ${$fitMaxAvailableHeight && `
    height: 100%;
  `}
`;
