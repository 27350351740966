import { Text, View } from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { EnergyClass } from '../../../../domain/types';
import * as styles from './EnergySection.style';

interface EnergySectionProps {
  type: EnergyClass;
  value: string;
}

export const EnergySection: FunctionComponent<EnergySectionProps> = (props) => {
  const {
    type,
    value,
  } = props;

  const theme = useTheme();

  return (
    <View style={styles.wrapper as any}>
      <View style={styles.box}>
        <View style={styles.tag(theme, type)}>
          <Text style={styles.tagText as any}>
            Classe
            {' '}
            {type}
          </Text>
          <View style={styles.arrow(theme, type)} />
        </View>
      </View>
      <View style={styles.box}>
        <Text style={styles.label as any}>Indice prestazione energetica</Text>
        <Text style={styles.value}>{value}</Text>
      </View>
    </View>
  );
};
