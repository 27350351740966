import React from 'react';
import OfferWizardCommissionsForm, { OfferWizardCommissionsFormModel } from './offer-wizard-commissions-form/OfferWizardCommissionsForm';
import { OfferWizardStepProps } from '../types';
import { toOfferWizardCommissionsFormModel, toOfferWizardData } from './mappers';

const OfferWizardCommissions: React.FC<OfferWizardStepProps> = ({
  initialData,
  formRef,
  loading = false,
  onSubmit,
}) => {
  const handleFormSubmit = React.useCallback((formValues: OfferWizardCommissionsFormModel) => {
    const values = toOfferWizardData(initialData, formValues);

    const isDirty = (formRef?.current as any)?.dirty ?? false;

    onSubmit(values, isDirty);
  }, [formRef, initialData, onSubmit]);

  return (
    <OfferWizardCommissionsForm
      initialValues={toOfferWizardCommissionsFormModel(initialData)}
      innerRef={formRef}
      onSubmit={handleFormSubmit}
      loading={loading}
    />
  );
};

export default OfferWizardCommissions;
