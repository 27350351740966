/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as styles from './SimpleTableRow.style';
import { SimpleTableCell } from '../cell/SimpleTableCell';

export interface SimpleTableRowProps extends React.PropsWithChildren, React.AriaAttributes {
  asChild?: boolean,
}

export const SimpleTableRow: React.FC<SimpleTableRowProps> = ({
  children,
  asChild = false,
  ...rest
}) => {
  if (asChild) {
    return children;
  }

  React.Children.forEach(children as any, (child, i) => {
    if (!child) {
      return null;
    }

    if (![React.Fragment, SimpleTableCell].includes(child.type)) {
      throw new Error(`The ${i}-th component child is neither a SimpleTable.Cell component nor a React.Fragment`);
    }

    return null;
  });

  return (
    <tr
      aria-label="simple-table-row"
      css={styles.row}
      {...rest}
    >
      {children}
    </tr>
  );
};
