import React from 'react';
import {
  Action, Form, FormActionsAlign, useNotifications,
} from '@doveit/bricks';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { downloadFile } from '../../../utils/file/file';
import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import { AgentStatus } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';
import { generateAgentBrochure } from '../../../providers/api/agent/agentProvider';
import { Agent } from '../../../providers/api/dtos';
import generateAgentBrochureValidationSchema from './GenerateAgentBrochurePage.schema';
import { getAgentName } from '../../../agent/utils/utils';

export const GENERATE_AGENT_BROCHURE_ERROR_MESSAGE = "Non è stato possibile generare la brochure dell'agente";
export const GENERATE_AGENT_BROCHURE_SUCCESS_MESSAGE = "La brochure dell'agente è stata generata con successo";

const GenerateAgentBrochurePage: React.FC = () => {
  const {
    userIsAdmin, userIsContentEditor, userIsAgent, userIsAreaManager, userIsCoordinator,
  } = useRBAC();
  const currentAgent = useCurrentAgent();
  const { addError, addSuccess } = useNotifications();
  const [isGenerating, setIsGenerating] = React.useState(false);

  const canFilter = React.useMemo(
    () => userIsAdmin
      || userIsContentEditor
      || userIsAreaManager
      || userIsCoordinator
      || (currentAgent?.canManage?.length ?? 0) > 0,
    [currentAgent?.canManage?.length, userIsAdmin, userIsContentEditor, userIsAreaManager, userIsCoordinator],
  );

  const coordinatedBy = React.useMemo(
    () => (userIsCoordinator ? currentAgent?.email.work : undefined),
    [currentAgent?.email.work, userIsCoordinator],
  );

  const autocompleteStartFrom = React.useMemo(
    () => (userIsCoordinator ? undefined : currentAgent?.email.work),
    [currentAgent?.email.work, userIsCoordinator],
  );

  const initialValues: { agent: Agent | undefined } = React.useMemo(() => ({
    agent: currentAgent,
  }), [currentAgent]);

  const onSubmit = React.useCallback(async (selectedAgent: { agent?: Agent }) => {
    const { agent } = selectedAgent;
    if (!agent) return;
    try {
      setIsGenerating(true);

      const generatedShowcaseData = await generateAgentBrochure(agent.id!);

      downloadFile(generatedShowcaseData, 'application/pdf', `Brochure Agente ${getAgentName(agent, true)}`);

      setIsGenerating(false);
      addSuccess(GENERATE_AGENT_BROCHURE_SUCCESS_MESSAGE);
    } catch (_) {
      setIsGenerating(false);
      addError(GENERATE_AGENT_BROCHURE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content title="Genera brochure agente">
        <GenericPageLayout.InnerContent>
          {canFilter && (
            <Form
              initialValues={initialValues}
              aria-label="generate-agent-brochure-form"
              validationSchema={generateAgentBrochureValidationSchema}
              loading={isGenerating}
              onSubmit={onSubmit}
            >
              <Form.Item>
                <AgentAutoComplete
                  label="Agente"
                  name="agent"
                  aria-label="Campo per specificare l'agente"
                  placeholder="Cerca per nome"
                  status={AgentStatus.ACTIVE}
                  startFrom={autocompleteStartFrom}
                  coordinatedBy={coordinatedBy}
                />
              </Form.Item>
              <Form.Actions align={FormActionsAlign.RIGHT}>
                <Form.Submit
                  label="Scarica brochure"
                  aria-label="Scarica brochure dell'agente selezionato"
                />
              </Form.Actions>
            </Form>
          )}
          {userIsAgent && !canFilter && currentAgent && (
            <Action
              label="Scarica la tua brochure"
              aria-label="Scarica brochure dell'agente corrente"
              onClick={() => onSubmit({ agent: currentAgent })}
              loading={isGenerating}
              color="primary"
              emphasis="high"
            />
          )}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default GenerateAgentBrochurePage;
