import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const bar: ThemedFunctor = ({
  theme,
}) => css`
  padding: ${theme.remSize[200]} 0;
  background-color: ${theme.color.background.neutral.default.lowest};
  flex-shrink: 0;
  position: relative;
  border-top: 1px solid ${theme.palettes.neutrals.light};
  border-bottom: 1px solid ${theme.palettes.neutrals.light};
  margin-top: -1px;
  /** to show box-shadow over the content */
  z-index: ${theme.zIndex.navbar};

  &:hover {
    /**
      The element must have a z-index that:
      - Exceeds the main navigation bar's z-index (6003) to ensure tooltips or floating elements display correctly.
      - Remains lower than the z-index of the side navigation.

      But, keep in mind that the main navigation should always have a higher z-index than the side navigation.

      This is why the z-index for this element dynamically changes on hover
    */
    z-index: ${theme.zIndex.navbar + 4};
  }
`;
