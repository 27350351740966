/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Action, Form, FormActions, Grid, Modal, ModalSize, Portal, Stack, Text, useModal, useNotifications,
} from '@doveit/bricks';
import validationSchema from './SendConversationMessage.schema';
import { createConversationMessage } from '../../../providers/api/conversation/conversationProvider';
import { Conversation } from '../../../providers/api/dtos';
import useConversationTemplates from '../../hooks/use-conversation-templates/useConversationTemplates';
import * as styles from './SendConversationMessage.style';
import { WhatsAppTemplate } from '../../../types';

export const DEFAULT_ERROR_MESSAGE = 'Errore durante l\'invio del messaggio al contatto';
export const CONVERSATIONS_TEMPLATES_LOAD_ERROR_MESSAGE = 'Errore durante il caricamento dei messaggi predefiniti. Riprova più tardi.';

export interface SendConversationMessageProps {
  conversation: Conversation,
  onSuccess?: VoidFunction,
  onError?: (errorMessage: string) => void,
}

export interface SendConversationMessageFormModel {
  message: string,
}

const SendConversationMessage: React.FC<SendConversationMessageProps> = ({
  conversation,
  onSuccess,
  onError,
}) => {
  const {
    id, expired, lastMessage: { body }, contact: { name },
  } = conversation;

  const [isSaving, setIsSaving] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState<{ temp: string, confirmed: string }>({ temp: '', confirmed: '' });
  const templateMessageModal = useModal();
  const { addError } = useNotifications();
  const { data: templates, error: templatesError, mutate: mutateTemplates } = useConversationTemplates({ includeNames: [WhatsAppTemplate.RECONTACT_PROPERTY_EVALUATION, WhatsAppTemplate.MISSED_CALL] });

  const waitingForUserResponse = React.useMemo(() => (templates || []).some(({ content }) => body === content), [body, templates]);

  const initialValues: SendConversationMessageFormModel = React.useMemo(() => {
    if (waitingForUserResponse) {
      return { message: `In attesa di risposta da parte di ${name}` };
    }

    if (selectedTemplate) {
      return { message: selectedTemplate?.confirmed || selectedTemplate.temp };
    }

    return { message: '' };
  }, [name, selectedTemplate, waitingForUserResponse]);

  const restoreConfirmedTemplate = React.useCallback(() => {
    setSelectedTemplate((value) => ({ ...value, temp: value.confirmed }));
    templateMessageModal.close();
  }, [templateMessageModal]);

  const confirmTemplate = React.useCallback(() => {
    setSelectedTemplate((value) => ({ ...value, confirmed: value.temp }));
    templateMessageModal.close();
  }, [templateMessageModal]);

  const onMessageTemplateInputClick = React.useCallback(async () => {
    if (!templates && templatesError) {
      addError(CONVERSATIONS_TEMPLATES_LOAD_ERROR_MESSAGE);

      await mutateTemplates();
      return;
    }

    templateMessageModal.open();
  }, [addError, mutateTemplates, templateMessageModal, templates, templatesError]);

  const onTemplateSelected = React.useCallback((content: string) => () => {
    setSelectedTemplate((value) => ({ ...value, temp: content }));
  }, []);

  const onSubmit = React.useCallback(async (values: SendConversationMessageFormModel, { resetForm }: FormActions<SendConversationMessageFormModel>) => {
    setIsSaving(true);

    try {
      await createConversationMessage(id, values.message);
      setIsSaving(false);
      resetForm();

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      setIsSaving(false);
      if (onError) {
        onError(DEFAULT_ERROR_MESSAGE);
      }
    }
  }, [id, onError, onSuccess]);

  return (
    <>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        loading={isSaving}
        onSubmit={onSubmit}
        compact
        enableReinitialize
      >
        <Form.Item>
          <Grid gutter={150}>
            <Grid.Unit size={{ SM: 3 / 4 }}>
              <Form.TextArea
                name="message"
                placeholder={expired ? 'Richiedi contatto per...' : 'Scrivi un messaggio'}
                aria-label={expired ? 'Campo per selezionare un messaggio predefinito di WhatsApp' : 'Campo per inserire il messaggio Whatsapp da inviare al cliente'}
                rows={1}
                disabled={waitingForUserResponse}
                style={{ resize: 'none' }}
                onClick={expired ? onMessageTemplateInputClick : undefined}
              />
            </Grid.Unit>
            <Grid.Unit size={{ SM: 1 / 4 }}>
              <Form.Submit
                label="Invia"
                expanded
                disabled={waitingForUserResponse}
              />
            </Grid.Unit>
          </Grid>
        </Form.Item>
      </Form>
      <Portal>
        <Modal
          open={templateMessageModal.isOpen}
          size={ModalSize.MEDIUM}
          onCloseHandler={restoreConfirmedTemplate}
        >
          <Modal.Close />
          <Modal.Header>
            <Text.H4>
              Seleziona il template
            </Text.H4>
          </Modal.Header>
          <Modal.Body padded>
            <Stack>
              {templates?.map(({ sid, content }) => (
                <div
                  key={sid}
                  onClick={onTemplateSelected(content)}
                  css={styles.template}
                  $selected={selectedTemplate?.temp === content}
                  aria-label="Messaggio predefinito"
                >
                  {content}
                </div>
              ))}
            </Stack>
          </Modal.Body>
          <Modal.Footer padded>
            <Action
              label="OK"
              onClick={confirmTemplate}
              color="primary"
              emphasis="high"
              disabled={!selectedTemplate}
            />
          </Modal.Footer>
        </Modal>
      </Portal>
    </>
  );
};

export default SendConversationMessage;
