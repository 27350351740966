import { formatMeters } from '@doveit/hammer';
import { CompositeGeoSearchCriterion, GeoSearchCriterionType } from '../../providers/api/dtos/searchCriteria';

export function getGeoCriterionLabel(geo: CompositeGeoSearchCriterion) {
  if (geo.type === GeoSearchCriterionType.POINT) {
    return `${geo.address} (+${formatMeters(geo.radius)})`;
  }

  if (geo.type === GeoSearchCriterionType.AREA) {
    return `${geo.locality} - ${geo.name}`;
  }

  return geo.name;
}
