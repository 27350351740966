import React from 'react';
import {
  Grid, Spacing, Message, ICON_FILE_DOCUMENT_MULTIPLE_OUTLINE, HStack, DetailItemList, CardSkeleton, Text,
  Card,
  FontWeight,
  ICON_ALERT_CIRCLE_OUTLINE,
  Link,
  Icon,
} from '@doveit/bricks';
import { Document, Rumor } from '../../../providers/api/dtos';
import { OPTIONAL_RUMOR_DOCUMENT_TYPES } from '../../../document/constants';
import { DocumentReferenceType } from '../../../domain/types/documentReferenceType';
import ManageDocumentAction from '../../../document/containers/manage-document-action/ManageDocumentAction';
import { sortDocumentsByLabel } from '../../../utils';
import { useRumorDocuments } from '../../hooks/use-rumor-documents/useRumorDocuments';
import { RumorDocumentTypeValue } from '../../../domain/types';
import * as styles from './RumorDocumentsWidget.style';
import { ThemeIcons } from '../../../theme/icons';

export interface RumorDocumentsWidgetProps {
  rumorId: NonNullable<Rumor['id']>,
  onDocumentUpserted?: (document: Document) => void,
  canManage?: boolean
}

export const RUMOR_DOCUMENTS_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare i documenti della notizia.';
export const RUMOR_DOCUMENTS_REJECTED_ERROR_MESSAGE = 'È necessario correggere alcuni documenti relativi alla notizia.';
export const RUMOR_DOCUMENTS_REVIEW_INFO_MESSAGE = 'Hai dei documenti da revisionare.';
export const RUMOR_DOCUMENT_ADD_ERROR_MESSAGE = 'Non è possibile aggiungere il documento.';

const RumorDocumentsWidget: React.FC<RumorDocumentsWidgetProps> = ({
  rumorId,
  onDocumentUpserted,
  canManage = true,
}) => {
  const {
    data: rumorDocuments = [],
    isLoading: areRumorDocumentsLoading,
    error: rumorDocumentsError,
    mutate: mutateRumorDocuments,
  } = useRumorDocuments(rumorId);

  const onUpsertRumorDocument = React.useCallback(async (document: Document) => {
    await mutateRumorDocuments();

    onDocumentUpserted?.(document);
  }, [mutateRumorDocuments, onDocumentUpserted]);

  if (rumorDocumentsError) {
    return (
      <Card aria-label="Errore nel caricamento dei documenti della notizia">
        <Card.Header
          title="Documenti"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          color="critical"
        />
        <Card.Content>
          <Message message={RUMOR_DOCUMENTS_LOAD_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  if (areRumorDocumentsLoading) {
    return <CardSkeleton aria-label="Lista documenti della notizia in caricamento" />;
  }

  return (
    <Card>
      <Card.Header
        title="Documenti"
        icon={{ path: ICON_FILE_DOCUMENT_MULTIPLE_OUTLINE }}
      />

      <Card.Content>
        <Grid gutter={150}>
          <Grid.Unit aria-label="Lista documenti facoltativi">
            <Text.Body fontWeight={FontWeight.MEDIUM}>Facoltativi</Text.Body>
            <Spacing margin={[100, 0, 0]}>
              <DetailItemList columns={3}>
                {OPTIONAL_RUMOR_DOCUMENT_TYPES
                  .sort(sortDocumentsByLabel)
                  .map((type) => {
                    const existingDocument = rumorDocuments.find((document) => document.type === type);

                    return (
                      <DetailItemList.Item
                        key={type}
                        aria-label={`document-row-${type}`}
                      >
                        <HStack>
                          <ManageDocumentAction
                            referenceId={rumorId.toString()}
                            referenceType={DocumentReferenceType.RUMOR}
                            documentType={type}
                            document={existingDocument}
                            onCreate={onUpsertRumorDocument}
                            onUpdate={onUpsertRumorDocument}
                            canManage={canManage}
                          />
                          {!existingDocument?.files.length && type === RumorDocumentTypeValue.CADASTRAL_SURVEY && (
                          <Link
                            href="https://app.youdomus.it/Prodotto/Richiedi/342"
                            target="_blank"
                            aria-label="document-row-link"
                            css={styles.youdomusIcon}
                          >
                            <Icon
                              path={ThemeIcons.YOUDOMUS}
                              size={20}
                            />
                          </Link>
                          )}
                        </HStack>
                      </DetailItemList.Item>
                    );
                  })}
              </DetailItemList>
            </Spacing>
          </Grid.Unit>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default RumorDocumentsWidget;
