import * as Yup from 'yup';
import {
  endOfDay, isBefore, isAfter, startOfDay,
} from 'date-fns';
import { OfferDeedStatus } from '../../../domain/types';

export const offerDeedFormValidationSchema = Yup.object({
  date: Yup
    .string()
    .required('Il campo è obbligatorio'),
  status: Yup
    .mixed<OfferDeedStatus>()
    .oneOf(Object.values(OfferDeedStatus))
    .required('Il campo è obbligatorio'),
}).required().test({
  test: (values: any) => {
    const { date, status } = values;
    const datetime = new Date(date);
    switch (status) {
      case OfferDeedStatus.VAGUE:
      case OfferDeedStatus.EXACT:
        return isBefore(endOfDay(datetime), new Date())
          ? new Yup.ValidationError(
            'La data deve essere nel futuro',
            datetime,
            'date',
          )
          : true;
      case OfferDeedStatus.CONFIRMED:
        return isAfter(startOfDay(datetime), new Date())
          ? new Yup.ValidationError(
            'La data non può essere una data futura',
            datetime,
            'date',
          )
          : true;
      default:
        return true;
    }
  },
});
