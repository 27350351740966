import React, { FunctionComponent, useMemo } from 'react';
import {
  Form, FormActionsAlign, FormConfirmProps, Message, Action,
  HStack,
} from '@doveit/bricks';
import validationSchema from './EditProspectForm.schema';
import { ExtraAction } from '../../../types';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';
import { AgentStatus } from '../../../domain/types';
import { Agent } from '../../../providers/api/dtos';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { prospectSellabilityLabels, prospectWillingnessLabels } from '../../../labels';

export interface EditProspectFormModel {
  agentEvaluation?: string,
  ownerEvaluation?: string,
  sellability?: string,
  willingness?: string,
  agent?: Agent,
}

export interface EditProspectFormPros {
  initialValues?: Partial<EditProspectFormModel>,
  disabled?: boolean,
  loading?: boolean,
  extraActions?: ExtraAction[],
  confirmConfig?: FormConfirmProps,
  disableAgentEvaluation?: boolean,
  onSubmit(values: EditProspectFormModel): void,
}

const DEFAULT_INITIAL_VALUES: EditProspectFormModel = {
  willingness: '3',
  sellability: '3',
};

const EditProspectForm: FunctionComponent<EditProspectFormPros> = ({
  onSubmit,
  loading,
  initialValues,
  extraActions,
  confirmConfig,
  disableAgentEvaluation,
}) => {
  const initialValuesMerged: EditProspectFormModel = useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...(initialValues || {}),
  }), [initialValues]);

  return (
    <Form
      initialValues={initialValuesMerged}
      onSubmit={onSubmit}
      loading={loading}
      validationSchema={validationSchema}
      preventEnterKeypress
    >
      {disableAgentEvaluation && (
        <Form.Item size={{ MD: 1 }}>
          <Message
            type="warning"
            message="La valutazione è stata approvata e non è più modificabile"
          />
        </Form.Item>
      )}
      <Form.Fieldset legend="Valutazioni">
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Input
            name="agentEvaluation"
            label="Val. agente (€)"
            aria-label="Campo per inserire la valutazione dell\'agente"
            placeholder="Es: 150000"
            type="text"
            min="0"
            disabled={disableAgentEvaluation}
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Input
            name="ownerEvaluation"
            label="Richiesta (€)"
            aria-label="Campo per inserire la richiesta del proprietario"
            placeholder="Es: 150000"
            type="text"
          />
        </Form.Item>
      </Form.Fieldset>
      <Form.Fieldset legend="Agente">
        <Form.Item size={{ MD: 1 / 2 }}>
          <AgentAutoComplete
            name="agent"
            aria-label="Campo per specificare l'agente incaricato"
            placeholder="Cerca per nome"
            status={AgentStatus.ACTIVE}
          />
        </Form.Item>
      </Form.Fieldset>
      <Form.Fieldset legend="Commerciabilità">
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Select
            name="willingness"
            label="Propensione"
            aria-label="Campo per selezionare la propensione alla vendita"
            options={asSelectOptions(prospectWillingnessLabels, false)}
          />
        </Form.Item>
        <Form.Item size={{ MD: 1 / 2 }}>
          <Form.Select
            name="sellability"
            label="Vendibilità"
            aria-label="Campo per selezionare la vendibilità"
            options={asSelectOptions(prospectSellabilityLabels, false)}
          />
        </Form.Item>
      </Form.Fieldset>
      <Form.Actions align={FormActionsAlign.RIGHT}>
        <HStack>
          <Form.Submit
            label="Salva"
            aria-label="Salva i dati della valutazione"
          />
          {extraActions && extraActions.map((action) => (
            <Action
              key={action.id}
              {...action}
            />
          ))}
        </HStack>
      </Form.Actions>
      {confirmConfig && (
        <Form.Confirm
          {...confirmConfig}
        />
      )}
    </Form>
  );
};

export default EditProspectForm;
