import React from 'react';
import {
  Action, ModalSize, Portal, useModal,
} from '@doveit/bricks';
import { Property, Review } from '../../../providers/api/dtos';
import useReviewsByPropertyId from '../../../review/hooks/use-reviews-by-property-id/useReviewsByPropertyId';
import { usePropertySellers } from '../../hooks/use-property-sellers/usePropertySellers';
import { usePropertyBuyer } from '../../hooks/use-property-buyer/usePropertyBuyer';
import { REVIEW_USER_TYPES } from '../../../domain/types';
import SendReviewRequests, { SendReviewRequestProps } from '../../../review/containers/send-review-requests/SendReviewRequests';
import SimpleModal from '../../../components/simple-modal/SimpleModal';

type ChildFnProps = {
  request: VoidFunction,
};

export interface RequestPropertyReviewsActionProps {
  property: Property,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (requestedReview: Review) => void,
}

const RequestPropertyReviewsAction: React.FC<RequestPropertyReviewsActionProps> = ({
  property,
  children,
  onSuccess,
}) => {
  const modal = useModal();

  const {
    data: reviews,
    isLoading: areReviewsLoading,
    error: reviewsError,
    mutate: mutateReviews,
  } = useReviewsByPropertyId(property.id);

  const { data: sellers } = usePropertySellers(property.id);
  const { data: buyer } = usePropertyBuyer(property.id);
  const seller = sellers?.[0];

  const canSendReviewRequests = reviews?.length !== REVIEW_USER_TYPES.length;

  const initialValues = React.useMemo(() => {
    const values: SendReviewRequestProps['initialValues'] = {};

    if (seller) {
      values.seller = {
        userName: seller.name || '',
        userEmail: seller.email || '',
      };
    }

    if (buyer) {
      values.buyer = {
        userName: buyer.name || '',
        userEmail: buyer.email || '',
      };
    }

    return values;
  }, [buyer, seller]);

  const onReviewRequested = React.useCallback(async (review: Review) => {
    await mutateReviews();

    onSuccess?.(review);
  }, [mutateReviews, onSuccess]);

  if (areReviewsLoading || reviewsError || !canSendReviewRequests) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          request: modal.open,
        })
        : (
          <Action
            label="Richiedi recensioni"
            aria-label="Richiedi recensioni"
            size="S"
            onClick={() => modal.open()}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Richiedi recensioni"
          aria-label="Modale per richiedere recensioni"
          size={ModalSize.LARGE}
        >
          <SendReviewRequests
            propertyId={property.id!}
            initialValues={initialValues}
            reviewsAlreadySent={reviews}
            onSuccess={onReviewRequested}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default RequestPropertyReviewsAction;
