import {
  ActionIcon, DetailItemList, HStack, ICON_ACCOUNT_OUTLINE, ICON_EMAIL_OUTLINE, ICON_EYE_OUTLINE, ICON_PHONE_OUTLINE, Spacing,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/card/Card';
import UserActions from '../../../components/user-actions/UserActions';
import { Contact } from '../../../providers/api/dtos/contact';
import ProcessContact from '../process-contact/ProcessContact';
import useRBAC from '../../../hooks/use-rbac/useRBAC';

export interface SearchContactCardProps {
  contact: Contact,
}

const SearchContactCard: FunctionComponent<SearchContactCardProps> = ({
  contact,
}) => {
  const navigate = useNavigate();
  const { userIsAdmin, userIsCallCenter } = useRBAC();

  const showProcessAction = React.useMemo(() => (userIsAdmin || userIsCallCenter), [userIsAdmin, userIsCallCenter]);

  const goToDetailPage = useCallback(
    () => navigate(`/contacts/${contact.id}`),
    [navigate, contact],
  );

  return (
    <Card key={contact.id}>
      <Card.Inline>
        <Card.Box aria-label="Informazioni del contatto">
          <DetailItemList>
            <DetailItemList.Item
              icon={ICON_ACCOUNT_OUTLINE}
            >
              {contact.name}
            </DetailItemList.Item>
            <DetailItemList.Item
              icon={ICON_PHONE_OUTLINE}
            >
              {contact.phoneNumber}
            </DetailItemList.Item>
            <DetailItemList.Item
              icon={ICON_EMAIL_OUTLINE}
            >
              {contact.email}
            </DetailItemList.Item>
          </DetailItemList>
          <Spacing margin={[100, 0, 0]}>
            <UserActions user={contact} />
          </Spacing>
        </Card.Box>

        <Card.ColumnDivider />

        <Card.Box fit>
          <HStack>
            <ActionIcon
              icon={{ path: ICON_EYE_OUTLINE }}
              label="Visualizza"
              aria-label="Visualizza dettagli contatto"
              onClick={goToDetailPage}
            />
            {showProcessAction && (
              <ProcessContact
                contact={contact}
              />
            )}
          </HStack>
        </Card.Box>
      </Card.Inline>
    </Card>
  );
};

export default SearchContactCard;
