import { View, Image } from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';

import * as styles from './Gallery.style';

export interface GalleryProps {
  images: string[];
}

export const Gallery: FunctionComponent<GalleryProps> = ({
  images,
}) => (
  <View style={styles.wrapper as any}>
    {
      images.map((image) => (
        <View
          key={image}
          style={styles.box}
        >
          <Image style={styles.image} src={image} />
        </View>
      ))
    }
  </View>
);
