import React from 'react';
import {
  Action,
  ConfirmModal,
  FormHandlers,
  Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { ShootingStatus } from '../../../domain/types';
import { Shooting } from '../../../providers/api/dtos';
import { updateShooting } from '../../../providers/api/shooting/shootingProvider';
import { useComputedShootingStatus } from '../../hooks/use-computed-shooting-status/useComputedShootingStatus';
import UpsertShootingForm, { UpsertShootingFormModel } from '../../components/upsert-shooting-form/UpsertShootingForm';
import { shootingToUpsertShootingFormModel, upsertShootingFormModelToShooting } from '../../utils';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { upsertShootingValidationSchema } from '../../components/upsert-shooting-form/UpsertShootingForm.schema';

interface ChildFnProps {
  isSaving: boolean,
  markAsDelivered: VoidFunction,
}

export interface MarkShootingAsDeliveredActionProps extends React.AriaAttributes {
  shooting: Shooting,
  onSuccess?: (shooting: Shooting) => void,
  children?: (props: ChildFnProps) => React.ReactNode
}

export const MARK_SHOOTING_AS_DELIVERED_ERROR_MESSAGE = 'Non è stato possibile segnare lo shooting come consegnato.';
export const MARK_SHOOTING_AS_DELIVERED_SUCCESS_MESSAGE = 'Lo shooting è stato segnato come consegnato con successo.';

const MarkShootingAsDeliveredAction: React.FC<MarkShootingAsDeliveredActionProps> = ({
  shooting,
  onSuccess,
  children,
  ...rest
}) => {
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const [isSaving, setIsSaving] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState<1 | 2>(1);

  const { userIsContentEditor, userIsAdmin } = useRBAC();
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const { data } = useComputedShootingStatus(shooting);

  const canBeMarkedAsDelivered = React.useMemo(() => data?.canBeUpdatedTo(ShootingStatus.DELIVERED), [data]);

  const closeModal = React.useCallback(() => {
    modal.close();
    setCurrentStep(1);
  }, [modal]);

  const confirmCancel = React.useCallback(async (formValues: UpsertShootingFormModel) => {
    setIsSaving(true);

    try {
      const shootingToMarkAsDelivered: Shooting = upsertShootingFormModelToShooting(formValues, {
        ...shooting,
        status: ShootingStatus.DELIVERED,
      });

      const shootingMarkedAsDelivered = await updateShooting(shootingToMarkAsDelivered);

      setIsSaving(false);
      closeModal();

      addSuccess(MARK_SHOOTING_AS_DELIVERED_SUCCESS_MESSAGE);
      onSuccess?.(shootingMarkedAsDelivered);
    } catch (error) {
      addError(MARK_SHOOTING_AS_DELIVERED_ERROR_MESSAGE);
      setIsSaving(false);
    }
  }, [shooting, closeModal, addSuccess, onSuccess, addError]);

  const handleConfirm = React.useCallback(() => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else {
      formRef.current?.handleSubmit();
    }
  }, [currentStep]);

  if (!canBeMarkedAsDelivered) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          isSaving,
          markAsDelivered: modal.open,
        })
        : (
          <Action
            label="Segna come consegnato"
            aria-label="Pulsante per segnare lo shooting come consegnato"
            onClick={() => modal.open()}
            loading={isSaving}
            {...rest}
          />
        )}
      <Portal>
        <ConfirmModal
          isOpen={modal.isOpen}
          title="Conferma shooting consegnato"
          aria-label="Conferma shooting consegnato"
          onConfirm={handleConfirm}
          variant="critical"
          onAbort={closeModal}
          loading={isSaving}
        >
          {currentStep === 1 && "Confermando l'operazione lo shooting sarà segnato come consegnato."}
          {currentStep === 2 && (
            <UpsertShootingForm
              initialValues={shootingToUpsertShootingFormModel(shooting)}
              innerRef={formRef}
              onSubmit={confirmCancel}
              loading={isSaving}
              validationSchema={upsertShootingValidationSchema(shooting)}
              hiddenFields={(!userIsContentEditor && !userIsAdmin) ? {
                matterportDeliveryDate: true,
                matterportRequest: true,
                postProcessingRequest: true,
                photoDeliveryDate: true,
                photoPrice: true,
                expensesRefund: true,
                extraServicesPrice: true,
              } : undefined}
              disabledFields={{
                type: true,
                photographerName: true,
                date: true,
                time: true,
                matterportDeliveryDate: true,
                matterportRequest: true,
                postProcessingRequest: true,
                photoDeliveryDate: true,
                photoPrice: true,
                expensesRefund: true,
                extraServicesPrice: true,
              }}
              size="S"
            />
          )}
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default MarkShootingAsDeliveredAction;
