import React from 'react';
import {
  Spacing,
  Stack,
} from '@doveit/bricks';
import {
  ContactToProcessNonAssignableLeadsNoticeData,
  Notice,
  Severity,
  ContactToProcessNoticeData,
  ContactToProcessExistingMatchesNoticeData,
} from '../../providers/api/dtos/notice';
import {
  BuildContactExistingMatchesNoticeCtx, BuildContactToProcessNoticeCtx,
} from './types';
import SimpleTable from '../../components/simple-table/SimpleTable';
import ViewContactLeadCard from '../../contact/containers/view-contact-lead-card/ViewContactLeadCard';
import ViewContactLeadRow from '../../contact/containers/contact-leads-widget/ViewContactLeadRow';
import ContactPreviewTableRow from '../../contact/components/contact-preview-table-row/ContactPreviewTableRow';
import ContactCard from '../../contact/components/contact-card/ContactCard';

export function buildContactToProcessNonAssignableLeadsNotice(noticeData: ContactToProcessNonAssignableLeadsNoticeData, ctx: BuildContactToProcessNoticeCtx): Notice {
  const { type, data: { nonAssignableLeads } } = noticeData;
  const { isMobile, onLeadUpdate } = ctx;
  return {
    type,
    severity: Severity.WARNING,
    priority: 2,
    message: 'Alcune lead non sono assegnabili',
    children: (
      <>
        Il contatto ha inserito una o più lead con un indirizzo non risolvibile. Per provare as assegnarle, modifica gli indirizzi delle seguenti lead:
        <Spacing margin={[200, 0, 0]}>
          {isMobile && (
            <Stack gap={150}>
              {nonAssignableLeads.map((lead) => (
                <ViewContactLeadCard
                  key={lead.id}
                  lead={lead}
                  onLeadUpdate={onLeadUpdate}
                />
              ))}
            </Stack>
          )}
          {!isMobile && (
            <SimpleTable>
              <SimpleTable.Body>
                {nonAssignableLeads.map((lead) => (
                  <SimpleTable.Row
                    key={lead.id}
                    asChild
                  >
                    <ViewContactLeadRow
                      lead={lead}
                      onLeadUpdate={onLeadUpdate}
                    />
                  </SimpleTable.Row>
                ))}
              </SimpleTable.Body>
            </SimpleTable>
          )}
        </Spacing>
      </>
    ),
  };
}

export function buildContactExistingMatchesNotice(noticeData: ContactToProcessExistingMatchesNoticeData, ctx: BuildContactExistingMatchesNoticeCtx): Notice {
  const { type, data: { contactMatches } } = noticeData;
  const { isMobile } = ctx;

  return {
    type,
    severity: Severity.WARNING,
    priority: 1,
    message: 'Possibile contatto duplicato',
    children: (
      <>
        Esistono i seguenti contatti con lo stesso numero di telefono o email:
        <Spacing margin={[200, 0, 0]}>
          {isMobile && (
            <Stack gap={150}>
              {contactMatches.map((match) => (
                <ContactCard
                  key={match.id}
                  contact={match}
                />
              ))}
            </Stack>
          )}
          {!isMobile && (
            <SimpleTable>
              <SimpleTable.Body>
                {contactMatches.map((match) => (
                  <SimpleTable.Row
                    key={match.id}
                    asChild
                  >
                    <ContactPreviewTableRow contact={match} />
                  </SimpleTable.Row>
                ))}
              </SimpleTable.Body>
            </SimpleTable>
          )}
        </Spacing>
      </>
    ),
  };
}

export function buildContactToProcessNotice(noticeData: ContactToProcessNoticeData, ctx: BuildContactToProcessNoticeCtx | BuildContactExistingMatchesNoticeCtx): Notice {
  switch (noticeData.type) {
    case 'CONTACT_TO_PROCESS_NON_ASSIGNABLE_LEADS':
      return buildContactToProcessNonAssignableLeadsNotice(noticeData, ctx as BuildContactToProcessNoticeCtx);
    case 'CONTACT_TO_PROCESS_EXISTING_MATCHES':
      return buildContactExistingMatchesNotice(noticeData, ctx as BuildContactExistingMatchesNoticeCtx);
    default:
      throw new Error('Unknown contact to process notice type');
  }
}
