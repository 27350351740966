import { css } from 'styled-components';

export function setVerticalEllipsis(lines: number = 2) {
  return (
    css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lines};
    white-space: pre-wrap;`
  );
}
