/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent } from 'react';
import {
  Badge, ICON_CHECK, ICON_HOME_OUTLINE, ActionIcon, ICON_EYE_OUTLINE, ICON_ACCOUNT_OUTLINE, ICON_CASH_MULTIPLE, ICON_CLOCK_OUTLINE, ICON_RULER, HStack, DetailItemList,
} from '@doveit/bricks';
import {
  formatDate, formatEuro, formatSquareMeters, pluralizeWord,
} from '@doveit/hammer';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/card/Card';
import { FeatureType, OfferStatus, Status } from '../../../domain/types';
import { formatPropertyType } from '../../utils';
import PropertyStatusBadge from '../../components/property-status-badge/PropertyStatusBadge';
import useBalconies from '../../hooks/use-balconies/useBalconies';
import useTerraces from '../../hooks/use-terraces/useTerraces';
import useGarages from '../../hooks/use-garages/useGarages';
import PublishedOnList from '../../published-on/containers/published-on-list/PublishedOnList';
import { ActiveOfferStatus, activeOfferBadgeMetadata } from '../../../offer/components/active-offer-badge/ActiveOfferBadge';
import PropertyDocumentsStatusLabel from '../../../document/components/property-documents-status-label/PropertyDocumentsStatusLabel';
import InvoiceStatusBadge from '../../../invoice/components/invoice-status-badge/InvoiceStatusBadge';
import ViewPropertySellability from '../view-property-sellability/ViewPropertySellability';
import CountLabel from '../../../components/count-label/CountLabel';
import usePropertyMatchCounters from '../../hooks/use-property-match-counters/usePropertyMatchCounters';
import * as styles from './ViewPropertyCard.style';
import { PropertySearchItem } from '../../../providers/api/dtos';
import CreateIntentWithAppointmentAction from '../../../intent/containers/create-intent-with-appointment-action/CreateIntentWithAppointmentAction';
import { EntityType } from '../../../domain/types/entityType';
import ShowAgentName from '../../../agent/containers/show-agent-name/ShowAgentName';

export interface ViewPropertyCardProps {
  propertySearchItem: PropertySearchItem,
  showAgentName?: boolean,
  showStatus?: boolean,
  showPublishedOn?: boolean,
  showCharacteristics?: boolean,
  showActiveOfferBadge?: boolean,
  showInvoiceStatusBadge?: boolean,
  showDocumentsStatus?: boolean,
  showMatchCounters?: boolean,
  showCreateIntentAction?: boolean,
  showViewDetailsAction?: boolean,
}

const ViewPropertyCard: FunctionComponent<ViewPropertyCardProps> = ({
  propertySearchItem,
  showAgentName,
  showStatus,
  showPublishedOn,
  showCharacteristics,
  showActiveOfferBadge,
  showInvoiceStatusBadge,
  showDocumentsStatus,
  showMatchCounters,
  showCreateIntentAction,
  showViewDetailsAction,
}) => {
  const {
    id,
    status,
    referenceId,
    geo,
    propertySize,
    createdAt,
    propertyType,
    price,
    features,
    privateGarden,
    approvedMandatoryDocumentCounter,
    pendingMandatoryDocumentCounter,
    agentName,
    invoiceStatus,
    offerStatuses,
  } = propertySearchItem;

  const navigate = useNavigate();
  const { data: balconies } = useBalconies(showCharacteristics ? id! : undefined);
  const { data: terraces } = useTerraces(showCharacteristics ? id! : undefined);
  const { data: garages } = useGarages(showCharacteristics ? id! : undefined);
  const { data: propertyMatchesCounters, mutate: mutatePropertyMatchesCounters } = usePropertyMatchCounters(showMatchCounters ? id : undefined);

  const showInternalPublishedOn = React.useMemo(
    () => showPublishedOn && ![Status.DRAFT, Status.RITIRATO].includes(status),
    [showPublishedOn, status],
  );

  const activeOfferStatus: ActiveOfferStatus = React.useMemo(() => {
    if (offerStatuses?.some((offerStatus) => offerStatus === OfferStatus.ACCEPTED)) {
      return 'existsAcceptedOffer';
    }

    if (offerStatuses?.some((offerStatus) => offerStatus === OfferStatus.IN_PROGRESS)) {
      return 'existsInProgressOffer';
    }

    return 'missing';
  }, [offerStatuses]);

  const viewDetailsAction = React.useCallback(
    () => navigate(`/properties/${id}`),
    [id, navigate],
  );

  const onIntentCreate = React.useCallback(
    async () => {
      if (showMatchCounters) {
        await mutatePropertyMatchesCounters();
      }
    },
    [mutatePropertyMatchesCounters, showMatchCounters],
  );

  return (
    <Card aria-label="Card relativa a un immobile">
      <Card.Inline>
        <Card.Box>
          <DetailItemList>
            <DetailItemList.Item title="id">
              <HStack>
                <Badge
                  label={referenceId}
                  color="primary"
                  size="XS"
                  onClick={viewDetailsAction}
                />
                {showActiveOfferBadge && activeOfferStatus !== 'missing' && (
                  <Badge
                    color={activeOfferBadgeMetadata[activeOfferStatus]!.color}
                    label={activeOfferBadgeMetadata[activeOfferStatus]!.label!}
                    aria-label="Badge per segnalare lo stato della proposta"
                    size="XS"
                  />
                )}
                {showInvoiceStatusBadge && invoiceStatus && (
                  <InvoiceStatusBadge
                    invoiceStatus={invoiceStatus}
                    size="XS"
                  />
                )}
                <ViewPropertySellability
                  geo={geo?.latitude && geo.longitude ? {
                    latitude: geo.latitude.toString(),
                    longitude: geo.longitude.toString(),
                  } : undefined}
                  propertySize={propertySize}
                  size="XS"
                />
              </HStack>
            </DetailItemList.Item>
            <DetailItemList.Item
              icon={ICON_HOME_OUTLINE}
              title="Indirizzo"
            >
              <span>
                {geo?.normalizedAddress || '-'}
              </span>
            </DetailItemList.Item>
            <DetailItemList.Item
              icon={ICON_RULER}
              title="Tipo e dimensione immobile"
            >
              {formatPropertyType(propertyType)}
              {propertySize ? ` - ${formatSquareMeters(propertySize)}` : ''}
            </DetailItemList.Item>
            {price && propertySize && (
              <DetailItemList.Item
                icon={ICON_CASH_MULTIPLE}
                title="Prezzo"
              >
                {formatEuro(price)}
              </DetailItemList.Item>
            )}
            {showCharacteristics && (
              <DetailItemList.Item>
                <HStack aria-label="Caratteristiche dell'immobile">
                  {features?.find((feature) => feature.type === FeatureType.ASCENSORE) && (
                    <Badge
                      icon={ICON_CHECK}
                      label="Ascensore"
                      aria-label="Dotato di ascensore"
                      size="XS"
                    />
                  )}
                  {(privateGarden ?? 0) > 0 && (
                    <Badge
                      label="Giardino"
                      icon={ICON_CHECK}
                      aria-label="Dotato di giardino"
                      size="XS"
                    />
                  )}
                  {(garages?.length ?? 0) > 0 && (
                    <Badge
                      label="Box"
                      icon={ICON_CHECK}
                      aria-label="Dotato di box"
                      size="XS"
                    />
                  )}
                  {(balconies?.length ?? 0) > 0 && (
                    <Badge
                      label="Balcone"
                      icon={ICON_CHECK}
                      aria-label="Dotato di balcone"
                      size="XS"
                    />
                  )}
                  {(terraces?.length ?? 0) > 0 && (
                    <Badge
                      label="Terrazzo"
                      icon={ICON_CHECK}
                      aria-label="Dotato di terrazzo"
                      size="XS"
                    />
                  )}
                </HStack>
              </DetailItemList.Item>
            )}
            {showMatchCounters && propertyMatchesCounters && (propertyMatchesCounters.intents.total > 0 || propertyMatchesCounters.searchCriteria.total > 0) && (
              <DetailItemList.Item>
                {propertyMatchesCounters.intents.total > 0 && (
                  <div
                    css={styles.counterItem}
                    aria-label="count-label"
                  >
                    <CountLabel
                      count={propertyMatchesCounters.intents.total}
                      label={`${pluralizeWord('Interess', 'e', 'i', propertyMatchesCounters.intents.total)}`}
                    />
                  </div>
                )}
                {propertyMatchesCounters.searchCriteria.total > 0 && (
                  <div
                    css={styles.counterItem}
                    aria-label="count-label"
                  >
                    <CountLabel
                      count={propertyMatchesCounters.searchCriteria.total}
                      label={`${pluralizeWord('Possibil', 'e', 'i', propertyMatchesCounters.searchCriteria.total)} ${pluralizeWord('acquirent', 'e', 'i', propertyMatchesCounters.searchCriteria.total)}`}
                    />
                  </div>
                )}
              </DetailItemList.Item>
            )}
          </DetailItemList>
        </Card.Box>
        <Card.ColumnDivider />
        <Card.Box>
          <DetailItemList>
            {showAgentName && (
              <DetailItemList.Item
                icon={ICON_ACCOUNT_OUTLINE}
                title="Agente incaricato"
              >
                {agentName && (<div>{agentName}</div>)}
                {!agentName && (
                  <ShowAgentName
                    entityId={id}
                    entityType={EntityType.PROPERTY}
                  />
                )}
              </DetailItemList.Item>
            )}
            <DetailItemList.Item
              icon={ICON_CLOCK_OUTLINE}
              title="Data di creazione immobile"
            >
              {formatDate(new Date(createdAt!))}
            </DetailItemList.Item>
            {showStatus && (
              <DetailItemList.Item title="Stato pubblicazione">
                <PropertyStatusBadge
                  status={status}
                  size="XS"
                />
              </DetailItemList.Item>
            )}
            {showInternalPublishedOn && (
              <DetailItemList.Item>
                <PublishedOnList property={propertySearchItem} />
              </DetailItemList.Item>
            )}
            {showDocumentsStatus && (
              <DetailItemList.Item>
                <PropertyDocumentsStatusLabel
                  status={{
                    approvedMandatoryDocumentCounter: approvedMandatoryDocumentCounter!,
                    pendingMandatoryDocumentCounter: pendingMandatoryDocumentCounter!,
                  }}
                />
              </DetailItemList.Item>
            )}
          </DetailItemList>
        </Card.Box>
        <Card.ColumnDivider />
        <Card.Box fit>
          <HStack>
            {showViewDetailsAction && (
              <ActionIcon
                aria-label="Visualizza la pagina di dettagli dell'immobile"
                icon={{ path: ICON_EYE_OUTLINE }}
                label="Visualizza"
                onClick={viewDetailsAction}
              />
            )}
            {showCreateIntentAction && (
              <CreateIntentWithAppointmentAction
                property={{
                  id: propertySearchItem.id,
                  status: propertySearchItem.status,
                }}
                onIntentCreated={onIntentCreate}
              />
            )}
          </HStack>
        </Card.Box>
      </Card.Inline>
    </Card>
  );
};

export default ViewPropertyCard;
