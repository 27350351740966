export enum ShootingType {
  'DIRECT' = 'DIRECT',
  'AGENT' = 'AGENT',
  'PARTNER' = 'PARTNER',
}

export enum ShootingStatus {
  BOOKED = 'BOOKED',
  REVIEWED = 'REVIEWED',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  NOT_EXECUTED = 'NOT_EXECUTED',
}
