import { JobApplicationStatus } from '../../domain/types';
import { JobApplication } from '../../providers/api/dtos/jobApplication';

const archivingStatus = [
  JobApplicationStatus.KO_DUPLICATE,
  JobApplicationStatus.KO_GEO,
  JobApplicationStatus.KO_NOT_ELIGIBLE,
  JobApplicationStatus.KO_NOT_INTERESTED,
  JobApplicationStatus.KO_OFFER_REFUSED,
];

const finalStatus = [
  ...archivingStatus,
  JobApplicationStatus.HIRED,
];

const assignableStatus = [
  JobApplicationStatus.IN_PROGRESS,
  JobApplicationStatus.ASSIGNED,
  JobApplicationStatus.OFFER,
];

export function isArchived(jobApplication: JobApplication): Boolean;
export function isArchived(status: JobApplicationStatus): Boolean;

export function isArchived(arg: JobApplication | JobApplicationStatus): Boolean {
  const status = (arg as JobApplication).status
    ? (arg as JobApplication).status
    : arg as JobApplicationStatus;

  return archivingStatus.includes(status);
}

export function isFinal(jobApplication: JobApplication): Boolean;
export function isFinal(status: JobApplicationStatus): Boolean;

export function isFinal(arg: JobApplication | JobApplicationStatus): Boolean {
  const status = (arg as JobApplication).status
    ? (arg as JobApplication).status
    : arg as JobApplicationStatus;

  return finalStatus.includes(status);
}

export function isAssignable(jobApplication: JobApplication): Boolean;
export function isAssignable(status: JobApplicationStatus): Boolean;

export function isAssignable(arg: JobApplication | JobApplicationStatus): Boolean {
  const status = (arg as JobApplication).status
    ? (arg as JobApplication).status
    : arg as JobApplicationStatus;

  return assignableStatus.includes(status);
}
