import React, {
  FunctionComponent,
} from 'react';
import {
  Typography,
  Icon,
  ICON_CLOSE,
  ICON_CHECK,
  Badge,
  Stack,
  HStack,
} from '@doveit/bricks';
import { formatDate } from '@doveit/hammer';
import { optInSourceIcon } from '../../contact/constants';
import { OptInSource } from '../../domain/types';

export interface OptInDetailsProps {
  label: string,
  given: boolean,
  sourceType?: OptInSource,
  sourceValue?: string,
  ipAddress?: string,
  givenAt?: string,
  expiresAt?: string,
  canSeeDetails?: boolean,
}

const OptInDetails: FunctionComponent<OptInDetailsProps> = ({
  label,
  given,
  sourceType,
  sourceValue,
  ipAddress,
  givenAt,
  expiresAt,
  canSeeDetails,
}) => (
  <Stack>
    <HStack>
      <Typography.BODY>
        <strong>{label}</strong>
      </Typography.BODY>
      <Icon
        path={given ? ICON_CHECK : ICON_CLOSE}
        color={given ? 'success.default.low' : 'critical.default.low'}
        size={20}
      />
    </HStack>
    {canSeeDetails && (
      <Stack data-ref="opt-ins-item-info">
        {givenAt && expiresAt && (
          <Typography.BODY_SMALL>
            Prestato il {formatDate(new Date(givenAt))}
            {' - '}
            {given ? 'Scade il' : 'Scaduto il'}
            {' '}
            {formatDate(new Date(expiresAt))}
          </Typography.BODY_SMALL>
        )}
        <HStack>
          {sourceType && sourceValue && (
            <Badge
              icon={optInSourceIcon[sourceType]}
              label={sourceValue}
            />
          )}
          {ipAddress && (
            <Badge label={ipAddress} />
          )}
        </HStack>
      </Stack>
    )}
  </Stack>
);

export default OptInDetails;
