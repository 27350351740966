import { RumorStatus } from '../../../domain/types';
import { FiltersMapper } from '../../../hooks/use-filters/useFilters';
import { SortOrder } from '../../../providers/pagination';
import { GenericSerpSortOption } from '../../../types';
import { RUMOR_KO_STATUSES } from '../../constants';
import {
  RumorSerpPageFilterKeys, RumorSerpPageFilterOption, RumorSerpPageFilters, RumorSerpPageSortKeys,
} from './types';

export const LOAD_SERP_RUMORS_ERROR_MESSAGE = 'Non è stato possibile recuperare le notizie.';
export const NO_SERP_RUMORS = 'Non sono presenti notizie.';

type RumorSerpSortOption = GenericSerpSortOption<RumorSerpPageSortKeys>;

export const ALL_RUMORS_FILTER_OPTION: RumorSerpPageFilterOption = {
  key: 'all',
  label: {
    short: 'Tutte',
    long: 'Tutte le notizie',
  },
  value: {},
};

export const STATUS_FILTERS: RumorSerpPageFilterOption[] = [
  {
    key: 'active',
    label: {
      short: 'Attive',
      long: 'Attive',
    },
    value: {
      status: [RumorStatus.IN_PROGRESS, RumorStatus.APPOINTMENT_SCHEDULED],
    },
  },
  {
    key: 'inactive',
    label: {
      short: 'Non attive',
      long: 'Non attive',
    },
    value: {
      status: [...RUMOR_KO_STATUSES],
    },
  },
];

export const DEFAULT_FILTER_OPTION: RumorSerpPageFilterOption = {
  key: 'toBeManaged',
  label: {
    short: 'Da gestire',
    long: 'Da gestire',
  },
  value: {
    toBeManaged: true,
  },
};

export const LATEST_REMINDERS_FILTERS_OPTION: RumorSerpPageFilterOption[] = [{
  key: 'futureReminders',
  label: {
    short: 'Futuri',
    long: 'Con promemoria futuri',
  },
  value: {
    status: RumorStatus.IN_PROGRESS,
    latestReminderGt: new Date(),
  },
},
{
  key: 'pastReminders',
  label: {
    short: 'Passati',
    long: 'Con promemoria passati',
  },
  value: {
    status: RumorStatus.IN_PROGRESS,
    latestReminderLt: new Date(),
  },
}];

export const FILTER_OPTIONS: RumorSerpPageFilterOption[] = [
  DEFAULT_FILTER_OPTION,
  ...STATUS_FILTERS,
  ...LATEST_REMINDERS_FILTERS_OPTION,
  ALL_RUMORS_FILTER_OPTION,
];

export const DEFAULT_SORT_OPTION: RumorSerpSortOption = {
  key: 'default',
  label: 'In evidenza',
} as const;

export const MOST_RECENT_UPDATE_SORT_OPTION: RumorSerpSortOption = {
  key: 'mostRecentRumorUpdate',
  label: 'Aggiornamento più recente',
  value: {
    updatedAt: SortOrder.DESC,
  },
} as const;

export const LEAST_RECENT_UPDATE_SORT_OPTION: RumorSerpSortOption = {
  key: 'leastRecentRumorUpdate',
  label: 'Aggiornamento meno recente',
  value: {
    updatedAt: SortOrder.ASC,
  },
} as const;

export const MOST_RECENT_CREATION_SORT_OPTION: RumorSerpSortOption = {
  key: 'mostRecentRumorCreate',
  label: 'Creazione più recente',
  value: {
    createdAt: SortOrder.DESC,
  },
} as const;

export const LEAST_RECENT_CREATION_SORT_OPTION: RumorSerpSortOption = {
  key: 'leastRecentRumorCreate',
  label: 'Creazione meno recente',
  value: {
    createdAt: SortOrder.ASC,
  },
} as const;

export const SORT_OPTIONS: RumorSerpSortOption[] = [
  DEFAULT_SORT_OPTION,
  MOST_RECENT_CREATION_SORT_OPTION,
  LEAST_RECENT_CREATION_SORT_OPTION,
  MOST_RECENT_UPDATE_SORT_OPTION,
  LEAST_RECENT_UPDATE_SORT_OPTION,
] as const;

export const SORT_OPTIONS_PER_FILTER: Record<RumorSerpPageFilterKeys, RumorSerpSortOption> = {
  toBeManaged: {
    ...MOST_RECENT_UPDATE_SORT_OPTION,
    key: 'default',
  },
  active: {
    ...MOST_RECENT_CREATION_SORT_OPTION,
    key: 'default',
  },
  inactive: {
    ...MOST_RECENT_UPDATE_SORT_OPTION,
    key: 'default',
  },
  pastReminders: {
    label: 'Promemoria più recente',
    value: {
      'latest_reminder.date': SortOrder.DESC,
    },
    key: 'default',
  },
  futureReminders: {
    label: 'Promemoria più prossimo',
    value: {
      'latest_reminder.date': SortOrder.ASC,
    },
    key: 'default',
  },
  all: {
    ...MOST_RECENT_CREATION_SORT_OPTION,
    key: 'default',
  },
};

export const GROUPED_FILTER_OPTIONS = [
  {
    label: 'Stato',
    options: STATUS_FILTERS,
  },
  {
    label: 'Promemoria',
    options: LATEST_REMINDERS_FILTERS_OPTION,
  },
];

export const FILTERS_MAPPER: FiltersMapper<RumorSerpPageFilters> = {
  q: (v) => v,
  filter: (v) => FILTER_OPTIONS.find(({
    key,
  }) => key === v)?.key || DEFAULT_FILTER_OPTION.key,
  sort: (v) => SORT_OPTIONS.find(({
    key,
  }) => key === v)?.key || DEFAULT_SORT_OPTION.key,
};
