import useSWR, { Key, SWRResponse } from 'swr';
import { PropertiesMatchesFilters, getPropertyMatchCounters } from '../../../providers/api/property/propertyProvider';
import { PropertyMatchCounters } from '../../../providers/api/dtos/propertyMatchCounters';

export const PROPERTY_MATCH_COUNTERS_RID = '/properties/:id/match-counters';

export default function usePropertyMatchCounters(propertyId?: number, filters: PropertiesMatchesFilters = {}): SWRResponse<PropertyMatchCounters> {
  const key: Key = propertyId ? [PROPERTY_MATCH_COUNTERS_RID, propertyId].join() : null;

  return useSWR(key, () => getPropertyMatchCounters(propertyId!, filters));
}
