import React from 'react';
import {
  ActionIcon, ConfirmModal, ICON_HOME_REMOVE_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Status } from '../../../domain/types';
import { Property } from '../../../providers/api/dtos';
import { updateProperty } from '../../../providers/api/property/propertyProvider';
import { useComputedPropertyStatus } from '../../hooks/use-computed-property-status/useComputedPropertyStatus';

interface ChildFnProps {
  disabled: boolean,
  loading: boolean,
  withdraw: VoidFunction,
}

export interface WithdrawPropertyActionProps {
  property: Property,
  disabled?: boolean,
  onSuccess?: (withdrawedProperty: Property) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const WITHDRAW_PROPERTY_SUCCESS_MESSAGE = 'Immobile ritirato con successo';
export const WITHDRAW_PROPERTY_ERROR_MESSAGE = 'Non è stato possibile ritirare l\'immobile';

const WithdrawPropertyAction: React.FC<WithdrawPropertyActionProps> = ({
  property,
  disabled = false,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const confirmModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const {
    data: computedPropertyStatus,
    isLoading: isComputedPropertyStatusLoading,
  } = useComputedPropertyStatus(property);

  const canBeWithdrawn = computedPropertyStatus?.canBeUpdatedTo(Status.RITIRATO) || false;

  const openConfirmModal = React.useCallback(() => {
    confirmModal.open();
  }, [confirmModal]);

  const onConfirm = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const updatedProperty = await updateProperty(property.id!, { ...property, status: Status.RITIRATO });

      setIsSaving(false);
      confirmModal.close();
      addSuccess(WITHDRAW_PROPERTY_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedProperty);
      }
    } catch (error) {
      setIsSaving(false);
      addError(WITHDRAW_PROPERTY_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, confirmModal, onSuccess, property]);

  if (!canBeWithdrawn) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          disabled,
          withdraw: openConfirmModal,
          loading: isComputedPropertyStatusLoading || isSaving,
        }) : (
          <ActionIcon
            icon={{ path: ICON_HOME_REMOVE_OUTLINE }}
            label="Ritira"
            title="Ritira l'immobile"
            onClick={openConfirmModal}
            aria-label="Ritira l'immobile"
            disabled={disabled}
            loading={isComputedPropertyStatusLoading || isSaving}
          />
        )}
      <Portal>
        <ConfirmModal
          isOpen={confirmModal.isOpen}
          aria-label="Conferma ritiro dell'immobile"
          title="Ritiro dell'immobile"
          saving={isSaving}
          onConfirm={onConfirm}
          onAbort={confirmModal.close}
        >
          Ritirando l&apos;immobile, l&apos;annuncio non sarà più visibile sui portali e sul sito di Dove.it.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default WithdrawPropertyAction;
