/* eslint-disable react/no-unknown-property */
import { Image } from '@doveit/bricks';
import React, { FunctionComponent } from 'react';
import { DocumentFile } from '../../../providers/api/dtos';
import { FileMimeType } from '../../../types';
import * as styles from './DocumentFilePreview.style';
import PDFViewer from '../../../components/pdf-viewer/PDFViewer';

export interface DocumentFilePreviewProps {
  file: DocumentFile,
  height?: string,
}

const DocumentFilePreview: FunctionComponent<DocumentFilePreviewProps> = ({
  file,
  height = '100%',
}) => (
  <>
    {file.mimeType === FileMimeType.PDF && file.url && (
      <PDFViewer
        src={file.url}
        title={file.filename}
        height={height}
      />
    )}
    {[FileMimeType.JPEG, FileMimeType.PNG].includes(file.mimeType) && (
      <div
        data-ref="image-wrapper"
        css={styles.image}
        height={height}
      >
        <Image
          src={file.url!}
          alt={file.filename}
        />
      </div>
    )}
  </>
);

export default DocumentFilePreview;
