import hash from 'object-hash';
import useSWR from 'swr';
import { ReminderV3 } from '../../../providers/api/dtos/reminder';
import { getRemindersV3, RemindersProviderFiltersV3 } from '../../../providers/api/reminder/reminderProvider';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

export const REMINDERS_RID = '/v3/reminders/';

export default function useRemindersV3(
  filters: RemindersProviderFiltersV3 = {},
  pagination?: PaginationParams,
): SWRCustomResponse<PaginatedResult<ReminderV3>, Error> {
  const matchMutate = useMatchMutate();
  const key = [REMINDERS_RID, hash({ ...filters, ...pagination })];
  const result = useSWR(key, () => getRemindersV3(filters, pagination));
  return {
    ...result,
    mutate: () => matchMutate(REMINDERS_RID),
  };
}
