import {
  Form, FormActionsAlign, FormHandlers, FormSubmitType,
} from '@doveit/bricks';
import React, { FunctionComponent } from 'react';
import AgentAutoComplete from '../../../../containers/agent-autocomplete/AgentAutoComplete';
import validationSchema from './EditPropertyAssignmentForm.schema';
import { AgentStatus } from '../../../../domain/types/agent';
import { Agent } from '../../../../providers/api/dtos';

export interface EditPropertyAssignmentFormModel {
  id?: number,
  assignee?: Agent,
  exclusive?: boolean,
  autoRenew?: boolean,
  keysHanded?: boolean,
  endDate?: string,
  prospectId?: string,
}

export interface EditPropertyAssignmentFormProps {
  onSubmit: FormSubmitType<EditPropertyAssignmentFormModel>,
  initialValues: EditPropertyAssignmentFormModel,
  innerRef?: React.MutableRefObject<FormHandlers>
  disabled?: boolean,
  loading?: boolean,
  /**
   * @todo remove when the new property detail page is live (submit is already in `EditPropertyAssignmentAction`)
   */
  noSubmit?: boolean,
  hiddenFields?: {
    assignee?: boolean,
    exclusive?: boolean,
    autoRenew?: boolean,
    keysHanded?: boolean,
    endDate?: boolean,
  }
}

export const DEFAULT_INITIAL_VALUES: EditPropertyAssignmentFormModel = {
  assignee: {} as Agent,
  autoRenew: false,
  exclusive: false,
  keysHanded: false,
  endDate: '',
};

const EditPropertyAssignmentForm: FunctionComponent<EditPropertyAssignmentFormProps> = ({
  initialValues,
  disabled,
  loading,
  onSubmit,
  hiddenFields,
  innerRef,
  noSubmit = false,
}) => {
  const mergedInitialValues: EditPropertyAssignmentFormModel = {
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  };

  return (
    <Form
      initialValues={mergedInitialValues}
      enableReinitialize
      disabled={disabled}
      loading={loading}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={innerRef}
    >
      {!hiddenFields?.assignee && (
        <Form.Item>
          <AgentAutoComplete
            name="assignee"
            label="Agente assegnatario"
            aria-label="Seleziona l'agente assegnatario"
            placeholder="Cerca per nome"
            status={AgentStatus.ACTIVE}
          />
        </Form.Item>
      )}
      {!hiddenFields?.endDate && (
        <Form.Item>
          <Form.Input
            name="endDate"
            type="date"
            label="Data di fine del mandato"
            aria-label="Data di fine mandato"
          />
        </Form.Item>
      )}
      {!hiddenFields?.exclusive && (
        <Form.Item size={{ LG: 1 / 2 }}>
          <Form.CheckboxToggle
            name="exclusive"
            aria-label="Campo per specificare se il mandato è in esclusiva"
            text="Mandato in esclusiva"
          />
        </Form.Item>
      )}
      {!hiddenFields?.autoRenew && (
        <Form.Item size={{ LG: 1 / 2 }}>
          <Form.CheckboxToggle
            name="autoRenew"
            aria-label="Campo per specificare se il rinnovo è automatico"
            text="Rinnovo automatico"
          />
        </Form.Item>
      )}
      {!hiddenFields?.keysHanded && (
        <Form.Item size={{ LG: 1 / 2 }}>
          <Form.CheckboxToggle
            name="keysHanded"
            aria-label="Campo per specificare se le chiavi sono state consegnate"
            text="Chiavi consegnate"
          />
        </Form.Item>
      )}
      {!noSubmit && (
        <Form.Actions align={FormActionsAlign.RIGHT}>
          <Form.Submit label="Modifica" />
        </Form.Actions>
      )}
    </Form>
  );
};

export default EditPropertyAssignmentForm;
