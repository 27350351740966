import {
  ICON_ACCOUNT_OUTLINE, ICON_DATABASE_ARROW_RIGHT_OUTLINE, ICON_FILE_DOCUMENT_OUTLINE, ICON_LAPTOP,
} from '@doveit/bricks';
import { OptInSource, OptInType } from '../domain/types';

export const optInSourceIcon: { [key in OptInSource]: string } = {
  [OptInSource.WEB]: ICON_LAPTOP,
  [OptInSource.BACKOFFICE]: ICON_ACCOUNT_OUTLINE,
  [OptInSource.DOCUMENT]: ICON_FILE_DOCUMENT_OUTLINE,
  [OptInSource.THIRD_PARTY]: ICON_DATABASE_ARROW_RIGHT_OUTLINE,
};

export const optInValidityInYears: { [key in OptInType]: number } = {
  [OptInType.SERVICE]: 10,
  [OptInType.MARKETING]: 2,
  [OptInType.THIRD_PARTIES]: 2,
};
