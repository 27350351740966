/* eslint-disable react/no-unknown-property */
import { Form, FormHandlers, FormProps } from '@doveit/bricks';
import React from 'react';
import { GarageType } from '../../../../domain/types/garageType';
import { asSelectOptions } from '../../../../select-options/utils/asSelectOptions';
import { garageTypeLabels } from '../../../../labels/garageTypeLabels';
import upsertPropertyGarageValidationSchema from './UpsertPropertyGarageForm.schema';

export interface UpsertPropertyGarageFormModel {
  size: string,
  type: GarageType | '',
  adjacent: boolean,
}

export interface UpsertPropertyGarageFormProps {
  initialValues?: Partial<UpsertPropertyGarageFormModel>;
  disabled?: boolean;
  innerRef?: React.MutableRefObject<FormHandlers>,
  loading?: FormProps<UpsertPropertyGarageFormModel>['loading'],
  onSubmit: FormProps<UpsertPropertyGarageFormModel>['onSubmit'],
}

const UPSERT_PROPERTY_GARAGE_FORM_DEFAULT_VALUES: UpsertPropertyGarageFormModel = {
  size: '',
  adjacent: false,
  type: '',
};

const UpsertPropertyGarageForm: React.FC<UpsertPropertyGarageFormProps> = ({
  initialValues,
  ...rest
}) => {
  const mergedInitialValues: UpsertPropertyGarageFormModel = {
    ...UPSERT_PROPERTY_GARAGE_FORM_DEFAULT_VALUES,
    ...(initialValues || {}),
  };

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={upsertPropertyGarageValidationSchema}
      {...rest}
    >
      <Form.Item size={{ XS: 1, MD: 4.5 / 10 }}>
        <Form.Select
          size="S"
          name="type"
          aria-label="Campo per inserire la tipologia del garage"
          options={asSelectOptions(garageTypeLabels, {
            value: '',
            label: 'Seleziona tipologia',
            disabled: true,
          })}
          hasErrorTooltip
        />
      </Form.Item>
      <Form.Item size={{ XS: 1, MD: 2.5 / 10 }}>
        <Form.Input
          size="S"
          name="size"
          type="number"
          prefixElement="m²"
          aria-label="Campo per inserire la dimensione del garage"
          hasErrorTooltip
          min="0"
        />
      </Form.Item>
      <Form.Item size={{ XS: 1, MD: 3 / 10 }}>
        <Form.CheckboxButton
          size="S"
          name="adjacent"
          text="Di pertinenza"
          aria-label="Casella per indicare se un garage è di pertinenza dell'immobile"
        />
      </Form.Item>
    </Form>
  );
};

export default UpsertPropertyGarageForm;
