import { ContentType } from '../../../domain/types';
import { API_CONTENTS_PATH, API_PROPERTIES_PATH, getClient } from '../api';
import { Content } from '../dtos';

export async function getContentsByPropertyId(propertyId: number, contentType?: ContentType | ContentType[]): Promise<Content[]> {
  const client = await getClient();

  const { data } = await client.get<Content[]>(`${API_PROPERTIES_PATH}/${propertyId}/contents`, contentType ? {
    params: { 'content-type': contentType },
  } : undefined);

  return data;
}

export async function updateContentsForPropertyId(contents: Content[], propertyId: number): Promise<Content[]> {
  const client = await getClient();
  const { data } = await client.put<Content[]>(`${API_PROPERTIES_PATH}/${propertyId}/contents`, contents);
  return data;
}

export async function createContentWithFile(content: Content, file: File, propertyId: number): Promise<Content> {
  const client = await getClient();
  const formData = new FormData();
  formData.append('image', file);
  formData.append('propertyId', propertyId.toString(10));
  formData.append('fileName', content.fileName);
  formData.append('contentType', content.contentType.toString());

  if (content.position) {
    formData.append('position', content.position.toFixed(0));
  }

  const { data: dataResult } = await client.post<Content>(
    API_CONTENTS_PATH,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return dataResult;
}

export async function createContent(content: Content): Promise<Content> {
  const client = await getClient();
  const { data } = await client.post<Content>(API_CONTENTS_PATH, content);
  return data;
}

export async function updateContent(contentId: number, content: Content): Promise<Content> {
  const client = await getClient();
  const { data } = await client.put<Content>(`${API_CONTENTS_PATH}/${contentId}`, content);
  return data;
}

export async function deleteContent(contentId: number): Promise<{ id: string | number }> {
  const client = await getClient();
  const { data } = await client.delete(`${API_CONTENTS_PATH}/${contentId}`);
  return data;
}
