import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { getAssignmentByPropertyId } from '../../../providers/api/assignment/assignmentProvider';
import { Assignment } from '../../../providers/api/dtos/assignment';

export const ASSIGNMENT_BY_PROPERTY_ID_RID = '/property/:id/assignment';

export default function useAssignmentByPropertyId(
  propertyId?: number | null | undefined,
  options?: SWRConfiguration<Assignment, AxiosError>,
): SWRResponse<Assignment, AxiosError> {
  const key = propertyId ? [ASSIGNMENT_BY_PROPERTY_ID_RID, propertyId] : null;

  return useSWR(key, () => getAssignmentByPropertyId(propertyId!), options);
}
