/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, useMemo } from 'react';
import GenericPageLayoutBar, { GenericPageLayoutBarProps } from './bar/GenericPageLayoutBar';
import GenericPageLayoutContent, { GenericPageLayoutContentProps } from './content/GenericPageLayoutContent';
import * as styles from './GenericPageLayout.style';
import GenericPageLayoutContext from './GenericPageLayoutContext';
import GenericPageLayoutInnerContent, { GenericPageLayoutInnerContentProps } from './inner-content/GenericPageLayoutInnerContent';
import GenericPageLayoutTabs, { GenericPageLayoutTabsProps } from './tabs/GenericPageLayoutTabs';
import { GenericPageLayoutWrapperWidth } from './wrapper/GenericPageLayoutWrapper';

export interface GenericPageLayoutProps extends React.PropsWithChildren, React.AriaAttributes {
  maxWrapperWidth?: GenericPageLayoutWrapperWidth,
}

interface DetailsLayoutComposition {
  Bar: FunctionComponent<GenericPageLayoutBarProps>,
  Tabs: FunctionComponent<GenericPageLayoutTabsProps>,
  Content: FunctionComponent<GenericPageLayoutContentProps>,
  InnerContent: FunctionComponent<GenericPageLayoutInnerContentProps>,
}

const GenericPageLayout: FunctionComponent<GenericPageLayoutProps> & DetailsLayoutComposition = ({
  maxWrapperWidth,
  children,
  ...rest
}) => {
  const pageLayoutContext = useMemo(() => ({
    maxWrapperWidth,
  }), [maxWrapperWidth]);

  return (
    <GenericPageLayoutContext.Provider
      value={pageLayoutContext}
    >
      <div css={styles.base} {...rest}>
        {children}
      </div>
    </GenericPageLayoutContext.Provider>
  );
};

GenericPageLayout.Bar = GenericPageLayoutBar;
GenericPageLayout.Tabs = GenericPageLayoutTabs;
GenericPageLayout.Content = GenericPageLayoutContent;
GenericPageLayout.InnerContent = GenericPageLayoutInnerContent;

export default GenericPageLayout;
