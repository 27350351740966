import { Department } from '../domain/types';
import { Filters } from '../hooks/use-filters/useFilters';

export enum JobApplicationSection {
  TODO = 'todo',
  ASSIGNED = 'assigned',
  OFFER = 'offer',
  HIRED = 'hired',
  ARCHIVED = 'archived',
}

export interface JobApplicationFilters extends Filters {
  department?: Department,
  assignee?: number,
  province?: string,
  license?: boolean,
}

export type JobApplicationFilterKeys = keyof JobApplicationFilters;
