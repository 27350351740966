import React from 'react';
import {
  Action, ConfirmModal, Portal, Text, useModal, useNotifications,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { Prospect } from '../../../providers/api/dtos'; import { useComputedProspectApprovalStatus } from '../../hooks/use-computed-prospect-approval-status/useComputedProspectApprovalStatus';
import { ProspectApprovalStatus } from '../../../domain/types';
import { updateProspect } from '../../../providers/api/prospect/prospectProvider';

interface ChildFnProps {
  confirmApproval: VoidFunction,
  label: string,
  ariaLabel: string,
  isSaving: boolean,
}

export interface ConfirmProspectApprovalActionProps extends React.AriaAttributes {
  prospect: Prospect,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (prospect: Prospect) => void,
}

export const ACCEPT_PROSPECT_SUCCESS_MESSAGE = 'La valutazione è stata accettata con successo.';
export const ACCEPT_PROSPECT_ERROR_MESSAGE = 'Non è stato possibile accettare la valutazione.';
export const APPROVE_PROSPECT_SUCCESS_MESSAGE = 'La valutazione è stata approvata con successo.';
export const APPROVE_PROSPECT_ERROR_MESSAGE = 'Non è stato possibile approvare la valutazione.';

const ConfirmProspectApprovalAction: React.FC<ConfirmProspectApprovalActionProps> = ({
  prospect,
  children,
  onSuccess,
  ...rest
}) => {
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);
  const { data: { status, canBeUpdatedTo } } = useComputedProspectApprovalStatus(prospect);

  const shouldBeUpdatedTo = ProspectApprovalStatus.APPROVED;
  const isWaitingForSupervisor = status === ProspectApprovalStatus.WAITING_FOR_SUPERVISOR;

  const canBeConfirmed = React.useMemo(
    () => canBeUpdatedTo(shouldBeUpdatedTo),
    [canBeUpdatedTo, shouldBeUpdatedTo],
  );

  const handleConfirm = React.useCallback(async () => {
    try {
      setIsSaving(true);

      const updatedProspect = await updateProspect(prospect.id!, {
        ...prospect,
        approval: {
          ...prospect.approval!,
          status: shouldBeUpdatedTo,
        },
      });

      setIsSaving(false);
      modal.close();
      addSuccess(isWaitingForSupervisor
        ? APPROVE_PROSPECT_SUCCESS_MESSAGE
        : ACCEPT_PROSPECT_SUCCESS_MESSAGE);

      onSuccess?.(updatedProspect);
    } catch (err) {
      setIsSaving(false);
      addError(isWaitingForSupervisor
        ? APPROVE_PROSPECT_ERROR_MESSAGE
        : ACCEPT_PROSPECT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, isWaitingForSupervisor, modal, onSuccess, prospect, shouldBeUpdatedTo]);

  const label = React.useMemo(
    () => (isWaitingForSupervisor ? 'Approva valutazione' : 'Accetta valutazione'),
    [isWaitingForSupervisor],
  );

  const ariaLabel = React.useMemo(
    () => (isWaitingForSupervisor
      ? 'Azione per approvare la valutazione'
      : 'Azione per accettare la valutazione'),
    [isWaitingForSupervisor],
  );

  if (!canBeConfirmed) return null;

  return (
    <>
      {children ? children({
        confirmApproval: modal.open,
        label,
        ariaLabel,
        isSaving,
      }) : (
        <Action
          label={label}
          aria-label={ariaLabel}
          size="S"
          loading={isSaving}
          onClick={() => modal.open()}
          {...rest}
        />
      )}
      <Portal>
        <ConfirmModal
          isOpen={modal.isOpen}
          saving={isSaving}
          aria-label={isWaitingForSupervisor ? 'Modale per approvare la valutazione' : 'Modale per accettare la valutazione'}
          title={isWaitingForSupervisor ? 'Conferma approvazione valutazione' : 'Conferma accettazione valutazione'}
          onConfirm={handleConfirm}
          onAbort={() => modal.close()}
          dismissable
        >
          <Text.Body>
            {isWaitingForSupervisor
              ? `Confermando l'operazione approverai la valutazione di ${formatEuro(prospect.agentEvaluation!)} dell'immobile.`
              : `Confermando l'operazione accetterai la valutazione di ${formatEuro(prospect.agentEvaluation!)} dell'immobile.`}
          </Text.Body>
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default ConfirmProspectApprovalAction;
