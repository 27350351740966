import { Reminder, ReminderV3 } from '../dtos';
import { getClient, API_REMINDERS_PATH, API_REMINDERS_PATH_V3 } from '../api';
import { ReferenceType } from '../../../domain/types';
import {
  PaginatedResult, PaginationParams, serializePagination, SortOrder,
} from '../../pagination';
import { serializeParams } from '../utils/serializer';
import { DEFAULT_PAGINATION_SIZE } from '../../../utils/querystring/querystring';

export interface RemindersProviderFilters {
  referenceId?: string | number,
  referenceType?: ReferenceType,
  agentEmail?: string,
}

export interface RemindersProviderFiltersV3 {
  referenceId?: string | number,
  referenceType?: ReferenceType,
  userEmail?: string,
}

export async function getReminders(
  filters: RemindersProviderFilters = {},
  pagination: PaginationParams = {
    size: DEFAULT_PAGINATION_SIZE,
    sort: {
      createdAt: SortOrder.DESC,
    },
  },
): Promise<PaginatedResult<Reminder>> {
  const client = await getClient();

  const { data } = await client.get<PaginatedResult<Reminder>>(API_REMINDERS_PATH, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function createReminder(reminder: Reminder): Promise<Reminder> {
  const client = await getClient();
  const { data } = await client.post<Reminder>(API_REMINDERS_PATH, reminder);
  return data;
}

export async function updateReminder(reminderId: number, reminder: Reminder): Promise<Reminder> {
  const client = await getClient();
  const { data } = await client.put<Reminder>(`${API_REMINDERS_PATH}/${reminderId}`, reminder);
  return data;
}

export async function deleteReminder(reminderId: number): Promise<{}> {
  const client = await getClient();
  const { data } = await client.delete<{}>(`${API_REMINDERS_PATH}/${reminderId}`);
  return data;
}

export async function createReminderV3(reminder: ReminderV3): Promise<ReminderV3> {
  const client = await getClient();
  const { data } = await client.post<ReminderV3>(API_REMINDERS_PATH_V3, reminder);
  return data;
}

export async function getRemindersV3(
  filters: RemindersProviderFiltersV3 = {},
  pagination: PaginationParams = {
    size: DEFAULT_PAGINATION_SIZE,
    sort: {
      createdAt: SortOrder.DESC,
    },
  },
): Promise<PaginatedResult<ReminderV3>> {
  const client = await getClient();

  const { data } = await client.get<PaginatedResult<ReminderV3>>(API_REMINDERS_PATH_V3, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });

  return data;
}

export async function updateReminderV3(reminderId: number, reminder: ReminderV3): Promise<ReminderV3> {
  const client = await getClient();
  const { data } = await client.put<ReminderV3>(`${API_REMINDERS_PATH_V3}/${reminderId}`, reminder);

  return data;
}

export async function deleteReminderV3(reminderId: number): Promise<{}> {
  const client = await getClient();
  const { data } = await client.delete<{}>(`${API_REMINDERS_PATH_V3}/${reminderId}`);

  return data;
}

export async function deleteRemindersV3(reminderIds: number[]): Promise<{}> {
  const client = await getClient();
  const { data } = await client.delete<{}>(API_REMINDERS_PATH_V3, {
    params: {
      id: reminderIds,
    },
  });

  return data;
}
