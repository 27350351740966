import {
  ThemedFunctor, getTypography, getSpacing, TypographyName, SpacingName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({
  theme,
  $checked,
}) => css`
  ${getTypography(theme, TypographyName.BODY)}
  line-height: 1.2 !important;
  color: ${$checked ? theme.palettes.neutrals.darkest : theme.palettes.neutrals.medium};
  display: inline-flex;
`;

export const icon: ThemedFunctor = ({
  theme,
  $checked,
}) => {
  function getIconColor(checked?: boolean) {
    if (checked === undefined) {
      return theme.palettes.neutrals.medium;
    }

    return checked ? theme.palettes.status.success : theme.palettes.brand.primary;
  }

  return css`
    ${getSpacing.marginRight(theme, SpacingName.SMALLER)}
    color: ${getIconColor($checked)};
    line-height: 1.2;
    flex-shrink: 0;
  `;
};
