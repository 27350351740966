/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  HStack,
  Icon, ICON_BELL_OUTLINE, ICON_CALENDAR_OUTLINE, Popover, Spacing, Text,
} from '@doveit/bricks';
import { DiscreteCalendarEvent } from '../../../hooks/use-calendar-events/useCalendarEvents';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import * as styles from './CalendarEventTableRow.style';
import DateViewer from '../../../components/date-viewer/DateViewer';
import UpdateReminderStatus from '../../../reminders/components/update-reminder-status/UpdateReminderStatus';
import UpdateAppointmentStatus from '../../../reminders/components/update-appointment-status/UpdateAppointmentStatus';
import { CalendarEvent } from '../../../providers/api/dtos';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import UpsertAppointmentAction from '../../../appointment/containers/upsert-appointment-action/UpsertAppointmentAction';
import UpsertReminderAction from '../../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import DeleteReminderAction from '../../../reminders/containers/delete-reminder-action/DeleteReminderAction';

const NOTES_POPOVER_LENGTH_THRESHOLD = 80;

export interface CalendarEventTableRowProps extends React.AriaAttributes {
  calendarEvent: DiscreteCalendarEvent,
  canEditReminder?: boolean,
  canDeleteReminder?: boolean,
  canEditAppointment?: boolean
  onEventUpdate?: (event: CalendarEvent) => void,
  onEventDelete?: (eventId: NonNullable<CalendarEvent['id']>) => void,
}

const CalendarEventTableRow: React.FC<CalendarEventTableRowProps> = ({
  calendarEvent,
  canEditReminder = false,
  canDeleteReminder = false,
  canEditAppointment = false,
  onEventUpdate,
  onEventDelete,
  ...rest
}) => {
  const { type, event } = calendarEvent;

  return (
    <SimpleTable.Row
      data-testid={`calendar-event-table-row-${calendarEvent.event.id}`}
      aria-label="Informazioni evento del calendario"
      {...rest}
    >
      <SimpleTable.Cell aria-label="Data evento">
        <div css={styles.flex}>
          <Icon path={type === 'appointment' ? ICON_CALENDAR_OUTLINE : ICON_BELL_OUTLINE} />
          <Text.BodySmall>
            <DateViewer
              stringDate={event.startDate}
              checkIfToday
              checkIfTomorrow
              withStrong
              withHour
            />
          </Text.BodySmall>
        </div>
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Stato evento">
        {type === 'reminder' && canEditReminder && (
          <UpdateReminderStatus
            reminder={event}
            onSuccess={onEventUpdate}
          />
        )}
        {type === 'appointment' && canEditAppointment && (
          <UpdateAppointmentStatus
            appointment={event}
            onSuccess={onEventUpdate}
          />
        )}
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Note evento" width="20rem">
        {event.notes && event.notes.length > NOTES_POPOVER_LENGTH_THRESHOLD ? (
          <Popover
            hasArrow
            aria-label="Resoconto nota"
            trigger={(
              <Text.Caption>
                {truncateTextWithSuffix(event.notes, NOTES_POPOVER_LENGTH_THRESHOLD)}
              </Text.Caption>
            )}
          >
            <Spacing
              padding={200}
              css={styles.notesPopover}
            >
              <Text.Caption>{event.notes}</Text.Caption>
            </Spacing>
          </Popover>
        ) : <Text.Caption>{event.notes}</Text.Caption>}
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Azioni"
        align="right"
      >
        <HStack gap={75} aria-label="Azioni degli eventi di calendario">
          {type === 'appointment' && canEditAppointment && (
            <UpsertAppointmentAction
              aria-label="Pulsante di modifica dell'appuntamento"
              appointment={event}
              onSuccess={onEventUpdate}
              withStatus={false}
            />
          )}
          {type === 'reminder' && (
            <div css={styles.flex}>
              {canEditReminder && (
                <UpsertReminderAction
                  aria-label="Pulsante di modifica del promemoria"
                  reminder={event}
                  onSuccess={onEventUpdate}
                />
              )}
              {canDeleteReminder && (
                <DeleteReminderAction
                  aria-label="Pulsante di cancellazione del promemoria"
                  reminderId={event.id!}
                  onSuccess={onEventDelete}
                />
              )}
            </div>
          )}
        </HStack>
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default CalendarEventTableRow;
