import { Department, JobApplicationStatus } from '../../../domain/types';
import {
  PaginatedResult, PaginationParams, serializePagination,
} from '../../pagination';
import { API_JOB_APPLICATIONS_PATH, getClient } from '../api';
import { JobApplication } from '../dtos';
import { serializeParams } from '../utils/serializer';

export interface JobApplicationFilters {
  applicantId?: number | number[],
  status?: JobApplicationStatus | JobApplicationStatus[],
  department?: Department,
  assignee?: number,
  province?: string,
  license?: boolean,
}

export async function getJobApplication(jobApplicationId: string): Promise<JobApplication> {
  const client = await getClient();
  const { data } = await client.get<JobApplication>(`${API_JOB_APPLICATIONS_PATH}/${jobApplicationId}`);
  return data;
}

export async function getJobApplications(filters: JobApplicationFilters = {}, pagination: PaginationParams = { size: 50 }): Promise<PaginatedResult<JobApplication>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<JobApplication>>(API_JOB_APPLICATIONS_PATH,
    {
      params: {
        ...serializeParams(filters),
        ...serializePagination(pagination),
      },
    });
  return data;
}

export async function searchJobApplications(
  query: string,
  assignee?: number,
  pagination: PaginationParams = {},
): Promise<PaginatedResult<JobApplication>> {
  const client = await getClient();

  const { data } = await client.get<PaginatedResult<JobApplication>>(`${API_JOB_APPLICATIONS_PATH}/search`, {
    params: serializeParams({
      q: query,
      assignee,
      ...serializePagination(pagination),
    }),
  });

  return data;
}

export async function createJobApplication(jobApplication: JobApplication): Promise<JobApplication> {
  const client = await getClient();
  const { data } = await client.post<JobApplication>(`${API_JOB_APPLICATIONS_PATH}`, jobApplication);
  return data;
}

export async function updateJobApplication(id: string, jobApplication: JobApplication): Promise<JobApplication> {
  const client = await getClient();
  const { data } = await client.put<JobApplication>(`${API_JOB_APPLICATIONS_PATH}/${id}`, jobApplication);
  return data;
}
