import { SWRResponse } from 'swr';
import { Contact } from '../../../providers/api/dtos';
import { IntentStatus } from '../../../domain/types';
import useContact from '../../../contact/hooks/use-contact/useContact';
import useIntents from '../../../intent/hooks/use-intents/useIntents';

export function usePropertyBuyer(
  propertyId?: number,
): SWRResponse<Contact, Error> {
  const intentsResult = useIntents({ propertyId, status: IntentStatus.BOUGHT });
  const contactResult = useContact(intentsResult.data?.content[0]?.contactId);

  if (intentsResult.error) {
    return {
      ...contactResult,
      error: intentsResult.error,
    };
  }

  return contactResult;
}
