import { calculateAspectRatio, ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const virtualTourPlaceholder: ThemedFunctor = ({
  theme,
  $width,
  $height,
}) => css`
  background: ${theme.color.background.neutral.default.low};
  position: relative;
  color: ${theme.color.background.neutral.hover.high}; // @TODO: review color palettes to add neutral.default.medium

  > div:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  > div:last-child {
    aspect-ratio: ${calculateAspectRatio($width, $height, '/')}
  }
`;
