import {
  ActionIcon,
  BreakpointQueryName,
  ConfirmModal,
  HStack,
  ICON_EYE_OUTLINE,
  ICON_TRASH_CAN_OUTLINE,
  ModalSize,
  Portal,
  Spacing,
  Stack,
  Typography,
  useModal,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useState } from 'react';
import update from 'immutability-helper';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import Card from '../../../components/card/Card';
import FileUploadCard from '../../../components/file-upload-card/FileUploadCard';
import FileUploadManager, { UploadModel } from '../../../components/file-upload-manager/FileUploadManager';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { createDocumentFile, deleteDocumentFile } from '../../../providers/api/document-file/documentFileProvider';
import { DocumentFile } from '../../../providers/api/dtos';
import { duplicatedFileNameValidator, getNormalizedFileName } from '../../../utils/file/file';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import DocumentFilePreview from '../../../document/components/document-file-preview/DocumentFilePreview';
import { FileMimeType } from '../../../types';
import { DocumentStatus } from '../../../domain/types';

export interface DocumentFileManagerProps {
  documentId: number,
  documentStatus: DocumentStatus,
  existingFiles: DocumentFile[],
  onCreateSuccess?: (file: DocumentFile) => void,
  onDeleteSuccess?: (fileId: number) => void,
}

export const ALLOWED_MIME_TYPES = [
  FileMimeType.PDF,
  FileMimeType.JPEG,
  FileMimeType.PNG,
];

export enum DocumentFileOperationType {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

interface DocumentFileOperation {
  success: boolean,
  error: boolean,
  message?: string,
  type: DocumentFileOperationType,
  file: DocumentFile,
}

function toDocumentFileOperation(file: DocumentFile): DocumentFileOperation {
  return ({
    success: true,
    error: false,
    file,
    type: DocumentFileOperationType.ADD,
  });
}

const DocumentFileManager: FunctionComponent<DocumentFileManagerProps> = ({
  documentId,
  documentStatus,
  existingFiles,
  onCreateSuccess,
  onDeleteSuccess,
}) => {
  const { user } = useRBAC();
  const [documentFileOperations, setDocumentFileOperations] = useState(existingFiles.map(toDocumentFileOperation));
  const previewModal = useModal<DocumentFile | undefined>();
  const confirmDeleteModal = useModal<DocumentFile | undefined>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  const onFileUploadRequest = useCallback(async (_: UploadModel, file: File) => {
    const documentFile = await createDocumentFile({ documentId, createdBy: user!.email }, file);
    return documentFile;
  }, [documentId, user]);

  const onFileUploadSuccess = useCallback((_: UploadModel, documentFile: DocumentFile) => {
    setDocumentFileOperations((prevState) => [toDocumentFileOperation(documentFile), ...prevState]);

    if (onCreateSuccess) {
      onCreateSuccess(documentFile);
    }
  }, [onCreateSuccess]);

  const openPreview = useCallback((documentFile: DocumentFile) => () => {
    previewModal.open(documentFile);
  }, [previewModal]);

  const confirmFileDelete = useCallback((documentFileToRemove: DocumentFile) => () => {
    confirmDeleteModal.open(documentFileToRemove);
  }, [confirmDeleteModal]);

  const deleteFile = useCallback((toRemovedId: number) => async () => {
    confirmDeleteModal.close();
    const indexToRemove = documentFileOperations.findIndex((operation) => operation.file.id === toRemovedId);

    setDocumentFileOperations((prevState) => update(prevState, {
      [indexToRemove]: {
        success: { $set: false },
        error: { $set: false },
        type: { $set: DocumentFileOperationType.DELETE },
      },
    }));

    try {
      await deleteDocumentFile(toRemovedId);

      setDocumentFileOperations((prevState) => update(prevState, {
        [indexToRemove]: {
          success: { $set: true },
          message: { $set: 'Eliminato' },
        },
      }));

      if (onDeleteSuccess) {
        onDeleteSuccess(toRemovedId);
      }
    } catch (error) {
      setDocumentFileOperations((prevState) => update(prevState, {
        [indexToRemove]: {
          error: { $set: true },
          message: { $set: 'Errore durante l\'eliminazione' },
        },
      }));
    }
  }, [confirmDeleteModal, documentFileOperations, onDeleteSuccess]);

  const existingFilesDuplicatesValidator = useCallback(
    (files: DocumentFile[]) => (file: File) => {
      const existingFileNames = files.map((existingFile) => existingFile.filename);

      return duplicatedFileNameValidator(
        file.name,
        existingFileNames,
        getNormalizedFileName,
      );
    },
    [],
  );

  return (
    <>
      <Card>
        <Card.Box>
          <FileUploadManager
            onFileUploadRequest={onFileUploadRequest}
            onFileUploadSuccess={onFileUploadSuccess}
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            validator={existingFilesDuplicatesValidator(existingFiles)}
            render={{
              queue: (uploads) => (
                <Spacing margin={[200, 0, 0]}>
                  <Stack>
                    {uploads.filter((upload) => !upload.success).map((upload) => (
                      <FileUploadCard
                        key={upload.fileName}
                        {...upload}
                      />
                    ))}
                    {documentFileOperations
                      .filter((operation) => !(operation.type === DocumentFileOperationType.DELETE && operation.success))
                      .map(({
                        file, success, error, message,
                      }) => (
                        <FileUploadCard
                          key={file.id}
                          fileName={file.filename}
                          success={success}
                          error={error}
                          message={message}
                          actions={(
                            <HStack>
                              {(isMobile && file.mimeType === FileMimeType.PDF) ? (
                                <ActionIcon
                                  icon={{ path: ICON_EYE_OUTLINE }}
                                  label="Visualizza"
                                  size="S"
                                  href={file.url}
                                  target="_blank"
                                />
                              ) : (
                                <ActionIcon
                                  icon={{ path: ICON_EYE_OUTLINE }}
                                  label="Visualizza"
                                  size="S"
                                  onClick={openPreview(file)}
                                />
                              )}

                              <ActionIcon
                                label="Elimina"
                                icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                                color="critical"
                                size="S"
                                onClick={confirmFileDelete(file)}
                                disabled={documentStatus === DocumentStatus.APPROVED || (!success && !error)}
                              />
                            </HStack>
                          )}
                        />
                      ))}
                  </Stack>
                </Spacing>
              ),
            }}
          />
        </Card.Box>
      </Card>
      <Portal>
        {confirmDeleteModal.data?.id && (
          <ConfirmModal
            title="Elimina file"
            isOpen={confirmDeleteModal.isOpen}
            onAbort={confirmDeleteModal.close}
            onConfirm={deleteFile(confirmDeleteModal.data.id)}
          >
            <Typography.BODY>
              Vuoi rimuovere il file &quot;<strong>{confirmDeleteModal.data.filename}</strong>&quot;? L&apos;operazione non puó essere annullata.
            </Typography.BODY>
          </ConfirmModal>
        )}
        {previewModal.data?.id && (
          <SimpleModal
            {...previewModal}
            size={ModalSize.LARGE}
            title={previewModal.data.filename}
            padded={false}
            fillAvailableHeight={previewModal.data.mimeType === FileMimeType.PDF}
          >
            <DocumentFilePreview file={previewModal.data} />
          </SimpleModal>
        )}
      </Portal>
    </>
  );
};

export default DocumentFileManager;
