import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { COUNTRY } from '../../../constants';

export default Yup.object().shape({
  phoneNumber: Yup
    .string()
    .when('email', {
      is: (email: string) => !email || email.length === 0,
      then: (schema) => schema.required('Devi inserire numero di telefono o email'),
      otherwise: (schema) => schema.notRequired(),
    })
    .test('isValid', 'Inserisci un numero di telefono valido', (value: string | undefined) => !value || isValidPhoneNumber(value, COUNTRY)),
  email: Yup
    .string()
    .trim()
    .email('Inserisci un indirizzo email valido')
    .when('phoneNumber', {
      is: (phoneNumber: string) => !phoneNumber || phoneNumber.length === 0,
      then: (schema) => schema.required('Devi inserire email o numero di telefono'),
      otherwise: (schema) => schema.notRequired(),
    }),
  fiscalCode: Yup.string().length(16, 'Il codice fiscale è composto da 16 caratteri.'),
}, [['email', 'phoneNumber']]);
