import React, { FunctionComponent } from 'react';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import ManageConversations from '../../../conversation/containers/manage-conversations/ManageConversations';

const CallCenterMessageCenterPage: FunctionComponent = () => (
  <GenericPageLayout>
    <GenericPageLayout.Content fitMaxAvailableHeight>
      <ManageConversations />
    </GenericPageLayout.Content>
  </GenericPageLayout>
);

export default CallCenterMessageCenterPage;
