import React from 'react';
import { AssignmentWizardStepProps } from '../types';
import { toAssignmentWizardData, toAssignmentWizardPropertyType } from './mappers';
import PropertyOwnershipTypeForm, { PropertyOwnershipTypeFormModel } from '../../property-ownership-type-form/PropertyOwnershipTypeForm';

const AssignmentWizardPropertyType: React.FC<AssignmentWizardStepProps> = ({
  initialData,
  formRef,
  onSubmit,
}) => {
  const onInternalSubmit = React.useCallback((formValues: PropertyOwnershipTypeFormModel) => {
    const values = toAssignmentWizardData(initialData, formValues);

    const isFormDirty = (formRef?.current as any)?.dirty ?? false;

    onSubmit(values, isFormDirty);
  }, [formRef, initialData, onSubmit]);

  return (
    <PropertyOwnershipTypeForm
      initialValues={toAssignmentWizardPropertyType(initialData)}
      innerRef={formRef}
      onSubmit={onInternalSubmit}
    />
  );
};

export default AssignmentWizardPropertyType;
