import React, { FunctionComponent } from 'react';
import { Badge, BadgeProps } from '@doveit/bricks';
import { Status } from '../../../domain/types';
import { statusLabels } from '../../../labels';

export const badgeColor: Record<Status, BadgeProps['color']> = {
  LIVE: 'info',
  DRAFT: 'warning',
  ANTEPRIMA: 'warning',
  VENDUTO: 'success',
  RITIRATO: 'critical',
  PROPOSTA: 'info',
};

export interface PropertyStatusBadgeProps {
  status: Status,
  size?: BadgeProps['size']
}

const PropertyStatusBadge: FunctionComponent<PropertyStatusBadgeProps> = ({
  status,
  size,
}) => (
  <Badge
    color={badgeColor[status]}
    label={statusLabels[status]}
    size={size}
  />
);

export default PropertyStatusBadge;
