import React from 'react';
import { Message, Spacing } from '@doveit/bricks';
import { AssignmentWizardStepProps } from '../types';
import { toAssignmentWizardData, toStepFormModel } from './mappers';
import AssignmentWizardPlanimetryDelegationForm, { AssignmentWizardPlanimetryDelegationFormModel } from './AssignmentWizardPlanimetryDelegationForm';

const NO_CONTACTS_INFO_MESSAGE = 'Devi inserire almeno un venditore (step venditore) per attivare la delega.';

const AssignmentWizardPlanimetryDelegation: React.FC<AssignmentWizardStepProps> = ({
  initialData,
  loading = false,
  formRef,
  onSubmit,
}) => {
  const onInternalSubmit = React.useCallback((formValues: AssignmentWizardPlanimetryDelegationFormModel) => {
    const values = toAssignmentWizardData(initialData, formValues);

    const isFormDirty = (formRef?.current as any)?.dirty ?? false;

    onSubmit(values, isFormDirty);
  }, [formRef, initialData, onSubmit]);

  return (
    <>
      {(initialData.contacts || []).length === 0 && (
        <Spacing margin={[0, 0, 200]}>
          <Message
            type="info"
            message={NO_CONTACTS_INFO_MESSAGE}
          />
        </Spacing>
      )}
      <AssignmentWizardPlanimetryDelegationForm
        initialValues={toStepFormModel(initialData)}
        formRef={formRef}
        onSubmit={onInternalSubmit}
        loading={loading}
        contacts={initialData.contacts}
      />
    </>
  );
};

export default AssignmentWizardPlanimetryDelegation;
