import {
  Form, FormProps, FormSubmitType,
} from '@doveit/bricks';
import * as Yup from 'yup';
import React from 'react';
import { BooleanString } from '../../../../utils/boolean-string/booleanString';
import defaultValidationSchema from './CreateAssignmentForm.schema';
import { INPUT_PLACEHOLDERS } from '../../../../constants';
import { formatInputDate } from '../../../../utils/form';

export interface CreateAssignmentFormModel {
  price: string,
  minimumPrice: string,
  evaluation: string,
  autoRenew: BooleanString,
  exclusive: BooleanString,
  keysHanded: BooleanString,
  isMortgageInProgress: BooleanString,
  residualMortgage: string,
  endDate: string,
  hasLien: BooleanString,
  lienValue: string
}

export interface CreateAssignmentFormProps {
  initialValues?: Partial<CreateAssignmentFormModel>,
  loading?: FormProps<CreateAssignmentFormModel>['loading'],
  disabled?: FormProps<CreateAssignmentFormModel>['disabled'],
  canEditEvaluation?: boolean,
  onSubmit: FormSubmitType<CreateAssignmentFormModel>,
  formRef: FormProps<CreateAssignmentFormModel>['innerRef'],
  validationSchema?: Yup.AnySchema;
}

const DEFAULT_INITIAL_VALUES: CreateAssignmentFormModel = {
  evaluation: '',
  price: '',
  minimumPrice: '',
  exclusive: '',
  autoRenew: '',
  keysHanded: '',
  endDate: '',
  isMortgageInProgress: '',
  residualMortgage: '',
  hasLien: '',
  lienValue: '',
};

const CreateAssignmentForm: React.FC<CreateAssignmentFormProps> = ({
  initialValues = {},
  canEditEvaluation = true,
  formRef,
  validationSchema,
  ...rest
}) => {
  const mergedInitialValues: CreateAssignmentFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={validationSchema || defaultValidationSchema}
      innerRef={formRef}
      enableReinitialize
      {...rest}
    >
      {({ values, setFieldValue }) => {
        const onIsMortgageInProgressChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
          if (target.value === 'false' && values.residualMortgage.length > 0) {
            setFieldValue('residualMortgage', '');
          }
        };

        const onHasLienChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
          if (target.value === 'false') {
            setFieldValue('lienValue', '');
          }
        };

        return (
          <Form.Fieldset legend="Dati mandato">
            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.PriceField
                name="price"
                label="Prezzo di pubblicazione"
                aria-label="Campo per inserire il prezzo di pubblicazione dell'immobile"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_PRICE}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.PriceField
                name="minimumPrice"
                label="Prezzo di mandato"
                aria-label="Campo per inserire il prezzo del mandato"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_MINIMUM_PRICE}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.PriceField
                name="evaluation"
                label="Valutazione"
                aria-label="Campo per inserire la valutazione"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_EVALUATION_PRICE}
                disabled={!canEditEvaluation}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.YesOrNotButtons
                name="exclusive"
                label="In esclusiva"
                aria-label="In esclusiva"
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.YesOrNotButtons
                name="autoRenew"
                label="Rinnovo automatico"
                aria-label="rinnovo automatico"
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.YesOrNotButtons
                name="keysHanded"
                label="Chiavi consegnate"
                aria-label="chiavi consegnate"
              />
            </Form.Item>

            <Form.Group>
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.YesOrNotButtons
                  name="isMortgageInProgress"
                  label="Mutuo in corso"
                  aria-label="mutuo in corso"
                  onChange={onIsMortgageInProgressChange}
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.DecimalPriceField
                  name="residualMortgage"
                  label="Mutuo residuo"
                  aria-label="Campo per inserire il mutuo residuo"
                  placeholder={INPUT_PLACEHOLDERS.PROPERTY_RESIDUAL_MORTGAGE}
                  disabled={values.isMortgageInProgress !== 'true'}
                />
              </Form.Item>
            </Form.Group>

            <Form.Group>
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.YesOrNotButtons
                  name="hasLien"
                  label="Ipoteca"
                  aria-label="ipoteca"
                  onChange={onHasLienChange}
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.DecimalPriceField
                  name="lienValue"
                  label="Valore ipoteca"
                  aria-label="Campo per inserire il valore dell'ipoteca"
                  placeholder={INPUT_PLACEHOLDERS.PROPERTY_LIEN_VALUE}
                  disabled={values.hasLien !== 'true'}
                />
              </Form.Item>
            </Form.Group>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.Input
                name="endDate"
                label="Data di fine mandato"
                type="date"
                aria-label="Data di fine mandato"
                min={formatInputDate(new Date())}
              />
            </Form.Item>
          </Form.Fieldset>
        );
      }}
    </Form>
  );
};

export default CreateAssignmentForm;
