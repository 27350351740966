import {
  DOVEIT_EXTRA_PACKAGES_DEFAULT_VALUES, ExtraPackage, ExtraPackageDoveIt, ExtraPackagePriceType, ExtraPackageProvider, ExtraPackageSPF, SPF_EXTRA_PACKAGES_DEFAULT_VALUES,
} from '../../../domain/types';
import { Assignment, AssignmentWizardData } from '../../../providers/api/dtos/assignment';
import { AssignmentWizardPackagesFormModel } from '../components/assignment-wizard/assignment-wizard-packages/AssignmentWizardPackagesForm';
import { EditPropertyAssignmentFormModel } from '../components/edit-property-assignment-form/EditPropertyAssignmentForm';
import { Agent } from '../../../providers/api/dtos';
import { formatInputDate } from '../../../utils/form';

function computeDoveItExtraPackages(extraPackages: ExtraPackage[] = []): AssignmentWizardPackagesFormModel['doveItPackages'] {
  return extraPackages
    .filter((p) => p.providedBy === ExtraPackageProvider.DOVE_IT)
    .map((packageItem) => ({
      type: packageItem.name as ExtraPackageDoveIt,
      price: packageItem?.price?.toString() ?? '',
      priceType: packageItem?.priceType ?? ExtraPackagePriceType.FIXED,
      payment: packageItem?.payment ?? '',
    }));
}

function computeSpfExtraPackages(extraPackages: ExtraPackage[] = []): AssignmentWizardPackagesFormModel['spfPackages'] {
  return extraPackages
    .filter((p) => p.providedBy === ExtraPackageProvider.SPF)
    .map((packageItem) => ({
      type: packageItem.name as ExtraPackageSPF,
    }));
}

export function computeExtraPackages(
  DOVE_IT: AssignmentWizardPackagesFormModel['doveItPackages'],
  SPF: AssignmentWizardPackagesFormModel['spfPackages'],
): ExtraPackage[] {
  const extraPackages: ExtraPackage[] = [];

  DOVE_IT.forEach(({
    type, price, priceType, payment,
  }) => {
    extraPackages.push({
      id: null,
      name: type,
      price: price ? parseFloat(price) : DOVEIT_EXTRA_PACKAGES_DEFAULT_VALUES[type],
      priceType,
      providedBy: ExtraPackageProvider.DOVE_IT,
      legacy: false,
      payment: payment || undefined,
    });
  });

  SPF.forEach(({ type }) => {
    extraPackages.push({
      id: null,
      name: type,
      price: SPF_EXTRA_PACKAGES_DEFAULT_VALUES[type],
      priceType: ExtraPackagePriceType.FIXED,
      providedBy: ExtraPackageProvider.SPF,
      legacy: false,
      payment: undefined,
    });
  });

  return extraPackages;
}

export function toAssignmentWizardPackagesFormModel(initialData: AssignmentWizardData): AssignmentWizardPackagesFormModel {
  const { assignment } = initialData;
  return {
    noPackagesDoveIt: typeof assignment?.extraPackagesDoveItEnabled === 'undefined' ? false : !assignment.extraPackagesDoveItEnabled,
    noPackagesSPF: typeof assignment?.extraPackagesSPFEnabled === 'undefined' ? false : !assignment.extraPackagesSPFEnabled,
    doveItPackages: computeDoveItExtraPackages(assignment?.extraPackages),
    spfPackages: computeSpfExtraPackages(assignment?.extraPackages),
  };
}

export function toPackagesFormModel(extraPackages: ExtraPackage[]): AssignmentWizardPackagesFormModel {
  return {
    noPackagesDoveIt: !extraPackages.some((item) => item.providedBy === ExtraPackageProvider.DOVE_IT),
    noPackagesSPF: !extraPackages.some((item) => item.providedBy === ExtraPackageProvider.SPF),
    doveItPackages: computeDoveItExtraPackages(extraPackages),
    spfPackages: computeSpfExtraPackages(extraPackages),
  };
}

export function toAssignmentWizardData(
  initialValues: AssignmentWizardData,
  formValues: AssignmentWizardPackagesFormModel,
): AssignmentWizardData {
  const {
    noPackagesDoveIt,
    noPackagesSPF,
    doveItPackages,
    spfPackages,
  } = formValues;
  return {
    ...initialValues,
    assignment: {
      ...initialValues.assignment,
      extraPackages: computeExtraPackages(doveItPackages, spfPackages),
      extraPackagesDoveItEnabled: !noPackagesDoveIt,
      extraPackagesSPFEnabled: !noPackagesSPF,
    },
  };
}

export function toPropertyAssignmentModel(
  assignment: Assignment,
  assignee?: Agent,
): EditPropertyAssignmentFormModel {
  return {
    id: assignment.id,
    assignee,
    exclusive: assignment.exclusive,
    autoRenew: assignment.autoRenew,
    keysHanded: assignment.keysHanded,
    endDate: assignment.endDate ? formatInputDate(new Date(assignment.endDate)) : undefined,
    prospectId: assignment.prospectId,
  };
}

export function toAssignment(
  assignmentFormModel: EditPropertyAssignmentFormModel,
  propertyId: number,
  assignment: Assignment,
): Assignment {
  return {
    ...assignment,
    id: assignmentFormModel.id!,
    propertyId,
    agentId: assignmentFormModel.assignee!.id!,
    exclusive: assignmentFormModel.exclusive,
    autoRenew: assignmentFormModel.autoRenew,
    keysHanded: assignmentFormModel.keysHanded,
    endDate: assignmentFormModel.endDate ? new Date(assignmentFormModel.endDate).toISOString() : undefined,
    prospectId: assignmentFormModel.prospectId,
  };
}
