import { LeadSource } from '../domain/types';

export const leadSourceLabels: { [key in LeadSource]: string } = {
  [LeadSource.WEBSITE]: 'Dove.it organic',
  [LeadSource.ADWORDS]: 'Google-ads',
  [LeadSource.GOOGLE]: 'Google-ads',
  [LeadSource.FACEBOOK]: 'Facebook',
  [LeadSource.EMAIL]: 'Email',
  [LeadSource.IMMOBILIARE]: 'Immobiliare.it',
  [LeadSource.CASA]: 'Casa.it',
  [LeadSource.IDEALISTA]: 'Idealista',
  [LeadSource.REQUOT]: 'Requot',
  [LeadSource.FACILE]: 'Facile.it',
  [LeadSource.BAKECA]: 'Bakeca.it',
  [LeadSource.BORSINO]: 'Borsino',
  [LeadSource.CASAVO]: 'Casavo',
  [LeadSource.INBOUND]: 'Inbound',
  [LeadSource.B2B]: 'B2B',
  [LeadSource.BENEFITHUB]: 'Benefithub',
  [LeadSource.PERSONAL]: 'Personale',
  [LeadSource.REFERRAL]: 'Dove.it referral',
  [LeadSource.SALES_REFERRAL]: 'Agent referral',
  [LeadSource.SUBITO]: 'Subito.it',
  [LeadSource.WIKICASA]: 'Wikicasa',
  [LeadSource.PUNTOZERO]: 'Puntozero',
  [LeadSource.NETTBUREAU]: 'Nettbureau',
  [LeadSource.INTENT]: 'Interesse',
  [LeadSource.ZAPPYRENT]: 'Zappyrent',
  [LeadSource.COLD_LEAD]: 'Lead fredde',
};
