import {
  BadgeProps, ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE, ICON_PROGRESS_CHECK,
} from '@doveit/bricks';
import { intentStatusBadgeColor } from '../../components/intent-status-badge/IntentStatusBadge';
import { intentStatusLabels } from '../../../labels';
import { IntentStatusAction } from './types';
import { ComputedIntentStatus } from '../../hooks/use-computed-intent-status/useComputedIntentStatus';

export const computedIntentStatusLabels: Record<ComputedIntentStatus, string> = {
  ...intentStatusLabels,
  IN_PROGRESS: 'Da lavorare',
  WITH_FUTURE_APPOINTMENT: 'Appuntamento',
  WITH_PAST_APPOINTMENT: 'Appuntamento',
  WITH_CANCELLED_APPOINTMENT: 'App. annullato',
  WITH_OFFER: 'In contrattazione',
};

export const updateIntentActionStatusColors: Record<ComputedIntentStatus, BadgeProps['color']> = {
  ...intentStatusBadgeColor,
  WITH_FUTURE_APPOINTMENT: 'info',
  WITH_PAST_APPOINTMENT: 'info',
  WITH_CANCELLED_APPOINTMENT: 'warning',
  WITH_OFFER: 'success',
};

export const updateIntentActionStatusIcon: Record<ComputedIntentStatus, string> = {
  IN_PROGRESS: ICON_PROGRESS_CHECK,
  WITH_FUTURE_APPOINTMENT: ICON_PROGRESS_CHECK,
  WITH_PAST_APPOINTMENT: ICON_PROGRESS_CHECK,
  WITH_CANCELLED_APPOINTMENT: ICON_ALERT_CIRCLE_OUTLINE,
  WITH_OFFER: ICON_PROGRESS_CHECK,
  BOUGHT: ICON_CHECK_CIRCLE_OUTLINE,
  KO_DUPLICATE: ICON_ALERT_CIRCLE_OUTLINE,
  KO_INFO: ICON_ALERT_CIRCLE_OUTLINE,
  KO_OVERPRICED: ICON_ALERT_CIRCLE_OUTLINE,
  KO_MORTGAGE: ICON_ALERT_CIRCLE_OUTLINE,
  KO_PROPERTY: ICON_ALERT_CIRCLE_OUTLINE,
  KO_PROPERTY_NOT_AVAILABLE: ICON_ALERT_CIRCLE_OUTLINE,
  KO_BOUGHT_OTHER: ICON_ALERT_CIRCLE_OUTLINE,
  KO_AREA: ICON_ALERT_CIRCLE_OUTLINE,
  KO_REFUSED: ICON_ALERT_CIRCLE_OUTLINE,
  KO_NOT_INTERESTED: ICON_ALERT_CIRCLE_OUTLINE,
  KO_OTHER_CONSTRAINTS: ICON_ALERT_CIRCLE_OUTLINE,
};

export const intentStatusActionLabels: Record<IntentStatusAction, string> = {
  ADD_APPOINTMENT: 'Aggiungi appuntamento',
  ADD_OFFER: 'Aggiungi offerta',
  CANCEL_APPOINTMENT: 'Annulla appuntamento',
  ARCHIVE_INTENT: 'Archivia interesse',
  RESTORE_INTENT: 'Ripristina interesse',
};

export const intentDropDownActionsByStatus: Record<ComputedIntentStatus, IntentStatusAction[]> = {
  IN_PROGRESS: [IntentStatusAction.ADD_APPOINTMENT, IntentStatusAction.ADD_OFFER, IntentStatusAction.ARCHIVE_INTENT],
  WITH_FUTURE_APPOINTMENT: [IntentStatusAction.CANCEL_APPOINTMENT, IntentStatusAction.ADD_OFFER, IntentStatusAction.ARCHIVE_INTENT],
  WITH_PAST_APPOINTMENT: [IntentStatusAction.ADD_APPOINTMENT, IntentStatusAction.ADD_OFFER, IntentStatusAction.ARCHIVE_INTENT],
  WITH_CANCELLED_APPOINTMENT: [IntentStatusAction.ADD_APPOINTMENT, IntentStatusAction.ADD_OFFER, IntentStatusAction.ARCHIVE_INTENT],
  WITH_OFFER: [],
  BOUGHT: [],
  KO_AREA: [IntentStatusAction.RESTORE_INTENT],
  KO_BOUGHT_OTHER: [IntentStatusAction.RESTORE_INTENT],
  KO_INFO: [IntentStatusAction.RESTORE_INTENT],
  KO_NOT_INTERESTED: [IntentStatusAction.RESTORE_INTENT],
  KO_OVERPRICED: [IntentStatusAction.RESTORE_INTENT],
  KO_PROPERTY: [IntentStatusAction.RESTORE_INTENT],
  KO_REFUSED: [IntentStatusAction.RESTORE_INTENT],
  KO_MORTGAGE: [IntentStatusAction.RESTORE_INTENT],
  KO_OTHER_CONSTRAINTS: [IntentStatusAction.RESTORE_INTENT],
  KO_DUPLICATE: [],
  KO_PROPERTY_NOT_AVAILABLE: [],
};
