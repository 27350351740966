import { JobTitle } from '../domain/types';

export const jobTitleLabels: Record<JobTitle, string> = {
  AGENTE: 'Agente',
  AREA_MANAGER: 'Area Manager',
  BROKER: 'Broker',
  CONSULENTE: 'Consulente',
  EXECUTIVE: 'Executive',
  TEAM_LEADER: 'Team Leader',
};
