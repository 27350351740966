/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unknown-property */
import {
  Text,
  HStack,
  Pictogram,
} from '@doveit/bricks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as styles from './ViewMainNavigation.style';
import ApplicationNotificationsStatus from '../../application-notification/containers/application-notifications-status/ApplicationNotificationsStatus';
import useRBAC from '../../hooks/use-rbac/useRBAC';

export interface ViewMainNavigationProps extends React.Attributes {
  title?: string,
  onLinkClick?: VoidFunction,
}

export const ViewMainNavigation: React.FC<ViewMainNavigationProps> = ({
  title,
  onLinkClick,
  ...rest
}) => {
  const { userIsContentEditor, userIsAgent } = useRBAC();
  const navigate = useNavigate();

  const showNotificationBell = React.useMemo(
    () => userIsContentEditor || userIsAgent,
    [userIsAgent, userIsContentEditor],
  );

  const onLogoClick = React.useCallback(
    () => {
      onLinkClick?.();
      navigate('/');
    },
    [navigate, onLinkClick],
  );

  return (
    <div
      {...rest}
      css={styles.base}
    >
      <HStack alignItems="center" justifyContent="space-between">
        <HStack alignItems="center" gap={100}>
          <button
            css={styles.pictogram}
            onClick={onLogoClick}
          >
            <Pictogram />
          </button>
          {title && (
            <Text.H4 color="neutral.default.lowest">{title}</Text.H4>
          )}
        </HStack>
      </HStack>
      {showNotificationBell && (
        <div>
          <ApplicationNotificationsStatus onClick={onLinkClick} />
        </div>
      )}
    </div>
  );
};

export default ViewMainNavigation;
