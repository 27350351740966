import {
  ThemedFunctor,
  SpacingName,
  getSpacing,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const header: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.padding(theme, SpacingName.SMALLER)}
  display: flex;
  gap: .5rem;
  align-items: center;
  border-top: 1px solid ${theme.palettes.neutrals.light};
`;

export const status: ThemedFunctor = () => css`
  flex-shrink: 0;
`;

export const box: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.padding(theme, SpacingName.SMALL)}
  background-color: ${theme.palettes.neutrals.dark};
  display: flex;
  justify-content: center;
`;
