/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent } from 'react';
import { ICON_STAIRS, Icon } from '@doveit/bricks';
import { formatEuro, formatSquareMeters } from '@doveit/hammer';
import { DEFAULT_MARKER_ICON } from '../../../constants';
import { CrawledProperty } from '../../../providers/api/dtos/crawledProperties';
import getIconByPropertyType from '../../../utils/get-icon-by-property-type/getIconByPropertyType';
import { aggregatorLabels } from '../../../labels/aggregatorLabels';
import getCrawledPropertyColor from '../../utils/get-crawled-property-color/getCrawledPropertyColor';
import { ResidentialPropertyTypeValue } from '../../../domain/types';
import { formatFloor } from '../../../property/utils';
import * as styles from './CrawledPropertyCard.style';

export interface CrawledPropertyCardProps {
  data: CrawledProperty
}

const CrawledPropertyCard: FunctionComponent<CrawledPropertyCardProps> = ({
  data,
}) => (
  <div css={styles.main}>
    <div
      css={styles.pin}
      data-ref="pill"
      $backgroundColor={getCrawledPropertyColor(data.expired, data.address)}
    >
      <Icon
        path={getIconByPropertyType(data.propertyType ?? ResidentialPropertyTypeValue.APPARTAMENTO)}
        size={DEFAULT_MARKER_ICON}
      />
    </div>
    <div css={styles.info}>
      <div css={styles.address}>
        {data.address ?? data.locality}
      </div>
      <div css={styles.details}>
        <div css={styles.detailsLeft}>
          {data.floor !== undefined && data.floor !== null && (
            <>
              <span>
                <Icon
                  path={ICON_STAIRS}
                  size={16}
                />
                {formatFloor(data.floor, true)}
              </span>
              {' · '}
            </>
          )}
          <span>
            {formatSquareMeters(data.size)}
          </span>
          {' · '}
          <span>
            {formatEuro(data.price)}
          </span>
          {!data.expired && (
            <>
              {' · '}
              <span>
                <a
                  href={data.url}
                  target="_blank"
                  rel="noreferrer"
                  css={styles.link}
                >
                  {aggregatorLabels[data.aggregator]}
                </a>
              </span>
            </>
          )}
        </div>
        <div css={styles.detailsRight}>
          {data.distance} m
        </div>
      </div>
    </div>
  </div>
);

export default CrawledPropertyCard;
