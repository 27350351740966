import { OptInType } from '../../domain/types';
import { optInTypeLabels } from '../../labels';
import { OptIn } from '../../providers/api/dtos';
import { SortOrder } from '../../providers/pagination';

function toSortingOrder(optInType: OptInType) : number {
  return optInTypeLabels[optInType].sortingOrder;
}

export default function sortOptIns(optIns: OptIn[], sort: SortOrder = SortOrder.ASC) {
  const sortedOptIns = [...optIns].sort((a, b) => toSortingOrder(a.type) - toSortingOrder(b.type));

  return (sort === SortOrder.ASC) ? sortedOptIns : sortedOptIns.reverse();
}
