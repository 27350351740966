import * as Yup from 'yup';
import { JobTitle } from '../../../domain/types';

const editAgentDetailsFormValidationSchema = Yup.object({
  jobTitle: Yup
    .string()
    .trim()
    .required('Il campo è obbligatorio'),
  hasLicense: Yup
    .boolean()
    .required('Il campo è obbligatorio')
    .test(
      'validHasLicense',
      'Inserisci un valore valido',
      (value, ctx) => {
        const { jobTitle, needsSupervisor } = ctx.parent;

        switch (jobTitle) {
          case JobTitle.AGENTE: return value;
          case JobTitle.AREA_MANAGER: return value;
          case JobTitle.CONSULENTE: return !value;
          case JobTitle.BROKER:
          case JobTitle.EXECUTIVE:
          case JobTitle.TEAM_LEADER: return !needsSupervisor ? value : true;
          default: return true;
        }
      },
    ),
  needsSupervisor: Yup
    .boolean()
    .required('Il campo è obbligatorio')
    .test(
      'validNeedsSupervisor',
      'Inserisci un valore valido',
      (value, ctx) => {
        const { jobTitle, hasLicense } = ctx.parent;

        switch (jobTitle) {
          case JobTitle.AREA_MANAGER: return !value;
          case JobTitle.CONSULENTE: return value;
          case JobTitle.BROKER:
          case JobTitle.EXECUTIVE:
          case JobTitle.TEAM_LEADER: return !hasLicense ? value : true;
          default: return true;
        }
      },
    ),
});

export default editAgentDetailsFormValidationSchema;
