import {
  SpacingName,
  ThemedFunctor, getSpacing,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const template: ThemedFunctor = ({ theme, $selected }) => css`
  ${getSpacing.padding(theme, SpacingName.SMALL)}
  background-color: ${theme.palettes.neutrals.light};
  border-radius: 8px;
  cursor: pointer;
  ${$selected && `
    outline: 3px solid ${theme.palettes.brand.primary};
  `}
`;
