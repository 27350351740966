import React, {
  FunctionComponent, useCallback, useState, useMemo,
} from 'react';
import { useNotifications } from '@doveit/bricks';
import { Reminder } from '../../../providers/api/dtos/reminder';
import { createReminder, updateReminder } from '../../../providers/api/reminder/reminderProvider';
import { formDateTimeToDate, formatInputDate, formatInputTime } from '../../../utils/form';
import ReminderForm, { ReminderFormProps } from '../../components/reminder-form/ReminderForm';
import { ReminderFormModel } from '../../../prospect/models/ReminderFormModel';

export const UPSERT_REMINDER_ERROR_MESSAGE = 'Non è stato possibile effettuare l\'operazione';
export const UPSERT_REMINDER_SUCCESS_MESSAGE = 'Operazione effettuata con successo';

export interface UpsertReminderProps {
  reminder: Omit<Reminder, 'startDate' | 'endDate' | 'notes'> & {
    startDate?: Reminder['startDate'],
    endDate?: Reminder['endDate'],
    notes?: Reminder['notes']
  },
  onSuccess?: (reminder: Reminder) => void,
  onError?: VoidFunction,
  formRef?: ReminderFormProps['formRef'],
  hideSubmit?: boolean,
}

const UpsertReminder: FunctionComponent<UpsertReminderProps> = ({
  reminder,
  onSuccess,
  onError,
  formRef,
  hideSubmit,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const initialValues = useMemo(() => {
    if (reminder.id) {
      return {
        id: reminder.id?.toString(),
        date: reminder.startDate ? formatInputDate(new Date(reminder.startDate)) : '',
        time: reminder.startDate ? formatInputTime(new Date(reminder.startDate)) : '',
        notes: reminder.notes ? reminder.notes : '',
      };
    }

    return {};
  }, [reminder]);

  const onSubmit = useCallback(async (values: ReminderFormModel) => {
    setIsSaving(true);

    try {
      const payload: Reminder = {
        ...reminder,
        startDate: formDateTimeToDate(values.date!, values.time!).toISOString(),
        endDate: formDateTimeToDate(values.date!, values.time!).toISOString(),
        notes: values.notes!,
      };

      const result = await (reminder.id
        ? updateReminder(reminder.id, payload)
        : createReminder(payload)
      );

      onSuccess?.(result);

      addSuccess(UPSERT_REMINDER_SUCCESS_MESSAGE);
    } catch (error) {
      onError?.();

      addError(UPSERT_REMINDER_ERROR_MESSAGE);
    }

    setIsSaving(false);
  },
  [onSuccess, reminder, addSuccess, addError, onError]);

  return (
    <ReminderForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      loading={isSaving}
      formRef={formRef}
      hideSubmit={hideSubmit}
    />
  );
};

export default UpsertReminder;
