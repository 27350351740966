import {
  Action, FormHandlers, ModalSize, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Property } from '../../../providers/api/dtos';
import { updateProperty } from '../../../providers/api/property/propertyProvider';
import EditPropertyInfoForm, { EditPropertyInfoFormModel } from '../../components/edit-property-info-form/EditPropertyInfoForm';
import { editPropertyInfoFormModelToProperty, propertyToEditPropertyInfoFormModel } from '../../components/edit-property-info-form/utils';

interface ChildFnProps {
  edit: VoidFunction,
  isSaving: boolean,
}

export interface EditPropertyInfoActionProps {
  property: Property,
  onSuccess?: (updatedProperty: Property) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_PROPERTY_INFO_SUCCESS_MESSAGE = "I dati dell'immobile sono stati modificati con successo";
export const EDIT_PROPERTY_INFO_ERROR_MESSAGE = "Non è stato possibile modificare i dati dell'immobile";

const EditPropertyInfoAction: React.FC<EditPropertyInfoActionProps> = ({
  property,
  onSuccess,
  children,
}) => {
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onSubmit = React.useCallback(async (formValues: EditPropertyInfoFormModel) => {
    try {
      setIsSaving(true);

      const propertyToUpdate = editPropertyInfoFormModelToProperty(formValues, property);
      const updatedProperty = await updateProperty(propertyToUpdate.id!, propertyToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_PROPERTY_INFO_SUCCESS_MESSAGE);
      modal.close();

      onSuccess?.(updatedProperty);
    } catch (_) {
      setIsSaving(false);
      addError(EDIT_PROPERTY_INFO_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, modal, onSuccess, property]);

  return (
    <>
      {children
        ? children({
          edit: modal.open,
          isSaving,
        })
        : (
          <Action
            aria-label="Modifica i dati dell'immobile"
            label="Modifica"
            size="S"
            onClick={() => modal.open()}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Modifica dati immobile"
          aria-label="Modale per modificare i dati dell'immobile"
          size={ModalSize.LARGE}
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per modificare i dati dell'immobile"
              onClick={submitForm}
            />
          )}
        >
          <EditPropertyInfoForm
            innerRef={formRef}
            initialValues={propertyToEditPropertyInfoFormModel(property)}
            onSubmit={onSubmit}
            loading={isSaving}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditPropertyInfoAction;
