import { IntentStatus, Status } from '../../../domain/types';

export const NOT_ALLOWED_INTENT_STATUS_FOR_RECOVERY = [
  IntentStatus.IN_PROGRESS,
  IntentStatus.KO_DUPLICATE,
  IntentStatus.BOUGHT,
];

export const NOT_ALLOWED_PROPERTY_STATUS_FOR_RECOVERY = [
  Status.RITIRATO,
  Status.VENDUTO,
];
