import React from 'react';
import { BreakpointQueryName, CardSkeleton, Stack } from '@doveit/bricks';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import SimpleTableRowSkeleton from './SimpleTableRow.skeleton';
import SimpleTable from './SimpleTable';

export type SimpleTableSkeletonProps = React.AriaAttributes;

const SimpleTableSkeleton: React.FC<SimpleTableSkeletonProps> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  return isMobile ? (
    <Stack>
      <CardSkeleton />
      <CardSkeleton />
    </Stack>
  ) : (
    <SimpleTable {...props}>
      <SimpleTable.Body>
        {[...Array(3).keys()].map((rowIndex) => (
          <SimpleTable.Row key={`table-row-${rowIndex}`} asChild>
            <SimpleTableRowSkeleton />
          </SimpleTable.Row>
        ))}
      </SimpleTable.Body>
    </SimpleTable>
  );
};

export default SimpleTableSkeleton;
