import useSWR from 'swr';
import hash from 'object-hash';
import { useMatchMutate } from '../../../utils/swr/swr';
import { PaginationParams, PaginatedResult } from '../../../providers/pagination';
import { getProperties, PropertiesProviderFilters } from '../../../providers/api/property/propertyProvider';
import { Property } from '../../../providers/api/dtos/property';
import { SWRCustomResponse } from '../../../hooks/types';
import { WithRequiredKeys } from '../../../types';

export const PROPERTIES_RID = '/properties/';

export default function useProperties(
  filters?: PropertiesProviderFilters,
  pagination?: PaginationParams,
): SWRCustomResponse<PaginatedResult<WithRequiredKeys<Property, 'id'>>, Error> {
  const matchMutate = useMatchMutate();
  const key = [PROPERTIES_RID, hash({ ...filters, ...pagination })];
  const result = useSWR(key, () => getProperties(filters, pagination));
  return {
    ...result,
    mutate: () => matchMutate(PROPERTIES_RID),
  };
}
