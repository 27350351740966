import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Document } from '../../../providers/api/dtos';
import { getPropertyDocuments } from '../../../providers/api/property/propertyProvider';

export const PROPERTY_DOCUMENTS_RID = '/properties/:id/documents';

export function usePropertyDocuments(
  propertyId?: number,
  options?: SWRConfiguration<Document[], AxiosError>,
): SWRResponse<Document[], AxiosError> {
  const key = propertyId ? [PROPERTY_DOCUMENTS_RID, propertyId] : null;

  return useSWR(key, () => getPropertyDocuments(propertyId!), options);
}
