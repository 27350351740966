import {
  ThemedFunctor,
  getTypography,
  TypographyName,
  getColorPalette,
  BadgeProps,
} from '@doveit/bricks';
import { css } from 'styled-components';

import { LeadStatus } from '../../../domain/types';

export const LeadStatusColor: Record<LeadStatus, BadgeProps['color']> = {
  IN_PROGRESS: 'info',
  APPOINTMENT_SCHEDULED: 'success',
  KO_BUY: 'critical',
  KO_CANNOT_CONTACT: 'critical',
  KO_DUPLICATE: 'critical',
  KO_GARAGE: 'critical',
  KO_GEO: 'critical',
  KO_NOT_INTERESTED: 'critical',
  KO_NOT_RESIDENTIAL: 'critical',
  KO_WRONG_PHONE_NUMBER: 'critical',
  KO_OTHER: 'critical',
  KO_RENT: 'critical',
  KO_TEST: 'critical',
  KO_ONLY_EVALUATION: 'info',
  PRIVATE: 'critical',
};

export const base: ThemedFunctor = ({
  theme,
  status,
}) => css`
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  background-color: ${getColorPalette(theme, LeadStatusColor[status as LeadStatus] || 'neutral')};
  border-radius: 3px;
  color: ${theme.palettes.neutrals.lightest};
  letter-spacing: .05rem;
  display: inline-block;
  padding: .25rem 1rem;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
`;
