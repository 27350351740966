import React, { FunctionComponent, useMemo } from 'react';
import { ContentType, Status } from '../../../domain/types';
import useContentsByPropertyId from '../../hooks/use-contents-by-property-id/useContentsByPropertyId';
import PropertyCover, { PropertyCoverError, PropertyCoverSkeleton } from '../../components/property-cover/PropertyCover';
import { statusLabels } from '../../../labels';
import ManageMultimediaContentsAction from '../../../content/containers/manage-multimedia-contents-action/ManageMultimediaContentsAction';

export interface ViewPropertyCoverProps {
  propertyId: number,
  propertyStatus: Status,
  canSort?: boolean,
  canEdit?: boolean,
  canAdd?: boolean,
  canDelete?: boolean,
  canSeeMissingAboutError?: boolean,
}

const ViewPropertyCover: FunctionComponent<ViewPropertyCoverProps> = ({
  propertyId,
  propertyStatus,
  canSort = false,
  canEdit = false,
  canAdd = false,
  canDelete = false,
  canSeeMissingAboutError = false,
}) => {
  const { data: contents, error: contentsErrors, mutate: mutateContents } = useContentsByPropertyId(propertyId, ContentType.FOTO);

  const cover = useMemo(
    () => ((contents && contents.length > 0) ? contents[0] : undefined),
    [contents],
  );

  const action: { type: 'gallery' | 'upload', index?: number } | undefined = React.useMemo(() => {
    if (cover) {
      return { type: 'gallery', index: 0 };
    }

    if (canAdd && !cover) {
      return { type: 'upload', index: undefined };
    }
    return undefined;
  }, [canAdd, cover]);

  const overlayLabel = useMemo(() => ([Status.PROPOSTA, Status.VENDUTO].includes(propertyStatus)
    ? statusLabels[propertyStatus]
    : undefined), [propertyStatus]);

  if (!contents && !contentsErrors) {
    return (
      <PropertyCoverSkeleton />
    );
  }

  if (contentsErrors) {
    return (
      <PropertyCoverError />
    );
  }

  return contents! && (
    <ManageMultimediaContentsAction
      propertyId={propertyId}
      contentType={ContentType.FOTO}
      canSort={canSort}
      canEdit={canEdit}
      canAdd={canAdd}
      canDelete={canDelete}
      canSeeMissingAboutError={canSeeMissingAboutError}
      onContentChange={() => mutateContents()}
    >
      {({ openManageModal }) => (
        <PropertyCover
          url={cover?.url ?? undefined}
          overlayLabel={overlayLabel}
          onClick={action ? () => openManageModal(action.type, action.index) : undefined}
        />
      )}
    </ManageMultimediaContentsAction>
  );
};

export default ViewPropertyCover;
