import { PaginatedResult, PaginationParams, serializePagination } from '../../pagination';
import { API_AGENCIES_PATH, getClient } from '../api';
import { Agency } from '../dtos';
import { serializeParams } from '../utils/serializer';

export interface SearchAgenciesParams {
  q: string,
}

export async function getAgencyById(id: NonNullable<Agency['id']>): Promise<Agency> {
  const client = await getClient();
  const { data } = await client.get<Agency>(`${API_AGENCIES_PATH}/${id}`);
  return data;
}

export async function updateAgency(id: number, agency: Agency): Promise<Agency> {
  const client = await getClient();
  const { data } = await client.put<Agency>(`${API_AGENCIES_PATH}/${id}`, agency);
  return data;
}

export async function searchAgencies(
  params: SearchAgenciesParams,
  pagination: PaginationParams = { size: 50 },
): Promise<PaginatedResult<Agency>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Agency>>(`${API_AGENCIES_PATH}/search`, {
    params: {
      ...serializeParams(params),
      ...serializePagination(pagination),
    },
  });

  return data;
}
