import { Form } from '@doveit/bricks';
import React from 'react';
import { Aggregator, AGGREGATORS, LUXURY_AGGREGATORS } from '../../../../domain/types';
import { noop } from '../../../../utils';
import { PublishedOnModel } from '../../models/publishedOnModel';
import { aggregatorLabels } from '../../../../labels';

export interface PublishedOnChangeEvent {
  aggregator: Aggregator,
  checked: boolean,
}

export interface PublishedOnFormProps {
  values: PublishedOnModel,
  disabled?: boolean,
  luxuryWarning?: React.ReactNode,
  disabledAggregators?: Aggregator[],
  onChange: (changed: PublishedOnChangeEvent) => void,
}

export const PublishedOnForm: React.FC<PublishedOnFormProps> = ({
  values,
  onChange,
  disabled,
  luxuryWarning,
  disabledAggregators = [],
}) => {
  const onChangeHandler = React.useCallback((aggregator: Aggregator) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    onChange({
      aggregator,
      checked,
    });
  }, [onChange]);

  return (
    <div aria-label="Form per la selezione di uno o più portali immobiliari">
      <Form
        initialValues={values}
        onSubmit={noop}
        disabled={disabled}
      >
        <Form.Group>
          <Form.Fieldset legend="Lista portali annunci">
            {AGGREGATORS.map((aggregator) => (
              <Form.Item
                key={aggregator}
                size={{ XS: 1 / 2, MD: 1 / 5 }}
              >
                <Form.Checkbox
                  name={`${aggregator}.checked`}
                  text={aggregatorLabels[aggregator]}
                  aria-label={aggregatorLabels[aggregator]}
                  onChange={onChangeHandler(aggregator)}
                  disabled={disabledAggregators.includes(aggregator)}
                />
              </Form.Item>
            ))}
          </Form.Fieldset>
          <Form.Fieldset legend="Lista portali annunci luxury">
            {luxuryWarning && (
              <Form.Item data-ref="luxury-warning">
                {luxuryWarning}
              </Form.Item>
            )}
            {LUXURY_AGGREGATORS.map((aggregator) => (
              <Form.Item
                key={aggregator}
                size={{ XS: 1 / 2, MD: 1 / 5 }}
              >
                <Form.Checkbox
                  name={`${aggregator}.checked`}
                  text={aggregatorLabels[aggregator]}
                  aria-label={aggregatorLabels[aggregator]}
                  onChange={onChangeHandler(aggregator)}
                  disabled={disabledAggregators.includes(aggregator)}
                />
              </Form.Item>
            ))}
          </Form.Fieldset>
        </Form.Group>
      </Form>
    </div>
  );
};

export default PublishedOnForm;
