import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Intent, OfferWizardData } from '../../../providers/api/dtos';
import { API_OFFERS_PATH } from '../../../providers/api/api';
import { getOfferWizardData } from '../../../providers/api/offer/offerProvider';

export const OFFER_WIZARD_DATA_RID = `${API_OFFERS_PATH}/wizard`;

export default function useOfferWizardData(
  intentId?: NonNullable<Intent['id']>,
  options?: SWRConfiguration<OfferWizardData, AxiosError>,
): SWRResponse<OfferWizardData, AxiosError> {
  const key = intentId ? [OFFER_WIZARD_DATA_RID, intentId] : null;

  return useSWR(key, () => getOfferWizardData(intentId!), options);
}
