import { AssignmentWizardData, DigitalAssignmentAgencyConfiguration } from '../../../../../providers/api/dtos';
import { boolToBooleanString, booleanStringToBool } from '../../../../../utils/boolean-string/booleanString';
import { AssignmentWizardWithdrawalFormModel } from './AssignmentWizardWithdrawalForm';

export const toAssignmentWizardWithdrawalFormModel = (
  initialData: AssignmentWizardData,
  config: DigitalAssignmentAgencyConfiguration = { penaltyClauseAgencyDefault: 5000 },
): AssignmentWizardWithdrawalFormModel => ({
  rightOfWithdrawal: typeof initialData.assignment?.rightOfWithdrawal !== 'undefined'
    ? boolToBooleanString(initialData.assignment.rightOfWithdrawal)
    : '',
  startDate: initialData.assignment?.startDate ?? '',
  cancellationFee: `${initialData.assignment ? initialData.assignment.cancellationFee ?? '' : config.penaltyClauseAgencyDefault}`,
  notes: initialData.assignment?.notes || '',
});

export const toAssignmentWizardData = (
  existingData: AssignmentWizardData,
  values: AssignmentWizardWithdrawalFormModel,
): AssignmentWizardData => {
  const {
    rightOfWithdrawal,
    startDate,
    cancellationFee,
    notes,
  } = values;

  const newAssignmentValues: Partial<AssignmentWizardData['assignment']> = {
    rightOfWithdrawal: booleanStringToBool(rightOfWithdrawal),
    startDate: startDate ? new Date(startDate).toISOString() : undefined,
    cancellationFee: cancellationFee ? parseFloat(cancellationFee) : undefined,
    notes: notes.trim() || undefined,
  };

  const newValues: AssignmentWizardData = {
    ...existingData,
    isDigitallySigned: false,
    assignment: {
      ...existingData.assignment,
      ...newAssignmentValues,
    },
  };

  return newValues;
};
