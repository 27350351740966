/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, PropsWithChildren } from 'react';
import * as styles from './CardTitle.style';

const CardTitle: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <div css={styles.base}>
    {children}
  </div>
);

export default CardTitle;
