import { ContentAbout, ContentAboutType } from '../domain/types';

export const contentAboutLabels: Record<ContentAboutType, string> = {
  [ContentAbout.BATHROOM]: 'Bagno',
  [ContentAbout.BALCONY]: 'Balcone',
  [ContentAbout.WALK_IN_WARDROBE]: 'Cabina armadio',
  [ContentAbout.BEDROOM]: 'Camera da letto',
  [ContentAbout.CELLAR]: 'Cantina',
  [ContentAbout.CORRIDOR]: 'Corridoio',
  [ContentAbout.PATIO]: 'Patio',
  [ContentAbout.COURTYARD]: 'Cortile interno',
  [ContentAbout.GARDEN]: 'Giardino',
  [ContentAbout.KITCHEN]: 'Cucina',
  [ContentAbout.DETAILS]: 'Dettagli',
  [ContentAbout.FACADE]: 'Facciata',
  [ContentAbout.GATEWAY]: 'Ingresso',
  [ContentAbout.GARAGE]: 'Posto macchina',
  [ContentAbout.PENTHOUSE]: 'Mansarda',
  [ContentAbout.DINING_ROOM]: 'Sala da pranzo',
  [ContentAbout.STAIRCASE]: 'Scala',
  [ContentAbout.BASEMENT]: 'Seminterrato',
  [ContentAbout.ROOM]: 'Stanza',
  [ContentAbout.POOL]: 'Piscina',
  [ContentAbout.OFFICE]: 'Ufficio',
  [ContentAbout.VIEWS]: 'Viste',
  [ContentAbout.SURROUNDINGS]: 'Vicinanze',
  [ContentAbout.COMMUNALAREAS]: 'Zone comuni',
  [ContentAbout.PORCH]: 'Portico',
  [ContentAbout.VERANDA]: 'Veranda',
  [ContentAbout.LIVING]: 'Sala',
  [ContentAbout.TAVERN]: 'Taverna',
  [ContentAbout.LAND]: 'Terreno',
  [ContentAbout.TERRACE]: 'Terrazza',
  [ContentAbout.STUDIO_FLAT]: 'Monolocale',
};
