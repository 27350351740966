import { Text, View } from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';

import * as styles from './Details.style';

export type StringTuple = [ string, string ];
export interface DetailsProps {
  values: StringTuple[];
}

export const Details: FunctionComponent<DetailsProps> = ({
  values,
}) => (
  <View>
    {values.map((tuple) => (
      <View
        key={tuple[0]}
        style={styles.item as any}
      >
        <Text style={styles.key as any}>
          {tuple[0]}
          :
        </Text>
        <Text style={styles.value}>{tuple[1]}</Text>
      </View>
    ))}
  </View>
);
