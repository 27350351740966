/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  ActionIcon, ActionIconDropdown, Dropdown, FontWeight, HStack, Icon, ICON_BELL_OUTLINE, ICON_EYE_OUTLINE, Popover, Stack, Text, useNotifications, UserPreview,
} from '@doveit/bricks';
import {
  Note, Rumor, Reminder, SerpRumor,
} from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewContactPopoverPreview from '../../../contact/containers/view-contact-popover-preview/ViewContactPopoverPreview';
import UpdateRumorStatus from '../update-rumor-status/UpdateRumorStatus';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import ViewRumorPopoverPreview from '../view-rumor-popover-preview/ViewRumorPopoverPreview';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import RumorAvatar from '../../components/rumor-avatar/RumorAvatar';
import { ReferenceType, RumorStatus } from '../../../domain/types';
import { computeResolvableStatus } from '../../../utils/reminder/reminder';
import { ResolvableReminderStatus } from '../../../types';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import UpsertReminderAction from '../../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import { rumorMessagesProvider } from '../../../containers/send-whatsapp-action/messages/rumorMessagesProvider';
import { updateRumor } from '../../../providers/api/rumor/rumorProvider';
import { buildWhatsappMessageSentNote } from '../../../intent/utils/buildWhatsappMessageSentNote';
import UpsertNoteAction from '../../../notes/containers/UpsertNoteAction';
import * as styles from './ViewSerpRumorTableRow.styles';
import { reminderDateColor } from '../../utils';

export const UPSERT_NOTE_ERROR_MESSAGE = 'Non è stato possibile aggiungere la nota';
export const UPSERT_NOTE_SUCCESS_MESSAGE = 'La nota è stata aggiunta con successo';
const MAX_RUMOR_DESCRIPTION_LENGTH = 90;

export interface ViewSerpRumorTableRowProps extends React.AriaAttributes {
  serpRumor: SerpRumor,
  onRumorUpdate?: (rumor: Rumor) => void,
  onReminderCreate?: (reminder: Reminder) => void,
}

const ViewSerpRumorTableRow: React.FC<ViewSerpRumorTableRowProps> = ({
  serpRumor,
  onRumorUpdate,
  onReminderCreate,
  ...rest
}) => {
  const { addSuccess, addError } = useNotifications();

  const {
    agent, contact, rumor, reminder,
  } = serpRumor;
  const {
    user, mainUserRole, userIsAdmin,
  } = useRBAC();
  const userIsRumorAgentOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);

  const reminderToCreate: Reminder = React.useMemo(() => ({
    agentId: agent.id,
    referenceId: rumor.id,
    referenceType: ReferenceType.RUMOR,
    resolved: false,
  } as Reminder), [agent.id, rumor.id]);

  const reminderStatus = React.useMemo(() => (reminder ? computeResolvableStatus(reminder) : undefined), [reminder]);

  const showReminder = React.useMemo(
    () => reminderStatus && [ResolvableReminderStatus.ACTIVE, ResolvableReminderStatus.EXPIRED].includes(reminderStatus),
    [reminderStatus],
  );

  const handleWhatsappSend = React.useCallback(async (message: string) => {
    try {
      const updatedRumor = await updateRumor({
        ...rumor,
        notes: [{
          text: buildWhatsappMessageSentNote(message),
          author: user.name,
          role: mainUserRole,
        }, ...(rumor.notes || [])],
      });

      onRumorUpdate?.(updatedRumor);
    } catch (_) { /* empty */ }
  }, [mainUserRole, onRumorUpdate, rumor, user.name]);

  const handleNoteCreated = React.useCallback(async (note: Note) => {
    try {
      const updatedRumor = await updateRumor({
        ...rumor,
        notes: [note, ...(rumor.notes || [])],
      });

      onRumorUpdate?.(updatedRumor);
      addSuccess(UPSERT_NOTE_SUCCESS_MESSAGE);
    } catch (err) {
      addError(UPSERT_NOTE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, onRumorUpdate, rumor]);

  const goToRumorPage = React.useCallback(() => {
    goToDetailPage(rumor.id!, ReferenceType.RUMOR);
  }, [rumor.id]);

  const goToProspectPage = React.useCallback(() => {
    goToDetailPage(rumor.prospectId!, ReferenceType.PROSPECT);
  }, [rumor.prospectId]);

  return (
    <SimpleTable.Row {...rest}>
      <SimpleTable.Cell
        aria-label="Contatto"
        width="9rem"
      >
        {contact && (
          <ViewContactPopoverPreview
            contact={contact}
            trigger={(
              <div css={styles.userWrapper}>
                <UserPreview
                  name={contact.name || 'Contatto'}
                  size="S"
                >
                  {contact.phoneNumber || contact.email}
                </UserPreview>
              </div>
            )}
          />
        )}
        {!contact && (
          <UserPreview
            name="Non presente"
            size="S"
          />
        )}
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Notizia"
        width="12rem"
      >
        <ViewRumorPopoverPreview
          rumorId={rumor.id!}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <RumorAvatar size="S" />
              <Text.BodySmall
                aria-label="Indirizzo immobile"
                fontWeight={FontWeight.MEDIUM}
                css={setVerticalEllipsis()}
              >
                {rumor.propertyAddress || 'Non presente'}
              </Text.BodySmall>
            </HStack>
            )}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Stato della valutazione"
        width="9.5rem"
      >
        <UpdateRumorStatus
          rumor={rumor}
          size="XS"
          onSuccess={onRumorUpdate}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Agente"
        width="2rem"
      >
        <ViewAgentPopoverPreview
          agent={agent}
          trigger={(
            <AgentPreview
              {...toAgentPreviewProps(agent, { omit: { name: true } })}
              size="S"
              expanded
            />
          )}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Descrizione"
        width="24rem"
        withDivider
      >
        <Popover
          aria-label="Visualizza dettagli notizia"
          trigger={(
            <div style={{ maxWidth: '24rem' }}>
              <Text.Caption>
                {truncateTextWithSuffix(rumor.description, MAX_RUMOR_DESCRIPTION_LENGTH)}
              </Text.Caption>
            </div>
          )}
          hasArrow
        >
          <div css={styles.notesPopoverWrapper}>
            <Text.BodySmall>{rumor.description}</Text.BodySmall>
          </div>
        </Popover>
      </SimpleTable.Cell>
      <SimpleTable.Cell
        aria-label="Promemoria"
        width="8rem"
      >
        {showReminder && (
          <Popover
            aria-label="Visualizza dettagli promemoria"
            trigger={(
              <div style={{ maxWidth: '10rem' }}>
                <HStack gap={100} wrap="nowrap">
                  <HStack wrap="nowrap">
                    <Icon path={ICON_BELL_OUTLINE} size={20} />
                    <div css={styles.dateWrapper}>
                      <Stack gap={25}>
                        <Text.Mini transform="uppercase">
                          Data promemoria
                        </Text.Mini>
                        <Text.Caption fontWeight={FontWeight.REGULAR} color={reminderDateColor(reminderStatus!)}>
                          <DateViewer
                            stringDate={reminder!.startDate}
                            checkIfToday
                            checkIfTomorrow
                          />
                        </Text.Caption>
                      </Stack>
                    </div>
                  </HStack>
                </HStack>
              </div>
            )}
            hasArrow
          >
            <div css={styles.notesPopoverWrapper}>
              <Text.BodySmall>{reminder!.notes}</Text.BodySmall>
            </div>
          </Popover>
        )}
      </SimpleTable.Cell>
      <SimpleTable.Cell
        aria-label="Azioni"
        align="right"
      >
        <HStack
          gap={75}
          wrap="nowrap"
        >
          <ActionIcon
            aria-label="Vai alla pagina della notizia"
            label="Visualizza notizia"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToRumorPage}
            size="S"
          />
          {(userIsAdmin || userIsRumorAgentOrManager) && (
            <ActionIconDropdown
              aria-label="Visualizza azioni secondarie"
              label="Azioni"
              size="S"
              emphasis="low"
            >
              {rumor.prospectId && (
                <Dropdown.Option
                  label="Visualizza valutazione"
                  aria-label="Apri la pagina di valutazione"
                  onClick={goToProspectPage}
                />
              )}
              {contact?.phoneNumber && (
                <SendWhatsappAction
                  phoneNumber={contact.phoneNumber}
                  name={contact.name}
                  fetchMessages={rumorMessagesProvider(agent)}
                  onMessageSent={handleWhatsappSend}
                >
                  {({ onClick }) => (
                    <Dropdown.Option
                      label="Invia whatsapp"
                      onClick={onClick}
                    />
                  )}
                </SendWhatsappAction>
              )}
              {rumor.status === RumorStatus.IN_PROGRESS && (
                <UpsertReminderAction
                  reminder={reminderToCreate}
                  onSuccess={onReminderCreate}
                >
                  {({ upsert }) => (
                    <Dropdown.Option
                      label="Aggiungi promemoria"
                      onClick={upsert}
                    />
                  )}
                </UpsertReminderAction>
              )}
              <UpsertNoteAction upsertNote={handleNoteCreated}>
                {({ upsert }) => (
                  <Dropdown.Option
                    label="Aggiungi nota"
                    onClick={upsert}
                  />
                )}
              </UpsertNoteAction>
            </ActionIconDropdown>
          )}
        </HStack>
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default ViewSerpRumorTableRow;
