import React, {
  FunctionComponent, ReactChild, useCallback, useMemo, useState,
} from 'react';
import {
  ActionIcon, Form, FormActionsAlign, ICON_ACCOUNT_MULTIPLE_PLUS_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agent, JobApplication } from '../../../providers/api/dtos';
import { updateJobApplication } from '../../../providers/api/job-application/jobApplicationProvider';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';
import validationSchema from './AssignJobApplication.schema';
import { JobApplicationStatus } from '../../../domain/types';
import { useAgent } from '../../../hooks/use-agent/useAgent';

interface AssignJobApplicationFormModel {
  agent?: Agent,
  note?: string,
}

export interface AssignJobApplicationProps {
  jobApplication: JobApplication,
  disabled?: boolean,
  onSuccess?: (jobApplication: JobApplication) => void,
  children?: ({ openModal }: { openModal: VoidFunction }) => ReactChild,
}

export const ASSIGN_JOB_APPLICATION_SUCCESS_MESSAGE = 'Candidatura assegnata con successo';
export const ASSIGN_JOB_APPLICATION_ERROR_MESSAGE = 'Non è stato possibile assegnare la candidatura';

const AssignJobApplication: FunctionComponent<AssignJobApplicationProps> = ({
  jobApplication,
  disabled,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const assignModal = useModal();
  const { addSuccess, addError } = useNotifications();
  const { data: agent, error: agentError } = useAgent(jobApplication.assignment?.agentId);
  const openModal = useCallback(() => assignModal.open(), [assignModal]);
  const closeModal = useCallback(() => assignModal.close(), [assignModal]);

  const initialValues: AssignJobApplicationFormModel = useMemo(() => ({
    agent,
    note: jobApplication.assignment?.note,
  }), [agent, jobApplication.assignment?.note]);

  const onSubmit = useCallback(async (values: AssignJobApplicationFormModel) => {
    setIsSaving(true);
    try {
      const updatedJobApplication = await updateJobApplication(
        jobApplication.id!,
        {
          ...jobApplication,
          status: JobApplicationStatus.ASSIGNED,
          assignment: {
            agentId: values.agent!.id!,
            note: values.note || undefined,
          },
        },
      );
      setIsSaving(false);
      closeModal();
      addSuccess(ASSIGN_JOB_APPLICATION_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedJobApplication);
      }
    } catch (error) {
      setIsSaving(false);
      addError(ASSIGN_JOB_APPLICATION_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, closeModal, jobApplication, onSuccess]);

  return (
    <>
      {children
        ? children({ openModal })
        : (
          <ActionIcon
            label={jobApplication.status !== JobApplicationStatus.ASSIGNED ? 'Assegna' : 'Riassegna'}
            onClick={openModal}
            icon={{ path: ICON_ACCOUNT_MULTIPLE_PLUS_OUTLINE }}
            disabled={disabled || !!agentError}
          />
        )}
      <Portal>
        <SimpleModal
          {...assignModal}
          title="Assegna ad agente"
        >
          <Form
            loading={isSaving}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            preventEnterKeypress
          >
            <Form.Item>
              <AgentAutoComplete
                name="agent"
                label="Assegna"
                aria-label="Campo per la selezione dell'agente"
                placeholder="Cerca agente"
              />
            </Form.Item>
            <Form.Item>
              <Form.TextArea
                rows={7}
                name="note"
                label="Feedback"
                aria-label="Campo per inserire il feedback dell'archiviazione per l'agente"
                placeholder="Inserisci eventuali feedback aggiuntivi per l'agente"
              />
            </Form.Item>
            <Form.Actions align={FormActionsAlign.RIGHT}>
              <Form.Submit label="Assegna candidato" />
            </Form.Actions>
          </Form>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default AssignJobApplication;
