import { FileMimeType } from '../../types';

const IMAGE_FILES_TYPE = [
  FileMimeType.JPEG,
  FileMimeType.PNG,
  FileMimeType.WEBP,
];

export interface ImageFile extends File {
  width: number,
  height: number
}

export function toImageFile(file:File): Promise<ImageFile> | undefined {
  if (IMAGE_FILES_TYPE.includes(file.type as FileMimeType)) {
    const imageFile = file as ImageFile;
    return new Promise<ImageFile>((resolve, _) => {
      const image = new Image();
      image.onload = () => {
        imageFile.width = image.width;
        imageFile.height = image.height;
        resolve(imageFile);
      };
      image.src = URL.createObjectURL(imageFile);
    });
  }
  return undefined;
}
