import { OfferStatus } from '../../../domain/types/offerStatus';
import { OfferDeedStatus } from '../../../domain/types/offerDeedStatus';
import { Intent } from './intent';
import { Geo } from './geo';
import {
  AdditionalExpenses, CadastralRegistry, Compliance, Constraints, Ownership, Rent, WizardContact,
} from './assignment';

export interface OfferDeed {
  date: string,
  status: OfferDeedStatus,
}

export interface Mortgage {
  percentage: number,
  maxAcceptanceDate?: string,
  request?: boolean,
  daysToProof?: number,
}

export interface Offer {
  id?: number,
  status: OfferStatus,
  intentId: string,
  value: number,
  counterOffer?: number,
  /**
   * @deprecated Don't use hasMortgage anymore. Use mortgage field instead
   */
  hasMortgage: boolean,
  /**
   * @deprecated Don't use mortgagePercentage anymore. use mortgage field instead
   */
  mortgagePercentage?: number,
  mortgage?: Mortgage
  acceptedAt?: string,
  offeredAt: string,
  deed?: OfferDeed,
  validUntil?: string,
  createdAt?: string,
  updatedAt?: string
}

export enum OfferPropertyStatus {
  FREE = 'FREE',
  FREE_UPON_DEED = 'FREE_UPON_DEED',
  RENTAL = 'RENTAL',
  OCCUPIED = 'OCCUPIED',
  UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION',
  OTHER = 'OTHER',
}

type Status = {
  type?: OfferPropertyStatus,
  details?: string,
};

export interface OfferPropertyData {
  geo?: Partial<Geo>,
  unit?: string,
  stairwell?: string,
  cadastralRegistry?: CadastralRegistry,
  composition?: string,
  ownership?: Ownership,
  status?: Status,
  rent?: Rent,
  compliance?: Compliance,
  constraints?: Constraints,
  additionalExpenses?: AdditionalExpenses,
  clearOfObjects?: boolean,
  clearOfOccupants?: boolean,
}

export interface DepositPayment {
  value?: number,
  paymentMethod?: PaymentMethod,
  bank?: string,
  checkNumber?: string,
}

export interface IntegrationPayment {
  preliminary?: boolean,
  paymentDateWithin?: string,
  depositIntegration?: boolean,
  value?: number,
  decay?: boolean,
  daysFromDecay?: number,
}

export interface BalancePayment {
  value?: number,
  paymentDateWithin?: string,
}

export interface Payment {
  deposit?: DepositPayment,
  integration?: IntegrationPayment,
  balance?: BalancePayment,
}

export interface OfferData {
  value?: number,
  payment?: Payment,
  notaryPriceDeposit?: boolean,
  validUntil?: string,
}

export interface OfferWizardMortgage {
  mandatoryMortgageAcceptance?: boolean,
  mortgageRequest?: boolean,
  value?: number,
  maxAcceptanceDate?: string,
  daysToProof?: number,
}

export interface OfferCommissions {
  noBuyerCommission?: boolean,
  type?: CommissionPrice,
  value?: number,
}

export interface AntiMoneyLaundering {
  politicallyExposed: boolean,
  relatedToAPoliticallyExposedFigure: boolean,
  moneySources: MoneySource[],
  otherMoneySourceValue?: string,
  notes?: string,
}

export interface OfferWizardData {
  intentId: NonNullable<Intent['id']>,
  buyerContacts?: WizardContact[],
  sellerContacts?: WizardContact[],
  property?: OfferPropertyData,
  offer?: OfferData,
  mortgage?: OfferWizardMortgage,
  commissions?: OfferCommissions,
  excludeAntiMoneyLaundering?: boolean,
  antiMoneyLaundering?: AntiMoneyLaundering,
  notes?: string,
  signaturePlace?: string,
  isDigitallySigned?: boolean,
  isSellerLegalPersonOnly?: boolean,
  isBuyerLegalPersonOnly?: boolean,
  updatedAt?: string,
  signatureRequestId?: string,
}

export enum PaymentMethod {
  CHECK = 'CHECK',
  BANK_TRANSFER = 'BANK_TRANSFER',
}

export enum CommissionPrice {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

export enum MoneySource {
  MORTGAGE = 'MORTGAGE',
  PERSONAL = 'PERSONAL',
  SELLING_PERSONAL_PROPERTIES = 'SELLING_PERSONAL_PROPERTIES',
  OTHER = 'OTHER',
}
