import { OfferWizardData } from '../../../../providers/api/dtos';
import { boolToBooleanString, booleanStringToBool } from '../../../../utils/boolean-string/booleanString';
import { OfferWizardMortgageFormModel } from './OfferWizardMortgageForm';

export function toOfferWizardData(initialData: OfferWizardData, values: OfferWizardMortgageFormModel): OfferWizardData {
  return ({
    ...initialData,
    mortgage: {
      mandatoryMortgageAcceptance: booleanStringToBool(values.mandatoryMortgageAcceptance),
      mortgageRequest: values.mortgageRequest ? booleanStringToBool(values.mortgageRequest) : undefined,
      daysToProof: values.daysToProof ? Number(values.daysToProof) : undefined,
      value: values.value ? Number(values.value) : undefined,
      maxAcceptanceDate: values.maxAcceptanceDate || undefined,
    },
  });
}

export function toOfferWizardMortgageModel(existingData: OfferWizardData): OfferWizardMortgageFormModel {
  return ({
    mandatoryMortgageAcceptance: boolToBooleanString(existingData.mortgage?.mandatoryMortgageAcceptance) || '',
    mortgageRequest: boolToBooleanString(existingData.mortgage?.mortgageRequest) || '',
    daysToProof: existingData.mortgage?.daysToProof?.toString() || '',
    maxAcceptanceDate: existingData.mortgage?.maxAcceptanceDate || '',
    value: existingData.mortgage?.value?.toString() || '',
  });
}
