import useSWR, { SWRConfiguration } from 'swr';
import { ContentType } from '../../../domain/types';
import { getContentsByPropertyId } from '../../../providers/api/content/contentProvider';
import { Content } from '../../../providers/api/dtos';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

export const CONTENTS_BY_PROPERTY_ID = '/properties/:id/contents';

export default function useContentsByPropertyId(propertyId?: number, contentType?: ContentType | ContentType[], options?: SWRConfiguration):
SWRCustomResponse<Content[], Error> {
  const matchMutate = useMatchMutate();
  const result = useSWR(propertyId ? [CONTENTS_BY_PROPERTY_ID, propertyId, contentType] : null, () => getContentsByPropertyId(propertyId!, contentType), options);

  return {
    ...result,
    mutate: () => matchMutate(CONTENTS_BY_PROPERTY_ID),
  };
}
