import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { COUNTRY } from '../../../constants';
import { LINKEDIN_REGEX } from '../../../applicant/components/applicant-form/ApplicantForm.schema';

interface ValidationSchemaOptions {
  skipSource?: boolean,
}

const validationSchema = (options: ValidationSchemaOptions = {}) => {
  const { skipSource = false } = options;

  return Yup.object().shape({
    name: Yup
      .string()
      .required('Il campo è obbligatorio'),
    jobPosition: Yup
      .object({
        id: Yup.string().required('Il campo è obbligatorio'),
      })
      .nullable(),
    source: Yup
      .string()
      .when([], {
        is: () => !skipSource,
        then: (schema) => schema.required('Il campo è obbligatorio'),
        otherwise: (schema) => schema.notRequired(),
      }),
    phoneNumber: Yup
      .string()
      .when('email', {
        is: (email: string) => !email || email.length === 0,
        then: (schema) => schema.required('Devi inserire numero di telefono o email'),
        otherwise: (schema) => schema.notRequired(),
      })
      .test('isValid', 'Inserisci un numero di telefono valido', (value: string | undefined) => !value || isValidPhoneNumber(value, COUNTRY)),
    email: Yup
      .string()
      .trim()
      .email('Inserisci un indirizzo email valido')
      .when('phoneNumber', {
        is: (phoneNumber: string) => !phoneNumber || phoneNumber.length === 0,
        then: (schema) => schema.required('Devi inserire email o numero di telefono'),
        otherwise: (schema) => schema.notRequired(),
      }),
    linkedIn: Yup
      .string()
      .matches(LINKEDIN_REGEX, 'Inserisci un URL LinkedIn valido')
      .optional(),
  }, [['email', 'phoneNumber']]);
};

export default validationSchema;
