/* eslint-disable react/no-unknown-property */
import {
  Typography,
  Icon,
  Portal,
  useModal,
  Spacing,
  useNotifications,
  Message,
  Action,
  ICON_TEXT_BOX_OUTLINE,
  HStack,
} from '@doveit/bricks';
import React from 'react';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Note } from '../../../providers/api/dtos';
import Card from '../../../components/card/Card';
import NoteCard from '../note-card/NoteCard';
import NoteForm, { NoteFormModel } from '../note-form/NoteForm';
import SimpleModal from '../../../components/simple-modal/SimpleModal';

export const NOTE_CREATE_SUCCESS_MESSAGE = 'Nota creata con successo';
export const NOTE_CREATE_AND_NOTIFY_SUCCESS_MESSAGE = 'Nota creata con successo e notifica inviata all\'agente';
export const NOTE_UPDATE_SUCCESS_MESSAGE = 'Nota aggiornata con successo';
export const NOTE_SAVE_ERROR_MESSAGE = 'Errore durante il salvataggio della nota';
export const NO_NOTES_MESSAGE = 'Nessuna nota presente';

export interface NotesWidgetProps extends React.AriaAttributes {
  notes: Note[],
  canCreate?: boolean,
  showNudgeCheckbox?: boolean,
  onSubmit: (notes: Note[]) => Promise<void>
}

/**
 * @deprecated use `NotesSection`
 */
const NotesWidget: React.FC<NotesWidgetProps> = ({
  notes = [],
  canCreate = true,
  showNudgeCheckbox = false,
  onSubmit,
  ...rest
}) => {
  const { user, userIsCallCenter, mainUserRole } = useRBAC();

  const [isLoading, setIsLoading] = React.useState(false);
  const noteModal = useModal<NoteFormModel>();
  const viewAllNotesModal = useModal<Note[]>();

  const { addSuccess, addError } = useNotifications();

  const canShowNudgeCheckbox = React.useMemo(
    () => showNudgeCheckbox && userIsCallCenter && !noteModal.data?.date,
    [noteModal.data?.date, showNudgeCheckbox, userIsCallCenter],
  );

  const bottomAction = React.useMemo(() => {
    if (notes.length > 1) {
      return ({
        'aria-label': 'Vedi tutte le note',
        label: 'Vedi tutte',
        onClick: () => viewAllNotesModal.open(notes),
      });
    }

    return undefined;
  }, [notes, viewAllNotesModal]);

  const onNoteAdd = React.useCallback(() => {
    viewAllNotesModal.close();
    noteModal.open({
      nudge: userIsCallCenter,
    });
  }, [noteModal, userIsCallCenter, viewAllNotesModal]);

  const onNoteEdit = React.useCallback((note: Note) => {
    viewAllNotesModal.close();
    noteModal.open(note);
  }, [noteModal, viewAllNotesModal]);

  const onNoteSubmit = React.useCallback(async (values: NoteFormModel) => {
    try {
      setIsLoading(true);

      if (values.date) {
        const found = notes.find((note) => note.date === values.date);

        if (found) {
          found.nudge = values.nudge;
          found.text = values.text!;
        }
        await onSubmit(notes);

        setIsLoading(false);
        addSuccess(NOTE_UPDATE_SUCCESS_MESSAGE);
      } else {
        await onSubmit([{
          date: new Date().toISOString(),
          role: mainUserRole!,
          author: user!.name,
          text: values.text!,
          nudge: values.nudge,
        }, ...notes]);

        setIsLoading(false);
        if (!values.nudge) {
          addSuccess(NOTE_CREATE_SUCCESS_MESSAGE);
        } else {
          addSuccess(NOTE_CREATE_AND_NOTIFY_SUCCESS_MESSAGE);
        }
      }

      noteModal.close();
    } catch (err) {
      setIsLoading(false);
      addError(NOTE_SAVE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, mainUserRole, noteModal, notes, onSubmit, user]);

  return (
    <Card
      {...rest}
      data-ref="notes-widget"
      bottomAction={bottomAction}
    >
      <Card.Header
        primaryActions={[
          canCreate && (
            <Action
              label="Aggiungi"
              size="S"
              aria-label="Aggiungi nuova nota"
              dataRef="add-note-action"
              onClick={onNoteAdd}
            />
          ),
        ]}
      >
        <Card.Title>
          <Icon path={ICON_TEXT_BOX_OUTLINE} />
          <Typography.HEADING_3 color="brand.primary">
            Note
          </Typography.HEADING_3>
        </Card.Title>
      </Card.Header>
      <Card.Box data-ref="notes-widget-body">
        {notes.length === 0 && (
          <Message
            message={NO_NOTES_MESSAGE}
            data-ref="no-notes-message"
          />
        )}
        {notes.length > 0 && (
          <NoteCard
            note={notes[0]}
            onEdit={onNoteEdit}
          />
        )}
      </Card.Box>
      <Portal>
        <>
          <SimpleModal
            {...noteModal}
            data-ref="add-note-modal"
            title={noteModal.data?.date ? 'Modifica nota' : 'Aggiungi nota'}
            aria-label="Modale per aggiungere una nota"
          >
            <NoteForm
              initialValues={noteModal.data}
              showNudgeCheckbox={canShowNudgeCheckbox}
              loading={isLoading}
              onSubmit={onNoteSubmit}
            />
          </SimpleModal>
          <SimpleModal
            {...viewAllNotesModal}
            data-ref="view-all-notes-modal"
            aria-label="Modale per visualizzare le note"
            header={(
              <HStack
                alignItems="center"
                justifyContent="space-between"
                aria-label="notes-modal-header"
              >
                <HStack>
                  <Typography.HEADING_3>
                    Note aggiunte
                  </Typography.HEADING_3>
                  <Typography.BODY>
                    {`(${notes.length})`}
                  </Typography.BODY>
                </HStack>

                <Action
                  label="Aggiungi nota"
                  data-ref="add-note-action-modal"
                  size="S"
                  onClick={onNoteAdd}
                />
              </HStack>
            )}
          >
            {notes.map((note) => (
              <Spacing
                key={note.date}
                margin={[200, 0, 0, 0]}
              >
                <NoteCard
                  note={note}
                  onEdit={onNoteEdit}
                />
              </Spacing>
            ))}
          </SimpleModal>
        </>
      </Portal>
    </Card>
  );
};

export default NotesWidget;
