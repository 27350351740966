import { AssignmentWizardData, IdentityDocumentType } from '../../../../../providers/api/dtos';
import { AssignmentWizardPlanimetryDelegationFormModel } from './AssignmentWizardPlanimetryDelegationForm';

export const toStepFormModel = (
  initialData: AssignmentWizardData,
): AssignmentWizardPlanimetryDelegationFormModel => {
  let contactDelegating;
  let contactDelegatingIndex;

  if (initialData.contacts?.length === 1) {
    [contactDelegating] = initialData.contacts;
    contactDelegatingIndex = 0;
  } else if (initialData.contacts && initialData.contacts?.length > 1) {
    contactDelegatingIndex = initialData.contacts?.findIndex((contact) => contact.planimetryDelegating);
    contactDelegating = contactDelegatingIndex >= 0 ? initialData.contacts![contactDelegatingIndex] : undefined;
  }

  return ({
    planimetryDelegationRequired: initialData.planimetryDelegationRequired,
    contactPosition: typeof contactDelegatingIndex !== 'undefined' && contactDelegatingIndex !== -1 ? `${contactDelegatingIndex}` : '',
    identityDocumentType: contactDelegating?.identityDocument?.type || '',
    identityDocumentNumber: contactDelegating?.identityDocument?.number || '',
    identityDocumentIssuedBy: contactDelegating?.identityDocument?.issuedBy || '',
    identityDocumentIssuedAt: contactDelegating?.identityDocument?.issuedAt || '',
  });
};

export const toAssignmentWizardData = (
  existingData: AssignmentWizardData,
  values: AssignmentWizardPlanimetryDelegationFormModel,
): AssignmentWizardData => {
  const {
    planimetryDelegationRequired,
    contactPosition,
    identityDocumentType,
    identityDocumentNumber,
    identityDocumentIssuedBy,
    identityDocumentIssuedAt,
  } = values;

  existingData.contacts = existingData.contacts?.map((contact, i) => {
    if (contactPosition === i.toString() && planimetryDelegationRequired) {
      return {
        ...contact,
        planimetryDelegating: true,
        identityDocument: {
          type: identityDocumentType ? identityDocumentType as IdentityDocumentType : undefined,
          number: identityDocumentNumber || undefined,
          issuedBy: identityDocumentIssuedBy || undefined,
          issuedAt: identityDocumentIssuedAt ? new Date(identityDocumentIssuedAt).toISOString() : undefined,
        },
      };
    }

    return {
      ...contact,
      planimetryDelegating: false,
    };
  });

  const newValues: AssignmentWizardData = {
    ...existingData,
    isDigitallySigned: false,
    planimetryDelegationRequired,
  };

  return newValues;
};
