import {
  ActionIcon, ConfirmModal, ICON_HOME_SEARCH_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import React from 'react';
import { Aggregator, Status } from '../../../domain/types';
import { Property } from '../../../providers/api/dtos';
import { updateProperty } from '../../../providers/api/property/propertyProvider';
import { WithRequiredKeys } from '../../../types';
import { useComputedPropertyStatus } from '../../hooks/use-computed-property-status/useComputedPropertyStatus';

export const PREVIEW_PROPERTY_SUCCESS_MESSAGE = 'Immobile pubblicato in anteprima con successo';
export const PREVIEW_PROPERTY_ERROR_MESSAGE = 'Non è stato possibile pubblicare in anteprima l\'immobile';

interface ChildFnProps {
  disabled: boolean,
  loading: boolean,
  preview: VoidFunction,
}

export interface PreviewPropertyActionProps {
  property: Property,
  disabled?: boolean,
  onSuccess?: (previewedProperty: Property) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const PreviewPropertyAction: React.FC<PreviewPropertyActionProps> = ({
  property,
  disabled = false,
  onSuccess,
  children,
}) => {
  const { id } = property as WithRequiredKeys<Property, 'id'>;

  const [isSaving, setIsSaving] = React.useState(false);
  const confirmModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const {
    data: computedPropertyStatus,
    isLoading: isComputedPropertyStatusLoading,
  } = useComputedPropertyStatus(property);

  const canBePreviewed = computedPropertyStatus?.canBeUpdatedTo(Status.ANTEPRIMA) || false;

  const openConfirmModal = React.useCallback(() => {
    confirmModal.open();
  }, [confirmModal]);

  const onConfirm = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const propertyToPreview: Property = {
        ...property,
        status: Status.ANTEPRIMA,
      };

      const previewedProperty = await updateProperty(id, propertyToPreview);

      setIsSaving(false);
      confirmModal.close();
      addSuccess(PREVIEW_PROPERTY_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(previewedProperty);
      }
    } catch (error) {
      setIsSaving(false);
      addError(PREVIEW_PROPERTY_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, confirmModal, id, onSuccess, property]);

  if (!canBePreviewed) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          disabled,
          preview: openConfirmModal,
          loading: isComputedPropertyStatusLoading || isSaving,
        }) : (
          <ActionIcon
            icon={{ path: ICON_HOME_SEARCH_OUTLINE }}
            label="Anteprima"
            title="Pubblica in anteprima l'immobile"
            onClick={openConfirmModal}
            aria-label="Pubblica in anteprima l'immobile"
            disabled={disabled}
            loading={isComputedPropertyStatusLoading || isSaving}
          />
        )}

      <Portal>
        <ConfirmModal
          isOpen={confirmModal.isOpen}
          aria-label="Conferma pubblicazione dell'immobile in anteprima"
          title="Conferma pubblicazione dell'immobile in anteprima"
          saving={isSaving}
          onConfirm={onConfirm}
          onAbort={confirmModal.close}
        >
          {property.publishedOn.some(({ aggregator }) => aggregator === Aggregator.DOVE_IT)
            ? "Pubblicando l'immobile in anteprima, l'annuncio sarà visibile esclusivamente sul sito di Dove.it."
            : "Pubblicando l'immobile in anteprima, l'annuncio non sarà visibile sui portali."}
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default PreviewPropertyAction;
