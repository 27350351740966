import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

// Both the left and right columns can grow or shrink
// depending on the content.
export const leftColumn: ThemedFunctor = () => css`
  flex: 1 1 auto;
`;

export const rightColumn: ThemedFunctor = () => css`
  flex: 1 1 auto;
`;
