import { Call } from '../dtos';

import {
  getClient, API_CALLS_PATH,
} from '../api';
import { PaginatedResult, PaginationParams, serializePagination } from '../../pagination';
import { serializeParams } from '../utils/serializer';

export interface CallsProviderFilters {
  phoneNumber?: string;
}

export async function createCall(call: Call): Promise<Call> {
  const client = await getClient();
  const { data } = await client.post<Call>(API_CALLS_PATH, call);
  return data;
}

export async function getCalls(filters: CallsProviderFilters = {}, pagination: PaginationParams = { size: 10 }): Promise<PaginatedResult<Call>> {
  const client = await getClient();
  const { data } = await client.get<PaginatedResult<Call>>(API_CALLS_PATH, {
    params: {
      ...serializeParams(filters),
      ...serializePagination(pagination),
    },
  });
  return data;
}
