import React, { FunctionComponent } from 'react';
import {
  ActionIcon,
  ICON_PRINTER_OUTLINE,
  ModalSize, Portal, useModal,
} from '@doveit/bricks';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import PrintBuyerBrochure from '../../print/containers/print-buyer-brochure/PrintBuyerBrochure';
import { Property } from '../../../providers/api/dtos';

interface ChildFnProps {
  print: VoidFunction,
}

export interface PrintPropertyActionProps {
  property: Property,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const PrintPropertyAction: FunctionComponent<PrintPropertyActionProps> = ({
  property,
  children,
}) => {
  const printModal = useModal();

  return (
    <>
      {children
        ? children({ print: printModal.open })
        : (
          <ActionIcon
            dataRef="print-action"
            icon={{ path: ICON_PRINTER_OUTLINE }}
            label="Stampa"
            onClick={printModal.open}
          />
        )}
      <Portal>
        <SimpleModal
          {...printModal}
          title="Stampa scheda immobile"
          size={ModalSize.LARGE}
          fillAvailableHeight
        >
          <PrintBuyerBrochure propertyId={property.id!} />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default PrintPropertyAction;
