import * as Yup from 'yup';
import { GarageType } from '../../../../domain/types/garageType';

const upsertPropertyGarageValidationSchema = Yup.object().shape({
  type: Yup.string().oneOf(Object.values(GarageType)).required('Il campo è obbligatorio'),
  size: Yup.number()
    .integer('Inserisci un numero intero')
    .positive('Inserisci un numero positivo'),
  adjacent: Yup.boolean(),
});

export default upsertPropertyGarageValidationSchema;
