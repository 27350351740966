import React, {
  createContext, Dispatch, FunctionComponent, PropsWithChildren, SetStateAction, useMemo, useState,
} from 'react';
import { GMAP_API_KEY } from '../../constants';
import { AutocompleteSessionToken } from '../../types';
import { noop } from '../../utils';

export interface GoogleMapsContextValue {
  apiKey: string | null,
  autoCompleteSessionToken: AutocompleteSessionToken | null,
  setAutoCompleteSessionToken: Dispatch<SetStateAction<AutocompleteSessionToken | null>>,
}

export const GoogleMapsContext = createContext<GoogleMapsContextValue>({
  apiKey: null,
  autoCompleteSessionToken: null,
  setAutoCompleteSessionToken: noop,
});

export const GoogleMapsContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [autoCompleteSessionToken, setAutoCompleteSessionToken] = useState<AutocompleteSessionToken | null>(null);
  const googleMapsContext = useMemo(() => ({
    apiKey: GMAP_API_KEY,
    autoCompleteSessionToken,
    setAutoCompleteSessionToken,
  }), [autoCompleteSessionToken]);

  return (
    <GoogleMapsContext.Provider value={googleMapsContext}>
      {children}
    </GoogleMapsContext.Provider>
  );
};
