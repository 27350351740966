import { Agent } from '../../../providers/api/dtos';
import { EditAgentCoveredGeoFormModel } from '../../components/edit-agent-covered-geo-form/EditAgentCoveredGeoForm';

export const editCoveredGeoFormModelToAgent = (coveredGeoFormModel: EditAgentCoveredGeoFormModel, existingAgent: Agent): Agent => {
  const coveredGeo = Object.entries(coveredGeoFormModel.provinces).map(([plateCode, { province, weight }]) => {
    const parsedWeight = Number.parseFloat(weight);
    const normalizedWeight = (parsedWeight / 100).toFixed(3);

    return {
      plateCode,
      province,
      weight: Number.parseFloat(normalizedWeight),
    };
  });

  return {
    ...existingAgent,
    coveredGeo,
  };
};

export const agentToEditCoveredGeoFormModel = (agent: Agent): EditAgentCoveredGeoFormModel => {
  const provinces = (agent.coveredGeo || []).reduce<EditAgentCoveredGeoFormModel['provinces']>((acc, {
    plateCode,
    province,
    weight,
  }) => ({
    ...acc,
    [plateCode]: {
      province,
      weight: (weight * 100).toFixed(1),
    },
  }), {});

  return {
    provinces,
  };
};
