import {
  ActionIcon, Badge, FontWeight, HStack, ICON_EYE_OUTLINE, ICON_FILE_PDF_BOX, Message, Spacing, Stack,
  Text, Card,
  Action,
  Select,
} from '@doveit/bricks';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { ThemeIcons } from '../../../theme/icons';
import { FileMimeType, NavigationItem } from '../../../types';
import {
  AGENT_TRIAL_RESOURCES_NAVIGATION_ITEMS,
  DOCUMENTATION_DOCUMENTS, DOCUMENTATION_STORAGE_BASE_URL, PARTNERS, RESOURCES_NAVIGATION_ITEMS, TRAINING_DOCUMENTS,
} from './constants';
import { ResourceSection } from '../../types';
import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import { template } from '../../../utils/text/text';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import SimpleTable from '../../../components/simple-table/SimpleTable';

const ViewDocumentationPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const currentAgent = useCurrentAgent();
  const { agencyId } = params;

  const isDesktop = useIsDevice('desktop');

  const section: ResourceSection = React.useMemo(() => {
    if (!params.section) {
      return ResourceSection.DOCUMENTATION;
    }

    return params.section as ResourceSection;
  }, [params]);

  const isItemActive = React.useCallback((item: NavigationItem) => !!item.path && location.pathname.endsWith(item.path), [location]);

  const navigateTo = React.useCallback((path?: string) => () => {
    navigate(agencyId ? `/resources/${agencyId}${path}` : `/resources${path}`);
  }, [agencyId, navigate]);

  const tabItems = React.useMemo(() => {
    let availableTabs: NavigationItem[] = [];

    if (currentAgent?.inTrial) {
      availableTabs = AGENT_TRIAL_RESOURCES_NAVIGATION_ITEMS;
    } else {
      availableTabs = RESOURCES_NAVIGATION_ITEMS;
    }

    return availableTabs.map((item) => ({
      id: item.id,
      label: item.label,
      active: isItemActive(item),
      onClick: navigateTo(item.path),
    }));
  }, [currentAgent?.inTrial, isItemActive, navigateTo]);

  const documentationBaseUrl = React.useMemo(
    () => (section === ResourceSection.DOCUMENTATION && agencyId ? `${DOCUMENTATION_STORAGE_BASE_URL}${agencyId}/` : DOCUMENTATION_STORAGE_BASE_URL),
    [agencyId, section],
  );

  const handleSelectChange = React.useCallback((resourceSection: ResourceSection) => () => {
    tabItems.find((item) => item.id === resourceSection)?.onClick();
  }, [tabItems]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Bar>
        {isDesktop && (
          <HStack
            aria-label="Tab per selezionare la sezione"
            gap={100}
          >
            {tabItems.map((item) => (
              <Action
                key={item.id}
                label={item.label}
                aria-label={`Tab ${item.label}`}
                onClick={item.onClick}
                size="M"
                color={item.active ? 'primary' : 'neutral'}
              />
            ))}
          </HStack>

        )}
        {!isDesktop && (
          <Select
            value={tabItems.find((item) => item.active)?.id}
            options={tabItems.map((item) => ({
              value: item.id,
              label: item.label,
            }))}
            aria-label="Dropdown per selezionare la sezione"
            size="S"
            onChange={({ target }) => handleSelectChange(target.value as ResourceSection)()}
          />
        )}
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent>

          {/** Documentation Section */}
          {section === ResourceSection.DOCUMENTATION && (
            <div aria-label="Sezione contenente documentazioni varie">
              <Spacing margin={[0, 0, 300]}>
                <Message
                  boxed
                  message="In questa sezione trovi la lista dei documenti che ti serviranno durante le fasi di una compravendita. Puoi fare il download in formato Word o PDF."
                />
              </Spacing>
              {isDesktop && (
                <SimpleTable>
                  <SimpleTable.Body>
                    {DOCUMENTATION_DOCUMENTS.map((document) => (
                      <SimpleTable.Row key={document.title}>
                        <SimpleTable.Cell>
                          <Text.Body fontWeight={FontWeight.REGULAR}>
                            {document.title}
                          </Text.Body>
                        </SimpleTable.Cell>
                        <SimpleTable.Cell align="right">
                          <HStack>
                            {document.files?.map((file) => (
                              <ActionIcon
                                key={file.name}
                                label={`Visualizza ${file.name}`}
                                size="S"
                                icon={{
                                  path: file.type === FileMimeType.DOCX
                                    ? ThemeIcons.FILE_WORD
                                    : ICON_FILE_PDF_BOX,
                                }}
                                href={documentationBaseUrl + file.name}
                                download
                                target="_blank"
                              />
                            ))}
                          </HStack>
                        </SimpleTable.Cell>
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}

              {!isDesktop && (
                <Stack gap={150}>
                  {DOCUMENTATION_DOCUMENTS.map((document) => (
                    <Card key={document.title}>
                      <Card.Header
                        title={document.title}
                        primaryActions={
                          document.files?.map((file) => (
                            <ActionIcon
                              key={file.name}
                              label={`Visualizza ${file.name}`}
                              size="S"
                              icon={{
                                path: file.type === FileMimeType.DOCX
                                  ? ThemeIcons.FILE_WORD
                                  : ICON_FILE_PDF_BOX,
                              }}
                              href={documentationBaseUrl + file.name}
                              download
                              target="_blank"
                            />
                          ))
                        }
                      />
                    </Card>
                  ))}
                </Stack>
              )}
            </div>
          )}

          {/** Partners Section */}
          {section === ResourceSection.PARTNERS && (
            <div aria-label="Sezione contenente informazioni sui partner">
              {isDesktop && (
                <SimpleTable>
                  <SimpleTable.Body>
                    {PARTNERS.map((partner) => (
                      <SimpleTable.Row key={partner.name}>
                        <SimpleTable.Cell>
                          <Text.Body fontWeight={FontWeight.REGULAR}>
                            {partner.name}
                          </Text.Body>
                        </SimpleTable.Cell>

                        <SimpleTable.Cell>
                          <Badge
                            label={partner.tag}
                            color="neutral"
                            size="XS"
                          />
                        </SimpleTable.Cell>

                        <SimpleTable.Cell>
                          <Text.Body>
                            {partner.description}
                          </Text.Body>
                        </SimpleTable.Cell>

                        <SimpleTable.Cell align="right">
                          <ActionIcon
                            aria-label="Naviga sul sito del partner"
                            label="Naviga sul sito del partner"
                            size="S"
                            icon={{ path: ICON_EYE_OUTLINE }}
                            href={template(partner.link, { AGENT_EMAIL: currentAgent?.email })}
                            target="_blank"
                          />
                        </SimpleTable.Cell>
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}

              {!isDesktop && (
                <Stack gap={150}>
                  {PARTNERS.map((partner) => (
                    <Card key={partner.name}>
                      <Card.Header
                        title={partner.name}
                        primaryActions={[
                          <ActionIcon
                            label={`Visualizza ${partner.name}`}
                            size="S"
                            icon={{ path: ICON_EYE_OUTLINE }}
                            href={partner.link}
                            download
                            target="_blank"
                          />,
                        ]}
                      />
                      <Card.Content>
                        <Stack>
                          <div>
                            <Badge
                              label={partner.tag}
                              size="XS"
                            />
                          </div>
                          <Text.Body>
                            {partner.description}
                          </Text.Body>
                        </Stack>
                      </Card.Content>
                    </Card>
                  ))}
                </Stack>
              )}
            </div>
          )}

          {/** Training Section */}
          {
            section === ResourceSection.TRAINING && (
              <div aria-label="Sezione contenente risorse per la formazione">
                {isDesktop && (
                  <SimpleTable>
                    <SimpleTable.Body>
                      {TRAINING_DOCUMENTS.map((document) => (
                        <SimpleTable.Row key={document.title}>
                          <SimpleTable.Cell>
                            <Text.Body fontWeight={FontWeight.REGULAR}>
                              {document.title}
                            </Text.Body>
                          </SimpleTable.Cell>

                          {document.description && (
                            <SimpleTable.Cell>
                              <Text.Body>
                                {document.description}
                              </Text.Body>
                            </SimpleTable.Cell>
                          )}

                          <SimpleTable.Cell align="right">
                            <ActionIcon
                              label={document.title}
                              icon={{ path: ThemeIcons.GOOGLE_DRIVE }}
                              size="S"
                              href={document.link}
                              target="_blank"
                            />
                          </SimpleTable.Cell>
                        </SimpleTable.Row>
                      ))}
                    </SimpleTable.Body>
                  </SimpleTable>
                )}

                {!isDesktop && (
                  <Stack gap={150}>
                    {TRAINING_DOCUMENTS.map((training) => (
                      <Card>
                        <Card.Header
                          title={training.title}
                          primaryActions={[
                            <ActionIcon
                              label={training.title}
                              icon={{ path: ThemeIcons.GOOGLE_DRIVE }}
                              size="S"
                              href={training.link}
                              target="_blank"
                            />,
                          ]}
                        />
                        {training.description && (
                          <Card.Content>
                            <Text.Body>
                              {training.description}
                            </Text.Body>
                          </Card.Content>
                        )}
                      </Card>
                    ))}
                  </Stack>
                )}
              </div>
            )
          }
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default ViewDocumentationPage;
