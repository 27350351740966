import { API_PROPERTIES_PATH, API_CELLARS_PATH, getClient } from '../api';
import { Cellar } from '../dtos';

export async function createCellar(cellar: Cellar): Promise<Cellar> {
  const client = await getClient();
  const { data } = await client.post<Cellar>(API_CELLARS_PATH, cellar);
  return data;
}

export async function getCellar(cellarId: string | number): Promise<Cellar> {
  const client = await getClient();
  const { data } = await client.get<Cellar>(`${API_CELLARS_PATH}/${cellarId}`);
  return data;
}

export async function updateCellar(cellar: Cellar): Promise<Cellar> {
  const client = await getClient();
  const { data } = await client.put<Cellar>(`${API_CELLARS_PATH}/${cellar.id}`, cellar);
  return data;
}

export async function deleteCellar(cellarId: string | number): Promise<{ id: number | string }> {
  const client = await getClient();
  const { data } = await client.delete(`${API_CELLARS_PATH}/${cellarId}`);
  return data;
}

export async function getCellarsByPropertyId(propertyId: number): Promise<Cellar[]> {
  const client = await getClient();
  const { data } = await client.get(`${API_PROPERTIES_PATH}/${propertyId}/cellars`);
  return data;
}
