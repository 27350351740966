import {
  Spacing,
  Icon,
  Typography,
  ModalSize,
  useModal,
  useNotifications,
  ICON_MAP_MARKER_OUTLINE,
  ICON_MAP_MARKER_RADIUS_OUTLINE,
  Badge,
  Action,
  Fieldset,
  HStack,
} from '@doveit/bricks';
import React, {
  FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/card/Card';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import SelectContact from '../../../contact/containers/select-contact/SelectContact';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import SelectGeoSearchCriteria from '../../container/select-geo-search-criteria/SelectGeoSearchCriteria';
import { Contact } from '../../../providers/api/dtos/contact';
import useContact from '../../../contact/hooks/use-contact/useContact';
import { CompositeGeoSearchCriterion, GeoSearchCriterionType } from '../../../providers/api/dtos/searchCriteria';
import { getGeoCriterionLabel } from '../../utils/getGeoCriterionLabel';
import { getGeoCriterionIcon } from '../../utils/getGeoCriterionIcon';
import SearchCriteriaForm, { SearchCriteriaFormModel } from '../../component/search-criteria-form/SearchCriteriaForm';
import { createSearchCriteria } from '../../../providers/api/search-criteria/searchCriteriaProvider';
import { toPartialSearchCriteria } from '../../mappers/searchCriteriaMapper';
import { SearchContactFormModel } from '../../../contact/components/search-contact-form/SearchContactForm';
import createOrUpdateContact from '../../../contact/utils/createOrUpdateContact';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { UserRole } from '../../../types';
import useFilters, { Filters, FiltersMapper } from '../../../hooks/use-filters/useFilters';
import useProperty from '../../../property/hooks/use-property/useProperty';
import {
  ResidentialPropertyTypeValue, Status,
} from '../../../domain/types';
import useBalconies from '../../../property/hooks/use-balconies/useBalconies';
import useTerraces from '../../../property/hooks/use-terraces/useTerraces';
import useGarages from '../../../property/hooks/use-garages/useGarages';
import { toSearchCriteriaFormModel } from '../../utils/toSearchCriteriaFormModel';
import CardSkeleton from '../../../components/card/skeleton/CardSkeleton';

export const LOAD_CONTACT_ERROR_MESSAGE = 'Errore durante il caricamento del contatto. Riprova inserendo i dati manualmente.';
export const LOAD_PROPERTY_ERROR_MESSAGE = 'Errore durante il caricamento dell\'immobile. Riprova inserendo i dati manualmente.';

interface CreateSearchCriteriaPageFilters extends Filters {
  contactId?: string,
  propertyId?: number,
}

const filtersMapper: FiltersMapper<CreateSearchCriteriaPageFilters> = {
  contactId: (value) => value,
  propertyId: (value) => (!Number.isNaN(Number(value)) ? Number(value) : undefined),
};

const CreateSearchCriteriaPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { filters: { contactId, propertyId } } = useFilters(filtersMapper);
  const { data: requestedContact, error: requestedContactError } = useContact(contactId);
  const { data: requestedProperty, error: requestedPropertyError } = useProperty(propertyId);
  const { data: balconies } = useBalconies(propertyId);
  const { data: terraces } = useTerraces(propertyId);
  const { data: garages } = useGarages(propertyId);
  const geoSearchCriteriaModal = useModal<CompositeGeoSearchCriterion[]>();
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>();
  const [overwriteValues, setOverwriteValues] = useState<SearchContactFormModel | undefined>();
  const [geoSearchCriteria, setGeoSearchCriteria] = useState<CompositeGeoSearchCriterion[]>([]);
  const [newGeoSearchCriteria, setNewGeoSearchCriteria] = useState<CompositeGeoSearchCriterion[]>([]);
  const [initialValuesSearchCriteria, setInitialValuesSearchCriteria] = useState<SearchCriteriaFormModel>();
  const [isSaving, setSaving] = useState(false);
  const { addSuccess, addError, addWarning } = useNotifications();
  const { userHasAnyRole } = useRBAC();
  const isAgent = userHasAnyRole(UserRole.AGENT);

  const onContactChange = useCallback((contact?: Contact, newValues?: SearchContactFormModel) => {
    setSelectedContact(contact);
    setOverwriteValues(newValues);
  }, []);

  useEffect(() => {
    if (requestedContact) {
      setSelectedContact(requestedContact);
    }

    if (requestedProperty && requestedProperty.status === Status.LIVE && requestedProperty.propertyType in ResidentialPropertyTypeValue) {
      const positionSearchCriteria: CompositeGeoSearchCriterion[] = [{
        type: GeoSearchCriterionType.POINT,
        address: requestedProperty.geo!.route!,
        latitude: requestedProperty.geo!.latitude!,
        longitude: requestedProperty.geo!.longitude!,
        radius: 500,
      }];

      setGeoSearchCriteria(positionSearchCriteria);

      const initialValues: SearchCriteriaFormModel = toSearchCriteriaFormModel(requestedProperty, balconies, terraces, garages);

      setInitialValuesSearchCriteria(initialValues);
    }

    if (requestedContactError) {
      addWarning(LOAD_CONTACT_ERROR_MESSAGE);
    }

    if (requestedPropertyError) {
      addWarning(LOAD_PROPERTY_ERROR_MESSAGE);
    }
  }, [requestedContact, requestedContactError, addWarning, requestedProperty, requestedPropertyError, balconies?.length, terraces?.length, garages?.length, balconies, terraces, garages]);

  const onGeoSearchCriteriaOpen = useCallback(() => {
    geoSearchCriteriaModal.open(geoSearchCriteria);
  }, [geoSearchCriteria, geoSearchCriteriaModal]);

  const onGeoSearchCriteriaSave = useCallback(() => {
    setGeoSearchCriteria(newGeoSearchCriteria);
    geoSearchCriteriaModal.close();
  }, [newGeoSearchCriteria, geoSearchCriteriaModal]);

  const onSubmit = useCallback(async (model: SearchCriteriaFormModel) => {
    setSaving(true);
    try {
      const contact: Contact = await createOrUpdateContact(overwriteValues, selectedContact);

      const partialSearchCriteria = toPartialSearchCriteria(model);
      const searchCriteria = await createSearchCriteria({
        ...partialSearchCriteria,
        source: isAgent ? 'agent' : 'inbound',
        contactId: contact!.id!,
        geo: geoSearchCriteria,
      });

      setSaving(false);
      addSuccess('Ricerca salvata correttamente');

      navigate(`/search-criteria/${searchCriteria.id}`);
    } catch (e) {
      setSaving(false);
      addError('Non è stato possibile salvare la ricerca');
    }
  }, [overwriteValues, selectedContact, isAgent, geoSearchCriteria, addSuccess, navigate, addError]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent>
          <Fieldset legend="Dati contatto">
            {contactId && !requestedContact && !requestedContactError ? (
              <CardSkeleton data-ref="contact-skeleton" />
            ) : (
              <SelectContact
                onContactChange={onContactChange}
                initialContact={requestedContact}
                showClearContact
              />
            )}
          </Fieldset>
          <Spacing margin={[300, 0, 0]}>
            <Fieldset legend="Posizione">
              {geoSearchCriteria?.length === 0 && (
                <Action
                  dataRef="open-geo-modal"
                  label="Imposta posizione"
                  iconLeft={{ path: ICON_MAP_MARKER_RADIUS_OUTLINE }}
                  disabled={!selectedContact && !overwriteValues}
                  onClick={onGeoSearchCriteriaOpen}
                />
              )}
              {geoSearchCriteria?.length > 0 && (
                <Card data-ref="geo-section">
                  <Card.Header>
                    <Card.Title>
                      <Icon path={ICON_MAP_MARKER_OUTLINE} />
                      <Typography.HEADING_3 color="brand.primary">
                        Posizione
                      </Typography.HEADING_3>
                    </Card.Title>
                    <Action
                      label="Modifica"
                      title="Modifica posizione"
                      size="S"
                      onClick={onGeoSearchCriteriaOpen}
                    />
                  </Card.Header>
                  <Card.Box>
                    <HStack>
                      {geoSearchCriteria.map((geo) => (
                        <Badge
                          key={getGeoCriterionLabel(geo)}
                          label={getGeoCriterionLabel(geo)}
                          icon={getGeoCriterionIcon(geo)}
                        />
                      ))}
                    </HStack>
                  </Card.Box>
                </Card>
              )}
            </Fieldset>
          </Spacing>
          <Spacing margin={[300, 0, 0]}>
            <Fieldset legend="Criteri di ricerca">
              <SearchCriteriaForm
                onSubmit={onSubmit}
                loading={isSaving}
                initialValues={initialValuesSearchCriteria}
                disabled={(!selectedContact && !overwriteValues) || geoSearchCriteria.length === 0}
              />
            </Fieldset>
          </Spacing>
        </GenericPageLayout.InnerContent>

        <SimpleModal
          data-ref="geo-search-criteria-modal"
          {...geoSearchCriteriaModal}
          title="Seleziona la posizione tramite mappa o località"
          size={ModalSize.LARGE}
          fillAvailableHeight
          footer={(
            <>
              <Action
                label="Annulla"
                title="Annulla azione"
                onClick={geoSearchCriteriaModal.close}
              />
              <Action
                label="Seleziona"
                title="Seleziona la posizione"
                emphasis="high"
                color="primary"
                disabled={newGeoSearchCriteria.length === 0}
                onClick={onGeoSearchCriteriaSave}
              />
            </>
          )}
        >
          <SelectGeoSearchCriteria
            initialValues={geoSearchCriteriaModal.data}
            onChange={setNewGeoSearchCriteria}
          />
        </SimpleModal>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default CreateSearchCriteriaPage;
