import {
  ThemedFunctor, SpacingName, getSpacing, BreakpointQueryName, getTypography, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';
import { NavigationMobileHeight, NavigationInPageHeight } from '../../../constants';

const sideDesktopWidth = '16rem';

export const content: ThemedFunctor = ({
  theme,
  $fitMaxAvailableHeight,
}) => css`
  flex-grow: 1;
  position: relative;
  display: flex;

  ${$fitMaxAvailableHeight ? `
    height: 100%;
  ` : `
    /** Without a minimum height, browsers don't calculate the right height */
    min-height: 7.5rem;
  `}

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    background-color: ${theme.color.background.neutral.default.low};
  }
`;

export const header: ThemedFunctor = ({ theme }) => css`
  margin-bottom: ${theme.remSize[300]};
`;

export const headerContent: ThemedFunctor = ({ theme }) => css`

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    display: flex;
  }
`;

export const headerPrimary: ThemedFunctor = ({ theme }) => css`
  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    flex-grow: 1;
  }
`;

export const headerSecondary: ThemedFunctor = ({ theme }) => css`
  display: flex;
  align-items: flex-start;
  margin-top: ${theme.spaceUnits.mobile.SMALL};

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    flex-shrink: 0;
    margin-top: 0;
  }
`;

export const subtitle: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLEST)}
  ${getTypography(theme, TypographyName.BODY)};
  display: flex;
  align-items: center;
  gap: .5rem;
`;

export const sideNavigation: ThemedFunctor = ({
  theme,
  mobileMenuOpen,
}) => css`
  ${getSpacing.padding(theme, [SpacingName.MEDIUM, SpacingName.SMALL])}
  position: fixed;
  z-index: ${theme.zIndex.sidebar};
  overflow-y: auto;
  left: 0;
  top: ${NavigationMobileHeight};
  bottom: 0;
  right: auto;
  width: calc(100% - 3rem);
  background-color: ${theme.color.background.neutral.default.lowest};
  border-left: 1px solid ${theme.color.border.neutral.default.high};
  transform: translateX(-100%);
  transition: transform .4s ease-in-out 0s, visibility .4s ease-in-out 0s;
  will-change: transform;
  visibility: hidden;

  ${mobileMenuOpen && `
    visibility: visible;
    transform: translateX(0);
  `}

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    position: initial;
    visibility: visible;
    transform: translateX(0);
    width: ${sideDesktopWidth};
  }

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const main: ThemedFunctor = () => css`
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const mainContent: ThemedFunctor = ({
  theme,
  hasInPageNavigation,
  $fitMaxAvailableHeight,
}) => css`
  padding: ${getSpacing.sum(hasInPageNavigation ? NavigationInPageHeight : '0', `${theme.spaceUnits.mobile.MEDIUM}`)} 0 ${theme.spaceUnits.mobile.MEDIUM};

  ${$fitMaxAvailableHeight && `
    height: 100%;
    display: flex;
    flex-direction: column;
  `}

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    padding: ${getSpacing.sum(hasInPageNavigation ? NavigationInPageHeight : '0', `${theme.spaceUnits.desktop.MEDIUM}`)} 0 ${theme.spaceUnits.desktop.MEDIUM};
  }
`;

export const inPageNavigation: ThemedFunctor = ({
  theme,
  hasSide,
}) => css`
  position: fixed;
  top: auto;
  left: 0;
  right: 0;
  z-index: ${theme.zIndex.inpage};
  background-color: ${theme.palettes.neutrals.lighter};
  border-bottom: 1px solid ${theme.palettes.neutrals.medium};
  height: ${NavigationInPageHeight};

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    ${hasSide && `
      padding-left: ${sideDesktopWidth};
    `}
  }
`;

export const sideCloseArea: ThemedFunctor = ({
  theme,
  mobileMenuOpen,
}) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  display: block;
  z-index: ${theme.zIndex.sidebar - 1};
  background-color: rgba(51, 51, 51, 0.6);
  cursor: pointer;
  will-change: opacity;
  border: 0;
  outline: 0;
  transition: opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;

  ${mobileMenuOpen && `
    opacity: 1;
    visibility: visible;
  `}

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    opacity: 0;
    visibility: hidden;
  }
`;

export const sideOpenArea: ThemedFunctor = ({
  theme,
  hasInPageNavigation,
}) => css`
  position: fixed;
  right: 1.5rem;
  top: ${hasInPageNavigation ? getSpacing.sum(NavigationMobileHeight, NavigationInPageHeight, '.75rem') : getSpacing.sum(NavigationMobileHeight, '.75rem')};
  z-index: ${theme.zIndex.sidebar + 1};
  display: block;

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    display: none;
  }
`;
