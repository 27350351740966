import React, { MutableRefObject } from 'react';
import {
  Action, Form, FormHandlers, FormProps, Grid, ICON_CURRENCY_EUR, ICON_PERCENT, ICON_SWAP_HORIZONTAL,
  Message,
} from '@doveit/bricks';
import { CommissionPrice } from '../../../../../providers/api/dtos';
import { BooleanString } from '../../../../../utils/boolean-string/booleanString';
import validationSchema from './OfferWizardCommissionsForm.schema';

export interface OfferWizardCommissionsFormModel {
  buyerCommission: BooleanString,
  type: CommissionPrice,
  value: string,
}

export interface OfferWizardCommissionsFormProps {
  initialValues?: Partial<OfferWizardCommissionsFormModel>,
  innerRef?: MutableRefObject<FormHandlers>,
  disabled?: FormProps<OfferWizardCommissionsFormModel>['disabled'],
  loading?: FormProps<OfferWizardCommissionsFormModel>['loading'],
  onSubmit: FormProps<OfferWizardCommissionsFormModel>['onSubmit'],
}

export const DEFAULT_INITIAL_VALUES: OfferWizardCommissionsFormModel = {
  buyerCommission: 'true',
  type: CommissionPrice.FIXED,
  value: '',
};

const OfferWizardCommissionsForm: React.FC<OfferWizardCommissionsFormProps> = ({
  initialValues = {},
  ...rest
}) => {
  const mergedInitialValues: OfferWizardCommissionsFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      enableReinitialize
      {...rest}
    >
      {({ values, setFieldValue }) => {
        const swapCommissionsType = () => {
          setFieldValue('type', values.type === CommissionPrice.FIXED
            ? CommissionPrice.PERCENTAGE
            : CommissionPrice.FIXED);
          setFieldValue('value', '');
        };

        const handleBuyerCommissionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const currentValue = e.target.value;

          if (currentValue === 'false') {
            setFieldValue('type', CommissionPrice.FIXED);
            setFieldValue('value', '0');
          }
        };

        return (
          <>
            <Form.Item>
              <Form.YesOrNotButtons
                label="Commissioni al compratore"
                name="buyerCommission"
                aria-label="Campo per specificare se si applicano commissioni al compratore"
                onChange={handleBuyerCommissionsChange}
                required
              />
            </Form.Item>

            <Form.Item>
              <Grid gutter={150}>
                <Grid.Unit size={{ SM: 2 / 3, MD: 1 / 3 }}>
                  <Form.DecimalPriceField
                    name="value"
                    disabled={values.buyerCommission === 'false'}
                    aria-label="Campo per specificare l'ammontare delle commissioni al compratore"
                    required
                    icon={values.type === CommissionPrice.FIXED
                      ? ICON_CURRENCY_EUR
                      : ICON_PERCENT}
                  />
                </Grid.Unit>
                <Grid.Unit size={{ SM: 1 / 3, MD: 1 / 3 }}>
                  <Action
                    iconLeft={{ path: ICON_SWAP_HORIZONTAL }}
                    disabled={values.buyerCommission === 'false'}
                    label={values.type === CommissionPrice.FIXED
                      ? 'Usa percentuale'
                      : 'Usa valore fisso'}
                    aria-label="Azione per cambiare l'unità delle commissioni al compratore"
                    onClick={swapCommissionsType}
                  />
                </Grid.Unit>
              </Grid>
            </Form.Item>

            <Form.Item>
              <Message
                type="info"
                message="Le somme inserite non comprendono l'IVA"
              />
            </Form.Item>
          </>
        );
      }}
    </Form>
  );
};

export default OfferWizardCommissionsForm;
