import {
  Form, FormProps, FormSubmitType, SelectOption,
} from '@doveit/bricks';
import React from 'react';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { assignmentPropertyStatusLabels, assignmentSubscriptionPlaceLabels } from '../../../labels';
import { AssignmentPropertyStatus, PropertySource } from '../../../providers/api/dtos';
import { BooleanString } from '../../../utils/boolean-string/booleanString';
import { propertySourceLabels } from '../../../labels/propertySourceLabels';
import { INPUT_PLACEHOLDERS } from '../../../constants';
import { formatInputDate } from '../../../utils/form';

export interface PropertyInfoFormModel {
  propertyStatus: string,
  annualRentFee: string,
  annualRentExpiresAt: string,
  isUrbanCompliant: BooleanString,
  urbanCompliantNotes: string,
  areSystemsCompliant: BooleanString,
  systemsCompliantNotes: string,
  hasOccupancyCertificate: BooleanString,
  occupancyCertificateNotes: string,
  isConstraintsFree: BooleanString,
  constraintsDetails: string,
  technicianNeeded: BooleanString,
  technician: string,
  annualCondominiumExpenses: string,
  extraExpensesDeliberated: BooleanString,
  extraExpensesDetails: string,
  legalIssues: BooleanString,
  propertySource: string,
  subscriptionPlace: string,
  propertySourceInfo: string,
}

export interface PropertyInfoFormProps {
  initialValues?: Partial<PropertyInfoFormModel>,
  hiddenFields?: Partial<Record<keyof PropertyInfoFormModel, boolean>>,
  propertyStatusOptions?: SelectOption[],
  loading?: FormProps<PropertyInfoFormModel>['loading'],
  disabled?: FormProps<PropertyInfoFormModel>['disabled'],
  onSubmit: FormSubmitType<PropertyInfoFormModel>,
  formRef: FormProps<PropertyInfoFormModel>['innerRef'],
}

const technicianOptions: SelectOption[] = [
  {
    label: 'Seleziona',
    value: '',
  },
  {
    label: 'Dove.it',
    value: 'dove.it',
  },
  {
    label: 'Venditore',
    value: 'seller',
  },
];

const DEFAULT_INITIAL_VALUES: PropertyInfoFormModel = {
  propertyStatus: '',
  annualRentFee: '',
  annualRentExpiresAt: '',
  isUrbanCompliant: '',
  urbanCompliantNotes: '',
  areSystemsCompliant: '',
  systemsCompliantNotes: '',
  hasOccupancyCertificate: '',
  occupancyCertificateNotes: '',
  isConstraintsFree: '',
  constraintsDetails: '',
  technicianNeeded: 'false',
  technician: '',
  annualCondominiumExpenses: '',
  extraExpensesDeliberated: '',
  extraExpensesDetails: '',
  legalIssues: '',
  propertySource: '',
  subscriptionPlace: '',
  propertySourceInfo: '',
};

const PropertyInfoForm: React.FC<PropertyInfoFormProps> = ({
  initialValues = {},
  hiddenFields = {},
  propertyStatusOptions = asSelectOptions(assignmentPropertyStatusLabels),
  formRef,
  ...rest
}) => {
  const mergedInitialValues: PropertyInfoFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  const minAnnualRentExpiresAt = React.useMemo(() => formatInputDate(new Date()), []);

  return (
    <Form
      initialValues={mergedInitialValues}
      innerRef={formRef}
      enableReinitialize
      {...rest}
    >
      {({ values, setFieldValue }) => {
        const onTechnicianNeededFieldChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
          if (target.value === 'false' && !hiddenFields.technician) {
            setFieldValue('technician', '');
          }
        };

        const onIsConstraintsFreeFieldChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
          if (target.value === 'true' && !hiddenFields.constraintsDetails) {
            setFieldValue('constraintsDetails', '');
          }
        };

        const onPropertySourceFieldChange: React.ChangeEventHandler<HTMLSelectElement> = ({ target }) => {
          if (target.value !== PropertySource.OTHER && !hiddenFields.propertySourceInfo) {
            setFieldValue('propertySourceInfo', '');
          }
        };

        return (
          <>
            {!hiddenFields.propertyStatus && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.Select
                  name="propertyStatus"
                  label="Stato immobile"
                  aria-label="Campo per inserire lo stato dell'immobile"
                  options={propertyStatusOptions}
                />
              </Form.Item>
            )}

            {!hiddenFields.annualRentFee && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.DecimalPriceField
                  name="annualRentFee"
                  label="Canone annuo"
                  aria-label="Campo per inserire il canone annuo della locazione"
                  disabled={values.propertyStatus !== AssignmentPropertyStatus.RENTAL}
                  placeholder={INPUT_PLACEHOLDERS.PROPERTY_ANNUAL_RENT_FEE}
                />
              </Form.Item>
            )}

            {!hiddenFields.annualRentExpiresAt && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.Input
                  name="annualRentExpiresAt"
                  label="Scadenza"
                  type="date"
                  aria-label="Campo per inserire la data di fine locazione"
                  disabled={values.propertyStatus !== AssignmentPropertyStatus.RENTAL}
                  min={minAnnualRentExpiresAt}
                />
              </Form.Item>
            )}

            {!hiddenFields.isUrbanCompliant && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.YesOrNotButtons
                  name="isUrbanCompliant"
                  label="È conforme"
                  aria-label="Campo per indicare se l'immobile è conforme"
                />
              </Form.Item>
            )}

            {!hiddenFields.urbanCompliantNotes && (
              <Form.Item size={{ MD: 2 / 3 }}>
                <Form.TextArea
                  style={{ resize: 'vertical' }}
                  name="urbanCompliantNotes"
                  label="Note sulla conformità"
                  aria-label="Campo per inserire le note sulla conformità"
                  rows={1}
                />
              </Form.Item>
            )}

            {!hiddenFields.areSystemsCompliant && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.YesOrNotButtons
                  name="areSystemsCompliant"
                  label="Ha impianti conformi"
                  aria-label="Campo per indicare la conformità degli impianti dell'immobile"
                />
              </Form.Item>
            )}

            {!hiddenFields.systemsCompliantNotes && (
              <Form.Item size={{ MD: 2 / 3 }}>
                <Form.TextArea
                  style={{ resize: 'vertical' }}
                  name="systemsCompliantNotes"
                  label="Note sugli impianti"
                  aria-label="Campo per inserire le note sugli impianti"
                  rows={1}
                />
              </Form.Item>
            )}

            {!hiddenFields.hasOccupancyCertificate && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.YesOrNotButtons
                  name="hasOccupancyCertificate"
                  label="Ha il certificato di agibilità"
                  aria-label="Campo per indicare se l'immobile possiede il certificato di agibilità"
                />
              </Form.Item>
            )}

            {!hiddenFields.occupancyCertificateNotes && (
              <Form.Item size={{ MD: 2 / 3 }}>
                <Form.TextArea
                  style={{ resize: 'vertical' }}
                  name="occupancyCertificateNotes"
                  label="Note sulla agibilità"
                  aria-label="Campo per inserire le note sulla agibilità"
                  rows={1}
                />
              </Form.Item>
            )}

            {!hiddenFields.isConstraintsFree && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.YesOrNotButtons
                  name="isConstraintsFree"
                  label="È privo di pregiudizi e vincoli"
                  aria-label="Campo per indicare se l'immobile è privo di pregiudizi e vincoli"
                  onChange={onIsConstraintsFreeFieldChange}
                />
              </Form.Item>
            )}

            {!hiddenFields.constraintsDetails && (
              <Form.Item size={{ MD: 2 / 3 }}>
                <Form.Input
                  name="constraintsDetails"
                  label="Pregiudizi, servitù e vincoli"
                  aria-label="Campo per inserire dettagli su pregiudizi, servitù e vincoli"
                  disabled={values.isConstraintsFree !== 'false'}
                />
              </Form.Item>
            )}

            {!hiddenFields.technicianNeeded && (
              <Form.Item size={{ MD: 2 / 3 }}>
                <Form.YesOrNotButtons
                  name="technicianNeeded"
                  label="Conferisce incarico a tecnico per verifica conformità urbanistica"
                  aria-label="Campo per indicare se si conferisce l'incarico per verifica della conformità urbanistica"
                  onChange={onTechnicianNeededFieldChange}
                />
              </Form.Item>
            )}

            {!hiddenFields.technician && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.Select
                  name="technician"
                  label="Nomina tecnico"
                  options={technicianOptions}
                  aria-label="Campo per indicare il tecnico incaricato per verifica della conformità urbanistica"
                  disabled={values.technicianNeeded !== 'true'}
                />
              </Form.Item>
            )}

            {!hiddenFields.annualCondominiumExpenses && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.PriceField
                  name="annualCondominiumExpenses"
                  label="Spese condominiali annue"
                  aria-label="Campo per inserire le spese condominiali annuali"
                  placeholder={INPUT_PLACEHOLDERS.PROPERTY_ANNUAL_CONDOMINIUM_EXPENSES}
                />
              </Form.Item>
            )}

            {!hiddenFields.extraExpensesDeliberated && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.YesOrNotButtons
                  name="extraExpensesDeliberated"
                  label="Previste spese straordinarie"
                  aria-label="Campo per indicare se sono previste spese straordinarie"
                />
              </Form.Item>
            )}

            {!hiddenFields.extraExpensesDetails && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.TextArea
                  style={{ resize: 'vertical' }}
                  name="extraExpensesDetails"
                  label="Note sulle spese straordinarie"
                  aria-label="Campo per inserire le note sulle spese straordinarie"
                  rows={1}
                />
              </Form.Item>
            )}

            {!hiddenFields.legalIssues && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.YesOrNotButtons
                  name="legalIssues"
                  label="Controversie legali"
                  aria-label="Campo per indicare se esistono controversie legali"
                />
              </Form.Item>
            )}

            {!hiddenFields.propertySource && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.Select
                  name="propertySource"
                  label="Provenienza"
                  options={asSelectOptions(propertySourceLabels)}
                  aria-label="Campo per selezionare la provenienza dell'immobile"
                  onChange={onPropertySourceFieldChange}
                />
              </Form.Item>
            )}

            {!hiddenFields.subscriptionPlace && (
              <Form.Item size={{ MD: 1 / 3 }}>
                <Form.Select
                  name="subscriptionPlace"
                  label="Luogo di stipula"
                  options={asSelectOptions(assignmentSubscriptionPlaceLabels)}
                  aria-label="Campo per indicare il luogo di stipula"
                />
              </Form.Item>
            )}

            {!hiddenFields.propertySourceInfo && (
              <Form.Item>
                <Form.Input
                  name="propertySourceInfo"
                  label="Info provenienza"
                  aria-label="Campo per inserire i dettagli sulla provenienza dell'immobile"
                  disabled={values.propertySource !== PropertySource.OTHER}
                />
              </Form.Item>
            )}
          </>
        );
      }}
    </Form>
  );
};

export default PropertyInfoForm;
