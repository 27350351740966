import {
  Form, FormSubmitType, ICON_MAGNIFY,
} from '@doveit/bricks';
import React from 'react';
import FullTextSearchFormSchema from './FullTextSearchForm.schema';

export type FullTextSearchFormModel = {
  search?: string;
};

export type FullTextSearchFormSubmit = (values: FullTextSearchFormModel) => void;

export interface FullTextSearchFormProps extends React.AriaAttributes {
  onSubmit: FormSubmitType<FullTextSearchFormModel>;
  initialValues?: FullTextSearchFormModel;
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

const FullTextSearchForm: React.FC<FullTextSearchFormProps> = ({
  initialValues,
  disabled,
  loading,
  placeholder,
  onSubmit,
  ...rest
}) => {
  const mergedInitialValues = React.useMemo(() => ({
    search: initialValues?.search ?? '',
  }), [initialValues]);

  return (
    <Form
      {...rest}
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      validationSchema={FullTextSearchFormSchema}
      disabled={disabled}
      loading={loading}
    >
      <Form.Item size={{ MD: 3 / 4, LG: 4 / 5 }}>
        <Form.Input
          name="search"
          aria-label="Campo per inserire il valore di ricerca"
          placeholder={placeholder || 'Inserisci un valore di ricerca'}
          icon={ICON_MAGNIFY}
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 4, LG: 1 / 5 }}>
        <Form.Submit
          iconLeft={{ path: ICON_MAGNIFY }}
          label="Cerca"
          expanded
        />
      </Form.Item>
    </Form>
  );
};

export default FullTextSearchForm;
