import {
  AgencyCommissionSchemaType, AgentCommissionSchemaType, CommissionSchema, CommissionSchemaType,
} from '../domain/types';

export const agentCommissionSchemaLabels: Record<AgentCommissionSchemaType, string> = {
  [CommissionSchema['50_15']]: '50-15',
  [CommissionSchema['60_20']]: '60-20',
  [CommissionSchema['60_25']]: '60-25',
  [CommissionSchema['70_30']]: '70-30',
  [CommissionSchema['50_30_OLD']]: '50-30 vecchio',
  [CommissionSchema['60_20_OLD']]: '60-20 vecchio',
  [CommissionSchema['70_20_OLD']]: '70-20 vecchio',
  [CommissionSchema.AREA_MANAGER]: 'Area manager',
  [CommissionSchema.CUSTOM]: 'Personalizzato',
};

export const agencyCommissionSchemaLabels: Record<AgencyCommissionSchemaType, string> = {
  [CommissionSchema.SILVER]: 'Silver',
  [CommissionSchema.GOLD]: 'Gold',
  [CommissionSchema.CUSTOM]: 'Personalizzato',
};

export const commissionSchemaLabels: Record<CommissionSchemaType, string> = {
  ...agentCommissionSchemaLabels,
  ...agencyCommissionSchemaLabels,
};
