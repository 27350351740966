import {
  ThemedFunctor, getTypography, TypographyName, FontWeight, getSpacing, SpacingName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({ theme, $hasChildren }) => css`
  ${getTypography(theme, TypographyName.BODY, { fontWeight: FontWeight.MEDIUM })}
  ${getSpacing.padding(theme, [SpacingName.SMALL, 0])}
  color: ${theme.palettes.neutrals.medium};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 1rem;
  line-height: 1rem;

  &::after {
    content: '';
    height: 1px;
    display: block;
    background-color: ${theme.palettes.neutrals.light};
    flex-grow: 1;
    margin-left: ${$hasChildren ? 1 : 0}rem;
  }
`;
