import {
  ActionIcon, ConfirmModal, ICON_TRASH_CAN_OUTLINE, Portal, useModal,
  useNotifications,
} from '@doveit/bricks';
import React from 'react';
import { Property } from '../../../providers/api/dtos';
import { updateProperty } from '../../../providers/api/property/propertyProvider';

export const DELETE_UNIT_ERROR_MESSAGE = "Errore durante l'eliminazione dell'unità catastale";
export const DELETE_UNIT_SUCCESS_MESSAGE = "L'unità catastale è stata eliminata correttamente";

interface ChildFnProps {
  openDeleteModal: VoidFunction,
}

export interface DeletePropertyUnitActionProps {
  property: Property,
  unitIndex: number,
  onSuccess?: (property: Property) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const DeletePropertyUnitAction: React.FC<DeletePropertyUnitActionProps> = ({
  property,
  unitIndex,
  onSuccess,
  children,
}) => {
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const openModal = React.useCallback(
    () => modal.open(),
    [modal],
  );

  const onAbort = React.useCallback(() => {
    modal.close();
  }, [modal]);

  const onConfirm = React.useCallback(async () => {
    setIsSaving(true);

    const propertyUnitsUpdated = (property.cadastralRegistry?.units ?? []).slice();
    const normalizedIndex = Math.max(Math.min(unitIndex, propertyUnitsUpdated.length - 1), 0);
    propertyUnitsUpdated.splice(normalizedIndex, 1);

    try {
      const payload: Property = {
        ...property,
        cadastralRegistry: {
          ...property.cadastralRegistry,
          units: propertyUnitsUpdated,
        },
      };

      const result = await updateProperty(property.id!, payload);

      onSuccess?.(result);
      modal.close();
      addSuccess(DELETE_UNIT_SUCCESS_MESSAGE);
    } catch (error) {
      addError(DELETE_UNIT_ERROR_MESSAGE);
    }

    setIsSaving(false);
  }, [property, unitIndex, onSuccess, modal, addSuccess, addError]);

  return (
    <>
      {children
        ? children({
          openDeleteModal: openModal,
        })
        : (
          <ActionIcon
            icon={
              { path: ICON_TRASH_CAN_OUTLINE }
            }
            label="Elimina"
            aria-label="Pulsante per eliminare l'unità catastale"
            size="S"
            onClick={openModal}
          />
        )}
      <Portal>
        <ConfirmModal
          variant="critical"
          title="Conferma eliminazione unità catastale"
          aria-label="Modale per eliminare l'unità catastale"
          isOpen={modal.isOpen}
          saving={isSaving}
          onConfirm={onConfirm}
          onAbort={onAbort}
          dismissable={false}
        >
          Confermando l&apos;azione, l&apos;unità catastale sarà eliminata e i dati non potranno essere recuperati.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default DeletePropertyUnitAction;
