export enum LeadSource {
  WEBSITE = 'website',
  ADWORDS = 'adwords',
  GOOGLE = 'google-ads',
  FACEBOOK = 'facebook',
  EMAIL = 'email',
  IMMOBILIARE = 'immobiliare',
  CASA = 'casa',
  IDEALISTA = 'idealista',
  REQUOT = 'requot',
  FACILE = 'facile',
  SUBITO = 'subito',
  WIKICASA = 'wikicasa',
  BAKECA = 'bakeca',
  BORSINO = 'borsino',
  INBOUND = 'inbound',
  B2B = 'b2b',
  BENEFITHUB = 'benefithub',
  PUNTOZERO = 'puntozero',
  CASAVO = 'casavo',
  PERSONAL = 'personal',
  REFERRAL = 'referral',
  SALES_REFERRAL = 'sales-referral',
  NETTBUREAU = 'nettbureau',
  ZAPPYRENT = 'zappyrent',
  INTENT = 'intent',
  COLD_LEAD = 'cold-lead',
}
