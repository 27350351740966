import { CommissionSchemaType } from '../../domain/types/commissionSchema';
import { Commissions } from '../../providers/api/dtos';

export const commissionSchemaDictionary: Record<CommissionSchemaType, Commissions | undefined> = {
  '50_15': {
    fixedSalary: 0,
    commissionSplit: {
      personal: 0.5,
      dove: 0.15,
    },
    managementCost: 0,
    fee: 0,
    photoPrice: {
      personal: 0,
      dove: 0,
    },
    overCommissionSplit: {
      personal: 0.5,
      dove: 0.15,
    },
    overCommissionThreshold: 0,
  },
  '60_20': {
    fixedSalary: 0,
    commissionSplit: {
      personal: 0.6,
      dove: 0.2,
    },
    managementCost: 0,
    fee: 0,
    photoPrice: {
      personal: 99,
      dove: 0,
    },
    overCommissionSplit: {
      personal: 0.6,
      dove: 0.3,
    },
    overCommissionThreshold: 30_000,
  },
  '60_25': {
    fixedSalary: 0,
    commissionSplit: {
      personal: 0.6,
      dove: 0.25,
    },
    managementCost: 0,
    fee: 0,
    photoPrice: {
      personal: 99,
      dove: 0,
    },
    overCommissionSplit: {
      personal: 0.6,
      dove: 0.3,
    },
    overCommissionThreshold: 30_000,
  },
  '70_30': {
    fixedSalary: 0,
    commissionSplit: {
      personal: 0.7,
      dove: 0.3,
    },
    managementCost: 0,
    fee: 0,
    photoPrice: {
      personal: 99,
      dove: 0,
    },
    overCommissionSplit: {
      personal: 0.7,
      dove: 0.3,
    },
    overCommissionThreshold: 0,
  },
  '50_30_OLD': {
    fixedSalary: 0,
    commissionSplit: {
      personal: 0.5,
      dove: 0.3,
    },
    managementCost: 0,
    fee: 0,
    photoPrice: {
      personal: 0,
      dove: 0,
    },
    overCommissionSplit: {
      personal: 0.7,
      dove: 0.3,
    },
    overCommissionThreshold: 20_000,
  },
  '60_20_OLD': {
    fixedSalary: 0,
    commissionSplit: {
      personal: 0.6,
      dove: 0.2,
    },
    managementCost: 0,
    fee: 0,
    photoPrice: {
      personal: 0,
      dove: 0,
    },
    overCommissionSplit: {
      personal: 0.7,
      dove: 0.2,
    },
    overCommissionThreshold: 30_000,
  },
  '70_20_OLD': {
    fixedSalary: 0,
    commissionSplit: {
      personal: 0.7,
      dove: 0.2,
    },
    managementCost: 0,
    fee: 0,
    photoPrice: {
      personal: 0,
      dove: 0,
    },
    overCommissionSplit: {
      personal: 0.7,
      dove: 0.2,
    },
    overCommissionThreshold: 0,
  },
  AREA_MANAGER: {
    fixedSalary: 1_500,
    commissionSplit: {
      personal: 0.5,
      dove: 0.3,
    },
    managementCost: 0.1,
    fee: 0,
    photoPrice: {
      personal: 0,
      dove: 0,
    },
    overCommissionSplit: {
      personal: 0.5,
      dove: 0.3,
    },
    overCommissionThreshold: 0,
  },
  SILVER: {
    fixedSalary: 0,
    commissionSplit: {
      personal: 0.8,
      dove: 0.5,
    },
    managementCost: 0,
    fee: 499,
    photoPrice: {
      personal: 99,
      dove: 99,
    },
    overCommissionSplit: {
      personal: 0.8,
      dove: 0.5,
    },
    overCommissionThreshold: 0,
  },
  GOLD: {
    fixedSalary: 0,
    commissionSplit: {
      personal: 0.85,
      dove: 0.55,
    },
    managementCost: 0,
    fee: 999,
    photoPrice: {
      personal: 99,
      dove: 99,
    },
    overCommissionSplit: {
      personal: 0.85,
      dove: 0.55,
    },
    overCommissionThreshold: 0,
  },
  CUSTOM: undefined,
};
