import React from 'react';
import {
  Action, ConfirmModal, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { AssignmentWizardData } from '../../../providers/api/dtos';
import { cancelAssignmentSignatureRequest } from '../../../providers/api/assignment/assignmentProvider';

export const CANCEL_ASSIGNMENT_SIGNATURE_REQUEST_SUCCESS_MESSAGE = "La richiesta di firma digitale dell'incarico è stata annullata con successo";
export const CANCEL_ASSIGNMENT_SIGNATURE_REQUEST_ERROR_MESSAGE = "Non è stato possibile annullare la richiesta di firma digitale dell'incarico";

interface ChildFnProps {
  cancelSignatureRequest: VoidFunction,
  isLoading: boolean,
}

export interface CancelAssignmentSignatureRequestActionProps {
  assignmentWizardData: AssignmentWizardData,
  onSuccess?: (assignmentWizardData: AssignmentWizardData) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const CancelAssignmentSignatureRequestAction: React.FC<CancelAssignmentSignatureRequestActionProps> = (props) => {
  const { assignmentWizardData, onSuccess, children } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const confirmCancelSignatureRequestModal = useModal();

  const openConfirmModal = React.useCallback(() => {
    confirmCancelSignatureRequestModal.open();
  }, [confirmCancelSignatureRequestModal]);

  const cancelSignatureRequest = React.useCallback(async () => {
    try {
      setIsLoading(true);

      await cancelAssignmentSignatureRequest(assignmentWizardData.prospectId);

      setIsLoading(false);
      confirmCancelSignatureRequestModal.close();

      addSuccess(CANCEL_ASSIGNMENT_SIGNATURE_REQUEST_SUCCESS_MESSAGE);
      onSuccess?.(assignmentWizardData);
    } catch (_) {
      setIsLoading(false);
      addError(CANCEL_ASSIGNMENT_SIGNATURE_REQUEST_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, assignmentWizardData, confirmCancelSignatureRequestModal, onSuccess]);

  if (!assignmentWizardData.isDigitallySigned) {
    return null;
  }

  return (
    <>
      {
      children
        ? children({ cancelSignatureRequest: openConfirmModal, isLoading })
        : (
          <Action
            aria-label="Annulla la richiesta di firma digitale dell'incarico"
            title="Annulla la richiesta di firma digitale dell'incarico"
            label="Annulla"
            onClick={openConfirmModal}
            size="S"
            loading={isLoading}
          />
        )
    }
      <Portal>
        <ConfirmModal
          isOpen={confirmCancelSignatureRequestModal.isOpen}
          title="Conferma annullamento richiesta di firma"
          aria-label="Conferma annullamento richiesta di firma"
          onConfirm={cancelSignatureRequest}
          onAbort={confirmCancelSignatureRequestModal.close}
        >
          Cliccando sul pulsante OK annullerai la richiesta di firma digitale del mandato.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default CancelAssignmentSignatureRequestAction;
