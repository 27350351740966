import useSWR from 'swr';
import hash from 'object-hash';
import { CallsProviderFilters, getCalls } from '../../../providers/api/call/callProvider';
import { Call } from '../../../providers/api/dtos/call';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';

export const CALLS_RID = '/calls/';

export default function useCalls(filters?: CallsProviderFilters, pagination?: PaginationParams): SWRCustomResponse<PaginatedResult<Call>, Error> {
  const matchMutate = useMatchMutate();
  const key = filters ? [CALLS_RID, hash({ ...filters, ...pagination })] : null;
  const swrResponse = useSWR(key, () => getCalls(filters!, pagination));

  return {
    ...swrResponse,
    mutate: () => matchMutate(CALLS_RID),
  };
}
