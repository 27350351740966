import {
  Spacing, Badge, Message,
  HStack,
} from '@doveit/bricks';
import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import { formatMeters } from '@doveit/hammer';
import ContextualNavigation from '../../../components/contextual-navigation/ContextualNavigation';
import {
  CompositeGeoSearchCriterion, GeoSearchCriterionType, PlaceGeoSearchCriterion, PointGeoSearchCriterion,
} from '../../../providers/api/dtos/searchCriteria';
import PointGeoSearchCriterionForm from '../../component/point-geo-search-criterion-form/PointGeoSearchCriterionForm';
import PlaceGeoSearchCriterionForm from '../../component/place-geo-search-criterion-form/PlaceGeoSearchCriterionForm';

export interface SelectGeoSearchCriteriaProps {
  initialValues?: CompositeGeoSearchCriterion[],
  onChange: (values: CompositeGeoSearchCriterion[]) => void
}

export enum SelectGeoSearchCriteriaTab {
  POINT = 'POINT',
  PLACE = 'PLACE',
}

export const MAX_NUMBER_OF_PLACE_GEO_SEARCH_CRITERIA = 10;
export const MAX_NUMBER_OF_PLACE_GEO_SEARCH_CRITERIA_WARNING = `Hai raggiunto il numero massimo di località selezionabili (${MAX_NUMBER_OF_PLACE_GEO_SEARCH_CRITERIA}).`;

const SelectGeoSearchCriteria: FunctionComponent<SelectGeoSearchCriteriaProps> = ({
  initialValues,
  onChange,
}) => {
  const hasPlaceGeoSearchCriterion = useMemo(
    () => initialValues?.find((criterion) => criterion.type !== GeoSearchCriterionType.POINT) !== undefined,
    [initialValues],
  );
  const [activeTab, setActiveTab] = useState(
    hasPlaceGeoSearchCriterion
      ? SelectGeoSearchCriteriaTab.PLACE
      : SelectGeoSearchCriteriaTab.POINT,
  );
  const [geoSearchCriteria, setGeoSearchCriteria] = useState<CompositeGeoSearchCriterion[]>(initialValues ?? []);

  const pointGeoSearchCriterion = useMemo(
    () => geoSearchCriteria.find((criterion) => criterion.type === GeoSearchCriterionType.POINT),
    [geoSearchCriteria],
  );

  const navigationItems = useMemo(() => ([
    {
      id: 'point-geo-search-criteria',
      label: 'Posizione',
      active: activeTab === SelectGeoSearchCriteriaTab.POINT,
      onClick() {
        setActiveTab(SelectGeoSearchCriteriaTab.POINT);
      },
    },
    {
      id: 'locality-geo-search-criteria',
      label: 'Località',
      active: activeTab === SelectGeoSearchCriteriaTab.PLACE,
      onClick() {
        setActiveTab(SelectGeoSearchCriteriaTab.PLACE);
      },
    },
  ]), [activeTab]);

  const getGeoSearchCriterionLabel = useCallback((criterion: CompositeGeoSearchCriterion) => {
    if (criterion.type === GeoSearchCriterionType.LOCALITY) {
      return `${criterion.name} - Comune`;
    }

    if (criterion.type === GeoSearchCriterionType.PROVINCE) {
      return `${criterion.name} - Provincia`;
    }

    if (criterion.type === GeoSearchCriterionType.AREA) {
      return `${criterion.locality} - ${criterion.name}`;
    }

    // GeoSearchCriterionType.POINT
    return `${criterion.address} (+${formatMeters(criterion.radius)})`;
  }, []);

  const onPointSelected = useCallback((selected: PointGeoSearchCriterion) => {
    const newGeoSearchCriteria = [selected];

    setGeoSearchCriteria(newGeoSearchCriteria);
    onChange(newGeoSearchCriteria);
  }, [onChange]);

  const onPlaceSelected = useCallback((selected: PlaceGeoSearchCriterion) => {
    const filtered = geoSearchCriteria
      .filter((criterion) => criterion.type !== GeoSearchCriterionType.POINT) as PlaceGeoSearchCriterion[];

    if (!filtered.find((criterion) => {
      const commonCheck = criterion.name === selected.name && criterion.type === selected.type;

      if (criterion.type === GeoSearchCriterionType.AREA && selected.type === GeoSearchCriterionType.AREA) {
        return commonCheck && criterion.locality === selected.locality;
      }

      return commonCheck;
    })) {
      const newGeoSearchCriteria = [...filtered, selected];

      setGeoSearchCriteria(newGeoSearchCriteria);
      onChange(newGeoSearchCriteria);
    }
  }, [geoSearchCriteria, onChange]);

  const removeCriterion = useCallback((criterion: CompositeGeoSearchCriterion) => () => {
    const newGeoSearchCriteria = geoSearchCriteria.filter((current) => current !== criterion);

    setGeoSearchCriteria(newGeoSearchCriteria);
    onChange(newGeoSearchCriteria);
  }, [geoSearchCriteria, onChange]);

  return (
    <div>
      <ContextualNavigation
        items={navigationItems}
      />
      <Spacing margin={[200, 0]}>
        {activeTab === SelectGeoSearchCriteriaTab.POINT && (
          <div data-ref="point-geo-search-criteria-tab">
            <PointGeoSearchCriterionForm
              initialValues={pointGeoSearchCriterion as PointGeoSearchCriterion}
              onChange={onPointSelected}
            />
          </div>
        )}
        {activeTab === SelectGeoSearchCriteriaTab.PLACE && (
          <div data-ref="place-geo-search-criteria-tab">
            <PlaceGeoSearchCriterionForm
              isDisabled={geoSearchCriteria.length === MAX_NUMBER_OF_PLACE_GEO_SEARCH_CRITERIA}
              onChange={onPlaceSelected}
            />
            {geoSearchCriteria.length === MAX_NUMBER_OF_PLACE_GEO_SEARCH_CRITERIA && (
              <Spacing margin={[200, 0]}>
                <Message
                  type="warning"
                  message={MAX_NUMBER_OF_PLACE_GEO_SEARCH_CRITERIA_WARNING}
                />
              </Spacing>
            )}
          </div>
        )}
      </Spacing>
      <div>
        <HStack>
          {geoSearchCriteria.map((criterion) => (
            <Badge
              key={`${criterion.type}-${getGeoSearchCriterionLabel(criterion)}`}
              label={getGeoSearchCriterionLabel(criterion)}
              onRemove={removeCriterion(criterion)}
            />
          ))}
        </HStack>
      </div>
    </div>
  );
};

export default SelectGeoSearchCriteria;
