import {
  PaginatedResult, PaginationParams, SortOrder, serializePagination,
} from '../../pagination';
import {
  API_APPLICATION_NOTIFICATIONS_COUNT_PATH, API_APPLICATION_NOTIFICATIONS_MARK_ALL_AS_READ_PATH, API_APPLICATION_NOTIFICATIONS_PATH, getClient,
} from '../api';
import { Count } from '../dtos';
import ApplicationNotification from '../dtos/applicationNotification';
import { serializeParams } from '../utils/serializer';

export const DEFAULT_APPLICATION_NOTIFICATIONS_PAGINATION: PaginationParams = {
  page: 0,
  size: 5,
  sort: {
    notificationDate: SortOrder.DESC,
  },
};

export interface ApplicationNotificationFilters {
  read?: ApplicationNotification['read'],
  notificationDate?: ApplicationNotification['notificationDate'],
}

export async function getApplicationNotifications(pagination: PaginationParams = DEFAULT_APPLICATION_NOTIFICATIONS_PAGINATION): Promise<PaginatedResult<ApplicationNotification>> {
  const client = await getClient();

  const { data } = await client.get<PaginatedResult<ApplicationNotification>>(API_APPLICATION_NOTIFICATIONS_PATH, {
    params: serializePagination(pagination),
  });

  return data;
}

export async function getApplicationNotificationsCount(filters: ApplicationNotificationFilters = {}): Promise<Count> {
  const client = await getClient();

  const { data } = await client.get<Count>(API_APPLICATION_NOTIFICATIONS_COUNT_PATH, {
    params: serializeParams(filters),
  });

  return data;
}

export async function markAllApplicationNotificationsAsRead(filters: ApplicationNotificationFilters = {}): Promise<void> {
  const client = await getClient();

  await client.put<void>(API_APPLICATION_NOTIFICATIONS_MARK_ALL_AS_READ_PATH, null, {
    params: serializeParams(filters),
  });
}
