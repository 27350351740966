import {
  ActionIcon, ConfirmModal, ICON_TRASH_CAN_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import React, { FunctionComponent, useState, useCallback } from 'react';
import { deleteReminderV3 } from '../../../providers/api/reminder/reminderProvider';

interface ChildFnProps {
  disabled?: boolean,
  openDeleteReminderModal?: VoidFunction,
}

export interface DeleteReminderActionV3Props {
  reminderId: number,
  disabled?: boolean,
  onSuccess?: VoidFunction,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const DELETE_REMINDER_SUCCESS_MESSAGGE = 'Il promemoria è stato eliminato correttamente';
export const DELETE_REMINDER_ERROR_MESSAGGE = "Errore durante l'eliminazione del promemoria";

const DeleteReminderActionV3: FunctionComponent<DeleteReminderActionV3Props> = ({
  reminderId,
  disabled,
  onSuccess,
  children,
}) => {
  const [saving, setSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();
  const modal = useModal<number>();

  const openDeleteReminderModal = useCallback(() => {
    modal.open(reminderId);
  }, [modal, reminderId]);

  const onAbort = useCallback(() => {
    modal.close();
  }, [modal]);

  const onConfirm = useCallback(async () => {
    setSaving(true);

    try {
      await deleteReminderV3(reminderId);

      setSaving(false);
      addSuccess(DELETE_REMINDER_SUCCESS_MESSAGGE);

      modal.close();
      onSuccess?.();
    } catch (err) {
      setSaving(false);
      addError(DELETE_REMINDER_ERROR_MESSAGGE);
    }
  }, [reminderId, addSuccess, modal, onSuccess, addError]);

  return (
    <>
      {children
        ? children({
          disabled: !!disabled,
          openDeleteReminderModal: disabled
            ? undefined
            : openDeleteReminderModal,
        })
        : (
          <ActionIcon
            icon={{ path: ICON_TRASH_CAN_OUTLINE }}
            size="S"
            color="critical"
            label="Elimina promemoria"
            aria-label="Pulsante per eliminare il promemoria"
            onClick={openDeleteReminderModal}
            disabled={disabled}
          />
        )}
      <Portal>
        <ConfirmModal
          {...modal}
          saving={saving}
          aria-label="Modale per eliminare il promemoria"
          title="Conferma eliminazione promemoria"
          onConfirm={onConfirm}
          onAbort={onAbort}
          dismissable={false}
        >
          Confermando l’azione, il promemoria sarà eliminato e i dati non potranno essere recuperati.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default DeleteReminderActionV3;
