import {
  Form, FormActions, FormProps, FormSubmitType,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import parsePhoneNumber from 'libphonenumber-js/max';
// eslint-disable-next-line import/no-cycle
import validationSchema from '../contact-form/ContactForm.schema';
import { COUNTRY } from '../../../constants';

export interface SearchContactFormModel {
  name?: string,
  phoneNumber?: string,
  email?: string,
}

export interface SearchContactFormProps {
  onSubmit: FormSubmitType<SearchContactFormModel>,
  initialValues?: SearchContactFormModel,
  loading?: boolean,
  formRef?: FormProps<SearchContactFormModel>['innerRef']
}

const DEFAULT_INITIAL_VALUES: SearchContactFormModel = {
  name: '',
  email: '',
  phoneNumber: '',
};

const SearchContactForm: FunctionComponent<SearchContactFormProps> = ({
  onSubmit,
  initialValues,
  loading,
  formRef,
}) => {
  const onInternalSubmit = useCallback((values: SearchContactFormModel, actions: FormActions<SearchContactFormModel>) => {
    let { phoneNumber } = values;

    if (values.phoneNumber) {
      const parsed = parsePhoneNumber(values.phoneNumber, COUNTRY);
      phoneNumber = parsed?.number.toString();
    }

    onSubmit({
      ...values,
      phoneNumber,
    }, actions);
  }, [onSubmit]);

  const mergedInitialValues = useMemo(() => ({
    ...(initialValues || {}),
    ...DEFAULT_INITIAL_VALUES,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={onInternalSubmit}
      validationSchema={validationSchema}
      loading={loading}
      innerRef={formRef}
      preventEnterKeypress
    >
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="phoneNumber"
          aria-label="Telefono"
          label="Telefono"
          placeholder="Es. 3923456789"
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="email"
          aria-label="Email"
          label="Email"
          placeholder="Es. mario.rossi@gmail.com"
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="name"
          aria-label="Nome e cognome"
          label="Nome e cognome"
          placeholder="Es. Mario Rossi"
        />
      </Form.Item>
    </Form>
  );
};

export default SearchContactForm;
