import { Form, FormActionsAlign, FormProps } from '@doveit/bricks';
import { isFuture, min } from 'date-fns';
import React from 'react';
import { formatInputDate } from '../../../utils/form';
import validationSchema from './MortgageMaxAcceptanceDateForm.schema';

export interface MortgageMaxAcceptanceDateFormModel {
  maxAcceptanceDate: string,
}

export interface MortgageMaxAcceptanceDateFormProps {
  initialValues: MortgageMaxAcceptanceDateFormModel,
  loading?: boolean,
  disabled?: boolean,
  onSubmit: FormProps<MortgageMaxAcceptanceDateFormModel>['onSubmit'],
}

const MortgageMaxAcceptanceDateForm: React.FC<MortgageMaxAcceptanceDateFormProps> = ({
  initialValues,
  onSubmit,
  ...rest
}) => {
  const computedInitialValues = React.useMemo(() => ({
    maxAcceptanceDate: initialValues.maxAcceptanceDate && isFuture(initialValues.maxAcceptanceDate) ? initialValues.maxAcceptanceDate : formatInputDate(new Date()),
  }), [initialValues.maxAcceptanceDate]);

  const minDate = React.useMemo(() => formatInputDate(min([new Date(), initialValues.maxAcceptanceDate])),
    [initialValues.maxAcceptanceDate]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={computedInitialValues}
      validationSchema={validationSchema}
      {...rest}
    >
      <Form.Item>
        <Form.Input
          label="Data massima delibera"
          name="maxAcceptanceDate"
          type="date"
          min={minDate}
        />
      </Form.Item>
      <Form.Actions align={FormActionsAlign.RIGHT}>
        <Form.Submit
          label="Modifica"
        />
      </Form.Actions>

    </Form>
  );
};

export default MortgageMaxAcceptanceDateForm;
