import {
  Card,
  ICON_CHECK,
  Message,
  Badge,
  CardSkeleton,
  ICON_ALERT_CIRCLE_OUTLINE,
  HStack,
  Dropdown,
  ICON_CHECKBOX_OUTLINE,
} from '@doveit/bricks';
import React from 'react';
import useContactOptIns from '../../hooks/use-contact-opt-ins/useContactOptIns';
import UpdateContactOptInsAction from '../update-contact-opt-ins-action/UpdateContactOptInsAction';
import sortOptIns from '../../utils/sortOptIns';
import { optInTypeLabels } from '../../../labels/optInTypeLabels';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';

export interface OptInsWidgetProps {
  contactId: string,
  canAdd?: boolean,
  canDelete?: boolean,
  canSeeDetails?: boolean,
  onLoadError?: VoidFunction,
}

const OptInsWidget: React.FC<OptInsWidgetProps> = ({
  contactId,
  canAdd = false,
  canDelete = false,
  canSeeDetails = false,
  onLoadError,
}) => {
  const isMobile = useIsDevice('mobile');

  const {
    data: optIns,
    error: optInsError,
    isLoading: areOptInsLoading,
  } = useContactOptIns(contactId, {
    onError: onLoadError,
  });

  if (areOptInsLoading) {
    return <CardSkeleton aria-label="Dati consensi in caricamento" />;
  }

  if (optInsError) {
    return (
      <Card aria-label="Errore nel caricamento dei consensi">
        <Card.Header
          title="Consensi"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          color="critical"
        />
        <Card.Content>
          <Message message="Non è stato possibile caricare i dati dei consensi." />
        </Card.Content>
      </Card>
    );
  }

  return optIns! && (
    <Card aria-label="Sezione consensi">
      <Card.Header
        title="Consensi"
        icon={{ path: ICON_CHECKBOX_OUTLINE }}
        primaryActions={[
          !isMobile && canSeeDetails && (
            <UpdateContactOptInsAction
              contactId={contactId}
              canAdd={canAdd}
              canDelete={canDelete}
            />
          )]}
        secondaryActions={[
          isMobile && canSeeDetails && (
            <UpdateContactOptInsAction
              contactId={contactId}
              canAdd={canAdd}
              canDelete={canDelete}
            >
              {({ update, label, isSaving }) => (
                <Dropdown.Option
                  label={label}
                  aria-label="Apri modale di modifica consensi"
                  onClick={update}
                  loading={isSaving}
                />
              )}
            </UpdateContactOptInsAction>
          ),
        ]}
      />

      <Card.Content>
        {optIns.length === 0 && (
          <Message
            message="Nessun consenso prestato."
            type="neutral"
          />
        )}

        {optIns && (
          <HStack>
            {sortOptIns(optIns).map((optIn) => (
              <Badge
                key={optIn.id}
                icon={ICON_CHECK}
                label={optInTypeLabels[optIn.type].label}
                size="XS"
              />
            ))}
          </HStack>
        )}
      </Card.Content>
    </Card>
  );
};

export default OptInsWidget;
