import { LeadStatus } from '../../../../domain/types';
import { LeadsProviderFilters } from '../../../../providers/api/lead/leadProvider';

export const CONTACT_LEADS_LOAD_ERROR = 'Non è stato possibile caricare i dati relativi alle lead del contatto.';

export const EXCLUDED_LEAD_STATUSES = [LeadStatus.APPOINTMENT_SCHEDULED, LeadStatus.KO_DUPLICATE, LeadStatus.KO_TEST, LeadStatus.PRIVATE];

export const CONTACT_ACTIVITIES_SUMMARY_LEADS_FILTERS: LeadsProviderFilters = {
  excludeStatus: EXCLUDED_LEAD_STATUSES,
};

// The lower the number, the higher the priority
export const LEAD_STATUS_SORT_ORDER: Record<LeadStatus, number> = {
  [LeadStatus.IN_PROGRESS]: 0,
  [LeadStatus.KO_BUY]: 1,
  [LeadStatus.KO_CANNOT_CONTACT]: 1,
  [LeadStatus.KO_GARAGE]: 1,
  [LeadStatus.KO_GEO]: 1,
  [LeadStatus.KO_NOT_INTERESTED]: 1,
  [LeadStatus.KO_NOT_RESIDENTIAL]: 1,
  [LeadStatus.KO_ONLY_EVALUATION]: 1,
  [LeadStatus.KO_OTHER]: 1,
  [LeadStatus.KO_RENT]: 1,
  [LeadStatus.KO_WRONG_PHONE_NUMBER]: 1,
  [LeadStatus.APPOINTMENT_SCHEDULED]: Number.POSITIVE_INFINITY,
  [LeadStatus.PRIVATE]: Number.POSITIVE_INFINITY,
  [LeadStatus.KO_TEST]: Number.POSITIVE_INFINITY,
  [LeadStatus.KO_DUPLICATE]: Number.POSITIVE_INFINITY,
};

export const LEAD_KO_STATUSES = [
  LeadStatus.KO_BUY,
  LeadStatus.KO_CANNOT_CONTACT,
  LeadStatus.KO_GARAGE,
  LeadStatus.KO_GEO,
  LeadStatus.KO_NOT_INTERESTED,
  LeadStatus.KO_NOT_RESIDENTIAL,
  LeadStatus.KO_OTHER,
  LeadStatus.KO_RENT,
  LeadStatus.KO_WRONG_PHONE_NUMBER,
  LeadStatus.KO_TEST,
  LeadStatus.KO_DUPLICATE,
  LeadStatus.PRIVATE,
];

export const LEAD_ACTIVE_STATUSES = [
  LeadStatus.IN_PROGRESS,
  LeadStatus.KO_ONLY_EVALUATION,
];
