import { LeadPropertyFeature } from '../domain/types';
import { leadFeatureLabels } from '../labels/leadFeatureLabels';

export const leadPropertyFeaturesOptions = [
  {
    value: LeadPropertyFeature.BALCONE_TERRAZZO,
    label: leadFeatureLabels.BALCONE_TERRAZZO,
  },
  {
    value: LeadPropertyFeature.BOX_AUTO,
    label: leadFeatureLabels.BOX_AUTO,
  },
  {
    value: LeadPropertyFeature.GIARDINO,
    label: leadFeatureLabels.GIARDINO,
  },
  {
    value: LeadPropertyFeature.ASCENSORE,
    label: leadFeatureLabels.ASCENSORE,
  },
];

export default leadPropertyFeaturesOptions;
