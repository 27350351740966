/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
import {
  Icon, ICON_ALERT_CIRCLE_OUTLINE, ICON_IMAGE_DRAG, ICON_IMAGE_OUTLINE, ICON_MAGNIFY, ICON_PLUS, ICON_VIDEO_OUTLINE, Image,
} from '@doveit/bricks';
import React from 'react';
import * as styles from './MultimediaContentThumbnail.style';
import { ContentType } from '../../domain/types';

type MultimediaContentOperation = 'view' | 'drag' | 'add';

export interface MultimediaContentThumbnailProps extends React.AriaAttributes {
  index?: number,
  type?: ContentType.FOTO | ContentType.VIDEO,
  operation?: MultimediaContentOperation,
  picture?: {
    src: string,
    alt: string,
  },
  active?: boolean,
  interactive?: boolean,
  isRemoving?: boolean,
  placeholderIcon?: string,
  hasError?: boolean,
  onClick?: VoidFunction,
}

const mapIconToOperation: { [key in MultimediaContentOperation]: string } = {
  view: ICON_MAGNIFY,
  drag: ICON_IMAGE_DRAG,
  add: ICON_PLUS,
};

const MultimediaContentThumbnail: React.FC<MultimediaContentThumbnailProps> = ({
  index,
  type = ContentType.FOTO,
  operation = 'view',
  picture,
  active,
  interactive = false,
  isRemoving = false,
  hasError,
  onClick,
  placeholderIcon,
  'aria-label': ariaLabel = 'multimedia-content-thumbnail',
  ...rest
}) => (
  <div
    {...rest}
    css={styles.base}
    $isRemoving={isRemoving}
    onClick={onClick}
    aria-label={ariaLabel}
    role="button"
  >
    <div css={styles.placeholder}>
      <Icon
        path={placeholderIcon || (type === ContentType.FOTO ? ICON_IMAGE_OUTLINE : ICON_VIDEO_OUTLINE)}
        size={32}
      />
    </div>
    <div
      css={styles.content}
      $hasPicture={!!picture}
    >
      {picture && (
        <Image
          aria-label={`${ariaLabel} (picture)`}
          src={picture.src}
          alt={picture.alt}
          lazyLoad
          responsive
        />
      )}
    </div>
    <div
      css={styles.frame}
      $operation={operation}
      $interactive={!!onClick || interactive}
      $hasPicture={!!picture}
      $active={active}
    >
      {hasError && (
        <div
          css={styles.errorIcon}
          aria-label="error icon"
        >
          <Icon
            path={ICON_ALERT_CIRCLE_OUTLINE}
            size={18}
          />
        </div>
      )}
      <div css={styles.icon} className="icon">
        <Icon
          path={mapIconToOperation[operation]}
          size={18}
        />
      </div>
      {(index !== undefined && index !== null) && (
        <span
          aria-label={`${ariaLabel} (index)`}
          css={styles.index}
          className="index"
        >
          {index}
        </span>
      )}
    </div>
  </div>
);

export default MultimediaContentThumbnail;
