import { useSWRConfig } from 'swr';

export type MatchMutate = ReturnType<typeof useMatchMutate>;

export function useMatchMutate() {
  const { cache, mutate } = useSWRConfig();

  return (identifier: string) => {
    if (!(cache instanceof Map)) {
      throw new Error('matchMutate requires the cache provider to be a Map instance');
    }

    const keys = [];
    const matcher = new RegExp(identifier);

    // eslint-disable-next-line no-restricted-syntax
    for (const key of cache.keys()) {
      if (matcher.test(key)) {
        keys.push(key);
      }
    }

    const mutations = keys.map((key) => mutate(key));
    return Promise.all(mutations);
  };
}
