import {
  getColorPalette, getSpacing, SpacingName, ThemedFunctor,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({
  theme,
  $status,
  $elevated,
  $hasActions,
}) => css`
  ${getSpacing.padding(theme, SpacingName.SMALLEST)}
  background-color: ${theme.palettes.neutrals.lightest};
  border: 1px solid ${$status ? getColorPalette(theme, $status) : theme.palettes.neutrals.light};
  border-radius: 10px;
  ${$elevated && 'box-shadow: 0 0 4px 0 rgba(0,0,0,0.15)'};
  height: 100%;
  position: relative;

  ${$hasActions && `
    padding-right: 3rem !important;
  `}
`;

export const actions: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, [SpacingName.SMALLEST, 0])}
  width: 3rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  border-left: 1px solid ${theme.palettes.neutrals.light};
  align-items: flex-start;
`;

export const actionsButton: ThemedFunctor = () => css`
  cursor: pointer;
  line-height: 0;
`;

export const actionsList: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLER)}
  ${getSpacing.padding(theme, [SpacingName.SMALLER, 0])}
  position: absolute;
  z-index: ${theme.zIndex.popover};
  right: 0;
  width: 13rem;
  background-color: ${theme.palettes.neutrals.darkest};
  border-radius: 5px;
  overflow: hidden;
`;

export const actionsItem: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.padding(theme, [SpacingName.SMALLER, SpacingName.SMALL])}
  color: ${theme.palettes.neutrals.light};
  cursor: pointer;

  &:hover {
    color: ${theme.palettes.neutrals.lightest};
  }
`;

export const closeArea: ThemedFunctor = ({
  theme,
}) => css`
  z-index: ${theme.zIndex.popover - 1};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const bottomAction: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.margin(theme, [SpacingName.SMALLEST, SpacingName.NEGATIVE_SMALLEST, SpacingName.NEGATIVE_SMALLEST])}
  ${getSpacing.padding(theme, SpacingName.SMALLER)}
  background-color: ${theme.palettes.neutrals.lighter};
`;
