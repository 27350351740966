import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingBlock from '../../components/loading-block/LoadingBlock';
import GenericErrorPage from '../../pages/errors/generic/GenericErrorPage';

export interface RequireAuthProps {
  renderConditions?: boolean,
  children: JSX.Element,
}

export const ForbiddenErrorPage: React.FC = () => <GenericErrorPage title="Non sei autorizzato a visualizzare la pagina" />;

const RequireAuth: React.FC<RequireAuthProps> = ({
  children,
  renderConditions = true,
}) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    const login = async () => {
      if (!isLoading && !isAuthenticated) {
        await loginWithRedirect({
          appState: {
            targetUrl: window.location.pathname + window.location.search + window.location.hash,
          },
        });
      }
    };

    // eslint-disable-next-line no-console
    login().catch((err) => console.log(err));
  }, [isAuthenticated, loginWithRedirect, isLoading]);

  const canRender = React.useMemo(() => isAuthenticated && renderConditions, [isAuthenticated, renderConditions]);

  if (isLoading) {
    return <LoadingBlock />;
  }

  return canRender
    ? children
    : <ForbiddenErrorPage />;
};

export default RequireAuth;
