import React, { FunctionComponent, MutableRefObject } from 'react';
import { Form, FormHandlers, FormSubmitType } from '@doveit/bricks';
import { AppointmentAvailability } from '../../../providers/api/dtos';
import { appointmentAvailabilityLabels } from '../../../labels/appointmentAvailabilityLabels';
import sortAvailabilities from '../../utils/sortAvailabilities';

export type AppointmentAvailabilityFormModel = {
  [key in AppointmentAvailability]: boolean
};

export interface AppointmentAvailabilityFormProps {
  initialValues?: AppointmentAvailabilityFormModel,
  innerRef?: MutableRefObject<FormHandlers>,
  onSubmit: FormSubmitType<AppointmentAvailabilityFormModel>,
  loading?: boolean,
  disabled?: boolean,
}

export const DEFAULT_INITIAL_VALUES = {
  [AppointmentAvailability.MORNING]: false,
  [AppointmentAvailability.LUNCH]: false,
  [AppointmentAvailability.AFTERNOON]: false,
  [AppointmentAvailability.EVENING]: false,
};

const AppointmentAvailabilityForm: FunctionComponent<AppointmentAvailabilityFormProps> = ({
  initialValues,
  innerRef,
  onSubmit,
  loading,
  disabled,
}) => (
  <Form
    initialValues={initialValues || DEFAULT_INITIAL_VALUES}
    innerRef={innerRef}
    onSubmit={onSubmit}
    loading={loading}
    disabled={disabled}
    enableReinitialize
    preventEnterKeypress
  >
    {sortAvailabilities(Object.keys(AppointmentAvailability) as AppointmentAvailability[]).map((availabilityType) => {
      const label = appointmentAvailabilityLabels[availabilityType];

      return (
        <Form.Item
          key={availabilityType}
          size={{ MD: 1 / 2 }}
        >
          <Form.CheckboxButton
            name={availabilityType}
            text={label}
            aria-label={`Campo per selezionare la disponibilità per: ${label}`}
          />
        </Form.Item>
      );
    })}
  </Form>
);

export default AppointmentAvailabilityForm;
