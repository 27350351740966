import { useNotifications } from '@doveit/bricks';
import { useCallback } from 'react';

export const DEFAULT_ERROR_MESSAGE = 'Il form contiene degli errori. Correggili per salvare.';
export const DEFAULT_ERROR_TTL = 4000;

export default function useErrorNotification(
  message: string = DEFAULT_ERROR_MESSAGE,
  ttl: number = DEFAULT_ERROR_TTL,
) {
  const { addError } = useNotifications();

  const result = useCallback(() => {
    addError(message, ttl);
  }, [addError, message, ttl]);

  return result;
}
