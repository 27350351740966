import {
  ThemedFunctor, getTypography, TypographyName, getSpacing, SpacingName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, SpacingName.MEDIUM)}
  background-color: ${theme.palettes.neutrals.lightest};
  border: 1px solid ${theme.palettes.neutrals.light};
  border-radius: 3px;
  max-width: 29rem;
`;

export const message: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.HEADING_4)}
  ${getSpacing.marginBottom(theme, SpacingName.SMALL)}
  font-weight: normal;
  text-align: center;
`;
