import {
  BreakpointQueryName, getSpacing, SpacingName, ThemedFunctor,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({ theme }) => css`
  position: relative;
  width: 100%;

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    display: flex;
  }
`;

export const fields: ThemedFunctor = ({ theme }) => css`
  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    width: 70%;
  }
`;

export const notes: ThemedFunctor = ({ theme }) => css`
  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    ${getSpacing.paddingLeft(theme, SpacingName.SMALL)}
    width: 30%;

    > * {
      position: sticky;
      top: 0;
    }
  }
`;

export const formItemErrorsHelp: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.paddingLeft(theme, SpacingName.SMALLEST)}
`;
