import {
  ThemedFunctor, SpacingName, getSpacing, getTypography, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const bubble: ThemedFunctor = ({ theme, $inbound }) => css`
  ${getTypography(theme, TypographyName.BODY)}
  ${getSpacing.padding(theme, [SpacingName.SMALL, SpacingName.SMALL, SpacingName.SMALLER])}
  background-color: ${$inbound ? theme.palettes.neutrals.lighter : theme.palettes.neutrals.light};
  border-radius: 8px;
  box-shadow: 0px 1px 0.5px 0px rgba(11, 20, 26, 0.13);
  word-break: break-word;
`;

export const date: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLEST)}
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  text-align: right;
  color: ${theme.palettes.neutrals.medium};
`;

export const author: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLEST)}
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  text-align: right;
`;
