import {
  CardSkeleton, Skeleton, UserPreviewSkeleton,
} from '@doveit/bricks';
import React from 'react';
import { useTheme } from 'styled-components';
import RightColumnPageLayout from '../../layouts/right-column-page-layout/RightColumnPageLayout';
import SimpleTableSkeleton from '../simple-table/SimpleTable.skeleton';

const RightColumnPageSkeleton: React.FC = () => {
  const theme = useTheme();

  return (
    <RightColumnPageLayout
      aria-label="page skeleton"
      title={(
        <div style={{ width: '12rem' }}>
          <UserPreviewSkeleton size="M" />
        </div>
      )}
      actions={(
        <Skeleton
          width={theme.remHeight.M}
          height={theme.remHeight.M}
        />
      )}
      primarySide={(
        <CardSkeleton>
          <Skeleton count={5} />
        </CardSkeleton>
      )}
      secondarySide={(
        <CardSkeleton />
      )}
    >
      <CardSkeleton>
        <SimpleTableSkeleton />
      </CardSkeleton>
      <CardSkeleton />
      <CardSkeleton>
        <SimpleTableSkeleton />
      </CardSkeleton>
    </RightColumnPageLayout>
  );
};

export default RightColumnPageSkeleton;
