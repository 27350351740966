import { snakeCase } from 'snake-case';
import { ReferenceType } from '../../domain/types';
import { referenceTypeMapping } from './constants';

export type CreateSearchCriteriaQueryParams = {
  contactId?: string,
  propertyId?: number,
};

export function computeDestinationUrl(referenceId: string | number, referenceType: ReferenceType): string {
  return `/${referenceTypeMapping[referenceType]}/${referenceId}`;
}

export function goToDetailPage(referenceId: string | number, referenceType: ReferenceType, forceOpenInTheSameTab = false): void {
  if (forceOpenInTheSameTab) {
    window.location.href = computeDestinationUrl(referenceId, referenceType);
  } else {
    window.open(computeDestinationUrl(referenceId, referenceType), '_blank');
  }
}

export function goToCreatePage(referenceType: ReferenceType.SEARCH_CRITERIA, queryParams: CreateSearchCriteriaQueryParams): void;

export function goToCreatePage(referenceType: ReferenceType, queryParams: Record<string, any>): void {
  const normalizedParams = Object.entries(queryParams)
    .map(([key, value]) => `${snakeCase(key)}=${value}`)
    .join('&');

  window.open(`/${referenceTypeMapping[referenceType]}/create?${normalizedParams}`, '_blank');
}

export function goToSearchPage(referenceType: ReferenceType): void {
  window.open(`/${referenceTypeMapping[referenceType]}/search`, '_blank');
}
