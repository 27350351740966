import {
  ThemedFunctor, BreakpointQueryName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const slotMobile: ThemedFunctor = ({ theme }) => css`
  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    display: none;
  }
`;

export const slotDesktop: ThemedFunctor = ({ theme }) => css`
  display: none;

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    display: block;
  }
`;
