import {
  Action, ICON_CHECK, ICON_CLOSE, useNotifications,
} from '@doveit/bricks';
import { isFuture } from 'date-fns';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { AppointmentStatus } from '../../../domain/types';
import { appointmentStatusLabels } from '../../../labels';
import { updateAppointment } from '../../../providers/api/appointment/appointmentProvider';
import { Appointment } from '../../../providers/api/dtos/appointment';

export interface ChildFnProps {
  loading: boolean,
  disabled?: boolean,
  onClick: () => Promise<void>,
}

export interface SetAppointmentOutcomeProps {
  appointment: Appointment,
  outcome: AppointmentStatus.DONE | AppointmentStatus.CANCELLED,
  disableAction?: boolean,
  onSuccess?: (appointment: Appointment) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const UPDATE_APPOINTMENT_SUCCESS_MESSAGE = 'Appuntamento salvato con successo';
export const UPDATE_APPOINTMENT_ERROR_MESSAGE = 'Non è stato possibile salvare l\'appuntamento';

const SetAppointmentOutcome: FunctionComponent<SetAppointmentOutcomeProps> = ({
  appointment,
  outcome,
  disableAction,
  onSuccess,
  children,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const onClick = useCallback(async () => {
    setIsSaving(true);

    try {
      const updatedAppointment = await updateAppointment(appointment.id!, {
        ...appointment,
        status: outcome,
      });

      setIsSaving(false);
      addSuccess(UPDATE_APPOINTMENT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedAppointment);
      }
    } catch (error) {
      setIsSaving(false);

      addError(UPDATE_APPOINTMENT_ERROR_MESSAGE);
    }
  }, [appointment, outcome, addSuccess, addError, onSuccess]);

  if (outcome === appointment.status) {
    return null;
  }

  return (
    children
      ? children({ onClick, loading: isSaving, disabled: disableAction })
      : (
        <Action
          size="S"
          color={outcome === AppointmentStatus.DONE
            ? 'success'
            : 'critical'}
          iconLeft={{
            path: outcome === AppointmentStatus.DONE
              ? ICON_CHECK
              : ICON_CLOSE,
          }}
          label={appointmentStatusLabels[outcome]}
          loading={isSaving}
          disabled={disableAction || (outcome === AppointmentStatus.DONE && isFuture(new Date(appointment.startDate)))}
          onClick={onClick}
        />
      )
  );
};

export default SetAppointmentOutcome;
