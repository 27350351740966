import React, {
  FunctionComponent, useState, useCallback, useMemo,
} from 'react';
import { FormSkeleton, Message, useNotifications } from '@doveit/bricks';
import { Prospect } from '../../../providers/api/dtos/prospect';
import EditProspectForm, { EditProspectFormModel, EditProspectFormPros } from '../../components/edit-prospect-form/EditProspectForm';
import { updateProspect } from '../../../providers/api/prospect/prospectProvider';
import { ProspectApprovalStatus, ProspectStatus } from '../../../domain/types';
import useProspect from '../../hooks/use-prospect/useProspect';
import { prospectStatusLabels } from '../../../labels';
import { useAgent } from '../../../hooks/use-agent/useAgent';

export interface EditProspectProps {
  prospectId: string,
  extraActions?: EditProspectFormPros['extraActions'],
  onSuccess?: (prospect: Prospect) => void,
  onError?: (error: Error) => void,
}

export const PROSPECT_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare la valutazione';
export const AGENT_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare l\'agente associato alla valutazione';
export const PROSPECT_EDIT_ERROR_MESSAGE = 'Non è stato possibile aggiornare la valutazione';
export const PROSPECT_EDIT_SUCCESS_MESSAGE = 'La valutazione è stata aggiornata con successo';

const EditProspect: FunctionComponent<EditProspectProps> = ({
  prospectId,
  onSuccess,
  onError,
  extraActions,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { data: prospect, error: prospectError, mutate } = useProspect(prospectId);
  const { data: agent, error: agentError } = useAgent(prospect?.agentId);
  const { addSuccess, addError } = useNotifications();

  const isAgentValuationApproved = React.useMemo(
    () => prospect?.approval?.status === ProspectApprovalStatus.APPROVED,
    [prospect?.approval?.status],
  );

  const initialValues: EditProspectFormModel = useMemo(() => ({
    agentEvaluation: prospect?.agentEvaluation?.toString(),
    ownerEvaluation: prospect?.ownerEvaluation?.toString(),
    willingness: prospect?.willingness.toString(),
    sellability: prospect?.sellability.toString(),
    agent,
  }), [agent, prospect]);

  const saveProspect = useCallback(
    async (values: EditProspectFormModel) => {
      setIsSaving(true);

      try {
        const payload: Prospect = {
          ...prospect!,
          willingness: parseInt(values.willingness!, 10),
          sellability: parseInt(values.sellability!, 10),
          agentId: values.agent?.id!,
          agentEvaluation: values.agentEvaluation ? parseInt(values.agentEvaluation, 10) : undefined,
          ownerEvaluation: values.ownerEvaluation ? parseInt(values.ownerEvaluation, 10) : undefined,
        };

        const updatedProspect = await updateProspect(prospectId, payload);
        mutate(updatedProspect);

        addSuccess(PROSPECT_EDIT_SUCCESS_MESSAGE);

        if (onSuccess) {
          onSuccess(updatedProspect);
        }
      } catch (err) {
        addError(PROSPECT_EDIT_ERROR_MESSAGE);

        if (onError) {
          onError(err as Error);
        }
      }

      setIsSaving(false);
    },
    [onError, onSuccess, prospect, prospectId, mutate, addError, addSuccess],
  );

  const onSubmit = useCallback(async (values: EditProspectFormModel) => {
    await saveProspect(values);
  }, [saveProspect]);

  if (prospectError) {
    return (
      <Message
        type="critical"
        message={PROSPECT_LOAD_ERROR_MESSAGE}
      />
    );
  }

  if (agentError) {
    return (
      <Message
        type="critical"
        message={AGENT_LOAD_ERROR_MESSAGE}
      />
    );
  }

  if (!prospect || !agent) {
    return (<FormSkeleton />);
  }

  return (
    <EditProspectForm
      confirmConfig={{
        visible: showConfirm,
        title: 'Conferma creazione mandato',
        onConfirm: saveProspect,
        onAbort: () => setShowConfirm(false),
        description: (
          <>
            <p>
              Sei sicuro di voler modificare lo stato della valutazione a&nbsp;
              <strong>{prospectStatusLabels[ProspectStatus.ASSIGNMENT_CREATED]}</strong>?
            </p>
            <p>L&apos;operazione è irreversibile e causerà la creazione automatica di un mandato.</p>
          </>
        ),
        confirmLabel: 'Procedi',
        abortLabel: 'Annulla',
      }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      loading={isSaving}
      extraActions={extraActions}
      disableAgentEvaluation={isAgentValuationApproved}
    />
  );
};

export default EditProspect;
