import { API_SEARCH_CRITERIA_PATH, getClient } from '../api';
import { SearchCriteria } from '../dtos';

export async function getSearchCriteria(id: number): Promise<SearchCriteria> {
  const client = await getClient();
  const { data } = await client.get<SearchCriteria>(`${API_SEARCH_CRITERIA_PATH}/${id}`);
  return data;
}

export async function updateSearchCriteria(id: number, searchCriteria: SearchCriteria): Promise<SearchCriteria> {
  const client = await getClient();
  const { data } = await client.put<SearchCriteria>(`${API_SEARCH_CRITERIA_PATH}/${id}`, searchCriteria);
  return data;
}

export async function createSearchCriteria(searchCriteria: SearchCriteria): Promise<SearchCriteria> {
  const client = await getClient();
  const { data } = await client.post<SearchCriteria>(API_SEARCH_CRITERIA_PATH, searchCriteria);
  return data;
}
