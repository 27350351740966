import {
  Card, CardSkeleton, ICON_ALERT_CIRCLE_OUTLINE, ICON_LIST_BOX_OUTLINE, Message,
} from '@doveit/bricks';
import React from 'react';
import objectHash from 'object-hash';
import { Contact } from '../../../providers/api/dtos';
import useCalls from '../../../call/hooks/use-calls/useCalls';
import { callsToEventRegistryItems } from '../../mappers/toEventRegistry';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewEventRegistryTableRow from './ViewEventRegistryTableRow';

export const CALL_EVENT_ERROR_MESSAGE = 'Non è stato possibile recuperare i dati delle attività.';
export const EMPTY_CALL_EVENT_MESSAGE = 'Non sono presenti attività.';
export const CALLS_PAGINATION = { size: 10 };

export interface EventRegistryWidgetProps {
  phoneNumber: Contact['phoneNumber'],
}

const EventRegistryWidget: React.FC<EventRegistryWidgetProps> = ({ phoneNumber }) => {
  const { data: calls, isLoading: isCallsLoading, error: callsError } = useCalls(
    { phoneNumber },
    CALLS_PAGINATION,
  );

  const eventRegistryItems = React.useMemo(() => {
    if (!calls) {
      return [];
    }

    const phoneCalls = callsToEventRegistryItems(calls.content);

    return [
      ...phoneCalls,
    ].sort((a, b) => (a.date < b.date ? 1 : -1));
  }, [calls]);

  if (isCallsLoading) {
    return (<CardSkeleton />);
  }

  if (callsError) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Attività"
          color="critical"
        />
        <Card.Content>
          <Message
            message={CALL_EVENT_ERROR_MESSAGE}
          />
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card aria-label="Registro attività">
      <Card.Header
        title="Attività"
        icon={{ path: ICON_LIST_BOX_OUTLINE }}
      />
      <Card.Content>
        {eventRegistryItems.length === 0 && (
          <Message
            type="neutral"
            message={EMPTY_CALL_EVENT_MESSAGE}
          />
        )}

        {eventRegistryItems.length > 0 && (
          <SimpleTable aria-label="tabella delle attività">
            <SimpleTable.Body>
              {eventRegistryItems.map((eventRegistryItem, i) => (
                <SimpleTable.Row
                  key={objectHash({ eventRegistryItem, i })}
                  asChild
                >
                  <ViewEventRegistryTableRow
                    event={eventRegistryItem}
                  />
                </SimpleTable.Row>
              ))}
            </SimpleTable.Body>
          </SimpleTable>
        )}
      </Card.Content>
    </Card>
  );
};

export default EventRegistryWidget;
