import {
  AssignmentPropertyStatus, AssignmentSubscriptionPlace, AssignmentWizardData, PropertySource,
} from '../../../../../providers/api/dtos';
import { boolToBooleanString, booleanStringToBool } from '../../../../../utils/boolean-string/booleanString';
import { PropertyInfoFormModel } from '../../../../components/property-info-form/PropertyInfoForm';

export const toPropertyInfoWizardStep = (
  initialData: AssignmentWizardData,
): Partial<PropertyInfoFormModel> => {
  const { property, assignment } = initialData;
  return {
    propertyStatus: property?.status?.type || '',
    annualRentFee: property?.rent?.annualFee ? `${property.rent.annualFee}` : '',
    annualRentExpiresAt: property?.rent?.expiresAt ?? '',
    isUrbanCompliant: typeof property?.compliance?.isUrbanCompliant !== 'undefined'
      ? boolToBooleanString(property.compliance.isUrbanCompliant)
      : '',
    urbanCompliantNotes: property?.compliance?.urbanCompliantNotes ?? '',
    areSystemsCompliant: typeof property?.compliance?.areSystemsCompliant !== 'undefined'
      ? boolToBooleanString(property.compliance.areSystemsCompliant)
      : '',
    systemsCompliantNotes: property?.compliance?.systemsCompliantNotes ?? '',
    hasOccupancyCertificate: typeof property?.compliance?.hasOccupancyCertificate !== 'undefined'
      ? boolToBooleanString(property.compliance.hasOccupancyCertificate)
      : '',
    occupancyCertificateNotes: property?.compliance?.occupancyCertificateNotes ?? '',
    isConstraintsFree: typeof property?.constraints?.isConstraintsFree !== 'undefined'
      ? boolToBooleanString(property.constraints.isConstraintsFree)
      : '',
    constraintsDetails: property?.constraints?.details || '',
    technicianNeeded: typeof property?.compliance?.technicianNeeded !== 'undefined'
      ? boolToBooleanString(property.compliance.technicianNeeded)
      : '',
    technician: typeof property?.compliance?.technicianByDoveIt !== 'undefined' ? `${property?.compliance?.technicianByDoveIt ? 'dove.it' : 'seller'}` : '',
    annualCondominiumExpenses: (property?.additionalExpenses?.value !== undefined && property.additionalExpenses.value >= 0) ? `${property?.additionalExpenses?.value}` : '',
    extraExpensesDetails: property?.additionalExpenses?.extraDetails ?? '',
    extraExpensesDeliberated: typeof property?.additionalExpenses?.extraDeliberated !== 'undefined'
      ? boolToBooleanString(property.additionalExpenses.extraDeliberated)
      : '',
    legalIssues: typeof property?.constraints?.legalIssues !== 'undefined'
      ? boolToBooleanString(property.constraints.legalIssues)
      : '',
    propertySource: property?.source?.type || '',
    subscriptionPlace: assignment?.subscriptionPlace || '',
    propertySourceInfo: property?.source?.details || '',
  };
};

export const toAssignmentWizardData = (
  existingData: AssignmentWizardData,
  values: Partial<PropertyInfoFormModel>,
): AssignmentWizardData => {
  const {
    propertyStatus,
    annualRentFee,
    annualRentExpiresAt,
    isUrbanCompliant,
    urbanCompliantNotes,
    areSystemsCompliant,
    systemsCompliantNotes,
    hasOccupancyCertificate,
    occupancyCertificateNotes,
    isConstraintsFree,
    constraintsDetails,
    technicianNeeded,
    technician,
    annualCondominiumExpenses,
    extraExpensesDeliberated,
    extraExpensesDetails,
    legalIssues,
    propertySource,
    subscriptionPlace,
    propertySourceInfo,
  } = values;

  const newAssignmentValues: Partial<AssignmentWizardData['assignment']> = {
    subscriptionPlace: subscriptionPlace ? subscriptionPlace as AssignmentSubscriptionPlace : undefined,
  };

  let technicianByDoveIt: boolean | undefined;

  if (technicianNeeded === 'true') {
    if (technician === 'dove.it') {
      technicianByDoveIt = true;
    } else if (technician === 'seller') {
      technicianByDoveIt = false;
    }
  }

  const newPropertyValues: Partial<AssignmentWizardData['property']> = {
    source: {
      type: propertySource ? propertySource as PropertySource : undefined,
      details: propertySourceInfo || undefined,
    },
    status: {
      type: propertyStatus ? propertyStatus as AssignmentPropertyStatus : undefined,
    },
    rent: {
      annualFee: annualRentFee ? parseFloat(annualRentFee) : undefined,
      expiresAt: annualRentExpiresAt ? new Date(annualRentExpiresAt).toISOString() : undefined,
    },
    compliance: {
      isUrbanCompliant: booleanStringToBool(isUrbanCompliant),
      urbanCompliantNotes: urbanCompliantNotes?.trim() || undefined,
      areSystemsCompliant: booleanStringToBool(areSystemsCompliant),
      systemsCompliantNotes: systemsCompliantNotes?.trim() || undefined,
      hasOccupancyCertificate: booleanStringToBool(hasOccupancyCertificate),
      occupancyCertificateNotes: occupancyCertificateNotes?.trim() || undefined,
      technicianNeeded: booleanStringToBool(technicianNeeded),
      technicianByDoveIt,
    },
    constraints: {
      legalIssues: booleanStringToBool(legalIssues),
      isConstraintsFree: booleanStringToBool(isConstraintsFree),
      details: constraintsDetails || undefined,
    },
    additionalExpenses: {
      extraDeliberated: booleanStringToBool(extraExpensesDeliberated),
      extraDetails: extraExpensesDetails?.trim() || undefined,
      value: annualCondominiumExpenses ? parseInt(annualCondominiumExpenses, 10) : undefined,
    },
  };

  const newValues: AssignmentWizardData = {
    ...existingData,
    isDigitallySigned: false,
    assignment: {
      ...existingData.assignment,
      ...newAssignmentValues,
    },
    property: {
      ...existingData.property,
      ...newPropertyValues,
    },
  };

  return newValues;
};
