import { Text, View } from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import * as styles from './Features.style';

interface FeaturesProps {
  values: string[];
}

export const Features: FunctionComponent<FeaturesProps> = (props) => {
  const { values } = props;

  const theme = useTheme();

  return (
    <View style={styles.list as any}>
      {(values.map((value) => (
        <Text
          style={styles.item(theme)}
          key={value}
        >
          {value}
        </Text>
      )))}
    </View>
  );
};
