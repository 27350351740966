import { ICON_FILE_DOCUMENT_OUTLINE, StepNavigationItemType } from '@doveit/bricks';
import * as Yup from 'yup';
import OfferWizardSellers from '../../components/offer-wizard/offer-wizard-sellers/OfferWizardSellers';
import OfferWizardBuyers from '../../components/offer-wizard/offer-wizard-buyers/OfferWizardBuyers';
import { OfferWizardStepProps } from '../../components/offer-wizard/types';
import OfferWizardDigitalRequest from '../../components/offer-wizard/offer-wizard-digital-request/OfferWizardDigitalRequest';
import {
  OfferWizardAntiMoneyLaunderingSchema,
  OfferWizardBuyersValidationSchema, OfferWizardCommissionsValidationSchema, OfferWizardMortgageValidationSchema, OfferWizardNotesSchema, OfferWizardPaymentsSchema, OfferWizardPropertyDetailsValidationSchema, OfferWizardPropertyGeoValidationSchema, OfferWizardSellersValidationSchema,
} from './schemas';
import OfferWizardPropertyDetails from '../../components/offer-wizard/offer-wizard-property-details/OfferWizardPropertyDetails';
import OfferWizardPropertyGeo from '../../components/offer-wizard/offer-wizard-property-geo/OfferWizardPropertyGeo';
import OfferWizardPayments from '../../components/offer-wizard/offer-wizard-payments/OfferWizardPayments';
import OfferWizardMortgage from '../../components/offer-wizard/offer-wizard-mortgage/OfferWizardMortgage';
import OfferWizardCommissions from '../../components/offer-wizard/offer-wizard-commissions/OfferWizardCommissions';
import OfferWizardNotes from '../../components/offer-wizard/offer-wizard-notes/OfferWizardNotes';
import OfferWizardManualRequest from '../../components/offer-wizard/offer-wizard-manual-request/OfferWizardManualRequest';
import OfferWizardAntiMoneyLaundering from '../../components/offer-wizard/offer-wizard-anti-money-laundering/OfferWizardAntiMoneyLaundering';

export const SAVE_OFFER_WIZARD_DATA_ERROR_MESSAGE = 'Errore durante il salvataggio dei dati della proposta';
export const LOAD_OFFER_WIZARD_DATA_ERROR_MESSAGE = 'Errore durante il caricamento dei dati della proposta';
export const SIGNATURE_ALREADY_REQUESTED_ERROR_MESSAGE = 'La firma per la proposta è già stata richiesta';
export const OFFER_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE = 'È necessario risolvere i seguenti errori prima di poter generare la proposta digitale.';
export const OFFER_MANUAL_WIZARD_VALIDATION_ERROR_MESSAGE = 'È necessario risolvere i seguenti errori prima di poter generare la proposta cartacea.';
export const AT_LEAST_ONE_OFFER_WIZARD_VALIDATION_ERROR_MESSAGE = 'Non è stato possibile generare la proposta. Alcuni step risultano incompleti.';
export const OFFER_CREATION_FAILING_PRECONDITIONS_ERROR_MESSAGE = "Non è possibile creare una proposta per l'interesse";

export const OFFER_MANUAL_CREATE_WIZARD_NAVIGATION: StepNavigationItemType[] = [
  {
    title: 'Prezzo e pagamenti',
  },
  {
    title: 'Mutuo',
  },
  {
    title: 'Commissioni',
  },
  {
    title: 'Scadenze e note',
  },
  {
    title: 'Creazione proposta',
    icon: ICON_FILE_DOCUMENT_OUTLINE,
  },
];

export const OFFER_DIGITAL_CREATE_WIZARD_NAVIGATION: StepNavigationItemType[] = [
  {
    title: 'Dati acquirente',
  },
  {
    title: 'Dati venditore',
  },
  {
    title: 'Dati immobile',
  },
  {
    title: 'Info immobile',
  },
  {
    title: 'Prezzo e pagamenti',
  },
  {
    title: 'Mutuo',
  },
  {
    title: 'Commissioni',
  },
  {
    title: 'Scadenze e note',
  },
  {
    title: 'Antiriciclaggio',
  },
  {
    title: 'Creazione proposta',
    icon: ICON_FILE_DOCUMENT_OUTLINE,
  },
];

export const OFFER_MANUAL_CREATE_WIZARD_STEPS: Record<number, React.FC<OfferWizardStepProps>> = {
  0: OfferWizardPayments,
  1: OfferWizardMortgage,
  2: OfferWizardCommissions,
  3: OfferWizardNotes,
  4: OfferWizardManualRequest,
};

export const OFFER_MANUAL_WIZARD_VALIDATION_SCHEMAS: Record<number, Yup.AnySchema> = {
  0: OfferWizardPaymentsSchema,
  1: OfferWizardMortgageValidationSchema,
  2: OfferWizardCommissionsValidationSchema,
  3: OfferWizardNotesSchema,
};

export const OFFER_DIGITAL_CREATE_WIZARD_STEPS: Record<number, React.FC<OfferWizardStepProps>> = {
  0: OfferWizardBuyers,
  1: OfferWizardSellers,
  2: OfferWizardPropertyGeo,
  3: OfferWizardPropertyDetails,
  4: OfferWizardPayments,
  5: OfferWizardMortgage,
  6: OfferWizardCommissions,
  7: OfferWizardNotes,
  8: OfferWizardAntiMoneyLaundering,
  9: OfferWizardDigitalRequest,
};

export const OFFER_DIGITAL_WIZARD_VALIDATION_SCHEMAS: Record<number, Yup.AnySchema> = {
  0: OfferWizardBuyersValidationSchema,
  1: OfferWizardSellersValidationSchema,
  2: OfferWizardPropertyGeoValidationSchema,
  3: OfferWizardPropertyDetailsValidationSchema,
  4: OfferWizardPaymentsSchema,
  5: OfferWizardMortgageValidationSchema,
  6: OfferWizardCommissionsValidationSchema,
  7: OfferWizardNotesSchema,
  8: OfferWizardAntiMoneyLaunderingSchema,
};
