import { AxiosError } from 'axios';
import useSWR, { SWRResponse } from 'swr';
import { Offer } from '../../../providers/api/dtos/offer';
import { API_OFFERS_PATH } from '../../../providers/api/api';
import { getOffer } from '../../../providers/api/offer/offerProvider';

export const OFFER_RID = `${API_OFFERS_PATH}/:id`;

export function useOffer(offerId?: number): SWRResponse<Offer, AxiosError> {
  return useSWR(offerId ? OFFER_RID : undefined, () => getOffer(offerId!));
}
