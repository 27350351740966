import {
  ExtraPackageDoveIt, ExtraPackageProvider, ExtraPackageSPF, ExtraPackageType,
} from '../domain/types/extraPackageType';

export const extraPackageLabels: {
  [key in ExtraPackageType]: string
} = {
  PHOTO_PLUS: 'Foto plus',
  EXTRA_VISIBILITY: 'Visibilità extra',
  INTERIOR_RESTYLING: 'Restyling interno',
  PRESTIGE: 'Prestige',
  DRONE: 'Drone',
  VERIFICATION_SERVICE: 'Servizio di verifica',
  APE: 'APE',
  DOCUMENTS_ACCESS: 'Accesso agli atti',
  PERSONALIZED_MARKETING: 'Marketing personalizzato',
  PHOTO_PRO: 'Foto pro',
};

export const extraPackageDoveItLabels: {
  [key in ExtraPackageDoveIt]: string
} = {
  PHOTO_PLUS: 'Foto plus',
  EXTRA_VISIBILITY: 'Visibilità extra',
  INTERIOR_RESTYLING: 'Restyling interno',
  PRESTIGE: 'Prestige',
  DRONE: 'Drone',
  PERSONALIZED_MARKETING: 'Marketing personalizzato',
  PHOTO_PRO: 'Foto pro',
};

export const extraPackageSPFLabels: {
  [key in ExtraPackageSPF]: string
} = {
  VERIFICATION_SERVICE: 'Servizio di verifica',
  DOCUMENTS_ACCESS: 'Accesso agli atti',
  APE: 'APE',
};

export const extraPackageProviderLabels: Record<ExtraPackageProvider, string> = {
  DOVE_IT: 'Dove.it',
  SPF: 'SPF',
};
