import React from 'react';
import {
  Action, ConfirmModal, Form, FormHandlers, Portal, Spacing, useModal,
  useNotifications,
} from '@doveit/bricks';
import { Offer } from '../../../providers/api/dtos';
import { OfferKoStatus, OfferStatus } from '../../../domain/types';
import { isOfferArchived } from '../../utils';
import { useComputedOfferStatus } from '../../hooks/use-computed-offer-status/useComputedOfferStatus';
import { updateOffer } from '../../../providers/api/offer/offerProvider';
import {
  ARCHIVE_OFFER_ERROR_MESSAGE, KO_STATUS_TO_ARCHIVE_OFFER_ACTION_LABELS, REFUSE_OFFER_FORM_DEFAULT_VALUES, refuseOfferFormValidationSchema,
} from './constants';

interface ChildFnProps {
  updateStatus: VoidFunction,
  isLoading: boolean,
}

export interface ArchiveOfferActionProps {
  offer: Offer,
  toStatus: OfferKoStatus,
  onSuccess?: (archivedOffer: Offer) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const ArchiveOfferAction: React.FC<ArchiveOfferActionProps> = (props) => {
  const {
    offer, toStatus, onSuccess, children,
  } = props;

  const labels = KO_STATUS_TO_ARCHIVE_OFFER_ACTION_LABELS[toStatus];

  const confirmModal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);
  const refuseOfferFormRef = React.useRef() as React.MutableRefObject<FormHandlers>;
  const { data: computedOfferStatus } = useComputedOfferStatus(offer.intentId, offer);

  const openConfirmModal = React.useCallback(() => {
    confirmModal.open();
  }, [confirmModal]);

  const submitRefuseOfferForm = React.useCallback(() => {
    refuseOfferFormRef.current.handleSubmit();
  }, []);

  const updateOfferStatus = React.useCallback(async (counterOfferFormValues?: { counterOffer: string }) => {
    try {
      setIsSaving(true);

      const offerToArchive: Offer = {
        ...offer,
        status: toStatus,
        counterOffer: counterOfferFormValues
          ? parseFloat(counterOfferFormValues.counterOffer)
          : offer.counterOffer,
      };

      const archivedOffer = await updateOffer(offerToArchive);

      setIsSaving(false);
      addSuccess(labels.successNotification);
      confirmModal.close();

      onSuccess?.(archivedOffer);
    } catch (_) {
      setIsSaving(false);
      addError(ARCHIVE_OFFER_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, confirmModal, labels.successNotification, offer, onSuccess, toStatus]);

  if (isOfferArchived(offer) || !computedOfferStatus?.canBeUpdatedTo.includes(toStatus)) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          updateStatus: openConfirmModal,
          isLoading: isSaving,
        })
        : (
          <Action
            label={labels.actionLabel}
            aria-label={labels.actionLabel}
            size="S"
            onClick={openConfirmModal}
          />
        )}
      <Portal>
        <ConfirmModal
          variant="critical"
          isOpen={confirmModal.isOpen}
          saving={isSaving}
          aria-label={labels.modalTitle}
          title={labels.modalTitle}
          onConfirm={toStatus === OfferStatus.REFUSED ? submitRefuseOfferForm : () => updateOfferStatus()}
          onAbort={() => confirmModal.close()}
        >
          {labels.modalBody}
          {toStatus === OfferStatus.REFUSED && (
            <Spacing margin={[400, 0, 0]}>
              <Form
                innerRef={refuseOfferFormRef}
                initialValues={REFUSE_OFFER_FORM_DEFAULT_VALUES}
                validationSchema={refuseOfferFormValidationSchema(offer.value)}
                onSubmit={updateOfferStatus}
              >
                <Form.Item>
                  <Form.DecimalPriceField
                    name="counterOffer"
                    label="Controproposta"
                    aria-label="Campo per inserire la controproposta"
                  />
                </Form.Item>
              </Form>
            </Spacing>
          )}
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default ArchiveOfferAction;
