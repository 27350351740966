import { API_BALCONIES_PATH, API_PROPERTIES_PATH, getClient } from '../api';
import { Balcony } from '../dtos';

export async function createBalcony(balcony: Balcony): Promise<Balcony> {
  const client = await getClient();
  const { data } = await client.post<Balcony>(API_BALCONIES_PATH, balcony);
  return data;
}

export async function getBalcony(balconyId: string | number): Promise<Balcony> {
  const client = await getClient();
  const { data } = await client.get<Balcony>(`${API_BALCONIES_PATH}/${balconyId}`);
  return data;
}

export async function updateBalcony(balcony: Balcony): Promise<Balcony> {
  const client = await getClient();
  const { data } = await client.put<Balcony>(`${API_BALCONIES_PATH}/${balcony.id}`, balcony);
  return data;
}

export async function deleteBalcony(balconyId: string | number): Promise<{ id: number | string }> {
  const client = await getClient();
  const { data } = await client.delete(`${API_BALCONIES_PATH}/${balconyId}`);
  return data;
}

export async function getBalconiesByPropertyId(propertyId: number): Promise<Balcony[]> {
  const client = await getClient();
  const { data } = await client.get(`${API_PROPERTIES_PATH}/${propertyId}/balconies`);
  return data;
}
