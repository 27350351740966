/* eslint-disable react/no-unknown-property */
import { Form, Message } from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useState } from 'react';
import AreasTable from '../../../components/areas-table/AreasTable';
import { getAgentAreasByAgentId } from '../../../providers/api/agent/agentProvider';
import { AgentArea } from '../../../providers/api/dtos/agentArea';
import * as styles from './AgentCoveredAreasContainer.style';
import { Agent } from '../../../providers/api/dtos';
import { getAgentName } from '../../utils/utils';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';
import { AgentStatus } from '../../../domain/types';

export const ERROR_MESSAGE = 'Si è verificato un errore durante il recupero dati.';
export const AGENT_WITH_NO_AREAS_MESSAGE = 'L’agente selezionato non è operativo su nessuna area';

const AgentCoveredAreasContainer: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [agent, setAgent] = useState<Agent | null>();
  const [data, setData] = useState<AgentArea[]>();

  const onAgentSelected = useCallback(async (selected: Agent | null) => {
    setLoading(true);
    setError(undefined);
    setAgent(selected);
    if (selected) {
      try {
        const result = await getAgentAreasByAgentId(selected.id!);
        setData(result);
      } catch {
        setError(ERROR_MESSAGE);
      }

      setLoading(false);
    }
  }, []);

  return (
    <>
      <Form
        onSubmit={(_) => null}
        initialValues={{}}
      >
        <Form.Item>
          <AgentAutoComplete
            name="agent"
            aria-label="Campo per cercare un agente"
            placeholder="Cerca per nome"
            onSuggestionSelected={onAgentSelected}
            status={AgentStatus.ACTIVE}
          />
        </Form.Item>
      </Form>

      {error && (
        <div css={styles.message}>
          <Message type="critical" message={error} />
        </div>
      )}
      {
        (data && !data.length) && (
          <div css={styles.message}>
            <Message type="info" message={AGENT_WITH_NO_AREAS_MESSAGE} />
          </div>
        )
      }
      {
        (!loading && !error && data && data.length > 0) && (
          <div css={styles.table}>
            {
              agent && (
                <h4 css={styles.tableTitle}>
                  Risultati per:
                  {' '}
                  <strong>{getAgentName(agent)}</strong>
                </h4>
              )
            }
            <AreasTable data={data} />
          </div>
        )
      }
    </>
  );
};

export default AgentCoveredAreasContainer;
