import { DocumentReferenceType } from '../../../domain/types/documentReferenceType';
import useIntent from '../../../intent/hooks/use-intent/useIntent';
import { useOffer } from '../../../offer/hooks/use-offer/useOffer';
import useProperty from '../../../property/hooks/use-property/useProperty';
import useProspect from '../../../prospect/hooks/use-prospect/useProspect';
import { useIsAreaManagerOfAgent } from '../use-is-area-manager-of-agent/useIsAreaManagerOfAgent';

export function useIsAreaManagerOfDocumentOwnerAgent(
  referenceId: string,
  referenceType: DocumentReferenceType,
) {
  const { data: prospect } = useProspect(referenceType === DocumentReferenceType.PROSPECT ? referenceId : undefined);

  const { data: offer } = useOffer(referenceType === DocumentReferenceType.OFFER && Number.isFinite(+referenceId)
    ? Number.parseInt(referenceId, 10)
    : undefined);

  const { data: intent } = useIntent(offer?.intentId);

  const propertyId = referenceType === DocumentReferenceType.PROPERTY && Number.isFinite(+referenceId)
    ? Number.parseInt(referenceId, 10)
    : intent?.propertyId;

  const { data: property } = useProperty(propertyId || undefined);

  return useIsAreaManagerOfAgent(prospect?.agentId || property?.agentEmail);
}
