import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import {
  Action, ConfirmModal, ICON_ACCOUNT_MULTIPLE_PLUS_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { JobApplicationStatus } from '../../../domain/types/jobApplicationStatus';
import { JobApplication } from '../../../providers/api/dtos';
import { updateJobApplication } from '../../../providers/api/job-application/jobApplicationProvider';
import { isArchived } from '../../../utils/job-application/getJobApplicationProgress';

interface ChildFnProps {
  restore: VoidFunction,
  loading: boolean,
}

export interface RestoreJobApplicationProps {
  jobApplication: JobApplication,
  onSuccess?: (jobApplication: JobApplication) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const RESTORE_JOB_APPLICATION_WARNING_MESSAGE = 'Confermi di voler ripristinare la candidatura?';
export const RESTORE_JOB_APPLICATION_SUCCESS_MESSAGE = 'Candidatura ripristinata con successo';
export const RESTORE_JOB_APPLICATION_ERROR_MESSAGE = 'Non è stato possibile ripristinare la candidatura';

const RestoreJobApplication: FunctionComponent<RestoreJobApplicationProps> = ({
  jobApplication,
  onSuccess,
  children,
}) => {
  const restoreModal = useModal();
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const isArchivedJobApplication = useMemo(
    () => (jobApplication?.status ? isArchived(jobApplication?.status) : undefined),
    [jobApplication?.status],
  );

  const onConfirm = useCallback(async () => {
    setIsSaving(true);

    try {
      const updatedJobApplication = await updateJobApplication(
        jobApplication.id!,
        {
          ...jobApplication,
          status: jobApplication.assignment ? JobApplicationStatus.ASSIGNED : JobApplicationStatus.IN_PROGRESS,
          archivingReason: undefined,
        },
      );

      setIsSaving(false);
      restoreModal.close();
      addSuccess(RESTORE_JOB_APPLICATION_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedJobApplication);
      }
    } catch (error) {
      setIsSaving(false);
      addError(RESTORE_JOB_APPLICATION_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, restoreModal, jobApplication, onSuccess]);

  if (
    !(isArchivedJobApplication)
  ) {
    return null;
  }

  return (
    <>
      {children
        ? children({ restore: restoreModal.open, loading: isSaving })
        : (
          <Action
            label="Ripristina"
            onClick={restoreModal.open}
            iconLeft={{ path: ICON_ACCOUNT_MULTIPLE_PLUS_OUTLINE }}
            color="success"
            emphasis="high"
          />
        )}
      <Portal>
        <ConfirmModal
          isOpen={restoreModal.isOpen}
          title="Ripristina candidatura"
          onConfirm={onConfirm}
          onAbort={restoreModal.close}
          loading={isSaving}
        >
          {RESTORE_JOB_APPLICATION_WARNING_MESSAGE}
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default RestoreJobApplication;
