/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as styles from './CardInline.style';
import CardBox from '../box/CardBox';

export type CardInlineProps = React.PropsWithChildren & React.AriaAttributes;

const CardInline: React.FC<CardInlineProps> = ({
  children,
  ...rest
}) => (
  <div
    css={styles.base}
    {...rest}
  >
    {React.Children.map(children as React.ReactElement[], (child: React.ReactElement) => (
      child?.type === CardBox
        ? React.cloneElement(child, { inline: true })
        : child))}
  </div>
);

export default CardInline;
