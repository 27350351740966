import React from 'react';
import { Badge, BadgeProps, DetailItemList } from '@doveit/bricks';
import { formatDate } from '@doveit/hammer';
import { DetailItemProps } from '@doveit/bricks/dist/typings/components/detail-item-list/item/DetailItem';
import Card, { CardProps } from '../../../components/card/Card';

const contactActivityTypeLabels: Record<ContactActivityCardProps['type'], string> = {
  intent: 'Interesse',
  lead: 'Lead',
  property: 'Immobile',
  prospect: 'Valutazione',
  'search-criteria': 'Ricerca',
};

export interface ContactActivityCardProps extends Pick<CardProps, 'actions'> {
  type: 'lead' | 'property' | 'prospect' | 'intent' | 'search-criteria',
  status: string,
  statusColor: BadgeProps['color'],
  createdAt?: string,
  updatedAt?: string,
  features?: Pick<DetailItemProps, 'icon' | 'label' | 'title'>[],
}

const ContactActivityCard: React.FC<ContactActivityCardProps> = ({
  type,
  status,
  statusColor,
  createdAt,
  updatedAt,
  features = [],
  actions = [],
}) => (
  <Card
    actions={actions}
    aria-label={`Dettaglio ${contactActivityTypeLabels[type]} del contatto`}
    compact
  >
    <Card.Box>
      <DetailItemList>
        <DetailItemList.Item>
          <Badge
            label={contactActivityTypeLabels[type]}
            size="XS"
          />
        </DetailItemList.Item>
        {createdAt && (
          <DetailItemList.Item>
            Inserito il {formatDate(new Date(createdAt))}
          </DetailItemList.Item>
        )}
        {updatedAt && (
          <DetailItemList.Item>
            Aggiornato il {formatDate(new Date(updatedAt))}
          </DetailItemList.Item>
        )}
      </DetailItemList>
    </Card.Box>
    <Card.Box>
      <DetailItemList>
        <DetailItemList.Item>
          <Badge
            label={status}
            color={statusColor}
            size="XS"
          />
        </DetailItemList.Item>
        {features.length > 0 && features.map((feature) => (
          <DetailItemList.Item
            key={feature.label}
            title={feature.title}
            icon={feature.icon}
          >
            {feature.label}
          </DetailItemList.Item>
        ))}
      </DetailItemList>
    </Card.Box>
  </Card>
);

export default ContactActivityCard;
