/* eslint-disable react/no-unknown-property */
import {
  ICON_CHECK, ICON_CLOSE, ICON_MINUS, Icon,
} from '@doveit/bricks';
import React from 'react';
import * as styles from './FlagLabel.style';

export interface FlagLabelProps extends React.AriaAttributes {
  checked?: boolean,
  children?: React.ReactText,
}

const FlagLabel: React.FC<FlagLabelProps> = ({
  checked,
  children,
  ...rest
}) => {
  const icon = React.useMemo(() => {
    if (checked === undefined) {
      return ICON_MINUS;
    }

    return checked ? ICON_CHECK : ICON_CLOSE;
  }, [checked]);

  return (
    <span
      {...rest}
      css={styles.base}
      $checked={checked}
    >
      <span
        css={styles.icon}
        $checked={checked}
      >
        <Icon
          size={18}
          path={icon}
        />
      </span>
      {children}
    </span>
  );
};

export default FlagLabel;
