/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  ActionIcon, Badge, DetailItemList, ICON_ACCOUNT_OUTLINE, ICON_CASH_MULTIPLE, ICON_CLOCK_OUTLINE, ICON_EYE_OUTLINE, ICON_HOME_OUTLINE, ICON_RULER, Modal, Portal, Stack, useModal,
} from '@doveit/bricks';
import {
  formatDate, formatEuro, formatSquareMeters, pluralizeWord,
} from '@doveit/hammer';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/card/Card';
import {
  Intent, MatchingIntents, PropertyPreview,
} from '../../../providers/api/dtos';
import PropertyStatusBadge from '../../../property/components/property-status-badge/PropertyStatusBadge';
import { propertyTypeLabels } from '../../../labels';
import ShowAgentName from '../../../agent/containers/show-agent-name/ShowAgentName';
import { EntityType } from '../../../domain/types/entityType';
import useIntents from '../../../intent/hooks/use-intents/useIntents';
import ViewMatchingIntentCard, { HiddenAwareIntent } from '../view-matching-intent-card/ViewMatchingIntentCard';
import * as styles from './ViewMatchingIntentsCard.style';
import { joinById } from '../../../utils/array';
import { WithRequiredKeys } from '../../../types';
import { ContactMatchBlacklistActionProps } from '../../../contact-match/containers/contact-match-blacklist-action/ContactMatchBlacklistAction';

export interface ViewMatchingIntentsCardProps {
  property: PropertyPreview,
  matchingIntents: MatchingIntents,
  disableAddIntentAction?: boolean,
  disableContactMatchBlacklistAction?: boolean,
  onIntentCreated?: (intent: Intent) => void,
  onContactMatchBlacklistAction?: ContactMatchBlacklistActionProps['onSuccess'],
}

function renderBasicInfo(matchingIntents: MatchingIntents): React.ReactElement {
  return (
    <DetailItemList>
      <DetailItemList.Item icon={ICON_HOME_OUTLINE}>
        <PropertyStatusBadge status={matchingIntents.propertyStatus} />
        <Badge
          label={matchingIntents.propertyReferenceId}
          color="primary"
          size="XS"
        />
        {matchingIntents.propertyAddress}
      </DetailItemList.Item>
      <DetailItemList.Item icon={ICON_RULER}>
        <strong>{propertyTypeLabels[matchingIntents.propertyType]}</strong>
        {matchingIntents.propertySize && (
          formatSquareMeters(matchingIntents.propertySize)
        )}
      </DetailItemList.Item>
      {matchingIntents.propertyPrice && (
        <DetailItemList.Item
          icon={ICON_CASH_MULTIPLE}
          title="Prezzo dell'immobile"
        >
          {formatEuro(matchingIntents.propertyPrice)}
        </DetailItemList.Item>
      )}
    </DetailItemList>
  );
}

const ViewMatchingIntentsCard: React.FC<ViewMatchingIntentsCardProps> = ({
  property,
  matchingIntents,
  disableAddIntentAction = false,
  disableContactMatchBlacklistAction = false,
  onIntentCreated,
  onContactMatchBlacklistAction,
}) => {
  const navigate = useNavigate();
  const intentsModal = useModal();
  const {
    data: intents,
    isLoading: areIntentsLoading,
  } = useIntents(intentsModal.isOpen ? { ids: matchingIntents.intents.map((intent) => intent.id) } : undefined, { size: matchingIntents.intents.length });

  const goToPropertyDetailPage = React.useCallback(() => {
    navigate(`/properties/${matchingIntents.propertyId}`);
  }, [matchingIntents.propertyId, navigate]);

  const bottomAction = React.useMemo(() => ({
    label: `Vedi ${matchingIntents.intents.length} ${pluralizeWord('interess', 'e', 'i', matchingIntents.intents.length)} di questo immobile`,
    onClick: () => intentsModal.open(),
  }), [matchingIntents, intentsModal]);

  const hiddenAwareIntents = React.useMemo(() => {
    if (!intents) return [];

    const intentsWithIds = intents.content as WithRequiredKeys<Intent, 'id'>[];

    return joinById<string, HiddenAwareIntent>(intentsWithIds, matchingIntents.intents);
  }, [intents, matchingIntents.intents]);

  return (
    <>
      <Card bottomAction={bottomAction}>
        <Card.Inline>
          <Card.Box>
            {renderBasicInfo(matchingIntents)}
          </Card.Box>
          <Card.ColumnDivider />
          <Card.Box>
            <DetailItemList>
              <DetailItemList.Item
                icon={ICON_ACCOUNT_OUTLINE}
                title="Agente incaricato"
              >
                <ShowAgentName
                  entityId={matchingIntents.propertyId}
                  entityType={EntityType.PROPERTY}
                />
              </DetailItemList.Item>
              <DetailItemList.Item
                icon={ICON_CLOCK_OUTLINE}
                title="Data di creazione immobile"
              >
                {formatDate(new Date(matchingIntents.propertyCreatedAt!))}
              </DetailItemList.Item>
            </DetailItemList>
          </Card.Box>
          <Card.ColumnDivider />
          <Card.Box fit>
            <ActionIcon
              dataRef="view-property-action"
              aria-label="Visualizza dettagli immobile"
              label="Visualizza immobile"
              icon={{ path: ICON_EYE_OUTLINE }}
              onClick={goToPropertyDetailPage}
            />
          </Card.Box>
        </Card.Inline>
      </Card>
      <Portal>
        <Modal
          open={intentsModal.isOpen}
          onCloseHandler={intentsModal.close}
          loading={areIntentsLoading}
          aria-label="Modale interessi simili"
        >
          <Modal.Close />
          <Modal.Header>
            {renderBasicInfo(matchingIntents)}
          </Modal.Header>
          <Modal.Body>
            <div css={styles.body}>
              <Stack>
                {hiddenAwareIntents.map((hiddenAwareIntent) => (
                  <ViewMatchingIntentCard
                    key={hiddenAwareIntent.id}
                    property={property}
                    hiddenAwareIntent={hiddenAwareIntent}
                    disableAddIntentAction={disableAddIntentAction}
                    disableContactMatchBlacklistAction={disableContactMatchBlacklistAction}
                    onAppointmentCreated={onIntentCreated}
                    onAppointmentAborted={onIntentCreated}
                    onContactMatchBlacklistAction={onContactMatchBlacklistAction}
                  />
                ))}
              </Stack>
            </div>
          </Modal.Body>
        </Modal>
      </Portal>
    </>
  );
};

export default ViewMatchingIntentsCard;
