import { ProspectApprovalStatus, ProspectStatus } from '../../../domain/types';
import { FiltersMapper } from '../../../hooks/use-filters/useFilters';
import { SortOrder } from '../../../providers/pagination';
import { GenericSerpSortOption } from '../../../types';
import {
  ProspectSerpPageFilterKeys, ProspectSerpPageFilterOption, ProspectSerpPageFilters, ProspectSerpPageSortKeys,
} from './types';

export const SERP_PROSPECTS_ERROR_MESSAGE = 'Non è stato possibile recuperare le valutazioni.';
export const EMPTY_SERP_PROSPECTS = 'Non sono presenti valutazioni.';

type ProspectSerpSortOption = GenericSerpSortOption<ProspectSerpPageSortKeys>;

export const DEFAULT_FILTER_OPTION: ProspectSerpPageFilterOption = {
  key: 'toBeManaged',
  label: {
    short: 'Da gestire',
    long: 'Da gestire',
  },
  value: {
    toBeManaged: true,
  },
};

export const ALL_PROSPECTS_FILTER_OPTION: ProspectSerpPageFilterOption = {
  key: 'all',
  label: {
    short: 'Tutte',
    long: 'Tutte le valutazioni',
  },
  value: {},
};

export const STATUS_FILTERS: ProspectSerpPageFilterOption[] = [
  {
    key: 'active',
    label: {
      short: 'Attive',
      long: 'Attive',
    },
    value: {
      status: ProspectStatus.IN_PROGRESS,
    },
  },
  {
    key: 'inactive',
    label: {
      short: 'Non attive',
      long: 'Non attive',
    },
    value: {
      status: [
        ProspectStatus.ASSIGNMENT_CREATED,
        ProspectStatus.ONLY_EVALUATION,
        ProspectStatus.OVER_PRICED,
        ProspectStatus.CANCELLED,
        ProspectStatus.UNSELLABLE,
        ProspectStatus.NOT_INTERESTED,
        ProspectStatus.SOLD,
      ],
    },
  },
];

export const APPOINTMENTS_FILTERS: ProspectSerpPageFilterOption[] = [
  {
    key: 'futureAppointments',
    label: {
      short: 'Futuri',
      long: 'Con appuntamento futuro',
    },
    value: {
      status: ProspectStatus.IN_PROGRESS,
      latestAppointmentGt: new Date(),
    },
  },
  {
    key: 'pastAppointments',
    label: {
      short: 'Passati',
      long: 'Con appuntamento passato',
    },
    value: {
      status: ProspectStatus.IN_PROGRESS,
      latestAppointmentLt: new Date(),
    },
  },
];

export const APPROVAL_FILTERS: ProspectSerpPageFilterOption[] = [
  {
    key: 'toBeApproved',
    label: {
      short: 'Da approvare',
      long: 'Da approvare',
    },
    value: {
      status: ProspectStatus.IN_PROGRESS,
      approvalStatus: [
        ProspectApprovalStatus.WAITING_FOR_AGENT,
        ProspectApprovalStatus.WAITING_FOR_SUPERVISOR,
      ],
    },
  },
  {
    key: 'approved',
    label: {
      short: 'Approvate',
      long: 'Approvate',
    },
    value: {
      status: ProspectStatus.IN_PROGRESS,
      approvalStatus: ProspectApprovalStatus.APPROVED,
    },
  },
];

export const FILTER_OPTIONS: ProspectSerpPageFilterOption[] = [
  DEFAULT_FILTER_OPTION,
  ...STATUS_FILTERS,
  ...APPOINTMENTS_FILTERS,
  ...APPROVAL_FILTERS,
  ALL_PROSPECTS_FILTER_OPTION,
];

export const GROUPED_FILTER_OPTIONS = [
  {
    label: 'Stato',
    options: STATUS_FILTERS,
  },
  {
    label: 'Appuntamenti',
    options: APPOINTMENTS_FILTERS,
  },
  {
    label: 'Approvazione',
    options: APPROVAL_FILTERS,
  },
];

export const DEFAULT_SORT_OPTION: ProspectSerpSortOption = {
  key: 'default',
  label: 'In evidenza',
} as const;

export const MOST_RECENT_CREATION_SORT_OPTION: ProspectSerpSortOption = {
  key: 'mostRecentProspectCreation',
  label: 'Creazione più recente',
  value: {
    createdAt: SortOrder.DESC,
  },
} as const;

export const LEAST_RECENT_CREATION_SORT_OPTION: ProspectSerpSortOption = {
  key: 'leastRecentProspectCreation',
  label: 'Creazione meno recente',
  value: {
    createdAt: SortOrder.ASC,
  },
} as const;

export const MOST_RECENT_UPDATE_SORT_OPTION: ProspectSerpSortOption = {
  key: 'mostRecentProspectUpdate',
  label: 'Aggiornamento più recente',
  value: {
    updatedAt: SortOrder.DESC,
  },
} as const;

export const LEAST_RECENT_UPDATE_SORT_OPTION: ProspectSerpSortOption = {
  key: 'leastRecentProspectUpdate',
  label: 'Aggiornamento meno recente',
  value: {
    updatedAt: SortOrder.ASC,
  },
} as const;

export const SORT_OPTIONS: ProspectSerpSortOption[] = [
  DEFAULT_SORT_OPTION,
  MOST_RECENT_CREATION_SORT_OPTION,
  LEAST_RECENT_CREATION_SORT_OPTION,
  MOST_RECENT_UPDATE_SORT_OPTION,
  LEAST_RECENT_UPDATE_SORT_OPTION,
] as const;

export const SORT_OPTIONS_PER_FILTER: Record<ProspectSerpPageFilterKeys, ProspectSerpSortOption> = {
  toBeManaged: {
    ...MOST_RECENT_UPDATE_SORT_OPTION,
    key: 'default',
  },
  active: {
    ...MOST_RECENT_CREATION_SORT_OPTION,
    key: 'default',
  },
  inactive: {
    ...MOST_RECENT_UPDATE_SORT_OPTION,
    key: 'default',
  },
  pastAppointments: {
    label: 'Appuntamento più recente',
    value: {
      'appointment.date': SortOrder.DESC,
    },
    key: 'default',
  },
  futureAppointments: {
    label: 'Appuntamento più prossimo',
    value: {
      'appointment.date': SortOrder.ASC,
    },
    key: 'default',
  },
  toBeApproved: {
    label: 'Da approvare',
    value: {
      updatedAt: SortOrder.DESC,
    },
    key: 'default',
  },
  approved: {
    label: 'Approvate',
    value: {
      updatedAt: SortOrder.DESC,
    },
    key: 'default',
  },
  all: {
    ...MOST_RECENT_CREATION_SORT_OPTION,
    key: 'default',
  },
};

export const FILTERS_MAPPER: FiltersMapper<ProspectSerpPageFilters> = {
  q: (v) => v,
  filter: (v) => FILTER_OPTIONS.find(({
    key,
  }) => key === v)?.key || DEFAULT_FILTER_OPTION.key,
  sort: (v) => SORT_OPTIONS.find(({
    key,
  }) => key === v)?.key || DEFAULT_SORT_OPTION.key,
};
