/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as styles from './SimpleTableHeaderCell.style';

export interface SimpleTableHeaderCellProps extends React.PropsWithChildren, React.AriaAttributes {
}

export const SimpleTableHeaderCell: React.FC<SimpleTableHeaderCellProps> = ({
  children,
  ...rest
}) => (
  <th
    aria-label="simple-table-header-cell"
    css={styles.th}
    {...rest}
  >
    <div>
      {children}
    </div>
  </th>
);
