import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  useNotifications, ICON_CHECK, ICON_CLOSE, Action, ActionProps,
} from '@doveit/bricks';
import { AppointmentStatus } from '../../../domain/types';
import { appointmentStatusLabels } from '../../../labels';
import { updateAppointment } from '../../../providers/api/appointment/appointmentProvider';
import { Appointment } from '../../../providers/api/dtos';

export interface EditAppointmentStatusButtonProps {
  appointment: Appointment,
  to: AppointmentStatus,
  disabled?: ActionProps['disabled'],
  expanded?: ActionProps['expanded'],
  onSaving?: (appointment: Appointment, to: AppointmentStatus) => void,
  onSuccess?: (appointment: Appointment, to: AppointmentStatus) => void,
  onError?: (error: Error, message: string) => void,
}

const buttonColorByStatus: { [key in AppointmentStatus]?: ActionProps['color'] } = {
  DONE: 'success',
  CANCELLED: 'critical',
};

const buttonIconByStatus: { [key in AppointmentStatus]?: string } = {
  DONE: ICON_CHECK,
  CANCELLED: ICON_CLOSE,
};

export const successMessage = (status: AppointmentStatus) => `L'esito dell'appuntamento è stato segnato come "${appointmentStatusLabels[status]}"`;
export const ERROR_MESSAGE = 'Non è stato possibile salvare l\'esito dell\'appuntamento. Riprova.';

const EditAppointmentStatusButton: FunctionComponent<EditAppointmentStatusButtonProps> = ({
  appointment,
  to,
  disabled,
  onSaving,
  onSuccess,
  onError,
}) => {
  const [isLoading, setLoading] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const onClick = useCallback(async () => {
    setLoading(true);

    if (onSaving) {
      onSaving(appointment, to);
    }

    try {
      const modified = await updateAppointment(appointment.id!, {
        ...appointment,
        status: to,
      });
      addSuccess(successMessage(to));

      if (onSuccess) {
        onSuccess(modified, to);
      }
    } catch (err) {
      addError(ERROR_MESSAGE);

      if (onError) {
        onError(err as Error, ERROR_MESSAGE);
      }
    }

    setLoading(false);
  }, [appointment, onError, onSaving, onSuccess, to, addSuccess, addError]);

  return (
    <Action
      label={appointmentStatusLabels[to]}
      aria-label="Azione per esitare l'appuntamento"
      color={buttonColorByStatus[to]}
      size="S"
      iconLeft={{ path: buttonIconByStatus[to]! }}
      loading={isLoading}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export default EditAppointmentStatusButton;
