export enum OfferStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  ACCEPTED = 'ACCEPTED',
  CONFIRMED = 'CONFIRMED',
  REFUSED = 'REFUSED',
  WITHDRAWN = 'WITHDRAWN',
  MORTGAGE_REFUSED = 'MORTGAGE_REFUSED',
  OTHER_CONSTRAINTS = 'OTHER_CONSTRAINTS',
}

export enum DigitalOfferStatus {
  WAITING_FOR_SIGNATURE = 'WAITING_FOR_SIGNATURE',
  WAITING_FOR_ACCEPTANCE = 'WAITING_FOR_ACCEPTANCE',
}

export type ComputedOfferStatus = OfferStatus | DigitalOfferStatus | 'DEED_DONE';

export const OFFER_KO_STATUSES = [
  OfferStatus.REFUSED,
  OfferStatus.WITHDRAWN,
  OfferStatus.MORTGAGE_REFUSED,
  OfferStatus.OTHER_CONSTRAINTS,
] as const;

export type OfferKoStatus = typeof OFFER_KO_STATUSES[number];
