import { Place, AddressSuggestion } from '../dtos';

type PlaceMap = {
  place_id: string,
  locality: string,
  route?: string,
  street_number?: string,
  administrative_area_level_3: string,
  administrative_area_level_2: string,
  postal_code: string,
};

function mapPlaceResult(placeResult: google.maps.places.PlaceResult): Place {
  const gMapObject = placeResult.address_components!.reduce<PlaceMap>((result, item) => {
    result[item.types[0] as keyof PlaceMap] = item.short_name;
    return result;
  }, {} as any as PlaceMap);

  return {
    placeId: placeResult.place_id,
    district: gMapObject.locality,
    locality: gMapObject.administrative_area_level_3 || gMapObject.locality,
    route: gMapObject.route,
    streetNumber: gMapObject.street_number,
    plateCode: gMapObject.administrative_area_level_2,
    postalCode: gMapObject.postal_code,
    latitude: placeResult.geometry?.location?.lat(),
    longitude: placeResult.geometry?.location?.lng(),
  };
}

export type GetAddressSuggestionsOptions = Omit<google.maps.places.AutocompletionRequest, 'input' | 'componentRestrictions' | 'types'>;

export function getAddressSuggestions(query: string, options: GetAddressSuggestionsOptions = {}): Promise<AddressSuggestion[]> {
  const { google } = window; // destructure after script has been loaded!!!
  const autocomplete = new google.maps.places.AutocompleteService();
  const { OK, ZERO_RESULTS } = google.maps.places.PlacesServiceStatus;

  return new Promise((resolve, reject) => {
    autocomplete.getPlacePredictions({
      ...options,
      input: query,
      componentRestrictions: { country: 'IT' },
      types: ['geocode'],
    }, (results, status) => {
      if (status !== OK && status !== ZERO_RESULTS) {
        const err = `google.maps places autocomplete failed with status: ${status}`;
        reject(new Error(err));
      }

      resolve(results?.map((result) => (
        {
          placeId: result.place_id,
          description: result.description,
        }
      )) ?? []);
    });
  });
}

export function getAutoCompleteSessionToken() {
  const { google } = window;
  return new google.maps.places.AutocompleteSessionToken();
}

export function getPlaceDetails(placeId: string, options: Partial<google.maps.places.PlaceDetailsRequest> = {}): Promise<Place> {
  if (!placeId) {
    return Promise.reject(new Error('placeId is mandatory'));
  }

  const { google, document } = window;

  const places = new google.maps.places.PlacesService(document.createElement('div'));
  const { OK } = google.maps.places.PlacesServiceStatus;

  return new Promise((resolve, reject) => {
    places.getDetails({
      ...options,
      placeId,
      fields: ['place_id', 'address_components', 'geometry'],
    }, (result, status) => {
      if (status !== OK) {
        const err = `google.maps get place data failed with status: ${status}`;
        // eslint-disable-next-line no-console
        console.error(err);

        return reject(new Error(err));
      }

      if (!result) {
        return reject(new Error('no result from google.maps get place data'));
      }

      return resolve(mapPlaceResult(result));
    });
  });
}
