import {
  Message, Pagination, PaginationProps, Spacing,
  Stack,
} from '@doveit/bricks';
import { pluralizeWord } from '@doveit/hammer';
import React from 'react';

export type PaginatedListProps = React.PropsWithChildren<{
  query?: string,
  emptyResultMessage?: string,
  hideResultCount?: boolean,
  hideNavigation?: boolean,
  number: number,
  totalPages: number,
  size: number,
  totalElements: number,
  goToPage: PaginationProps['navigateTo'],
}> & React.AriaAttributes;

export const NO_RESULTS_MESSAGE = 'Nessun risultato trovato';

const PaginatedList: React.FC<PaginatedListProps> = ({
  query,
  emptyResultMessage,
  hideResultCount,
  hideNavigation,
  number,
  totalPages,
  size,
  totalElements,
  goToPage,
  children,
  ...rest
}) => {
  if (totalElements <= 0) {
    const message = emptyResultMessage || `${NO_RESULTS_MESSAGE}${query ? ` per: ${query}` : ''}`;

    return (<Message message={message} />);
  }

  return (
    <div {...rest}>
      {!hideResultCount && (
        <Spacing data-ref="paginated-list-header" margin={[0, 0, 200]}>
          <strong>{totalElements}</strong>
          {pluralizeWord(' risultat', 'o', 'i', totalElements)}
          {query && (
            <>
              {' per: '}
              <strong>{query}</strong>
            </>
          )}
        </Spacing>
      )}
      <Stack wrap="nowrap">
        {children}
      </Stack>
      {!hideNavigation && totalPages > 1 && (
        <Spacing margin={[200, 0, 0]}>
          <Pagination
            current={number + 1}
            pageSize={size}
            totalItems={totalElements}
            navigateTo={goToPage}
          />
        </Spacing>
      )}
    </div>
  );
};

export default PaginatedList;
