import {
  ActionIcon, ICON_FOLDER_TABLE_OUTLINE, Portal, useModal,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import EventRegistryList from '../../../call-center/components/event-registry-list/EventRegistryList';
import { Lead } from '../../../providers/api/dtos';
import useContact from '../../../contact/hooks/use-contact/useContact';
import { WithRequiredKeys } from '../../../types';
import useCalls from '../../../call/hooks/use-calls/useCalls';
import { SortOrder } from '../../../providers/pagination';
import { callsToEventRegistryItems } from '../../../call-center/mappers/toEventRegistry';
import EventRegistryListSkeleton from '../../../call-center/components/event-registry-list/EventRegistryList.skeleton';
import ScrollableArea from '../../../components/scrollable-area/ScrollableArea';
import { CALLS_PAGINATION } from '../../../call-center/containers/event-registry-widget/EventRegistryWidget';

export const LOAD_LEAD_EVENT_REGISTRY_ERROR_MESSAGE = 'Non è stato possibile caricare il registro attività';

interface ChildFnProps {
  view: VoidFunction,
  disabled: boolean,
}

export interface ViewLeadEventRegistryActionProps {
  lead: Lead,
  disabled?: boolean,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const ViewLeadEventRegistryAction: React.FC<ViewLeadEventRegistryActionProps> = ({
  lead,
  disabled = false,
  children,
}) => {
  const { contactId } = lead as WithRequiredKeys<Lead, 'id'>;

  const viewEventsModal = useModal();

  const { data: contact, isLoading: isContactLoading } = useContact(viewEventsModal.isOpen ? contactId : undefined);
  const { data: calls, isLoading: areCallsLoading } = useCalls(
    (viewEventsModal.isOpen && contact) ? { phoneNumber: contact.phoneNumber } : undefined,
    {
      ...CALLS_PAGINATION,
      sort: {
        startedAt: SortOrder.DESC,
      },
    },
  );

  const eventRegistryItems = React.useMemo(() => (calls ? callsToEventRegistryItems(calls.content) : []), [calls]);

  const openViewEventsModal = React.useCallback(() => {
    viewEventsModal.open();
  }, [viewEventsModal]);

  return (
    <>
      {children?.({ disabled, view: openViewEventsModal }) ?? (
        <ActionIcon
          label="Attività"
          aria-label="Visualizza registro attività"
          disabled={disabled}
          icon={{ path: ICON_FOLDER_TABLE_OUTLINE }}
          onClick={openViewEventsModal}
        />
      )}
      <Portal>
        <SimpleModal
          {...viewEventsModal}
          aria-label="Registro attività"
          title="Registro attività"
        >
          {(isContactLoading || areCallsLoading) && <EventRegistryListSkeleton />}
          {eventRegistryItems.length > 0
            ? (
              <ScrollableArea height="20rem">
                <EventRegistryList events={eventRegistryItems} />
              </ScrollableArea>
            )
            : <div>Nessuna attività</div>}
        </SimpleModal>
      </Portal>
    </>

  );
};

export default ViewLeadEventRegistryAction;
