import { OfferStatus } from '../../../domain/types';
import {
  API_INTENTS_PATH,
  API_OFFERS_PATH,
  API_PROPERTIES_PATH,
  getClient,
} from '../api';
import {
  Document, Intent, Offer, OfferWizardData, Signature,
} from '../dtos';
import { Notary } from '../dtos/notary';
import { serializeParams } from '../utils/serializer';

export async function getOffer(offerId: number): Promise<Offer> {
  const client = await getClient();
  const { data } = await client.get<Offer>(`${API_OFFERS_PATH}/${offerId}`);

  return data;
}

export async function getOffersByIntentId(intentId: string, status?: OfferStatus | OfferStatus[]): Promise<Offer[]> {
  const client = await getClient();
  const { data } = await client.get<Offer[]>(`${API_INTENTS_PATH}/${intentId}/offers`, status ? {
    params: serializeParams({ status }),
  } : undefined);

  return data;
}

export async function getOffersByPropertyId(propertyId: number, offerStatus?: OfferStatus | OfferStatus[]): Promise<Offer[]> {
  const client = await getClient();
  const { data } = await client.get<Offer[]>(`${API_PROPERTIES_PATH}/${propertyId}/offers`, offerStatus ? {
    params: serializeParams({ offerStatus }),
  } : undefined);

  return data;
}

export async function createOffer(offer: Offer): Promise<Offer> {
  const client = await getClient();
  const { data } = await client.post<Offer>(API_OFFERS_PATH, offer);
  return data;
}

export async function updateOffer(offer: Offer): Promise<Offer> {
  const client = await getClient();
  const { data } = await client.put<Offer>(`${API_OFFERS_PATH}/${offer.id}`, offer);
  return data;
}

export async function getOfferDocuments(offerId: number): Promise<Document[]> {
  const client = await getClient();
  const { data } = await client.get<Document[]>(`${API_OFFERS_PATH}/${offerId}/documents`);

  return data;
}

export async function getOfferNotary(offerId: number): Promise<Notary> {
  const client = await getClient();
  const { data } = await client.get<Notary>(`${API_OFFERS_PATH}/${offerId}/notaries`);

  return data;
}

export async function getOfferWizardData(intentId: NonNullable<Intent['id']>): Promise<OfferWizardData> {
  const client = await getClient();

  const { data } = await client.get<OfferWizardData>(`${API_OFFERS_PATH}/wizard/${intentId}`);

  return data;
}

export async function createOfferFromManualWizard(data: OfferWizardData): Promise<Offer> {
  const client = await getClient();

  const res = await client.post<Offer>(`${API_OFFERS_PATH}/wizard/manual`, data);

  return res.data;
}

export async function saveOfferWizardData(
  data: OfferWizardData,
): Promise<OfferWizardData> {
  const client = await getClient();

  const res = await client.put<OfferWizardData>(`${API_OFFERS_PATH}/wizard`, data);

  return res.data;
}

export async function getOfferWizardPreviewPDF(intentId: string) {
  const client = await getClient();
  const { data } = await client.get<ArrayBuffer>(`${API_OFFERS_PATH}/wizard/${intentId}/preview`, {
    responseType: 'arraybuffer',
  });

  return data;
}

export async function getOfferSignatureStatus(intentId: NonNullable<Intent['id']>): Promise<Signature> {
  const client = await getClient();

  const { data } = await client.get<Signature>(`${API_OFFERS_PATH}/wizard/${intentId}/signature-status`);

  return data;
}

export async function cancelOfferSignatureRequest(intentId: NonNullable<Intent['id']>): Promise<void> {
  const client = await getClient();

  await client.post<void>(`${API_OFFERS_PATH}/wizard/${intentId}/cancel`);
}
