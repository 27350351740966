import {
  Action,
  ModalActions, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import React from 'react';
import NoteForm, { NoteFormModel } from '../../../notes/components/note-form/NoteForm';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Property } from '../../../providers/api/dtos';
import { updateProperty } from '../../../providers/api/property/propertyProvider';

export interface UpsertPropertyNotesActionCustomViewProps {
  openUpsertPropertyNotesModal?: ModalActions<unknown>['open'],
}

export interface UpsertPropertyNotesActionProps {
  property: Property,
  children?: (customViewProps: UpsertPropertyNotesActionCustomViewProps) => React.ReactNode,
  onSuccess?: (updatedProperty: Property) => void,
}

export const UPSERT_PROPERTY_NOTES_SUCCESS_MESSAGE = 'Appunti modificati con successo';
export const UPSERT_PROPERTY_NOTES_ERROR_MESSAGE = 'Non è stato possibile modificare gli appunti';

const UpsertPropertyNotesAction: React.FC<UpsertPropertyNotesActionProps> = ({
  property,
  children,
  onSuccess,
}) => {
  const upsertPropertyNotesModal = useModal();
  const [isSaving, setIsSaving] = React.useState(false);
  const { userIsAgent, userIsAdmin } = useRBAC();
  const { addSuccess, addError } = useNotifications();

  const noteFormInitialValues: Partial<NoteFormModel> = React.useMemo(() => ({
    text: property.notes || '',
  }), [property.notes]);

  const handleUpsertPropertyNotes = React.useCallback(async (values: Partial<NoteFormModel>) => {
    setIsSaving(true);

    try {
      const { text } = values;

      const propertyWithUpdatedNotes: Property = {
        ...property,
        notes: text?.trim() || undefined,
      };

      const updatedProperty = await updateProperty(property.id!, propertyWithUpdatedNotes);

      setIsSaving(false);
      upsertPropertyNotesModal.close();
      addSuccess(UPSERT_PROPERTY_NOTES_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedProperty);
      }
    } catch (_) {
      setIsSaving(false);
      addError(UPSERT_PROPERTY_NOTES_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, onSuccess, property, upsertPropertyNotesModal]);

  if (!userIsAdmin && !userIsAgent) {
    return null;
  }

  return (
    <>
      {children
        ? children({ openUpsertPropertyNotesModal: upsertPropertyNotesModal.open })
        : (
          <Action
            data-ref="upsert-property-notes-action"
            label="Modifica"
            size="S"
            onClick={upsertPropertyNotesModal.open}
          />
        )}
      <Portal>
        <SimpleModal
          {...upsertPropertyNotesModal}
          data-ref="upsert-property-notes-modal"
          title="Modifica appunti"
        >
          <NoteForm
            initialValues={noteFormInitialValues}
            loading={isSaving}
            allowBlankText
            onSubmit={handleUpsertPropertyNotes}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpsertPropertyNotesAction;
