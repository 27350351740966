/* eslint-disable react/no-unknown-property */
import React from 'react';
import { HorizontalAlignment, VerticalAlignment } from '../../../types';
import { useCardContext } from '../CardContext';
import * as styles from './CardBox.style';

export interface CardBoxProps extends React.PropsWithChildren, React.AriaAttributes {
  unpadded?: boolean,
  fit?: boolean,
  grow?: number,
  verticalAlignment?: VerticalAlignment,
  horizontalAlignment?: HorizontalAlignment,
}

const CardBox: React.FC<CardBoxProps> = ({
  unpadded = false,
  fit = false,
  grow,
  verticalAlignment = VerticalAlignment.CENTER,
  horizontalAlignment = HorizontalAlignment.LEFT,
  children,
  ...rest
}) => {
  const { isCollapsed, compact } = useCardContext();

  return (
    <div
      css={styles.base}
      className="card-box"
      $unpadded={unpadded}
      $compact={compact}
      $isCollapsed={isCollapsed}
      $fit={fit}
      $grow={grow}
      $verticalAlignment={verticalAlignment}
      $horizontalAlignment={horizontalAlignment}
      {...rest}
    >
      {children}
    </div>
  );
};

export default CardBox;
