/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, PropsWithChildren } from 'react';
import GenericPageLayoutWrapper, { GenericPageLayoutWrapperWidth } from '../wrapper/GenericPageLayoutWrapper';

import * as styles from './GenericPageLayoutBar.style';

export type GenericPageLayoutBarProps = PropsWithChildren<{
  maxWrapperWidth?: GenericPageLayoutWrapperWidth;
}>;

const GenericPageLayoutBar: FunctionComponent<GenericPageLayoutBarProps> = ({
  maxWrapperWidth,
  children,
}) => (
  <div css={styles.bar}>
    <GenericPageLayoutWrapper
      maxWidth={maxWrapperWidth || GenericPageLayoutWrapperWidth.LARGE}
    >
      {children}
    </GenericPageLayoutWrapper>
  </div>
);

export default GenericPageLayoutBar;
