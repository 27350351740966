/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Form, FormActionsAlign, FormProps, FormSubmitType, Grid, HStack, Link, Spacing,
} from '@doveit/bricks';
import React from 'react';
import { subYears } from 'date-fns';
import validationSchema from './AssignmentWizardContactForm.schema';
import AddressSuggestionAutoComplete from '../../../../../components/address-suggestion-autocomplete/AddressSuggestionAutoComplete';
import { AddressSuggestion } from '../../../../../providers/geo/dtos';
import { INPUT_PLACEHOLDERS } from '../../../../../constants';
import { geocodeByAddress } from '../../../../../providers/geo/geocode/geocodeProvider';
import { isKeyOf } from '../../../../../utils/object/object';
import { identityDocumentTypeLabels } from '../../../../../labels';
import { asSelectOptions } from '../../../../../select-options/utils/asSelectOptions';
import { IdentityDocumentType } from '../../../../../providers/api/dtos';
import { IDENTITY_DOCUMENT_ISSUED_BY, IDENTITY_DOCUMENT_PLACEHOLDER } from '../assignment-wizard-planimetry-delegation/AssignmentWizardPlanimetryDelegationForm';
import { formatInputDate } from '../../../../../utils/form';

export interface AssignmentWizardContactFormModel {
  name: string,
  phoneNumber: string,
  email: string,
  birthday: string,
  birthplace: string,
  fiscalCode: string,
  citizenship?: string,
  placeOfResidence: {
    administrativeAreaLevelOne?: string,
    administrativeAreaLevelTwo: string,
    locality: string,
    plateCode: string,
    postalCode: string,
    route: string,
    streetNumber: string,
    latitude?: string,
    longitude?: string,
    normalizedAddress?: AddressSuggestion,
  },
  identityDocument?: {
    type: string,
    number: string,
    issuedBy: string,
    issuedAt: string,
  }
}

export interface AssignmentWizardContactFormProps {
  initialValues?: Partial<AssignmentWizardContactFormModel>,
  loading?: FormProps<AssignmentWizardContactFormModel>['loading'],
  disabled?: FormProps<AssignmentWizardContactFormModel>['disabled'],
  onSubmit: FormSubmitType<AssignmentWizardContactFormModel>,
  showIdentityDocument?: boolean,
  showCitizenship?: boolean,
}

const GEO_FIELDS = [
  'administrativeAreaLevelOne',
  'administrativeAreaLevelTwo',
  'locality',
  'postalCode',
  'plateCode',
  'route',
  'streetNumber',
  'latitude',
  'longitude',
];

const DEFAULT_INITIAL_VALUES: AssignmentWizardContactFormModel = {
  name: '',
  phoneNumber: '',
  email: '',
  birthday: '',
  birthplace: '',
  fiscalCode: '',
  citizenship: '',
  placeOfResidence: {
    administrativeAreaLevelTwo: '',
    locality: '',
    plateCode: '',
    postalCode: '',
    route: '',
    streetNumber: '',
  },
  identityDocument: {
    type: '',
    number: '',
    issuedBy: '',
    issuedAt: '',
  },
};

const AssignmentWizardContactForm: React.FC<AssignmentWizardContactFormProps> = ({
  initialValues = {},
  showCitizenship = false,
  showIdentityDocument = false,
  ...rest
}) => {
  const [showGeoFields, setShowGeoFields] = React.useState(!initialValues.placeOfResidence?.latitude && !initialValues.placeOfResidence?.longitude && !!initialValues.placeOfResidence?.normalizedAddress);
  const mergedInitialValues: AssignmentWizardContactFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
    citizenship: initialValues.citizenship || 'Italiana',
  }), [initialValues]);

  const minIdentityDocumentIssuedAt = React.useMemo(() => formatInputDate(subYears(new Date(), 10)), []);
  const today = React.useMemo(() => formatInputDate(new Date()), []);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      enableReinitialize
      {...rest}
    >
      {({ values, setFieldValue }) => {
        const onNormalizedAddressChanged = async (suggestion: AddressSuggestion | null) => {
          GEO_FIELDS.forEach((field) => {
            setFieldValue(`placeOfResidence.${field}`, '');
          });

          if (!suggestion) return;

          const { description } = suggestion;
          const [geoCode] = await geocodeByAddress(description);

          GEO_FIELDS.forEach((field) => {
            if (isKeyOf(geoCode, field)) {
              setFieldValue(`placeOfResidence.${field}`, geoCode[field] || '');
            }
          });
        };

        const toggleGeoFieldsBox = (open: boolean) => () => {
          setShowGeoFields(open);
          setFieldValue('placeOfResidence.normalizedAddress', null);

          GEO_FIELDS.forEach((field) => {
            setFieldValue(`placeOfResidence.${field}`, '');
          });
        };

        return (
          <>
            <Form.Item size={{ MD: 2 / 3 }}>
              <Form.Input
                name="name"
                label="Nome e cognome"
                aria-label="Campo per inserire il nome e cognome"
                placeholder={INPUT_PLACEHOLDERS.CONTACT_NAME_SURNAME}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Input
                name="phoneNumber"
                label="Telefono"
                aria-label="Campo per inserire il numero di telefono"
                placeholder={INPUT_PLACEHOLDERS.CONTACT_PHONE_NUMBER}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Input
                name="email"
                label="Email"
                aria-label="Campo per inserire l'indirizzo email"
                placeholder={INPUT_PLACEHOLDERS.CONTACT_EMAIL}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Input
                name="birthday"
                label="Data di nascita"
                type="date"
                aria-label="Campo per inserire la data di nascita"
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Input
                name="birthplace"
                label="Luogo di nascita"
                aria-label="Campo per inserire il luogo di nascita"
                placeholder={INPUT_PLACEHOLDERS.CONTACT_BIRTHPLACE}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Input
                name="fiscalCode"
                label="Codice fiscale"
                aria-label="Campo per inserire il codice fiscale"
                placeholder={INPUT_PLACEHOLDERS.CONTACT_FISCALCODE}
              />
            </Form.Item>

            {showCitizenship && (
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="citizenship"
                  label="Cittadinanza"
                  aria-label="Campo per inserire la cittadinanza"
                  placeholder={INPUT_PLACEHOLDERS.CONTACT_CITIZENSHIP}
                />
              </Form.Item>
            )}

            {/**
             * @todo move this logic in a dedicated component and replace it here
             * @see https://doveit.atlassian.net/browse/DOVE-11881
             */}
            <Form.Item>
              <Grid gutter={75}>
                <Grid.Unit size={{ MD: 2 / 3 }}>
                  <AddressSuggestionAutoComplete
                    name="placeOfResidence.normalizedAddress"
                    aria-label="Campo per inserire l'indirizzo di residenza"
                    label="Indirizzo di residenza"
                    placeholder={INPUT_PLACEHOLDERS.RESIDENCE_ADDRESS}
                    onSuggestionSelected={onNormalizedAddressChanged}
                    disabled={showGeoFields}
                  />
                  <Spacing margin={[100, 0, 0]}>
                    <HStack>
                      {showGeoFields ? ' Vuoi cercare un nuovo indirizzo?' : 'Non hai trovato l\'indirizzo?'}
                      <Link
                        role="button"
                        aria-label={showGeoFields ? 'Cerca un nuovo indirizzo' : 'Inserisci l\'indirizzo manualmente'}
                        onClick={showGeoFields
                          ? toggleGeoFieldsBox(false)
                          : toggleGeoFieldsBox(true)}
                      >
                        <strong>Clicca qui</strong>
                      </Link>
                    </HStack>
                  </Spacing>
                </Grid.Unit>
              </Grid>
            </Form.Item>
            {showGeoFields && (
              <>
                <Form.Item size={{ MD: 2 / 3 }}>
                  <Form.Input
                    name="placeOfResidence.route"
                    label="Strada"
                    aria-label="Campo per inserire la strada dell'immobile"
                    placeholder={INPUT_PLACEHOLDERS.PROPERTY_ROUTE}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 3 }}>
                  <Form.Input
                    name="placeOfResidence.streetNumber"
                    label="Numero civico"
                    aria-label="Campo per inserire il civico dell'immobile"
                    placeholder={INPUT_PLACEHOLDERS.PROPERTY_STREET_NUMBER}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 2 / 3 }}>
                  <Form.Input
                    name="placeOfResidence.locality"
                    label="Comune"
                    aria-label="Campo per inserire il comune dell'immobile"
                    placeholder={INPUT_PLACEHOLDERS.PROPERTY_LOCALITY}
                    required
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 3 }}>
                  <Form.Input
                    name="placeOfResidence.plateCode"
                    label="Provincia (sigla)"
                    aria-label="Campo per inserire la provincia dell'immobile"
                    placeholder={INPUT_PLACEHOLDERS.PROPERTY_PLATE_CODE}
                    minLength={2}
                    maxLength={2}
                  />
                </Form.Item>
              </>
            )}

            {showIdentityDocument && (
              <Form.Fieldset legend="Documento di riconoscimento">
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Select
                    name="identityDocument.type"
                    label="Tipo di documento"
                    aria-label="Campo per selezionare la tipologia di documento di riconoscimento"
                    options={asSelectOptions(identityDocumentTypeLabels)}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Input
                    name="identityDocument.number"
                    label="Numero"
                    aria-label="Campo per inserire il numero del documento di riconoscimento"
                    placeholder={values.identityDocument?.type ? IDENTITY_DOCUMENT_PLACEHOLDER[values.identityDocument.type as IdentityDocumentType] : 'Es. XXXXXXXXX'}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Input
                    name="identityDocument.issuedBy"
                    label="Rilasciato da"
                    aria-label="Campo per inserire l'ente di emissione del documento di riconoscimento"
                    placeholder={values.identityDocument?.type ? IDENTITY_DOCUMENT_ISSUED_BY[values.identityDocument.type as IdentityDocumentType] : ''}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Input
                    name="identityDocument.issuedAt"
                    type="date"
                    label="Data rilascio"
                    aria-label="Campo per selezionare la data di rilascio del documento di riconoscimento"
                    min={minIdentityDocumentIssuedAt}
                    max={today}
                  />
                </Form.Item>
              </Form.Fieldset>
            )}

            <Form.Actions align={FormActionsAlign.RIGHT}>
              <Form.Submit
                label="Modifica"
                aria-label="Salva dati"
              />
            </Form.Actions>
          </>
        );
      }}
    </Form>
  );
};

export default AssignmentWizardContactForm;
