export const box = {
  marginBottom: 20,
  fontSize: 10,
};

export const lastBox = {
  ...box,
  marginBottom: 0,
};

export const label = {
  fontWeight: 'bold',
  marginBottom: 2,
};

export const value = {
  marginTop: 2,
};
