/* eslint-disable react/no-unknown-property */
import React, { ChangeEvent, FunctionComponent } from 'react';
import {
  Grid, Skeleton, Spacing, Icon, CheckboxToggle, ModalSize, Portal, useModal, useNotifications, ICON_CHECK, ICON_HOME_OUTLINE, ICON_MAP_MARKER_OUTLINE, ICON_EMAIL_OUTLINE, Modal, ICON_WHATSAPP, Badge, Action, Message, ICON_HOME_SEARCH_OUTLINE, HStack,
  DetailItemList, Text,
} from '@doveit/bricks';
import { formatDate, formatEuroRange, formatSquareMetersRange } from '@doveit/hammer';
import { useParams } from 'react-router-dom';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import useSearchCriteria from '../../hooks/use-search-criteria/useSearchCriteria';
import useContact from '../../../contact/hooks/use-contact/useContact';
import Card from '../../../components/card/Card';
import ContactWidget from '../../../contact/components/contact-widget/ContactWidget';
import { getGeoCriterionLabel } from '../../utils/getGeoCriterionLabel';
import { propertyStatusLabels, propertyTypeLabels } from '../../../labels';
import { featureTypeLabels } from '../../../property/labels';
import Divider from '../../../components/divider/Divider';
import FlagLabel from '../../../components/flag-label/FlagLabel';
import MultilineContent from '../../../prospect/components/multiline-content/MultilineContent';
import useMatchProperties from '../../../property/hooks/use-match-properties/useMatchProperties';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import ViewPropertyCard from '../../../property/containers/view-property-card/ViewPropertyCard';
import { updateSearchCriteria } from '../../../providers/api/search-criteria/searchCriteriaProvider';
import usePagination from '../../../hooks/use-pagination/usePagination';
import { getGeoCriterionIcon } from '../../utils/getGeoCriterionIcon';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import SelectGeoSearchCriteria from '../../container/select-geo-search-criteria/SelectGeoSearchCriteria';
import { CompositeGeoSearchCriterion } from '../../../providers/api/dtos/searchCriteria';
import SearchCriteriaForm, { SearchCriteriaFormModel } from '../../component/search-criteria-form/SearchCriteriaForm';
import { toPartialSearchCriteria, toSearchCriteriaFormModel } from '../../mappers/searchCriteriaMapper';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import { WidgetCardSkeleton } from '../../../components/card/Card.skeleton';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { notifyExactMatchingProperties } from '../../../providers/api/notification/notificationProvider';
import { startConversation } from '../../../providers/api/conversation/conversationProvider';
import { getShortURL } from '../../../providers/rebrandly/rebrandlyProvider';
import { createJWTSignature } from '../../../providers/api/jwt-signature/jwtSignatureProvider';
import useConversationTemplates from '../../../conversation/hooks/use-conversation-templates/useConversationTemplates';
import { WhatsAppTemplate } from '../../../types';

export const SEARCH_CRITERIA_UPDATE_SUCCESS_MESSAGE = 'Criterio di ricerca aggiornato correttamente';
export const SEARCH_CRITERIA_UPDATE_ERROR_MESSAGE = 'Non è stato possibile aggiornare la ricerca';
export const NOTIFY_SEARCH_CRITERIA_INFO_MESSAGE = 'Saranno inviati solo gli immobili le cui caratteristiche corrispondono a quelle salvate nella ricerca';
export const EMAIL_NOTIFY_SEARCH_CRITERIA_RESULT_ERROR_MESSAGE = 'Non è stato possibile inviare l\'email con i risultati della ricerca';
export const WHATSAPP_NOTIFY_SEARCH_CRITERIA_RESULT_ERROR_MESSAGE = 'Non è stato possibile inviare il messaggio Whatsapp con i risultati della ricerca';
export const EMAIL_NOTIFY_SEARCH_CRITERIA_RESULT_SUCCESS_MESSAGE = 'L\'email con i risultati della ricerca è stata inviata con successo';
export const WHATSAPP_NOTIFY_SEARCH_CRITERIA_RESULT_SUCCESS_MESSAGE = 'Il messaggio Whatsapp con i risultati della ricerca è stata inviato con successo';
export const CONVERSATION_TEMPLATES_LOAD_ERROR = 'Impossibile caricare il template del messaggio whatsapp';
export const SEARCH_CRITERIA_LOAD_ERROR_MESSAGE = 'Impossibile caricare i dati dei criteri di ricerca';
export const CONTACT_ERROR_MESSAGE = 'Impossibile caricare il contatto associato.';
export const SEARCH_ERROR_MESSAGE = 'Errore durante la ricerca di immobili compatibili con i criteri di ricerca';

export const DOVE_IT_SEARCH_RESULTS_BASE_PATH = 'https://www.dove.it/vendita-case/consigliati';
const ViewSearchCriteriaPage: FunctionComponent = () => {
  const { userIsCallCenter, userIsAdmin } = useRBAC();
  const params = useParams();
  const id = parseInt(params.id!, 10);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const { page, goToPage } = usePagination('page', scrollRef);
  const { addSuccess, addError } = useNotifications();
  const geoSearchCriteriaModal = useModal();
  const searchCriteriaModal = useModal();
  const sendResultsModal = useModal();

  const [isSendingEmail, setIsSendingEmail] = React.useState(false);
  const [isSendingWhatsapp, setIsSendingWhatsapp] = React.useState(false);
  const [newGeoSearchCriteria, setNewGeoSearchCriteria] = React.useState<CompositeGeoSearchCriterion[]>([]);
  const [exactMatch, setExactMatch] = React.useState(false);

  const { data: searchCriteria, error: searchCriteriaError, mutate } = useSearchCriteria(id);
  const { data: contact, error: contactError } = useContact(searchCriteria?.contactId);
  const { data: matchedProperties, error: matchPropertiesError } = useMatchProperties(searchCriteria, { page }, exactMatch);
  const { data: conversationTemplates, error: conversationTemplatesError } = useConversationTemplates({ includeNames: [WhatsAppTemplate.SEARCH_CRITERIA] });

  const sendResultsEnabled = React.useMemo(() => matchedProperties && !!matchedProperties?.totalElements, [matchedProperties]);
  const canSendResultsEmail = React.useMemo(() => !!contact?.email, [contact?.email]);
  const canSendResultsWhatsapp = React.useMemo(() => !!contact?.phoneNumber, [contact?.phoneNumber]);

  const onExactMatchToggleChange = React.useCallback(() => {
    setExactMatch((prev) => !prev);
  }, []);

  const toggleSearch = React.useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      await updateSearchCriteria(searchCriteria?.id!, {
        ...searchCriteria!,
        active: event.target.checked,
      });

      mutate();
    } catch (error) {
      addError(SEARCH_CRITERIA_UPDATE_ERROR_MESSAGE);
    }
  }, [addError, mutate, searchCriteria]);

  const openGeoSearchCriteriaModal = React.useCallback(() => {
    setNewGeoSearchCriteria(searchCriteria?.geo ?? []);
    geoSearchCriteriaModal.open();
  }, [geoSearchCriteriaModal, searchCriteria]);

  const sendResultsEmail = React.useCallback(async () => {
    setIsSendingEmail(true);
    try {
      await notifyExactMatchingProperties({ contactId: contact!.id!, propertyIds: matchedProperties!.content.map((p) => p.id) });
      setIsSendingEmail(false);
      sendResultsModal.close();
      addSuccess(EMAIL_NOTIFY_SEARCH_CRITERIA_RESULT_SUCCESS_MESSAGE);
    } catch (error) {
      setIsSendingEmail(false);
      addError(EMAIL_NOTIFY_SEARCH_CRITERIA_RESULT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, contact, matchedProperties, sendResultsModal]);

  const sendResultsWhatsapp = React.useCallback(async () => {
    if (!conversationTemplates || conversationTemplatesError) {
      addError(CONVERSATION_TEMPLATES_LOAD_ERROR);
      return;
    }

    setIsSendingWhatsapp(true);
    try {
      const jwtSignature = await createJWTSignature({
        propertyIds: matchedProperties!.content.map((matchedProperty) => matchedProperty.id),
      });

      const formattedUrl = await getShortURL(`${DOVE_IT_SEARCH_RESULTS_BASE_PATH}/${jwtSignature}`);
      const message = conversationTemplates![0].content.replace('{{1}}', formattedUrl);
      await startConversation({ to: contact!.phoneNumber!, metadata: {}, message });
      setIsSendingWhatsapp(false);
      sendResultsModal.close();
      addSuccess(WHATSAPP_NOTIFY_SEARCH_CRITERIA_RESULT_SUCCESS_MESSAGE);
    } catch (error) {
      setIsSendingEmail(false);
      addError(WHATSAPP_NOTIFY_SEARCH_CRITERIA_RESULT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, contact, conversationTemplates, conversationTemplatesError, matchedProperties, sendResultsModal]);

  const onGeoSearchCriteriaSave = React.useCallback(async () => {
    try {
      await updateSearchCriteria(searchCriteria!.id!, {
        ...searchCriteria!,
        geo: newGeoSearchCriteria,
      });

      mutate();
      geoSearchCriteriaModal.close();
      addSuccess(SEARCH_CRITERIA_UPDATE_SUCCESS_MESSAGE);
    } catch (err) {
      addError(SEARCH_CRITERIA_UPDATE_ERROR_MESSAGE);
    }
  }, [searchCriteria, newGeoSearchCriteria, mutate, geoSearchCriteriaModal, addSuccess, addError]);

  const onSubmit = React.useCallback(async (model: SearchCriteriaFormModel) => {
    try {
      if (searchCriteria) {
        const partialSearchCriteria = toPartialSearchCriteria(model);
        await updateSearchCriteria(searchCriteria.id!, { ...searchCriteria, ...partialSearchCriteria });
        mutate();
        searchCriteriaModal.close();
        addSuccess(SEARCH_CRITERIA_UPDATE_SUCCESS_MESSAGE);
      }
    } catch (e: any) {
      addError(SEARCH_CRITERIA_UPDATE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, searchCriteria, searchCriteriaModal, mutate]);

  const searchCriteriaFormModel = React.useMemo(
    () => (searchCriteria ? toSearchCriteriaFormModel(searchCriteria) : undefined),
    [searchCriteria],
  );

  if (searchCriteriaError) {
    return (
      <GenericErrorPage
        title={SEARCH_CRITERIA_LOAD_ERROR_MESSAGE}
      />
    );
  }

  if (!searchCriteria) {
    return null;
  }

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content
        title={contact ? (
          <HStack>
            <span aria-label="Titolo della pagina" data-ref="page-title">Ricerca di {contact.name}</span>
            <CheckboxToggle
              checked={searchCriteria.active}
              title={`Ricerca ${searchCriteria.active ? 'attiva' : 'non attiva'}`}
              aria-label={`Campo per ${searchCriteria.active ? 'disattivare' : 'attivare'} la ricerca`}
              onChange={toggleSearch}
            />
          </HStack>
        ) : <Skeleton width="60%" />}
      >
        <Spacing margin={[0, 0, 200]}>
          <div aria-label="Sottotitolo della pagina">
            <Text.Body data-ref="subtitle">
              Creata il <strong>{formatDate(new Date(searchCriteria.createdAt!))}</strong>
              {' - '}
              {searchCriteria.active ? 'Aggiornata' : 'Disattivata'}
              {' il '}
              <strong>{formatDate(new Date(searchCriteria.updatedAt!))}</strong>
            </Text.Body>
          </div>
        </Spacing>

        <Grid gutter={150}>
          <Grid.Unit>
            {!contact && !contactError && (
              <WidgetCardSkeleton />
            )}
            {contactError && (
              <Message
                type="critical"
                boxed
                message={CONTACT_ERROR_MESSAGE}
              />
            )}
            {contact && (
              <ContactWidget
                contact={contact}
                aria-label="Informazioni del contatto"
              />
            )}
          </Grid.Unit>
          <Grid.Unit>
            <Card data-ref="geo-section" aria-label="Sezione della geografia">
              <Card.Header
                primaryActions={[
                  <Action
                    aria-label="Modifica la posizione"
                    label="Modifica"
                    size="S"
                    onClick={openGeoSearchCriteriaModal}
                  />,
                ]}
              >
                <Card.Title>
                  <Icon path={ICON_MAP_MARKER_OUTLINE} />
                  <Text.Body color="primary.default.low">
                    Posizione
                  </Text.Body>
                </Card.Title>
              </Card.Header>
              <Card.Box>
                <HStack>
                  {searchCriteria.geo.map((geo) => (
                    <Badge
                      key={getGeoCriterionLabel(geo)}
                      icon={getGeoCriterionIcon(geo)}
                      label={getGeoCriterionLabel(geo)}
                    />
                  ))}
                </HStack>
              </Card.Box>
            </Card>
          </Grid.Unit>
          <Grid.Unit>
            <Card data-ref="search-criteria" aria-label="Sezione dei criteri di ricerca">
              <Card.Header
                primaryActions={[
                  <Action
                    aria-label="Modifica i criteri di ricerca"
                    label="Modifica"
                    size="S"
                    onClick={searchCriteriaModal.open}
                  />,
                ]}
              >
                <Card.Title>
                  <Icon path={ICON_HOME_SEARCH_OUTLINE} />
                  <Text.H3 color="primary.default.low">
                    Criteri di ricerca
                  </Text.H3>
                </Card.Title>
              </Card.Header>
              <Card.Box>
                <DetailItemList columns={3}>
                  <DetailItemList.Item label="Tipologia">
                    {searchCriteria.propertyTypes.length > 0
                      ? searchCriteria.propertyTypes.map((type) => propertyTypeLabels[type]).join(', ')
                      : undefined}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Superficie">
                    {formatSquareMetersRange(searchCriteria.minSize, searchCriteria.maxSize)}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Stato immobile">
                    {searchCriteria.propertyStatus.length > 0
                      ? searchCriteria.propertyStatus.map((type) => propertyStatusLabels[type]).join(', ')
                      : undefined}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Dispone di">
                    <HStack>
                      {searchCriteria.features.map((type) => (
                        <Badge
                          key={type}
                          label={featureTypeLabels[type]}
                          icon={ICON_CHECK}
                          size="XS"
                        />
                      ))}
                      {searchCriteria.privateGarden && (
                        <Badge
                          label="Giardino"
                          icon={ICON_CHECK}
                          size="XS"
                        />
                      )}
                      {searchCriteria.garage && (
                        <Badge
                          label="Box"
                          icon={ICON_CHECK}
                          size="XS"
                        />
                      )}
                      {searchCriteria.balcony && (
                        <Badge
                          label="Balcone"
                          icon={ICON_CHECK}
                          size="XS"
                        />
                      )}
                      {searchCriteria.terrace && (
                        <Badge
                          label="Terrazzo"
                          icon={ICON_CHECK}
                          size="XS"
                        />
                      )}
                      {!searchCriteria.features.length
                        && !searchCriteria.privateGarden
                        && !searchCriteria.garage
                        && !searchCriteria.balcony
                        && !searchCriteria.terrace
                        && (
                          ' — '
                        )}
                    </HStack>
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Budget">
                    {formatEuroRange(searchCriteria.minPrice, searchCriteria.maxPrice)}
                  </DetailItemList.Item>
                </DetailItemList>
                <Spacing margin={[200, 0, 0, 0]}>
                  <Divider>Info aggiuntive</Divider>
                  <DetailItemList columns={4}>
                    <DetailItemList.Item>
                      <FlagLabel checked={searchCriteria.mortgage}>
                        Mutuo
                      </FlagLabel>
                    </DetailItemList.Item>
                    <DetailItemList.Item>
                      <FlagLabel checked={searchCriteria.auction}>
                        Interessato ad aste
                      </FlagLabel>
                    </DetailItemList.Item>
                  </DetailItemList>
                </Spacing>
                {searchCriteria.notes && (
                  <>
                    <Divider>Note</Divider>
                    <MultilineContent openLabel="Mostra tutto">
                      {searchCriteria.notes}
                    </MultilineContent>
                  </>
                )}
              </Card.Box>
            </Card>
          </Grid.Unit>
          <Grid.Unit>
            <div ref={scrollRef} />
            <Card data-ref="search-matches" aria-label="Sezione dei risultati">
              <Card.Header
                primaryActions={[
                  (userIsCallCenter || userIsAdmin) && (
                    <Action
                      aria-label="Invia i risultati della ricerca al contatto"
                      label="Invia risultati"
                      size="S"
                      onClick={sendResultsModal.open}
                      disabled={!sendResultsEnabled}
                    />
                  ),
                ]}
              >
                <Card.Title>
                  <Icon path={ICON_HOME_OUTLINE} />
                  <Text.H3 color="primary.default.low">
                    Risultati
                  </Text.H3>
                </Card.Title>
              </Card.Header>
              <Card.Box>
                <HStack alignItems="center" justifyContent="end">
                  <CheckboxToggle
                    data-ref="exact-match-toggle"
                    text="Includi caratteristiche"
                    checked={exactMatch}
                    aria-label="Campo per specificare se vedere soltanto gli immobili che includono le caratteristiche"
                    onChange={onExactMatchToggleChange}
                    style={{ fontSize: '10px' }}
                  />
                </HStack>
                {!matchedProperties && matchPropertiesError && (
                  <Message
                    type="critical"
                    message={SEARCH_ERROR_MESSAGE}
                  />
                )}
                {matchedProperties && (
                  <PaginatedList
                    {...matchedProperties}
                    emptyResultMessage="Non sono presenti immobili compatibili con i criteri di ricerca"
                    goToPage={goToPage}
                  >
                    {matchedProperties.content.map((property) => (
                      <ViewPropertyCard
                        key={property.id}
                        propertySearchItem={property}
                        showStatus
                        showAgentName
                        showCharacteristics
                        showCreateIntentAction
                        showViewDetailsAction
                      />
                    ))}
                  </PaginatedList>
                )}
              </Card.Box>
            </Card>
          </Grid.Unit>
        </Grid>
        <Portal>
          <SimpleModal
            data-ref="geo-search-criteria-modal"
            {...geoSearchCriteriaModal}
            title="Modifica la posizione tramite mappa o località"
            size={ModalSize.LARGE}
            aria-label="Modale per modificare la posizione"
            footer={(
              <Action
                label="Modifica"
                aria-label="Modifica"
                color="primary"
                emphasis="high"
                disabled={newGeoSearchCriteria.length === 0}
                onClick={onGeoSearchCriteriaSave}
              />
            )}
          >
            <div aria-label="Campo per definire la posizione">
              <SelectGeoSearchCriteria
                initialValues={searchCriteria?.geo}
                onChange={setNewGeoSearchCriteria}
              />
            </div>
          </SimpleModal>
          <SimpleModal
            {...searchCriteriaModal}
            data-ref="search-criteria-modal"
            title="Modifica criteri di ricerca"
          >
            <SearchCriteriaForm
              initialValues={searchCriteriaFormModel}
              onSubmit={onSubmit}
            />
          </SimpleModal>
          <Modal
            open={sendResultsModal.isOpen}
            size={ModalSize.MEDIUM}
            onCloseHandler={sendResultsModal.close}
            data-ref="send-results-modal"
            aria-label="Modale per inviare i risultati al contatto"
          >
            <Modal.Close />
            <Modal.Header>
              <Text.H4>
                Invia i risultati della ricerca al contatto
              </Text.H4>
            </Modal.Header>
            <Modal.Body padded>
              <Spacing margin={[0, 0, 400]}>
                <Message type="info" message={NOTIFY_SEARCH_CRITERIA_INFO_MESSAGE} />
              </Spacing>
              <HStack>
                <Action
                  label="Email"
                  aria-label="Invia email coi risultati"
                  iconLeft={{ path: ICON_EMAIL_OUTLINE }}
                  emphasis="high"
                  onClick={sendResultsEmail}
                  disabled={!canSendResultsEmail}
                  loading={isSendingEmail}
                />
                <Action
                  label="Whatsapp"
                  aria-label="Invia messaggio Whatsapp coi risultati"
                  iconLeft={{ path: ICON_WHATSAPP }}
                  emphasis="high"
                  onClick={sendResultsWhatsapp}
                  disabled={!canSendResultsWhatsapp}
                  loading={isSendingWhatsapp}
                />
              </HStack>
            </Modal.Body>
          </Modal>
        </Portal>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default ViewSearchCriteriaPage;
