import { CardSkeleton, Grid, Skeleton } from '@doveit/bricks';
import React from 'react';
import GenericPageLayout from '../../layouts/generic-page-layout/GenericPageLayout';

const PageSkeleton: React.FC = () => (
  <GenericPageLayout aria-label="page skeleton">
    <GenericPageLayout.Content
      title={<Skeleton height="3rem" width="40%" />}
      subtitle={<Skeleton width="60%" />}
    >
      <Grid gutter={150}>
        <Grid.Unit size={{ MD: 1 / 2 }}>
          <CardSkeleton />
        </Grid.Unit>
        <Grid.Unit size={{ MD: 1 / 2 }}>
          <CardSkeleton />
        </Grid.Unit>
      </Grid>
    </GenericPageLayout.Content>
  </GenericPageLayout>
);

export default PageSkeleton;
