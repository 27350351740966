import React from 'react';
import {
  Badge, useModal, Portal, ICON_CASH_MULTIPLE, ICON_HOME_OUTLINE, ICON_PENCIL_OUTLINE, Message, ActionIcon, ActionIconProps, ICON_ACCOUNT_OUTLINE, ICON_FILE_DOCUMENT_OUTLINE, ICON_CLOCK_OUTLINE, ICON_INVOICE_OUTLINE, ICON_PIGGY_BANK_OUTLINE, HStack, DetailItemList,
} from '@doveit/bricks';
import { formatDate, formatEuro } from '@doveit/hammer';
import { useNavigate } from 'react-router-dom';
import { Invoice, Property } from '../../../providers/api/dtos';
import Card from '../../../components/card/Card';
import useInvoiceByProperty from '../../../invoice/hooks/use-invoice-by-property/useInvoiceByProperty';
import ShowAgentName from '../../../agent/containers/show-agent-name/ShowAgentName';
import { EntityType } from '../../../domain/types/entityType';
import { InvoiceStatus } from '../../../domain/types';
import { InlineCardSkeleton } from '../../../components/card/Card.skeleton';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import EditInvoice from '../../../invoice/containers/edit-invoice/EditInvoice';
import { isForecastedDateInThePast } from '../../../invoice/containers/edit-invoice/utils';
import ActiveOfferBadge from '../../../offer/components/active-offer-badge/ActiveOfferBadge';

export interface ViewPropertyWithInvoiceCardProps {
  property: Property,
  onInvoiceUpdate?: (updatedInvoice: Invoice, previousStatus?: InvoiceStatus) => void,
}

type InvoiceAction = 'edit' | 'manage';

export const INVOICE_LOAD_ERROR_MESSAGE = 'Non è stato possibile recuperare i dati del pagamento';

const mapInvoiceStatusToManageActionData: Record<InvoiceStatus.TODO | InvoiceStatus.TO_COLLECT, Pick<ActionIconProps, 'icon' | 'label' | 'color'>> = {
  [InvoiceStatus.TODO]: {
    color: 'neutral',
    label: 'Emetti proforma',
    icon: { path: ICON_FILE_DOCUMENT_OUTLINE },
  },
  [InvoiceStatus.TO_COLLECT]: {
    color: 'primary',
    icon: { path: ICON_PIGGY_BANK_OUTLINE },
    label: 'Incassa fattura',
  },
};

const ViewPropertyWithInvoiceCard: React.FC<ViewPropertyWithInvoiceCardProps> = ({
  property,
  onInvoiceUpdate,
}) => {
  const navigate = useNavigate();
  const { data: invoice, error: invoiceError, mutate: mutateInvoice } = useInvoiceByProperty(property.id || undefined);
  const invoiceModal = useModal<InvoiceAction>();

  const onInternalInvoiceUpdate = React.useCallback((updatedInvoice: Invoice) => {
    invoiceModal.close();

    if (onInvoiceUpdate) {
      onInvoiceUpdate(updatedInvoice, invoice?.status);
    }

    mutateInvoice();
  }, [invoiceModal, invoice?.status, mutateInvoice, onInvoiceUpdate]);

  const openInvoiceModal = React.useCallback((action: InvoiceAction) => () => {
    invoiceModal.open(action);
  }, [invoiceModal]);

  const onViewActionClick = React.useCallback(() => {
    navigate(`/properties/${property.id!}`);
  }, [navigate, property.id]);

  const showCollectedAt = React.useMemo(() => invoice?.status === InvoiceStatus.COLLECTED && !!invoice.collectedAt, [invoice]);

  const showForecastedDate = React.useMemo(
    () => !showCollectedAt && invoice?.status !== InvoiceStatus.TO_COLLECT,
    [showCollectedAt, invoice],
  );

  if (invoiceError) {
    return (
      <Message
        type="critical"
        message={INVOICE_LOAD_ERROR_MESSAGE}
      />
    );
  }

  if (!invoice) {
    return <InlineCardSkeleton />;
  }

  return (
    <>
      <Card>
        <Card.Inline>
          <Card.Box>
            <DetailItemList>
              <DetailItemList.Item>
                <HStack>
                  <Badge
                    data-ref="property-reference-badge"
                    label={property.referenceId!}
                    color="primary"
                    size="XS"
                  />
                  <ActiveOfferBadge
                    propertyId={property.id!}
                    size="XS"
                  />
                </HStack>
              </DetailItemList.Item>
              <DetailItemList.Item
                icon={ICON_HOME_OUTLINE}
                title="Indirizzo immobile"
              >
                {property.geo!.normalizedAddress}
              </DetailItemList.Item>
              {property.soldPrice && (
                <DetailItemList.Item
                  icon={ICON_CASH_MULTIPLE}
                  title="Prezzo di vendita"
                >
                  <strong>{formatEuro(property.soldPrice)}</strong>
                </DetailItemList.Item>
              )}
            </DetailItemList>
          </Card.Box>
          <Card.ColumnDivider />
          <Card.Box>
            <DetailItemList>
              {property.agentEmail && (
                <DetailItemList.Item
                  icon={ICON_ACCOUNT_OUTLINE}
                  title="Agente incaricato"
                >
                  <ShowAgentName
                    entityId={property.id!}
                    entityType={EntityType.PROPERTY}
                  />
                </DetailItemList.Item>
              )}
              {showCollectedAt && (
                <DetailItemList.Item
                  data-ref="collect-date-item"
                  icon={ICON_CLOCK_OUTLINE}
                  title="Data di incasso"
                >
                  {formatDate(new Date(invoice.collectedAt!))}
                </DetailItemList.Item>
              )}
              {showForecastedDate && (
                <DetailItemList.Item
                  data-ref="forecasted-date-item"
                  icon={ICON_CLOCK_OUTLINE}
                  title="Data di fatturazione prevista"
                  textColor={isForecastedDateInThePast(invoice.forecastedInvoiceDate) ? 'critical' : undefined}
                >
                  {formatDate(new Date(invoice.forecastedInvoiceDate))}
                </DetailItemList.Item>
              )}
              <DetailItemList.Item
                data-ref="fee-item"
                icon={ICON_INVOICE_OUTLINE}
                title="Commissioni (venditore • compratore)"
              >
                {formatEuro(invoice.buyerFee)}{invoice.sellerFee !== undefined ? ` • ${formatEuro(invoice.sellerFee)}` : ''}
              </DetailItemList.Item>
            </DetailItemList>
          </Card.Box>
          {invoice.status !== InvoiceStatus.COLLECTED && (
            <>
              <Card.ColumnDivider />
              <Card.Box fit>
                <HStack>
                  <ActionIcon
                    data-ref="edit-invoice-action"
                    icon={{ path: ICON_PENCIL_OUTLINE }}
                    label="Modifica"
                    onClick={openInvoiceModal('edit')}
                  />
                  <ActionIcon
                    data-ref="view-property-action"
                    icon={{ path: ICON_HOME_OUTLINE }}
                    label="Visualizza"
                    onClick={onViewActionClick}
                  />
                  <ActionIcon
                    data-ref="manage-invoice-action"
                    icon={mapInvoiceStatusToManageActionData[invoice.status].icon}
                    label={mapInvoiceStatusToManageActionData[invoice.status].label}
                    color={mapInvoiceStatusToManageActionData[invoice.status].color}
                    onClick={openInvoiceModal('manage')}
                  />
                </HStack>
              </Card.Box>
            </>
          )}
        </Card.Inline>
      </Card>
      <Portal>
        {invoice.status !== InvoiceStatus.COLLECTED && (
          <SimpleModal
            {...invoiceModal}
            title={invoiceModal.data === 'manage'
              ? mapInvoiceStatusToManageActionData[invoice.status].label
              : 'Modifica fattura'}
          >
            <EditInvoice
              invoice={invoice}
              onSuccess={onInternalInvoiceUpdate}
              allowStatusChange={invoiceModal.data === 'manage'}
            />
          </SimpleModal>
        )}
      </Portal>
    </>
  );
};

export default ViewPropertyWithInvoiceCard;
