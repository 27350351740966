import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Property, Review } from '../../../providers/api/dtos';
import { getReviewsByPropertyId } from '../../../providers/api/review/reviewProvider';

export const REVIEWS_RID = '/api/v2/properties/:propertyId/reviews';

export default function useReviewsByPropertyId(
  propertyId: Property['id'],
  options?: SWRConfiguration<Review[], AxiosError>,
): SWRResponse<Review[], AxiosError> {
  const key = propertyId ? [REVIEWS_RID, propertyId] : null;

  return useSWR(
    key,
    () => getReviewsByPropertyId(propertyId!),
    options,
  );
}
