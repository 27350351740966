import React, { MutableRefObject } from 'react';
import { Form, FormHandlers, Spacing } from '@doveit/bricks';
import PropertyOwnershipTypeForm, { PropertyOwnershipTypeFormModel } from '../../../../property/assignment/components/property-ownership-type-form/PropertyOwnershipTypeForm';
import PropertyInfoForm, { PropertyInfoFormModel } from '../../../../property/components/property-info-form/PropertyInfoForm';
import { OfferWizardStepProps } from '../types';
import Divider from '../../../../components/divider/Divider';
import { asSelectOptions } from '../../../../select-options/utils/asSelectOptions';
import { offerPropertyStatusLabels } from '../../../../labels';
import { toOfferWizardData, toPropertyInfoFormModel, toPropertyOwnershipTypeFormModel } from './mappers';
import useProperty from '../../../../property/hooks/use-property/useProperty';
import PageSkeleton from '../../../../components/page-skeleton/PageSkeleton';

export interface FormModel {
  clearOfObjects: boolean,
  clearOfOccupants: boolean
}

const OfferWizardPropertyDetails: React.FC<OfferWizardStepProps> = ({
  intent,
  initialData,
  formRef: propertyOwnershipTypeFormRef,
  loading = false,
  onSubmit,
}) => {
  const parentFormRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;
  const propertyInfoFormRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;

  const { data: property, isLoading: isPropertyLoading } = useProperty(intent.propertyId);

  const [propertyOwnershipTypeFormValues, setPropertyOwnershipTypeFormValues] = React.useState<PropertyOwnershipTypeFormModel>();
  const [propertyInfoFormValues, setPropertyInfoFormValues] = React.useState<PropertyInfoFormModel>();

  const handlePropertyOwnershipTypeFormSubmit = React.useCallback((values: PropertyOwnershipTypeFormModel) => {
    setPropertyOwnershipTypeFormValues(values);

    propertyInfoFormRef.current.handleSubmit();
  }, [setPropertyOwnershipTypeFormValues]);

  const handlePropertyInfoFormSubmit = React.useCallback((values: PropertyInfoFormModel) => {
    setPropertyInfoFormValues(values);

    parentFormRef.current.handleSubmit();
  }, []);

  const handleFormSubmit = React.useCallback((values: FormModel) => {
    if (!propertyOwnershipTypeFormValues || !propertyInfoFormValues) return;

    const updatedOfferWizardData = toOfferWizardData(
      initialData,
      propertyOwnershipTypeFormValues,
      propertyInfoFormValues,
      values,
    );

    const isPropertyOwnershipTypeFormDirty = (propertyOwnershipTypeFormRef?.current as any)?.dirty ?? false;
    const isPropertyInfoFormDirty = (propertyInfoFormRef?.current as any)?.dirty ?? false;
    const isParentFormDirty = (parentFormRef?.current as any)?.dirty ?? false;

    const isDirty = isPropertyOwnershipTypeFormDirty || isPropertyInfoFormDirty || isParentFormDirty;

    onSubmit(updatedOfferWizardData, isDirty);
  }, [initialData, onSubmit, propertyInfoFormValues, propertyOwnershipTypeFormRef, propertyOwnershipTypeFormValues]);

  if (isPropertyLoading) return <PageSkeleton />;

  return (
    <Form
      loading={loading}
      innerRef={parentFormRef}
      onSubmit={handleFormSubmit}
      initialValues={{
        clearOfObjects: initialData.property?.clearOfObjects ?? true,
        clearOfOccupants: initialData.property?.clearOfOccupants ?? true,
      }}
    >
      <Spacing margin={[200, 0]}>
        <PropertyOwnershipTypeForm
          initialValues={toPropertyOwnershipTypeFormModel(initialData, property)}
          loading={loading}
          innerRef={propertyOwnershipTypeFormRef}
          onSubmit={handlePropertyOwnershipTypeFormSubmit}
        />
      </Spacing>

      <Divider />

      <Spacing margin={[200, 0]}>
        <PropertyInfoForm
          loading={loading}
          initialValues={toPropertyInfoFormModel(initialData)}
          propertyStatusOptions={asSelectOptions(offerPropertyStatusLabels)}
          formRef={propertyInfoFormRef}
          onSubmit={handlePropertyInfoFormSubmit}
          hiddenFields={{
            technician: true,
            technicianNeeded: true,
            propertySource: true,
            subscriptionPlace: true,
            propertySourceInfo: true,
          }}
        />
      </Spacing>

      <Divider>
        Libero da
      </Divider>

      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Checkbox
          name="clearOfObjects"
          text="Cose"
          aria-label="L'immobile è libero da cose"
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Checkbox
          name="clearOfOccupants"
          text="Persone"
          aria-label="L'immobile è libero da persone"
        />
      </Form.Item>
    </Form>
  );
};

export default OfferWizardPropertyDetails;
