import React from 'react';
import {
  Dropdown,
  ICON_HOME_OUTLINE, ICON_MAP_MARKER_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { useNavigate } from 'react-router-dom';
import { formatSquareMeters } from '@doveit/hammer';
import { Intent, Note } from '../../../../../providers/api/dtos';
import { raise } from '../../../../../utils';
import useRBAC from '../../../../../hooks/use-rbac/useRBAC';
import ContactActivityCard, { ContactActivityCardProps } from '../../../../components/contact-activity-card/ContactActivityCard';
import { formatPropertyType } from '../../../../../property/utils';
import NoteForm, { NoteFormModel } from '../../../../../notes/components/note-form/NoteForm';
import { updateIntent } from '../../../../../providers/api/intent/intentProvider';
import { NOTE_CREATE_SUCCESS_MESSAGE, NOTE_SAVE_ERROR_MESSAGE } from '../../../../../notes/components/notes-widget/NotesWidget';
import { intentStatusLabels } from '../../../../../labels';
import { intentStatusBadgeColor } from '../../../../../intent/components/intent-status-badge/IntentStatusBadge';
import SimpleModal from '../../../../../components/simple-modal/SimpleModal';
import usePropertyPreview from '../../../../../property/hooks/use-property-preview/usePropertyPreview';

export interface ContactIntentSummaryCardProps {
  intent: Intent,
  onIntentUpdate?: (updatedIntent: Intent) => void,
}

const ContactIntentSummaryCard: React.FC<ContactIntentSummaryCardProps> = ({
  intent,
  onIntentUpdate,
}) => {
  const {
    id, status, propertyId, createdAt, updatedAt,
  } = intent;

  const intentId = id ?? raise('No intent id provided');

  const { mainUserRole, user } = useRBAC();
  const [isNoteAddLoading, setIsNoteAddLoading] = React.useState(false);
  const { data: property } = usePropertyPreview(propertyId);
  const addNoteModal = useModal();
  const navigate = useNavigate();
  const { addError, addSuccess } = useNotifications();

  const intentActions = React.useMemo(() => [
    {
      'aria-label': 'Vedi interesse del contatto',
      label: 'Vedi interesse',
      onClick: () => navigate(`/intents/${id}`),
    },
    {
      'aria-label': 'Aggiungi una nota all\'interesse',
      label: 'Aggiungi nota',
      onClick: addNoteModal.open,
    },
  ], [addNoteModal.open, id, navigate]);

  const features: ContactActivityCardProps['features'] = React.useMemo(() => {
    if (!property) return [];

    const { propertyType, propertySize, geo } = property;

    return [{
      icon: ICON_HOME_OUTLINE,
      title: 'Dettagli immobile',
      label: [formatPropertyType(propertyType), propertySize && formatSquareMeters(propertySize)]
        .filter((part) => !!part)
        .join(', '),
    }, {
      icon: ICON_MAP_MARKER_OUTLINE,
      title: 'Indirizzo immobile',
      label: geo?.normalizedAddress || ' - ',
    }];
  }, [property]);

  const addIntentNote = React.useCallback(async (values: Partial<NoteFormModel>) => {
    try {
      setIsNoteAddLoading(true);

      const now = new Date().toISOString();

      const noteToAdd: Note = {
        date: now,
        role: mainUserRole,
        author: user.name,
        text: values.text!,
        nudge: values.nudge,
      };

      const intentToUpdate: Intent = {
        ...intent,
        notes: [noteToAdd, ...(intent.notes ?? [])],
      };

      const updatedIntent = await updateIntent(intentId, intentToUpdate);

      setIsNoteAddLoading(false);
      addSuccess(NOTE_CREATE_SUCCESS_MESSAGE);
      addNoteModal.close();

      if (onIntentUpdate) {
        onIntentUpdate(updatedIntent);
      }
    } catch (err) {
      setIsNoteAddLoading(false);
      addError(NOTE_SAVE_ERROR_MESSAGE);
    }
  }, [addError, addNoteModal, addSuccess, intent, intentId, mainUserRole, onIntentUpdate, user.name]);

  return (
    <>
      <ContactActivityCard
        key={id}
        type="intent"
        status={intentStatusLabels[status]}
        statusColor={intentStatusBadgeColor[status]}
        createdAt={createdAt}
        updatedAt={updatedAt}
        features={features}
        actions={intentActions.map((action) => (
          <Dropdown.Option {...action} />
        ))}
      />
      <Portal>
        <SimpleModal
          {...addNoteModal}
          title="Aggiungi nota"
        >
          <NoteForm
            loading={isNoteAddLoading}
            onSubmit={addIntentNote}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ContactIntentSummaryCard;
