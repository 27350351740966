import { Agency, Geo } from '../../../providers/api/dtos';
import { EditAgencyRegistryFormModel } from '../../components/edit-agency-registry-form/EditAgencyRegistryForm';

const toGeoFormModel = (geo?: Geo): EditAgencyRegistryFormModel['address'] => ({
  administrativeAreaLevelOne: geo?.administrativeAreaLevelOne || '',
  administrativeAreaLevelTwo: geo?.administrativeAreaLevelTwo || '',
  locality: geo?.locality || '',
  plateCode: geo?.plateCode || '',
  postalCode: geo?.postalCode || '',
  route: geo?.route || '',
  streetNumber: geo?.streetNumber || '',
  normalizedAddress: geo?.normalizedAddress ? {
    placeId: '',
    description: geo.normalizedAddress,
  } : undefined,
  latitude: geo?.latitude ? `${geo.latitude}` : '',
  longitude: geo?.longitude ? `${geo.longitude}` : '',
});

const toGeo = (geoFormModel: EditAgencyRegistryFormModel['address']): Geo => ({
  administrativeAreaLevelOne: geoFormModel.administrativeAreaLevelOne?.trim() || undefined,
  administrativeAreaLevelTwo: geoFormModel.administrativeAreaLevelTwo?.trim() || undefined,
  latitude: geoFormModel.latitude?.trim() ? Number(geoFormModel.latitude.trim()) : undefined,
  longitude: geoFormModel.longitude?.trim() ? Number(geoFormModel.longitude.trim()) : undefined,
  locality: geoFormModel.locality?.trim() || undefined,
  plateCode: geoFormModel.plateCode?.trim() || undefined,
  postalCode: geoFormModel.postalCode?.trim() || undefined,
  route: geoFormModel.route?.trim() || undefined,
  streetNumber: geoFormModel.streetNumber?.trim() || undefined,
});

export const agencyToEditAgencyRegistryFormModel = (agency: Agency): EditAgencyRegistryFormModel => ({
  name: agency.name || '',
  phoneNumber: agency.phoneNumber || '',
  address: toGeoFormModel(agency.address),
  openingHours: agency.openingHours?.join('\n') || '',
});

export const editAgencyRegistryFormModelToAgency = (agencyFormModel: EditAgencyRegistryFormModel, existingAgency: Agency): Agency => {
  const openingHours = agencyFormModel.openingHours
    .replace(/(^[ \t]*\n)/gm, '')
    .split('\n')
    .map((hour) => hour.replace(/\s+/g, ' ').trim())
    .filter(Boolean);

  return {
    ...existingAgency,
    name: agencyFormModel.name.trim(),
    phoneNumber: agencyFormModel.phoneNumber?.trim() || undefined,
    address: toGeo(agencyFormModel.address),
    openingHours: openingHours.length ? openingHours : undefined,
  };
};
