import { Contact } from '../../providers/api/dtos/contact';
import { SearchContactFormModel } from '../components/search-contact-form/SearchContactForm';

export function stringEquals(a?: string, b?: string): boolean {
  return !!(a && b && a !== b);
}

export function hasContactConflicts(contact: Contact, searchValues: SearchContactFormModel) {
  return (searchValues.email && searchValues.email !== contact.email)
    || (searchValues.phoneNumber && searchValues.phoneNumber !== contact.phoneNumber)
    || (searchValues.name && searchValues.name !== contact.name);
}
