/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, PropsWithChildren } from 'react';

import * as styles from './GenericPageLayoutWrapper.style';

export enum GenericPageLayoutWrapperWidth {
  SMALL = '40rem',
  MEDIUM = '60rem',
  LARGE = '80rem',
  FULL = '100%',
}

export type GenericPageLayoutWrapperProps = PropsWithChildren<{
  maxWidth?: GenericPageLayoutWrapperWidth,
  fitMaxAvailableHeight?: boolean,
}>;

const GenericPageLayoutWrapper: FunctionComponent<GenericPageLayoutWrapperProps> = ({
  maxWidth,
  fitMaxAvailableHeight,
  children,
}) => (
  <div
    css={styles.wrapper}
    width={maxWidth || GenericPageLayoutWrapperWidth.LARGE}
    $fitMaxAvailableHeight={fitMaxAvailableHeight}
  >
    {children}
  </div>
);

export default GenericPageLayoutWrapper;
