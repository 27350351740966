import useSWR, { SWRResponse, SWRConfiguration } from 'swr';
import { AxiosError } from 'axios';
import { Signature, Intent } from '../../../providers/api/dtos';
import { API_OFFERS_PATH } from '../../../providers/api/api';
import { getOfferSignatureStatus } from '../../../providers/api/offer/offerProvider';

export const OFFER_SIGNATURE_STATUS_RID = `${API_OFFERS_PATH}/wizard/signature-status`;

export default function useOfferSignatureStatus(
  intentId?: NonNullable<Intent['id']>,
  options?: SWRConfiguration<Signature, AxiosError>,
): SWRResponse<Signature, AxiosError> {
  const key = intentId ? [OFFER_SIGNATURE_STATUS_RID, intentId] : null;

  return useSWR(key, () => getOfferSignatureStatus(intentId!), options);
}
