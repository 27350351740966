import React from 'react';
import {
  useNotifications, FormHandlers, useModal, Action, Portal,
} from '@doveit/bricks';
import { Prospect, Rumor } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { updateProspect } from '../../../providers/api/prospect/prospectProvider';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { ReferenceType } from '../../../domain/types';
import EditAssignedAgentForm, { EditAssignedAgentFormModel } from '../../components/edit-assigned-agent-form/EditAssignedAgentForm';
import { updateRumor } from '../../../providers/api/rumor/rumorProvider';

export const EDIT_ASSIGNED_AGENT_SUCCESS_MESSAGE = "L'agente incaricato è stato sostituito con successo";
export const EDIT_ASSIGNED_AGENT_ERROR_MESSAGE = "Non è stato possibile sostituire l'agente incaricato";

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

type Reference =
| {
  referenceType: ReferenceType.PROSPECT,
  reference: Prospect,
} | {
  referenceType: ReferenceType.RUMOR,
  reference: Rumor,
};

interface EditAssignedAgentActionBaseProps extends React.AriaAttributes {
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (reference: Reference['reference']) => void,
}

export type EditAssignedAgentActionProps = EditAssignedAgentActionBaseProps & Reference;

const EditAssignedAgentAction: React.FC<EditAssignedAgentActionProps> = ({
  reference,
  referenceType,
  children,
  onSuccess,
  ...rest
}) => {
  const modal = useModal();
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const { data: agent } = useAgent(reference.agentId);

  const handleSubmit = React.useCallback(async (values: EditAssignedAgentFormModel) => {
    try {
      setIsSaving(true);

      let updatedReference: Reference['reference'] = reference;

      switch (referenceType) {
        case ReferenceType.PROSPECT:
          updatedReference = await updateProspect(reference.id!, {
            ...reference,
            agentId: values.assignee!.id!,
          });
          break;
        case ReferenceType.RUMOR:
          updatedReference = await updateRumor({
            ...reference,
            agentId: values.assignee!.id!,
          });
          break;
        default:
          break;
      }

      setIsSaving(false);
      modal.close();
      addSuccess(EDIT_ASSIGNED_AGENT_SUCCESS_MESSAGE);

      onSuccess?.(updatedReference);
    } catch (err) {
      setIsSaving(false);
      addError(EDIT_ASSIGNED_AGENT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, modal, onSuccess, reference, referenceType]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  if (!agent) {
    return null;
  }

  return (
    <>
      {children ? children({
        openEditModal: modal.open,
        isSaving,
      }) : (
        <Action
          label="Sostituisci agente incaricato"
          aria-label="Azione per sostituire l'agente incaricato"
          size="S"
          loading={isSaving}
          onClick={() => modal.open()}
          {...rest}
        />
      )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Sostituisci agente incaricato"
          aria-label="Modale per sostituire l'agente incaricato"
          footer={(
            <Action
              label="Sostituisci"
              color="primary"
              emphasis="high"
              aria-label="Azione per confermare la sostituzione dell'agente incaricato"
              onClick={submitForm}
            />
          )}
        >
          <EditAssignedAgentForm
            initialValues={{ assignee: agent }}
            onSubmit={handleSubmit}
            innerRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAssignedAgentAction;
