import React from 'react';
import {
  ActionIcon, DetailItemList, HStack, ICON_ACCOUNT_OUTLINE, ICON_EYE_OUTLINE, ICON_PHONE_OUTLINE, Spacing,
} from '@doveit/bricks';
import Card from '../../../components/card/Card';
import { Intent, PropertyPreview } from '../../../providers/api/dtos';
import IntentStatusBadge from '../../../intent/components/intent-status-badge/IntentStatusBadge';
import useContact from '../../../contact/hooks/use-contact/useContact';
import UserActions from '../../../components/user-actions/UserActions';
import CreateIntentWithAppointmentAction, { CreateIntentWithAppointmentActionProps } from '../../../intent/containers/create-intent-with-appointment-action/CreateIntentWithAppointmentAction';
import ContactMatchBlacklistAction, { ContactMatchBlacklistActionProps } from '../../../contact-match/containers/contact-match-blacklist-action/ContactMatchBlacklistAction';
import { BlacklistAction, WithRequiredKeys } from '../../../types';
import { IntentSource } from '../../../domain/types/intentSource';

export interface HiddenAwareIntent extends WithRequiredKeys<Intent, 'id'> {
  hidden: boolean,
}

export interface ViewMatchingIntentCardProps extends Pick<CreateIntentWithAppointmentActionProps, 'onIntentCreated' | 'onAppointmentCreated' | 'onAppointmentAborted'> {
  hiddenAwareIntent: HiddenAwareIntent,
  property: PropertyPreview,
  disableAddIntentAction?: boolean,
  disableContactMatchBlacklistAction?: boolean,
  onContactMatchBlacklistAction?: ContactMatchBlacklistActionProps['onSuccess'],
}

export const CREATE_WHATSAPP_EVENT_ERROR_MESSAGE = 'Non è stato possibile aprire WhatsApp';

const ViewMatchingIntentCard: React.FC<ViewMatchingIntentCardProps> = ({
  hiddenAwareIntent,
  property,
  disableAddIntentAction = false,
  disableContactMatchBlacklistAction = false,
  onContactMatchBlacklistAction,
  ...rest
}) => {
  const { data: contact } = useContact(hiddenAwareIntent.contactId);

  const goToIntentDetailPage = React.useCallback(() => {
    window.open(`/intents/${hiddenAwareIntent.id}`, '_blank');
  }, [hiddenAwareIntent.id]);

  return (
    <Card>
      <Card.Inline>
        <Card.Box>
          <DetailItemList>
            <DetailItemList.Item>
              <IntentStatusBadge status={hiddenAwareIntent.status} />
            </DetailItemList.Item>
            <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
              {contact?.name}
            </DetailItemList.Item>
            <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
              {contact?.phoneNumber}
            </DetailItemList.Item>
          </DetailItemList>
          {contact && (
            <Spacing margin={[100, 0, 0]}>
              <UserActions
                user={contact}
              />
            </Spacing>
          )}
        </Card.Box>
        <Card.ColumnDivider />
        <Card.Box fit>
          <HStack>
            <ActionIcon
              aria-label="Visualizza dettagli interesse"
              icon={{ path: ICON_EYE_OUTLINE }}
              label="Visualizza interesse"
              onClick={goToIntentDetailPage}
            />
            <CreateIntentWithAppointmentAction
              contactId={hiddenAwareIntent.contactId}
              property={property}
              source={IntentSource.MATCHING}
              disabled={disableAddIntentAction}
              {...rest}
            />
            <ContactMatchBlacklistAction
              contactId={hiddenAwareIntent.contactId}
              propertyId={property.id!}
              disabled={disableContactMatchBlacklistAction}
              onSuccess={onContactMatchBlacklistAction}
              action={hiddenAwareIntent.hidden ? BlacklistAction.REMOVE : BlacklistAction.ADD}
            />
          </HStack>
        </Card.Box>
      </Card.Inline>
    </Card>
  );
};

export default ViewMatchingIntentCard;
