import {
  compareDesc, format, isFuture, isPast, isToday, isTomorrow,
  startOfDay,
} from 'date-fns';
import { formatDate } from '@doveit/hammer';
import {
  Aggregator,
  AppointmentStatus, IntentStatus, OfferStatus, ReferenceType,
  Status,
} from '../../../domain/types';
import { getContactAppointments } from '../../../providers/api/contact/contactProvider';
import { Agent } from '../../../providers/api/dtos/agent';
import { Intent } from '../../../providers/api/dtos/intent';
import { getProperty } from '../../../providers/api/property/propertyProvider';
import { WhatsappMessage } from '../SendWhatsappAction';
import { getOffersByPropertyId } from '../../../providers/api/offer/offerProvider';
import { isNonResidential } from '../../../property/utils';
import { getAgentName } from '../../../agent/utils/utils';

export function intentMessagesProvider(intent: Intent, agent?: Agent): () => Promise<WhatsappMessage[]> {
  return async () => {
    const agentName = agent?.name ? getAgentName(agent) : '<nome agente>';
    const agentMobile = agent?.mobile.work ?? '<numero telefono agente>';

    if (intent.status === IntentStatus.KO_DUPLICATE) {
      return [];
    }

    const filters = {
      referenceId: intent.id!,
      referenceType: ReferenceType.INTENT,
    };

    const property = await getProperty(intent.propertyId);
    if (!property.geo) {
      return [];
    }
    const doveLink = property.publishedOn.some((publish) => publish.aggregator === Aggregator.DOVE_IT)
      && [Status.ANTEPRIMA, Status.LIVE, Status.PROPOSTA].includes(property.status)
      && !isNonResidential(property.propertyType) ? `https://www.dove.it/dv/${property.id}` : undefined;

    const otherOffers = await getOffersByPropertyId(property.id!)
      .then((offers) => offers.filter((offer) => offer.intentId !== intent.id));
    const otherAcceptedOffer = otherOffers.filter((offer) => [OfferStatus.ACCEPTED, OfferStatus.IN_PROGRESS].includes(offer.status));
    const otherRefusedOffer = otherOffers.filter((offer) => OfferStatus.REFUSED === offer.status);
    const messages: WhatsappMessage[] = [];

    const incipit = `Salve, sono ${agentName} di Dove.it.`;

    if (intent.status === IntentStatus.IN_PROGRESS) {
      const intentAppointments = await getContactAppointments(intent.contactId, filters); // default 50 appointments
      const existsFutureTodo = intentAppointments.content
        .filter((appointment) => isFuture(new Date(appointment.startDate)))
        .sort((a, b) => compareDesc(new Date(a.startDate), new Date(b.startDate)))
        .find((appointment) => appointment.status === AppointmentStatus.TODO);

      const existsPastDone = intentAppointments.content
        .filter((appointment) => isPast(startOfDay(new Date(appointment.startDate))))
        .find((appointment) => appointment.status === AppointmentStatus.DONE);

      if (intentAppointments.content.length === 0 && doveLink) {
        messages.push({
          id: '1',
          title: 'Organizzazione primo appuntamento via whatsapp',
          text: `${incipit} La contatto per fornirle ulteriori informazioni o chiarimenti sull'immobile situato in ${property.geo?.normalizedAddress}, che può visualizzare al seguente link: ${doveLink}.<br /><br />Resto a disposizione per organizzare un'eventuale visita dell'immobile.<br /><br />Cordiali saluti`,
        }, {
          id: '2',
          title: 'Organizzazione primo appuntamento con telefonata',
          text: `${incipit} La contatto in riferimento alla sua richiesta riguardante l'immobile situato in ${property.geo?.normalizedAddress}. Può trovare ulteriori dettagli al seguente link: ${doveLink}.<br /><br />Quando sarebbe disponibile per una chiamata telefonica?<br /><br />Cordiali saluti`,
        }, {
          id: '3',
          title: 'Organizzazione primo appuntamento con mancata risposta',
          text: `${incipit} Ho cercato di contattarla telefonicamente in merito alla sua richiesta sull'immobile situato in ${property.geo?.normalizedAddress}. Può trovare ulteriori dettagli al seguente link: ${doveLink}.<br /><br />Per maggiori informazioni o per fissare un appuntamento, può richiamarmi al numero ${agentMobile} o, se preferisce, possiamo continuare la conversazione qui.<br /><br />Cordiali saluti`,
        }, {
          id: '4',
          title: 'Invio informazioni immobile',
          text: `${incipit} Le scrivo per fornirle le principali informazioni sull'immobile situato in ${property.geo?.normalizedAddress}. Può consultare ulteriori dettagli al seguente link: ${doveLink}.<br /><br />Di seguito, un riepilogo delle caratteristiche principali: ${property.abstract}<br /><br />Resto a disposizione per fissare un appuntamento, qualora fosse interessato a visionare l'immobile.<br /><br />Cordiali saluti`,
        });
      }

      if (existsFutureTodo) {
        const appointmentStartDate = new Date(existsFutureTodo.startDate);
        const hour = format(appointmentStartDate, 'HH:mm');
        let appointmentDate = `del ${formatDate(appointmentStartDate, { time: true })}`;

        if (isToday(appointmentStartDate)) {
          appointmentDate = `di oggi alle ore ${hour}`;
        }
        if (isTomorrow(appointmentStartDate)) {
          appointmentDate = `di domani alle ore ${hour}`;
        }
        if (doveLink) {
          messages.push({
            id: '5',
            title: 'Conferma appuntamento',
            text: `${incipit} Volevo confermarle l'appuntamento ${appointmentDate} per visitare l'immobile in ${property.geo?.normalizedAddress}, ${doveLink}.<br /><br />Cordiali saluti`,
          }, {
            id: '6',
            title: 'Promemoria appuntamento',
            text: `${incipit} Le ricordo l'appuntamento ${appointmentDate} per visitare l'immobile in ${property.geo?.normalizedAddress}, ${doveLink}.<br /><br />Cordiali saluti`,
          });
        }
        messages.push(
          {
            id: '7',
            title: 'Annullamento appuntamento generico',
            text: `${incipit} Volevo informarla che sono costretto ad annullare l'appuntamento ${appointmentDate} per l'immobile in ${property.geo?.normalizedAddress} a causa di un imprevisto.<br /><br />Sarà mia premura ricontattarla al più presto per concordare una nuova data. Mi scuso per il disagio e la ringrazio per la comprensione.<br /><br />Cordiali saluti`,
          },
        );
        if (otherAcceptedOffer.length > 0) {
          messages.push({
            id: '8',
            title: 'Annullamento appuntamento per trattativa',
            text: `${incipit} La contatto per informarla che, purtroppo, sono costretto ad annullare l'appuntamento fissato per il ${appointmentDate.replace(/(di |del )/gm, '')} relativo all'immobile in ${property.geo?.normalizedAddress}, attualmente in fase di trattativa.<br />Sarà mia premura ricontattarla tempestivamente nel caso in cui l'immobile torni disponibile.<br /><br />Cordiali saluti`,
          });
        }
      }

      if (existsPastDone && doveLink) {
        messages.push({
          id: '9',
          title: 'Feedback appuntamento',
          text: `${incipit} La contatto in merito alla visita dell'immobile situato in ${property.geo?.normalizedAddress}. Può trovare ulteriori dettagli al seguente link: ${doveLink}.<br /><br />Ha trovato l'immobile di suo interesse? Sono a sua disposizione per qualsiasi informazione o chiarimento che possa aiutarla a valutare al meglio la proprietà.<br /><br />Cordiali saluti`,
        });
        if (otherRefusedOffer.length > 0 && otherAcceptedOffer.length === 0) {
          messages.push({
            id: '10',
            title: 'Ricontatto per trattativa fallita appuntamento',
            text: `${incipit} La contatto in merito alla visita dell'immobile situato in ${property.geo?.normalizedAddress}, ${doveLink}.<br /><br />La trattativa non è andata a buon fine e mi chiedevo se l'immobile fosse ancora di suo interesse. In caso positivo, non esiti a contattarmi per fissare una visita.<br /><br />Cordiali saluti`,
          });
        }
      }
    }

    if (otherAcceptedOffer.length > 0 && doveLink) {
      messages.push({
        id: '11',
        title: 'Proposta immobili simili',
        text: `${incipit} La contatto in merito alla visita dell'immobile situato in ${property.geo?.normalizedAddress}, ${doveLink}.<br /><br /> Purtroppo, l'immobile è attualmente in trattativa.<br /><br />Se è interessato, posso proporle alcuni immobili simili. Resto in attesa di una sua risposta.<br /><br />Cordiali saluti`,
      });
    }

    return messages;
  };
}
