import * as Yup from 'yup';

export default Yup.object({
  date: Yup.string().required('La data è obbligatoria'),
  time: Yup.string().required('L\'orario è obbligatorio'),
  notes: Yup.string().required('Le note sono obbligatorie'),
}).test({
  test: (values: any) => {
    const datetime = new Date(`${values.date} ${values.time}`);

    return datetime.getTime() <= Date.now() ? new Yup.ValidationError(
      'La data e ora devono essere nel futuro',
      datetime,
      'date',
    ) : true;
  },
});
