import {
  ActionIcon,
  FontWeight, HStack, ICON_EYE_OUTLINE,
  Stack, Text,
  UserPreviewSkeleton,
} from '@doveit/bricks';
import React from 'react';
import { AgentPreview } from '../../../agent/components';
import DateViewer from '../../../components/date-viewer/DateViewer';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import { ReferenceType } from '../../../domain/types';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { Property } from '../../../providers/api/dtos';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import PropertyAvatar from '../../components/property-avatar/PropertyAvatar';
import ViewPropertyPopoverPreview from '../view-property-popover-preview/ViewPropertyPopoverPreview';
import UpdatePropertyStatus from '../update-property-status/UpdatePropertyStatus';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';

export interface ViewPropertyPreviewTableRowProps {
  property: Property,
  onPropertyUpdate?: (updatedProperty: Property) => void,
}

const ViewPropertyPreviewTableRow: React.FC<ViewPropertyPreviewTableRowProps> = ({
  property,
  onPropertyUpdate,
}) => {
  const {
    data: agent,
    isLoading: isAgentLoading,
    error: agentError,
  } = useAgent(property.agentEmail);

  const goToPropertyPage = React.useCallback(() => {
    goToDetailPage(property.id!, ReferenceType.PROPERTY);
  }, [property.id]);

  return (
    <SimpleTable.Row
      data-testid={`property-preview-table-row-${property.id}`}
      aria-label="Informazioni immobile"
    >
      <SimpleTable.Cell
        aria-label="Geo"
        width="15rem"
      >
        <ViewPropertyPopoverPreview
          property={property}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <PropertyAvatar size="S" />
              <Text.BodySmall
                fontWeight={FontWeight.MEDIUM}
                css={setVerticalEllipsis(2)}
              >
                {property.geo?.normalizedAddress}
              </Text.BodySmall>
            </HStack>
          )}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Stato">
        <UpdatePropertyStatus
          property={property}
          size="XS"
          onSuccess={onPropertyUpdate}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Agente">
        {isAgentLoading && (
          <UserPreviewSkeleton
            aria-label="Agente in caricamento"
            size="S"
          />
        )}
        {agentError && (
          <AgentPreview
            name="Non disponibile"
            size="S"
          />
        )}
        {agent && (
          <ViewAgentPopoverPreview
            agent={agent}
            trigger={(
              <AgentPreview
                {...toAgentPreviewProps(agent)}
                size="S"
              />
            )}
          />
        )}
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Data di creazione">
        <Stack gap={50}>
          <Text.Mini transform="uppercase">
            Aggiunto
          </Text.Mini>
          <Text.Caption>
            <DateViewer
              stringDate={property.createdAt!}
              checkIfToday
            />
          </Text.Caption>
        </Stack>
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Azioni"
        align="right"
      >
        <ActionIcon
          aria-label="Visualizza immobile"
          label="Visualizza"
          size="S"
          icon={{ path: ICON_EYE_OUTLINE }}
          onClick={goToPropertyPage}
        />
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default ViewPropertyPreviewTableRow;
