import { API_ROOMS_PATH, API_PROPERTIES_PATH, getClient } from '../api';
import { Room } from '../dtos';

export async function createRoom(room: Room): Promise<Room> {
  const client = await getClient();
  const { data } = await client.post<Room>(API_ROOMS_PATH, room);
  return data;
}

export async function getRoom(roomId: string | number): Promise<Room> {
  const client = await getClient();
  const { data } = await client.get<Room>(`${API_ROOMS_PATH}/${roomId}`);
  return data;
}

export async function updateRoom(room: Room): Promise<Room> {
  const client = await getClient();
  const { data } = await client.put<Room>(`${API_ROOMS_PATH}/${room.id}`, room);
  return data;
}

export async function deleteRoom(roomId: string | number): Promise<{ id: number | string }> {
  const client = await getClient();
  const { data } = await client.delete(`${API_ROOMS_PATH}/${roomId}`);
  return data;
}

export async function getRoomsByPropertyId(propertyId: number): Promise<Room[]> {
  const client = await getClient();
  const { data } = await client.get(`${API_PROPERTIES_PATH}/${propertyId}/rooms`);
  return data;
}
