import {
  Action,
  Badge, HStack, ICON_MAGNIFY, Message, Spacing, Stack, Typography,
} from '@doveit/bricks';
import React, {
  FunctionComponent, useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InlineCardSkeleton } from '../../../components/card/Card.skeleton';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import { JobApplicationStatus } from '../../../domain/types';
import usePagination from '../../../hooks/use-pagination/usePagination';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { NavigationItem, UserRole } from '../../../types';
import { DEFAULT_PAGINATION_SIZE, removeQueryParams } from '../../../utils/querystring/querystring';
import useJobApplications from '../../hooks/use-job-applications/useJobApplications';
import { JobApplicationFilterKeys, JobApplicationSection } from '../../types';
import ViewJobApplicationCard from '../../containers/view-job-application-card/ViewJobApplicationCard';
import { SortOrder, SortParams } from '../../../providers/pagination';
import { JobApplication } from '../../../providers/api/dtos';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import getShortAgentNameFromEmail from '../../../utils/agent/getShortAgentNameFromEmail';
import FilterJobApplications from '../../containers/filter-job-applications/FilterJobApplications';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import useUserIsOnlyAreaManager from '../../../hooks/use-user-is-only-area-manager/useUserIsOnlyAreaManager';
import useFilters from '../../../hooks/use-filters/useFilters';
import { getJobApplicationFilterMapper } from '../../utils/filterMapper';

export interface NavigationJobApplicationProcessItem extends NavigationItem {
  match?: RegExp,
}

export const JOB_APPLICATIONS_ERROR_MESSAGE = 'Errore durante il caricamento delle candidature';
export const NO_JOB_APPLICATIONS_MESSAGE = 'Non sono presenti candidature';

const jobApplicationProcessNavigation: NavigationJobApplicationProcessItem[] = [
  {
    id: JobApplicationSection.TODO,
    label: 'Da fare',
    path: `/job-applications/process/${JobApplicationSection.TODO}`,
  },
  {
    id: JobApplicationSection.ASSIGNED,
    label: 'Assegnate',
    path: `/job-applications/process/${JobApplicationSection.ASSIGNED}`,
  },
  {
    id: JobApplicationSection.OFFER,
    label: 'Proposte',
    path: `/job-applications/process/${JobApplicationSection.OFFER}`,
  },
  {
    id: JobApplicationSection.HIRED,
    label: 'Assunzioni',
    path: `/job-applications/process/${JobApplicationSection.HIRED}`,
  },
  {
    id: JobApplicationSection.ARCHIVED,
    label: 'Archiviate',
    path: `/job-applications/process/${JobApplicationSection.ARCHIVED}`,
  },
];

const mapSectionToStatus: { [key in JobApplicationSection]: JobApplicationStatus | JobApplicationStatus[] } = {
  [JobApplicationSection.TODO]: JobApplicationStatus.IN_PROGRESS,
  [JobApplicationSection.ASSIGNED]: JobApplicationStatus.ASSIGNED,
  [JobApplicationSection.OFFER]: JobApplicationStatus.OFFER,
  [JobApplicationSection.HIRED]: JobApplicationStatus.HIRED,
  [JobApplicationSection.ARCHIVED]: [
    JobApplicationStatus.KO_DUPLICATE,
    JobApplicationStatus.KO_GEO,
    JobApplicationStatus.KO_NOT_ELIGIBLE,
    JobApplicationStatus.KO_NOT_INTERESTED,
    JobApplicationStatus.KO_OFFER_REFUSED,
  ],
};

const mapSectionToSortingCriteria: { [key in JobApplicationSection]: SortParams | undefined } = {
  [JobApplicationSection.TODO]: { createdAt: SortOrder.DESC },
  [JobApplicationSection.ASSIGNED]: { 'assignment.date': SortOrder.DESC },
  [JobApplicationSection.OFFER]: { updatedAt: SortOrder.DESC },
  [JobApplicationSection.HIRED]: { updatedAt: SortOrder.DESC },
  [JobApplicationSection.ARCHIVED]: { updatedAt: SortOrder.DESC },
};

const JobApplicationProcessPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const section: JobApplicationSection = React.useMemo(() => {
    if (!params.section) {
      return JobApplicationSection.TODO;
    }

    return params.section as JobApplicationSection;
  }, [params]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const { page, goToPage } = usePagination('page', scrollRef);
  const { filters, removeFilter } = useFilters(getJobApplicationFilterMapper());
  const { user, userHasAnyRole } = useRBAC();
  const isOnlyAreaManager = useUserIsOnlyAreaManager();

  const userIsHrOrAdmin = React.useMemo(() => userHasAnyRole([UserRole.HR, UserRole.ADMIN]), [userHasAnyRole]);

  const assigneeIdOrEmail = useMemo(() => {
    if (isOnlyAreaManager) {
      return user.email;
    }

    return filters.assignee;
  }, [filters.assignee, isOnlyAreaManager, user?.email]);

  const { data: agentFilter } = useAgent(assigneeIdOrEmail);

  const jobApplicationsExtendedFilters = useMemo(() => {
    const basicFilters = {
      status: mapSectionToStatus[section],
      ...filters,
    };

    if (isOnlyAreaManager) {
      return agentFilter ? {
        ...basicFilters,
        assignee: agentFilter.id ? agentFilter.id : undefined,
      } : null;
    }

    return basicFilters;
  }, [agentFilter, filters, isOnlyAreaManager, section]);

  const { data: jobApplications, error: jobApplicationsError, mutate: mutateJobApplications } = useJobApplications(
    jobApplicationsExtendedFilters,
    {
      size: DEFAULT_PAGINATION_SIZE,
      page,
      sort: mapSectionToSortingCriteria[section],
    },
  );

  const filterChips = useMemo(() => Object
    .entries(filters)
    .filter(([_, value]) => !!value)
    .map(([key, value]) => {
      if (key === 'assignee' && agentFilter) {
        return {
          filterKey: 'assignee',
          filterValue: getShortAgentNameFromEmail(agentFilter.email.work!),
        };
      }

      if (key === 'license') {
        return {
          filterKey: 'license',
          filterValue: 'Patentino',
        };
      }
      if (key === 'department') {
        return {
          filterKey: 'department',
          filterValue: value.toString().charAt(0).toUpperCase() + value.toString().substring(1).toLowerCase(),
        };
      }

      return {
        filterKey: key,
        filterValue: value,
      };
    }), [agentFilter, filters]);

  const isItemActive = useCallback(
    (item: NavigationJobApplicationProcessItem) => location.pathname === item.path,
    [location],
  );

  const navigateTo = useCallback((path: string) => () => {
    navigate(`${path}?${removeQueryParams(location.search, 'page')}`);
  }, [navigate, location]);

  const onViewJobApplication = useCallback((jobApplication: JobApplication) => () => {
    navigate(`/job-applications/${jobApplication.id}`);
  }, [navigate]);

  const onSearchActionClick = useCallback(() => {
    navigate('/job-applications/search');
  }, [navigate]);

  const onJobApplicationUpdate = useCallback(() => {
    mutateJobApplications();
  }, [mutateJobApplications]);

  const onRemoveFilter = useCallback((filterKey: JobApplicationFilterKeys) => () => {
    removeFilter(filterKey);
  }, [removeFilter]);

  const processNavigationItems = useMemo(
    () => jobApplicationProcessNavigation.filter((item) => !isOnlyAreaManager || item.id !== JobApplicationSection.TODO),
    [isOnlyAreaManager],
  );

  const isSectionIncluded = useMemo(() => processNavigationItems
    .map((item) => item.id)
    .includes(section), [processNavigationItems, section]);

  useEffect(() => {
    if (!isSectionIncluded) {
      navigate(`/job-applications/process/${processNavigationItems[0].id}`);
    }
  }, [navigate, isSectionIncluded, processNavigationItems]);

  const tabItems = useMemo(() => processNavigationItems.map((item) => ({
    id: item.id,
    label: item.label,
    active: isItemActive(item),
    onClick: navigateTo(item.path!),
  })), [isItemActive, navigateTo, processNavigationItems]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content
        title="Gestione candidature"
        headerSlot={(
          <HStack>
            <Action
              iconLeft={{ path: ICON_MAGNIFY }}
              emphasis="high"
              label="Cerca"
              onClick={onSearchActionClick}
            />
            {!isOnlyAreaManager && (
              <FilterJobApplications />
            )}
          </HStack>
        )}
      >
        {filterChips.length > 0 && (
          <Spacing margin={[0, 0, 400]}>
            <Spacing margin={[100, 0, 0]}>
              <HStack>
                <Typography.BODY><strong>Filtri attivi:</strong></Typography.BODY>
                {filterChips.map(({ filterKey, filterValue }) => (
                  <Badge
                    key={filterKey}
                    label={filterValue?.toString()}
                    onRemove={onRemoveFilter(filterKey as JobApplicationFilterKeys)}
                  />
                ))}
              </HStack>
            </Spacing>
          </Spacing>
        )}
        <GenericPageLayout.Tabs
          innerRef={scrollRef}
          items={tabItems}
        />
        <GenericPageLayout.InnerContent>
          <Spacing margin={[200, 0, 0]}>
            {!jobApplications && !jobApplicationsError && (
              <Stack>
                <InlineCardSkeleton />
                <InlineCardSkeleton />
                <InlineCardSkeleton />
                <InlineCardSkeleton />
              </Stack>
            )}
          </Spacing>

          {!jobApplications && jobApplicationsError && (
            <Message
              type="critical"
              message={JOB_APPLICATIONS_ERROR_MESSAGE}
            />
          )}

          {jobApplications && (
            <PaginatedList
              number={jobApplications.number}
              totalPages={jobApplications.totalPages}
              size={jobApplications.size}
              totalElements={jobApplications.totalElements}
              emptyResultMessage={NO_JOB_APPLICATIONS_MESSAGE}
              goToPage={goToPage}
            >
              {jobApplications.content.map((jobApplication) => (
                <ViewJobApplicationCard
                  key={jobApplication.id}
                  jobApplication={jobApplication}
                  hideAssignAction={isOnlyAreaManager}
                  hideOfferAction={isOnlyAreaManager}
                  hideUpsertReminderAction={!userIsHrOrAdmin}
                  hideHireAction={isOnlyAreaManager}
                  onView={onViewJobApplication(jobApplication)}
                  onArchive={onJobApplicationUpdate}
                  onAssign={onJobApplicationUpdate}
                  onOffer={onJobApplicationUpdate}
                  onHire={onJobApplicationUpdate}
                />
              ))}
            </PaginatedList>
          )}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default JobApplicationProcessPage;
