import { AssignmentWizardData, Ownership } from '../../../../../providers/api/dtos';
import { PropertyOwnershipTypeFormModel } from '../../property-ownership-type-form/PropertyOwnershipTypeForm';

export const toAssignmentWizardPropertyType = (
  initialData: AssignmentWizardData,
): PropertyOwnershipTypeFormModel => ({
  type: typeof initialData?.property?.ownership?.type !== 'undefined'
    ? initialData?.property?.ownership?.type
    : '',
  details: typeof initialData?.property?.ownership?.details !== 'undefined'
    ? initialData?.property?.ownership?.details
    : '',
});

export const toAssignmentWizardData = (
  existingData: AssignmentWizardData,
  values: PropertyOwnershipTypeFormModel,
): AssignmentWizardData => {
  const {
    type,
    details,
  } = values;

  const newOwnershipValues: Ownership = {
    type,
    details,
  };

  const newValues: AssignmentWizardData = {
    ...existingData,
    isDigitallySigned: false,
    property: {
      ...existingData.property,
      ownership: newOwnershipValues,
    },
  };

  return newValues;
};
