import {
  ThemedFunctor,
  SpacingName,
  getSpacing,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const basic: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.padding(theme, [SpacingName.SMALLEST, SpacingName.SMALLER])}
`;
