/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Contact } from '../../../providers/api/dtos';
import ContactLeadsSummaryGroup from './leads/contact-leads-summary-group/ContactLeadsSummaryGroup';
import ContactSearchCriteriaSummaryGroup from './search-criteria/contact-search-criteria-summary-group/ContactSearchCriteriaSummaryGroup';
import ContactIntentsSummaryGroup from './intents/contact-intents-summary-group/ContactIntentsSummaryGroup';
import ContactPropertiesSummaryGroup from './properties/contact-properties-summary-group/ContactPropertiesSummaryGroup';
import * as styles from './ViewContactActivitiesSummary.style';
import ContactProspectsSummaryGroup from './prospects/contact-prospects-summary-group/ContactProspectsSummaryGroup';

export interface ViewContactActivitiesSummaryProps {
  contactId: NonNullable<Contact['id']>
}

const ViewContactActivitiesSummary: React.FC<ViewContactActivitiesSummaryProps> = ({
  contactId,
}) => (
  <div css={styles.activitiesSummaryWrapper}>
    <ContactLeadsSummaryGroup contactId={contactId} />
    <ContactIntentsSummaryGroup contactId={contactId} />
    <ContactSearchCriteriaSummaryGroup contactId={contactId} />
    <ContactPropertiesSummaryGroup contactId={contactId} />
    <ContactProspectsSummaryGroup contactId={contactId} />
  </div>
);

export default ViewContactActivitiesSummary;
