import React from 'react';
import {
  Action, Grid, Portal, useModal,
} from '@doveit/bricks';
import { useNavigate } from 'react-router-dom';
import { Agency, Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import AgencyPreview from '../../../agency/components/agency-preview/AgencyPreview';

interface ChildFnProps {
  viewAgencies: VoidFunction,
}

export interface ViewAgentAgenciesActionProps {
  agent: Agent,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const ViewAgentAgenciesAction: React.FC<ViewAgentAgenciesActionProps> = ({
  agent,
  children,
}) => {
  const navigate = useNavigate();
  const modal = useModal<Agent>();

  const viewAgencies = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const goToAgencyPage = React.useCallback((id: Agency['id']) => () => {
    navigate(`/admin/agencies/${id}`);
  }, [navigate]);

  if (!agent.agencies || agent.agencies.length === 0) {
    return null;
  }

  return (
    <>
      {children
        ? children({ viewAgencies })
        : (
          <Action
            aria-label="Visualizza le agenzie dell'agente"
            label="Vedi"
            size="S"
            onClick={viewAgencies}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Agenzie"
        >
          <Grid
            aria-label="Agenzie dell'agente"
            gutter={150}
          >
            {agent.agencies.map(({ id, name, franchising }) => (
              <Grid.Unit key={id} size={{ MD: 1 / 2 }}>
                <AgencyPreview
                  name={name}
                  franchising={franchising}
                  onClick={goToAgencyPage(id)}
                />
              </Grid.Unit>
            ))}
          </Grid>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ViewAgentAgenciesAction;
