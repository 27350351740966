import { Agent, Geo } from '../../../providers/api/dtos';
import { EditAgentRegistryFormModel } from '../../components/edit-agent-registry-form/EditAgentRegistryForm';

const toGeoFormModel = (geo?: Geo): EditAgentRegistryFormModel['residenceAddress'] => ({
  administrativeAreaLevelOne: geo?.administrativeAreaLevelOne || '',
  administrativeAreaLevelTwo: geo?.administrativeAreaLevelTwo || '',
  locality: geo?.locality || '',
  plateCode: geo?.plateCode || '',
  postalCode: geo?.postalCode || '',
  route: geo?.route || '',
  streetNumber: geo?.streetNumber || '',
  normalizedAddress: geo?.normalizedAddress ? {
    placeId: '',
    description: geo.normalizedAddress,
  } : undefined,
  latitude: geo?.latitude ? `${geo.latitude}` : '',
  longitude: geo?.longitude ? `${geo.longitude}` : '',
});

const toGeo = (geoFormModel: EditAgentRegistryFormModel['residenceAddress']): Geo => ({
  administrativeAreaLevelOne: geoFormModel.administrativeAreaLevelOne?.trim() || undefined,
  administrativeAreaLevelTwo: geoFormModel.administrativeAreaLevelTwo?.trim() || undefined,
  latitude: geoFormModel.latitude?.trim() ? Number(geoFormModel.latitude.trim()) : undefined,
  longitude: geoFormModel.longitude?.trim() ? Number(geoFormModel.longitude.trim()) : undefined,
  locality: geoFormModel.locality?.trim() || undefined,
  plateCode: geoFormModel.plateCode?.trim() || undefined,
  postalCode: geoFormModel.postalCode?.trim() || undefined,
  route: geoFormModel.route?.trim() || undefined,
  streetNumber: geoFormModel.streetNumber?.trim() || undefined,
});

export const agentToEditAgentRegistryFormModel = (agent: Agent): EditAgentRegistryFormModel => ({
  fiscalCode: agent.fiscalCode || '',
  pecEmail: agent.email.pec || '',
  personalEmail: agent.email.personal || '',
  workEmail: agent.email.work || '',
  personalMobile: agent.mobile.personal || '',
  workMobile: agent.mobile.work || '',
  personalName: agent.realName?.name || '',
  workName: agent.name.name || '',
  personalSurname: agent.realName?.surname || '',
  workSurname: agent.name.surname || '',
  residenceAddress: toGeoFormModel(agent.address?.residence),
  shippingAddress: toGeoFormModel(agent.address?.shipping),
});

export const editAgentRegistryFormModelToAgent = (agentFormModel: EditAgentRegistryFormModel, existingAgent: Agent): Agent => ({
  ...existingAgent,
  name: {
    name: agentFormModel.workName.trim(),
    surname: agentFormModel.workSurname.trim(),
  },
  realName: (agentFormModel.personalName.trim() || agentFormModel.personalSurname.trim()) ? {
    name: agentFormModel.personalName.trim(),
    surname: agentFormModel.personalSurname.trim(),
  } : undefined,
  email: {
    work: agentFormModel.workEmail.trim() || undefined,
    personal: agentFormModel.personalEmail.trim(),
    pec: agentFormModel.pecEmail.trim() || undefined,
  },
  mobile: {
    personal: agentFormModel.personalMobile.trim(),
    work: agentFormModel.workMobile.trim() || undefined,
  },
  fiscalCode: agentFormModel.fiscalCode.trim() || undefined,
  address: {
    residence: toGeo(agentFormModel.residenceAddress),
    shipping: toGeo(agentFormModel.shippingAddress),
  },
});
