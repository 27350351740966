/* eslint-disable react/no-unknown-property */
import {
  Action,
  ActionDropDown,
  ActionIconDropdown,
  CardSkeleton,
  HStack,
  ICON_CHECK,
  ICON_CUSTOM_SORT_PLUS,
  ICON_SORT,
  Message,
  Select,
  Spacing,
  Stack,
  Text,
} from '@doveit/bricks';
import React from 'react';
import SearchByQueryAction from '../../../components/search-by-query-action/SearchByQueryAction';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import useFilters from '../../../hooks/use-filters/useFilters';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import CreateIntentAction from '../../containers/create-intent-action/CreateIntentAction';
import ViewSerpIntentCard from '../../containers/view-serp-intent-card/ViewSerpIntentCard';
import ViewSerpIntentRow from '../../containers/view-serp-intent-row/ViewSerpIntentRow';
import { useSerpIntent } from '../../hooks/use-serp-intent/useSerpIntent';
import AgentFilterAction from '../../../components/agent-filter-action/AgentFilterAction';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import { useAgentFilter } from '../../../hooks/agent-filter/useAgentFilter';
import usePagination from '../../../hooks/use-pagination/usePagination';
import {
  ALL_INTENTS_FILTER_OPTION,
  DEFAULT_FILTER_OPTION, DEFAULT_SORT_OPTION, EMPTY_SERP_INTENTS, FILTER_OPTIONS, FILTERS_MAPPER, GROUPED_FILTER_OPTIONS, MOST_RECENT_CREATION_SORT_OPTION,
  SERP_INTENTS_ERROR_MESSAGE, SORT_OPTIONS, SORT_OPTIONS_PER_FILTER,
} from './constants';
import { IntentSerpPageFilterKeys } from './types';
import getShortAgentName from '../../../utils/agent/getShortAgentName';
import SerpFiltersSummary from '../../../components/serp-filters-summary/SerpFiltersSummary';

const IntentSerpPage: React.FC = () => {
  const isDesktop = useIsDevice('desktop');
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const { page, paginationQueryParam, goToPage } = usePagination('page', scrollRef);
  const {
    filters, addFilters, removeFilter, removeFilters,
  } = useFilters(FILTERS_MAPPER);

  const sortOption = React.useMemo(() => {
    if (filters.sort && filters.sort !== DEFAULT_SORT_OPTION.key) {
      return SORT_OPTIONS.find(({ key }) => key === filters.sort);
    }
    if (filters.filter) {
      return SORT_OPTIONS_PER_FILTER[filters.filter!];
    }
    return MOST_RECENT_CREATION_SORT_OPTION;
  }, [filters.filter, filters.sort]);

  const { agentEmails: agents, isExplicit: isAgentFilterExplicit, replaceFilter: replaceAgentFilter } = useAgentFilter(paginationQueryParam, getShortAgentName);
  const agentEmail = React.useMemo(() => agents.map((it) => it.email || ''), [agents]);

  const {
    data: serpIntents,
    isLoading: isSerpIntentsLoading,
    error: serpIntentsError,
    mutate: mutateSerpIntents,
  } = useSerpIntent({
    q: filters.q,
    agentEmail,
    ...(FILTER_OPTIONS.find(({
      key,
    }) => key === filters.filter)?.value),
  }, {
    page,
    sort: sortOption?.value,
  });

  const filterLabel = React.useMemo(() => {
    if (filters.filter === ALL_INTENTS_FILTER_OPTION.key) {
      return '';
    }

    const filterObject = FILTER_OPTIONS.find((option) => option.key === filters.filter);

    return filterObject?.label.long.toLowerCase();
  }, [filters.filter]);

  const handleSerpIntentsUpdate = React.useCallback(async () => {
    await mutateSerpIntents();
  }, [mutateSerpIntents]);

  const handleSearch = React.useCallback((query: string) => {
    if (query) {
      addFilters({ q: query });
      removeFilter(paginationQueryParam);
    } else {
      removeFilters(['q', paginationQueryParam]);
    }
  }, [addFilters, paginationQueryParam, removeFilter, removeFilters]);

  const handleBadgeClick = React.useCallback((badgeFilter: IntentSerpPageFilterKeys) => () => {
    removeFilter(paginationQueryParam);
    addFilters({ filter: badgeFilter });
  }, [addFilters, paginationQueryParam, removeFilter]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Bar>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          gap={100}
        >
          <div>
            {isDesktop && (
              <HStack aria-label="Filtri (desktop)" gap={150}>
                <Action
                  label={DEFAULT_FILTER_OPTION.label.short}
                  aria-label="Interessi non visti"
                  onClick={filters.filter !== DEFAULT_FILTER_OPTION.key
                    ? handleBadgeClick(DEFAULT_FILTER_OPTION.key)
                    : undefined}
                  color={filters.filter !== DEFAULT_FILTER_OPTION.key
                    ? 'neutral'
                    : 'primary'}
                />

                {GROUPED_FILTER_OPTIONS.map(({ label, options }) => (
                  <ActionDropDown
                    key={label}
                    label={label}
                    aria-label={`Filtra interessi per ${label}`}
                    emphasis="low"
                    color={options.some(({ key }) => key === filters.filter) ? 'primary' : 'neutral'}
                    options={options.map(({ key, label: optionLabel }) => ({
                      key,
                      label: optionLabel.short,
                      color: filters.filter !== key ? 'neutral' : 'primary',
                      iconRight: filters.filter !== key ? undefined : { path: ICON_CHECK },
                      onClick: filters.filter !== key ? handleBadgeClick(key) : undefined,
                    }))}
                  />
                ))}

                <Action
                  label={ALL_INTENTS_FILTER_OPTION.label.short}
                  aria-label="Tutti gli interessi"
                  onClick={filters.filter !== ALL_INTENTS_FILTER_OPTION.key
                    ? handleBadgeClick(ALL_INTENTS_FILTER_OPTION.key)
                    : undefined}
                  color={filters.filter !== ALL_INTENTS_FILTER_OPTION.key
                    ? 'neutral'
                    : 'primary'}
                />
              </HStack>
            )}
            {!isDesktop && (
              <Select
                value={filters.filter}
                aria-label="Seleziona un filtro dell'interesse (mobile)"
                options={FILTER_OPTIONS.map(({ label, key }) => ({
                  label: label.long,
                  value: key,
                }))}
                size="S"
                onChange={({ target }) => handleBadgeClick(target.value as IntentSerpPageFilterKeys)()}
              />
            )}
          </div>
          <HStack
            alignItems="center"
            gap={100}
          >
            <SearchByQueryAction
              initialQuery={filters.q}
              onSearch={handleSearch}
              placeholder="Cerca per contatto o immobile"
              size={isDesktop ? 'M' : 'S'}
              variant={isDesktop ? 'input' : 'action'}
            />
            <AgentFilterAction
              size={isDesktop ? 'M' : 'S'}
              onSubmit={(emails) => replaceAgentFilter(emails)()}
            />
            <ActionIconDropdown
              label="Ordina"
              aria-label="Pulsante per selezionare l'ordinamento degli interessi"
              placement="bottom-end"
              icon={{ path: !filters.sort || filters.sort !== DEFAULT_SORT_OPTION.key ? ICON_CUSTOM_SORT_PLUS : ICON_SORT }}
              size={isDesktop ? 'M' : 'S'}
              color={filters.sort && filters.sort !== DEFAULT_SORT_OPTION.key ? 'primary' : 'neutral'}
              emphasis="low"
              options={SORT_OPTIONS.map(({ key, label }) => ({
                key,
                label,
                iconRight:
                  // ICON_CHECK on "default" if no sort option has been selected
                  (!filters.sort && key === DEFAULT_SORT_OPTION.key)
                    // or the sort option is the current one
                    || filters.sort === key ? { path: ICON_CHECK } : undefined,
                color:
                  // Apply neutral color if "default" sort option has been selected
                  key !== DEFAULT_SORT_OPTION.key
                    // Apply the primary color if the sort option is the current one
                    && filters.sort === key
                    ? 'primary'
                    : 'neutral',
                onClick: () => addFilters({ sort: key }),
              }))}
            />
          </HStack>
        </HStack>
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <HStack justifyContent="space-between" alignItems="center">
          <Stack gap={50}>
            <Text.H4 aria-label="Titolo pagina">
              {`Interessi${filterLabel ? ` ${filterLabel}` : ''}${(serpIntents && serpIntents.totalElements > 0) ? ` (${serpIntents.totalElements})` : ''}`}
            </Text.H4>
            <SerpFiltersSummary
              aria-label="search-filters-summary"
              filters={{ ...filters, agentFilters: { values: agents, isExplicit: isAgentFilterExplicit } }}
              sortOptions={SORT_OPTIONS}
            />
          </Stack>
          <CreateIntentAction
            aria-label="Pulsante per aggiungere un nuovo interesse d'acquisto"
            onSuccess={handleSerpIntentsUpdate}
          >
            {({ createIntent }) => (
              <Action
                label="Aggiungi interesse"
                aria-label="Aggiungi un nuovo interesse d'acquisto"
                emphasis="high"
                size="S"
                onClick={createIntent}
              />
            )}
          </CreateIntentAction>
        </HStack>
        <Spacing margin={[300, 0, 0]} aria-label="Lista interessi">
          {isSerpIntentsLoading && isDesktop && (
            <SimpleTableSkeleton aria-label="Sezione interessi in caricamento" />
          )}
          {isSerpIntentsLoading && !isDesktop && (
            <Stack aria-label="Sezione interessi in caricamento">
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </Stack>
          )}
          {serpIntentsError && !isSerpIntentsLoading && (
            <Message message={SERP_INTENTS_ERROR_MESSAGE} type="critical" boxed />
          )}
          {(!isSerpIntentsLoading && (serpIntents && serpIntents.totalElements > 0)) && (
            <PaginatedList
              {...serpIntents}
              hideResultCount
              goToPage={goToPage}
            >
              {!isDesktop && serpIntents.content.map((serpIntent) => (
                <ViewSerpIntentCard
                  key={serpIntent.intent.id}
                  serpIntent={serpIntent}
                  onIntentUpdate={handleSerpIntentsUpdate}
                  onAppointmentUpdate={handleSerpIntentsUpdate}
                  onOfferUpdate={handleSerpIntentsUpdate}
                  onReminderCreated={handleSerpIntentsUpdate}
                />
              ))}
              {isDesktop && (
                <SimpleTable>
                  <SimpleTable.Body>
                    {serpIntents.content.map((serpIntent) => (
                      <SimpleTable.Row
                        asChild
                        key={serpIntent.intent.id}
                      >
                        <ViewSerpIntentRow
                          serpIntent={serpIntent}
                          onIntentUpdate={handleSerpIntentsUpdate}
                          onAppointmentUpdate={handleSerpIntentsUpdate}
                          onOfferUpdate={handleSerpIntentsUpdate}
                          onReminderCreated={handleSerpIntentsUpdate}
                        />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </PaginatedList>
          )}
          {(serpIntents && serpIntents.totalElements === 0) && (
            <Message message={EMPTY_SERP_INTENTS} boxed />
          )}
        </Spacing>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default IntentSerpPage;
