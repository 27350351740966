import React from 'react';
import { Badge, BadgeProps } from '@doveit/bricks';
import { LeadStatus } from '../../../domain/types';
import { leadStatusColors, leadStatusIcons, leadStatusLabels } from '../../constants';

export interface LeadStatusBadgeProps {
  status: LeadStatus,
  emphasis?: BadgeProps['emphasis'],
  size?: BadgeProps['size'],
  showIcon?: boolean,
}

const LeadStatusBadge: React.FC<LeadStatusBadgeProps> = ({
  status,
  emphasis = 'low',
  size,
  showIcon = false,
}) => (
  <Badge
    aria-label="Badge informazioni lead"
    label={leadStatusLabels[status]}
    color={leadStatusColors[status]}
    icon={showIcon ? leadStatusIcons[status] : undefined}
    emphasis={emphasis}
    size={size}
  />
);

export default LeadStatusBadge;
