import {
  Action,
  ConfirmModal,
  Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { formatDate } from '@doveit/hammer';
import React from 'react';
import { OfferDeedStatus } from '../../../domain/types';
import { Offer } from '../../../providers/api/dtos';
import { updateOffer } from '../../../providers/api/offer/offerProvider';

type ChildFnProps = {
  confirm: VoidFunction,
  isSaving: boolean,
};

export interface ConfirmOfferDeedActionProps {
  offer: Offer,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (updatedOffer: Offer) => void,
}

export const CONFIRM_DEED_OFFER_SUCCESS_MESSAGE = 'La data del rogito è stata confermata correttamente';
export const CONFIRM_DEED_OFFER_ERROR_MESSAGE = 'Non è stato possibile confermare la data del rogito';

const ConfirmOfferDeedAction: React.FC<ConfirmOfferDeedActionProps> = ({
  offer,
  children,
  onSuccess,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();

  const onConfirm = React.useCallback(async () => {
    if (!offer.deed) return;

    setIsSaving(true);

    const offerToSave: Offer = {
      ...offer,
      deed: {
        ...offer.deed,
        status: OfferDeedStatus.CONFIRMED,
      },
    };

    try {
      const offerWithConfirmedDeed = await updateOffer(offerToSave);

      setIsSaving(false);
      addSuccess(CONFIRM_DEED_OFFER_SUCCESS_MESSAGE);

      modal.close();

      onSuccess?.(offerWithConfirmedDeed);
    } catch (_) {
      setIsSaving(false);

      addError(CONFIRM_DEED_OFFER_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, modal, offer, onSuccess]);

  if (!offer.deed?.date || offer.deed.status === OfferDeedStatus.CONFIRMED) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          confirm: modal.open,
          isSaving,
        })
        : (
          <Action
            label="Conferma rogito"
            aria-label="Conferma rogito"
            size="S"
            onClick={() => modal.open()}
            loading={isSaving}
          />
        )}
      <Portal>
        <ConfirmModal
          isOpen={modal.isOpen}
          title="Conferma avvenuta rogito"
          aria-label="Modale di conferma avvenuta rogito"
          onConfirm={onConfirm}
          onAbort={modal.close}
        >
          Confermando che il rogito è avvenuto in data {formatDate(new Date(offer.deed.date))} potrai richiedere le recensioni verificate a venditore e compratore.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default ConfirmOfferDeedAction;
