/* eslint-disable react/no-unknown-property */
import React, {
  createContext, FunctionComponent, ReactNode, useState, useContext, useCallback, useEffect, useMemo, PropsWithChildren,
} from 'react';
import {
  ICON_EMPTY,
} from '@doveit/bricks';
import SideNavigationItem, { SideNavigationItemProps } from './side-navigation-item/SideNavigationItem';
import * as styles from './SideNavigation.style';

export type SideNavigationItemClick = (
  { id, hasChildren, path }: { id: string, hasChildren: boolean, path?: string }
) => void;

export interface SideNavigationContextValue {
  activeItemPath?: string;
  onCommonClick?: SideNavigationItemClick,
}

export type SideNavigationChildrenFunction = (props: SideNavigationContextValue) => ReactNode;

export const SideNavigationContext = createContext<SideNavigationContextValue>({
  activeItemPath: undefined,
  onCommonClick: undefined,
});

export type SideNavigationItemWithContextProps = Omit<SideNavigationItemProps, 'onClick'> & PropsWithChildren<{
  id: string;
  path?: string;
  alwaysOpen?: boolean;
  onClick?: SideNavigationItemClick;
}>;

export const SideNavigationItemWithContext: React.FC<SideNavigationItemWithContextProps> = ({
  id,
  path,
  onClick,
  icon,
  children,
  alwaysOpen,
  ...rest
}) => {
  const [open, setOpen] = useState(alwaysOpen);

  useEffect(() => {
    setOpen(alwaysOpen);
  }, [alwaysOpen]);

  const {
    activeItemPath,
    onCommonClick,
  } = useContext(SideNavigationContext);

  const onItemClick = useCallback(
    (currentPath?: string) => {
      const action = onClick || onCommonClick;

      if (action) {
        action({ id, hasChildren: !!children, path: currentPath });
      }

      if (children && !alwaysOpen) {
        setOpen(!open);
      }
    },
    [alwaysOpen, children, id, onClick, onCommonClick, open],
  );

  return (
    <SideNavigationItem
      {...rest}
      icon={icon}
      onClick={onItemClick}
      path={path}
      active={activeItemPath !== undefined && activeItemPath === path}
      open={open}
    >
      {open && React.Children.map(children, (child) => {
        const c = child as React.ReactElement<SideNavigationItemWithContextProps>;

        return React.cloneElement(c, {
          ...c.props,
          icon: c.props.icon || ICON_EMPTY,
          nested: true,
        });
      })}
    </SideNavigationItem>
  );
};

export interface SideNavigationProps {
  activeItemPath?: string;
  onCommonClick?: SideNavigationItemClick;
  children: SideNavigationChildrenFunction | ReactNode;
}

export interface SideNavigationComposition {
  Item: FunctionComponent<SideNavigationItemWithContextProps>
}

const SideNavigation: FunctionComponent<SideNavigationProps> & SideNavigationComposition = ({
  onCommonClick,
  activeItemPath,
  children,
}) => {
  const sideNavigationContext = useMemo(() => ({
    activeItemPath,
    onCommonClick,
  }), [activeItemPath, onCommonClick]);

  return (
    <SideNavigationContext.Provider
      value={sideNavigationContext}
    >
      <nav
        css={styles.base}
        role="navigation"
      >
        <SideNavigationContext.Consumer>
          {(context) => (typeof children === 'function'
            ? (children as SideNavigationChildrenFunction)(context)
            : children)}
        </SideNavigationContext.Consumer>
      </nav>
    </SideNavigationContext.Provider>
  );
};

SideNavigation.Item = SideNavigationItemWithContext;

export default SideNavigation;
