import { BadgeProps } from '@doveit/bricks';
import { ProspectApprovalStatus, ProspectStatus } from '../domain/types';
import { ComputedProspectApprovalStatus } from './hooks/use-computed-prospect-approval-status/useComputedProspectApprovalStatus';

export const prospectKOStatus: ProspectStatus[] = [
  ProspectStatus.CANCELLED,
  ProspectStatus.NOT_INTERESTED,
  ProspectStatus.ONLY_EVALUATION,
  ProspectStatus.OVER_PRICED,
  ProspectStatus.UNSELLABLE,
  ProspectStatus.SOLD,
];

export const prospectApprovalStatusLabelForAgent: Record<ComputedProspectApprovalStatus, string> = {
  TO_BE_REQUESTED: 'Senza approvazione',
  [ProspectApprovalStatus.WAITING_FOR_AGENT]: 'In approvazione',
  [ProspectApprovalStatus.WAITING_FOR_SUPERVISOR]: 'In approvazione',
  [ProspectApprovalStatus.APPROVED]: 'Approvata',
};

export const prospectApprovalStatusLabelForSupervisor: Record<ComputedProspectApprovalStatus, string> = {
  TO_BE_REQUESTED: 'Senza approvazione',
  [ProspectApprovalStatus.WAITING_FOR_AGENT]: 'Da approvare',
  [ProspectApprovalStatus.WAITING_FOR_SUPERVISOR]: 'Da approvare',
  [ProspectApprovalStatus.APPROVED]: 'Approvata',
};

export const prospectApprovalStatusColor: Record<ComputedProspectApprovalStatus, BadgeProps['color']> = {
  TO_BE_REQUESTED: 'warning',
  [ProspectApprovalStatus.WAITING_FOR_AGENT]: 'info',
  [ProspectApprovalStatus.WAITING_FOR_SUPERVISOR]: 'info',
  [ProspectApprovalStatus.APPROVED]: 'success',
};
