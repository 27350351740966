import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Fieldset, Spacing, useNotifications } from '@doveit/bricks';
import { Contact } from '../../../providers/api/dtos/contact';
import { createLead } from '../../../providers/api/lead/leadProvider';
import { CreateLeadFormExtendedModel } from '../../models/CreateLeadFormExtendedModel';
import CreateLeadForm from '../../components/create-lead-form/CreateLeadForm';
import { Lead } from '../../../providers/api/dtos/lead';
import { LeadSource, LeadStatus, LeadWillingness } from '../../../domain/types';
import useGoogleMapsAPI from '../../../hooks/use-google-maps-api/useGoogleMapsAPI';
import SelectContact from '../../../contact/containers/select-contact/SelectContact';
import useContact from '../../../contact/hooks/use-contact/useContact';
import { SearchContactFormModel } from '../../../contact/components/search-contact-form/SearchContactForm';
import createOrUpdateContact from '../../../contact/utils/createOrUpdateContact';
import { saveCallBackDate } from '../../../providers/api/call-center/callCenterProvider';
import { formDateTimeToDate } from '../../../utils/form';
import { geolocate } from '../../../providers/public-api/location/locationProvider';
import CardSkeleton from '../../../components/card/skeleton/CardSkeleton';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { ProspectFormByLeadProps } from '../../../prospect/components/prospect-form-by-lead/ProspectFormByLead';

export interface CreateLeadProps {
  lead?: Partial<Lead>,
  contactId?: string,
  initialValues?: Partial<CreateLeadFormExtendedModel>,
  hideFormSubmit?: boolean,
  formRef?: ProspectFormByLeadProps['innerRef'],
  onSuccess?: (contact: Contact, lead: Lead) => void;
}

export const LOAD_CONTACT_ERROR_MESSAGE = 'Errore durante il caricamento del contatto. Riprova inserendo i dati manualmente.';

const CreateLead: FunctionComponent<CreateLeadProps> = ({
  lead,
  contactId,
  initialValues,
  formRef,
  hideFormSubmit,
  onSuccess,
}) => {
  const { userIsAdmin, userIsCallCenter } = useRBAC();

  const { getPlaceDetails } = useGoogleMapsAPI();
  const [isSaving, setIsSaving] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>();
  const [overwriteValues, setOverwriteValues] = useState<SearchContactFormModel | undefined>();
  const { data: initialContact, error: initialContactError } = useContact(contactId);
  const { addSuccess, addError, addWarning } = useNotifications();

  useEffect(() => {
    if (initialContact) {
      setSelectedContact(initialContact);
    }

    if (initialContactError) {
      addWarning(LOAD_CONTACT_ERROR_MESSAGE);
    }
  }, [initialContact, initialContactError, addWarning]);

  const onSubmit = useCallback(async (values: CreateLeadFormExtendedModel) => {
    if (!overwriteValues && !selectedContact) {
      addError('Per creare la lead, devi aggiungere un contatto');
      return;
    }

    if (!(overwriteValues?.phoneNumber || selectedContact?.phoneNumber)) {
      addError('Non è possibile creare una lead per un contatto senza numero di telefono. Scegli o crea un altro contatto');
      return;
    }

    setIsSaving(true);

    try {
      const place = await getPlaceDetails(values.propertyAddress?.placeId!);
      const contact = await createOrUpdateContact(overwriteValues, selectedContact);

      const { suburb } = (place.latitude && place.longitude)
        ? await geolocate({
          latitude: `${place.latitude}`,
          longitude: `${place.longitude}`,
        }).catch((_) => ({ suburb: undefined }))
        : { suburb: undefined };

      const createdLead = await createLead({
        ...lead,
        contactId: contact.id!,
        status: LeadStatus.IN_PROGRESS,
        propertyAddress: values.propertyAddress?.description!,
        propertyFloor: values.propertyFloor ? parseFloat(values.propertyFloor) : undefined,
        propertySize: parseInt(values.propertySize, 10),
        propertyStatus: values.propertyStatus,
        propertyType: values.propertyType,
        propertyFeatures: values.propertyFeatures.reduce((acc, curr) => ({
          ...acc,
          [curr]: true,
        }), {}),
        locality: place.locality,
        area: suburb?.area,
        plateCode: place.plateCode,
        postalCode: place.postalCode,
        latitude: place.latitude,
        longitude: place.longitude,
        source: values.source,
        residualMortgage: false,
        otherAgencies: false,
        inheritedProperty: false,
        giftedProperty: false,
        multiOwner: false,
        ownedByCompany: false,
        registryDiscrepancies: false,
        subsidizedHousing: false,
        willingness: values.willingness ?? LeadWillingness.NOW,
        utmCampaign: values?.source === LeadSource.B2B ? 'b2b-development' : undefined,
        originAgentId: values?.originAgent?.id,
        createdAt: (values.createdAtDate && values.createdAtTime)
          ? formDateTimeToDate(values.createdAtDate, values.createdAtTime).toISOString()
          : undefined,
      });

      if (values.nextOpportunityDate) {
        const nextOpportunityTime = values.nextOpportunityTime || '10:00';
        await saveCallBackDate(contact.id!, formDateTimeToDate(values.nextOpportunityDate, nextOpportunityTime));
      }

      setIsSaving(false);
      addSuccess('Lead salvata con successo');

      if (onSuccess) {
        onSuccess(contact, createdLead);
      }
    } catch (err) {
      setIsSaving(false);
      addError('Non è stato possibile salvare la lead');
    }
  }, [addError, addSuccess, getPlaceDetails, lead, onSuccess, overwriteValues, selectedContact]);

  const onContactChange = useCallback((contactData?: Contact, search?: SearchContactFormModel) => {
    setSelectedContact(contactData);
    setOverwriteValues(search);
  }, []);

  return (
    <>
      <Fieldset legend="Dati contatto">
        {contactId && !initialContact && !initialContactError ? (
          <CardSkeleton data-ref="contact-skeleton" />
        ) : (
          <SelectContact
            onContactChange={onContactChange}
            initialContact={initialContact}
          />
        )}
      </Fieldset>
      <Spacing margin={[300, 0, 0]}>
        <CreateLeadForm
          loading={isSaving}
          initialValues={initialValues}
          canSetAsPersonal={userIsAdmin || userIsCallCenter}
          innerRef={formRef}
          hideSubmit={hideFormSubmit}
          onSubmit={onSubmit}
        />
      </Spacing>
    </>
  );
};

export default CreateLead;
