import { DEFAULT_MARKER_SIZE, DEFAULT_MARKER_ICON } from '../../constants';

export const DEFAULT_SCALE_RATIO = 0.75;

export default function getMarkerIcon(backgroundColor: string, icon?: string, ratio = DEFAULT_SCALE_RATIO): string {
  const canvas = document.createElement('canvas');

  canvas.width = DEFAULT_MARKER_SIZE * ratio;
  canvas.height = DEFAULT_MARKER_SIZE * ratio;

  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.scale(ratio, ratio);
    ctx.beginPath();
    ctx.arc(DEFAULT_MARKER_SIZE / 2, DEFAULT_MARKER_SIZE / 2, DEFAULT_MARKER_SIZE / 2, 0, 2 * Math.PI);
    ctx.fillStyle = backgroundColor;
    ctx.fill();

    if (icon) {
      ctx.translate(DEFAULT_MARKER_ICON / 2, DEFAULT_MARKER_ICON / 2);
      const path = new Path2D(icon);
      ctx.fillStyle = '#fff';
      ctx.fill(path);
    }
  }

  return canvas.toDataURL();
}
