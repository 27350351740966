import React from 'react';
import {
  ActionIcon, ICON_PENCIL_OUTLINE, Portal, useModal, useNotifications, ICON_CALENDAR_PLUS_OUTLINE, Action, FormHandlers,
} from '@doveit/bricks';
import { Appointment } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import AppointmentForm from '../../components/appointment-form/AppointmentForm';
import { formDateTimeToDate, formatInputDate, formatInputTime } from '../../../utils/form';
import { AppointmentFormModel } from '../../models/appointmentFormModel';
import { getAppointmentEndDate } from '../../utils/getAppointmentEndDate';
import { createAppointment, updateAppointment } from '../../../providers/api/appointment/appointmentProvider';

export const UPSERT_APPOINTMENT_ERROR_MESSAGE = 'Non è stato possibile effettuare l\'operazione';
export const UPSERT_APPOINTMENT_SUCCESS_MESSAGE = 'Operazione effettuata con successo';

interface ChildFnProps {
  upsert: VoidFunction,
}

export interface UpsertAppointmentActionProps {
  appointment: Appointment,
  withStatus?: boolean,
  withVirtual?: boolean,
  onSuccess?: (appointment: Appointment, operationType: 'create' | 'update') => void;
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const UpsertAppointmentAction: React.FC<UpsertAppointmentActionProps> = (
  {
    appointment,
    withStatus = true,
    withVirtual = true,
    onSuccess,
    children,
  },
) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const formRef = React.useRef<FormHandlers>() as React.MutableRefObject<FormHandlers>;

  const openModal = React.useCallback(
    () => modal.open(),
    [modal],
  );

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onSubmit = React.useCallback(async (values: AppointmentFormModel) => {
    setIsSaving(true);

    try {
      const startDate = formDateTimeToDate(values.date!, values.time!);

      const payload: Appointment = {
        ...appointment,
        status: values.status!,
        startDate: startDate.toISOString(),
        endDate: getAppointmentEndDate(appointment.referenceType, startDate, values.virtual).toISOString(),
        virtual: values.virtual,
        notes: values.notes?.trim() || undefined,
      };

      const result = await (appointment.id
        ? updateAppointment(appointment.id, payload)
        : createAppointment(payload)
      );

      onSuccess?.(result, appointment.id ? 'update' : 'create');
      modal.close();
      addSuccess(UPSERT_APPOINTMENT_SUCCESS_MESSAGE);
    } catch (error) {
      addError(UPSERT_APPOINTMENT_ERROR_MESSAGE);
    }

    setIsSaving(false);
  }, [appointment, onSuccess, modal, addSuccess, addError]);

  const initialValues = React.useMemo(() => {
    if (appointment.id) {
      return {
        id: appointment.id?.toString(),
        status: appointment.status,
        date: appointment.startDate ? formatInputDate(new Date(appointment.startDate)) : '',
        time: appointment.startDate ? formatInputTime(new Date(appointment.startDate)) : '',
        virtual: appointment.virtual,
        notes: appointment.notes ? appointment.notes : '',
      };
    }

    return {};
  }, [appointment]);

  return (
    <>
      {
        children
          ? children({ upsert: openModal })
          : (
            <ActionIcon
              label={
                appointment.id
                  ? 'Modifica'
                  : 'Aggiungi'
              }
              aria-label={
                appointment.id
                  ? "Pulsante per modificare l'appuntamento"
                  : 'Pulsante per aggiungere un appuntamento'
              }
              icon={
                appointment.id
                  ? { path: ICON_PENCIL_OUTLINE }
                  : { path: ICON_CALENDAR_PLUS_OUTLINE }
              }
              size="S"
              onClick={openModal}
            />
          )
      }
      <Portal>
        <SimpleModal
          {...modal}
          title={
            appointment.id
              ? 'Modifica appuntamento'
              : 'Aggiungi appuntamento'
          }
          aria-label={
            appointment.id
              ? "Modale per modificare l'appuntamento"
              : 'Modale per aggiungere un appuntamento'
          }
          footer={(
            <Action
              label={
                appointment.id
                  ? 'Modifica'
                  : 'Aggiungi'
              }
              color="primary"
              emphasis="high"
              onClick={submitForm}
              loading={isSaving}
            />
          )}
        >
          <AppointmentForm
            submitLabel={appointment.id
              ? 'Modifica'
              : 'Aggiungi'}
            onSubmit={onSubmit}
            initialValues={initialValues}
            formRef={formRef}
            loading={isSaving}
            withStatus={withStatus}
            withVirtual={withVirtual}
            hideSubmit
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpsertAppointmentAction;
