/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Action, ActionIcon, Badge, Card, FontWeight, Form, FormProps, FormSubmitType, Grid, Helper, HStack, ICON_CANCEL, ICON_CHECK, ICON_CLOSE, ICON_CURRENCY_EUR, ICON_PERCENT, ICON_PLUS, Spacing, Stack, Text,
  Tooltip,
} from '@doveit/bricks';
import {
  ExtraPackageDoveIt, ExtraPackagePayment, ExtraPackagePriceType, ExtraPackageProvider, ExtraPackageSPF, ExtraPackageType,
} from '../../../../../domain/types';
import { ExtraPackageConfig } from '../../../../../providers/api/dtos';
import validationSchema from './AssignmentWizardPackagesForm.schema';
import { extraPackageLabels } from '../../../../../labels/extraPackageLabels';
import SimpleTable from '../../../../../components/simple-table/SimpleTable';
import { asSelectOptions } from '../../../../../select-options/utils/asSelectOptions';
import { extraPackagePaymentLabels } from '../../../../../labels';
import * as styles from './AssignmentWizardPackagesForm.style';
import useIsDevice from '../../../../../hooks/use-is-device/useIsDevice';

export interface DoveItPackageFormModel {
  type: ExtraPackageDoveIt,
  price: string,
  priceType: ExtraPackagePriceType,
  payment: ExtraPackagePayment | '',
}

export interface SPFPackageFormModel {
  type: ExtraPackageSPF,
}

export interface AssignmentWizardPackagesFormModel {
  noPackagesDoveIt?: boolean,
  doveItPackages: DoveItPackageFormModel[],
  noPackagesSPF?: boolean,
  spfPackages: SPFPackageFormModel[],
}

export interface AssignmentWizardPackagesFormProps extends React.AriaAttributes {
  initialValues?: Partial<AssignmentWizardPackagesFormModel>,
  allowedDoveItPackages: ExtraPackageConfig[],
  canAdd?: boolean,
  canEdit?: boolean,
  loading?: FormProps<AssignmentWizardPackagesFormModel>['loading'],
  disabled?: FormProps<AssignmentWizardPackagesFormModel>['disabled'],
  innerRef?: FormProps<AssignmentWizardPackagesFormModel>['innerRef'],
  onSubmit: FormSubmitType<AssignmentWizardPackagesFormModel>,
}

export const DEFAULT_INITIAL_VALUES: AssignmentWizardPackagesFormModel = {
  noPackagesDoveIt: false,
  doveItPackages: [],
  noPackagesSPF: false,
  spfPackages: [],
};

const packages = {
  [ExtraPackageProvider.DOVE_IT]: (Object.keys(ExtraPackageDoveIt) as ExtraPackageDoveIt[]),
  [ExtraPackageProvider.SPF]: (Object.keys(ExtraPackageSPF) as ExtraPackageSPF[]),
};

const AssignmentWizardPackagesForm: React.FC<AssignmentWizardPackagesFormProps> = ({
  initialValues = {},
  canAdd,
  canEdit,
  allowedDoveItPackages,
  ...rest
}) => {
  const isMobile = useIsDevice('mobile');

  const mergedInitialValues: AssignmentWizardPackagesFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      enableReinitialize
      {...rest}
    >
      {({ values, setFieldValue }) => {
        const resetAllPackages = (provider: ExtraPackageProvider) => ({ target }: React.ChangeEvent<HTMLInputElement>) => {
          if (target.checked) {
            setFieldValue(provider === ExtraPackageProvider.DOVE_IT ? 'doveItPackages' : 'spfPackages', []);
          }
        };

        return (
          <>
            <Form.Fieldset legend="Servizi extra Dove.it">
              <Form.Item>
                <Form.Checkbox
                  name="noPackagesDoveIt"
                  text="Nessun pacchetto"
                  aria-label="Campo per selezionare l'opzione 'Nessun pacchetto Dove.it'"
                  onChange={resetAllPackages(ExtraPackageProvider.DOVE_IT)}
                  disabled={!canEdit}
                />
              </Form.Item>

              {!values.noPackagesDoveIt && (
                <Form.Item>
                  <Form.FieldArray
                    name="doveItPackages"
                    render={({ push, replace, remove }) => {
                      const selectPackage = (packageType: ExtraPackageType) => () => {
                        if (packageType === ExtraPackageDoveIt.PRESTIGE) {
                          setFieldValue('doveItPackages', values.doveItPackages.filter(({
                            type,
                          }) => type !== ExtraPackageDoveIt.EXTRA_VISIBILITY && type !== ExtraPackageDoveIt.INTERIOR_RESTYLING));
                        }

                        push({
                          type: packageType,
                          price: '',
                          priceType: ExtraPackagePriceType.FIXED,
                          payment: '',
                        });
                      };

                      return (
                        <>
                          <HStack>
                            {packages[ExtraPackageProvider.DOVE_IT]
                              .filter((packageType) => allowedDoveItPackages.some(({ name }) => packageType === name))
                              .map((packageType) => {
                                const isForcedSelected = [ExtraPackageDoveIt.EXTRA_VISIBILITY, ExtraPackageDoveIt.INTERIOR_RESTYLING].includes(packageType)
                                  && values.doveItPackages.some(({ type }) => type === ExtraPackageDoveIt.PRESTIGE);

                                const isSelected = values.doveItPackages.some(({ type }) => type === packageType);

                                const badgeIcon = () => {
                                  if (!canAdd) {
                                    return undefined;
                                  }

                                  if (isSelected || isForcedSelected) {
                                    return ICON_CHECK;
                                  }

                                  return ICON_PLUS;
                                };

                                return (
                                  <Badge
                                    aria-label={`Badge del pacchetto ${extraPackageLabels[packageType]}`}
                                    key={packageType}
                                    label={extraPackageLabels[packageType]}
                                    icon={badgeIcon()}
                                    color={(isSelected && !isForcedSelected) ? 'primary' : 'neutral'}
                                    onClick={!canAdd || isSelected || isForcedSelected ? undefined : selectPackage(packageType)}
                                  />
                                );
                              })}
                          </HStack>

                          {values.doveItPackages.length > 0 && (
                            <div css={styles.packageBox}>
                              <Text.BodySmall>Pacchetti selezionati</Text.BodySmall>
                              <Spacing margin={[200, 0, 0]}>

                                {isMobile && (
                                  <Stack>
                                    {values.doveItPackages.map((doveItPackage, idx) => {
                                      const swapPriceType = () => {
                                        replace(idx, {
                                          ...doveItPackage,
                                          type: doveItPackage.type,
                                          price: '',
                                          priceType: doveItPackage.priceType === ExtraPackagePriceType.FIXED
                                            ? ExtraPackagePriceType.PERCENTAGE
                                            : ExtraPackagePriceType.FIXED,
                                        });
                                      };

                                      return (
                                        <Card
                                          key={doveItPackage.type}
                                          aria-label={`${doveItPackage.type} (card)`}
                                        >
                                          <Card.Header
                                            title={extraPackageLabels[doveItPackage.type]}
                                            primaryActions={[(
                                              <ActionIcon
                                                aria-label={`Pulsante per rimuovere il pacchetto ${extraPackageLabels[doveItPackage.type]}`}
                                                label="Rimuovi"
                                                size="S"
                                                icon={{ path: ICON_CLOSE }}
                                                onClick={() => remove(idx)}
                                              />
                                            )]}
                                          />
                                          <Card.Content>
                                            <Grid gutter={75}>
                                              <Grid.Unit size={2 / 3}>
                                                <Form.DecimalPriceField
                                                  name={`doveItPackages.${idx}.price`}
                                                  size="S"
                                                  aria-label={`Campo per inserire il prezzo del pacchetto ${extraPackageLabels[doveItPackage.type]}`}
                                                  disabled={!canEdit}
                                                  icon={doveItPackage.priceType === ExtraPackagePriceType.FIXED
                                                    ? ICON_CURRENCY_EUR
                                                    : ICON_PERCENT}
                                                />
                                              </Grid.Unit>
                                              <Grid.Unit size={1 / 3}>
                                                <Action
                                                  expanded
                                                  label={doveItPackage.priceType === ExtraPackagePriceType.FIXED
                                                    ? 'Usa %'
                                                    : 'Usa €'}
                                                  aria-label={`Azione per cambiare l'unità del prezzo del pacchetto ${extraPackageLabels[doveItPackage.type]}`}
                                                  size="S"
                                                  onClick={swapPriceType}
                                                  disabled={!canEdit}
                                                />
                                              </Grid.Unit>

                                              {(allowedDoveItPackages.find(({ name }) => name === doveItPackage.type)?.supportedPayments || []).length > 1 && (
                                                <Grid.Unit>
                                                  <Form.Select
                                                    name={`doveItPackages.${idx}.payment`}
                                                    size="S"
                                                    aria-label={`Campo per selezionare il momento di pagamento del pacchetto ${extraPackageLabels[doveItPackage.type]}`}
                                                    options={asSelectOptions(extraPackagePaymentLabels)}
                                                    disabled={!canEdit}
                                                  />
                                                </Grid.Unit>
                                              )}
                                            </Grid>
                                          </Card.Content>
                                        </Card>
                                      );
                                    })}
                                  </Stack>
                                )}

                                {!isMobile && (
                                  <SimpleTable css={styles.table}>
                                    <SimpleTable.Body>
                                      {values.doveItPackages.map((doveItPackage, idx) => {
                                        const swapPriceType = () => {
                                          replace(idx, {
                                            ...doveItPackage,
                                            type: doveItPackage.type,
                                            price: '',
                                            priceType: doveItPackage.priceType === ExtraPackagePriceType.FIXED
                                              ? ExtraPackagePriceType.PERCENTAGE
                                              : ExtraPackagePriceType.FIXED,
                                          });
                                        };

                                        return (
                                          <SimpleTable.Row
                                            key={doveItPackage.type}
                                            aria-label={`${doveItPackage.type} (table row)`}
                                          >
                                            <SimpleTable.Cell aria-label="Tipologia del pacchetto">
                                              <HStack>
                                                <Text.Body fontWeight={FontWeight.MEDIUM}>
                                                  {extraPackageLabels[doveItPackage.type]}
                                                </Text.Body>
                                                {doveItPackage.type === ExtraPackageDoveIt.PRESTIGE && (
                                                  <Helper
                                                    content="Il pacchetto Prestige include i pacchetti Visibilità extra e Restyling interno."
                                                    addCSS={styles.prestigeTooltip}
                                                  />
                                                )}
                                              </HStack>
                                            </SimpleTable.Cell>

                                            <SimpleTable.Cell aria-label="Valore del pacchetto">
                                              <HStack wrap="nowrap">
                                                <div>
                                                  <Form.DecimalPriceField
                                                    name={`doveItPackages.${idx}.price`}
                                                    size="S"
                                                    disabled={!canEdit}
                                                    aria-label={`Campo per inserire il prezzo del pacchetto ${extraPackageLabels[doveItPackage.type]}`}
                                                    icon={doveItPackage.priceType === ExtraPackagePriceType.FIXED
                                                      ? ICON_CURRENCY_EUR
                                                      : ICON_PERCENT}
                                                  />
                                                </div>
                                                <Action
                                                  label={doveItPackage.priceType === ExtraPackagePriceType.FIXED
                                                    ? 'Usa %'
                                                    : 'Usa €'}
                                                  aria-label={`Azione per cambiare l'unità del prezzo del pacchetto ${extraPackageLabels[doveItPackage.type]}`}
                                                  size="S"
                                                  onClick={swapPriceType}
                                                  disabled={!canEdit}
                                                />
                                              </HStack>
                                            </SimpleTable.Cell>

                                            <SimpleTable.Cell
                                              aria-label="Momento del pagamento del pacchetto"
                                              width="12.5rem"
                                            >
                                              {(allowedDoveItPackages.find(({ name }) => name === doveItPackage.type)?.supportedPayments || []).length > 1 && (
                                                <Form.Select
                                                  name={`doveItPackages.${idx}.payment`}
                                                  size="S"
                                                  aria-label={`Campo per selezionare il momento di pagamento del pacchetto ${extraPackageLabels[doveItPackage.type]}`}
                                                  options={asSelectOptions(extraPackagePaymentLabels)}
                                                  disabled={!canEdit}
                                                />
                                              )}
                                            </SimpleTable.Cell>

                                            <SimpleTable.Cell
                                              align="right"
                                              aria-label="Azioni"
                                            >
                                              <ActionIcon
                                                label="Rimuovi"
                                                icon={{ path: ICON_CLOSE }}
                                                onClick={() => remove(idx)}
                                                size="S"
                                                aria-label={`Pulsante per rimuovere il pacchetto ${extraPackageLabels[doveItPackage.type]}`}
                                                disabled={!canEdit}
                                              />
                                            </SimpleTable.Cell>
                                          </SimpleTable.Row>
                                        );
                                      })}
                                    </SimpleTable.Body>
                                  </SimpleTable>
                                )}
                              </Spacing>
                            </div>
                          )}
                        </>
                      );
                    }}
                  />
                </Form.Item>
              )}
            </Form.Fieldset>
            <Form.Fieldset legend="Servizi SPF">
              <Form.Item>
                <Form.Checkbox
                  name="noPackagesSPF"
                  text="Nessun pacchetto"
                  aria-label="Campo per selezionare l'opzione 'Nessun pacchetto SPF'"
                  onChange={resetAllPackages(ExtraPackageProvider.SPF)}
                  disabled={!canEdit}
                />
              </Form.Item>

              {!values.noPackagesSPF && (
                <Form.Item>
                  <Form.FieldArray
                    name="spfPackages"
                    render={({ push, remove }) => {
                      const selectPackage = (packageType: ExtraPackageType) => () => {
                        push({ type: packageType });
                      };

                      return (
                        <HStack>
                          {packages[ExtraPackageProvider.SPF].map((packageType) => {
                            const isAPEForcedSelected = (packageType === ExtraPackageSPF.APE && values.doveItPackages.some(({ type }) => type === ExtraPackageDoveIt.PRESTIGE));

                            const isSelected = values.spfPackages.some(({ type }) => type === packageType) || isAPEForcedSelected;

                            const isForbidden = (!values.spfPackages.some(({ type }) => type === ExtraPackageSPF.VERIFICATION_SERVICE)
                              && [ExtraPackageSPF.DOCUMENTS_ACCESS, ExtraPackageSPF.APE].includes(packageType));

                            const getIcon = () => {
                              if (!canAdd) {
                                return undefined;
                              }

                              if (isSelected) {
                                return ICON_CHECK;
                              }

                              return isForbidden ? ICON_CANCEL : ICON_PLUS;
                            };

                            const badge = (
                              <Badge
                                aria-label={`Badge del pacchetto ${extraPackageLabels[packageType]}`}
                                label={extraPackageLabels[packageType]}
                                icon={getIcon()}
                                color={(isSelected && !isAPEForcedSelected) ? 'primary' : 'neutral'}
                                onClick={(!canAdd || isSelected || isForbidden) ? undefined : selectPackage(packageType)}
                                onRemove={(canAdd && isSelected && !isAPEForcedSelected) ? () => remove(values.spfPackages.findIndex(({ type }) => type === packageType)) : undefined}
                              />
                            );

                            return (
                              <div key={packageType}>
                                {isForbidden
                                  ? (
                                    <Tooltip content={isSelected
                                      ? "Il pacchetto 'APE' è compreso nel pacchetto 'Prestige'."
                                      : "È necessario selezionare il pacchetto 'Servizio di verifica'."}
                                    >
                                      <div>{badge}</div>
                                    </Tooltip>
                                  ) : badge}
                              </div>
                            );
                          })}
                        </HStack>
                      );
                    }}
                  />
                </Form.Item>
              )}
            </Form.Fieldset>
          </>
        );
      }}
    </Form>
  );
};

export default AssignmentWizardPackagesForm;
