import React from 'react';
import {
  Action,
  Badge, Card, CardSkeleton, Dropdown, HStack, ICON_ALERT_CIRCLE_OUTLINE, ICON_HOME_SEARCH_OUTLINE, Message,
  Stack,
} from '@doveit/bricks';
import { Contact } from '../../../providers/api/dtos';
import useContactSearchCriteria from '../../hooks/use-contact-search-criteria/useContactSearchCriteria';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewSearchCriteriaPreviewTableRow from '../../../search-criteria/container/view-search-criteria-preview-table-row/ViewSearchCriteriaPreviewTableRow';
import { goToCreatePage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import ViewSearchCriteriaCard from '../../../search-criteria/container/view-search-criteria-card/ViewSearchCriteriaCard';

export const LOAD_CONTACT_SEARCH_CRITERIA_ERROR_MESSAGE = 'Non è stato possibile recuperare le ricerche del contatto.';
export const EMPTY_CONTACT_SEARCH_CRITERIA_INFO_MESSAGE = 'Non sono presenti ricerche associate al contatto.';

export interface ContactSearchCriteriaWidgetProps {
  contactId: NonNullable<Contact['id']>,
}

const FILTERS = ['all', 'active', 'inactive'] as const;

type Filter = typeof FILTERS[number];

const filterLabels: Record<Filter, string> = {
  all: 'Tutte',
  active: 'Attive',
  inactive: 'Non attive',
};

const ContactSearchCriteriaWidget: React.FC<ContactSearchCriteriaWidgetProps> = ({
  contactId,
}) => {
  const [activeFilter, setActiveFilter] = React.useState<Filter>('all');

  const isMobile = useIsDevice('mobile');

  const {
    data: searchCriteria = [],
    isLoading: areSearchCriteriaLoading,
    error: searchCriteriaError,
    mutate: mutateSearchCriteria,
  } = useContactSearchCriteria(contactId);

  const onInternalSearchCriteriaUpdate = React.useCallback(async () => {
    await mutateSearchCriteria();
  }, [mutateSearchCriteria]);

  const goToCreateSearchCriteriaPage = React.useCallback(() => {
    goToCreatePage(ReferenceType.SEARCH_CRITERIA, {
      contactId,
    });
  }, [contactId]);

  const sortedSearchCriteria = React.useMemo(
    () => searchCriteria.filter(({ active }) => {
      if (activeFilter === 'active') return active;
      if (activeFilter === 'inactive') return !active;
      return true;
    }).sort((a, b) => Date.parse(b.createdAt!) - Date.parse(a.createdAt!)), [activeFilter, searchCriteria],
  );

  if (areSearchCriteriaLoading) {
    return (
      <CardSkeleton aria-label="Sezione ricerche in caricamento">
        {!isMobile && <SimpleTableSkeleton />}
      </CardSkeleton>
    );
  }

  if (searchCriteriaError) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Ricerche"
          color="critical"
        />
        <Card.Content>
          <Message message={LOAD_CONTACT_SEARCH_CRITERIA_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  return (
    <Card aria-label="Sezione ricerche">
      <Card.Header
        title="Ricerche"
        icon={{ path: ICON_HOME_SEARCH_OUTLINE }}
        primaryActions={[
          (!isMobile && (
            <Action
              aria-label="Pulsante per aggiungere una nuova ricerca"
              label="Aggiungi"
              size="S"
              onClick={goToCreateSearchCriteriaPage}
            />
          )),
        ]}
        secondaryActions={[
          (isMobile && (
            <Dropdown.Option
              aria-label="Pulsante per aggiungere una nuova ricerca"
              label="Aggiungi"
              onClick={goToCreateSearchCriteriaPage}
            />
          )),
        ]}
      />
      <Card.Content>
        <Stack gap={150} aria-label="Lista ricerche">
          {isMobile && sortedSearchCriteria.length > 0 && (
            sortedSearchCriteria.map((searchCriterion) => (
              <ViewSearchCriteriaCard
                key={searchCriterion.id}
                searchCriteria={searchCriterion}
                onSearchCriteriaUpdate={onInternalSearchCriteriaUpdate}
              />
            ))
          )}
          {!isMobile && (
            <>
              {searchCriteria.length > 0 && (
                <HStack aria-label="Filtri">
                  {FILTERS.map((filter) => (
                    <Badge
                      key={filter}
                      label={filterLabels[filter]}
                      color={filter === activeFilter ? 'primary' : 'neutral'}
                      emphasis="low"
                      onClick={filter !== activeFilter ? () => setActiveFilter(filter) : undefined}
                    />
                  ))}
                </HStack>
              )}

              {sortedSearchCriteria.length > 0 && (
                <SimpleTable aria-label="Tabella ricerche">
                  <SimpleTable.Body>
                    {sortedSearchCriteria.map((searchCriterion) => (
                      <SimpleTable.Row
                        key={searchCriterion.id}
                        asChild
                      >
                        <ViewSearchCriteriaPreviewTableRow
                          searchCriteria={searchCriterion}
                          onSearchCriteriaUpdate={onInternalSearchCriteriaUpdate}
                        />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </>
          )}
          {sortedSearchCriteria.length === 0 && (
            <Message message={EMPTY_CONTACT_SEARCH_CRITERIA_INFO_MESSAGE} />
          )}
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ContactSearchCriteriaWidget;
