import { OfferWizardData, PaymentMethod } from '../../../../providers/api/dtos';
import { boolToBooleanString, booleanStringToBool } from '../../../../utils/boolean-string/booleanString';
import { formatInputDate } from '../../../../utils/form';
import { OfferWizardPaymentsFormModel } from './OfferWizardPaymentsForm';

export const toOfferWizardPaymentsFormModel = (
  initialData: OfferWizardData,
): OfferWizardPaymentsFormModel => ({
  offerValue: initialData.offer?.value?.toString() ?? '',
  offerNotaryPriceDeposit: boolToBooleanString(initialData.offer?.notaryPriceDeposit) ?? 'false',

  depositValue: initialData.offer?.payment?.deposit?.value?.toString() || '',
  depositType: initialData.offer?.payment?.deposit?.paymentMethod || '',
  depositBank: initialData.offer?.payment?.deposit?.bank || '',
  depositCheckNumber: initialData.offer?.payment?.deposit?.checkNumber || '',

  preliminary: boolToBooleanString(initialData.offer?.payment?.integration?.preliminary) || '',
  preliminaryPaymentDateWithin: initialData.offer?.payment?.integration?.paymentDateWithin
    ? formatInputDate(new Date(initialData.offer?.payment?.integration?.paymentDateWithin))
    : '',
  depositIntegration: boolToBooleanString(initialData.offer?.payment?.integration?.depositIntegration) || '',
  integrationValue: initialData.offer?.payment?.integration?.value?.toString() || '',
  decay: boolToBooleanString(initialData.offer?.payment?.integration?.decay) || '',
  daysFromDecay: initialData.offer?.payment?.integration?.daysFromDecay?.toString() || '',

  balanceValue: initialData.offer?.payment?.balance?.value?.toString() || '',
  balanceExpiresAt: initialData.offer?.payment?.balance?.paymentDateWithin ? formatInputDate(new Date(initialData.offer?.payment?.balance?.paymentDateWithin)) : '',
});

export const toOfferWizardData = (
  existingData: OfferWizardData,
  paymentsFormModel: OfferWizardPaymentsFormModel,
): OfferWizardData => ({
  ...existingData,
  offer: {
    ...existingData.offer,
    value: paymentsFormModel.offerValue.trim() ? Number(paymentsFormModel.offerValue.trim()) : undefined,
    notaryPriceDeposit: booleanStringToBool(paymentsFormModel.offerNotaryPriceDeposit),
    payment: {
      ...existingData.offer?.payment,
      deposit: {
        ...existingData.offer?.payment?.deposit,
        value: paymentsFormModel.depositValue.trim() ? Number(paymentsFormModel.depositValue.trim()) : undefined,
        paymentMethod: paymentsFormModel.depositType as PaymentMethod || undefined,
        bank: paymentsFormModel.depositBank.trim() || undefined,
        checkNumber: paymentsFormModel.depositCheckNumber.trim() || undefined,
      },
      integration: {
        preliminary: booleanStringToBool(paymentsFormModel.preliminary),
        paymentDateWithin: paymentsFormModel.preliminaryPaymentDateWithin || undefined,
        depositIntegration: booleanStringToBool(paymentsFormModel.depositIntegration),
        value: paymentsFormModel.integrationValue.trim() ? parseFloat(paymentsFormModel.integrationValue.trim()) : undefined,
        decay: booleanStringToBool(paymentsFormModel.decay),
        daysFromDecay: paymentsFormModel.daysFromDecay ? parseInt(paymentsFormModel.daysFromDecay, 10) : undefined,
      },
      balance: {
        ...existingData.offer?.payment?.balance,
        value: paymentsFormModel.balanceValue.trim() ? Number(paymentsFormModel.balanceValue.trim()) : undefined,
        paymentDateWithin: paymentsFormModel.balanceExpiresAt ? paymentsFormModel.balanceExpiresAt : undefined,
      },
    },
  },
});
