import {
  getTypography, ThemedFunctor, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';
import { DEFAULT_MARKER_SIZE } from '../../../constants';

export const main: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.BODY)}
  display: flex;
  align-items: center;
`;

export const info: ThemedFunctor = () => css`
  flex-grow: 1;
`;

export const pin: ThemedFunctor = ({
  theme,
  $backgroundColor,
}) => css`
  align-items: center;
  background-color: ${$backgroundColor};
  border-radius: 50%;
  color: ${theme.palettes.neutrals.lightest};
  display: inline-flex;
  flex-shrink: 0;
  height: ${DEFAULT_MARKER_SIZE}px;
  justify-content: center;
  margin-right: 0.5rem;
  width: ${DEFAULT_MARKER_SIZE}px;
`;

export const details: ThemedFunctor = () => css`
  display: flex;
  justify-content: space-between;
  line-height: 1.2;
`;

export const address: ThemedFunctor = () => css`
  font-weight: bold;
  line-height: 1.2;
  text-transform: capitalize;
`;

export const detailsLeft: ThemedFunctor = () => css`
  flex-grow: 1;

  span {
    white-space: nowrap;
  }
`;

export const link: ThemedFunctor = ({
  theme,
}) => css`
  color: ${theme.palettes.neutrals.darkest};
`;

export const detailsRight: ThemedFunctor = ({
  theme,
}) => css`
  color: ${theme.palettes.neutrals.medium};
  margin-left: 1rem;
  flex-shrink: 0;
`;
