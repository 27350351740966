import { Agent } from '../../../providers/api/dtos';
import { EditMembershipContractFormModel } from '../../components/edit-membership-contract-from/EditMembershipContractForm';

export const editMembershipContractFormModelToAgent = (agentFormModel: EditMembershipContractFormModel, existingAgent: Agent): Agent => {
  const {
    bic, swift, iban, role, companyName, vatNumber,
  } = agentFormModel;

  return ({
    ...existingAgent,
    contract: {
      ...existingAgent.contract,
      bankAccount: {
        ...existingAgent.contract.bankAccount,
        iban,
        bic,
        swift,
      },
      legal: {
        ...existingAgent.contract.legal,
        companyName,
        vatNumber,
        role,
      },
    },
  });
};

export const agentToEditMembershipContractFormModel = (agent: Agent): EditMembershipContractFormModel => {
  const { contract: { legal, bankAccount } } = agent;

  return (
    {
      companyName: legal?.companyName || '',
      role: legal?.role || '',
      vatNumber: legal?.vatNumber || '',
      iban: bankAccount?.iban || '',
      bic: bankAccount?.bic || '',
      swift: bankAccount?.swift || '',
    }
  );
};
