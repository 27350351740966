import {
  ThemedFunctor,
  SpacingName,
  getSpacing,
  rgba,
  BreakpointQueryName,
} from '@doveit/bricks';
import { css } from 'styled-components';
import { NavigationMobileHeight, NavigationDesktopHeight } from '../../constants';

export const base = () => css`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const pictogram: ThemedFunctor = ({ theme }) => css`
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 0;
  color: currentColor;
  cursor: pointer;
  width: ${theme.remSize[250]};

  ${theme.breakpoints.up(BreakpointQueryName.XL)} {
    display: none;
  }
`;

export const shortcutsList: ThemedFunctor = () => css`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const sideMenu: ThemedFunctor = ({
  theme,
  $open,
}) => css`
  background-color: ${theme.color.background.neutral.default.low};
  color: ${theme.color.foreground.neutral.default.low};
  max-width: 17rem;
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  padding-top: ${NavigationMobileHeight};
  z-index: ${theme.zIndex.navbar + 2};
  transition: transform .4s ease-in-out, visibility .4s ease-in-out;
  transform: translateX(0);
  will-change: transform;
  box-sizing: border-box;
  overflow-y: auto;

  ${!$open && `
    visibility: hidden;
    transform: translateX(-100%);
  `}

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    padding-top: ${NavigationDesktopHeight};
    max-width: 20rem;
  }
`;

export const sideMenuOverlay: ThemedFunctor = ({
  theme,
  $open,
}) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  border: 0;
  outline: 0;
  appearance: none;
  display: block;
  z-index: ${theme.zIndex.navbar + 2};
  background-color: ${rgba(theme.palettes.neutrals.darkest, 0.6)};
  cursor: pointer;
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
  will-change: opacity;

  ${!$open && `
    opacity: 0;
    visibility: hidden;
  `}
`;

export const sideMenuItems: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.padding(theme, SpacingName.SMALL)}

  a, button {
    margin-top: 0 !important;
  }
`;
