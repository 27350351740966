import {
  ICON_ACCOUNT_CLOCK_OUTLINE,
  ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE, ICON_CLOSE_CIRCLE_OUTLINE, ICON_PLUS_CIRCLE_OUTLINE,
} from '@doveit/bricks';
import { DocumentStatus } from '../../../domain/types';
import { DocumentStatusIcon } from './types';

export const DEFAULT_MANDATORY_DOCUMENT_STATUS_ICON: DocumentStatusIcon = {
  icon: ICON_ALERT_CIRCLE_OUTLINE,
  color: 'critical',
};

export const DEFAULT_OPTIONAL_DOCUMENT_STATUS_COLOR = 'neutral';

export const DEFAULT_OPTIONAL_DOCUMENT_STATUS_ICON: DocumentStatusIcon = {
  icon: ICON_PLUS_CIRCLE_OUTLINE,
  color: DEFAULT_OPTIONAL_DOCUMENT_STATUS_COLOR,
};

export const DEFAULT_OPTIONAL_DOCUMENT_STATUS_ICON_EXISTING_FILES: DocumentStatusIcon = {
  icon: ICON_PLUS_CIRCLE_OUTLINE,
  color: 'warning',
};

export const documentStatusIcons: {
  [key in DocumentStatus]: {
    mandatory: DocumentStatusIcon,
    optional: DocumentStatusIcon,
  }
} = {
  [DocumentStatus.APPROVED]: {
    mandatory: {
      icon: ICON_CHECK_CIRCLE_OUTLINE,
      color: 'success',
    },
    optional: {
      icon: ICON_CHECK_CIRCLE_OUTLINE,
      color: DEFAULT_OPTIONAL_DOCUMENT_STATUS_COLOR,
    },
  },
  [DocumentStatus.DRAFT]: {
    mandatory: DEFAULT_MANDATORY_DOCUMENT_STATUS_ICON,
    optional: {
      icon: ICON_PLUS_CIRCLE_OUTLINE,
      color: DEFAULT_OPTIONAL_DOCUMENT_STATUS_COLOR,
    },
  },
  [DocumentStatus.REVIEW]: {
    mandatory: {
      icon: ICON_ACCOUNT_CLOCK_OUTLINE,
      color: 'warning',
    },
    optional: {
      icon: ICON_ACCOUNT_CLOCK_OUTLINE,
      color: DEFAULT_OPTIONAL_DOCUMENT_STATUS_COLOR,
    },
  },
  [DocumentStatus.REJECTED]: {
    mandatory: {
      icon: ICON_CLOSE_CIRCLE_OUTLINE,
      color: 'critical',
    },
    optional: {
      icon: ICON_CLOSE_CIRCLE_OUTLINE,
      color: DEFAULT_OPTIONAL_DOCUMENT_STATUS_COLOR,
    },
  },
};
