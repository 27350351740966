import {
  AssignmentWizardData, Contact, Geo, IdentityDocumentType, Legal, WizardContact,
} from '../../../../../providers/api/dtos';
import { getPlaceDetails } from '../../../../../providers/geo/places/places';
import { AssignmentWizardContactFormModel } from './AssignmentWizardContactForm';
import { AssignmentWizardSellerFormModel } from './AssignmentWizardSellerForm';
import { geocodeByAddress } from '../../../../../providers/geo/geocode/geocodeProvider';
import { getNormalizedAddress } from '../../../../../utils/geo/utils';
import { formatInputDate } from '../../../../../utils/form';

export function mapToWizardContact(contact: Contact): WizardContact;
export function mapToWizardContact(contact?: Contact): WizardContact | undefined;

export function mapToWizardContact(contact?: Contact): WizardContact | undefined {
  if (typeof contact !== 'undefined') {
    return {
      id: contact?.id || undefined,
      name: contact?.name,
      email: contact?.email,
      phoneNumber: contact?.phoneNumber,
      birthday: contact?.birthday,
      birthplace: contact?.birthplace,
      fiscalCode: contact?.fiscalCode,
      placeOfResidence: contact.residence,
    };
  }

  return undefined;
}

export const toAssignmentWizardSellerModel = (initialData: AssignmentWizardData, prospectContact?: Contact) => {
  const contacts: WizardContact[] = initialData.contacts || [mapToWizardContact(prospectContact)].filter((item) => item) as WizardContact[];

  const firstLegalContact = contacts?.find((contact) => contact.legal !== undefined)?.legal;

  const formData: AssignmentWizardSellerFormModel = {
    companyName: firstLegalContact?.companyName || '',
    companyAddress: {
      description: firstLegalContact?.registeredOffice?.normalizedAddress || '',
      placeId: '',
    },
    companyFiscalCode: firstLegalContact?.fiscalCode || '',
  };

  return formData;
};

export const toAssignmentWizardData = async (existingData: AssignmentWizardData, values: AssignmentWizardSellerFormModel, updatedContacts: WizardContact[], isLegalPersonOnly?: boolean) => {
  const newContacts: WizardContact[] = updatedContacts.map((contact) => ({ ...contact, legal: undefined }));

  if (isLegalPersonOnly && updatedContacts.length > 0) {
    const legalContact = newContacts[0];
    let registeredOffice: Geo | undefined;
    const placeId = values.companyAddress?.placeId;

    if (placeId) {
      const place = values.companyAddress ? await getPlaceDetails(placeId) : undefined;
      registeredOffice = {
        route: place?.route,
        streetNumber: place?.streetNumber,
        locality: place?.locality,
        postalCode: place?.postalCode,
        latitude: place?.latitude,
        longitude: place?.longitude,
      };
    } else if (values.companyAddress?.description) {
      const [geoCode] = await geocodeByAddress(values.companyAddress.description);
      registeredOffice = {
        route: geoCode.route,
        streetNumber: geoCode.streetNumber,
        locality: geoCode.locality,
        postalCode: geoCode.postalCode,
        latitude: geoCode.latitude ? Number(geoCode.latitude) : undefined,
        longitude: geoCode.longitude ? Number(geoCode.longitude) : undefined,
      };
    }

    const legal: Legal = {
      companyName: values.companyName || undefined,
      fiscalCode: values.companyFiscalCode || undefined,
      registeredOffice,
    };

    newContacts[0] = { ...legalContact, legal };
  }

  const newValues: AssignmentWizardData = {
    ...existingData,
    isLegalPersonOnly,
    contacts: newContacts,
    isDigitallySigned: false,
  };

  return newValues;
};

export const toPropertyContact = (contact: WizardContact): AssignmentWizardContactFormModel => {
  const { placeOfResidence, identityDocument } = contact;

  return {
    name: contact.name || '',
    phoneNumber: contact.phoneNumber || '',
    email: contact.email || '',
    birthday: contact.birthday ? formatInputDate(new Date(contact.birthday)) : '',
    birthplace: contact.birthplace || '',
    fiscalCode: contact.fiscalCode || '',
    citizenship: contact.citizenship || '',
    placeOfResidence: {
      route: placeOfResidence?.route || '',
      locality: placeOfResidence?.locality || '',
      plateCode: placeOfResidence?.plateCode || '',
      administrativeAreaLevelOne: placeOfResidence?.administrativeAreaLevelOne || '',
      administrativeAreaLevelTwo: placeOfResidence?.administrativeAreaLevelTwo || '',
      latitude: placeOfResidence?.latitude ? `${placeOfResidence?.latitude}` : '',
      longitude: placeOfResidence?.longitude ? `${placeOfResidence?.longitude}` : '',
      postalCode: placeOfResidence?.postalCode || '',
      streetNumber: placeOfResidence?.streetNumber || '',
      normalizedAddress: placeOfResidence?.normalizedAddress ? {
        placeId: '',
        description: placeOfResidence?.normalizedAddress || '',
      } : undefined,
    },
    identityDocument: {
      number: identityDocument?.number || '',
      issuedAt: identityDocument?.issuedAt ? formatInputDate(new Date(identityDocument?.issuedAt)) : '',
      issuedBy: identityDocument?.issuedBy || '',
      type: identityDocument?.type ? IdentityDocumentType[identityDocument.type] : '',
    },
  };
};

export const toWizardContact = (
  contact: WizardContact,
  values: AssignmentWizardContactFormModel,
): WizardContact => {
  const {
    placeOfResidence: {
      administrativeAreaLevelOne,
      administrativeAreaLevelTwo,
      locality,
      postalCode,
      plateCode,
      route,
      streetNumber,
      latitude,
      longitude,
    },
    identityDocument,
  } = values;

  return ({
    ...contact,
    name: values.name.trim() || undefined,
    phoneNumber: values.phoneNumber || undefined,
    email: values.email.trim() || undefined,
    birthday: values.birthday ? new Date(values.birthday).toISOString() : undefined,
    birthplace: values.birthplace || undefined,
    fiscalCode: values.fiscalCode || undefined,
    citizenship: values.citizenship,
    identityDocument: {
      type: identityDocument ? IdentityDocumentType[identityDocument.type as IdentityDocumentType] : undefined,
      number: identityDocument?.number,
      issuedAt: identityDocument?.issuedAt,
      issuedBy: identityDocument?.issuedBy,
    },
    placeOfResidence: {
      route,
      locality,
      plateCode: plateCode?.toUpperCase() || undefined,
      administrativeAreaLevelOne: administrativeAreaLevelOne || undefined,
      administrativeAreaLevelTwo: administrativeAreaLevelTwo || undefined,
      latitude: latitude ? Number(latitude) : undefined,
      longitude: longitude ? Number(longitude) : undefined,
      postalCode: postalCode || undefined,
      streetNumber: streetNumber || undefined,
      normalizedAddress: getNormalizedAddress({
        route,
        streetNumber,
        postalCode: postalCode || '',
        locality: locality || '',
      }),
    },
  });
};
