import React from 'react';
import {
  Action, ConfirmModal, Portal, Text, useModal, useNotifications,
} from '@doveit/bricks';
import { Prospect } from '../../../providers/api/dtos'; import { useComputedProspectApprovalStatus } from '../../hooks/use-computed-prospect-approval-status/useComputedProspectApprovalStatus';
import { updateProspect } from '../../../providers/api/prospect/prospectProvider';

interface ChildFnProps {
  cancelApproval: VoidFunction,
  label: string,
  ariaLabel: string,
  isSaving: boolean,
}

export interface CancelProspectApprovalActionProps extends React.AriaAttributes {
  prospect: Prospect,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (prospect: Prospect) => void,
}

export const CANCEL_PROSPECT_APPROVAL_SUCCESS_MESSAGE = 'La richiesta di approvazione è stata annullata con successo.';
export const CANCEL_PROSPECT_APPROVAL_ERROR_MESSAGE = 'Non è stato possibile annullare la richiesta di approvazione.';

const CancelProspectApprovalAction: React.FC<CancelProspectApprovalActionProps> = ({
  prospect,
  children,
  onSuccess,
  ...rest
}) => {
  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);
  const { data: { canBeUpdatedTo } } = useComputedProspectApprovalStatus(prospect);

  const shouldBeUpdatedTo = 'TO_BE_REQUESTED';

  const canBeCancelled = React.useMemo(
    () => canBeUpdatedTo(shouldBeUpdatedTo),
    [canBeUpdatedTo, shouldBeUpdatedTo],
  );

  const handleConfirm = React.useCallback(async () => {
    try {
      setIsSaving(true);

      const updatedProspect = await updateProspect(prospect.id!, {
        ...prospect,
        approval: undefined,
      });

      setIsSaving(false);
      modal.close();
      addSuccess(CANCEL_PROSPECT_APPROVAL_SUCCESS_MESSAGE);

      onSuccess?.(updatedProspect);
    } catch (err) {
      setIsSaving(false);
      addError(CANCEL_PROSPECT_APPROVAL_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, modal, onSuccess, prospect]);

  if (!canBeCancelled) return null;

  return (
    <>
      {children ? children({
        cancelApproval: modal.open,
        label: 'Annulla richiesta',
        ariaLabel: 'Azione per annullare la richiesta di approvazione',
        isSaving,
      }) : (
        <Action
          label="Annulla richiesta"
          aria-label="Azione per annullare la richiesta di approvazione"
          size="S"
          loading={isSaving}
          onClick={() => modal.open()}
          {...rest}
        />
      )}
      <Portal>
        <ConfirmModal
          isOpen={modal.isOpen}
          saving={isSaving}
          aria-label="Modale per annullare la richiesta di approvazione"
          title="Conferma annullamento richiesta di approvazione"
          onConfirm={handleConfirm}
          onAbort={() => modal.close()}
          dismissable
        >
          <Text.Body>
            Confermando l&apos;operazione la richiesta di approvazione della valutazione sarà annullata.
          </Text.Body>
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default CancelProspectApprovalAction;
