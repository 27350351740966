import * as Yup from 'yup';
import { inRange } from 'lodash';
import { ExtraPackagePriceType } from '../../../../../domain/types';
import { CommissionPrice } from '../../../../../providers/api/dtos';

export default Yup.object({
  buyerCommission: Yup.string().oneOf(['true', 'false']).required('Il campo è obbligatorio'),
  type: Yup.string().oneOf(Object.values(CommissionPrice)).required('Il campo è obbligatorio'),
  value: Yup.string().matches(/^\d*\.?\d*$/).test('isValid', 'Valore invalido', (value, ctx) => {
    const { buyerCommission, type } = ctx.parent;

    if (!value) return true;

    const numericValue = value ? parseFloat(value) : -1;

    if (buyerCommission === 'true' && type === ExtraPackagePriceType.FIXED && numericValue < 1) {
      return ctx.createError({
        path: 'value',
        message: 'Il valore minimo è 1€',
      });
    }

    if (buyerCommission === 'true' && type === ExtraPackagePriceType.PERCENTAGE && !inRange(numericValue, 0.01, 100.01)) {
      return ctx.createError({
        path: 'value',
        message: 'Il valore deve essere compreso tra 0 e 100%',
      });
    }

    return true;
  }).optional(),
});
