import React from 'react';
import {
  ActionIcon,
  ConfirmModal, ICON_RESTORE, Portal, Typography, useModal, useNotifications,
} from '@doveit/bricks';
import { Lead } from '../../../providers/api/dtos';
import { LEAD_KO_STATUSES } from '../../../contact/containers/view-contact-activities-summary/leads/constants';
import { WithRequiredKeys } from '../../../types';
import { LeadStatus } from '../../../domain/types';
import { updateLead } from '../../../providers/api/lead/leadProvider';

export const RESTORE_LEAD_SUCCESS_MESSAGE = 'La lead è stata ripristinata con successo';
export const RESTORE_LEAD_ERROR_MESSAGE = 'Non è stato possibile ripristinare la lead';

interface ChildFnProps {
  disabled?: boolean,
  openRestoreLeadModal: VoidFunction,
}

export interface RestoreLeadActionProps {
  lead: Lead,
  disabled?: boolean,
  onSuccess?: (restoredLead: Lead) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

const RestoreLeadAction: React.FC<RestoreLeadActionProps> = ({
  lead,
  disabled = false,
  onSuccess,
  children,
}) => {
  const {
    id,
    status,
  } = lead as WithRequiredKeys<Lead, 'id'>;

  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const restoreLeadModal = useModal();

  const isRestorable = React.useMemo(() => LEAD_KO_STATUSES.includes(status), [status]);

  const openRestoreLeadModal = React.useCallback(() => {
    restoreLeadModal.open();
  }, [restoreLeadModal]);

  const onConfirm = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const leadToRestore: Lead = {
        ...lead,
        status: LeadStatus.IN_PROGRESS,
      };

      const restoredLead = await updateLead(id, leadToRestore);

      setIsSaving(false);
      restoreLeadModal.close();
      addSuccess(RESTORE_LEAD_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(restoredLead);
      }
    } catch (error) {
      setIsSaving(false);
      addError(RESTORE_LEAD_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, restoreLeadModal, id, onSuccess, lead]);

  return (
    <>
      {children?.({ disabled: !isRestorable, openRestoreLeadModal }) ?? (
        <ActionIcon
          label="Ripristina"
          aria-label="Ripristina lead"
          title={isRestorable ? 'Ripristina la lead' : 'Non è possibile ripristinare la lead'}
          disabled={disabled || !isRestorable}
          icon={{ path: ICON_RESTORE }}
          color="success"
          onClick={openRestoreLeadModal}
        />
      )}
      <Portal>
        <ConfirmModal
          isOpen={restoreLeadModal.isOpen}
          aria-label="Conferma il ripristino della lead"
          title="Ripristina lead"
          saving={isSaving}
          onConfirm={onConfirm}
          onAbort={restoreLeadModal.close}
        >
          <Typography.BODY>
            Confermando l&apos;azione la lead sarà riportata nello stato Da processare.
          </Typography.BODY>
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default RestoreLeadAction;
