import { FeatureType, HeatingType } from '../../domain/types';

export const featureTypeLabels: Record<FeatureType, string> = {
  CAMINO: 'Camino',
  ALLARME: 'Allarme',
  VIDEOCITOFONO: 'Videocitofono',
  GIARDINO_CONDOMINIALE: 'Giardino condominiale',
  DOMOTICA: 'Domotica',
  PORTIERE: 'Portiere',
  ASCENSORE: 'Ascensore',
  EDIFICIO_STORICO: 'Edificio storico',
  PANNELLI_SOLARI: 'Pannelli solari',
  PISCINA: 'Piscina',
  PORTA_BLINDATA: 'Porta blindata',
  ARMADI_A_MURO: 'Armadi a muro',
  IDROMASSAGGIO: 'Idromassaggio',
  CANCELLO_ELETTRICO: 'Cancello elettrico',
  FIBRA_OTTICA: 'Fibra ottica',
  CAMPO_DA_TENNIS: 'Campo da tennis',
  VISTA_MARE: 'Vista mare',
  VISTA_MONTAGNA: 'Vista montagna',
  FATTORIA: 'Fattoria',
  ELIPORTO: 'Eliporto',
  VIGNETO: 'Vigneto',
  PALESTRA: 'Palestra',
  VICINANZA_IMPIANTI_SCIISTICI: 'Vicinanza imp. sciistici',
  CANTINA_VINI: 'Cantina vini',
  SCUDERIE: 'Scuderie',
  MOLO: 'Molo',
};

export const heatingTypeLabels: Record<HeatingType, string> = {
  CENTRALIZZATO: 'Centralizzato',
  AUTONOMO: 'Autonomo',
};
