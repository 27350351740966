/* eslint-disable react/no-unknown-property */
import React, { } from 'react';
import { isEqual } from 'lodash';
import { OfferWizardStepProps } from '../types';
import {
  toOfferWizardData, toOfferWizardPaymentsFormModel,
} from './mappers';
import OfferWizardPaymentsForm, { OfferWizardPaymentsFormModel } from './OfferWizardPaymentsForm';

const OfferWizardPayments: React.FC<OfferWizardStepProps> = ({
  initialData,
  formRef,
  onSubmit,
}) => {
  const onInternalSubmit = React.useCallback(async (formValues: OfferWizardPaymentsFormModel) => {
    const updatedOfferWizardData = toOfferWizardData(
      initialData,
      formValues,
    );
    const isFormDirty = !isEqual(toOfferWizardPaymentsFormModel(initialData), formValues);
    onSubmit(updatedOfferWizardData, isFormDirty);
  }, [initialData, onSubmit]);

  return (
    <OfferWizardPaymentsForm
      initialValues={toOfferWizardPaymentsFormModel(initialData)}
      onSubmit={onInternalSubmit}
      formRef={formRef}
    />
  );
};

export default OfferWizardPayments;
