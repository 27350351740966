import React from 'react';
import {
  ActionDropDown, Badge, Dropdown, Skeleton,
} from '@doveit/bricks';
import { useTheme } from 'styled-components';
import { Property } from '../../../providers/api/dtos';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { statusActionColor, statusActionIcon, statusLabels } from '../../../labels';
import { useComputedPropertyStatus } from '../../hooks/use-computed-property-status/useComputedPropertyStatus';
import { Status } from '../../../domain/types';
import PreviewPropertyAction from '../preview-property-action/PreviewPropertyAction';
import PublishPropertyAction from '../publish-property-action/PublishPropertyAction';
import WithdrawPropertyAction from '../withdraw-property-action/WithdrawPropertyAction';
import DraftPropertyAction from '../draft-property-action/DraftPropertyAction';
import NegotiationPropertyAction from '../negotiation-property-action/NegotiationPropertyAction';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';

export interface UpdatePropertyStatusProps {
  property: Property,
  onSuccess?: (updatedProperty: Property) => void,
  emphasis?: 'low' | 'high',
  size?: 'XS' | 'S',
  showIcon?: boolean,
}

const UpdatePropertyStatus: React.FC<UpdatePropertyStatusProps> = ({
  property,
  onSuccess,
  emphasis = 'low',
  size = 'S',
  showIcon = false,
}) => {
  const theme = useTheme();
  const { userIsAdmin, userIsContentEditor } = useRBAC();
  const { data: propertyAgent, error: propertyAgentError, isLoading: propertyAgentIsLoading } = useAgentByPropertyId(property.id);
  const isPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(propertyAgent?.id);

  const { data: computedPropertyStatus, errors: computedPropertyStatusError, isLoading: isComputedPropertyStatusLoading } = useComputedPropertyStatus(property);

  const showAsBadge = React.useMemo(
    () => !computedPropertyStatus?.canBeUpdated || !(userIsAdmin || userIsContentEditor || isPropertyAgentOrManager),
    [computedPropertyStatus?.canBeUpdated, isPropertyAgentOrManager, userIsAdmin, userIsContentEditor],
  );

  const canBeUpdatedTo = computedPropertyStatus?.canBeUpdatedTo;

  if (propertyAgentError || computedPropertyStatusError) {
    return null;
  }

  if (propertyAgentIsLoading || isComputedPropertyStatusLoading) {
    return (
      <Skeleton
        width="7rem"
        height={theme.remHeight[size]}
        aria-label="Stato dell'immobile in caricamento"
      />
    );
  }

  return (
    (showAsBadge)
      ? (
        <Badge
          label={statusLabels[property.status]}
          color={statusActionColor[property.status]}
          icon={showIcon ? statusActionIcon[property.status] : undefined}
          emphasis={emphasis}
          size={size}
          aria-label="Badge per mostrare lo stato dell'immobile"
        />
      )
      : (
        <ActionDropDown
          label={statusLabels[property.status]}
          color={statusActionColor[property.status]}
          iconLeft={showIcon ? { path: statusActionIcon[property.status] } : undefined}
          emphasis={emphasis}
          size={size}
          aria-label="Selezione per aggiornare lo stato dell'immobile"
        >
          {canBeUpdatedTo?.(Status.PROPOSTA) && (
            <NegotiationPropertyAction
              property={property}
              aria-label="Pulsante per mettere l'immobile in trattativa"
              onSuccess={onSuccess}
            >
              {({ onNegotiation }) => (
                <Dropdown.Option
                  label="Metti in trattativa"
                  onClick={onNegotiation}
                />
              )}
            </NegotiationPropertyAction>
          )}

          {canBeUpdatedTo?.(Status.LIVE) && (
            <PublishPropertyAction
              aria-label="Pulsante di pubblicazione immobile"
              property={property}
              onSuccess={onSuccess}
            >
              {({ publish }) => (
                <Dropdown.Option
                  label={property.status === Status.PROPOSTA ? 'Nascondi trattativa' : 'Pubblica'}
                  onClick={publish}
                />
              )}
            </PublishPropertyAction>
          )}

          {canBeUpdatedTo?.(Status.ANTEPRIMA) && (
            <PreviewPropertyAction
              aria-label="Pulsante di anteprima immobile"
              property={property}
              onSuccess={onSuccess}
            >
              {({ preview }) => (
                <Dropdown.Option
                  label="Pubblica in anteprima"
                  onClick={preview}
                />
              )}
            </PreviewPropertyAction>
          )}

          {canBeUpdatedTo?.(Status.DRAFT) && (
            <DraftPropertyAction
              aria-label="Pulsante di bozza immobile"
              property={property}
              onSuccess={onSuccess}
            >
              {({ onDraft: onDraftSuccess }) => (
                <Dropdown.Option
                  label={property.status === Status.RITIRATO ? 'Ripristina' : 'Sposta in bozza'}
                  onClick={onDraftSuccess}
                />
              )}
            </DraftPropertyAction>
          )}

          {canBeUpdatedTo?.(Status.RITIRATO) && (
            <WithdrawPropertyAction
              aria-label="Pulsante di ritiro immobile"
              property={property}
              onSuccess={onSuccess}
            >
              {({ withdraw }) => (
                <Dropdown.Option
                  label="Ritira"
                  onClick={withdraw}
                />
              )}
            </WithdrawPropertyAction>
          )}
        </ActionDropDown>
      )
  );
};

export default UpdatePropertyStatus;
