import { SelectOption } from '@doveit/bricks';
import { prospectStatusLabels } from '../labels';
import { prospectKOStatus } from '../prospect/constants';

export const prospectKOStatusSelectOptions: SelectOption[] = [
  {
    value: '',
    label: 'Seleziona motivo archiviazione',
    disabled: true,
  },
  ...prospectKOStatus
    .sort((a, b) => prospectStatusLabels[a].localeCompare(prospectStatusLabels[b], 'it'))
    .map((status) => ({
      value: status,
      label: prospectStatusLabels[status],
    })),
];
