import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const actions: ThemedFunctor = ({ theme }) => css`
  display: flex;
  gap: ${theme.remSize[75]};
`;

export const notesPopover: ThemedFunctor = ({ theme }) => css`
  min-width: 25rem;
  padding: ${theme.spaceUnits.desktop.SMALLER};
`;
