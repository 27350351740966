import React from 'react';
import { Form, FormActionsAlign, FormProps } from '@doveit/bricks';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { energyClassLabels } from '../../../labels';
import { registryCategoryLabels } from '../../../labels/registryCategoryLabels';
import { propertyUnitIntendedUseLabels } from '../../../labels/propertyUnitIntendedUseLabels';
import validationSchema from './PropertyUnitForm.schema';
import { INPUT_PLACEHOLDERS } from '../../../constants';

export interface PropertyUnitFormModel {
  section: string,
  sheet: string,
  parcel: string,
  subunit: string,
  category: string,
  intendedUse: string,
  value: string,
  energyClass: string,
}

export interface PropertyUnitFormProps extends React.AriaAttributes {
  initialValues?: Partial<PropertyUnitFormModel>,
  loading?: FormProps<PropertyUnitFormModel>['loading'],
  disabled?: FormProps<PropertyUnitFormModel>['disabled'],
  innerRef?: FormProps<PropertyUnitFormModel>['innerRef'],
  submitLabel?: string,
  hideSubmit?: boolean,
  onSubmit: FormProps<PropertyUnitFormModel>['onSubmit'],
}

export const DEFAULT_INITIAL_VALUES: PropertyUnitFormModel = {
  section: '',
  sheet: '',
  parcel: '',
  subunit: '',
  category: '',
  intendedUse: '',
  value: '',
  energyClass: '',
};

const PropertyUnitForm: React.FC<PropertyUnitFormProps> = (props) => {
  const {
    initialValues = {},
    innerRef,
    hideSubmit = false,
    submitLabel = 'Salva',
    onSubmit,
    ...rest
  } = props;

  const mergedInitialValues: PropertyUnitFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      innerRef={innerRef}
      onSubmit={onSubmit}
      {...rest}
    >
      <Form.Item size={{ MD: 1 / 4 }}>
        <Form.Input
          name="section"
          label="Sezione"
          aria-label="Campo per inserire la sezione dell'unità immobiliare"
          placeholder={INPUT_PLACEHOLDERS.PROPERTY_UNIT_SECTION}
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 4 }}>
        <Form.Input
          name="sheet"
          label="Foglio"
          aria-label="Campo per inserire il riferimento al foglio dell'unità immobiliare"
          placeholder={INPUT_PLACEHOLDERS.PROPERTY_UNIT_SHEET}
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 4 }}>
        <Form.Input
          name="parcel"
          label="Particella"
          aria-label="Campo per inserire il riferimento alla particella dell'unità immobiliare"
          placeholder={INPUT_PLACEHOLDERS.PROPERTY_UNIT_PARCEL}
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 4 }}>
        <Form.Input
          name="subunit"
          label="Subalterno"
          aria-label="Campo per inserire il riferimento al subalterno dell'unità immobiliare"
          placeholder={INPUT_PLACEHOLDERS.PROPERTY_UNIT_SUBUNIT}
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 4 }}>
        <Form.Select
          name="category"
          label="Categoria catastale"
          options={asSelectOptions(registryCategoryLabels, {
            label: 'Seleziona',
            value: '',
            disabled: true,
          })}
          aria-label="Campo per inserire la categoria catastale dell'unità immobiliare"
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 4 }}>
        <Form.Select
          name="intendedUse"
          label="Destinazione d'uso"
          options={asSelectOptions(propertyUnitIntendedUseLabels, {
            label: 'Seleziona',
            value: '',
            disabled: true,
          })}
          aria-label="Campo per inserire la destinazione d'uso dell'unità immobiliare"
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 4 }}>
        <Form.DecimalPriceField
          name="value"
          label="Rendita catastale"
          aria-label="Campo per inserire il valore della rendita catastale"
          placeholder={INPUT_PLACEHOLDERS.PROPERTY_UNIT_CADASTRAL_INCOME}
        />
      </Form.Item>

      <Form.Item size={{ MD: 1 / 4 }}>
        <Form.Select
          id="energyClass"
          name="energyClass"
          label="Classe energetica"
          options={asSelectOptions(energyClassLabels)}
          aria-label="Campo per la classe energetica dell'unità immobiliare"
        />
      </Form.Item>
      {!hideSubmit && (
        <Form.Actions align={FormActionsAlign.RIGHT}>
          <Form.Submit label={submitLabel} />
        </Form.Actions>
      )}
    </Form>
  );
};

export default PropertyUnitForm;
