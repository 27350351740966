/* eslint-disable react/no-unknown-property */
import {
  Dropdown,
  ICON_CASH_MULTIPLE, ICON_HOME_OUTLINE, ICON_MAP_MARKER_OUTLINE, Message,
} from '@doveit/bricks';
import { formatEuro, formatSquareMeters } from '@doveit/hammer';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '../../../../../components/divider/Divider';
import { statusLabels } from '../../../../../labels';
import { badgeColor } from '../../../../../property/components/property-status-badge/PropertyStatusBadge';
import { NO_VALUE_SYMBOL, formatPropertyType } from '../../../../../property/utils';
import { Property } from '../../../../../providers/api/dtos';
import ContactActivityCard from '../../../../components/contact-activity-card/ContactActivityCard';
import ContactActivityCardSkeleton from '../../../../components/contact-activity-card/ContactActivityCard.skeleton';
import useContactProperties from '../../../../hooks/use-contact-properties/useContactProperties';
import type { ViewContactActivitiesSummaryProps } from '../../ViewContactActivitiesSummary';
import * as styles from '../../ViewContactActivitiesSummary.style';
import { CONTACT_PROPERTIES_LOAD_ERROR, PROPERTIES_STATUS_SORT_ORDER } from '../constants';
import { orderByStatus } from '../../utils';

const ContactPropertiesSummaryGroup: React.FC<ViewContactActivitiesSummaryProps> = ({
  contactId,
}) => {
  const navigate = useNavigate();
  const { data: propertiesContact, error: propertiesError } = useContactProperties(contactId);

  const propertyActions = React.useCallback((propertyId: Property['id']) => [
    {
      key: 'see-property',
      label: 'Vedi immobile',
      onClick: () => navigate(`/properties/${propertyId}`),
    },
    {
      key: 'see-listing',
      label: 'Vedi annuncio',
      onClick: () => window.open(`https://www.dove.it/vendita-case/dove/dettaglio-immobile/${propertyId}`, '_blank'),
    },
  ], [navigate]);

  if (propertiesError) {
    return (
      <Message
        type="critical"
        message={CONTACT_PROPERTIES_LOAD_ERROR}
      />
    );
  }

  if (!propertiesContact) {
    return <ContactActivityCardSkeleton />;
  }

  if (propertiesContact.length === 0) {
    return null;
  }

  return (
    <div aria-label="Lista degli immobili del contatto">
      <Divider>
        Immobili
      </Divider>

      <div css={styles.activityGroupWrapper}>
        {orderByStatus(propertiesContact.map((propertyContact) => propertyContact.property), PROPERTIES_STATUS_SORT_ORDER).map((property) => {
          const {
            id, status, propertyType, propertySize, geo, price, updatedAt, createdAt,
          } = property;

          return (
            <ContactActivityCard
              key={id}
              type="property"
              status={statusLabels[status]}
              statusColor={badgeColor[status]}
              createdAt={createdAt?.toString()}
              updatedAt={updatedAt?.toString()}
              actions={propertyActions(id).map((action) => (
                <Dropdown.Option {...action} />
              ))}
              features={[
                {
                  icon: ICON_HOME_OUTLINE,
                  label: `${formatPropertyType(propertyType)}, ${propertySize ? formatSquareMeters(propertySize) : ' - '}`,
                  title: 'Dettagli immobile',
                },
                {
                  icon: ICON_MAP_MARKER_OUTLINE,
                  title: 'Indirizzo immobile',
                  label: geo?.normalizedAddress || ' - ',
                },
                {
                  icon: ICON_CASH_MULTIPLE,
                  title: 'Prezzo',
                  label: `${price ? formatEuro(price) : NO_VALUE_SYMBOL}`,
                },
              ]}
            />
          );
        })}
      </div>
    </div>

  );
};

export default ContactPropertiesSummaryGroup;
