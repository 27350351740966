import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { COUNTRY } from '../../../constants';

export const LINKEDIN_REGEX = /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/gm;

export default Yup.object().shape({
  name: Yup.string()
    .required('Il campo è obbligatorio'),
  phoneNumber: Yup
    .string()
    .when('email', {
      is: (email: string) => !email || email.length === 0,
      then: (schema) => schema.required('Devi inserire numero di telefono o email'),
      otherwise: (schema) => schema.notRequired(),
    })
    .test('isValid', 'Inserisci un numero di telefono valido', (value: string | undefined) => !value || isValidPhoneNumber(value, COUNTRY)),
  email: Yup
    .string()
    .trim()
    .email('Inserisci un indirizzo email valido')
    .when('phoneNumber', {
      is: (phoneNumber: string) => !phoneNumber || phoneNumber.length === 0,
      then: (schema) => schema.required('Devi inserire email o numero di telefono'),
      otherwise: (schema) => schema.notRequired(),
    }),
  linkedIn: Yup
    .string()
    .matches(LINKEDIN_REGEX, 'Inserisci un URL LinkedIn valido')
    .notRequired(),
}, [['email', 'phoneNumber']]);
