import React, {
  FormEvent,
  FunctionComponent, MutableRefObject, useCallback, useRef, useState, useEffect,
} from 'react';
import {
  Action, Form, FormHandlers, HStack, Message, Skeleton, Stack, useNotifications,
} from '@doveit/bricks';
import { DocumentStatus } from '../../../domain/types';
import { updateDocument } from '../../../providers/api/document/documentProvider';
import { Document } from '../../../providers/api/dtos';
import useDocument from '../../hooks/use-document/useDocument';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import validationSchema from './rejectReviewDocument.schema';

export type EditableFieldState = 'editable' | 'disabled' | 'hidden';

interface ReviewDocumentFormModel {
  reviewNotes: string,
}

export interface ReviewDocumentProps {
  documentId: number,
  canReview?: boolean,
  reviewNotesState?: EditableFieldState,
  onSuccess?: (updatedDocument: Document) => void,
}

export const DOCUMENT_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare il documento';
export const DOCUMENT_APPROVED_SUCCESS_MESSAGE = 'Documento approvato con successo';
export const DOCUMENT_REJECTED_SUCCESS_MESSAGE = 'Documento rifiutato con successo';
export const DOCUMENT_REVIEW_ERROR_MESSAGE = 'Non è stato possibile completare la revisione';

const ReviewDocument: FunctionComponent<ReviewDocumentProps> = ({
  documentId,
  canReview,
  reviewNotesState = 'hidden',
  onSuccess,
}) => {
  const formRef = useRef<FormHandlers>() as MutableRefObject<FormHandlers>;
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();
  const { data: document, error: documentError, mutate: mutateDocument } = useDocument(documentId);
  const [targetStatus, setTargetStatus] = useState<DocumentStatus | undefined>();
  const [reviewTryCounter, setReviewTryCounter] = useState(0);
  const { user } = useRBAC();

  useEffect(() => {
    if (targetStatus && [DocumentStatus.APPROVED, DocumentStatus.REJECTED].includes(targetStatus)) {
      const formEvent = {} as any as FormEvent<HTMLFormElement>;

      formRef.current.handleSubmit(formEvent);
    }
  }, [targetStatus, reviewTryCounter]);

  const onSubmit = useCallback(async ({ reviewNotes }: ReviewDocumentFormModel) => {
    try {
      setIsSaving(true);

      const documentToUpdate: Document = {
        ...document!,
        reviewNotes: targetStatus === DocumentStatus.APPROVED ? undefined : reviewNotes,
        status: targetStatus!,
        approvedBy: targetStatus === DocumentStatus.APPROVED ? user?.email : undefined,
        approvedAt: targetStatus === DocumentStatus.APPROVED ? new Date().toISOString() : undefined,
      };

      const updatedDocument = await updateDocument(document!.id!, documentToUpdate);

      setIsSaving(false);
      addSuccess(targetStatus === DocumentStatus.APPROVED
        ? DOCUMENT_APPROVED_SUCCESS_MESSAGE
        : DOCUMENT_REJECTED_SUCCESS_MESSAGE);
      mutateDocument();

      if (onSuccess) {
        onSuccess(updatedDocument);
      }
    } catch (err) {
      setIsSaving(false);
      addError(DOCUMENT_REVIEW_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, document, targetStatus, mutateDocument, onSuccess, user?.email]);

  const onReviewActionClick = useCallback((status: DocumentStatus) => () => {
    setReviewTryCounter((value) => value + 1);
    setTargetStatus(status);
  }, []);

  const formInitialValues: ReviewDocumentFormModel = React.useMemo(() => ({
    reviewNotes: document?.reviewNotes || '',
  }), [document?.reviewNotes]);

  if (documentError) {
    return (
      <Message
        type="critical"
        message={DOCUMENT_LOAD_ERROR_MESSAGE}
      />
    );
  }

  if (!document) {
    return <Skeleton count={5} />;
  }

  return (
    <Stack>
      {reviewNotesState !== 'hidden' && (
        <Form
          initialValues={formInitialValues}
          innerRef={formRef}
          loading={isSaving}
          onSubmit={onSubmit}
          validationSchema={targetStatus === DocumentStatus.REJECTED
            ? validationSchema
            : undefined}
          enableReinitialize
        >
          <Form.Item>
            <Form.TextArea
              aria-label="Campo per l'inserimento delle note di revisione"
              label="Note di revisione"
              name="reviewNotes"
              rows={3}
              disabled={reviewNotesState === 'disabled'}
            />
          </Form.Item>
        </Form>
      )}
      {canReview && (
        <HStack>
          {[DocumentStatus.REVIEW, DocumentStatus.REJECTED].includes(document.status) && (
            <Action
              label="Approva"
              color="success"
              emphasis="high"
              loading={isSaving}
              onClick={onReviewActionClick(DocumentStatus.APPROVED)}
            />
          )}
          {[DocumentStatus.REVIEW, DocumentStatus.APPROVED].includes(document.status) && (
            <Action
              label="Rifiuta"
              color="critical"
              emphasis="high"
              loading={isSaving}
              onClick={onReviewActionClick(DocumentStatus.REJECTED)}
            />
          )}
        </HStack>
      )}
    </Stack>
  );
};

export default ReviewDocument;
