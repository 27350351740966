import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as Yup from 'yup';
import { CustomUser, UserRole } from '../../types';
import { hasAnyRole } from '../../utils/users/usersUtils';
import { getMainUserRole } from './utilities/getMainUserRole';

export interface UseRBACResult {
  loading: boolean,
  user: CustomUser,
  mainUserRole: UserRole,
  userHasAnyRole: (roles: UserRole | UserRole[]) => boolean,
  userIsAgent: boolean,
  userIsContentEditor: boolean,
  userIsCallCenter: boolean,
  userIsFinance: boolean,
  userIsHR: boolean,
  userIsAdmin: boolean,
  userIsAreaManager: boolean,
  userIsCoordinator: boolean,
}

const IMPERSONIFICATION_SESSION_KEY = 'impersonate_user';

const schema = Yup.object({
  email: Yup
    .string()
    .email()
    .required(),
  name: Yup
    .string()
    .min(1)
    .required(),
  'https://backoffice.dove.it/roles': Yup
    .array(
      Yup
        .string()
        .oneOf(Object.values(UserRole))
        .required(),
    ).required(),
});

// @TODO: capire perché currentUser è undefined quando viene richiamata nell'app layout
function impersonateUser(currentUser: CustomUser = {} as CustomUser) {
  if (currentUser.can_impersonate) {
    const impersonationValues = window.sessionStorage.getItem(IMPERSONIFICATION_SESSION_KEY);
    if (impersonationValues) {
      try {
        const impersonationData = schema.validateSync(JSON.parse(impersonationValues!));
        return { ...currentUser, ...impersonationData };
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Error during impersonification: ${err}`);
        window.sessionStorage.removeItem(IMPERSONIFICATION_SESSION_KEY);
      }
    }
  }
  return currentUser;
}

export default function useRBAC(): UseRBACResult {
  const { user, isLoading } = useAuth0();

  const currentUser = impersonateUser(user as CustomUser);

  const userHasAnyRole = React.useCallback(
    (roles: UserRole | UserRole[]) => hasAnyRole(currentUser!, roles),
    [currentUser],
  );

  const mainUserRole = React.useMemo(() => getMainUserRole(currentUser as CustomUser), [currentUser]);

  const userIs = React.useMemo(() => ({
    userIsAgent: userHasAnyRole(UserRole.AGENT),
    userIsContentEditor: userHasAnyRole(UserRole.CONTENT_EDITOR),
    userIsCallCenter: userHasAnyRole(UserRole.CALL_CENTER),
    userIsFinance: userHasAnyRole(UserRole.FINANCE),
    userIsHR: userHasAnyRole(UserRole.HR),
    userIsAdmin: userHasAnyRole(UserRole.ADMIN),
    userIsAreaManager: userHasAnyRole(UserRole.AREA_MANAGER),
    userIsCoordinator: userHasAnyRole(UserRole.COORDINATOR),
  }), [userHasAnyRole]);

  return {
    loading: isLoading,
    user: currentUser as CustomUser,
    mainUserRole,
    userHasAnyRole,
    ...userIs,
  };
}
