import {
  Action, ActionIcon, Badge, Card, CardSkeleton, DetailItemList, Dropdown, FontWeight, Grid, HStack, ICON_ALERT_CIRCLE_OUTLINE, ICON_EYE_OUTLINE, ICON_HANDSHAKE_OUTLINE, Message, Skeleton, Spacing, Text,
} from '@doveit/bricks';
import { formatDate, formatEuro } from '@doveit/hammer';
import React from 'react';
import objectHash from 'object-hash';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import {
  IntentStatus, InvoiceStatus, OfferDeedStatus, OfferStatus, ReferenceType, REVIEW_USER_TYPES, Status,
} from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Property, Review } from '../../../providers/api/dtos';
import useProperty from '../../hooks/use-property/useProperty';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import EditOfferDeedAction from '../../../offer/containers/edit-offer-deed-action/EditOfferDeedAction';
import useOffersByIntentId from '../../../offer/hooks/use-offers-by-intent-id/useOffersByIntentId';
import useIntents from '../../../intent/hooks/use-intents/useIntents';
import useContact from '../../../contact/hooks/use-contact/useContact';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import useOfferWizardData from '../../../offer/hooks/use-offer-wizard-data/useOfferWizardData';
import { goToDetailPage } from '../../../utils/navigate/utils';
import SimpleTableRowSkeleton from '../../../components/simple-table/SimpleTableRow.skeleton';
import useInvoiceByProperty from '../../../invoice/hooks/use-invoice-by-property/useInvoiceByProperty';
import {
  invoiceStatusColors, invoiceStatusIcons, invoiceStatusLabels, reviewUserTypeLabels,
} from '../../../labels';
import ManageInvoiceStatusAction from '../manage-invoice-status-action/ManageInvoiceStatusAction';
import useReviewsByPropertyId from '../../../review/hooks/use-reviews-by-property-id/useReviewsByPropertyId';
import TrustpilotRating from '../../../trustpilot/components/trustpilot-rating/TrustpilotRating';
import ViewTrustpilotReviewAction from '../view-trustpilot-review-action/ViewTrustpilotReviewAction';
import RequestPropertyReviewsAction from '../request-property-reviews-action/RequestPropertyReviewsAction';

export const LOAD_PROPERTY_SALE_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni di compravendita.';
export const LOAD_PROPERTY_INVOICE_ERROR_MESSAGE = 'Non è stato possibile caricare i dati relativi ai pagamenti.';
export const PROPERTY_REVIEWS_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare le recensioni';

export interface PropertySaleWidgetProps extends React.AriaAttributes {
  propertyId: NonNullable<Property['id']>,
}

const PropertySaleWidget: React.FC<PropertySaleWidgetProps> = ({
  propertyId,
  ...rest
}) => {
  const { userIsAdmin, userIsFinance, userIsContentEditor } = useRBAC();
  const { data: property, error: errorProperty, isLoading: isPropertyLoading } = useProperty(propertyId);
  const isPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(property?.agentEmail);
  const { data: intentsWithBoughtStatus, isLoading: isIntentsLoading, error: errorIntents } = useIntents(property?.status === Status.VENDUTO
    ? { propertyId, status: IntentStatus.BOUGHT }
    : undefined);

  const canShow = React.useMemo(() => property?.status === Status.VENDUTO && (userIsAdmin || userIsFinance || userIsContentEditor || isPropertyAgentOrManager), [isPropertyAgentOrManager, property?.status, userIsAdmin, userIsContentEditor, userIsFinance]);
  const canEditPayments = React.useMemo(() => userIsAdmin || userIsFinance, [userIsAdmin, userIsFinance]);
  const intentWithBoughtStatus = React.useMemo(() => intentsWithBoughtStatus?.content[0],
    [intentsWithBoughtStatus?.content]);

  const {
    data: confirmedOffers, isLoading: isOffersLoading, error: errorOffers, mutate: mutateConfirmedOffers,
  } = useOffersByIntentId(intentWithBoughtStatus?.id, OfferStatus.CONFIRMED);

  const { data: intentBuyer, error: intentBuyerError, isLoading: isIntentBuyerLoading } = useContact(intentWithBoughtStatus?.contactId);
  const { data: offerWizardData, error: offerWizardDataError, isLoading: isOfferWizardDataLoading } = useOfferWizardData(intentWithBoughtStatus?.id);
  const offerWizardBuyerContacts = React.useMemo(() => offerWizardData?.buyerContacts, [offerWizardData]);

  const buyersToShow = React.useMemo(() => {
    if (offerWizardBuyerContacts) {
      return offerWizardBuyerContacts;
    }
    if (intentBuyer) {
      return [intentBuyer];
    }
    return [];
  }, [offerWizardBuyerContacts, intentBuyer]);

  const offer = React.useMemo(() => confirmedOffers?.[0], [confirmedOffers]);

  const thereAreErrors = React.useMemo(() => intentBuyerError || (offerWizardDataError && offerWizardDataError.response?.status !== 404), [intentBuyerError, offerWizardDataError]);
  const dataAreLoading = React.useMemo(() => isIntentBuyerLoading || isOfferWizardDataLoading, [isIntentBuyerLoading, isOfferWizardDataLoading]);

  const {
    data: invoice, isLoading: isInvoiceLoading, error: invoiceError, mutate: mutateInvoice,
  } = useInvoiceByProperty(property?.status === Status.VENDUTO ? propertyId : undefined);

  const {
    data: reviews, isLoading: areReviewsLoading, error: reviewsError,
    mutate: mutateReviews,
  } = useReviewsByPropertyId(property?.status === Status.VENDUTO && offer?.deed?.status === OfferDeedStatus.CONFIRMED ? propertyId : undefined);

  const canSendReviewRequest: boolean = React.useMemo(() => (userIsAdmin || isPropertyAgentOrManager) && !!reviews && reviews.length < REVIEW_USER_TYPES.length, [reviews, isPropertyAgentOrManager, userIsAdmin]);

  const onOfferDeedEditSuccess = React.useCallback(() => mutateConfirmedOffers(), [mutateConfirmedOffers]);

  const onReviewRequestSent = React.useCallback(() => {
    mutateReviews();
  }, [mutateReviews]);

  const onViewBuyerContact = React.useCallback((contactId: string) => () => {
    goToDetailPage(contactId, ReferenceType.CONTACT);
  }, []);

  if (isPropertyLoading || isIntentsLoading || isOffersLoading) {
    return <CardSkeleton aria-label="Dati compravendita in caricamento" />;
  }

  if (errorProperty || errorIntents || errorOffers) {
    return (
      <Card>
        <Card.Header
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          title="Compravendita"
          color="critical"
        />
        <Card.Content>
          <Message message={LOAD_PROPERTY_SALE_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  if (!canShow) {
    return null;
  }

  return property && (
    <Card
      aria-label="Sezione dati compravendita"
      {...rest}
    >
      <Card.Header
        title="Compravendita"
        icon={{ path: ICON_HANDSHAKE_OUTLINE }}
        primaryActions={[
          (offer && (
            <EditOfferDeedAction
              offer={offer}
              modalTitle="Modifica la data del rogito"
              onSuccess={onOfferDeedEditSuccess}
            >
              {({ openEditOfferDeedModal }) => (
                <Action
                  label="Modifica rogito"
                  onClick={openEditOfferDeedModal}
                  size="XS"
                />
              )}
            </EditOfferDeedAction>
          )),
        ]}
      />
      <Card.Content aria-label="Dati compravendita">
        <DetailItemList columns={2} withoutDividers>
          <DetailItemList.Item label="Prezzo acquisto" ellipsis>
            {property.soldPrice ? formatEuro(property.soldPrice) : undefined}
          </DetailItemList.Item>

          <DetailItemList.Item label="Data vendita">
            {property.soldAt ? formatDate(new Date(property.soldAt)) : undefined}
          </DetailItemList.Item>

          <DetailItemList.Item label="Data rogito">
            {offer?.deed?.date ? formatDate(new Date(offer.deed.date)) : undefined}
          </DetailItemList.Item>

          <DetailItemList.BooleanItem
            label="Mutuo"
            value={offer?.mortgage !== undefined}
          >
            {offer?.mortgage?.percentage ? ` (${offer?.mortgage!.percentage}%)` : undefined}
          </DetailItemList.BooleanItem>
        </DetailItemList>
      </Card.Content>
      <Card.Divider />
      <Card.Header
        title="Compratori"
        isSecondary
      />
      <Card.Content>
        <SimpleTable aria-label="Tabella dati compratori">
          <SimpleTable.Body>
            {thereAreErrors && (
              <SimpleTable.Row
                aria-label="Errore caricamento dati compratori"
              >
                <SimpleTable.Cell>
                  <Message type="critical" message="Errore caricamento dati" />
                </SimpleTable.Cell>
              </SimpleTable.Row>
            )}
            {dataAreLoading && (
              <SimpleTable.Row asChild>
                <SimpleTableRowSkeleton />
              </SimpleTable.Row>
            )}
            {!thereAreErrors && !dataAreLoading && buyersToShow.map((buyer) => (
              <SimpleTable.Row
                aria-label="Riga di un compratore"
                key={objectHash(buyer)}
              >
                <SimpleTable.Cell>
                  <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                    {buyer.name}
                  </Text.BodySmall>
                </SimpleTable.Cell>
                <SimpleTable.Cell align="right">
                  <ActionIcon
                    aria-label="view-property-contact-action"
                    label="Visualizza referente"
                    size="S"
                    icon={{ path: ICON_EYE_OUTLINE }}
                    onClick={onViewBuyerContact(buyer.id!)}
                  />
                </SimpleTable.Cell>
              </SimpleTable.Row>
            ))}
          </SimpleTable.Body>
        </SimpleTable>
      </Card.Content>
      <Card.Divider />
      {isInvoiceLoading && (
        <>
          <Card.Header
            title="Pagamenti"
            isSecondary
          />
          <Card.Content>
            <Skeleton count={5} aria-label="Dati pagamenti in caricamento" />
          </Card.Content>
        </>
      )}
      {invoiceError && (
        <>
          <Card.Header
            title="Pagamenti"
            isSecondary
            color="critical"
          />
          <Card.Content>
            <Message message={LOAD_PROPERTY_INVOICE_ERROR_MESSAGE} />
          </Card.Content>
        </>
      )}
      {invoice && (
        <>
          <Card.Header
            title="Pagamenti"
            isSecondary
            primaryActions={[
              (canEditPayments && (
                <ManageInvoiceStatusAction
                  invoice={invoice}
                  action="manage"
                  onSuccess={mutateInvoice}
                />
              )),
            ]}
            secondaryActions={[
              (canEditPayments && (
                <ManageInvoiceStatusAction
                  invoice={invoice}
                  action="edit"
                  onSuccess={mutateInvoice}
                >
                  {({ openModal }) => (
                    <Dropdown.Option
                      label="Modifica fattura"
                      aria-label="Modifica fattura"
                      onClick={openModal}
                    />
                  )}
                </ManageInvoiceStatusAction>
              )),
            ]}
          />
          <Card.Content aria-label="Dati pagamenti">
            <Badge
              icon={invoiceStatusIcons[invoice.status]}
              label={invoiceStatusLabels[invoice.status]}
              color={invoiceStatusColors[invoice.status]}
              size="XS"
            />
            <Spacing padding={[150, 0, 0]}>
              <DetailItemList columns={2} withoutDividers>
                <DetailItemList.Item label="Commissioni compratore">
                  {formatEuro(invoice.buyerFee)}
                </DetailItemList.Item>
                <DetailItemList.Item label="Commissioni venditore">
                  {invoice.sellerFee ? formatEuro(invoice.sellerFee) : undefined}
                </DetailItemList.Item>
                {invoice.status !== InvoiceStatus.COLLECTED && (
                  <DetailItemList.Item label="Data presunta fatturazione">
                    {format(new Date(invoice.forecastedInvoiceDate), 'LLLL yyyy', { locale: it })}
                  </DetailItemList.Item>
                )}
                {invoice.status === InvoiceStatus.COLLECTED && (
                  <DetailItemList.Item label="Data d'incasso">
                    {invoice.collectedAt ? formatDate(new Date(invoice.collectedAt)) : undefined}
                  </DetailItemList.Item>
                )}
              </DetailItemList>
            </Spacing>
          </Card.Content>
        </>
      )}
      <Card.Divider />
      {areReviewsLoading && (
        <>
          <Card.Header
            title="Recensioni verificate"
            isSecondary
          />
          <Card.Content>
            <Skeleton count={5} aria-label="Dati recensioni in caricamento" />
          </Card.Content>
        </>
      )}
      {reviewsError && (
        <>
          <Card.Header
            title="Recensioni verificate"
            isSecondary
            color="critical"
          />
          <Card.Content>
            <Message message={PROPERTY_REVIEWS_LOAD_ERROR_MESSAGE} />
          </Card.Content>
        </>
      )}
      {reviews && (
        <>
          <Card.Header
            title="Recensioni verificate"
            isSecondary
            primaryActions={[
              (canSendReviewRequest && (
                <RequestPropertyReviewsAction
                  property={property}
                  onSuccess={onReviewRequestSent}
                />
              )),
            ]}
          />
          <Card.Content aria-label="Dati recensioni">
            <Grid gutter={150}>
              {REVIEW_USER_TYPES.map((reviewUserType) => {
                const sentRequest: Review | undefined = reviews.find((review) => review.userType === reviewUserType);
                const hasBeenReviewed = !!sentRequest?.reviewedAt;
                return (
                  <Grid.Unit size={{ MD: 1 / 2 }} key={reviewUserType}>
                    <Text.BodySmall transform="uppercase" fontWeight={FontWeight.MEDIUM}>
                      {reviewUserTypeLabels[reviewUserType]}
                    </Text.BodySmall>
                    <Spacing padding={[150, 0, 0]}>
                      {hasBeenReviewed && (
                        <>
                          <Message message={`Recensione ricevuta il ${formatDate(new Date(sentRequest?.reviewedAt))}`} />
                          <Spacing padding={[150, 0, 0]} />
                          <HStack>
                            <TrustpilotRating stars={sentRequest!.stars!} size={16} />
                            <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                              {sentRequest!.stars!}
                            </Text.BodySmall>
                            <Spacing padding={[0, 0, 0, 100]} />
                            <ViewTrustpilotReviewAction
                              review={sentRequest}
                            />
                          </HStack>
                        </>
                      )}
                      {!hasBeenReviewed && sentRequest && (
                        <Message type="success" message={`Richiesta inviata il ${formatDate(new Date(sentRequest.createdAt))}`} />
                      )}
                      {!hasBeenReviewed && !sentRequest && (
                        <Message message="Richiesta non inviata" />
                      )}
                    </Spacing>
                  </Grid.Unit>
                );
              })}
            </Grid>
          </Card.Content>
        </>
      )}

    </Card>
  );
};

export default PropertySaleWidget;
