import type { GeoCodeResult } from '../../../../providers/geo/dtos';
import type { PropertyGeoFieldNames, PropertyGeoFields } from './renderPropertyGeoFields';

export const GEO_CODE_FIELDS: Array<keyof Omit<GeoCodeResult, 'placeId' | 'country'>> = [
  'route',
  'streetNumber',
  'locality',
  'administrativeAreaLevelOne',
  'administrativeAreaLevelTwo',
  'postalCode',
  'latitude',
  'longitude',
  'suburb',
];

export const DEFAULT_PROPERTY_GEO_FORM_VALUES: PropertyGeoFields = Object.freeze({
  normalizedAddress: '',
  administrativeAreaLevelOne: '',
  administrativeAreaLevelTwo: '',
  locality: '',
  postalCode: '',
  plateCode: '',
  route: '',
  streetNumber: '',
  suburb: '',
  latitude: '',
  longitude: '',
  geoCodeResult: null,
});

export const DEFAULT_PROPERTY_GEO_FIELD_NAMES: PropertyGeoFieldNames = Object.freeze({
  normalizedAddress: 'normalizedAddress',
  administrativeAreaLevelOne: 'administrativeAreaLevelOne',
  administrativeAreaLevelTwo: 'administrativeAreaLevelTwo',
  locality: 'locality',
  postalCode: 'postalCode',
  plateCode: 'plateCode',
  route: 'route',
  streetNumber: 'streetNumber',
  suburb: 'suburb',
  latitude: 'latitude',
  longitude: 'longitude',
  geoCodeResult: 'geoCodeResult',
});
