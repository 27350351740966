import { BreakpointQueryName, ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const tabs: ThemedFunctor = ({ theme }) => css`
  padding: ${theme.remSize[200]} 0;
  background: ${theme.color.background.neutral.default.lowest};

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    margin: 0;
    padding: ${theme.remSize[200]};
  }
`;
