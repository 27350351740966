import { raise } from '..';
import { Geo } from '../../providers/api/dtos';
import { GeoCodeResult, Place } from '../../providers/geo/dtos';

export type NormalizedAddressParams = Pick<Place, 'route' | 'streetNumber' | 'postalCode' | 'locality'>;

export function getNormalizedAddress({
  route, streetNumber, postalCode, locality,
}: NormalizedAddressParams) {
  return [route, streetNumber, postalCode, locality].filter((i) => i).join(', ');
}

export function geoCodeResultToGeo(geoCodeResult: GeoCodeResult | undefined, overrides?: Partial<Geo>): Geo | undefined;
export function geoCodeResultToGeo(geoCodeResult: GeoCodeResult, overrides?: Partial<Geo>): Geo;

export function geoCodeResultToGeo(
  geoCodeResult: GeoCodeResult | undefined,
  overrides: Partial<Geo> = {},
): Geo | undefined {
  if (typeof geoCodeResult === 'undefined') {
    return undefined;
  }

  const {
    route,
    locality,
    latitude,
    longitude,
    administrativeAreaLevelOne,
    administrativeAreaLevelTwo,
    postalCode,
    streetNumber,
    suburb,
  } = geoCodeResult;

  return {
    latitude: Number(latitude) || raise('invalid latitude'),
    longitude: Number(longitude) || raise('invalid longitude'),
    administrativeAreaLevelOne,
    administrativeAreaLevelTwo,
    postalCode,
    streetNumber,
    suburb: suburb || undefined,
    route,
    locality,
    ...overrides,
  };
}
