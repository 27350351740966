import { snakeCase } from 'snake-case';

export function serializeParams(obj: any, result: any = {}, parentKey: string = '') {
  // eslint-disable-next-line consistent-return
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const newKey = `${parentKey}_${key}`;

    if (typeof value === 'object' && !Array.isArray(value) && !(value instanceof Date)) {
      return serializeParams(value, result, newKey);
    }

    result[snakeCase(newKey)] = value instanceof Date ? value.toISOString() : value;
  });

  return result;
}
