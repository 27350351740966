import React from 'react';
import * as Yup from 'yup';
import { Form, FormHandlers, FormProps } from '@doveit/bricks';
import { ShootingType } from '../../../domain/types';
import { upsertShootingValidationSchema } from './UpsertShootingForm.schema';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { shootingTypeLabels } from '../../../labels';
import { INPUT_PLACEHOLDERS } from '../../../constants';

export interface UpsertShootingFormModel {
  type: ShootingType | '',
  photographerName: string,
  date: string,
  time: string,
  matterportRequest: boolean,
  matterportDeliveryDate: string,
  postProcessingRequest: boolean,
  photoPrice: string,
  extraServicesPrice: string,
  expensesRefund: string,
  photoDeliveryDate: string,
  notes: string
}

export interface UpsertShootingFormProps {
  initialValues?: Partial<UpsertShootingFormModel>,
  hiddenFields?: Partial<Record<keyof UpsertShootingFormModel, boolean>>,
  disabledFields?: Partial<Record<keyof UpsertShootingFormModel, boolean>>,
  innerRef?: React.MutableRefObject<FormHandlers>,
  validationSchema?: Yup.AnySchema | ReturnType<typeof upsertShootingValidationSchema>,
  size?: 'S' | 'M',
  loading?: FormProps<UpsertShootingFormModel>['loading'],
  onSubmit: FormProps<UpsertShootingFormModel>['onSubmit'],
}

export const UPSERT_SHOOTING_FORM_DEFAULT_VALUES: UpsertShootingFormModel = {
  type: '',
  photographerName: '',
  date: '',
  time: '',
  photoPrice: '',
  extraServicesPrice: '',
  expensesRefund: '',
  postProcessingRequest: false,
  matterportRequest: false,
  matterportDeliveryDate: '',
  photoDeliveryDate: '',
  notes: '',
};

const UpsertShootingForm: React.FC<UpsertShootingFormProps> = ({
  initialValues,
  hiddenFields,
  disabledFields,
  validationSchema,
  size = 'M',
  ...rest
}) => {
  const mergedInitialValues: UpsertShootingFormModel = React.useMemo(() => ({
    ...UPSERT_SHOOTING_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={typeof validationSchema === 'function'
        ? validationSchema(hiddenFields, disabledFields)
        : validationSchema}
      {...rest}
    >
      {({ values, setFieldValue }) => {
        const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          if (values.type === 'DIRECT' && e.target.value !== 'DIRECT') {
            setFieldValue('photographerName', UPSERT_SHOOTING_FORM_DEFAULT_VALUES.photographerName);
            setFieldValue('photoPrice', UPSERT_SHOOTING_FORM_DEFAULT_VALUES.photoPrice);
          }
        };

        const onMatterportRequestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.checked) {
            setFieldValue('matterportDeliveryDate', UPSERT_SHOOTING_FORM_DEFAULT_VALUES.matterportDeliveryDate);
          }
        };

        return (
          <>
            {!hiddenFields?.type && (
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Select
                  size={size}
                  name="type"
                  label="Tipologia"
                  aria-label="Campo per specificare la tipologia del fotografo"
                  options={asSelectOptions(shootingTypeLabels, {
                    value: '',
                    label: 'Seleziona',
                    disabled: true,
                  })}
                  onChange={onTypeChange}
                  disabled={disabledFields?.type}
                  hasErrorTooltip
                  required
                />
              </Form.Item>
            )}

            {!hiddenFields?.photographerName && (
              <Form.Item size={{ MD: 1 / 2 }}>
                {values.type === 'DIRECT' && (
                  <Form.Input
                    size={size}
                    name="photographerName"
                    label="Nome fotografo"
                    aria-label="Campo per specificare il nome del fotografo"
                    placeholder={INPUT_PLACEHOLDERS.CONTACT_NAME_SURNAME}
                    disabled={disabledFields?.photographerName}
                    hasErrorTooltip
                    required
                  />
                )}
              </Form.Item>
            )}

            {!hiddenFields?.date && (
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  size={size}
                  name="date"
                  label="Data shooting"
                  type="date"
                  aria-label="Campo per specificare la data dello shooting"
                  disabled={disabledFields?.date}
                  hasErrorTooltip
                  required
                />
              </Form.Item>
            )}

            {!hiddenFields?.time && (
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  size={size}
                  name="time"
                  label="Ora shooting"
                  type="time"
                  aria-label="Campo per specificare l'ora dello shooting"
                  disabled={disabledFields?.time}
                  hasErrorTooltip
                  required
                />
              </Form.Item>
            )}

            {!(hiddenFields?.matterportRequest && hiddenFields.postProcessingRequest) && (
              <Form.Group label="Extra">
                {!hiddenFields?.matterportRequest && (
                  <Form.Item size={{ MD: 1 / 2 }}>
                    <Form.CheckboxButton
                      size={size}
                      text="Matterport"
                      name="matterportRequest"
                      onChange={onMatterportRequestChange}
                      disabled={disabledFields?.matterportRequest}
                    />
                  </Form.Item>
                )}

                {!hiddenFields?.postProcessingRequest && (
                  <Form.Item size={{ MD: 1 / 2 }}>
                    <Form.CheckboxButton
                      size={size}
                      text="Post produzione"
                      name="postProcessingRequest"
                      disabled={disabledFields?.postProcessingRequest}
                    />
                  </Form.Item>
                )}
              </Form.Group>
            )}

            {!hiddenFields?.matterportDeliveryDate && values.matterportRequest && (
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  size={size}
                  name="matterportDeliveryDate"
                  label="Consegna Matterport"
                  type="date"
                  hasErrorTooltip
                  aria-label="Campo per specificare la data di consegna di Matterport"
                  disabled={disabledFields?.matterportDeliveryDate}
                />
              </Form.Item>
            )}

            {!hiddenFields?.photoPrice && values.type === ShootingType.DIRECT && (
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.DecimalPriceField
                  size={size}
                  name="photoPrice"
                  label="Costo foto"
                  aria-label="Campo per specificare il costo delle foto"
                  disabled={disabledFields?.photoPrice}
                />
              </Form.Item>
            )}

            {!hiddenFields?.extraServicesPrice && values.type === ShootingType.DIRECT && (
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.DecimalPriceField
                  size={size}
                  name="extraServicesPrice"
                  label="Costo extra"
                  aria-label="Campo per specificare il costo dei servizi extra"
                  disabled={disabledFields?.extraServicesPrice || !values.postProcessingRequest}
                />
              </Form.Item>
            )}

            {!hiddenFields?.expensesRefund && values.type === ShootingType.DIRECT && (
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.DecimalPriceField
                  size={size}
                  name="expensesRefund"
                  label="Rimborso spese"
                  aria-label="Campo per specificare il costo del rimborso spese"
                  disabled={disabledFields?.expensesRefund}
                />
              </Form.Item>
            )}

            {!hiddenFields?.photoDeliveryDate && (
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  size={size}
                  name="photoDeliveryDate"
                  label="Consegna foto"
                  type="date"
                  hasErrorTooltip
                  aria-label="Campo per specificare la data di consegna delle foto"
                  disabled={disabledFields?.photoDeliveryDate}
                />
              </Form.Item>
            )}

            {!hiddenFields?.notes && (
              <Form.Item>
                <Form.TextArea
                  size={size}
                  name="notes"
                  aria-label="Campo per specificare le note"
                  label="Note"
                  rows={3}
                  style={{ resize: 'vertical' }}
                  disabled={disabledFields?.notes}
                />
              </Form.Item>
            )}
          </>
        );
      }}
    </Form>
  );
};

export default UpsertShootingForm;
