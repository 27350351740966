import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const mapWrapper: ThemedFunctor = ({ theme }) => css`
  align-items: center;
  border: 1px solid ${theme.palettes.neutrals.light};
  color: ${theme.palettes.neutrals.medium};
  display: flex;
  height: 20rem;
  justify-content: center;
  position: relative;
  width: 100%;
`;
