import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { COUNTRY } from '../../../constants';

const editAgentRegistryValidationSchema = Yup.object({
  workName: Yup
    .string()
    .trim()
    .required('Il campo è obbligatorio'),
  workSurname: Yup
    .string()
    .trim()
    .required('Il campo è obbligatorio'),
  personalEmail: Yup
    .string()
    .trim()
    .required('Il campo è obbligatorio')
    .email('Inserisci un indirizzo email valido'),
  workEmail: Yup
    .string()
    .trim()
    .email('Inserisci un indirizzo email valido'),
  pecEmail: Yup
    .string()
    .trim()
    .email('Inserisci un indirizzo PEC valido'),
  personalMobile: Yup
    .string()
    .trim()
    .required('Il campo è obbligatorio')
    .test(
      'validPhoneNumber',
      'Inserisci un numero di telefono valido',
      (value: string | undefined) => !value || isValidPhoneNumber(value, COUNTRY),
    ),
  workMobile: Yup
    .string()
    .trim()
    .test(
      'validPhoneNumber',
      'Inserisci un numero di telefono valido',
      (value: string | undefined) => !value || isValidPhoneNumber(value, COUNTRY),
    ),
  fiscalCode: Yup
    .string()
    .trim()
    .length(16, 'Il codice fiscale deve essere composto da 16 caratteri.'),
});

export default editAgentRegistryValidationSchema;
