import {
  useModal, Portal, Message, Action, ICON_ACCOUNT_OUTLINE, CardSkeleton, Card, ICON_ALERT_CIRCLE_OUTLINE, Text, Badge, ActionIconDropdown, Dropdown,
} from '@doveit/bricks';
import { pluralizeWord } from '@doveit/hammer';
import React from 'react';
import objectHash from 'object-hash';
import { propertyRelationshipTypeLabels } from '../../../labels';
import CreatePropertyContact from '../../../property-contact/containers/create-property-contact/CreatePropertyContact';
import DeletePropertyContact from '../../../property-contact/containers/delete-property-contact/DeletePropertyContact';
import EditPropertyContact from '../../../property-contact/containers/edit-property-contact/EditPropertyContact';
import { usePropertyContacts } from '../../hooks/use-property-contacts/usePropertyContacts';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import { ReferenceType } from '../../../domain/types';
import { goToDetailPage } from '../../../utils/navigate/utils';

export interface PropertyContactsWidgetProps {
  propertyId: number,
}

export const PROPERTY_CONTACTS_LOAD_ERROR_MESSAGE = "Non è stato possibile caricare i referenti dell'immobile";
export const MISSING_PROPERTY_CONTACTS_MESSAGE = 'Non sono presenti referenti associati a questo immobile';

const PropertyContactsWidget: React.FC<PropertyContactsWidgetProps> = ({
  propertyId,
}) => {
  const {
    userIsAdmin, userIsFinance, userIsCallCenter, userIsContentEditor,
  } = useRBAC();
  const { data: propertyAgent } = useAgentByPropertyId(propertyId);
  const isPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(propertyAgent?.id);

  const canView = userIsAdmin || userIsCallCenter || userIsContentEditor || isPropertyAgentOrManager || userIsFinance;
  const canChange = userIsAdmin || isPropertyAgentOrManager;

  const {
    data: propertyContacts,
    error: propertyContactsError,
    mutate: mutatePropertyContacts,
    isLoading: isPropertyContactsLoading,
  } = usePropertyContacts(propertyId);
  const createModal = useModal();

  const onViewPropertyContact = React.useCallback((contactId: string) => () => {
    goToDetailPage(contactId, ReferenceType.CONTACT);
  }, []);

  const onUpdate = React.useCallback(() => {
    mutatePropertyContacts();
  }, [mutatePropertyContacts]);

  const onInternalSuccess = React.useCallback(() => {
    createModal.close();
    onUpdate();
  }, [createModal, onUpdate]);

  if (!canView) {
    return null;
  }

  if (isPropertyContactsLoading) {
    return <CardSkeleton aria-label="Dati referenti immobile in caricamento" />;
  }

  if (propertyContactsError) {
    return (
      <Card aria-label="Errore nel caricamento dei referenti dell'immobile">
        <Card.Header
          title="Referenti"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          color="critical"
        />
        <Card.Content>
          <Message
            message={PROPERTY_CONTACTS_LOAD_ERROR_MESSAGE}
          />
        </Card.Content>
      </Card>
    );
  }

  return propertyContacts && (
    <>
      <Card aria-label="Sezione referenti immobile">
        <Card.Header
          title={pluralizeWord('Referent', 'e', 'i', propertyContacts.length)}
          icon={{ path: ICON_ACCOUNT_OUTLINE }}
          primaryActions={[
            canChange && (
              <Action
                aria-label="add-property-contact-action"
                label="Aggiungi"
                title="Aggiungi referente"
                size="S"
                onClick={createModal.open}
              />
            ),
          ]}
        />
        <Card.Content>
          {propertyContacts.length === 0 && (
            <Message message={MISSING_PROPERTY_CONTACTS_MESSAGE} />
          )}
          {propertyContacts.length > 0 && (
            <SimpleTable aria-label="Tabella dati referenti">
              <SimpleTable.Body>
                {propertyContacts.map((propertyContact) => (
                  <SimpleTable.Row
                    aria-label="Riga di un referente"
                    key={objectHash(propertyContact)}
                  >
                    <SimpleTable.Cell width="10rem">
                      <Text.Body>
                        {propertyContact.contact.name}
                      </Text.Body>
                    </SimpleTable.Cell>
                    <SimpleTable.Cell>
                      <Text.Body>
                        <Badge
                          label={propertyRelationshipTypeLabels[propertyContact.relationship]}
                          size="XS"
                        />
                      </Text.Body>
                    </SimpleTable.Cell>
                    <SimpleTable.Cell align="right">
                      <ActionIconDropdown
                        aria-label="change-property-contact-dropdown-action"
                        label="Azioni referente"
                        size="S"
                        emphasis="low"
                      >
                        <Dropdown.Option
                          label="Visualizza"
                          aria-label="Visualizza dettagli referente"
                          onClick={onViewPropertyContact(propertyContact.contact.id!)}
                        />
                        {canChange && (
                          <EditPropertyContact
                            propertyContact={propertyContact}
                            onSuccess={onUpdate}
                          >
                            {({ onEditAction }) => (
                              <Dropdown.Option
                                label="Modifica"
                                aria-label="Apri la modale di modifica referente"
                                onClick={onEditAction}
                              />
                            )}
                          </EditPropertyContact>
                        )}
                        {canChange && (
                          <DeletePropertyContact
                            propertyContact={propertyContact}
                            propertyContactsTotal={propertyContacts?.length}
                            onSuccess={onUpdate}
                            onAdd={createModal.open}
                          >
                            {({ onDelete }) => (
                              <Dropdown.Option
                                label="Elimina"
                                aria-label="Elimina il referente"
                                onClick={onDelete}
                              />
                            )}
                          </DeletePropertyContact>
                        )}
                      </ActionIconDropdown>
                    </SimpleTable.Cell>
                  </SimpleTable.Row>
                ))}
              </SimpleTable.Body>
            </SimpleTable>
          )}
        </Card.Content>
      </Card>
      {canChange && (
        <Portal>
          <SimpleModal
            aria-label="Modale per aggiungere un nuovo referente"
            title="Aggiungi Referente"
            isOpen={createModal.isOpen}
            close={createModal.close}
          >
            <CreatePropertyContact
              propertyId={propertyId}
              onSuccess={onInternalSuccess}
            />
          </SimpleModal>
        </Portal>
      )}
    </>
  );
};

export default PropertyContactsWidget;
