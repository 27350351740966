import {
  Form, FormActionsAlign, FormHandlers, FormSubmitType, SelectOption,
} from '@doveit/bricks';
import React from 'react';
import { ShowcaseFormat, ShowcaseOrientation, ShowcaseVariant } from '../../../domain/types/showcaseTypes';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import {
  propertyTypeLabels,
  showcaseFormatLabels, showcaseOrientationLabels, showcaseVariantLabels,
} from '../../../labels';
import validationSchema from './ShowcaseForm.schema';
import { DOVE_IT_INBOUND_PHONE_NUMBER, DOVE_IT_INBOUND_MOBILE_PHONE_NUMBER } from './constants';
import PropertyAutoComplete, { PropertyAutoCompleteProps } from '../../../containers/property-autocomplete/PropertyAutoComplete';
import { PropertiesProviderFilters } from '../../../providers/api/property/propertyProvider';

export interface ShowcaseFormModel {
  propertyId?: number,
  title: string,
  variant: ShowcaseVariant,
  abstract: string,
  phoneNumber: string,
  format: ShowcaseFormat,
  orientation: ShowcaseOrientation,
}

export interface ShowcaseFormProps {
  onSubmit: FormSubmitType<ShowcaseFormModel>,
  initialValues?: Partial<ShowcaseFormModel>,
  loading?: boolean,
  disabled?: boolean,
  innerRef?: React.MutableRefObject<FormHandlers>,
  extraPhoneNumber?: SelectOption[],
  propertyProviderFilters?: Pick<PropertiesProviderFilters, 'agentEmail' | 'status'>
  hideVariant?: boolean,
}

export const DEFAULT_INITIAL_VALUES: ShowcaseFormModel = {
  title: '',
  variant: ShowcaseVariant.STANDARD,
  abstract: '',
  phoneNumber: DOVE_IT_INBOUND_MOBILE_PHONE_NUMBER,
  format: ShowcaseFormat.A4,
  orientation: ShowcaseOrientation.LANDSCAPE,
};

const FormPropertyAutoComplete = Form.withField<PropertyAutoCompleteProps>(PropertyAutoComplete);

const variantOptions = asSelectOptions(showcaseVariantLabels, false);
const formatOptions = asSelectOptions(showcaseFormatLabels, false);
const orientationOptions = asSelectOptions(showcaseOrientationLabels, false);
const phoneNumberOptions = [
  { label: DOVE_IT_INBOUND_MOBILE_PHONE_NUMBER, value: DOVE_IT_INBOUND_MOBILE_PHONE_NUMBER },
  { label: DOVE_IT_INBOUND_PHONE_NUMBER, value: DOVE_IT_INBOUND_PHONE_NUMBER },
];

const ShowcaseForm: React.FC<ShowcaseFormProps> = ({
  onSubmit,
  initialValues,
  loading,
  disabled,
  innerRef,
  extraPhoneNumber,
  propertyProviderFilters,
  hideVariant = true,
}) => {
  const mergedInitialValues = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...(initialValues || {}),
  }), [initialValues]);

  const mergedPhoneNumberOptions = React.useMemo(() => ([
    ...phoneNumberOptions,
    ...(extraPhoneNumber || []),
  ]), [extraPhoneNumber]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={mergedInitialValues}
      loading={loading}
      disabled={disabled}
      innerRef={innerRef}
      validationSchema={validationSchema}
      preventEnterKeypress
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const onPropertySuggestionSelected: PropertyAutoCompleteProps['onSuggestionSelected'] = (property) => {
          setFieldValue('propertyId', property?.id || '');
          setFieldValue('title', property?.propertyType ? propertyTypeLabels[property?.propertyType] : '');
          setFieldValue('abstract', property?.abstract || '');
        };

        return (
          <>
            <Form.Item>
              <FormPropertyAutoComplete
                name="propertyId"
                value={mergedInitialValues.propertyId}
                aria-label="Autocomplete per selezionare un immobile"
                placeholder="Cerca immobile"
                status={propertyProviderFilters?.status}
                agentEmail={propertyProviderFilters?.agentEmail}
                onSuggestionSelected={onPropertySuggestionSelected}
                maxSuggestions={10}
                required
              />
            </Form.Item>
            {!hideVariant && (
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Select
                  name="variant"
                  label="Variante"
                  aria-label="Tendina di selezione della variante"
                  options={variantOptions}
                />
              </Form.Item>
            )}
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Select
                name="phoneNumber"
                label="Telefono"
                aria-label="Tendina di selezione del telefono"
                options={mergedPhoneNumberOptions}
              />
            </Form.Item>
            <Form.Item>
              <Form.Input
                name="title"
                label="Titolo"
                aria-label="Campo di testo per inserire il titolo della locandina"
                placeholder="Es. Monolocale"
                required
              />
            </Form.Item>
            <Form.Item>
              <Form.TextArea
                name="abstract"
                label="Abstract"
                aria-label="Campo di testo per inserire l'abstract della locandina"
                rows={4}
                required
              />
            </Form.Item>
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.Select
                name="format"
                label="Formato"
                aria-label="Tendina di selezione del formato"
                options={formatOptions}
              />
            </Form.Item>
            {[ShowcaseFormat.A4, ShowcaseFormat.A3].includes(values.format) && (
            <Form.Item size={{ MD: 1 / 2 }}>
              <Form.RadioButtonGroup
                name="orientation"
                label="Orientamento"
                aria-label="Campo per specificare l'orientamento del formato"
                itemSize={1 / 2}
                items={orientationOptions}
              />
            </Form.Item>
            )}
            {!innerRef && (
            <Form.Actions align={FormActionsAlign.RIGHT}>
              <Form.Submit label="Genera locandina" />
            </Form.Actions>
            )}
          </>
        );
      }}
    </Form>
  );
};

export default ShowcaseForm;
