import React from 'react';
import { AxiosError } from 'axios';
import { addDays, isAfter, isPast } from 'date-fns';
import useContactAdditionalServices from '../../../contact/hooks/use-contact-additional-services/useContactAdditionalServices';
import useContactOptIns from '../../../contact/hooks/use-contact-opt-ins/useContactOptIns';
import { Document, Intent } from '../../../providers/api/dtos';
import { IntentNoticeData } from '../../../providers/api/dtos/notice';
import {
  AppointmentStatus, DocumentStatus, INTENT_KO_STATUS, IntentStatus, OfferDeedStatus, OfferDocumentType, OfferDocumentTypeValue, OfferStatus, OptInType, ReferenceType,
} from '../../../domain/types';
import useContactAppointments from '../../../appointment/hooks/use-contact-appointments/useContactAppointments';
import { SortOrder } from '../../../providers/pagination';
import { useOfferDocuments } from '../../../offer/hooks/use-offer-documents/useOfferDocuments';
import useOffersByIntentId from '../../../offer/hooks/use-offers-by-intent-id/useOffersByIntentId';
import { MANDATORY_OFFER_DOCUMENT_TYPES } from '../../../document/constants';
import { DocumentReferenceType } from '../../../domain/types/documentReferenceType';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import useOfferWizardData from '../../../offer/hooks/use-offer-wizard-data/useOfferWizardData';
import useIntentsWithOffer from '../use-intents-with-offer/useIntentsWithOffer';
import useContact from '../../../contact/hooks/use-contact/useContact';
import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import usePropertyPreview from '../../../property/hooks/use-property-preview/usePropertyPreview';
import { useIntentReminders } from '../use-intent-reminders/useIntentReminders';

export type UseIntentNoticesDataResult = {
  data?: IntentNoticeData[],
  isLoading: boolean,
  errors?: Error[] | undefined,
  mutate: VoidFunction
};

export function useIntentNoticesData(intent?: Intent): UseIntentNoticesDataResult {
  const [errors, setErrors] = React.useState<AxiosError[] | undefined>();

  const { user } = useRBAC();
  const currentAgent = useCurrentAgent();

  // destructure in order to have undefined ids for KO status and avoid api calls
  const { id: intentId, contactId, propertyId } = !intent || INTENT_KO_STATUS.includes(intent.status)
    ? { id: undefined, contactId: undefined, propertyId: undefined }
    : intent;

  const handleError = React.useCallback((err: AxiosError) => {
    if (err.response?.status === 404) { return; }

    setErrors((prev) => {
      if (!prev) return [err];
      return [...prev, err];
    });
  }, []);

  const {
    data: contactOptIns,
    isLoading: areContactOptInsLoading,
    mutate: mutateContactOptIns,
  } = useContactOptIns(contactId, { onError: handleError });

  const {
    data: contactAdditionalServices,
    isLoading: areContactAdditionalServicesLoading,
    mutate: mutateContactAdditionalServices,
  } = useContactAdditionalServices(contactId, { onError: handleError });

  // @TODO add status and start_date filters when they will be available
  const {
    data: appointments,
    isLoading: areAppointmentsLoading,
    mutate: mutateAppointments,
  } = useContactAppointments(
    contactId,
    { referenceId: intentId!, referenceType: ReferenceType.INTENT },
    { sort: { createdAt: SortOrder.DESC } },
    { onError: handleError },
  );

  const {
    data: reminders,
    isLoading: areRemindersLoading,
    mutate: mutateReminders,
  } = useIntentReminders(intent?.id, { size: 1 }, { onError: handleError });

  const {
    data: intentOffers,
    isLoading: areIntentOffersLoading,
    mutate: mutateIntentOffers,
  } = useOffersByIntentId(
    intentId,
    [OfferStatus.IN_PROGRESS, OfferStatus.ACCEPTED, OfferStatus.REFUSED, OfferStatus.CONFIRMED],
    { onError: handleError },
  );

  const {
    data: propertyIntentsWithAcceptedOffer,
    isLoading: arePropertyIntentsWithAcceptedOfferLoading,
    mutate: mutatePropertyIntentsWithAcceptedOffer,
  } = useIntentsWithOffer({
    propertyId,
    offerStatus: OfferStatus.ACCEPTED,
  }, undefined, { onError: handleError });

  const {
    data: wizardData,
    isLoading: isWizardDataLoading,
    mutate: mutateWizardData,
  } = useOfferWizardData(intentId, { onError: handleError });

  const latestOffer = React.useMemo(() => intentOffers?.[0], [intentOffers]);

  const {
    data: offerDocuments,
    isLoading: areOfferDocumentsLoading,
    mutate: mutateOfferDocuments,
  } = useOfferDocuments(latestOffer?.id, { onError: handleError });

  const {
    data: contact,
    isLoading: isContactLoading,
    mutate: mutateContact,
  } = useContact(contactId, { onError: handleError });

  const {
    data: propertyPreview,
    isLoading: isPropertyLoading,
    mutate: mutateProperty,
  } = usePropertyPreview(propertyId, { onError: handleError });

  const approvedOffer = React.useMemo(() => offerDocuments?.find((document) => document.type === OfferDocumentTypeValue.PURCHASE_PROPOSAL && document.status === DocumentStatus.APPROVED),
    [offerDocuments]);

  const isLoading = React.useMemo(
    () => (
      areContactOptInsLoading
      || areContactAdditionalServicesLoading
      || areAppointmentsLoading
      || areRemindersLoading
      || arePropertyIntentsWithAcceptedOfferLoading
      || areIntentOffersLoading
      || areOfferDocumentsLoading
      || isWizardDataLoading
      || isContactLoading
      || isPropertyLoading
    ),
    [
      areContactAdditionalServicesLoading,
      areContactOptInsLoading,
      areAppointmentsLoading,
      areRemindersLoading,
      areIntentOffersLoading,
      areOfferDocumentsLoading,
      arePropertyIntentsWithAcceptedOfferLoading,
      isWizardDataLoading,
      isContactLoading,
      isPropertyLoading,
    ],
  );

  const mutate = React.useCallback(async () => Promise.all(
    [
      mutateContactOptIns(),
      mutateContactAdditionalServices(),
      mutateAppointments(),
      mutateReminders(),
      mutateIntentOffers(),
      mutateOfferDocuments(),
      mutateWizardData(),
      mutatePropertyIntentsWithAcceptedOffer(),
      mutateContact(),
      mutateProperty(),
    ],
  ), [
    mutateContactAdditionalServices,
    mutateContactOptIns,
    mutateAppointments,
    mutateReminders,
    mutateIntentOffers,
    mutateOfferDocuments,
    mutatePropertyIntentsWithAcceptedOffer,
    mutateWizardData,
    mutateContact,
    mutateProperty,
  ]);

  const data = React.useMemo(() => {
    if (isLoading || !!errors) {
      return undefined;
    }

    const noticeData: IntentNoticeData[] = [];

    if (!intent || INTENT_KO_STATUS.includes(intent.status)) {
      return noticeData; // no computation for KO intents
    }

    /* contact notices */
    // do not show for archived intents or sold properties
    if (intent?.status === IntentStatus.IN_PROGRESS && contactAdditionalServices) {
      const hasThirdPartiesOptIn = contactOptIns?.some((optIn) => optIn.type === OptInType.THIRD_PARTIES);

      if (!hasThirdPartiesOptIn) {
        noticeData.push({
          type: 'CONTACT_MISSING_THIRD_PARTIES_OPT_IN',
          referenceId: intent.contactId,
          data: {
            additionalServices: contactAdditionalServices,
          },
        });
      }
    }

    if (appointments?.content) {
      // @TODO remove filtering when there will be the new API with status, start_date and end_date filters
      const pendingAppointments = appointments.content
        .filter((appointment) => appointment.status === AppointmentStatus.TODO)
        .filter((appointment) => isPast(new Date(appointment.startDate)))
        .filter((appointment) => appointment.agentId === currentAgent?.id || currentAgent?.canManage?.some((agentManaged) => agentManaged.id === appointment.agentId));

      if (pendingAppointments.length > 0) {
        noticeData.push({
          type: 'INTENT_APPOINTMENT_OUTCOME',
          referenceId: intent.id!,
          data: {
            appointments: pendingAppointments,
          },
        });
      }
    }

    if (latestOffer?.status === OfferStatus.IN_PROGRESS && offerDocuments) {
      const notApprovedMandatoryOfferDocuments = offerDocuments
        .filter((document) => document.status !== DocumentStatus.APPROVED && MANDATORY_OFFER_DOCUMENT_TYPES.includes(document.type as OfferDocumentType));
      const offerDocumentTypes = offerDocuments!.map((document) => document.type);
      const notUploadedMandatoryDocuments = MANDATORY_OFFER_DOCUMENT_TYPES
        .filter((type) => !offerDocumentTypes?.includes(type))
        .map((documentType) => ({
          reference: {
            id: latestOffer.id!.toString(),
            type: DocumentReferenceType.OFFER,
          },
          type: documentType,
          files: [],
          status: DocumentStatus.DRAFT,
          createdBy: user.email,
        }));

      const missingDocuments: Document[] = [
        ...notApprovedMandatoryOfferDocuments,
        ...notUploadedMandatoryDocuments,
      ];

      if (missingDocuments.length > 0 && approvedOffer) {
        noticeData.push({
          type: 'OFFER_MISSING_MANDATORY_DOCUMENT_TYPES',
          referenceId: latestOffer.id!,
          data: {
            documents: missingDocuments,
          },
        });
      }
    }

    if (latestOffer?.status === OfferStatus.IN_PROGRESS && latestOffer.validUntil && isPast(latestOffer.validUntil)) {
      noticeData.push({
        type: 'OFFER_EXPIRED',
        referenceId: latestOffer.id!,
        data: {
          offer: latestOffer,
        },
      });
    }
    if (
      intent.status === IntentStatus.IN_PROGRESS
      && latestOffer?.status === OfferStatus.REFUSED
      && latestOffer.counterOffer !== undefined
      && (!wizardData || isAfter(new Date(latestOffer.updatedAt!), new Date(wizardData.updatedAt!)))
    ) {
      noticeData.push({
        type: 'OFFER_REFUSED',
        referenceId: latestOffer.id!,
        data: {
          offer: latestOffer,
        },
      });
    }

    if (intent.status === IntentStatus.BOUGHT
      && latestOffer?.status === OfferStatus.CONFIRMED
      && latestOffer.deed?.status !== OfferDeedStatus.CONFIRMED
      && latestOffer.deed?.date
      && isPast(new Date(latestOffer.deed.date))) {
      noticeData.push({
        type: 'OFFER_DEED_DATE_EXPIRED',
        referenceId: latestOffer.id!,
        data: {
          offer: latestOffer,
        },
      });
    }

    if (latestOffer?.status === OfferStatus.ACCEPTED
      && latestOffer?.mortgage?.maxAcceptanceDate
      && !isAfter(latestOffer.mortgage.maxAcceptanceDate, addDays(new Date(), 7))
    ) {
      noticeData.push({
        type: 'OFFER_MORTGAGE_ACCEPTANCE_DATE',
        referenceId: latestOffer.id!,
        data: {
          offer: latestOffer,
        },
      });
    }

    if (intent.status === IntentStatus.IN_PROGRESS) {
      const existingIntentWithAcceptedOffer = propertyIntentsWithAcceptedOffer?.content.find(({ id }) => id !== intent.id);

      if (existingIntentWithAcceptedOffer) {
        noticeData.push({
          type: 'PROPERTY_EXISTING_ACCEPTED_OFFER',
          referenceId: intent.propertyId,
          data: {
            intent: existingIntentWithAcceptedOffer,
          },
        });
      }

      const hasNoOffersNorAppointmentsNorReminders = !latestOffer
        && (appointments?.totalElements || 0) === 0
        && (reminders?.totalElements || 0) === 0;

      if (hasNoOffersNorAppointmentsNorReminders && contact && currentAgent && propertyPreview) {
        noticeData.push({
          type: 'INTENT_MISSING_OFFERS_APPOINTMENTS_AND_REMINDERS',
          referenceId: intent.id!,
          data: {
            contact,
            agent: currentAgent,
            property: propertyPreview,
          },
        });
      }
    }

    return noticeData;
  }, [
    approvedOffer,
    contactAdditionalServices,
    contactOptIns,
    errors,
    intent,
    appointments?.content,
    appointments?.totalElements,
    reminders?.totalElements,
    isLoading,
    latestOffer,
    offerDocuments,
    propertyIntentsWithAcceptedOffer?.content,
    user.email,
    wizardData,
    currentAgent,
    contact,
    propertyPreview,
  ]);

  return {
    data,
    isLoading,
    errors,
    mutate,
  };
}
