import React from 'react';
import { OfferWizardStepProps } from '../types';
import OfferWizardNotesForm, { OfferWizardNotesFormModel } from './OfferWizardNotesForm';
import { toOfferWizardData, toOfferWizardNotesFormModel } from './mappers';

const OfferWizardNotes: React.FC<OfferWizardStepProps> = ({
  initialData,
  formRef,
  onSubmit,
}) => {
  const onInternalSubmit = React.useCallback((formValues: OfferWizardNotesFormModel) => {
    const values = toOfferWizardData(initialData, formValues);

    const isFormDirty = (formRef?.current as any)?.dirty ?? false;

    onSubmit(values, isFormDirty);
  }, [formRef, initialData, onSubmit]);

  return (
    <OfferWizardNotesForm
      initialValues={toOfferWizardNotesFormModel(initialData)}
      formRef={formRef}
      onSubmit={onInternalSubmit}
    />
  );
};

export default OfferWizardNotes;
