import {
  ThemedFunctor, getSpacing, SpacingName, getTypography, TypographyName, FontWeight,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const message: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.padding(theme, [SpacingName.SMALL, 0])}
`;

export const table: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.marginTop(theme, SpacingName.MEDIUM)}
`;

export const tableTitle: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.BODY)}
  ${getSpacing.marginBottom(theme, SpacingName.SMALLER)}

  strong {
    font-weight: ${FontWeight.MEDIUM};
  }
`;
