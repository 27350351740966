import React, { MutableRefObject } from 'react';
import {
  Action, FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { updateAgent } from '../../../providers/api/agent/agentProvider';
import EditAgentBioForm, { EditAgentBioFormModel } from '../../components/edit-agent-bio-form/EditAgentBioForm';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface EditAgentBioActionProps {
  agent: Agent,
  modalTitle?: string,
  onSuccess?: (updatedAgent: Agent) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_AGENT_BIO_SUCCESS_MESSAGE = "La biografia dell'agente è stata modificata con successo";
export const EDIT_AGENT_BIO_ERROR_MESSAGE = "Non è stato possibile modificare la biografia dell'agente";

const EditAgentBioAction: React.FC<EditAgentBioActionProps> = (props) => {
  const {
    agent, onSuccess, modalTitle = 'Modifica biografia', children,
  } = props;

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const formRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const openEditModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const onSubmit = React.useCallback(async (formValues: EditAgentBioFormModel) => {
    try {
      setIsSaving(true);

      const {
        bio, hobbies, professionalBackground, professionalReasons,
      } = formValues;

      const agentToUpdate: Agent = {
        ...agent,
        personalInfo: {
          ...agent.personalInfo,
          bio: bio.trim() || undefined,
          hobbies: hobbies.trim() || undefined,
          professionalBackground: professionalBackground.trim() || undefined,
          professionalReasons: professionalReasons.trim() || undefined,
        },
      };

      const updatedAgent = await updateAgent(agent.id!, agentToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_AGENT_BIO_SUCCESS_MESSAGE);
      modal.close();

      if (onSuccess) {
        onSuccess(updatedAgent);
      }
    } catch (error) {
      setIsSaving(false);
      addError(EDIT_AGENT_BIO_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agent, modal, onSuccess]);

  return (
    <>
      {children
        ? children({ openEditModal, isSaving })
        : (
          <Action
            aria-label="Modifica la biografia dell'agente"
            label="Modifica"
            size="S"
            onClick={openEditModal}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title={modalTitle}
          aria-label="Modale per modificare la biografia dell'agente"
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per modificare la biografia dell'agente"
              onClick={submitForm}
            />
          )}
        >
          <EditAgentBioForm
            innerRef={formRef}
            initialValues={agent.personalInfo}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAgentBioAction;
