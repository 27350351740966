import { addMinutes } from 'date-fns';
import { ReferenceType } from '../../domain/types';
import { DEFAULT_APPOINTMENT_DURATION_IN_MINUTES, PROSPECT_APPOINTMENT_DURATION_IN_MINUTES, PROSPECT_VIRTUAL_APPOINTMENT_DURATION_IN_MINUTES } from '../constants';

export function getAppointmentEndDate(referenceType: ReferenceType, startDate: Date, virtual: boolean = false): Date {
  let duration = DEFAULT_APPOINTMENT_DURATION_IN_MINUTES;
  switch (referenceType) {
    case ReferenceType.PROSPECT:
      duration = virtual ? PROSPECT_VIRTUAL_APPOINTMENT_DURATION_IN_MINUTES : PROSPECT_APPOINTMENT_DURATION_IN_MINUTES;
      break;
    default:
      break;
  }

  return addMinutes(new Date(startDate), duration);
}
