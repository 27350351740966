import React, { FunctionComponent } from 'react';
import { Badge, BadgeProps } from '@doveit/bricks';
import { intentStatusLabels } from '../../../labels';
import { IntentStatus } from '../../../domain/types';

export const intentStatusBadgeColor: Record<IntentStatus, BadgeProps['color']> = {
  BOUGHT: 'success',
  KO_OVERPRICED: 'critical',
  KO_INFO: 'critical',
  KO_AREA: 'critical',
  KO_PROPERTY: 'critical',
  KO_BOUGHT_OTHER: 'critical',
  KO_NOT_INTERESTED: 'critical',
  KO_PROPERTY_NOT_AVAILABLE: 'critical',
  KO_MORTGAGE: 'critical',
  KO_OTHER_CONSTRAINTS: 'critical',
  KO_REFUSED: 'critical',
  KO_DUPLICATE: 'critical',
  IN_PROGRESS: 'warning',
};

export interface IntentStatusBadgeProps {
  status: IntentStatus,
  showInProgress?: boolean,
  emphasis?: BadgeProps['emphasis'],
  size?: BadgeProps['size'],
}

const IntentStatusBadge: FunctionComponent<IntentStatusBadgeProps> = ({
  status,
  showInProgress = false,
  emphasis = 'low',
  size,
}) => {
  if (status === IntentStatus.IN_PROGRESS && !showInProgress) {
    return null;
  }

  return (
    <Badge
      color={intentStatusBadgeColor[status]}
      label={intentStatusLabels[status]}
      emphasis={emphasis}
      size={size}
    />
  );
};

export default IntentStatusBadge;
