import React from 'react';
import {
  Action, FormHandlers, ModalSize, Portal, useModal,
  useNotifications,
} from '@doveit/bricks';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Invoice } from '../../../providers/api/dtos';
import { INVOICEABLE_STATUSES, InvoiceStatus } from '../../../domain/types';
import InvoiceForm from '../../../invoice/components/invoice-form/InvoiceForm';
import { InvoiceModel } from '../../../invoice/models/invoiceModel';
import { computeActionLabels, computeLabels, computeTargetInvoiceStatus } from '../../../invoice/containers/edit-invoice/utils';
import { updateInvoice } from '../../../providers/api/invoice/invoiceProvider';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { formatInputDate } from '../../../utils/form';

export const PROPERTY_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare alcuni dati relativi al pagamento';
export const PROPERTY_WITH_NOT_SELLABLE_STATUS_MESSAGE = `L'immobile non si trova in uno degli stati ammessi (${INVOICEABLE_STATUSES.join(', ')}). Cambia lo stato prima di poter inserire i dati del pagamento`;

interface ChildFnProps {
  openModal: VoidFunction,
}

export interface ManageInvoiceStatusActionProps {
  action: 'edit' | 'manage',
  invoice: Invoice,
  onSuccess?: (updatedInvoice: Invoice) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const ManageInvoiceStatusAction: React.FC<ManageInvoiceStatusActionProps> = ({
  action,
  invoice,
  onSuccess,
  children,
}) => {
  const { userIsFinance, userIsAdmin } = useRBAC();
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const modal = useModal();
  const [isSaving, setIsSaving] = React.useState(false);
  const { addError, addSuccess } = useNotifications();

  const invoiceFormInitialValues: InvoiceModel = React.useMemo(() => ({
    agentFee: invoice?.agentFee?.toString() || '',
    buyerFee: invoice?.buyerFee?.toString() || '',
    sellerFee: invoice?.sellerFee?.toString() || '',
    collectedAt: invoice?.collectedAt ? formatInputDate(new Date(invoice.collectedAt)) : '',
    soldPrice: invoice?.soldPrice?.toString() || '',
    forecastedInvoiceDate: invoice?.forecastedInvoiceDate ? formatInputDate(new Date(invoice.forecastedInvoiceDate)) : '',
  }), [invoice]);

  const allowStatusChange = React.useMemo(() => action === 'manage', [action]);
  const hideForecastedDateField = React.useMemo(() => [InvoiceStatus.TO_COLLECT, InvoiceStatus.COLLECTED].includes(invoice.status), [invoice.status]);
  const hideAdministrativeFields = React.useMemo(() => !userIsAdmin && !userIsFinance, [userIsAdmin, userIsFinance]);
  const { actionLabel, submitActionLabel } = React.useMemo(() => computeActionLabels(action, invoice.status), [action, invoice.status]);
  const { formSubmitLabel, successNotification, errorNotification } = React.useMemo(() => computeLabels(invoice.status, allowStatusChange), [allowStatusChange, invoice.status]);

  const openModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onInvoiceFormSubmit = React.useCallback(async (formValues: InvoiceModel) => {
    setIsSaving(true);

    try {
      const {
        agentFee,
        buyerFee,
        soldPrice,
        sellerFee,
        collectedAt,
        forecastedInvoiceDate,
      } = formValues;

      const invoiceToUpdate: Invoice = {
        ...invoice,
        agentFee: parseFloat(agentFee),
        buyerFee: parseFloat(buyerFee),
        sellerFee: sellerFee ? parseFloat(sellerFee) : undefined,
        soldPrice: parseInt(soldPrice, 10),
        collectedAt: collectedAt || undefined,
        forecastedInvoiceDate,
        status: computeTargetInvoiceStatus(invoice.status, collectedAt, allowStatusChange),
      };

      const updatedInvoice = await updateInvoice(invoiceToUpdate);

      setIsSaving(false);
      addSuccess(successNotification);
      modal.close();

      onSuccess?.(updatedInvoice);
    } catch (error) {
      setIsSaving(false);
      addError(errorNotification);
    }
  }, [addError, addSuccess, allowStatusChange, errorNotification, invoice, modal, onSuccess, successNotification]);

  if (allowStatusChange && invoice.status === InvoiceStatus.COLLECTED) {
    return null;
  }

  return (
    <>
      {children?.({ openModal }) ?? (
        <Action
          label={actionLabel}
          aria-label={actionLabel}
          onClick={openModal}
          size="S"
        />
      )}
      <Portal>
        <SimpleModal
          isOpen={modal.isOpen}
          close={modal.close}
          aria-label="Modale di gestione fattura"
          title={actionLabel}
          size={ModalSize.MEDIUM}
          footer={(
            <Action
              label={submitActionLabel}
              aria-label={submitActionLabel}
              color="primary"
              emphasis="high"
              disabled={invoice.status === InvoiceStatus.COLLECTED}
              onClick={submitForm}
            />
          )}
        >
          <InvoiceForm
            innerRef={formRef}
            initialValues={invoiceFormInitialValues}
            loading={isSaving}
            hideAdministrativeFields={hideAdministrativeFields}
            hideForecastedDateField={hideForecastedDateField}
            submitLabel={formSubmitLabel}
            onSubmit={onInvoiceFormSubmit}
            disabled={invoice.status === InvoiceStatus.COLLECTED}
            disableCollectedAt={invoice.status === InvoiceStatus.TODO || !allowStatusChange}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ManageInvoiceStatusAction;
