/* eslint-disable react/no-unknown-property */
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, Dropdown, Action, ActionIconDropdown, Badge, HStack, Text, Spacing, FontWeight, Tooltip, useNotifications, Message, DetailItemList,
} from '@doveit/bricks';
import { formatEuro } from '@doveit/hammer';
import { raise } from '../../../utils';
import useProperty from '../../hooks/use-property/useProperty';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import RightColumnPageLayout from '../../../layouts/right-column-page-layout/RightColumnPageLayout';
import PropertyCadastralRegistryWidget from '../../containers/property-cadastral-registry-widget/PropertyCadastralRegistryWidget';
import PropertyInfoWidget from '../../containers/property-info-widget/PropertyInfoWidget';
import PropertyDescriptionsWidget from '../../containers/property-descriptions-widget/PropertyDescriptionsWidget';
import PropertyNotesWidget from '../../containers/property-notes-widget/PropertyNotesWidget';
import RightColumnPageSkeleton from '../../../components/page-skeleton/RightColumnPageSkeleton';
import PropertyDocumentsWidget from '../../containers/property-documents-widget/PropertyDocumentsWidget';
import PropertyContactsWidget from '../../containers/property-contacts-widget/PropertyContactsWidget';
import ViewPropertySummary from '../../containers/view-property-summary/ViewPropertySummary';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import {
  Status, ReferenceType, NonResidentialPropertyTypeValue, PropertyType,
} from '../../../domain/types';
import EditPropertyAssignmentAction from '../../containers/edit-property-assignment-action/EditPropertyAssignmentAction';
import { usePropertyNoticesData } from '../../hooks/use-property-notices-data/usePropertyNoticesData';
import NoticeBoard from '../../../notice/components/notice-board/NoticeBoard';
import PropertyAvatar from '../../components/property-avatar/PropertyAvatar';
import ViewLivePropertyAction from '../../containers/view-live-property-action/ViewLivePropertyAction';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import useAssignmentByPropertyId from '../../hooks/use-assignment-by-property-id/useAssignmentByPropertyId';
import PrintPropertyAction from '../../containers/print-property-action/PrintPropertyAction';
import CreateIntentWithAppointmentAction from '../../../intent/containers/create-intent-with-appointment-action/CreateIntentWithAppointmentAction';
import RepublishPropertyAction from '../../containers/republish-property-action/RepublishPropertyAction';
import { goToDetailPage } from '../../../utils/navigate/utils';
import PropertyAssignmentWidget from '../../assignment/components/property-assignment-widget/PropertyAssignmentWidget';
import PropertySaleWidget from '../../containers/property-sale-widget/PropertySaleWidget';
import EditPropertyPrivacyAction from '../../containers/edit-property-privacy-action/EditPropertyPrivacyAction';
import PublishedOnList from '../../published-on/containers/published-on-list/PublishedOnList';
import ManagePublishedOnsAction from '../../published-on/containers/manage-published-ons-action/ManagePublishedOnsAction';
import PropertyMultimediaWidget from '../../containers/property-multimedia-widget/PropertyMultimediaWidget';
import useLatestShootingByPropertyId from '../../hooks/use-latest-shooting-by-property-id/useLatestShootingByPropertyId';
import ManagePropertySpacesAction from '../../containers/manage-property-spaces-action/ManagePropertySpacesAction';
import EditPropertyInfoAction from '../../containers/edit-property-info-action/EditPropertyInfoAction';
import { NO_VALUE_SYMBOL } from '../../utils';
import ViewPropertySellability from '../../containers/view-property-sellability/ViewPropertySellability';
import { copyToClipboard } from '../../../utils/text/text';
import { propertyTypeLabels } from '../../../labels';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import UpsertPropertyShootingAction from '../../containers/upsert-property-shooting-action/UpsertPropertyShootingAction';
import PropertyShootingWidget from '../../containers/property-shooting-widget/PropertyShootingWidget';
import { buildPropertyNotice } from '../../../utils/notice/buildPropertyNotice';

export const LOAD_PROPERTY_ERROR_MESSAGE = "Non è stato possibile caricare le informazioni dell'immobile";

const ViewPropertyPage: React.FC = () => {
  const { id } = useParams();

  const propertyId = id
    ? parseInt(id, 10)
    : raise('missing property id');

  const { userIsAdmin, userIsContentEditor, userIsCallCenter } = useRBAC();
  const { addSuccess, addError } = useNotifications();

  const {
    data: property,
    isLoading: isPropertyLoading,
    error: propertyError,
    mutate: mutateProperty,
  } = useProperty(propertyId);

  const {
    data: shooting,
    mutate: mutateShooting,
  } = useLatestShootingByPropertyId(propertyId);

  const isMobile = useIsDevice('mobile');
  const { data: noticesData, mutate: mutateNoticesData } = usePropertyNoticesData(property);

  const userIsPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(property?.agentEmail);
  const { data: assignment, error: assignmentError } = useAssignmentByPropertyId(propertyId);

  const canCopyPropertyReference = React.useMemo(() => userIsAdmin || userIsContentEditor, [userIsAdmin, userIsContentEditor]);
  const canRepublishProperty = React.useMemo(() => userIsAdmin || userIsContentEditor, [userIsAdmin, userIsContentEditor]);
  const canPrintProperty = React.useMemo(() => userIsAdmin || userIsPropertyAgentOrManager, [userIsAdmin, userIsPropertyAgentOrManager]);
  const canViewProspect = React.useMemo(() => (userIsAdmin || userIsPropertyAgentOrManager || userIsContentEditor), [userIsAdmin, userIsPropertyAgentOrManager, userIsContentEditor]);
  const canCreateIntent = React.useMemo(() => userIsAdmin || userIsPropertyAgentOrManager || userIsContentEditor || userIsCallCenter, [userIsAdmin, userIsPropertyAgentOrManager, userIsContentEditor, userIsCallCenter]);

  const goToViewProspectPage = React.useCallback(() => {
    if (assignment) {
      goToDetailPage(assignment.prospectId!, ReferenceType.PROSPECT);
    }
  }, [assignment]);

  const goToGenerateShowcasePage = React.useCallback(() => {
    window.open(`/marketing/generate/showcase?property_id=${propertyId}`, '_blank');
  }, [propertyId]);

  const copyPropertyReference = React.useCallback(async () => {
    try {
      const ref = [property?.referenceId, property?.geo?.normalizedAddress]
        .filter((v) => v)
        .join(' - ');

      await copyToClipboard(ref);
      addSuccess("Il riferimento all'immobile è stato copiato negli appunti con successo");
    } catch (_) {
      addError("Non è stato possibile copiare il riferimento all'immobile negli appunti");
    }
  }, [addError, addSuccess, property?.geo?.normalizedAddress, property?.referenceId]);

  const onIntentCreated = React.useCallback(async () => {
    // TODO Let's define what we need to mutate
    // The affected part should be the "Matching" info into "Portale" section
    // See https://doveit.atlassian.net/browse/DOVE-12889
  }, []);

  const canUpdateAssignment = React.useMemo(() => {
    if (!property) return false;

    if ((userIsPropertyAgentOrManager && !userIsContentEditor && !userIsAdmin) && property.status !== Status.DRAFT) {
      return 'limited';
    }

    if ([Status.RITIRATO, Status.VENDUTO].includes(property.status)) {
      return userIsAdmin;
    }

    return userIsAdmin || userIsPropertyAgentOrManager;
  }, [userIsPropertyAgentOrManager, property, userIsAdmin, userIsContentEditor]);

  const canManageShooting = React.useMemo(
    () => property?.status
      && [Status.DRAFT, Status.ANTEPRIMA].includes(property.status)
      && (userIsAdmin || userIsContentEditor || userIsPropertyAgentOrManager),
    [property?.status, userIsAdmin, userIsPropertyAgentOrManager, userIsContentEditor],
  );

  const canUpdateInfo = React.useMemo(() => {
    if (!property) return false;

    if ([Status.DRAFT, Status.ANTEPRIMA].includes(property.status)) {
      return userIsAdmin || userIsContentEditor || userIsPropertyAgentOrManager;
    }

    return userIsAdmin || userIsContentEditor;
  }, [property, userIsAdmin, userIsContentEditor, userIsPropertyAgentOrManager]);

  const canManageSpaces = React.useMemo(() => {
    if (!property) return false;

    if ([Status.DRAFT, Status.ANTEPRIMA].includes(property.status)) {
      return userIsAdmin || userIsContentEditor || userIsPropertyAgentOrManager;
    }

    return userIsAdmin || userIsContentEditor;
  }, [property, userIsAdmin, userIsContentEditor, userIsPropertyAgentOrManager]);

  const canUpdatePrivacy = React.useMemo(() => {
    switch (property?.status) {
      case Status.DRAFT:
      case Status.ANTEPRIMA:
        return userIsPropertyAgentOrManager || userIsContentEditor || userIsAdmin;
      case Status.LIVE:
      case Status.PROPOSTA:
        return userIsContentEditor || userIsAdmin;
      default:
        return false;
    }
  }, [property?.status, userIsAdmin, userIsContentEditor, userIsPropertyAgentOrManager]);

  const canViewPublishedOns = React.useMemo(() => {
    if (property?.status === Status.RITIRATO) {
      return false;
    }

    return userIsCallCenter || userIsContentEditor || userIsPropertyAgentOrManager || userIsAdmin;
  }, [property?.status, userIsAdmin, userIsCallCenter, userIsContentEditor, userIsPropertyAgentOrManager]);

  const canViewMatchingSection = React.useMemo(
    () => userIsCallCenter || userIsContentEditor || userIsPropertyAgentOrManager || userIsAdmin,
    [userIsAdmin, userIsCallCenter, userIsContentEditor, userIsPropertyAgentOrManager],
  );

  const canUpdatePublishedOns = React.useMemo(() => {
    if (property?.status === Status.RITIRATO) {
      return false;
    }

    return userIsContentEditor || userIsAdmin;
  }, [property?.status, userIsAdmin, userIsContentEditor]);

  const canGenerateShowcase = React.useMemo(
    () => property?.status
      && [Status.ANTEPRIMA, Status.LIVE, Status.VENDUTO, Status.PROPOSTA].includes(property.status)
      && (userIsAdmin || userIsPropertyAgentOrManager),
    [property?.status, userIsAdmin, userIsPropertyAgentOrManager],
  );

  const showEvaluation = React.useMemo(
    () => property?.evaluation && (userIsAdmin || userIsPropertyAgentOrManager) && ![Status.RITIRATO, Status.VENDUTO].includes(property?.status),
    [property, userIsAdmin, userIsPropertyAgentOrManager],
  );

  const notices = React.useMemo(() => {
    if (!property) return [];

    return (noticesData || []).map((noticeData) => buildPropertyNotice(noticeData, {
      onPropertyUpdate: async () => {
        await mutateProperty();
        await mutateNoticesData();
      },
      onOfferUpdate: mutateNoticesData,
      onAssignmentUpdate: mutateNoticesData,
      onDocumentCreate: mutateNoticesData,
      onDocumentUpdate: mutateNoticesData,
      onReviewRequest: mutateNoticesData,
      onAssignmentWizardUpdate: mutateNoticesData,
      onPropertyShootingUpdate: mutateNoticesData,
      onPropertyContentUpdate: mutateNoticesData,
      onPropertyPublishedOnsUpdate: async () => {
        await mutateProperty();
        await mutateNoticesData();
      },
    }));
  }, [mutateNoticesData, mutateProperty, noticesData, property]);

  const showNonResidentialWarning = React.useMemo(() => {
    if (!property) return false;

    const isNonResidential = (Object.values(NonResidentialPropertyTypeValue) as PropertyType[]).includes(property.propertyType);

    return (isNonResidential && (userIsAdmin || userIsContentEditor || userIsPropertyAgentOrManager));
  }, [property, userIsAdmin, userIsContentEditor, userIsPropertyAgentOrManager]);

  const galleryActions = React.useMemo(() => {
    if (property) {
      return {
        canSort: userIsAdmin || userIsContentEditor || (userIsPropertyAgentOrManager && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE, Status.PROPOSTA].includes(property.status)),
        canAdd: userIsAdmin || userIsContentEditor || (userIsPropertyAgentOrManager && property.status === Status.DRAFT),
        canDelete: userIsAdmin || userIsContentEditor || (userIsPropertyAgentOrManager && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE].includes(property.status)),
        canEdit: userIsAdmin || userIsContentEditor || (userIsPropertyAgentOrManager && [Status.DRAFT, Status.ANTEPRIMA, Status.LIVE, Status.PROPOSTA].includes(property.status)),
        canSeeMissingAboutError: userIsAdmin || userIsContentEditor,
      };
    }
    return {
      canSort: false,
      canAdd: false,
      canDelete: false,
      canEdit: false,
    };
  }, [property, userIsAdmin, userIsContentEditor, userIsPropertyAgentOrManager]);

  const navigateToMatchingPage = React.useCallback(() => {
    window.open(`/properties/${property!.id}/matching`, '_blank');
  }, [property]);

  if (propertyError) {
    return <GenericErrorPage title={LOAD_PROPERTY_ERROR_MESSAGE} />;
  }

  if (!property || isPropertyLoading) {
    return <RightColumnPageSkeleton />;
  }

  return (
    <RightColumnPageLayout
      aria-label="Pagina immobile"
      topContent={(
        <>
          <NoticeBoard notices={notices} />
          {showNonResidentialWarning && (
            <Message
              data-testid="non-residential-warning-message"
              boxed
              type="warning"
              message={`
                La tipologia dell'immobile è ${propertyTypeLabels[property.propertyType]} e la sua pubblicazione sui portali è eseguita manualmente.
                Eventuali modifiche a questa scheda non sono automaticamente apportate alle pubblicazioni relative.
              `}
            />
          )}
        </>
      )}
      title={(
        <HStack
          data-testid="page-title"
          gap={200}
          wrap="nowrap"
        >
          <PropertyAvatar size="M" />
          <div>
            <Text.H4
              as="div"
              fontWeight={FontWeight.REGULAR}
            >
              {property.geo?.normalizedAddress || 'Immobile'}
            </Text.H4>
            <Badge
              color="primary"
              label={property.referenceId!}
              size="XS"
            />
          </div>
        </HStack>
      )}
      actions={(
        <>
          {!isMobile && canViewMatchingSection && (
            <Action
              aria-label="Vedi potenziali acquirenti (header)"
              label="Vedi potenziali acquirenti"
              emphasis="high"
              size="M"
              onClick={navigateToMatchingPage}
            />
          )}
          <ActionIconDropdown
            label="Azioni sull'immobile"
            aria-label="Azioni sull'immobile"
          >
            {isMobile && canViewMatchingSection && (
              <Dropdown.Option
                aria-label="Vedi potenziali acquirenti (header)"
                label="Vedi potenziali acquirenti"
                onClick={navigateToMatchingPage}
              />
            )}
            {canCopyPropertyReference && (
              <Dropdown.Option
                label="Copia riferimento"
                aria-label="Copia riferimento all'immobile"
                onClick={copyPropertyReference}
              />
            )}
            <ViewLivePropertyAction property={property}>
              {({ view }) => (
                <Dropdown.Option
                  label="Visita"
                  onClick={view}
                />
              )}
            </ViewLivePropertyAction>
            {canRepublishProperty && (
              <RepublishPropertyAction
                property={property}
                onSuccess={mutateProperty}
              >
                {({ loading, republish }) => (
                  <Dropdown.Option
                    label="Ripubblica"
                    loading={loading}
                    onClick={republish}
                  />
                )}
              </RepublishPropertyAction>
            )}
            {canPrintProperty && (
              <PrintPropertyAction property={property}>
                {({ print }) => (
                  <Dropdown.Option
                    label="Stampa"
                    onClick={print}
                  />
                )}
              </PrintPropertyAction>
            )}
            {canViewProspect && (
              <Dropdown.Option
                label="Visualizza valutazione"
                aria-label="view-prospect-action"
                disabled={assignmentError && !assignment}
                onClick={goToViewProspectPage}
              />
            )}
            {canCreateIntent && (
              <CreateIntentWithAppointmentAction
                property={property}
                onIntentCreated={onIntentCreated}
              >
                {({ disabled, openCreateIntentModal }) => (
                  <Dropdown.Option
                    label="Aggiungi interesse"
                    disabled={disabled}
                    onClick={openCreateIntentModal}
                  />
                )}
              </CreateIntentWithAppointmentAction>
            )}
            {canGenerateShowcase && (
              <Dropdown.Option
                label="Genera locandina"
                aria-label="Genera locandina immobile"
                onClick={goToGenerateShowcasePage}
              />
            )}
          </ActionIconDropdown>
        </>
      )}
      primarySide={(
        <Card aria-label="Sommario immobile">
          <Card.Header
            title="Sommario"
            secondaryActions={[
              (canUpdateInfo && (
                <EditPropertyInfoAction
                  property={property}
                  onSuccess={() => mutateProperty()}
                >
                  {({ edit, isSaving }) => (
                    <Dropdown.Option
                      aria-label="Modifica dati immobile"
                      label="Modifica dati immobile"
                      onClick={edit}
                      loading={isSaving}
                    />
                  )}
                </EditPropertyInfoAction>
              )),
              (canManageSpaces && (
                <ManagePropertySpacesAction propertyId={propertyId}>
                  {({ manage }) => (
                    <Dropdown.Option
                      label="Modifica spazi"
                      onClick={manage}
                    />
                  )}
                </ManagePropertySpacesAction>
              )),
              (canManageShooting && shooting && (
                <UpsertPropertyShootingAction
                  propertyId={propertyId}
                  shooting={shooting}
                  onSuccess={() => mutateShooting()}
                >
                  {({ upsert, isSaving }) => (
                    <Dropdown.Option
                      label="Modifica dati shooting"
                      loading={isSaving}
                      onClick={upsert}
                    />
                  )}
                </UpsertPropertyShootingAction>
              )),
              (canUpdateAssignment && (
                <EditPropertyAssignmentAction
                  propertyId={propertyId}
                  limitEdit={canUpdateAssignment === 'limited'}
                >
                  {({ openEditModal, isSaving }) => (
                    <Dropdown.Option
                      label="Modifica mandato"
                      loading={isSaving}
                      onClick={openEditModal}
                    />
                  )}
                </EditPropertyAssignmentAction>
              )),
              (canUpdatePrivacy && (
                <EditPropertyPrivacyAction
                  property={property}
                  onSuccess={() => mutateProperty()}
                >
                  {({ edit, isSaving }) => (
                    <Dropdown.Option
                      label="Modifica privacy"
                      loading={isSaving}
                      onClick={edit}
                    />
                  )}
                </EditPropertyPrivacyAction>
              )),
            ]}
          />
          <Card.Content>
            <ViewPropertySummary
              property={property}
              onPropertyUpdate={() => mutateProperty()}
              {...galleryActions}
            />
          </Card.Content>
          {canViewPublishedOns && (
            <>
              <Card.Header
                secondaryActions={[
                  (canUpdatePublishedOns && (
                    <ManagePublishedOnsAction
                      property={property}
                      onSuccess={() => mutateProperty()}
                    >
                      {({ openPublishedOnsModal, disabled }) => (
                        <Dropdown.Option
                          label="Modifica"
                          disabled={disabled}
                          onClick={openPublishedOnsModal}
                        />
                      )}
                    </ManagePublishedOnsAction>
                  )),
                ]}
              >
                <Text.H4 lineHeight="1" fontWeight={FontWeight.MEDIUM}>
                  Portali
                </Text.H4>
                {(userIsAdmin || userIsContentEditor) && property.version > 0 && (
                  <Tooltip content="Versione pubblicazione">
                    <div>
                      <Badge
                        label={`v.${property.version}`}
                        aria-label="Versione della pubblicazione"
                        size="XS"
                      />
                    </div>
                  </Tooltip>
                )}
              </Card.Header>
              <Card.Content>
                <PublishedOnList property={property} />
              </Card.Content>
            </>
          )}
          <Card.Divider />
          <Card.Content aria-label="price-section">
            <ViewPropertySellability
              geo={property.geo?.latitude && property.geo.longitude ? {
                latitude: property.geo.latitude.toString(),
                longitude: property.geo.longitude.toString(),
              } : undefined}
              propertySize={property.propertySize}
              size="XS"
            />
            <Spacing margin={[150, 0, 0]} />
            <DetailItemList>
              {property.status !== Status.VENDUTO && (
                <>
                  <DetailItemList.Item label="Prezzo di pubblicazione">
                    {property.price ? formatEuro(property.price) : NO_VALUE_SYMBOL}
                  </DetailItemList.Item>
                  <DetailItemList.Item label="Prezzo minimo">
                    {property.minimumPrice ? formatEuro(property.minimumPrice) : NO_VALUE_SYMBOL}
                  </DetailItemList.Item>
                </>
              )}
              {property.status === Status.VENDUTO && (
                <DetailItemList.Item label="Prezzo di vendita">
                  {property.soldPrice ? formatEuro(property.soldPrice) : NO_VALUE_SYMBOL}
                </DetailItemList.Item>
              )}
              {showEvaluation && (
                <DetailItemList.Item label="Val. agente">
                  {formatEuro(property.evaluation!)}
                </DetailItemList.Item>
              )}
            </DetailItemList>
          </Card.Content>
          {canViewMatchingSection && (
            <>
              <Card.Divider />
              <Card.Content aria-label="matching-section">
                <DetailItemList>
                  <DetailItemList.Item label="Matching" withoutPadding>
                    <Action
                      aria-label="Vedi potenziali acquirenti"
                      label="Vedi potenziali acquirenti"
                      size="S"
                      onClick={navigateToMatchingPage}
                    />
                  </DetailItemList.Item>
                </DetailItemList>
              </Card.Content>
            </>
          )}
        </Card>
      )}
      secondarySide={(
        <>
          <PropertyShootingWidget
            propertyId={propertyId}
            onUpsert={mutateShooting}
          />
          <PropertyNotesWidget propertyId={propertyId} />
          <PropertyContactsWidget propertyId={propertyId} />
          <PropertyAssignmentWidget propertyId={propertyId} propertyStatus={property.status} />
        </>
      )}
    >
      <PropertySaleWidget propertyId={propertyId} />
      <PropertyInfoWidget propertyId={propertyId} />
      <PropertyCadastralRegistryWidget propertyId={propertyId} />
      <PropertyDescriptionsWidget propertyId={propertyId} />
      <PropertyMultimediaWidget
        propertyId={propertyId}
        {...galleryActions}
      />
      <PropertyDocumentsWidget propertyId={propertyId} />
    </RightColumnPageLayout>
  );
};

export default ViewPropertyPage;
