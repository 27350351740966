import React from 'react';
import {
  ActionDropDown, ActionDropdownProps, ConfirmModal, Input, Label, Message, Spacing, useModal, useNotifications,
} from '@doveit/bricks';
import {
  endOfDay, isFuture, isValid, max, startOfDay,
} from 'date-fns';
import { Agency } from '../../../providers/api/dtos';
import {
  optionsByStatus, AGENCY_STATUS_UPDATED_SUCCESS_MESSAGE, AGENCY_STATUS_UPDATED_ERROR_MESSAGE, statusColor, confirmModalTextByStatus, AGENCY_NAME_PLACEHOLDER, NOT_VALID_DATE_ERROR_MESSAGE, NOT_FUTURE_DATE_ERROR_MESSAGE, agencyStatusActionLabels,
} from './constants';
import { AgencyStatusAction } from './types';
import { formatInputDate } from '../../../utils/form';
import { updateAgency } from '../../../providers/api/agency/agencyProvider';
import { calculateMembershipStatus } from '../../../agent/utils/calculateMembershipStatus';
import { MembershipStatus } from '../../../domain/types';
import { membershipStatusLabels } from '../../../labels';
import { membershipStatusBadgeIcon } from '../../../agent/constants';

export interface UpdateAgencyStatusProps {
  agency: Agency,
  onSuccess?: (updatedStatus: MembershipStatus) => void,
  emphasis?: ActionDropdownProps['emphasis'],
}

const UpdateAgencyStatus: React.FC<UpdateAgencyStatusProps> = ({
  agency,
  onSuccess,
  emphasis = 'low',
}) => {
  const confirmModal = useModal();
  const [selectedAction, setSelectedAction] = React.useState<AgencyStatusAction>();
  const [startDate, setStartDate] = React.useState(new Date(agency.startDate));
  const [endDate, setEndDate] = React.useState<Date | undefined>(agency.endDate ? new Date(agency.endDate) : undefined);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dateError, setDateError] = React.useState<string | undefined>();
  const { addSuccess, addError } = useNotifications();

  const currentStatus = React.useMemo(
    () => calculateMembershipStatus(new Date(agency.startDate), agency.endDate ? new Date(agency.endDate) : undefined),
    [agency.endDate, agency.startDate],
  );

  const options = React.useMemo(() => Object.values(AgencyStatusAction).map((action) => ({
    key: action,
    label: agencyStatusActionLabels[action],
    onClick: () => {
      setSelectedAction(action);

      switch (action) {
        case AgencyStatusAction.ACTIVATE: {
          setStartDate(currentStatus === MembershipStatus.UNDER_ACTIVATION ? new Date(agency.startDate) : new Date());
          setEndDate(undefined);
          break;
        }
        case AgencyStatusAction.REVOKE: {
          setEndDate(agency.endDate ? new Date(agency.endDate) : undefined);
          break;
        }
        case AgencyStatusAction.REACTIVATE: {
          setEndDate(undefined);
          break;
        }
        default: break;
      }

      confirmModal.open();
    },
  })).filter((option) => optionsByStatus[currentStatus].includes(option.key)), [agency.endDate, agency.startDate, confirmModal, currentStatus]);

  const onConfirm = React.useCallback(async () => {
    if (dateError) {
      return;
    }

    setIsSaving(true);

    try {
      await updateAgency(agency.id!, {
        ...agency,
        startDate: startDate.toISOString(),
        endDate: endDate && endDate.toISOString(),
      });

      setIsSaving(false);
      addSuccess(AGENCY_STATUS_UPDATED_SUCCESS_MESSAGE);

      if (onSuccess) {
        const updatedStatus = calculateMembershipStatus(new Date(startDate), endDate ? new Date(endDate) : undefined);
        onSuccess(updatedStatus);
      }
    } catch (error) {
      setIsSaving(false);
      addError(AGENCY_STATUS_UPDATED_ERROR_MESSAGE);
    }

    confirmModal.close();
  }, [addError, addSuccess, agency, confirmModal, dateError, endDate, onSuccess, startDate]);

  const onAbort = React.useCallback(() => {
    setDateError(undefined);
    confirmModal.close();
  }, [confirmModal]);

  const onDateSet: React.FocusEventHandler<HTMLInputElement> = ({ target }) => {
    const dateToSave = new Date(target.value);
    const dateIsValid = isValid(dateToSave);

    setDateError(undefined);

    if (dateIsValid && isFuture(endOfDay(dateToSave))) {
      if (selectedAction === AgencyStatusAction.ACTIVATE) {
        setStartDate(startOfDay(dateToSave));
      } else {
        setEndDate(endOfDay(dateToSave));
      }
    } else {
      setDateError(!dateIsValid ? NOT_VALID_DATE_ERROR_MESSAGE : NOT_FUTURE_DATE_ERROR_MESSAGE);
    }
  };

  return (
    <>
      <ActionDropDown
        aria-label="Selezione dello stato dell'agenzia"
        label={membershipStatusLabels[currentStatus]}
        options={options}
        size="S"
        iconLeft={{ path: membershipStatusBadgeIcon[currentStatus] }}
        color={statusColor[currentStatus]}
        emphasis={emphasis}
      />
      {selectedAction && (
        <ConfirmModal
          isOpen={confirmModal.isOpen}
          saving={isSaving}
          aria-label="Modale di conferma cambio di stato agenzia"
          title={confirmModalTextByStatus[selectedAction].title}
          onConfirm={onConfirm}
          onAbort={onAbort}
        >
          <div dangerouslySetInnerHTML={{ __html: confirmModalTextByStatus[selectedAction].message.replace(AGENCY_NAME_PLACEHOLDER, agency.name) }} />

          <Spacing margin={[200, 0, 0]}>
            {selectedAction === AgencyStatusAction.ACTIVATE && (
              <>
                <Label text="Data di inizio" />
                <Input
                  type="date"
                  defaultValue={currentStatus === MembershipStatus.REVOKED ? '' : formatInputDate(startDate)}
                  aria-label="Campo per inserire la data"
                  onBlur={onDateSet}
                  error={!!dateError}
                  min={formatInputDate(new Date())}
                />
              </>
            )}
            {selectedAction === AgencyStatusAction.REVOKE && (
              <>
                <Label text="Data di fine" />
                <Input
                  type="date"
                  defaultValue={endDate ? formatInputDate(endDate) : ''}
                  aria-label="Campo per inserire la data"
                  onBlur={onDateSet}
                  error={!!dateError}
                  min={formatInputDate(max([new Date(), startDate]))}
                />
              </>
            )}
            {dateError && (
              <Spacing margin={[100, 0, 0]}>
                <Message
                  type="critical"
                  message={dateError}
                />
              </Spacing>
            )}
          </Spacing>
        </ConfirmModal>
      )}
    </>
  );
};

export default UpdateAgencyStatus;
