import {
  ThemedFunctor,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({
  theme,
}) => css`
  display: flex;
  align-items: center;
  color: ${theme.palettes.brand.primary};

  > * {
    margin-right: .75rem;
  }

  > *:last-child {
    margin-right: 0;
  }
`;
