import React from 'react';
import {
  ActionDropDown, ActionDropdownOption, Badge, ConfirmModal, ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE,
  ICON_PROGRESS_CHECK,
  Portal,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import { isFuture } from 'date-fns';
import { BadgeColor } from '@doveit/bricks/dist/typings/components/badge/types';
import { formatDate } from '@doveit/hammer';
import { Appointment } from '../../../providers/api/dtos';
import { AppointmentStatus } from '../../../domain/types';
import { updateAppointment } from '../../../providers/api/appointment/appointmentProvider';
import useRBAC from '../../../hooks/use-rbac/useRBAC';

export const RESOLVED_APPOINTMENT_SUCCESS_MESSAGE = 'L\'appuntamento è stato completato con successo.';
export const CANCEL_APPOINTMENT_SUCCESS_MESSAGE = 'L\'appuntamento è stato annullato con successo.';
export const CHANGE_STATUS_APPOINTMENT_ERROR_MESSAGE = 'Non è stato possibile aggiornare lo stato dell\'appuntamento.';

export interface UpdateAppointmentStatusProps {
  appointment: Appointment,
  showAsBadge?: boolean,
  showIcon?: boolean,
  onSuccess?: (updatedAppointment: Appointment) => void,
  emphasis?: 'low' | 'high',
}

interface StatusProps {
  label: string,
  icon: string,
  color: BadgeColor,
  showAsBadge: boolean,
  actions?: ActionDropdownOption[],
}

const UpdateAppointmentStatus: React.FC<UpdateAppointmentStatusProps> = ({
  showAsBadge = false,
  showIcon = false,
  appointment,
  onSuccess,
  emphasis = 'low',
}) => {
  const { userIsCallCenter } = useRBAC();
  const resolveAppointmentModal = useModal<{ newStatus: AppointmentStatus, appointment: Appointment }>();
  const { addSuccess, addError } = useNotifications();
  const onClickAction = React.useCallback((newStatus: AppointmentStatus) => () => {
    resolveAppointmentModal.open({
      newStatus,
      appointment,
    });
  }, [appointment, resolveAppointmentModal]);

  const resolveAppointment = React.useCallback((status: AppointmentStatus) => async () => {
    try {
      const updatedAppointment = await updateAppointment(appointment.id!, {
        ...appointment,
        status,
      });

      resolveAppointmentModal.close();
      addSuccess(status === AppointmentStatus.DONE ? RESOLVED_APPOINTMENT_SUCCESS_MESSAGE : CANCEL_APPOINTMENT_SUCCESS_MESSAGE);

      onSuccess?.(updatedAppointment);
    } catch {
      addError(CHANGE_STATUS_APPOINTMENT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, onSuccess, appointment, resolveAppointmentModal]);

  const modalMessage = React.useMemo(() => {
    if (resolveAppointmentModal.data) {
      const formattedDate = formatDate(new Date(resolveAppointmentModal.data.appointment.startDate), { time: true });
      const status = resolveAppointmentModal.data.newStatus === AppointmentStatus.DONE ? 'risolto' : 'annullato';
      return `Confermando l'operazione l'appuntamento previsto per il giorno ${formattedDate} sarà ${status}.`;
    }
    return '';
  }, [resolveAppointmentModal.data]);

  const statusProps: StatusProps = React.useMemo(() => {
    switch (appointment.status) {
      case AppointmentStatus.DONE: {
        return {
          label: 'Fatto',
          icon: ICON_CHECK_CIRCLE_OUTLINE,
          color: 'success',
          showAsBadge: true,
        };
      }
      case AppointmentStatus.CANCELLED: {
        return {
          label: 'Annullato',
          icon: ICON_ALERT_CIRCLE_OUTLINE,
          color: 'critical',
          showAsBadge: true,
        };
      }
      case AppointmentStatus.TODO: {
        return isFuture(appointment.startDate)
          ? {
            label: 'Da fare',
            icon: ICON_PROGRESS_CHECK,
            color: 'info',
            showAsBadge: userIsCallCenter ? false : showAsBadge,
            actions: [{
              key: 'cancel',
              label: 'Annulla appuntamento',
              onClick: onClickAction(AppointmentStatus.CANCELLED),
            }],
          } : {
            label: 'Da esitare',
            icon: ICON_PROGRESS_CHECK,
            color: 'warning',
            showAsBadge,
            actions: [{
              key: 'done',
              label: 'Segna come fatto',
              onClick: onClickAction(AppointmentStatus.DONE),
            }, {
              key: 'cancel',
              label: 'Segna come annullato',
              onClick: onClickAction(AppointmentStatus.CANCELLED),
            }],
          };
      }
      default: throw Error('Unreachable code');
    }
  }, [appointment.startDate, appointment.status, onClickAction, showAsBadge, userIsCallCenter]);
  return (
    <>
      {(statusProps.showAsBadge) && (
        <Badge
          aria-label="Badge per visualizzare lo stato dell'appuntamento"
          label={statusProps.label}
          icon={showIcon ? statusProps.icon : undefined}
          color={statusProps.color}
          emphasis={emphasis}
          size="XS"
        />
      )}

      {!statusProps.showAsBadge && (
        <ActionDropDown
          aria-label="Azione per aggiornare lo stato dell'appuntamento"
          label={statusProps.label}
          iconLeft={showIcon ? { path: statusProps.icon } : undefined}
          color={statusProps.color}
          emphasis={emphasis}
          size="XS"
          options={statusProps.actions}
        />
      )}
      {resolveAppointmentModal.data && (
        <Portal>
          <ConfirmModal
            {...resolveAppointmentModal}
            title={resolveAppointmentModal.data.newStatus === AppointmentStatus.DONE ? 'Conferma risoluzione appuntamento' : 'Conferma annullamento appuntamento'}
            onConfirm={resolveAppointment(resolveAppointmentModal.data.newStatus)}
            onAbort={resolveAppointmentModal.close}
            dismissable={false}
            aria-label="Modale per cambio stato appuntamento"
          >
            {modalMessage}
          </ConfirmModal>
        </Portal>
      )}
    </>
  );
};

export default UpdateAppointmentStatus;
