import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { paramsSerializer } from '../api/api';
import { createClient as createAxiosClient } from './axiosClient';

export const TRUSTPILOT_API_V1_BASE_URL = process.env.REACT_APP_TRUSTPILOT_API_V1_BASE_URL || 'http://localhost:8081/v1';
export const TRUSTPILOT_API_KEY = process.env.REACT_APP_TRUSTPILOT_API_KEY || '';

export type TrustpilotClient = AxiosInstance;

let trustpilotClient: TrustpilotClient;

export async function getTrustpilotClient(): Promise<TrustpilotClient> {
  if (trustpilotClient) {
    return trustpilotClient;
  }

  const options: AxiosRequestConfig = {
    baseURL: TRUSTPILOT_API_V1_BASE_URL,
    params: {
      apikey: TRUSTPILOT_API_KEY,
    },
    paramsSerializer,
  };

  trustpilotClient = createAxiosClient(options);

  return trustpilotClient;
}
