import {
  ActionIcon, ICON_TRASH_CAN_OUTLINE, Portal, useModal, useNotifications, ConfirmModal,
} from '@doveit/bricks';
import React, { FunctionComponent, useState, useCallback } from 'react';
import { deleteReminder } from '../../../providers/api/reminder/reminderProvider';
import { Reminder } from '../../../providers/api/dtos';

interface ChildFnProps {
  disabled?: boolean,
  openDeleteReminderModal?: VoidFunction,
}

export interface DeleteReminderActionProps {
  reminderId: number,
  disabled?: boolean,
  onSuccess?: (deletedReminderId: NonNullable<Reminder['id']>) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const DELETE_REMINDER_SUCCESS_MESSAGGE = 'Il promemoria è stato eliminato correttamente';
export const DELETE_REMINDER_ERROR_MESSAGGE = "Errore durante l'eliminazione del promemoria";

const DeleteReminderAction: FunctionComponent<DeleteReminderActionProps> = ({
  reminderId,
  disabled,
  onSuccess,
  children,
}) => {
  const [saving, setSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();
  const modal = useModal();

  const openModal = useCallback(() => {
    modal.open();
  }, [modal]);

  const onAbort = useCallback(() => {
    modal.close();
  }, [modal]);

  const onConfirm = useCallback(async () => {
    setSaving(true);

    try {
      await deleteReminder(reminderId);

      setSaving(false);
      addSuccess(DELETE_REMINDER_SUCCESS_MESSAGGE);

      modal.close();
      onSuccess?.(reminderId);
    } catch (err) {
      setSaving(false);
      addError(DELETE_REMINDER_ERROR_MESSAGGE);
    }
  }, [reminderId, addSuccess, modal, onSuccess, addError]);

  return (
    <>
      {children
        ? children({
          disabled: !!disabled,
          openDeleteReminderModal: disabled
            ? undefined
            : openModal,
        })
        : (
          <ActionIcon
            icon={{ path: ICON_TRASH_CAN_OUTLINE }}
            aria-label="Pulsante per eliminare il promemoria"
            dataRef="delete-reminder-action"
            label="Elimina"
            size="S"
            onClick={openModal}
            disabled={disabled}
          />
        )}
      <Portal>
        <ConfirmModal
          {...modal}
          saving={saving}
          aria-label="Modale per eliminare il promemoria"
          title="Conferma eliminazione promemoria"
          onConfirm={onConfirm}
          onAbort={onAbort}
          dismissable={false}
        >
          Confermando l’azione, il promemoria sarà eliminato e i dati non potranno essere recuperati.
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default DeleteReminderAction;
