/* eslint-disable react/no-unknown-property */
import {
  Icon, ICON_IMAGE_OUTLINE, Stack, Text,
} from '@doveit/bricks';
import React from 'react';
import {
  useDropzone, DropEvent, FileRejection, DropzoneOptions, Accept,
} from 'react-dropzone';
import { FileMimeType } from '../../types';
import formatBytes from '../../utils/format-bytes/formatBytes';
import * as styles from './FileUploader.style';
import { FileMimeTypeExtensions } from '../../constants';
import { toImageFile } from './toImageFile';

export type OnDropType = (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => void;

export interface FileUploaderProps extends React.AriaAttributes {
  maxFiles?: number,
  maxSize?: number,
  allowedMimeTypes?: FileMimeType[],
  onDrop: OnDropType,
  validator?: DropzoneOptions['validator'],
}

export const DEFAULT_MAX_FILES = 5;
export const DEFAULT_MAX_SIZE = 20971520; // Equal to 20 MB

export const DEFAULT_ALLOWED_MIME_TYPES = [
  FileMimeType.JPEG,
  FileMimeType.PNG,
];

const FileUploader: React.FC<FileUploaderProps> = ({
  maxFiles = DEFAULT_MAX_FILES,
  maxSize = DEFAULT_MAX_SIZE,
  allowedMimeTypes = DEFAULT_ALLOWED_MIME_TYPES,
  onDrop,
  validator,
  ...rest
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    validator,
    maxSize,
    maxFiles,
    accept: allowedMimeTypes.reduce((acc, fileMimeType) => {
      acc[fileMimeType] = FileMimeTypeExtensions[fileMimeType];
      return acc;
    }, {} as Accept),
    useFsAccessApi: false,
    getFilesFromEvent: async (event: any) => {
      const files = event.target?.files || event.dataTransfer?.files;
      const promises = [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const imageFile = toImageFile(file);
        if (imageFile) {
          promises.push(imageFile);
        } else {
          promises.push(file); // is not an image file
        }
      }
      return Promise.all(promises);
    },
  });

  return (
    <div
      {...rest}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div
        $isDragActive={isDragActive}
        css={styles.baseStyle}
      >
        <Icon
          path={ICON_IMAGE_OUTLINE}
          size={48}
        />
        <Stack gap={50}>
          <Text.H4 css={styles.text}>
            {isDragActive ? 'Rilascia i file per caricarli' : 'Trascina o clicca qui per caricare nuovi file'}
          </Text.H4>

          <Text.BodySmall css={styles.text}>
            Sono consentiti file
            {` ${(allowedMimeTypes as []).map((t: FileMimeType) => `*.${t.split('/').pop()}`).join(', ')} `}
            di dimensione massima
            {' '}
            {formatBytes(maxSize as number)}
          </Text.BodySmall>
        </Stack>
      </div>
    </div>
  );
};

export default FileUploader;
