import React from 'react';
import {
  Icon, Grid, Spacing, Link, Message, ICON_FILE_DOCUMENT_MULTIPLE_OUTLINE, HStack, DetailItemList, CardSkeleton, Text,
  Card,
  FontWeight,
  ICON_ALERT_CIRCLE_OUTLINE,
} from '@doveit/bricks';
import { ProspectDocumentTypeValue } from '../../../domain/types';
import { Document } from '../../../providers/api/dtos';
import { ThemeIcons } from '../../../theme/icons';
import {
  OPTIONAL_PROSPECT_DOCUMENT_TYPES, YOUDOMUS_PROSPECT_DOCUMENT_TYPES,
} from '../../../document/constants';
import { DocumentReferenceType } from '../../../domain/types/documentReferenceType';
import ManageDocumentAction from '../../../document/containers/manage-document-action/ManageDocumentAction';
import { useProspectDocuments } from '../../hooks/use-prospect-documents/useProspectDocuments';
import { sortDocumentsByLabel } from '../../../utils';
import * as styles from './ProspectDocumentsWidget.style';

export interface ProspectDocumentsWidgetProps {
  prospectId: string,
  onDocumentUpserted?: (document: Document) => void,
}

export const PROSPECT_DOCUMENTS_LOAD_ERROR_MESSAGE = 'Non è stato possibile caricare i documenti della valutazione.';
export const PROSPECT_DOCUMENTS_REJECTED_ERROR_MESSAGE = 'È necessario correggere alcuni documenti relativi alla valutazione.';
export const PROSPECT_DOCUMENTS_REVIEW_INFO_MESSAGE = 'Hai dei documenti da revisionare.';
export const PROSPECT_DOCUMENT_ADD_ERROR_MESSAGE = 'Non è possibile aggiungere il documento.';

const ProspectDocumentsWidget: React.FC<ProspectDocumentsWidgetProps> = ({
  prospectId,
  onDocumentUpserted,
}) => {
  const {
    data: prospectDocuments = [],
    isLoading: areProspectDocumentsLoading,
    error: prospectDocumentsError,
    mutate: mutateProspectDocuments,
  } = useProspectDocuments(prospectId);

  const onUpsertProspectDocument = React.useCallback(async (document: Document) => {
    await mutateProspectDocuments();

    onDocumentUpserted?.(document);
  }, [mutateProspectDocuments, onDocumentUpserted]);

  if (prospectDocumentsError) {
    return (
      <Card aria-label="Errore nel caricamento dei documenti della valutazione">
        <Card.Header
          title="Documenti"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          color="critical"
        />
        <Card.Content>
          <Message message={PROSPECT_DOCUMENTS_LOAD_ERROR_MESSAGE} />
        </Card.Content>
      </Card>
    );
  }

  if (areProspectDocumentsLoading) {
    return <CardSkeleton aria-label="Lista documenti della valutazione in caricamento" />;
  }

  return (
    <Card>
      <Card.Header
        title="Documenti"
        icon={{ path: ICON_FILE_DOCUMENT_MULTIPLE_OUTLINE }}
      />

      <Card.Content>
        <Grid gutter={150}>
          <Grid.Unit aria-label="Lista documenti facoltativi">
            <Text.Body fontWeight={FontWeight.MEDIUM}>Facoltativi</Text.Body>
            <Spacing margin={[100, 0, 0]}>
              <DetailItemList columns={3}>
                {OPTIONAL_PROSPECT_DOCUMENT_TYPES
                  .sort(sortDocumentsByLabel)
                  .map((type) => {
                    const existingDocument = prospectDocuments.find((document) => document.type === type);

                    return (
                      <DetailItemList.Item
                        key={type}
                        aria-label={`document-row-${type}`}
                      >
                        <HStack>
                          <ManageDocumentAction
                            referenceId={prospectId}
                            referenceType={DocumentReferenceType.PROSPECT}
                            documentType={type}
                            document={existingDocument}
                            onCreate={onUpsertProspectDocument}
                            onUpdate={onUpsertProspectDocument}
                          />
                          {!existingDocument?.files.length && YOUDOMUS_PROSPECT_DOCUMENT_TYPES.includes(type) && (
                            <Link
                              href={type === ProspectDocumentTypeValue.CADASTRAL_SURVEY ? 'https://app.youdomus.it/Prodotto/Richiedi/342' : 'https://app.youdomus.it/Prodotto/Richiedi/482'}
                              target="_blank"
                              aria-label="document-row-link"
                              css={styles.youdomusIcon}
                            >
                              <Icon
                                path={ThemeIcons.YOUDOMUS}
                                size={20}
                              />
                            </Link>
                          )}
                        </HStack>
                      </DetailItemList.Item>
                    );
                  })}
              </DetailItemList>
            </Spacing>
          </Grid.Unit>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default ProspectDocumentsWidget;
