import { BreakpointQueryName, ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const searchValue: ThemedFunctor = ({ theme }) => css`
  color: ${theme.palettes.status.alert};
`;

export const rightBox: ThemedFunctor = ({
  theme,
  $complete,
}) => css`
  position: relative;
  padding-top: ${$complete ? theme.spaceUnits.mobile.BIG : 0};

  ${$complete && theme.breakpoints.up(BreakpointQueryName.LG)} {
    padding-top: ${theme.spaceUnits.desktop.MEDIUM};
    padding-bottom: ${theme.spaceUnits.desktop.MEDIUM};
  }
`;

export const completeContact: ThemedFunctor = ({ theme }) => css`
  position: absolute;
  top: 0;
  left: 0;
  color: ${theme.palettes.status.success};
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    left: auto;
    right: 0;
  }
`;
