import {
  Action,
  FormHandlers,
  Portal, useModal,
  useNotifications,
} from '@doveit/bricks';
import React, { MutableRefObject } from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { AppointmentAvailability, Intent } from '../../../providers/api/dtos';
import AppointmentAvailabilityForm, { AppointmentAvailabilityFormModel } from '../../components/appointment-availability-form/AppointmentAvailabilityForm';
import { updateIntent } from '../../../providers/api/intent/intentProvider';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface UpdateAppointmentAvailabilitiesActionProps {
  intent: Intent,
  onSuccess?: (updatedIntent: Intent) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const UPDATE_APPOINTMENT_AVAILABILITY_SUCCESS_MESSAGE = 'Le disponibilità sono state aggiornate correttamente';
export const UPDATE_APPOINTMENT_AVAILABILITY_ERROR_MESSAGE = 'Non è stato possibile aggiornare le disponibilità';

const UpdateAppointmentAvailabilitiesAction: React.FC<UpdateAppointmentAvailabilitiesActionProps> = ({
  intent,
  onSuccess,
  children,
}) => {
  const modal = useModal<Intent>();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const formRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;

  const initialValues = React.useMemo(() => Object.keys(AppointmentAvailability).reduce((acc, current) => {
    const availability = current as AppointmentAvailability;
    acc[availability] = intent.appointmentAvailabilities?.includes(availability) ?? false;

    return acc;
  }, {} as AppointmentAvailabilityFormModel), [intent]);

  const openEditModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const onSubmit = React.useCallback(async (values: AppointmentAvailabilityFormModel) => {
    setIsSaving(true);

    try {
      const updatedIntent = await updateIntent(intent.id!, {
        ...intent,
        appointmentAvailabilities: (Object.keys(values) as AppointmentAvailability[])
          .filter((availability) => values[availability]),
      });
      setIsSaving(false);
      addSuccess(UPDATE_APPOINTMENT_AVAILABILITY_SUCCESS_MESSAGE);
      modal.close();

      onSuccess?.(updatedIntent);
    } catch (error) {
      setIsSaving(false);
      addError(UPDATE_APPOINTMENT_AVAILABILITY_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, intent, modal, onSuccess]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  return (
    <>
      {children
        ? children({ openEditModal, isSaving })
        : (
          <Action
            onClick={openEditModal}
            label="Modifica"
            size="S"
            aria-label="Pulsante per modificare le disponibilità per l'appuntamento di visita"
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Modifica disponibilità appuntamento"
          aria-label="Modale per modificare le disponibilità per l'appuntamento di visita"
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per modificare le provvigioni dell'agenzia"
              onClick={submitForm}
            />
          )}
        >
          <AppointmentAvailabilityForm
            initialValues={initialValues}
            loading={isSaving}
            onSubmit={onSubmit}
            innerRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default UpdateAppointmentAvailabilitiesAction;
