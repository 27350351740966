import React from 'react';
import {
  Action, ActionIcon, Card, ConfirmModal, FormHandlers, Grid, HStack, ICON_CHECK, ICON_CLOSE, ICON_PENCIL_OUTLINE, ICON_TRASH_CAN_OUTLINE, Message, Portal, Spacing, Stack, Text,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import { Property } from '../../../../providers/api/dtos';
import useIsDevice from '../../../../hooks/use-is-device/useIsDevice';
import SimpleTableSkeleton from '../../../../components/simple-table/SimpleTable.skeleton';
import SimpleTable from '../../../../components/simple-table/SimpleTable';
import Divider from '../../../../components/divider/Divider';
import UpsertPropertySpaceWithSizeForm, { UpsertPropertySpaceWithSizeFormModel } from '../../../spaces/components/upsert-property-space-with-size-form/UpsertPropertySpaceWithSizeForm';
import useProperty from '../../../hooks/use-property/useProperty';
import { updateProperty } from '../../../../providers/api/property/propertyProvider';

export interface ManagePropertyPrivateGardensProps {
  propertyId: NonNullable<Property['id']>,
  onCreate?: VoidFunction,
  onUpdate?: VoidFunction,
  onDelete?: VoidFunction,
}

const ManagePropertyPrivateGardens: React.FC<ManagePropertyPrivateGardensProps> = ({
  propertyId,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const isMobile = useIsDevice('mobile');
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const privateGardenFormRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const deletePrivateGardenModal = useModal<{ property: Property }>();

  const {
    data: property,
    isLoading: isPropertyLoading,
    error: propertyError,
    mutate: propertyMutate,
  } = useProperty(propertyId);

  const isPrivateGardenPresent = React.useMemo(() => property?.privateGarden !== undefined, [property]);

  const upsertPrivateGarden = React.useCallback(async ({ size }: UpsertPropertySpaceWithSizeFormModel) => {
    try {
      setIsSaving(true);

      const propertyToUpdate: Property = {
        ...property!,
        privateGarden: size ? parseFloat(size) : undefined,
      };

      await updateProperty(propertyId, propertyToUpdate);

      setIsSaving(false);
      addSuccess(`Il giardino privato è stato ${isPrivateGardenPresent ? 'modificato' : 'aggiunto'} con successo.`);

      if (isEditing) {
        setIsEditing(false);
      }

      if (isPrivateGardenPresent) {
        onUpdate?.();
      } else {
        onCreate?.();
      }
      await propertyMutate();
    } catch (error) {
      setIsSaving(false);
      addError(`Non è stato possibile ${isPrivateGardenPresent ? 'modificare' : 'aggiungere'} il giardino privato.`);
    }
  }, [property, propertyId, addSuccess, isPrivateGardenPresent, isEditing, propertyMutate, onUpdate, onCreate, addError]);

  const removePrivateGarden = React.useCallback(async () => {
    try {
      setIsSaving(true);

      const propertyToUpdate: Property = {
        ...property!,
        privateGarden: undefined,
      };

      await updateProperty(propertyId, propertyToUpdate);

      setIsSaving(false);
      addSuccess('Il giardino privato è stato rimosso con successo.');
      deletePrivateGardenModal.close();

      onDelete?.();
      await propertyMutate();
    } catch (error) {
      setIsSaving(false);
      addError('Non è stato possibile rimuovere il giardino privato.');
    }
  }, [addError, addSuccess, deletePrivateGardenModal, onDelete, property, propertyId, propertyMutate]);

  const submitUpsertPrivateGardenForm = React.useCallback(() => {
    privateGardenFormRef.current?.handleSubmit();
  }, []);

  if (isPropertyLoading) {
    return <SimpleTableSkeleton aria-label="Giardino privato in caricamento" />;
  }

  if (propertyError) {
    return (
      <Message
        type="critical"
        message="Non è stato possibile caricare il giardino privato."
      />
    );
  }

  return property && (
    <>
      <Stack gap={200} data-testid="manage-property-private-garden">
        {!isMobile && (
          <SimpleTable aria-label="Tabella giardini privati">
            <SimpleTable.Body>
              {!isPrivateGardenPresent && (
                <SimpleTable.Row>
                  <SimpleTable.Cell>
                    <Message
                      type="neutral"
                      message="Nessun giardino privato inserito."
                    />
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              )}
              {isPrivateGardenPresent && (
                <SimpleTable.Row
                  key={property.id}
                  aria-label="Informazioni giardino privato"
                >
                  {isEditing ? (
                    <>
                      <SimpleTable.Cell colSpan={3}>
                        <UpsertPropertySpaceWithSizeForm
                          loading={isSaving}
                          initialValues={{ size: property.privateGarden!.toString() }}
                          innerRef={privateGardenFormRef}
                          onSubmit={upsertPrivateGarden}
                          isSizeRequired
                        />
                      </SimpleTable.Cell>
                      <SimpleTable.Cell align="right">
                        <HStack>
                          <ActionIcon
                            size="S"
                            color="primary"
                            icon={{ path: ICON_CHECK }}
                            label="Conferma modifiche"
                            aria-label="Conferma modifiche"
                            onClick={submitUpsertPrivateGardenForm}
                          />
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_CLOSE }}
                            label="Annulla modifiche"
                            aria-label="Annulla modifiche"
                            onClick={() => setIsEditing(false)}
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </>
                  ) : (
                    <>
                      <SimpleTable.Cell colSpan={3}>
                        <Text.BodySmall>
                          {formatSquareMeters(property.privateGarden!)}
                        </Text.BodySmall>
                      </SimpleTable.Cell>
                      <SimpleTable.Cell align="right">
                        <HStack>
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_PENCIL_OUTLINE }}
                            label="Modifica giardino privato"
                            aria-label="Modifica giardino privato"
                            onClick={() => setIsEditing(true)}
                          />
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                            label="Rimuovi giardino privato"
                            aria-label="Rimuovi giardino privato"
                            onClick={() => deletePrivateGardenModal.open({ property })}
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </>
                  )}
                </SimpleTable.Row>
              )}
              {!isPrivateGardenPresent && (
                <SimpleTable.Row aria-label="Aggiungi informazioni nuovo giardino privato">
                  <SimpleTable.Cell colSpan={3}>
                    <UpsertPropertySpaceWithSizeForm
                      loading={isSaving}
                      innerRef={privateGardenFormRef}
                      onSubmit={(values, actions) => {
                        actions.resetForm();

                        upsertPrivateGarden(values);
                      }}
                      isSizeRequired
                    />
                  </SimpleTable.Cell>
                  <SimpleTable.Cell align="right">
                    <Action
                      size="S"
                      label="Aggiungi"
                      aria-label="Aggiungi giardino privato"
                      onClick={submitUpsertPrivateGardenForm}
                    />
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        )}
        {isMobile && (
          <Stack
            gap={150}
            aria-label="Lista card giardini privati"
          >
            {!isPrivateGardenPresent && (
              <Spacing margin={[0, 0, 400]}>
                <Message
                  type="neutral"
                  message="Nessun giardino privato inserito."
                />
              </Spacing>
            )}
            {isPrivateGardenPresent && (
              <Card
                key={property.id}
                aria-label="Informazioni giardino privato"
              >
                <Card.Header
                  title="Giardino privato"
                  primaryActions={
                    !isEditing ? [
                      <ActionIcon
                        size="S"
                        icon={{ path: ICON_PENCIL_OUTLINE }}
                        label="Modifica giardino privato"
                        aria-label="Modifica giardino privato"
                        onClick={() => setIsEditing(true)}
                      />,
                      <ActionIcon
                        size="S"
                        icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                        label="Rimuovi giardino privato"
                        aria-label="Rimuovi giardino privato"
                        onClick={() => deletePrivateGardenModal.open({ property })}
                      />,
                    ] : undefined
                  }
                />
                <Card.Content>
                  {isEditing ? (
                    <>
                      <UpsertPropertySpaceWithSizeForm
                        loading={isSaving}
                        initialValues={{ size: property.privateGarden!.toString() }}
                        innerRef={privateGardenFormRef}
                        onSubmit={upsertPrivateGarden}
                        isSizeRequired
                      />
                      <Divider />
                      <Grid gutter={150}>
                        <Grid.Unit size={1 / 2}>
                          <Action
                            expanded
                            size="S"
                            color="primary"
                            label="Salva"
                            aria-label="Conferma modifiche"
                            onClick={submitUpsertPrivateGardenForm}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={1 / 2}>
                          <Action
                            expanded
                            size="S"
                            label="Annulla"
                            aria-label="Annulla modifiche"
                            onClick={() => setIsEditing(false)}
                          />
                        </Grid.Unit>
                      </Grid>
                    </>
                  ) : (
                    <Text.BodySmall>
                      {formatSquareMeters(property.privateGarden!)}
                    </Text.BodySmall>
                  )}
                </Card.Content>
              </Card>
            )}
            {!isPrivateGardenPresent && (
              <Card aria-label="Aggiungi informazioni nuovo giardino privato">
                <Card.Header title="Aggiungi nuovo giardino privato" />
                <Card.Content>
                  <>
                    <UpsertPropertySpaceWithSizeForm
                      loading={isSaving}
                      innerRef={privateGardenFormRef}
                      onSubmit={(values, actions) => {
                        actions.resetForm();

                        upsertPrivateGarden(values);
                      }}
                      isSizeRequired
                    />
                    <Divider />
                    <Action
                      expanded
                      size="S"
                      label="Aggiungi"
                      aria-label="Aggiungi giardino privato"
                      onClick={submitUpsertPrivateGardenForm}
                    />
                  </>
                </Card.Content>
              </Card>
            )}
          </Stack>
        )}
      </Stack>
      <Portal>
        {deletePrivateGardenModal.data?.property && (
          <ConfirmModal
            variant="critical"
            isOpen={deletePrivateGardenModal.isOpen}
            title="Conferma rimozione giardino privato"
            aria-label="Conferma la rimozione del giardino privato"
            onConfirm={removePrivateGarden}
            onAbort={deletePrivateGardenModal.close}
          >
            Confermando l&apos;operazione, il giardino privato sarà rimosso.
          </ConfirmModal>
        )}
      </Portal>
    </>
  );
};

export default ManagePropertyPrivateGardens;
