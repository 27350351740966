import { Aggregator } from '../domain/types';
import { ThemeIcons } from '../theme/icons';

export const AggregatorIcon: Record<Aggregator, ThemeIcons> = {
  IMMOBILIARE_IT: ThemeIcons.IMMOBILIARE,
  CASA_IT: ThemeIcons.CASA,
  IDEALISTA: ThemeIcons.IDEALISTA,
  WIKICASA: ThemeIcons.WIKICASA,
  GATE_AWAY: ThemeIcons.GATE_AWAY,
  DOVE_IT: ThemeIcons.DOVE,
  JAMES_EDITION: ThemeIcons.JAMES_EDITION,
};
