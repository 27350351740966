import React, { MutableRefObject } from 'react';
import {
  Action,
  FormHandlers, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { updateAgent } from '../../../providers/api/agent/agentProvider';
import EditCommissionsForm, { EditCommissionsFormModel } from '../../components/edit-commissions-form/EditCommissionsForm';
import { commissionsToEditCommissionsFormModel, editCommissionFormModelToAgent } from './mappers';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface EditAgentCommissionsActionProps {
  agent: Agent,
  onSuccess?: (updatedAgent: Agent) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_AGENT_COMMISSIONS_SUCCESS_MESSAGE = 'Le provvigioni sono state modificate con successo';
export const EDIT_AGENT_COMMISSIONS_ERROR_MESSAGE = 'Non è stato possibile modificare le provvigioni';

const EditAgentCommissionsAction: React.FC<EditAgentCommissionsActionProps> = (props) => {
  const { agent, onSuccess, children } = props;

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const formRef = React.useRef<FormHandlers>(null) as MutableRefObject<FormHandlers>;

  const openEditModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const initialFormValues = React.useMemo(() => (agent.commissions ? commissionsToEditCommissionsFormModel(agent.commissions) : {}), [agent.commissions]);

  const onSubmit = React.useCallback(async (formValues: EditCommissionsFormModel) => {
    try {
      setIsSaving(true);

      const agentToUpdate = editCommissionFormModelToAgent(formValues, agent);

      const updatedAgent = await updateAgent(agent.id!, agentToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_AGENT_COMMISSIONS_SUCCESS_MESSAGE);
      modal.close();

      onSuccess?.(updatedAgent);
    } catch (error) {
      setIsSaving(false);
      addError(EDIT_AGENT_COMMISSIONS_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agent, modal, onSuccess]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  return (
    <>
      {children
        ? children({ openEditModal, isSaving })
        : (
          <Action
            aria-label="Modifica le provvigioni dell'agente"
            size="S"
            title="Modifica le provvigioni dell'agente"
            label="Modifica"
            onClick={openEditModal}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Modifica provvigioni"
          aria-label="Modale per modificare le provvigioni dell'agente"
          footer={(
            <Action
              label="Modifica"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per modificare le provvigioni dell'agente"
              onClick={submitForm}
            />
          )}
        >
          <EditCommissionsForm
            initialValues={initialFormValues}
            onSubmit={onSubmit}
            innerRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAgentCommissionsAction;
