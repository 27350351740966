import { CustomUser, UserRole } from '../../../types';
import { hasAnyRole } from '../../../utils/users/usersUtils';

const rolesSortedByImportance = [
  UserRole.HR,
  UserRole.FINANCE,
  UserRole.DEVELOPER,
  UserRole.ADMIN,
  UserRole.CALL_CENTER,
  UserRole.AGENT,
  UserRole.AREA_MANAGER,
  UserRole.CONTENT_EDITOR,
  UserRole.CUSTOMER,
];

export function getMainUserRole(user: CustomUser): UserRole {
  const mainRole = rolesSortedByImportance.find((role) => hasAnyRole(user, role));

  return mainRole || UserRole.CUSTOMER;
}
