import { ActionProps } from '@doveit/bricks';
import { AgencyStatusAction } from './types';
import { MembershipStatus } from '../../../domain/types';

export const AGENCY_NAME_PLACEHOLDER = '__AGENCY_NAME__';
export const AGENCY_STATUS_UPDATED_SUCCESS_MESSAGE = 'Lo stato dell\'agenzia è stato aggiornato con successo';
export const AGENCY_STATUS_UPDATED_ERROR_MESSAGE = 'Non è stato possibile aggiornare lo stato dell\'agenzia';
export const NOT_VALID_DATE_ERROR_MESSAGE = 'La data non è valida';
export const NOT_FUTURE_DATE_ERROR_MESSAGE = 'La data deve essere nel futuro';

export const statusColor: Record<MembershipStatus, ActionProps['color']> = {
  [MembershipStatus.UNDER_ACTIVATION]: 'info',
  [MembershipStatus.ACTIVE]: 'success',
  [MembershipStatus.UNDER_REVOCATION]: 'warning',
  [MembershipStatus.REVOKED]: 'critical',
};

export const optionsByStatus: Record<MembershipStatus, AgencyStatusAction[]> = {
  [MembershipStatus.UNDER_ACTIVATION]: [AgencyStatusAction.ACTIVATE, AgencyStatusAction.REVOKE],
  [MembershipStatus.ACTIVE]: [AgencyStatusAction.REVOKE],
  [MembershipStatus.UNDER_REVOCATION]: [AgencyStatusAction.REACTIVATE, AgencyStatusAction.REVOKE],
  [MembershipStatus.REVOKED]: [AgencyStatusAction.ACTIVATE],
};

export const confirmModalTextByStatus: Record<AgencyStatusAction, { title: string, message: string }> = {
  [AgencyStatusAction.ACTIVATE]: {
    title: 'Attivazione agenzia',
    message: `Scegli la data di inizio collaborazione con l'agenzia <strong>${AGENCY_NAME_PLACEHOLDER}</strong>`,
  },
  [AgencyStatusAction.REVOKE]: {
    title: 'Revoca agenzia',
    message: `Scegli la data di fine collaborazione con l'agenzia <strong>${AGENCY_NAME_PLACEHOLDER}</strong>`,
  },
  [AgencyStatusAction.REACTIVATE]: {
    title: 'Annullamento revoca agenzia',
    message: `Confermando l’operazione, annullerai la revoca dell’agenzia <strong>${AGENCY_NAME_PLACEHOLDER}</strong>`,
  },

};

export const agencyStatusActionLabels: Record<AgencyStatusAction, string> = {
  [AgencyStatusAction.ACTIVATE]: 'Attiva',
  [AgencyStatusAction.REVOKE]: 'Revoca',
  [AgencyStatusAction.REACTIVATE]: 'Attiva',
};
