import { LeadPropertyFeature } from '../domain/types';

export const leadFeatureLabels: {
  [key in LeadPropertyFeature]: string;
} = {
  GIARDINO: 'Giardino',
  BOX_AUTO: 'Box auto',
  BALCONE_TERRAZZO: 'Balcone/Terrazzo',
  ASCENSORE: 'Ascensore',
};
