import React from 'react';
import {
  Action, ActionIcon, Card, ConfirmModal, FormHandlers, Grid, HStack, ICON_CHECK, ICON_CLOSE, ICON_PENCIL_OUTLINE, ICON_TRASH_CAN_OUTLINE, Message, Portal, Spacing, Stack, Text,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import { Property, Balcony } from '../../../../providers/api/dtos';
import useIsDevice from '../../../../hooks/use-is-device/useIsDevice';
import SimpleTableSkeleton from '../../../../components/simple-table/SimpleTable.skeleton';
import SimpleTable from '../../../../components/simple-table/SimpleTable';
import { NO_VALUE_SYMBOL } from '../../../utils';
import Divider from '../../../../components/divider/Divider';
import useBalconies from '../../../hooks/use-balconies/useBalconies';
import UpsertPropertySpaceWithSizeForm, { UpsertPropertySpaceWithSizeFormModel } from '../../../spaces/components/upsert-property-space-with-size-form/UpsertPropertySpaceWithSizeForm';
import { createBalcony, deleteBalcony, updateBalcony } from '../../../../providers/api/balcony/balconyProvider';

export interface ManagePropertyBalconiesProps {
  propertyId: NonNullable<Property['id']>,
  onCreate?: (createdBalcony: Balcony) => void,
  onUpdate?: (updatedBalcony: Balcony) => void,
  onDelete?: (deletedBalconyId: NonNullable<Balcony['id']>) => void,
}

const ManagePropertyBalconies: React.FC<ManagePropertyBalconiesProps> = ({
  propertyId,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const isMobile = useIsDevice('mobile');
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const [isEditing, setIsEditing] = React.useState<{ balcony: Balcony } | false>(false);
  const createBalconyFormRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const updateBalconyFormRefs = React.useRef<{ [key: number]: React.MutableRefObject<any> }>({});
  const deleteBalconyModal = useModal<{ balcony: Balcony }>();

  const {
    data: balconies,
    isLoading: areBalconiesLoading,
    error: balconiesError,
    mutate: mutateBalconies,
  } = useBalconies(propertyId);

  const addBalcony = React.useCallback(async ({ size }: UpsertPropertySpaceWithSizeFormModel) => {
    try {
      setIsSaving(true);

      const balconyToCreate: Balcony = {
        id: null,
        propertyId,
        size: size ? parseFloat(size) : null,
      };

      const createdBalcony = await createBalcony(balconyToCreate);

      setIsSaving(false);
      addSuccess('Il balcone è stato aggiunto con successo.');

      onCreate?.(createdBalcony);
      await mutateBalconies();
    } catch (error) {
      setIsSaving(false);
      addError('Non è stato possibile aggiungere il balcone.');
    }
  }, [addError, addSuccess, mutateBalconies, onCreate, propertyId]);

  const editBalcony = React.useCallback(async ({ size }: UpsertPropertySpaceWithSizeFormModel) => {
    if (!isEditing) return;

    try {
      setIsSaving(true);

      const balconyToUpdate: Balcony = {
        ...isEditing.balcony,
        size: size ? parseFloat(size) : null,
      };

      const updatedBalcony = await updateBalcony(balconyToUpdate);

      setIsSaving(false);
      addSuccess('Il balcone è stato modificato con successo.');
      setIsEditing(false);

      onUpdate?.(updatedBalcony);
      await mutateBalconies();
    } catch (_) {
      setIsSaving(false);
      addError('Non è stato possibile modificare il balcone.');
    }
  }, [addError, addSuccess, isEditing, mutateBalconies, onUpdate]);

  const removeBalcony = React.useCallback(async () => {
    if (!deleteBalconyModal.data?.balcony) return;

    try {
      setIsSaving(true);

      await deleteBalcony(deleteBalconyModal.data.balcony.id!);

      setIsSaving(false);
      addSuccess('Il balcone è stato rimosso con successo.');
      deleteBalconyModal.close();

      onDelete?.(deleteBalconyModal.data.balcony.id!);
      await mutateBalconies();
    } catch (error) {
      setIsSaving(false);
      addError('Non è stato possibile rimuovere il balcone.');
    }
  }, [addError, addSuccess, deleteBalconyModal, mutateBalconies, onDelete]);

  const getUpdateBalconyFormRef = React.useCallback((id: number) => {
    if (!updateBalconyFormRefs.current[id]) {
      updateBalconyFormRefs.current[id] = React.createRef();
    }
    return updateBalconyFormRefs.current[id];
  }, []);

  const submitCreateBalconyForm = React.useCallback(() => {
    createBalconyFormRef.current?.handleSubmit();
  }, []);

  const submitUpdateBalconyForm = React.useCallback((id: number) => {
    if (updateBalconyFormRefs.current[id]) {
      updateBalconyFormRefs.current[id].current.handleSubmit();
    }
  }, []);

  if (areBalconiesLoading && !isMobile) {
    return <SimpleTableSkeleton aria-label="Balconi in caricamento" />;
  }

  if (balconiesError) {
    return (
      <Message
        type="critical"
        message="Non è stato possibile caricare i balconi."
      />
    );
  }

  return (
    <>
      <Stack gap={200} data-testid="manage-property-balconies">
        {!isMobile && (
          <SimpleTable aria-label="Tabella balconi">
            <SimpleTable.Body>
              {balconies?.length === 0 && (
                <SimpleTable.Row>
                  <SimpleTable.Cell>
                    <Message
                      type="neutral"
                      message="Nessun balcone inserito."
                    />
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              )}
              {balconies?.map((balcony) => (
                <SimpleTable.Row
                  key={balcony.id}
                  aria-label={`Informazioni balcone ${balcony.id}`}
                >
                  {(isEditing && isEditing.balcony.id === balcony.id) ? (
                    <>
                      <SimpleTable.Cell colSpan={3}>
                        <UpsertPropertySpaceWithSizeForm
                          loading={isSaving}
                          initialValues={{ size: balcony.size?.toString() }}
                          innerRef={getUpdateBalconyFormRef(balcony.id!)}
                          onSubmit={editBalcony}
                        />
                      </SimpleTable.Cell>
                      <SimpleTable.Cell align="right">
                        <HStack>
                          <ActionIcon
                            size="S"
                            color="primary"
                            icon={{ path: ICON_CHECK }}
                            label="Conferma modifiche"
                            aria-label="Conferma modifiche"
                            onClick={() => submitUpdateBalconyForm(balcony.id!)}
                          />
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_CLOSE }}
                            label="Annulla modifiche"
                            aria-label="Annulla modifiche"
                            onClick={() => setIsEditing(false)}
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </>
                  ) : (
                    <>
                      <SimpleTable.Cell colSpan={3}>
                        <Text.BodySmall>
                          {balcony.size ? formatSquareMeters(balcony.size) : NO_VALUE_SYMBOL}
                        </Text.BodySmall>
                      </SimpleTable.Cell>
                      <SimpleTable.Cell align="right">
                        <HStack>
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_PENCIL_OUTLINE }}
                            label="Modifica balcone"
                            aria-label="Modifica balcone"
                            onClick={() => setIsEditing({ balcony })}
                          />
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                            label="Rimuovi balcone"
                            aria-label="Rimuovi balcone"
                            onClick={() => deleteBalconyModal.open({ balcony })}
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </>
                  )}
                </SimpleTable.Row>
              ))}
              <SimpleTable.Row aria-label="Aggiungi informazioni nuovo balcone">
                <SimpleTable.Cell colSpan={3}>
                  <UpsertPropertySpaceWithSizeForm
                    loading={isSaving}
                    innerRef={createBalconyFormRef}
                    onSubmit={(values, actions) => {
                      actions.resetForm();

                      addBalcony(values);
                    }}
                  />
                </SimpleTable.Cell>
                <SimpleTable.Cell align="right">
                  <Action
                    size="S"
                    label="Aggiungi"
                    aria-label="Aggiungi balcone"
                    onClick={submitCreateBalconyForm}
                  />
                </SimpleTable.Cell>
              </SimpleTable.Row>
            </SimpleTable.Body>
          </SimpleTable>
        )}
        {isMobile && (
          <Stack
            gap={150}
            aria-label="Lista card balconi"
          >
            {balconies?.length === 0 && (
              <Spacing margin={[0, 0, 400]}>
                <Message
                  type="neutral"
                  message="Nessun balcone inserito."
                />
              </Spacing>
            )}
            {balconies?.map((balcony, idx) => (
              <Card
                key={balcony.id}
                aria-label={`Informazioni balcone ${balcony.id}`}
              >
                <Card.Header
                  title={`Balcone (${idx + 1})`}
                  primaryActions={
                    !(isEditing && isEditing.balcony.id === balcony.id) ? [
                      <ActionIcon
                        size="S"
                        icon={{ path: ICON_PENCIL_OUTLINE }}
                        label="Modifica balcone"
                        aria-label="Modifica balcone"
                        onClick={() => setIsEditing({ balcony })}
                      />,
                      <ActionIcon
                        size="S"
                        icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                        label="Rimuovi balcone"
                        aria-label="Rimuovi balcone"
                        onClick={() => deleteBalconyModal.open({ balcony })}
                      />,
                    ] : undefined
                  }
                />
                <Card.Content>
                  {(isEditing && isEditing.balcony.id === balcony.id) ? (
                    <>
                      <UpsertPropertySpaceWithSizeForm
                        loading={isSaving}
                        initialValues={{ size: balcony.size?.toString() }}
                        innerRef={getUpdateBalconyFormRef(balcony.id!)}
                        onSubmit={editBalcony}
                      />
                      <Divider />
                      <Grid gutter={150}>
                        <Grid.Unit size={1 / 2}>
                          <Action
                            expanded
                            size="S"
                            color="primary"
                            label="Salva"
                            aria-label="Conferma modifiche"
                            onClick={() => submitUpdateBalconyForm(balcony.id!)}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={1 / 2}>
                          <Action
                            expanded
                            size="S"
                            label="Annulla"
                            aria-label="Annulla modifiche"
                            onClick={() => setIsEditing(false)}
                          />
                        </Grid.Unit>
                      </Grid>
                    </>
                  ) : (
                    <Text.BodySmall>
                      {balcony.size ? formatSquareMeters(balcony.size) : NO_VALUE_SYMBOL}
                    </Text.BodySmall>
                  )}
                </Card.Content>
              </Card>
            ))}
            <Card aria-label="Aggiungi informazioni nuovo balcone">
              <Card.Header title="Aggiungi nuovo balcone" />
              <Card.Content>
                <>
                  <UpsertPropertySpaceWithSizeForm
                    loading={isSaving}
                    innerRef={createBalconyFormRef}
                    onSubmit={(values, actions) => {
                      actions.resetForm();

                      addBalcony(values);
                    }}
                  />
                  <Divider />
                  <Action
                    expanded
                    size="S"
                    label="Aggiungi"
                    aria-label="Aggiungi balcone"
                    onClick={submitCreateBalconyForm}
                  />
                </>
              </Card.Content>
            </Card>
          </Stack>
        )}
      </Stack>
      <Portal>
        {deleteBalconyModal.data?.balcony && (
          <ConfirmModal
            variant="critical"
            isOpen={deleteBalconyModal.isOpen}
            title="Conferma rimozione balcone"
            aria-label="Conferma la rimozione del balcone"
            onConfirm={removeBalcony}
            onAbort={deleteBalconyModal.close}
          >
            Confermando l&apos;operazione, il balcone sarà rimosso.
          </ConfirmModal>
        )}
      </Portal>
    </>
  );
};

export default ManagePropertyBalconies;
