import React from 'react';
import {
  ActionIcon, FontWeight, ICON_EYE_OUTLINE, Stack, Text, UserPreview,
} from '@doveit/bricks';
import { Contact } from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';

export interface ContactPreviewTableRowProps {
  contact: Contact,
}

const ContactPreviewTableRow: React.FC<ContactPreviewTableRowProps> = ({
  contact,
}) => {
  const goToContactPage = React.useCallback(() => {
    goToDetailPage(contact.id!, ReferenceType.CONTACT);
  }, [contact.id]);

  return (
    <SimpleTable.Row
      data-testid={`contact-preview-table-row-${contact.id}`}
      aria-label="Informazioni contatto"
    >
      <SimpleTable.Cell aria-label="Nome">
        <UserPreview
          name={contact.name || 'Nome assente'}
          size="S"
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Numero di telefono">
        <Stack gap={50}>
          <Text.Mini transform="uppercase">
            Telefono
          </Text.Mini>
          <Text.Caption fontWeight={FontWeight.REGULAR}>
            {contact?.phoneNumber || NO_VALUE_SYMBOL}
          </Text.Caption>
        </Stack>
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Email">
        <Stack gap={50}>
          <Text.Mini transform="uppercase">
            Email
          </Text.Mini>
          <Text.Caption fontWeight={FontWeight.REGULAR}>
            {contact?.email || NO_VALUE_SYMBOL}
          </Text.Caption>
        </Stack>
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Data di creazione">
        <Stack gap={50}>
          <Text.Mini transform="uppercase">
            Aggiunto
          </Text.Mini>
          <Text.Caption>
            <DateViewer
              stringDate={contact.createdAt!}
              checkIfToday
            />
          </Text.Caption>
        </Stack>
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Azioni"
        align="right"
      >
        <ActionIcon
          aria-label="Visualizza contatto"
          label="Visualizza"
          size="S"
          icon={{ path: ICON_EYE_OUTLINE }}
          onClick={goToContactPage}
        />
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default ContactPreviewTableRow;
