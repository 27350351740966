import {
  BreakpointQueryName,
  FontWeight,
  getTypography, rgba, ThemedFunctor, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const slide: ThemedFunctor = () => css`
  aspect-ratio: 3/2;
  position: relative;
`;

export const removeOverlay: ThemedFunctor = ({ theme }) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${rgba(theme.color.background.neutral.default.highest, 0.8)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.remSize[200]};
  color: ${theme.color.foreground.neutral.default.lowest};
  text-shadow: ${theme.color.foreground.neutral.default.highest} 0 0 2px;
`;

export const commonSlider: ThemedFunctor = ({
  theme,
}) => css`
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    color: ${theme.palettes.neutral[800]};
    top: 0;
    bottom: 0;
    height: 100%;
    margin-top: 0;
    padding: 0 ${theme.remSize[200]};
    transition: padding 0.5s ease;
    width: auto;

    &::after {
      font-size: ${theme.remSize[300]};
      width: ${theme.remSize[600]};
      height: ${theme.remSize[600]};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.color.foreground.neutral.default.lowest};
      background-color: ${rgba(theme.color.background.neutral.default.highest, 0.7)};
    }

    &.swiper-button-disabled {
      display: none;
    }

    &:hover:not(.swiper-button-disabled) {
      padding: 0 ${theme.remSize[300]};
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    .swiper-button-prev,
    .swiper-button-next {
     display: flex;
    }
  }
`;

export const slider: ThemedFunctor = ({
  theme,
}) => css`
  margin-top: ${theme.remSize[50]};
  position: relative;
  background-color: ${theme.color.background.neutral.default.highest};

  ${commonSlider({ theme })}
`;

export const thumbnails: ThemedFunctor = ({
  theme,
}) => css`
  ${commonSlider({ theme })}
  background-color: ${theme.color.background.neutral.default.lowest};
  padding-bottom: ${theme.remSize[50]};

  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      font-size: ${theme.remSize[200]};
      width: ${theme.remSize[400]};
      height: ${theme.remSize[400]};
    }
  }
`;

export const info: ThemedFunctor = ({
  theme,
}) => css`
  display: flex;
  gap: ${theme.remSize[200]};
  align-items: center;
`;

export const status: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.BODY, { fontWeight: FontWeight.MEDIUM })}
  color: ${theme.color.foreground.neutral.default.lowest};
  text-shadow: ${theme.color.foreground.neutral.default.highest} 0 0 2px;
`;

export const header: ThemedFunctor = ({
  theme,
}) => css`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  gap: ${theme.remSize[200]};
  background: linear-gradient(to bottom, ${theme.color.background.neutral.default.highest} 15%, ${rgba(theme.color.background.neutral.default.highest, 0)} 100%);
  z-index: 2;
`;

export const toolbar: ThemedFunctor = ({
  theme,
}) => css`
  padding: ${theme.remSize[150]} ${theme.remSize[150]} ${theme.remSize[400]};
  gap: ${theme.remSize[200]};
  color: ${theme.color.foreground.neutral.default.highest};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const actions: ThemedFunctor = ({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.remSize[150]};
  flex-grow: 1;

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    min-width: 25rem;
    flex-grow: initial;
  }
`;

export const select: ThemedFunctor = () => css`
  flex-grow: 1;
`;

export const slideContent: ThemedFunctor = () => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.1s ease-in-out;
  z-index: 1;
`;
