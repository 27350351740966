import { format, parse } from 'date-fns';

let formKey = 0;
export function getFormKey(formName: string) {
  const key = `${formName}-${formKey}`;
  formKey += 1;
  return key;
}

export function formDateTimeToDate(day: String, time: String): Date {
  return parse(`${day} ${time}`, 'yyyy-MM-dd HH:mm', new Date());
}

export function formatInputDate(date: Date | number): string {
  return format(date, 'yyyy-MM-dd');
}

export function formatInputTime(date: Date | number): string {
  return format(date, 'HH:mm');
}
