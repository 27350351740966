import {
  isFuture, isAfter, isPast, endOfDay, startOfDay,
} from 'date-fns';
import { MembershipStatus } from '../../domain/types';

export function calculateMembershipStatus(startDate: Date, endDate?: Date): MembershipStatus {
  const startOfStartDate = startOfDay(startDate);
  const endOfEndDate = endDate && endOfDay(endDate);

  if (isFuture(startOfStartDate)) {
    if (!endOfEndDate) {
      return MembershipStatus.UNDER_ACTIVATION;
    } if (isAfter(endOfEndDate, startOfStartDate)) {
      return MembershipStatus.UNDER_REVOCATION;
    }

    return MembershipStatus.REVOKED;
  }

  if (!endOfEndDate) {
    return MembershipStatus.ACTIVE;
  } if (!isPast(endOfEndDate)) {
    return MembershipStatus.UNDER_REVOCATION;
  }

  return MembershipStatus.REVOKED;
}
