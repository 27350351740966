import {
  ThemedFunctor, getTypography, getSpacing, TypographyName, SpacingName, FontWeight, getColorPalette,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({ theme, $highlighted, $clickable }) => css`
  ${getSpacing.padding(theme, SpacingName.SMALL)}
  display: flex;

  ${$highlighted && `
    background-color: ${theme.palettes.neutrals.light};
  `}

  ${$clickable && `
    cursor: pointer;
  `}
`;

export const avatar: ThemedFunctor = ({ theme, $skeleton }) => css`
  ${getTypography(theme, TypographyName.HEADING_4)}
  background-color: ${theme.palettes.brand.secondary};
  color: ${theme.palettes.neutrals.lightest};
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  margin-right: 2rem;

  ${$skeleton && `
    background-color: ${theme.palettes.neutrals.light};
  `}
`;

export const icon: ThemedFunctor = ({ theme, $color, $highlighted }) => css`
  position: absolute;
  background-color: ${$color ? getColorPalette(theme, $color) : theme.palettes.neutrals.dark};
  color: ${theme.palettes.neutrals.lightest};
  line-height: 0;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
  transform: translateX(40%) translateY(40%);
  border: 1px solid ${$highlighted ? theme.palettes.neutrals.light : theme.palettes.neutrals.lightest};
`;

export const pictogram: ThemedFunctor = () => css`
  width: 1.3rem;
  line-height: 0;
`;

export const info: ThemedFunctor = () => css`
  flex-grow: 1;
`;

export const header: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLEST)}
  display: flex;
  align-items: center;
`;

export const title: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginRight(theme, SpacingName.SMALLER)}
  ${getTypography(theme, TypographyName.BODY, { fontWeight: FontWeight.MEDIUM })}
  line-height: 1 !important; /** it is necessary to align title and date that have different line-height by default */
`;

export const date: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  color: ${theme.palettes.neutrals.medium};
  white-space: nowrap;
  line-height: 1 !important; /** it is necessary to align title and date that have different line-height by default */
`;

export const message: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLEST)}
  ${getTypography(theme, TypographyName.BODY_SMALL)}
`;

export const footer: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLEST)}
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  display: flex;
  align-items: center;
  gap: .5rem;
`;

export const context: ThemedFunctor = ({ theme }) => css`
  color: ${theme.palettes.neutrals.medium};
`;

export const readAll: ThemedFunctor = ({ theme }) => css`
  color: ${theme.palettes.neutrals.darkest};
  font-weight: ${FontWeight.MEDIUM};
  display: flex;
  align-items: center;
`;

export const actionBar: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLER)}
  display: flex;
  gap: 1rem;
`;
