import React, { FunctionComponent, useCallback, useRef } from 'react';
import { Spacing, Message } from '@doveit/bricks';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import FullTextSearchForm, { FullTextSearchFormModel } from '../../../components/full-text-search-form/FullTextSearchForm';
import { SortOrder } from '../../../providers/pagination';
import ViewPropertyCard from '../../containers/view-property-card/ViewPropertyCard';
import { Status } from '../../../domain/types';
import { PropertiesProviderFilters } from '../../../providers/api/property/propertyProvider';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import usePagination from '../../../hooks/use-pagination/usePagination';
import useFilters, { Filters } from '../../../hooks/use-filters/useFilters';
import useSearchProperties from '../../hooks/use-search-properties/useSearchProperties';

interface SearchPropertyPageFilters extends Filters {
  q?: string;
}

export const SEARCH_ERROR_MESSAGE = 'Errore durante la ricerca';

const SearchPropertyPage: FunctionComponent = () => {
  const { filters: { q }, addFilters, removeFilter } = useFilters<SearchPropertyPageFilters>();

  const filters: PropertiesProviderFilters = {
    status: [Status.DRAFT, Status.LIVE, Status.PROPOSTA, Status.VENDUTO, Status.RITIRATO, Status.ANTEPRIMA],
  };

  const scrollRef = useRef<HTMLDivElement>(null);
  const { page, paginationQueryParam, goToPage } = usePagination('page', scrollRef);

  const {
    data: properties,
    isLoading: arePropertiesLoading,
    error: propertiesError,
  } = useSearchProperties(q, filters, {
    page,
    size: 20,
    sort: {
      createdAt: SortOrder.DESC,
    },
  });

  const onSubmit = useCallback(({ search }: FullTextSearchFormModel) => {
    const newFilter: SearchPropertyPageFilters = {
      q: search,
    };
    addFilters(newFilter);
    removeFilter(paginationQueryParam);
  }, [addFilters, removeFilter, paginationQueryParam]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent innerRef={scrollRef}>
          <FullTextSearchForm
            onSubmit={onSubmit}
            loading={arePropertiesLoading}
            placeholder="Cerca referenza o indirizzo..."
            initialValues={{ search: q || '' }}
          />

          {propertiesError && (
            <Spacing margin={[200, 0, 0]}>
              <Message
                type="critical"
                message={SEARCH_ERROR_MESSAGE}
              />
            </Spacing>
          )}

          {properties && (
            <Spacing margin={[200, 0, 0]}>
              <PaginatedList
                number={properties.number}
                totalPages={properties.totalPages}
                size={properties.size}
                totalElements={properties.totalElements}
                query={q}
                goToPage={goToPage}
              >
                {properties.content.map((property) => (
                  <ViewPropertyCard
                    key={property.id!}
                    propertySearchItem={property}
                    showAgentName
                    showStatus
                    showCreateIntentAction
                    showViewDetailsAction
                  />
                ))}
              </PaginatedList>
            </Spacing>
          )}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};
export default SearchPropertyPage;
