import { Form, FormSubmitType, FormHandlers } from '@doveit/bricks';
import React, { FunctionComponent } from 'react';
import { IntentSource } from '../../../domain/types';
import { appointmentAvailabilityLabels } from '../../../labels';
import { AppointmentAvailability } from '../../../providers/api/dtos';
import sortAvailabilities from '../../utils/sortAvailabilities';
import validationSchema from './CreateIntentForm.schema';
import INTENT_SOURCE_OPTIONS from './select-options/source';

export interface CreateIntentFormModel {
  source?: IntentSource,
  notes?: string,
  availabilities: {
    [key in AppointmentAvailability]: boolean
  }
}

export interface CreateIntentFormProps {
  onSubmit: FormSubmitType<CreateIntentFormModel>,
  initialValues?: Partial<CreateIntentFormModel>,
  showAvailabilities?: boolean,
  loading?: boolean,
  disabled?: boolean,
  hideSource?: boolean,
  innerRef?: React.MutableRefObject<FormHandlers>,
}

const DEFAULT_INITIAL_VALUES = {
  availabilities: {
    [AppointmentAvailability.MORNING]: false,
    [AppointmentAvailability.LUNCH]: false,
    [AppointmentAvailability.AFTERNOON]: false,
    [AppointmentAvailability.EVENING]: false,
  },
};

const CreateIntentForm: FunctionComponent<CreateIntentFormProps> = ({
  onSubmit,
  initialValues,
  showAvailabilities,
  loading,
  disabled,
  hideSource,
  innerRef,
}) => {
  const mergedInitialValues = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...(initialValues || {}),
  }), [initialValues]);

  return ((
    <Form
      aria-label="Modulo di salvataggio interesse"
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      loading={loading}
      disabled={disabled}
      validationSchema={validationSchema}
      innerRef={innerRef}
      preventEnterKeypress
    >
      {!hideSource && (
        <Form.Item>
          <Form.Select
            name="source"
            label="Sorgente"
            aria-label="Campo per selezionare la sorgente"
            options={INTENT_SOURCE_OPTIONS}
          />
        </Form.Item>
      )}
      <Form.Item>
        <Form.TextArea
          name="notes"
          label="Note"
          aria-label="Campo per aggiungere note sull'interesse"
          placeholder="Inserisci eventuali note sull'interesse"
          rows={4}
        />
      </Form.Item>
      {showAvailabilities && (
        <Form.Group label="Fasce orarie per appuntamento">
          {sortAvailabilities(Object.keys(AppointmentAvailability) as AppointmentAvailability[]).map((availabilityType) => {
            const label = appointmentAvailabilityLabels[availabilityType as AppointmentAvailability];
            return (
              <Form.Item
                key={availabilityType}
                size={{ MD: 1 / 2 }}
              >
                <Form.CheckboxButton
                  name={`availabilities.${availabilityType}`}
                  text={label}
                  aria-label={`Campo per selezionare la disponibilità per: ${label}`}
                />
              </Form.Item>
            );
          })}
        </Form.Group>
      )}
    </Form>
  ));
};

export default CreateIntentForm;
