import React from 'react';
import {
  Action, BreakpointQueryName, Card, ConfirmModal, DetailItemList, Dropdown, FormSubmitType, HStack, Message, Spacing, Stack, Tooltip, useModal,
} from '@doveit/bricks';
import hash from 'object-hash';
import { formatDate } from '@doveit/hammer';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import Divider from '../../../../components/divider/Divider';
import SelectContact, { SelectContactRef } from '../../../../contact/containers/select-contact/SelectContact';
import NoContactView from '../../../../contact/containers/select-contact/components/no-contact-view/NoContactView';
import { Contact, OfferWizardData, WizardContact } from '../../../../providers/api/dtos';
import { isWizardContactComplete } from '../../../../property/assignment/components/assignment-wizard/assignment-wizard-seller/utils';
import AssignmentWizardSellerForm, { AssignmentWizardSellerFormModel } from '../../../../property/assignment/components/assignment-wizard/assignment-wizard-seller/AssignmentWizardSellerForm';
import {
  mapToWizardContact, toPropertyContact, toWizardContact,
} from '../../../../property/assignment/components/assignment-wizard/assignment-wizard-seller/mappers';
import SimpleModal from '../../../../components/simple-modal/SimpleModal';
import AssignmentWizardContactForm, { AssignmentWizardContactFormModel } from '../../../../property/assignment/components/assignment-wizard/assignment-wizard-seller/AssignmentWizardContactForm';
import { OfferWizardStepProps } from '../types';
import useContact from '../../../../contact/hooks/use-contact/useContact';
import { SearchContactFormModel } from '../../../../contact/components/search-contact-form/SearchContactForm';
import { toAssignmentWizardSellerModel, toOfferWizardData } from './mapper';
import { identityDocumentTypeLabels } from '../../../../labels';

export const NO_CONTACTS_INFO_MESSAGE = 'Aggiungi il/i compratore/i dell\'immobile.';
export const NO_LEGAL_INFO_MESSAGE = 'Aggiungi il rappresentante legale.';
export const MISSING_CONTACT_INFORMATION_INFO_MESSAGE = 'Inserisci i dati mancanti del contatto.';
export const LEGAL_PERSON_WITH_MANY_CONTACTS_TOOLTIP = 'Non è possibile indicare la tipologia Persona giuridica in presenza di più contatti.';

const OfferWizardBuyers: React.FC<OfferWizardStepProps> = ({
  intent,
  initialData,
  formRef,
  loading = false,
  onSubmit,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  const { data: intentContact, isLoading: contactIntentLoading } = useContact(intent.contactId);
  const [contacts, setContacts] = React.useState<WizardContact[]>([]);
  const [isLegalPersonOnly, setIsLegalPersonOnly] = React.useState(initialData.isBuyerLegalPersonOnly || false);
  const selectContactRef = React.useRef<SelectContactRef>(null);
  const updateContactModal = useModal<number | undefined>();
  const removeContactConfirmModal = useModal<number | undefined>();
  const [areContactsDirty, setAreContactsDirty] = React.useState(false);
  const [isContactSaving, setIsContactSaving] = React.useState(false);
  const isLegalPersonDisabled = React.useMemo(() => contacts.length > 1, [contacts.length]);

  const wizardIntentContact = React.useMemo(() => mapToWizardContact(intentContact), [intentContact]);

  React.useEffect(() => {
    let contactsToShow: WizardContact[] = [];

    if (initialData.buyerContacts) {
      contactsToShow = initialData.buyerContacts;
    } else if (wizardIntentContact) {
      contactsToShow = [wizardIntentContact];
    }

    setContacts(contactsToShow);
  }, [initialData.buyerContacts, wizardIntentContact]);

  const updateContact = React.useCallback((index: number) => async (formValues: AssignmentWizardContactFormModel) => {
    setContacts((current) => {
      current[index] = toWizardContact(current[index], formValues);

      return current;
    });

    setAreContactsDirty(true);
    setIsContactSaving(false);

    updateContactModal.close();
  }, [updateContactModal]);

  const onInternalSubmit: FormSubmitType<AssignmentWizardSellerFormModel> = React.useCallback(async (formValues: AssignmentWizardSellerFormModel) => {
    const values: OfferWizardData = await toOfferWizardData(initialData, formValues, contacts, isLegalPersonOnly);

    const isSellerInfoFormDirty = (formRef?.current as any)?.dirty ?? false;
    const isLegalPersonOnlyDirty = initialData.isBuyerLegalPersonOnly !== isLegalPersonOnly;

    onSubmit(values, isSellerInfoFormDirty || areContactsDirty || isLegalPersonOnlyDirty);
  }, [areContactsDirty, contacts, formRef, initialData, isLegalPersonOnly, onSubmit]);

  const openUpdateContactModal = React.useCallback(
    (index: number) => () => updateContactModal.open(index),
    [updateContactModal],
  );

  const openRemoveContactConfirmModal = React.useCallback(
    (index: number) => () => removeContactConfirmModal.open(index),
    [removeContactConfirmModal],
  );

  const onContactChange = React.useCallback((contactData?: Contact, searchContact?: SearchContactFormModel) => {
    let newWizardContact: WizardContact;

    if (contactData) {
      const {
        id, name, email, phoneNumber, fiscalCode, birthday, birthplace, residence, citizenship,
      } = contactData;
      const { name: overriddenName, email: overridenEmail, phoneNumber: overridenPhoneNumber } = searchContact || {};

      newWizardContact = {
        id: id ?? undefined,
        name: overriddenName || name,
        email: overridenEmail || email,
        phoneNumber: overridenPhoneNumber || phoneNumber,
        birthday,
        birthplace,
        fiscalCode,
        citizenship,
        placeOfResidence: residence,
      };
    } else {
      const { name, phoneNumber, email } = searchContact!;

      newWizardContact = {
        name,
        email,
        phoneNumber,
      };
    }

    setContacts((prev) => [...prev, newWizardContact]);
    selectContactRef.current?.resetInternalState();
    setAreContactsDirty(true);
  }, []);

  const onPersonTypeChange = React.useCallback((isLegalPerson: boolean) => () => {
    setIsLegalPersonOnly(isLegalPerson);
  }, []);

  const removeContact = React.useCallback((index: number) => () => {
    setContacts((prev) => {
      const toSplice = prev.slice();
      toSplice.splice(index, 1);

      return toSplice;
    });

    setAreContactsDirty(true);
    removeContactConfirmModal.close();
  }, [removeContactConfirmModal]);

  return (
    <>
      <Divider>
        Tipologia
      </Divider>
      <Spacing margin={[0, 0, 200]}>
        <HStack>
          <Action
            label="Persona fisica"
            aria-label="Selezione persona fisica"
            size="S"
            emphasis="high"
            color={!isLegalPersonOnly ? 'primary' : 'neutral'}
            onClick={onPersonTypeChange(false)}
          />
          {isLegalPersonDisabled && (
            <Tooltip content={LEGAL_PERSON_WITH_MANY_CONTACTS_TOOLTIP}>
              <span>
                &nbsp;&nbsp;&nbsp;
                <Action
                  label="Persona giuridica"
                  aria-label="Selezione persona giuridica"
                  size="S"
                  emphasis="high"
                  color={isLegalPersonOnly ? 'primary' : 'neutral'}
                  disabled
                />
              </span>
            </Tooltip>
          )}
          {!isLegalPersonDisabled && (
            <Action
              label="Persona giuridica"
              aria-label="Selezione persona giuridica"
              size="S"
              emphasis="high"
              color={isLegalPersonOnly ? 'primary' : 'neutral'}
              onClick={onPersonTypeChange(true)}
            />
          )}
        </HStack>
      </Spacing>

      <Divider>
        {isLegalPersonOnly ? 'Rappresentante legale' : 'Elenco compratori'}
      </Divider>

      {(!isLegalPersonOnly || contacts.length === 0) && (
        <SelectContact
          onContactChange={onContactChange}
          componentRef={selectContactRef}
          render={{
            noContactView: ({ openContactFinder }) => (
              <NoContactView
                label="Cerca o aggiungi"
                openContactFinder={openContactFinder}
              />
            ),
          }}
        />
      )}
      {contacts.length === 0 && (
        <Spacing margin={[200, 0, 0]}>
          <Message
            type="info"
            message={isLegalPersonOnly ? NO_LEGAL_INFO_MESSAGE : NO_CONTACTS_INFO_MESSAGE}
          />
        </Spacing>
      )}
      {contacts.length > 0 && (
        <Spacing margin={[200, 0, 0]}>
          <Stack>
            {contacts.map((buyerContact, index) => (
              <Card
                key={hash([buyerContact, index])}
                aria-label="Informazioni compratore"
              >
                <Card.Header
                  title="Contatto"
                  primaryActions={
                    !isMobile ? [
                      <Action
                        label="Modifica"
                        size="S"
                        onClick={openUpdateContactModal(index)}
                      />,
                      <Action
                        label="Dissocia"
                        size="S"
                        onClick={openRemoveContactConfirmModal(index)}
                      />,
                    ] : undefined
                  }
                  secondaryActions={isMobile ? [
                    <Dropdown.Option
                      label="Modifica"
                      onClick={openUpdateContactModal(index)}
                    />,
                    <Dropdown.Option
                      label="Dissocia"
                      onClick={openRemoveContactConfirmModal(index)}
                    />,
                  ] : undefined}
                />
                <Card.Content>
                  {!isWizardContactComplete(buyerContact) && (
                    <Spacing margin={[0, 0, 200]}>
                      <Message
                        type="info"
                        message={MISSING_CONTACT_INFORMATION_INFO_MESSAGE}
                      />
                    </Spacing>
                  )}
                  <DetailItemList columns={2}>
                    <DetailItemList.Item label="Nome e cognome">
                      {buyerContact.name}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Telefono">
                      {buyerContact.phoneNumber}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Email">
                      {buyerContact.email}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Residenza">
                      {buyerContact.placeOfResidence?.normalizedAddress}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Data di nascita">
                      {buyerContact.birthday ? formatDate(new Date(buyerContact.birthday)) : undefined}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Luogo di nascita">
                      {buyerContact.birthplace}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Codice fiscale">
                      {buyerContact.fiscalCode}
                    </DetailItemList.Item>
                    <DetailItemList.Item label="Cittadinanza">
                      {buyerContact.citizenship}
                    </DetailItemList.Item>
                  </DetailItemList>
                  <Spacing margin={[150, 0]} />
                  <DetailItemList columns={2}>
                    <DetailItemList.Group label="Documento di identità">
                      <DetailItemList.Item label="Tipo">
                        {buyerContact.identityDocument?.type ? identityDocumentTypeLabels[buyerContact.identityDocument.type] : undefined}
                      </DetailItemList.Item>
                      <DetailItemList.Item label="Numero">
                        {buyerContact.identityDocument?.number}
                      </DetailItemList.Item>
                      <DetailItemList.Item label="Rilasciato da">
                        {buyerContact.identityDocument?.issuedBy}
                      </DetailItemList.Item>
                      <DetailItemList.Item label="Data rilascio">
                        {buyerContact.identityDocument?.issuedAt && formatDate(new Date(buyerContact.identityDocument.issuedAt))}
                      </DetailItemList.Item>
                    </DetailItemList.Group>
                  </DetailItemList>
                </Card.Content>
              </Card>
            ))}
          </Stack>
        </Spacing>
      )}
      <div style={{ display: isLegalPersonOnly && contacts.length > 0 ? 'block' : 'none' }}>
        <Spacing margin={[200, 0, 0]}>
          <AssignmentWizardSellerForm
            initialValues={toAssignmentWizardSellerModel(initialData, wizardIntentContact)}
            formRef={formRef}
            onSubmit={onInternalSubmit}
            disabled={contacts.length === 0}
            loading={loading || contactIntentLoading}
          />
        </Spacing>
      </div>
      {
        updateContactModal.data !== undefined && (
          <SimpleModal
            {...updateContactModal}
            title="Modifica contatto"
            loading={isContactSaving}
          >
            <AssignmentWizardContactForm
              initialValues={toPropertyContact(contacts[updateContactModal.data])}
              onSubmit={updateContact(updateContactModal.data)}
              showCitizenship
              showIdentityDocument
            />
          </SimpleModal>
        )
      }
      {
        removeContactConfirmModal.data !== undefined && contacts.length > 0 && (
          <ConfirmModal
            isOpen={removeContactConfirmModal.isOpen}
            title={`Dissocia ${isLegalPersonOnly ? 'rappresentante legale' : 'compratore'}`}
            onConfirm={removeContact(removeContactConfirmModal.data!)}
            onAbort={removeContactConfirmModal.close}
          >
            Confermando l&apos;operazione, il contatto sarà rimosso {isLegalPersonOnly ? 'dalla posizione di rappresentante legale' : 'dall\'elenco compratori'}.
          </ConfirmModal>
        )
      }
    </>
  );
};

export default OfferWizardBuyers;
