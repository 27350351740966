import { SWRConfiguration } from 'swr';
import { IntentsWithOfferProviderFilters } from '../../../providers/api/intent/intentProvider';
import { MatchMutate } from '../../../utils/swr/swr';
import useOffersByPropertyId from '../use-offers-by-property-id/useOffersByPropertyId';

export interface OfferWithStatusResult {
  exist?: boolean,
  error?: Error,
  isLoading: boolean,
  mutate: () => ReturnType<MatchMutate>,
}

export type OfferWithStatusFilter = Pick<IntentsWithOfferProviderFilters, 'propertyId' | 'offerStatus'>;

export default function useOffersWithStatusExists(
  filters: OfferWithStatusFilter,
  options?: SWRConfiguration,
): OfferWithStatusResult {
  const {
    data: offers,
    isLoading,
    error,
    mutate,
  } = useOffersByPropertyId(filters?.propertyId, filters?.offerStatus, options);

  return {
    exist: offers && !error ? offers.length > 0 : undefined,
    error,
    isLoading,
    mutate,
  };
}
