import { PropertySource } from '../providers/api/dtos';

export const propertySourceLabels: {
  [key in PropertySource]: string
} = {
  SALE: 'Vendita',
  SUCCESSION: 'Successione',
  DONATION: 'Donazione',
  OTHER: 'Altro',
};
