import React, { FunctionComponent, useMemo } from 'react';
import {
  Form, FormActionsAlign, FormProps, FormSubmitType, SelectOption,
} from '@doveit/bricks';
import { CreateLeadFormExtendedModel } from '../../models/CreateLeadFormExtendedModel';
import {
  LeadPropertyFeature, LeadSource, PropertyStatus, LeadWillingness, ResidentialPropertyTypeValue, AgentStatus,
} from '../../../domain/types';
import { propertyTypesRadio } from '../../../call-center/labels/lead';
import {
  leadPropertyFeaturesOptions, leadWillingnessSelectOptions, floorSelectOptions,
} from '../../../select-options';
import AddressSuggestionAutoComplete from '../../../components/address-suggestion-autocomplete/AddressSuggestionAutoComplete';
import leadValidationSchema from '../../../call-center/components/lead-form/LeadForm.schema';
import { propertyStatusLabels } from '../../../labels';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { formatInputDate, formatInputTime } from '../../../utils/form';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';
import { boolToBooleanString } from '../../../utils/boolean-string/booleanString';

export interface CreateLeadFormProps {
  initialValues?: Partial<CreateLeadFormExtendedModel>;
  loading?: boolean;
  canSetAsPersonal?: boolean,
  hideSubmit?: boolean,
  innerRef?: FormProps<CreateLeadFormExtendedModel>['innerRef'],
  onSubmit: FormSubmitType<CreateLeadFormExtendedModel>;
}

const sourceOptions: SelectOption[] = [
  {
    value: LeadSource.INBOUND,
    label: 'Chiamata in entrata',
  },
  {
    value: LeadSource.BORSINO,
    label: 'Borsino',
  },
  {
    value: LeadSource.CASAVO,
    label: 'Casavo',
  },
  {
    value: LeadSource.FACILE,
    label: 'Facile.it',
  },
  {
    value: LeadSource.IMMOBILIARE,
    label: 'Immobiliare.it',
  },
  {
    value: LeadSource.ZAPPYRENT,
    label: 'Zappyrent',
  },
  {
    value: LeadSource.B2B,
    label: 'B2B',
  },
  {
    value: LeadSource.INTENT,
    label: 'Interesse',
  },
  {
    value: LeadSource.COLD_LEAD,
    label: 'Lead fredde',
  },
];

const CreateLeadForm: FunctionComponent<CreateLeadFormProps> = ({
  initialValues,
  loading,
  canSetAsPersonal = false,
  hideSubmit,
  innerRef,
  onSubmit,
}) => {
  const now = useMemo(() => new Date(), []);
  const nowDate = useMemo(() => formatInputDate(now), [now]);
  const nowTime = useMemo(() => formatInputTime(now), [now]);
  const initialValuesMerged = useMemo<CreateLeadFormExtendedModel>(() => ({
    propertyFloor: initialValues?.propertyFloor ?? '',
    propertyFeatures: initialValues?.propertyFeatures ?? [],
    propertyAddress: initialValues?.propertyAddress,
    propertySize: initialValues?.propertySize ?? '',
    propertyType: initialValues?.propertyType ?? ResidentialPropertyTypeValue.APPARTAMENTO,
    propertyStatus: initialValues?.propertyStatus ?? PropertyStatus.ABITABILE,
    nextOpportunityDate: initialValues?.nextOpportunityDate ?? '',
    nextOpportunityTime: initialValues?.nextOpportunityTime ?? '',
    willingness: initialValues?.willingness ?? LeadWillingness.NOW,
    source: initialValues?.source ?? LeadSource.INBOUND,
    createdAtDate: nowDate,
    createdAtTime: nowTime,
    isPersonal: boolToBooleanString(!!initialValues?.originAgent),
    originAgent: initialValues?.originAgent,
  }), [nowDate, nowTime, initialValues]);

  return (
    <Form
      initialValues={initialValuesMerged}
      validationSchema={leadValidationSchema}
      loading={loading}
      onSubmit={onSubmit}
      innerRef={innerRef}
      preventEnterKeypress
    >
      {({
        values,
        setFieldValue,
      }) => {
        const onPropertyTypeChange = () => {
          setFieldValue('propertyFloor', '', false);
          setFieldValue('propertyFeatures', values.propertyFeatures.filter((feature) => ![LeadPropertyFeature.BALCONE_TERRAZZO, LeadPropertyFeature.ASCENSORE].includes(feature)));
        };

        const onNextOpportunityDateChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
          if (!event.target.value) {
            setFieldValue('nextOpportunityTime', '');
          }
        };

        const onIsPersonalChange = () => {
          setFieldValue('originAgent', '');
        };

        return (
          <>
            <Form.Fieldset legend="Dati immobile">
              <Form.Item>
                <Form.RadioButtonGroup
                  name="propertyType"
                  label="Tipologia immobile"
                  aria-label="Campo per specificare la tipologia dell'immobile"
                  itemSize={{ MD: 1 / 2 }}
                  onChange={onPropertyTypeChange}
                  items={propertyTypesRadio}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <AddressSuggestionAutoComplete
                  name="propertyAddress"
                  label="Indirizzo"
                  aria-label="Campo per inserire l'indirizzo dell'immobile"
                  placeholder="Es: Piazza Armando Diaz, 6, Milano"
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Select
                  name="propertyFloor"
                  label="Piano"
                  aria-label="Piano"
                  options={floorSelectOptions}
                  disabled={values.propertyType !== ResidentialPropertyTypeValue.APPARTAMENTO}
                  required
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  type="number"
                  name="propertySize"
                  min="0"
                  placeholder="Es: 60"
                  label="Superficie (mq)"
                  aria-label="Superficie (mq)"
                  required
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Select
                  name="propertyStatus"
                  label="Stato immobile"
                  aria-label="Stato immobile"
                  options={asSelectOptions(propertyStatusLabels, false)}
                  required
                />
              </Form.Item>
              <Form.Item>
                <Form.CheckboxButtonGroup
                  label="L'immobile dispone di:"
                  name="propertyFeatures"
                  itemSize={{ MD: 1 / 4 }}
                  items={leadPropertyFeaturesOptions.map((option) => ({
                    ...option,
                    disabled: values.propertyType === ResidentialPropertyTypeValue.VILLA && (option.value === LeadPropertyFeature.ASCENSORE || option.value === LeadPropertyFeature.BALCONE_TERRAZZO),
                  }))}
                />
              </Form.Item>
              <Form.Item>
                <Form.RadioButtonGroup
                  name="willingness"
                  label="Tempistica di vendita"
                  aria-label="Campo per specificare le tempistiche di vendita"
                  itemSize={{ MD: 1 / 3 }}
                  items={leadWillingnessSelectOptions}
                />
              </Form.Item>
            </Form.Fieldset>
            <Form.Fieldset legend="Prossimo contatto e acquisizione">
              <Form.Group label="Data e ora prossimo contatto">
                <Form.Item size={1 / 2}>
                  <Form.Input
                    type="date"
                    aria-label="Campo per l'inserimento della data del prossimo contatto"
                    name="nextOpportunityDate"
                    onChange={onNextOpportunityDateChange}
                    required
                  />
                </Form.Item>
                <Form.Item size={1 / 2}>
                  <Form.Input
                    type="time"
                    aria-label="Campo per l'inserimento dell'ora del prossimo contatto"
                    name="nextOpportunityTime"
                    required
                    disabled={!values.nextOpportunityDate}
                  />
                </Form.Item>
              </Form.Group>
              <Form.Group label="Data di acquisizione">
                <Form.Item size={1 / 2}>
                  <Form.Input
                    type="date"
                    aria-label="Campo per l'inserimento della data di acquisizione"
                    name="createdAtDate"
                    max={nowDate}
                    required
                  />
                </Form.Item>
                <Form.Item size={1 / 2}>
                  <Form.Input
                    type="time"
                    aria-label="Campo per l'inserimento dell'ora di acquisizione"
                    name="createdAtTime"
                    required
                  />
                </Form.Item>
              </Form.Group>
              <Form.Item>
                <Form.Select
                  name="source"
                  label="Canale di acquisizione"
                  aria-label="Canale di acquisizione"
                  options={sourceOptions}
                />
              </Form.Item>
            </Form.Fieldset>
            <Form.Fieldset legend="Attribuzione lead">
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.YesOrNotButtons
                  label="Lead personale?"
                  name="isPersonal"
                  aria-label="Campo per specificare se la lead è personale"
                  disabled={!canSetAsPersonal}
                  onChange={onIsPersonalChange}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <AgentAutoComplete
                  name="originAgent"
                  label="Agente"
                  aria-label="Campo per inserire l'agente gestore della lead"
                  placeholder="Cerca per nome"
                  status={AgentStatus.ACTIVE}
                  disabled={!canSetAsPersonal || values.isPersonal === 'false'}
                />
              </Form.Item>
            </Form.Fieldset>
            {!hideSubmit && (
              <Form.Actions align={FormActionsAlign.RIGHT}>
                <Form.Submit aria-label="Aggiungi lead" label="Aggiungi lead" />
              </Form.Actions>
            )}
          </>
        );
      }}
    </Form>
  );
};

export default CreateLeadForm;
