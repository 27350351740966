import {
  Form, FormHandlers, FormProps, Message,
} from '@doveit/bricks';
import React from 'react';

export interface EditPropertyPrivacyFormModel {
  hidePrice: boolean,
  hideStreetNumber: boolean,
  hideRoute: boolean,
}

export interface EditPropertyPrivacyFormProps {
  initialValues?: Partial<EditPropertyPrivacyFormModel>,
  innerRef?: React.MutableRefObject<FormHandlers>
  onSubmit: FormProps<EditPropertyPrivacyFormModel>['onSubmit']
}

const EDIT_PROPERTY_PRIVACY_FORM_DEFAULT_VALUES: EditPropertyPrivacyFormModel = {
  hidePrice: false,
  hideStreetNumber: false,
  hideRoute: false,
};

const EditPropertyPrivacyForm: React.FC<EditPropertyPrivacyFormProps> = ({
  initialValues,
  innerRef,
  onSubmit,
}) => {
  const mergedInitialValues: EditPropertyPrivacyFormModel = React.useMemo(() => ({
    ...EDIT_PROPERTY_PRIVACY_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      innerRef={innerRef}
    >
      {({ setFieldValue }) => {
        const handleHideRouteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.checked) return;

          setFieldValue('hideStreetNumber', true);
        };

        return (
          <>
            <Form.Item>
              <Message
                type="warning"
                message="Possibile penalizzazione"
              >
                Nascondendo le informazioni, l&apos;annuncio potrebbe subire una penalizzazione nella visualizzazione o pubblicazione sui portali.
              </Message>
            </Form.Item>
            <Form.Item>
              <Form.CheckboxButton
                name="hidePrice"
                text="Nascondi prezzo"
                aria-label="Checkbox per nascondere il prezzo"
              />
            </Form.Item>
            <Form.Item>
              <Form.CheckboxButton
                name="hideStreetNumber"
                text="Nascondi numero civico"
                aria-label="Checkbox per nascondere il numero civico"
              />
            </Form.Item>
            <Form.Item>
              <Form.CheckboxButton
                name="hideRoute"
                text="Nascondi indirizzo"
                aria-label="Checkbox per nascondere l'indirizzo"
                onChange={handleHideRouteChange}
                dataRef="privacy-form-checkbox-hideRoute"
              />
            </Form.Item>
          </>
        );
      }}
    </Form>
  );
};

export default EditPropertyPrivacyForm;
