/* eslint-disable react/no-unknown-property */
import React from 'react';
import { formatEuro, formatSquareMeters } from '@doveit/hammer';
import {
  Action,
  FontWeight, HStack, Icon, ICON_MAP_MARKER_OUTLINE, Message, Popover, PopoverProps, Spacing, Stack, Text,
} from '@doveit/bricks';
import EntityReferenceBadge from '../../../components/entity-reference-badge/EntityReferenceBadge';
import PopoverPreview from '../../../components/popover-preview/PopoverPreview';
import * as styles from './ViewPropertyPopoverPreview.style';
import { ReferenceType } from '../../../domain/types';
import { NO_VALUE_SYMBOL, countRooms, formatFloor } from '../../utils';
import useRooms from '../../hooks/use-rooms/useRooms';
import PopoverPreviewSkeleton from '../../../components/popover-preview/PopoverPreview.skeleton';
import PropertyAvatar from '../../components/property-avatar/PropertyAvatar';
import { goToDetailPage } from '../../../utils/navigate/utils';
import usePropertyPreview from '../../hooks/use-property-preview/usePropertyPreview';
import { Property, PropertyPreview } from '../../../providers/api/dtos';

interface BaseViewPropertyPopoverPreviewProps extends React.AriaAttributes {
  trigger: PopoverProps['trigger'],
  hideAction?: boolean,
  showMatchingAction?: boolean,
}

export type ViewPropertyPopoverPreviewProps = BaseViewPropertyPopoverPreviewProps & ({
  propertyId: NonNullable<Property['id']>,
  property?: undefined,
} | {
  property: PropertyPreview,
  propertyId?: undefined,
});

export const LOAD_PROPERTY_DATA_ERROR_MESSAGE = 'Non è stato possibile caricare i dati dell\'immobile';

const ViewPropertyPopoverPreview: React.FC<ViewPropertyPopoverPreviewProps> = ({
  propertyId,
  property: externalProperty,
  trigger,
  hideAction = false,
  showMatchingAction = false,
  ...rest
}) => {
  const { data: loadedProperty, isLoading: isPropertyLoading, error: propertyError } = usePropertyPreview(propertyId);

  const property = externalProperty || loadedProperty;

  const { data: rooms, isLoading: isRoomsLoading, error: roomsError } = useRooms(property?.id);

  const title = React.useMemo(() => (
    property?.overriddenGeo
      ? [property.overriddenGeo?.route, property.overriddenGeo?.streetNumber]
      : [property?.geo?.route, property?.geo?.streetNumber]).filter((value) => !!value).join(', '), [property]);

  const features = React.useMemo(() => ([
    {
      label: 'Superficie',
      value: property?.propertySize ? formatSquareMeters(property.propertySize) : NO_VALUE_SYMBOL,
    },
    {
      label: 'Locali',
      value: rooms ? countRooms(rooms) : NO_VALUE_SYMBOL,
    },
    {
      label: 'Piano',
      value: property?.mainFloor !== undefined ? formatFloor(property?.mainFloor) : NO_VALUE_SYMBOL,
    },
  ]), [property, rooms]);

  const goToPropertyPage = React.useCallback(
    () => goToDetailPage(property?.id!, ReferenceType.PROPERTY),
    [property],
  );

  const goToMatchingPage = React.useCallback(() => {
    window.open(`/properties/${property!.id}/matching`, '_blank');
  }, [property]);

  return (
    <Popover
      trigger={trigger}
      hasArrow
      {...rest}
    >
      <div css={styles.wrapper}>
        {((isPropertyLoading || isRoomsLoading) && !propertyError && !roomsError) && (
          <PopoverPreviewSkeleton />
        )}

        {(propertyError || roomsError) && (
          <Spacing padding={200}>
            <Message
              type="critical"
              message={LOAD_PROPERTY_DATA_ERROR_MESSAGE}
            />
          </Spacing>
        )}

        {property && rooms && (
          <PopoverPreview
            title={title}
            details={property?.geo?.locality}
            avatar={<PropertyAvatar size="L" />}
          >
            <Stack gap={150}>
              <HStack gap={75}>
                {property.overriddenGeo && (
                  <div css={styles.address}>
                    <Icon path={ICON_MAP_MARKER_OUTLINE} size={20} />
                    <Text.Body as="div">{property.geo?.normalizedAddress}</Text.Body>
                  </div>
                )}
                <EntityReferenceBadge
                  id={property.id!}
                  entity={ReferenceType.PROPERTY}
                  label={property?.referenceId}
                  isNewTab
                />
                {property?.price && (
                  <div>
                    <Text.Body
                      lineHeight="1"
                      fontWeight={FontWeight.MEDIUM}
                      as="div"
                    >
                      {formatEuro(property?.price)}
                    </Text.Body>
                  </div>
                )}
              </HStack>
              {features.length > 0 && (
                <div css={styles.features}>
                  {features.map((feature) => (
                    <div
                      key={`${feature.label}-${feature.value}`}
                      css={styles.feature}
                    >
                      <Text.BodySmall
                        fontWeight={FontWeight.MEDIUM}
                        lineHeight="1"
                        as="div"
                      >
                        {feature.value}
                      </Text.BodySmall>
                      <Text.Mini
                        transform="uppercase"
                        as="div"
                      >
                        {feature.label}
                      </Text.Mini>
                    </div>
                  ))}
                </div>
              )}
              {(!hideAction || showMatchingAction) && (
                <HStack gap={75} wrap="nowrap">
                  {!hideAction && (
                    <Action
                      size="S"
                      color="primary"
                      label="Visualizza"
                      aria-label="Vai alla pagina dell'immobile"
                      onClick={goToPropertyPage}
                      expanded={!showMatchingAction}
                    />
                  )}
                  {showMatchingAction && (
                    <Action
                      aria-label="Vedi potenziali acquirenti"
                      label="Vedi potenziali acquirenti"
                      size="S"
                      onClick={goToMatchingPage}
                      expanded={hideAction}
                    />
                  )}
                </HStack>
              )}
            </Stack>
          </PopoverPreview>
        )}
      </div>
    </Popover>
  );
};

export default ViewPropertyPopoverPreview;
