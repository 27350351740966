/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Action,
  ActionProps,
  LinkOnClickEvent,
  ICON_CHEVRON,
  Pictogram,
  Icon,
  useToggle,
} from '@doveit/bricks';
import React, {
  FunctionComponent, MouseEvent as ReactMouseEvent, useCallback, useMemo,
} from 'react';
import { cleanText, truncateHTMLTextWithSuffix } from '../../../utils/text/text';
import { formatTimeDistance } from '../../../utils/time/format-time-distance/formatTimeDistance';
import * as styles from './ApplicationNotificationCard.style';

export interface ApplicationNotificationAction extends Pick<ActionProps, 'label' | 'color'> {
  onClick: VoidFunction,
}

export interface ApplicationNotificationCardProps {
  dataRef?: string,
  senderName?: string,
  icon: string,
  iconColor?: string,
  title: string,
  message: string,
  additionalInfo?: string,
  date: string,
  context?: string[],
  read?: boolean,
  onClick?: VoidFunction,
  actions?: ApplicationNotificationAction[],
}

export const DEFAULT_MESSAGE_TRUNCATE_LENGTH = 100;

const ApplicationNotificationCard: FunctionComponent<ApplicationNotificationCardProps> = ({
  dataRef = 'application-notification-card',
  senderName,
  icon,
  iconColor,
  title,
  message,
  additionalInfo,
  date,
  context,
  onClick,
  actions = [],
  read,
}) => {
  const { opened, toggle } = useToggle(false);
  const onActionClick = useCallback((action: ApplicationNotificationAction) => (e: LinkOnClickEvent | ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    action.onClick();
  }, []);

  const onSeeMoreClick = useCallback((e: LinkOnClickEvent | ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    toggle();
  }, [toggle]);

  const compositeMessage = useMemo(
    () => `${message} ${additionalInfo || ''}`.trim(),
    [additionalInfo, message],
  );

  const isCompositeMessageTooLong = useMemo(
    () => cleanText(compositeMessage).length > DEFAULT_MESSAGE_TRUNCATE_LENGTH,
    [compositeMessage],
  );

  const finalCompositeMessage = useMemo(
    () => {
      if (!opened && isCompositeMessageTooLong) {
        return truncateHTMLTextWithSuffix(compositeMessage, DEFAULT_MESSAGE_TRUNCATE_LENGTH, '...');
      }

      return compositeMessage;
    },
    [isCompositeMessageTooLong, compositeMessage, opened],
  );

  return (
    <div
      data-ref={dataRef}
      css={styles.base}
      $highlighted={!read}
      $clickable={!!onClick}
      onClick={onClick}
    >
      <div css={styles.avatar}>
        <div data-ref={`${dataRef}-avatar`}>
          {senderName
            ? senderName.substring(0, 1)
            : (
              <div css={styles.pictogram}>
                <Pictogram />
              </div>
            )}
        </div>
        <div
          css={styles.icon}
          $color={iconColor}
          $highlighted={!read}
        >
          <Icon
            path={icon}
            size={18}
          />
        </div>
      </div>
      <div css={styles.info}>
        <div css={styles.header}>
          <div css={styles.title}>
            {title}
          </div>
          <div css={styles.date}>
            {formatTimeDistance(date, Date.now())}
          </div>
        </div>
        <div
          data-ref={`${dataRef}-message`}
          css={styles.message}
          dangerouslySetInnerHTML={{ __html: finalCompositeMessage }}
        />
        <div css={styles.footer}>
          {context && (
            <div
              data-ref={`${dataRef}-context`}
              css={styles.context}
            >
              {context.join(' - ')}
            </div>
          )}
          {isCompositeMessageTooLong && (
            <div
              data-ref={`${dataRef}-read-all-action`}
              css={styles.readAll}
              onClick={onSeeMoreClick}
            >
              <span>{opened ? 'Nascondi' : 'Mostra tutto'}</span>
              <Icon
                path={ICON_CHEVRON}
                direction={opened ? 'top' : 'bottom'}
                size={18}
              />
            </div>
          )}
        </div>
        {actions.length > 0 && (
          <div css={styles.actionBar}>
            {actions.map((action) => {
              const { label, color = 'neutral' } = action;

              return (
                <Action
                  key={label}
                  label={label}
                  onClick={onActionClick(action)}
                  color={color}
                  size="S"
                  emphasis="high"
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationNotificationCard;
