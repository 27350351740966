import * as Yup from 'yup';

export default Yup.object().shape({
  price: Yup.object().shape({
    min: Yup.number()
      .optional()
      .test('min price', (value, context) => {
        if (value !== undefined && value <= 10000) {
          return context.createError({
            path: 'price.min',
            message: 'Il budget minimo deve essere superiore a € 10.000',
          });
        }
        return true;
      }),
    max: Yup.number()
      .optional()
      .test('max price', (value, context) => {
        if (value !== undefined && value <= 10000) {
          return context.createError({
            path: 'price.max',
            message: 'Il budget massimo deve essere superiore a € 10.000',
          });
        }
        return true;
      }),
  }),
});
