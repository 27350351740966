/* eslint-disable react/no-unknown-property */
import { Action, Message, Spacing } from '@doveit/bricks';
import { pluralizeWord } from '@doveit/hammer';
import React from 'react';
import * as styles from './WelcomeBox.style';

export interface WelcomeBoxProps extends React.AriaAttributes {
  userName: string,
  loading?: boolean,
  count: number,
  onStartClick: VoidFunction,
  onRefreshClick: VoidFunction,
}

const WelcomeBox: React.FC<WelcomeBoxProps> = ({
  loading,
  userName,
  count,
  onStartClick,
  onRefreshClick,
  ...rest
}) => {
  const countMessage = `${pluralizeWord('contatt', 'o', 'i', count)}`;
  const zeroResultsMessage = 'non hai nuovi contatti da processare.';

  return (
    <div
      {...rest}
      css={styles.base}
    >
      <h1 css={styles.message}>
        {`Ciao ${userName},`}
        <br />
        {count ? (
          <>
            {'hai '}
            <strong>{count} {countMessage}</strong>
            {' da processare.'}
          </>
        ) : (zeroResultsMessage)}
      </h1>

      {count ? (
        <Message
          type="warning"
          message="Prima di iniziare attiva Aircall"
        />
      ) : (
        <Message
          type="info"
          message="Aggiorna la pagina o aspetta che lo faccia in automatico"
        />
      )}

      <Spacing margin={[400, 0, 0]}>
        <Action
          loading={loading}
          label={count ? 'Inizia' : 'Aggiorna'}
          color="primary"
          emphasis="high"
          expanded
          onClick={count ? onStartClick : onRefreshClick}
        />
      </Spacing>
    </div>
  );
};

export default WelcomeBox;
