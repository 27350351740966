import React from 'react';
import {
  ActionIcon, ICON_ACCOUNT_MULTIPLE_CHECK_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agency, Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import HireApplicantForm, { HireApplicantFormModel } from '../../components/hire-applicant-form/HireApplicantForm';
import { JobTitle } from '../../../domain/types';
import { createAgent } from '../../../providers/api/agent/agentProvider';

interface ChildFnProps {
  openModal: VoidFunction,
  isLoading: boolean,
}

export interface CreateAgentActionProps {
  agencies?: Agency[],
  modalTitle?: string,
  onSuccess?: (createdAgent: Agent) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const CREATE_AGENT_SUCCESS_MESSAGE = "L'agente è stato creato con successo";
export const CREATE_AGENT_ERROR_MESSAGE = "Non è stato possibile creare l'agente";

const CreateAgentAction: React.FC<CreateAgentActionProps> = (props) => {
  const {
    agencies, modalTitle = 'Crea agente', onSuccess, children,
  } = props;

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isLoading, setIsLoading] = React.useState(false);

  const openModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const onSubmit = React.useCallback(async (formValues: HireApplicantFormModel) => {
    try {
      setIsLoading(true);

      const {
        email, mobile, name, role, startDate, surname,
      } = formValues;

      const agentToCreate: Agent = {
        name: {
          name: name.trim(),
          surname: surname.trim(),
        },
        email: { personal: email.trim() },
        mobile: { personal: mobile.trim() },
        contract: { startDate: new Date(startDate.trim()).toISOString() },
        jobTitle: role as JobTitle,
        hasLicense: role !== JobTitle.CONSULENTE,
        needsSupervisor: role === JobTitle.CONSULENTE,
        source: 'referral',
        agencies,
        inTrial: false,
      };

      const createdAgent = await createAgent(agentToCreate);

      setIsLoading(false);
      addSuccess(CREATE_AGENT_SUCCESS_MESSAGE);

      modal.close();

      if (onSuccess) {
        onSuccess(createdAgent);
      }
    } catch (error) {
      setIsLoading(false);
      addError(CREATE_AGENT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agencies, modal, onSuccess]);

  return (
    <>
      {children
        ? children({ openModal, isLoading })
        : (
          <ActionIcon
            aria-label="Crea nuovo agente"
            label="Crea"
            size="S"
            onClick={openModal}
            loading={isLoading}
            icon={{ path: ICON_ACCOUNT_MULTIPLE_CHECK_OUTLINE }}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title={modalTitle}
        >
          <HireApplicantForm
            onSubmit={onSubmit}
            hiddenFields={agencies?.some(({ franchising }) => franchising) ? ['inTrial'] : []}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default CreateAgentAction;
