/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  ColumnDef,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import {
  ActionIcon,
  ICON_ARROW_UP,
  ICON_CHEVRON,
  ICON_EMPTY,
  Input,
  Select,
  Spacing,
  Icon,
  HStack,
} from '@doveit/bricks';
import * as styles from './Table.style';
import { asSelectOptions } from '../../select-options/utils/asSelectOptions';

export const DEFAULT_TABLE_PAGE_SIZE = 10;

export type ColumnDefinition<TData extends unknown, TValue = unknown> = ColumnDef<TData, TValue>;

export interface TableProps<TData extends unknown, TValue = unknown> extends React.AriaAttributes {
  data: TData[],
  columns: ColumnDefinition<TData, TValue>[],
  pageable?: boolean,
  sortable?: boolean,
  filterable?: boolean,
  showHeader?: boolean,
  onRowClick?: (row: TData) => void,
  EmptyDataComponent?: React.ReactNode,
}

const Table = <TData, TValue>(props: TableProps<TData, TValue>) => {
  const {
    columns,
    data,
    sortable = false,
    pageable = false,
    filterable = false,
    showHeader = true,
    onRowClick,
    EmptyDataComponent = 'Nessun risultato',
    ...rest
  } = props;

  const table = useReactTable({
    columns,
    data,
    initialState: {
      pagination: {
        pageSize: DEFAULT_TABLE_PAGE_SIZE,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: filterable ? getFilteredRowModel() : undefined,
    getSortedRowModel: sortable ? getSortedRowModel() : undefined,
    getPaginationRowModel: pageable ? getPaginationRowModel() : undefined,
  });

  return (
    <>
      <table
        {...rest}
        css={styles.table}
      >
        {showHeader && (
          <thead css={styles.thead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                css={styles.thRow}
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    css={styles.th}
                    $align={header.column.columnDef.meta?.style?.align}
                    $width={header.column.columnDef.meta?.style?.width}
                  >
                    {header.isPlaceholder
                      ? null
                      : (
                        <div>
                          {(sortable && header.column.getCanSort()) ? (
                            <div
                              onClick={header.column.getToggleSortingHandler()}
                              onKeyDown={header.column.getToggleSortingHandler()}
                              role="presentation"
                              css={styles.header}
                            >
                              <HStack>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                                <Icon
                                  direction={header.column.getIsSorted() === 'asc' ? 'top' : 'bottom'}
                                  path={header.column.getIsSorted() ? ICON_CHEVRON : ICON_EMPTY}
                                  size={20}
                                />
                              </HStack>
                            </div>
                          ) : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {filterable && header.column.getCanFilter() ? (
                            <Spacing margin={[100, 0, 0]}>
                              {header.column.columnDef.meta?.filter?.type === 'select' ? (
                                <Select
                                  value={(header.column.getFilterValue() ?? '') as string}
                                  options={asSelectOptions(header.column.columnDef.meta.filter.options, { label: 'Tutti', value: '' })}
                                  onChange={({ target }) => header.column.setFilterValue(target.value)}
                                  placeholder={`Filtra per ${header.column.columnDef.header}`}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  value={(header.column.getFilterValue() ?? '') as string}
                                  onChange={({ target }) => header.column.setFilterValue(target.value)}
                                  placeholder={`Filtra per ${header.column.columnDef.header}`}
                                />
                              )}
                            </Spacing>
                          ) : null}
                        </div>
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}

        <tbody css={styles.tbody}>
          {table.getRowModel().rows.length
            ? table.getRowModel().rows.map((row, rowIndex) => (
              <tr
                key={row.id}
                css={styles.row}
                data-testid={`row ${rowIndex}`}
                $interactive={!!onRowClick}
              >
                {row.getVisibleCells().map((cell, colIndex) => (
                  <td
                    key={cell.id}
                    css={styles.td}
                    data-testid={`column ${colIndex}`}
                    $align={cell.column.columnDef.meta?.style?.align}
                    $width={cell.column.columnDef.meta?.style?.width}
                    $pointerEvents={cell.column.columnDef.meta?.style?.pointerEvents}
                  >
                    <div css={styles.tdContent}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  </td>
                ))}
                {onRowClick && (
                  <td
                    aria-label="table-row-clickable-area"
                    css={styles.rowClickArea}
                    onClick={() => onRowClick(row.original)}
                  />
                )}
              </tr>
            ))
            : <tr><td>{EmptyDataComponent}</td></tr>}
        </tbody>
      </table>
      {pageable && (
        <HStack css={styles.pagination}>
          <div>
            <strong>{table.getPrePaginationRowModel().rows.length}</strong> Risultati
          </div>

          <HStack>
            <ActionIcon
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              size="M"
              label="Pagina precedente"
              color="neutral"
              icon={{
                path: ICON_ARROW_UP,
                direction: 'left',
              }}
            />

            <HStack>
              Pagina
              <Input
                type="number"
                value={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                min={0}
                max={table.getPageCount()}
              />
              di
              <strong>
                {table.getPageCount()}
              </strong>
            </HStack>

            <ActionIcon
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              size="M"
              label="Pagina successiva"
              color="neutral"
              icon={{
                path: ICON_ARROW_UP,
                direction: 'right',
              }}
            />
          </HStack>
        </HStack>
      )}
    </>
  );
};

export default Table;
