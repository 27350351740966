import { Badge } from '@doveit/bricks';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { AreaType, EvaluationType } from '../../../domain/types';
import { evaluationTypeLabels } from '../../../labels';
import { AgentsPerArea } from '../../models/AgentsPerArea';
import { getAgentName } from '../../utils/utils';

export const agentAreasListColumns = (areaType: AreaType) => {
  const columns: ColumnDef<AgentsPerArea, string>[] = [
    {
      id: 'coverage',
      header: 'Copertura',
      accessorKey: 'coverage',
      maxSize: 120,
      accessorFn: ({ areas }) => (areas.length > 0 ? 'SI' : 'NO'),
      cell: ({ getValue }) => (
        <Badge
          label={getValue()}
          color={getValue() === 'SI' ? 'success' : 'critical'}
          size="XS"
        />
      ),
      meta: {
        filter: {
          type: 'select',
          options: { SI: 'Sì', NO: 'No' },
        },
      },
    },
    areaType === AreaType.ZIP_CODE
      ? {
        id: 'locality',
        header: 'Città',
        accessorKey: 'locality',
      }
      : {
        id: 'zipCode',
        header: 'CAP',
        accessorKey: 'zipCode',
      },
    {
      id: 'agent',
      header: 'Agente',
      accessorKey: 'agents',
      accessorFn: ({ areas }) => areas
        .map(({ agent }) => (agent ? getAgentName(agent) : undefined))
        .filter((it) => !!it)
        .join(', '),
    },
    {
      id: 'evaluationType',
      header: 'Valutazione',
      accessorKey: 'evaluationType',
      accessorFn: ({ areas }) => areas.flatMap(({ evaluationType }) => (evaluationType ?? [])).at(0) ?? '',
      cell: ({ getValue }) => {
        const type = getValue();

        return type ? (
          <Badge
            label={evaluationTypeLabels[type as EvaluationType]}
            color="info"
            size="XS"
          />
        ) : null;
      },
      meta: {
        filter: {
          type: 'select',
          options: evaluationTypeLabels,
        },
      },
    },
  ];

  return columns;
};
