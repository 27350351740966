import useSWR, { SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Document, Prospect } from '../../../providers/api/dtos';
import { getProspectDocuments } from '../../../providers/api/prospect/prospectProvider';
import { API_PROSPECTS } from '../../../providers/api/api';

export const PROSPECT_DOCUMENTS_RID = `${API_PROSPECTS}/:id/documents`;

export function useProspectDocuments(prospectId?: Prospect['id']): SWRResponse<Document[], AxiosError> {
  const key = prospectId ? [PROSPECT_DOCUMENTS_RID, prospectId] : null;

  return useSWR(key, () => getProspectDocuments(prospectId!));
}
