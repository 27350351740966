import React, {
  FunctionComponent, useCallback, useRef,
} from 'react';
import { Message, Spacing } from '@doveit/bricks';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import FullTextSearchForm, { FullTextSearchFormModel } from '../../../components/full-text-search-form/FullTextSearchForm';
import useSearchAgents from '../../hooks/use-search-agents/useSearchAgents';
import ViewAgentCard from '../../containers/view-agent-card/ViewAgentCard';
import { SortOrder } from '../../../providers/pagination';
import usePagination from '../../../hooks/use-pagination/usePagination';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import useFilters, { Filters } from '../../../hooks/use-filters/useFilters';

interface SearchAgentPageFilters extends Filters {
  q?: string;
}

export const SEARCH_ERROR_MESSAGE = 'Errore durante la ricerca';

const SearchAgentPage: FunctionComponent = () => {
  const { filters: { q }, addFilters, removeFilter } = useFilters<SearchAgentPageFilters>();

  const scrollRef = useRef<HTMLDivElement>(null);
  const { page, paginationQueryParam, goToPage } = usePagination('page', scrollRef);

  const { data: agents, error } = useSearchAgents(q ? { q } : undefined, {
    page,
    size: 20,
    sort: {
      name: SortOrder.ASC,
      surname: SortOrder.ASC,
    },
  });

  const isSearching = !!q && !agents && !error;

  const onSubmit = useCallback(({ search }: FullTextSearchFormModel) => {
    const newFilter: SearchAgentPageFilters = {
      q: search,
    };
    addFilters(newFilter);
    removeFilter(paginationQueryParam);
  }, [addFilters, removeFilter, paginationQueryParam]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent innerRef={scrollRef}>
          <FullTextSearchForm
            onSubmit={onSubmit}
            loading={isSearching}
            placeholder="Cerca per nome, telefono o email"
            initialValues={{ search: q || '' }}
          />

          {!agents && error && (
            <Spacing margin={[200, 0, 0]}>
              <Message
                type="critical"
                message={SEARCH_ERROR_MESSAGE}
              />
            </Spacing>
          )}

          {agents && (
            <Spacing margin={[200, 0, 0]}>
              <PaginatedList
                {...agents}
                goToPage={goToPage}
              >
                {agents.content.map((agent) => (
                  <ViewAgentCard
                    key={agent.id}
                    agent={agent}
                  />
                ))}
              </PaginatedList>
            </Spacing>
          )}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default SearchAgentPage;
