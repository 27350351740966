import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const flex: ThemedFunctor = ({ theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.remSize[75]};
`;

export const documentIcon: ThemedFunctor = ({
  theme,
}) => css`
  color: ${theme.color.foreground.neutral.default.low};
  display: flex;
  align-items: center;
`;
