import { ProspectStatus } from '../../domain/types';
import { Prospect } from '../../providers/api/dtos';
import { prospectKOStatus } from '../constants';

export function isArchived(arg: Prospect): Boolean;
export function isArchived(arg: ProspectStatus): Boolean;

export function isArchived(arg: Prospect | ProspectStatus): Boolean {
  const status = (arg as Prospect).status
    ? (arg as Prospect).status
    : arg as ProspectStatus;

  return prospectKOStatus.includes(status);
}
