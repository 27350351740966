import React, {
  FormEvent,
  FunctionComponent, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Action, FormHandlers, LinkOnClickEvent, Message, Portal, Spacing, useModal, useNotifications,
} from '@doveit/bricks';
import { Contact } from '../../../providers/api/dtos/contact';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import SearchContactForm, { SearchContactFormModel } from '../../components/search-contact-form/SearchContactForm';
import { matchContact } from '../../../providers/api/contact/contactProvider';
import ContactMatchCard from '../../components/contact-match-card/ContactMatchCard';
import { hasContactConflicts } from '../../utils/hasContactConflicts';
import getSearchResultTitle from './utils/getSearchResultTitle';
import NoContactView, { NoContactViewProps } from './components/no-contact-view/NoContactView';
import ContactSelectedView, { ContactSelectedViewProps } from './components/contact-selected-view/ContactSelectedView';

export interface SelectContactRef {
  resetInternalState: VoidFunction,
}

export interface SelectContactProps {
  initialContact?: Contact,
  onContactChange: (existingContact?: Contact, formValues?: SearchContactFormModel) => void,
  showClearContact?: boolean,
  render?: {
    noContactView?: FunctionComponent<NoContactViewProps>,
    contactSelectedView?: FunctionComponent<ContactSelectedViewProps>,
  },
  componentRef?: React.RefObject<SelectContactRef>
}

export const MATCH_CONTACT_ERROR_MESSAGE = 'Non è stato possibile verificare l\'esistenza del contatto';
export const UPDATE_WARNING_MESSAGE = 'Cliccando il pulsante "AGGIORNA" aggiornerai il contatto selezionato con i dati in rosso.';
export const NEW_CONTACT_WARNING_MESSAGE = 'Nessun contatto trovato. Verrà creato un nuovo contatto con i seguenti dati.';
export const OVERRIDE_EXISTING_CONTACT_MESSAGE = 'Aggiorneremo il contatto selezionato con i seguenti dati';

const SelectContact: FunctionComponent<SelectContactProps> = ({
  onContactChange,
  initialContact,
  showClearContact,
  render,
  componentRef,
}) => {
  const searchModal = useModal();
  const resultsModal = useModal();
  const formRef = useRef<FormHandlers>();
  const [isSearching, setIsSearching] = useState(false);
  const { addError } = useNotifications();
  const [searchValues, setSearchValues] = useState<SearchContactFormModel | undefined>();
  const [existingContact, setExistingContact] = useState<Contact | undefined>(initialContact);
  const [isSelected, setIsSelected] = useState(false);
  const [results, setResults] = useState<Contact[] | undefined>();

  React.useImperativeHandle(componentRef, () => ({
    resetInternalState: () => {
      setIsSelected(false);
      setExistingContact(undefined);
    },
  }));

  useEffect(() => {
    if (initialContact) {
      setExistingContact(initialContact);
      setIsSelected(true);
    }
  }, [initialContact]);

  const onSearchClick = useCallback((e: LinkOnClickEvent) => {
    const formEvent = e as unknown as FormEvent<HTMLFormElement>;
    formRef.current?.handleSubmit(formEvent);
  }, []);

  const onInternalContactSelected = useCallback((contact?: Contact, overwriteValues?: SearchContactFormModel) => {
    setExistingContact(contact);
    setSearchValues(overwriteValues);
    setIsSelected(contact !== undefined || overwriteValues !== undefined);
    onContactChange(contact, overwriteValues);
  }, [onContactChange]);

  const onResultContactSelected = useCallback((contact: Contact) => {
    resultsModal.close();
    onInternalContactSelected(contact);
  }, [resultsModal, onInternalContactSelected]);

  const onResultContactUpdated = useCallback((contact: Contact, searchResult?: SearchContactFormModel) => {
    resultsModal.close();
    onInternalContactSelected(contact, searchResult);
  }, [resultsModal, onInternalContactSelected]);

  const onContactFormSubmit = useCallback(async (values: SearchContactFormModel) => {
    try {
      setIsSearching(true);
      setSearchValues(values);

      const contacts = await matchContact({
        phoneNumber: values.phoneNumber,
        email: values.email,
      });

      setResults(contacts);

      searchModal.close();

      if (contacts.length === 0) {
        onInternalContactSelected(undefined, values);
      } else {
        resultsModal.open();
      }
    } catch (e) {
      addError(MATCH_CONTACT_ERROR_MESSAGE);
    } finally {
      setIsSearching(false);
    }
  }, [addError, searchModal, resultsModal, onInternalContactSelected]);

  const [filteredResults, perfectMatch, completeResults] = useMemo(() => {
    if (searchValues?.email && searchValues?.phoneNumber) {
      const perfectMatchFound = results?.find(
        (contact) => contact.email === searchValues?.email
          && contact.phoneNumber === searchValues?.phoneNumber,
      );

      if (perfectMatchFound) {
        return [[perfectMatchFound], perfectMatchFound, [perfectMatchFound]];
      }
    }

    return [results, undefined, results?.filter((contact) => contact.complete)];
  }, [results, searchValues]);

  const canBeUpdated = useCallback(
    (result: Contact) => !perfectMatch && searchValues && hasContactConflicts(result, searchValues!),
    [perfectMatch, searchValues],
  );

  const showUpdateWarning = useMemo(() => filteredResults?.some((result) => canBeUpdated(result)),
    [filteredResults, canBeUpdated]);

  const createNewContact = useCallback(() => {
    onInternalContactSelected(undefined, searchValues);
  }, [onInternalContactSelected, searchValues]);

  const clearContact = useCallback(
    () => onInternalContactSelected(undefined, undefined),
    [onInternalContactSelected],
  );

  return (
    <>
      {!isSelected && (render?.noContactView
        ? render.noContactView({ openContactFinder: searchModal.open })
        : <NoContactView openContactFinder={searchModal.open} />
      )}
      {isSelected && !existingContact && (
        <Spacing margin={[100, 0]}>
          <Message
            type="warning"
            message={NEW_CONTACT_WARNING_MESSAGE}
          />
        </Spacing>
      )}
      {isSelected && existingContact && searchValues && (
        <Spacing margin={[100, 0]}>
          <Message
            type="warning"
            message={OVERRIDE_EXISTING_CONTACT_MESSAGE}
          />
        </Spacing>
      )}

      {isSelected && (render?.contactSelectedView
        ? render.contactSelectedView({ existingContact, searchValues, clearContact })
        : (
          <div>
            <ContactSelectedView
              existingContact={existingContact}
              searchValues={searchValues}
              clearContact={showClearContact ? clearContact : undefined}
            />
          </div>
        )
      )}
      <Portal>
        <SimpleModal
          {...searchModal}
          data-ref="search-modal"
          title="Inserisci dati contatto"
          footer={(
            <Action
              label="Aggiungi"
              dataRef="submit-contact-form"
              color="primary"
              emphasis="high"
              onClick={onSearchClick}
              loading={isSearching}
            />
          )}
        >
          <SearchContactForm
            formRef={formRef as React.MutableRefObject<FormHandlers>}
            onSubmit={onContactFormSubmit}
            loading={isSearching}
          />
        </SimpleModal>
        {filteredResults && completeResults && (
          <SimpleModal
            {...resultsModal}
            data-ref="results-modal"
            title={getSearchResultTitle(filteredResults.length, completeResults.length)}
            footer={(!perfectMatch && !completeResults.length) ? (
              <Action
                label="Crea nuovo"
                dataRef="create-contact-action"
                color="primary"
                emphasis="high"
                onClick={createNewContact}
              />
            ) : undefined}
          >
            {showUpdateWarning && (
              <Spacing margin={[0, 0, 200, 0]}>
                <Message
                  type="critical"
                  data-ref="update-warning-message"
                  message={UPDATE_WARNING_MESSAGE}
                />
              </Spacing>
            )}
            {filteredResults.map((result) => (
              <Spacing
                key={result.id}
                margin={[0, 0, 200, 0]}
              >
                <ContactMatchCard
                  contact={result}
                  onSelected={onResultContactSelected}
                  searchValues={searchValues}
                  onUpdate={canBeUpdated(result) ? onResultContactUpdated : undefined}
                />
              </Spacing>
            ))}
          </SimpleModal>
        )}
      </Portal>
    </>
  );
};

export default SelectContact;
