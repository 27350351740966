import {
  ActionIcon, ConfirmModal, ICON_HOME_SWITCH_OUTLINE, Portal, Text, useModal,
  useNotifications,
} from '@doveit/bricks';
import React from 'react';
import { updateProperty } from '../../../providers/api/property/propertyProvider';
import { Property } from '../../../providers/api/dtos';
import { Status } from '../../../domain/types';
import { useComputedPropertyStatus } from '../../hooks/use-computed-property-status/useComputedPropertyStatus';

export interface ChildFnProps {
  isLoading?: boolean,
  onNegotiation: VoidFunction,
}

export interface NegotiationPropertyActionProps {
  property: Property,
  onSuccess?: (updatedProperty: Property) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const PROPOSAL_PROPERTY_SUCCESS_MESSAGE = 'Immobile messo in trattativa con successo';
export const PROPOSAL_PROPERTY_ERROR_MESSAGE = 'Non è stato possibile mettere in trattativa l\'immobile';

const NegotiationPropertyAction: React.FC<NegotiationPropertyActionProps> = ({
  property,
  onSuccess,
  children,
}) => {
  const { id } = property;
  const [isSaving, setIsSaving] = React.useState(false);

  const { data: computedPropertyStatus, isLoading: isComputedPropertyStatusLoading } = useComputedPropertyStatus(property);

  const { addSuccess, addError } = useNotifications();

  const confirmModal = useModal();

  const openConfirmModal = React.useCallback(() => confirmModal.open(), [confirmModal]);

  const onConfirm = React.useCallback(async () => {
    setIsSaving(true);

    try {
      const updatedProperty = await updateProperty(id!, {
        ...property,
        status: Status.PROPOSTA,
      });

      setIsSaving(false);
      confirmModal.close();

      addSuccess(PROPOSAL_PROPERTY_SUCCESS_MESSAGE);

      onSuccess?.(updatedProperty);
    } catch (error) {
      addError(PROPOSAL_PROPERTY_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, confirmModal, id, onSuccess, property]);

  if (!computedPropertyStatus?.canBeUpdatedTo(Status.PROPOSTA)) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          onNegotiation: openConfirmModal,
          isLoading: isSaving || isComputedPropertyStatusLoading,
        })
        : (
          <ActionIcon
            label="Metti in trattativa"
            icon={{ path: ICON_HOME_SWITCH_OUTLINE }}
            onClick={openConfirmModal}
            aria-label="Pulsante per mettere l'immobile in trattativa"
            loading={isSaving || isComputedPropertyStatusLoading}
          />
        )}
      <Portal>
        <ConfirmModal
          {...confirmModal}
          title="Metti in trattativa"
          aria-label="Modale per confermare lo spostamento in trattativa"
          onConfirm={onConfirm}
          onAbort={() => confirmModal.close()}
        >
          <Text.Body>
            Mettendo l’immobile in trattativa, l&apos;annuncio non sarà più visibile sui portali, ma sarà presente sul sito di Dove.it con un banner di trattativa in corso.
          </Text.Body>
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default NegotiationPropertyAction;
