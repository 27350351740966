/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  ActionIcon, ActionIconDropdown, Dropdown, FontWeight, HStack, Icon, ICON_BELL_OUTLINE, ICON_CALENDAR_OUTLINE, ICON_EYE_OUTLINE, ICON_MAP_MARKER_OUTLINE, Popover, Stack, Text, useNotifications, UserPreview,
} from '@doveit/bricks';
import { isFuture } from 'date-fns';
import { formatEuro } from '@doveit/hammer';
import {
  Appointment, Note, Prospect, Reminder, SerpProspect,
} from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import ViewContactPopoverPreview from '../../../contact/containers/view-contact-popover-preview/ViewContactPopoverPreview';
import * as styles from './ViewSerpProspectTableRow.styles';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import UpdateProspectStatus from '../update-prospect-status/UpdateProspectStatus';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import ViewProspectPopoverPreview from '../view-prospect-popover-preview/ViewProspectPopoverPreview';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import ProspectAvatar from '../../components/prospect-avatar/ProspectAvatar';
import {
  AppointmentStatus, ProspectApprovalStatus, ProspectStatus, ReferenceType,
} from '../../../domain/types';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import { computeResolvableStatus } from '../../../utils/reminder/reminder';
import { ResolvableReminderStatus } from '../../../types';
import DateViewer from '../../../components/date-viewer/DateViewer';
import UpdateAppointmentStatus from '../../../reminders/components/update-appointment-status/UpdateAppointmentStatus';
import UpdateProspectApprovalStatus from '../update-prospect-approval-status/UpdateProspectApprovalStatus';
import { goToDetailPage } from '../../../utils/navigate/utils';
import ViewPropertyPopoverPreview from '../../../property/containers/view-property-popover-preview/ViewPropertyPopoverPreview';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import useAssignmentByProspectId from '../../hooks/use-assignment-by-prospect-id/useAssignmentByProspectId';
import useProperty from '../../../property/hooks/use-property/useProperty';
import UpsertReminderAction from '../../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import { prospectMessagesProvider } from '../../../containers/send-whatsapp-action/messages/prospectMessagesProvider';
import { updateProspect } from '../../../providers/api/prospect/prospectProvider';
import { buildWhatsappMessageSentNote } from '../../../intent/utils/buildWhatsappMessageSentNote';
import UpsertAppointmentAction from '../../../appointment/containers/upsert-appointment-action/UpsertAppointmentAction';
import UpsertNoteAction from '../../../notes/containers/UpsertNoteAction';

export const UPSERT_NOTE_ERROR_MESSAGE = 'Non è stato possibile aggiungere la nota';
export const UPSERT_NOTE_SUCCESS_MESSAGE = 'La nota è stata aggiunta con successo';
const MAX_REMINDER_NOTE_LENGTH = 90;

export interface ViewSerpProspectTableRowProps extends React.AriaAttributes {
  serpProspect: SerpProspect,
  onProspectUpdate?: (prospect: Prospect) => void,
  onAppointmentCreate?: (appointment: Appointment) => void,
  onAppointmentUpdate?: (appointment: Appointment) => void,
  onReminderCreate?: (reminder: Reminder) => void,
}

const ViewSerpProspectTableRow: React.FC<ViewSerpProspectTableRowProps> = ({
  serpProspect,
  onProspectUpdate,
  onAppointmentUpdate,
  onAppointmentCreate,
  onReminderCreate,
  ...rest
}) => {
  const {
    agent, appointment, contact, prospect, reminder, lead,
  } = serpProspect;

  const { addSuccess, addError } = useNotifications();

  const { data: assignment } = useAssignmentByProspectId(
    prospect.status === ProspectStatus.ASSIGNMENT_CREATED
      ? prospect.id
      : undefined,
  );

  const { data: property } = useProperty(assignment?.propertyId);

  const {
    user, mainUserRole, userIsAdmin,
  } = useRBAC();

  const userIsProspectAgentOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);

  const reminderToCreate: Reminder = React.useMemo(() => ({
    agentId: agent.id,
    referenceId: prospect.id,
    referenceType: ReferenceType.PROSPECT,
    resolved: false,
  } as Reminder), [agent.id, prospect.id]);

  const appointmentToCreate: Appointment = React.useMemo(() => ({
    agentId: agent.id,
    contactId: prospect.contactId,
    referenceId: prospect.id,
    referenceType: ReferenceType.PROSPECT,
  } as Appointment), [agent?.id, prospect.contactId, prospect.id]);

  const showReminder = React.useMemo(
    () => appointment?.status !== AppointmentStatus.TODO
      && reminder
      && computeResolvableStatus(reminder) === ResolvableReminderStatus.ACTIVE,
    [appointment?.status, reminder],
  );

  const showApprovalStatus = React.useMemo(
    () => (userIsAdmin || userIsProspectAgentOrManager)
      && prospect.agentEvaluation !== undefined
      && prospect.status === ProspectStatus.IN_PROGRESS
      && agent.needsSupervisor, [agent.needsSupervisor, prospect.agentEvaluation, prospect.status, userIsAdmin, userIsProspectAgentOrManager],
  );

  const handleWhatsappSend = React.useCallback(async (message: string) => {
    try {
      const updatedProspect = await updateProspect(prospect.id!, {
        ...prospect,
        notes: [{
          text: buildWhatsappMessageSentNote(message),
          author: user.name,
          role: mainUserRole,
        }, ...(prospect.notes || [])],
      });

      onProspectUpdate?.(updatedProspect);
    } catch (_) { /* empty */ }
  }, [mainUserRole, onProspectUpdate, prospect, user.name]);

  const handleNoteCreated = React.useCallback(async (note: Note) => {
    try {
      const updatedProspect = await updateProspect(prospect.id!, {
        ...prospect,
        notes: [note, ...(prospect.notes || [])],
      });

      onProspectUpdate?.(updatedProspect);
      addSuccess(UPSERT_NOTE_SUCCESS_MESSAGE);
    } catch (err) {
      addError(UPSERT_NOTE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, onProspectUpdate, prospect]);

  const goToProspectPage = React.useCallback(() => {
    goToDetailPage(prospect.id!, ReferenceType.PROSPECT);
  }, [prospect.id]);

  return (
    <SimpleTable.Row {...rest}>
      <SimpleTable.Cell
        aria-label="Contatto"
        width="9rem"
      >
        <ViewContactPopoverPreview
          contact={contact}
          trigger={(
            <div css={styles.userWrapper}>
              <UserPreview
                name={contact.name || NO_VALUE_SYMBOL}
                size="S"
              >
                {contact.phoneNumber}
              </UserPreview>
            </div>
          )}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Immobile"
        width="12rem"
      >
        {!property && (
          <ViewProspectPopoverPreview
            prospect={prospect}
            trigger={(
              <HStack gap={100} wrap="nowrap">
                <ProspectAvatar size="S" />
                <Text.BodySmall
                  aria-label="Indirizzo immobile"
                  fontWeight={FontWeight.MEDIUM}
                  css={setVerticalEllipsis()}
                >
                  {lead.propertyAddress}
                </Text.BodySmall>
              </HStack>
            )}
          />
        )}
        {property && (
          <ViewPropertyPopoverPreview
            property={property}
            trigger={(
              <HStack gap={100} wrap="nowrap">
                <PropertyAvatar
                  size="S"
                  icon={property.overriddenGeo ? ICON_MAP_MARKER_OUTLINE : undefined}
                />
                <Text.BodySmall
                  aria-label="Indirizzo immobile"
                  fontWeight={FontWeight.MEDIUM}
                  css={setVerticalEllipsis()}
                >
                  {property.overriddenGeo?.normalizedAddress || property.geo?.normalizedAddress}
                </Text.BodySmall>
              </HStack>
            )}
            showMatchingAction={userIsAdmin || userIsProspectAgentOrManager}
          />
        )}
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Stato della valutazione"
        width="8.5rem"
      >
        <UpdateProspectStatus
          showAsBadge={!(userIsAdmin || userIsProspectAgentOrManager)}
          prospect={prospect}
          size="XS"
          onSuccess={onProspectUpdate}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell
        aria-label="Agente"
        width="2rem"
      >
        <ViewAgentPopoverPreview
          agent={agent}
          trigger={(
            <AgentPreview
              {...toAgentPreviewProps(agent, { omit: { name: true } })}
              size="S"
              expanded
            />
          )}
        />
      </SimpleTable.Cell>

      {showReminder && reminder && (
        <SimpleTable.Cell
          aria-label="Promemoria"
          withDivider
          width="16rem"
        >
          <HStack gap={100} wrap="nowrap">
            <HStack wrap="nowrap">
              <Icon path={ICON_BELL_OUTLINE} size={20} />
              <div css={styles.dateWrapper}>
                <Stack gap={25}>
                  <Text.Mini transform="uppercase">
                    Data promemoria
                  </Text.Mini>
                  <Text.Caption fontWeight={FontWeight.REGULAR}>
                    <DateViewer
                      stringDate={reminder.startDate}
                      checkIfToday
                      checkIfTomorrow
                    />
                  </Text.Caption>
                </Stack>
              </div>
            </HStack>
            <Popover
              aria-label="Visualizza dettagli promemoria"
              trigger={(
                <div style={{ maxWidth: '16rem' }}>
                  <Text.Caption>
                    {truncateTextWithSuffix(reminder.notes, MAX_REMINDER_NOTE_LENGTH)}
                  </Text.Caption>
                </div>
              )}
              hasArrow
            >
              <div css={styles.noteAndReminderPopoverWrapper}>
                <Text.BodySmall>{reminder.notes}</Text.BodySmall>
              </div>
            </Popover>
          </HStack>
        </SimpleTable.Cell>
      )}

      {!showReminder && (
        <SimpleTable.Cell
          aria-label="Appuntamento"
          withDivider
          width="16rem"
        >
          <HStack gap={100}>
            <HStack wrap="nowrap">
              <Icon path={ICON_CALENDAR_OUTLINE} size={20} />
              <div css={styles.dateWrapper}>
                <Stack gap={25}>
                  <Text.Mini transform="uppercase">
                    Data appuntamento
                  </Text.Mini>
                  <Text.Caption fontWeight={FontWeight.REGULAR}>
                    {appointment ? (
                      <DateViewer
                        stringDate={appointment.startDate}
                        checkIfToday
                        checkIfTomorrow
                        withHour={isFuture(appointment.startDate)}
                      />
                    ) : 'Nessuno'}
                  </Text.Caption>
                </Stack>
              </div>
            </HStack>
            {appointment && (
              <UpdateAppointmentStatus
                appointment={appointment}
                onSuccess={onAppointmentUpdate}
                showAsBadge={!(userIsAdmin || userIsProspectAgentOrManager)}
              />
            )}
          </HStack>
        </SimpleTable.Cell>
      )}

      <SimpleTable.Cell>
        {prospect.agentEvaluation !== undefined && (
          <HStack
            aria-label="Valutazione"
            gap={100}
            wrap="nowrap"
          >
            <Stack gap={25}>
              <Text.Mini transform="uppercase">
                Valutazione
              </Text.Mini>
              <Text.Caption
                fontWeight={FontWeight.MEDIUM}
                color={prospect.approval && prospect.approval.status !== ProspectApprovalStatus.APPROVED
                  ? 'critical.default.high'
                  : 'neutral.default.high'}
              >
                {formatEuro(prospect.agentEvaluation)}
              </Text.Caption>
            </Stack>
            {showApprovalStatus && (
              <UpdateProspectApprovalStatus
                prospect={prospect}
                onSuccess={onProspectUpdate}
                size="XS"
              />
            )}
          </HStack>
        )}
      </SimpleTable.Cell>
      <SimpleTable.Cell
        aria-label="Azioni"
        align="right"
      >
        <HStack
          gap={75}
          wrap="nowrap"
        >
          <ActionIcon
            aria-label="Vai alla pagina della valutazione"
            label="Visualizza"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToProspectPage}
            size="S"
          />
          {(userIsAdmin || userIsProspectAgentOrManager) && (
            <ActionIconDropdown
              aria-label="Visualizza azioni secondarie"
              label="Azioni"
              size="S"
              emphasis="low"
            >
              {contact.phoneNumber && (
                <SendWhatsappAction
                  phoneNumber={contact.phoneNumber}
                  name={contact.name}
                  fetchMessages={prospectMessagesProvider(prospect, agent)}
                  onMessageSent={handleWhatsappSend}
                >
                  {({ onClick }) => (
                    <Dropdown.Option
                      label="Invia whatsapp"
                      onClick={onClick}
                    />
                  )}
                </SendWhatsappAction>
              )}
              <UpsertReminderAction
                reminder={reminderToCreate}
                onSuccess={onReminderCreate}
              >
                {({ upsert }) => (
                  <Dropdown.Option
                    label="Aggiungi promemoria"
                    onClick={upsert}
                  />
                )}
              </UpsertReminderAction>
              <UpsertAppointmentAction
                appointment={appointmentToCreate}
                onSuccess={onAppointmentCreate}
              >
                {({ upsert }) => (
                  <Dropdown.Option
                    label="Aggiungi appuntamento"
                    onClick={upsert}
                  />
                )}
              </UpsertAppointmentAction>
              <UpsertNoteAction upsertNote={handleNoteCreated}>
                {({ upsert }) => (
                  <Dropdown.Option
                    label="Aggiungi nota"
                    onClick={upsert}
                  />
                )}
              </UpsertNoteAction>
            </ActionIconDropdown>
          )}
        </HStack>
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default ViewSerpProspectTableRow;
