import hash from 'object-hash';
import useSWR from 'swr';
import { SWRCustomResponse } from '../../../hooks/types';
import { searchContacts } from '../../../providers/api/contact/contactProvider';
import { Contact } from '../../../providers/api/dtos/contact';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';

export const SEARCH_CONTACTS_RID = '/contacts/v2/search';

export default function useSearchContacts(
  query?: string,
  pagination: PaginationParams = {},
): SWRCustomResponse<PaginatedResult<Contact>, Error> {
  const matchMutate = useMatchMutate();
  const key = query ? [SEARCH_CONTACTS_RID, hash({ query, ...pagination })] : null;
  const result = useSWR(key, () => searchContacts(query!, pagination));

  return {
    ...result,
    mutate: () => matchMutate(SEARCH_CONTACTS_RID),
  };
}
