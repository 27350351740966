import * as Yup from 'yup';

export const jobApplicationValidationSchema = Yup.object({
  jobPosition: Yup
    .object({
      id: Yup.string().required('Il ruolo è richiesto'),
    })
    .notRequired(),
});

export const salesJobApplicationValidationSchema = jobApplicationValidationSchema.shape({
  license: Yup.string()
    .required('Il campo patentino è richiesto'),
  agency: Yup.string()
    .required('Il campo agenzia è richiesto'),
});
