import React from 'react';
import {
  ActionIcon,
  FontWeight, HStack, ICON_EYE_OUTLINE, Stack, Text, UserPreview, UserPreviewSkeleton,
} from '@doveit/bricks';
import { Rumor } from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import RumorAvatar from '../../../rumor/components/rumor-avatar/RumorAvatar';
import UpdateRumorStatus from '../../../rumor/containers/update-rumor-status/UpdateRumorStatus';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import ViewRumorPopoverPreview from '../../../rumor/containers/view-rumor-popover-preview/ViewRumorPopoverPreview';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';

export interface ViewContactRumorTableRowProps extends React.AriaAttributes {
  rumor: Rumor,
  onRumorUpdate?: (updatedRumor: Rumor) => void,
}

const ViewContactRumorTableRow: React.FC<ViewContactRumorTableRowProps> = ({
  rumor,
  onRumorUpdate,
  ...rest
}) => {
  const {
    data: agent,
    isLoading: isAgentLoading,
    error: agentError,
  } = useAgent(rumor.agentId);

  const { userIsAdmin, userIsCallCenter, userIsAgent } = useRBAC();

  const canViewAction = React.useMemo(() => userIsAdmin || userIsCallCenter || userIsAgent, [userIsAdmin, userIsAgent, userIsCallCenter]);

  const goToRumorPage = React.useCallback(() => {
    goToDetailPage(rumor.id!, ReferenceType.RUMOR);
  }, [rumor.id]);

  return (
    <SimpleTable.Row aria-label="Informazioni notizia" {...rest}>
      <SimpleTable.Cell aria-label="Indirizzo immobile">
        <ViewRumorPopoverPreview
          rumorId={rumor.id!}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <RumorAvatar size="S" />
              <Text.BodySmall fontWeight={FontWeight.MEDIUM} css={setVerticalEllipsis()}>
                {rumor.propertyAddress || 'Indirizzo non disponibile'}
              </Text.BodySmall>
            </HStack>
          )}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Stato notizia">
        <UpdateRumorStatus
          rumor={rumor}
          size="XS"
          onSuccess={onRumorUpdate}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell width="10rem" aria-label="Informazioni agente">
        {isAgentLoading && (
          <UserPreviewSkeleton
            size="S"
            aria-label="Agente in caricamento"
          />
        )}
        {agentError && (
          <UserPreview
            size="S"
            name="Errore agente"
          />
        )}
        {agent && (
          <ViewAgentPopoverPreview
            agent={agent}
            trigger={(
              <AgentPreview
                {...toAgentPreviewProps(agent)}
                size="S"
              />
            )}
          />
        )}
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Data di creazione">
        <Stack gap={50}>
          <Text.Mini transform="uppercase">
            Aggiunta
          </Text.Mini>
          <Text.Caption>
            <DateViewer
              stringDate={rumor.createdAt!}
              checkIfToday
            />
          </Text.Caption>
        </Stack>
      </SimpleTable.Cell>

      <SimpleTable.Cell aria-label="Data di aggiornamento">
        <Stack gap={50}>
          <Text.Mini transform="uppercase">
            Aggiornata
          </Text.Mini>
          <Text.Caption>
            <DateViewer
              stringDate={rumor.updatedAt!}
              checkIfToday
            />
          </Text.Caption>
        </Stack>
      </SimpleTable.Cell>

      {canViewAction && (
        <SimpleTable.Cell
          aria-label="Azioni"
          align="right"
        >
          <ActionIcon
            aria-label="Visualizza notizia"
            label="Visualizza"
            size="S"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToRumorPage}
          />
        </SimpleTable.Cell>
      )}
    </SimpleTable.Row>
  );
};

export default ViewContactRumorTableRow;
