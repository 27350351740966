import { useNotifications } from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  Department, JobApplicationStatus, OptInSource, OptInType,
} from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { createApplicant } from '../../../providers/api/applicant/applicantProvider';
import { Applicant, JobApplication, JobPosition } from '../../../providers/api/dtos';
import { createJobApplication } from '../../../providers/api/job-application/jobApplicationProvider';
import JobApplicationWithApplicantForm, { JobApplicationWithApplicantFormModel } from '../../components/job-application-with-applicant-form/JobApplicationWithApplicantForm';
import { booleanStringToBool } from '../../../utils/boolean-string/booleanString';
import { UserRole } from '../../../types';

export interface CreateJobApplicationWithApplicantProps {
  onSuccess?: (applicant: Applicant, jobApplication: JobApplication) => void,
}

export const JOB_POSITION_LOAD_ERROR = 'Non è stato possibile caricare il ruolo';
export const JOB_APPLICATION_WITH_APPLICANT_SUCCESS_MESSAGE = 'Candidatura aggiunta correttamente';
export const JOB_APPLICATION_WITH_APPLICANT_ERROR_MESSAGE = 'Impossibile aggiungere la candidatura';

export const AGENT_SOURCE = 'referral';
export const AGENT_ALLOWED_JOB_POSITIONS: Omit<JobPosition, 'id'>[] = [
  { name: 'Agente Immobiliare', department: Department.SALES },
  { name: 'Consulente Immobiliare', department: Department.SALES },
];
export const AGENT_JOB_POSITIONS_FILTER = (jobPosition: JobPosition) => AGENT_ALLOWED_JOB_POSITIONS.some(
  (allowedJobPosition) => allowedJobPosition.name.toLowerCase() === jobPosition.name.toLowerCase() && allowedJobPosition.department === jobPosition.department,
);

const CreateJobApplicationWithApplicant: FunctionComponent<CreateJobApplicationWithApplicantProps> = ({
  onSuccess,
}) => {
  const { user, mainUserRole } = useRBAC();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = useState(false);

  const initialValues: Partial<JobApplicationWithApplicantFormModel> = React.useMemo(() => {
    if (mainUserRole === UserRole.AGENT) {
      return {
        source: AGENT_SOURCE,
        note: `Referral ${user.name}`,
      };
    }

    return {};
  }, [mainUserRole, user]);

  const onSubmit = useCallback(async (values: JobApplicationWithApplicantFormModel) => {
    try {
      setIsSaving(true);

      const newApplicant: Applicant = {
        name: values.name,
        email: values.email || undefined,
        phoneNumber: values.phoneNumber || undefined,
        linkedIn: values.linkedIn || undefined,
        optIns: [{
          contactId: 'a-contact-id',
          type: OptInType.SERVICE,
          sourceType: OptInSource.BACKOFFICE,
          sourceValue: 'dove.it',
          givenAt: new Date().toISOString(),
        }],
      };

      const savedApplicant = await createApplicant(newApplicant);

      const newJobApplication: JobApplication = {
        applicantId: savedApplicant.id!,
        jobPositionId: values.jobPosition!.id,
        notes: values.note ? [{
          date: new Date().toISOString(),
          role: mainUserRole!,
          author: user.name,
          text: values.note,
        }] : [],
        source: mainUserRole === UserRole.AGENT ? AGENT_SOURCE : values.source,
        experience: values.experience || undefined,
        status: JobApplicationStatus.IN_PROGRESS,
        province: values.province || undefined,
        agency: booleanStringToBool(values.agency),
        license: booleanStringToBool(values.license),
      };

      const savedJobApplication = await createJobApplication(newJobApplication);

      setIsSaving(false);
      addSuccess(JOB_APPLICATION_WITH_APPLICANT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(savedApplicant, savedJobApplication);
      }
    } catch (err) {
      setIsSaving(false);
      addError(JOB_APPLICATION_WITH_APPLICANT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, mainUserRole, onSuccess, user.name]);

  return (
    <JobApplicationWithApplicantForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      loading={isSaving}
      disableSource={mainUserRole === UserRole.AGENT}
      filterJobPositions={mainUserRole === UserRole.AGENT ? AGENT_JOB_POSITIONS_FILTER : undefined}
    />
  );
};

export default CreateJobApplicationWithApplicant;
