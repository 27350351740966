import {
  ICON_ALERT_CIRCLE_OUTLINE, ICON_CHECK_CIRCLE_OUTLINE, ICON_PROGRESS_CHECK,
} from '@doveit/bricks';
import { BadgeColor } from '@doveit/bricks/dist/typings/components/badge/types';
import { ComputedProspectStatus } from '../../hooks/use-computed-prospect-status/useComputedProspectStatus';

export const computedProspectStatusActionIcon: Record<ComputedProspectStatus, string> = {
  APPOINTMENT: ICON_PROGRESS_CHECK,
  ONLY_EVALUATION: ICON_PROGRESS_CHECK,
  APPOINTMENT_CANCELLED: ICON_ALERT_CIRCLE_OUTLINE,
  ASSIGNMENT_CREATED: ICON_CHECK_CIRCLE_OUTLINE,
  OVER_PRICED: ICON_ALERT_CIRCLE_OUTLINE,
  UNSELLABLE: ICON_ALERT_CIRCLE_OUTLINE,
  NOT_INTERESTED: ICON_ALERT_CIRCLE_OUTLINE,
  SOLD: ICON_ALERT_CIRCLE_OUTLINE,
  NO_STATUS: '',
};

export const computedProspectStatusActionColors: Record<ComputedProspectStatus, BadgeColor> = {
  APPOINTMENT: 'info',
  ONLY_EVALUATION: 'critical',
  APPOINTMENT_CANCELLED: 'warning',
  ASSIGNMENT_CREATED: 'success',
  NOT_INTERESTED: 'critical',
  OVER_PRICED: 'critical',
  UNSELLABLE: 'critical',
  SOLD: 'critical',
  NO_STATUS: 'info',
};
