import useSWR, { SWRResponse, SWRConfiguration } from 'swr';
import { AxiosError } from 'axios';
import { Signature, Prospect } from '../../../providers/api/dtos';
import { API_ASSIGNMENTS_PATH } from '../../../providers/api/api';
import { getAssignmentSignatureStatus } from '../../../providers/api/assignment/assignmentProvider';

export const ASSIGNMENT_SIGNATURE_STATUS_RID = `${API_ASSIGNMENTS_PATH}/from-prospect/wizard/signature-status`;

export default function useAssignmentSignatureStatus(
  prospectId?: NonNullable<Prospect['id']>,
  options?: SWRConfiguration<Signature, AxiosError>,
): SWRResponse<Signature, AxiosError> {
  const key = prospectId ? [ASSIGNMENT_SIGNATURE_STATUS_RID, prospectId] : null;

  return useSWR(key, () => getAssignmentSignatureStatus(prospectId!), options);
}
