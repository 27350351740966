import React from 'react';
import { noop } from '../../utils';

export interface CardContextValue {
  isCollapsed: boolean,
  compact: boolean,
  setCollapsed: (value: boolean) => void,
}

export const CardContext = React.createContext<CardContextValue>({
  isCollapsed: false,
  compact: false,
  setCollapsed: noop,
});

export const useCardContext = () => {
  const context = React.useContext(CardContext);

  if (!context) {
    throw new Error('useCardContext must be used within a CardContextProvider');
  }

  return context;
};
