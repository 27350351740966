import {
  FontWeight,
  ThemedFunctor, TypographyName, getTypography,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const th: ThemedFunctor = ({ theme, $align = 'left', $width = 'auto' }) => css`
  ${getTypography(theme, TypographyName.BODY, { fontWeight: FontWeight.REGULAR, lineHeight: '1' })}
  padding: ${theme.remSize[150]} ${theme.remSize[100]};
  text-align: ${$align};
  vertical-align: middle;
  display: table-cell;
  width: ${$width};

  &:first-child {
    padding-left: ${theme.remSize[150]};
  }

  &:last-child {
    padding-right: ${theme.remSize[150]};
  }
`;
