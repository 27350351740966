/* eslint-disable react/no-unknown-property */
/* eslint-disable import/no-duplicates */
import {
  Portal,
  Form,
  FormHandlers,
  useModal,
  Message,
  Action,
  Spacing,
  ICON_CALENDAR_OUTLINE,
  HStack,
} from '@doveit/bricks';
import React, {
  useState, useCallback, FunctionComponent, useRef,
} from 'react';
import { mutate } from 'swr';
import {
  addWeeks, format, setHours, startOfHour, addMonths, formatDistance, isFuture,
} from 'date-fns';
import { it } from 'date-fns/locale';
import { saveCallBackDate } from '../../../providers/api/call-center/callCenterProvider';
import { formDateTimeToDate } from '../../../utils/form';
import schema from './CallbackButton.schema';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { CONTACT_RID } from '../../../contact/hooks/use-contact/useContact';

const ERROR_MESSAGE = 'Non è stato possibile aggiornare la data di prossimo contatto.';

export interface CallBackButtonProps extends React.AriaAttributes {
  contactId: string,
  disabled?: boolean,
  onSuccess?: (dateTime: string) => void,
  onError?: (error: Error) => void,
}

const CallBackButton: FunctionComponent<CallBackButtonProps> = ({
  contactId,
  disabled = false,
  onSuccess,
  onError,
  ...rest
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [hasError, setHasError] = useState(false);
  const modal = useModal();
  const formRef = useRef<FormHandlers>() as React.MutableRefObject<FormHandlers>;

  const openModal = useCallback(() => modal.open(), [modal]);
  const closeModal = useCallback(() => modal.close(), [modal]);
  const onSubmitClick = useCallback(() => formRef.current.handleSubmit(), []);

  const onSubmit = async ({ date, time }: { date: string, time: string }) => {
    setIsSaving(true);
    setHasError(false);

    try {
      await saveCallBackDate(contactId, formDateTimeToDate(date, time));

      setIsSaving(false);
      closeModal();

      if (onSuccess) {
        onSuccess(`${date} ${time}`);
      }

      mutate([CONTACT_RID, contactId]);
    } catch (err: any) {
      setIsSaving(false);
      setHasError(true);
      if (onError) {
        onError(err);
      }
    }
  };

  return (
    <>
      <Action
        {...rest}
        label="Da richiamare"
        emphasis="high"
        iconLeft={{ path: ICON_CALENDAR_OUTLINE }}
        disabled={disabled}
        onClick={openModal}
      />
      <Portal>
        <SimpleModal
          title="A che ora vuoi richiamare il contatto?"
          {...modal}
          footer={(
            <Action
              label="Salva"
              loading={isSaving}
              color="primary"
              emphasis="high"
              onClick={onSubmitClick}
            />
          )}
        >
          <Form
            onSubmit={onSubmit}
            innerRef={formRef}
            initialValues={{
              date: format(new Date(), 'yyyy-MM-dd'),
              time: '',
            }}
            loading={isSaving}
            validationSchema={schema}
            preventEnterKeypress
          >
            {({ values, setFieldValue }) => {
              const setDatetime = (datetime: Date) => {
                setFieldValue('date', format(datetime, 'yyyy-MM-dd'));
                setFieldValue('time', format(datetime, 'HH:mm'));
              };

              return (
                <>
                  <HStack>
                    <Action
                      onClick={() => setDatetime(startOfHour(setHours(new Date(), 18)))}
                      label="Stasera"
                      size="S"
                      emphasis="high"
                      data-ref="this-evening-button"
                    />
                    <Action
                      onClick={() => setDatetime(addWeeks(new Date(), 2))}
                      label="Tra due settimane"
                      size="S"
                      emphasis="high"
                      data-ref="two-weeks-button"
                    />
                    <Action
                      onClick={() => setDatetime(addMonths(new Date(), 1))}
                      label="Tra un mese"
                      size="S"
                      emphasis="high"
                      data-ref="one-month-button"
                    />
                  </HStack>
                  <Form.Group label="Seleziona data e ora per richiamare il contatto">
                    <Form.Item size={1 / 2}>
                      <Form.Input
                        name="date"
                        type="date"
                        aria-label="Campo per inserire la data"
                        required
                      />
                    </Form.Item>
                    <Form.Item size={1 / 2}>
                      <Form.Input
                        type="time"
                        aria-label="Campo per inserire l'ora"
                        name="time"
                        required
                      />
                    </Form.Item>
                    {isFuture(new Date(`${values.date} ${values.time}`)) && (
                      <Form.Item>
                        <div data-ref="recall-label">
                          Prossima chiamata tra: <strong>{formatDistance(new Date(), new Date(`${values.date} ${values.time}`), { locale: it })}</strong>
                        </div>
                      </Form.Item>
                    )}
                  </Form.Group>

                  {hasError && (
                    <Spacing margin={[200, 0, 0]}>
                      <Message
                        type="critical"
                        message={ERROR_MESSAGE}
                      />
                    </Spacing>
                  )}
                </>
              );
            }}
          </Form>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default CallBackButton;
