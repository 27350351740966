/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Form, FormHandlers, FormProps, Grid, HStack, Link, Spacing,
} from '@doveit/bricks';
import React from 'react';
import { AddressSuggestion } from '../../../providers/geo/dtos';
import { INPUT_PLACEHOLDERS } from '../../../constants';
import AddressSuggestionAutoComplete from '../../../components/address-suggestion-autocomplete/AddressSuggestionAutoComplete';
import { geocodeByAddress } from '../../../providers/geo/geocode/geocodeProvider';
import { isKeyOf } from '../../../utils/object/object';
import editAgentRegistryValidationSchema from './EditAgentRegistryForm.schema';

export interface EditAgentRegistryFormModel {
  workName: string,
  personalName: string,
  workSurname: string,
  personalSurname: string,
  workEmail: string,
  personalEmail: string,
  pecEmail: string,
  workMobile: string,
  personalMobile: string,
  fiscalCode: string,
  residenceAddress: {
    administrativeAreaLevelOne?: string,
    administrativeAreaLevelTwo: string,
    locality: string,
    plateCode: string,
    postalCode: string,
    route: string,
    streetNumber: string,
    latitude?: string,
    longitude?: string,
    normalizedAddress?: AddressSuggestion,
  },
  shippingAddress: {
    administrativeAreaLevelOne?: string,
    administrativeAreaLevelTwo: string,
    locality: string,
    plateCode: string,
    postalCode: string,
    route: string,
    streetNumber: string,
    latitude?: string,
    longitude?: string,
    normalizedAddress?: AddressSuggestion,
  },
}

export interface EditAgentRegistryFormProps {
  initialValues?: Partial<EditAgentRegistryFormModel>,
  loading?: FormProps<EditAgentRegistryFormModel>['loading'],
  disabled?: FormProps<EditAgentRegistryFormModel>['disabled'],
  innerRef?: React.MutableRefObject<FormHandlers>,
  onSubmit: FormProps<EditAgentRegistryFormModel>['onSubmit'],
}

export const EDIT_AGENT_REGISTRY_FORM_DEFAULT_VALUES: EditAgentRegistryFormModel = {
  workName: '',
  personalName: '',
  workSurname: '',
  personalSurname: '',
  workEmail: '',
  personalEmail: '',
  pecEmail: '',
  workMobile: '',
  personalMobile: '',
  fiscalCode: '',
  residenceAddress: {
    administrativeAreaLevelOne: '',
    administrativeAreaLevelTwo: '',
    locality: '',
    plateCode: '',
    postalCode: '',
    route: '',
    streetNumber: '',
    latitude: '',
    longitude: '',
  },
  shippingAddress: {
    administrativeAreaLevelOne: '',
    administrativeAreaLevelTwo: '',
    locality: '',
    plateCode: '',
    postalCode: '',
    route: '',
    streetNumber: '',
    latitude: '',
    longitude: '',
  },
};

const GEO_FIELDS = [
  'administrativeAreaLevelOne',
  'administrativeAreaLevelTwo',
  'locality',
  'postalCode',
  'plateCode',
  'route',
  'streetNumber',
  'latitude',
  'longitude',
];

const EditAgentRegistryForm: React.FC<EditAgentRegistryFormProps> = (props) => {
  const { initialValues = {}, innerRef, ...rest } = props;

  const [
    showResidenceAddressGeoFields,
    setShowResidenceAddressGeoFields,
  ] = React.useState(
    !initialValues.residenceAddress?.latitude
    && !initialValues.residenceAddress?.longitude
    && !!initialValues.residenceAddress?.normalizedAddress,
  );
  const [
    showShippingAddressGeoFields,
    setShowShippingAddressGeoFields,
  ] = React.useState(
    !initialValues.shippingAddress?.latitude
    && !initialValues.shippingAddress?.longitude
    && !!initialValues.shippingAddress?.normalizedAddress,
  );

  const mergedValues: EditAgentRegistryFormModel = React.useMemo(() => ({
    ...EDIT_AGENT_REGISTRY_FORM_DEFAULT_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedValues}
      validationSchema={editAgentRegistryValidationSchema}
      enableReinitialize
      innerRef={innerRef}
      {...rest}
    >
      {({ setFieldValue }) => {
        const onResidenceAddressChange = async (suggestion: AddressSuggestion | null) => {
          GEO_FIELDS.forEach((field) => {
            setFieldValue(`residenceAddress.${field}`, '');
          });

          if (!suggestion) return;

          const { description } = suggestion;
          const [geoCode] = await geocodeByAddress(description);

          GEO_FIELDS.forEach((field) => {
            if (isKeyOf(geoCode, field)) {
              setFieldValue(`residenceAddress.${field}`, geoCode[field] || '');
            }
          });
        };

        const onShippingAddressChange = async (suggestion: AddressSuggestion | null) => {
          GEO_FIELDS.forEach((field) => {
            setFieldValue(`shippingAddress.${field}`, '');
          });

          if (!suggestion) return;

          const { description } = suggestion;
          const [geoCode] = await geocodeByAddress(description);

          GEO_FIELDS.forEach((field) => {
            if (isKeyOf(geoCode, field)) {
              setFieldValue(`shippingAddress.${field}`, geoCode[field] || '');
            }
          });
        };

        const toggleResidenceAddressGeoFieldsBox = (open: boolean) => () => {
          setShowResidenceAddressGeoFields(open);
          setFieldValue('residenceAddress.normalizedAddress', null);

          GEO_FIELDS.forEach((field) => {
            setFieldValue(`residenceAddress.${field}`, '');
          });
        };

        const toggleShippingAddressGeoFieldsBox = (open: boolean) => () => {
          setShowShippingAddressGeoFields(open);
          setFieldValue('shippingAddress.normalizedAddress', null);

          GEO_FIELDS.forEach((field) => {
            setFieldValue(`shippingAddress.${field}`, '');
          });
        };

        return (
          <>
            <Form.Fieldset legend="Nome e cognome">
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="workName"
                  label="Nome"
                  aria-label="Campo per inserire il nome lavorativo dell'agente"
                  placeholder={INPUT_PLACEHOLDERS.NAME}
                  required
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="workSurname"
                  label="Cognome"
                  aria-label="Campo per inserire il cognome lavorativo dell'agente"
                  placeholder={INPUT_PLACEHOLDERS.SURNAME}
                  required
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="personalName"
                  label="Nome reale"
                  aria-label="Campo per inserire il nome reale dell'agente"
                  placeholder={INPUT_PLACEHOLDERS.NAME}
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="personalSurname"
                  label="Cognome reale"
                  aria-label="Campo per inserire il cognome reale dell'agente"
                  placeholder={INPUT_PLACEHOLDERS.SURNAME}
                />
              </Form.Item>
            </Form.Fieldset>

            <Form.Fieldset legend="Telefono">
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="workMobile"
                  label="Lavorativo"
                  aria-label="Campo per inserire il telefono lavorativo dell'agente"
                  placeholder={INPUT_PLACEHOLDERS.CONTACT_PHONE_NUMBER}
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="personalMobile"
                  label="Personale"
                  aria-label="Campo per inserire il telefono personale dell'agente"
                  placeholder={INPUT_PLACEHOLDERS.CONTACT_PHONE_NUMBER}
                  required
                />
              </Form.Item>
            </Form.Fieldset>

            <Form.Fieldset legend="Email">
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="workEmail"
                  label="Lavorativa"
                  aria-label="Campo per inserire l'email lavorativa dell'agente"
                  placeholder={INPUT_PLACEHOLDERS.DOVE_IT_EMAIL}
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="personalEmail"
                  label="Personale"
                  aria-label="Campo per inserire l'email personale dell'agente"
                  placeholder={INPUT_PLACEHOLDERS.CONTACT_EMAIL}
                  required
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="pecEmail"
                  label="PEC"
                  aria-label="Campo per inserire la PEC dell'agente"
                  placeholder={INPUT_PLACEHOLDERS.PEC_EMAIL}
                />
              </Form.Item>
            </Form.Fieldset>

            <Form.Fieldset legend="Indirizzi">
              {/**
               * @todo move this logic in a dedicated component and replace it here
               * @see https://doveit.atlassian.net/browse/DOVE-11881
               */}
              <Form.Item>
                <Grid gutter={75}>
                  <Grid.Unit size={{ MD: 2 / 3 }}>
                    <AddressSuggestionAutoComplete
                      name="residenceAddress.normalizedAddress"
                      aria-label="Campo per inserire l'indirizzo di residenza dell'agente"
                      label="Residenza"
                      placeholder={INPUT_PLACEHOLDERS.RESIDENCE_ADDRESS}
                      onSuggestionSelected={onResidenceAddressChange}
                      disabled={showResidenceAddressGeoFields}
                    />
                    <Spacing margin={[100, 0, 0]}>
                      <HStack>
                        {showResidenceAddressGeoFields ? ' Vuoi cercare un nuovo indirizzo?' : 'Non hai trovato l\'indirizzo?'}
                        <Link
                          role="button"
                          aria-label={showResidenceAddressGeoFields ? 'Cerca un nuovo indirizzo' : 'Inserisci l\'indirizzo manualmente'}
                          onClick={toggleResidenceAddressGeoFieldsBox(!showResidenceAddressGeoFields)}
                        >
                          <strong>Clicca qui</strong>
                        </Link>
                      </HStack>
                    </Spacing>
                  </Grid.Unit>
                </Grid>
              </Form.Item>
              {showResidenceAddressGeoFields && (
                <>
                  <Form.Item size={{ MD: 2 / 3 }}>
                    <Form.Input
                      name="residenceAddress.route"
                      label="Strada"
                      aria-label="Campo per inserire la strada dell'indirizzo di residenza"
                      placeholder={INPUT_PLACEHOLDERS.PROPERTY_ROUTE}
                    />
                  </Form.Item>
                  <Form.Item size={{ MD: 1 / 3 }}>
                    <Form.Input
                      name="residenceAddress.streetNumber"
                      label="Numero civico"
                      aria-label="Campo per inserire il numero civico dell'indirizzo di residenza"
                      placeholder={INPUT_PLACEHOLDERS.PROPERTY_STREET_NUMBER}
                    />
                  </Form.Item>
                  <Form.Item size={{ MD: 2 / 3 }}>
                    <Form.Input
                      name="residenceAddress.locality"
                      label="Comune"
                      aria-label="Campo per inserire il comune dell'indirizzo di residenza"
                      placeholder={INPUT_PLACEHOLDERS.PROPERTY_LOCALITY}
                    />
                  </Form.Item>
                  <Form.Item size={{ MD: 1 / 3 }}>
                    <Form.Input
                      name="residenceAddress.plateCode"
                      label="Provincia (sigla)"
                      aria-label="Campo per inserire la provincia dell'indirizzo di residenza"
                      placeholder={INPUT_PLACEHOLDERS.PROPERTY_PLATE_CODE}
                      minLength={2}
                      maxLength={2}
                    />
                  </Form.Item>
                </>
              )}

              {/**
               * @todo move this logic in a dedicated component and replace it here
               * @see https://doveit.atlassian.net/browse/DOVE-11881
               */}
              <Form.Item>
                <Grid gutter={75}>
                  <Grid.Unit size={{ MD: 2 / 3 }}>
                    <AddressSuggestionAutoComplete
                      name="shippingAddress.normalizedAddress"
                      aria-label="Campo per inserire l'indirizzo di spedizione dell'agente"
                      label="Invio materiali"
                      placeholder={INPUT_PLACEHOLDERS.RESIDENCE_ADDRESS}
                      onSuggestionSelected={onShippingAddressChange}
                      disabled={showShippingAddressGeoFields}
                    />
                    <Spacing margin={[100, 0, 0]}>
                      <HStack>
                        {showShippingAddressGeoFields ? ' Vuoi cercare un nuovo indirizzo?' : 'Non hai trovato l\'indirizzo?'}
                        <Link
                          role="button"
                          aria-label={showShippingAddressGeoFields ? 'Cerca un nuovo indirizzo' : 'Inserisci l\'indirizzo manualmente'}
                          onClick={toggleShippingAddressGeoFieldsBox(!showShippingAddressGeoFields)}
                        >
                          <strong>Clicca qui</strong>
                        </Link>
                      </HStack>
                    </Spacing>
                  </Grid.Unit>
                </Grid>
              </Form.Item>
              {showShippingAddressGeoFields && (
                <>
                  <Form.Item size={{ MD: 2 / 3 }}>
                    <Form.Input
                      name="shippingAddress.route"
                      label="Strada"
                      aria-label="Campo per inserire la strada dell'indirizzo di spedizione"
                      placeholder={INPUT_PLACEHOLDERS.PROPERTY_ROUTE}
                    />
                  </Form.Item>
                  <Form.Item size={{ MD: 1 / 3 }}>
                    <Form.Input
                      name="shippingAddress.streetNumber"
                      label="Numero civico"
                      aria-label="Campo per inserire il numero civico dell'indirizzo di spedizione"
                      placeholder={INPUT_PLACEHOLDERS.PROPERTY_STREET_NUMBER}
                    />
                  </Form.Item>
                  <Form.Item size={{ MD: 2 / 3 }}>
                    <Form.Input
                      name="shippingAddress.locality"
                      label="Comune"
                      aria-label="Campo per inserire il comune dell'indirizzo di spedizione"
                      placeholder={INPUT_PLACEHOLDERS.PROPERTY_LOCALITY}
                      required
                    />
                  </Form.Item>
                  <Form.Item size={{ MD: 1 / 3 }}>
                    <Form.Input
                      name="shippingAddress.plateCode"
                      label="Provincia (sigla)"
                      aria-label="Campo per inserire la provincia dell'indirizzo di spedizione"
                      placeholder={INPUT_PLACEHOLDERS.PROPERTY_PLATE_CODE}
                      minLength={2}
                      maxLength={2}
                    />
                  </Form.Item>
                </>
              )}
            </Form.Fieldset>

            <Form.Fieldset legend="Codice fiscale">
              <Form.Item size={{ MD: 2 / 3 }}>
                <Form.Input
                  name="fiscalCode"
                  aria-label="Campo per inserire il codice fiscale dell'agente"
                  placeholder={INPUT_PLACEHOLDERS.CONTACT_FISCALCODE}
                />
              </Form.Item>
            </Form.Fieldset>
          </>
        );
      }}
    </Form>
  );
};

export default EditAgentRegistryForm;
