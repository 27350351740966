import React, { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Action, Spacing, Text } from '@doveit/bricks';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { Contact } from '../../../providers/api/dtos/contact';
import { PageType } from '../../../call-center/types';
import ContactTopBar from '../../../call-center/components/contact-top-bar/ContactTopBar';
import CreateLead from '../../containers/create-lead/CreateLead';
import useFilters, { Filters } from '../../../hooks/use-filters/useFilters';
import CreateProspectByLead from '../../../prospect/containers/create-prospect-by-lead/CreateProspectByLead';
import { Lead, Prospect } from '../../../providers/api/dtos';
import Divider from '../../../components/divider/Divider';

interface CreateLeadPageFilters extends Filters {
  contactId?: string;
}

const CreateLeadPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { filters: { contactId } } = useFilters<CreateLeadPageFilters>();
  const [leadContact, setLeadContact] = React.useState<Contact | undefined>();
  const [lead, setLead] = React.useState<Lead | undefined>();

  const onAppointmentStepSkipped = useCallback(() => {
    if (leadContact) {
      navigate(`/call-center/contacts/${leadContact.id}`);
    }
  }, [navigate, leadContact]);

  const onLeadCreated = useCallback((contact: Contact, createdLead: Lead) => {
    setLeadContact(contact);
    setLead(createdLead);
  }, []);

  const onProspectWithAppointmentCreated = useCallback((prospect: Prospect) => {
    navigate(`/prospects/${prospect.id}`);
  }, [navigate]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Bar>
        <ContactTopBar page={PageType.ADD} />
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent>
          {!lead && (
            <CreateLead
              contactId={contactId}
              onSuccess={onLeadCreated}
            />
          )}
          {lead && (
            <>
              <Action
                label="No, fissa l'appuntamento in seguito"
                color="neutral"
                onClick={onAppointmentStepSkipped}
                emphasis="high"
              />

              <Spacing margin={[400, 0]}>
                <Divider>Oppure</Divider>
              </Spacing>

              <Text.H3>Aggiungi un appuntamento</Text.H3>

              <Spacing margin={[400, 0]}>
                <CreateProspectByLead
                  lead={lead}
                  onSuccess={onProspectWithAppointmentCreated}
                />
              </Spacing>
            </>
          )}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default CreateLeadPage;
