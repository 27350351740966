import React, { ChangeEvent, FunctionComponent, useMemo } from 'react';
import {
  Form, FormActionsAlign, FormProps, FormSubmitType,
} from '@doveit/bricks';
import { prospectKOStatusSelectOptions } from '../../../select-options/prospectKOStatusSelectOptions';
import validationSchema from './ArchiveProspectForm.schema';
import { ProspectStatus } from '../../../domain/types';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { prospectSellabilityLabels } from '../../../labels';
import { prospectWillingnessLabels } from '../../../labels/prospectWillingnessLabels';

export interface ArchiveProspectFormModel {
  status?: string,
  agentEvaluation?: string,
  ownerEvaluation?: string,
  sellability: string,
  willingness: string,
  notes?: string,
}

export interface ArchiveProspectFormProps {
  initialValues?: Omit<ArchiveProspectFormModel, 'status'>,
  loading?: FormProps<ArchiveProspectFormModel>['loading'],
  disabled?: FormProps<ArchiveProspectFormModel>['disabled'],
  onSubmit: FormSubmitType<ArchiveProspectFormModel>,
}

const DEFAULT_INITIAL_VALUES: ArchiveProspectFormModel = {
  status: '',
  agentEvaluation: '',
  ownerEvaluation: '',
  sellability: '',
  willingness: '',
  notes: '',
};

const ArchiveProspectForm: FunctionComponent<ArchiveProspectFormProps> = ({
  initialValues,
  ...rest
}) => {
  const mergedInitialValues = useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...(initialValues || {}),
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      validationSchema={validationSchema}
      {...rest}
    >
      {({ values, setFieldValue }) => {
        const onStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
          const selectedStatus = e.target.value as ProspectStatus;

          setFieldValue('status', selectedStatus);

          if (selectedStatus === ProspectStatus.NOT_INTERESTED) {
            setFieldValue('willingness', '1');
          }

          if (selectedStatus === ProspectStatus.UNSELLABLE) {
            setFieldValue('sellability', '1');
          }
        };

        return (
          <>
            <Form.Fieldset legend="Motivazione">
              <Form.Item>
                <Form.Select
                  name="status"
                  aria-label="Campo per specificare il motivo dell'archiviazione"
                  options={prospectKOStatusSelectOptions}
                  onChange={onStatusChange}
                  required
                />
              </Form.Item>
            </Form.Fieldset>
            <Form.Fieldset legend="Valutazioni">
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="agentEvaluation"
                  label="Val. agente (€)"
                  placeholder="Es: 150000"
                  aria-label="Campo per inserire la valutazione dell'agente"
                  type="text"
                  min="0"
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="ownerEvaluation"
                  label="Richiesta (€)"
                  placeholder="Es: 150000"
                  aria-label="Campo per inserire la richiesta del proprietario"
                  type="text"
                  min="0"
                />
              </Form.Item>
            </Form.Fieldset>
            <Form.Fieldset legend="Commerciabilità">
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Select
                  name="willingness"
                  label="Propensione"
                  aria-label="Campo per specificare la propensione alla vendita del propietario"
                  options={asSelectOptions(prospectWillingnessLabels, false)}
                  disabled={values.status === ProspectStatus.NOT_INTERESTED}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Select
                  name="sellability"
                  label="Vendibilità"
                  aria-label="Campo per specificare la vendibilità dell'immobile"
                  options={asSelectOptions(prospectSellabilityLabels, false)}
                  disabled={values.status === ProspectStatus.UNSELLABLE}
                />
              </Form.Item>
            </Form.Fieldset>
            <Form.Fieldset legend="Note">
              <Form.Item>
                <Form.TextArea
                  aria-label="Campo per l'inserimento delle note"
                  name="notes"
                  rows={5}
                />
              </Form.Item>
            </Form.Fieldset>
            <Form.Actions align={FormActionsAlign.RIGHT}>
              <Form.Submit
                label="Archivia"
                aria-label="Archivia la valutazione"
              />
            </Form.Actions>
          </>
        );
      }}
    </Form>
  );
};

export default ArchiveProspectForm;
