import * as Yup from 'yup';

const upsertPropertySpaceWithSizeValidationSchema = (isSizeRequired: boolean) => {
  const sizeBaseSchema = Yup.number().integer('Inserisci un numero intero').positive('Inserisci un numero positivo');
  return Yup.object().shape({
    size: isSizeRequired ? sizeBaseSchema.required('Il campo è obbligatorio') : sizeBaseSchema,
  });
};

export default upsertPropertySpaceWithSizeValidationSchema;
