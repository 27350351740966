import {
  ThemedFunctor, getSpacing, SpacingName, getColorPalette, getTypography, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({ theme, $backgroundColor }) => css`
  ${getSpacing.padding(theme, [SpacingName.SMALLEST, SpacingName.SMALLER])}
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  background-color: ${getColorPalette(theme, $backgroundColor)};
  bottom: 0;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: ${theme.palettes.neutrals.lightest};
  opacity: 0.8;
  position: absolute;
  right: 0;
`;
