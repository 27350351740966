import * as Yup from 'yup';
import { RumorStatus } from '../../../domain/types';
import { RUMOR_KO_STATUSES } from '../../constants';

export const RUMOR_KO_STATUS_TO_ARCHIVE_RUMOR_ACTION_LABELS: Record<typeof RUMOR_KO_STATUSES[number], {
  actionLabel: string,
  modalTitle: string,
  modalBody: string,
}> = {
  [RumorStatus.KO_NOT_CONFIRMED]: {
    actionLabel: 'Archivia come non confermata',
    modalTitle: 'Conferma archivio come non confermata',
    modalBody: "Conferma l'operazione per archiviare la notizia con stato Non confermata.",
  },
  [RumorStatus.KO_NOT_INTERESTED]: {
    actionLabel: 'Archivia come non interessato',
    modalTitle: 'Conferma archivio come non interessato',
    modalBody: "Conferma l'operazione per archiviare la notizia con stato Non interessato.",
  },
  [RumorStatus.KO_DUPLICATE_LEAD]: {
    actionLabel: 'Archivia come duplicato lead',
    modalTitle: 'Conferma archivio come duplicato lead',
    modalBody: "Conferma l'operazione per archiviare la notizia con stato Duplicato lead.",
  },
};

export const ARCHIVE_RUMOR_FORM_DEFAULT_VALUES = {
  status: '' as RumorStatus,
};

export const archiveRumorFormValidationSchema = Yup.object({
  status: Yup
    .string()
    .required('Il campo è obbligatorio')
    .oneOf(RUMOR_KO_STATUSES),
});

export const ARCHIVE_RUMOR_SUCCESS_MESSAGE = 'La notizia è stata archiviata con successo.';
export const ARCHIVE_RUMOR_ERROR_MESSAGE = 'Non è stato possibile archiviare la notizia.';
