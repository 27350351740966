/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent } from 'react';
import Table, { DEFAULT_TABLE_PAGE_SIZE } from '../../../components/table/Table';
import { AreaType } from '../../../domain/types';
import { AgentsPerArea } from '../../models/AgentsPerArea';
import * as styles from './AgentAreasList.style';
import { agentAreasListColumns } from './columnsSettings';
import Card from '../../../components/card/Card';

export interface AgentAreasListProps {
  data: AgentsPerArea[],
  areaType?: AreaType,
  dataFor: string,
}

const AgentAreasList: FunctionComponent<AgentAreasListProps> = ({
  areaType = AreaType.ZIP_CODE,
  data,
  dataFor,
}) => (
  <div css={styles.table}>
    {data.length > 0 && (
      <h4 css={styles.tableTitle}>
        Risultati per: <strong>{dataFor}</strong>
      </h4>
    )}

    <Card>
      <Card.Box>
        <Table
          data={data}
          columns={agentAreasListColumns(areaType)}
          pageable={data.length > DEFAULT_TABLE_PAGE_SIZE}
          sortable
          filterable
        />
      </Card.Box>
    </Card>
  </div>
);

export default AgentAreasList;
