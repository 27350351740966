/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import { AgentFilterValue } from '../../hooks/agent-filter/useAgentFilter';

import * as styles from './SerpFiltersSummary.style';
import { GenericSerpSortOption } from '../../types';

const MAX_AGENTS_SUMMARY = 2;
const DEFAULT_AGENT_FILTERS = { values: [], isExplicit: false };

export interface SerpFiltersSummaryProps<T, V extends string> extends React.AriaAttributes {
  filters: Partial<T> & { agentFilters?: { values: AgentFilterValue[], isExplicit: boolean } },
  sortOptions: GenericSerpSortOption<V>[],
}

export const SerpFiltersSummary = <T, V extends string>({
  filters,
  'aria-label': ariaLabel = 'Sommario filtri serp',
  sortOptions,
  ...rest
}: SerpFiltersSummaryProps<T, V>) => {
  const summaryBlocks = [];

  if (Object.keys(filters).length === 0) {
    return null;
  }

  const agentFilters = filters.agentFilters ?? DEFAULT_AGENT_FILTERS;

  if (agentFilters?.values.length > 0 && agentFilters.isExplicit) {
    const more = agentFilters.values.length - MAX_AGENTS_SUMMARY;
    const agentSummaryValue = agentFilters.values.slice(0, MAX_AGENTS_SUMMARY).map((it) => it.name)
      .concat(more > 0 ? `+${more}` : '')
      .filter((element) => !!element)
      .join(', ');

    summaryBlocks.push(
      <div key="agent-filter-summary" aria-label={`${ariaLabel} (Filtro agente)`}>
        Agenti &quot;<strong>{agentSummaryValue}</strong>&quot;
      </div>,
    );
  }

  if ((filters as any).q) {
    summaryBlocks.push(
      <div key="search-string-summary" aria-label={`${ariaLabel} (Stringa di ricerca)`}>
        Ricerca &quot;<strong>{(filters as any).q}</strong>&quot;
      </div>,
    );
  }

  const sortOption = sortOptions.find((option) => option.key === (filters as any).sort);

  if ((filters as any).sort && sortOption) {
    summaryBlocks.push(
      <div key="sort-summary" aria-label={`${ariaLabel} (Tipo di ordinamento)`}>
        Ordinamento &quot;<strong>{sortOption.label}</strong>&quot;
      </div>,
    );
  }

  return summaryBlocks.length === 0
    ? null
    : (
      <div
        {...rest}
        aria-label={ariaLabel}
        css={styles.basic}
      >
        {summaryBlocks}
      </div>
    );
};

export default SerpFiltersSummary;
