import React from 'react';
import { Action, HStack, ICON_MAGNIFY } from '@doveit/bricks';
import { useNavigate } from 'react-router-dom';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import useAgents from '../../../hooks/use-agents/useAgents';
import ViewAgentCard from '../../containers/view-agent-card/ViewAgentCard';
import { SortOrder } from '../../../providers/pagination';
import { AgentStatus, DescendantsStrategy } from '../../../domain/types';
import usePagination from '../../../hooks/use-pagination/usePagination';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import { getAgentsActiveFilterMapper } from '../../utils/filterMapper';
import useFilters from '../../../hooks/use-filters/useFilters';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import PageSkeleton from '../../../components/page-skeleton/PageSkeleton';

export const ERROR_MESSAGE = 'Errore durante il caricamento';

const BrowseAgentsPage: React.FC = () => {
  const navigate = useNavigate();

  const { filters: { active } } = useFilters(getAgentsActiveFilterMapper());
  const filters = React.useMemo(() => ({ active: active ? AgentStatus.ACTIVE : AgentStatus.INACTIVE }),
    [active]);

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const { page, goToPage } = usePagination('page', scrollRef);

  const { data: agents, error } = useAgents(filters, {
    page,
    size: 20,
    sort: {
      workEmail: SortOrder.ASC,
    },
  }, {
    descendantsStrategy: DescendantsStrategy.ALL,
  });

  const goTo = React.useCallback((newPage: string) => () => {
    navigate(newPage);
  }, [navigate]);

  if (error) {
    return (<GenericErrorPage title={ERROR_MESSAGE} />);
  }

  if (!agents) {
    return <PageSkeleton />;
  }

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content
        title="Gestione agenti"
        headerSlot={(
          <HStack>
            <Action
              iconLeft={{ path: ICON_MAGNIFY }}
              aria-label="Cerca agenti"
              label="Cerca"
              emphasis="high"
              onClick={goTo('/admin/agents/search')}
            />
          </HStack>
        )}
      >
        <GenericPageLayout.InnerContent innerRef={scrollRef}>
          <PaginatedList
            {...agents!}
            goToPage={goToPage}
          >
            {agents.content.map((agent) => (
              <ViewAgentCard
                key={agent.id}
                agent={agent}
              />
            ))}
          </PaginatedList>
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default BrowseAgentsPage;
