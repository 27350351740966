/* eslint-disable react/no-unknown-property */
import { formatDate } from '@doveit/hammer';
import { format, isToday, isTomorrow } from 'date-fns';
import React from 'react';
import * as styles from './DateViewer.style';

export interface DateViewerProps {
  stringDate: string;
  checkIfToday?: boolean;
  checkIfTomorrow?: boolean;
  withStrong?: boolean;
  withHour?: boolean;
}

const DateViewer: React.FC<DateViewerProps> = ({
  stringDate,
  checkIfToday = false,
  checkIfTomorrow = false,
  withStrong = false,
  withHour = false,
}): React.ReactElement => {
  const date = new Date(stringDate);
  const hour = format(date, 'HH:mm');

  if (checkIfToday && isToday(date)) {
    return withStrong ? (
      <span css={styles.noWrap}>
        <strong aria-label="strong-date"> Oggi </strong>
        {withHour && <> - <span aria-label="hour">{hour}</span> </>}
      </span>
    ) : <span css={styles.noWrap}> Oggi {withHour && <>- {hour} </>}</span>;
  }

  if (checkIfTomorrow && isTomorrow(date)) {
    return withStrong ? (
      <div css={styles.noWrap}>
        <strong aria-label="strong-date"> Domani </strong>
        {withHour && <> - <span aria-label="hour">{hour}</span> </>}
      </div>
    )
      : <span css={styles.noWrap}> Domani {withHour && <> - {hour} </>} </span>;
  }

  return withHour
    ? (
      <span css={styles.noWrap}>
        {formatDate(date)} - {hour}
      </span>
    ) : (
      <span css={styles.noWrap}>
        {formatDate(date)}
      </span>
    );
};

export default DateViewer;
