import { OfferWizardData } from '../../../../providers/api/dtos';
import { OfferWizardNotesFormModel } from './OfferWizardNotesForm';

export const toOfferWizardNotesFormModel = (
  initialData: OfferWizardData,
): OfferWizardNotesFormModel => ({
  signaturePlace: initialData.signaturePlace ?? '',
  validUntil: initialData.offer?.validUntil ?? '',
  notes: initialData.notes || '',
});

export const toOfferWizardData = (
  existingData: OfferWizardData,
  values: OfferWizardNotesFormModel,
): OfferWizardData => {
  const {
    signaturePlace,
    validUntil,
    notes,
  } = values;

  const newValues: OfferWizardData = {
    ...existingData,
    signaturePlace: signaturePlace.trim() || undefined,
    notes: notes.trim() || undefined,
    offer: {
      ...existingData.offer,
      validUntil: validUntil ? new Date(validUntil).toISOString() : undefined,
    },
  };

  return newValues;
};
