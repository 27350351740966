/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Skeleton } from '@doveit/bricks';
import { useTheme } from 'styled-components';
import * as styles from './PopoverPreview.style';

const PopoverPreviewSkeleton: React.FC<React.AriaAttributes> = ({
  'aria-label': ariaLabel = 'entity-popover-preview-skeleton',
  ...rest
}) => {
  const theme = useTheme();

  return (
    <div
      {...rest}
      aria-label={ariaLabel}
      css={styles.basic}
    >
      <div css={styles.header}>
        <div css={styles.avatar}>
          <Skeleton
            width={theme.remHeight.L}
            height={theme.remHeight.L}
          />
        </div>
        <div css={styles.info}>
          <Skeleton />
          <Skeleton width="60%" />
        </div>
      </div>
      <div css={styles.wrapper}>
        <Skeleton height={theme.remHeight.S} />
      </div>
    </div>
  );
};

export default PopoverPreviewSkeleton;
