import { SelectOption } from '@doveit/bricks';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { ReviewType } from './types';

export const REVIEW_TYPE_LABELS: Record<ReviewType, string> = {
  BUYER: 'Solo compratore',
  SELLER: 'Solo venditore',
  BOTH: 'Venditore e compratore',
};

export const REVIEW_TYPE_SELECT_OPTIONS: SelectOption[] = asSelectOptions(REVIEW_TYPE_LABELS, {
  value: '',
  label: 'Seleziona',
  disabled: true,
});
