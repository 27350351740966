import React from 'react';
import {
  ActionIcon, ActionIconDropdown, Dropdown, FontWeight, HStack, ICON_EYE_OUTLINE, ICON_MAP_MARKER_OUTLINE, Message, Stack, Text,
  useNotifications,
  UserPreview,
  UserPreviewSkeleton,
} from '@doveit/bricks';
import { Intent, Note, Reminder } from '../../../providers/api/dtos';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import UpdateIntentStatus from '../update-intent-status/UpdateIntentStatus';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import ViewPropertyPopoverPreview from '../../../property/containers/view-property-popover-preview/ViewPropertyPopoverPreview';
import SimpleTableRowSkeleton from '../../../components/simple-table/SimpleTableRow.skeleton';
import { setVerticalEllipsis } from '../../../utils/styles/verticalEllipsis.style';
import usePropertyPreview from '../../../property/hooks/use-property-preview/usePropertyPreview';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import useContact from '../../../contact/hooks/use-contact/useContact';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import UpsertReminderAction from '../../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import UpsertNoteAction from '../../../notes/containers/UpsertNoteAction';
import { intentMessagesProvider } from '../../../containers/send-whatsapp-action/messages/intentMessagesProvider';
import { updateIntent } from '../../../providers/api/intent/intentProvider';
import { buildWhatsappMessageSentNote } from '../../utils/buildWhatsappMessageSentNote';
import useMarkIntentAsSeen from '../../../hooks/use-mark-intent-as-seen/useMarkIntentAsSeen';

export const UPSERT_NOTE_ERROR_MESSAGE = 'Non è stato possibile aggiungere la nota';
export const UPSERT_NOTE_SUCCESS_MESSAGE = 'Nota aggiunta con successo';

export interface ViewContactIntentRowProps extends React.AriaAttributes {
  intent: Intent,
  onIntentUpdate?: (intent: Intent) => void,
}

const ViewContactIntentRow: React.FC<ViewContactIntentRowProps> = ({
  intent,
  onIntentUpdate,
  ...rest
}) => {
  const { addSuccess, addError } = useNotifications();
  const {
    user, mainUserRole, userIsAdmin, userIsCallCenter,
  } = useRBAC();

  const { data: property, isLoading: isPropertyLoading, error: propertyError } = usePropertyPreview(intent.propertyId);
  const { data: agent, isLoading: isAgentLoading, error: agentError } = useAgentByPropertyId(property ? intent.propertyId : undefined);

  const { markAsSeen } = useMarkIntentAsSeen(agent?.id);
  const { data: contact } = useContact(intent.contactId);

  const isOwnerOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);
  const isAdminOrOwnerOrManager = React.useMemo(() => userIsAdmin || isOwnerOrManager,
    [isOwnerOrManager, userIsAdmin]);
  const isAdminOrCallCenterOrOwnerOrManager = React.useMemo(() => userIsAdmin || userIsCallCenter || isOwnerOrManager,
    [isOwnerOrManager, userIsAdmin, userIsCallCenter]);

  const reminderToCreate: Reminder = React.useMemo(() => ({
    agentId: agent?.id!,
    referenceId: intent.id!,
    referenceType: ReferenceType.INTENT,
    resolved: false,
  } as Reminder), [agent?.id, intent.id]);

  const goToIntentPage = React.useCallback(() => {
    goToDetailPage(intent.id!, ReferenceType.INTENT);
  }, [intent.id]);

  const onStatusUpdate = React.useCallback((updatedIntent: Intent) => {
    onIntentUpdate?.(updatedIntent);
  }, [onIntentUpdate]);

  const handleReminderCreated = React.useCallback(async () => {
    await markAsSeen(intent, { onSuccess: onIntentUpdate });
  }, [intent, markAsSeen, onIntentUpdate]);

  const handleNoteCreated = React.useCallback(async (note: Note) => {
    if (!intent) return;

    try {
      let updatedIntent;

      updatedIntent = await updateIntent(intent.id!, {
        ...intent,
        notes: [note, ...(intent.notes || [])],
      });

      updatedIntent = await markAsSeen(updatedIntent);
      onIntentUpdate?.(updatedIntent);
      addSuccess(UPSERT_NOTE_SUCCESS_MESSAGE);
    } catch (err) {
      addError(UPSERT_NOTE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, intent, markAsSeen, onIntentUpdate]);

  const onMessageSent = React.useCallback(async (message: string) => {
    if (!intent) return;

    let updatedIntent;

    updatedIntent = await updateIntent(intent.id!, {
      ...intent,
      notes: [{
        text: buildWhatsappMessageSentNote(message),
        author: user.name,
        role: mainUserRole,
      }, ...(intent.notes || [])],
    });

    updatedIntent = await markAsSeen(updatedIntent);

    onIntentUpdate?.(updatedIntent);
  }, [intent, mainUserRole, markAsSeen, onIntentUpdate, user.name]);

  if (isPropertyLoading) {
    return (
      <SimpleTableRowSkeleton />
    );
  }

  if (propertyError) {
    return (
      <SimpleTable.Row>
        <SimpleTable.Cell>
          <Message type="critical" message="Errore caricamento dati" />
        </SimpleTable.Cell>
      </SimpleTable.Row>
    );
  }

  return property && (
    <SimpleTable.Row {...rest}>
      <SimpleTable.Cell>
        <ViewPropertyPopoverPreview
          property={property}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <PropertyAvatar
                size="S"
                icon={property.overriddenGeo ? ICON_MAP_MARKER_OUTLINE : undefined}
              />
              <Text.BodySmall fontWeight={FontWeight.MEDIUM} css={setVerticalEllipsis()}>
                {property.overriddenGeo?.normalizedAddress || property.geo?.normalizedAddress}
              </Text.BodySmall>
            </HStack>
          )}
        />
      </SimpleTable.Cell>
      <SimpleTable.Cell>
        <UpdateIntentStatus
          showAsBadge={!isAdminOrCallCenterOrOwnerOrManager}
          intent={intent}
          size="XS"
          onSuccess={onStatusUpdate}
        />
      </SimpleTable.Cell>

      <SimpleTable.Cell width="10rem">
        {isAgentLoading && (
          <UserPreviewSkeleton size="S" />
        )}
        {agentError && (
          <UserPreview
            size="S"
            name="Errore agente"
          />
        )}
        {agent && (
          <ViewAgentPopoverPreview
            agent={agent}
            trigger={(
              <AgentPreview
                {...toAgentPreviewProps(agent)}
                size="S"
                expanded
              />
            )}
          />
        )}
      </SimpleTable.Cell>

      <SimpleTable.Cell>
        <Stack gap={50}>
          <Text.Mini transform="uppercase">
            Aggiunto
          </Text.Mini>
          <Text.Caption>
            <DateViewer
              stringDate={intent.createdAt!}
              checkIfToday
            />
          </Text.Caption>
        </Stack>
      </SimpleTable.Cell>

      <SimpleTable.Cell align="right">
        <HStack gap={75} wrap="nowrap">
          {isAdminOrCallCenterOrOwnerOrManager && (
            <ActionIcon
              aria-label="Visualizza interesse"
              label="Visualizza"
              size="S"
              icon={{ path: ICON_EYE_OUTLINE }}
              onClick={goToIntentPage}
            />
          )}
          <ActionIconDropdown
            label="Azioni rapide sull'interesse"
            aria-label="Azioni rapide sull'interesse"
            size="S"
            emphasis="low"
          >
            {isAdminOrOwnerOrManager && contact?.phoneNumber && (
              <SendWhatsappAction
                phoneNumber={contact.phoneNumber}
                name={contact.name}
                fetchMessages={intentMessagesProvider(intent, agent)}
                onMessageSent={onMessageSent}
              >
                {({ onClick }) => (
                  <Dropdown.Option
                    label="Invia whatsapp"
                    onClick={onClick}
                  />
                )}
              </SendWhatsappAction>
            )}
            {isAdminOrOwnerOrManager && (
              <UpsertReminderAction
                reminder={reminderToCreate}
                onSuccess={handleReminderCreated}
                aria-label="Pulsante per aggiungere un promemoria"
              >
                {({ upsert }) => (
                  <Dropdown.Option
                    label="Aggiungi promemoria"
                    onClick={upsert}
                  />
                )}
              </UpsertReminderAction>
            )}
            <UpsertNoteAction
              upsertNote={handleNoteCreated}
              aria-label="Pulsante per aggiungere una nota"
            >
              {({ upsert }) => (
                <Dropdown.Option
                  label="Aggiungi nota"
                  onClick={upsert}
                />
              )}
            </UpsertNoteAction>
          </ActionIconDropdown>
        </HStack>
      </SimpleTable.Cell>
    </SimpleTable.Row>
  );
};

export default ViewContactIntentRow;
