import {
  BreakpointQueryName, getSpacing, SpacingName, ThemedFunctor,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({
  theme,
  $unpadded,
  $compact,
}) => css`
  ${!$unpadded && getSpacing.padding(theme, $compact ? SpacingName.SMALLEST : SpacingName.SMALLER)}
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    cursor: initial;
  }

  > *:not(:first-child) {
    position: relative;
  }
`;

export const collapseArea: ThemedFunctor = () => css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;
