/* eslint-disable react/no-unknown-property */
import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  Icon, ICON_CHEVRON, ICON_EMPTY, Link,
} from '@doveit/bricks';
import * as styles from './SideNavigationItem.style';

export type SideNavigationItemProps = PropsWithChildren<{
  label: string,
  'aria-label'?: string,
  onClick?(path?: string): void,
  path?: string,
  title?: string,
  icon?: string,
  active?: boolean,
  nested?: boolean,
  open?: boolean,
} & React.AriaAttributes>;

export const SideNavigationItem: FunctionComponent<SideNavigationItemProps> = ({
  label,
  onClick,
  path,
  title,
  icon,
  children,
  active,
  nested,
  open,
  'aria-label': ariaLabel = 'side-navigation-item',
}) => (
  <div
    css={styles.base}
    $open={open}
  >
    <div
      css={styles.item}
      $nested={nested}
      $active={active}
    >
      <Link
        href={path}
        onClick={onClick ? () => onClick(path) : undefined}
        aria-label={ariaLabel || label}
        title={title}
      >
        <span>
          {icon && (
            <Icon
              path={icon}
              size={20}
            />
          )}
          {label}
        </span>
        {!nested && (
          <Icon
            path={(open || active) ? ICON_CHEVRON : ICON_EMPTY}
            direction={open ? 'bottom' : 'right'}
            size={20}
          />
        )}
      </Link>
    </div>
    {children && (
      <div>
        {children}
      </div>
    )}
  </div>
);

export default SideNavigationItem;
