import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {children || null}
    </>
  );
};

export default ScrollToTop;
