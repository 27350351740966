import { BreakpointQueryName, ThemeDefinition } from '@doveit/bricks';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';

export type Device = 'mobile' | 'tablet' | 'desktop';

const deviceBreakpoints = (device: Device, theme: ThemeDefinition): string => {
  switch (device) {
    case 'mobile': return theme.breakpoints.down(BreakpointQueryName.MD);
    case 'tablet': return theme.breakpoints.only(BreakpointQueryName.MD);
    default: return theme.breakpoints.up(BreakpointQueryName.LG);
  }
};

const useIsDevice = (device: Device): boolean => {
  const theme = useTheme();

  return useMediaQuery(deviceBreakpoints(device, theme));
};

export default useIsDevice;
