import {
  Action, ModalSize, Portal, useModal,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import EditProspect from '../edit-prospect/EditProspect';
import { Prospect } from '../../../providers/api/dtos/prospect';
import { EditProspectFormPros } from '../../components/edit-prospect-form/EditProspectForm';

export interface ChildFnProps {
  onClick: VoidFunction,
  disabled?: boolean,
}

export interface EditProspectActionProps {
  prospect: Prospect,
  extraActions?: EditProspectFormPros['extraActions'],
  disabled?: boolean,
  onSuccess?: (updatedProspect: Prospect) => void,
  onError?: (error: Error) => void,
  children?: (props: ChildFnProps) => React.ReactNode;
}

const EditProspectAction: React.FC<EditProspectActionProps> = ({
  prospect,
  extraActions,
  disabled,
  onSuccess,
  onError,
  children,
}) => {
  const editModal = useModal();

  const onActionClick = React.useCallback(() => editModal.open(), [editModal]);

  const onInternalSuccess = React.useCallback(() => {
    onSuccess?.(prospect);
    editModal.close();
  }, [editModal, onSuccess, prospect]);

  return (
    <>
      {
        children
          ? children({ onClick: onActionClick, disabled })
          : (
            <Action
              label="Modifica"
              aria-label="Pulsante per modificare una valutazione"
              size="S"
              disabled={disabled}
              onClick={onActionClick}
            />
          )
      }
      <Portal>
        <SimpleModal
          {...editModal}
          size={ModalSize.MEDIUM}
          title="Modifica valutazione"
          aria-label="Modale per modificare una valutazione"
        >
          <EditProspect
            prospectId={prospect.id!!}
            onSuccess={onInternalSuccess}
            onError={onError}
            extraActions={extraActions}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditProspectAction;
