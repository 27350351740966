import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { AssignmentWizardData, Prospect } from '../../../providers/api/dtos';
import { API_ASSIGNMENTS_PATH } from '../../../providers/api/api';
import { getAssignmentWizardData } from '../../../providers/api/assignment/assignmentProvider';

export const ASSIGNMENT_WIZARD_DATA_RID = `${API_ASSIGNMENTS_PATH}/from-prospect/wizard`;

export default function useAssignmentWizardData(prospectId?: NonNullable<Prospect['id']>, options?: SWRConfiguration): SWRResponse<AssignmentWizardData, AxiosError> {
  const key = prospectId ? [ASSIGNMENT_WIZARD_DATA_RID, prospectId] : null;

  return useSWR(key, () => getAssignmentWizardData(prospectId!), options);
}
