import {
  DOVEIT_EXTRA_PACKAGES_DEFAULT,
} from '../../domain/types';
import { Agent, ExtraPackageConfig } from '../../providers/api/dtos';

export function getAllowedDoveItPackages(agent: Agent): ExtraPackageConfig[] {
  const agencyConfig = agent.agencies?.[0]?.configuration;

  if (!agent.franchising || !agencyConfig?.extraPackages?.allowedExtraPackages) {
    return DOVEIT_EXTRA_PACKAGES_DEFAULT;
  }

  return agencyConfig.extraPackages.allowedExtraPackages;
}
