import React, { useState } from 'react';
import {
  ActionIcon, ConfirmModal, ICON_ACCOUNT_MINUS_OUTLINE, ICON_ACCOUNT_PLUS_OUTLINE, Portal, Typography, useModal, useNotifications,
} from '@doveit/bricks';
import { Contact, Property } from '../../../providers/api/dtos';
import { addContactMatchToBlacklist, removeContactMatchFromBlacklist } from '../../../providers/api/contact-match-blacklist/contactMatchBlacklistProvider';
import { BlacklistAction } from '../../../types';

interface ContactMatchBlacklist {
  contactId: NonNullable<Contact['id']>,
  propertyId: NonNullable<Property['id']>,
  action: BlacklistAction,
}

export interface ContactMatchBlacklistActionProps extends ContactMatchBlacklist {
  contactId: NonNullable<Contact['id']>,
  propertyId: NonNullable<Property['id']>,
  action: BlacklistAction,
  disabled?: boolean,
  onSuccess?: (contactId: NonNullable<Contact['id']>, propertyId: NonNullable<Property['id']>, action: BlacklistAction) => void,
}

export const ADD_CONTACT_TO_BLACKLIST_MATCH_SUCCESS_MESSAGE = 'Elemento nascosto con successo.';
export const REMOVE_CONTACT_FROM_BLACKLIST_MATCH_SUCCESS_MESSAGE = 'Elemento nuovamente visibile.';
export const ADD_CONTACT_TO_BLACKLIST_MATCH_ERROR_MESSAGE = 'Non è stato possibile nascondere l\'elemento.';
export const REMOVE_CONTACT_FROM_BLACKLIST_MATCH_ERROR_MESSAGE = 'Non è stato possibile rendere visibile l\'elemento.';

const dataMapping = {
  [BlacklistAction.ADD]: {
    icon: ICON_ACCOUNT_MINUS_OUTLINE,
    color: 'critical',
    actionLabel: 'Escludi',
    ariaLabel: 'Escludi elemento',
    modalTitle: 'Escludi elemento',
    modalDescription: 'Confermando l\'operazione, l\'elemento sarà nascosto. Potrai ripristinarlo successivamente.',
  },
  [BlacklistAction.REMOVE]: {
    icon: ICON_ACCOUNT_PLUS_OUTLINE,
    color: 'success',
    actionLabel: 'Includi',
    ariaLabel: 'Includi elemento',
    modalTitle: 'Includi elemento',
    modalDescription: 'Confermando l\'operazione, l\'elemento sarà nuovamente visibile.', // @TODO: review text
  },
} as const;

const ContactMatchBlacklistAction: React.FC<ContactMatchBlacklistActionProps> = ({
  contactId,
  propertyId,
  action,
  disabled = false,
  onSuccess,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const confirmModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const isAddAction = React.useMemo(
    () => action === BlacklistAction.ADD,
    [action],
  );

  const onConfirm = React.useCallback(
    async () => {
      setIsSaving(true);

      try {
        await (isAddAction ? addContactMatchToBlacklist : removeContactMatchFromBlacklist)(contactId, propertyId);
        addSuccess(isAddAction
          ? ADD_CONTACT_TO_BLACKLIST_MATCH_SUCCESS_MESSAGE
          : REMOVE_CONTACT_FROM_BLACKLIST_MATCH_SUCCESS_MESSAGE);
      } catch (error) {
        addError(isAddAction
          ? ADD_CONTACT_TO_BLACKLIST_MATCH_ERROR_MESSAGE
          : REMOVE_CONTACT_FROM_BLACKLIST_MATCH_ERROR_MESSAGE);
      }

      setIsSaving(false);
      confirmModal.close();

      if (onSuccess) {
        onSuccess(contactId, propertyId, action);
      }
    }, [action, addError, addSuccess, confirmModal, contactId, isAddAction, onSuccess, propertyId],
  );

  return (
    <>
      <ActionIcon
        label={dataMapping[action].actionLabel}
        aria-label={dataMapping[action].ariaLabel}
        icon={{ path: dataMapping[action].icon }}
        color={dataMapping[action].color}
        onClick={confirmModal.open}
        loading={isSaving}
        disabled={disabled}
      />
      <Portal>
        <ConfirmModal
          title={dataMapping[action].modalTitle}
          aria-label="Modale di conferma azione"
          isOpen={confirmModal.isOpen}
          onAbort={confirmModal.close}
          onConfirm={onConfirm}
          saving={isSaving}
        >
          <Typography.BODY>
            {dataMapping[action].modalDescription}
          </Typography.BODY>
        </ConfirmModal>
      </Portal>
    </>
  );
};

export default ContactMatchBlacklistAction;
