export enum JobApplicationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  ASSIGNED = 'ASSIGNED',
  OFFER = 'OFFER',
  HIRED = 'HIRED',
  KO_DUPLICATE = 'KO_DUPLICATE',
  KO_NOT_ELIGIBLE = 'KO_NOT_ELIGIBLE',
  KO_NOT_INTERESTED = 'KO_NOT_INTERESTED',
  KO_OFFER_REFUSED = 'KO_OFFER_REFUSED',
  KO_GEO = 'KO_GEO',
}
