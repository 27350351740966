import { API_CONTENT_PROCESSING_PATH, getClient } from '../api';
import type {
  EntityRelatedGenerationRequest,
  EntityRelatedGenerationResponse,
  RephraseRequest,
  RephraseResponse,
  TranslationRequest,
  TranslationResponse,
} from '../dtos/contentProcessing';

export async function generateForEntity<TRes extends EntityRelatedGenerationResponse>(request: EntityRelatedGenerationRequest): Promise<TRes> {
  const client = await getClient();

  const { data } = await client.post<TRes>(`${API_CONTENT_PROCESSING_PATH}/generate`, request);
  return data;
}

export async function rephrase(request: RephraseRequest): Promise<RephraseResponse> {
  const client = await getClient();

  const { data } = await client.post<RephraseResponse>(`${API_CONTENT_PROCESSING_PATH}/rephrase`, request);
  return data;
}

export async function translate(request: TranslationRequest): Promise<TranslationResponse> {
  const client = await getClient();

  const { data } = await client.post<TranslationResponse>(`${API_CONTENT_PROCESSING_PATH}/translate`, request);
  return data;
}
