/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Skeleton } from '@doveit/bricks';
import * as styles from './ConversationPreview.style';

const ConversationPreviewSkeleton = () => (
  <div css={styles.basic}>
    <div css={styles.avatarContainer}>
      <Skeleton height="2.75rem" width="2.75rem" circle />
    </div>
    <div css={styles.primary}>
      <div>
        <Skeleton width="80%" />
      </div>
      <div css={styles.message}>
        <Skeleton height=".7rem" />
      </div>
    </div>
  </div>
);

export default ConversationPreviewSkeleton;
