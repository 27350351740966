/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  ICON_EMAIL_OUTLINE, ICON_EMPTY, ICON_PHONE_INCOMING_OUTLINE, ICON_PHONE_OUTGOING_OUTLINE, ICON_PHONE_OUTLINE, Icon,
} from '@doveit/bricks';
import { format } from 'date-fns';
import { formatDate } from '@doveit/hammer';
import * as styles from './EventRegistryList.style';
import { EventRegistryType } from '../../types';
import { Event } from '../../interfaces';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import { formatInputTime } from '../../../utils/form';

export const columns = [
  {
    header: 'Tipo',
    accessor: 'type',
    format: (type: any) => {
      let path;
      switch (type as EventRegistryType) {
        case EventRegistryType.PHONE_CALL_INBOUND:
          path = ICON_PHONE_INCOMING_OUTLINE;
          break;
        case EventRegistryType.PHONE_CALL_OUTBOUND:
          path = ICON_PHONE_OUTGOING_OUTLINE;
          break;
        case EventRegistryType.MOBILE_CALL_OUTBOUND:
          path = ICON_PHONE_OUTLINE;
          break;
        case EventRegistryType.EMAIL_OUTBOUND:
          path = ICON_EMAIL_OUTLINE;
          break;
        default:
          path = ICON_EMPTY;
          break;
      }

      return <Icon path={path} />;
    },
  },
  {
    header: 'Data',
    accessor: 'date',
    format: (date: any) => formatDate(new Date(date)),
  },
  {
    header: 'Ora',
    accessor: 'date',
    format: (date: any) => formatInputTime(new Date(date)),
  },
  {
    header: 'Stato',
    accessor: 'status',
    format: (status: any) => status ?? NO_VALUE_SYMBOL,
  },
  {
    header: 'Durata',
    accessor: 'duration',
    format: (duration: any) => format(+duration * 1000, 'mm:ss'),
  },
];

interface EventRegistryItemProps {
  event: Event;
}

const EventRegistryItem: React.FC<EventRegistryItemProps> = ({ event }) => (
  <tr>
    {columns.map((column) => (
      <td key={`column-${column.header}`} data-testid={`column-${column.header}`}>
        {column.format(event[column.accessor as keyof Event])}
      </td>
    ))}
  </tr>
);

interface EventRegistryListProps {
  events: Event[]
}

const EventRegistryList: React.FC<EventRegistryListProps> = ({ events }) => (
  <table css={styles.table}>
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={`th-${column.header}`}>
            {column.header}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {events.map((event, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <EventRegistryItem key={`event-${index}`} event={event} />
      ))}
    </tbody>
  </table>
);

export default EventRegistryList;
