import React from 'react';
import {
  Action, ActionIcon, Card, ConfirmModal, FormHandlers, Grid, HStack, ICON_CHECK, ICON_CLOSE, ICON_PENCIL_OUTLINE, ICON_TRASH_CAN_OUTLINE, Message, Portal, Spacing, Stack, Text,
  useModal,
  useNotifications,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import { Property, Room } from '../../../../providers/api/dtos';
import useRooms from '../../../hooks/use-rooms/useRooms';
import useIsDevice from '../../../../hooks/use-is-device/useIsDevice';
import SimpleTableSkeleton from '../../../../components/simple-table/SimpleTable.skeleton';
import SimpleTable from '../../../../components/simple-table/SimpleTable';
import { propertyRoomLabels } from '../../../../labels';
import { NO_VALUE_SYMBOL } from '../../../utils';
import UpsertPropertyRoomForm, { UpsertPropertyRoomFormModel } from '../../components/upsert-property-room-form/UpsertPropertyRoomForm';
import { RoomType } from '../../../../domain/types';
import { createRoom, deleteRoom, updateRoom } from '../../../../providers/api/room/roomsProvider';
import Divider from '../../../../components/divider/Divider';

export interface ManagePropertyRoomsProps {
  propertyId: NonNullable<Property['id']>,
  onCreate?: (createdRoom: Room) => void,
  onUpdate?: (updatedRoom: Room) => void,
  onDelete?: (deletedRoomId: NonNullable<Room['id']>) => void,
}

const ManagePropertyRooms: React.FC<ManagePropertyRoomsProps> = ({
  propertyId,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const isMobile = useIsDevice('mobile');
  const [isSaving, setIsSaving] = React.useState(false);
  const { addSuccess, addError } = useNotifications();
  const [isEditing, setIsEditing] = React.useState<{ room: Room } | false>(false);
  const createRoomFormRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const updateRoomFormRefs = React.useRef<{ [key: number]: React.MutableRefObject<any> }>({});
  const deleteRoomModal = useModal<{ room: Room }>();

  const {
    data: rooms,
    isLoading: areRoomsLoading,
    error: roomsErrors,
    mutate: mutateRooms,
  } = useRooms(propertyId);

  const addRoom = React.useCallback(async (formValues: UpsertPropertyRoomFormModel) => {
    try {
      setIsSaving(true);

      const roomToCreate: Room = {
        id: null,
        about: null,
        amenities: [],
        propertyId,
        roomType: formValues.type as RoomType,
        floorNumber: parseFloat(formValues.floor) || 0,
        size: parseFloat(formValues.size) || null,
      };

      const createdRoom = await createRoom(roomToCreate);

      setIsSaving(false);
      addSuccess('La stanza è stata aggiunta con successo.');

      onCreate?.(createdRoom);
      await mutateRooms();
    } catch (error) {
      setIsSaving(false);
      addError('Non è stato possibile aggiungere la stanza.');
    }
  }, [addError, addSuccess, mutateRooms, onCreate, propertyId]);

  const editRoom = React.useCallback(async (formValues: UpsertPropertyRoomFormModel) => {
    if (!isEditing) return;

    try {
      setIsSaving(true);

      const roomToUpdate: Room = {
        ...isEditing.room,
        roomType: formValues.type as RoomType,
        floorNumber: parseFloat(formValues.floor) || 0,
        size: parseFloat(formValues.size) || null,
      };

      const updatedRoom = await updateRoom(roomToUpdate);

      setIsSaving(false);
      addSuccess('La stanza è stata modificata con successo.');
      setIsEditing(false);

      onUpdate?.(updatedRoom);
      await mutateRooms();
    } catch (_) {
      setIsSaving(false);
      addError('Non è stato possibile modificare la stanza.');
    }
  }, [addError, addSuccess, isEditing, mutateRooms, onUpdate]);

  const confirmRoomDeletion = React.useCallback(async () => {
    if (!deleteRoomModal.data?.room) return;

    try {
      setIsSaving(true);

      await deleteRoom(deleteRoomModal.data.room.id!);

      setIsSaving(false);
      addSuccess('La stanza è stata rimossa con successo.');
      deleteRoomModal.close();

      onDelete?.(deleteRoomModal.data.room.id!);
      await mutateRooms();
    } catch (error) {
      setIsSaving(false);
      addError('Non è stato possibile rimuovere la stanza.');
    }
  }, [addError, addSuccess, deleteRoomModal, mutateRooms, onDelete]);

  const getUpdateRoomFormRef = React.useCallback((id: number) => {
    if (!updateRoomFormRefs.current[id]) {
      updateRoomFormRefs.current[id] = React.createRef();
    }
    return updateRoomFormRefs.current[id];
  }, []);

  const submitCreateRoomForm = React.useCallback(() => {
    createRoomFormRef.current?.handleSubmit();
  }, []);

  const submitUpdateRoomForm = React.useCallback((id: number) => {
    if (updateRoomFormRefs.current[id]) {
      updateRoomFormRefs.current[id].current.handleSubmit();
    }
  }, []);

  if (areRoomsLoading && !isMobile) {
    return <SimpleTableSkeleton aria-label="Stanze in caricamento" />;
  }

  if (roomsErrors) {
    return (
      <Message
        type="critical"
        message="Non è stato possibile caricare le stanze."
      />
    );
  }

  return (
    <>
      <Stack gap={200} data-testid="manage-property-rooms">
        {!isMobile && (
          <SimpleTable aria-label="Tabella stanze">
            <SimpleTable.Body>
              {rooms?.length === 0 && (
                <SimpleTable.Row>
                  <SimpleTable.Cell>
                    <Message
                      type="neutral"
                      message="Nessuna stanza inserita."
                    />
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              )}
              {rooms?.map((room) => (
                <SimpleTable.Row
                  key={room.id}
                  aria-label={`Informazioni stanza ${room.id}`}
                >
                  {(isEditing && isEditing.room.id === room.id) ? (
                    <>
                      <SimpleTable.Cell colSpan={3}>
                        <UpsertPropertyRoomForm
                          loading={isSaving}
                          initialValues={{
                            type: room.roomType,
                            floor: room.floorNumber.toString(),
                            size: room.size?.toString(),
                          }}
                          innerRef={getUpdateRoomFormRef(room.id!)}
                          onSubmit={editRoom}
                        />
                      </SimpleTable.Cell>
                      <SimpleTable.Cell align="right">
                        <HStack>
                          <ActionIcon
                            size="S"
                            color="primary"
                            icon={{ path: ICON_CHECK }}
                            label="Conferma modifiche"
                            aria-label="Conferma modifiche"
                            onClick={() => submitUpdateRoomForm(room.id!)}
                          />
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_CLOSE }}
                            label="Annulla modifiche"
                            aria-label="Annulla modifiche"
                            onClick={() => setIsEditing(false)}
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </>
                  ) : (
                    <>
                      <SimpleTable.Cell width="18.5rem">
                        <Text.BodySmall>
                          {propertyRoomLabels[room.roomType]}
                        </Text.BodySmall>
                      </SimpleTable.Cell>
                      <SimpleTable.Cell width="8.5rem">
                        <Text.BodySmall>
                          {room.size ? formatSquareMeters(room.size) : NO_VALUE_SYMBOL}
                        </Text.BodySmall>
                      </SimpleTable.Cell>
                      <SimpleTable.Cell>
                        <Text.BodySmall>
                          Piano {room.floorNumber}
                        </Text.BodySmall>
                      </SimpleTable.Cell>
                      <SimpleTable.Cell align="right">
                        <HStack>
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_PENCIL_OUTLINE }}
                            label="Modifica stanza"
                            aria-label="Modifica stanza"
                            onClick={() => setIsEditing({ room })}
                          />
                          <ActionIcon
                            size="S"
                            icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                            label="Rimuovi stanza"
                            aria-label="Rimuovi stanza"
                            onClick={() => deleteRoomModal.open({ room })}
                          />
                        </HStack>
                      </SimpleTable.Cell>
                    </>
                  )}
                </SimpleTable.Row>
              ))}
              <SimpleTable.Row aria-label="Aggiungi informazioni nuova stanza">
                <SimpleTable.Cell colSpan={3}>
                  <UpsertPropertyRoomForm
                    loading={isSaving}
                    innerRef={createRoomFormRef}
                    onSubmit={(values, actions) => {
                      actions.resetForm();

                      addRoom(values);
                    }}
                  />
                </SimpleTable.Cell>
                <SimpleTable.Cell align="right">
                  <Action
                    size="S"
                    label="Aggiungi"
                    aria-label="Aggiungi stanza"
                    onClick={submitCreateRoomForm}
                  />
                </SimpleTable.Cell>
              </SimpleTable.Row>
            </SimpleTable.Body>
          </SimpleTable>
        )}
        {isMobile && (
          <Stack
            gap={150}
            aria-label="Lista card stanze"
          >
            {rooms?.length === 0 && (
              <Spacing margin={[0, 0, 400]}>
                <Message
                  type="neutral"
                  message="Nessuna stanza inserita."
                />
              </Spacing>
            )}
            {rooms?.map((room) => (
              <Card
                key={room.id}
                aria-label={`Informazioni stanza ${room.id}`}
              >
                <Card.Header
                  title={propertyRoomLabels[room.roomType]}
                  primaryActions={
                    !(isEditing && isEditing.room.id === room.id) ? [
                      <ActionIcon
                        size="S"
                        icon={{ path: ICON_PENCIL_OUTLINE }}
                        label="Modifica stanza"
                        aria-label="Modifica stanza"
                        onClick={() => setIsEditing({ room })}
                      />,
                      <ActionIcon
                        size="S"
                        icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                        label="Rimuovi stanza"
                        aria-label="Rimuovi stanza"
                        onClick={() => deleteRoomModal.open({ room })}
                      />,
                    ] : undefined
                  }
                />
                <Card.Content>
                  {(isEditing && isEditing.room.id === room.id) ? (
                    <>
                      <UpsertPropertyRoomForm
                        loading={isSaving}
                        initialValues={{
                          type: room.roomType,
                          floor: room.floorNumber.toString(),
                          size: room.size?.toString(),
                        }}
                        innerRef={getUpdateRoomFormRef(room.id!)}
                        onSubmit={editRoom}
                      />
                      <Divider />
                      <Grid gutter={150}>
                        <Grid.Unit size={1 / 2}>
                          <Action
                            expanded
                            size="S"
                            color="primary"
                            label="Salva"
                            aria-label="Conferma modifiche"
                            onClick={() => submitUpdateRoomForm(room.id!)}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={1 / 2}>
                          <Action
                            expanded
                            size="S"
                            label="Annulla"
                            aria-label="Annulla modifiche"
                            onClick={() => setIsEditing(false)}
                          />
                        </Grid.Unit>
                      </Grid>
                    </>
                  ) : (
                    <Grid gutter={150}>
                      <Grid.Unit size={1 / 2}>
                        <Text.BodySmall>
                          {room.size ? formatSquareMeters(room.size) : NO_VALUE_SYMBOL}
                        </Text.BodySmall>
                      </Grid.Unit>
                      <Grid.Unit size={1 / 2}>
                        <Text.BodySmall>
                          Piano {room.floorNumber}
                        </Text.BodySmall>
                      </Grid.Unit>
                    </Grid>
                  )}
                </Card.Content>
              </Card>
            ))}
            <Card aria-label="Aggiungi informazioni nuova stanza">
              <Card.Header title="Aggiungi nuova stanza" />
              <Card.Content>
                <>
                  <UpsertPropertyRoomForm
                    loading={isSaving}
                    innerRef={createRoomFormRef}
                    onSubmit={(values, actions) => {
                      actions.resetForm();

                      addRoom(values);
                    }}
                  />
                  <Divider />
                  <Action
                    expanded
                    size="S"
                    label="Aggiungi"
                    aria-label="Aggiungi stanza"
                    onClick={submitCreateRoomForm}
                  />
                </>
              </Card.Content>
            </Card>
          </Stack>
        )}
      </Stack>
      <Portal>
        {deleteRoomModal.data?.room && (
          <ConfirmModal
            variant="critical"
            isOpen={deleteRoomModal.isOpen}
            title="Conferma rimozione stanza"
            aria-label="Conferma la rimozione della stanza"
            onConfirm={confirmRoomDeletion}
            onAbort={deleteRoomModal.close}
          >
            Confermando l&apos;operazione, la stanza sarà rimossa.
          </ConfirmModal>
        )}
      </Portal>
    </>
  );
};

export default ManagePropertyRooms;
