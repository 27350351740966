import { IntentStatus } from '../domain/types';

export const intentStatusLabels: Record<IntentStatus, string> = {
  IN_PROGRESS: 'In lavorazione',
  BOUGHT: 'Comprato',
  KO_DUPLICATE: 'Duplicato',
  KO_INFO: 'Solo informazioni',
  KO_OVERPRICED: 'Sovrapprezzo',
  KO_MORTGAGE: 'Mutuo non concesso',
  KO_PROPERTY: 'Non piace l\'immobile',
  KO_PROPERTY_NOT_AVAILABLE: 'Immobile non disponibile',
  KO_BOUGHT_OTHER: 'Ha comprato altro',
  KO_AREA: 'Non piace la zona',
  KO_REFUSED: 'Proposta rifiutata',
  KO_NOT_INTERESTED: 'Non interessato',
  KO_OTHER_CONSTRAINTS: 'Altri vincoli',
};
