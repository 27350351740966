/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Form,
  FormSubmitType,
  FormSubmitErrorsType,
  FormHandlers,
} from '@doveit/bricks';
import {
  PropertyStatus, LeadPropertyFeature, LeadWillingness, ResidentialPropertyTypeValue,
  AgentStatus,
} from '../../../domain/types';
import { propertyTypesRadio } from '../../labels/lead';
import validationSchema from './LeadForm.schema';
import {
  leadPropertyFeaturesOptions, floorSelectOptions, leadWillingnessSelectOptions,
} from '../../../select-options';
import * as styles from './LeadForm.style';
import AddressSuggestionAutoComplete from '../../../components/address-suggestion-autocomplete/AddressSuggestionAutoComplete';
import { LeadFormModel } from '../../../lead/models/LeadFormModel';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { propertyStatusLabels, registryStatusLabels } from '../../../labels';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';
import { boolToBooleanString } from '../../../utils/boolean-string/booleanString';

export interface LeadFormProps {
  onSubmit: FormSubmitType<LeadFormModel>,
  onSubmitErrors?: FormSubmitErrorsType<LeadFormModel>,
  initialValues?: Partial<LeadFormModel>,
  disabled?: boolean,
  loading?: boolean,
  // extraActions?: ExtraAction[],
  canSetAsPersonal?: boolean,
  innerRef?: React.MutableRefObject<FormHandlers>,
}

const DEFAULT_INITIAL_VALUES: LeadFormModel = {
  propertyType: ResidentialPropertyTypeValue.APPARTAMENTO,
  propertyStatus: PropertyStatus.ABITABILE,
  propertyFloor: '',
  propertySize: '',
  buildingFloors: '',
  propertyFeatures: [],
  willingness: LeadWillingness.NOW,
  borsinoSquareMeterEvaluation: {
    min: '',
    max: '',
  },
  notes: '',
};

const LeadForm: React.FC<LeadFormProps> = ({
  initialValues,
  onSubmit,
  onSubmitErrors,
  loading,
  canSetAsPersonal = false,
  innerRef,
}) => {
  const initialValuesMerged: LeadFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...(initialValues || {}),
    isPersonal: boolToBooleanString(!!initialValues?.originAgent),
  }), [initialValues]);

  return (
    <Form
      initialValues={initialValuesMerged}
      onSubmit={onSubmit}
      onSubmitErrors={onSubmitErrors}
      loading={loading}
      validationSchema={validationSchema}
      innerRef={innerRef}
      preventEnterKeypress
    >
      {({
        values,
        setFieldValue,
      }) => {
        const onPropertyTypeChange = () => {
          setFieldValue('propertyFloor', '', false);
          setFieldValue('buildingFloors', '', false);
          setFieldValue('propertyFeatures', values.propertyFeatures.filter((feature) => ![LeadPropertyFeature.BALCONE_TERRAZZO, LeadPropertyFeature.ASCENSORE].includes(feature)));
        };

        const onIsPersonalChange = () => {
          setFieldValue('originAgent', '');
        };

        return (
          <div css={styles.base}>
            <div css={styles.fields}>
              <Form.Fieldset legend="Tipologia">
                {propertyTypesRadio.map(({ value, label }) => (
                  <Form.Item
                    key={`lead-form-property-type-${value}`}
                    size={{ XS: 1 / 2, LG: 1 / 3 }}
                  >
                    <Form.RadioButton
                      name="propertyType"
                      text={label}
                      aria-label={`Tipologia immobile ${label}`}
                      value={value}
                      onChange={onPropertyTypeChange}
                    />
                  </Form.Item>
                ))}
              </Form.Fieldset>

              <Form.Fieldset legend="Indirizzo">
                <Form.Item size={{ MD: 3 / 5 }}>
                  <AddressSuggestionAutoComplete
                    name="propertyAddress"
                    label="Indirizzo"
                    aria-label="Campo per inserire l'indirizzo dell'immobile"
                    placeholder="Es: Piazza Armando Diaz, 6, Milano"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 5 }}>
                  <Form.Select
                    name="propertyFloor"
                    label="Piano"
                    aria-label="Campo per inserire il piano dell'immobile"
                    options={floorSelectOptions}
                    disabled={values.propertyType !== ResidentialPropertyTypeValue.APPARTAMENTO}
                    required
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 5 }}>
                  <Form.Input
                    type="number"
                    name="buildingFloors"
                    label="Totale piani"
                    aria-label="Campo per inserire il totale piani dell'immobile"
                    disabled={values.propertyType !== ResidentialPropertyTypeValue.APPARTAMENTO}
                    required
                  />
                </Form.Item>
              </Form.Fieldset>

              <Form.Fieldset legend="Caratteristiche">
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Input
                    type="number"
                    name="propertySize"
                    min="0"
                    label="Superficie"
                    aria-label="Campo per inserire i mq dell'immobile"
                    placeholder="Es: 60"
                    required
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Select
                    name="propertyStatus"
                    label="Stato"
                    aria-label="Campo per inserire lo stato dell'immobile"
                    options={asSelectOptions(propertyStatusLabels, false)}
                    required
                  />
                </Form.Item>
                <Form.Item>
                  <Form.CheckboxButtonGroup
                    label="L'immobile dispone di:"
                    name="propertyFeatures"
                    itemSize={{ MD: 1 / 4 }}
                    items={leadPropertyFeaturesOptions.map((option) => ({
                      ...option,
                      disabled: values.propertyType === ResidentialPropertyTypeValue.VILLA && (option.value === LeadPropertyFeature.ASCENSORE || option.value === LeadPropertyFeature.BALCONE_TERRAZZO),
                    }))}
                  />
                </Form.Item>
              </Form.Fieldset>

              {/** @todo */}
              <Form.Fieldset legend="Vincoli immobile">
                <Form.Item size={{ MD: 1 / 4 }}>
                  <Form.CheckboxButton
                    name="residualMortgage"
                    text="Mutuo residuo"
                    aria-label="Ha un mutuo residuo?"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 4 }}>
                  <Form.CheckboxButton
                    name="multiOwner"
                    text="Più proprietari"
                    aria-label="Ci sono più proprietari?"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 4 }}>
                  <Form.CheckboxButton
                    name="otherAgencies"
                    text="Altre agenzie"
                    aria-label="È in contatto con altre agenzie?"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 4 }}>
                  <Form.CheckboxButton
                    name="inheritedProperty"
                    text="Immobile ereditato"
                    aria-label="È stato ereditato?"
                    disabled={values.giftedProperty === true}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 4 }}>
                  <Form.CheckboxButton
                    name="giftedProperty"
                    text="Immobile donato"
                    aria-label="È stato donato?"
                    disabled={values.inheritedProperty === true}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 4 }}>
                  <Form.CheckboxButton
                    name="ownedByCompany"
                    text="Intestato a società"
                    aria-label="È intestato a società?"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 4 }}>
                  <Form.CheckboxButton
                    name="registryDiscrepancies"
                    text="Con difformità"
                    aria-label="Ha difformità catastali?"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 4 }}>
                  <Form.CheckboxButton
                    name="subsidizedHousing"
                    text="Soggetto a convenzione"
                    aria-label="È soggetto a convenzione edilizia?"
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 4 }}>
                  <Form.Select
                    name="registryStatus"
                    label="L'immobile è"
                    aria-label="Seleziona stato al catasto"
                    options={asSelectOptions(registryStatusLabels)}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Select
                    name="willingness"
                    label="Tra quanto vorrebbe vendere?"
                    aria-label="Tra quanto vorrebbe vendere?"
                    options={leadWillingnessSelectOptions}
                  />
                </Form.Item>
              </Form.Fieldset>

              <Form.Fieldset legend="Valutazione Borsino al m²">
                <Form.Item>
                  <Form.PriceRange
                    name="borsinoSquareMeterEvaluation"
                    label="Valutazione (min/max)"
                  />
                </Form.Item>
              </Form.Fieldset>

              <Form.Fieldset legend="Attribuzione lead">
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.YesOrNotButtons
                    label="Lead personale?"
                    name="isPersonal"
                    aria-label="Campo per specificare se la lead è personale"
                    disabled={!canSetAsPersonal}
                    onChange={onIsPersonalChange}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <AgentAutoComplete
                    name="originAgent"
                    label="Agente"
                    aria-label="Campo per inserire l'agente gestore della lead"
                    placeholder="Cerca per nome"
                    status={AgentStatus.ACTIVE}
                    disabled={!canSetAsPersonal || values.isPersonal === 'false'}
                  />
                </Form.Item>
              </Form.Fieldset>
            </div>
            <div css={styles.notes}>
              <Form.Item>
                <Form.TextArea
                  name="notes"
                  rows={10}
                  label="Note aggiuntive"
                  aria-label="Campo per inserire note aggiuntive"
                />
              </Form.Item>
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default LeadForm;
