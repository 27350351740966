/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { ActionIcon, HStack } from '@doveit/bricks';
import * as styles from './MediaActionsBar.style';
import { ExtraActionIcon } from '../../types';

export interface MediaActionsBarProps extends React.AriaAttributes {
  primary?: ExtraActionIcon[],
  secondary?: ExtraActionIcon[],
  disabled?: boolean,
}

function renderAction(disabled?: boolean, baseDataRef?: string) {
  return (action: ExtraActionIcon) => (
    <ActionIcon
      key={action.id}
      {...action}
      data-ref={`${baseDataRef}-${action.id}`}
      disabled={action.disabled || disabled}
      size="S"
    />
  );
}

const MediaActionsBar: React.FC<MediaActionsBarProps> = ({
  primary = [],
  secondary = [],
  disabled,
  ...rest
}) => {
  if (primary.length === 0 && secondary.length === 0) {
    return null;
  }

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      css={styles.basic}
      data-ref="media-actions-bar"
      {...rest}
    >
      <HStack>
        {primary.map(renderAction(disabled, 'primary-action-button'))}
      </HStack>
      <HStack>
        {secondary.map(renderAction(disabled, 'secondary-action-button'))}
      </HStack>
    </HStack>
  );
};

export default MediaActionsBar;
