import { API_NOTIFICATIONS_PATH, getClient } from '../api';
import { NotifyExactMatchingProperties, UpdateContactInfoNotification } from '../dtos';

export async function createUpdateContactInfoNotification(notification: UpdateContactInfoNotification) {
  const client = await getClient();
  await client.post<UpdateContactInfoNotification>(`${API_NOTIFICATIONS_PATH}/update-contact-info`, notification);
}

export async function notifyExactMatchingProperties(notification: NotifyExactMatchingProperties) {
  const client = await getClient();
  await client.post<NotifyExactMatchingProperties>(`${API_NOTIFICATIONS_PATH}/exact-matching-properties`, notification);
}
