import useSWR from 'swr';
import hash from 'object-hash';
import { Count } from '../../../providers/api/dtos/count';
import { ApplicationNotificationFilters, getApplicationNotificationsCount } from '../../../providers/api/application-notification/applicationNotificationProvider';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

export const DEFAULT_APPLICATION_NOTIFICATIONS_REFRESH_INTERVAL = 10000;

export const APPLICATION_NOTIFICATIONS_NEW_COUNT_RID = '/application-notifications/count';

export default function useApplicationNotificationsCount(
  filters?: ApplicationNotificationFilters,
  refreshInterval: number = DEFAULT_APPLICATION_NOTIFICATIONS_REFRESH_INTERVAL,
): SWRCustomResponse<Count, Error> {
  const matchMutate = useMatchMutate();
  const key = filters ? [APPLICATION_NOTIFICATIONS_NEW_COUNT_RID, hash({ ...filters })] : null;
  const result = useSWR(key, () => getApplicationNotificationsCount(filters!), {
    refreshInterval,
  });

  return {
    ...result,
    mutate: () => matchMutate(APPLICATION_NOTIFICATIONS_NEW_COUNT_RID),
  };
}
