import {
  Action, Badge, HStack, ICON_FILTER_OUTLINE, Modal, Spacing, Text, useModal,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { AgentStatus } from '../../domain/types';
import { useAgentFilter } from '../../hooks/agent-filter/useAgentFilter';
import { useCurrentAgent } from '../../hooks/use-agent/useAgent';
import useRBAC from '../../hooks/use-rbac/useRBAC';
import { UserRole } from '../../types';
import AgentFilterForm, { AgentFilterFormModel } from '../agent-filter-form/AgentFilterForm';
import getShortAgentName from '../../utils/agent/getShortAgentName';
import getShortAgentNameFromEmail from '../../utils/agent/getShortAgentNameFromEmail';

export const AGENT_FILTER_QUERY_PARAM = 'agent_email';

export interface FilterByAgentsEmailsProps {
  title: string,
}

const FilterByAgentsEmails: FunctionComponent<FilterByAgentsEmailsProps> = ({
  title,
}) => {
  const agent = useCurrentAgent();
  const { userHasAnyRole, userIsCoordinator } = useRBAC();
  const filtersModal = useModal();
  const {
    isExplicit, agentEmails: agents, addFilter, removeFilter,
  } = useAgentFilter('page', getShortAgentName);

  const explicitAgentFilters = useMemo(
    () => (isExplicit ? agents : []),
    [agents, isExplicit],
  );

  const onFilterClick = useCallback(
    () => filtersModal.open(),
    [filtersModal],
  );

  const onAgentFormSubmit = useCallback(
    (values: AgentFilterFormModel) => addFilter({
      email: values.agent?.email.work ?? '',
      name: values.agent?.name ? getShortAgentName(values.agent?.name) : '',
    })(),
    [addFilter],
  );

  if (!userHasAnyRole([UserRole.CALL_CENTER, UserRole.CONTENT_EDITOR, UserRole.ADMIN]) && !agent?.isManager) {
    return null;
  }

  return (
    <>
      <Action
        iconLeft={{ path: ICON_FILTER_OUTLINE }}
        label="Filtra"
        emphasis="high"
        onClick={onFilterClick}
      />
      <Modal
        open={filtersModal.isOpen}
        onCloseHandler={filtersModal.close}
      >
        <Modal.Close />
        <Modal.Header>
          <Text.H4>{title}</Text.H4>
        </Modal.Header>
        <Modal.Body padded>
          {explicitAgentFilters.length > 0 && (
            <Spacing margin={[0, 0, 400]}>
              <Text.Body>Filtri attivi:</Text.Body>
              <Spacing margin={[100, 0, 0]}>
                <HStack>
                  {explicitAgentFilters.map((agentFilter) => (
                    <Badge
                      key={agentFilter.email}
                      label={agentFilter.name || getShortAgentNameFromEmail(agentFilter.email)}
                      onRemove={removeFilter(agentFilter)}
                    />
                  ))}
                </HStack>
              </Spacing>
            </Spacing>
          )}
          <AgentFilterForm
            excludeAgentsByEmail={explicitAgentFilters.map((it) => it.email).filter((it) => !!it)}
            status={AgentStatus.ACTIVE}
            onSubmit={onAgentFormSubmit}
            startFrom={agent?.isManager && !userIsCoordinator ? agent.email.work : undefined}
            coordinatedBy={userIsCoordinator ? agent?.email.work : undefined}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FilterByAgentsEmails;
