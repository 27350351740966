/* eslint-disable react/no-unknown-property */
import {
  Action, HStack, ICON_ACCOUNT_CHECK_OUTLINE, ICON_ACCOUNT_OUTLINE, ICON_CARD_TEXT_OUTLINE, ICON_EMAIL_OUTLINE, ICON_HOME_OUTLINE, ICON_PHONE_OUTLINE, Icon, DetailItemList,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback } from 'react';
import Card, { CardStatus } from '../../../components/card/Card';
import { Contact } from '../../../providers/api/dtos/contact';
import { SearchContactFormModel } from '../search-contact-form/SearchContactForm';
import * as styles from './ContactMatchCard.style';

export interface ContactMatchCardProps {
  contact: Contact,
  searchValues?: SearchContactFormModel,
  onSelected: (contact: Contact, searchValues?: SearchContactFormModel) => void,
  onUpdate?: (contact: Contact, searchValues?: SearchContactFormModel) => void,
}

const ContactMatchCard: FunctionComponent<ContactMatchCardProps> = ({
  contact,
  onSelected,
  onUpdate,
  searchValues,
}) => {
  const onInternalSelected = useCallback(
    () => onSelected(contact),
    [contact, onSelected],
  );

  const onInternalUpdate = useCallback(
    () => {
      if (onUpdate) {
        onUpdate(contact, searchValues);
      }
    },
    [contact, searchValues, onUpdate],
  );

  return (
    <Card status={contact.complete ? CardStatus.SUCCESS : undefined}>
      <Card.Inline>
        <Card.Box>
          <DetailItemList>
            {(contact.name || searchValues?.name) && (
              <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
                <span aria-label="Indicazione del nome">
                  {contact.name}
                </span>
                {searchValues?.name && searchValues.name !== contact.name && (
                  <>
                    <span>⤑</span>
                    <span
                      css={styles.searchValue}
                      aria-label="Indicazione del nuovo nome"
                    >
                      {searchValues.name}
                    </span>
                  </>
                )}
              </DetailItemList.Item>
            )}
            {(contact.phoneNumber || searchValues?.phoneNumber) && (
              <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
                <span aria-label="Indicazione del numero di telefono">
                  {contact.phoneNumber}
                </span>
                {searchValues?.phoneNumber && searchValues.phoneNumber !== contact.phoneNumber && (
                  <>
                    <span>⤑</span>
                    <span
                      css={styles.searchValue}
                      aria-label="Indicazione del nuovo numero di telefono"
                    >
                      {searchValues.phoneNumber}
                    </span>
                  </>
                )}
              </DetailItemList.Item>
            )}
            {(contact.email || searchValues?.email) && (
              <DetailItemList.Item icon={ICON_EMAIL_OUTLINE}>
                <span aria-label="Indicazione dell'indirizzo email">
                  {contact.email}
                </span>
                {searchValues?.email && searchValues.email !== contact.email && (
                  <>
                    <span>⤑</span>
                    <span
                      css={styles.searchValue}
                      aria-label="Indicazione del nuovo indirizzo email"
                    >
                      {searchValues.email}
                    </span>
                  </>
                )}
              </DetailItemList.Item>
            )}
            {contact.fiscalCode && (
              <DetailItemList.Item icon={ICON_CARD_TEXT_OUTLINE}>
                {contact.fiscalCode}
              </DetailItemList.Item>
            )}
            {contact.residence?.normalizedAddress && (
              <DetailItemList.Item icon={ICON_HOME_OUTLINE}>
                {contact.residence?.normalizedAddress}
              </DetailItemList.Item>
            )}
          </DetailItemList>
        </Card.Box>
        <Card.Box fit>
          <div
            css={styles.rightBox}
            $complete={contact.complete}
          >
            {contact.complete && (
              <div css={styles.completeContact}>
                Completo
                {' '}
                <Icon
                  path={ICON_ACCOUNT_CHECK_OUTLINE}
                />
              </div>
            )}
            <HStack>
              <Action
                aria-label="Pulsante per selezionare il contatto"
                label="Seleziona"
                size="S"
                emphasis="high"
                color="info"
                onClick={onInternalSelected}
              />
              {onUpdate && (
                <Action
                  aria-label="Pulsante per aggiornare il contatto"
                  dataRef="update-button"
                  label="Aggiorna"
                  size="S"
                  emphasis="high"
                  color="critical"
                  onClick={onInternalUpdate}
                />
              )}
            </HStack>
          </div>
        </Card.Box>
      </Card.Inline>
    </Card>
  );
};

export default ContactMatchCard;
