import React from 'react';
import {
  Stack, HStack, Text, Spacing, CardSkeleton, Message, Action, ActionDropDown, Select, ActionIconDropdown, ICON_CUSTOM_SORT_PLUS, ICON_SORT, ICON_CHECK,
} from '@doveit/bricks';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import { useSerpProspects } from '../../hooks/use-serp-prospects/useSerpProspects';
import usePagination from '../../../hooks/use-pagination/usePagination';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import SimpleTableSkeleton from '../../../components/simple-table/SimpleTable.skeleton';
import {
  ALL_PROSPECTS_FILTER_OPTION, FILTER_OPTIONS, FILTERS_MAPPER, GROUPED_FILTER_OPTIONS, SERP_PROSPECTS_ERROR_MESSAGE, DEFAULT_SORT_OPTION, EMPTY_SERP_PROSPECTS, MOST_RECENT_CREATION_SORT_OPTION, SORT_OPTIONS, SORT_OPTIONS_PER_FILTER, DEFAULT_FILTER_OPTION,
} from './constants';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import SimpleTable from '../../../components/simple-table/SimpleTable';
import useFilters from '../../../hooks/use-filters/useFilters';
import { useAgentFilter } from '../../../hooks/agent-filter/useAgentFilter';
import getShortAgentName from '../../../utils/agent/getShortAgentName';
import ViewSerpProspectCard from '../../containers/view-serp-prospect-card/ViewSerpProspectCard';
import ViewSerpProspectTableRow from '../../containers/view-serp-prospect-table-row/ViewSerpProspectTableRow';
import SearchByQueryAction from '../../../components/search-by-query-action/SearchByQueryAction';
import AgentFilterAction from '../../../components/agent-filter-action/AgentFilterAction';
import { ProspectSerpPageFilterKeys } from './types';
import SerpFiltersSummary from '../../../components/serp-filters-summary/SerpFiltersSummary';

const ProspectSerpPage: React.FC = () => {
  const isDesktop = useIsDevice('desktop');
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const { page, paginationQueryParam, goToPage } = usePagination('page', scrollRef);
  const {
    filters,
    addFilters,
    removeFilters,
    removeFilter,
  } = useFilters(FILTERS_MAPPER);
  const { agentEmails: agents, isExplicit: isAgentFilterExplicit, replaceFilter: replaceAgentFilter } = useAgentFilter(paginationQueryParam, getShortAgentName);

  const agentEmail = React.useMemo(() => agents.map((it) => it.email || ''), [agents]);

  const sortOption = React.useMemo(() => {
    if (filters.sort && filters.sort !== DEFAULT_SORT_OPTION.key) {
      return SORT_OPTIONS.find(({ key }) => key === filters.sort);
    }
    if (filters.filter) {
      return SORT_OPTIONS_PER_FILTER[filters.filter!];
    }
    return MOST_RECENT_CREATION_SORT_OPTION;
  }, [filters.filter, filters.sort]);

  const {
    data: serpProspects,
    isLoading: areSerpProspectsLoading,
    error: serpProspectsError,
    mutate: mutateSerpProspects,
  } = useSerpProspects(
    {
      q: filters.q,
      agentEmail,
      ...(FILTER_OPTIONS.find(({
        key,
      }) => key === filters.filter)?.value),
    }, { page, sort: sortOption?.value },
  );

  const filterLabel = React.useMemo(() => {
    if (filters.filter === ALL_PROSPECTS_FILTER_OPTION.key) {
      return '';
    }

    const filterObject = FILTER_OPTIONS.find((option) => option.key === filters.filter);

    return filterObject?.label.long.toLowerCase();
  }, [filters.filter]);

  const handleSerpProspectsUpdate = React.useCallback(async () => {
    await mutateSerpProspects();
  }, [mutateSerpProspects]);

  const handleSearch = React.useCallback((query: string) => {
    if (query) {
      addFilters({ q: query });
      removeFilter(paginationQueryParam);
    } else {
      removeFilters(['q', paginationQueryParam]);
    }
  }, [addFilters, paginationQueryParam, removeFilter, removeFilters]);

  const handleBadgeClick = React.useCallback((badgeFilter: ProspectSerpPageFilterKeys) => () => {
    removeFilter(paginationQueryParam);
    addFilters({ filter: badgeFilter });
  }, [addFilters, paginationQueryParam, removeFilter]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Bar>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          gap={100}
        >
          <div style={{ flexGrow: '1' }}>
            {isDesktop && (
              <HStack aria-label="Filtri (desktop)" gap={150}>
                <Action
                  label={DEFAULT_FILTER_OPTION.label.short}
                  aria-label="Da gestire"
                  onClick={filters.filter !== DEFAULT_FILTER_OPTION.key
                    ? handleBadgeClick(DEFAULT_FILTER_OPTION.key)
                    : undefined}
                  color={filters.filter !== DEFAULT_FILTER_OPTION.key
                    ? 'neutral'
                    : 'primary'}
                />

                {GROUPED_FILTER_OPTIONS.map(({ label, options }) => (
                  <ActionDropDown
                    key={label}
                    label={label}
                    aria-label={`Filtra valutazioni per ${label}`}
                    emphasis="low"
                    color={options.some(({ key }) => key === filters.filter) ? 'primary' : 'neutral'}
                    options={options.map(({ key, label: optionLabel }) => ({
                      key,
                      label: optionLabel.short,
                      color: filters.filter !== key ? 'neutral' : 'primary',
                      iconRight: filters.filter !== key ? undefined : { path: ICON_CHECK },
                      onClick: filters.filter !== key ? handleBadgeClick(key) : undefined,
                    }))}
                  />
                ))}

                <Action
                  label={ALL_PROSPECTS_FILTER_OPTION.label.short}
                  aria-label="Tutte le valutazioni"
                  onClick={filters.filter !== ALL_PROSPECTS_FILTER_OPTION.key
                    ? handleBadgeClick(ALL_PROSPECTS_FILTER_OPTION.key)
                    : undefined}
                  color={filters.filter !== ALL_PROSPECTS_FILTER_OPTION.key
                    ? 'neutral'
                    : 'primary'}
                />
              </HStack>
            )}
            {!isDesktop && (
              <Select
                value={filters.filter}
                aria-label="Seleziona un filtro della valutazione (mobile)"
                options={FILTER_OPTIONS.map(({ label, key }) => ({
                  label: label.long,
                  value: key,
                }))}
                size="S"
                onChange={({ target }) => handleBadgeClick(target.value as ProspectSerpPageFilterKeys)()}
              />
            )}
          </div>
          <HStack
            alignItems="center"
            gap={100}
          >
            <SearchByQueryAction
              initialQuery={filters.q}
              onSearch={handleSearch}
              placeholder="Cerca per contatto o immobile"
              size={isDesktop ? 'M' : 'S'}
              variant={isDesktop ? 'input' : 'action'}
            />
            <AgentFilterAction
              size={isDesktop ? 'M' : 'S'}
              onSubmit={(emails) => replaceAgentFilter(emails)()}
            />
            <ActionIconDropdown
              label="Ordina"
              aria-label="Pulsante per selezionare l'ordinamento delle valutazioni"
              placement="bottom-end"
              icon={{ path: !filters.sort || filters.sort !== DEFAULT_SORT_OPTION.key ? ICON_CUSTOM_SORT_PLUS : ICON_SORT }}
              size={isDesktop ? 'M' : 'S'}
              color={filters.sort && filters.sort !== DEFAULT_SORT_OPTION.key ? 'primary' : 'neutral'}
              emphasis="low"
              options={SORT_OPTIONS.map(({ key, label }) => ({
                key,
                label,
                iconRight:
                  // ICON_CHECK on "default" if no sort option has been selected
                  (!filters.sort && key === DEFAULT_SORT_OPTION.key)
                    // or the sort option is the current one
                    || filters.sort === key ? { path: ICON_CHECK } : undefined,
                color:
                  // Apply neutral color if "default" sort option has been selected
                  key !== DEFAULT_SORT_OPTION.key
                    // Apply the primary color if the sort option is the current one
                    && filters.sort === key
                    ? 'primary'
                    : 'neutral',
                onClick: () => addFilters({ sort: key }),
              }))}
            />
          </HStack>
        </HStack>
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        <HStack justifyContent="space-between" alignItems="center">
          <Stack gap={50}>
            <Text.H4 aria-label="Titolo pagina">
              {`Valutazioni${filterLabel ? ` ${filterLabel}` : ''}${(serpProspects && serpProspects.totalElements > 0) ? ` (${serpProspects.totalElements})` : ''}`}
            </Text.H4>
            <SerpFiltersSummary
              aria-label="search-filters-summary"
              filters={{ ...filters, agentFilters: { values: agents, isExplicit: isAgentFilterExplicit } }}
              sortOptions={SORT_OPTIONS}
            />
          </Stack>
        </HStack>
        <Spacing margin={[300, 0, 0]} aria-label="Lista valutazioni">
          {areSerpProspectsLoading && isDesktop && (
            <SimpleTableSkeleton aria-label="Sezione valutazioni in caricamento" />
          )}
          {areSerpProspectsLoading && !isDesktop && (
            <Stack aria-label="Sezione valutazioni in caricamento">
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </Stack>
          )}
          {serpProspectsError && !areSerpProspectsLoading && (
            <Message message={SERP_PROSPECTS_ERROR_MESSAGE} type="critical" boxed />
          )}
          {(!areSerpProspectsLoading && (serpProspects && serpProspects.totalElements > 0)) && (
            <PaginatedList
              {...serpProspects}
              hideResultCount
              goToPage={goToPage}
            >
              {!isDesktop && serpProspects.content.map((serpProspect) => (
                <ViewSerpProspectCard
                  key={serpProspect.prospect.id}
                  serpProspect={serpProspect}
                  onAppointmentCreate={handleSerpProspectsUpdate}
                  onAppointmentUpdate={handleSerpProspectsUpdate}
                  onProspectUpdate={handleSerpProspectsUpdate}
                  onReminderCreate={handleSerpProspectsUpdate}
                />
              ))}
              {isDesktop && (
                <SimpleTable>
                  <SimpleTable.Body>
                    {serpProspects.content.map((serpProspect) => (
                      <SimpleTable.Row
                        asChild
                        key={serpProspect.prospect.id}
                      >
                        <ViewSerpProspectTableRow
                          key={serpProspect.prospect.id}
                          serpProspect={serpProspect}
                          onAppointmentCreate={handleSerpProspectsUpdate}
                          onAppointmentUpdate={handleSerpProspectsUpdate}
                          onProspectUpdate={handleSerpProspectsUpdate}
                          onReminderCreate={handleSerpProspectsUpdate}
                        />
                      </SimpleTable.Row>
                    ))}
                  </SimpleTable.Body>
                </SimpleTable>
              )}
            </PaginatedList>
          )}
          {(serpProspects && serpProspects.totalElements === 0) && (
            <Message message={EMPTY_SERP_PROSPECTS} boxed />
          )}
        </Spacing>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default ProspectSerpPage;
