import { Avatar, AvatarProps, ICON_HOME_OUTLINE } from '@doveit/bricks';
import React from 'react';
import { AgencyPreviewStatus } from '../types';
import { ThemeIcons } from '../../../theme/icons';
import { mapAgencyPreviewStatusToColor } from '../constants';
import { HYGRAPH_ASSET_BASE_URL } from '../../../providers/api/api';

export type AgencyAvatarProps = Omit<AvatarProps, 'color' | 'icon'> & {
  status?: AgencyPreviewStatus,
  franchising?: boolean,
  photoId?: string,
};

export const AgencyAvatar: React.FC<AgencyAvatarProps> = ({
  status,
  franchising,
  src,
  photoId,
  ...rest
}) => {
  const agencyAvatarUrl = React.useMemo(() => {
    if (src) {
      return src;
    }

    return photoId
      ? `${HYGRAPH_ASSET_BASE_URL}/${photoId}`
      : undefined;
  }, [photoId, src]);

  return (
    <Avatar
      {...rest}
      src={agencyAvatarUrl}
      placeholder={ICON_HOME_OUTLINE}
      icon={!franchising ? ThemeIcons.DOVE : undefined}
      color={status ? mapAgencyPreviewStatusToColor[status] : undefined}
    />
  );
};

export default AgencyAvatar;
