import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const headerTitle: ThemedFunctor = () => css`
  flex-grow: 1;
`;

export const headerActions: ThemedFunctor = () => css`
  flex-shrink: 0;
`;
