import {
  Action, ActionIcon, ICON_TRASH_CAN_OUTLINE, Message, Portal, Spacing, Typography, useModal, useNotifications,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback } from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { PropertyContact, PropertyContactReference } from '../../../providers/api/dtos';
import { deletePropertyContact } from '../../../providers/api/property-contact/propertyContactProvider';

export interface ChildFnProps {
  onDelete: VoidFunction,
}

export interface DeletePropertyContactProps {
  propertyContact: PropertyContact,
  propertyContactsTotal: number,
  onSuccess?: VoidFunction,
  onAdd?: VoidFunction,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const DELETE_PROPERTY_CONTACT_WARNING_MESSAGE = 'Cliccando il tasto conferma rimuoverai la relazione tra il contatto e l\'immobile, ma non eliminerai il contatto';
export const CANNOT_DELETE_UNIQUE_PROPERTY_CONTACT_ERROR_MESSAGE = 'Non è possibile eliminare l\'unico referente dell\'immobile. Aggiungi un nuovo referente prima di eliminare quello corrente';
export const PROPERTY_CONTACT_DELETE_SUCCESS_MESSAGE = 'Il referente è stato eliminato con successo';
export const PROPERTY_CONTACT_DELETE_ERROR_MESSAGE = 'Non è stato possibile eliminare il referente';

export const DeletePropertyContact: FunctionComponent<DeletePropertyContactProps> = ({
  propertyContact: {
    contact,
    property,
    relationship,
  },
  propertyContactsTotal,
  onSuccess,
  onAdd,
  children,
}) => {
  const deletePropertyContactModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const canDelete = propertyContactsTotal > 1;

  const onDelete = useCallback(() => {
    deletePropertyContactModal.open();
  }, [deletePropertyContactModal]);

  const onInternalAdd = useCallback(() => {
    deletePropertyContactModal.close();

    if (onAdd) {
      onAdd();
    }
  }, [deletePropertyContactModal, onAdd]);

  const onSubmit = useCallback(async () => {
    try {
      const propertyContactReference: PropertyContactReference = {
        contactId: contact.id!,
        propertyId: property.id!,
        relationship,
      };

      await deletePropertyContact(propertyContactReference);

      addSuccess(PROPERTY_CONTACT_DELETE_SUCCESS_MESSAGE);
      deletePropertyContactModal.close();

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      addError(PROPERTY_CONTACT_DELETE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, contact.id, deletePropertyContactModal, onSuccess, property.id, relationship]);

  return (
    <>
      {children
        ? children({ onDelete })
        : (
          <ActionIcon
            label="Elimina referente"
            icon={{ path: ICON_TRASH_CAN_OUTLINE }}
            color="critical"
            size="S"
            onClick={onDelete}
            aria-label="Pulsante per rimuovere un contatto di un immobile"
            data-ref="delete-property-contact-action"
          />
        )}
      <Portal>
        <SimpleModal
          {...deletePropertyContactModal}
          title="Elimina referente"
          footer={canDelete
            ? (
              <Action
                label="Elimina"
                color="primary"
                emphasis="high"
                onClick={onSubmit}
              />
            )
            : (
              <Action
                label="Chiudi"
                emphasis="high"
                onClick={deletePropertyContactModal.close}
              />
            )}
        >
          {canDelete && (
            <Spacing margin={[0, 0, 100]}>
              <Typography.BODY>Confermi di voler eliminare il referente?</Typography.BODY>
            </Spacing>
          )}

          <Message
            type="warning"
            message={canDelete
              ? DELETE_PROPERTY_CONTACT_WARNING_MESSAGE
              : CANNOT_DELETE_UNIQUE_PROPERTY_CONTACT_ERROR_MESSAGE}
            actions={(!canDelete && onAdd)
              ? [
                <Action
                  onClick={onInternalAdd}
                  label="Aggiungi referente"
                  size="S"
                  color="warning"
                  emphasis="high"
                />,
              ] : undefined}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default DeletePropertyContact;
