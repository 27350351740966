import { NonResidentialPropertyType, PropertyType, ResidentialPropertyType } from '../domain/types';

export const residentialPropertyTypeLabels: Record<ResidentialPropertyType, string> = {
  APPARTAMENTO: 'Appartamento',
  CASA_INDIPENDENTE: 'Casa indipendente',
  ATTICO: 'Attico',
  MANSARDA: 'Mansarda',
  LOFT: 'Loft',
  VILLA: 'Villa',
  VILLETTA_A_SCHIERA: 'Villetta a schiera',
  RUSTICO_CASALE: 'Rustico/Casale',
  PALAZZO: 'Palazzo',
};

export const nonResidentialPropertyTypeLabels: Record<NonResidentialPropertyType, string> = {
  TERRENO: 'Terreno',
  LOCALE_COMMERCIALE: 'Locale commerciale',
  GARAGE: 'Garage',
  UFFICIO: 'Ufficio',
  MAGAZZINO: 'Magazzino',
  CAPANNONE: 'Capannone',
};

export const propertyTypeLabels: {
  [key in PropertyType]: string
} = {
  ...residentialPropertyTypeLabels,
  ...nonResidentialPropertyTypeLabels,
};
