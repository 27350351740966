/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Action, ActionIcon, Card, ICON_PLUS, ICON_TRASH_CAN_OUTLINE, Message, Portal, Spacing, Stack, TextArea, useModal, DetailItemList,
  HStack,
} from '@doveit/bricks';
import objectHash from 'object-hash';
import { formatEuro } from '@doveit/hammer';
import { isEqual } from 'lodash';
import { OfferWizardStepProps } from '../types';
import { OfferWizardData, PropertyUnit } from '../../../../providers/api/dtos';
import { ADD_PROPERTY_UNITS_INFO_MESSAGE } from './constants';
import PropertyGeoForm, { PropertyGeoFormModel } from '../../../../property/components/property-geo-form/PropertyGeoForm';
import { PASS_THROUGH_VALIDATION_SCHEMA } from '../../../../constants';
import Divider from '../../../../components/divider/Divider';
import PropertyUnitForm, { PropertyUnitFormModel } from '../../../../property/components/property-unit-form/PropertyUnitForm';
import { toOfferWizardData, toPropertyGeoFormModel } from './mappers';
import SimpleModal from '../../../../components/simple-modal/SimpleModal';
import { registryCategoryLabels } from '../../../../labels/registryCategoryLabels';
import { energyClassLabels, propertyUnitIntendedUseLabels } from '../../../../labels';
import useProperty from '../../../../property/hooks/use-property/useProperty';
import { PropertyUnitModalData } from '../../../../property/types';
import { toPropertyUnit, toPropertyUnitFormModel } from '../../../../property/components/property-unit-form/mappers';

const OfferWizardPropertyGeo: React.FC<OfferWizardStepProps> = ({
  intent,
  initialData,
  formRef,
  loading = false,
  onSubmit,
}) => {
  const propertyUnitModal = useModal<PropertyUnitModalData>();

  const { data: property, isLoading: isPropertyLoading } = useProperty(intent.propertyId);

  const [isEditingPropertyUnits, setIsEditingPropertyUnits] = React.useState(false);
  const [propertyUnits, setPropertyUnits] = React.useState([...(initialData.property?.cadastralRegistry?.units ?? property?.cadastralRegistry?.units ?? [])]);
  const [propertyComposition, setPropertyComposition] = React.useState(initialData.property?.composition);

  const addPropertyUnit = React.useCallback((formValues: PropertyUnitFormModel) => {
    setIsEditingPropertyUnits(true);

    const propertyUnit = toPropertyUnit(formValues);

    setPropertyUnits((current) => [...current, propertyUnit]);

    setIsEditingPropertyUnits(false);
  }, []);

  const editPropertyUnit = React.useCallback((formValues: PropertyUnitFormModel, index: number) => {
    setIsEditingPropertyUnits(true);

    const propertyUnit = toPropertyUnit(formValues);

    setPropertyUnits((current) => {
      current[index] = propertyUnit;

      return current;
    });

    setIsEditingPropertyUnits(false);
  }, []);

  const removePropertyUnit = React.useCallback((index: number) => () => {
    setPropertyUnits((prev) => {
      const toSplice = prev.slice();
      toSplice.splice(index, 1);

      return toSplice;
    });
  }, []);

  const handlePropertyUnitFormSubmit = React.useCallback((formValues: PropertyUnitFormModel) => {
    const { data } = propertyUnitModal;

    if (typeof data?.index !== 'undefined') {
      editPropertyUnit(formValues, data.index);
    } else {
      addPropertyUnit(formValues);
    }

    propertyUnitModal.close();
  }, [addPropertyUnit, editPropertyUnit, propertyUnitModal]);

  const openPropertyUnitModal = React.useCallback((data?: PropertyUnit, index?: number) => () => {
    propertyUnitModal.open({ propertyUnit: data, index });
  }, [propertyUnitModal]);

  const handlePropertyGeoFormSubmit = React.useCallback(async (formValues: PropertyGeoFormModel) => {
    const newAssignmentWizardData = await toOfferWizardData(initialData, property!, formValues);

    /**
     * @todo fix the type in bricks
     */
    const isGeoFormDirty = (formRef?.current as any)?.dirty ?? false;
    const arePropertyUnitsDirty = !isEqual(initialData.property?.cadastralRegistry?.units ?? [], propertyUnits);
    const isPropertyCompositionDirty = initialData.property?.composition !== propertyComposition;

    const withPropertyUnitsAndComposition: OfferWizardData = {
      ...newAssignmentWizardData,
      property: {
        ...newAssignmentWizardData.property,
        composition: propertyComposition?.trim() || undefined,
        cadastralRegistry: {
          ...newAssignmentWizardData.property?.cadastralRegistry,
          units: propertyUnits.length > 0 ? propertyUnits : undefined,
        },
      },
    };

    onSubmit(withPropertyUnitsAndComposition, isGeoFormDirty || arePropertyUnitsDirty || isPropertyCompositionDirty);
  }, [formRef, initialData, onSubmit, property, propertyComposition, propertyUnits]);

  return (
    <>
      <>
        <PropertyGeoForm
          validationSchema={PASS_THROUGH_VALIDATION_SCHEMA}
          innerRef={formRef}
          initialValues={toPropertyGeoFormModel(initialData, property)}
          onSubmit={handlePropertyGeoFormSubmit}
          loading={loading || isPropertyLoading}
        />

        <Divider>
          Dati catastali
        </Divider>

        <HStack alignItems="center" justifyContent="end">
          {propertyUnits.length === 0 && (
            <Message
              type="info"
              message={ADD_PROPERTY_UNITS_INFO_MESSAGE}
            />
          )}
          <Action
            label="Unità immobiliare"
            aria-label="Aggiungi unità immobiliare"
            iconLeft={{ path: ICON_PLUS }}
            size="S"
            onClick={openPropertyUnitModal()}
          />
        </HStack>

        {propertyUnits.length > 0 && (
          <Spacing margin={[200, 0]}>
            <Stack aria-label="Unità immobiliari">
              {propertyUnits.map((propertyUnit, i) => {
                const {
                  category,
                  energyClass,
                  intendedUse,
                  parcel,
                  section,
                  sheet,
                  subunit,
                  value,
                } = propertyUnit;

                /**
                 * @todo create a dedicated PropertyUnitCard, replace it here and on AssignmentWizardProperty
                 */
                return (
                  <Card
                    key={objectHash({ ...propertyUnit, i })}
                    aria-label="Resoconto unità immobiliare"
                  >
                    <Card.Header
                      primaryActions={[
                        <Action
                          label="Modifica"
                          aria-label="Modifica unità immobiliare"
                          size="S"
                          onClick={openPropertyUnitModal(propertyUnit, i)}
                        />,
                        <ActionIcon
                          label="Elimina"
                          icon={{ path: ICON_TRASH_CAN_OUTLINE }}
                          color="critical"
                          size="S"
                          aria-label="Elimina unità immobiliare"
                          onClick={removePropertyUnit(i)}
                        />,
                      ]}
                      title=" Unità immobiliare"
                    />

                    <Card.Content>
                      <DetailItemList columns={4}>
                        <DetailItemList.Item label="Sezione" inline={false}>
                          {section}
                        </DetailItemList.Item>
                        <DetailItemList.Item label="Categoria" inline={false}>
                          {category && registryCategoryLabels[category]}
                        </DetailItemList.Item>
                        <DetailItemList.Item label="Foglio" inline={false}>
                          {sheet}
                        </DetailItemList.Item>
                        <DetailItemList.Item label="Destinazione d'uso" inline={false}>
                          {intendedUse && propertyUnitIntendedUseLabels[intendedUse]}
                        </DetailItemList.Item>
                        <DetailItemList.Item label="Particella" inline={false}>
                          {parcel}
                        </DetailItemList.Item>
                        <DetailItemList.Item label="Rendita catastale" inline={false}>
                          {value && formatEuro(value)}
                        </DetailItemList.Item>
                        <DetailItemList.Item label="Subalterno" inline={false}>
                          {subunit}
                        </DetailItemList.Item>
                        <DetailItemList.Item label="Classe energetica" inline={false}>
                          {energyClass && energyClassLabels[energyClass]}
                        </DetailItemList.Item>
                      </DetailItemList>
                    </Card.Content>
                  </Card>
                );
              })}
            </Stack>
          </Spacing>
        )}

        <Divider>
          Composizione
        </Divider>

        <TextArea
          style={{ resize: 'vertical' }}
          aria-label="Composizione dell'immobile"
          value={propertyComposition}
          onChange={({ target }) => setPropertyComposition(target.value)}
          rows={6}
        />
      </>

      <Portal>
        <SimpleModal
          {...propertyUnitModal}
          aria-label={propertyUnitModal.data?.propertyUnit
            ? 'Modale per la modifica dell\'unità immobiliare'
            : 'Modale per l\'aggiunta di un\'unità immobiliare'}
          title={propertyUnitModal.data?.propertyUnit
            ? 'Modifica unità immobiliare'
            : 'Aggiungi unità immobiliare'}
        >
          <PropertyUnitForm
            onSubmit={handlePropertyUnitFormSubmit}
            initialValues={propertyUnitModal.data?.propertyUnit
              ? toPropertyUnitFormModel(propertyUnitModal.data.propertyUnit)
              : {}}
            loading={isEditingPropertyUnits}
            submitLabel={propertyUnitModal.data?.propertyUnit
              ? 'Modifica'
              : 'Aggiungi'}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default OfferWizardPropertyGeo;
