import React, {
  FunctionComponent, useCallback, useState, useMemo,
} from 'react';
import { useNotifications } from '@doveit/bricks';
import { ReminderV3 } from '../../../providers/api/dtos/reminder';
import {
  createReminderV3, updateReminderV3,
} from '../../../providers/api/reminder/reminderProvider';
import { formDateTimeToDate, formatInputDate, formatInputTime } from '../../../utils/form';
import ReminderForm, { ReminderFormProps } from '../../components/reminder-form/ReminderForm';
import { ReminderFormModel } from '../../../prospect/models/ReminderFormModel';

export const UPSERT_REMINDER_ERROR_MESSAGE = 'Non è stato possibile salvare il promemoria';
export const CREATE_REMINDER_SUCCESS_MESSAGE = 'Il promemoria è stato creato';
export const UPDATE_REMINDER_SUCCESS_MESSAGE = 'Il promemoria è stato aggiornato';

export type PartialReminder = Omit<ReminderV3, 'startDate' | 'endDate' | 'notes' | 'expired'> & {
  startDate?: ReminderV3['startDate'],
  endDate?: ReminderV3['endDate'],
  notes?: ReminderV3['notes']
  expired?: ReminderV3['expired'],
};

export interface UpsertReminderV3Props {
  reminder: PartialReminder,
  onSuccess?: (reminder: ReminderV3) => void,
  onError?: VoidFunction,
  formRef?: ReminderFormProps['formRef'],
  hideSubmit?: boolean,
}

const UpsertReminderV3: FunctionComponent<UpsertReminderV3Props> = ({
  reminder,
  onSuccess,
  onError,
  formRef,
  hideSubmit,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();

  const initialValues = useMemo(() => {
    if (reminder.id) {
      return {
        id: reminder.id?.toString(),
        date: reminder.startDate ? formatInputDate(new Date(reminder.startDate)) : '',
        time: reminder.startDate ? formatInputTime(new Date(reminder.startDate)) : '',
        notes: reminder.notes ? reminder.notes : '',
      };
    }

    return {};
  }, [reminder]);

  const onSubmit = useCallback(async (values: ReminderFormModel) => {
    setIsSaving(true);

    try {
      const payload: ReminderV3 = {
        ...reminder,
        expired: !!reminder.expired,
        startDate: formDateTimeToDate(values.date!, values.time!).toISOString(),
        endDate: formDateTimeToDate(values.date!, values.time!).toISOString(),
        notes: values.notes!,
      };

      const result = await (reminder.id
        ? updateReminderV3(reminder.id, payload)
        : createReminderV3(payload)
      );

      setIsSaving(false);
      addSuccess(reminder.id
        ? UPDATE_REMINDER_SUCCESS_MESSAGE
        : CREATE_REMINDER_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      setIsSaving(false);
      addError(UPSERT_REMINDER_ERROR_MESSAGE);

      if (onError) {
        onError();
      }
    }
  },
  [onSuccess, reminder, addSuccess, addError, onError]);

  return (
    <ReminderForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      loading={isSaving}
      formRef={formRef}
      hideSubmit={hideSubmit}
    />
  );
};

export default UpsertReminderV3;
