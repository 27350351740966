import { FileError } from 'react-dropzone';

export function getNormalizedFileName(fileName: string): string {
  const extension = fileName.split('.').pop();
  const firstPart = fileName.substring(0, fileName.lastIndexOf(extension!));
  const firstPartNormalized = firstPart.replace(/[^A-Za-z0-9_\\-]/g, '');

  return [firstPartNormalized, extension].join('.');
}

export interface Validator<T, E extends FileError> {
  (fileName: string, validationContext?: T, normalize?: (toNormalize: string) => string): E | null
}

export interface FileAlreadyExistsError extends FileError {
  message: FileError['message'],
  code: 'file-already-exists'
}

export const duplicatedFileNameValidator: Validator<string[], FileAlreadyExistsError> = (fileName, validationContext, normalize) => {
  const normalizedFileName = normalize ? normalize(fileName) : fileName;
  const normalizedValidationContext = validationContext?.map((existingFileName) => (normalize ? normalize(existingFileName) : existingFileName));

  const fileAlreadyExists = normalizedValidationContext?.some((existingFileName) => existingFileName === normalizedFileName);

  if (fileAlreadyExists) {
    const fileAlreadyExistsError: FileAlreadyExistsError = {
      message: 'File already exists',
      code: 'file-already-exists',
    };

    return fileAlreadyExistsError;
  }

  return null;
};

export const downloadFile = (
  data: BlobPart,
  mimeType: string,
  fileName: string = 'file',
) => {
  const blob = new Blob([data], { type: mimeType });
  const fileURL = URL.createObjectURL(blob);

  const anchor = document.createElement('a');
  anchor.href = fileURL;
  anchor.download = fileName;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(fileURL);
};
