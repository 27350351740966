import React from 'react';
import {
  Action, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { updateAgent } from '../../../providers/api/agent/agentProvider';
import EditAgentTeamForm, { EditAgentTeamFormModel } from '../../components/edit-agent-team-form/EditAgentTeamForm';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { JobTitle } from '../../../domain/types';

interface ChildFnProps {
  openEditModal: VoidFunction,
  isSaving: boolean,
}

export interface EditAgentTeamActionProps {
  agent: Agent,
  onSuccess?: (updatedAgent: Agent) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const EDIT_AGENT_TEAM_SUCCESS_MESSAGE = 'Le informazioni del team sono state modificate con successo';
export const EDIT_AGENT_TEAM_ERROR_MESSAGE = 'Non è stato possibile modificare le informazioni del team';

const EditAgentTeamAction: React.FC<EditAgentTeamActionProps> = (props) => {
  const { agent, onSuccess, children } = props;

  const { data: agentAreaManager } = useAgent(agent.areaManagerId);
  const { data: agentParent } = useAgent(agent.idParent);

  const modal = useModal();
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);

  const openEditModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const formInitialValues: Partial<EditAgentTeamFormModel> = React.useMemo(() => ({
    areaManager: agentAreaManager,
    parent: agentParent,
    agencies: agent.agencies,
    jobTitle: agent.jobTitle,
  }), [agent.agencies, agent.jobTitle, agentAreaManager, agentParent]);

  const onSubmit = React.useCallback(async (formValues: EditAgentTeamFormModel) => {
    try {
      setIsSaving(true);

      const {
        agencies, jobTitle, areaManager, parent,
      } = formValues;

      const agentToUpdate: Agent = {
        ...agent,
        agencies,
        areaManagerId: areaManager?.id,
        idParent: parent?.id,
        jobTitle: jobTitle as JobTitle,
      };

      const updatedAgent = await updateAgent(agent.id!, agentToUpdate);

      setIsSaving(false);
      addSuccess(EDIT_AGENT_TEAM_SUCCESS_MESSAGE);
      modal.close();

      if (onSuccess) {
        onSuccess(updatedAgent);
      }
    } catch (error) {
      setIsSaving(false);
      addError(EDIT_AGENT_TEAM_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, agent, modal, onSuccess]);

  return (
    <>
      {children
        ? children({ openEditModal, isSaving })
        : (
          <Action
            aria-label="Modifica le informazioni del team dell'agente"
            size="S"
            label="Modifica"
            onClick={openEditModal}
            loading={isSaving}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Modifica team"
        >
          <EditAgentTeamForm
            initialValues={formInitialValues}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default EditAgentTeamAction;
