import { geolocate } from '../../public-api/location/locationProvider';
import { GeoCodeResult } from '../dtos';

function getAddressComponent(geocoderResult: google.maps.GeocoderResult, type: string) {
  return geocoderResult.address_components.find((component) => component.types[0] === type)?.long_name;
}

export async function enrichGecodeResultWithSuburb(results: GeoCodeResult[]): Promise<Array<GeoCodeResult>> {
  const promises = results
    .map((result) => geolocate({
      latitude: (result.latitude!),
      longitude: (result.longitude!),
    })
      .then(({ suburb }) => ({ ...result, suburb: suburb?.name || null }))
      .catch((_) => result));

  return Promise.all(promises);
}

export function mapGeocodeResult(geocoderResult: google.maps.GeocoderResult): GeoCodeResult {
  return {
    placeId: geocoderResult.place_id,
    locality: getAddressComponent(geocoderResult, 'administrative_area_level_3') || getAddressComponent(geocoderResult, 'locality'),
    route: getAddressComponent(geocoderResult, 'route'),
    streetNumber: getAddressComponent(geocoderResult, 'street_number'),
    administrativeAreaLevelOne: getAddressComponent(geocoderResult, 'administrative_area_level_1'),
    administrativeAreaLevelTwo: getAddressComponent(geocoderResult, 'administrative_area_level_2'),
    country: getAddressComponent(geocoderResult, 'country'),
    postalCode: getAddressComponent(geocoderResult, 'postal_code'),
    latitude: geocoderResult.geometry.location.lat().toString(),
    longitude: geocoderResult.geometry.location.lng().toString(),
  };
}

export async function geocodeByAddress(address: string): Promise<GeoCodeResult[]> {
  const geocoder = new google.maps.Geocoder();
  const { OK, ZERO_RESULTS } = google.maps.GeocoderStatus;

  return new Promise<GeoCodeResult[]>((resolve, reject) => {
    geocoder.geocode({ address, region: 'IT' }, (results, status) => {
      if (status !== OK && status !== ZERO_RESULTS) {
        reject(new Error(`google.maps geocode failed with status: ${status}`));
      }
      resolve(results?.map(mapGeocodeResult) ?? []);
    });
  }).then((results) => enrichGecodeResultWithSuburb(results));
}

export async function reverseGeocode(latitude: number, longitude: number): Promise<GeoCodeResult[]> {
  const geocoder = new google.maps.Geocoder();
  const { OK, ZERO_RESULTS } = google.maps.GeocoderStatus;

  return new Promise<GeoCodeResult[]>((resolve, reject) => {
    geocoder.geocode({
      location: {
        lat: latitude,
        lng: longitude,
      },
    }, (results, status) => {
      if (status !== OK && status !== ZERO_RESULTS) {
        reject(new Error(`google.maps geocode failed with status: ${status}`));
      }
      resolve(results?.map(mapGeocodeResult) ?? []);
    });
  });
}
