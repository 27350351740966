/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as styles from './ScrollableArea.style';

export interface ScrollableAreaProps extends React.PropsWithChildren, React.AriaAttributes {
  height?: string,
}

/**
 * @todo move this to bricks
 * @todo integrate this in Card.Scrollable
 */
const ScrollableArea: React.FC<ScrollableAreaProps> = ({
  height = '5rem',
  children,
  ...rest
}) => (
  <div
    css={styles.base}
    $height={height}
    {...rest}
  >
    <div>
      {children}
    </div>
  </div>
);

export default ScrollableArea;
