import { IntentStatus } from '../../domain/types';
import { Intent } from '../../providers/api/dtos';

export function isArchived(arg: Intent): Boolean;
export function isArchived(arg: IntentStatus): Boolean;

export function isArchived(arg: Intent | IntentStatus): Boolean {
  const status = (arg as Intent).status
    ? (arg as Intent).status
    : arg as IntentStatus;

  return status.toString().toLowerCase().startsWith('ko');
}
