import {
  Action, Form, HStack, Spacing,
} from '@doveit/bricks';
import React from 'react';
import { capitalize } from 'lodash';
import { getCharactersCount } from '../../../utils/text/text';
import { Language } from '../../../types';
import GenerateEntityInfoAction from '../../../containers/generate-entity-info-action/GenerateEntityInfoAction';
import {
  TextProcessing, ItalianTextualInfoType, mapToContext, EnglishTextualInfoType,
} from '../../../providers/api/dtos/contentProcessing';
import RephraseTextAction from '../../../containers/rephrase-text-action/RephraseTextAction';
import TranslateTextAction from '../../../containers/translate-text-action/TranslateTextAction';

interface PropertyTextualInfoProps {
  propertyId: number,
  fieldName: string,
  fieldLabel: string;
  initialValue: string,
  currentValue: string,
  toTranslate?: string,
  language: Language,
  fieldMaxLength: number;
  rows: number;
  onRestore: (field: string) => void
  onValueChange: (value: string) => void
}

const PropertyTextualInfo: React.FC<PropertyTextualInfoProps> = ({
  propertyId,
  fieldName,
  fieldLabel,
  initialValue,
  currentValue,
  toTranslate,
  language,
  fieldMaxLength,
  rows,
  onRestore,
  onValueChange,
}) => {
  const languageLabel = React.useMemo(() => (language === 'it' ? 'italiana' : 'inglese'), [language]);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const showProcessingItalianActions = React.useMemo(() => Object.keys(ItalianTextualInfoType).includes(fieldName), [fieldName]);
  const showProcessingEnglishActions = React.useMemo(() => Object.keys(EnglishTextualInfoType).includes(fieldName), [fieldName]);

  const onProcessingStarted = React.useCallback(() => setIsProcessing(true), []);
  const onProcessingSuccess = React.useCallback((res: TextProcessing) => {
    onValueChange(res.text);
    setIsProcessing(false);
  }, [onValueChange]);
  const onProcessingError = React.useCallback(() => setIsProcessing(false), []);

  return (
    <Form.Fieldset legend={capitalize(fieldLabel)} aria-label={`${fieldLabel} in lingua ${languageLabel}`}>
      <Form.Item>
        <Spacing margin={[0, 0, 100]}>
          <HStack
            alignItems="center"
            justifyContent="end"
            gap={100}
          >
            {showProcessingItalianActions && (
              <>
                <GenerateEntityInfoAction
                  entityId={propertyId}
                  context={mapToContext[fieldName as ItalianTextualInfoType]}
                  onClick={onProcessingStarted}
                  onSuccess={onProcessingSuccess}
                  onError={onProcessingError}
                  disabled={isProcessing}
                />
                <RephraseTextAction
                  text={initialValue}
                  onClick={onProcessingStarted}
                  onSuccess={onProcessingSuccess}
                  onError={onProcessingError}
                  disabled={isProcessing || !currentValue || currentValue !== initialValue}
                />
              </>
            )}
            {showProcessingEnglishActions && (
              <TranslateTextAction
                text={toTranslate || ''}
                disabled={isProcessing || !toTranslate}
                onSuccess={onProcessingSuccess}
                onClick={onProcessingStarted}
                onError={onProcessingError}
              />
            )}
            <Action
              label="Ripristina"
              onClick={() => onRestore(fieldName)}
              aria-label={`Ripristina il contenuto ${fieldLabel} in lingua ${languageLabel}`}
              disabled={isProcessing || currentValue === initialValue}
              size="S"
            />
          </HStack>
        </Spacing>

        <Form.TextArea
          id={fieldName}
          name={fieldName}
          aria-label={`Campo ${fieldLabel} dell'immobile in lingua ${languageLabel}`}
          rows={rows}
          help={getCharactersCount(currentValue, fieldMaxLength)}
          disabled={isProcessing}
        />
      </Form.Item>
    </Form.Fieldset>
  );
};

export default PropertyTextualInfo;
