import { Spacing, useNotifications } from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Divider from '../../../components/divider/Divider';
import { SearchContactFormModel } from '../../../contact/components/search-contact-form/SearchContactForm';
import SelectContact from '../../../contact/containers/select-contact/SelectContact';
import createOrUpdateContact from '../../../contact/utils/createOrUpdateContact';
import { Contact, PropertyContactReference } from '../../../providers/api/dtos';
import { createPropertyContact } from '../../../providers/api/property-contact/propertyContactProvider';
import PropertyContactForm, { PropertyContactFormModel } from '../../components/property-contact-form/PropertyContactForm';

export interface CreatePropertyContactProps {
  propertyId: number,
  onSuccess?: (propertyContactReference: PropertyContactReference) => void,
}

export const CREATE_PROPERTY_CONTACT_SUCCESS_MESSAGE = 'Il referente è stato aggiunto con successo';
export const CREATE_PROPERTY_CONTACT_ERROR_MESSAGE = 'Non è stato possibile aggiungere il referente';

const CreatePropertyContact: FunctionComponent<CreatePropertyContactProps> = ({
  propertyId,
  onSuccess,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>();
  const [overwriteValues, setOverwriteValues] = useState<SearchContactFormModel | undefined>();

  const onContactChange = useCallback((contactData?: Contact, search?: SearchContactFormModel) => {
    setSelectedContact(contactData);
    setOverwriteValues(search);
  }, []);

  const onSubmit = useCallback(async (values: PropertyContactFormModel) => {
    setIsSaving(true);

    try {
      const contact = await createOrUpdateContact(overwriteValues, selectedContact);

      const propertyContactReference = await createPropertyContact({
        contactId: contact.id!,
        propertyId,
        relationship: values.relationship,
      });

      setIsSaving(false);
      addSuccess(CREATE_PROPERTY_CONTACT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(propertyContactReference);
      }
    } catch (error) {
      setIsSaving(false);
      addError(CREATE_PROPERTY_CONTACT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, onSuccess, overwriteValues, propertyId, selectedContact]);

  return (
    <div>
      <Divider>Dati contatto</Divider>
      <SelectContact onContactChange={onContactChange} />
      <Spacing margin={[200, 0, 0]}>
        <PropertyContactForm
          submitLabel="Aggiungi"
          onSubmit={onSubmit}
          disabled={!(selectedContact || overwriteValues)}
          loading={isSaving}
        />
      </Spacing>
    </div>
  );
};

export default CreatePropertyContact;
