/* eslint-disable react/no-unknown-property */
import React from 'react';
import { SimpleTableRow } from '../row/SimpleTableRow';

export interface SimpleTableBodyProps extends React.PropsWithChildren, React.AriaAttributes {
}

export const SimpleTableBody: React.FC<SimpleTableBodyProps> = ({
  children,
  ...rest
}) => {
  React.Children.forEach(children as any, (child, i) => {
    if (!child) {
      return null;
    }

    if (child.type !== SimpleTableRow) {
      throw new Error(`The ${i}-th component child is not a SimpleTable.Row component`);
    }

    return null;
  });

  return (
    <tbody
      aria-label="simple-table-body"
      {...rest}
    >
      {children}
    </tbody>
  );
};
