import { Form } from '@doveit/bricks';
import React, {
  FunctionComponent, useCallback, useMemo,
} from 'react';
import PlaceAutoComplete from '../../../components/place-autocomplete/PlaceAutoComplete';
import { GeoSearchCriterionType, PlaceGeoSearchCriterion } from '../../../providers/api/dtos/searchCriteria';
import { PlaceSuggestion, PlaceType } from '../../../providers/public-api/dtos';
import { noop } from '../../../utils';

export interface PlaceGeoSearchCriterionFormProps {
  isDisabled?: boolean,
  onChange: (value: PlaceGeoSearchCriterion) => void,
}

const PlaceGeoSearchCriterionForm: FunctionComponent<PlaceGeoSearchCriterionFormProps> = ({
  isDisabled,
  onChange,
}) => {
  const defaultInitialValues = useMemo(() => ({}), []);

  const onSuggestionSelected = useCallback((suggestion?: PlaceSuggestion | null) => {
    if (suggestion) {
      onChange({
        type: GeoSearchCriterionType[suggestion.place.type],
        name: suggestion.place.name,
        ...(suggestion.place.type === PlaceType.AREA
          ? { locality: suggestion.place.parent!.name }
          : {}
        ),
      } as PlaceGeoSearchCriterion);
    }
  }, [onChange]);

  return (
    <Form
      initialValues={defaultInitialValues}
      onSubmit={noop}
    >
      <Form.Item>
        <PlaceAutoComplete
          name="place"
          value={null}
          placeType={[PlaceType.AREA, PlaceType.LOCALITY, PlaceType.PROVINCE]}
          maxSuggestions={5}
          disabled={isDisabled}
          onSuggestionSelected={onSuggestionSelected}
        />
      </Form.Item>
    </Form>
  );
};

export default PlaceGeoSearchCriterionForm;
