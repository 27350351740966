import * as Yup from 'yup';
import { isAfter, isToday } from 'date-fns';
import { inRange } from 'lodash';
import {
  CommissionPrice, MoneySource, OfferPropertyStatus, PaymentMethod, WizardContact,
} from '../../../providers/api/dtos';
import { ExtraPackagePriceType, OwnershipType } from '../../../domain/types';
import { contactValidation, contactValidationWithAdditionalFields, registeredOfficeValidation } from '../../../prospect/pages/manage-assignment/schemas';
import { CAP_REGEX, NUMBER_REGEX } from '../../../constants';
import { formatInputDate } from '../../../utils/form';

export const OfferWizardBuyersValidationSchema = Yup.object({
  isBuyerLegalPersonOnly: Yup
    .bool()
    .required('Il campo "Tipologia" è obbligatorio'),
  buyerContacts: Yup
    .array()
    .when('isBuyerLegalPersonOnly', {
      is: (isBuyerLegalPersonOnly: boolean) => isBuyerLegalPersonOnly,
      then: (schema) => {
        const legalContactValidation = Yup.object({
          companyName: Yup
            .string()
            .required('Il campo "Ragione sociale" è obbligatorio'),
          registeredOffice: registeredOfficeValidation,
          fiscalCode: Yup
            .string()
            .required('Il campo "Partita IVA" è obbligatorio'),
        });

        return schema.of(contactValidationWithAdditionalFields.shape({
          legal: legalContactValidation,
        }));
      },
      otherwise: (schema) => schema.of(contactValidationWithAdditionalFields),
    }).when('isBuyerLegalPersonOnly', {
      is: (isBuyerLegalPersonOnly: boolean) => isBuyerLegalPersonOnly,
      then: (schema) => schema.min(1, 'È necessario inserire il rappresentante legale'),
      otherwise: (schema) => schema.min(1, 'È necessario inserire almeno un compratore'),
    })
    .test(
      'noDuplicates',
      'Sono presenti più contatti con la stessa email e numero di telefono',
      (buyerContacts: WizardContact[] = []) => {
        const occs = new Map<string, number>();

        buyerContacts.forEach((contact) => {
          const key = `${contact.email?.trim()?.toLowerCase()}-${contact.phoneNumber?.trim()}`;
          const count = occs.get(key) || 0;
          occs.set(key, count + 1);
        });

        const hasDuplicates = Array.from(occs.entries()).some(([_, count]) => count > 1);

        return !hasDuplicates;
      },
    ),
});

export const OfferWizardSellersValidationSchema = Yup.object({
  isSellerLegalPersonOnly: Yup
    .bool()
    .required('Il campo "Tipologia" è obbligatorio'),
  sellerContacts: Yup
    .array()
    .when('isSellerLegalPersonOnly', {
      is: (isSellerLegalPersonOnly: boolean) => isSellerLegalPersonOnly,
      then: (schema) => {
        const legalContactValidation = Yup.object({
          companyName: Yup
            .string()
            .required('Il campo "Ragione sociale" è obbligatorio'),
          registeredOffice: registeredOfficeValidation,
          fiscalCode: Yup
            .string()
            .required('Il campo "Partita IVA" è obbligatorio'),
        });

        return schema.of(contactValidation.shape({
          legal: legalContactValidation,
        }));
      },
      otherwise: (schema) => schema.of(contactValidation),
    }).when('isSellerLegalPersonOnly', {
      is: (isSellerLegalPersonOnly: boolean) => isSellerLegalPersonOnly,
      then: (schema) => schema.min(1, 'È necessario inserire il rappresentante legale'),
      otherwise: (schema) => schema.min(1, 'È necessario inserire almeno un venditore'),
    })
    .test(
      'noDuplicates',
      'Sono presenti più contatti con la stessa email e numero di telefono',
      (sellerContacts: WizardContact[] = []) => {
        const occs = new Map<string, number>();

        sellerContacts.forEach((contact) => {
          const key = `${contact.email?.trim()?.toLowerCase()}-${contact.phoneNumber?.trim()}`;
          const count = occs.get(key) || 0;
          occs.set(key, count + 1);
        });

        const hasDuplicates = Array.from(occs.entries()).some(([_, count]) => count > 1);

        return !hasDuplicates;
      },
    ),
});

const geoValidation = Yup.object({
  route: Yup
    .string()
    .required('Il campo "Strada" è obbligatorio'),
  locality: Yup
    .string()
    .required('Il campo "Comune" è obbligatorio'),
  administrativeAreaLevelTwo: Yup
    .string()
    .required('Il campo "Provincia" è obbligatorio'),
  postalCode: Yup
    .string()
    .required('Il campo "CAP" è obbligatorio')
    .matches(CAP_REGEX, 'Inserisci un CAP valido'),
});

export const OfferWizardPropertyGeoValidationSchema = Yup.object({
  property: Yup.object({
    geo: geoValidation,
    composition: Yup.string().trim().min(1, "La composizione dell'immobile è obbligatoria"),
    cadastralRegistry: Yup.object({
      units: Yup
        .array()
        .min(1, "È necessario inserire almeno un'unità immobiliare")
        .required('Il campo "Unità immobiliari" è obbligatorio"'),
    }),
  }),
});

export const OfferWizardPropertyDetailsValidationSchema = Yup.object({
  property: Yup.object({
    status: Yup.object({
      type: Yup
        .string()
        .required('Il campo "Stato dell\'immobile" è obbligatorio')
        .oneOf(Object.values(OfferPropertyStatus)),
    }),
    rent: Yup.object().when('status.type', {
      is: (status: string) => status === OfferPropertyStatus.RENTAL,
      then: (schema) => schema.shape({
        annualFee: Yup
          .number()
          .positive()
          .required('Il campo "Canone annuo" è obbligatorio'),
        expiresAt: Yup
          .date()
          .required('Il campo "Scadenza" è obbligatorio')
          .test('isDateInTheFuture',
            'La data di scadenza del contratto di locazione deve essere nel futuro',
            (value) => value && (isToday(value) || isAfter(value, new Date()))),
      }),
      otherwise: (schema) => schema.notRequired(),
    }),
    compliance: Yup.object({
      isUrbanCompliant: Yup
        .bool()
        .required('Il campo "È conforme" è obbligatorio'),
      areSystemsCompliant: Yup
        .bool()
        .required('Il campo "Ha impianti conformi" è obbligatorio'),
      hasOccupancyCertificate: Yup
        .bool()
        .required('Il campo "Ha il certificato di agibilità" è obbligatorio'),
    }),
    constraints: Yup.object({
      legalIssues: Yup
        .bool()
        .required('Il campo "Controversie legali" è obbligatorio'),
      isConstraintsFree: Yup
        .bool()
        .required('Il campo "È privo di pregiudizi e vincoli" è obbligatorio'),
      details: Yup
        .string()
        .when('isConstraintsFree', {
          is: (isConstraintsFree: boolean) => !isConstraintsFree,
          then: (schema) => schema.required('È necessario specificare i Pregiudizi, servitù o vincoli'),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
    additionalExpenses: Yup.object({
      extraDeliberated: Yup
        .bool()
        .required('Il campo "Previste spese straordinarie" è obbligatorio'),
      value: Yup
        .number()
        .nullable()
        .min(0, 'Le spese condominiali devono essere maggiori o uguali a 0€'),
    }),
    ownership: Yup.object({
      type: Yup
        .string()
        .required('Il campo "Tipologia" è obbligatorio')
        .oneOf([OwnershipType.NUDA_PROPRIETA, OwnershipType.INTERA_PROPRIETA, 'ALTRO'], 'Il campo "Tipologia" non è valido'),
      details: Yup
        .string()
        .when('type', {
          is: (type: string) => type === 'ALTRO',
          then: (schema) => schema.required('È necessario specificare la tipologia non prevista'),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
  }),
});

const paymentValidation = Yup.object({
  deposit: Yup.object({
    value: Yup
      .string()
      .matches(NUMBER_REGEX, 'L\'importo della caparra deve essere un valore numerico intero. Es."200000"')
      .required('L\'importo della caparra è richiesto'),
    paymentMethod: Yup
      .string()
      .required('Il metodo di pagamento della caparra è obbligatorio')
      .oneOf([PaymentMethod.CHECK, PaymentMethod.BANK_TRANSFER]),
    bank: Yup.string()
      .when('paymentMethod', {
        is: (paymentMethod: string) => paymentMethod === PaymentMethod.CHECK,
        then: (schema) => schema.required('È necessario specificare la banca'),
        otherwise: (schema) => schema.notRequired(),
      }),
    checkNumber: Yup
      .string()
      .when('paymentMethod', {
        is: (paymentMethod: string) => paymentMethod === PaymentMethod.CHECK,
        then: (schema) => schema.required('È necessario specificare il numero di assegno'),
        otherwise: (schema) => schema.notRequired(),
      }),
  }),
  integration: Yup
    .object({
      preliminary: Yup
        .bool()
        .required('Il campo "Preliminare" è obbligatorio'),
      paymentDateWithin: Yup
        .date()
        .when('preliminary', {
          is: (preliminary: boolean) => preliminary,
          then: (schema) => schema
            .test('isDateInTheFuture',
              'La data di scadenza del preliminare deve essere nel futuro',
              (value) => value && (isToday(value) || isAfter(value, new Date())))
            .required('La data di scadenza del preliminare è obbligatoria'),
          otherwise: (schema) => schema.notRequired(),
        }),
      depositIntegration: Yup
        .bool()
        .when('preliminary', {
          is: (preliminary: boolean) => preliminary,
          then: (schema) => schema.required('Il campo "Integrazione caparra" è obbligatorio'),
          otherwise: (schema) => schema.notRequired(),
        }),
      value: Yup
        .string()
        .when('depositIntegration', {
          is: (depositIntegration: boolean) => depositIntegration,
          then: (schema) => schema
            .matches(NUMBER_REGEX, 'L\'importo dell\'integrazione deve essere un valore numerico intero. Es."200000"')
            .required("L'importo dell'integrazione è richiesto"),
          otherwise: (schema) => schema.notRequired(),
        }),
      decay: Yup.bool()
        .when('preliminary', {
          is: (preliminary: boolean) => preliminary,
          then: (schema) => schema.required('Il campo "Condizione al decadimento" è obbligatorio'),
          otherwise: (schema) => schema.notRequired(),
        }),
      daysFromDecay: Yup
        .number()
        .integer()
        .positive()
        .when('decay', {
          is: (decay: boolean) => decay,
          then: (schema) => schema.required('Il numero di giorni dal decadimento è obbligatorio'),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
  balance: Yup
    .object({
      value: Yup
        .string()
        .matches(NUMBER_REGEX, 'L\'importo del saldo deve essere un valore numerico intero. Es."200000"')
        .required('L\'importo del saldo è richiesto'),
      paymentDateWithin: Yup.date()
        .test('isDateInTheFuture',
          'La data del rogito deve essere nel futuro',
          (value) => value && (isToday(value) || isAfter(value, new Date())))
        .required('La data del rogito è obbligatoria'),
    }),
})
  .test(
    'sumOfValues',
    'La somma dei valori di caparra, integrazione e saldo deve essere esattamente uguale all\'importo dell\'offerta',
    (value, ctx) => {
      const { value: offerValue } = ctx.parent;
      const deposit = value.deposit.value;
      const integration = value.integration.value ?? 0;
      const balance = value.balance.value;

      const paymentSum = Number(deposit) + Number(balance) + Number(integration);
      return paymentSum === Number(offerValue);
    },
  );

export const OfferWizardPaymentsSchema = Yup.object({
  offer: Yup.object({
    value: Yup.string()
      .matches(NUMBER_REGEX, 'L\'importo dell\'offerta deve essere un valore numerico intero. Es."200000"')
      .required('L\'importo dell\'offerta è richiesto'),
    notaryPriceDeposit: Yup.bool()
      .required('Il campo "Deposito del prezzo presso il notaio" è obbligatorio'),
    payment: paymentValidation,
  }),
});

export const OfferWizardMortgageValidationSchema = Yup.object({
  mortgage: Yup.object({
    mandatoryMortgageAcceptance: Yup
      .boolean()
      .required('La condizione sospensiva del mutuo è obbligatoria'),
    value: Yup
      .number()
      .positive()
      .when('mandatoryMortgageAcceptance', {
        is: (mandatoryMortgageAcceptance: boolean) => mandatoryMortgageAcceptance,
        then: (schema) => schema
          .required("L'importo del mutuo è obbligatorio")
          .test('Greater than offer', "L'importo del mutuo deve essere inferiore alla proposta", (mortgageValue: number, ctx) => {
            const offerValue = ctx.from?.[1].value.offer.value;

            return mortgageValue <= offerValue;
          }),
        otherwise: (schema) => schema.notRequired(),
      }),
    maxAcceptanceDate: Yup
      .date()
      .when('mandatoryMortgageAcceptance', {
        is: (mandatoryMortgageAcceptance: boolean) => mandatoryMortgageAcceptance,
        then: (schema) => schema
          .required('La data massima di delibera è obbligatoria')
          .min(formatInputDate(new Date()), 'La data di accettazione deve essere nel futuro'),
        otherwise: (schema) => schema.notRequired(),
      }),
    mortgageRequest: Yup
      .boolean()
      .when('mandatoryMortgageAcceptance', {
        is: (mandatoryMortgageAcceptance: boolean) => mandatoryMortgageAcceptance,
        then: (schema) => schema.required('La condizione di presentazione del mutuo è obbligatoria'),
        otherwise: (schema) => schema.notRequired(),
      }),
    daysToProof: Yup
      .number()
      .positive()
      .when('mortgageRequest', {
        is: (mortgageRequest: boolean) => mortgageRequest,
        then: (schema) => schema
          .required('I giorni per la presentazione del mutuo sono obbligatori')
          .max(30, 'I giorni di presentazione del mutuo non possono essere maggiori di 30'),
        otherwise: (schema) => schema.notRequired(),
      }),
  }),
});

export const OfferWizardCommissionsValidationSchema = Yup.object({
  commissions: Yup.object({
    noBuyerCommission: Yup
      .boolean()
      .required('È necessario specificare se si applicano commissioni al compratore'),
    type: Yup
      .string()
      .required()
      .oneOf(Object.values(CommissionPrice)),
    value: Yup
      .number()
      .required('L\'ammontare delle commissioni del compratore è obbligatorio')
      .min(0)
      .test('isValid', 'Valore invalido', (value, ctx) => {
        const { noBuyerCommission, type } = ctx.parent;

        if (!noBuyerCommission && type === ExtraPackagePriceType.FIXED && value < 1) {
          return ctx.createError({
            path: 'value',
            message: 'Il valore minimo è 1€',
          });
        }

        if (!noBuyerCommission && type === ExtraPackagePriceType.PERCENTAGE && !inRange(value, 0.01, 100.01)) {
          return ctx.createError({
            path: 'value',
            message: 'Il valore deve essere compreso tra 0 e 100%',
          });
        }

        return true;
      }),
  }),
});

export const OfferWizardNotesSchema = Yup.object({
  signaturePlace: Yup
    .string()
    .trim()
    .required('Il campo "Luogo proposta" è obbligatorio'),
  notes: Yup.string(),
  offer: Yup.object({
    validUntil: Yup
      .date()
      .required('Il campo "Valida fino al" è obbligatorio')
      .test('isDateInTheFuture',
        'La data di validità della proposta deve essere nel futuro',
        (value) => value && (isToday(value) || isAfter(value, new Date()))),
  }),
});

export const OfferWizardAntiMoneyLaunderingSchema = Yup.object({
  excludeAntiMoneyLaundering: Yup
    .boolean()
    .required(),
  antiMoneyLaundering: Yup.object()
    .when('excludeAntiMoneyLaundering', {
      is: (excludeAntiMoneyLaundering: boolean) => !excludeAntiMoneyLaundering,
      then: (antiMoneyLaunderingSchema) => antiMoneyLaunderingSchema.shape({
        politicallyExposed: Yup
          .boolean()
          .when('excludeAntiMoneyLaundering', {
            is: (excludeAntiMoneyLaundering: boolean) => !excludeAntiMoneyLaundering,
            then: (schema) => schema.required('Il campo "Persona politicamente esposta" è obbligatorio'),
            otherwise: (schema) => schema.notRequired(),
          }),
        relatedToAPoliticallyExposedFigure: Yup
          .boolean()
          .when('excludeAntiMoneyLaundering', {
            is: (excludeAntiMoneyLaundering: boolean) => !excludeAntiMoneyLaundering,
            then: (schema) => schema.required('Il campo "Parente o convivente di persona politicamente esposta" è obbligatorio'),
            otherwise: (schema) => schema.notRequired(),
          }),
        moneySources: Yup
          .array()
          .test('atLeastOneSource',
            'Selezionare almeno una sorgente del denaro',
            (values) => (values !== undefined && values.length >= 1)),
        otherMoneySourceValue: Yup
          .string()
          .trim()
          .test('many source contains OTHER option',
            'Specificare i dettagli relativi alla sorgente "Altro"',
            (value, ctx) => {
              const { moneySources } = ctx.parent;
              const detailsArePresent = value !== undefined && value.length > 0;
              const otherSourceExists = moneySources !== undefined && moneySources.some((source: MoneySource) => source === MoneySource.OTHER);
              return (otherSourceExists && detailsArePresent) || (!otherSourceExists && !detailsArePresent);
            }),
      }).required(),
      otherwise: (schema) => schema.notRequired(),
    }),
});
