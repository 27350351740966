import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import {
  ActionIcon, Form, FormActionsAlign, ICON_ACCOUNT_CANCEL_OUTLINE, Message, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { JobApplication } from '../../../providers/api/dtos';
import { koReasonOptions } from './koReasonOptions';
import validationSchema from './ArchiveJobApplication.schema';
import { updateJobApplication } from '../../../providers/api/job-application/jobApplicationProvider';
import { isArchived } from '../../../utils/job-application/getJobApplicationProgress';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import useRemindersV3, { REMINDERS_RID } from '../../../reminders/hooks/use-reminders/useRemindersV3';
import { JobApplicationStatus, ReferenceType } from '../../../domain/types';
import { MAX_PAGED_SIZE } from '../../../constants';
import { deleteRemindersV3 } from '../../../providers/api/reminder/reminderProvider';
import { SortOrder } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';

interface ChildFnProps {
  archive: VoidFunction,
  loading: boolean,
}

interface ArchiveJobApplicationFormModel {
  status: JobApplicationStatus | '',
  archivingReason: string,
}

export interface ArchiveJobApplicationProps {
  label?: string,
  jobApplication: JobApplication,
  onSuccess?: (jobApplication: JobApplication) => void,
  children?: (props: ChildFnProps) => React.ReactNode,
}

export const ARCHIVE_JOB_APPLICATION_WARNING_MESSAGE = 'Archiviando la candidatura verranno automaticamente annullati eventuali promemoria e colloqui futuri, qualora presenti.';
export const ARCHIVE_JOB_APPLICATION_SUCCESS_MESSAGE = 'Candidatura archiviata con successo';
export const ARCHIVE_JOB_APPLICATION_ERROR_MESSAGE = 'Non è stato possibile archiviare la candidatura';

const ArchiveJobApplication: FunctionComponent<ArchiveJobApplicationProps> = ({
  label,
  jobApplication,
  onSuccess,
  children,
}) => {
  const archiveModal = useModal();
  const [isSaving, setIsSaving] = useState(false);
  const { addSuccess, addError } = useNotifications();
  const matchMutate = useMatchMutate();
  const { data: reminders } = useRemindersV3({
    referenceId: jobApplication.id,
    referenceType: ReferenceType.JOB_APPLICATION,
  }, {
    size: MAX_PAGED_SIZE,
    sort: {
      createdAt: SortOrder.DESC,
    },
  });

  const isArchivedJobApplication = useMemo(
    () => (jobApplication?.status ? isArchived(jobApplication?.status) : undefined),
    [jobApplication?.status],
  );

  const initialValues: ArchiveJobApplicationFormModel = useMemo(() => ({
    status: isArchivedJobApplication ? jobApplication.status : '',
    archivingReason: jobApplication.archivingReason || '',
  }), [isArchivedJobApplication, jobApplication.archivingReason, jobApplication.status]);

  const remindersToDelete = useMemo(
    () => reminders?.content
      .filter((reminder) => !reminder.expired)
      .map((reminder) => reminder.id!) || [],
    [reminders?.content],
  );

  const onSubmit = useCallback(async (values: ArchiveJobApplicationFormModel) => {
    setIsSaving(true);

    try {
      const updatedJobApplication = await updateJobApplication(
        jobApplication.id!,
        {
          ...jobApplication,
          status: values.status as JobApplicationStatus,
          archivingReason: values.archivingReason || undefined,
        },
      );

      if (remindersToDelete.length > 0) {
        await deleteRemindersV3(remindersToDelete);
        matchMutate(REMINDERS_RID);
      }

      setIsSaving(false);
      archiveModal.close();
      addSuccess(ARCHIVE_JOB_APPLICATION_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(updatedJobApplication);
      }
    } catch (error) {
      setIsSaving(false);
      addError(ARCHIVE_JOB_APPLICATION_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, archiveModal, jobApplication, matchMutate, onSuccess, remindersToDelete]);

  return (
    <>
      {children
        ? children({ archive: archiveModal.open, loading: isSaving })
        : (
          <ActionIcon
            label={label ?? 'Archivia'}
            onClick={archiveModal.open}
            icon={{ path: ICON_ACCOUNT_CANCEL_OUTLINE }}
            color="critical"
          />
        )}

      <Portal>
        <SimpleModal
          {...archiveModal}
          title="Archivia candidatura"
        >
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            loading={isSaving}
          >
            <Form.Item>
              <Form.Select
                name="status"
                label="Motivazione"
                aria-label="Campo per inserire la motivazione dell'archiviazione"
                options={koReasonOptions}
                required
              />
            </Form.Item>
            <Form.Item>
              <Form.TextArea
                rows={7}
                name="archivingReason"
                label="Note"
                aria-label="Campo per inserire le note dell'archiviazione"
                placeholder="Inserisci le note dell'archiviazione"
                required
              />
            </Form.Item>
            <Form.Item>
              <Message
                type="warning"
                message={ARCHIVE_JOB_APPLICATION_WARNING_MESSAGE}
              />
            </Form.Item>
            <Form.Actions align={FormActionsAlign.RIGHT}>
              <Form.Submit label="Archivia" />
            </Form.Actions>
          </Form>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ArchiveJobApplication;
