export function uniqueByField<TItem, TField extends keyof TItem>(items: TItem[], field: TField): TItem[] {
  const set = new Set();
  return items.filter((item) => {
    const isDuplicate = set.has(item[field]);
    set.add(item[field]);
    return !isDuplicate;
  });
}

export function uniqueById<TItem extends { id: unknown }>(items: TItem[]): TItem[] {
  return uniqueByField(items, 'id');
}

type Obj<TId extends string | number | symbol> = { id: TId };

/**
 * Merges multiple arrays of objects by a common property 'id' and returns a new array
 * where objects with the same 'id' are merged into a single object.
 *
 * @template TId - The type of the identifier (string, number, or symbol).
 * @template TMerged - The type of the merged object, defaults to Obj<TId>.
 * @param {...Obj<TId>[][]} lists - Arrays of objects to be merged based on their id.
 * @returns {TMerged[]} An array of merged objects.
 *
 * @example
 * const array1 = [{ id: 1, name: 'Alice' }, { id: 2, name: 'Bob' }];
 * const array2 = [{ id: 2, age: 30 }, { id: 3, age: 25 }];
 * const mergedArray = joinById(array1, array2);
 * // mergedArray is:
 * // [{ id: 1, name: 'Alice' }, { id: 2, name: 'Bob', age: 30 }, { id: 3, age: 25 }]
 */
export function joinById<TId extends string | number | symbol, TMerged extends Obj<TId> = Obj<TId>>(...lists: Obj<TId>[][]): TMerged[] {
  return Object.values(
    lists.reduce<Record<TId, Obj<TId>>>((idx, list) => {
      list.forEach((record) => {
        if (idx[record.id]) {
          idx[record.id] = Object.assign(idx[record.id], record);
        } else {
          idx[record.id] = record;
        }
      });
      return idx;
    }, {} as Record<TId, Obj<TId>>),
  );
}

export function removeNullAndUndefinedValues<T>(array: (T | null | undefined)[]): T[] {
  return array.filter((item) => item != null);
}
