import { BooleanString } from '../../../../utils/boolean-string/booleanString';

export function computeBalanceValue(
  offerValue: string,
  depositValue: string,
  integrationValue: string,
  preliminary: BooleanString,
  depositIntegration: BooleanString,
): string {
  if (
    offerValue === ''
    || depositValue === ''
    || preliminary === ''
    || (preliminary === 'true' && depositIntegration === '')
    || (preliminary === 'true' && depositIntegration === 'true' && integrationValue === '')
  ) {
    return '';
  }

  const offer = parseInt(offerValue, 10);
  const deposit = parseInt(depositValue, 10);
  const integration = depositIntegration === 'true'
    ? parseInt(integrationValue, 10)
    : 0;
  const balance = offer - deposit - integration;

  return balance > 0 ? balance.toString() : '';
}
