import {
  ActionIcon,
  Badge, BreakpointQueryName, DetailItemList, HStack, ICON_ACCOUNT_OUTLINE, ICON_BRIEFCASE_OUTLINE, ICON_CALENDAR_CLOCK_OUTLINE, ICON_CLOCK_OUTLINE, ICON_EYE_OUTLINE, ICON_MAP_MARKER_RADIUS_OUTLINE, ICON_PHONE_OUTLINE, Message, Spacing,
} from '@doveit/bricks';
import { format } from 'date-fns';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import { formatDate } from '@doveit/hammer';
import UserActions from '../../../components/user-actions/UserActions';
import useApplicant from '../../../applicant/hooks/use-applicant/useApplicant';
import Card from '../../../components/card/Card';
import { InlineCardSkeleton } from '../../../components/card/Card.skeleton';
import { Department, JobApplicationStatus, ReferenceType } from '../../../domain/types';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import useJobPosition from '../../../job-position/hooks/use-job-position/useJobPosition';
import { jobApplicationStatusLabels, jobPositionDepartmentLabels } from '../../../labels';
import { JobApplication } from '../../../providers/api/dtos';
import UpsertReminderActionV3 from '../../../reminders/containers/upsert-reminder-action/UpsertReminderActionV3';
import { PartialReminder } from '../../../reminders/containers/upsert-reminder/UpsertReminderV3';
import useRemindersV3 from '../../../reminders/hooks/use-reminders/useRemindersV3';
import { isArchived, isAssignable, isFinal } from '../../../utils/job-application/getJobApplicationProgress';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import JobApplicationStatusBadge from '../../components/job-application-status-badge/JobApplicationStatusBadge';
import ArchiveJobApplication, { ArchiveJobApplicationProps } from '../archive-job-application/ArchiveJobApplication';
import AssignJobApplication, { AssignJobApplicationProps } from '../assign-job-application/AssignJobApplication';
import HireJobApplication, { HireJobApplicationProps } from '../hire-job-application/HireJobApplication';
import OfferJobApplication, { OfferJobApplicationProps } from '../offer-job-application/OfferJobApplication';
import HireApplicantAction from '../../../agent/containers/hire-applicant-action/HireApplicantAction';
import { getAgentName } from '../../../agent/utils/utils';

export interface ViewJobApplicationCardProps {
  jobApplication: JobApplication,
  compact?: boolean,
  hideViewAction?: boolean,
  hideArchiveAction?: boolean,
  hideAssignAction?: boolean,
  hideOfferAction?: boolean,
  hideUpsertReminderAction?: boolean,
  hideHireAction?: boolean,
  onView?: VoidFunction,
  onArchive?: ArchiveJobApplicationProps['onSuccess'],
  onAssign?: AssignJobApplicationProps['onSuccess'],
  onOffer?: OfferJobApplicationProps['onSuccess'],
  onHire?: HireJobApplicationProps['onSuccess'],
}

export const DATA_LOAD_ERROR = 'Non è stato possibile caricare alcuni dati relativi alla candidatura.';

const ViewJobApplicationCard: FunctionComponent<ViewJobApplicationCardProps> = ({
  jobApplication,
  compact,
  hideViewAction,
  hideArchiveAction,
  hideAssignAction,
  hideOfferAction,
  hideUpsertReminderAction,
  hideHireAction,
  onView,
  onArchive,
  onAssign,
  onOffer,
  onHire,
}) => {
  const { data: jobPosition, error: jobPositionError } = useJobPosition(jobApplication.jobPositionId);
  const { data: applicant, error: applicantError } = useApplicant(jobApplication.applicantId);
  const { data: agent, error: agentError } = useAgent(jobApplication.assignment?.agentId);
  const { user } = useRBAC();
  const { data: reminders, error: remindersError, mutate: mutateReminders } = useRemindersV3({
    referenceType: ReferenceType.JOB_APPLICATION,
    referenceId: jobApplication.id!,
  });

  const theme = useTheme();
  const isXLView = useMediaQuery(theme.breakpoints.up(BreakpointQueryName.XL));

  const reminder: PartialReminder = useMemo(
    () => ({
      referenceType: ReferenceType.JOB_APPLICATION,
      referenceId: jobApplication.id!,
      userEmail: user?.email ?? '',
    }),
    [jobApplication.id, user?.email],
  );

  const isUpsertReminderActionDisabled = useMemo(() => {
    if (!reminders) {
      return true;
    }

    if (reminders.numberOfElements > 0) {
      return !reminders.content[0].expired;
    }
    return false;
  }, [reminders]);

  const isSalesJobApplication = useMemo(
    () => jobPosition?.department === Department.SALES,
    [jobPosition?.department],
  );

  const onUpsertReminderSuccess = useCallback(() => {
    mutateReminders();
  }, [mutateReminders]);

  if (jobPositionError || applicantError || agentError || remindersError) {
    return (
      <Message
        type="critical"
        message={DATA_LOAD_ERROR}
      />
    );
  }

  if (!jobPosition
    || !applicant
    || ([JobApplicationStatus.ASSIGNED, JobApplicationStatus.OFFER].includes(jobApplication.status) && !agent)) {
    return <InlineCardSkeleton />;
  }

  return (
    <Card>
      <Card.Inline>
        <Card.Box>
          <DetailItemList>
            <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
              {applicant.name}
            </DetailItemList.Item>
            {applicant.phoneNumber && (
              <DetailItemList.Item icon={ICON_PHONE_OUTLINE}>
                {applicant.phoneNumber}
              </DetailItemList.Item>
            )}
          </DetailItemList>
          <Spacing margin={[100, 0, 0]}>
            <UserActions
              user={applicant}
              hideLinkedInAction={false}
            />
          </Spacing>
        </Card.Box>

        <Card.ColumnDivider />

        {compact && (
          <Card.Box>
            <DetailItemList>
              <DetailItemList.Item>
                <JobApplicationStatusBadge status={jobApplication.status} size="XS" />
              </DetailItemList.Item>

              <DetailItemList.Item icon={ICON_BRIEFCASE_OUTLINE}>
                {jobPosition.name}
              </DetailItemList.Item>

              {jobApplication.province && (
                <DetailItemList.Item icon={ICON_MAP_MARKER_RADIUS_OUTLINE}>
                  {jobApplication.province}
                </DetailItemList.Item>
              )}

              {jobApplication.createdAt && (
                <DetailItemList.Item icon={ICON_CLOCK_OUTLINE}>
                  {formatDate(new Date(jobApplication.createdAt))}
                </DetailItemList.Item>
              )}
            </DetailItemList>
          </Card.Box>
        )}

        {!compact && jobApplication.status === JobApplicationStatus.IN_PROGRESS && (
          <Card.Box>
            <DetailItemList>
              {(jobApplication.source || jobPosition.department) && (
                <DetailItemList.Item>
                  <HStack>
                    {jobApplication.source && (
                      <Badge
                        label={jobApplication.source}
                        size="XS"
                      />
                    )}
                    {jobPosition.department && (
                      <Badge
                        label={jobPositionDepartmentLabels[jobPosition.department]}
                        size="XS"
                        color="primary"
                      />
                    )}
                  </HStack>
                </DetailItemList.Item>
              )}

              {jobApplication.province && (
                <DetailItemList.Item icon={ICON_MAP_MARKER_RADIUS_OUTLINE}>
                  {jobApplication.province}
                </DetailItemList.Item>
              )}

              {jobApplication.experience && (
                <DetailItemList.Item icon={ICON_BRIEFCASE_OUTLINE}>
                  {jobApplication.experience}
                </DetailItemList.Item>
              )}

              {jobApplication.createdAt && (
                <DetailItemList.Item icon={ICON_CLOCK_OUTLINE}>
                  {formatDate(new Date(jobApplication.createdAt))}
                </DetailItemList.Item>
              )}

              {jobApplication.notes.length > 0 && (
                <DetailItemList.Item>
                  {truncateTextWithSuffix(jobApplication.notes[0].text, 100)}
                </DetailItemList.Item>
              )}
            </DetailItemList>
          </Card.Box>
        )}

        {!compact && jobApplication.status === JobApplicationStatus.ASSIGNED && (
          <>
            <Card.Box>
              <DetailItemList>
                {(jobApplication.source || jobPosition.department) && (
                  <DetailItemList.Item>
                    <HStack>
                      {jobApplication.source && (
                        <Badge
                          label={jobApplication.source}
                          size="XS"
                        />
                      )}
                      {jobPosition.department && (
                        <Badge
                          label={jobPositionDepartmentLabels[jobPosition.department]}
                          size="XS"
                          color="primary"
                        />
                      )}
                    </HStack>
                  </DetailItemList.Item>
                )}

                {jobApplication.province && (
                  <DetailItemList.Item icon={ICON_MAP_MARKER_RADIUS_OUTLINE}>
                    {jobApplication.province}
                  </DetailItemList.Item>
                )}

                {jobApplication.notes.length > 0 && (
                  <DetailItemList.Item>
                    {format(new Date(jobApplication.notes[0].date!), 'dd/MM')} {truncateTextWithSuffix(jobApplication.notes[0].text, 100)}
                  </DetailItemList.Item>
                )}
              </DetailItemList>
            </Card.Box>

            {isXLView && (
              <>
                <Card.ColumnDivider />

                <Card.Box>
                  <DetailItemList>
                    <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
                      {getAgentName(agent)}
                    </DetailItemList.Item>
                    {jobApplication.assignment!.date && (
                      <DetailItemList.Item icon={ICON_CALENDAR_CLOCK_OUTLINE}>
                        {formatDate(new Date(jobApplication.assignment!.date))}
                      </DetailItemList.Item>
                    )}
                    {jobApplication.createdAt && (
                      <DetailItemList.Item icon={ICON_CLOCK_OUTLINE}>
                        {formatDate(new Date(jobApplication.createdAt))}
                      </DetailItemList.Item>
                    )}
                  </DetailItemList>
                </Card.Box>
              </>
            )}
          </>
        )}

        {!compact && jobApplication.status === JobApplicationStatus.OFFER && (
          <>
            <Card.Box>
              <DetailItemList>
                {(jobApplication.source || jobPosition.department) && (
                  <DetailItemList.Item>
                    <HStack>
                      {jobApplication.source && (
                        <Badge
                          label={jobApplication.source}
                          size="XS"
                        />
                      )}
                      {jobPosition.department && (
                        <Badge
                          label={jobPositionDepartmentLabels[jobPosition.department]}
                          color="primary"
                          size="XS"
                        />
                      )}
                    </HStack>
                  </DetailItemList.Item>
                )}

                {jobApplication.province && (
                  <DetailItemList.Item icon={ICON_MAP_MARKER_RADIUS_OUTLINE}>
                    {jobApplication.province}
                  </DetailItemList.Item>
                )}

                {jobApplication.notes.length > 0 && (
                  <DetailItemList.Item>
                    {format(new Date(jobApplication.notes[0].date!), 'dd/MM')} {truncateTextWithSuffix(jobApplication.notes[0].text, 100)}
                  </DetailItemList.Item>
                )}
              </DetailItemList>
            </Card.Box>

            <Card.ColumnDivider />

            <Card.Box>
              <DetailItemList>
                <DetailItemList.Item icon={ICON_ACCOUNT_OUTLINE}>
                  {getAgentName(agent)}
                </DetailItemList.Item>
                {jobApplication.updatedAt && (
                  <DetailItemList.Item icon={ICON_CLOCK_OUTLINE}>
                    {formatDate(new Date(jobApplication.updatedAt))}
                  </DetailItemList.Item>
                )}
              </DetailItemList>
            </Card.Box>
          </>
        )}

        {!compact && jobApplication.status === JobApplicationStatus.HIRED && (
          <Card.Box>
            <DetailItemList>
              {(jobApplication.source || jobPosition.department) && (
                <DetailItemList.Item>
                  <HStack>
                    {jobApplication.source && (
                      <Badge
                        label={jobApplication.source}
                        size="XS"
                      />
                    )}
                    {jobPosition.department && (
                      <Badge
                        label={jobPositionDepartmentLabels[jobPosition.department]}
                        size="XS"
                        color="primary"
                      />
                    )}
                  </HStack>
                </DetailItemList.Item>
              )}

              {jobApplication.province && (
                <DetailItemList.Item icon={ICON_MAP_MARKER_RADIUS_OUTLINE}>
                  {jobApplication.province}
                </DetailItemList.Item>
              )}

              {jobApplication.updatedAt && (
                <DetailItemList.Item icon={ICON_CLOCK_OUTLINE}>
                  {formatDate(new Date(jobApplication.updatedAt))}
                </DetailItemList.Item>
              )}
            </DetailItemList>
          </Card.Box>
        )}

        {!compact && isArchived(jobApplication) && (
          <Card.Box>
            <DetailItemList>
              <DetailItemList.Item>
                <Badge
                  label={jobApplicationStatusLabels[jobApplication.status]}
                  color="critical"
                  size="XS"
                />
              </DetailItemList.Item>
              {jobApplication.archivingReason && (
                <DetailItemList.Item>
                  {truncateTextWithSuffix(jobApplication.archivingReason, 180)}
                </DetailItemList.Item>
              )}
            </DetailItemList>
          </Card.Box>
        )}

        <Card.ColumnDivider />

        <Card.Box fit>
          <HStack>
            {!hideViewAction && (
              <ActionIcon
                dataRef="view-action"
                icon={{ path: ICON_EYE_OUTLINE }}
                label="Visualizza"
                onClick={onView}
              />
            )}

            {!compact
              && !hideUpsertReminderAction
              && !isFinal(jobApplication)
              && (
                <UpsertReminderActionV3
                  reminder={reminder}
                  label="Promemoria"
                  disabled={isUpsertReminderActionDisabled}
                  onSuccess={onUpsertReminderSuccess}
                />
              )}

            {!compact
              && !hideAssignAction
              && isAssignable(jobApplication)
              && (
                <AssignJobApplication
                  jobApplication={jobApplication}
                  disabled={!isSalesJobApplication}
                  onSuccess={onAssign}
                />
              )}

            {!compact && !hideOfferAction && jobApplication.status === JobApplicationStatus.ASSIGNED && (
              <OfferJobApplication
                jobApplication={jobApplication}
                onSuccess={onOffer}
              />
            )}

            {!compact && !hideHireAction && jobApplication.status === JobApplicationStatus.OFFER && jobPosition.department !== Department.SALES && (
              <HireJobApplication
                jobApplication={jobApplication}
                onSuccess={onHire}
              />
            )}

            {!compact && !hideHireAction && jobApplication.status === JobApplicationStatus.OFFER && jobPosition.department === Department.SALES && (
              <HireApplicantAction
                jobApplication={jobApplication}
                applicant={applicant}
              />
            )}

            {!compact && !hideArchiveAction && !isFinal(jobApplication) && (
              <ArchiveJobApplication
                jobApplication={jobApplication}
                onSuccess={onArchive}
              />
            )}
          </HStack>
        </Card.Box>
      </Card.Inline>
    </Card>
  );
};

export default ViewJobApplicationCard;
