import { OwnershipType, PropertyType, Status } from '../../../domain/types';
import { Property } from '../../../providers/api/dtos';
import { toGeo, toGeoFormModel } from '../../geo/mappers/geoMapper';
import { EditPropertyInfoFormModel } from './EditPropertyInfoForm';

export const propertyToEditPropertyInfoFormModel = (property: Property): EditPropertyInfoFormModel => ({
  status: property.status,
  geo: toGeoFormModel(property.geo),
  overriddenGeo: toGeoFormModel(property.overriddenGeo),
  ownershipType: property.ownershipType,
  propertyType: property.propertyType,
  propertySize: property.propertySize?.toFixed(0) || '',
  walkableSize: property.walkableSize?.toFixed(0) || '',
  energyClass: property.energyClass || '',
  energyValue: property.energyValue?.toString() || '',
  yearOfDevelopment: property.yearOfDevelopment?.toFixed(0) || '',
  registryCategory: property.registryCategory || '',
  registryStatus: property.registryStatus || '',
  propertyStatus: property.propertyStatus || '',
  privateGarden: property.privateGarden?.toFixed(0) || '',
  additionalExpenses: property.additionalExpenses?.toFixed(0) || '',
  furniture: property.furniture || '',
  windowExposure: property.windowExposure || '',
  tv: property.tv || '',
  heatingType: property.heating?.type || '',
  heatingSystem: property.heating?.system || '',
  heatingSupply: property.heating?.supply || '',
  airConditioningType: property.airConditioning?.type || '',
  airConditioningMode: property.airConditioning?.mode ?? '',
  price: property.price?.toFixed(0) || '',
  minimumPrice: property.minimumPrice?.toFixed(0) || '',
  evaluation: property.evaluation?.toFixed(0) || '',
  features: property.features.map(({ type }) => type),
  mainFloor: property.mainFloor?.toString() || '',
  buildingFloors: property.buildingFloors?.toFixed(0) || '',
  isBannerPlaced: property.isBannerPlaced,
  bannerNotes: property.bannerNotes || '',
  classType: property.classType || '',
  enableGeoOverride: !!property.overriddenGeo,
});

export const editPropertyInfoFormModelToProperty = (
  values: EditPropertyInfoFormModel,
  existingProperty: Property,
): Property => ({
  ...(existingProperty || {}),
  geo: toGeo(values.geo),
  overriddenGeo: toGeo(values.overriddenGeo),
  status: values.status as Status,
  ownershipType: values.ownershipType as OwnershipType,
  propertyType: values.propertyType as PropertyType,
  propertySize: values.propertySize ? Number.parseFloat(values.propertySize) : undefined,
  walkableSize: values.walkableSize ? Number.parseFloat(values.walkableSize) : undefined,
  energyClass: values.energyClass || undefined,
  energyValue: values.energyValue ? Number.parseFloat(values.energyValue) : undefined,
  registryStatus: values.registryStatus || undefined,
  yearOfDevelopment: values.yearOfDevelopment ? Number.parseInt(values.yearOfDevelopment, 10) : undefined,
  registryCategory: values.registryCategory || undefined,
  propertyStatus: values.propertyStatus || undefined,
  privateGarden: values.privateGarden ? Number.parseFloat(values.privateGarden) : undefined,
  additionalExpenses: values.additionalExpenses ? Number.parseFloat(values.additionalExpenses) : undefined,
  furniture: values.furniture || undefined,
  mainFloor: values.mainFloor ? Number.parseFloat(values.mainFloor) : undefined,
  buildingFloors: values.buildingFloors ? Number.parseInt(values.buildingFloors, 10) : undefined,
  windowExposure: values.windowExposure || undefined,
  tv: values.tv || undefined,
  price: values.price ? Number.parseInt(values.price, 10) : undefined,
  minimumPrice: values.minimumPrice ? Number.parseInt(values.minimumPrice, 10) : undefined,
  evaluation: values.evaluation ? Number.parseInt(values.evaluation, 10) : undefined,
  features: values.features.map((featureType) => ({
    id: existingProperty.features.find(({ type }) => type === featureType)?.id || null,
    type: featureType,
  })),
  heating: values.heatingType ? {
    id: existingProperty.heating?.type === values.heatingType ? existingProperty.heating?.id : null,
    type: values.heatingType,
    supply: values.heatingSupply || null,
    system: values.heatingSystem || null,
    about: existingProperty.heating?.type === values.heatingType ? existingProperty.heating?.about : null,
  } : undefined,
  airConditioning: values.airConditioningType ? {
    id: existingProperty.airConditioning?.type === values.airConditioningType ? existingProperty.airConditioning?.id : null,
    type: values.airConditioningType,
    mode: values.airConditioningMode || null,
  } : undefined,
  isBannerPlaced: !!values.isBannerPlaced,
  bannerNotes: values.bannerNotes || undefined,
  classType: values.classType || undefined,
});
