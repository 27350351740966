import React from 'react';
import { PASS_THROUGH_VALIDATION_SCHEMA } from '../../../../../constants';
import { useCurrentAgent } from '../../../../../hooks/use-agent/useAgent';
import useLead from '../../../../../lead/hooks/use-lead/useLead';
import CreateAssignmentForm, { CreateAssignmentFormModel } from '../../create-assignment-form/CreateAssignmentForm';
import { AssignmentWizardStepProps } from '../types';
import { toAssignmentWizardData, toAssignmentWizardDetails } from './mappers';

const AssignmentWizardDetails: React.FC<AssignmentWizardStepProps> = ({
  prospect,
  initialData,
  loading = false,
  formRef,
  onSubmit,
}) => {
  const { data: lead, isLoading: isLeadLoading } = useLead(prospect?.leadId);

  const currentAgent = useCurrentAgent();
  const canEditEvaluation = React.useMemo(() => currentAgent && !currentAgent?.needsSupervisor, [currentAgent]);

  const onInternalSubmit = React.useCallback((formValues: CreateAssignmentFormModel) => {
    const values = toAssignmentWizardData(initialData, formValues);

    // TODO: Fix the type in bricks
    const isFormDirty = (formRef?.current as any)?.dirty ?? false;

    onSubmit(values, isFormDirty);
  }, [formRef, initialData, onSubmit]);

  return (
    <CreateAssignmentForm
      initialValues={toAssignmentWizardDetails(initialData, prospect, lead)}
      validationSchema={PASS_THROUGH_VALIDATION_SCHEMA}
      formRef={formRef}
      onSubmit={onInternalSubmit}
      loading={loading || isLeadLoading}
      canEditEvaluation={canEditEvaluation}
    />
  );
};

export default AssignmentWizardDetails;
