import {
  ICON_PROGRESS_CHECK, ICON_CHECK_CIRCLE_OUTLINE, ICON_PROGRESS_ALERT, ICON_ALERT_CIRCLE_OUTLINE,
} from '@doveit/bricks';
import { MembershipStatus } from '../domain/types';

export const membershipStatusBadgeIcon: Record<MembershipStatus, string> = {
  [MembershipStatus.UNDER_ACTIVATION]: ICON_PROGRESS_CHECK,
  [MembershipStatus.ACTIVE]: ICON_CHECK_CIRCLE_OUTLINE,
  [MembershipStatus.UNDER_REVOCATION]: ICON_PROGRESS_ALERT,
  [MembershipStatus.REVOKED]: ICON_ALERT_CIRCLE_OUTLINE,
};
