import {
  Action,
  ActionIcon, FormHandlers, ICON_NOTE_PLUS_OUTLINE, ModalActions, ModalSize, Portal, useModal,
} from '@doveit/bricks';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import CreateIntentWithAppointment, { CreateIntentWithAppointmentProps } from '../create-intent-with-appointment/CreateIntentWithAppointment';
import { Appointment, Intent, Property } from '../../../providers/api/dtos';
import { canAddIntentToProperty } from '../../../property/utils';

interface ChildrenFnProps {
  disabled: boolean,
  openCreateIntentModal: ModalActions<unknown>['open'],
}

export interface CreateIntentWithAppointmentActionProps extends Omit<CreateIntentWithAppointmentProps, 'propertyId'> {
  property?: Pick<Property, 'id' | 'status'>,
  disabled?: boolean,
  children?: (props: ChildrenFnProps) => React.ReactElement,
}

const CreateIntentWithAppointmentAction: React.FC<CreateIntentWithAppointmentActionProps> = ({
  contactId,
  property,
  source,
  disabled = false,
  children,
  onAppointmentCreated,
  onAppointmentAborted,
  onIntentCreated,
}) => {
  const bodyRef = React.useRef<HTMLDivElement | null>(null);
  const [intentCreated, setIntentCreated] = React.useState<Intent | undefined>(undefined);
  const intentModal = useModal();
  const createIntentFormRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;

  const openModal = React.useCallback(() => {
    intentModal.open();
  }, [intentModal]);

  const internalOnIntentCreated = React.useCallback((intent: Intent) => {
    bodyRef.current?.lastElementChild?.scrollIntoView();
    setIntentCreated(intent);

    if (onIntentCreated) {
      onIntentCreated(intent);
    }
  }, [onIntentCreated]);

  const onInternalAppointmentCreated = React.useCallback((intent: Intent, appointment: Appointment) => {
    intentModal.close();

    if (onAppointmentCreated) {
      onAppointmentCreated(intent, appointment);
    }
  }, [intentModal, onAppointmentCreated]);

  const onInternalAppointmentAborted = React.useCallback((intent: Intent) => {
    intentModal.close();

    if (onAppointmentAborted) {
      onAppointmentAborted(intent);
    }
  }, [intentModal, onAppointmentAborted]);

  const onClose = React.useCallback(() => {
    intentModal.close();

    if (onAppointmentAborted && intentCreated) {
      onAppointmentAborted(intentCreated);
    }
  }, [intentCreated, intentModal, onAppointmentAborted]);

  const notToShow = React.useMemo(
    () => !!property && !canAddIntentToProperty(property.status),
    [property],
  );

  const submitCreateIntentForm = React.useCallback(() => {
    createIntentFormRef.current?.handleSubmit();
  }, []);

  if (notToShow) {
    return null;
  }

  return (
    <>
      {children
        ? children({
          disabled,
          openCreateIntentModal: openModal,
        })
        : (
          <ActionIcon
            label="Aggiungi interesse"
            aria-label="Aggiungi interesse d'acquisto"
            icon={{ path: ICON_NOTE_PLUS_OUTLINE }}
            onClick={openModal}
            disabled={disabled}
          />
        )}
      <Portal>
        <SimpleModal
          {...intentModal}
          title={intentCreated ? 'Aggiungi appuntamento' : 'Aggiungi interesse'}
          size={ModalSize.LARGE}
          aria-label="Modale per aggiungere un interesse d'acquisto"
          close={onClose}
          bodyRef={bodyRef}
          footer={!intentCreated ? (
            <Action
              label="Aggiungi interesse"
              color="primary"
              emphasis="high"
              aria-label="Pulsante per aggiungere un nuovo interesse d'acquisto"
              onClick={submitCreateIntentForm}
            />
          ) : undefined}
        >
          <CreateIntentWithAppointment
            contactId={contactId}
            propertyId={property?.id}
            source={source}
            onIntentCreated={internalOnIntentCreated}
            onAppointmentCreated={onInternalAppointmentCreated}
            onAppointmentAborted={onInternalAppointmentAborted}
            createIntentFormRef={createIntentFormRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default CreateIntentWithAppointmentAction;
