import { ShootingStatus, ShootingType } from '../domain/types';
import { shootingStatusLabels } from '../labels';
import { Shooting } from '../providers/api/dtos';
import { WithRequiredKeys } from '../types';
import { formatInputDate, formatInputTime, formDateTimeToDate } from '../utils/form';
import { UPSERT_SHOOTING_FORM_DEFAULT_VALUES, UpsertShootingFormModel } from './components/upsert-shooting-form/UpsertShootingForm';
import {
  colorsTypeAgent, colorsTypeDirectOrPartner, iconsTypeAgent, iconsTypeDirectOrPartner,
} from './constants';
import { ComputedShootingStatus } from './hooks/use-computed-shooting-status/useComputedShootingStatus';

export const shootingToUpsertShootingFormModel = (shooting?: Shooting): UpsertShootingFormModel => {
  if (!shooting) return UPSERT_SHOOTING_FORM_DEFAULT_VALUES;

  return {
    type: shooting.type ?? '',
    date: formatInputDate(new Date(shooting.date)),
    time: formatInputTime(new Date(shooting.date)),
    photoPrice: shooting.price.photo?.toString() ?? '',
    extraServicesPrice: shooting.price.extraServices?.toString() ?? '',
    expensesRefund: shooting.price.refund?.toString() ?? '',
    matterportDeliveryDate: shooting.matterportDeliveryDate
      ? formatInputDate(new Date(shooting.matterportDeliveryDate))
      : '',
    photoDeliveryDate: shooting.photoDeliveryDate
      ? formatInputDate(new Date(shooting.photoDeliveryDate))
      : '',
    matterportRequest: shooting.matterportRequest,
    postProcessingRequest: shooting?.postProcessingRequest,
    notes: shooting.notes ?? '',
    photographerName: shooting?.photographerName ?? '',
  };
};

export const upsertShootingFormModelToShooting = (
  formValues: UpsertShootingFormModel,
  existingShooting: WithRequiredKeys<Partial<Shooting>, 'propertyId' | 'status'>,
): Shooting => ({
  ...existingShooting,
  date: formDateTimeToDate(formValues.date, formValues.time).toISOString(),
  matterportRequest: formValues.matterportRequest,
  postProcessingRequest: formValues.postProcessingRequest,
  price: {
    photo: formValues.photoPrice.trim() ? parseFloat(formValues.photoPrice.trim()) : undefined,
    extraServices: formValues.extraServicesPrice.trim() ? parseFloat(formValues.extraServicesPrice.trim()) : undefined,
    refund: formValues.expensesRefund.trim() ? parseFloat(formValues.expensesRefund.trim()) : undefined,
  },
  type: formValues.type as ShootingType,
  matterportDeliveryDate: formValues.matterportDeliveryDate ? new Date(formValues.matterportDeliveryDate).toISOString() : undefined,
  photoDeliveryDate: formValues.photoDeliveryDate ? new Date(formValues.photoDeliveryDate).toISOString() : undefined,
  notes: formValues.notes.trim() || undefined,
  photographerName: formValues.photographerName.trim() || undefined,
});

export function computeShootingStatusStyles(
  shootingStatus: ComputedShootingStatus,
  shootingType: ShootingType,
  userIsAdminContentOrAgent: boolean,
) {
  if (shootingStatus === 'NO_STATUS') {
    return {
      icon: undefined,
      color: undefined,
      label: undefined,
    };
  }

  if (userIsAdminContentOrAgent && [ShootingType.DIRECT, ShootingType.PARTNER].includes(shootingType)) {
    return {
      icon: iconsTypeDirectOrPartner[shootingStatus],
      color: colorsTypeDirectOrPartner[shootingStatus],
      label: shootingStatusLabels[shootingStatus],
    };
  }

  if (userIsAdminContentOrAgent && shootingType === ShootingType.AGENT) {
    return {
      icon: iconsTypeAgent[shootingStatus] || undefined,
      color: colorsTypeAgent[shootingStatus] || undefined,
      // eslint-disable-next-line no-nested-ternary
      label: (() => {
        switch (shootingStatus) {
          case ShootingStatus.DELIVERED:
            return 'Foto agente';
          case ShootingStatus.COMPLETED:
            return shootingStatusLabels[shootingStatus];
          default:
            return undefined;
        }
      })(),
    };
  }

  return {
    icon: undefined,
    color: undefined,
    label: undefined,
  };
}
