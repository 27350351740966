import {
  Label, AutoCompleteV2, AutoCompleteV2Props, InputAction,
} from '@doveit/bricks';
import React from 'react';
import { AreaType } from '../../../domain/types';
import { AreaSuggestion } from '../../../providers/public-api/dtos';
import { getAreaSuggestions } from '../../../providers/public-api/location/locationProvider';

export interface AgentAreaAutocompleteProps extends Pick<AutoCompleteV2Props<AreaSuggestion>, 'onSuggestionSelected'> {
  areaType: AreaType,
}

const labels = {
  [AreaType.LOCALITY]: 'Cerca una città',
  [AreaType.ZIP_CODE]: 'Cerca un CAP',
};

const printSuggestion = (areaSuggestion: AreaSuggestion) => areaSuggestion.suggestion;

const getSuggestions = (areaType: AreaType) => async (value: string) => getAreaSuggestions(value, areaType);

const AgentAreasAutocomplete: React.FC<AgentAreaAutocompleteProps> = ({
  areaType,
  onSuggestionSelected,
}) => {
  const [value, setValue] = React.useState<AreaSuggestion | null>(null);
  const [input, setInput] = React.useState('');

  const onInputChange = React.useCallback(
    (newValue: string, action: InputAction) => {
      switch (action) {
        case 'input-blur':
          setInput(value ? printSuggestion(value) : '');
          break;
        case 'input-change':
          setInput(newValue);
          setValue(newValue ? value : null);
          break;
        default:
          break;
      }
    },
    [value],
  );

  const onInternalSuggestionSelected = React.useCallback((suggestion: AreaSuggestion | null) => {
    setInput(suggestion ? printSuggestion(suggestion) : '');
    setValue(suggestion);

    onSuggestionSelected?.(suggestion);
  }, [onSuggestionSelected]);

  return (
    <>
      <Label text={labels[areaType]} />
      <AutoCompleteV2
        aria-label="Seleziona un agente"
        placeholder="Inizia a digitare"
        printSuggestion={printSuggestion}
        suggestions={getSuggestions(areaType)}
        onSuggestionSelected={onInternalSuggestionSelected}
        value={value || undefined}
        inputValue={input}
        onInputChange={onInputChange}
      />
    </>
  );
};

export default AgentAreasAutocomplete;
