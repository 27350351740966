/* eslint-disable react/no-unknown-property */
import {
  ActionIcon,
  ICON_BELL_OUTLINE, Popover, Card, Dropdown, FontWeight, HStack, Icon, ICON_CALENDAR_OUTLINE, ICON_EYE_OUTLINE, ICON_MAP_MARKER_OUTLINE, Stack, Text, useNotifications, UserPreview,
} from '@doveit/bricks';
import React from 'react';
import { isFuture } from 'date-fns';
import { formatEuro } from '@doveit/hammer';
import { AgentPreview } from '../../../agent/components';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import {
  AppointmentStatus, ProspectApprovalStatus, ProspectStatus, ReferenceType,
} from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import ViewPropertyPopoverPreview from '../../../property/containers/view-property-popover-preview/ViewPropertyPopoverPreview';
import { NO_VALUE_SYMBOL } from '../../../property/utils';
import {
  Appointment, Prospect, Note, Reminder, SerpProspect,
} from '../../../providers/api/dtos';
import { goToDetailPage } from '../../../utils/navigate/utils';
import * as styles from './ViewSerpProspectCard.style';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import ViewContactPopoverPreview from '../../../contact/containers/view-contact-popover-preview/ViewContactPopoverPreview';
import { truncateTextWithSuffix } from '../../../utils/text/text';
import UpsertReminderAction from '../../../reminders/containers/upsert-reminder-action/UpsertReminderAction';
import UpsertNoteAction from '../../../notes/containers/UpsertNoteAction';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import { updateProspect } from '../../../providers/api/prospect/prospectProvider';
import { buildWhatsappMessageSentNote } from '../../../intent/utils/buildWhatsappMessageSentNote';
import { prospectMessagesProvider } from '../../../containers/send-whatsapp-action/messages/prospectMessagesProvider';
import useAssignmentByProspectId from '../../hooks/use-assignment-by-prospect-id/useAssignmentByProspectId';
import ViewProspectPopoverPreview from '../view-prospect-popover-preview/ViewProspectPopoverPreview';
import ProspectAvatar from '../../components/prospect-avatar/ProspectAvatar';
import UpdateProspectStatus from '../update-prospect-status/UpdateProspectStatus';
import useProperty from '../../../property/hooks/use-property/useProperty';
import UpdateProspectApprovalStatus from '../update-prospect-approval-status/UpdateProspectApprovalStatus';
import UpdateAppointmentStatus from '../../../reminders/components/update-appointment-status/UpdateAppointmentStatus';
import { computeResolvableStatus } from '../../../utils/reminder/reminder';
import { ResolvableReminderStatus } from '../../../types';
import UpsertAppointmentAction from '../../../appointment/containers/upsert-appointment-action/UpsertAppointmentAction';

const MAX_NOTE_LENGTH = 100;
export const UPSERT_NOTE_ERROR_MESSAGE = 'Non è stato possibile aggiungere la nota.';
export const UPSERT_NOTE_SUCCESS_MESSAGE = 'Nota aggiunta con successo.';

export interface ViewSerpProspectCardProps extends React.AriaAttributes {
  serpProspect: SerpProspect,
  onProspectUpdate?: (prospect: Prospect) => void,
  onAppointmentCreate?: (appointment: Appointment) => void,
  onAppointmentUpdate?: (appointment: Appointment) => void,
  onReminderCreate?: (reminder: Reminder) => void,
}

const ViewSerpProspectCard: React.FC<ViewSerpProspectCardProps> = ({
  serpProspect,
  onProspectUpdate,
  onAppointmentCreate,
  onAppointmentUpdate,
  onReminderCreate,
  ...rest
}) => {
  const {
    prospect, agent, contact, appointment, lead, reminder,
  } = serpProspect;

  const {
    user, mainUserRole, userIsAdmin,
  } = useRBAC();
  const { addSuccess, addError } = useNotifications();

  const { data: assignment } = useAssignmentByProspectId(prospect.status === ProspectStatus.ASSIGNMENT_CREATED ? prospect.id : undefined);
  const { data: property } = useProperty(assignment?.propertyId);

  const userIsProspectAgentOrManager = useCurrentAgentIsSameAgentOrManager(agent?.id);
  const userIsAdminOrOwnerOrManager = React.useMemo(
    () => userIsAdmin || userIsProspectAgentOrManager,
    [userIsAdmin, userIsProspectAgentOrManager],
  );

  const reminderToCreate: Reminder = React.useMemo(() => ({
    agentId: agent.id,
    referenceId: prospect.id,
    referenceType: ReferenceType.PROSPECT,
    resolved: false,
  } as Reminder), [agent, prospect.id]);

  const appointmentToCreate: Appointment = React.useMemo(() => ({
    agentId: agent.id,
    contactId: prospect.contactId,
    referenceId: prospect.id,
    referenceType: ReferenceType.PROSPECT,
  } as Appointment), [agent?.id, prospect.contactId, prospect.id]);

  const goToProspectPage = React.useCallback(() => {
    goToDetailPage(prospect.id!, ReferenceType.PROSPECT, true);
  }, [prospect.id]);

  const showReminder = React.useMemo(
    () => appointment?.status !== AppointmentStatus.TODO && reminder && computeResolvableStatus(reminder) === ResolvableReminderStatus.ACTIVE,
    [appointment?.status, reminder],
  );

  const showApprovalStatus = React.useMemo(
    () => (userIsAdmin || userIsProspectAgentOrManager) && prospect.agentEvaluation !== undefined && prospect.status === ProspectStatus.IN_PROGRESS && agent.needsSupervisor,
    [agent.needsSupervisor, prospect.agentEvaluation, prospect.status, userIsAdmin, userIsProspectAgentOrManager],
  );

  const handleNoteCreated = React.useCallback(async (note: Note) => {
    if (!prospect) return;

    try {
      const updatedProspect = await updateProspect(prospect.id!, {
        ...prospect,
        notes: [note, ...(prospect.notes || [])],
      });

      onProspectUpdate?.(updatedProspect);
      addSuccess(UPSERT_NOTE_SUCCESS_MESSAGE);
    } catch (err) {
      addError(UPSERT_NOTE_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, onProspectUpdate, prospect]);

  const onMessageSent = React.useCallback(async (message: string) => {
    if (!prospect) return;

    try {
      const updatedProspect = await updateProspect(prospect.id!, {
        ...prospect,
        notes: [{
          text: buildWhatsappMessageSentNote(message),
          author: user.name,
          role: mainUserRole,
        }, ...(prospect.notes || [])],
      });
      onProspectUpdate?.(updatedProspect);
      // eslint-disable-next-line no-empty
    } catch (error) { }
  }, [mainUserRole, onProspectUpdate, prospect, user.name]);

  return (
    <Card {...rest}>
      <Card.Header
        primaryActions={[(
          <ActionIcon
            aria-label="Visualizza valutazione"
            label="Visualizza"
            size="S"
            icon={{ path: ICON_EYE_OUTLINE }}
            onClick={goToProspectPage}
          />
        )]}
        secondaryActions={[
          contact.phoneNumber && (
            <SendWhatsappAction
              phoneNumber={contact.phoneNumber}
              name={contact.name}
              fetchMessages={prospectMessagesProvider(prospect, agent)}
              onMessageSent={onMessageSent}
            >
              {({ onClick }) => (
                <Dropdown.Option
                  label="Invia whatsapp"
                  onClick={onClick}
                />
              )}
            </SendWhatsappAction>
          ),
          <UpsertReminderAction
            reminder={reminderToCreate}
            onSuccess={onReminderCreate}
            aria-label="Pulsante per aggiungere un promemoria"
          >
            {({ upsert }) => (
              <Dropdown.Option
                label="Aggiungi promemoria"
                onClick={upsert}
              />
            )}
          </UpsertReminderAction>,
          <UpsertAppointmentAction
            appointment={appointmentToCreate}
            onSuccess={onAppointmentCreate}
          >
            {({ upsert }) => (
              <Dropdown.Option
                label="Aggiungi appuntamento"
                onClick={upsert}
              />
            )}
          </UpsertAppointmentAction>,
          <UpsertNoteAction
            upsertNote={handleNoteCreated}
            aria-label="Pulsante per aggiungere una nota"
          >
            {({ upsert }) => (
              <Dropdown.Option
                label="Aggiungi nota"
                onClick={upsert}
              />
            )}
          </UpsertNoteAction>,
        ]}
      >
        {!property && (
          <ViewProspectPopoverPreview
            prospect={prospect}
            trigger={(
              <HStack gap={100} wrap="nowrap">
                <ProspectAvatar
                  size="S"
                />
                <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                  {lead.propertyAddress}
                </Text.BodySmall>
              </HStack>
            )}
          />
        )}
        {property && (
          <ViewPropertyPopoverPreview
            property={property}
            trigger={(
              <HStack gap={100} wrap="nowrap">
                <PropertyAvatar
                  size="S"
                  icon={property.overriddenGeo ? ICON_MAP_MARKER_OUTLINE : undefined}
                />
                <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                  {property.overriddenGeo?.normalizedAddress || property.geo?.normalizedAddress}
                </Text.BodySmall>
              </HStack>
            )}
            showMatchingAction={userIsAdminOrOwnerOrManager}
          />
        )}
      </Card.Header>
      <Card.Content aria-label="Informazioni valutazione">
        <Stack gap={150}>
          <div>
            <UpdateProspectStatus
              prospect={prospect}
              showAsBadge={!(userIsAdmin || userIsProspectAgentOrManager)}
              size="XS"
              onSuccess={onProspectUpdate}
            />
          </div>

          <HStack gap={150}>
            <HStack
              gap={100}
              wrap="nowrap"
              css={styles.firstRowWrapper}
            >
              <ViewContactPopoverPreview
                contact={contact}
                trigger={(
                  <UserPreview
                    name={contact.name || NO_VALUE_SYMBOL}
                    size="S"
                  >
                    {contact.phoneNumber}
                  </UserPreview>
                )}
              />
              <ViewAgentPopoverPreview
                agent={agent}
                trigger={(
                  <AgentPreview
                    {...toAgentPreviewProps(agent, { omit: { name: true } })}
                    size="S"
                  />
                )}
              />
            </HStack>

            {showReminder && (
              <HStack
                gap={150}
                css={styles.secondRowWrapper}
                aria-label="Dettagli promemoria"
              >
                <HStack wrap="nowrap">
                  <Icon path={ICON_BELL_OUTLINE} size={20} />
                  <div css={styles.dateWrapper}>
                    <Stack gap={25}>
                      <Text.Mini transform="uppercase">
                        Data promemoria
                      </Text.Mini>
                      <Text.Caption fontWeight={FontWeight.REGULAR}>
                        <DateViewer
                          stringDate={serpProspect.reminder!.startDate}
                          checkIfToday
                          checkIfTomorrow
                          withHour={isFuture(serpProspect.reminder!.startDate)}
                        />
                      </Text.Caption>
                    </Stack>
                  </div>
                </HStack>
                <Popover
                  aria-label="Visualizza dettagli promemoria"
                  trigger={(
                    <Text.Caption>
                      {truncateTextWithSuffix(serpProspect.reminder!.notes, MAX_NOTE_LENGTH)}
                    </Text.Caption>
                  )}
                  hasArrow
                >
                  <div css={styles.noteAndReminderPopoverWrapper}>
                    <Text.BodySmall>{serpProspect.reminder!.notes}</Text.BodySmall>
                  </div>
                </Popover>
              </HStack>
            )}

            {(!showReminder && appointment) && (
              <HStack
                aria-label="Dettagli appuntamento"
                gap={150}
                css={styles.secondRowWrapper}
              >
                <HStack>
                  <Icon
                    path={ICON_CALENDAR_OUTLINE}
                    size={20}
                  />
                  <div css={styles.dateWrapper}>
                    <Stack gap={25}>
                      <Text.Mini transform="uppercase">
                        Data appuntamento
                      </Text.Mini>
                      <Text.Caption fontWeight={FontWeight.REGULAR}>
                        <DateViewer
                          stringDate={appointment.startDate}
                          checkIfToday
                          checkIfTomorrow
                          withHour={isFuture(appointment.startDate)}
                        />
                      </Text.Caption>
                    </Stack>
                  </div>
                </HStack>
                <UpdateAppointmentStatus
                  appointment={appointment}
                  onSuccess={onAppointmentUpdate}
                  showAsBadge={!(userIsAdmin || userIsProspectAgentOrManager)}
                />
              </HStack>
            )}

            {prospect.agentEvaluation !== undefined && (
              <HStack gap={150}>
                <div css={styles.dateWrapper}>
                  <Stack gap={25}>
                    <Text.Mini transform="uppercase">
                      Valutazione
                    </Text.Mini>
                    <Text.Caption
                      fontWeight={FontWeight.MEDIUM}
                      color={(prospect.approval && prospect.approval.status !== ProspectApprovalStatus.APPROVED)
                        ? 'critical.default.high'
                        : 'neutral.default.high'}
                    >
                      {formatEuro(prospect.agentEvaluation)}
                    </Text.Caption>
                  </Stack>
                </div>
                {showApprovalStatus && (
                  <UpdateProspectApprovalStatus
                    prospect={prospect}
                    onSuccess={onProspectUpdate}
                    size="XS"
                  />
                )}
              </HStack>
            )}
          </HStack>
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default ViewSerpProspectCard;
