import { Message, Skeleton } from '@doveit/bricks';
import React, { FunctionComponent, ReactElement } from 'react';
import { useNextCallCenterContactsCount } from '../../hooks/use-next-callcenter-contacts-count/useNextCallCenterContactsCount';

export const LOAD_ERROR_MESSAGE = 'Errore di caricamento';

export interface ViewContactToProcessCountProps {
  children: (count: number) => ReactElement,
}

const ViewContactToProcessCount: FunctionComponent<ViewContactToProcessCountProps> = ({
  children,
}) => {
  const { data: contactCount, error: contactCountError } = useNextCallCenterContactsCount();

  if (contactCountError) {
    return (
      <Message
        type="critical"
        message={LOAD_ERROR_MESSAGE}
      />
    );
  }

  if (!contactCount) {
    return <Skeleton />;
  }

  return children(contactCount.count);
};

export default ViewContactToProcessCount;
