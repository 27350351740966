import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const starsWrapper: ThemedFunctor = ({
  $size,
}) => css`
  display: flex;
  align-items: center;
  gap: ${($size * 0.15) / 24}rem;
`;

export const starBackground: ThemedFunctor = ({
  $color,
  $size,
}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${$color};
  padding: ${($size * 0.15) / 24}rem;
  color: #fff;
`;
