import {
  Action,
  Badge,
  HStack,
  Portal,
  Select,
  Stack, useModal,
} from '@doveit/bricks';
import { capitalize } from 'lodash';
import React from 'react';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import { Property } from '../../../providers/api/dtos';
import ManagePropertyGarages from '../../garages/containers/manage-property-garages/ManagePropertyGarages';
import ManagePropertyBalconies from '../../rooms/containers/manage-property-balconies/ManagePropertyBalconies';
import ManagePropertyCellars from '../../rooms/containers/manage-property-cellars/ManagePropertyCellars';
import ManagePropertyPrivateGardens from '../../rooms/containers/manage-property-private-gardens/ManagePropertyPrivateGardens';
import ManagePropertyRooms from '../../rooms/containers/manage-property-rooms/ManagePropertyRooms';
import ManagePropertyTerraces from '../../rooms/containers/manage-property-terraces/ManagePropertyTerraces';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';

const spaces = [
  'rooms',
  'terraces',
  'balconies',
  'cellars',
  'garages',
  'private-garden',
] as const;

type Space = typeof spaces[number];

const spacesLabels: Record<Space, string> = {
  rooms: 'stanze',
  terraces: 'terrazzi',
  balconies: 'balconi',
  cellars: 'cantine',
  garages: 'spazi auto',
  'private-garden': 'giardino privato',
};

interface ChildFnProps {
  manage: VoidFunction,
}

export interface ManagePropertySpacesActionProps {
  propertyId: NonNullable<Property['id']>,
  onManage?: VoidFunction,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const ManagePropertySpacesAction: React.FC<ManagePropertySpacesActionProps> = ({
  propertyId,
  onManage,
  children,
}) => {
  const isMobile = useIsDevice('mobile');
  const modal = useModal();
  const [selectedSpace, setSelectedSpace] = React.useState<Space>('rooms');

  return (
    <>
      {children
        ? children({ manage: () => modal.open() })
        : (
          <Action
            aria-label="Gestisci gli spazi dell'immobile"
            label="Gestisci spazi"
            size="S"
            onClick={() => modal.open()}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Gestisci spazi"
          aria-label="Modale per gestire gli spazi dell'immobile"
        >
          <Stack gap={400}>
            {!isMobile && (
              <HStack>
                {spaces.map((space) => (
                  <Badge
                    key={space}
                    label={capitalize(spacesLabels[space])}
                    color={selectedSpace === space ? 'primary' : 'neutral'}
                    onClick={selectedSpace === space ? undefined : () => setSelectedSpace(space)}
                  />
                ))}
              </HStack>
            )}

            {isMobile && (
              <Select
                value={selectedSpace}
                options={spaces.map((space) => ({
                  label: capitalize(spacesLabels[space]),
                  value: space,
                }))}
                onChange={(event) => { setSelectedSpace(event.target.value as Space); }}
              />
            )}

            {selectedSpace === 'rooms' && (
              <ManagePropertyRooms
                propertyId={propertyId}
                onCreate={onManage}
                onUpdate={onManage}
                onDelete={onManage}
              />
            )}
            {selectedSpace === 'balconies' && (
              <ManagePropertyBalconies
                propertyId={propertyId}
                onCreate={onManage}
                onUpdate={onManage}
                onDelete={onManage}
              />
            )}
            {selectedSpace === 'terraces' && (
              <ManagePropertyTerraces
                propertyId={propertyId}
                onCreate={onManage}
                onUpdate={onManage}
                onDelete={onManage}
              />
            )}
            {selectedSpace === 'cellars' && (
              <ManagePropertyCellars
                propertyId={propertyId}
                onCreate={onManage}
                onUpdate={onManage}
                onDelete={onManage}
              />
            )}
            {selectedSpace === 'garages' && (
              <ManagePropertyGarages
                propertyId={propertyId}
                onCreate={onManage}
                onUpdate={onManage}
                onDelete={onManage}
              />
            )}
            {selectedSpace === 'private-garden' && (
              <ManagePropertyPrivateGardens
                propertyId={propertyId}
                onCreate={onManage}
                onUpdate={onManage}
                onDelete={onManage}
              />
            )}
          </Stack>
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ManagePropertySpacesAction;
