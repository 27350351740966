/* eslint-disable react/no-unknown-property */
import {
  Image,
  Spinner,
  SpinnerSize,
  Icon,
  ICON_CHECK_CIRCLE_OUTLINE,
  ActionProps,
  ICON_ALERT_CIRCLE_OUTLINE,
  Text,
  FontWeight,
} from '@doveit/bricks';
import React from 'react';
import MediaActionsBar from '../media-actions-bar/MediaActionsBar';

import * as styles from './MediaCard.style';
import { ExtraActionIcon } from '../../types';

export type MediaCardStatusAction = Pick<ActionProps, 'onClick' | 'title' | 'disabled' | 'label'>;

export interface MediaCardProps extends React.AriaAttributes {
  dataRef?: string,
  selected?: boolean,
  imageSrc: string,
  primaryActions?: ExtraActionIcon[],
  secondaryActions?: ExtraActionIcon[],
  loading?: boolean,
  error?: boolean,
  success?: boolean,
  children?: React.ReactNode,
}

const MediaCard: React.FC<MediaCardProps> = ({
  dataRef = 'media-card',
  imageSrc,
  primaryActions,
  secondaryActions,
  loading,
  error,
  success,
  children,
  ...rest
}) => (
  <article
    {...rest}
    css={styles.basic}
    $error={error}
    $success={success}
  >
    {loading && (
      <div
        css={styles.overlay}
        data-ref={`${dataRef}-loading`}
      >
        <div css={styles.innerOverlay}>
          <div
            css={styles.icon}
            $color="info"
          >
            <Spinner
              size={SpinnerSize.SMALLER}
              color="status.info" /** TODO: update spinner with new token */
            />
          </div>
          <Text.BodySmall
            color="info.default.high"
            fontWeight={FontWeight.REGULAR}
          >
            In carica
          </Text.BodySmall>
        </div>
      </div>
    )}
    {!loading && error && (
      <div
        css={styles.overlay}
        data-ref={`${dataRef}-error`}
      >
        <div css={styles.innerOverlay}>
          <div
            css={styles.icon}
            $color="critical"
          >
            <Icon
              size={18}
              path={ICON_ALERT_CIRCLE_OUTLINE}
            />
          </div>
          <Text.BodySmall
            color="critical.default.high"
            fontWeight={FontWeight.REGULAR}
          >
            Errore
          </Text.BodySmall>
        </div>
      </div>
    )}
    {!loading && success && (
      <div data-ref={`${dataRef}-success`}>
        <div
          css={styles.innerOverlay}
          success
        >
          <div
            css={styles.icon}
            $color="success"
          >
            <Icon
              size={18}
              path={ICON_CHECK_CIRCLE_OUTLINE}
            />
          </div>
          <Text.BodySmall
            color="success.default.high"
            fontWeight={FontWeight.REGULAR}
          >
            Caricato
          </Text.BodySmall>
        </div>
      </div>
    )}
    <div css={styles.actionsBar}>
      <MediaActionsBar
        primary={primaryActions}
        secondary={secondaryActions}
        disabled={loading}
      />
    </div>
    <div css={styles.cover}>
      <Image
        src={imageSrc}
        alt=""
        lazyLoad
        responsive
      />
    </div>
    {children && (
      <div data-ref={`${dataRef}-children`}>
        {children}
      </div>
    )}
  </article>
);

export default MediaCard;
