import { css } from 'styled-components';
import {
  getTypography, TypographyName, ThemedFunctor, getSpacing, SpacingName,
} from '@doveit/bricks';

export const wrapper: ThemedFunctor = ({ theme }) => css`
  width: 100%;
  height: 400px;
  background-color: ${theme.palettes.status.infoLight};
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const header: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, SpacingName.SMALL)};
  ${getTypography(theme, TypographyName.HEADING_3)};
  color: ${theme.palettes.brand.primary};
  text-align: right;
  opacity: 0.9;
`;

export const points: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginLeft(theme, SpacingName.SMALLER)};
  color: ${theme.palettes.status.success};
`;

export const main: ThemedFunctor = ({ theme }) => css`
  flex: 1;
  position: relative;
  overflow: hidden;
  border-bottom: solid 20px ${theme.palettes.status.warning};
`;

export const start: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.HEADING_2)};
  color: ${theme.palettes.neutrals.medium};
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  text-align: center;
  background-color: rgba(0,0,0,.1);
`;
