import { Action, ICON_ARROW_UP } from '@doveit/bricks';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { getNextCallCenterContact } from '../../../providers/api/call-center/callCenterProvider';
import { ProcessableContact } from '../../../providers/api/dtos';
import { useMatchMutate } from '../../../utils/swr/swr';
import { NEXT_CALLCENTER_CONTACTS_COUNT_RID } from '../../hooks/use-next-callcenter-contacts-count/useNextCallCenterContactsCount';

export interface NextContactActionProps {
  disabled: boolean,
  onSuccess: (processableContact?: ProcessableContact) => void,
  onError: (err: Error) => void,
}

const NextContactAction: FunctionComponent<NextContactActionProps> = ({
  disabled,
  onSuccess,
  onError,
}) => {
  const [loading, setIsLoading] = useState(false);
  const mutateCallCenterContactsCount = useMatchMutate();

  const onClick = useCallback(async () => {
    setIsLoading(true);

    try {
      const processableContact = await getNextCallCenterContact();
      setIsLoading(false);
      mutateCallCenterContactsCount(NEXT_CALLCENTER_CONTACTS_COUNT_RID);

      onSuccess(processableContact);
    } catch (err) {
      setIsLoading(false);

      onError(err as Error);
    }
  }, [mutateCallCenterContactsCount, onSuccess, onError]);

  return (
    <Action
      label="Prossimo contatto"
      iconRight={{
        path: ICON_ARROW_UP,
        direction: 'right',
      }}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export default NextContactAction;
