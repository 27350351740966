import hash from 'object-hash';
import useSWR, { SWRConfiguration } from 'swr';
import { AxiosError } from 'axios';
import { CalendarEventsFilters, getContactAppointments } from '../../../providers/api/contact/contactProvider';
import { Appointment } from '../../../providers/api/dtos/appointment';
import { PaginatedResult, PaginationParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';
import { Contact } from '../../../providers/api/dtos';

const CONTACT_APPOINTMENTS_RID = '/api/v2/contacts/:id/appointments';

export default function useContactAppointments(
  contactId?: Contact['id'],
  filters?: CalendarEventsFilters,
  pagination?: PaginationParams,
  options?: SWRConfiguration<PaginatedResult<Appointment>, AxiosError>,
): SWRCustomResponse<PaginatedResult<Appointment>, AxiosError> {
  const matchMutate = useMatchMutate();
  const rid = [CONTACT_APPOINTMENTS_RID, contactId].join();
  const key = contactId ? [rid, hash({ ...filters, ...pagination })] : null;
  const result = useSWR(
    key,
    () => getContactAppointments(contactId!, filters, pagination),
    options,
  );
  return {
    ...result,
    mutate: () => matchMutate(rid),
  };
}
