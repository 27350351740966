import { ThemeDefinition } from '@doveit/bricks';
import type { Style } from '@react-pdf/types';

export const list: Style = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginHorizontal: -5,
  marginTop: -10,
};

export const item = (theme: ThemeDefinition): Style => ({
  lineHeight: 1,
  fontSize: 10,
  marginTop: 10,
  marginHorizontal: 5,
  paddingLeft: 8,
  paddingRight: 5,
  paddingVertical: 5,
  borderLeft: 1,
  borderBottom: 1,
  borderTop: 1,
  borderRight: 1, // wrongly declared by ReactPDF as never, that's why we cast
  borderLeftColor: theme.palettes.neutrals.light,
  borderRightColor: theme.palettes.neutrals.light,
  borderTopColor: theme.palettes.neutrals.light,
  borderBottomColor: theme.palettes.neutrals.light,
  borderBottomLeftRadius: 2,
  borderBottomRightRadius: 2,
  borderTopLeftRadius: 2,
  borderTopRightRadius: 2,
});
