import React from 'react';
import {
  ActionIcon, ICON_ACCOUNT_MULTIPLE_CHECK_OUTLINE, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { useNavigate } from 'react-router-dom';
import { Applicant, JobApplication } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import HireApplicantForm, { HireApplicantFormModel } from '../../components/hire-applicant-form/HireApplicantForm';
import { applicantToHire, getInitialValuesFromApplicant } from './mappers';
import { hireApplicant } from '../../../providers/api/applicant/applicantProvider';

interface ChildFnProps {
  openModal: VoidFunction,
  isLoading: boolean,
}

export interface HireApplicantActionProps {
  applicant: Applicant,
  jobApplication: JobApplication,
  onSuccess?: (id: number) => void,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

export const HIRE_APPLICANT_SUCCESS_MESSAGE = 'Il candidato è stato assunto con successo';
export const HIRE_APPLICANT_ERROR_MESSAGE = 'Non è stato possibile assumere il candidato';

const HireApplicantAction: React.FC<HireApplicantActionProps> = (props) => {
  const {
    applicant, jobApplication, onSuccess, children,
  } = props;

  const modal = useModal();
  const navigate = useNavigate();
  const { addSuccess, addError } = useNotifications();
  const [isLoading, setIsLoading] = React.useState(false);

  const openHireModal = React.useCallback(() => {
    modal.open();
  }, [modal]);

  const onSubmit = React.useCallback(async (formValues: HireApplicantFormModel) => {
    try {
      setIsLoading(true);

      const hiringApplicant = applicantToHire(formValues, jobApplication);

      const hiredApplicant = await hireApplicant(hiringApplicant);

      setIsLoading(false);
      addSuccess(HIRE_APPLICANT_SUCCESS_MESSAGE);
      modal.close();
      navigate(`/admin/agents/${hiredApplicant.id}`);

      if (onSuccess) {
        onSuccess(hiredApplicant.id);
      }
    } catch (error) {
      setIsLoading(false);
      addError(HIRE_APPLICANT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, jobApplication, modal, navigate, onSuccess]);

  return (
    <>
      {children
        ? children({ openModal: openHireModal, isLoading })
        : (
          <ActionIcon
            aria-label="Assumi il candidato"
            label="Assumi"
            onClick={openHireModal}
            loading={isLoading}
            icon={{ path: ICON_ACCOUNT_MULTIPLE_CHECK_OUTLINE }}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Assumi candidato"
        >
          <HireApplicantForm
            initialValues={getInitialValuesFromApplicant(applicant)}
            onSubmit={onSubmit}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default HireApplicantAction;
