import {
  BreakpointQueryName, SpacingName, ThemedFunctor, getSpacing,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const wizardNavigation: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.paddingTop(theme, SpacingName.SMALLER)}
  ${getSpacing.marginBottom(theme, SpacingName.SMALLER)}
  border-top: 1px solid ${theme.palettes.neutrals.light};
  display: flex;
  justify-content: space-between;
  gap: ${theme.remSize[100]};

  > *:first-child{
    flex-grow: 1;
  }

  > *:last-child{
    flex-shrink: 0;
  }

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    align-items: center;
    height: 3.125rem;
  }
`;

export const wizardStepFooter: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALL)}
  ${getSpacing.paddingTop(theme, SpacingName.SMALL)}
  border-top: 1px solid ${theme.palettes.neutrals.light};
  display: flex;
  justify-content: space-between;
`;

export const erroredStepListItem: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLEST)}
  ${getSpacing.padding(theme, SpacingName.SMALLER)}
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
  background-color: ${theme.palettes.neutrals.lighter};

  &:hover {
    background-color: ${theme.palettes.status.alertLighter};
    border-color: ${theme.palettes.status.alertLight};
    color: ${theme.palettes.status.alert};
  }
`;
