import { IntentSource } from '../domain/types';

export const intentSourceLabels: Record<IntentSource, string> = {
  [IntentSource.WEBSITE]: 'Dove.it',
  [IntentSource.CALLCENTER]: 'Chiamata in entrata',
  [IntentSource.AGENT]: 'Agente',
  [IntentSource.SALES_REFERRAL]: 'Referral',
  [IntentSource.IMMOBILIARE]: 'Immobiliare.it',
  [IntentSource.CASA]: 'Casa.it',
  [IntentSource.IDEALISTA]: 'Idealista',
  [IntentSource.WIKICASA]: 'Wikicasa',
  [IntentSource.SUBITO]: 'Subito.it',
  [IntentSource.BAKECA]: 'Bakeca.it',
  [IntentSource.BROCHURE]: 'Brochure',
  [IntentSource.CARTELLO_VENDESI]: 'Cartello vendesi',
  [IntentSource.LUXURY_ESTATE]: 'Luxury Estate',
  [IntentSource.JAMES_EDITION]: 'JamesEdition',
  [IntentSource.GATE_AWAY]: 'Gate-Away.com',
  [IntentSource.MATCHING]: 'Matching',
  [IntentSource.DIDIMORA]: 'Didimora',
};
