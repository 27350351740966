import {
  Select, Spacing, useNotifications,
} from '@doveit/bricks';
import React, { ChangeEvent } from 'react';
import { ReviewUserType, REVIEW_USER_TYPES } from '../../../domain/types';
import { Property, Review, ReviewRequest } from '../../../providers/api/dtos';
import { createReview } from '../../../providers/api/review/reviewProvider';
import ReviewRequestForm from '../../components/review-request-form/ReviewRequestForm';
import { ReviewRequestFormModel, ReviewType } from '../../components/review-request-form/types';
import { REVIEW_TYPE_SELECT_OPTIONS } from './constants';

export interface SendReviewRequestProps {
  propertyId: NonNullable<Property['id']>,
  reviewsAlreadySent?: Review[],
  initialValues?: {
    buyer?: ReviewRequestFormModel,
    seller?: ReviewRequestFormModel,
  },
  onSuccess?: (review: Review) => void,
}

export const REVIEW_REQUEST_SENT_SUCCESS_MESSAGE = "L'email di richiesta di recensione è stata inviata correttamente";
export const REVIEW_REQUEST_NOT_SENT_ERROR_MESSAGE = 'Non è stato possibile inviare la richiesta di recensione';

export const SENT_REQUEST_LABEL = 'Richiesta inviata';

const SendReviewRequests: React.FC<SendReviewRequestProps> = ({
  propertyId,
  reviewsAlreadySent,
  initialValues,
  onSuccess,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [selectedReviewType, setSelectedReviewType] = React.useState<ReviewType>();
  const [userTypesAlreadyNotified, setUserTypesAlreadyNotified] = React.useState<Set<ReviewUserType>>(new Set());

  React.useEffect(() => {
    const userTypesToNotify = reviewsAlreadySent?.map((review) => review.userType);

    if (userTypesToNotify && userTypesToNotify.length !== 0) {
      setUserTypesAlreadyNotified((prevUserTypesAlreadyNotified) => new Set([...prevUserTypesAlreadyNotified, ...userTypesToNotify]));

      if (userTypesToNotify.length === 1) {
        setSelectedReviewType(REVIEW_USER_TYPES.find((reviewUserType) => reviewUserType !== userTypesToNotify[0]));
      }
    }
  }, [reviewsAlreadySent]);

  const { addSuccess, addError } = useNotifications();

  const onReviewTypeChange = React.useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    const newReviewType = value as ReviewType;

    setSelectedReviewType(newReviewType);
  }, []);

  const sendReview = React.useCallback((userType: ReviewUserType) => async (reviewRequestFormValues: ReviewRequestFormModel) => {
    try {
      setIsSaving(true);

      const { userEmail, userName } = reviewRequestFormValues;

      const reviewRequest: ReviewRequest = {
        propertyId,
        userType,
        userEmail,
        userName,
      };

      const createdReview = await createReview(reviewRequest);

      setIsSaving(false);
      setUserTypesAlreadyNotified((prevUserTypesAlreadyNotified) => new Set([...prevUserTypesAlreadyNotified, userType]));
      addSuccess(REVIEW_REQUEST_SENT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(createdReview);
      }
    } catch (error) {
      setIsSaving(false);
      addError(REVIEW_REQUEST_NOT_SENT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, onSuccess, propertyId]);

  return (
    <>
      <Select
        value={selectedReviewType}
        label="Indica a chi vuoi richiedere la recensione"
        disabled={reviewsAlreadySent && reviewsAlreadySent.length > 0}
        options={REVIEW_TYPE_SELECT_OPTIONS}
        onChange={onReviewTypeChange}
      />
      {selectedReviewType && ['BOTH', 'BUYER'].includes(selectedReviewType) && (
        <Spacing margin={[200, 0, 0]}>
          <ReviewRequestForm
            reviewType="BUYER"
            initialValues={initialValues?.buyer}
            loading={isSaving}
            disabled={userTypesAlreadyNotified.has('BUYER')}
            submitLabel={userTypesAlreadyNotified.has('BUYER') ? SENT_REQUEST_LABEL : undefined}
            onSubmit={sendReview('BUYER')}
          />
        </Spacing>
      )}
      {selectedReviewType && ['BOTH', 'SELLER'].includes(selectedReviewType) && (
        <Spacing margin={[200, 0, 0]}>
          <ReviewRequestForm
            reviewType="SELLER"
            initialValues={initialValues?.seller}
            loading={isSaving}
            disabled={userTypesAlreadyNotified.has('SELLER')}
            submitLabel={userTypesAlreadyNotified.has('SELLER') ? SENT_REQUEST_LABEL : undefined}
            onSubmit={sendReview('SELLER')}
          />
        </Spacing>
      )}
    </>
  );
};

export default SendReviewRequests;
