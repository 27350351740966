import React, { FunctionComponent } from 'react';
import {
  Badge, BadgeProps, ICON_CHECK, ICON_CLOSE, ICON_PROGRESS_CHECK,
} from '@doveit/bricks';
import { AppointmentStatus } from '../../../domain/types';
import { appointmentStatusLabels } from '../../../labels';

export interface AppointmentStatusBadgeProps {
  status: AppointmentStatus,
  emphasis?: BadgeProps['emphasis'],
  size?: BadgeProps['size'],
}

const badgeColor: Record<AppointmentStatus, BadgeProps['color']> = {
  [AppointmentStatus.TODO]: 'info',
  [AppointmentStatus.DONE]: 'success',
  [AppointmentStatus.CANCELLED]: 'critical',
};

const badgeIcon: Record<AppointmentStatus, string> = {
  [AppointmentStatus.TODO]: ICON_PROGRESS_CHECK,
  [AppointmentStatus.DONE]: ICON_CHECK,
  [AppointmentStatus.CANCELLED]: ICON_CLOSE,
};

const AppointmentStatusBadge: FunctionComponent<AppointmentStatusBadgeProps> = ({
  status,
  emphasis = 'low',
  size,
}) => (
  <Badge
    label={appointmentStatusLabels[status]}
    color={badgeColor[status]}
    emphasis={emphasis}
    size={size}
    icon={badgeIcon[status]}
  />
);

export default AppointmentStatusBadge;
