import { PublishedOn } from '../../../providers/api/dtos/publishedOn';
import { PublishedOnModel } from '../models/publishedOnModel';

export function toPublishedOnModel(publishedOn: PublishedOn[]): PublishedOnModel {
  return publishedOn.reduce<PublishedOnModel>((model, item) => {
    model[item.aggregator] = {
      id: item.id,
      checked: true,
    };

    return model;
  }, {});
}
