/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Action, ActionProps, Text } from '@doveit/bricks';
import * as styles from './PopoverPreview.style';

export interface EntityPopoverPreviewProps extends React.AriaAttributes, React.PropsWithChildren {
  title: string,
  details?: string,
  avatar: React.ReactNode,
  action?: Pick<ActionProps, 'label' | 'onClick' | 'aria-label'>,
}

const PopoverPreview: React.FC<EntityPopoverPreviewProps> = ({
  title,
  details,
  avatar,
  action,
  'aria-label': ariaLabel = 'entity-popover-preview',
  children,
  ...rest
}) => (
  <div
    {...rest}
    aria-label={ariaLabel}
    css={styles.basic}
  >
    <div css={styles.header}>
      <div css={styles.avatar}>
        {avatar}
      </div>
      <div css={styles.info}>
        <Text.H4
          lineHeight="1"
          as="div"
          aria-label={`${ariaLabel} (title)`}
        >
          {title}
        </Text.H4>
        {details && (
          <Text.Body
            lineHeight="1"
            as="div"
          >
            {details}
          </Text.Body>
        )}
      </div>
    </div>
    {(children || action) && (
      <div css={styles.wrapper}>
        {children && (
          <div css={styles.content}>
            {children}
          </div>
        )}
        {action && (
          <Action
            label={action.label}
            aria-label={action['aria-label']}
            color="primary"
            size="S"
            onClick={action.onClick}
            expanded
          />
        )}
      </div>
    )}
  </div>
);

export default PopoverPreview;
