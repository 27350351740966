import useSWR, { SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Document, Rumor } from '../../../providers/api/dtos';
import { API_RUMORS_PATH } from '../../../providers/api/api';
import { getRumorDocuments } from '../../../providers/api/rumor/rumorProvider';

export const RUMOR_DOCUMENTS_RID = `${API_RUMORS_PATH}/:id/documents`;

export function useRumorDocuments(rumorId?: Rumor['id']): SWRResponse<Document[], AxiosError> {
  const key = rumorId ? [RUMOR_DOCUMENTS_RID, rumorId] : null;

  return useSWR(key, () => getRumorDocuments(rumorId!));
}
