import React, { FunctionComponent } from 'react';
import { Badge, BadgeProps } from '@doveit/bricks';
import { JobApplicationStatus } from '../../../domain/types/jobApplicationStatus';

const badgeColor: Record<JobApplicationStatus, BadgeProps['color']> = {
  IN_PROGRESS: 'info',
  ASSIGNED: 'success',
  OFFER: 'success',
  HIRED: 'success',
  KO_DUPLICATE: 'critical',
  KO_NOT_ELIGIBLE: 'critical',
  KO_NOT_INTERESTED: 'critical',
  KO_OFFER_REFUSED: 'critical',
  KO_GEO: 'critical',
};

const badgeText: Record<JobApplicationStatus, string> = {
  IN_PROGRESS: 'Aperta',
  ASSIGNED: 'Assegnata',
  OFFER: 'Proposta',
  HIRED: 'Candidato assunto',
  KO_DUPLICATE: 'Archiviata',
  KO_NOT_ELIGIBLE: 'Archiviata',
  KO_NOT_INTERESTED: 'Archiviata',
  KO_OFFER_REFUSED: 'Archiviata',
  KO_GEO: 'Archiviata',
};

export interface JobApplicationStatusBadgeProps {
  status: JobApplicationStatus,
  size?: BadgeProps['size'],
}

const JobApplicationStatusBadge: FunctionComponent<JobApplicationStatusBadgeProps> = ({
  status,
  size,
}) => (
  <Badge
    color={badgeColor[status]}
    label={badgeText[status]}
    size={size}
  />
);

export default JobApplicationStatusBadge;
