import React from 'react';
import { Spacing, DetailItemList } from '@doveit/bricks';
import { Contact } from '../../../providers/api/dtos';
import UserActions, { UserActionsProps } from '../../../components/user-actions/UserActions';

export interface ContactInfoProps extends React.AriaAttributes {
  contact: Contact,
  hideActions?: boolean,
  actions?: Omit<UserActionsProps, 'user'>
}

const ContactInfo: React.FC<ContactInfoProps> = ({
  contact,
  actions,
  hideActions = false,
  ...rest
}) => (
  <div {...rest}>
    <DetailItemList>
      <DetailItemList.Item label="Nome">
        {contact.name}
      </DetailItemList.Item>
      <DetailItemList.Item
        label="Telefono"
        textToCopy={contact.phoneNumber}
      >
        {contact.phoneNumber}
      </DetailItemList.Item>
      {contact.email && (
        <DetailItemList.Item
          label="Email"
          textToCopy={contact.email}
        >
          {contact.email}
        </DetailItemList.Item>
      )}
      {contact.fiscalCode && (
        <DetailItemList.Item
          label="Codice fiscale"
        >
          {contact.fiscalCode}
        </DetailItemList.Item>
      )}
      {contact.residence?.normalizedAddress && (
        <DetailItemList.Item
          label="Indirizzo"
        >
          {contact.residence.normalizedAddress}
        </DetailItemList.Item>
      )}
    </DetailItemList>
    {!hideActions && (
      <Spacing margin={[200, 0, 0]}>
        <UserActions
          {...actions}
          user={contact}
        />
      </Spacing>
    )}
  </div>
);

export default ContactInfo;
