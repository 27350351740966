/* eslint-disable react/no-unknown-property */
import React from 'react';
import * as styles from './Dot.style';

export interface DotProps extends React.AriaAttributes {
  size?: 'S' | 'M',
}

const Dot: React.FC<DotProps> = ({
  size = 'M',
  ...rest
}) => (
  <div
    {...rest}
    css={styles.dot}
    $size={size}
  />
);

export default Dot;
