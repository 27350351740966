/* eslint-disable react/no-unknown-property */
import {
  ActionIcon, ActionIconProps, Form, ICON_CLOSE, ICON_MAGNIFY, ICON_MAGNIFY_PLUS, Popover, PopoverProps,
} from '@doveit/bricks';
import React from 'react';
import * as styles from './SearchByQueryAction.style';

export interface SearchByQueryActionFormModel {
  query: string,
}

interface SearchByQueryActionBaseProps extends React.AriaAttributes {
  size?: 'S' | 'M',
  placeholder?: string,
  initialQuery?: string,
  onSearch?: (query: string) => void,
}

export type SearchByQueryActionProps = SearchByQueryActionBaseProps & ({
  variant: 'input',
  color?: undefined,
  emphasis?: undefined,
  children?: undefined,
} | {
  variant: 'action',
  color?: ActionIconProps['color'],
  emphasis?: ActionIconProps['emphasis'],
  children?: PopoverProps['trigger'],
});

interface FormProps extends React.AriaAttributes {
  initialValues: SearchByQueryActionFormModel,
  size?: 'S' | 'M',
  placeholder?: string,
  onSubmit: (values: SearchByQueryActionFormModel) => void,
}

const SearchForm: React.FC<FormProps> = ({
  initialValues,
  placeholder,
  size,
  onSubmit,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    css={styles.searchByQueryForm}
    enableReinitialize
  >
    {({ values, setFieldValue, submitForm }) => {
      const handleClear = () => {
        setFieldValue('query', '');
        submitForm();
      };

      return (
        <Form.Item>
          <Form.Input
            aria-label="Campo per specificare il valore di ricerca"
            name="query"
            size={size}
            placeholder={placeholder}
            icon={values.query ? ICON_CLOSE : ICON_MAGNIFY}
            onClickIcon={values.query ? handleClear : undefined}
            onBlur={submitForm}
          />
        </Form.Item>
      );
    }}
  </Form>
);

const SearchByQueryAction: React.FC<SearchByQueryActionProps> = ({
  size = 'M',
  color = 'neutral',
  emphasis = 'low',
  placeholder = 'Cerca...',
  initialQuery = '',
  variant,
  onSearch,
  children,
  ...rest
}) => {
  if (variant === 'input') {
    return (
      <SearchForm
        initialValues={{ query: initialQuery }}
        onSubmit={({ query }) => onSearch?.(query)}
        placeholder={placeholder}
        size={size}
        {...rest}
      />
    );
  }

  return (
    <Popover
      aria-label="Cerca"
      placement="bottom-end"
      trigger={children ?? (
        <ActionIcon
          icon={{ path: initialQuery ? ICON_MAGNIFY_PLUS : ICON_MAGNIFY }}
          size={size}
          color={initialQuery ? 'primary' : color}
          emphasis={emphasis}
          label="Cerca"
        />
      )}
      {...rest}
    >
      <div css={styles.searchByQueryPopoverWrapper}>
        <SearchForm
          initialValues={{ query: initialQuery }}
          onSubmit={({ query }) => onSearch?.(query)}
          placeholder={placeholder}
          size={size}
        />
      </div>
    </Popover>
  );
};

export default SearchByQueryAction;
