import React from 'react';
import {
  Action, FormHandlers, Message, Portal, Spacing, useModal,
  useNotifications,
} from '@doveit/bricks';
import { Prospect } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import ProspectApprovalRequestForm, { ProspectApprovalRequestFormModel } from '../../components/prospect-approval-request-form-v2/ProspectApprovalRequestForm';
import { useComputedProspectApprovalStatus } from '../../hooks/use-computed-prospect-approval-status/useComputedProspectApprovalStatus';
import { ProspectApprovalStatus } from '../../../domain/types';
import { updateProspect } from '../../../providers/api/prospect/prospectProvider';

interface ChildFnProps {
  requestApproval: VoidFunction,
  label: string,
  ariaLabel: string,
  isSaving: boolean,
}

export interface RequestProspectApprovalActionProps extends React.AriaAttributes {
  prospect: Prospect,
  children?: (props: ChildFnProps) => React.ReactNode,
  onSuccess?: (prospect: Prospect) => void,
}

export const REQUEST_PROSPECT_APPROVAL_SUCCESS_MESSAGE = 'La richiesta di approvazione è stata inviata con successo';
export const REQUEST_PROSPECT_APPROVAL_ERROR_MESSAGE = 'Non è stato possibile inviare la richiesta di approvazione';

const RequestProspectApprovalAction: React.FC<RequestProspectApprovalActionProps> = ({
  prospect,
  children,
  onSuccess,
  ...rest
}) => {
  const modal = useModal();
  const formRef = React.useRef<FormHandlers>(null) as React.MutableRefObject<FormHandlers>;
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = React.useState(false);
  const { data: { canBeUpdatedTo } } = useComputedProspectApprovalStatus(prospect);

  const shouldBeUpdatedTo = ProspectApprovalStatus.WAITING_FOR_SUPERVISOR;

  const canBeRequested = React.useMemo(
    () => canBeUpdatedTo(shouldBeUpdatedTo),
    [canBeUpdatedTo, shouldBeUpdatedTo],
  );

  const initialValues = React.useMemo(() => ({
    agentEvaluation: prospect.agentEvaluation?.toString() || '',
  }), [prospect.agentEvaluation]);

  const handleSubmit = React.useCallback(async (values: ProspectApprovalRequestFormModel) => {
    try {
      setIsSaving(true);

      const updatedProspect = await updateProspect(prospect.id!, {
        ...prospect,
        approval: {
          status: shouldBeUpdatedTo,
          firstRequest: new Date().toISOString(),
        },
        agentEvaluation: parseInt(values.agentEvaluation, 10),
      });

      setIsSaving(false);
      modal.close();
      addSuccess(REQUEST_PROSPECT_APPROVAL_SUCCESS_MESSAGE);

      onSuccess?.(updatedProspect);
    } catch (err) {
      setIsSaving(false);
      addError(REQUEST_PROSPECT_APPROVAL_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, modal, onSuccess, prospect, shouldBeUpdatedTo]);

  const submitForm = React.useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  if (!canBeRequested) return null;

  return (
    <>
      {children ? children({
        requestApproval: modal.open,
        label: 'Richiedi approvazione',
        ariaLabel: "Azione per richiedere l'approvazione della valutazione",
        isSaving,
      }) : (
        <Action
          label="Richiedi approvazione"
          aria-label="Azione per richiedere l'approvazione della valutazione"
          size="S"
          loading={isSaving}
          onClick={() => modal.open()}
          {...rest}
        />
      )}
      <Portal>
        <SimpleModal
          {...modal}
          title="Richiedi approvazione"
          aria-label="Modale per richiedere l'approvazione della valutazione"
          footer={(
            <Action
              label="Richiedi approvazione"
              color="primary"
              emphasis="high"
              aria-label="Azione per confermare la richiesta di approvazione della valutazione"
              onClick={submitForm}
            />
          )}
        >
          <Message
            type="neutral"
            message="Richiedi l'approvazione da parte del tuo supervisore per poter convertire la valutazione in mandato."
          />

          <Spacing margin={[300, 0, 0]} />

          <ProspectApprovalRequestForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            loading={isSaving}
            innerRef={formRef}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default RequestProspectApprovalAction;
