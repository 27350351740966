import {
  FontWeight,
  getTypography,
  ThemedFunctor,
  TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({ theme, $isRemoving }) => css`
  background: ${theme.color.background.neutral.default.low};
  aspect-ratio: 1;
  position: relative;
  user-select: none;

  ${$isRemoving && `
    opacity: .5;
  `}
`;

export const placeholder: ThemedFunctor = ({ theme }) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.color.background.neutral.hover.high}; // @TODO: review color palettes to add neutral.default.medium
`;

export const content: ThemedFunctor = ({ theme, $hasPicture }) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.1s ease-in-out;
  z-index: 1;

  ${$hasPicture && `
    background: ${theme.color.background.neutral.default.low};
  `}
`;

export const frame: ThemedFunctor = ({
  theme,
  $operation,
  $interactive,
  $active,
}) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.2s ease-in-out;
  z-index: 2;

  ${$operation === 'drag' && `
    cursor: move;
  `}

  ${$interactive && `
    border: 2px solid ${theme.color.background.neutral.default.high};
    cursor: pointer;

    &:hover {
      border-color: ${theme.color.background.primary.hover.high};

      .index {
        background-color: ${theme.color.background.primary.hover.high};
        color: ${theme.color.foreground.primary.default.low};
      }

      .icon {
        visibility: visible;
        opacity: 1;
      }
    }
  `}

   ${$active && `
    border-color: ${theme.color.background.primary.hover.high};

    .index {
      background-color: ${theme.color.background.primary.hover.high};
      color: ${theme.color.foreground.primary.default.low};
    }
  `}
`;

export const errorIcon: ThemedFunctor = ({ theme }) => css`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${theme.remSize[300]};
  height: ${theme.remSize[300]};
  margin: 0 0 ${theme.remSize[25]} ${theme.remSize[25]};
  border-radius: ${theme.remSize[150]};
  background-color: ${theme.color.background.critical.default.high};
  color: ${theme.color.foreground.critical.default.low};
`;

export const icon: ThemedFunctor = ({ theme }) => css`
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${theme.remSize[300]};
  height: ${theme.remSize[300]};
  border-radius: ${theme.remSize[150]};
  background-color: ${theme.color.background.primary.default.high};
  color: ${theme.color.foreground.primary.default.low};
  transition: opacity 0.2s ease-in-out;
`;

export const index: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.CAPTION, { fontWeight: FontWeight.MEDIUM })}
  background: ${theme.color.background.neutral.default.high};
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(${theme.remSize[250]} + 2px);
  height: calc(${theme.remSize[250]} + 2px);
  border-radius: ${theme.remSize[50]} 0 0 0;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`;
