import React, { } from 'react';
import { Message, Spacing } from '@doveit/bricks';
import { OfferWizardStepProps } from '../types';
import { toOfferWizardData, toOfferWizardAntiMoneyLaunderingModel } from './mappers';
import OfferWizardAntiMoneyLaunderingForm, { OfferWizardAntiMoneyLaunderingFormModel } from './OfferWizardAntiMoneyLaunderingForm';

export const ANTI_LAUNDERING_NOT_NEEDED_MESSAGE = 'In caso di più compratori o di persona giuridica, compila manualmente il modulo di antiriciclaggio e richiedi la firma digitale tramite il team content.';

const OfferWizardAntiMoneyLaundering: React.FC<OfferWizardStepProps> = ({
  initialData,
  formRef,
  onSubmit,
}) => {
  const antiMoneyLaunderingIsNotNeeded = React.useMemo(() => {
    const multipleBuyers = (initialData.buyerContacts?.length ?? 0) > 1;
    const isLegalBuyers = initialData.buyerContacts?.some((buyer) => buyer.legal !== undefined) ?? false;
    return multipleBuyers || isLegalBuyers;
  }, [initialData.buyerContacts]);

  const onInternalSubmit = React.useCallback(async (formValues: OfferWizardAntiMoneyLaunderingFormModel) => {
    const updatedOfferWizardData = toOfferWizardData(
      initialData,
      formValues,
    );
    const isFormDirty = (formRef.current as any)?.dirty || false;

    onSubmit(updatedOfferWizardData, isFormDirty);
  }, [formRef, initialData, onSubmit]);

  return (
    <>
      {antiMoneyLaunderingIsNotNeeded && (
        <Spacing margin={[0, 0, 200, 0]}>
          <Message
            type="info"
            message={ANTI_LAUNDERING_NOT_NEEDED_MESSAGE}
          />
        </Spacing>
      )}
      <OfferWizardAntiMoneyLaunderingForm
        initialValues={toOfferWizardAntiMoneyLaunderingModel(initialData, antiMoneyLaunderingIsNotNeeded)}
        onSubmit={onInternalSubmit}
        formRef={formRef}
        disabled={antiMoneyLaunderingIsNotNeeded}
      />
    </>
  );
};

export default OfferWizardAntiMoneyLaundering;
