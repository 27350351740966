import { IntentStatus } from '../../../../domain/types';
import { IntentsProviderFilters } from '../../../../providers/api/intent/intentProvider';

export const CONTACT_INTENTS_LOAD_ERROR = 'Non è stato possibile caricare i dati relativi agli interessi del contatto.';

export const EXCLUDED_INTENT_STATUSES = [IntentStatus.KO_DUPLICATE];

export const CONTACT_ACTIVITIES_SUMMARY_INTENTS_FILTERS: Pick<IntentsProviderFilters, 'excludeStatus'> = {
  excludeStatus: EXCLUDED_INTENT_STATUSES,
};

// The lower the number, the higher the priority
export const INTENT_STATUS_SORT_ORDER: Record<IntentStatus, number> = {
  [IntentStatus.IN_PROGRESS]: 0,
  [IntentStatus.BOUGHT]: 1,
  [IntentStatus.KO_AREA]: 2,
  [IntentStatus.KO_BOUGHT_OTHER]: 2,
  [IntentStatus.KO_INFO]: 2,
  [IntentStatus.KO_MORTGAGE]: 2,
  [IntentStatus.KO_NOT_INTERESTED]: 2,
  [IntentStatus.KO_OTHER_CONSTRAINTS]: 2,
  [IntentStatus.KO_OVERPRICED]: 2,
  [IntentStatus.KO_PROPERTY]: 2,
  [IntentStatus.KO_PROPERTY_NOT_AVAILABLE]: 2,
  [IntentStatus.KO_REFUSED]: 2,
  [IntentStatus.KO_DUPLICATE]: Number.POSITIVE_INFINITY,
};
