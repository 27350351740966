import React, {
  FunctionComponent, useState, useCallback, ReactChild,
} from 'react';
import {
  ActionIcon, ICON_ACCOUNT_CASH_OUTLNE, Message, Portal, useModal, useNotifications,
} from '@doveit/bricks';
import { Offer, OfferDeed } from '../../../providers/api/dtos/offer';
import { offerStatusLabels } from '../../../labels';
import { OfferDeedStatus, OfferStatus } from '../../../domain/types';
import { updateOffer } from '../../../providers/api/offer/offerProvider';
import OfferDeedForm, { OfferDeedFormModel } from '../../components/offer-deed-form/OfferDeedForm';
import SimpleModal from '../../../components/simple-modal/SimpleModal';

export interface ConfirmOfferActionProps {
  offer: Offer,
  onSuccess?: (offer: Offer) => void,
  children?: ({ openConfirmOfferModal }: { openConfirmOfferModal: VoidFunction }) => ReactChild,
}

export const CONFIRM_OFFER_ERROR_MESSAGE = 'Non è stato possibile confermare l\'offerta';
export const CONFIRM_OFFER_SUCCESS_MESSAGE = 'La proposta è stata confermata e l\'immobile è ora in stato <strong>venduto</strong>.';
export const INTENT_STATUS_CHANGE_MESSAGE = 'Lo stato dell\'interesse è stato aggiornato correttamente';
export const CANCEL_APPOINTMENTS_REMINDERS_WARNING_MESSAGE = 'Confermando la proposta verranno automaticamente annullati gli appuntamenti programmati e i promemoria futuri, qualora presenti.';

const ConfirmOfferAction: FunctionComponent<ConfirmOfferActionProps> = ({
  offer,
  onSuccess,
  children,
}) => {
  const [isSaving, setSaving] = useState(false);
  const confirmOfferModal = useModal();
  const { addSuccess, addError } = useNotifications();

  const openConfirmModal = useCallback(
    () => confirmOfferModal.open(),
    [confirmOfferModal],
  );

  const onConfirmOffer = useCallback(async ({ date, status }: OfferDeedFormModel) => {
    setSaving(true);

    const deed: OfferDeed = {
      date,
      status: status as OfferDeedStatus,
    };

    try {
      const updatedOffer = await updateOffer({
        ...offer,
        deed,
        status: OfferStatus.CONFIRMED,
      });

      setSaving(false);
      addSuccess(CONFIRM_OFFER_SUCCESS_MESSAGE);
      addSuccess(INTENT_STATUS_CHANGE_MESSAGE);

      confirmOfferModal.close();

      if (onSuccess) {
        onSuccess(updatedOffer);
      }
    } catch (error) {
      setSaving(false);
      addError(CONFIRM_OFFER_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, confirmOfferModal, offer, onSuccess]);

  if (offer.status !== OfferStatus.ACCEPTED) {
    return null;
  }

  return (
    <>
      {children
        ? children({ openConfirmOfferModal: openConfirmModal })
        : (
          <ActionIcon
            icon={{ path: ICON_ACCOUNT_CASH_OUTLNE }}
            label="Acquistato"
            size="S"
            color="success"
            onClick={openConfirmModal}
          />
        )}
      <Portal>
        <SimpleModal
          title={`Vuoi cambiare lo stato della proposta in "${offerStatusLabels[OfferStatus.CONFIRMED]}"?`}
          {...confirmOfferModal}
        >
          <Message
            type="warning"
            message={CANCEL_APPOINTMENTS_REMINDERS_WARNING_MESSAGE}
          />
          <OfferDeedForm
            loading={isSaving}
            submitLabel="Cambia"
            onSubmit={onConfirmOffer}
          />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ConfirmOfferAction;
