/* eslint-disable react/no-unknown-property */
import { Spinner, SpinnerSize, SpinnerProps } from '@doveit/bricks';
import React, { FunctionComponent } from 'react';

import * as styles from './LoadingBlock.style';

const LoadingBlock: FunctionComponent<SpinnerProps> = ({
  ...rest
}) => (
  <div
    css={styles.base}
    role="status" // @TODO: add role="status" to Spinner component in @doveit/bricks
    aria-label="Caricamento in corso" // @TODO: expose aria-label from Spinner component in @doveit/bricks
  >
    <Spinner
      size={SpinnerSize.BIG}
      color="neutrals.light"
      {...rest}
    />
  </div>
);

export default LoadingBlock;
