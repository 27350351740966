import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Intent } from '../../../providers/api/dtos/intent';
import { getIntent } from '../../../providers/api/intent/intentProvider';

export const INTENT_BY_ID_RID = '/api/v2/intents/:id';

function getIntentRID(id: string) {
  return [INTENT_BY_ID_RID, id];
}

export default function useIntent(
  intentId?: string,
  options?: SWRConfiguration<Intent, AxiosError>,
): SWRResponse<Intent, AxiosError> {
  return useSWR(intentId ? getIntentRID(intentId) : null, () => getIntent(intentId!), options);
}
