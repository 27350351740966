import { Badge, BadgeProps } from '@doveit/bricks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { computeDestinationUrl, goToDetailPage } from '../../utils/navigate/utils';
import { ReferenceType } from '../../domain/types';

export interface EntityReferenceBadgeProps extends Omit<BadgeProps, 'label'> {
  id: string | number,
  entity: ReferenceType,
  label?: string | number,
  isNewTab?: boolean,
}

const EntityReferenceBadge: React.FC<EntityReferenceBadgeProps> = (props) => {
  const {
    id,
    entity,
    label = id,
    isNewTab = false,
    ...rest
  } = props;

  const navigate = useNavigate();

  const handleClick = React.useCallback(() => {
    if (isNewTab) goToDetailPage(id, entity);
    else navigate(computeDestinationUrl(id, entity));
  }, [entity, id, isNewTab, navigate]);

  return (
    <Badge
      label={label}
      color="primary"
      size="XS"
      onClick={handleClick}
      {...rest}
    />
  );
};

export default EntityReferenceBadge;
