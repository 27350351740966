import React, { FunctionComponent } from 'react';
import { Badge, BadgeProps } from '@doveit/bricks';
import { RumorStatus } from '../../../domain/types/rumorStatus';
import { rumorStatusColors, rumorStatusIcons, rumorStatusLabels } from '../../constants';

export interface RumorStatusBadgeProps {
  status: RumorStatus,
  emphasis?: BadgeProps['emphasis'],
  size?: BadgeProps['size'],
  showIcon?: boolean,
}

const RumorStatusBadge: FunctionComponent<RumorStatusBadgeProps> = ({
  status,
  emphasis = 'low',
  size,
  showIcon = false,
}) => (
  <Badge
    aria-label="Badge stato notizia"
    color={rumorStatusColors[status]}
    label={rumorStatusLabels[status]}
    icon={showIcon ? rumorStatusIcons[status] : undefined}
    emphasis={emphasis}
    size={size}
  />
);

export default RumorStatusBadge;
