import React from 'react';
import { FormHandlers } from '@doveit/bricks';
import CreateIntent, { CreateIntentProps } from '../create-intent/CreateIntent';
import { Appointment, Intent } from '../../../providers/api/dtos';
import CreateIntentAppointment from '../create-intent-appointment/CreateIntentAppointment';

export interface CreateIntentWithAppointmentProps extends Omit<CreateIntentProps, 'onSuccess' | 'formRef'> {
  createIntentFormRef?: React.MutableRefObject<FormHandlers>,
  onIntentCreated?: (intent: Intent) => void,
  onAppointmentCreated?: (intent: Intent, appointment: Appointment) => void,
  onAppointmentAborted?: (intent: Intent) => void,
}

const CreateIntentWithAppointment: React.FC<CreateIntentWithAppointmentProps> = ({
  propertyId,
  contactId,
  source,
  createIntentFormRef,
  onIntentCreated,
  onAppointmentCreated,
  onAppointmentAborted,
}) => {
  const [createdIntent, setCreatedIntent] = React.useState<Intent | undefined>();

  const onInternalIntentCreated = React.useCallback((intent: Intent) => {
    setCreatedIntent(intent);

    if (onIntentCreated) {
      onIntentCreated(intent);
    }
  }, [onIntentCreated]);

  const onInternalAppointmentCreated = React.useCallback((appointment: Appointment) => {
    if (onAppointmentCreated && createdIntent) {
      onAppointmentCreated(createdIntent, appointment);
    }
  }, [createdIntent, onAppointmentCreated]);

  const onInternalAppointmentCreationAbort = React.useCallback(() => {
    if (onAppointmentAborted && createdIntent) {
      onAppointmentAborted(createdIntent);
    }
  }, [createdIntent, onAppointmentAborted]);

  return (
    <div>
      {!createdIntent && (
        <CreateIntent
          propertyId={propertyId}
          contactId={contactId}
          source={source}
          onSuccess={onInternalIntentCreated}
          formRef={createIntentFormRef}
        />
      )}
      {createdIntent && (
        <CreateIntentAppointment
          intent={createdIntent}
          onSuccess={onInternalAppointmentCreated}
          onAbort={onInternalAppointmentCreationAbort}
        />
      )}
    </div>
  );
};
export default CreateIntentWithAppointment;
