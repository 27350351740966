import { ReferenceType } from '../../domain/types';

export const referenceTypeMapping: Record<ReferenceType, string> = {
  CONTACT: 'contacts',
  INTENT: 'intents',
  JOB_APPLICATION: 'job-applications',
  LEAD: 'leads',
  PROPERTY: 'properties',
  PROSPECT: 'prospects',
  RUMOR: 'rumors',
  SEARCH_CRITERIA: 'search-criteria',
};
