import { SellabilityRange } from '../../domain/types';

export function mapToSellabilityRange(propertySize: number) {
  if (propertySize <= 50) {
    return SellabilityRange.UP_TO_50_MQ;
  }
  if (propertySize <= 85) {
    return SellabilityRange.BETWEEN_50_AND_85_MQ;
  }
  if (propertySize <= 115) {
    return SellabilityRange.BETWEEN_85_AND_115_MQ;
  }
  if (propertySize <= 145) {
    return SellabilityRange.BETWEEN_115_AND_145_MQ;
  }

  return SellabilityRange.OVER_145_MQ;
}
