import { View, Image } from '@react-pdf/renderer';
import React, { FunctionComponent } from 'react';

import * as styles from './Location.style';

export interface LocationProps {
  apiKey: string;
  width?: number;
  height?: number;
  latitude: number;
  longitude: number;
}

export const BASE_STATIC_MAP_URL = 'https://maps.googleapis.com/maps/api/staticmap';

export const Location: FunctionComponent<LocationProps> = ({
  apiKey,
  width = 800,
  height = 470,
  latitude,
  longitude,
}) => (
  <View>
    <Image
      style={styles.image}
      src={
        `${BASE_STATIC_MAP_URL}?center=${latitude},${longitude}&zoom=16&size=${width}x${height}&markers=color:magenta%7C${latitude},${longitude}&key=${apiKey}`
      }
    />
  </View>
);
