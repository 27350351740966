export enum IntentSource {
  WEBSITE = 'website',
  CALLCENTER = 'callcenter',
  AGENT = 'agent',
  SALES_REFERRAL = 'sales_referral',
  IMMOBILIARE = 'immobiliare',
  CASA = 'casa',
  IDEALISTA = 'idealista',
  WIKICASA = 'wikicasa',
  SUBITO = 'subito',
  BAKECA = 'bakeca',
  BROCHURE = 'brochure',
  CARTELLO_VENDESI = 'cartello_vendesi',
  LUXURY_ESTATE = 'luxury_estate',
  JAMES_EDITION = 'james_edition',
  GATE_AWAY = 'gate_away',
  MATCHING = 'matching',
  DIDIMORA = 'didimora',
}
