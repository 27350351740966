/* eslint-disable react/no-unknown-property */
import React from 'react';
import { castArray } from 'lodash';
import {
  SimpleTableBody, SimpleTableCell, SimpleTableRow,
} from './composition';
import * as styles from './SimpleTable.style';
import { SimpleTableHeader } from './composition/header/SimpleTableHeader';
import { SimpleTableHeaderCell } from './composition/header-cell/SimpleTableHeaderCell';

export interface SimpleTableProps extends React.PropsWithChildren, React.AriaAttributes {

}

export interface SimpleTableComposition {
  Body: typeof SimpleTableBody,
  Header: typeof SimpleTableHeader,
  Row: typeof SimpleTableRow,
  Cell: typeof SimpleTableCell,
  HeaderCell: typeof SimpleTableHeaderCell
}

const SimpleTable: React.FC<SimpleTableProps> & SimpleTableComposition = ({
  children,
  ...rest
}) => {
  if (children === null || children === undefined) {
    return null;
  }

  const isValidChildren = (castArray(children))
    .every((child) => [SimpleTableBody, SimpleTableHeader].includes((child as any).type));

  if (!isValidChildren) {
    throw new Error('The component children prop just accepts SimpleTable.Body and SimpleTable.Header component');
  }

  return (
    <table
      aria-label="simple-table"
      css={styles.table}
      {...rest}
    >
      {children}
    </table>
  );
};

SimpleTable.Body = SimpleTableBody;
SimpleTable.Header = SimpleTableHeader;
SimpleTable.Row = SimpleTableRow;
SimpleTable.Cell = SimpleTableCell;
SimpleTable.HeaderCell = SimpleTableHeaderCell;

export default SimpleTable;
