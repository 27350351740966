import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const inputWrapper: ThemedFunctor = ({
  generated,
}) => css`
  ${generated && `
    display: flex;

    > *:first-child {
      flex-grow: 1;
    }

    > *:last-child {
      flex-shrink: 0;
      margin-left: .5rem;
    }
  `}
`;
