export const ContentAbout = Object.freeze({
  BALCONY: 'balcony',
  BASEMENT: 'basement',
  BATHROOM: 'bathroom',
  BEDROOM: 'bedroom',
  CELLAR: 'cellar',
  COMMUNALAREAS: 'communalareas',
  CORRIDOR: 'corridor',
  COURTYARD: 'courtyard',
  DETAILS: 'details',
  DINING_ROOM: 'dining_room',
  FACADE: 'facade',
  GARAGE: 'garage',
  GARDEN: 'garden',
  GATEWAY: 'gateway',
  KITCHEN: 'kitchen',
  PENTHOUSE: 'penthouse',
  OFFICE: 'office',
  PATIO: 'patio',
  POOL: 'pool',
  ROOM: 'room',
  STAIRCASE: 'staircase',
  SURROUNDINGS: 'surroundings',
  VIEWS: 'views',
  WALK_IN_WARDROBE: 'walk_in_wardrobe',
  PORCH: 'porch',
  VERANDA: 'veranda',
  LIVING: 'living',
  TAVERN: 'tavern',
  LAND: 'land',
  TERRACE: 'terrace',
  STUDIO_FLAT: 'studio_flat',
});

export type ContentAboutType = typeof ContentAbout[keyof typeof ContentAbout];
