import { ThemedFunctor } from '@doveit/bricks';
import { css } from 'styled-components';

export const documentLabelWrapper: ThemedFunctor = () => css`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  max-width: 20rem;
`;

export const viewDocumentModalHeader: ThemedFunctor = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;  

  > *:first-child {
    flex-grow: 1;
  }
`;
