import {
  Form, FormProps, FormSubmitType, SelectOption,
} from '@doveit/bricks';
import React from 'react';
import { subYears } from 'date-fns';
import { asSelectOptions } from '../../../../../select-options/utils/asSelectOptions';
import { identityDocumentTypeLabels } from '../../../../../labels/identityDocumentTypeLabels';
import { AssignmentWizardData, IdentityDocumentType } from '../../../../../providers/api/dtos';
import { INPUT_PLACEHOLDERS } from '../../../../../constants';
import { formatInputDate } from '../../../../../utils/form';

export interface AssignmentWizardPlanimetryDelegationFormModel {
  planimetryDelegationRequired: boolean,
  contactPosition: string,
  identityDocumentType: string,
  identityDocumentNumber: string,
  identityDocumentIssuedBy: string,
  identityDocumentIssuedAt: string,
}

export interface AssignmentWizardPlanimetryDelegationFormProps {
  initialValues?: Partial<AssignmentWizardPlanimetryDelegationFormModel>,
  loading?: FormProps<AssignmentWizardPlanimetryDelegationFormModel>['loading'],
  disabled?: FormProps<AssignmentWizardPlanimetryDelegationFormModel>['disabled'],
  onSubmit: FormSubmitType<AssignmentWizardPlanimetryDelegationFormModel>,
  formRef: FormProps<AssignmentWizardPlanimetryDelegationFormModel>['innerRef'],
  contacts?: NonNullable<AssignmentWizardData['contacts']>,
}

export const IDENTITY_DOCUMENT_PLACEHOLDER: Record<IdentityDocumentType, string> = {
  IDENTITY_CARD: INPUT_PLACEHOLDERS.CONTACT_IDENTIY_CARD_NUMBER,
  PASSPORT: INPUT_PLACEHOLDERS.CONTACT_PASSPORT_NUMBER,
  DRIVER_LICENSE: INPUT_PLACEHOLDERS.CONTACT_DRIVER_LICENSE_NUMBER,
};

export const IDENTITY_DOCUMENT_ISSUED_BY: Record<IdentityDocumentType, string> = {
  IDENTITY_CARD: INPUT_PLACEHOLDERS.CONTACT_IDENTIY_CARD_ISSUED_BY,
  PASSPORT: INPUT_PLACEHOLDERS.CONTACT_PASSPORT_ISSUED_BY,
  DRIVER_LICENSE: INPUT_PLACEHOLDERS.CONTACT_DRIVER_LICENCE_ISSUED_BY,
};

const DEFAULT_INITIAL_VALUES: AssignmentWizardPlanimetryDelegationFormModel = {
  planimetryDelegationRequired: true,
  contactPosition: '',
  identityDocumentType: '',
  identityDocumentNumber: '',
  identityDocumentIssuedBy: '',
  identityDocumentIssuedAt: '',
};

const AssignmentWizardPlanimetryDelegationForm: React.FC<AssignmentWizardPlanimetryDelegationFormProps> = ({
  initialValues = {},
  formRef,
  contacts = [],
  ...rest
}) => {
  const mergedInitialValues: AssignmentWizardPlanimetryDelegationFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
    planimetryDelegationRequired: contacts.length === 0 ? false : Boolean(initialValues.planimetryDelegationRequired),
  }), [contacts.length, initialValues]);

  const contactsOptions: SelectOption[] = React.useMemo(
    () => [
      {
        label: 'Seleziona',
        value: '',
        disabled: true,
      },
      ...contacts.map((contact, i) => ({
        value: `${i}`,
        label: contact.name || contact.email || contact.phoneNumber || `Venditore ${i}`,
      })),
    ],
    [contacts],
  );

  const minIdentityDocumentIssuedAt = React.useMemo(() => formatInputDate(subYears(new Date(), 10)), []);
  const today = React.useMemo(() => formatInputDate(new Date()), []);

  return (
    <Form
      initialValues={mergedInitialValues}
      innerRef={formRef}
      enableReinitialize
      {...rest}
    >
      {({ values, setFieldValue }) => {
        const onContactChange: React.ChangeEventHandler<HTMLSelectElement> = ({ target }) => {
          const selectedContact = contacts.find((contact) => contact.id === target.value);
          setFieldValue('identityDocumentType', selectedContact?.identityDocument?.type || '', false);
          setFieldValue('identityDocumentNumber', selectedContact?.identityDocument?.number || '', false);
          setFieldValue('identityDocumentIssuedBy', selectedContact?.identityDocument?.issuedBy || '', false);
          setFieldValue('identityDocumentIssuedAt', selectedContact?.identityDocument?.issuedAt || '', false);
        };

        return (
          <>
            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.CheckboxButton
                name="planimetryDelegationRequired"
                text="Aggiungi delega"
                aria-label="Campo per mostrare il form di aggiunta delega"
                disabled={contacts.length === 0}
              />
            </Form.Item>
            {values.planimetryDelegationRequired && contacts.length > 0 && (
              <Form.Fieldset legend="Delegante e documento di riconoscimento">
                <Form.Item size={{ MD: 2 / 3 }}>
                  <Form.Select
                    name="contactPosition"
                    label="Delegante"
                    aria-label="Campo per selezionare il delegante"
                    options={contactsOptions}
                    onChange={onContactChange}
                    disabled={contacts.length === 1}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Select
                    name="identityDocumentType"
                    label="Tipo di documento"
                    aria-label="Campo per selezionare la tipologia di documento di riconoscimento"
                    options={asSelectOptions(identityDocumentTypeLabels)}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Input
                    name="identityDocumentNumber"
                    label="Numero"
                    aria-label="Campo per inserire il numero del documento di riconoscimento"
                    placeholder={values.identityDocumentType ? IDENTITY_DOCUMENT_PLACEHOLDER[values.identityDocumentType as IdentityDocumentType] : 'Es. XXXXXXXXX'}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 2 / 3 }}>
                  <Form.Input
                    name="identityDocumentIssuedBy"
                    label="Rilasciato da"
                    aria-label="Campo per inserire l'ente di emissione del documento di riconoscimento"
                    placeholder={values.identityDocumentType ? IDENTITY_DOCUMENT_ISSUED_BY[values.identityDocumentType as IdentityDocumentType] : ''}
                  />
                </Form.Item>
                <Form.Item size={{ MD: 1 / 3 }}>
                  <Form.Input
                    name="identityDocumentIssuedAt"
                    type="date"
                    label="Data rilascio"
                    aria-label="Campo per selezionare la data di rilascio del documento di riconoscimento"
                    min={minIdentityDocumentIssuedAt}
                    max={today}
                  />
                </Form.Item>
              </Form.Fieldset>
            )}
          </>
        );
      }}
    </Form>
  );
};

export default AssignmentWizardPlanimetryDelegationForm;
