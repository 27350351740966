import { Aggregator } from '../domain/types';

export const aggregatorLabels: Record<Aggregator, string> = {
  DOVE_IT: 'dove.it',
  IMMOBILIARE_IT: 'immobiliare.it',
  IDEALISTA: 'idealista',
  WIKICASA: 'wikicasa',
  CASA_IT: 'casa.it',
  GATE_AWAY: 'gate-away.com',
  JAMES_EDITION: 'jamesedition.com',
};
