import React from 'react';
import { useParams } from 'react-router-dom';
import {
  ActionIconDropdown, Badge, Dropdown, FontWeight, ICON_CHECK, Card, Text, HStack, Stack, useNotifications, DetailItemList, Action, Tooltip,
  Skeleton,
} from '@doveit/bricks';
import { capitalizeWords, formatDate, formatEuro } from '@doveit/hammer';
import { useTheme } from 'styled-components';
import { raise } from '../../../utils';
import useIntent from '../../hooks/use-intent/useIntent';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import RightColumnPageLayout from '../../../layouts/right-column-page-layout/RightColumnPageLayout';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgent, useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import { appointmentAvailabilityLabels, intentSourceLabels } from '../../../labels';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import { AgentPreview } from '../../../agent/components';
import useContact from '../../../contact/hooks/use-contact/useContact';
import ViewPropertySellability from '../../../property/containers/view-property-sellability/ViewPropertySellability';
import sortAvailabilities from '../../utils/sortAvailabilities';
import UpdateAppointmentAvailabilitiesAction from '../../containers/update-appointment-availabilities-action/UpdateAppointmentAvailabilitiesAction';
import { IntentStatus, ReferenceType } from '../../../domain/types';
import UpdateIntentStatus from '../../containers/update-intent-status/UpdateIntentStatus';
import OfferWidget from '../../../offer/containers/offer-widget/OfferWidget';
import CalendarWidget from '../../../components/calendar-widget/CalendarWidget';
import PropertyDetailsWidget from '../../../property/containers/property-details-widget/PropertyDetailsWidget';
import { goToCreatePage } from '../../../utils/navigate/utils';
import ViewPropertyPopoverPreview from '../../../property/containers/view-property-popover-preview/ViewPropertyPopoverPreview';
import PropertyAvatar from '../../../property/components/property-avatar/PropertyAvatar';
import { useIntentNoticesData } from '../../hooks/use-intent-notices-data/useIntentNoticesData';
import NoticeBoard from '../../../notice/components/notice-board/NoticeBoard';
import AdditionalServicesWidget from '../../../contact/containers/additional-services-widget/AdditionalServicesWidget';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import RightColumnPageSkeleton from '../../../components/page-skeleton/RightColumnPageSkeleton';
import NotesSection from '../../../notes/components/notes-section/NotesSection';
import { CalendarEvent, Note } from '../../../providers/api/dtos';
import { updateIntent } from '../../../providers/api/intent/intentProvider';
import usePropertyPreview from '../../../property/hooks/use-property-preview/usePropertyPreview';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';
import SendWhatsappAction from '../../../containers/send-whatsapp-action/SendWhatsappAction';
import { intentMessagesProvider } from '../../../containers/send-whatsapp-action/messages/intentMessagesProvider';
import { buildWhatsappMessageSentNote } from '../../utils/buildWhatsappMessageSentNote';
import useMarkIntentAsSeen from '../../../hooks/use-mark-intent-as-seen/useMarkIntentAsSeen';
import { CalendarEventType } from '../../../hooks/use-calendar-events/useCalendarEvents';
import Dot from '../../../components/dot/Dot';
import { useComputedIntentStatus } from '../../hooks/use-computed-intent-status/useComputedIntentStatus';
import { buildIntentNotice } from '../../../utils/notice/buildIntentNotice';
import ContactWidget from '../../../contact/containers/contact-widget/ContactWidget';

export const LOAD_INTENT_ERROR_MESSAGE = "Non è stato possibile caricare le informazioni dell'interesse";
export const UPDATE_CONTACT_WARNING_MESSAGE = 'Stai modificando il contatto {{contactName}}. Le modifiche saranno visibili in ogni interesse, notizia, valutazione o immobile associato al contatto.';
export const INTENT_UPDATE_ERROR_MESSAGE = "Non è stato possibile modificare le informazioni dell'interesse.";
export const INTENT_UPDATE_SUCCESS_MESSAGE = "L'interesse è stato modificato con successo.";

const ViewIntentPage: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams();
  const intentId = id ?? raise('missing intent id');

  const { addSuccess, addError } = useNotifications();

  const isMobile = useIsDevice('mobile');

  const {
    userIsAdmin, userIsCallCenter, userIsFinance, user, mainUserRole,
  } = useRBAC();

  const {
    data: intent, isLoading: isIntentLoading, error: intentError, mutate: mutateIntent,
  } = useIntent(intentId);
  const {
    data: contact, isLoading: isContactLoading, error: contactError, mutate: contactMutate,
  } = useContact(intent?.contactId);
  const { data: property, isLoading: isPropertyLoading, error: propertyError } = usePropertyPreview(intent?.propertyId);
  const { data: agent, isLoading: isAgentLoading } = useAgentByPropertyId(property?.id);
  const { data: intentNoticesData = [], mutate: mutateIntentNoticesData } = useIntentNoticesData(intent);
  const currentAgent = useCurrentAgent();
  const canActAsPropertyAgent = useCurrentAgentIsSameAgentOrManager(agent?.id);
  const {
    data: computedIntentStatus,
    isLoading: isComputedIntentStatusLoading,
  } = useComputedIntentStatus(intent);
  const {
    markAsSeen, markAs, forceAsSeen, isSaving: isMarkIntentSaving, canMark,
  } = useMarkIntentAsSeen(agent?.id);

  React.useEffect(() => {
    if (intent && !isComputedIntentStatusLoading && !isAgentLoading && canActAsPropertyAgent) {
      markAsSeen(
        intent,
        { onSuccess: () => mutateIntent() },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComputedIntentStatusLoading, computedIntentStatus?.status, markAsSeen]);

  const intentNotices = React.useMemo(() => {
    if (!intent) return [];

    return intentNoticesData.map((noticeData) => buildIntentNotice(noticeData, {
      isMobile,
      user,
      intent,
      disableEditIntentAction: !canActAsPropertyAgent,
      mutateNoticeData: mutateIntentNoticesData,
    }));
  }, [intent, intentNoticesData, isMobile, canActAsPropertyAgent, mutateIntentNoticesData, user]);

  const canActWithHighPrivileges = React.useMemo(() => userIsAdmin || userIsCallCenter || canActAsPropertyAgent,
    [canActAsPropertyAgent, userIsAdmin, userIsCallCenter]);

  const canManageContactConcessions = React.useMemo(
    () => canActWithHighPrivileges || userIsFinance,
    [canActWithHighPrivileges, userIsFinance],
  );

  const canManageContactOptIns = React.useMemo(
    () => userIsAdmin || userIsCallCenter,
    [userIsAdmin, userIsCallCenter],
  );

  const canSendWhatsapp = React.useMemo(
    () => contact?.phoneNumber && ((currentAgent && canActAsPropertyAgent) || userIsAdmin),
    [currentAgent, contact?.phoneNumber, userIsAdmin, canActAsPropertyAgent],
  );

  const availabilitiesHaveBeenDeclared = React.useMemo(
    () => (intent?.appointmentAvailabilities || []).length > 0,
    [intent?.appointmentAvailabilities],
  );

  const sortedAvailabilities = React.useMemo(() => (availabilitiesHaveBeenDeclared
    ? sortAvailabilities(intent!.appointmentAvailabilities).map((availability) => appointmentAvailabilityLabels[availability])
    : Object.values(appointmentAvailabilityLabels)), [availabilitiesHaveBeenDeclared, intent]);

  const showUpdateAvailabilitiesAction = React.useMemo(
    () => userIsAdmin || userIsCallCenter || canActAsPropertyAgent,
    [canActAsPropertyAgent, userIsAdmin, userIsCallCenter],
  );

  const markIntent = React.useCallback(async () => {
    await markAs(
      intent!,
      !intent!.seen,
      { onSuccess: () => mutateIntent(), sendNotification: true },
    );
  }, [intent, markAs, mutateIntent]);

  const canMarkIntent = React.useMemo(
    () => (intent ? canMark() : false),
    [canMark, intent],
  );

  const handleNotesUpdate = React.useCallback(async (notes: Note[]) => {
    if (!intent) return;

    try {
      const updatedIntent = await updateIntent(intentId, {
        ...intent,
        notes,
      });
      await markAsSeen(updatedIntent);

      addSuccess(INTENT_UPDATE_SUCCESS_MESSAGE);
      mutateIntent();
    } catch (err) {
      addError(INTENT_UPDATE_ERROR_MESSAGE);
    }
  }, [intent, intentId, markAsSeen, addSuccess, mutateIntent, addError]);

  const handleAddSearchCriteria = React.useCallback(() => {
    if (intent?.contactId) {
      goToCreatePage(ReferenceType.SEARCH_CRITERIA, {
        contactId: intent.contactId,
        propertyId: intent.propertyId,
      });
    }
  }, [intent?.contactId, intent?.propertyId]);

  const mutateUpdateIntent = React.useCallback(() => {
    mutateIntent();
  }, [mutateIntent]);

  const handleCalendarEventCreate = React.useCallback(async (event: CalendarEvent, type: CalendarEventType) => {
    if (type === 'reminder') {
      await markAsSeen(intent!, { onSuccess: () => mutateIntent() });
    } else {
      await forceAsSeen(intent!, { onSuccess: () => mutateIntent() });
    }
  }, [markAsSeen, intent, mutateIntent, forceAsSeen]);

  const onMessageSent = React.useCallback(async (message: string) => {
    if (!intent) return;

    const updatedIntent = await updateIntent(intent.id!, {
      ...intent,
      notes: [{
        text: buildWhatsappMessageSentNote(message),
        author: user.name,
        role: mainUserRole,
      }, ...(intent.notes || [])],
    });
    markAsSeen(updatedIntent);
    mutateIntent();
  }, [intent, mainUserRole, markAsSeen, mutateIntent, user.name]);

  if (intentError || contactError || propertyError) {
    return <GenericErrorPage title={LOAD_INTENT_ERROR_MESSAGE} />;
  }

  if (isIntentLoading || isContactLoading || isPropertyLoading) {
    return <RightColumnPageSkeleton />;
  }

  return intent! && property! && contact! && (
    <RightColumnPageLayout
      title={(
        <HStack gap={200} wrap="nowrap">
          <ViewPropertyPopoverPreview
            property={property}
            trigger={<PropertyAvatar size="M" />}
            showMatchingAction
          />
          <div>
            <div>
              <Text.H4
                fontWeight={FontWeight.REGULAR}
                as="div"
              >
                {property.geo?.normalizedAddress && capitalizeWords(property.geo?.normalizedAddress)}
              </Text.H4>
            </div>
            <Text.Body as="div">
              {contact.name}
            </Text.Body>
          </div>
        </HStack>
      )}
      actions={(
        <>
          {!intent.seen && (
            <Tooltip content={"L'interesse è segnato come non visto"}>
              <div>
                <Dot size="M" />
              </div>
            </Tooltip>
          )}
          {!isMobile && canMarkIntent && (
            <Action
              aria-label={`Pulsante per segnare l'interesse come ${intent.seen ? 'non visto' : 'visto'}`}
              label={`Segna come ${intent.seen ? 'non visto' : 'visto'}`}
              emphasis="high"
              onClick={markIntent}
              loading={isMarkIntentSaving}
            />
          )}
          {((isMobile && canMarkIntent) || canSendWhatsapp || canActWithHighPrivileges) && (
            <ActionIconDropdown
              label="Azioni sull'interesse"
              aria-label="Azioni sull'interesse"
            >
              {isMobile && canMarkIntent && (
                <Dropdown.Option
                  aria-label={`Pulsante per segnare l'interesse come ${intent.seen ? 'non visto' : 'visto'}`}
                  label={`Segna come ${intent.seen ? 'non visto' : 'visto'}`}
                  onClick={markIntent}
                  loading={isMarkIntentSaving}
                />
              )}
              {canSendWhatsapp && (
                <SendWhatsappAction
                  phoneNumber={contact.phoneNumber!}
                  name={contact.name}
                  fetchMessages={intentMessagesProvider(intent, currentAgent)}
                  onMessageSent={onMessageSent}
                >
                  {({ onClick }) => (
                    <Dropdown.Option
                      label="Invia whatsapp"
                      aria-label="Pulsante per inviare un messaggio Whatsapp"
                      onClick={onClick}
                    />
                  )}
                </SendWhatsappAction>
              )}
              {canActWithHighPrivileges && (
                <Dropdown.Option
                  label="Aggiungi ricerca"
                  aria-label="Aggiungi nuova ricerca"
                  onClick={handleAddSearchCriteria}
                />
              )}
            </ActionIconDropdown>
          )}
        </>
      )}
      topContent={<NoticeBoard notices={intentNotices} />}
      primarySide={(
        <Card>
          <Card.Header isSecondary>
            <UpdateIntentStatus
              intent={intent}
              showAsBadge={!canActWithHighPrivileges}
              onSuccess={mutateUpdateIntent}
              emphasis="high"
              showIcon
            />
          </Card.Header>
          <Card.Content aria-label="Sezione stato interesse">
            <DetailItemList>
              {intent.createdAt && (
                <DetailItemList.Item label="Data creazione">
                  {formatDate(new Date(intent.createdAt))}
                </DetailItemList.Item>
              )}
              <DetailItemList.Item label="Sorgente">
                {intentSourceLabels[intent.source]}
              </DetailItemList.Item>
              <DetailItemList.Item
                label="Agente"
                withoutPadding
                loading={isAgentLoading}
                skeleton={(
                  <Skeleton
                    aria-label="skeleton-agent-loading"
                    width="12rem"
                    height={theme.remHeight.S}
                  />
                )}
              >
                {agent && (
                  <ViewAgentPopoverPreview
                    agent={agent}
                    trigger={(
                      <AgentPreview
                        {...toAgentPreviewProps(agent)}
                        size="S"
                        expanded
                      />
                    )}
                  />
                )}
              </DetailItemList.Item>
            </DetailItemList>
          </Card.Content>

          <Card.Divider />

          <ContactWidget
            contactId={contact.id!}
            aria-label="Sezione contatto"
            asNestedComponent
            canEdit
            canAddAdditionalServices={canActWithHighPrivileges}
            canDeleteAdditionalServices={canActWithHighPrivileges}
            canAddOptIns={canManageContactOptIns}
            canDeleteOptIns={canManageContactOptIns}
            canAddConcessions={canManageContactConcessions}
            canDeleteConcessions={canManageContactConcessions}
            onContactUpdated={contactMutate}
          />

          <Card.Divider />

          <Card.Content aria-label="Sezione prezzo">
            <DetailItemList>
              <DetailItemList.Item>
                <ViewPropertySellability
                  size="XS"
                  propertySize={property.propertySize}
                  geo={(property.geo?.latitude && property.geo.longitude) ? {
                    latitude: property.geo.latitude.toString(),
                    longitude: property.geo.longitude.toString(),
                  } : undefined}
                />
              </DetailItemList.Item>
              <DetailItemList.Item label="Prezzo">
                {property.price ? formatEuro(property.price) : undefined}
              </DetailItemList.Item>
              <DetailItemList.Item label="Prezzo minimo">
                {property.minimumPrice ? formatEuro(property.minimumPrice) : undefined}
              </DetailItemList.Item>
            </DetailItemList>
          </Card.Content>
        </Card>
      )}
      secondarySide={(
        <Stack gap={300}>
          {intent.status === IntentStatus.IN_PROGRESS && (
            <Card aria-label="Disponibilità appuntamento">
              <Card.Header
                title="Disponibilità appuntamento"
                icon={{ path: 'M7 11H9V13H7V11M19 3H18V1H16V3H8V1H6V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M19 5V7H5V5H19M5 19V9H19V19H5M11 15H13V17H11V15M15 15H17V17H15V15M15 11H17V13H15V11Z' }}
                primaryActions={!isMobile ? [
                  showUpdateAvailabilitiesAction && (
                    <UpdateAppointmentAvailabilitiesAction intent={intent} onSuccess={mutateUpdateIntent} />
                  ),
                ] : []}
                secondaryActions={!isMobile ? [] : [
                  showUpdateAvailabilitiesAction && (
                    <UpdateAppointmentAvailabilitiesAction intent={intent} onSuccess={mutateUpdateIntent}>
                      {({ openEditModal, isSaving }) => (
                        <Dropdown.Option
                          label="Modifica"
                          aria-label="Pulsante per modificare le disponibilità per l'appuntamento di visita"
                          onClick={openEditModal}
                          loading={isSaving}
                        />
                      )}
                    </UpdateAppointmentAvailabilitiesAction>
                  )]}
              />
              <Card.Content>
                <Stack gap={75}>
                  {sortedAvailabilities?.map((availability) => (
                    <div key={availability}>
                      <Badge
                        icon={ICON_CHECK}
                        label={availability}
                        color={availabilitiesHaveBeenDeclared ? 'primary' : 'neutral'}
                        size="XS"
                      />
                    </div>
                  ))}
                </Stack>
              </Card.Content>
            </Card>
          )}

          <AdditionalServicesWidget
            contactId={contact.id!}
            canSeeDetails={canActWithHighPrivileges}
            canAdd={canActWithHighPrivileges}
            canDelete={canActWithHighPrivileges}
          />
        </Stack>
      )}
    >
      <OfferWidget
        intentId={intentId}
        onOfferStatusChange={mutateUpdateIntent}
      />

      {contact?.id && agent?.id && (
        <CalendarWidget
          contactId={contact.id}
          agentId={agent.id}
          filters={{
            referenceId: intentId,
            referenceType: ReferenceType.INTENT,
          }}
          onEventCreate={handleCalendarEventCreate}
          canAddAppointment
          canAddReminder
          canEditAppointment
          canEditReminder
          canDeleteReminder
        />
      )}

      <PropertyDetailsWidget propertyId={intent.propertyId} />

      <NotesSection
        notes={intent.notes || []}
        onNotesUpdate={handleNotesUpdate}
      />
    </RightColumnPageLayout>
  );
};

export default ViewIntentPage;
