import {
  Form, FormActionsAlign, FormHandlers, FormSubmitType,
} from '@doveit/bricks';
import React from 'react';
import { Agent, Contact } from '../../../providers/api/dtos';
import { AddressSuggestion } from '../../../providers/geo/dtos';
import SelectContact from '../../../contact/containers/select-contact/SelectContact';
import { SearchContactFormModel } from '../../../contact/components/search-contact-form/SearchContactForm';
import AgentAutoComplete from '../../../containers/agent-autocomplete/AgentAutoComplete';
import AddressSuggestionAutoComplete from '../../../components/address-suggestion-autocomplete/AddressSuggestionAutoComplete';
import { asSelectOptions } from '../../../select-options/utils/asSelectOptions';
import { propertyStatusLabels, residentialPropertyTypeLabels } from '../../../labels';
import { floorSelectOptions } from '../../../select-options';
import validationSchema from './RumorForm.schema';
import { AgentStatus, PropertyStatus, ResidentialPropertyType } from '../../../domain/types';

export interface RumorFormModel {
  contact?: Contact,
  agent?: Agent,
  description?: string,
  propertyAddress?: AddressSuggestion,
  propertyType?: ResidentialPropertyType,
  propertySize?: string,
  propertyStatus?: PropertyStatus,
  propertyFloor?: string,
}

export interface RumorFormProps {
  initialValues: RumorFormModel,
  onSubmit: FormSubmitType<RumorFormModel>,
  onContactChange: (existingContact?: Contact, formValues?: SearchContactFormModel) => void
  loading?: boolean,
  disabled?: boolean,
  innerRef?: React.MutableRefObject<FormHandlers>,
}

const RumorForm: React.FC<RumorFormProps> = ({
  onSubmit,
  onContactChange,
  initialValues,
  loading,
  disabled,
  innerRef,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    enableReinitialize
    validationSchema={validationSchema}
    loading={loading}
    disabled={disabled}
    innerRef={innerRef}
  >
    <Form.Fieldset legend="Proprietario immobile">
      <Form.Item>
        <SelectContact
          initialContact={initialValues.contact}
          onContactChange={onContactChange}
          showClearContact
        />
      </Form.Item>
    </Form.Fieldset>
    <Form.Fieldset legend="Contenuto notizia">
      <Form.Item size={{ MD: 1 / 2 }}>
        <AgentAutoComplete
          status={AgentStatus.ACTIVE}
          name="agent"
          label="Agente incaricato"
          aria-label="Campo per la selezione dell'agente incaricato"
          required
        />
      </Form.Item>
      <Form.Item>
        <Form.TextArea
          label="Descrizione"
          aria-label="Campo per l'inserimento della descrizione"
          name="description"
          rows={6}
          required
        />
      </Form.Item>
      <Form.Item>
        <AddressSuggestionAutoComplete
          name="propertyAddress"
          label="Indirizzo"
          aria-label="Campo per inserire l'indirizzo dell'immobile"
          placeholder="Es: Piazza Armando Diaz, 6, Milano"
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Select
          id="propertyType"
          name="propertyType"
          label="Tipologia"
          options={asSelectOptions(residentialPropertyTypeLabels, { value: '', label: 'Seleziona', disabled: true })}
          aria-label="Campo per inserire la tipologia dell'immobile"
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          type="number"
          name="propertySize"
          min="0"
          placeholder="Es: 60"
          label="Superficie (mq)"
          aria-label="Superficie (mq)"
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Select
          name="propertyStatus"
          label="Stato immobile"
          aria-label="Stato immobile"
          options={asSelectOptions(propertyStatusLabels, { value: '', label: 'Seleziona', disabled: true })}
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Select
          name="propertyFloor"
          label="Piano"
          aria-label="Piano"
          options={floorSelectOptions}
        />
      </Form.Item>
    </Form.Fieldset>
    {!innerRef && (
      <Form.Actions align={FormActionsAlign.RIGHT}>
        <Form.Submit label="Salva notizia" />
      </Form.Actions>
    )}
  </Form>
);

export default RumorForm;
