/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  StepNavigation,
  StepNavigationVariant,
  useCarousel,
  useNotifications,
  Link,
  Spacing,
  Typography,
  StepNavigationItemType,
  Message,
  Action,
  ICON_ARROW_UP,
  ActionIcon,
  HStack,
} from '@doveit/bricks';
import * as Yup from 'yup';
import objectHash from 'object-hash';
import { inRange } from 'lodash';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import useProspect from '../../hooks/use-prospect/useProspect';
import GenericErrorPage from '../../../pages/errors/generic/GenericErrorPage';
import useExtendedNavigate from '../../../hooks/use-extended-navigate/useExtendedNavigate';
import { GenericPageLayoutWrapperWidth } from '../../../layouts/generic-page-layout/wrapper/GenericPageLayoutWrapper';
import * as styles from './ManageAssignmentPage.style';
import { createManualAssignmentFromProspect, saveAssignmentWizardData, updateManualAssignmentFromProspect } from '../../../providers/api/assignment/assignmentProvider';
import useAssignmentWizardData from '../../hooks/use-assignment-wizard-data/useAssignmentWizardData';
import { AssignmentWizardData } from '../../../providers/api/dtos';
import {
  ASSIGNMENT_DIGITAL_CREATE_WIZARD_NAVIGATION,
  ASSIGNMENT_MANUAL_CREATE_WIZARD_STEPS,
  ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_SCHEMAS,
  AT_LEAST_ONE_ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE_CREATE_ACTION,
  LOAD_ASSIGNMENT_WIZARD_DATA_ERROR_MESSAGE,
  SAVE_ASSIGNMENT_WIZARD_DATA_ERROR_MESSAGE,
  SIGNATURE_ALREADY_REQUESTED_ERROR_MESSAGE,
  ASSIGNMENT_MANUAL_CREATE_WIZARD_NAVIGATION,
  ASSIGNMENT_DIGITAL_CREATE_WIZARD_STEPS,
  ASSIGNMENT_MANUAL_WIZARD_VALIDATION_SCHEMAS,
  AT_LEAST_ONE_ASSIGNMENT_MANUAL_WIZARD_VALIDATION_ERROR_MESSAGE_CREATE_ACTION,
  ASSIGNMENT_MANUAL_WIZARD_VALIDATION_ERROR_MESSAGE,
  ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE,
  ASSIGNMENT_DIGITAL_RENEW_WIZARD_STEPS,
  ASSIGNMENT_MANUAL_RENEW_WIZARD_STEPS,
  AT_LEAST_ONE_ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE_EDIT_ACTION,
  AT_LEAST_ONE_ASSIGNMENT_MANUAL_WIZARD_VALIDATION_ERROR_MESSAGE_EDIT_ACTION,
  ASSIGNMENT_DIGITAL_EDIT_EXTRA_PACKAGES_WIZARD_STEPS,
  ASSIGNMENT_MANUAL_EDIT_EXTRA_PACKAGES_WIZARD_STEPS,
  ASSIGNMENT_EDIT_EXTRA_PACKAGE_WIZARD_NAVIGATION,
  ASSIGNMENT_RENEW_WIZARD_NAVIGATION,
  AT_LEAST_ONE_ASSIGNMENT_VALIDATION_ERROR_MESSAGE_RENEW_ACTION,
} from './constants';
import PreviewAssignmentWizardPDFAction from '../../../assignment/containers/preview-assignment-wizard-pdf-action/PreviewAssignmentWizardPDFAction';
import Divider from '../../../components/divider/Divider';
import { useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import { AssignmentWizardStepProps } from '../../../property/assignment/components/assignment-wizard/types';
import useAssignmentByProspectId from '../../hooks/use-assignment-by-prospect-id/useAssignmentByProspectId';

interface StepValidationStatus {
  hasError: boolean,
  additionalInfo: Record<string, string>,
}

const ManageAssignmentPage: React.FC = () => {
  const navigate = useExtendedNavigate();
  const location = useLocation();
  const { addError } = useNotifications();
  const { id: prospectId, mode, action } = useParams();
  const agent = useCurrentAgent();
  const { data: prospect, isLoading: isProspectLoading, error: prospectError } = useProspect(prospectId);
  const isCreate = React.useMemo(() => action === 'create', [action]);
  const { data: assignment } = useAssignmentByProspectId(!isCreate ? prospectId : undefined);

  const {
    data: wizardData = { prospectId: prospectId!, planimetryDelegationRequired: false },
    isLoading: isWizardDataLoading,
    error: wizardDataError,
    mutate: mutateWizardData,
  } = useAssignmentWizardData(prospectId);
  const [isSaving, setIsSaving] = React.useState(false);
  const formRef = React.useRef<any>() as React.MutableRefObject<any>; // @TODO type
  const [triedToGenerateAssignment, setTriedToGenerateAssignment] = React.useState(false);

  const isDigitalCreation = React.useMemo(() => mode === 'digital', [mode]);
  const hasDigitalAssignmentEnabled = React.useMemo(
    () => !agent?.franchising || (agent?.agencies?.some((agency) => agency.configuration?.digitalAssignment?.enabled === true) ?? false),
    [agent?.agencies, agent?.franchising],
  );
  const [ASSIGNMENT_WIZARD_NAVIGATION, ASSIGNMENT_WIZARD_STEPS] = React.useMemo(() => {
    let steps: Record<number, React.FC<AssignmentWizardStepProps>>;
    let navigations: StepNavigationItemType[];
    switch (action) {
      case 'create':
      case 'edit':
        steps = isDigitalCreation ? ASSIGNMENT_DIGITAL_CREATE_WIZARD_STEPS : ASSIGNMENT_MANUAL_CREATE_WIZARD_STEPS;
        navigations = isDigitalCreation ? ASSIGNMENT_DIGITAL_CREATE_WIZARD_NAVIGATION : ASSIGNMENT_MANUAL_CREATE_WIZARD_NAVIGATION;
        break;
      case 'renew':
        steps = isDigitalCreation ? ASSIGNMENT_DIGITAL_RENEW_WIZARD_STEPS : ASSIGNMENT_MANUAL_RENEW_WIZARD_STEPS;
        navigations = ASSIGNMENT_RENEW_WIZARD_NAVIGATION;
        break;
      case 'edit-extra-packages':
        steps = isDigitalCreation ? ASSIGNMENT_DIGITAL_EDIT_EXTRA_PACKAGES_WIZARD_STEPS : ASSIGNMENT_MANUAL_EDIT_EXTRA_PACKAGES_WIZARD_STEPS;
        navigations = ASSIGNMENT_EDIT_EXTRA_PACKAGE_WIZARD_NAVIGATION;
        break;
      default:
        steps = [];
        navigations = [];
    }

    return [navigations, steps];
  }, [action, isDigitalCreation]);

  const finalButtonLabel = React.useMemo(() => {
    switch (`${action}-${mode}`) {
      case 'create-digital':
      case 'edit-digital':
        return 'Genera mandato';
      case 'create-manual':
        return "Crea l'immobile";
      case 'edit-manual':
        return 'Carica mandato';
      case 'renew-digital':
      case 'renew-manual':
        return 'Modifica mandato';
      case 'edit-extra-packages-digital':
      case 'edit-extra-packages-manual':
        return 'Modifica pacchetti';
      default:
        return '';
    }
  }, [action, mode]);

  const validationErrorMessage = React.useMemo(() => {
    switch (`${action}-${mode}`) {
      case 'create-digital':
        return AT_LEAST_ONE_ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE_CREATE_ACTION;
      case 'create-manual':
        return AT_LEAST_ONE_ASSIGNMENT_MANUAL_WIZARD_VALIDATION_ERROR_MESSAGE_CREATE_ACTION;
      case 'edit-digital':
        return AT_LEAST_ONE_ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE_EDIT_ACTION;
      case 'edit-manual':
        return AT_LEAST_ONE_ASSIGNMENT_MANUAL_WIZARD_VALIDATION_ERROR_MESSAGE_EDIT_ACTION;
      case 'renew-digital':
      case 'renew-manual':
        return AT_LEAST_ONE_ASSIGNMENT_VALIDATION_ERROR_MESSAGE_RENEW_ACTION;
      default:
        return '';
    }
  }, [action, mode]);

  const [stepsValidationStatus, setStepsValidationStatus] = React.useState({
    digital: Object.keys(ASSIGNMENT_DIGITAL_CREATE_WIZARD_STEPS).reduce<Record<number, StepValidationStatus>>((acc, step) => ({
      ...acc,
      [step]: { hasError: false },
    }), {}),
    manual: Object.keys(ASSIGNMENT_MANUAL_CREATE_WIZARD_STEPS).reduce<Record<number, StepValidationStatus>>((acc, step) => ({
      ...acc,
      [step]: { hasError: false },
    }), {}),
  });

  const { activePosition = 0, jump } = useCarousel({ totalElements: ASSIGNMENT_WIZARD_NAVIGATION.length });
  const [shouldSubmitAndGoToStep, setShouldSubmitAndGoToStep] = React.useState<[boolean, number | 'heaven']>([false, activePosition]);

  React.useEffect(() => {
    const [shouldSubmit] = shouldSubmitAndGoToStep;

    if (shouldSubmit) {
      const formEvent = {} as unknown as React.FormEvent<HTMLFormElement>;
      formRef?.current?.handleSubmit(formEvent);
    }
  }, [shouldSubmitAndGoToStep]);

  React.useEffect(() => {
    const hashStep = Number(location.hash.substring(1));

    if (!Number.isNaN(hashStep) && inRange(hashStep, 1, Object.values(ASSIGNMENT_WIZARD_STEPS).length + 1)) {
      jump?.(hashStep - 1);
    } else {
      jump?.(0);
    }
  }, [ASSIGNMENT_WIZARD_STEPS, jump, location.hash, location.pathname, navigate]);

  const hasAtLeastOneError = React.useMemo(
    () => Object.values(stepsValidationStatus[mode as keyof typeof stepsValidationStatus]).some(({ hasError }) => hasError),
    [mode, stepsValidationStatus],
  );

  const goBackToProspectPage = React.useCallback(
    () => navigate(`/prospects/${prospectId}`),
    [navigate, prospectId],
  );

  const goBackToPropertyPage = React.useCallback(() => {
    navigate(`/properties/${assignment?.propertyId}`);
  }, [assignment?.propertyId, navigate]);

  const goToPropertyPage = React.useCallback(
    (propertyId: number) => navigate(`/properties/${propertyId}`),
    [navigate],
  );

  const goToFlow = React.useCallback(
    () => navigate(`/prospects/${prospectId}/assignment/create/${isDigitalCreation ? 'manual' : 'digital'}`),
    [isDigitalCreation, navigate, prospectId],
  );

  const validateData = React.useCallback(async (data: AssignmentWizardData): Promise<boolean> => {
    const errors: Yup.ValidationError[] = [];

    await Promise.all(Object.entries(
      isDigitalCreation
        ? ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_SCHEMAS
        : ASSIGNMENT_MANUAL_WIZARD_VALIDATION_SCHEMAS,
    ).map(async ([step, schema]) => {
      try {
        await schema.validate(data, { abortEarly: false });

        setStepsValidationStatus((prev) => ({
          ...prev,
          [mode as keyof typeof stepsValidationStatus]: {
            ...prev[mode as keyof typeof stepsValidationStatus],
            [step]: {
              hasError: false,
            },
          },
        }));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          errors.push(err);

          const additionalInfo = err.inner.reduce<Record<string, string>>((acc, { path, message }) => {
            if (path) {
              return {
                ...acc,
                [path]: message,
              };
            }

            return acc;
          }, {});

          setStepsValidationStatus((prev) => ({
            ...prev,
            [mode as keyof typeof stepsValidationStatus]: {
              ...prev[mode as keyof typeof stepsValidationStatus],
              [step]: {
                hasError: true,
                additionalInfo,
              },
            },
          }));
        }
      }
    }));

    return errors.length > 0;
  }, [isDigitalCreation, mode]);

  const saveData = React.useCallback(async (data: AssignmentWizardData) => {
    const [, nextPosition] = shouldSubmitAndGoToStep;
    let propertyId = assignment?.propertyId;

    try {
      setIsSaving(true);

      if (!isDigitalCreation && nextPosition === 'heaven') {
        propertyId = (isCreate
          ? await createManualAssignmentFromProspect(data)
          : await updateManualAssignmentFromProspect(prospectId!, data)).id!;
      } else {
        await saveAssignmentWizardData(data);
      }

      setIsSaving(false);
      if (nextPosition !== 'heaven') {
        navigate(`${location.pathname}#${nextPosition + 1}`);
      } else if (isCreate && isDigitalCreation) {
        goBackToProspectPage();
      } else if (propertyId) {
        goToPropertyPage(propertyId);
      }

      await mutateWizardData();
    } catch (_) {
      setIsSaving(false);
      addError(SAVE_ASSIGNMENT_WIZARD_DATA_ERROR_MESSAGE);
    }
  }, [addError, assignment?.propertyId, goBackToProspectPage, goToPropertyPage, isCreate, isDigitalCreation, location.pathname, mutateWizardData, navigate, prospectId, shouldSubmitAndGoToStep]);

  const onSubmit = React.useCallback(async (data: AssignmentWizardData, isDirty: boolean) => {
    const [, nextPosition] = shouldSubmitAndGoToStep;

    /**
     * Validate every step when we create the assignment
     */
    if (nextPosition === 'heaven' || triedToGenerateAssignment) {
      setTriedToGenerateAssignment(true);
      const hasAtLeastAnError = await validateData(data);

      if (nextPosition === 'heaven' && hasAtLeastAnError) {
        return;
      }
    }

    /**
     * Don't make any API call but just jump to the `nextPosition` when:
     * 1. The form values have not been touched
     * 2. `nextPosition` is a valid one
     */
    if (!isDirty && nextPosition !== 'heaven') {
      navigate(`${location.pathname}#${nextPosition + 1}`);
      return;
    }

    await saveData(data);

    setShouldSubmitAndGoToStep(([_, prevNextPosition]) => [false, prevNextPosition]);
  }, [location.pathname, navigate, saveData, shouldSubmitAndGoToStep, triedToGenerateAssignment, validateData]);

  const jumpToStep = React.useCallback((step: number | 'heaven') => () => {
    setShouldSubmitAndGoToStep([true, step]);
  }, []);

  const navigationItems = React.useMemo(() => ASSIGNMENT_WIZARD_NAVIGATION.map((navigationStep, i) => ({
    ...navigationStep,
    hasError: stepsValidationStatus[mode as keyof typeof stepsValidationStatus][i].hasError,
  })), [ASSIGNMENT_WIZARD_NAVIGATION, mode, stepsValidationStatus]);

  const [isLastStepCompleted, setIslastStepCompleted] = React.useState(false);

  const handleStepWorkComplete = React.useCallback(() => {
    setIslastStepCompleted(true);
  }, []);

  if (prospectError || (wizardDataError && wizardDataError.response?.status !== 404)) {
    return <GenericErrorPage title={LOAD_ASSIGNMENT_WIZARD_DATA_ERROR_MESSAGE} />;
  }

  if (isCreate && wizardData.isDigitallySigned) {
    return <GenericErrorPage title={SIGNATURE_ALREADY_REQUESTED_ERROR_MESSAGE} />;
  }

  if (isProspectLoading || isWizardDataLoading) {
    return null;
  }

  return prospect && (
    <GenericPageLayout>
      <GenericPageLayout.Content
        title={isDigitalCreation ? 'Mandato digitale' : 'Mandato cartaceo'}
        maxWrapperWidth={GenericPageLayoutWrapperWidth.MEDIUM}
        subtitle={(
          !isCreate && assignment ? (
            <Link
              aria-label={'Vai alla pagina dell\'immobile'}
              onClick={goBackToPropertyPage}
            >
              ← Vedi immobile
            </Link>
          ) : (
            <Link
              aria-label="Vai alla pagina della valutazione"
              onClick={goBackToProspectPage}
            >
              ← Vedi valutazione
            </Link>
          )
        )}
        headerSlot={(hasDigitalAssignmentEnabled && action === 'create') ? (
          <HStack>
            <Typography.BODY>
              {isDigitalCreation ? 'Hai già il documento cartaceo' : 'Vuoi creare il mandato digitale'}?
            </Typography.BODY>
            <Action
              label={isDigitalCreation ? 'Crea immobile' : 'Crea il mandato'}
              aria-label={`Vai alla procedura ${isDigitalCreation ? 'manuale' : 'digitale'}`}
              size="S"
              emphasis="high"
              onClick={goToFlow}
              iconRight={{
                path: ICON_ARROW_UP,
                direction: 'right',
              }}
            />
          </HStack>
        ) : undefined}
      >
        <div css={styles.wizardNavigation}>
          <div>
            <StepNavigation
              items={navigationItems}
              onChange={(pos) => jumpToStep(pos)()}
              variant={StepNavigationVariant.COMPACT}
              currentPosition={activePosition}
            />
          </div>
          {isDigitalCreation && (
            <PreviewAssignmentWizardPDFAction prospectId={prospectId!} />
          )}
        </div>
        <GenericPageLayout.InnerContent>
          {ASSIGNMENT_WIZARD_NAVIGATION.map((_, i) => {
            const Step = ASSIGNMENT_WIZARD_STEPS[activePosition];

            if (activePosition >= Object.keys(ASSIGNMENT_WIZARD_STEPS).length) {
              return null;
            }

            const { hasError, additionalInfo } = stepsValidationStatus[mode as keyof typeof stepsValidationStatus][activePosition];

            return i === activePosition ? (
              <div key={activePosition}>
                {hasError && (
                  <Spacing margin={[0, 0, 400, 0]}>
                    {/** @todo Replace with Message when it will handle children */}
                    <Message
                      type="critical"
                      message={isDigitalCreation
                        ? ASSIGNMENT_DIGITAL_WIZARD_VALIDATION_ERROR_MESSAGE
                        : ASSIGNMENT_MANUAL_WIZARD_VALIDATION_ERROR_MESSAGE}
                    >
                      <ul>
                        {Object.values(additionalInfo).map((message) => (
                          <li key={message}>
                            {message}
                          </li>
                        ))}
                      </ul>
                    </Message>
                  </Spacing>
                )}
                <Step
                  prospect={prospect}
                  initialData={wizardData}
                  formRef={formRef}
                  onSubmit={onSubmit}
                  loading={isSaving}
                  handleStepWorkComplete={handleStepWorkComplete}
                />

                {activePosition === ASSIGNMENT_WIZARD_NAVIGATION.length - 1 && hasAtLeastOneError && (
                  <>
                    <Divider />
                    <div aria-label="Elenco degli errori di validazione">
                      <Message
                        type="critical"
                        message={validationErrorMessage}
                      />

                      <Spacing margin={[400, 0, 0, 0]}>
                        <strong>Correggi i seguenti step prima di poter procedere:</strong>

                        <Spacing margin={[200, 0, 0, 0]}>
                          {ASSIGNMENT_WIZARD_NAVIGATION.map(({ title }, j) => {
                            if (stepsValidationStatus[mode as keyof typeof stepsValidationStatus][j].hasError) {
                              const humanStepNumber = j + 1;

                              return (
                                <div
                                  key={objectHash([j, title])}
                                  css={styles.erroredStepListItem}
                                  onClick={() => navigate(`${location.pathname}#${j + 1}`)}
                                  aria-label={`Correggi i dati dello step ${humanStepNumber}`}
                                  role="button"
                                >
                                  <div>{humanStepNumber}. {title}</div>
                                  <strong>Correggi</strong>
                                </div>
                              );
                            }

                            return null;
                          })}
                        </Spacing>
                      </Spacing>
                    </div>
                  </>
                )}

                <div css={styles.wizardStepFooter}>
                  <div>
                    {i !== 0 && (
                      <ActionIcon
                        aria-label="Salva i dati e torna allo step precedente"
                        label="Salva i dati e torna allo step precedente"
                        onClick={jumpToStep(activePosition - 1)}
                        disabled={isSaving}
                        icon={{
                          path: ICON_ARROW_UP,
                          direction: 'left',
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {i < ASSIGNMENT_WIZARD_NAVIGATION.length - 1 ? (
                      <Action
                        label="Salva e continua"
                        aria-label="Salva i dati e procedi allo step successivo"
                        color="primary"
                        emphasis="high"
                        onClick={jumpToStep(activePosition + 1)}
                        loading={isSaving}
                        iconRight={{
                          path: ICON_ARROW_UP,
                          direction: 'right',
                        }}
                      />
                    ) : (
                      <Action
                        label={finalButtonLabel}
                        aria-label={finalButtonLabel}
                        onClick={jumpToStep('heaven')}
                        emphasis="high"
                        color="primary"
                        loading={isSaving}
                        disabled={!isLastStepCompleted || activePosition !== (ASSIGNMENT_WIZARD_NAVIGATION.length - 1)}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default ManageAssignmentPage;
