import React, { FunctionComponent } from 'react';
import {
  Badge, BadgeProps,
} from '@doveit/bricks';
import { InvoiceStatus } from '../../../domain/types';
import { invoiceStatusColors, invoiceStatusLabels } from '../../../labels';

export interface InvoiceStatusBadgeProps {
  invoiceStatus: InvoiceStatus,
  size?: BadgeProps['size'],
}

const InvoiceStatusBadge: FunctionComponent<InvoiceStatusBadgeProps> = ({
  invoiceStatus,
  size,
}) => (
  <Badge
    label={invoiceStatusLabels[invoiceStatus]}
    color={invoiceStatusColors[invoiceStatus]}
    size={size}
  />
);

export default InvoiceStatusBadge;
