import useSWR from 'swr';
import hash from 'object-hash';
import { AxiosError } from 'axios';
import { PropertyContact } from '../../../providers/api/dtos';
import { getPropertyContacts } from '../../../providers/api/property/propertyProvider';
import { SortParams } from '../../../providers/pagination';
import { useMatchMutate } from '../../../utils/swr/swr';
import { SWRCustomResponse } from '../../../hooks/types';

const PROPERTY_CONTACTS_RID = '/properties/:id/contacts';

export function usePropertyContacts(
  propertyId?: number,
  sort: SortParams = {},
): SWRCustomResponse<PropertyContact[], AxiosError> {
  const matchMutate = useMatchMutate();
  const rid = [PROPERTY_CONTACTS_RID, propertyId].join();
  const key = propertyId ? [rid, hash(sort)] : null;
  const result = useSWR(key, () => getPropertyContacts(propertyId!, sort));

  return {
    ...result,
    mutate: () => matchMutate(rid),
  };
}
