import { appointmentAvailabilityLabels } from '../../labels/appointmentAvailabilityLabels';
import { AppointmentAvailability } from '../../providers/api/dtos';
import { SortOrder } from '../../providers/pagination';

function toNumber(str: string): number {
  return Number(str.replace(/(^.+\D)(\d+)(\D.+$)/i, '$2'));
}

export default function sortAvailabilities(availabilities: AppointmentAvailability[], sort: SortOrder = SortOrder.ASC) {
  const sortedAvailabilities = [...availabilities].sort((a, b) => toNumber(appointmentAvailabilityLabels[a]) - toNumber(appointmentAvailabilityLabels[b]));

  return (sort === SortOrder.ASC) ? sortedAvailabilities : sortedAvailabilities.reverse();
}
