import React from 'react';
import { MessagePayload, Unsubscribe } from 'firebase/messaging';
import { isError } from 'lodash';
import {
  OnMessage, getFCMToken, onFCMMessageListener, registerMessageHandler, UseFCMResult,
} from '../../providers/clients/firebase-cloud-messaging';
import { createConversationsMessagingSubscription } from '../../providers/api/conversation/conversationProvider';

export function useFCM(
  componentId: string,
  onMessage: OnMessage,
  onBackgroundMessage?: OnMessage,
): UseFCMResult {
  const result = React.useRef<{ error? :Error }>({
    error: undefined,
  });

  const subscribeToMessaging = React.useCallback(async () => {
    const token = await getFCMToken();
    await createConversationsMessagingSubscription({ token });
  }, []);

  React.useEffect(() => {
    registerMessageHandler(componentId, onMessage);
  }, [componentId, onMessage]);

  // Foreground messages handler
  React.useEffect(() => {
    let unsubscribeFCMListener: Unsubscribe;

    subscribeToMessaging()
      .then(async () => {
        unsubscribeFCMListener = await onFCMMessageListener();
      }).catch((err) => {
        if (isError(err)) {
          result.current.error = err;
        }
      });

    return () => {
      if (unsubscribeFCMListener) {
        unsubscribeFCMListener();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Background messages handler
  React.useEffect(() => {
    let broadcastChannel: BroadcastChannel;

    if (onBackgroundMessage) {
      broadcastChannel = new BroadcastChannel('call-center-channel');

      broadcastChannel.addEventListener('message', ({ data }: MessageEvent<MessagePayload>) => {
        onBackgroundMessage(data);
      });
    }

    return () => {
      broadcastChannel?.close();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return result.current;
}
