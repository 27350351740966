import React from 'react';
import {
  ActionIcon,
  Card,
  FontWeight,
  Grid,
  HStack,
  ICON_EYE_OUTLINE,
  Stack,
  Text,
  UserPreview,
  UserPreviewSkeleton,
} from '@doveit/bricks';
import { Rumor } from '../../../providers/api/dtos';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import { goToDetailPage } from '../../../utils/navigate/utils';
import { ReferenceType } from '../../../domain/types';
import ViewRumorPopoverPreview from '../../../rumor/containers/view-rumor-popover-preview/ViewRumorPopoverPreview';
import RumorAvatar from '../../../rumor/components/rumor-avatar/RumorAvatar';
import UpdateRumorStatus from '../../../rumor/containers/update-rumor-status/UpdateRumorStatus';
import { AgentPreview } from '../../../agent/components';
import { toAgentPreviewProps } from '../../../agent/mappers/toAgentPreviewProps';
import DateViewer from '../../../components/date-viewer/DateViewer';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import ViewAgentPopoverPreview from '../../../agent/containers/view-agent-popover-preview/ViewAgentPopoverPreview';

export interface ViewContactRumorCardProps extends React.AriaAttributes {
  rumor: Rumor,
  onRumorUpdate?: (updatedRumor: Rumor) => void,
}

const ViewContactRumorCard: React.FC<ViewContactRumorCardProps> = ({
  rumor,
  onRumorUpdate,
  ...rest
}) => {
  const {
    data: agent,
    isLoading: isAgentLoading,
    error: agentError,
  } = useAgent(rumor.agentId);

  const { userIsAdmin, userIsCallCenter, userIsAgent } = useRBAC();

  const canViewAction = React.useMemo(() => userIsAdmin || userIsCallCenter || userIsAgent, [userIsAdmin, userIsAgent, userIsCallCenter]);

  const goToRumorPage = React.useCallback(() => {
    goToDetailPage(rumor.id!, ReferenceType.RUMOR, true);
  }, [rumor.id]);

  return (
    <Card aria-label="Informazioni notizia" {...rest}>
      <Card.Header
        primaryActions={[
          canViewAction && (
            <ActionIcon
              aria-label="Visualizza notizia"
              label="Visualizza"
              size="S"
              icon={{ path: ICON_EYE_OUTLINE }}
              onClick={goToRumorPage}
            />
          )]}
      >
        <ViewRumorPopoverPreview
          rumorId={rumor.id!}
          trigger={(
            <HStack gap={100} wrap="nowrap">
              <RumorAvatar size="S" />
              <Text.BodySmall fontWeight={FontWeight.MEDIUM}>
                {rumor.propertyAddress || 'Indirizzo non disponibile'}
              </Text.BodySmall>
            </HStack>
          )}
        />
      </Card.Header>

      <Card.Content>
        <Grid gutter={150}>
          <Grid.Unit>
            <UpdateRumorStatus
              rumor={rumor}
              size="XS"
              onSuccess={onRumorUpdate}
            />
          </Grid.Unit>

          <Grid.Unit>
            {isAgentLoading && (
              <div style={{ width: '10rem' }}>
                <UserPreviewSkeleton
                  size="S"
                  aria-label="Agente in caricamento"
                />
              </div>
            )}
            {agentError && (
              <UserPreview
                size="S"
                name="Errore agente"
              />
            )}
            {agent && (
              <ViewAgentPopoverPreview
                agent={agent}
                trigger={(
                  <AgentPreview
                    {...toAgentPreviewProps(agent)}
                    size="S"
                  />
                )}
              />
            )}
          </Grid.Unit>

          <Grid.Unit size={1 / 2}>
            <Stack gap={50}>
              <Text.Mini transform="uppercase">
                Aggiunta
              </Text.Mini>
              <Text.Caption>
                <DateViewer
                  stringDate={rumor.createdAt!}
                  checkIfToday
                />
              </Text.Caption>
            </Stack>
          </Grid.Unit>

          <Grid.Unit size={1 / 2}>
            <Stack>
              <Text.Mini transform="uppercase">
                Aggiornata
              </Text.Mini>
              <Text.Caption>
                <DateViewer
                  stringDate={rumor.updatedAt!}
                  checkIfToday
                />
              </Text.Caption>
            </Stack>
          </Grid.Unit>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default ViewContactRumorCard;
