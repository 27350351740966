import { css } from 'styled-components';
import {
  BreakpointQueryName, getSpacing, getTypography, SpacingName, ThemedFunctor, TypographyName,
} from '@doveit/bricks';

export const base: ThemedFunctor = () => css`
 height: 100%;
 display: flex;
`;

export const content: ThemedFunctor = () => css`
 display: flex;
 flex-wrap: nowrap;
 width: 100%;
`;

export const main: ThemedFunctor = ({ theme }) => css`
  display: none;

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const side: ThemedFunctor = ({
  theme,
  $height,
}) => css`
  ${getSpacing.padding(theme, SpacingName.SMALL)}
  width: 100%;
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    height: ${$height}px;
    width: auto;
    flex-basis: 25rem;
    flex-shrink: 0;
  }
`;

export const info: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.paddingBottom(theme, SpacingName.SMALLER)}
  border-bottom: 1px solid ${theme.palettes.neutrals.light};
  flex-shrink: 0;
`;

export const filters: ThemedFunctor = ({ $visible }) => css`
  display: ${$visible ? 'block' : 'none'};
`;

export const list: ThemedFunctor = () => css`
  flex: 1;
  overflow-y: auto;
`;

export const item: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.padding(theme, [SpacingName.SMALLER, 0])}
  border-top: 1px solid ${theme.palettes.neutrals.light};

  &:first-child {
    border-top: 0;
  }
`;

export const legend: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  ${getSpacing.paddingTop(theme, SpacingName.SMALL)}
  border-top: 1px solid ${theme.palettes.neutrals.light};
`;

export const legendItems: ThemedFunctor = () => css`
  columns: 2;
  column-gap: 1rem;
`;

export const legendItem: ThemedFunctor = ({
  $color,
}) => css`
  font-weight: bold;
  display: inline-flex;

  &::before {
    display: block;
    content: ' ';
    background-color: ${$color};
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 100%;
    margin-right: .5rem;
  }
`;
