import * as Yup from 'yup';

export default Yup.object().shape({
  date: Yup.string().required('La data dell\'appuntamento è obbligatoria'),
  time: Yup.string().required('L\'ora dell\'appuntamento è obbligatoria'),
  agent: Yup
    .object({
      id: Yup.number().required('È necessario selezionare un agente'),
    })
    .nullable(),
});
