import React from 'react';
import { Grid, Message, UserPreviewSkeleton } from '@doveit/bricks';
import { useNavigate } from 'react-router-dom';
import { Agent } from '../../../providers/api/dtos';
import { DescendantsStrategy } from '../../../domain/types';
import { useAgent } from '../../../hooks/use-agent/useAgent';
import AgentPreview from '../../components/agent-preview/AgentPreview';
import { toAgentPreviewProps } from '../../mappers/toAgentPreviewProps';
import { formatAgentDetails } from '../../../utils/agent/formatAgentDetails';

export interface ViewAgentDescendantsProps {
  agentId: NonNullable<Agent['id']>,
  strategy: DescendantsStrategy,
  showSelf?: boolean,
}

export const LOAD_AGENT_DESCENDANTS_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni degli agenti';

const ViewAgentDescendants: React.FC<ViewAgentDescendantsProps> = ({
  agentId,
  strategy,
  showSelf = false,
}) => {
  const navigate = useNavigate();
  const { data: agent, isLoading: isAgentLoading, error: agentError } = useAgent(
    agentId,
    { descendantsStrategy: strategy },
  );

  const descendants = React.useMemo(() => {
    if (!agent || !agent.descendants) return [];

    return showSelf ? [agent, ...agent.descendants] : agent.descendants;
  }, [agent, showSelf]);

  const goToAgentDetailPage = React.useCallback((id: NonNullable<Agent['id']>) => () => {
    navigate(`/admin/agents/${id}`);
  }, [navigate]);

  if (agentError) {
    return (
      <Message
        type="critical"
        message={LOAD_AGENT_DESCENDANTS_ERROR_MESSAGE}
      />
    );
  }

  if (isAgentLoading) {
    return <UserPreviewSkeleton />;
  }

  return agent! && (
    <Grid
      aria-label="Discendenti dell'agente"
      gutter={75}
    >
      {descendants.map((descendant) => (
        <Grid.Unit
          key={descendant.id}
          size={{ MD: 1 / 2 }}
        >
          <AgentPreview
            {...toAgentPreviewProps(descendant)}
            details={formatAgentDetails(descendant.jobTitle, descendant.coveredGeo)}
            onClick={goToAgentDetailPage(descendant.id!)}
          />
        </Grid.Unit>
      ))}
    </Grid>
  );
};

export default ViewAgentDescendants;
