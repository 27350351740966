import React from 'react';
import { Badge, BadgeProps } from '@doveit/bricks';
import { prospectApprovalStatusColor, prospectApprovalStatusLabelForAgent, prospectApprovalStatusLabelForSupervisor } from '../../constants';
import { ComputedProspectApprovalStatus } from '../../hooks/use-computed-prospect-approval-status/useComputedProspectApprovalStatus';

export interface ProspectApprovalStatusBadgeProps extends React.AriaAttributes {
  status: ComputedProspectApprovalStatus,
  userIs: 'agent' | 'supervisor',
  size?: BadgeProps['size'],
  emphasis?: BadgeProps['emphasis'],
}

const ProspectApprovalStatusBadge: React.FC<ProspectApprovalStatusBadgeProps> = ({
  userIs,
  status,
  size,
  emphasis,
}) => (
  <Badge
    aria-label="Badge stato richiesta di approvazione della valutazione"
    label={userIs === 'agent'
      ? prospectApprovalStatusLabelForAgent[status]
      : prospectApprovalStatusLabelForSupervisor[status]}
    color={prospectApprovalStatusColor[status]}
    size={size}
    emphasis={emphasis}
  />
);

export default ProspectApprovalStatusBadge;
