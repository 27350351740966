import { differenceInDays, isBefore, startOfDay } from 'date-fns';
import { Assignment } from '../../../providers/api/dtos';

export enum AssignmentStatus {
  ACTIVE = 'ACTIVE',
  EXPIRING = 'EXPIRING',
  EXPIRED = 'EXPIRED',
}

export const EXPIRING_THRESHOLD_IN_DAYS = 30;

export function getAssignmentStatus(assignmentEndDate: Assignment['endDate']): AssignmentStatus {
  if (!assignmentEndDate) {
    return AssignmentStatus.ACTIVE;
  }

  const now = startOfDay(new Date());
  const assignmentEndDateAsDate = startOfDay(new Date(assignmentEndDate));

  if (isBefore(assignmentEndDateAsDate, now)) {
    return AssignmentStatus.EXPIRED;
  }

  const diffInDays = differenceInDays(assignmentEndDateAsDate, now);

  if (diffInDays <= EXPIRING_THRESHOLD_IN_DAYS) {
    return AssignmentStatus.EXPIRING;
  }

  return AssignmentStatus.ACTIVE;
}
