import { FeatureType, PropertyStatus, ResidentialPropertyType } from '../../domain/types';

import { SearchCriteria } from '../../providers/api/dtos/searchCriteria';
import { OptionalBoolean } from '../../types';
import { SearchCriteriaFormModel } from '../component/search-criteria-form/SearchCriteriaForm';

function optionaBooleanToBoolean(value?: OptionalBoolean): boolean | undefined {
  switch (value) {
    case OptionalBoolean.YES:
      return true;
    case OptionalBoolean.NO:
      return false;
    case OptionalBoolean.UNKNOWN:
      return undefined;
    default:
      return undefined;
  }
}

function booleanToOptionalBoolean(value?: boolean): OptionalBoolean {
  switch (value) {
    case true:
      return OptionalBoolean.YES;
    case false:
      return OptionalBoolean.NO;
    default:
      return OptionalBoolean.UNKNOWN;
  }
}

function selectedValues<T>(field: { [key in FeatureType | PropertyStatus | ResidentialPropertyType]?: boolean }): T[] {
  return Object.entries(field)
    .filter(([_, value]) => !!value)
    .map(([key, _]) => key as unknown as T);
}

function enumToSelectedValues<T extends FeatureType | PropertyStatus | ResidentialPropertyType>(enums: T[]) {
  return enums.reduce((accumulator: { [key in T]?: boolean }, feature) => ({
    ...accumulator,
    [feature]: true,
  }), {});
}

export function toPartialSearchCriteria(model: SearchCriteriaFormModel): Omit<SearchCriteria, 'geo' | 'contactId'> {
  return {
    features: selectedValues<FeatureType>(model.features),
    propertyStatus: model.propertyStatus,
    propertyTypes: model.propertyTypes,
    balcony: model.balcony,
    terrace: model.terrace,
    garage: model.garage,
    privateGarden: model.privateGarden,
    mortgage: optionaBooleanToBoolean(model.mortgage),
    auction: optionaBooleanToBoolean(model.auction),
    minSize: model.size?.min ? Number.parseInt(model.size.min!, 10) : undefined,
    maxSize: model.size?.max ? Number.parseInt(model.size.max!, 10) : undefined,
    minPrice: model.price?.min ? Number.parseInt(model.price.min!, 10) : undefined,
    maxPrice: model.price?.max ? Number.parseInt(model.price.max!, 10) : undefined,
    notes: model.notes,
    active: true,
  };
}

export function toSearchCriteriaFormModel(searchCriteria: SearchCriteria): SearchCriteriaFormModel {
  return {
    features: enumToSelectedValues(searchCriteria.features),
    propertyStatus: searchCriteria.propertyStatus,
    propertyTypes: searchCriteria.propertyTypes,
    balcony: searchCriteria.balcony,
    terrace: searchCriteria.terrace,
    garage: searchCriteria.garage,
    privateGarden: searchCriteria.privateGarden,
    mortgage: booleanToOptionalBoolean(searchCriteria.mortgage),
    auction: booleanToOptionalBoolean(searchCriteria.auction),
    size: {
      min: searchCriteria.minSize?.toString(),
      max: searchCriteria.maxSize?.toString(),
    },
    price: {
      min: searchCriteria.minPrice?.toString(),
      max: searchCriteria.maxPrice?.toString(),
    },
    notes: searchCriteria.notes,
  };
}
