import {
  Form, FormActionsAlign, FormSubmitType, Icon,
} from '@doveit/bricks';
import React, {
  FunctionComponent, useCallback, useState, ChangeEvent,
} from 'react';
import { Department } from '../../../domain/types';
import JobPositionsSelect from '../../../job-position/containers/job-positions-select/JobPositionsSelect';
import { JobPosition } from '../../../providers/api/dtos';
import { provinceSelectOptions } from '../../../select-options';
import { ThemeIcons } from '../../../theme/icons';
import { BooleanString } from '../../../utils/boolean-string/booleanString';
import { experiencesRadioOptions } from '../job-application-form/fieldPresets';
import validationSchema from './JobApplicationWithApplicantForm.schema';

export interface JobApplicationWithApplicantFormModel {
  name: string,
  phoneNumber?: string,
  email?: string,
  linkedIn?: string,
  jobPosition?: JobPosition,
  province?: string,
  experience?: string,
  agency?: BooleanString,
  license?: BooleanString,
  note?: string,
  source: string,
}

export interface JobApplicationWithApplicantFormProps {
  initialValues?: Partial<JobApplicationWithApplicantFormModel>,
  disableSource?: boolean,
  filterJobPositions?: (jobPosition: JobPosition) => boolean,
  loading?: boolean,
  onSubmit: FormSubmitType<JobApplicationWithApplicantFormModel>
}

const DEFAULT_INITIAL_VALUES: JobApplicationWithApplicantFormModel = {
  name: '',
  email: '',
  phoneNumber: '',
  linkedIn: '',
  source: '',
  province: '',
  experience: '',
  agency: '',
  license: '',
  note: '',
};

const JobApplicationWithApplicantForm: FunctionComponent<JobApplicationWithApplicantFormProps> = ({
  initialValues = DEFAULT_INITIAL_VALUES,
  disableSource = false,
  loading = false,
  filterJobPositions = () => true,
  onSubmit,
}) => {
  const [selectedPosition, setSelectedPosition] = useState<JobPosition>();

  const mergedInitialValues: JobApplicationWithApplicantFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  const isSales = useCallback((jobPosition?: JobPosition) => jobPosition?.department === Department.SALES, []);

  return (
    <Form
      initialValues={mergedInitialValues}
      onSubmit={onSubmit}
      loading={loading}
      validationSchema={validationSchema({ skipSource: disableSource })}
      aria-label="Form per inserire una nuova candidatura"
    >
      {({ setFieldValue, values }) => {
        const onRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
          const newJobPosition = e.target.value as unknown as JobPosition;
          setFieldValue('jobPosition', newJobPosition);

          if (isSales(selectedPosition) && !isSales(newJobPosition)) {
            setFieldValue('agency', DEFAULT_INITIAL_VALUES.agency);
            setFieldValue('license', DEFAULT_INITIAL_VALUES.license);
            setFieldValue('province', DEFAULT_INITIAL_VALUES.province);
          }

          setSelectedPosition(newJobPosition);
        };
        return (
          <>
            <Form.Fieldset legend="Contatto">
              <Form.Item>
                <Form.Input
                  name="name"
                  label="Nome e cognome"
                  placeholder="Es. Mario Rossi"
                  aria-label="Campo per inserire il nome e il cognome"
                  type="text"
                  required
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="email"
                  label="Email"
                  placeholder="Es. mario.rossi@gmail.com"
                  aria-label="Campo per inserire l'indirizzo email"
                  type="text"
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  name="phoneNumber"
                  label="Numero di telefono"
                  placeholder="Es. 3923456789"
                  aria-label="Campo per inserire il numero di telefono"
                  type="text"
                />
              </Form.Item>

              <Form.Item>
                <Form.Input
                  name="linkedIn"
                  label="Profilo LinkedIn"
                  placeholder="Es. https://www.linkedin.com/in/mariorossi"
                  prefixElement={<Icon path={ThemeIcons.LINKEDIN} />}
                  aria-label="Campo per inserire il profilo LinkedIn"
                  type="text"
                />
              </Form.Item>
            </Form.Fieldset>
            <Form.Fieldset legend="Candidatura">
              <Form.Item>
                <Form.RadioButtonGroup
                  name="source"
                  label="Sorgente"
                  aria-label="Sorgente della candidatura"
                  disabled={disableSource}
                  itemSize={1 / 4}
                  items={[{
                    label: 'Inbound',
                    value: 'inbound',
                  }, {
                    label: 'Referral',
                    value: 'referral',
                  }, {
                    label: 'Spam',
                    value: 'spam',
                  }, {
                    label: 'Webinar',
                    value: 'webinar',
                  }]}
                />
              </Form.Item>

              <Form.Item size={{ MD: 1 / 2 }}>
                <JobPositionsSelect
                  name="jobPosition"
                  label="Ruolo"
                  aria-label="Campo per specificare il ruolo"
                  value={values.jobPosition}
                  onChange={onRoleChange}
                  filterJobPositions={filterJobPositions}
                  required
                />
              </Form.Item>

              {isSales(selectedPosition) && (
                <Form.Item size={{ MD: 1 / 2 }}>
                  <Form.Select
                    name="province"
                    label="Zona di riferimento"
                    aria-label="Campo per specificare la zona di riferimento"
                    options={provinceSelectOptions}
                    dataRef="province-selection"
                  />
                </Form.Item>
              )}
              {selectedPosition && (
                <Form.Item>
                  <Form.RadioButtonGroup
                    name="experience"
                    label="Livello di esperienza"
                    aria-label="Campo per specificare l'esperienza nella compravendita immobiliare"
                    itemSize={{ MD: 1 / 2, LG: 1 / 4 }}
                    items={experiencesRadioOptions}
                  />
                </Form.Item>
              )}
              {isSales(selectedPosition) && (
                <>
                  <Form.Item size={{ MD: 1 / 4 }}>
                    <Form.YesOrNotButtons
                      name="license"
                      label="Hai il patentino da agente?"
                      aria-label="Campo per specificare se si dispone di patentino"
                    />
                  </Form.Item>
                  <Form.Item size={{ MD: 1 / 4 }}>
                    <Form.YesOrNotButtons
                      name="agency"
                      label="Attualmente lavora presso un'agenzia?"
                      aria-label="Campo per specificare se attualmente lavora presso un'agenzia"
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item>
                <Form.TextArea
                  name="note"
                  label="Note"
                  aria-label="Campo per inserire una nota"
                />
              </Form.Item>

              <Form.Actions align={FormActionsAlign.RIGHT}>
                <Form.Submit label="Aggiungi candidatura" />
              </Form.Actions>
            </Form.Fieldset>
          </>
        );
      }}
    </Form>
  );
};

export default JobApplicationWithApplicantForm;
