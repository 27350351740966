import React from 'react';
import { AxiosError } from 'axios';
import { isFuture } from 'date-fns';
import {
  AppointmentStatus, IntentStatus, OfferStatus, ReferenceType,
  Status,
} from '../../../domain/types';
import { Intent } from '../../../providers/api/dtos';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';
import useContactAppointments from '../../../appointment/hooks/use-contact-appointments/useContactAppointments';
import { SortOrder } from '../../../providers/pagination';
import useOffersByIntentId from '../../../offer/hooks/use-offers-by-intent-id/useOffersByIntentId';
import usePropertyPreview from '../../../property/hooks/use-property-preview/usePropertyPreview';

export type ComputedIntentStatus =
  | IntentStatus
  | 'WITH_FUTURE_APPOINTMENT'
  | 'WITH_PAST_APPOINTMENT'
  | 'WITH_CANCELLED_APPOINTMENT'
  | 'WITH_OFFER';

export type ComputedIntentStatusCanBeUpdatedTo = ComputedIntentStatus | 'ARCHIVED' | 'RECOVERED';

export type UseComputedIntentStatusData = {
  status: ComputedIntentStatus,
  canBeUpdated: boolean,
  canBeUpdatedTo: (status: ComputedIntentStatusCanBeUpdatedTo) => boolean,
};

type UseComputedIntentStatusResult = {
  data?: UseComputedIntentStatusData | undefined,
  isLoading: boolean,
  errors?: Error[] | undefined,
  mutate: () => Promise<void>,
};

const computedIntentStatusCanBeUpdatedTo: Record<ComputedIntentStatus, ComputedIntentStatusCanBeUpdatedTo[]> = {
  [IntentStatus.IN_PROGRESS]: ['WITH_FUTURE_APPOINTMENT', 'WITH_PAST_APPOINTMENT', 'WITH_OFFER', 'ARCHIVED'],
  WITH_FUTURE_APPOINTMENT: ['WITH_CANCELLED_APPOINTMENT', 'WITH_OFFER', 'ARCHIVED'],
  WITH_PAST_APPOINTMENT: ['WITH_FUTURE_APPOINTMENT', 'WITH_PAST_APPOINTMENT', 'WITH_OFFER', 'ARCHIVED'],
  WITH_CANCELLED_APPOINTMENT: ['WITH_FUTURE_APPOINTMENT', 'WITH_PAST_APPOINTMENT', 'WITH_OFFER', 'ARCHIVED'],
  [IntentStatus.KO_AREA]: ['RECOVERED'],
  [IntentStatus.KO_BOUGHT_OTHER]: ['RECOVERED'],
  [IntentStatus.KO_INFO]: ['RECOVERED'],
  [IntentStatus.KO_NOT_INTERESTED]: ['RECOVERED'],
  [IntentStatus.KO_OVERPRICED]: ['RECOVERED'],
  [IntentStatus.KO_PROPERTY]: ['RECOVERED'],
  [IntentStatus.KO_REFUSED]: ['RECOVERED'],
  [IntentStatus.KO_MORTGAGE]: ['RECOVERED'],
  [IntentStatus.KO_OTHER_CONSTRAINTS]: ['RECOVERED'],
  [IntentStatus.KO_DUPLICATE]: [],
  [IntentStatus.KO_PROPERTY_NOT_AVAILABLE]: ['RECOVERED'],
  [IntentStatus.BOUGHT]: [],
  WITH_OFFER: [],
};

export const useComputedIntentStatus = (intent?: Intent): UseComputedIntentStatusResult => {
  const [errors, setErrors] = React.useState<Error[] | undefined>();

  const handleNewError = React.useCallback((err: AxiosError) => {
    setErrors((prev) => {
      if (!prev) return [err];
      return [...prev, err];
    });
  }, []);

  const {
    data: propertyPreview,
    isLoading: isPropertyPreviewLoading,
    mutate: mutatePropertyPreview,
  } = usePropertyPreview(
    intent?.propertyId,
    { onError: handleNewError },
  );

  const { userIsAdmin, userIsCallCenter } = useRBAC();
  const userIsIntentAgentOrManager = useCurrentAgentIsSameAgentOrManager(propertyPreview?.agentEmail);

  const {
    data: appointments,
    isLoading: areAppointmentsLoading,
    mutate: mutateAppointments,
  } = useContactAppointments(
    intent?.contactId,
    { referenceId: intent?.id, referenceType: ReferenceType.INTENT },
    { sort: { createdAt: SortOrder.DESC } },
    { onError: handleNewError },
  );

  const {
    data: pendingOffers,
    isLoading: arePendingOffersLoading,
    mutate: mutateOffers,
  } = useOffersByIntentId(
    intent?.id,
    [OfferStatus.ACCEPTED, OfferStatus.IN_PROGRESS],
    { onError: handleNewError },
  );

  const isAClosedProperty = React.useMemo(() => propertyPreview && [Status.RITIRATO, Status.VENDUTO].includes(propertyPreview.status), [propertyPreview]);

  const computedIntentStatus: ComputedIntentStatus | undefined = React.useMemo(() => {
    if (!intent) {
      return undefined;
    }

    const hasPendingOffers = !!pendingOffers?.length;
    const hasAppointments = !!appointments?.content.length;
    const latestAppointment = appointments?.content?.[0];
    const hasOnlyCancelledAppointments = hasAppointments && appointments?.content?.every(({ status }) => status === AppointmentStatus.CANCELLED);
    const latestAppointmentStatus = latestAppointment?.status;

    if (intent.status !== IntentStatus.IN_PROGRESS) {
      return intent.status;
    }

    if (hasPendingOffers) {
      return 'WITH_OFFER';
    }

    if (hasOnlyCancelledAppointments) {
      return 'WITH_CANCELLED_APPOINTMENT';
    }

    if (hasAppointments && latestAppointment) {
      if (latestAppointmentStatus === AppointmentStatus.TODO && isFuture(new Date(latestAppointment.startDate))) {
        return 'WITH_FUTURE_APPOINTMENT';
      }
      return 'WITH_PAST_APPOINTMENT';
    }

    return IntentStatus.IN_PROGRESS;
  }, [appointments?.content, intent, pendingOffers?.length]);

  const mutate = React.useCallback(async () => {
    await mutatePropertyPreview();
    await mutateAppointments();
    await mutateOffers();
  }, [mutateAppointments, mutateOffers, mutatePropertyPreview]);

  const isLoading = React.useMemo(
    () => isPropertyPreviewLoading || areAppointmentsLoading || arePendingOffersLoading,
    [areAppointmentsLoading, arePendingOffersLoading, isPropertyPreviewLoading],
  );

  const data: UseComputedIntentStatusData | undefined = React.useMemo(() => {
    if (errors || isLoading || !computedIntentStatus) {
      return undefined;
    }

    const canBeUpdatedTo = (userIsAdmin || userIsCallCenter || userIsIntentAgentOrManager) && !isAClosedProperty
      ? computedIntentStatusCanBeUpdatedTo[computedIntentStatus]
      : [];

    return {
      status: computedIntentStatus,
      canBeUpdated: canBeUpdatedTo.length > 0,
      canBeUpdatedTo: (status: ComputedIntentStatusCanBeUpdatedTo) => canBeUpdatedTo.includes(status),
    };
  }, [computedIntentStatus, errors, isAClosedProperty, isLoading, userIsAdmin, userIsCallCenter, userIsIntentAgentOrManager]);

  return React.useMemo(() => ({
    data,
    isLoading,
    errors,
    mutate,
  }), [data, errors, isLoading, mutate]);
};
