import { AdditionalServiceType } from '../domain/types';

export const additionalServicesLabels: { [key in AdditionalServiceType]: string } = {
  [AdditionalServiceType.MORTGAGE]: 'Consulenza gratuita mutuo',
  [AdditionalServiceType.INSURANCE]: 'Assicurazione casa',
  [AdditionalServiceType.UTILITIES]: 'Utilities (ADSL, luce, gas)',
  [AdditionalServiceType.RELOCATION]: 'Trasloco',
  [AdditionalServiceType.RENOVATION]: 'Ristrutturazione',
  [AdditionalServiceType.FURNITURE]: 'Arredamento',
};
