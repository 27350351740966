import * as Yup from 'yup';

export default Yup.object({
  propertyId: Yup
    .number()
    .required('Il campo è obbligatorio'),
  title: Yup
    .string()
    .required('Il campo è obbligatorio'),
  abstract: Yup
    .string()
    .required('Il campo è obbligatorio'),
});
