import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { Property } from '../../../providers/api/dtos/property';
import { getProperty } from '../../../providers/api/property/propertyProvider';

export const PROPERTY_RID = '/property/';

export default function useProperty(
  propertyId?: number,
  options?: SWRConfiguration<Property, AxiosError>,
): SWRResponse<Property, AxiosError> {
  return useSWR(propertyId ? [PROPERTY_RID, propertyId] : null, () => getProperty(propertyId!), options);
}
