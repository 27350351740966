import {
  BreakpointQueryName, SpacingName, ThemedFunctor, getSpacing,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({
  theme,
  $columns = 1,
}) => css`
  > * {
    ${getSpacing.padding(theme, [SpacingName.SMALLEST, 0, 0])}
    ${getSpacing.marginBottom(theme, SpacingName.SMALLER)}
    width: 100%;
    break-inside: avoid;
  }

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    column-count: ${$columns};
    column-gap: 4rem;
  }
`;
