import React, { FunctionComponent, useMemo } from 'react';
import { Form, FormProps } from '@doveit/bricks';
import { addDays } from 'date-fns';
import schema from './ReminderForm.schema';
import { ReminderFormModel, ReminderType } from '../../../prospect/models/ReminderFormModel';
import { formatInputDate, formatInputTime } from '../../../utils/form';

/*
 * WARNING: we use Date.now here instead of "new Date" because of testing
 */

export interface ReminderFormProps {
  loading?: boolean,
  initialValues?: ReminderFormModel,
  onSubmit: (values: ReminderFormModel) => void,
  formRef?: FormProps<ReminderFormModel>['innerRef'],
  hideSubmit?: boolean,
}

function getDate(values: ReminderFormModel) {
  if (values.type === ReminderType.TOMORROW) {
    return formatInputDate(addDays(Date.now(), 1));
  }
  if (values.type === ReminderType.NEXT_WEEK) {
    return formatInputDate(addDays(Date.now(), 7));
  }

  return values.date;
}

function getTime(values: ReminderFormModel) {
  if (values.type !== ReminderType.OTHER) {
    return formatInputTime(Date.now());
  }

  return values.time;
}

const ReminderForm: FunctionComponent<ReminderFormProps> = ({
  loading,
  initialValues,
  onSubmit,
  formRef,
  hideSubmit,
}) => {
  const today = useMemo(() => formatInputDate(Date.now()), []);
  const initialValuesMerged = useMemo(() => {
    const result: ReminderFormModel = {
      type: ReminderType.TOMORROW,
      ...initialValues,
    };

    if (result.id) {
      result.type = ReminderType.OTHER;
    }
    result.date = getDate(result);
    result.time = getTime(result);

    return result;
  }, [initialValues]);

  return (
    <Form
      loading={loading}
      initialValues={initialValuesMerged}
      validationSchema={schema}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {({ values, setFieldValue }) => {
        const onRadioChanged = (type: ReminderType) => () => {
          const newValues = { ...values, type };
          setFieldValue('date', getDate(newValues));
          setFieldValue('time', getTime(newValues));
        };

        return (
          <>
            <Form.Group label="Ricordamelo:">
              {!values.id && (
                <>
                  <Form.Item size={{ MD: 1 / 3 }}>
                    <Form.RadioButton
                      name="type"
                      value={ReminderType.TOMORROW}
                      text="Domani"
                      aria-label="Domani"
                      onChange={onRadioChanged(ReminderType.TOMORROW)}
                    />
                  </Form.Item>
                  <Form.Item size={{ MD: 1 / 3 }}>
                    <Form.RadioButton
                      name="type"
                      value={ReminderType.NEXT_WEEK}
                      text="Tra una settimana"
                      aria-label="Tra una settimana"
                      onChange={onRadioChanged(ReminderType.NEXT_WEEK)}
                    />
                  </Form.Item>
                  <Form.Item size={{ MD: 1 / 3 }}>
                    <Form.RadioButton
                      name="type"
                      value={ReminderType.OTHER}
                      text="Altro"
                      aria-label="Altro"
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  label="Data"
                  name="date"
                  type="date"
                  min={today}
                  value={values.date}
                  aria-label="Inserisci la data del promemoria"
                  disabled={values.type !== ReminderType.OTHER}
                />
              </Form.Item>
              <Form.Item size={{ MD: 1 / 2 }}>
                <Form.Input
                  label="Ora"
                  name="time"
                  type="time"
                  value={values.time}
                  aria-label="Inserisci l'ora del promemoria"
                  disabled={values.type !== ReminderType.OTHER}
                />
              </Form.Item>
            </Form.Group>
            <Form.Item>
              <Form.TextArea
                label="Note"
                name="notes"
                aria-label="Inserisci il motivo del promemoria"
                placeholder="Inserisci il motivo del promemoria qui"
              />
            </Form.Item>
            {
              !hideSubmit && (
                <Form.Item>
                  <Form.Submit
                    label={values.id ? 'Modifica promemoria' : 'Aggiungi promemoria'}
                  />
                </Form.Item>
              )
            }
          </>
        );
      }}
    </Form>
  );
};

export default ReminderForm;
