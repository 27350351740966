import useSWR, { SWRResponse } from 'swr';
import { JobPosition } from '../../../providers/api/dtos';
import { getJobPositions } from '../../../providers/api/job-position/jobPositionProvider';

export function getJobPositionsRID() {
  return ['/v1/job-positions'];
}

export default function useJobPositions(): SWRResponse<JobPosition[], Error> {
  return useSWR(getJobPositionsRID(), () => getJobPositions());
}
