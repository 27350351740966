import React from 'react';
import { FileError } from 'react-dropzone';
import FileUploadManager, { UploadModel } from '../../../../components/file-upload-manager/FileUploadManager';
import { createContentWithFile } from '../../../../providers/api/content/contentProvider';
import { ImageFile } from '../../../../components/file-uploader/toImageFile';
import { ContentType } from '../../../../domain/types';
import { Content } from '../../../../providers/api/dtos';

export interface PropertyContentsUploadManagerProps {
  propertyId: number,
  contentType: ContentType,
  onCreateSuccess?: (content: Content) => void,
  onFileUploadAddedToQueue?: VoidFunction,
  onUploadQueueEnd?: VoidFunction,
}

const IMAGE_FILE_SIZE_ERROR_PARAMS = {
  constraints: {
    width: 1200,
    height: 800,
  },
  error: {
    message: 'Image file width is less than 1200 or image file height is less than 800',
    code: 'image-file-size',
  },
};

const PropertyContentsUploadManager: React.FC<PropertyContentsUploadManagerProps> = ({
  propertyId,
  contentType,
  onCreateSuccess,
  onFileUploadAddedToQueue,
  onUploadQueueEnd,
}) => {
  const { constraints: imagesizeConstraints, error: imageSizeError } = IMAGE_FILE_SIZE_ERROR_PARAMS;

  const onInternalFileUploadRequest = React.useCallback(async ({ fileName }: UploadModel, file: File) => {
    const contentToCreate: Content = {
      id: null,
      url: null,
      about: undefined,
      fileName,
      contentType,
      propertyId,
    };

    const createdContent = await createContentWithFile(contentToCreate, file, propertyId);

    return createdContent;
  }, [contentType, propertyId]);

  const onFileUploadSuccess = React.useCallback((_: UploadModel, content: Content) => {
    if (onCreateSuccess) {
      onCreateSuccess(content);
    }
  }, [onCreateSuccess]);

  const imageSizeValidator = React.useCallback((file: File): FileError | null => {
    const imageFile = file as ImageFile;
    return (imageFile.width < imagesizeConstraints.width || imageFile.height < imagesizeConstraints.height) ? imageSizeError : null;
  }, [imagesizeConstraints, imageSizeError]);

  return (
    <FileUploadManager<Content>
      validator={imageSizeValidator}
      additionalErrorLabels={{ [imageSizeError.code]: `ATTENZIONE i seguenti file non sono stati caricati perché la loro dimensione è inferiore a ${imagesizeConstraints.width} x  ${imagesizeConstraints.height}` }}
      onFileUploadRequest={onInternalFileUploadRequest}
      onFileUploadSuccess={onFileUploadSuccess}
      onFileUploadAddedToQueue={onFileUploadAddedToQueue}
      onUploadQueueEnd={onUploadQueueEnd}
    />
  );
};

export default PropertyContentsUploadManager;
