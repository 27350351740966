import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError } from 'axios';
import { getContactAdditionalServices } from '../../../providers/api/contact/contactProvider';
import { AdditionalService } from '../../../providers/api/dtos';

export const CONTACT_ADDITIONAL_SERVICES_RID = '/contacts/:id/additional-services';

export default function useContactAdditionalServices(
  contactId?: string,
  options?: SWRConfiguration<AdditionalService[], AxiosError>,
): SWRResponse<AdditionalService[], AxiosError> {
  return useSWR(
    contactId ? [CONTACT_ADDITIONAL_SERVICES_RID, contactId] : null,
    () => getContactAdditionalServices(contactId!),
    options,
  );
}
