import { OptInType } from '../domain/types';

type SortableOptInLabel = {
  label: string;
  sortingOrder: number,
};

export const optInTypeLabels: { [key in OptInType]: SortableOptInLabel } = {
  [OptInType.SERVICE]: {
    label: 'Servizio',
    sortingOrder: 1,
  },
  [OptInType.MARKETING]: {
    label: 'Marketing',
    sortingOrder: 2,
  },
  [OptInType.THIRD_PARTIES]: {
    label: 'Trasferimento',
    sortingOrder: 3,
  },
};
