/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  Dropdown, ICON_HOME_OUTLINE, ICON_MAP_MARKER_OUTLINE, Message,
} from '@doveit/bricks';
import { formatSquareMeters } from '@doveit/hammer';
import useContactLeads from '../../../../hooks/use-contact-leads/useContactLeads';
import type { ViewContactActivitiesSummaryProps } from '../../ViewContactActivitiesSummary';
import {
  CONTACT_ACTIVITIES_SUMMARY_LEADS_FILTERS, CONTACT_LEADS_LOAD_ERROR, LEAD_KO_STATUSES, LEAD_STATUS_SORT_ORDER,
} from '../constants';
import ContactActivityCardSkeleton from '../../../../components/contact-activity-card/ContactActivityCard.skeleton';
import Divider from '../../../../../components/divider/Divider';
import * as styles from '../../ViewContactActivitiesSummary.style';
import { orderByStatus } from '../../utils';
import ContactActivityCard from '../../../../components/contact-activity-card/ContactActivityCard';
import { LeadStatusColor } from '../../../../../call-center/components/lead-status-badge/LeadStatusBadge.style';
import { formatPropertyType } from '../../../../../property/utils';
import { EditLeadAction } from '../../../../../lead/containers/edit-lead-action/EditLeadAction';
import ViewLeadEventRegistryAction from '../../../../../lead/containers/view-lead-event-registry-action/ViewLeadEventRegistryAction';
import CreateProspectWithAppointmentAction from '../../../../../prospect/containers/create-prospect-with-appointment-action/CreateProspectWithAppointmentAction';
import RestoreLeadAction from '../../../../../lead/containers/restore-lead-action/RestoreLeadAction';
import ArchiveLeadAction from '../../../../../lead/containers/archive-lead-action/ArchiveLeadAction';
import { leadStatusLabels } from '../../../../../lead/constants';

const ContactLeadsSummaryGroup: React.FC<ViewContactActivitiesSummaryProps> = ({
  contactId,
}) => {
  const { data: leads, error: leadsError, mutate } = useContactLeads(contactId, CONTACT_ACTIVITIES_SUMMARY_LEADS_FILTERS);

  const mutateLeads = React.useCallback(() => {
    mutate();
  }, [mutate]);

  if (leadsError) {
    return (
      <Message
        type="critical"
        message={CONTACT_LEADS_LOAD_ERROR}
      />
    );
  }

  if (!leads) {
    return <ContactActivityCardSkeleton />;
  }

  if (leads.length === 0) {
    return null;
  }

  return (
    <div aria-label="Lista delle leads del contatto">
      <Divider>
        Lead
      </Divider>

      {/** Lead Summary List */}
      <div css={styles.activityGroupWrapper}>
        {orderByStatus(leads, LEAD_STATUS_SORT_ORDER).map((lead) => {
          const {
            id, status, createdAt, updatedAt, propertyType, propertySize, propertyAddress,
          } = lead;

          return (
            <ContactActivityCard
              key={id}
              type="lead"
              status={leadStatusLabels[status]}
              statusColor={LeadStatusColor[status]}
              createdAt={createdAt}
              updatedAt={updatedAt}
              features={[{
                icon: ICON_HOME_OUTLINE,
                title: 'Dettagli immobile',
                label: `${formatPropertyType(propertyType)}, ${formatSquareMeters(propertySize)}`,
              }, {
                icon: ICON_MAP_MARKER_OUTLINE,
                title: 'Indirizzo immobile',
                label: propertyAddress,
              }]}
              actions={[
                <EditLeadAction key={`edit-${lead.id}`} lead={lead} onSuccess={mutateLeads}>
                  {({ edit, disabled }) => (
                    <Dropdown.Option
                      label="Modifica"
                      disabled={disabled}
                      onClick={edit}
                    />
                  )}
                </EditLeadAction>,
                <CreateProspectWithAppointmentAction
                  key={`create-prospect-${lead.id}`}
                  lead={lead}
                  onSuccess={mutateLeads}
                >
                  {({ openModal, disabled }) => (
                    <Dropdown.Option
                      label="Crea valutazione con appuntamento"
                      disabled={disabled}
                      onClick={openModal}
                    />
                  )}
                </CreateProspectWithAppointmentAction>,
                <ViewLeadEventRegistryAction key={`view-${lead.id}`} lead={lead}>
                  {({ disabled, view }) => (
                    <Dropdown.Option
                      label="Visualizza registro attività"
                      disabled={disabled}
                      onClick={view}
                    />
                  )}
                </ViewLeadEventRegistryAction>,
                LEAD_KO_STATUSES.includes(status)
                  ? (
                    <RestoreLeadAction
                      key={`restore-${lead.id}`}
                      lead={lead}
                      onSuccess={mutateLeads}
                    >
                      {({ disabled, openRestoreLeadModal }) => (
                        <Dropdown.Option
                          label="Ripristina"
                          disabled={disabled}
                          onClick={openRestoreLeadModal}
                        />
                      )}
                    </RestoreLeadAction>
                  )
                  : (
                    <ArchiveLeadAction
                      key={`archive-${lead.id}`}
                      lead={lead}
                      onSuccess={mutateLeads}
                    >
                      {({ disabled, openArchiveModal }) => (
                        <Dropdown.Option
                          label="Archivia"
                          disabled={disabled}
                          onClick={openArchiveModal}
                        />
                      )}
                    </ArchiveLeadAction>
                  ),
              ]}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ContactLeadsSummaryGroup;
