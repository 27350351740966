import { CommissionSchemaType } from '../../../domain/types';
import { Agent, Commissions } from '../../../providers/api/dtos';
import type { EditCommissionsFormModel } from '../../components/edit-commissions-form/EditCommissionsForm';
import { getCommissionSchema } from '../../utils/getCommissionSchema';

export const editCommissionFormModelToAgent = (commissionFormModel: EditCommissionsFormModel, existingAgent: Agent): Agent => {
  const commissions: Commissions = {
    fixedSalary: Number.parseInt(commissionFormModel.fixedSalary || '0', 10),
    managementCost: Number.parseInt(commissionFormModel.managementCost || '0', 10) / 100,
    fee: Number.parseInt(commissionFormModel.fee || '0', 10),
    photoPrice: {
      personal: Number.parseInt(commissionFormModel.photoPricePersonal || '0', 10),
      dove: Number.parseInt(commissionFormModel.photoPriceDove || '0', 10),
    },
    commissionSplit: {
      personal: Number.parseInt(commissionFormModel.commissionSplitPersonal || '0', 10) / 100,
      dove: Number.parseInt(commissionFormModel.commissionSplitDove || '0', 10) / 100,
    },
    overCommissionSplit: {
      personal: Number.parseInt(commissionFormModel.overCommissionSplitPersonal || '0', 10) / 100,
      dove: Number.parseInt(commissionFormModel.overCommissionSplitDove || '0', 10) / 100,
    },
    overCommissionThreshold: Number.parseInt(commissionFormModel.overCommissionThreshold || '0', 10),
    notes: commissionFormModel.notes,
  };

  return {
    ...existingAgent,
    commissions,
  };
};

export const commissionsToEditCommissionsFormModel = (commissions: Commissions, schema?: CommissionSchemaType): EditCommissionsFormModel => {
  const commissionSchema = schema || getCommissionSchema(commissions);

  return {
    commissionSplitPersonal: (commissions.commissionSplit.personal * 100).toFixed(0),
    commissionSplitDove: (commissions.commissionSplit.dove * 100).toFixed(0),
    photoPricePersonal: commissions.photoPrice.personal.toString(),
    photoPriceDove: commissions.photoPrice.dove.toString(),
    overCommissionThreshold: commissions.overCommissionThreshold.toString(),
    overCommissionSplitPersonal: (commissions.overCommissionSplit.personal * 100).toFixed(0),
    overCommissionSplitDove: (commissions.overCommissionSplit.dove * 100).toFixed(0),
    fixedSalary: commissions.fixedSalary.toString(),
    fee: commissions.fee.toString(),
    managementCost: (commissions.managementCost * 100).toFixed(0),
    schema: commissionSchema,
    notes: commissions.notes,
  };
};
