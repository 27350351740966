import { capitalizeWords } from '@doveit/hammer';
import { Agent, Name } from '../../providers/api/dtos';

export const getFormattedName = (
  name: Name,
  reversed: boolean = false,
) => (!reversed
  ? capitalizeWords(`${name.name} ${name.surname}`)
  : capitalizeWords(`${name.surname} ${name.name}`));

export const getAgentName = (
  agent: Agent | undefined,
  reversed: boolean = false,
): string | undefined => (agent ? getFormattedName(agent.name, reversed) : undefined);

export const getAgentRealName = (
  agent: Agent,
  reversed: boolean = false,
): string | undefined => (agent.realName ? getFormattedName(agent.realName, reversed) : undefined);
