import { endOfDay, isBefore } from 'date-fns';
import { InvoiceStatus } from '../../../domain/types';
import { Invoice } from '../../../providers/api/dtos';

export const computeTargetInvoiceStatus = (currentInvoiceStatus: InvoiceStatus, collectedAt?: string, allowStatusChange: boolean = false): Invoice['status'] => {
  if (allowStatusChange) {
    if (currentInvoiceStatus === InvoiceStatus.TODO) {
      return InvoiceStatus.TO_COLLECT;
    }

    if (currentInvoiceStatus === InvoiceStatus.TO_COLLECT && collectedAt) {
      return InvoiceStatus.COLLECTED;
    }
  }

  return currentInvoiceStatus;
};

interface Labels {
  formSubmitLabel: string,
  successNotification: string,
  errorNotification: string,
}

export const computeLabels = (invoiceStatus: Invoice['status'], allowStatusChange: boolean = false): Labels => {
  if (allowStatusChange && invoiceStatus === InvoiceStatus.TODO) {
    return {
      formSubmitLabel: 'Emetti proforma',
      successNotification: 'Il proforma è stato emesso con successo',
      errorNotification: 'Non è stato possibile emettere il proforma',
    };
  }

  return {
    formSubmitLabel: 'Modifica',
    successNotification: 'I dati del pagamento sono stati modificati con successo',
    errorNotification: 'Non è stato possibile modificare i dati del pagamento',
  };
};

export const computeActionLabels = (action: 'edit' | 'manage', invoiceStatus: Invoice['status']): {
  actionLabel: string,
  submitActionLabel: string,
} => {
  if (action === 'edit') {
    return {
      actionLabel: 'Modifica fattura',
      submitActionLabel: 'Modifica',
    };
  }

  if (invoiceStatus === InvoiceStatus.TODO) {
    return {
      actionLabel: 'Emetti proforma',
      submitActionLabel: 'Emetti',
    };
  }

  if (invoiceStatus === InvoiceStatus.TO_COLLECT) {
    return {
      actionLabel: 'Incassa fattura',
      submitActionLabel: 'Incassa',
    };
  }

  return {
    actionLabel: '',
    submitActionLabel: '',
  };
};

export const isForecastedDateInThePast = (forecastedDate: string): boolean => {
  const NOW = new Date();
  const FORECASTED_DATE = endOfDay(new Date(forecastedDate));

  return isBefore(FORECASTED_DATE, NOW);
};
