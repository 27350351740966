import React from 'react';
import { Form, FormProps } from '@doveit/bricks';
import { AddressSuggestion, GeoCodeResult } from '../../../providers/geo/dtos';
import { isKeyOf } from '../../../utils/object/object';
import { INPUT_PLACEHOLDERS } from '../../../constants';
import AddressSuggestionAutoComplete from '../../../components/address-suggestion-autocomplete/AddressSuggestionAutoComplete';
import { geocodeByAddress } from '../../../providers/geo/geocode/geocodeProvider';

export interface PropertyGeoFormModel {
  administrativeAreaLevelTwo: string,
  locality: string,
  postalCode: string,
  route: string,
  streetNumber: string,
  unit: string,
  stairwell: string,
  normalizedAddress?: AddressSuggestion,
}

export interface PropertyGeoFormProps extends React.AriaAttributes {
  initialValues?: Partial<PropertyGeoFormModel>,
  loading?: FormProps<PropertyGeoFormModel>['loading'],
  disabled?: FormProps<PropertyGeoFormModel>['disabled'],
  innerRef?: FormProps<PropertyGeoFormModel>['innerRef'],
  validationSchema?: FormProps<PropertyGeoFormModel>['validationSchema'],
  onSubmit: FormProps<PropertyGeoFormModel>['onSubmit'],
}

export const DEFAULT_INITIAL_VALUES: PropertyGeoFormModel = {
  administrativeAreaLevelTwo: '',
  locality: '',
  postalCode: '',
  route: '',
  stairwell: '',
  streetNumber: '',
  unit: '',
};

export function getNormalizedAddress(fields: Pick<GeoCodeResult, 'route' | 'streetNumber' | 'postalCode' | 'locality'>) {
  return [fields.route, fields.streetNumber, fields.postalCode, fields.locality].filter((i) => i).join(', ');
}

const PropertyGeoForm: React.FC<PropertyGeoFormProps> = (props) => {
  const { initialValues = {}, ...rest } = props;

  const mergedInitialValues: PropertyGeoFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      enableReinitialize
      {...rest}
    >
      {({ setFieldValue }) => {
        const onNormalizedAddressChanged = async (suggestion: AddressSuggestion | null) => {
          if (!suggestion) return;

          const { description } = suggestion;

          const [geoCode] = await geocodeByAddress(description);

          Object.keys(DEFAULT_INITIAL_VALUES).forEach((field) => {
            if (isKeyOf(geoCode, field)) {
              setFieldValue(field, geoCode[field] || '');
            }
          });
        };

        return (
          <Form.Fieldset legend="Dati geografici">
            <Form.Item>
              <AddressSuggestionAutoComplete
                name="normalizedAddress"
                label="Indirizzo"
                onSuggestionSelected={onNormalizedAddressChanged}
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_ADDRESS}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.Input
                name="administrativeAreaLevelTwo"
                label="Provincia"
                aria-label="Campo per inserire la provincia dell'immobile"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_PROVINCE}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.Input
                name="locality"
                label="Comune"
                aria-label="Campo per inserire il Comune dell'immobile"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_LOCALITY}
                required
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.Input
                name="postalCode"
                label="CAP"
                aria-label="Campo per inserire il CAP dell'immobile"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_POSTAL_CODE}
              />
            </Form.Item>

            <Form.Item size={{ MD: 2 / 3 }}>
              <Form.Input
                name="route"
                label="Strada"
                aria-label="Campo per inserire la strada dell'immobile"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_ROUTE}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.Input
                name="streetNumber"
                label="Numero civico"
                aria-label="Campo per inserire il civico dell'immobile"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_STREET_NUMBER}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.Input
                name="unit"
                label="Interno"
                aria-label="Campo per inserire l'interno"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_BLOCK}
              />
            </Form.Item>

            <Form.Item size={{ MD: 1 / 3 }}>
              <Form.Input
                name="stairwell"
                label="Scala"
                aria-label="Campo per inserire la scala"
                placeholder={INPUT_PLACEHOLDERS.PROPERTY_STAIRWELL}
              />
            </Form.Item>
          </Form.Fieldset>
        );
      }}
    </Form>
  );
};

export default PropertyGeoForm;
