import {
  Form, FormProps, FormSubmitType,
} from '@doveit/bricks';
import React from 'react';
import { AddressSuggestion } from '../../../../../providers/geo/dtos';
import AddressSuggestionAutoComplete from '../../../../../components/address-suggestion-autocomplete/AddressSuggestionAutoComplete';
import { INPUT_PLACEHOLDERS } from '../../../../../constants';

export interface AssignmentWizardSellerFormModel {
  companyName: string,
  companyAddress?: AddressSuggestion,
  companyFiscalCode: string,
}

export interface AssignmentWizardSellerFormProps extends React.PropsWithChildren {
  initialValues?: Partial<AssignmentWizardSellerFormModel>,
  loading?: FormProps<AssignmentWizardSellerFormModel>['loading'],
  disabled?: FormProps<AssignmentWizardSellerFormModel>['disabled'],
  onSubmit: FormSubmitType<AssignmentWizardSellerFormModel>,
  formRef: FormProps<AssignmentWizardSellerFormModel>['innerRef'],
}

const DEFAULT_INITIAL_VALUES: AssignmentWizardSellerFormModel = {
  companyName: '',
  companyFiscalCode: '',
};

const AssignmentWizardSellerForm: React.FC<AssignmentWizardSellerFormProps> = ({
  initialValues = {},
  formRef,
  ...rest
}) => {
  const mergedInitialValues: AssignmentWizardSellerFormModel = React.useMemo(() => ({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  }), [initialValues]);

  return (
    <Form
      initialValues={mergedInitialValues}
      innerRef={formRef}
      enableReinitialize
      {...rest}
    >
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="companyName"
          label="Ragione sociale"
          aria-label="Campo per inserire la ragione sociale"
          required
          placeholder={INPUT_PLACEHOLDERS.COMPANY_NAME}
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <Form.Input
          name="companyFiscalCode"
          label="Partita IVA"
          aria-label="Campo per inserire la partita IVA"
          required
          placeholder={INPUT_PLACEHOLDERS.COMPANY_VAT_NUMBER}
        />
      </Form.Item>
      <Form.Item size={{ MD: 1 / 2 }}>
        <AddressSuggestionAutoComplete
          name="companyAddress"
          label="Indirizzo sede"
          aria-label="Campo per inserire l'indirizzo della sede"
          placeholder={INPUT_PLACEHOLDERS.COMPANY_ADDRESS}
        />
      </Form.Item>
    </Form>
  );
};

export default AssignmentWizardSellerForm;
