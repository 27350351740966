import { isAfter } from 'date-fns';
import { Reminder } from '../../providers/api/dtos/reminder';
import { ResolvableReminderStatus } from '../../types';

export function isReminderExpired(reminder: Reminder) {
  return isAfter(Date.now(), new Date(reminder.startDate));
}

export function computeResolvableStatus(reminder: Reminder): ResolvableReminderStatus {
  if (reminder.resolved) return ResolvableReminderStatus.RESOLVED;
  if (isReminderExpired(reminder)) return ResolvableReminderStatus.EXPIRED;
  return ResolvableReminderStatus.ACTIVE;
}
