import { Content } from '../../../providers/api/dtos/content';
import { Media } from '../../../types';

export function toMedia(content: Content): Media {
  return {
    id: content.id!,
    fileName: content.fileName,
    url: content.url || '',
    about: content.about || null,
  };
}
