import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
  Action, Badge, HStack, ICON_MAGNIFY, Message, Spacing, Text,
} from '@doveit/bricks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Status } from '../../../domain/types';
import { PropertySection } from '../../types';
import { NavigationItem } from '../../../types';
import { SortOrder } from '../../../providers/pagination';
import usePagination from '../../../hooks/use-pagination/usePagination';
import { removeQueryParams } from '../../../utils/querystring/querystring';
import { useAgentFilter } from '../../../hooks/agent-filter/useAgentFilter';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import ViewPropertyCard from '../../containers/view-property-card/ViewPropertyCard';
import FilterByAgentsEmails from '../../../components/filter-by-agents-emails/FilterByAgentsEmails';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import useSearchProperties from '../../hooks/use-search-properties/useSearchProperties';
import getShortAgentName from '../../../utils/agent/getShortAgentName';
import getShortAgentNameFromEmail from '../../../utils/agent/getShortAgentNameFromEmail';

export interface BasePropertyProcessPageProps {
  navigationItems: NavigationItem[],
  allowedSections: PropertySection[],
  pageTitle: string,
}

const sectionToStatus: { [key in PropertySection]: Status } = {
  [PropertySection.DRAFT]: Status.DRAFT,
  [PropertySection.LIVE]: Status.LIVE,
  [PropertySection.OFFER]: Status.PROPOSTA,
  [PropertySection.RETIRED]: Status.RITIRATO,
  [PropertySection.SOLD]: Status.VENDUTO,
  [PropertySection.PREVIEW]: Status.ANTEPRIMA,
};

export const LOAD_ERROR_MESSAGE = 'Errore durante il caricamento dei dati';

const BasePropertyProcessPage: FunctionComponent<BasePropertyProcessPageProps> = ({
  navigationItems,
  allowedSections,
  pageTitle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const section: PropertySection = React.useMemo(() => params.section as PropertySection, [params]);

  const {
    userIsAgent, userIsContentEditor, userIsAdmin,
  } = useRBAC();

  if (!allowedSections.includes(section)) {
    navigate(navigationItems[0].path!);
  }
  const { isExplicit, agentEmails: agents, removeFilter } = useAgentFilter('page', getShortAgentName);
  const agentEmail = useMemo(() => agents.map((it) => it.email || ''), [agents]);

  const { page, goToPage } = usePagination('page');
  const { data: propertySearchItems, error: propertySearchItemsError } = useSearchProperties('*', {
    agentEmail,
    status: sectionToStatus[section],
  }, {
    page,
    size: 20,
    sort: {
      createdAt: SortOrder.DESC,
    },
  });

  const navigateTo = useCallback((path: string) => () => {
    navigate(`${path}?${removeQueryParams(location.search, 'page')}`);
  }, [navigate, location]);

  const goToSearchPage = useCallback(() => {
    navigate('/properties/search');
  }, [navigate]);

  const tabItems = useMemo(() => navigationItems.map((item) => ({
    id: item.id,
    label: item.label,
    active: location.pathname === item.path,
    onClick: navigateTo(item.path!),
  })), [location.pathname, navigateTo, navigationItems]);

  const showDocumentsStatus = useMemo(() => section === PropertySection.DRAFT && (userIsAgent || userIsContentEditor || userIsAdmin),
    [section, userIsAgent, userIsContentEditor, userIsAdmin]);

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content
        title={pageTitle}
        headerSlot={(
          <HStack>
            <Action
              iconLeft={{ path: ICON_MAGNIFY }}
              emphasis="high"
              label="Cerca"
              onClick={goToSearchPage}
            />
            <FilterByAgentsEmails title="Filtra immobili per agente" />
          </HStack>
        )}
      >
        <GenericPageLayout.Tabs items={tabItems} />
        <GenericPageLayout.InnerContent>
          {isExplicit && agents.length > 0 && (
            <Spacing margin={[0, 0, 400]}>
              <Text.H4>Immobili filtrati per agente:</Text.H4>
              <Spacing margin={[100, 0, 0]}>
                <HStack>
                  {agents.map((agentFilter) => (
                    <Badge
                      key={agentFilter.email}
                      color="primary"
                      label={agentFilter.name || getShortAgentNameFromEmail(agentFilter.email)}
                      onRemove={removeFilter(agentFilter)}
                    />
                  ))}
                </HStack>
              </Spacing>
            </Spacing>
          )}
          {propertySearchItemsError && (
            <Spacing margin={[200, 0, 0]}>
              <Message
                type="critical"
                message={LOAD_ERROR_MESSAGE}
              />
            </Spacing>
          )}

          {propertySearchItems && (
            <Spacing margin={[200, 0, 0]}>
              <PaginatedList
                {...propertySearchItems}
                goToPage={goToPage}
              >
                {propertySearchItems.content.map((propertySearchItem) => (
                  <ViewPropertyCard
                    key={propertySearchItem.id}
                    propertySearchItem={propertySearchItem}
                    showAgentName
                    showPublishedOn
                    showActiveOfferBadge={section === PropertySection.LIVE || section === PropertySection.OFFER}
                    showInvoiceStatusBadge={section === PropertySection.SOLD}
                    showDocumentsStatus={showDocumentsStatus}
                    showCreateIntentAction
                    showViewDetailsAction
                  />
                ))}
              </PaginatedList>
            </Spacing>
          )}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export const PropertyProcessPage: FunctionComponent = () => {
  const navigationItems = useMemo(() => [{
    id: 'property-live',
    label: 'Live',
    path: '/properties/process/live',
  },
  {
    id: 'property-preview',
    label: 'Anteprima',
    path: '/properties/process/preview',
  },
  {
    id: 'property-draft',
    label: 'Bozza',
    path: '/properties/process/draft',
  }], []);

  const allowedSections = useMemo(() => [PropertySection.LIVE, PropertySection.DRAFT, PropertySection.PREVIEW], []);

  return (
    <BasePropertyProcessPage
      navigationItems={navigationItems}
      allowedSections={allowedSections}
      pageTitle="Attività immobili"
    />
  );
};

export const PropertyBrowsePage: FunctionComponent = () => {
  const navigationItems = useMemo(() => [{
    id: 'property-offer',
    label: 'Trattative',
    path: '/properties/browse/offer',
  },
  {
    id: 'property-sold',
    label: 'Venduti',
    path: '/properties/browse/sold',
  },
  {
    id: 'property-retired',
    label: 'Ritirati',
    path: '/properties/browse/retired',
  }], []);

  const allowedSections = useMemo(() => [PropertySection.OFFER, PropertySection.SOLD, PropertySection.RETIRED], []);

  return (
    <BasePropertyProcessPage
      navigationItems={navigationItems}
      allowedSections={allowedSections}
      pageTitle="Consultazione immobili"
    />
  );
};

export default BasePropertyProcessPage;
