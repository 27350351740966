import useSWR from 'swr';
import useGoogleMapsAPI from '../use-google-maps-api/useGoogleMapsAPI';

export const ADDRESS_SUGGESTIONS_RID = '/address-suggestions';

export default function useAddressSuggestions(query?: string) {
  const { getAddressSuggestions } = useGoogleMapsAPI();

  return useSWR(query ? [ADDRESS_SUGGESTIONS_RID, query] : null, () => getAddressSuggestions(query!));
}
