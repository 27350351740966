import { ProspectApprovalStatus } from '../../domain/types';
import { Agent, Prospect } from '../../providers/api/dtos';

export function needsSupervisorApproval(prospect: Prospect, agent: Agent) {
  if (agent.hasLicense && !agent.needsSupervisor) {
    return false;
  }

  if (!!prospect.approval && prospect.approval.status === ProspectApprovalStatus.APPROVED) {
    return false;
  }

  return true;
}
