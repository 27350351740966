/* eslint-disable react/no-unknown-property */
import React from 'react';
import {
  BreakpointQueryName, Grid, HStack, Spacing, Stack,
} from '@doveit/bricks';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import GenericPageLayout from '../generic-page-layout/GenericPageLayout';
import { GenericPageLayoutContentProps } from '../generic-page-layout/content/GenericPageLayoutContent';
import * as styles from './RightColumnPageLayout.style';

export type RightColumnPageLayoutProps = Pick<GenericPageLayoutContentProps, 'title'> & React.AriaAttributes & React.PropsWithChildren<{
  topContent?: React.ReactNode,
  primarySide: React.ReactNode,
  secondarySide?: React.ReactNode,
  children: React.ReactNode,
  actions?: React.ReactNode,
  footer?: React.ReactNode,
}>;

const RightColumnPageLayout: React.FC<RightColumnPageLayoutProps> = ({
  title,
  actions = [],
  topContent,
  primarySide,
  secondarySide,
  footer,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const isTabletOrBigger = useMediaQuery(theme.breakpoints.up(BreakpointQueryName.XL));

  return (
    <GenericPageLayout
      aria-label="Pagina dettaglio"
      {...rest}
    >
      <GenericPageLayout.Bar>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
          gap={200}
        >
          <div css={styles.headerTitle}>
            {title}
          </div>
          {actions && (
            <div css={styles.headerActions}>
              <HStack>
                {actions}
              </HStack>
            </div>
          )}
        </HStack>
      </GenericPageLayout.Bar>
      <GenericPageLayout.Content>
        {!isTabletOrBigger && (
          <Spacing margin={[0, 0, 300]}>
            {topContent}
          </Spacing>
        )}
        <Grid gutter={200}>
          <Grid.Unit size={{ XL: 2 / 3 }}>
            <Stack gap={300}>
              {isTabletOrBigger && topContent}
              {!isTabletOrBigger && primarySide}
              {children}
            </Stack>
          </Grid.Unit>

          <Grid.Unit size={{ XL: 1 / 3 }}>
            <Stack gap={300}>
              {isTabletOrBigger && primarySide}
              {secondarySide}
            </Stack>
          </Grid.Unit>
        </Grid>
      </GenericPageLayout.Content>
      {footer && (
        <GenericPageLayout.Bar>
          {footer}
        </GenericPageLayout.Bar>
      )}
    </GenericPageLayout>
  );
};

export default RightColumnPageLayout;
