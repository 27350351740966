import {
  Icon, Grid, Spacing, Link, Message, ICON_FILE_DOCUMENT_MULTIPLE_OUTLINE, HStack, CardSkeleton, Card, ICON_ALERT_CIRCLE_OUTLINE, Text, FontWeight, DetailItemList,
} from '@doveit/bricks';
import React, { FunctionComponent, useCallback } from 'react';
import { IntentStatus, OfferStatus, PropertyDocumentTypeValue } from '../../../domain/types';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { Document } from '../../../providers/api/dtos';
import { ThemeIcons } from '../../../theme/icons';
import { usePropertyDocuments } from '../../hooks/use-property-documents/usePropertyDocuments';
import {
  MANDATORY_OFFER_DOCUMENT_TYPES, MANDATORY_PROPERTY_DOCUMENT_TYPES, OPTIONAL_PROPERTY_DOCUMENT_TYPES, YOUDOMUS_PROPERTY_DOCUMENT_TYPES,
} from '../../../document/constants';
import { DocumentReferenceType } from '../../../domain/types/documentReferenceType';
import ManageDocumentAction from '../../../document/containers/manage-document-action/ManageDocumentAction';
import { IntentsProviderFilters } from '../../../providers/api/intent/intentProvider';
import useIntents from '../../../intent/hooks/use-intents/useIntents';
import useOffersByIntentId from '../../../offer/hooks/use-offers-by-intent-id/useOffersByIntentId';
import { useOfferDocuments } from '../../../offer/hooks/use-offer-documents/useOfferDocuments';
import { sortDocumentsByLabel } from '../../../utils';
import * as styles from './PropertyDocumentsWidget.style';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';

export interface PropertyDocumentsWidgetProps {
  propertyId: number,
  onDocumentUpserted?: (document: Document) => void,
}

export const PROPERTY_DOCUMENTS_LOAD_ERROR_MESSAGE = "Non è stato possibile caricare i documenti dell'immobile.";
export const PROPERTY_DOCUMENT_ADD_ERROR_MESSAGE = 'Non è possibile aggiungere il documento.';

const PropertyDocumentsWidget: FunctionComponent<PropertyDocumentsWidgetProps> = ({
  propertyId,
  onDocumentUpserted,
}) => {
  const { userIsContentEditor, userIsAdmin } = useRBAC();
  const { data: propertyAgent } = useAgentByPropertyId(propertyId);
  const isPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(propertyAgent?.id);

  const canShow = React.useMemo(() => userIsAdmin || userIsContentEditor || isPropertyAgentOrManager, [isPropertyAgentOrManager, userIsAdmin, userIsContentEditor]);

  const {
    data: propertyDocuments = [],
    isLoading: arePropertyDocumentsLoading,
    error: propertyDocumentsError,
    mutate: mutatePropertyDocuments,
  } = usePropertyDocuments(propertyId);

  const intentFilters: IntentsProviderFilters = React.useMemo(() => ({
    propertyId,
    status: IntentStatus.BOUGHT,
  }), [propertyId]);

  const { data: paginatedBoughtIntents } = useIntents(intentFilters);
  const { data: confirmedOffers = [] } = useOffersByIntentId(paginatedBoughtIntents?.content?.[0]?.id, OfferStatus.CONFIRMED);

  const hasAConfirmedOffer = React.useMemo(() => confirmedOffers.length > 0, [confirmedOffers.length]);

  const { data: offerDocuments = [], mutate: mutateOfferDocuments } = useOfferDocuments(hasAConfirmedOffer ? confirmedOffers[0].id : undefined);

  const onUpsertPropertyDocument = useCallback(async (document: Document) => {
    await mutatePropertyDocuments();

    onDocumentUpserted?.(document);
  }, [mutatePropertyDocuments, onDocumentUpserted]);

  const onUpsertOfferDocument = useCallback(async (_: Document) => {
    await mutateOfferDocuments();
  }, [mutateOfferDocuments]);

  if (!canShow) {
    return null;
  }

  if (propertyDocumentsError) {
    return (
      <Card aria-label="Errore nel caricamento dei documenti dell'immobile">
        <Card.Header
          title="Documenti"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          color="critical"
        />
        <Card.Content>
          <Message
            message={PROPERTY_DOCUMENTS_LOAD_ERROR_MESSAGE}
          />
        </Card.Content>
      </Card>
    );
  }

  if (arePropertyDocumentsLoading) {
    return <CardSkeleton aria-label="Documenti immobile in caricamento" />;
  }

  return (
    <Card aria-label="Sezione documenti dell'immobile">
      <Card.Header
        title="Documenti"
        icon={{ path: ICON_FILE_DOCUMENT_MULTIPLE_OUTLINE }}
      />
      <Card.Content>
        <Grid gutter={150}>
          <Grid.Unit>
            <Text.BodySmall fontWeight={FontWeight.MEDIUM} transform="uppercase">Obbligatori</Text.BodySmall>
            <Spacing margin={[100, 0, 0]}>
              <DetailItemList
                columns={hasAConfirmedOffer ? 2 : 1}
                withoutDividers
              >
                {MANDATORY_PROPERTY_DOCUMENT_TYPES
                  .sort(sortDocumentsByLabel)
                  .map((type) => {
                    const existingDocument = propertyDocuments.find((document) => document.type === type);
                    return (
                      <DetailItemList.Item
                        key={type}
                        aria-label={`document-row-${type}`}
                      >
                        <ManageDocumentAction
                          referenceId={existingDocument?.reference.id ?? propertyId.toString()}
                          referenceType={existingDocument?.reference.type ?? DocumentReferenceType.PROPERTY}
                          referenceContext={DocumentReferenceType.PROPERTY}
                          documentType={type}
                          document={existingDocument}
                          onCreate={onUpsertPropertyDocument}
                          onUpdate={onUpsertPropertyDocument}
                        />
                      </DetailItemList.Item>
                    );
                  })}
                {hasAConfirmedOffer && MANDATORY_OFFER_DOCUMENT_TYPES
                  .sort(sortDocumentsByLabel)
                  .map((type) => (
                    <DetailItemList.Item
                      key={type}
                      aria-label={`document-row-${type}`}
                    >
                      <ManageDocumentAction
                        key={type}
                        referenceId={confirmedOffers[0].id!.toString()}
                        referenceType={DocumentReferenceType.OFFER}
                        documentType={type}
                        document={offerDocuments.find((document) => document.type === type)}
                        onCreate={onUpsertOfferDocument}
                        onUpdate={onUpsertOfferDocument}
                      />
                    </DetailItemList.Item>
                  ))}
              </DetailItemList>
            </Spacing>
          </Grid.Unit>
          <Grid.Unit>
            <Text.BodySmall fontWeight={FontWeight.MEDIUM} transform="uppercase">Facoltativi</Text.BodySmall>
            <Spacing margin={[100, 0, 0]}>
              <DetailItemList
                columns={hasAConfirmedOffer ? 2 : 3}
                withoutDividers
              >
                {OPTIONAL_PROPERTY_DOCUMENT_TYPES
                  .sort(sortDocumentsByLabel)
                  .map((type) => {
                    const existingDocument = propertyDocuments.find((document) => document.type === type);

                    return (
                      <DetailItemList.Item
                        key={type}
                        aria-label={`document-row-${type}`}
                      >
                        <HStack>
                          <ManageDocumentAction
                            referenceId={existingDocument?.reference.id ?? propertyId.toString()}
                            referenceType={existingDocument?.reference.type ?? DocumentReferenceType.PROPERTY}
                            referenceContext={DocumentReferenceType.PROPERTY}
                            documentType={type}
                            document={existingDocument}
                            onCreate={onUpsertPropertyDocument}
                            onUpdate={onUpsertPropertyDocument}
                          />
                          {!existingDocument?.files.length && YOUDOMUS_PROPERTY_DOCUMENT_TYPES.includes(type) && (
                            <Link
                              href={type === PropertyDocumentTypeValue.CADASTRAL_SURVEY ? 'https://app.youdomus.it/Prodotto/Richiedi/342' : 'https://app.youdomus.it/Prodotto/Richiedi/482'}
                              target="_blank"
                              aria-label="document-row-link"
                              css={styles.youdomusIcon}
                            >
                              <Icon
                                path={ThemeIcons.YOUDOMUS}
                                size={20}
                              />
                            </Link>
                          )}
                        </HStack>
                      </DetailItemList.Item>
                    );
                  })}
              </DetailItemList>
            </Spacing>
          </Grid.Unit>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default PropertyDocumentsWidget;
