import {
  ThemedFunctor,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const base: ThemedFunctor = ({
  $height,
}) => css`
  height: ${$height};
  overflow-y: auto;
`;
