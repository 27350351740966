import * as Yup from 'yup';

const fieldValidationSchema = (limit: number) => Yup
  .string()
  .max(limit, `Non deve superare i ${limit} caratteri`);

export const PROPERTY_ABSTRACT_MAX_LENGTH = 1000;
export const PROPERTY_COMPOSITION_MAX_LENGTH = 1000;
export const PROPERTY_DESCRIPTION_MAX_LENGTH = 3000;

const validationSchema = Yup.object({
  abstract: fieldValidationSchema(PROPERTY_ABSTRACT_MAX_LENGTH),
  composition: fieldValidationSchema(PROPERTY_COMPOSITION_MAX_LENGTH),
  description: fieldValidationSchema(PROPERTY_DESCRIPTION_MAX_LENGTH),
});

export default validationSchema;
