import React from 'react';
import { Grid, Message, UserPreviewSkeleton } from '@doveit/bricks';
import { useNavigate } from 'react-router-dom';
import { Agent } from '../../../providers/api/dtos';
import AgentPreview from '../../components/agent-preview/AgentPreview';
import { toAgentPreviewProps } from '../../mappers/toAgentPreviewProps';
import useAgents from '../../../hooks/use-agents/useAgents';
import { AgentsProviderFilters } from '../../../providers/api/agent/agentProvider';
import { sortAgents } from '../../utils/sortAgents';
import { formatAgentDetails } from '../../../utils/agent/formatAgentDetails';

export interface ViewAgentsByFiltersProps {
  filters: AgentsProviderFilters,
}

export const LOAD_FILTERED_AGENTS_ERROR_MESSAGE = 'Non è stato possibile caricare le informazioni degli agenti';

const ViewAgentsByFilters: React.FC<ViewAgentsByFiltersProps> = ({
  filters,
}) => {
  const navigate = useNavigate();
  const { data: agents, isLoading: isAgentsLoading, error: agentsError } = useAgents(filters);
  const sortedAgents = React.useMemo(
    () => (agents?.content ? sortAgents(agents.content) : undefined),
    [agents],
  );

  const goToAgentDetailPage = React.useCallback((id: NonNullable<Agent['id']>) => () => {
    navigate(`/admin/agents/${id}`);
  }, [navigate]);

  if (agentsError) {
    return (
      <Message
        type="critical"
        message={LOAD_FILTERED_AGENTS_ERROR_MESSAGE}
      />
    );
  }

  if (isAgentsLoading) {
    return <UserPreviewSkeleton />;
  }

  return sortedAgents! && (
    <Grid
      aria-label="Agenti estratti"
      gutter={75}
    >
      {sortedAgents.map((agent) => (
        <Grid.Unit key={agent.id} size={{ MD: 1 / 2 }}>
          <AgentPreview
            {...toAgentPreviewProps(agent)}
            details={formatAgentDetails(agent.jobTitle, agent.coveredGeo)}
            onClick={goToAgentDetailPage(agent.id!)}
          />
        </Grid.Unit>
      ))}
    </Grid>
  );
};

export default ViewAgentsByFilters;
