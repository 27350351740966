export enum PageType {
  PROCESS_WELCOME = 'PROCESS_WELCOME',
  ADD = 'ADD',
  SEARCH = 'SEARCH',
  DETAIL = 'DETAIL',
  PROCESS_CONTACT = 'PROCESS_CONTACT',
}

export enum EventRegistryType {
  PHONE_CALL_OUTBOUND = 'PHONE_CALL_OUTBOUND',
  PHONE_CALL_INBOUND = 'PHONE_CALL_INBOUND',
  MOBILE_CALL_OUTBOUND = 'MOBILE_CALL_OUTBOUND',
  EMAIL_OUTBOUND = 'EMAIL_OUTBOUND',
}
