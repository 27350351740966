import { Message, useNotifications } from '@doveit/bricks';
import React, {
  FunctionComponent, useCallback, useMemo, useState,
} from 'react';
import useJobPosition from '../../../job-position/hooks/use-job-position/useJobPosition';
import { JobApplication } from '../../../providers/api/dtos';
import { updateJobApplication } from '../../../providers/api/job-application/jobApplicationProvider';
import JobApplicationForm, { JobApplicationFormModel } from '../../components/job-application-form/JobApplicationForm';
import { booleanStringToBool, boolToBooleanString } from '../../../utils/boolean-string/booleanString';

export interface EditJobApplicationProps {
  jobApplication: JobApplication,
  onSuccess?: (jobApplication: JobApplication) => void
}

export const JOB_POSITION_LOAD_ERROR = 'Non è stato possibile caricare il ruolo';
export const JOB_APPLICATION_EDIT_SUCCESS_MESSAGE = 'I dati della candidatura sono stati aggiornati correttamente';
export const JOB_APPLICATION_EDIT_ERROR_MESSAGE = 'Non è stato possibile aggiornare il contenuto della Candidatura';

const EditJobApplication: FunctionComponent<EditJobApplicationProps> = ({
  jobApplication,
  onSuccess,
}) => {
  const { data: jobPosition, error: jobPositionError } = useJobPosition(jobApplication?.jobPositionId);
  const { addSuccess, addError } = useNotifications();
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = useCallback(async (values: JobApplicationFormModel) => {
    setIsSaving(true);

    try {
      const mergedJobApplication: JobApplication = {
        ...jobApplication,
        jobPositionId: values.jobPosition!.id,
        province: values.province || undefined,
        experience: values.experience || undefined,
        agency: booleanStringToBool(values.agency),
        license: booleanStringToBool(values.license),
        message: values.message || undefined,
      };

      const savedJobApplication = await updateJobApplication(mergedJobApplication.id!, mergedJobApplication);

      setIsSaving(false);
      addSuccess(JOB_APPLICATION_EDIT_SUCCESS_MESSAGE);

      if (onSuccess) {
        onSuccess(savedJobApplication);
      }
    } catch {
      setIsSaving(false);
      addError(JOB_APPLICATION_EDIT_ERROR_MESSAGE);
    }
  }, [addError, addSuccess, jobApplication, onSuccess]);

  const initialValues: JobApplicationFormModel = useMemo(() => ({
    jobPosition,
    province: jobApplication.province,
    experience: jobApplication.experience,
    agency: boolToBooleanString(jobApplication.agency),
    license: boolToBooleanString(jobApplication.license),
    message: jobApplication.message,
  }), [jobApplication.agency, jobApplication.experience, jobApplication.license, jobApplication.message, jobApplication.province, jobPosition]);

  if (jobPositionError) {
    return (
      <Message
        type="critical"
        message={JOB_POSITION_LOAD_ERROR}
      />
    );
  }

  return (
    <JobApplicationForm
      initialValues={initialValues}
      loading={isSaving}
      onSubmit={onSubmit}
    />
  );
};

export default EditJobApplication;
