import {
  BreakpointQueryName,
  FontWeight, getTypography, ThemedFunctor, TypographyName,
} from '@doveit/bricks';
import { css } from 'styled-components';

export const basic: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.BODY_SMALL)}
  display: flex;
  color: ${theme.color.foreground.neutral.default.low};
  gap: ${theme.remSize[50]};
  flex-direction: column;

  strong {
    font-weight: ${FontWeight.MEDIUM};
  }

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    flex-direction: row;
    align-items: center;
    gap: ${theme.remSize[100]};
  }
`;
