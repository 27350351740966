import useSWR from 'swr';
import hash from 'object-hash';
import { Contact, Prospect } from '../../../providers/api/dtos';
import { ContactProspectsFilters, getContactProspects } from '../../../providers/api/contact/contactProvider';
import { SortParams } from '../../../providers/pagination';
import { SWRCustomResponse } from '../../../hooks/types';
import { useMatchMutate } from '../../../utils/swr/swr';

export const CONTACT_PROSPECTS_RID = '/contacts/:id/prospects';

export default function useContactProspects(
  contactId?: NonNullable<Contact['id']>,
  filters?: ContactProspectsFilters,
  sort?: SortParams,
): SWRCustomResponse<Prospect[]> {
  const matchMutate = useMatchMutate();
  const rid = [CONTACT_PROSPECTS_RID, contactId].join();
  const key = contactId ? [rid, hash({ ...filters, ...sort })] : null;

  const swrResponse = useSWR(key, () => getContactProspects(contactId!, filters, sort));

  return {
    ...swrResponse,
    mutate: () => matchMutate(rid),
  };
}
