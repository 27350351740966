import React from 'react';
import { Action, Portal, useModal } from '@doveit/bricks';
import { Agent } from '../../../providers/api/dtos';
import SimpleModal from '../../../components/simple-modal/SimpleModal';
import ViewAgentDescendants, { ViewAgentDescendantsProps } from '../view-agent-descendants/ViewAgentDescendants';

interface ChildFnProps {
  viewDescendants: VoidFunction,
}

export interface ViewAgentDescendantsActionProps extends ViewAgentDescendantsProps {
  modalTitle?: string,
  children?: (childFnProps: ChildFnProps) => React.ReactNode,
}

const ViewAgentDescendantsAction: React.FC<ViewAgentDescendantsActionProps> = ({
  children,
  modalTitle = 'Team',
  ...rest
}) => {
  const modal = useModal<Agent>();

  const viewDescendants = React.useCallback(() => {
    modal.open();
  }, [modal]);

  return (
    <>
      {children
        ? children({ viewDescendants })
        : (
          <Action
            aria-label="Visualizza le informazioni del team dell'agente"
            label="Vedi"
            size="S"
            onClick={viewDescendants}
          />
        )}
      <Portal>
        <SimpleModal
          {...modal}
          title={modalTitle}
        >
          <ViewAgentDescendants {...rest} />
        </SimpleModal>
      </Portal>
    </>
  );
};

export default ViewAgentDescendantsAction;
