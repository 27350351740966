import React, { FunctionComponent, useMemo } from 'react';
import { Spacing } from '@doveit/bricks';
import { isPast } from 'date-fns/isPast';
import { OptInType } from '../../domain/types';
import { optInTypeLabels } from '../../labels/optInTypeLabels';
import { OptIn } from '../../providers/api/dtos';
import OptInDetails from './OptInDetails';
import useRBAC from '../../hooks/use-rbac/useRBAC';

export interface OptInDetailsListProps {
  optIns: OptIn[],
  excludedOptInTypes?: OptInType[],
}

const OptInDetailsList: FunctionComponent<OptInDetailsListProps> = ({
  optIns,
  excludedOptInTypes,
}) => {
  const { userIsAdmin } = useRBAC();
  const optInDetails = useMemo(() => Object.entries(optInTypeLabels)
    .filter(([key, _]) => !(excludedOptInTypes?.includes(key as OptInType) ?? false))
    .map(([key, { label }]) => {
      const type = key as any as OptInType;
      const optIn = optIns.find((current) => current.type === type);
      const given = optIn !== undefined && !isPast(new Date(optIn.expiresAt!));

      return {
        ...(optIn || {}),
        label,
        type,
        given,
      };
    }), [optIns, excludedOptInTypes]);

  return (
    <>
      {optInDetails.map((optIn, i) => (
        <Spacing
          key={optIn.type}
          margin={[i === 0 ? 0 : 200, 0, 0, 0]}
          data-ref={`opt-ins-${optIn.type}`}
        >
          <OptInDetails
            {...optIn}
            canSeeDetails={userIsAdmin}
          />
        </Spacing>
      ))}
    </>
  );
};

export default OptInDetailsList;
