/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ICON_BELL_OUTLINE, Icon } from '@doveit/bricks';
import React from 'react';
import { mutate } from 'swr';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_APPLICATION_NOTIFICATIONS_PAGINATION } from '../../../providers/api/application-notification/applicationNotificationProvider';
import { getApplicationNotificationsKey } from '../../hooks/use-application-notifications/useApplicationNotifications';
import { useApplicationNotificationsContext } from '../application-notifications-provider/ApplicationNotificationsProvider';
import * as styles from './ApplicationNotificationsStatus.style';

export interface ApplicationNotificationsStatusProps {
  onClick?: VoidFunction,
}

export const MAX_NOTIFICATIONS_COUNT = 99;

const ApplicationNotificationsStatus: React.FC<ApplicationNotificationsStatusProps> = ({
  onClick,
}) => {
  const { newNotificationsCount } = useApplicationNotificationsContext();
  const navigate = useNavigate();
  const location = useLocation();

  const isNotificationsPage = React.useMemo(() => location.pathname === '/notifications', [location.pathname]);

  const goToNotificationsPage = React.useCallback(
    () => {
      if (isNotificationsPage) {
        mutate(getApplicationNotificationsKey(DEFAULT_APPLICATION_NOTIFICATIONS_PAGINATION));
      }

      if (onClick) {
        onClick();
      }

      navigate('/notifications');
    },
    [isNotificationsPage, navigate, onClick],
  );

  return (
    <div
      data-ref="application-notifications"
      css={styles.base}
      role="button"
      onClick={goToNotificationsPage}
    >
      <Icon path={ICON_BELL_OUTLINE} />
      {newNotificationsCount > 0 && (
        <div
          css={styles.count}
          data-ref="application-notifications-count"
        >
          {newNotificationsCount > MAX_NOTIFICATIONS_COUNT ? `${MAX_NOTIFICATIONS_COUNT}+` : newNotificationsCount}
        </div>
      )}
    </div>
  );
};

export default ApplicationNotificationsStatus;
