import {
  Message, Skeleton, Stack, UIConfigurator,
} from '@doveit/bricks';
import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import { Layout } from '../../../pdf/buyer-brochure/layout/Layout';
import { toBuyerBrochure } from '../mappers/toBuyerBrochure';
import useProperty from '../../../hooks/use-property/useProperty';
import { useAgentByPropertyId } from '../../../../hooks/use-agent/useAgent';
import useContentsByPropertyId from '../../../hooks/use-contents-by-property-id/useContentsByPropertyId';
import useRooms from '../../../hooks/use-rooms/useRooms';

export const LOADING_ERROR = 'Non è stato possibile caricare i dati';

export interface PrintBuyerBrochureProps {
  propertyId: number;
}

const PrintBuyerBrochure: React.FC<PrintBuyerBrochureProps> = ({
  propertyId,
}) => {
  const { data: property, error: propertyError } = useProperty(propertyId);
  const { data: agent, error: agentError } = useAgentByPropertyId(propertyId);
  const { data: contents, error: contentsError } = useContentsByPropertyId(propertyId);
  const { data: rooms, error: roomsError } = useRooms(propertyId);

  if (propertyError || agentError || contentsError || roomsError) {
    return (
      <Message
        type="critical"
        message={LOADING_ERROR}
      />
    );
  }

  if (!property || !agent || !contents || !rooms) {
    return (
      <Stack data-ref="skeleton">
        <Skeleton count={3} />
        <Skeleton width="50%" />
      </Stack>
    );
  }

  return (
    <PDFViewer
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <UIConfigurator>
        <Layout values={toBuyerBrochure(property, agent, contents, rooms)} />
      </UIConfigurator>
    </PDFViewer>
  );
};

export default PrintBuyerBrochure;
