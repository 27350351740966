import { EditMembershipContractFormModel } from '../../../agent/components/edit-membership-contract-from/EditMembershipContractForm';
import { Agency } from '../../../providers/api/dtos';

export const editMembershipContractFormModelToAgency = (
  existingAgency: Agency,
  formModel: EditMembershipContractFormModel,
): Agency => {
  const trimmedFormValues = Object.fromEntries(
    Object
      .entries(formModel)
      .map(([key, value]) => [key, value.trim()]),
  ) as EditMembershipContractFormModel;

  const {
    bic, companyName, iban, role, swift, vatNumber,
  } = trimmedFormValues;

  const bankAccount: Agency['bankAccount'] = (iban) ? {
    ...existingAgency.bankAccount,
    iban,
    bic: bic || undefined,
    swift: swift || undefined,
  } : undefined;

  const legal: Agency['legal'] = (companyName || role || vatNumber) ? {
    ...existingAgency.legal,
    companyName: companyName || undefined,
    role: role || undefined,
    vatNumber: vatNumber || undefined,
  } : undefined;

  return {
    ...existingAgency,
    bankAccount,
    legal,
  };
};
