import { Auth0Client, Auth0ClientOptions, createAuth0Client } from '@auth0/auth0-spa-js';

export const AUTH0_CLIENT_DEFAULT_OPTIONS: Auth0ClientOptions = {
  domain: process.env.REACT_APP_AUTH_DOMAIN || 'auth-dev.dove.it',
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID || 'vkmdrvfylivisrPLqfac2wkPODeo89cl',
  authorizationParams: {
    audience: process.env.REACT_APP_AUTH_AUDIENCE || 'https://braniac/',
    scope: 'openid profile email read:users read:current_user read:user_idp_tokens',
    connection: 'dove-it',
    prompt: 'login',
    redirect_uri: `${window.location.origin}/callback`,
  },
};

let client: Auth0Client;

export async function getClient(options?: Partial<Auth0ClientOptions>) {
  if (client) {
    return client;
  }

  client = await createAuth0Client({
    ...AUTH0_CLIENT_DEFAULT_OPTIONS,
    ...(options || {}),
  });

  return client;
}
