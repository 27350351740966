import {
  Card, Message, Badge, CardSkeleton, ICON_ALERT_CIRCLE_OUTLINE, HStack, Dropdown, ICON_TEXT_LONG, Text, ActionDropDown, Select, Stack,
} from '@doveit/bricks';
import React from 'react';
import useIsDevice from '../../../hooks/use-is-device/useIsDevice';
import { Property } from '../../../providers/api/dtos';
import useProperty from '../../hooks/use-property/useProperty';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { PropertyClass, Status } from '../../../domain/types';
import EditPropertyDescriptionsAction from '../edit-property-descriptions-action/EditPropertyDescriptionsAction';
import * as styles from './PropertyDescriptionsWidget.style';
import { useAgentByPropertyId } from '../../../hooks/use-agent/useAgent';
import { useCurrentAgentIsSameAgentOrManager } from '../../../agent/hooks/use-current-agent-is-same-agent-or-manager/useCurrentAgentIsSameAgentOrManager';

export const LOAD_PROPERTY_ERROR_MESSAGE = 'Non è stato possibile caricare i dati dell\'immobile.';
export const NO_DESCRIPTION_INFO_MESSAGE = "La descrizione dell'immobile è mancante.";
export const NO_ABSTRACT_INFO_MESSAGE = "L'abstract dell'immobile è mancante.";
export const NO_COMPOSITION_INFO_MESSAGE = "La composizione dell'immobile è mancante.";

export type PropertyDescriptionsWidgetProps = React.AriaAttributes & ({
  propertyId: NonNullable<Property['id']>,
});

const DESCRIPTION_TYPE_FILTERS = ['abstract', 'composition', 'description'] as const;
const LANGUAGE_FILTERS = ['it', 'en'] as const;

type DescriptionTypeFilter = typeof DESCRIPTION_TYPE_FILTERS[number];
type LanguageFilter = typeof LANGUAGE_FILTERS[number];

export const descriptionTypeFilterLabels: Record<DescriptionTypeFilter, string> = {
  abstract: 'Abstract',
  composition: 'Composizione',
  description: 'Descrizione',
};

const languageFilterLabels: Record<LanguageFilter, string> = {
  it: 'Italiano',
  en: 'Inglese',
};

const descriptionSectionInfoMessages: {
  [key in DescriptionTypeFilter]: string
} = {
  abstract: NO_ABSTRACT_INFO_MESSAGE,
  composition: NO_COMPOSITION_INFO_MESSAGE,
  description: NO_DESCRIPTION_INFO_MESSAGE,
};

const PropertyDescriptionsWidget: React.FC<PropertyDescriptionsWidgetProps> = ({
  propertyId,
}) => {
  const isMobile = useIsDevice('mobile');
  const { userIsAdmin, userIsContentEditor } = useRBAC();
  const [activeDescriptionFilter, setActiveDescriptionFilter] = React.useState<DescriptionTypeFilter>('abstract');
  const [activeLanguageFilter, setActiveLanguageFilter] = React.useState<LanguageFilter>('it');
  const { data: propertyAgent } = useAgentByPropertyId(propertyId);
  const isPropertyAgentOrManager = useCurrentAgentIsSameAgentOrManager(propertyAgent?.id);

  const {
    data: property,
    isLoading: isPropertyLoading,
    error: propertyError,
    mutate: mutateProperty,
  } = useProperty(propertyId);

  const enableEnglishDescriptions = React.useMemo(
    () => typeof property?.price !== 'undefined' && property?.price >= 500_000 && property?.classType === PropertyClass.LUXURY,
    [property],
  );

  const showUpdateAction = React.useMemo(
    () => userIsAdmin || userIsContentEditor || (isPropertyAgentOrManager && property?.status === Status.DRAFT),
    [isPropertyAgentOrManager, property?.status, userIsAdmin, userIsContentEditor],
  );

  const textToShow = React.useMemo(() => {
    if (activeLanguageFilter === 'it') {
      return property?.[activeDescriptionFilter];
    }
    return property?.[`${activeDescriptionFilter}En`];
  }, [property, activeDescriptionFilter, activeLanguageFilter]);

  const refreshProperty = React.useCallback(() => {
    mutateProperty();
  }, [mutateProperty]);

  const onDescriptionsFilterChange = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setActiveDescriptionFilter(e.target.value as DescriptionTypeFilter);
  }, []);

  if (isPropertyLoading) {
    return (
      <CardSkeleton aria-label="Dati descrizioni immobile in caricamento" />
    );
  }

  if (propertyError) {
    return (
      <Card aria-label="Errore nel caricamento delle descrizioni dell'immobile">
        <Card.Header
          title="Descrizioni"
          icon={{ path: ICON_ALERT_CIRCLE_OUTLINE }}
          color="critical"
        />
        <Card.Content>
          <Message message="Non è stato possibile caricare i dati delle descrizioni." />
        </Card.Content>
      </Card>
    );
  }

  return property && (
    <Card aria-label="Sezione descrizioni">
      <Card.Header
        title="Descrizioni"
        icon={{ path: ICON_TEXT_LONG }}
        primaryActions={[
          !isMobile && showUpdateAction && (
            <EditPropertyDescriptionsAction
              property={property}
              onSuccess={refreshProperty}
            />
          )]}
        secondaryActions={[
          isMobile && showUpdateAction && (
            <EditPropertyDescriptionsAction
              property={property}
              onSuccess={refreshProperty}
            >
              {({ disabled, openEditDescriptionsModal }) => (
                <Dropdown.Option
                  label="Modifica"
                  aria-label="Apri modale di modifica descrizioni"
                  onClick={openEditDescriptionsModal}
                  disabled={disabled}
                />
              )}
            </EditPropertyDescriptionsAction>
          ),
        ]}
      />
      <Card.Content>
        <Stack gap={150}>
          <HStack justifyContent="space-between">
            {!isMobile && (
              <HStack gap={75} aria-label="Filtri tipo descrizione (desktop)">
                {DESCRIPTION_TYPE_FILTERS.map((filter) => (
                  <Badge
                    key={filter}
                    label={descriptionTypeFilterLabels[filter]}
                    color={filter === activeDescriptionFilter ? 'primary' : 'neutral'}
                    emphasis="low"
                    onClick={filter !== activeDescriptionFilter ? () => setActiveDescriptionFilter(filter) : undefined}
                  />
                ))}
              </HStack>
            )}
            {isMobile && (
              <Select
                css={styles.descriptionsFilterSelect}
                aria-label="Filtri tipo descrizione (mobile)"
                options={DESCRIPTION_TYPE_FILTERS.map((filter) => ({
                  label: descriptionTypeFilterLabels[filter],
                  value: filter,
                }))}
                value={activeDescriptionFilter}
                size="S"
                onChange={onDescriptionsFilterChange}
              />
            )}
            {enableEnglishDescriptions && (
              <ActionDropDown
                aria-label="Filtri lingua"
                label={languageFilterLabels[activeLanguageFilter]}
                emphasis="low"
                size="S"
              >
                {LANGUAGE_FILTERS.map((filter) => (
                  <Dropdown.Option
                    key={filter}
                    label={languageFilterLabels[filter]}
                    onClick={() => setActiveLanguageFilter(filter)}
                  />
                ))}
              </ActionDropDown>
            )}
          </HStack>
          {textToShow
            ? (
              <Text.Body aria-label="description-field">
                {textToShow}
              </Text.Body>
            )
            : (
              <Message
                aria-label="Descrizione mancante"
                type="neutral"
                message={descriptionSectionInfoMessages[activeDescriptionFilter]}
              />
            )}
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default PropertyDescriptionsWidget;
