import React from 'react';
import { Action } from '@doveit/bricks';
import { Aggregator } from '../../../domain/types';
import { Property, PublishedOn } from '../../../providers/api/dtos';
import { ThemeIcons } from '../../../theme/icons';
import { isPublishedOn, hasAStatusVisibleInSerp } from '../../utils';

interface ChildFnProps {
  view: VoidFunction,
}

export interface ViewLivePropertyActionProps {
  property: Pick<Property, 'id' | 'status'> & { publishedOn?: PublishedOn[] },
  children?: (props: ChildFnProps) => React.ReactNode,
}

const ViewLivePropertyAction: React.FC<ViewLivePropertyActionProps> = ({
  property,
  children,
}) => {
  const { id, publishedOn = [], status } = property;

  const isVisibleInSerp = React.useMemo(() => isPublishedOn(publishedOn, Aggregator.DOVE_IT) && hasAStatusVisibleInSerp(status),
    [status, publishedOn]);

  const onViewLivePropertyClick = React.useCallback(() => {
    if (isVisibleInSerp) {
      window.open(`https://www.dove.it/vendita-case/dove/dettaglio-immobile/${id}`, '_blank');
    }
  }, [isVisibleInSerp, id]);

  if (!isVisibleInSerp) {
    return null;
  }

  return children
    ? children({ view: onViewLivePropertyClick })
    : (
      <Action
        dataRef="view-live-link-action"
        iconLeft={{ path: ThemeIcons.DOVE }}
        emphasis="high"
        label="Visita"
        onClick={onViewLivePropertyClick}
      />
    );
};

export default ViewLivePropertyAction;
