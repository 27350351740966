import React from 'react';
import { ActionDropDown, Dropdown } from '@doveit/bricks';
import { Prospect } from '../../../providers/api/dtos';
import { useComputedProspectApprovalStatus } from '../../hooks/use-computed-prospect-approval-status/useComputedProspectApprovalStatus';
import ProspectApprovalStatusBadge from '../../components/prospect-approval-status-badge/ProspectApprovalStatusBagde';
import { useAgent, useCurrentAgent } from '../../../hooks/use-agent/useAgent';
import useRBAC from '../../../hooks/use-rbac/useRBAC';
import { prospectApprovalStatusColor, prospectApprovalStatusLabelForAgent, prospectApprovalStatusLabelForSupervisor } from '../../constants';
import { ProspectApprovalStatus } from '../../../domain/types';
import RequestProspectApprovalAction from '../request-prospect-approval-action/RequestProspectApprovalAction';
import CancelProspectApprovalAction from '../cancel-prospect-approval-action/CancelProspectApprovalAction';
import ConfirmProspectApprovalAction from '../confirm-prospect-approval-action/ConfirmProspectApprovalAction';
import EditProspectApprovalAction from '../edit-prospect-approval-action/EditProspectApprovalAction';

export interface UpdateProspectApprovalStatusProps extends React.AriaAttributes {
  prospect: Prospect,
  emphasis?: 'low' | 'high',
  size?: 'XS' | 'S',
  onSuccess?: (prospect: Prospect) => void,
}

const UpdateProspectApprovalStatus: React.FC<UpdateProspectApprovalStatusProps> = ({
  prospect,
  emphasis = 'low',
  size = 'S',
  onSuccess,
  ...rest
}) => {
  const { userIsAdmin } = useRBAC();
  const currentAgent = useCurrentAgent();
  const { data: { status, canBeUpdated, canBeUpdatedTo } } = useComputedProspectApprovalStatus(prospect);
  const { data: agent } = useAgent(prospect.agentId);

  const userIs = React.useMemo(() => {
    if (userIsAdmin || currentAgent?.id === agent?.supervisorId || (!currentAgent?.needsSupervisor && currentAgent?.hasLicense && currentAgent.canManage?.some((managedAgent) => managedAgent.id === agent?.id))) {
      return 'supervisor';
    }

    return 'agent';
  }, [agent?.id, agent?.supervisorId, currentAgent?.canManage, currentAgent?.hasLicense, currentAgent?.id, currentAgent?.needsSupervisor, userIsAdmin]);

  if (!canBeUpdated) {
    return (
      <ProspectApprovalStatusBadge
        status={status}
        size={size}
        emphasis={emphasis}
        userIs={userIs}
        {...rest}
      />
    );
  }

  return (
    <ActionDropDown
      aria-label="Seleziona un nuovo stato della richiesta di approvazione della valutazione"
      label={userIs === 'agent'
        ? prospectApprovalStatusLabelForAgent[status]
        : prospectApprovalStatusLabelForSupervisor[status]}
      color={prospectApprovalStatusColor[status]}
      emphasis={emphasis}
      size={size}
      {...rest}
    >
      {status === 'TO_BE_REQUESTED' && canBeUpdatedTo(ProspectApprovalStatus.WAITING_FOR_SUPERVISOR) && (
        <RequestProspectApprovalAction
          prospect={prospect}
          onSuccess={onSuccess}
        >
          {({
            requestApproval,
            label,
            ariaLabel,
            isSaving,
          }) => (
            <Dropdown.Option
              label={label}
              aria-label={ariaLabel}
              onClick={requestApproval}
              loading={isSaving}
            />
          )}
        </RequestProspectApprovalAction>
      )}
      {canBeUpdatedTo('TO_BE_REQUESTED') && (
        <CancelProspectApprovalAction
          prospect={prospect}
          onSuccess={onSuccess}
        >
          {({
            cancelApproval,
            label,
            ariaLabel,
            isSaving,
          }) => (
            <Dropdown.Option
              label={label}
              aria-label={ariaLabel}
              onClick={cancelApproval}
              loading={isSaving}
            />
          )}
        </CancelProspectApprovalAction>
      )}
      {canBeUpdatedTo(ProspectApprovalStatus.APPROVED) && (
        <ConfirmProspectApprovalAction
          prospect={prospect}
          onSuccess={onSuccess}
        >
          {({
            confirmApproval,
            label,
            ariaLabel,
            isSaving,
          }) => (
            <Dropdown.Option
              label={label}
              aria-label={ariaLabel}
              onClick={confirmApproval}
              loading={isSaving}
            />
          )}
        </ConfirmProspectApprovalAction>
      )}
      {status !== 'TO_BE_REQUESTED' && (canBeUpdatedTo(ProspectApprovalStatus.WAITING_FOR_SUPERVISOR) || canBeUpdatedTo(ProspectApprovalStatus.WAITING_FOR_AGENT)) && (
        <EditProspectApprovalAction
          prospect={prospect}
          onSuccess={onSuccess}
        >
          {({
            editApproval,
            label,
            ariaLabel,
            isSaving,
          }) => (
            <Dropdown.Option
              label={label}
              aria-label={ariaLabel}
              onClick={editApproval}
              loading={isSaving}
            />
          )}
        </EditProspectApprovalAction>
      )}
    </ActionDropDown>
  );
};

export default UpdateProspectApprovalStatus;
