import {
  ThemedFunctor,
  getTypography,
  SpacingName,
  TypographyName,
  getSpacing,
  BreakpointQueryName,
} from '@doveit/bricks';
import { css } from 'styled-components';
import { HorizontalAlignment, VerticalAlignment } from '../../../types';

const mapVerticalAlignmentToFlexAlignment: {
  [key in VerticalAlignment]: string
} = {
  [VerticalAlignment.TOP]: 'flex-start',
  [VerticalAlignment.CENTER]: 'center',
  [VerticalAlignment.BOTTOM]: 'flex-end',
};

const mapHorizontalAlignmentToFlexAlignment: {
  [key in HorizontalAlignment]: string
} = {
  [HorizontalAlignment.LEFT]: 'flex-start',
  [HorizontalAlignment.CENTER]: 'center',
  [HorizontalAlignment.RIGHT]: 'flex-end',
};

export const base: ThemedFunctor = ({
  theme,
  $unpadded,
  $compact,
  $fit,
  $grow,
  $isCollapsed,
  $verticalAlignment,
  $horizontalAlignment,
}) => css`
  ${getTypography(theme, TypographyName.BODY)}
  ${!$unpadded && getSpacing.padding(theme, $compact ? SpacingName.SMALLEST : SpacingName.SMALLER)}
  display: ${$isCollapsed ? 'none' : 'flex'};
  flex-direction: column;
  align-self: stretch;
  align-items: ${mapHorizontalAlignmentToFlexAlignment[$horizontalAlignment as HorizontalAlignment]};

  > * {
    /** It guarantees that direct children get a maximum available width */
    width: 100%;
  }

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    display: flex;
    flex-grow: ${$grow || 1};
    justify-content: ${mapVerticalAlignmentToFlexAlignment[$verticalAlignment as VerticalAlignment]};

    ${$fit && `
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: inherit !important;
      width: inherit !important;
    `}
  }
`;
