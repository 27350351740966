import { WithRequiredKeys } from '../../../types';
import { Property } from './property';

export enum ItalianTextualInfoType {
  abstract = 'abstract',
  composition = 'composition',
  description = 'description',
}

export enum EnglishTextualInfoType {
  abstractEn = 'abstractEn',
  compositionEn = 'compositionEn',
  descriptionEn = 'descriptionEn',
}

export type Descriptions = keyof typeof ItalianTextualInfoType | keyof typeof EnglishTextualInfoType;

export type PropertyDescriptionsFormValues = Pick<WithRequiredKeys<Property, Descriptions>, Descriptions>;

export type PropertyTransformationContext =
| 'PROPERTY_ABSTRACT'
| 'PROPERTY_COMPOSITION'
| 'PROPERTY_DESCRIPTION';

export type RephraseContext = 'REPHRASE';
export type TranslateContext = 'TRANSLATE';

export const mapToContext: Record<ItalianTextualInfoType, PropertyTransformationContext> = {
  abstract: 'PROPERTY_ABSTRACT',
  composition: 'PROPERTY_COMPOSITION',
  description: 'PROPERTY_DESCRIPTION',
};

export interface TextProcessing {
  text: string,
}

/**
 * Generation
 */
export interface EntityRelatedGenerationRequest {
  context: PropertyTransformationContext
  entityId: unknown,
}

export interface EntityRelatedGenerationResponse extends EntityRelatedGenerationRequest, TextProcessing {}

export interface PropertyGenerationRequest extends EntityRelatedGenerationRequest {
  context: PropertyTransformationContext,
  entityId: NonNullable<Property['id']>,
}

export type PropertyGenerationResponse = PropertyGenerationRequest & EntityRelatedGenerationResponse;

/**
 * Rephrase
 */
export interface RephraseRequest extends TextProcessing {
  context: RephraseContext
}

export type RephraseResponse = RephraseRequest;

/**
 * Translation
 */
export interface TranslationRequest extends TextProcessing {
  context: TranslateContext
}

export type TranslationResponse = TranslationRequest;
