import React, {
  FunctionComponent, useCallback, useRef,
} from 'react';
import { Message, Spacing } from '@doveit/bricks';
import FullTextSearchForm, { FullTextSearchFormModel } from '../../../components/full-text-search-form/FullTextSearchForm';
import GenericPageLayout from '../../../layouts/generic-page-layout/GenericPageLayout';
import useSearchContacts from '../../hooks/use-search-contacts/useSearchContacts';
import usePagination from '../../../hooks/use-pagination/usePagination';
import PaginatedList from '../../../components/paginated-list/PaginatedList';
import SearchContactCard from '../../containers/search-contact-card/SearchContactCard';
import useFilters, { Filters } from '../../../hooks/use-filters/useFilters';

interface SearchContactPageFilters extends Filters {
  q?: string;
}

export const CONTACT_SEARCH_ERROR_MESSAGE = 'Errore durante la ricerca del contatto';

const SearchContactPage: FunctionComponent = () => {
  const { filters: { q: searchQuery }, addFilters, removeFilter } = useFilters<SearchContactPageFilters>();
  const scrollRef = useRef<HTMLDivElement>(null);
  const contactsPagination = usePagination<HTMLDivElement>('page', scrollRef);

  const { data: contacts, error: contactsError } = useSearchContacts(searchQuery, {
    size: 20,
    page: contactsPagination.page,
  });

  const onSearchSubmit = useCallback(({ search }: FullTextSearchFormModel) => {
    const newFilter: SearchContactPageFilters = {
      q: search?.trim(),
    };
    addFilters(newFilter);
    removeFilter(contactsPagination.paginationQueryParam);
  }, [addFilters, removeFilter, contactsPagination.paginationQueryParam]);

  const isSearching = !!searchQuery && !contacts && !contactsError;

  return (
    <GenericPageLayout>
      <GenericPageLayout.Content>
        <GenericPageLayout.InnerContent innerRef={scrollRef}>
          <FullTextSearchForm
            onSubmit={onSearchSubmit}
            initialValues={{ search: searchQuery }}
            placeholder="Cerca per nome, telefono o email"
            loading={isSearching}
          />

          {!contacts && contactsError && (
            <Spacing margin={[200, 0, 0]}>
              <Message
                type="critical"
                message={CONTACT_SEARCH_ERROR_MESSAGE}
              />
            </Spacing>
          )}

          {contacts && (
            <Spacing margin={[200, 0, 0]}>
              <PaginatedList
                {...contacts}
                query={searchQuery}
                goToPage={contactsPagination.goToPage}
              >
                {contacts.content.map((contact) => (
                  <SearchContactCard
                    key={contact.id}
                    contact={contact}
                  />
                ))}
              </PaginatedList>
            </Spacing>
          )}
        </GenericPageLayout.InnerContent>
      </GenericPageLayout.Content>
    </GenericPageLayout>
  );
};

export default SearchContactPage;
